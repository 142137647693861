import { createStore } from "vuex";
import theme from "./modules/theme";
import wallet from "./modules/wallet";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    theme,
    wallet,
  },
});
