
export const roraHoldings = [
  {
    id: "0xfbadc5777efc2d14c9cc284b266a85037ed529b8",
    decimals: "18",
    name: "NYXEX Token",
    symbol: "NYX",
    logo: require("@/assets/rora/nyx.png"),
    priceUSD: "0.001",
    price: "0.001",
    totalValueLocked: "269900000000",
    totalValueLockedUSD: "2699000000",
    volume: "1100567",
    volumeUSD: "1100567",
    volumeChangePercentage: 0,
    holders: 17,
    marketCap: "881796291.74"
  },
  // {
  //   id: "0xd682C2FF2a311959E62F242e77037C18DF3aC823",
  //   decimals: "18",
  //   name: "Rora Diamond",
  //   symbol: "RORAD",
  //   logo: require("@/assets/rora/rorad.png"),
  //   priceUSD: "35",
  // },
  // {
  //   id: "0x8a63c399E4A6822B78280A36B927001012dC82F8",
  //   decimals: "18",
  //   name: "Rora Gold",
  //   symbol: "RORAG",
  //   logo: require("@/assets/rora/rorag.svg"),
  //   priceUSD: "1727.75",
  //   price: "1727.75",
  //   totalValueLocked: "1500000.00",
  //   totalValueLockedUSD: "1500000",
  //   volume: "0",
  //   volumeUSD: "0",
  //   volumeChangePercentage: "0",
  //   holders: 25
  // },
];
