<template>
  <div
    class="nx-inline-loader"
    :class="{
      'nx-inline-loader--small': isSmall,
    }"
  >
    <div class="nx-inline-loader__inner"></div>
  </div>
</template>

<script>
export default {
  name: "InlineLoader",
  props: {
    isSmall: Boolean,
  },
};
</script>

<style scoped>
.nx-inline-loader {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
}
.nx-inline-loader .nx-inline-loader__inner {
  position: relative;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: transparent;
  z-index: 2;
  border: 2px solid #f3f3f3;
  border-top: 2px solid var(--nxPrimary);
  animation: antispin 0.8s linear infinite;
}
.nx-inline-loader::before {
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid #f3f3f3;
  border-top: 2px solid var(--nxPrimary);
  position: absolute;
  z-index: 1;
  animation: spin 1s linear infinite;
}
.nx-inline-loader.nx-inline-loader--small {
  height: 18px;
  width: 18px;
  border-width: 1px !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes antispin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
