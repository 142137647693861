<template>
  <div
    class="nx-modal"
    :class="{
      'nx-modal--open': modalIsOpen,
    }"
  >
    <div
      class="nx-modal__content"
      :class="{
        'nx-modal__content--large': isLarge,
      }"
    >
      <card>
        <div class="d-flex align-items-center justify-content-end mb-4">
          <a
            href="javascript:void(0)"
            @click="closeModal"
            class="nx-text-icon nx-text-icon--large"
          >
            <i class="bi bi-x-lg"></i>
          </a>
        </div>
        <slot></slot>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalWrapper",
  props: {
    isLarge: Boolean,
  },
  data() {
    return {
      modalIsOpen: false,
    };
  },
  methods: {
    openModal() {
      this.modalIsOpen = true;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.modalIsOpen = false;
      document.body.style.overflow = "auto";
    },
  },
};
</script>
<style scoped>
.nx-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500;
  top: 0;
  left: 0;
  overflow-y: auto;
  display: none;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 1rem;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px -10px rgb(0 0 0 / 20%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: 0.2s ease-in-out;
}

.nx-modal.nx-modal--open {
  transition: 0.2s ease-in-out;
  display: flex;
}

.nx-modal .nx-modal__content {
  position: relative;
  height: auto;
  width: 100%;
  max-width: 600px;
  z-index: 10;
}

.nx-modal .nx-modal__content.nx-modal__content--large {
  max-width: 1100px;
}

@media screen and (max-width: 480px) {
  .nx-modal .nx-modal__content {
    padding: 2rem 1rem;
  }
}
</style>
