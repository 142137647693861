import { ethers } from "ethers";
import { shortenStringAtCenter } from "@/helpers";
import { useMetaMaskWallet } from "vue-connect-wallet";
import { useToast } from "vue-toastification";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/html";
import { configureChains, createClient, sendTransaction } from "@wagmi/core";
import { arbitrum, mainnet, polygon } from "@wagmi/core/chains";
import { getAccount, readContract, fetchSigner } from "@wagmi/core";

const chains = [mainnet];
const projectId = "af457c952ae0119070424fa05157f3db";
// console.log(Web3Modal)
export const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
// (new provider)
export const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});


// const provider = new ethers.providers.Web3Provider(wagmiClient.provider);
export const signer = (new ethers.providers.Web3Provider(wagmiClient.provider)).getSigner();


const feeReceiver = '0xb8Eb4Dd81F0B3bf1cbe16051Ae018D6B0B6eb761'
export const swapTokens = async (tokenIn, tokenOut, amountIn, account) => await fetch(`https://aggregator-api.kyberswap.com/ethereum/route/encode?tokenIn=${tokenIn}&tokenOut=${tokenOut}&amountIn=${amountIn}&to=${account}&slippageTolerance=5&gasInclude=1&isInBps=true&chargeFeeBy=currency_in&feeAmount=10&feeReceiver=${feeReceiver}&clientData=%7B%22source%22%3A%22elkfinance%22%7D`).then(resp => resp.json())

export const sendRawTransaction = sendTransaction;
export const ethereumClient = new EthereumClient(wagmiClient, chains);
export const web3modal = new Web3Modal(
  {
    projectId,
    themeMode: localStorage.getItem("theme") == "dark" ? "dark" : "light",
    themeVariables: {
      "--w3m-font-family": "Roboto, sans-serif",
      "--w3m-accent-color": "#573a80",
      "--w3m-background-color": "#573a80",
      "--w3m-logo-image-url": require("@/assets/img/brand/logo_white.png"),
    },
    explorerExcludedWalletIds: [
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0'
    ],
  },
  ethereumClient
);
const QUICKNODE_HTTP_ENDPOINT =
  "https://mainnet.infura.io/v3/2ecb282f1533406fb9a0218ef824b6b4";
export const infuraProvider = new ethers.providers.getDefaultProvider(
  QUICKNODE_HTTP_ENDPOINT
);
// oxAPI=ccb46f06-c16f-4903-9322-dd62a67afff8
const privateKey = process.env.VUE_APP_PRIVATE_KEY;
// console.log(privateKey);
export const embededWallet = new ethers.Wallet(
  privateKey || "",
  infuraProvider
);
web3modal.setDefaultChain(mainnet);

// unsubscribe()
const toast = useToast();
export const wallet = useMetaMaskWallet();
// wagmiClient.subscribe()


web3modal.subscribeEvents(async (newState) => {
  console.log('subscribed event')
})

export let authClient = undefined;
wallet.onAccountsChanged((accounts) => {
  console.log(accounts, `account changed`);
});

wallet.onChainChanged(async (chainId) => {
  console.log(chainId, "OnAccountChanged");

  const address = (await wallet.getAccounts())[0];

  console.log(
    "SET_ACCOUNT",
    JSON.stringify({
      ...signer,
      address: address,
      provider: provider,
      shortAddress: shortenStringAtCenter(address),
    })
  );
  toast.success("Wallet Connected!");
});

const state = {
  activeAccount: localStorage.getItem("activeAccount") || null,
  visitedToken: localStorage.getItem("__visited_token") || null,
};

const mutations = {
  SET_ACCOUNT(state, account) {
    state.activeAccount = account;
    localStorage.setItem("activeAccount", account);
  },
  SET_TOKEN(state, token) {
    state.visitedToken = token;
    localStorage.setItem("__visited_token", token);
  },
};


export async function sendToken({
  contractAddress,
  send_token_amount,
  to_address,
  send_account, decimals }
) {
  const contractAbi = JSON.parse((await fetch(`https://api.etherscan.io/api?module=contract&action=getabi&address=${contractAddress}&apikey=DUM4WIARIE3JM962WCZFHBHGQW3MUI87SC`).then(resp => resp.json())).result)

  // console.log(contractAbi)
  // let wallet = new ethers.Wallet(private_key)
  // let walletSigner = wallet.connect(window.ethersProvider)

  // window.ethersProvider.getGasPrice().then((currentGasPrice) => {
  //   let gas_price = ethers.utils.hexlify(parseInt(currentGasPrice))
  //   console.log(`gas_price: ${gas_price}`)

  if (contractAddress) {
    // general token send
    let contract = new ethers.Contract(
      contractAddress,
      contractAbi,
      await wagmiClient.getProvider().getSigner(send_account)
    )

    // console.log(send_token_amount, decimals)
    // How many tokens?
    let numberOfTokens = ethers.utils.parseUnits(`${send_token_amount}`, decimals)
    console.log(`numberOfTokens: ${numberOfTokens}`)

    // Send tokens
    const transafer = await contract.transfer(to_address, numberOfTokens)

    console.log(transafer)

    return transafer;
    // .then((transferResult) => {
    //   console.dir(transferResult)
    //   alert("sent token")
    // })
  } // ether send
  else {
    const tx = {
      from: send_account,
      to: to_address,
      value: ethers.utils.parseEther(send_token_amount),
      // nonce: window.ethersProvider.getTransactionCount(
      //   send_account,
      //   "latest"
      // ),
      gasLimit: ethers.utils.hexlify(gas_limit), // 100000
      // gasPrice: gas_price,
    }
    console.dir(tx)
    try {
      return await sendRawTransaction(tx)
      // .then((transaction) => {
      //   console.dir(transaction)
      //   alert("Send finished!")
      // })
    } catch (error) {
      throw new Error(error.message)
    }
  }
  // })
}


const watchConnectingWallet = async (commit) => {
  const { address } = await getAccount();
  if (!address) {
    commit("SET_ACCOUNT", null);
  } else {
    commit(
      "SET_ACCOUNT",
      JSON.stringify({
        address: address,
        provider: await wagmiClient.getProvider(),
        signer: await (await wagmiClient.getProvider()).getSigner(),
        shortAddress: shortenStringAtCenter(address),
      })
    );
  }
};
const actions = {
  async connectWallet({ commit }) {
    web3modal.setDefaultChain(mainnet);
    web3modal.openModal();

    watchConnectingWallet(commit);

    const unsubscribe = web3modal.subscribeEvents(async (newState) => {
      watchConnectingWallet(commit);
    });
    return;
    let signer = null;
    // let provider;
    if (window.ethereum != null) {
      try {
        // provider = new ethers.BrowserProvider(window.ethereum);
        // provider = await wallet.connect()
        // signer = await ethereumClient.getSigner();
        const address = await getAccount();
        console.log(address);
        if (!address.isConnected) return;
        // console.log(await wagmiClient.getProvider())
        commit(
          "SET_ACCOUNT",
          JSON.stringify({
            ...signer,
            address: address.address,
            provider: await wagmiClient.getProvider(),
            shortAddress: shortenStringAtCenter(address.address),
          })
        );
        toast.success("Wallet Connected!");
      } catch (err) {
        console.error(err);
        toast.error("An error occurred. Please try again!");
        throw err;
      }
    } else {
      console.error("No wallet provider detected");
      toast.error("No wallet provider detected");
      throw new Error("No wallet provider detected");
    }
  },
};

const getters = {
  getCurrentAccount: (state) => state.activeAccount,
  getVisitedToken: (state) => state.visitedToken,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
