// theme.js

const state = {
  currentTheme: localStorage.getItem("theme") || "light",
};

const mutations = {
  SET_THEME(state, theme) {
    state.currentTheme = theme;
    localStorage.setItem("theme", theme);
    if (theme == "light") {
      document.body.classList.remove("dark");
      document.body.classList.add(theme);
    } else {
      document.body.classList.remove("light");
      document.body.classList.add(theme);
    }
  },
};

const actions = {
  setTheme({ commit }, theme) {
    commit("SET_THEME", theme);
  },
};

const getters = {
  getCurrentTheme: (state) => state.currentTheme,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
