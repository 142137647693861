export const coinList = [
  {
    name: "Aerarium Fi",
    symbol: "AERA",
    slug: "aerarium-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aerarium-fi.png",
  },
  {
    name: "Modden",
    symbol: "MDDN",
    slug: "modden",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/modden.png",
  },
  {
    name: "GICTrade",
    symbol: "GICT",
    slug: "gictrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gictrade.png",
  },
  {
    name: "UAVI Drone",
    symbol: "UAVI",
    slug: "uavi-drone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uavi-drone.png",
  },
  {
    name: "Gary",
    symbol: "GARY",
    slug: "gary",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gary.png",
  },
  {
    name: "YES WORLD",
    symbol: "YES",
    slug: "yes-world-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yes-world-token.png",
  },
  {
    name: "Gravitationally Bound AURA",
    symbol: "GRAVIAURA",
    slug: "gravitationally-bound-aura",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gravitationally-bound-aura.png",
  },
  {
    name: "DashLeague Crystals",
    symbol: "DLC",
    slug: "dashleague-crystals",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dashleague-crystals.png",
  },
  {
    name: "Zombiverse",
    symbol: "ZBV",
    slug: "zombiverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zombiverse.png",
  },
  {
    name: "BLOCKS",
    symbol: "BLOCKS",
    slug: "blocks-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocks-.png",
  },
  {
    name: "Sleep Care",
    symbol: "SLEEP",
    slug: "sleep-care",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sleep-care.png",
  },
  {
    name: "ZenithFinance",
    symbol: "ZNT",
    slug: "zenithfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenithfinance.png",
  },
  {
    name: "Run For Life",
    symbol: "RFL",
    slug: "run-for-life",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/run-for-life.png",
  },
  {
    name: "Park Star",
    symbol: "P-S-T-A-R",
    slug: "park-star",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/park-star.png",
  },
  {
    name: "KROME Shares",
    symbol: "KROME",
    slug: "krome-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krome-shares.png",
  },
  {
    name: "10mb",
    symbol: "10MB",
    slug: "10mb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/10mb.png",
  },
  {
    name: "Stonk League",
    symbol: "Aegis",
    slug: "stonk-league",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stonk-league.png",
  },
  {
    name: "MetaCos",
    symbol: "MTCS",
    slug: "metacos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metacos.png",
  },
  {
    name: "CZbnb",
    symbol: "CZBNB",
    slug: "czbnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/czbnb.png",
  },
  {
    name: "Ramestta",
    symbol: "RAMA",
    slug: "ramestta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ramestta.png",
  },
  {
    name: "BananaClubToken",
    symbol: "BCT",
    slug: "bananaclubtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bananaclubtoken.png",
  },
  {
    name: "Fellaz",
    symbol: "FLZ",
    slug: "fellaz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fellaz.png",
  },
  {
    name: "Qlindo",
    symbol: "QLINDO",
    slug: "qlindo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qlindo.png",
  },
  {
    name: "Klever Finance",
    symbol: "KFI",
    slug: "klever-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klever-finance.png",
  },
  {
    name: "Mudra MDR",
    symbol: "MDR",
    slug: "mudra-mdr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mudra-mdr.png",
  },
  {
    name: "Victory",
    symbol: "AVIC",
    slug: "victory",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/victory.png",
  },
  {
    name: "EverStart",
    symbol: "START",
    slug: "everstart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everstart.png",
  },
  {
    name: "USD Freedom",
    symbol: "USDF",
    slug: "usd-freedom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usd-freedom.png",
  },
  {
    name: "Hermes Protocol",
    symbol: "HERMES",
    slug: "hermes-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hermes-protocol.png",
  },
  {
    name: "MoveMoon",
    symbol: "MVM",
    slug: "movemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/movemoon.png",
  },
  {
    name: "Wrapped Brise",
    symbol: "WBRISE",
    slug: "wrapped-brise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-brise.png",
  },
  {
    name: "Piggy Protocol",
    symbol: "PIGGY",
    slug: "piggy-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piggy-protocol.png",
  },
  {
    name: "APEmove",
    symbol: "APE",
    slug: "apemove",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apemove.png",
  },
  {
    name: "Genopets KI",
    symbol: "KI",
    slug: "genopets-ki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genopets-ki.png",
  },
  {
    name: "Based Finance Next Gen",
    symbol: "OBOL",
    slug: "based-finance-next-gen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/based-finance-next-gen.png",
  },
  {
    name: "Inflation Adjusted EUROS",
    symbol: "IEUROS",
    slug: "inflation-adjusted-euros",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inflation-adjusted-euros.png",
  },
  {
    name: "Super Soccer",
    symbol: "SPS",
    slug: "super-soccer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-soccer.png",
  },
  {
    name: "CHI Coin",
    symbol: "CHI",
    slug: "chi-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chi-coin.png",
  },
  {
    name: "StarBlock",
    symbol: "STB",
    slug: "starblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starblock.png",
  },
  {
    name: "F5 Sleep",
    symbol: "F5S",
    slug: "f5-sleep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/f5-sleep.png",
  },
  {
    name: "Parrot USD",
    symbol: "PAI",
    slug: "parrot-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parrot-usd.png",
  },
  {
    name: "LuxTTO",
    symbol: "LXTO",
    slug: "luxtto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luxtto.png",
  },
  {
    name: "Crown Finance",
    symbol: "CRN",
    slug: "crown-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crown-finance.png",
  },
  {
    name: "Baby Manchester City",
    symbol: "BCITY",
    slug: "baby-manchester-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-manchester-city.png",
  },
  {
    name: "NFT-MAKER",
    symbol: "NMKR",
    slug: "nft-maker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-maker.png",
  },
  {
    name: "Carbonic",
    symbol: "CO2",
    slug: "carbonic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbonic.png",
  },
  {
    name: "Enigma",
    symbol: "ENGM",
    slug: "enigma-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enigma-token.png",
  },
  {
    name: "MINT 2 EARN",
    symbol: "MTE",
    slug: "mint-2-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mint-2-earn.png",
  },
  {
    name: "Nabob",
    symbol: "NABOB",
    slug: "nabob",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nabob.png",
  },
  {
    name: "Safcoin",
    symbol: "SAF",
    slug: "safcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safcoin.png",
  },
  {
    name: "Pizza Inu",
    symbol: "PINU",
    slug: "pizza-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pizza-inu.png",
  },
  {
    name: "Metaxa",
    symbol: "METAXA",
    slug: "metaxa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaxa.png",
  },
  {
    name: "Kalycoin",
    symbol: "KLC",
    slug: "kalycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalycoin.png",
  },
  {
    name: "Fasst",
    symbol: "FAS",
    slug: "fasst",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fasst.png",
  },
  {
    name: "MoonShine",
    symbol: "Moon",
    slug: "moonshine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonshine.png",
  },
  {
    name: "Sing To Earn",
    symbol: "S2E",
    slug: "sing-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sing-to-earn.png",
  },
  {
    name: "MoveX",
    symbol: "MOVX",
    slug: "movex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/movex.png",
  },
  {
    name: "SEKOPAY",
    symbol: "SEKO",
    slug: "sekopay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sekopay.png",
  },
  {
    name: "Expanz",
    symbol: "XPANZ",
    slug: "expanz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/expanz.png",
  },
  {
    name: "Velodrome Finance",
    symbol: "VELO",
    slug: "velodrome-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/velodrome-finance.png",
  },
  {
    name: "ICE BEAR TOKEN",
    symbol: "IBT",
    slug: "ice-bear-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ice-bear-token.png",
  },
  {
    name: "Rare Ball Shares",
    symbol: "RBS",
    slug: "rare-ball-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rare-ball-shares.png",
  },
  {
    name: "Super Rare Ball Shares",
    symbol: "SRBS",
    slug: "super-rare-ball-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-rare-ball-shares.png",
  },
  {
    name: "Oceanland",
    symbol: "OLAND",
    slug: "oceanland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oceanland.png",
  },
  {
    name: "BlockSAFU",
    symbol: "BSAFU",
    slug: "blocksafu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocksafu.png",
  },
  {
    name: "AnonPay",
    symbol: "APAY",
    slug: "anonpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anonpay.png",
  },
  {
    name: "Battle World",
    symbol: "BWO",
    slug: "battle-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-world.png",
  },
  {
    name: "Polymesh",
    symbol: "POLYX",
    slug: "polymesh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polymesh.png",
  },
  {
    name: "BOX",
    symbol: "BOX",
    slug: "box",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/box.png",
  },
  {
    name: "Smart Music",
    symbol: "MUSIC",
    slug: "smart-music",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-music.png",
  },
  {
    name: "Metakillers",
    symbol: "KILL",
    slug: "metakillers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metakillers.png",
  },
  {
    name: "MULTIFI",
    symbol: "MLM",
    slug: "multifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multifi.png",
  },
  {
    name: "Richverse",
    symbol: "RIV",
    slug: "richverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/richverse.png",
  },
  {
    name: "HOME TO EARN",
    symbol: "H2E",
    slug: "home-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/home-to-earn.png",
  },
  {
    name: "Konnect",
    symbol: "KCT",
    slug: "konnect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/konnect.png",
  },
  {
    name: "SONICR",
    symbol: "SNR",
    slug: "sonicr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonicr.png",
  },
  {
    name: "Force of Nature",
    symbol: "FON",
    slug: "force-of-nature",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/force-of-nature.png",
  },
  {
    name: "Tangible",
    symbol: "TNGBL",
    slug: "tangible",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tangible.png",
  },
  {
    name: "Hedge",
    symbol: "HDG",
    slug: "hedge-hdg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedge-hdg.png",
  },
  {
    name: "Pixel Swap",
    symbol: "PIXEL",
    slug: "pixel-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixel-swap.png",
  },
  {
    name: "AjraCoin",
    symbol: "AJRC",
    slug: "ajracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ajracoin.png",
  },
  {
    name: "Dexpools",
    symbol: "DXP",
    slug: "dexpools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexpools.png",
  },
  {
    name: "STEPINU",
    symbol: "STEPI",
    slug: "stepinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stepinu.png",
  },
  {
    name: "Solily Protocol",
    symbol: "LILY",
    slug: "solily-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solily-protocol.png",
  },
  {
    name: "Aspen",
    symbol: "ASPEN",
    slug: "aspen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aspen.png",
  },
  {
    name: "PurrNFT",
    symbol: "PURRX",
    slug: "purrnft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/purrnft.png",
  },
  {
    name: "Yummi Universe",
    symbol: "YUMMI",
    slug: "yummi-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yummi-universe.png",
  },
  {
    name: "HyperRun",
    symbol: "HYPERR",
    slug: "hyperrun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperrun.png",
  },
  {
    name: "5STAR Protocol",
    symbol: "5STAR",
    slug: "5star-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/5star-protocol.png",
  },
  {
    name: "Chaotic Finance",
    symbol: "CHAOS",
    slug: "chaotic-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chaotic-finance.png",
  },
  {
    name: "Fuusion",
    symbol: "FUU",
    slug: "fuusion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fuusion.png",
  },
  {
    name: "METAZONX",
    symbol: "ZONX",
    slug: "metazonx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metazonx.png",
  },
  {
    name: "Meta Basket VR",
    symbol: "MBALL",
    slug: "meta-basket-vr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-basket-vr.png",
  },
  {
    name: "Empty Set Crypto",
    symbol: "ESC",
    slug: "empty-set-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/empty-set-crypto.png",
  },
  {
    name: "Cosmik Finance",
    symbol: "COSMIK",
    slug: "cosmik-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cosmik-finance.png",
  },
  {
    name: "Orbit",
    symbol: "ORBIT",
    slug: "orbit1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbit1.png",
  },
  {
    name: "MUDRA",
    symbol: "MUDRA",
    slug: "mudra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mudra.png",
  },
  {
    name: "Princess Striker Gem",
    symbol: "PRSG",
    slug: "princess-striker-gem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/princess-striker-gem.png",
  },
  {
    name: "LetCoinShop",
    symbol: "LCS",
    slug: "letcoinshop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/letcoinshop.png",
  },
  {
    name: "Oxfinance",
    symbol: "OXFI",
    slug: "oxfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oxfinance.png",
  },
  {
    name: "Moneta Digital",
    symbol: "MMXN",
    slug: "moneta-digital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moneta-digital.png",
  },
  {
    name: "ELON BUYS COKE COLA",
    symbol: "EBCC",
    slug: "elon-buys-coke-cola",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elon-buys-coke-cola.png",
  },
  {
    name: "SIN COIN",
    symbol: "SIN",
    slug: "sin-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sin-coin.png",
  },
  {
    name: "WOLF INU",
    symbol: "WOLF",
    slug: "wolf-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolf-inu.png",
  },
  {
    name: "Aleph Finance",
    symbol: "NALEPH",
    slug: "aleph-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aleph-finance.png",
  },
  {
    name: "Twittelon BOSS",
    symbol: "TBOSS",
    slug: "twittelon-boss",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twittelon-boss.png",
  },
  {
    name: "Snake Rinium",
    symbol: "RIM",
    slug: "snake-rinium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snake-rinium.png",
  },
  {
    name: "Slam Token",
    symbol: "SLAM",
    slug: "slam-token-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slam-token-new.png",
  },
  {
    name: "Demy Games",
    symbol: "DEMY",
    slug: "demy-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/demy-games.png",
  },
  {
    name: "DigitalDollar",
    symbol: "DUSD",
    slug: "digitaldollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digitaldollar.png",
  },
  {
    name: "DoctorS Token",
    symbol: "DST",
    slug: "doctors-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doctors-token.png",
  },
  {
    name: "sKCS.io",
    symbol: "SKCS",
    slug: "skcsio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skcsio.png",
  },
  {
    name: "ZipSwap",
    symbol: "ZIP",
    slug: "zipswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zipswap.png",
  },
  {
    name: "LOOF",
    symbol: "LOOF",
    slug: "loof",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loof.png",
  },
  {
    name: "RUN DOGE",
    symbol: "RUNDOGE",
    slug: "run-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/run-doge.png",
  },
  {
    name: "SafeDogeCoin V2",
    symbol: "SAFEDOGE",
    slug: "safedogecoin-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safedogecoin-v2.png",
  },
  {
    name: "NINJA INU",
    symbol: "NINJA",
    slug: "ninja-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninja-inu.png",
  },
  {
    name: "Lanuna",
    symbol: "LUNU",
    slug: "lanuna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lanuna.png",
  },
  {
    name: "PyramiDAO",
    symbol: "PYRA",
    slug: "pyramidao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyramidao.png",
  },
  {
    name: "Lifinity",
    symbol: "LFNTY",
    slug: "lifinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lifinity.png",
  },
  {
    name: "Lava Financial",
    symbol: "LAVA",
    slug: "lava-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lava-financial.png",
  },
  {
    name: "Meme Chain Capital",
    symbol: "MEMES",
    slug: "meme-chain-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meme-chain-capital.png",
  },
  {
    name: "Falcon9",
    symbol: "FALCON9",
    slug: "falcon9",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/falcon9.png",
  },
  {
    name: "TABI",
    symbol: "TABI",
    slug: "tabi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tabi.png",
  },
  {
    name: "TOKAMAK",
    symbol: "TKMK",
    slug: "tokamak",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokamak.png",
  },
  {
    name: "PIXEL NFT",
    symbol: "PNT",
    slug: "pixel-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixel-nft.png",
  },
  {
    name: "Lido On Kusama",
    symbol: "stKSM",
    slug: "lido-on-kusama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lido-on-kusama.png",
  },
  {
    name: "TWEELON",
    symbol: "TWEELON",
    slug: "tweelon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tweelon.png",
  },
  {
    name: "Tierra Meta",
    symbol: "TRMT",
    slug: "tierra-meta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tierra-meta.png",
  },
  {
    name: "H2OC",
    symbol: "H2OC",
    slug: "h2o",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/h2o.png",
  },
  {
    name: "MAFIA DOGE",
    symbol: "MDOGE",
    slug: "mafia-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mafia-doge.png",
  },
  {
    name: "Gojo Inu",
    symbol: "GOJO",
    slug: "gojo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gojo-inu.png",
  },
  {
    name: "Vehicle Mining System",
    symbol: "VMS",
    slug: "vehicle-mining-system",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vehicle-mining-system.png",
  },
  {
    name: "APPLECHAIN",
    symbol: "APPLE",
    slug: "applechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/applechain.png",
  },
  {
    name: "CAKEPAD LaunchPAD",
    symbol: "CKP",
    slug: "cakepad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cakepad.png",
  },
  {
    name: "Primo DAO",
    symbol: "PRIMO",
    slug: "primo-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/primo-dao.png",
  },
  {
    name: "Sincere Cate",
    symbol: "$SCATE",
    slug: "sincere-cate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sincere-cate.png",
  },
  {
    name: "REBASEAPY",
    symbol: "REBASEAPY",
    slug: "rebaseapy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rebaseapy.png",
  },
  {
    name: "Virtual Ads",
    symbol: "VADS",
    slug: "virtual-ads",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/virtual-ads.png",
  },
  {
    name: "Shiba's Wife",
    symbol: "SHIBASWIFE",
    slug: "shibas-wife",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibas-wife.png",
  },
  {
    name: "VIKTAMA",
    symbol: "VIK",
    slug: "viktama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viktama.png",
  },
  {
    name: "Medusa",
    symbol: "MEDUSA",
    slug: "medusa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medusa.png",
  },
  {
    name: "Belka",
    symbol: "BELKA",
    slug: "belka-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/belka-project.png",
  },
  {
    name: "Shiba Rewards",
    symbol: "SHREW",
    slug: "shiba-rewards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-rewards.png",
  },
  {
    name: "Sword Art Online",
    symbol: "SAO",
    slug: "sword-art-online",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sword-art-online.png",
  },
  {
    name: "Kragger Inu",
    symbol: "KINU",
    slug: "kragger-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kragger-inu.png",
  },
  {
    name: "Hummus",
    symbol: "HUM",
    slug: "hummus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hummus.png",
  },
  {
    name: "Hybrid",
    symbol: "$HYBRID",
    slug: "hybrid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hybrid.png",
  },
  {
    name: "Legend Of Galaxy",
    symbol: "GAE",
    slug: "legend-of-galaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legend-of-galaxy.png",
  },
  {
    name: "Prometheus",
    symbol: "PRO",
    slug: "prometheus-pro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prometheus-pro.png",
  },
  {
    name: "Probably Nothing",
    symbol: "NOTHING",
    slug: "probably-nothing-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/probably-nothing-token.png",
  },
  {
    name: "EVERFORK",
    symbol: "EVERF",
    slug: "everfork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everfork.png",
  },
  {
    name: "DecentraWorld Ecosystem",
    symbol: "$DEWO",
    slug: "decentraworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentraworld.png",
  },
  {
    name: "HighPoint Finance",
    symbol: "HPT",
    slug: "highpoint-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/highpoint-finance.png",
  },
  {
    name: "Puzzle And Dragons X",
    symbol: "PADX",
    slug: "puzzle-and-dragons-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puzzle-and-dragons-x.png",
  },
  {
    name: "LLEIDA ESPORTIU DAO",
    symbol: "LL",
    slug: "lleida-esportiu-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lleida-esportiu-dao.png",
  },
  {
    name: "MilkySwap",
    symbol: "MILKY",
    slug: "milkyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milkyswap.png",
  },
  {
    name: "Peak Finance",
    symbol: "PEAK",
    slug: "peak-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peak-finance.png",
  },
  {
    name: "AliCoin",
    symbol: "ALICN",
    slug: "alixconnect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alixconnect.png",
  },
  {
    name: "Aurora Finance",
    symbol: "AURA",
    slug: "aurorafinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurorafinance.png",
  },
  {
    name: "Revenant",
    symbol: "GAMEFI",
    slug: "revenant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revenant.png",
  },
  {
    name: "Leonidas",
    symbol: "LEO",
    slug: "leonidas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leonidas.png",
  },
  {
    name: "Vesta Finance",
    symbol: "VSTA",
    slug: "vesta-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vesta-finance.png",
  },
  {
    name: "EZChain",
    symbol: "EZC",
    slug: "ezchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ezchain.png",
  },
  {
    name: "Facebook Tokenized Stock Zipmex",
    symbol: "FB",
    slug: "facebook-tokenized-stock-zipmex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/facebook-tokenized-stock-zipmex.png",
  },
  {
    name: "Netflix Tokenized Stock Zipmex",
    symbol: "NFLX",
    slug: "netflix-tokenized-stock-zipmex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netflix-tokenized-stock-zipmex.png",
  },
  {
    name: "Twitter Tokenized Stock Zipmex",
    symbol: "TWTR",
    slug: "twitter-tokenized-stock-zipmex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twitter-tokenized-stock-zipmex.png",
  },
  {
    name: "Airbnb Tokenized Stock Zipmex",
    symbol: "ABNB",
    slug: "airbnb-tokenized-stock-zipmex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/airbnb-tokenized-stock-zipmex.png",
  },
  {
    name: "Alibaba Tokenized Stock Zipmex",
    symbol: "BABA",
    slug: "alibaba-tokenized-stock-zipmex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alibaba-tokenized-stock-zipmex.png",
  },
  {
    name: "SolanaPrime",
    symbol: "PRIME",
    slug: "solanaprime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solanaprime.png",
  },
  {
    name: "Apex Predator",
    symbol: "APEX",
    slug: "apex-predator",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apex-predator.png",
  },
  {
    name: "Kitty Kat Coin",
    symbol: "KATS",
    slug: "kitty-kat-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitty-kat-coin.png",
  },
  {
    name: "Zirve Coin",
    symbol: "ZIRVE",
    slug: "zirve-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zirve-coin.png",
  },
  {
    name: "United Emirate Coin",
    symbol: "UEC",
    slug: "united-emirate-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/united-emirate-coin.png",
  },
  {
    name: "Shiba Hunter",
    symbol: "FALQOM",
    slug: "shiba-hunter-falqom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-hunter-falqom.png",
  },
  {
    name: "Dogs Kombat",
    symbol: "DK",
    slug: "dogs-kombat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogs-kombat.png",
  },
  {
    name: "Concave",
    symbol: "CNV",
    slug: "concave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/concave.png",
  },
  {
    name: "Meta Continental",
    symbol: "CON",
    slug: "metacontinental",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metacontinental.png",
  },
  {
    name: "Crypto Klash",
    symbol: "KLH",
    slug: "crypto-klash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-klash.png",
  },
  {
    name: "ArtKit",
    symbol: "ARTI",
    slug: "artkit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artkit.png",
  },
  {
    name: "Dabb Doge",
    symbol: "DDOGE",
    slug: "dabb-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dabb-doge.png",
  },
  {
    name: "Vitteey",
    symbol: "VITY",
    slug: "vitteey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vitteey.png",
  },
  {
    name: "Honey Finance",
    symbol: "HONEY",
    slug: "honey-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honey-finance.png",
  },
  {
    name: "Hippo Protocol",
    symbol: "HIPPO",
    slug: "hippo-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hippo-protocol.png",
  },
  {
    name: "Aavegotchi FOMO",
    symbol: "FOMO",
    slug: "aavegotchi-fomo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aavegotchi-fomo.png",
  },
  {
    name: "Smart Finance",
    symbol: "SMRT",
    slug: "smartnodes-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartnodes-finance.png",
  },
  {
    name: "Treasure Token Finance",
    symbol: "TREASURE",
    slug: "treasure-token-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treasure-token-finance.png",
  },
  {
    name: "Hera Finance",
    symbol: "HERA",
    slug: "hera-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hera-finance.png",
  },
  {
    name: "Capital Aggregator Token",
    symbol: "CAT+",
    slug: "capital-aggregator-token-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/capital-aggregator-token-v2.png",
  },
  {
    name: "USD Velero Stablecoin",
    symbol: "USDV",
    slug: "usdv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdv.png",
  },
  {
    name: "Prism yLUNA",
    symbol: "YLUNA",
    slug: "prism-yluna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prism-yluna.png",
  },
  {
    name: "Monster Saga",
    symbol: "MTS",
    slug: "monster-saga",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-saga.png",
  },
  {
    name: "TiraVerse",
    symbol: "TVRS",
    slug: "tiraverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiraverse.png",
  },
  {
    name: "Dreamy Undersea World",
    symbol: "DUW",
    slug: "dreamy-undersea-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dreamy-undersea-world.png",
  },
  {
    name: "Etherstones",
    symbol: "ETHS",
    slug: "etherstones",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherstones.png",
  },
  {
    name: "DAO Farmer DAOF",
    symbol: "DAOF",
    slug: "dao-farmer-daof",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dao-farmer-daof.png",
  },
  {
    name: "Fantastic Protocol Peg-AVAX (FAVAX) Token",
    symbol: "FAVAX",
    slug: "fantastic-protocol-peg-avax-favax-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantastic-protocol-peg-avax-favax-token.png",
  },
  {
    name: "Fantastic Protocol SNOWY Token",
    symbol: "SNOWY",
    slug: "fantastic-protocol-snowy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantastic-protocol-snowy-token.png",
  },
  {
    name: "Meta Shark",
    symbol: "MTS",
    slug: "meta-shark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-shark.png",
  },
  {
    name: "CRYN",
    symbol: "CRYN",
    slug: "cryn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryn.png",
  },
  {
    name: "Nooft",
    symbol: "NOOFT",
    slug: "nooft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nooft.png",
  },
  {
    name: "Monastery Finance",
    symbol: "MONK",
    slug: "monastery-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monastery-finance.png",
  },
  {
    name: "BiggerMINDS",
    symbol: "MIND",
    slug: "biggerminds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biggerminds.png",
  },
  {
    name: "BinaryDAO",
    symbol: "BYTE",
    slug: "binarydao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binarydao.png",
  },
  {
    name: "CorgiNFTGame",
    symbol: "SPET",
    slug: "corginftgame-spet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corginftgame-spet.png",
  },
  {
    name: "OTO Protocol",
    symbol: "OTO",
    slug: "oto-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oto-protocol.png",
  },
  {
    name: "BNBDeFi",
    symbol: "$DEFI",
    slug: "bnbdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbdefi.png",
  },
  {
    name: "Crypviser",
    symbol: "CVNX",
    slug: "crypviser",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypviser.png",
  },
  {
    name: "Virtus Finance",
    symbol: "VAP",
    slug: "virtus-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/virtus-finance.png",
  },
  {
    name: "COVID-19 Recovery Token",
    symbol: "COVDR",
    slug: "covid-19-recovery-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/covid-19-recovery-token.png",
  },
  {
    name: "Snowcat DAO",
    symbol: "SCAT",
    slug: "snowcat-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowcat-dao.png",
  },
  {
    name: "Confetti",
    symbol: "CFTI",
    slug: "confetti",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/confetti.png",
  },
  {
    name: "MetaMatic",
    symbol: "META",
    slug: "metamatic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamatic.png",
  },
  {
    name: "Unitech",
    symbol: "UTC",
    slug: "unitech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unitech.png",
  },
  {
    name: "Boost Love Potion",
    symbol: "BLP",
    slug: "boost-love-potion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boost-love-potion.png",
  },
  {
    name: "Opsya Insurance",
    symbol: "OPSY",
    slug: "opsya-insurance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opsya-insurance.png",
  },
  {
    name: "Web 3 Development",
    symbol: "3WEB",
    slug: "web-3-development",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web-3-development.png",
  },
  {
    name: "Frank Inu",
    symbol: "FRANK",
    slug: "frank-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frank-inu.png",
  },
  {
    name: "Doaibu",
    symbol: "DOA",
    slug: "doaibu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doaibu.png",
  },
  {
    name: "NFT All Best ICO",
    symbol: "NFTALLBI",
    slug: "nft-all-best-ico",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-all-best-ico.png",
  },
  {
    name: "BCSSTAR",
    symbol: "BCSS",
    slug: "bcsstar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bcsstar.png",
  },
  {
    name: "Playground",
    symbol: "PLAYA",
    slug: "playground",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playground.png",
  },
  {
    name: "Boryoku Genesis Dragonz Index",
    symbol: "DRGNZ",
    slug: "boryoku-genesis-dragonz-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boryoku-genesis-dragonz-index.png",
  },
  {
    name: "Stoned Ape Crew Index",
    symbol: "SAC",
    slug: "stoned-ape-crew-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stoned-ape-crew-index.png",
  },
  {
    name: "Content Coin",
    symbol: "CONT",
    slug: "content-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/content-coin.png",
  },
  {
    name: "Logosunaux",
    symbol: "LOGOS",
    slug: "logosunaux",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/logosunaux.png",
  },
  {
    name: "ZINJA",
    symbol: "Z",
    slug: "zinja",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zinja.png",
  },
  {
    name: "Battle for Life",
    symbol: "BFL",
    slug: "battle-for-life",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-for-life.png",
  },
  {
    name: "Shuey Rhon Inu",
    symbol: "SHUEY",
    slug: "shuey-rhon-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shuey-rhon-inu.png",
  },
  {
    name: "MetaGameSpace",
    symbol: "METAGS",
    slug: "metagamespace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagamespace.png",
  },
  {
    name: "onPlanet",
    symbol: "OP",
    slug: "onplanet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onplanet.png",
  },
  {
    name: "Metaversero",
    symbol: "MVR",
    slug: "metaversero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaversero.png",
  },
  {
    name: "Minedrop",
    symbol: "MIND",
    slug: "minedrop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minedrop.png",
  },
  {
    name: "FitScrypt",
    symbol: "FIT",
    slug: "fitscrypt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fitscrypt.png",
  },
  {
    name: "Kongtama",
    symbol: "KONGTAMA",
    slug: "kongtama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kongtama.png",
  },
  {
    name: "Dpad Finance",
    symbol: "DPAD",
    slug: "dpad-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dpad-finance.png",
  },
  {
    name: "Liqwid Finance",
    symbol: "LQ",
    slug: "liqwid-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liqwid-finance.png",
  },
  {
    name: "Space Ore",
    symbol: "SPO",
    slug: "space-ore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-ore.png",
  },
  {
    name: "TraDAO",
    symbol: "TOD",
    slug: "tradao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tradao.png",
  },
  {
    name: "Metavault",
    symbol: "MVD",
    slug: "metavault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metavault.png",
  },
  {
    name: "Covid Doge",
    symbol: "COVID DOGE",
    slug: "covid-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/covid-doge.png",
  },
  {
    name: "StakeWise",
    symbol: "RETH2",
    slug: "stakewise-reth2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakewise-reth2.png",
  },
  {
    name: "BLASTER TOKEN",
    symbol: "BLT",
    slug: "blaster-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blaster-token.png",
  },
  {
    name: "friesDAO",
    symbol: "FRIES",
    slug: "friesdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/friesdao.png",
  },
  {
    name: "Mercury Tools",
    symbol: "MERCURY",
    slug: "mercury-tools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mercury-tools.png",
  },
  {
    name: "Dog Coin[New]",
    symbol: "DOG",
    slug: "dog-coin-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dog-coin-new.png",
  },
  {
    name: "BitCoke Token",
    symbol: "COKE",
    slug: "bitcoke-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoke-token.png",
  },
  {
    name: "METAROBOX",
    symbol: "RBX",
    slug: "metarobox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metarobox.png",
  },
  {
    name: "Cornatto",
    symbol: "CNC",
    slug: "cornatto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cornatto.png",
  },
  {
    name: "CyborgShiba",
    symbol: "CBS",
    slug: "cyborgshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyborgshiba.png",
  },
  {
    name: "MetaRoyale",
    symbol: "MRVR",
    slug: "metaroyale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaroyale.png",
  },
  {
    name: "Food Bank",
    symbol: "FOOD",
    slug: "food-bank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/food-bank.png",
  },
  {
    name: "Local Terra",
    symbol: "LOCAL",
    slug: "local-terra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/local-terra.png",
  },
  {
    name: "BaksDAO",
    symbol: "BAKS",
    slug: "baksdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baksdao.png",
  },
  {
    name: "Baby Vizsla Inu",
    symbol: "BABYVIZSLA",
    slug: "baby-vizsla-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-vizsla-inu.png",
  },
  {
    name: "Hirokage",
    symbol: "HIRO",
    slug: "hirokage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hirokage.png",
  },
  {
    name: "Hearts",
    symbol: "HEART",
    slug: "hearts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hearts.png",
  },
  {
    name: "Pandemia",
    symbol: "SUCCESS",
    slug: "pandemia-success",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pandemia-success.png",
  },
  {
    name: "PAZ",
    symbol: "PAZ",
    slug: "paz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paz-token.png",
  },
  {
    name: "Kevin",
    symbol: "KEVIN",
    slug: "kevin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kevin.png",
  },
  {
    name: "Lido Staked Matic",
    symbol: "stMATIC",
    slug: "lido-staked-matic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lido-staked-matic.png",
  },
  {
    name: "Cyn-C",
    symbol: "CYNC",
    slug: "cyn-c",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyn-c.png",
  },
  {
    name: "Apocalypse",
    symbol: "APOC",
    slug: "apocalypse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apocalypse.png",
  },
  {
    name: "The Xenobots Project",
    symbol: "XENO",
    slug: "the-xenobots-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-xenobots-project.png",
  },
  {
    name: "CHEEBS",
    symbol: "CHEE",
    slug: "cheebs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheebs.png",
  },
  {
    name: "Freemoon",
    symbol: "FREEMOON",
    slug: "freemoon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freemoon-token.png",
  },
  {
    name: "Pitquidity",
    symbol: "PITQD",
    slug: "pitquidity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pitquidity.png",
  },
  {
    name: "KuDoge",
    symbol: "KUDO",
    slug: "kudoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kudoge.png",
  },
  {
    name: "Naffiti",
    symbol: "NAFF",
    slug: "naffiti",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/naffiti.png",
  },
  {
    name: "ValleySwap",
    symbol: "VS",
    slug: "valleyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valleyswap.png",
  },
  {
    name: "CoolGas",
    symbol: "COOGA",
    slug: "coolgas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coolgas.png",
  },
  {
    name: "Oilz Finance",
    symbol: "OILZ",
    slug: "oilz-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oilz-finance.png",
  },
  {
    name: "Voltage Finance",
    symbol: "VOLT",
    slug: "voltage-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voltage-finance.png",
  },
  {
    name: "Bitazza",
    symbol: "BTZ",
    slug: "bitazza",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitazza.png",
  },
  {
    name: "MeganSwap",
    symbol: "MEGAN",
    slug: "meganswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meganswap.png",
  },
  {
    name: "Envision",
    symbol: "VIS",
    slug: "envision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/envision.png",
  },
  {
    name: "Miidas NFT",
    symbol: "MIIDAS",
    slug: "miidas-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miidas-nft.png",
  },
  {
    name: "axlUSDC",
    symbol: "axlUSDC",
    slug: "axlusdc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axlusdc.png",
  },
  {
    name: "Beskar",
    symbol: "BSK-BAA025",
    slug: "beskar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beskar.png",
  },
  {
    name: "Planet Infinity",
    symbol: "PLI",
    slug: "planet-infinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/planet-infinity.png",
  },
  {
    name: "Jumbo Exchange",
    symbol: "JUMBO",
    slug: "jumbo-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jumbo-exchange.png",
  },
  {
    name: "Drive Crypto",
    symbol: "DRIVECRYPTO",
    slug: "drivecrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drivecrypto.png",
  },
  {
    name: "Cardano Gold",
    symbol: "CARGO",
    slug: "cardano-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cardano-gold.png",
  },
  {
    name: "Croissant Games",
    symbol: "CROISSANT",
    slug: "croissant-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/croissant-games.png",
  },
  {
    name: "AnetaBTC",
    symbol: "cNETA",
    slug: "anetabtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anetabtc.png",
  },
  {
    name: "USHARE",
    symbol: "USHARE",
    slug: "ushare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ushare.png",
  },
  {
    name: "RED TOKEN",
    symbol: "RED",
    slug: "red-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/red-token.png",
  },
  {
    name: "Lavafall Finance",
    symbol: "LAVAFALL",
    slug: "lavafall-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lavafall-finance.png",
  },
  {
    name: "Wrapped CANTO",
    symbol: "WCANTO",
    slug: "wrapped-canto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-canto.png",
  },
  {
    name: "MonkeDAO",
    symbol: "DAOSOL",
    slug: "monkedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monkedao.png",
  },
  {
    name: "USDtez",
    symbol: "USDTZ",
    slug: "usdtez",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdtez.png",
  },
  {
    name: "Kolibri USD",
    symbol: "KUSD",
    slug: "kolibri-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kolibri-usd.png",
  },
  {
    name: "Canto Inu",
    symbol: "CINU",
    slug: "canto-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/canto-inu.png",
  },
  {
    name: "Note",
    symbol: "NOTE",
    slug: "note-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/note-io.png",
  },
  {
    name: "Aequinox",
    symbol: "AEQ",
    slug: "aequinox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aequinox.png",
  },
  {
    name: "Dogcoin",
    symbol: "DOGS",
    slug: "dogcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogcoin.png",
  },
  {
    name: "AETHR",
    symbol: "ATH",
    slug: "aethr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aethr.png",
  },
  {
    name: "Aada Finance",
    symbol: "AADA",
    slug: "aada-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aada-finance.png",
  },
  {
    name: "KAIDEX",
    symbol: "KDX",
    slug: "kaidex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaidex.png",
  },
  {
    name: "sphynx brc",
    symbol: "SPHYNX",
    slug: "sphynx-brc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sphynx-brc.png",
  },
  {
    name: "DNA Dollar",
    symbol: "DNA",
    slug: "dna-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dna-dollar.png",
  },
  {
    name: "GIF DAO",
    symbol: "$GIF",
    slug: "gif-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gif-dao.png",
  },
  {
    name: "HakuSwap",
    symbol: "HAKU",
    slug: "hakuswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hakuswap.png",
  },
  {
    name: "Diabolo",
    symbol: "DCASH",
    slug: "diabolo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diabolo.png",
  },
  {
    name: "VyFinance",
    symbol: "VYFI",
    slug: "vyfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vyfinance.png",
  },
  {
    name: "Babylonia",
    symbol: "BABY",
    slug: "babylonia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babylonia.png",
  },
  {
    name: "XFREE Coin",
    symbol: "XFREE",
    slug: "freedom-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freedom-coin.png",
  },
  {
    name: "Empowa",
    symbol: "EMP",
    slug: "empowa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/empowa.png",
  },
  {
    name: "Rubidium",
    symbol: "RBD",
    slug: "rubidium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rubidium.png",
  },
  {
    name: "TIA",
    symbol: "TIA",
    slug: "tia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tia.png",
  },
  {
    name: "Candle",
    symbol: "CNDL",
    slug: "candle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/candle.png",
  },
  {
    name: "VeleroDAO",
    symbol: "VDGT",
    slug: "velerodao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/velerodao.png",
  },
  {
    name: "coreDAO",
    symbol: "COREDAO",
    slug: "coredao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coredao.png",
  },
  {
    name: "MekaMiners",
    symbol: "MEKA",
    slug: "mekaminers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mekaminers.png",
  },
  {
    name: "CroBank",
    symbol: "BANK",
    slug: "crobank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crobank.png",
  },
  {
    name: "Digitalatto",
    symbol: "DGTL",
    slug: "digitalatto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digitalatto.png",
  },
  {
    name: "Pavia",
    symbol: "PAVIA",
    slug: "pavia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pavia.png",
  },
  {
    name: "MetaCelo",
    symbol: "CMETA",
    slug: "metacelo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metacelo.png",
  },
  {
    name: "Bogdanoff Forever",
    symbol: "BOGDANOFF",
    slug: "bogdanoff-forever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bogdanoff-forever.png",
  },
  {
    name: "Inverse MATIC Flexible Leverage Index",
    symbol: "IMATIC-FLI-P",
    slug: "inverse-matic-flexible-leverage-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inverse-matic-flexible-leverage-index.png",
  },
  {
    name: "Mozza",
    symbol: "MOZZA",
    slug: "mozzatoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mozzatoken.png",
  },
  {
    name: "FEG Wrapped BNB",
    symbol: "fBNB",
    slug: "feg-wrapped-bnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feg-wrapped-bnb.png",
  },
  {
    name: "DogeTown",
    symbol: "DTN",
    slug: "dogetown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogetown.png",
  },
  {
    name: "GreenWhaleS",
    symbol: "GWS",
    slug: "greenwhales",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenwhales.png",
  },
  {
    name: "RetroNeko",
    symbol: "RNK",
    slug: "retroneko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/retroneko.png",
  },
  {
    name: "Bear Billionaire",
    symbol: "BAIR",
    slug: "bear-billionaire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bear-billionaire.png",
  },
  {
    name: "Planet",
    symbol: "GAMMA",
    slug: "planet-gamma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/planet-gamma.png",
  },
  {
    name: "METABEE",
    symbol: "MBEE",
    slug: "metabee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabee.png",
  },
  {
    name: "Elongrab (NEW)",
    symbol: "ELONGRAB",
    slug: "elongrab-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elongrab-new.png",
  },
  {
    name: "Magic Inu",
    symbol: "MAGIC",
    slug: "magic-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-inu.png",
  },
  {
    name: "Holdenomics",
    symbol: "ʜᴏʟᴅᴇɴᴏᴍɪᴄs™",
    slug: "holdenomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holdenomics.png",
  },
  {
    name: "DOGE NINJA SAMURAI",
    symbol: "DINJA",
    slug: "doge-ninja-samurai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-ninja-samurai.png",
  },
  {
    name: "Demonic Doge",
    symbol: "DMD",
    slug: "demonic-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/demonic-doge.png",
  },
  {
    name: "Carbon",
    symbol: "CO2",
    slug: "carbon-co2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbon-co2.png",
  },
  {
    name: "Sweet BTC",
    symbol: "SBTC",
    slug: "sweet-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sweet-btc.png",
  },
  {
    name: "EverPrinter",
    symbol: "$EPRINT",
    slug: "everprinter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everprinter.png",
  },
  {
    name: "Pledge Finance",
    symbol: "MPLGR",
    slug: "master-pledger-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/master-pledger-token.png",
  },
  {
    name: "Bored Battle Apes",
    symbol: "BAPE",
    slug: "bored-battle-apes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bored-battle-apes.png",
  },
  {
    name: "Worthpad",
    symbol: "WORTH",
    slug: "worthpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/worthpad.png",
  },
  {
    name: "Blocksmith Labs Forge",
    symbol: "$FORGE",
    slug: "blocksmith-labs-forge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocksmith-labs-forge.png",
  },
  {
    name: "Liquid Swap",
    symbol: "LQD",
    slug: "liquidswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquidswap.png",
  },
  {
    name: "Wealthy Habits",
    symbol: "WHB",
    slug: "wealthy-habits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wealthy-habits.png",
  },
  {
    name: "Clexchain",
    symbol: "CLEX",
    slug: "clexchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clexchain.png",
  },
  {
    name: "Cricket",
    symbol: "CRICKET",
    slug: "cricket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cricket.png",
  },
  {
    name: "Free Novak",
    symbol: "FREEDOM",
    slug: "free-novak",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/free-novak.png",
  },
  {
    name: "Influencer Token",
    symbol: "INF",
    slug: "influencer-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/influencer-token.png",
  },
  {
    name: "Hachiko Charity",
    symbol: "HKC",
    slug: "hachiko-charity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hachiko-charity.png",
  },
  {
    name: "Rush Technology",
    symbol: "RUSH",
    slug: "rush-technology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rush-technology.png",
  },
  {
    name: "XAEA-Xii Token",
    symbol: "XAEA-Xii",
    slug: "xaea-xii-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xaea-xii-token.png",
  },
  {
    name: "Floki Run",
    symbol: "FLOKIRUN",
    slug: "floki-run",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-run.png",
  },
  {
    name: "EarnableFi",
    symbol: "EFI",
    slug: "earnablefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnablefi.png",
  },
  {
    name: "ShinjiRai",
    symbol: "$SHINJI",
    slug: "shinjirai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinjirai.png",
  },
  {
    name: "SoundBox",
    symbol: "SOUND",
    slug: "soundbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soundbox.png",
  },
  {
    name: "King Dog Inu",
    symbol: "KINGDOG",
    slug: "king-dog-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-dog-inu.png",
  },
  {
    name: "FOMO",
    symbol: "FOMO",
    slug: "fomo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fomo.png",
  },
  {
    name: "Okami Inu",
    symbol: "Okami",
    slug: "okami-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okami-inu.png",
  },
  {
    name: "Doge2K22",
    symbol: "DK",
    slug: "doge2k22",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge2k22.png",
  },
  {
    name: "DeFi Holdings",
    symbol: "DHOLD",
    slug: "defi-holdings",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-holdings.png",
  },
  {
    name: "NoahArk",
    symbol: "NRK",
    slug: "noahark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noahark.png",
  },
  {
    name: "BlueChip Capital Token",
    symbol: "BCC",
    slug: "bluechip-capital-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bluechip-capital-token.png",
  },
  {
    name: "PhotonSwap",
    symbol: "PHOTON",
    slug: "photonswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/photonswap.png",
  },
  {
    name: "Comfy Share",
    symbol: "CSHARE",
    slug: "comfy-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comfy-share.png",
  },
  {
    name: "Comfy",
    symbol: "COMFY",
    slug: "comfy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comfy.png",
  },
  {
    name: "GENART",
    symbol: "GENART",
    slug: "genart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genart.png",
  },
  {
    name: "Wordl DeFi",
    symbol: "WORDL",
    slug: "wordl-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wordl-defi.png",
  },
  {
    name: "Hakuna Shares",
    symbol: "MTT",
    slug: "hakuna-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hakuna-shares.png",
  },
  {
    name: "Gladian",
    symbol: "GLD",
    slug: "gladian",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gladian.png",
  },
  {
    name: "Lepricon",
    symbol: "LPR",
    slug: "lepriconlpr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lepriconlpr.png",
  },
  {
    name: "GTraX",
    symbol: "GTRX",
    slug: "gtrax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gtrax.png",
  },
  {
    name: "semicon1",
    symbol: "SMC1",
    slug: "semicon1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/semicon1.png",
  },
  {
    name: "eCredits",
    symbol: "ECS",
    slug: "ecredits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecredits.png",
  },
  {
    name: "ONTPAY",
    symbol: "ONTP",
    slug: "ontpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ontpay.png",
  },
  {
    name: "WhiteSwap",
    symbol: "WSD",
    slug: "whiteswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whiteswap.png",
  },
  {
    name: "ZARP Stablecoin",
    symbol: "ZARP",
    slug: "zarp-stablecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zarp-stablecoin.png",
  },
  {
    name: "Shanum",
    symbol: "SHAN",
    slug: "shanum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shanum.png",
  },
  {
    name: "BofB",
    symbol: "BOFB",
    slug: "bofb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bofb.png",
  },
  {
    name: "Maia",
    symbol: "MAIA",
    slug: "maia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maia.png",
  },
  {
    name: "Stabl.fi",
    symbol: "CASH",
    slug: "stabl-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stabl-fi.png",
  },
  {
    name: "Coin98 Dollar",
    symbol: "CUSD",
    slug: "coin98-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin98-dollar.png",
  },
  {
    name: "Kaddex",
    symbol: "KDX",
    slug: "kaddex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaddex.png",
  },
  {
    name: "Xmas Past",
    symbol: "XPAST",
    slug: "xmas-past",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xmas-past.png",
  },
  {
    name: "My Farm",
    symbol: "MFT",
    slug: "my-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-farm.png",
  },
  {
    name: "DEADPXLZ",
    symbol: "DING",
    slug: "deadpxlz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deadpxlz.png",
  },
  {
    name: "DogeApe",
    symbol: "DOGEAPE",
    slug: "dogeape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeape.png",
  },
  {
    name: "CoinField Coin",
    symbol: "CFC",
    slug: "coinfield-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinfield-coin.png",
  },
  {
    name: "Upsorber",
    symbol: "UP",
    slug: "upsorber",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upsorber.png",
  },
  {
    name: "TokenJenny",
    symbol: "tokenjenny",
    slug: "jenn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jenn.png",
  },
  {
    name: "AMETA",
    symbol: "APLUS",
    slug: "ameta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ameta.png",
  },
  {
    name: "Retawars GoldRose Token",
    symbol: "GRT",
    slug: "retawars-goldrose-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/retawars-goldrose-token.png",
  },
  {
    name: "ALEX Lab",
    symbol: "ALEX",
    slug: "alex-lab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alex-lab.png",
  },
  {
    name: "CryptoFootball",
    symbol: "BALLZ",
    slug: "cryptofootball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptofootball.png",
  },
  {
    name: "Cardashift",
    symbol: "CLAP",
    slug: "cardashift",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cardashift.png",
  },
  {
    name: "USD Balance",
    symbol: "USDB",
    slug: "usd-balance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usd-balance.png",
  },
  {
    name: "Paladin",
    symbol: "PAL",
    slug: "paladin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paladin.png",
  },
  {
    name: "Scalara NFT Index",
    symbol: "NFTI",
    slug: "scalara-nft-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scalara-nft-index.png",
  },
  {
    name: "Swapsicle",
    symbol: "POPS",
    slug: "swapsicle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapsicle.png",
  },
  {
    name: "K4 Rally",
    symbol: "K4R",
    slug: "k4-rally",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/k4-rally.png",
  },
  {
    name: "FarmBit",
    symbol: "FMB",
    slug: "farmbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmbit.png",
  },
  {
    name: "Wrapped VSolidus",
    symbol: "WVSOL",
    slug: "wrapped-vsolidus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-vsolidus.png",
  },
  {
    name: "APENOUT",
    symbol: "APENOUT",
    slug: "apenout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apenout.png",
  },
  {
    name: "FIAT DAO",
    symbol: "FDT",
    slug: "fiat-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fiat-dao.png",
  },
  {
    name: "Enegra (EGX)",
    symbol: "EGX",
    slug: "enegra-egx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enegra-egx.png",
  },
  {
    name: "Babacoin",
    symbol: "BBC",
    slug: "babacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babacoin.png",
  },
  {
    name: "NUTGAIN",
    symbol: "NUTGV2",
    slug: "nutgain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nutgain.png",
  },
  {
    name: "CoinFarm",
    symbol: "CFARM",
    slug: "coinfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinfarm.png",
  },
  {
    name: "TrinitySwap",
    symbol: "TRINITY",
    slug: "trinityswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trinityswap.png",
  },
  {
    name: "King of Legends",
    symbol: "KOL",
    slug: "king-of-legends-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-of-legends-2.png",
  },
  {
    name: "n00dle",
    symbol: "N00D",
    slug: "n00dle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/n00dle.png",
  },
  {
    name: "Wrapped Stacks",
    symbol: "WSTX",
    slug: "wrapped-stacks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-stacks.png",
  },
  {
    name: "BuckyBadger",
    symbol: "BUCKY",
    slug: "buckybadger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buckybadger.png",
  },
  {
    name: "Viva Classic (new)",
    symbol: "VIVA",
    slug: "viva-classic-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viva-classic-new.png",
  },
  {
    name: "Solaris Betting Token",
    symbol: "SBT",
    slug: "solaris-betting-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solaris-betting-token.png",
  },
  {
    name: "Coil",
    symbol: "COIL",
    slug: "coil-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coil-io.png",
  },
  {
    name: "GoldPesa Option",
    symbol: "GPO",
    slug: "goldpesa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldpesa.png",
  },
  {
    name: "Frax Price Index",
    symbol: "FPI",
    slug: "frax-price-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frax-price-index.png",
  },
  {
    name: "Adonis",
    symbol: "ADON",
    slug: "adonis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adonis.png",
  },
  {
    name: "Protofi",
    symbol: "PROTO",
    slug: "protofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/protofi.png",
  },
  {
    name: "Chain Lords",
    symbol: "GLORY",
    slug: "chain-lords",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain-lords.png",
  },
  {
    name: "DeSpace",
    symbol: "DSE",
    slug: "despace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/despace.png",
  },
  {
    name: "PLUTUS",
    symbol: "PLTS",
    slug: "plutus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plutus.png",
  },
  {
    name: "Lotus Capital",
    symbol: "LC",
    slug: "lotus-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lotus-capital.png",
  },
  {
    name: "Hot Potato",
    symbol: "STK",
    slug: "hot-potato-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hot-potato-market.png",
  },
  {
    name: "Stax Protocol",
    symbol: "STAX",
    slug: "stax-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stax-protocol.png",
  },
  {
    name: "Microtick",
    symbol: "TICK",
    slug: "microtick",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microtick.png",
  },
  {
    name: "Yugen Finance",
    symbol: "YGN",
    slug: "yugen-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yugen-finance.png",
  },
  {
    name: "Batmoon",
    symbol: "BATMOON",
    slug: "batmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/batmoon.png",
  },
  {
    name: "Cronofi Finance",
    symbol: "CRONO",
    slug: "cronofi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cronofi-finance.png",
  },
  {
    name: "GameCrypt",
    symbol: "GAMECRYPT",
    slug: "gamecrypt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamecrypt.png",
  },
  {
    name: "Croblanc",
    symbol: "CROBLANC",
    slug: "croblanc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/croblanc.png",
  },
  {
    name: "6DayHoneypot",
    symbol: "HONEY",
    slug: "6dayhoneypot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/6dayhoneypot.png",
  },
  {
    name: "1Swap",
    symbol: "1SWAP",
    slug: "1swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1swap.png",
  },
  {
    name: "ONEMOON",
    symbol: "ONEMOON",
    slug: "onemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onemoon.png",
  },
  {
    name: "Swivel Finance",
    symbol: "SWIV",
    slug: "swivel-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swivel-finance.png",
  },
  {
    name: "VeneraSwap",
    symbol: "VSW",
    slug: "veneraswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veneraswap.png",
  },
  {
    name: "Nukplan",
    symbol: "NKPL",
    slug: "nukplan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nukplan.png",
  },
  {
    name: "TourismX Token",
    symbol: "TRMX",
    slug: "tourismx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tourismx-token.png",
  },
  {
    name: "Chains of War",
    symbol: "MIRA",
    slug: "chains-of-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chains-of-war.png",
  },
  {
    name: "JPEX",
    symbol: "JPC",
    slug: "jpex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jpex.png",
  },
  {
    name: "CROCASH",
    symbol: "CROCASH",
    slug: "crocash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crocash.png",
  },
  {
    name: "EDAC",
    symbol: "EDAC",
    slug: "edac",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edac.png",
  },
  {
    name: "WETC",
    symbol: "WETC",
    slug: "wetc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wetc.png",
  },
  {
    name: "DeFiWall",
    symbol: "FIWA",
    slug: "defiwall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defiwall.png",
  },
  {
    name: "Ray Network",
    symbol: "XRAY",
    slug: "ray-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ray-network.png",
  },
  {
    name: "EduBits",
    symbol: "EDX",
    slug: "edubits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edubits.png",
  },
  {
    name: "KeplerSwap",
    symbol: "SDS",
    slug: "keplerswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keplerswap.png",
  },
  {
    name: "Lifeline Protocol",
    symbol: "FOUR (4)",
    slug: "lifeline-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lifeline-protocol.png",
  },
  {
    name: "Mocossi Planet",
    symbol: "MCOS",
    slug: "mocossi-planet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mocossi-planet.png",
  },
  {
    name: "BTC Proxy",
    symbol: "BTCPX",
    slug: "btc-proxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/btc-proxy.png",
  },
  {
    name: "CroNodes",
    symbol: "CRN",
    slug: "cronodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cronodes.png",
  },
  {
    name: "AkoyaLegends",
    symbol: "AKL",
    slug: "akoyalegends",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akoyalegends.png",
  },
  {
    name: "Degen Protocol Token",
    symbol: "SH33P",
    slug: "degen-protocol-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degen-protocol-token.png",
  },
  {
    name: "DarkCrypto",
    symbol: "DARK",
    slug: "darkcrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darkcrypto.png",
  },
  {
    name: "Pope Inu",
    symbol: "POPE",
    slug: "pope-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pope-inu.png",
  },
  {
    name: "Lazy Horse Race Club",
    symbol: "LHRC",
    slug: "lazy-horse-race-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lazy-horse-race-club.png",
  },
  {
    name: "Ghost Inu",
    symbol: "GHOST",
    slug: "ghost-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghost-inu.png",
  },
  {
    name: "ShibaBCH",
    symbol: "SHIBBCH",
    slug: "shiba-bch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-bch.png",
  },
  {
    name: "Devikins",
    symbol: "DVK",
    slug: "devikins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/devikins.png",
  },
  {
    name: "IDOL",
    symbol: "$IDOL",
    slug: "idol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idol.png",
  },
  {
    name: "Soldait",
    symbol: "SIT",
    slug: "soldait",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soldait.png",
  },
  {
    name: "Axie BCH - Gaming Guild",
    symbol: "AXIEBCH",
    slug: "axie-bch---gaming-guild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axie-bch---gaming-guild.png",
  },
  {
    name: "SolanaLite",
    symbol: "SLITE",
    slug: "solanalite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solanalite.png",
  },
  {
    name: "Huckleberry",
    symbol: "FINN",
    slug: "huckleberry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/huckleberry.png",
  },
  {
    name: "OrangeSwap",
    symbol: "ORNG",
    slug: "orangeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orangeswap.png",
  },
  {
    name: "Waka Finance",
    symbol: "WAKA",
    slug: "waka-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waka-finance.png",
  },
  {
    name: "QuipuSwap Governance Token",
    symbol: "QUIPU",
    slug: "quipuswap-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quipuswap-governance-token.png",
  },
  {
    name: "QCHAIN",
    symbol: "QDT",
    slug: "qchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qchain.png",
  },
  {
    name: "BMBCoin",
    symbol: "BMB",
    slug: "bmbcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bmbcoin.png",
  },
  {
    name: "ArbiRiseFinance",
    symbol: "ARF",
    slug: "arbirisefinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbirisefinance.png",
  },
  {
    name: "MONKI NETWORK",
    symbol: "MONKI",
    slug: "monki-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monki-network.png",
  },
  {
    name: "Atmosphere CCG",
    symbol: "ATMSSFT",
    slug: "atmosphere-ccg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atmosphere-ccg.png",
  },
  {
    name: "Orange",
    symbol: "ORG",
    slug: "orange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orange.png",
  },
  {
    name: "Blumel",
    symbol: "BLUEMEL",
    slug: "blumel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blumel.png",
  },
  {
    name: "Fozeus Coin",
    symbol: "FZS",
    slug: "fozeus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fozeus.png",
  },
  {
    name: "BYCOIN",
    symbol: "BYCO",
    slug: "bycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bycoin.png",
  },
  {
    name: "Crypto Family Token",
    symbol: "FT",
    slug: "crypto-family-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-family-token.png",
  },
  {
    name: "Blocktanium",
    symbol: "BKT",
    slug: "blocktanium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocktanium.png",
  },
  {
    name: "Crunchy DAO",
    symbol: "crDAO",
    slug: "crunchy-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crunchy-dao.png",
  },
  {
    name: "Metabot",
    symbol: "MBT",
    slug: "metabot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabot.png",
  },
  {
    name: "XChain Wallet",
    symbol: "XWALL",
    slug: "xchain-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xchain-wallet.png",
  },
  {
    name: "EGG Protocol",
    symbol: "EGG",
    slug: "egg-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egg-protocol.png",
  },
  {
    name: "Better",
    symbol: "BET",
    slug: "better",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/better.png",
  },
  {
    name: "MarsColony",
    symbol: "CLNY",
    slug: "marscolony",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marscolony.png",
  },
  {
    name: "Artemis Protocol",
    symbol: "MIS",
    slug: "artemis-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artemis-protocol.png",
  },
  {
    name: "mcEUR",
    symbol: "MCEUR",
    slug: "mceur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mceur.png",
  },
  {
    name: "mCELO",
    symbol: "MCELO",
    slug: "mcelo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcelo.png",
  },
  {
    name: "SMOKE Token",
    symbol: "SMOKE",
    slug: "smoke-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smoke-token.png",
  },
  {
    name: "HyperJump",
    symbol: "JUMP",
    slug: "hyperjump",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperjump.png",
  },
  {
    name: "Neighbourhoods",
    symbol: "NHT",
    slug: "neighbourhoods",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neighbourhoods.png",
  },
  {
    name: "Mooner",
    symbol: "MNR",
    slug: "mooner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mooner.png",
  },
  {
    name: "GarudaSwap",
    symbol: "GARUDA",
    slug: "garudaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/garudaswap.png",
  },
  {
    name: "Anamnesis",
    symbol: "ANA",
    slug: "anamnesis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anamnesis.png",
  },
  {
    name: "SMARTWORTH",
    symbol: "SMARTWORTH",
    slug: "smartworth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartworth.png",
  },
  {
    name: "Wrapped Elrond",
    symbol: "WEGLD",
    slug: "wrapped-elrond-egld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-elrond-egld.png",
  },
  {
    name: "Cross-Chain BCH",
    symbol: "CCBCH",
    slug: "cross-chain-bch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cross-chain-bch.png",
  },
  {
    name: "GrafenoCoin",
    symbol: "GFNC",
    slug: "grafeno",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grafeno.png",
  },
  {
    name: "Bertinity",
    symbol: "BRTX",
    slug: "bertinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bertinity.png",
  },
  {
    name: "Winner Coin",
    symbol: "WINB",
    slug: "winner-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winner-coin.png",
  },
  {
    name: "Grapeswap",
    symbol: "GRAPE",
    slug: "grapeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grapeswap.png",
  },
  {
    name: "Guarda token",
    symbol: "GRD",
    slug: "guarda-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guarda-token.png",
  },
  {
    name: "Maiar",
    symbol: "MEX",
    slug: "maiar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maiar.png",
  },
  {
    name: "Dragon Egg",
    symbol: "DREGG",
    slug: "dragon-egg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-egg.png",
  },
  {
    name: "Neonic Finance",
    symbol: "NEON",
    slug: "neonic-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neonic-finance.png",
  },
  {
    name: "SAVENFT",
    symbol: "SAVENFT",
    slug: "savenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/savenft.png",
  },
  {
    name: "ThorusStake",
    symbol: "STHO",
    slug: "thorusstake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thorusstake.png",
  },
  {
    name: "Mocha Token",
    symbol: "MOCHA",
    slug: "cafeswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cafeswap-finance.png",
  },
  {
    name: "Action Coin",
    symbol: "ACTN",
    slug: "action-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/action-coin.png",
  },
  {
    name: "Golfrochain",
    symbol: "GOLF",
    slug: "golfrochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golfrochain.png",
  },
  {
    name: "SaveAnimal",
    symbol: "SAVEANIMAL",
    slug: "saveanimal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saveanimal.png",
  },
  {
    name: "Material",
    symbol: "MTRL",
    slug: "material",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/material.png",
  },
  {
    name: "holoride",
    symbol: "RIDE",
    slug: "holoride",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holoride.png",
  },
  {
    name: "Meowshi",
    symbol: "MEOW",
    slug: "meowshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meowshi.png",
  },
  {
    name: "Zum Dark",
    symbol: "ZUMD",
    slug: "zum-dark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zum-dark.png",
  },
  {
    name: "Elastic Bitcoin",
    symbol: "XBT",
    slug: "elastic-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elastic-bitcoin.png",
  },
  {
    name: "Dollar on Chain",
    symbol: "DOC",
    slug: "dollar-on-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dollar-on-chain.png",
  },
  {
    name: "CremePieSwap",
    symbol: "CPIE",
    slug: "cremepieswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cremepieswap.png",
  },
  {
    name: "BitGuru Finance",
    symbol: "GURU",
    slug: "bitguru-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitguru-finance.png",
  },
  {
    name: "SoupSwap",
    symbol: "SPW",
    slug: "soupswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soupswap.png",
  },
  {
    name: "TORO",
    symbol: "TORO",
    slug: "toro-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toro-bsc.png",
  },
  {
    name: "Shiba Samurai",
    symbol: "SHIBURAI",
    slug: "shiba-samurai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-samurai.png",
  },
  {
    name: "RushMars",
    symbol: "RMARS",
    slug: "rushmars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rushmars.png",
  },
  {
    name: "Pros.Finance",
    symbol: "PROS",
    slug: "pros-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pros-finance.png",
  },
  {
    name: "GoodDollar",
    symbol: "G$",
    slug: "gooddollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gooddollar.png",
  },
  {
    name: "Save Animals",
    symbol: "SEAS",
    slug: "save-animals",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/save-animals.png",
  },
  {
    name: "Dstarlights Coin",
    symbol: "DSC",
    slug: "dstarlights-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dstarlights-coin.png",
  },
  {
    name: "The Blocknet",
    symbol: "aBLOCK",
    slug: "the-blocknet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-blocknet.png",
  },
  {
    name: "Baby Shibnobi",
    symbol: "BABYSHINJA",
    slug: "baby-shibnobi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-shibnobi.png",
  },
  {
    name: "INKzToken",
    symbol: "INKZ",
    slug: "inkztoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inkztoken.png",
  },
  {
    name: "Phoenix",
    symbol: "PHOENIX",
    slug: "phoenix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenix.png",
  },
  {
    name: "Tranquil Staked ONE",
    symbol: "STONE",
    slug: "tranquil-staked-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tranquil-staked-one.png",
  },
  {
    name: "CryptoCat",
    symbol: "CCAT",
    slug: "cryptocat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptocat.png",
  },
  {
    name: "impactMarket",
    symbol: "PACT",
    slug: "impactmarket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/impactmarket.png",
  },
  {
    name: "AgriChain",
    symbol: "AGRI",
    slug: "agrichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agrichain.png",
  },
  {
    name: "TempleDAO",
    symbol: "TEMPLE",
    slug: "templedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/templedao.png",
  },
  {
    name: "CeloLaunch",
    symbol: "cLA",
    slug: "celolaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celolaunch.png",
  },
  {
    name: "WAGMI on Solana",
    symbol: "WAGMI",
    slug: "wagmi-on-solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wagmi-on-solana.png",
  },
  {
    name: "SuperNova cash",
    symbol: "sCASH",
    slug: "supernova-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supernova-cash.png",
  },
  {
    name: "Moola Market",
    symbol: "MOO",
    slug: "moola-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moola-market.png",
  },
  {
    name: "Space Finance",
    symbol: "SPACE",
    slug: "space-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-finance.png",
  },
  {
    name: "Universal Gold",
    symbol: "UPXAU",
    slug: "universal-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universal-gold.png",
  },
  {
    name: "Harrison First",
    symbol: "FIRST",
    slug: "harrison-first",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harrison-first.png",
  },
  {
    name: "COMSA [ETH]",
    symbol: "CMS",
    slug: "comsa-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comsa-eth.png",
  },
  {
    name: "Kek.finance",
    symbol: "KEK",
    slug: "kek-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kek-finance.png",
  },
  {
    name: "Ari Swap",
    symbol: "ARI",
    slug: "ari-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ari-swap.png",
  },
  {
    name: "Zent Cash",
    symbol: "ZTC",
    slug: "zent-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zent-cash.png",
  },
  {
    name: "PegasusDAO",
    symbol: "SUS",
    slug: "pegasusdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pegasusdao.png",
  },
  {
    name: "Family",
    symbol: "FAM",
    slug: "family",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/family.png",
  },
  {
    name: "Spantale",
    symbol: "AEL",
    slug: "spantale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spantale.png",
  },
  {
    name: "WagmiDAO",
    symbol: "GMI",
    slug: "wagmidao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wagmidao.png",
  },
  {
    name: "Kuber Finance",
    symbol: "KUBER",
    slug: "kuber-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuber-finance.png",
  },
  {
    name: "HLTH Token",
    symbol: "HLTH",
    slug: "hlth-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hlth-network.png",
  },
  {
    name: "Shiba Bank",
    symbol: "SHIBABANK",
    slug: "shiba-bank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-bank.png",
  },
  {
    name: "0xzx Token",
    symbol: "0XZX",
    slug: "0xzx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0xzx-token.png",
  },
  {
    name: "Bitcoin Pro",
    symbol: "BTCP",
    slug: "bitcoin-pro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-pro.png",
  },
  {
    name: "FortuneDAO",
    symbol: "FORT",
    slug: "fortunedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fortunedao.png",
  },
  {
    name: "KING SHIBA INU",
    symbol: "KSHIBINU",
    slug: "king-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-shiba-inu.png",
  },
  {
    name: "XBank",
    symbol: "XBA",
    slug: "xbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xbank.png",
  },
  {
    name: "OolongSwap",
    symbol: "OLO",
    slug: "oolongswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oolongswap.png",
  },
  {
    name: "StormSwap Finance",
    symbol: "WIND",
    slug: "stormswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stormswap-finance.png",
  },
  {
    name: "DONASWAP",
    symbol: "DONA",
    slug: "donaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/donaswap.png",
  },
  {
    name: "TrueAUD",
    symbol: "TAUD",
    slug: "trueaud",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trueaud.png",
  },
  {
    name: "Layer2DAO",
    symbol: "L2DAO",
    slug: "layer2dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/layer2dao.png",
  },
  {
    name: "CronaSwap",
    symbol: "CRONA",
    slug: "cronaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cronaswap.png",
  },
  {
    name: "STREAMER",
    symbol: "STREAMER",
    slug: "streamer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/streamer.png",
  },
  {
    name: "rDAI",
    symbol: "RDAI",
    slug: "rdai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rdai.png",
  },
  {
    name: "Pundi X PURSE",
    symbol: "PURSE",
    slug: "purse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/purse.png",
  },
  {
    name: "Axion Hex2T",
    symbol: "HEX2T",
    slug: "hex2t",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hex2t.png",
  },
  {
    name: "HUMAN",
    symbol: "HUMAN",
    slug: "human-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/human-coin.png",
  },
  {
    name: "NOMY",
    symbol: "NOMY",
    slug: "nomy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nomy.png",
  },
  {
    name: "Ivory",
    symbol: "IVORY",
    slug: "ivory",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ivory.png",
  },
  {
    name: "Gorgeous",
    symbol: "GORGEOUS",
    slug: "gorgeous",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gorgeous.png",
  },
  {
    name: "upShib",
    symbol: "UPSHIB",
    slug: "upshib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upshib.png",
  },
  {
    name: "PolyDEX",
    symbol: "PLX",
    slug: "polydex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polydex.png",
  },
  {
    name: "Greeneum Network",
    symbol: "GREEN",
    slug: "greeneum-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greeneum-network.png",
  },
  {
    name: "PolyYork",
    symbol: "YORK",
    slug: "polyyork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyyork.png",
  },
  {
    name: "Flokiverse",
    symbol: "FLOKIV",
    slug: "flokiverse-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokiverse-token.png",
  },
  {
    name: "Vulcan Forged LAVA",
    symbol: "LAVA",
    slug: "vulcan-forged-lava",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vulcan-forged-lava.png",
  },
  {
    name: "Archimedes",
    symbol: "ACMD",
    slug: "archimedes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/archimedes.png",
  },
  {
    name: "Parking Infinity",
    symbol: "PARK",
    slug: "parking-infinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parking-infinity.png",
  },
  {
    name: "Islandswap",
    symbol: "ISL",
    slug: "islandswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/islandswap.png",
  },
  {
    name: "Oxy-Fi",
    symbol: "OXY",
    slug: "oxy-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oxy-fi.png",
  },
  {
    name: "pBREW Token",
    symbol: "pBREW",
    slug: "pbrew-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pbrew-token.png",
  },
  {
    name: "THE BIG FIVE",
    symbol: "TBF",
    slug: "the-big-five",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-big-five.png",
  },
  {
    name: "MySwap",
    symbol: "MST",
    slug: "myswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myswap.png",
  },
  {
    name: "Jurassic Nodes",
    symbol: "DINO",
    slug: "jurassic-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jurassic-nodes.png",
  },
  {
    name: "ZorgApp",
    symbol: "ZORG",
    slug: "zorgapp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zorgapp.png",
  },
  {
    name: "VINCI",
    symbol: "VINCI",
    slug: "byvinci",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/byvinci.png",
  },
  {
    name: "Spear Finance",
    symbol: "SPEAR",
    slug: "spear-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spear-finance.png",
  },
  {
    name: "Carb0n.fi",
    symbol: "ZRO",
    slug: "carb0n-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carb0n-fi.png",
  },
  {
    name: "Smartlink",
    symbol: "SMAK",
    slug: "smartlink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartlink.png",
  },
  {
    name: "Trillion",
    symbol: "TRL",
    slug: "trillion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trillion.png",
  },
  {
    name: "PumpkinDAO",
    symbol: "PUMP",
    slug: "pumpkindao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pumpkindao.png",
  },
  {
    name: "Avax Nodes",
    symbol: "ANODE",
    slug: "avax-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avax-nodes.png",
  },
  {
    name: "Rebase Aggregator Capital",
    symbol: "$REACT",
    slug: "rebase-aggregator-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rebase-aggregator-capital.png",
  },
  {
    name: "OTFINANCIAL",
    symbol: "OT",
    slug: "otfinancial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otfinancial.png",
  },
  {
    name: "ParadiseFi",
    symbol: "EDEN",
    slug: "paradisefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paradisefi.png",
  },
  {
    name: "WigoSwap",
    symbol: "WIGO",
    slug: "wigoswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wigoswap.png",
  },
  {
    name: "Cipher",
    symbol: "CPR",
    slug: "cipher-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cipher-v2.png",
  },
  {
    name: "Honey",
    symbol: "$HONEY",
    slug: "fancybearsmetaverse-honey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fancybearsmetaverse-honey.png",
  },
  {
    name: "Grand Time",
    symbol: "GRAND",
    slug: "grand-time",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grand-time.png",
  },
  {
    name: "UpDeFi",
    symbol: "UP",
    slug: "updefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/updefi.png",
  },
  {
    name: "Sleep",
    symbol: "SLEEP",
    slug: "sleep-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sleep-game.png",
  },
  {
    name: "Shade Cash",
    symbol: "SHADE",
    slug: "shade-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shade-cash.png",
  },
  {
    name: "Pet Store",
    symbol: "PSBD",
    slug: "pet-store",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pet-store.png",
  },
  {
    name: "YellowHeart Protocol",
    symbol: "HRTS",
    slug: "yellowheart-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yellowheart-protocol.png",
  },
  {
    name: "Freedom Gold",
    symbol: "FRG",
    slug: "freedom-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freedom-gold.png",
  },
  {
    name: "JustCarbon Removal",
    symbol: "JCR",
    slug: "justcarbon-removal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/justcarbon-removal.png",
  },
  {
    name: "SHIBAFRIEND",
    symbol: "SHF",
    slug: "shibafriend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibafriend.png",
  },
  {
    name: "10SHARE",
    symbol: "10SHARE",
    slug: "10share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/10share.png",
  },
  {
    name: "Ankr Reward Earning Staked AVAX",
    symbol: "aAVAXb",
    slug: "ankr-avalanche-reward-earning-bond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ankr-avalanche-reward-earning-bond.png",
  },
  {
    name: "Tiger Coin",
    symbol: "TIGER",
    slug: "tiger-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiger-coin.png",
  },
  {
    name: "Verasaw Plant Token",
    symbol: "VRS",
    slug: "verasaw-plant-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verasaw-plant-token.png",
  },
  {
    name: "KibbleSwap",
    symbol: "KIB",
    slug: "kibbleswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kibbleswap.png",
  },
  {
    name: "Global Smart Asset",
    symbol: "GSA",
    slug: "global-smart-asset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-smart-asset.png",
  },
  {
    name: "Nice",
    symbol: "NICE",
    slug: "nice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nice.png",
  },
  {
    name: "Paw V2",
    symbol: "PAW",
    slug: "paw-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paw-v2.png",
  },
  {
    name: "Space Game ORES",
    symbol: "$ORES",
    slug: "space-game-ores",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-game-ores.png",
  },
  {
    name: "VooVoo",
    symbol: "VOO",
    slug: "voovoo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voovoo.png",
  },
  {
    name: "Dreamscape",
    symbol: "DSC",
    slug: "dreamscape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dreamscape.png",
  },
  {
    name: "Avidax Finance",
    symbol: "AVI",
    slug: "avidax-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avidax-finance.png",
  },
  {
    name: "Mac&Cheese",
    symbol: "XVMC",
    slug: "mac-and-cheese-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mac-and-cheese-finance.png",
  },
  {
    name: "SonicSwap",
    symbol: "SONIC",
    slug: "sonic-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonic-swap.png",
  },
  {
    name: "Emerald Nodes",
    symbol: "EM",
    slug: "emerald-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emerald-nodes.png",
  },
  {
    name: "Hunter Crypto Coin",
    symbol: "HCC",
    slug: "hunter-crypto-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hunter-crypto-coin.png",
  },
  {
    name: "Coin Fast Alert V2",
    symbol: "CFA",
    slug: "coin-fast-alert",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-fast-alert.png",
  },
  {
    name: "Knights of Fantom",
    symbol: "KNIGHTS",
    slug: "knights-of-fantom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knights-of-fantom.png",
  },
  {
    name: "Bnb Energy",
    symbol: "BNBEN",
    slug: "bnb-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnb-energy.png",
  },
  {
    name: "Agora Defi",
    symbol: "AGORA",
    slug: "agora-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agora-defi.png",
  },
  {
    name: "Kika",
    symbol: "KIKA",
    slug: "kika",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kika.png",
  },
  {
    name: "Metavorz",
    symbol: "META",
    slug: "metavorz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metavorz.png",
  },
  {
    name: "StrategyX Finance",
    symbol: "STAY",
    slug: "strategyx-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strategyx-finance.png",
  },
  {
    name: "SALO Players",
    symbol: "SALO",
    slug: "salo-players",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/salo-players.png",
  },
  {
    name: "Abby Inu",
    symbol: "ABBY",
    slug: "abby-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abby-inu.png",
  },
  {
    name: "Punk.Network",
    symbol: "PUNK",
    slug: "punk-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/punk-network.png",
  },
  {
    name: "Ethereum Message Search",
    symbol: "EMS",
    slug: "ethereum-message-search",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-message-search.png",
  },
  {
    name: "The Fire Token",
    symbol: "XFR",
    slug: "the-fire-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-fire-token.png",
  },
  {
    name: "Metaweds",
    symbol: "MWEDS",
    slug: "metaweds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaweds.png",
  },
  {
    name: "FBBank",
    symbol: "FB",
    slug: "fbbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fbbank.png",
  },
  {
    name: "SkyDOS",
    symbol: "SDC",
    slug: "skydos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skydos.png",
  },
  {
    name: "Blockchain.io",
    symbol: "BCIO",
    slug: "bcio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bcio.png",
  },
  {
    name: "Cryptyk Token",
    symbol: "CTK",
    slug: "cryptyk-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptyk-token.png",
  },
  {
    name: "LadderCaster",
    symbol: "LADA",
    slug: "laddercaster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laddercaster.png",
  },
  {
    name: "Iotexchart",
    symbol: "IOTEXCHART",
    slug: "iotexchart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iotexchart.png",
  },
  {
    name: "BOOLISH TOKEN",
    symbol: "$BOOLISH",
    slug: "boolish-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boolish-token.png",
  },
  {
    name: "Noah",
    symbol: "NOAH",
    slug: "noah",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noah.png",
  },
  {
    name: "Bird Token",
    symbol: "BIRD",
    slug: "bird-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bird-token.png",
  },
  {
    name: "MetaVersus",
    symbol: "METAVS",
    slug: "metaversus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaversus.png",
  },
  {
    name: "LOFI",
    symbol: "LOFI",
    slug: "lofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lofi.png",
  },
  {
    name: "Parrot Egg",
    symbol: "IPEGG",
    slug: "parrot-egg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parrot-egg.png",
  },
  {
    name: "DATACHAIN FOUNDATION (DC)",
    symbol: "DC",
    slug: "datachain-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/datachain-foundation.png",
  },
  {
    name: "Solootbox DAO",
    symbol: "BOX",
    slug: "solootbox-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solootbox-dao.png",
  },
  {
    name: "Chubs Inu",
    symbol: "CHUBS",
    slug: "chubs-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chubs-inu.png",
  },
  {
    name: "Random",
    symbol: "RNDM",
    slug: "random-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/random-token.png",
  },
  {
    name: "INTDESTCOIN",
    symbol: "INTD",
    slug: "intdestcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intdestcoin.png",
  },
  {
    name: "ENKI Coin",
    symbol: "ENKI",
    slug: "enki-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enki-coin.png",
  },
  {
    name: "FTM PUP Token",
    symbol: "FPUP",
    slug: "ftm-pup-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ftm-pup-token.png",
  },
  {
    name: "Police & Thief Game",
    symbol: "BRIBE",
    slug: "police-thief-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/police-thief-game.png",
  },
  {
    name: "LooksCoin",
    symbol: "LOOK",
    slug: "lookscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lookscoin.png",
  },
  {
    name: "IoTexShiba",
    symbol: "IOSHIB",
    slug: "iotexshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iotexshiba.png",
  },
  {
    name: "Doge Dealer",
    symbol: "Doge Dealer",
    slug: "doge-dealer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-dealer.png",
  },
  {
    name: "CreDA",
    symbol: "CREDA",
    slug: "creda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creda.png",
  },
  {
    name: "Swapr",
    symbol: "SWPR",
    slug: "swapr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapr.png",
  },
  {
    name: "Newdex Token",
    symbol: "DEX",
    slug: "newdex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newdex-token.png",
  },
  {
    name: "CRODEX Metaverse Hub",
    symbol: "MHUB",
    slug: "crodex-metaverse-hub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crodex-metaverse-hub.png",
  },
  {
    name: "Wrapped PRCY Coin",
    symbol: "BPRCY",
    slug: "wrapped-prcy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-prcy-coin.png",
  },
  {
    name: "POLYDeFI",
    symbol: "OCTAGON",
    slug: "polydefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polydefi.png",
  },
  {
    name: "Space Game KLAYE",
    symbol: "$KLAYE",
    slug: "space-game-klaye",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-game-klaye.png",
  },
  {
    name: "Ribbit",
    symbol: "RIBT",
    slug: "ribbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ribbit.png",
  },
  {
    name: "Mad Meerkat ETF",
    symbol: "METF",
    slug: "mad-meerkat-etf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mad-meerkat-etf.png",
  },
  {
    name: "PLATFORM",
    symbol: "PLT",
    slug: "platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/platform.png",
  },
  {
    name: "Ape Universe",
    symbol: "APEU",
    slug: "ape-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ape-universe.png",
  },
  {
    name: "Camel Coin",
    symbol: "CMLCOIN",
    slug: "camel-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/camel-coin.png",
  },
  {
    name: "Metatrone",
    symbol: "MET",
    slug: "metatrone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metatrone.png",
  },
  {
    name: "JamonSwap",
    symbol: "JAMON",
    slug: "jamonswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jamonswap.png",
  },
  {
    name: "Moon Robots",
    symbol: "OIL",
    slug: "moon-robots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-robots.png",
  },
  {
    name: "MinerJoe",
    symbol: "CREDITS",
    slug: "minerjoe-credits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minerjoe-credits.png",
  },
  {
    name: "NoVa",
    symbol: "NVA",
    slug: "nova-battles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nova-battles.png",
  },
  {
    name: "Doge Rise Up",
    symbol: "DOGERISEUP",
    slug: "doge-rise-up",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-rise-up.png",
  },
  {
    name: "Babyllama",
    symbol: "BABYLLAMA",
    slug: "babyllama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyllama.png",
  },
  {
    name: "DaVinci Token",
    symbol: "VINCI",
    slug: "davinci-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/davinci-token.png",
  },
  {
    name: "Floki One",
    symbol: "floki",
    slug: "floki-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-one.png",
  },
  {
    name: "CheeseDAO",
    symbol: "CHEEZ",
    slug: "cheesedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheesedao.png",
  },
  {
    name: "Rammus",
    symbol: "RAMMUS",
    slug: "rammus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rammus.png",
  },
  {
    name: "Fief Guild",
    symbol: "FIEF",
    slug: "fief-guild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fief-guild.png",
  },
  {
    name: "Rome",
    symbol: "ROME",
    slug: "rome",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rome.png",
  },
  {
    name: "CoinStorey",
    symbol: "CST",
    slug: "coinstorey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinstorey.png",
  },
  {
    name: "KibaStableCapital",
    symbol: "KSC",
    slug: "kibastablecapital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kibastablecapital.png",
  },
  {
    name: "Vita Inu",
    symbol: "VINU",
    slug: "vita-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vita-inu.png",
  },
  {
    name: "Node Compiler AVAX",
    symbol: "NODEC",
    slug: "node-compiler-avax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/node-compiler-avax.png",
  },
  {
    name: "Mini Metis",
    symbol: "MINIME",
    slug: "mini-metis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-metis.png",
  },
  {
    name: "Meta Dragon City",
    symbol: "DRAGON",
    slug: "meta-dragon-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-dragon-city.png",
  },
  {
    name: "RoaoGame",
    symbol: "ROAO",
    slug: "roaogame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roaogame.png",
  },
  {
    name: "Probably Nothing",
    symbol: "PRBLY",
    slug: "probably-nothing-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/probably-nothing-v2.png",
  },
  {
    name: "Cry Cat Coin",
    symbol: "CRYY",
    slug: "cry-cat-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cry-cat-coin.png",
  },
  {
    name: "Enft.One",
    symbol: "EMJ",
    slug: "enft-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enft-one.png",
  },
  {
    name: "Traders Business Global",
    symbol: "TGB",
    slug: "traders-business-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/traders-business-global.png",
  },
  {
    name: "Intelligent Monsters",
    symbol: "IMON",
    slug: "intelligent-monsters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intelligent-monsters.png",
  },
  {
    name: "Hare Chain",
    symbol: "HAREC",
    slug: "hare-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hare-chain.png",
  },
  {
    name: "Open Swap",
    symbol: "OPENX",
    slug: "open-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/open-swap.png",
  },
  {
    name: "Crypto Fantasy League",
    symbol: "CFL",
    slug: "crypto-fantasy-league",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-fantasy-league.png",
  },
  {
    name: "Harmonyville",
    symbol: "HVILLE",
    slug: "harmonyville",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harmonyville.png",
  },
  {
    name: "DragonBall Coin",
    symbol: "DBC",
    slug: "dragonball-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonball-coin.png",
  },
  {
    name: "BeglobalDAO",
    symbol: "GLBD",
    slug: "beglobaldao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beglobaldao.png",
  },
  {
    name: "Meta Uranus",
    symbol: "MEUR",
    slug: "meta-uranus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-uranus.png",
  },
  {
    name: "Haruno Inu",
    symbol: "HARUNO",
    slug: "haruno-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/haruno-inu.png",
  },
  {
    name: "MeowSwap",
    symbol: "MEOW",
    slug: "meow-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meow-swap.png",
  },
  {
    name: "Far West Games",
    symbol: "CASH",
    slug: "far-west-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/far-west-games.png",
  },
  {
    name: "Amaze World",
    symbol: "AMZE",
    slug: "amaze-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amaze-world.png",
  },
  {
    name: "EternalWorld",
    symbol: "ETL",
    slug: "eternalworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eternalworld.png",
  },
  {
    name: "Lyfe Land",
    symbol: "LLAND",
    slug: "lyfe-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lyfe-land.png",
  },
  {
    name: "Fishera",
    symbol: "FSHR",
    slug: "fishera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fishera.png",
  },
  {
    name: "Hakuna Matata Finance",
    symbol: "HKN",
    slug: "hakuna-matata-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hakuna-matata-finance.png",
  },
  {
    name: "HotDoge",
    symbol: "HOTDOGE",
    slug: "hotdoge-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hotdoge-new.png",
  },
  {
    name: "Hello Gophers",
    symbol: "SHARD",
    slug: "hello-gophers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hello-gophers.png",
  },
  {
    name: "ShiblonMoon",
    symbol: "SHIBLONMOON",
    slug: "shiblonmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiblonmoon.png",
  },
  {
    name: "Excalibur",
    symbol: "EXC",
    slug: "excalibur-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/excalibur-exchange.png",
  },
  {
    name: "Cens World",
    symbol: "CENS",
    slug: "cens-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cens-world.png",
  },
  {
    name: "OpenStream World",
    symbol: "OSW",
    slug: "openstream-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/openstream-world.png",
  },
  {
    name: "MYSTERY",
    symbol: "MYST",
    slug: "mystery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mystery.png",
  },
  {
    name: "ElonJet",
    symbol: "ELONJET",
    slug: "elonjet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elonjet.png",
  },
  {
    name: "Dragon",
    symbol: "DRAGON",
    slug: "dragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon.png",
  },
  {
    name: "Asuna Hentai",
    symbol: "ASUNA",
    slug: "asuna-hentai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asuna-hentai.png",
  },
  {
    name: "Derify Protocol",
    symbol: "DRF",
    slug: "derify-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/derify-protocol.png",
  },
  {
    name: "Politicoin",
    symbol: "PBLC",
    slug: "politicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/politicoin.png",
  },
  {
    name: "Zilla Shares",
    symbol: "ZSHARE",
    slug: "zilla-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilla-shares.png",
  },
  {
    name: "Arrano",
    symbol: "ANO",
    slug: "arrano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arrano.png",
  },
  {
    name: "DegenFi",
    symbol: "DEGEN",
    slug: "degenfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degenfi.png",
  },
  {
    name: "BattleMechs",
    symbol: "GEMZ",
    slug: "battlemechs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battlemechs.png",
  },
  {
    name: "The Commission",
    symbol: "CMSN",
    slug: "the-commission",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-commission.png",
  },
  {
    name: "Clash Of Cars",
    symbol: "CLASH",
    slug: "clash-of-cars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clash-of-cars.png",
  },
  {
    name: "FINANCE PROTOCOL",
    symbol: "FP",
    slug: "finance-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/finance-protocol.png",
  },
  {
    name: "Lilith Swap",
    symbol: "LLTH",
    slug: "lilith-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lilith-swap.png",
  },
  {
    name: "Rafflet",
    symbol: "RAF",
    slug: "rafflet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rafflet.png",
  },
  {
    name: "Cornerchain",
    symbol: "CNR",
    slug: "cornerchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cornerchain.png",
  },
  {
    name: "Happy Duck Farm",
    symbol: "HDF",
    slug: "happy-duck-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/happy-duck-farm.png",
  },
  {
    name: "PSY Coin",
    symbol: "PSY",
    slug: "psy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/psy-coin.png",
  },
  {
    name: "Niffler Coin",
    symbol: "NIFF",
    slug: "niffler-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niffler-coin.png",
  },
  {
    name: "Agile Finance",
    symbol: "AGL",
    slug: "agile-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agile-finance.png",
  },
  {
    name: "Twindex",
    symbol: "TWX",
    slug: "twindex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twindex.png",
  },
  {
    name: "MOONEYE",
    symbol: "ME",
    slug: "mooneye",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mooneye.png",
  },
  {
    name: "Genesis Universe",
    symbol: "GUT",
    slug: "genesis-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesis-universe.png",
  },
  {
    name: "AnySniper",
    symbol: "SNIPE",
    slug: "anysniper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anysniper.png",
  },
  {
    name: "Ankr Reward Earning Staked ETH",
    symbol: "aETHb",
    slug: "ankr-reward-earning-staked-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ankr-reward-earning-staked-eth.png",
  },
  {
    name: "Meta Game Token",
    symbol: "MGT",
    slug: "meta-game-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-game-token.png",
  },
  {
    name: "Xaviera Techno Solutions",
    symbol: "XTS",
    slug: "xaviera-techno-solutions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xaviera-techno-solutions.png",
  },
  {
    name: "Apex Nodes",
    symbol: "APEX",
    slug: "apex-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apex-nodes.png",
  },
  {
    name: "Arenaverse",
    symbol: "AVERSE",
    slug: "arenaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arenaverse.png",
  },
  {
    name: "ChikinCoin",
    symbol: "CKC",
    slug: "chikincoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chikincoin.png",
  },
  {
    name: "SONIC INU",
    symbol: "SONIC",
    slug: "sonic-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonic-inu.png",
  },
  {
    name: "Pudgy Pups Club[new]",
    symbol: "PUPS",
    slug: "pudgy-pups-club-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pudgy-pups-club-new.png",
  },
  {
    name: "Arrano DEX",
    symbol: "ANDX",
    slug: "arrano-dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arrano-dex.png",
  },
  {
    name: "Walk To Earn",
    symbol: "W2E",
    slug: "walk-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/walk-to-earn.png",
  },
  {
    name: "Undead Finance",
    symbol: "UNDEAD",
    slug: "undead-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/undead-finance.png",
  },
  {
    name: "AGGREG8 FINANCE",
    symbol: "AG8",
    slug: "aggreg8-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aggreg8-finance.png",
  },
  {
    name: "Loot Dollar",
    symbol: "LOOTDOLLAR",
    slug: "loot-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loot-dollar.png",
  },
  {
    name: "Ape Of Arena",
    symbol: "AOA",
    slug: "ape-of-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ape-of-arena.png",
  },
  {
    name: "marmaj",
    symbol: "MARMAJ",
    slug: "marmaj",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marmaj.png",
  },
  {
    name: "Pugfather Token",
    symbol: "PUGT",
    slug: "pugfather-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pugfather-token.png",
  },
  {
    name: "TURKEY INU",
    symbol: "TURKEY",
    slug: "turkey-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turkey-inu.png",
  },
  {
    name: "SOMDEJ",
    symbol: "SDC",
    slug: "somdej",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/somdej.png",
  },
  {
    name: "MiniThunder",
    symbol: "THUNDER",
    slug: "minithunder",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minithunder.png",
  },
  {
    name: "DogeGiving",
    symbol: "DOGEGIVING",
    slug: "dogegiving",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogegiving.png",
  },
  {
    name: "Davis Cup Fan Token",
    symbol: "DAVIS",
    slug: "davis-cup-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/davis-cup-fan-token.png",
  },
  {
    name: "Floki Loves ADA",
    symbol: "FADA",
    slug: "floki-loves-ada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-loves-ada.png",
  },
  {
    name: "SharkBonk",
    symbol: "SBONK",
    slug: "sharkbonk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sharkbonk.png",
  },
  {
    name: "Cirrus",
    symbol: "CIRRUS",
    slug: "cirrus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cirrus.png",
  },
  {
    name: "CryptoBee",
    symbol: "BEE",
    slug: "cryptobee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobee.png",
  },
  {
    name: "Summeris",
    symbol: "SUM",
    slug: "summeris",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/summeris.png",
  },
  {
    name: "DADDY ETHEREUM",
    symbol: "DDETH",
    slug: "daddy-ethereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddy-ethereum.png",
  },
  {
    name: "The Earth Shiba",
    symbol: "EARTHSHIB",
    slug: "the-earth-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-earth-shiba.png",
  },
  {
    name: "Swinca",
    symbol: "SWI",
    slug: "swinca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swinca.png",
  },
  {
    name: "Flokifam",
    symbol: "FLOKIFAM",
    slug: "flokifam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokifam.png",
  },
  {
    name: "AltCrusaders",
    symbol: "ALT",
    slug: "altcrusaders",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altcrusaders.png",
  },
  {
    name: "Bezos Coin",
    symbol: "BZSC",
    slug: "bezos-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bezos-coin.png",
  },
  {
    name: "Human World",
    symbol: "SSAP",
    slug: "human-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/human-world.png",
  },
  {
    name: "Fans Squid",
    symbol: "FST",
    slug: "fans-squid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fans-squid.png",
  },
  {
    name: "Plata Network",
    symbol: "PLATA",
    slug: "plata-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plata-network.png",
  },
  {
    name: "Floki Inu Farm",
    symbol: "FLOKIFARM",
    slug: "floki-inu-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-inu-farm.png",
  },
  {
    name: "Hippie Inu",
    symbol: "HIPPIE",
    slug: "hippie-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hippie-inu.png",
  },
  {
    name: "Daddy Dogeback",
    symbol: "DADDYDB",
    slug: "daddy-dogeback",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddy-dogeback.png",
  },
  {
    name: "PowerInu",
    symbol: "POWERINU",
    slug: "powerinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/powerinu.png",
  },
  {
    name: "Plutonium",
    symbol: "PLN",
    slug: "plutonium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plutonium.png",
  },
  {
    name: "Megastarz",
    symbol: "MEG",
    slug: "megastarz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megastarz.png",
  },
  {
    name: "LetsGoBrandonToken",
    symbol: "LGBT",
    slug: "letsgobrandontoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/letsgobrandontoken.png",
  },
  {
    name: "The Veteran",
    symbol: "VTN",
    slug: "the-veteran",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-veteran.png",
  },
  {
    name: "LABS Protocol",
    symbol: "LABS",
    slug: "labs-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/labs-protocol.png",
  },
  {
    name: "FLOKIMOON NOW",
    symbol: "FLOKIMOON",
    slug: "flokimoon-now",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokimoon-now.png",
  },
  {
    name: "8ight Finance",
    symbol: "EIGHT",
    slug: "8ight-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/8ight-finance.png",
  },
  {
    name: "CATEMOON INU",
    symbol: "CATE",
    slug: "catemoon-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catemoon-inu.png",
  },
  {
    name: "Freyala",
    symbol: "XYA",
    slug: "freyala",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freyala.png",
  },
  {
    name: "Bloody Shiba",
    symbol: "BLOODYSHIBA",
    slug: "bloody-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bloody-shiba.png",
  },
  {
    name: "Shiba Floki Trillionaire",
    symbol: "SHIFLOTRI",
    slug: "shiba-floki-trillionaire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-floki-trillionaire.png",
  },
  {
    name: "MetaOceanCity",
    symbol: "MOC",
    slug: "metaoceancity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaoceancity.png",
  },
  {
    name: "RivrKitty",
    symbol: "RKITTY",
    slug: "rivrkitty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rivrkitty.png",
  },
  {
    name: "MoonDao",
    symbol: "MD",
    slug: "moondao-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moondao-2.png",
  },
  {
    name: "Microverse",
    symbol: "MVP",
    slug: "microverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microverse.png",
  },
  {
    name: "Maneki Inu",
    symbol: "MANEKI",
    slug: "maneki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maneki-inu.png",
  },
  {
    name: "DaddyETH",
    symbol: "DADDYETH",
    slug: "daddyeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddyeth.png",
  },
  {
    name: "PomPom",
    symbol: "POM",
    slug: "pompom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pompom.png",
  },
  {
    name: "AstroSanta",
    symbol: "ASA",
    slug: "astrosanta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astrosanta.png",
  },
  {
    name: "The Cat Inu",
    symbol: "THECAT",
    slug: "the-cat-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-cat-inu.png",
  },
  {
    name: "SuperPoocoin",
    symbol: "SUPERPOO",
    slug: "superpoocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superpoocoin.png",
  },
  {
    name: "Red Shiba Token",
    symbol: "RST",
    slug: "red-shiba-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/red-shiba-token.png",
  },
  {
    name: "QuackInu",
    symbol: "QUACK",
    slug: "quackinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quackinu.png",
  },
  {
    name: "The Moon Shiba",
    symbol: "MOONSHIB",
    slug: "the-moon-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-moon-shiba.png",
  },
  {
    name: "XRP Reflector",
    symbol: "XRPR",
    slug: "xrp-reflector",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrp-reflector.png",
  },
  {
    name: "DOK Token",
    symbol: "DOK",
    slug: "dok-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dok-token.png",
  },
  {
    name: "MUSKARDASHIAN",
    symbol: "MUSKARDASHIAN",
    slug: "muskardashian",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muskardashian.png",
  },
  {
    name: "Jolofcoin",
    symbol: "JOL",
    slug: "jolofcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jolofcoin.png",
  },
  {
    name: "Baby Yooshi",
    symbol: "BABY YOOSHI",
    slug: "baby-yooshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-yooshi.png",
  },
  {
    name: "Haven token",
    symbol: "HAVEN",
    slug: "haven-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/haven-token.png",
  },
  {
    name: "DPWK",
    symbol: "DPWK",
    slug: "dpwk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dpwk.png",
  },
  {
    name: "Pissing Cat",
    symbol: "PEECAT",
    slug: "pissing-cat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pissing-cat.png",
  },
  {
    name: "Articuno Inu",
    symbol: "ARTICUNO",
    slug: "articuno-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/articuno-inu.png",
  },
  {
    name: "Shiba Fame",
    symbol: "SHIBA FAME",
    slug: "shiba-fame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-fame.png",
  },
  {
    name: "Liquidity Accelerator Token",
    symbol: "LAT",
    slug: "liquidity-accelerator-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquidity-accelerator-token.png",
  },
  {
    name: "ALANO",
    symbol: "ALANO",
    slug: "alano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alano.png",
  },
  {
    name: "MetaZuckZilla",
    symbol: "META",
    slug: "metazuckzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metazuckzilla.png",
  },
  {
    name: "BambiUSDT",
    symbol: "BAMUSDT",
    slug: "bambiusdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bambiusdt.png",
  },
  {
    name: "Clash",
    symbol: "CLH",
    slug: "clash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clash.png",
  },
  {
    name: "Trump Inu",
    symbol: "TRUMP",
    slug: "trump-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trump-inu.png",
  },
  {
    name: "Cosmic Coin",
    symbol: "COSMIC",
    slug: "cosmic-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cosmic-coin.png",
  },
  {
    name: "Metarea VR",
    symbol: "METAVR",
    slug: "metarea-vr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metarea-vr.png",
  },
  {
    name: "Dog Club Token",
    symbol: "DCLUB",
    slug: "dog-club-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dog-club-token.png",
  },
  {
    name: "KrakenSquid",
    symbol: "KRAKENSQUID",
    slug: "krakensquid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krakensquid.png",
  },
  {
    name: "Girl Story",
    symbol: "METAGIRL",
    slug: "girl-story",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/girl-story.png",
  },
  {
    name: "Squirt Game",
    symbol: "SQUIRT",
    slug: "squirt-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squirt-game.png",
  },
  {
    name: "Vince chain",
    symbol: "VCE",
    slug: "vince-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vince-chain.png",
  },
  {
    name: "Fans of Doge",
    symbol: "DOGEFANS",
    slug: "fans-of-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fans-of-doge.png",
  },
  {
    name: "GiftBag",
    symbol: "GBAG",
    slug: "giftbag",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/giftbag.png",
  },
  {
    name: "MetaBean",
    symbol: "METABEAN",
    slug: "metabean",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabean.png",
  },
  {
    name: "No Face Inu",
    symbol: "NOFACE",
    slug: "no-face-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/no-face-inu.png",
  },
  {
    name: "DynamicB",
    symbol: "DYNB",
    slug: "dynamicb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dynamicb.png",
  },
  {
    name: "Doge Gay Son",
    symbol: "GOGE",
    slug: "dogegayson",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogegayson.png",
  },
  {
    name: "ETHSNIPER",
    symbol: "ETS",
    slug: "ethsniper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethsniper.png",
  },
  {
    name: "KarmaDao",
    symbol: "KDAO",
    slug: "karmadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karmadao.png",
  },
  {
    name: "Original Gangsta Shiba",
    symbol: "OGSHIB",
    slug: "original-gangsta-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/original-gangsta-shiba.png",
  },
  {
    name: "METAMEN",
    symbol: "MTM",
    slug: "metamen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamen.png",
  },
  {
    name: "Shibonics",
    symbol: "SNIS",
    slug: "shibonics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibonics.png",
  },
  {
    name: "Stadium Ape",
    symbol: "SAPE",
    slug: "stadium-ape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stadium-ape.png",
  },
  {
    name: "Contracto",
    symbol: "LOCK",
    slug: "contracto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/contracto.png",
  },
  {
    name: "Meta Shiba",
    symbol: "MESHI",
    slug: "metashiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metashiba.png",
  },
  {
    name: "Moon Sack",
    symbol: "SACK",
    slug: "moon-sack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-sack.png",
  },
  {
    name: "CryptoTwitter",
    symbol: "CT",
    slug: "cryptotwitter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptotwitter.png",
  },
  {
    name: "Bulldog Inu",
    symbol: "BULLD",
    slug: "bulldog-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulldog-inu.png",
  },
  {
    name: "Metaverse Network",
    symbol: "METANET",
    slug: "metaverse-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-network.png",
  },
  {
    name: "flokidoge",
    symbol: "$FLOGE",
    slug: "flokidoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokidoge.png",
  },
  {
    name: "Naruto Inu",
    symbol: "NARUTO",
    slug: "naruto-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/naruto-inu.png",
  },
  {
    name: "Tranquility City",
    symbol: "LUMEN",
    slug: "tranquility-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tranquility-city.png",
  },
  {
    name: "Soccer Infinity",
    symbol: "SOCIN",
    slug: "soccer-infinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soccer-infinity.png",
  },
  {
    name: "SeanceCircle",
    symbol: "SEANCE",
    slug: "seancecircle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seancecircle.png",
  },
  {
    name: "DogeXmoon",
    symbol: "DXM",
    slug: "dogexmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogexmoon.png",
  },
  {
    name: "x99Token",
    symbol: "X99",
    slug: "x99token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x99token.png",
  },
  {
    name: "CertRise",
    symbol: "CERT",
    slug: "certrise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/certrise.png",
  },
  {
    name: "Leprechaun Finance",
    symbol: "LEP",
    slug: "leprechaun-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leprechaun-finance.png",
  },
  {
    name: "Wall Street Inu",
    symbol: "WALLSTREETINU",
    slug: "wall-street-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wall-street-inu.png",
  },
  {
    name: "CoinTribe",
    symbol: "CTRIBE",
    slug: "cointribe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cointribe.png",
  },
  {
    name: "Saitama Samurai",
    symbol: "SAITAMURAI",
    slug: "saitama-samurai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saitama-samurai.png",
  },
  {
    name: "Unbanked",
    symbol: "UNBNK",
    slug: "unbanked",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unbanked.png",
  },
  {
    name: "MetaPortal",
    symbol: "METAPORTAL",
    slug: "metaportal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaportal.png",
  },
  {
    name: "Tranquil Finance",
    symbol: "TRANQ",
    slug: "tranquil-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tranquil-finance.png",
  },
  {
    name: "Zombie Skull Games",
    symbol: "ZSKULL",
    slug: "zombie-skull-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zombie-skull-games.png",
  },
  {
    name: "Baby Boxer",
    symbol: "BBOXER",
    slug: "baby-boxer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-boxer.png",
  },
  {
    name: "ScarpaCoin",
    symbol: "SC",
    slug: "scarpacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scarpacoin.png",
  },
  {
    name: "Deliq Finance",
    symbol: "DLQ",
    slug: "deliq-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deliq-finance.png",
  },
  {
    name: "FLOKIFOMO",
    symbol: "FLOKIFM",
    slug: "flokifomo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokifomo.png",
  },
  {
    name: "ImpactX",
    symbol: "IMPACTX",
    slug: "impactx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/impactx.png",
  },
  {
    name: "Sabac Warrior",
    symbol: "SW",
    slug: "sabac-warrior",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sabac-warrior.png",
  },
  {
    name: "VNDT",
    symbol: "VNDT",
    slug: "vndt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vndt.png",
  },
  {
    name: "Sata Exchange",
    symbol: "SATAX",
    slug: "sata-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sata-exchange.png",
  },
  {
    name: "Rebellion Protocol",
    symbol: "REBL",
    slug: "rebellion-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rebellion-protocol.png",
  },
  {
    name: "AirPay",
    symbol: "AIRPAY",
    slug: "airpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/airpay.png",
  },
  {
    name: "PANDAINU",
    symbol: "PWT",
    slug: "pandainu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pandainu.png",
  },
  {
    name: "Squid Inu",
    symbol: "SQUID",
    slug: "squid-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squid-inu.png",
  },
  {
    name: "Genshin NFT",
    symbol: "GENSHIN",
    slug: "genshin-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genshin-nft.png",
  },
  {
    name: "RivrFloki",
    symbol: "RIVRFLOKI",
    slug: "rivrfloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rivrfloki.png",
  },
  {
    name: "GHOST SHIBA",
    symbol: "GSHIBA",
    slug: "ghost-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghost-shiba.png",
  },
  {
    name: "Animverse",
    symbol: "ANM",
    slug: "animverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animverse.png",
  },
  {
    name: "CaashCow",
    symbol: "COW",
    slug: "caashcow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caashcow.png",
  },
  {
    name: "DogeGamer",
    symbol: "DGA",
    slug: "dogegamer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogegamer.png",
  },
  {
    name: "LuckyToken",
    symbol: "LKT",
    slug: "luckytoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luckytoken.png",
  },
  {
    name: "GnomeToken",
    symbol: "GNOME",
    slug: "gnometoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gnometoken.png",
  },
  {
    name: "HodlBUSD",
    symbol: "HBUSD",
    slug: "hodlbusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodlbusd.png",
  },
  {
    name: "Reverse Protocol",
    symbol: "RVRS",
    slug: "reverse-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reverse-protocol.png",
  },
  {
    name: "TacoEnergy",
    symbol: "TACOE",
    slug: "tacoenergy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tacoenergy.png",
  },
  {
    name: "Cryptorun Network",
    symbol: "CRN",
    slug: "cryptorun-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptorun-network.png",
  },
  {
    name: "Makk",
    symbol: "MAKK",
    slug: "makk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/makk.png",
  },
  {
    name: "SBER",
    symbol: "SBER",
    slug: "sber",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sber.png",
  },
  {
    name: "Garfield Token",
    symbol: "GARFIELD",
    slug: "garfield-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/garfield-token.png",
  },
  {
    name: "Shark Girl",
    symbol: "SGIRL",
    slug: "shark-girl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shark-girl.png",
  },
  {
    name: "PlayersOnly",
    symbol: "PO",
    slug: "playersonly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playersonly.png",
  },
  {
    name: "FOMO Pot",
    symbol: "FOMO",
    slug: "fomopot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fomopot.png",
  },
  {
    name: "Sphynx Token",
    symbol: "SPHYNX",
    slug: "sphynx-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sphynx-bsc.png",
  },
  {
    name: "CASHTHAI",
    symbol: "CTHAI",
    slug: "cashthai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashthai.png",
  },
  {
    name: "Shiba Monk",
    symbol: "SHIBAMONK",
    slug: "shiba-monk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-monk.png",
  },
  {
    name: "Squid Pet",
    symbol: "SQUIDPET",
    slug: "squid-pet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squid-pet.png",
  },
  {
    name: "BANG Decentralized",
    symbol: "BANG",
    slug: "bang-decentralized",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bang-decentralized.png",
  },
  {
    name: "Floki Rocket",
    symbol: "RKF",
    slug: "flokirocket-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokirocket-token.png",
  },
  {
    name: "Jaiho Crypto",
    symbol: "JAIHO",
    slug: "jaiho-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jaiho-crypto.png",
  },
  {
    name: "Jackpot Token",
    symbol: "JPT",
    slug: "jackpot-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jackpot-token.png",
  },
  {
    name: "Inverse Protocol",
    symbol: "INVERSE",
    slug: "inverse-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inverse-protocol.png",
  },
  {
    name: "Petsneaker",
    symbol: "PSC",
    slug: "petsneaker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petsneaker.png",
  },
  {
    name: "Famous Five",
    symbol: "FAFI",
    slug: "famous-five",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/famous-five.png",
  },
  {
    name: "FlokiBro",
    symbol: "FBRO",
    slug: "flokibro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokibro.png",
  },
  {
    name: "Mastiff Inu",
    symbol: "MINU",
    slug: "mastiff-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mastiff-inu.png",
  },
  {
    name: "Skywalker",
    symbol: "SKY",
    slug: "skywalker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skywalker.png",
  },
  {
    name: "MEMEKING.GAMES",
    symbol: "MMK",
    slug: "memeking-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memeking-games.png",
  },
  {
    name: "Articoin solana",
    symbol: "ATC",
    slug: "articoin-solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/articoin-solana.png",
  },
  {
    name: "Tipsy Santa",
    symbol: "TIPSY",
    slug: "tipsy-santa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tipsy-santa.png",
  },
  {
    name: "MEONG TOKEN",
    symbol: "MEONG",
    slug: "meong-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meong-token.png",
  },
  {
    name: "Farmageddon",
    symbol: "FG",
    slug: "farmageddon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmageddon.png",
  },
  {
    name: "WINDOGE95",
    symbol: "WNDG95",
    slug: "windoge95",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/windoge95.png",
  },
  {
    name: "Husky Inu",
    symbol: "HDOG",
    slug: "husky-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/husky-inu.png",
  },
  {
    name: "Shiba Inu Empire",
    symbol: "SHIBEMP",
    slug: "shiba-inu-empire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-inu-empire.png",
  },
  {
    name: "NFTFundArt",
    symbol: "NFA",
    slug: "nftfundart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftfundart.png",
  },
  {
    name: "CAIETF.Finance",
    symbol: "CAI",
    slug: "caietf-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caietf-finance.png",
  },
  {
    name: "Baby Mind",
    symbol: "BMND",
    slug: "baby-mind",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-mind.png",
  },
  {
    name: "Warship Battles",
    symbol: "$OIL",
    slug: "warship-battles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/warship-battles.png",
  },
  {
    name: "Mishka Token",
    symbol: "MISHKA",
    slug: "mishka-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mishka-token.png",
  },
  {
    name: "GM ETH",
    symbol: "GM",
    slug: "gm-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gm-coin.png",
  },
  {
    name: "BakedCake",
    symbol: "BAKEDCAKE",
    slug: "bakedcake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bakedcake.png",
  },
  {
    name: "Mangamon",
    symbol: "MAN",
    slug: "mangamon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mangamon.png",
  },
  {
    name: "Whive Protocol",
    symbol: "WHIVE",
    slug: "the-whive-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-whive-protocol.png",
  },
  {
    name: "BSC33DAO",
    symbol: "BSC33",
    slug: "bsc33dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsc33dao.png",
  },
  {
    name: "Weenie Inu",
    symbol: "WEENIE",
    slug: "weenie-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weenie-inu.png",
  },
  {
    name: "Will Liu",
    symbol: "LIU",
    slug: "will-liu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/will-liu.png",
  },
  {
    name: "KittyShiba",
    symbol: "KSHIBA",
    slug: "kittyshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kittyshiba.png",
  },
  {
    name: "Trister  World",
    symbol: "TWFI",
    slug: "trister-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trister-world.png",
  },
  {
    name: "Cashio Token",
    symbol: "CASHIO",
    slug: "cashio-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashio-io.png",
  },
  {
    name: "Shiba Interstellar",
    symbol: "SHINT",
    slug: "shiba-interstellar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-interstellar.png",
  },
  {
    name: "Dogggo",
    symbol: "DOGGGO",
    slug: "dogggo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogggo.png",
  },
  {
    name: "Spartans",
    symbol: "SPA",
    slug: "spartan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spartan-token.png",
  },
  {
    name: "Meta Floki Inu",
    symbol: "METAFLOKINU",
    slug: "meta-floki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-floki-inu.png",
  },
  {
    name: "Metaverse ALL BEST ICO",
    symbol: "METAALLBI",
    slug: "metaverse-all-best-ico",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-all-best-ico.png",
  },
  {
    name: "UpLink",
    symbol: "UPLINK",
    slug: "uplink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uplink.png",
  },
  {
    name: "Race.Top",
    symbol: "RACE",
    slug: "race-top",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/race-top.png",
  },
  {
    name: "Anons Network",
    symbol: "ANONS",
    slug: "anons-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anons-network.png",
  },
  {
    name: "Bitoshi",
    symbol: "BTI",
    slug: "bitoshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitoshi.png",
  },
  {
    name: "MELONx",
    symbol: "MLNX",
    slug: "melonx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/melonx.png",
  },
  {
    name: "K-9 INU",
    symbol: "K9",
    slug: "k-9-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/k-9-inu.png",
  },
  {
    name: "Nurse Cat",
    symbol: "NCAT",
    slug: "nurse-cat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nurse-cat.png",
  },
  {
    name: "ADToken",
    symbol: "AD",
    slug: "ad-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ad-token.png",
  },
  {
    name: "Bitmon",
    symbol: "BIT",
    slug: "bitmon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitmon.png",
  },
  {
    name: "Safety",
    symbol: "SFT",
    slug: "safety",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safety.png",
  },
  {
    name: "Dappsy",
    symbol: "APP",
    slug: "dappsy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dappsy.png",
  },
  {
    name: "Baby Santa Token",
    symbol: "$BST",
    slug: "baby-santa-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-santa-token.png",
  },
  {
    name: "Kitsune Mask",
    symbol: "KMASK",
    slug: "kitsune-mask",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitsune-mask.png",
  },
  {
    name: "Power Of Deep Ocean",
    symbol: "PODO",
    slug: "power-of-deep-ocean",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/power-of-deep-ocean.png",
  },
  {
    name: "Mashima Inu",
    symbol: "MASHIMA",
    slug: "mashima-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mashima-inu.png",
  },
  {
    name: "RedZilla",
    symbol: "REDZILLA",
    slug: "redzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redzilla.png",
  },
  {
    name: "Equinox",
    symbol: "ENX",
    slug: "equinox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/equinox.png",
  },
  {
    name: "DogeBNB.org",
    symbol: "DOGEBNB",
    slug: "dogebnb-org",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogebnb-org.png",
  },
  {
    name: "Chocoswap",
    symbol: "VNLA",
    slug: "chocoswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chocoswap.png",
  },
  {
    name: "Easy Finance Token",
    symbol: "EFT",
    slug: "easy-finance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/easy-finance-token.png",
  },
  {
    name: "OneDAO Finance",
    symbol: "ODAO",
    slug: "onedao-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onedao-finance.png",
  },
  {
    name: "Squishiverse",
    symbol: "SLIME",
    slug: "squishiverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squishiverse.png",
  },
  {
    name: "Shitzu Inu",
    symbol: "SHITZUINU",
    slug: "shitzu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shitzu-inu.png",
  },
  {
    name: "Superalgos",
    symbol: "SA",
    slug: "superalgos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superalgos.png",
  },
  {
    name: "Salary Mining",
    symbol: "SLRM",
    slug: "salary-mining",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/salary-mining.png",
  },
  {
    name: "Puppy Token",
    symbol: "$PUPPY",
    slug: "puppy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puppy-token.png",
  },
  {
    name: "Together As One",
    symbol: "TAO",
    slug: "together-as-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/together-as-one.png",
  },
  {
    name: "Alien Shiba Inu",
    symbol: "ASHIB",
    slug: "alien-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alien-shiba-inu.png",
  },
  {
    name: "QUOTA",
    symbol: "QTA",
    slug: "quota",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quota.png",
  },
  {
    name: "Meta Inu",
    symbol: "METAINU",
    slug: "meta-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-inu.png",
  },
  {
    name: "DRACARYS",
    symbol: "DRAC",
    slug: "dracarys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dracarys.png",
  },
  {
    name: "Angry Squid",
    symbol: "AngrySquid",
    slug: "angry-squid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/angry-squid.png",
  },
  {
    name: "Mobius Money",
    symbol: "MOBI",
    slug: "mobius-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobius-money.png",
  },
  {
    name: "Binosaurs",
    symbol: "BINOSAURS",
    slug: "binosaurs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binosaurs.png",
  },
  {
    name: "BNB Shinobi",
    symbol: "CHAKRA",
    slug: "bnb-shinobi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnb-shinobi.png",
  },
  {
    name: "Listen To Earn",
    symbol: "LTE",
    slug: "listen-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/listen-to-earn.png",
  },
  {
    name: "Binancedog",
    symbol: "Bidog",
    slug: "binancedog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binancedog.png",
  },
  {
    name: "SeatlabNFT",
    symbol: "SEAT",
    slug: "seatlabnft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seatlabnft.png",
  },
  {
    name: "Buff Shiba Inu",
    symbol: "BUFFSHIBA",
    slug: "buff-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buff-shiba-inu.png",
  },
  {
    name: "Frieza Inu",
    symbol: "FRINU",
    slug: "frieza-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frieza-inu.png",
  },
  {
    name: "PolkaInu",
    symbol: "PINU",
    slug: "polkainu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkainu.png",
  },
  {
    name: "Triathon",
    symbol: "GEON",
    slug: "triathon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/triathon.png",
  },
  {
    name: "DogeShrek",
    symbol: "DOGESHREK",
    slug: "dogeshrek",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeshrek.png",
  },
  {
    name: "Doge King",
    symbol: "DOGEK",
    slug: "doge-king",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-king.png",
  },
  {
    name: "Unified Society Quantum",
    symbol: "USX",
    slug: "unified-society-quantum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unified-society-quantum.png",
  },
  {
    name: "Pirate Boy",
    symbol: "PIRATEBOY",
    slug: "pirate-boy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pirate-boy.png",
  },
  {
    name: "MintSwap",
    symbol: "MINT",
    slug: "mintswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mintswap.png",
  },
  {
    name: "Premio",
    symbol: "PREMIO",
    slug: "premio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/premio.png",
  },
  {
    name: "Squeeze Token",
    symbol: "SQUEEZE",
    slug: "squeeze-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squeeze-token.png",
  },
  {
    name: "Idoscan",
    symbol: "IDOSCAN",
    slug: "idoscan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idoscan.png",
  },
  {
    name: "Go Ride",
    symbol: "RIDE",
    slug: "go-ride",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/go-ride.png",
  },
  {
    name: "Ironman",
    symbol: "IRONMAN",
    slug: "ironman",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ironman.png",
  },
  {
    name: "GO HAYYA",
    symbol: "HAYYA",
    slug: "go-hayya",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/go-hayya.png",
  },
  {
    name: "NFTASCII",
    symbol: "NFTASCII",
    slug: "nftascii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftascii.png",
  },
  {
    name: "ChefCake",
    symbol: "CHEFCAKE",
    slug: "chefcake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chefcake.png",
  },
  {
    name: "Doge Rocket",
    symbol: "DOGERKT",
    slug: "doge-rocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-rocket.png",
  },
  {
    name: "Riverboat",
    symbol: "RIB",
    slug: "riverboat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/riverboat.png",
  },
  {
    name: "Berserk Inu",
    symbol: "BERSERK",
    slug: "berserk-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/berserk-inu.png",
  },
  {
    name: "Mega Shiba Inu",
    symbol: "MEGASHIB",
    slug: "mega-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mega-shiba-inu.png",
  },
  {
    name: "TRINITY",
    symbol: "TRIN",
    slug: "trinity-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trinity-defi.png",
  },
  {
    name: "FalconX",
    symbol: "FALCX",
    slug: "falconx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/falconx.png",
  },
  {
    name: "FoodPay",
    symbol: "FOODPAY",
    slug: "foodpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foodpay.png",
  },
  {
    name: "Probinex",
    symbol: "PBX",
    slug: "probinex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/probinex.png",
  },
  {
    name: "Gennix",
    symbol: "GNNX",
    slug: "gennix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gennix.png",
  },
  {
    name: "MetaFace",
    symbol: "MFT",
    slug: "metaface",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaface.png",
  },
  {
    name: "FlokiZap",
    symbol: "FLOKIZ",
    slug: "flokizap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokizap.png",
  },
  {
    name: "5table Finance",
    symbol: "5TABLE",
    slug: "5table-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/5table-finance.png",
  },
  {
    name: "CryptoNijigen",
    symbol: "NGN",
    slug: "cryptonijigen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptonijigen.png",
  },
  {
    name: "Flying Colours",
    symbol: "OURS",
    slug: "flying-colours",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flying-colours.png",
  },
  {
    name: "Olympic Doge",
    symbol: "OLYMPIC DOGE",
    slug: "olympic-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olympic-doge.png",
  },
  {
    name: "Baby Squid Games",
    symbol: "SQUIDS",
    slug: "baby-squid-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-squid-games.png",
  },
  {
    name: "Crypto Rocket Launch Plus",
    symbol: "RKT",
    slug: "crypto-rocket-launch-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-rocket-launch-plus.png",
  },
  {
    name: "DinoStep",
    symbol: "DNS",
    slug: "dinostep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinostep.png",
  },
  {
    name: "Netswap",
    symbol: "NETT",
    slug: "netswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netswap.png",
  },
  {
    name: "FlokiLoki",
    symbol: "FLOKILOKI",
    slug: "flokiloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokiloki.png",
  },
  {
    name: "PayGo",
    symbol: "PAYGO",
    slug: "paygo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paygo.png",
  },
  {
    name: "Woof Token",
    symbol: "WOOF",
    slug: "woof-token-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/woof-token-2.png",
  },
  {
    name: "ApeFarmer",
    symbol: "APEF",
    slug: "apefarmer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apefarmer.png",
  },
  {
    name: "Share NFT Token",
    symbol: "SNT",
    slug: "share-nft-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/share-nft-token.png",
  },
  {
    name: "Kyuubi",
    symbol: "$KYUB",
    slug: "kyuubi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kyuubi.png",
  },
  {
    name: "Mini Cat Coin",
    symbol: "MiniCat",
    slug: "mini-cat-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-cat-coin.png",
  },
  {
    name: "Chip",
    symbol: "CHIP",
    slug: "chip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chip.png",
  },
  {
    name: "Arabian City",
    symbol: "ACITY",
    slug: "arabian-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arabian-city.png",
  },
  {
    name: "Kingtama",
    symbol: "KINGTAMA",
    slug: "kingtama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingtama.png",
  },
  {
    name: "BABYOKX",
    symbol: "BABYOKX",
    slug: "babyokx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyokx.png",
  },
  {
    name: "Cryptoheadz",
    symbol: "HEADZ",
    slug: "cryptoheadz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoheadz.png",
  },
  {
    name: "Argo",
    symbol: "ARGO",
    slug: "argo-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/argo-2.png",
  },
  {
    name: "Hangout",
    symbol: "HOPO",
    slug: "hangout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hangout.png",
  },
  {
    name: "Damn Token",
    symbol: "DAMN",
    slug: "damn-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/damn-token.png",
  },
  {
    name: "Aura Finance",
    symbol: "AURA",
    slug: "aura-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aura-finance.png",
  },
  {
    name: "Joe-Yo Coin",
    symbol: "JYC",
    slug: "joe-yo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joe-yo-coin.png",
  },
  {
    name: "SoldiersLand",
    symbol: "SLD",
    slug: "soldiersland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soldiersland.png",
  },
  {
    name: "Arkania Protocol",
    symbol: "ANIA",
    slug: "arkania-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arkania-protocol.png",
  },
  {
    name: "RevolutionGames",
    symbol: "RVLNG",
    slug: "revolutiongames",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revolutiongames.png",
  },
  {
    name: "The Starship Finance",
    symbol: "BIP",
    slug: "the-starship-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-starship-finance.png",
  },
  {
    name: "AceStarter",
    symbol: "ASTAR",
    slug: "acestarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acestarter.png",
  },
  {
    name: "SWTCoin",
    symbol: "SWAT",
    slug: "swtcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swtcoin.png",
  },
  {
    name: "LegioDAO",
    symbol: "LGO",
    slug: "legiodao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legiodao.png",
  },
  {
    name: "Pondering Orb",
    symbol: "ORB",
    slug: "ponderingorb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ponderingorb.png",
  },
  {
    name: "8bit",
    symbol: "8BIT",
    slug: "8bit-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/8bit-nft.png",
  },
  {
    name: "One Pearl",
    symbol: "(Δ)",
    slug: "one-pearl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-pearl.png",
  },
  {
    name: "Leisure",
    symbol: "LIS",
    slug: "leisure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leisure.png",
  },
  {
    name: "CryptoHub",
    symbol: "CHG",
    slug: "cryptohub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptohub.png",
  },
  {
    name: "Netcoincapital",
    symbol: "NCC",
    slug: "netcoincapital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netcoincapital.png",
  },
  {
    name: "MetaCars",
    symbol: "MTC",
    slug: "metacars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metacars.png",
  },
  {
    name: "Tokel",
    symbol: "TKL",
    slug: "tokel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokel.png",
  },
  {
    name: "Gomb",
    symbol: "GOMB",
    slug: "gomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gomb.png",
  },
  {
    name: "Creator Cabins",
    symbol: "CABIN",
    slug: "creator-cabins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creator-cabins.png",
  },
  {
    name: "ElonHype",
    symbol: "ELONHYPE",
    slug: "elonhype",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elonhype.png",
  },
  {
    name: "Step C",
    symbol: "STC",
    slug: "step-c",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/step-c.png",
  },
  {
    name: "Alpha Pad",
    symbol: "APAD",
    slug: "alpha-pad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-pad.png",
  },
  {
    name: "Meowth Neko",
    symbol: "MEWN",
    slug: "meowth-neko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meowth-neko.png",
  },
  {
    name: "Paras",
    symbol: "PARAS",
    slug: "paras",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paras.png",
  },
  {
    name: "WEB3 DEV",
    symbol: "WEB3",
    slug: "web3-dev",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web3-dev.png",
  },
  {
    name: "Bro Token",
    symbol: "BRO",
    slug: "bro-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bro-token.png",
  },
  {
    name: "Mufasa Inu",
    symbol: "MFSA",
    slug: "mufasa-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mufasa-inu.png",
  },
  {
    name: "MoveCash",
    symbol: "MCA",
    slug: "movecash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/movecash.png",
  },
  {
    name: "PumpIT Launchpad",
    symbol: "$PUMPIT",
    slug: "pumpit-launchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pumpit-launchpad.png",
  },
  {
    name: "SheepASheep",
    symbol: "YLGY",
    slug: "sheepasheep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sheepasheep.png",
  },
  {
    name: "SHIBA CLASSIC",
    symbol: "SHIBAC",
    slug: "shiba-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-classic.png",
  },
  {
    name: "Clock 24",
    symbol: "C24",
    slug: "clock-24",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clock-24.png",
  },
  {
    name: "Lion Scrub Finance",
    symbol: "Lion",
    slug: "lion-scrub-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lion-scrub-finance.png",
  },
  {
    name: "Meerkat Shares",
    symbol: "MSHARE",
    slug: "meerkat-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meerkat-shares.png",
  },
  {
    name: "OracleCapital",
    symbol: "OC",
    slug: "oraclecapital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oraclecapital.png",
  },
  {
    name: "Phuture DeFi Index",
    symbol: "PDI",
    slug: "phuture-defi-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phuture-defi-index.png",
  },
  {
    name: "MultiversePad",
    symbol: "MTVP",
    slug: "multiversepad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multiversepad.png",
  },
  {
    name: "PegasusDollar",
    symbol: "PES",
    slug: "pegasusdollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pegasusdollar.png",
  },
  {
    name: "Farmers Only",
    symbol: "FOX",
    slug: "farmers-only",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmers-only.png",
  },
  {
    name: "MetaverseAir",
    symbol: "MVRS",
    slug: "metaverseair",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverseair.png",
  },
  {
    name: "Kalkicoin",
    symbol: "KLC",
    slug: "kalkicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalkicoin.png",
  },
  {
    name: "Vaquita",
    symbol: "VAT",
    slug: "vaquita",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vaquita.png",
  },
  {
    name: "BeWhale Finance",
    symbol: "WALE",
    slug: "bewhale-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bewhale-finance.png",
  },
  {
    name: "Betaverse",
    symbol: "BET",
    slug: "betaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betaverse.png",
  },
  {
    name: "Atlantis Finance",
    symbol: "ATLS",
    slug: "atlantis-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlantis-finance.png",
  },
  {
    name: "TokenBank",
    symbol: "TBANK",
    slug: "tokenbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenbank.png",
  },
  {
    name: "ShiPlay",
    symbol: "SPLY",
    slug: "shiplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiplay.png",
  },
  {
    name: "Doges Among Us",
    symbol: "DAU",
    slug: "doges-among-us",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doges-among-us.png",
  },
  {
    name: "SyncDAO Governance",
    symbol: "SDG",
    slug: "syncdao-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/syncdao-governance.png",
  },
  {
    name: "xMooney",
    symbol: "XM",
    slug: "xmooney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xmooney.png",
  },
  {
    name: "CryptoRunner",
    symbol: "RUN",
    slug: "cryptorunner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptorunner.png",
  },
  {
    name: "Charactbit",
    symbol: "CHB",
    slug: "charactbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charactbit.png",
  },
  {
    name: "JUMPN",
    symbol: "JST",
    slug: "jumpn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jumpn.png",
  },
  {
    name: "Metamall",
    symbol: "MALL",
    slug: "metamall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamall.png",
  },
  {
    name: "RiseHero",
    symbol: "RISE",
    slug: "risehero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/risehero.png",
  },
  {
    name: "Solium Rise",
    symbol: "SOLAR",
    slug: "solium-rise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solium-rise.png",
  },
  {
    name: "TheSolanDAO",
    symbol: "SDO",
    slug: "thesolandao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thesolandao.png",
  },
  {
    name: "Bone",
    symbol: "BONE",
    slug: "bone-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bone-2.png",
  },
  {
    name: "Wrapped Near",
    symbol: "WNEAR",
    slug: "wrapped-near",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-near.png",
  },
  {
    name: "Clytie",
    symbol: "CLY",
    slug: "clytie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clytie.png",
  },
  {
    name: "Paricle Technology",
    symbol: "PART",
    slug: "paricle-technology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paricle-technology.png",
  },
  {
    name: "Cherish",
    symbol: "CHC",
    slug: "cherish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cherish.png",
  },
  {
    name: "1SAFU",
    symbol: "SAFU",
    slug: "1safu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1safu.png",
  },
  {
    name: "Tip.Blue",
    symbol: "BLUE",
    slug: "tip-blue",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tip-blue.png",
  },
  {
    name: "GymMov",
    symbol: "GYM",
    slug: "gymmov",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gymmov.png",
  },
  {
    name: "Emit Water Element",
    symbol: "WATER",
    slug: "emit-water-element",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emit-water-element.png",
  },
  {
    name: "Inu Base",
    symbol: "INUB",
    slug: "inu-base",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inu-base.png",
  },
  {
    name: "Marosca Inu",
    symbol: "MAROSCA",
    slug: "marosca-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marosca-inu.png",
  },
  {
    name: "Alpha Petto Shells",
    symbol: "APES",
    slug: "alpha-petto-shells",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-petto-shells.png",
  },
  {
    name: "Mirrored AMC Entertainment Holdings Inc",
    symbol: "mAMC",
    slug: "mirrored-amc-entertainment",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-amc-entertainment.png",
  },
  {
    name: "Ikura Token",
    symbol: "IKURA",
    slug: "ikura-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ikura-token.png",
  },
  {
    name: "MerryXRPmas",
    symbol: "XMAS",
    slug: "merryxrpmas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merryxrpmas.png",
  },
  {
    name: "DogeKing Metaverse",
    symbol: "DogeKing",
    slug: "dogeking-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeking-metaverse.png",
  },
  {
    name: "HonorLand",
    symbol: "HONOR",
    slug: "honorland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honorland.png",
  },
  {
    name: "SkyBridger",
    symbol: "SKBR",
    slug: "skybridger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skybridger.png",
  },
  {
    name: "Artemis Vision",
    symbol: "ARV",
    slug: "artemis-vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artemis-vision.png",
  },
  {
    name: "LUNA DOGE TOKEN",
    symbol: "LDT",
    slug: "luna-doge-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luna-doge-token.png",
  },
  {
    name: "Infinity Arena",
    symbol: "INAZ",
    slug: "infinity-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-arena.png",
  },
  {
    name: "Shiba Heist",
    symbol: "HEIST",
    slug: "shiba-heist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-heist.png",
  },
  {
    name: "MMM7",
    symbol: "MMM7",
    slug: "mmm7",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mmm7.png",
  },
  {
    name: "ZOOM Protocol",
    symbol: "$ZOOM",
    slug: "zoompro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoompro.png",
  },
  {
    name: "Meta Farmer Finance",
    symbol: "MFF",
    slug: "meta-farmer-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-farmer-finance.png",
  },
  {
    name: "Mafia World Cup",
    symbol: "MWC",
    slug: "mafia-world-cup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mafia-world-cup.png",
  },
  {
    name: "Infinity DAO",
    symbol: "IND",
    slug: "infinity-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-dao.png",
  },
  {
    name: "WAMO",
    symbol: "WAMO",
    slug: "wamo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wamo.png",
  },
  {
    name: "GambleFi",
    symbol: "BETIFY",
    slug: "gamblefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamblefi.png",
  },
  {
    name: "Jurassic Token",
    symbol: "JRSC",
    slug: "jurassic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jurassic-token.png",
  },
  {
    name: "CROC BOY",
    symbol: "CROC",
    slug: "croc-boy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/croc-boy.png",
  },
  {
    name: "Gami Studio",
    symbol: "GAMI",
    slug: "gami",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gami.png",
  },
  {
    name: "FDoge Finance",
    symbol: "FDOGE",
    slug: "fdoge-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fdoge-finance.png",
  },
  {
    name: "Neco Fun",
    symbol: "NECO",
    slug: "neco-fun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neco-fun.png",
  },
  {
    name: "Shiba Universe",
    symbol: "SHIBU",
    slug: "shiba-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-universe.png",
  },
  {
    name: "McDoge",
    symbol: "MCDOGE",
    slug: "mcdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcdoge.png",
  },
  {
    name: "Adsense Token",
    symbol: "ADT",
    slug: "adsense-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adsense-token.png",
  },
  {
    name: "eSwapping v2",
    symbol: "ESWAPV2",
    slug: "eswapping-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eswapping-v2.png",
  },
  {
    name: "Fraktionalized THUG 2856",
    symbol: "THUG",
    slug: "fraktionalized-thug-2856",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fraktionalized-thug-2856.png",
  },
  {
    name: "Tuzlaspor Token",
    symbol: "TUZLA",
    slug: "tuzlaspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tuzlaspor-token.png",
  },
  {
    name: "Coin Edelweis",
    symbol: "EDEL",
    slug: "coin-edelweis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-edelweis.png",
  },
  {
    name: "Owloper Owl",
    symbol: "OWL",
    slug: "owloper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/owloper.png",
  },
  {
    name: "Phant",
    symbol: "PNT",
    slug: "phant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phant.png",
  },
  {
    name: "StarChainDoge",
    symbol: "STARCHAINDOGE",
    slug: "starchaindoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starchaindoge.png",
  },
  {
    name: "Fame Doge Coin",
    symbol: "FAMEDOGE",
    slug: "fame-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fame-doge-coin.png",
  },
  {
    name: "LaCucina",
    symbol: "LAC",
    slug: "lacucina",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lacucina.png",
  },
  {
    name: "Baby Billionaires Club",
    symbol: "3COMMAS",
    slug: "baby-billionaires-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-billionaires-club.png",
  },
  {
    name: "Poriverse",
    symbol: "RIKEN",
    slug: "poriverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poriverse.png",
  },
  {
    name: "MovingOn Finance",
    symbol: "MOVON",
    slug: "movingon-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/movingon-finance.png",
  },
  {
    name: "MMA Gaming",
    symbol: "MMA",
    slug: "mma-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mma-gaming.png",
  },
  {
    name: "Evil Shiba Inu",
    symbol: "ESI",
    slug: "evil-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evil-shiba-inu.png",
  },
  {
    name: "Son Of Safemoon",
    symbol: "SOF",
    slug: "son-of-safemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/son-of-safemoon.png",
  },
  {
    name: "ChampInu",
    symbol: "CHAMPINU",
    slug: "champinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/champinu.png",
  },
  {
    name: "Joystick",
    symbol: "JOY",
    slug: "joystick-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joystick-2.png",
  },
  {
    name: "BurnRocket",
    symbol: "BURNROCKET",
    slug: "burnrocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burnrocket.png",
  },
  {
    name: "SNAPN Go Out Now",
    symbol: "GON",
    slug: "snapn-go-out-now",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snapn-go-out-now.png",
  },
  {
    name: "CryptoCurrency Network",
    symbol: "CCN",
    slug: "cryptocurrencynetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptocurrencynetwork.png",
  },
  {
    name: "XERIUM",
    symbol: "XERM",
    slug: "xerium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xerium.png",
  },
  {
    name: "NXUSD",
    symbol: "NXUSD",
    slug: "nxusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nxusd.png",
  },
  {
    name: "Mirrored GameStop Corp",
    symbol: "mGME",
    slug: "mirrored-gamestop-corp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-gamestop-corp.png",
  },
  {
    name: "Jelly",
    symbol: "JELLY",
    slug: "jelly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jelly.png",
  },
  {
    name: "Crinet",
    symbol: "CNT",
    slug: "crinet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crinet.png",
  },
  {
    name: "Stream2Earn",
    symbol: "STREAMN",
    slug: "stream2earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stream2earn.png",
  },
  {
    name: "Solana Ecosystem Index",
    symbol: "SOLI",
    slug: "solana-ecosystem-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solana-ecosystem-index.png",
  },
  {
    name: "Nomadland",
    symbol: "NOMAD",
    slug: "nomadland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nomadland.png",
  },
  {
    name: "REDFRUNK",
    symbol: "RFRUNK",
    slug: "redfrunk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redfrunk.png",
  },
  {
    name: "eFin Decentralized",
    symbol: "WEFIN",
    slug: "efin-decentralized",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/efin-decentralized.png",
  },
  {
    name: "GNOME",
    symbol: "GNOME",
    slug: "gnome",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gnome.png",
  },
  {
    name: "Cryptoids Game Coin",
    symbol: "CGC",
    slug: "cryptoids-game-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoids-game-coin.png",
  },
  {
    name: "Fairy Forest NFT",
    symbol: "FFN",
    slug: "fairy-forest-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fairy-forest-nft.png",
  },
  {
    name: "MXGP Fan Token",
    symbol: "MXGP",
    slug: "mxgp-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mxgp-fan-token.png",
  },
  {
    name: "Paycer Protocol",
    symbol: "PCR",
    slug: "paycer-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paycer-protocol.png",
  },
  {
    name: "PepeVerse",
    symbol: "PEPEVR",
    slug: "pepeverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pepeverse.png",
  },
  {
    name: "Bird Bro",
    symbol: "BIRDBRO",
    slug: "bird-bro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bird-bro.png",
  },
  {
    name: "NiftyPays",
    symbol: "NIFTY",
    slug: "niftypays",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niftypays.png",
  },
  {
    name: "Uranium Finance",
    symbol: "URF",
    slug: "uranium-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uranium-finance.png",
  },
  {
    name: "Bingo Game",
    symbol: "BINGO",
    slug: "bingo-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bingo-game.png",
  },
  {
    name: "Safefloki",
    symbol: "SFK",
    slug: "safefloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safefloki.png",
  },
  {
    name: "Colossal Token",
    symbol: "CLSL",
    slug: "shiba-titian",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-titian.png",
  },
  {
    name: "DefiBet",
    symbol: "DBET",
    slug: "defibet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defibet.png",
  },
  {
    name: "Hummingbot",
    symbol: "HBOT",
    slug: "hummingbot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hummingbot.png",
  },
  {
    name: "Bami",
    symbol: "BAMI",
    slug: "bami",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bami.png",
  },
  {
    name: "Evident Proof Transaction Token",
    symbol: "EPTT",
    slug: "evident-proof-transaction-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evident-proof-transaction-token.png",
  },
  {
    name: "PAYZ PAYMENTS",
    symbol: "PAYZ",
    slug: "payz-payments",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payz-payments.png",
  },
  {
    name: "Lots Gaming",
    symbol: "LTSG",
    slug: "lots-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lots-gaming.png",
  },
  {
    name: "Ava MIM",
    symbol: "AVAMIM",
    slug: "ava-mim",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ava-mim.png",
  },
  {
    name: "DogeSino",
    symbol: "ĐOGESINO",
    slug: "dogesino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogesino.png",
  },
  {
    name: "Superciety",
    symbol: "SUPER",
    slug: "superciety",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superciety.png",
  },
  {
    name: "SHIBA TRON",
    symbol: "SHIBT",
    slug: "shiba-tron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-tron.png",
  },
  {
    name: "Joe Hat Token",
    symbol: "HAT",
    slug: "joe-hat-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joe-hat-token.png",
  },
  {
    name: "GreenBioX",
    symbol: "GREENBIOX",
    slug: "greenbiox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenbiox.png",
  },
  {
    name: "Tokenarium",
    symbol: "TKNRM",
    slug: "tokenarium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenarium.png",
  },
  {
    name: "HashRush",
    symbol: "RUSH",
    slug: "hashrush",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashrush.png",
  },
  {
    name: "İstanbul Wild Cats Fan Token",
    symbol: "IWFT",
    slug: "istanbul-wild-cats-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/istanbul-wild-cats-fan-token.png",
  },
  {
    name: "Multinode Finance",
    symbol: "MNODE",
    slug: "multinodefinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multinodefinance.png",
  },
  {
    name: "Concretecodes",
    symbol: "CONC",
    slug: "concretecodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/concretecodes.png",
  },
  {
    name: "The Garden",
    symbol: "TGC",
    slug: "the-garden",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-garden.png",
  },
  {
    name: "Bee Financial",
    symbol: "BEE",
    slug: "bee-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bee-financial.png",
  },
  {
    name: "Starcro",
    symbol: "XSC",
    slug: "starcro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starcro.png",
  },
  {
    name: "SOS Amazonia",
    symbol: "SOSAMZ",
    slug: "sos-amazonia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sos-amazonia.png",
  },
  {
    name: ".Alpha",
    symbol: ".ALPHA",
    slug: "alphatoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alphatoken.png",
  },
  {
    name: "For Meta World",
    symbol: "4MW",
    slug: "for-meta-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/for-meta-world.png",
  },
  {
    name: "Apedoge",
    symbol: "APED",
    slug: "apedoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apedoge.png",
  },
  {
    name: "TalentCoin",
    symbol: "TLNT",
    slug: "talentcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/talentcoin.png",
  },
  {
    name: "BABY TETHER",
    symbol: "BABYTETHER",
    slug: "baby-tether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-tether.png",
  },
  {
    name: "DNA(RXC)",
    symbol: "DNA",
    slug: "rxc-dna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rxc-dna.png",
  },
  {
    name: "Good Luck Token",
    symbol: "GLT",
    slug: "good-luck-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/good-luck-token.png",
  },
  {
    name: "NuCoin",
    symbol: "NUC",
    slug: "nucoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nucoin.png",
  },
  {
    name: "Bull Force Token",
    symbol: "BFT",
    slug: "bull-force-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bull-force-token.png",
  },
  {
    name: "Baby Musk Coin",
    symbol: "BABYMUSK",
    slug: "baby-musk-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-musk-coin.png",
  },
  {
    name: "ParaState",
    symbol: "STATE",
    slug: "parastate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parastate.png",
  },
  {
    name: "NFTGamingStars",
    symbol: "GS1",
    slug: "nftgamingstars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftgamingstars.png",
  },
  {
    name: "Fantasy Doge",
    symbol: "FTD",
    slug: "fantasy-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasy-doge.png",
  },
  {
    name: "Meta Age of Empires",
    symbol: "MAOE",
    slug: "meta-age-of-empires",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-age-of-empires.png",
  },
  {
    name: "Celestial Finance",
    symbol: "CELES",
    slug: "celestial-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celestial-finance.png",
  },
  {
    name: "EarthByt",
    symbol: "EBYT",
    slug: "earthbyt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earthbyt.png",
  },
  {
    name: "Void",
    symbol: "VOID",
    slug: "void-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/void-games.png",
  },
  {
    name: "Lyfe Silver",
    symbol: "LSILVER",
    slug: "lyfe-silver",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lyfe-silver.png",
  },
  {
    name: "Staked TAROT",
    symbol: "XTAROT",
    slug: "staked-tarot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/staked-tarot.png",
  },
  {
    name: "Web3 Game",
    symbol: "WEB3",
    slug: "web3-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web3-game.png",
  },
  {
    name: "MetaPool",
    symbol: "MPOOL",
    slug: "metapool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metapool.png",
  },
  {
    name: "Bork Inu",
    symbol: "BORK",
    slug: "bork-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bork-inu.png",
  },
  {
    name: "BitDiamond",
    symbol: "BTDMD",
    slug: "bitdiamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitdiamond.png",
  },
  {
    name: "Cycling App",
    symbol: "CYC",
    slug: "cycling-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cycling-app.png",
  },
  {
    name: "Green Blue Coin",
    symbol: "GBC",
    slug: "green-blue-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-blue-coin.png",
  },
  {
    name: "Smart Marketing Token",
    symbol: "SMT",
    slug: "smart-marketing-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-marketing-token.png",
  },
  {
    name: "SpaceSHIB",
    symbol: "SPACESHIB",
    slug: "spaceshib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spaceshib.png",
  },
  {
    name: "MetaMounts",
    symbol: "MOUNT",
    slug: "metamounts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamounts.png",
  },
  {
    name: "Tweet To Earn",
    symbol: "TTE",
    slug: "tweet-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tweet-to-earn.png",
  },
  {
    name: "ShibaElonVerse",
    symbol: "SHIBEV",
    slug: "shibaelonverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibaelonverse.png",
  },
  {
    name: "KAPEX Cryptocurrency Token",
    symbol: "KAPEX",
    slug: "kapex-cryptocurrency-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kapex-cryptocurrency-token.png",
  },
  {
    name: "MATRIX",
    symbol: "MTRX",
    slug: "matrix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrix.png",
  },
  {
    name: "Conqueror",
    symbol: "CONQ",
    slug: "conqueror",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/conqueror.png",
  },
  {
    name: "Mimas Finance",
    symbol: "MIMAS",
    slug: "mimas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mimas.png",
  },
  {
    name: "IQ Coin",
    symbol: "IQG",
    slug: "iq-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iq-coin.png",
  },
  {
    name: "Recycling CYC",
    symbol: "CYC",
    slug: "recycling-cyc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/recycling-cyc.png",
  },
  {
    name: "Rocket Race",
    symbol: "RR",
    slug: "defihorse-rocket-race",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defihorse-rocket-race.png",
  },
  {
    name: "Kingdomswap (New)",
    symbol: "KS2",
    slug: "kingdomswap-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdomswap-2.png",
  },
  {
    name: "Super Doge Bros",
    symbol: "DOGEBROS",
    slug: "super-doge-bros",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-doge-bros.png",
  },
  {
    name: "Hellbound Squid - The Game",
    symbol: "SQUIDBOUND",
    slug: "hellbound-squid-the-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hellbound-squid-the-game.png",
  },
  {
    name: "Baby Tiger GOLD",
    symbol: "BABYTIGER",
    slug: "baby-tiger-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-tiger-gold.png",
  },
  {
    name: "WinterFloki",
    symbol: "WINTERFLOKI",
    slug: "winterfloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winterfloki.png",
  },
  {
    name: "ECLAT",
    symbol: "ELT",
    slug: "eclat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eclat.png",
  },
  {
    name: "LifeTime",
    symbol: "LFT",
    slug: "lifetime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lifetime.png",
  },
  {
    name: "Space Pig Coin",
    symbol: "SPACEPIG",
    slug: "space-pig-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-pig-coin.png",
  },
  {
    name: "Galatic Kitty Fighters",
    symbol: "GKF",
    slug: "galatic-kitty-fighters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galatic-kitty-fighters.png",
  },
  {
    name: "BabyPitbull",
    symbol: "BPIT",
    slug: "babypitbull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babypitbull.png",
  },
  {
    name: "Alien Wars Gold",
    symbol: "AWG",
    slug: "alien-wars-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alien-wars-gold.png",
  },
  {
    name: "MegaShiboX Inu",
    symbol: "MHX",
    slug: "megashibox-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megashibox-inu.png",
  },
  {
    name: "FutureFi",
    symbol: "FUFI",
    slug: "futurefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futurefi.png",
  },
  {
    name: "Shengweihu",
    symbol: "SHENGWEIHU",
    slug: "shengweihu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shengweihu.png",
  },
  {
    name: "Dark Land Survival",
    symbol: "BIG",
    slug: "dark-land-survival",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dark-land-survival.png",
  },
  {
    name: "InfinityCash",
    symbol: "IFC",
    slug: "infinitycash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitycash.png",
  },
  {
    name: "PDSHARE",
    symbol: "PDSHARE",
    slug: "pdshare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pdshare.png",
  },
  {
    name: "Ballswapper Accelerator Reflection Token - BART",
    symbol: "BART",
    slug: "ballswapper-accelerator-reflection-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ballswapper-accelerator-reflection-token.png",
  },
  {
    name: "Relbit",
    symbol: "RLB",
    slug: "relbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/relbit.png",
  },
  {
    name: "Nolian Credits",
    symbol: "NLCR",
    slug: "nolian-credits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nolian-credits.png",
  },
  {
    name: "Rocket Finance",
    symbol: "ROCKET",
    slug: "rocket-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-finance.png",
  },
  {
    name: "Diamond Love",
    symbol: "LOVE",
    slug: "diamond-love",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-love.png",
  },
  {
    name: "SpyritCoin",
    symbol: "SPYRIT",
    slug: "spyritcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spyritcoin.png",
  },
  {
    name: "Xave Coin",
    symbol: "XVC",
    slug: "xave-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xave-coin.png",
  },
  {
    name: "UPFUND",
    symbol: "UPT",
    slug: "upfund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upfund.png",
  },
  {
    name: "Magic Elpis Gem",
    symbol: "MEG",
    slug: "magic-elpis-gem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-elpis-gem.png",
  },
  {
    name: "Meetin Token",
    symbol: "METI",
    slug: "meetin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meetin-token.png",
  },
  {
    name: "AnnihilationCE",
    symbol: "ANCE",
    slug: "annihilationce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/annihilationce.png",
  },
  {
    name: "CargoLink",
    symbol: "CLX",
    slug: "cargolink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cargolink.png",
  },
  {
    name: "Ancient Raid",
    symbol: "RAID",
    slug: "ancient-raid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ancient-raid.png",
  },
  {
    name: "Bitcoin City Coin",
    symbol: "BCITY",
    slug: "bitcoin-city-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-city-coin.png",
  },
  {
    name: "Eskimo Inu",
    symbol: "ESK",
    slug: "eskimo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eskimo-inu.png",
  },
  {
    name: "Fantasy Girl",
    symbol: "FMEV2",
    slug: "fantasy-girl-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasy-girl-v2.png",
  },
  {
    name: "Petaverse",
    symbol: "PETA",
    slug: "petaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petaverse.png",
  },
  {
    name: "Green Meta",
    symbol: "GMETA",
    slug: "green-beli-gmeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-beli-gmeta.png",
  },
  {
    name: "Metra",
    symbol: "METRA",
    slug: "metra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metra.png",
  },
  {
    name: "GombShare",
    symbol: "GSHARE",
    slug: "gombshare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gombshare.png",
  },
  {
    name: "TerraFloki",
    symbol: "TFLOKI",
    slug: "terrafloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terrafloki.png",
  },
  {
    name: "Elfworld",
    symbol: "ELFT",
    slug: "elfworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elfworld.png",
  },
  {
    name: "BLOOM  Protocol",
    symbol: "BLOOM",
    slug: "bloom-token-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bloom-token-protocol.png",
  },
  {
    name: "The Land Of Strife",
    symbol: "LOS",
    slug: "the-land-of-strife",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-land-of-strife.png",
  },
  {
    name: "DAO Farmer DFG",
    symbol: "DFG",
    slug: "dao-farmer-dfg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dao-farmer-dfg.png",
  },
  {
    name: "Diamond DND",
    symbol: "DND",
    slug: "diamond-dnd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-dnd.png",
  },
  {
    name: "GenesisDefi",
    symbol: "GENF",
    slug: "genesisdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesisdefi.png",
  },
  {
    name: "PiConnect",
    symbol: "PICO",
    slug: "piconnect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piconnect.png",
  },
  {
    name: "BNB CHAIN ALL BEST ICO",
    symbol: "BNBALLBI",
    slug: "bnb-chain-all-best-ico",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnb-chain-all-best-ico.png",
  },
  {
    name: "4Bulls",
    symbol: "4B",
    slug: "4bulls",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/4bulls.png",
  },
  {
    name: "MicroCreditToken",
    symbol: "1MCT",
    slug: "microcredittoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microcredittoken.png",
  },
  {
    name: "AvaxDAO",
    symbol: "AVAXD",
    slug: "avaxdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaxdao.png",
  },
  {
    name: "JetSwap pWings",
    symbol: "pWINGS",
    slug: "jetswap-pwings",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jetswap-pwings.png",
  },
  {
    name: "CryptoNeur Network foundation",
    symbol: "CNF",
    slug: "cryptoneur-network-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoneur-network-foundation.png",
  },
  {
    name: "Shibana",
    symbol: "BANA",
    slug: "shibana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibana.png",
  },
  {
    name: "Mollector",
    symbol: "MOL",
    slug: "mollector",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mollector.png",
  },
  {
    name: "Wrapped Bitcoin Cash",
    symbol: "WBCH",
    slug: "wrapped-bitcoin-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-bitcoin-cash.png",
  },
  {
    name: "HDDcoin",
    symbol: "HDD",
    slug: "hddcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hddcoin.png",
  },
  {
    name: "Fitness Instructor",
    symbol: "FITI",
    slug: "fitness-instructor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fitness-instructor.png",
  },
  {
    name: "HEXAGON Pay",
    symbol: "HXP",
    slug: "hexagon-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hexagon-pay.png",
  },
  {
    name: "Starstream",
    symbol: "STARS",
    slug: "starstream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starstream.png",
  },
  {
    name: "SIF Token",
    symbol: "SIF",
    slug: "sif",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sif.png",
  },
  {
    name: "BUZZ",
    symbol: "BUZZ",
    slug: "buzz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buzz.png",
  },
  {
    name: "SleepEarn Finance",
    symbol: "SEN",
    slug: "sleepearn-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sleepearn-finance.png",
  },
  {
    name: "Only Gems Finance",
    symbol: "OGEM",
    slug: "only-gems-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/only-gems-finance.png",
  },
  {
    name: "Centurion Inu",
    symbol: "CENT",
    slug: "centurion-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centurion-inu.png",
  },
  {
    name: "Dante Finance",
    symbol: "DANTE",
    slug: "dante-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dante-finance.png",
  },
  {
    name: "Stable FOX",
    symbol: "ONEFOX",
    slug: "stable-fox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stable-fox.png",
  },
  {
    name: "TITS",
    symbol: "TITS",
    slug: "tits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tits.png",
  },
  {
    name: "BabyAETH",
    symbol: "$BAETH",
    slug: "babyaeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyaeth.png",
  },
  {
    name: "SIGNIN",
    symbol: "SIGNIN",
    slug: "signin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/signin.png",
  },
  {
    name: "Metagamble",
    symbol: "GAMBL",
    slug: "metagamble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagamble.png",
  },
  {
    name: "Shiryo-Inu",
    symbol: "SHIRYO-INU",
    slug: "shiryo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiryo-inu.png",
  },
  {
    name: "ArbaTycoon",
    symbol: "AT",
    slug: "arabtycoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arabtycoon.png",
  },
  {
    name: "No Fud Token",
    symbol: "NOFUD",
    slug: "no-fud-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/no-fud-token.png",
  },
  {
    name: "Epsilon",
    symbol: "EPS",
    slug: "epsilon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/epsilon.png",
  },
  {
    name: "Leafty",
    symbol: "LEAFTY",
    slug: "leafty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leafty.png",
  },
  {
    name: "Chihiro Inu",
    symbol: "CHIRO",
    slug: "chihiro-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chihiro-inu.png",
  },
  {
    name: "Bamboonium",
    symbol: "BAMB",
    slug: "bamboonium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bamboonium.png",
  },
  {
    name: "DaddyBabyDoge",
    symbol: "DBDOGE",
    slug: "daddybabydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddybabydoge.png",
  },
  {
    name: "NFT ROYAL TOKEN",
    symbol: "NRT",
    slug: "nft-royal-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-royal-token.png",
  },
  {
    name: "eGAME Initiative",
    symbol: "EGI",
    slug: "egame-initiative",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egame-initiative.png",
  },
  {
    name: "TrustUSD",
    symbol: "TRUSD",
    slug: "trustusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustusd.png",
  },
  {
    name: "BABY WHITE HAMSTER",
    symbol: "BWH",
    slug: "baby-white-hamster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-white-hamster.png",
  },
  {
    name: "First Inu",
    symbol: "FINU",
    slug: "first-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/first-inu.png",
  },
  {
    name: "xPAD",
    symbol: "XPAD",
    slug: "xpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xpad.png",
  },
  {
    name: "FishCoin",
    symbol: "FISH",
    slug: "fishcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fishcoin.png",
  },
  {
    name: "Cloud9BSC.finance",
    symbol: "CLOUD9",
    slug: "cloud9bsc-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cloud9bsc-finance.png",
  },
  {
    name: "FSD",
    symbol: "FSDCOIN",
    slug: "fsd-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fsd-coin.png",
  },
  {
    name: "Pumpkin Punks",
    symbol: "PPUNKS",
    slug: "pumpkin-punks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pumpkin-punks.png",
  },
  {
    name: "Rogue Doge",
    symbol: "ROGE",
    slug: "rogue-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rogue-doge.png",
  },
  {
    name: "Shibboo Inu",
    symbol: "SHIBBOO",
    slug: "shibboo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibboo-inu.png",
  },
  {
    name: "Potter Inu",
    symbol: "POTTERINU",
    slug: "potter-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/potter-inu.png",
  },
  {
    name: "Merit Circle",
    symbol: "MC",
    slug: "merit-circle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merit-circle.png",
  },
  {
    name: "PussyCat",
    symbol: "PUSS",
    slug: "pussycat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pussycat.png",
  },
  {
    name: "SingMe",
    symbol: "SINGME",
    slug: "singme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/singme.png",
  },
  {
    name: "Town Star",
    symbol: "TOWN",
    slug: "town-star",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/town-star.png",
  },
  {
    name: "Pet Alliance",
    symbol: "PAL",
    slug: "pet-alliance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pet-alliance.png",
  },
  {
    name: "BRICK Token",
    symbol: "BRICK",
    slug: "brick-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brick-token.png",
  },
  {
    name: "SHIWBAWITCH TOKEN",
    symbol: "SHIBAWITCH",
    slug: "shiwbawitch-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiwbawitch-token.png",
  },
  {
    name: "Baby Alucard",
    symbol: "ALUCARD",
    slug: "baby-alucard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-alucard.png",
  },
  {
    name: "Fantasy War",
    symbol: "FAWA",
    slug: "fantasy-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasy-war.png",
  },
  {
    name: "Aang Inu",
    symbol: "AANG",
    slug: "aang-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aang-inu.png",
  },
  {
    name: "RemeX",
    symbol: "RMX",
    slug: "remex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/remex.png",
  },
  {
    name: "NFTL",
    symbol: "NFTL",
    slug: "nftl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftl.png",
  },
  {
    name: "Web2 Inu",
    symbol: "WEB2",
    slug: "web2-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web2-inu.png",
  },
  {
    name: "AntGold",
    symbol: "ANTG",
    slug: "antgold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antgold.png",
  },
  {
    name: "Star Pacific Coin",
    symbol: "SPC",
    slug: "star-pacific-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/star-pacific-coin.png",
  },
  {
    name: "Aureus Token",
    symbol: "AUREUSRH",
    slug: "aureus-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aureus-token.png",
  },
  {
    name: "Plant Exodus",
    symbol: "PEXO",
    slug: "plant-exodus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plant-exodus.png",
  },
  {
    name: "DollarBack",
    symbol: "BACK",
    slug: "dollarback",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dollarback.png",
  },
  {
    name: "GEODNET",
    symbol: "GEOD",
    slug: "geodnet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geodnet.png",
  },
  {
    name: "NFT Crosschain",
    symbol: "CRC",
    slug: "nft-crosschain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-crosschain.png",
  },
  {
    name: "FamilyParty",
    symbol: "FPC",
    slug: "familyparty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/familyparty.png",
  },
  {
    name: "Reflecto USD",
    symbol: "RUSD",
    slug: "reflecto-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reflecto-usd.png",
  },
  {
    name: "Web3 ALL BEST ICO",
    symbol: "WEB3ALLBI",
    slug: "web3-all-best-ico",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web3-all-best-ico.png",
  },
  {
    name: "SocialsFi",
    symbol: "SCFL",
    slug: "socialsfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/socialsfi.png",
  },
  {
    name: "Chain",
    symbol: "CHN",
    slug: "chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain.png",
  },
  {
    name: "Ticket Finance",
    symbol: "TICKET",
    slug: "ticket-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ticket-finance.png",
  },
  {
    name: "ROCKET GLOBAL",
    symbol: "RCKC",
    slug: "rocket-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-global.png",
  },
  {
    name: "OLOID",
    symbol: "OLOID",
    slug: "oloid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oloid.png",
  },
  {
    name: "Cresio",
    symbol: "XCRE",
    slug: "cresio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cresio.png",
  },
  {
    name: "IDLE Token",
    symbol: "IDLE",
    slug: "idle-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idle-token.png",
  },
  {
    name: "Protector Roge",
    symbol: "PROGE",
    slug: "protector-roge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/protector-roge.png",
  },
  {
    name: "GNAR TOKEN",
    symbol: "GNAR",
    slug: "gnar-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gnar-token.png",
  },
  {
    name: "Bridgesplit Brand Index",
    symbol: "BBI",
    slug: "bridgesplit-brand-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bridgesplit-brand-index.png",
  },
  {
    name: "Vase Token",
    symbol: "VASE",
    slug: "vase-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vase-token.png",
  },
  {
    name: "Stream Smart Business",
    symbol: "SSB",
    slug: "stream-smart-business",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stream-smart-business.png",
  },
  {
    name: "RingFi",
    symbol: "RING",
    slug: "ring",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ring.png",
  },
  {
    name: "milestoneBased",
    symbol: "MILE",
    slug: "milestonebased",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milestonebased.png",
  },
  {
    name: "KeySwap",
    symbol: "KEYSWAP",
    slug: "keyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keyswap.png",
  },
  {
    name: "Utopia (utility Token)",
    symbol: "UTO",
    slug: "utopia-utility-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utopia-utility-token.png",
  },
  {
    name: "MetaFishing",
    symbol: "DGC",
    slug: "metafishing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafishing.png",
  },
  {
    name: "Bulldog Billionaires",
    symbol: "BONE",
    slug: "bulldog-billionaires",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulldog-billionaires.png",
  },
  {
    name: "EarnX V2",
    symbol: "EARNX",
    slug: "earnx-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnx-v2.png",
  },
  {
    name: "Tiger shares",
    symbol: "Tiger",
    slug: "tiger-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiger-shares.png",
  },
  {
    name: "Janus Network",
    symbol: "JNS",
    slug: "janus-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/janus-network.png",
  },
  {
    name: "DAO Farmer DFM",
    symbol: "DFM",
    slug: "dao-farmer-dfm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dao-farmer-dfm.png",
  },
  {
    name: "YieldLock",
    symbol: "YLF",
    slug: "yieldlock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yieldlock.png",
  },
  {
    name: "ILUS Coin",
    symbol: "ILUS",
    slug: "ilus-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ilus-coin.png",
  },
  {
    name: "GemMine",
    symbol: "GEMMINE",
    slug: "gemmine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gemmine.png",
  },
  {
    name: "The Bend",
    symbol: "BEND",
    slug: "the-bend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-bend.png",
  },
  {
    name: "Dejitaru Kaida",
    symbol: "KAIDA",
    slug: "dejitaru-kaida",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dejitaru-kaida.png",
  },
  {
    name: "ChorusX",
    symbol: "CX1",
    slug: "chorusx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chorusx.png",
  },
  {
    name: "Celestial Unity",
    symbol: "CU",
    slug: "celestial-unity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celestial-unity.png",
  },
  {
    name: "altfolio",
    symbol: "ALT",
    slug: "altfolio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altfolio.png",
  },
  {
    name: "Super Cat Coin",
    symbol: "SuperCat",
    slug: "super-cat-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-cat-coin.png",
  },
  {
    name: "SafuTitano",
    symbol: "SAFTI",
    slug: "safutitano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safutitano.png",
  },
  {
    name: "ABEYCHAIN",
    symbol: "ABEY",
    slug: "abeychain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abeychain.png",
  },
  {
    name: "Bitcoin Asset",
    symbol: "BTA",
    slug: "bitcoin-asset-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-asset-new.png",
  },
  {
    name: "Crown Sovereign",
    symbol: "CSOV",
    slug: "crown-sovereign",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crown-sovereign.png",
  },
  {
    name: "MagicofGold",
    symbol: "MAGIC",
    slug: "magicofgold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magicofgold.png",
  },
  {
    name: "Valor",
    symbol: "V$",
    slug: "valor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valor.png",
  },
  {
    name: "SafeNotMoon",
    symbol: "$SNM",
    slug: "safenotmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safenotmoon.png",
  },
  {
    name: "Secretworld",
    symbol: "SSD",
    slug: "secretworld-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secretworld-network.png",
  },
  {
    name: "MoonBeans",
    symbol: "BEANS",
    slug: "moonbeans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonbeans.png",
  },
  {
    name: "FLOV MARKET",
    symbol: "FLOVM",
    slug: "flov-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flov-market.png",
  },
  {
    name: "Torchbearer",
    symbol: "TBR",
    slug: "torchbearer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/torchbearer.png",
  },
  {
    name: "TrustKeys Network",
    symbol: "TRUSTK",
    slug: "trustkeys-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustkeys-network.png",
  },
  {
    name: "Kittens & Puppies",
    symbol: "KAP",
    slug: "kittens-puppies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kittens-puppies.png",
  },
  {
    name: "CoinBunchie",
    symbol: "BUNCHIE",
    slug: "coinbunchie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinbunchie.png",
  },
  {
    name: "S-ONE Finance",
    symbol: "SONE",
    slug: "sone-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sone-finance.png",
  },
  {
    name: "wShiba",
    symbol: "WSHIB",
    slug: "wshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wshiba.png",
  },
  {
    name: "Osmosis",
    symbol: "OSMO",
    slug: "osmosis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/osmosis.png",
  },
  {
    name: "Lonelyfans (NEW)",
    symbol: "LOF",
    slug: "lonelyfans-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lonelyfans-new.png",
  },
  {
    name: "Hype Club",
    symbol: "HYPE",
    slug: "hype-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hype-club.png",
  },
  {
    name: "Wasdaq Finance",
    symbol: "WSDQ",
    slug: "wasdaq-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wasdaq-finance.png",
  },
  {
    name: "Piggy Finance",
    symbol: "PIGGY",
    slug: "piggy-finance-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piggy-finance-io.png",
  },
  {
    name: "Whalmart",
    symbol: "WMART",
    slug: "whalmart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whalmart.png",
  },
  {
    name: "Ezillion",
    symbol: "EZI",
    slug: "ezillion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ezillion.png",
  },
  {
    name: "Luxury Club",
    symbol: "LUX",
    slug: "luxury-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luxury-club.png",
  },
  {
    name: "ShinFloki",
    symbol: "SHINFLOKI",
    slug: "shinfloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinfloki.png",
  },
  {
    name: "MessiahDoge",
    symbol: "MESSDOGE",
    slug: "messiahdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/messiahdoge.png",
  },
  {
    name: "YieldZard",
    symbol: "YLZ",
    slug: "yieldzard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yieldzard.png",
  },
  {
    name: "STIPS",
    symbol: "STIPS",
    slug: "stips",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stips.png",
  },
  {
    name: "Bitberry Token",
    symbol: "BBR",
    slug: "bitberry-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitberry-token.png",
  },
  {
    name: "Overmoon",
    symbol: "OVM",
    slug: "overmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/overmoon.png",
  },
  {
    name: "GigaChad",
    symbol: "CHAD",
    slug: "gigachad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gigachad.png",
  },
  {
    name: "THTF",
    symbol: "THTF",
    slug: "thtf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thtf.png",
  },
  {
    name: "SHiBGOTCHi",
    symbol: "SHIBGOTCHI",
    slug: "shibgotchi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibgotchi.png",
  },
  {
    name: "CondorChain",
    symbol: "CDR",
    slug: "condorchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/condorchain.png",
  },
  {
    name: "PAW",
    symbol: "PAW",
    slug: "paw",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paw.png",
  },
  {
    name: "Galaxy",
    symbol: "GLXY",
    slug: "galaxy-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-dao.png",
  },
  {
    name: "Lithium Ventures",
    symbol: "IONS",
    slug: "lithium-ventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lithium-ventures.png",
  },
  {
    name: "Vagabond",
    symbol: "VGO",
    slug: "vagabond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vagabond.png",
  },
  {
    name: "Burn To Earn",
    symbol: "BTE",
    slug: "burn-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burn-to-earn.png",
  },
  {
    name: "Regen Network",
    symbol: "REGEN",
    slug: "regen-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/regen-network.png",
  },
  {
    name: "Realms of Ethernity",
    symbol: "RETH",
    slug: "realms-of-ethernity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realms-of-ethernity.png",
  },
  {
    name: "fBomb",
    symbol: "BOMB",
    slug: "fbomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fbomb.png",
  },
  {
    name: "4INT",
    symbol: "4INT",
    slug: "4int",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/4int.png",
  },
  {
    name: "SatoshiCity",
    symbol: "$CITY",
    slug: "satoshicity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoshicity.png",
  },
  {
    name: "Bloktopia",
    symbol: "BLOK",
    slug: "bloktopia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bloktopia.png",
  },
  {
    name: "SquidKing",
    symbol: "SQUIDKING",
    slug: "squidking",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squidking.png",
  },
  {
    name: "Morality",
    symbol: "MO",
    slug: "morality",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morality.png",
  },
  {
    name: "Frost",
    symbol: "FROST",
    slug: "frost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frost.png",
  },
  {
    name: "KodexPay",
    symbol: "KXP",
    slug: "kodexpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kodexpay.png",
  },
  {
    name: "DEEPMAZE Finance",
    symbol: "DPZ",
    slug: "deepmaze-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deepmaze-finance.png",
  },
  {
    name: "CRYPTO BOMBER",
    symbol: "CBOMBER",
    slug: "crypto-bomber",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-bomber.png",
  },
  {
    name: "BB Gaming",
    symbol: "BB",
    slug: "bb-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bb-gaming.png",
  },
  {
    name: "CAGE GOVERNANCE TOKEN",
    symbol: "CGT",
    slug: "cage-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cage-io.png",
  },
  {
    name: "Moshiheads",
    symbol: "MOSHI",
    slug: "moshiheads",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moshiheads.png",
  },
  {
    name: "Stripto",
    symbol: "STRIP",
    slug: "stripto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stripto.png",
  },
  {
    name: "Cryptounit",
    symbol: "CRU",
    slug: "cryptounit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptounit.png",
  },
  {
    name: "Hibiki Finance Cronos",
    symbol: "CHIBIKI",
    slug: "hibiki-finance-cronos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hibiki-finance-cronos.png",
  },
  {
    name: "TOYO",
    symbol: "TOYO",
    slug: "toyo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toyo.png",
  },
  {
    name: "Luck2Earn",
    symbol: "LUCK",
    slug: "luck2earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luck2earn.png",
  },
  {
    name: "Europa",
    symbol: "ORBIT",
    slug: "europa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/europa.png",
  },
  {
    name: "BabyLoserCoin",
    symbol: "BABYLOWB",
    slug: "babylosercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babylosercoin.png",
  },
  {
    name: "RMRK",
    symbol: "RMRK",
    slug: "rmrk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rmrk.png",
  },
  {
    name: "MetaFashioners",
    symbol: "MFS",
    slug: "metafashioners",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafashioners.png",
  },
  {
    name: "LYFE GOLD",
    symbol: "LGOLD",
    slug: "lyfe-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lyfe-gold.png",
  },
  {
    name: "Deimos Token",
    symbol: "DIO",
    slug: "deimos-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deimos-token.png",
  },
  {
    name: "Vancat",
    symbol: "VANCAT",
    slug: "vancat-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vancat-v2.png",
  },
  {
    name: "Seismic",
    symbol: "SCB",
    slug: "seismic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seismic.png",
  },
  {
    name: "Gale Network",
    symbol: "GALE",
    slug: "gale-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gale-network.png",
  },
  {
    name: "Again Project",
    symbol: "AGAIN",
    slug: "again-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/again-project.png",
  },
  {
    name: "Poly-Peg Mdex",
    symbol: "HMDX",
    slug: "poly-peg-mdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poly-peg-mdex.png",
  },
  {
    name: "Fantom Libero Financial",
    symbol: "FLIBERO",
    slug: "fantom-libero-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantom-libero-financial.png",
  },
  {
    name: "Warriors Land Fuel",
    symbol: "WLF",
    slug: "warriors-land-fuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/warriors-land-fuel.png",
  },
  {
    name: "Chaincorn",
    symbol: "CORNX",
    slug: "chaincorn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chaincorn.png",
  },
  {
    name: "MetaDoctor",
    symbol: "MEDOC",
    slug: "metadoctor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadoctor.png",
  },
  {
    name: "Inflation Adjusted USDS",
    symbol: "IUSDS",
    slug: "inflation-adjusted-usds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inflation-adjusted-usds.png",
  },
  {
    name: "Bitcoin Classic",
    symbol: "BGH",
    slug: "bitcoin-classic-bgh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-classic-bgh.png",
  },
  {
    name: "Zenc Coin",
    symbol: "ZENC",
    slug: "zenc-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenc-coin.png",
  },
  {
    name: "Moremoney USD",
    symbol: "MONEY",
    slug: "moremoney-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moremoney-usd.png",
  },
  {
    name: "AstroShit",
    symbol: "ASHIT",
    slug: "astroshit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astroshit.png",
  },
  {
    name: "Struggle Doge",
    symbol: "SGDOGE",
    slug: "struggle-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/struggle-doge.png",
  },
  {
    name: "PDDOLLAR",
    symbol: "PDD",
    slug: "pddollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pddollar.png",
  },
  {
    name: "MYTE",
    symbol: "MYTE",
    slug: "myte",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myte.png",
  },
  {
    name: "Treepto",
    symbol: "TPO",
    slug: "treepto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treepto.png",
  },
  {
    name: "MOJITO",
    symbol: "MOJITO",
    slug: "mojito",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mojito.png",
  },
  {
    name: "CobraGoose",
    symbol: "CBG",
    slug: "cobragoose",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cobragoose.png",
  },
  {
    name: "Winry Inu",
    symbol: "WINRY",
    slug: "winry-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winry-inu.png",
  },
  {
    name: "PELE Network",
    symbol: "PELE",
    slug: "pele-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pele-network.png",
  },
  {
    name: "FLAPPY FLOKI",
    symbol: "FLAFLO",
    slug: "flappy-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flappy-floki.png",
  },
  {
    name: "Sparda Wallet",
    symbol: "SPW",
    slug: "sparda-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sparda-wallet.png",
  },
  {
    name: "ZJLT Distributed Factoring Network",
    symbol: "ZJLT",
    slug: "zjlt-distributed-factoring-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zjlt-distributed-factoring-network.png",
  },
  {
    name: "Poof.cash",
    symbol: "POOF",
    slug: "poof-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poof-cash.png",
  },
  {
    name: "PocketRocket",
    symbol: "POCROC",
    slug: "pocketrocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocketrocket.png",
  },
  {
    name: "Symmetric",
    symbol: "SYMM",
    slug: "symmetric",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/symmetric.png",
  },
  {
    name: "XR Shiba Inu",
    symbol: "XRSHIB",
    slug: "xr-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xr-shiba-inu.png",
  },
  {
    name: "Athens",
    symbol: "ATH",
    slug: "athens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/athens.png",
  },
  {
    name: "Micro Santa Coin",
    symbol: "MICROSANTA",
    slug: "micro-santa-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/micro-santa-coin.png",
  },
  {
    name: "Bountie Hunter",
    symbol: "BOUNTIE",
    slug: "bountie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bountie.png",
  },
  {
    name: "Moonseer",
    symbol: "MOON",
    slug: "moonseer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonseer.png",
  },
  {
    name: "Vitall Markets",
    symbol: "VITAL",
    slug: "vitall-markets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vitall-markets.png",
  },
  {
    name: "Solana Fren",
    symbol: "FREN",
    slug: "solana-fren",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solana-fren.png",
  },
  {
    name: "Crypto Champ",
    symbol: "CHMP",
    slug: "champ-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/champ-eth.png",
  },
  {
    name: "Belka",
    symbol: "BLK",
    slug: "belka",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/belka.png",
  },
  {
    name: "MarbleVerse",
    symbol: "RLM",
    slug: "marbleverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marbleverse.png",
  },
  {
    name: "Decentralized Universal Basic Income",
    symbol: "DUBI",
    slug: "decentralized-universal-basic-income",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-universal-basic-income.png",
  },
  {
    name: "PDBC Defichain",
    symbol: "DPDBC",
    slug: "pdbc-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pdbc-defichain.png",
  },
  {
    name: "Spice Trade",
    symbol: "SPICE",
    slug: "spice-trade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spice-trade.png",
  },
  {
    name: "ShibaSpaceToken",
    symbol: "SST",
    slug: "shibaspacetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibaspacetoken.png",
  },
  {
    name: "ZilWall",
    symbol: "ZWALL",
    slug: "zilwall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilwall.png",
  },
  {
    name: "Candy",
    symbol: "Candy",
    slug: "candybsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/candybsc.png",
  },
  {
    name: "DuckDuck",
    symbol: "DUCK",
    slug: "duckduck",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duckduck.png",
  },
  {
    name: "MemeBank",
    symbol: "MBK",
    slug: "memebank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memebank.png",
  },
  {
    name: "ZilWall Paint",
    symbol: "ZPAINT",
    slug: "zilwall-paint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilwall-paint.png",
  },
  {
    name: "Carbon",
    symbol: "CARB",
    slug: "carb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carb.png",
  },
  {
    name: "ZilStream",
    symbol: "STREAM",
    slug: "zilstream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilstream.png",
  },
  {
    name: "UNIFEES",
    symbol: "FEES",
    slug: "unifees",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unifees.png",
  },
  {
    name: "OKTPlay",
    symbol: "OKTP",
    slug: "oktplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oktplay.png",
  },
  {
    name: "Moss Governance Token",
    symbol: "MOSS",
    slug: "moss-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moss-governance-token.png",
  },
  {
    name: "pSTAKE Staked ATOM",
    symbol: "STKATOM",
    slug: "pstake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pstake.png",
  },
  {
    name: "Cougar",
    symbol: "CGS",
    slug: "cougar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cougar.png",
  },
  {
    name: "Son of Doge",
    symbol: "SOD",
    slug: "son-of-doge-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/son-of-doge-new.png",
  },
  {
    name: "CROSSY ANIMALS",
    symbol: "CA",
    slug: "crossy-animals",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crossy-animals.png",
  },
  {
    name: "Saja",
    symbol: "SAJA",
    slug: "saja",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saja.png",
  },
  {
    name: "CortexDAO",
    symbol: "CXD",
    slug: "cortexdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cortexdao.png",
  },
  {
    name: "Escape from the Matrix",
    symbol: "REDPILL",
    slug: "escape-from-the-matrix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/escape-from-the-matrix.png",
  },
  {
    name: "Solarbeam",
    symbol: "SOLAR",
    slug: "solarbeam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solarbeam.png",
  },
  {
    name: "PeeCoin Charts",
    symbol: "PEECOIN",
    slug: "peecoin-charts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peecoin-charts.png",
  },
  {
    name: "Magic",
    symbol: "MAGIC",
    slug: "magic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic.png",
  },
  {
    name: "MetaUFO",
    symbol: "METAUFO",
    slug: "metaufo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaufo.png",
  },
  {
    name: "zilSurvey",
    symbol: "SRV",
    slug: "zilsurvey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilsurvey.png",
  },
  {
    name: "Deblox",
    symbol: "DGS",
    slug: "deblox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deblox.png",
  },
  {
    name: "Dragon Fortune",
    symbol: "DF",
    slug: "dragon-fortune",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-fortune.png",
  },
  {
    name: "Kuro Shiba",
    symbol: "KURO",
    slug: "kuro-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuro-shiba.png",
  },
  {
    name: "Matic Launchpad",
    symbol: "MATICPAD",
    slug: "matic-launchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matic-launchpad.png",
  },
  {
    name: "Haino",
    symbol: "HE",
    slug: "haino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/haino.png",
  },
  {
    name: "INS3.Finance",
    symbol: "ITFX",
    slug: "ins3-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ins3-finance.png",
  },
  {
    name: "Limbo Token",
    symbol: "LNFS",
    slug: "limbo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/limbo-token.png",
  },
  {
    name: "Signata DAO",
    symbol: "DSATA",
    slug: "signata-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/signata-dao.png",
  },
  {
    name: "DIGINU",
    symbol: "DIGINU",
    slug: "diginu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diginu.png",
  },
  {
    name: "Bounty",
    symbol: "BNTY",
    slug: "bounty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bounty.png",
  },
  {
    name: "RUG BIDEN",
    symbol: "RUGBID",
    slug: "rug-biden",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rug-biden.png",
  },
  {
    name: "MetaToll",
    symbol: "TAX",
    slug: "metatoll",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metatoll.png",
  },
  {
    name: "GOMA Finance",
    symbol: "GOMA",
    slug: "goma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goma.png",
  },
  {
    name: "KRAKENPAD",
    symbol: "KRP",
    slug: "krakenpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krakenpad.png",
  },
  {
    name: "Chimp Fight",
    symbol: "NANA",
    slug: "chimp-fight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chimp-fight.png",
  },
  {
    name: "Clash of Gods",
    symbol: "ATHEN",
    slug: "clash-of-gods",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clash-of-gods.png",
  },
  {
    name: "Cow Inu - CI",
    symbol: "CI",
    slug: "cow-inu---ci",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cow-inu---ci.png",
  },
  {
    name: "Metaburst",
    symbol: "MEBU",
    slug: "metaburst",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaburst.png",
  },
  {
    name: "RadioLife",
    symbol: "RDL",
    slug: "radiolife",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radiolife.png",
  },
  {
    name: "HashBit BlockChain",
    symbol: "HBIT",
    slug: "hashbit-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashbit-blockchain.png",
  },
  {
    name: "Baby Aetherius",
    symbol: "BABYAETH",
    slug: "baby-aetherius",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-aetherius.png",
  },
  {
    name: "Defilancer token",
    symbol: "DEFILANCER",
    slug: "defilancer-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defilancer-token.png",
  },
  {
    name: "Parasset",
    symbol: "ASET",
    slug: "parasset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parasset.png",
  },
  {
    name: "Demeter",
    symbol: "DMT",
    slug: "demeter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/demeter.png",
  },
  {
    name: "Cyberclassic",
    symbol: "CLASS",
    slug: "cyberclassic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyberclassic.png",
  },
  {
    name: "Pandorium",
    symbol: "PAN",
    slug: "pandorium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pandorium.png",
  },
  {
    name: "Landboard",
    symbol: "LAND",
    slug: "landboard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/landboard.png",
  },
  {
    name: "Artik",
    symbol: "ARTK",
    slug: "artik",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artik.png",
  },
  {
    name: "SEA Token",
    symbol: "SEA",
    slug: "sea-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sea-token.png",
  },
  {
    name: "Maneki-neko",
    symbol: "NEKI",
    slug: "maneki-neko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maneki-neko.png",
  },
  {
    name: "Crabada Amulet",
    symbol: "CRAM",
    slug: "crabada-amulet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crabada-amulet.png",
  },
  {
    name: "METATOWN",
    symbol: "MTOWN",
    slug: "metatown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metatown.png",
  },
  {
    name: "TokenBook",
    symbol: "TBK",
    slug: "tokenbook",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenbook.png",
  },
  {
    name: "Qrkita Token",
    symbol: "QRT",
    slug: "qrkita-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qrkita-token.png",
  },
  {
    name: "Bitcoin Metaverse",
    symbol: "BITMETA",
    slug: "bitcoin-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-metaverse.png",
  },
  {
    name: "RavenMoon",
    symbol: "RVM",
    slug: "ravenmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ravenmoon.png",
  },
  {
    name: "BitcoinPrint",
    symbol: "BTCP",
    slug: "bitcoinprint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinprint.png",
  },
  {
    name: "Myteamcoin",
    symbol: "MYC",
    slug: "myteamcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myteamcoin.png",
  },
  {
    name: "Leonidasbilic",
    symbol: "LIO",
    slug: "leonidasbilic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leonidasbilic.png",
  },
  {
    name: "Unobtainium",
    symbol: "UNO",
    slug: "unobtainium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unobtainium.png",
  },
  {
    name: "COXSWAP V2",
    symbol: "COX",
    slug: "coxswap-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coxswap-v2.png",
  },
  {
    name: "Revive",
    symbol: "RVE",
    slug: "revive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revive.png",
  },
  {
    name: "Greenhouse",
    symbol: "GREEN",
    slug: "greenhouse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenhouse.png",
  },
  {
    name: "Aufin Protocol",
    symbol: "AUN",
    slug: "aufin-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aufin-protocol.png",
  },
  {
    name: "FILIPCOIN",
    symbol: "FCP",
    slug: "filipcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/filipcoin.png",
  },
  {
    name: "Movie Magic",
    symbol: "MVM",
    slug: "movie-magic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/movie-magic.png",
  },
  {
    name: "Quarashi",
    symbol: "QUA",
    slug: "quarashi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quarashi.png",
  },
  {
    name: "Brinc.fi",
    symbol: "BRC",
    slug: "brinc-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brinc-fi.png",
  },
  {
    name: "Polychain Monsters Land Genesis Token",
    symbol: "PMLG",
    slug: "polychain-monsters-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polychain-monsters-land.png",
  },
  {
    name: "Nblh",
    symbol: "NBLH",
    slug: "nblh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nblh.png",
  },
  {
    name: "Blockius",
    symbol: "BLOS",
    slug: "blockpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockpad.png",
  },
  {
    name: "Synex Coin",
    symbol: "MINECRAFT",
    slug: "synex-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synex-coin.png",
  },
  {
    name: "AST.finance",
    symbol: "AST",
    slug: "ast-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ast-finance.png",
  },
  {
    name: "Minionverse",
    symbol: "MIVRS",
    slug: "minionverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minionverse.png",
  },
  {
    name: "OXM Protocol",
    symbol: "OXM",
    slug: "oxm-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oxm-protocol.png",
  },
  {
    name: "BABYOKX",
    symbol: "BABYOKX",
    slug: "babyokx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyokx-token.png",
  },
  {
    name: "Provenance Blockchain",
    symbol: "HASH",
    slug: "provenance-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/provenance-blockchain.png",
  },
  {
    name: "ZEUS10000 (NEW)",
    symbol: "ZEUS",
    slug: "zeus10000-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeus10000-new.png",
  },
  {
    name: "YUSD Stablecoin",
    symbol: "YUSD",
    slug: "yusd-stablecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yusd-stablecoin.png",
  },
  {
    name: "ANUBI Token",
    symbol: "ANUBI",
    slug: "anubi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anubi-token.png",
  },
  {
    name: "Underground Warriors",
    symbol: "WP",
    slug: "underground-warriors",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/underground-warriors.png",
  },
  {
    name: "Takepile",
    symbol: "TAKE",
    slug: "takepile",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/takepile.png",
  },
  {
    name: "KlayGames",
    symbol: "KLAYG",
    slug: "klaygames",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klaygames.png",
  },
  {
    name: "Persistence Staked XPRT",
    symbol: "STKXPRT",
    slug: "persistence-staked-xprt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/persistence-staked-xprt.png",
  },
  {
    name: "HEROES OF THE LAND RUBY",
    symbol: "RUBY",
    slug: "heroes-of-the-land-ruby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heroes-of-the-land-ruby.png",
  },
  {
    name: "Jswap.Finance",
    symbol: "JF",
    slug: "jswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jswap-finance.png",
  },
  {
    name: "ChoccySwap",
    symbol: "CCY",
    slug: "choccyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/choccyswap.png",
  },
  {
    name: "BitRides",
    symbol: "RIDES",
    slug: "bitrides",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitrides.png",
  },
  {
    name: "Krill",
    symbol: "KRILL",
    slug: "krill-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krill-token.png",
  },
  {
    name: "OnlyFans",
    symbol: "FANNED",
    slug: "onlyfans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onlyfans.png",
  },
  {
    name: "Metaverse lab",
    symbol: "MVP",
    slug: "metaverse-lab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-lab.png",
  },
  {
    name: "Magic Forest",
    symbol: "MAGF",
    slug: "magic-forest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-forest.png",
  },
  {
    name: "Adroverse",
    symbol: "ADR",
    slug: "adroverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adroverse.png",
  },
  {
    name: "Highest Yield Savings Service",
    symbol: "HYSS",
    slug: "highest-yield-savings-service",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/highest-yield-savings-service.png",
  },
  {
    name: "ShibKiller",
    symbol: "SHIBKILLER",
    slug: "shibkiller",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibkiller.png",
  },
  {
    name: "Simpli Finance",
    symbol: "SIMPLI",
    slug: "simpli-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simpli-finance.png",
  },
  {
    name: "ORBIS",
    symbol: "ORBC",
    slug: "orbis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbis.png",
  },
  {
    name: "DAO Farmer DFW",
    symbol: "DFW",
    slug: "dao-farmer-dfw",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dao-farmer-dfw.png",
  },
  {
    name: "ZinaX Token",
    symbol: "ZINAX",
    slug: "zinax-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zinax-token.png",
  },
  {
    name: "InnovaMinex",
    symbol: "MINX",
    slug: "innovaminex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/innovaminex.png",
  },
  {
    name: "FlokiGainz",
    symbol: "GAINZ",
    slug: "flokigainz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokigainz.png",
  },
  {
    name: "Sukuna Inu",
    symbol: "SUKUNA",
    slug: "sukuna-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sukuna-inu.png",
  },
  {
    name: "ELON CAT COIN",
    symbol: "ELONCAT",
    slug: "elon-cat-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elon-cat-coin.png",
  },
  {
    name: "BANCC",
    symbol: "sBanc",
    slug: "bancc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bancc.png",
  },
  {
    name: "NEWTOWNGAMING",
    symbol: "NTG",
    slug: "newtowngaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newtowngaming.png",
  },
  {
    name: "SCIFI Index",
    symbol: "SCIFI",
    slug: "scifi-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scifi-index.png",
  },
  {
    name: "Mishka",
    symbol: "MSK",
    slug: "mishka-badbears",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mishka-badbears.png",
  },
  {
    name: "bePAY Finance",
    symbol: "BECOIN",
    slug: "bepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bepay.png",
  },
  {
    name: "American Dream",
    symbol: "ADREAM",
    slug: "american-dream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/american-dream.png",
  },
  {
    name: "Fast To Earn",
    symbol: "F2E",
    slug: "fast-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fast-to-earn.png",
  },
  {
    name: "ArbiFarm",
    symbol: "AFARM",
    slug: "arbifarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbifarm.png",
  },
  {
    name: "HALO network",
    symbol: "HO",
    slug: "halo-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/halo-network.png",
  },
  {
    name: "DeMon Token",
    symbol: "DMZ",
    slug: "dmz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dmz-token.png",
  },
  {
    name: "Structure finance",
    symbol: "STF",
    slug: "structure-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/structure-finance.png",
  },
  {
    name: "The Swappery",
    symbol: "SWPR",
    slug: "the-swappery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-swappery.png",
  },
  {
    name: "Blox Token",
    symbol: "BLOX",
    slug: "blox-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blox-token.png",
  },
  {
    name: "Snow Burn",
    symbol: "SNOW",
    slug: "snow-burn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snow-burn.png",
  },
  {
    name: "Chum Coin",
    symbol: "CHUM",
    slug: "chum-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chum-coin.png",
  },
  {
    name: "ShibaGalaxy",
    symbol: "SHIBGX",
    slug: "shibagalaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibagalaxy.png",
  },
  {
    name: "SolX Gaming Guild",
    symbol: "SGG",
    slug: "solx-gaming-guild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solx-gaming-guild.png",
  },
  {
    name: "iAssets",
    symbol: "ASSET",
    slug: "iassets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iassets.png",
  },
  {
    name: "ETHBTC 2x Long (Polygon)",
    symbol: "MRATIOMOON",
    slug: "ethbtc-2x-long-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethbtc-2x-long-polygon.png",
  },
  {
    name: "DIVI LAND",
    symbol: "DVLD",
    slug: "divi-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/divi-land.png",
  },
  {
    name: "BabyFrog",
    symbol: "BABYFROG",
    slug: "babyfrog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyfrog.png",
  },
  {
    name: "Takeda Shin",
    symbol: "TAKEDA",
    slug: "takeda-shin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/takeda-shin.png",
  },
  {
    name: "Fossil",
    symbol: "FOSSIL",
    slug: "fossil",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fossil.png",
  },
  {
    name: "VesTallyToken",
    symbol: "VTT",
    slug: "vestallytoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vestallytoken.png",
  },
  {
    name: "Score Token",
    symbol: "SCO",
    slug: "score-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/score-token.png",
  },
  {
    name: "Felix Token",
    symbol: "FLX",
    slug: "felix-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/felix-token.png",
  },
  {
    name: "Dogger Token",
    symbol: "DOGGER",
    slug: "dogger-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogger-token.png",
  },
  {
    name: "Cloud Protocol",
    symbol: "CPRO",
    slug: "cloud-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cloud-protocol.png",
  },
  {
    name: "Sourceless",
    symbol: "STR",
    slug: "sourceless",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sourceless.png",
  },
  {
    name: "AstroDonkey",
    symbol: "DNKY",
    slug: "astrodonkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astrodonkey.png",
  },
  {
    name: "META Gaming",
    symbol: "RMG",
    slug: "real-metagaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/real-metagaming.png",
  },
  {
    name: "Cryb token",
    symbol: "CRYB",
    slug: "cryb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryb-token.png",
  },
  {
    name: "Finance Sports",
    symbol: "FSD",
    slug: "finance-sports",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/finance-sports.png",
  },
  {
    name: "Crypto Warz",
    symbol: "WARZ",
    slug: "crypto-warz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-warz.png",
  },
  {
    name: "LIFEBIRD",
    symbol: "LIFEBIRD",
    slug: "lifebird",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lifebird.png",
  },
  {
    name: "Bursaspor Fan Token",
    symbol: "TMSH",
    slug: "bursaspor-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bursaspor-fan-token.png",
  },
  {
    name: "MetaWolf",
    symbol: "MWOLF",
    slug: "metawolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metawolf.png",
  },
  {
    name: "Bork",
    symbol: "BORK",
    slug: "bork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bork.png",
  },
  {
    name: "MechaChain",
    symbol: "MECHA",
    slug: "mechachain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mechachain.png",
  },
  {
    name: "NutsDAO",
    symbol: "NUTS",
    slug: "nutsdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nutsdao.png",
  },
  {
    name: "ZeldaVerse",
    symbol: "ZVRS",
    slug: "zeldaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeldaverse.png",
  },
  {
    name: "ClaimSwap",
    symbol: "CLA",
    slug: "claimswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/claimswap.png",
  },
  {
    name: "Lucky Metaverse",
    symbol: "LMETA",
    slug: "lucky-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-metaverse.png",
  },
  {
    name: "BezosDoge",
    symbol: "$BEDOGE",
    slug: "bezosdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bezosdoge.png",
  },
  {
    name: "Augmented Finance",
    symbol: "AGF",
    slug: "augmented-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/augmented-finance.png",
  },
  {
    name: "NinjaSwap",
    symbol: "NINJA",
    slug: "ninjaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninjaswap.png",
  },
  {
    name: "ATNIR Capital",
    symbol: "ATNIR",
    slug: "atnir-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atnir-capital.png",
  },
  {
    name: "ElonBank",
    symbol: "ELONBANK",
    slug: "elonbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elonbank.png",
  },
  {
    name: "Metatradefinance",
    symbol: "MTF",
    slug: "metatradefinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metatradefinance.png",
  },
  {
    name: "Fatih Karagümrük SK Fan Token",
    symbol: "FKSK",
    slug: "fatih-karagumruk-sk-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fatih-karagumruk-sk-fan-token.png",
  },
  {
    name: "Kala Finance",
    symbol: "KALA",
    slug: "kala-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kala-finance.png",
  },
  {
    name: "Imperial Obelisk",
    symbol: "IMP",
    slug: "imperial-obelisk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imperial-obelisk.png",
  },
  {
    name: "Tails",
    symbol: "TAILS",
    slug: "tails",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tails.png",
  },
  {
    name: "Biden Token",
    symbol: "BIDEN",
    slug: "biden-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biden-token.png",
  },
  {
    name: "Socean Staked Sol",
    symbol: "SCNSOL",
    slug: "socean-staked-sol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/socean-staked-sol.png",
  },
  {
    name: "Amaterasu Finance",
    symbol: "IZA",
    slug: "amaterasu-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amaterasu-finance.png",
  },
  {
    name: "Aerovek Aviation",
    symbol: "AERO",
    slug: "aerovek-aviation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aerovek-aviation.png",
  },
  {
    name: "Almond",
    symbol: "ALM",
    slug: "almond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/almond.png",
  },
  {
    name: "StepWell",
    symbol: "STW",
    slug: "stepwell",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stepwell.png",
  },
  {
    name: "SUPERPIXEL",
    symbol: "SUPERPIXEL",
    slug: "superpixel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superpixel.png",
  },
  {
    name: "Ceva Island",
    symbol: "CEV",
    slug: "ceva-island",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ceva-island.png",
  },
  {
    name: "Shiba Games",
    symbol: "SHIBAGAMES",
    slug: "shiba-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-games.png",
  },
  {
    name: "Salmonation",
    symbol: "SUI",
    slug: "salmonation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/salmonation.png",
  },
  {
    name: "Crypto Indian Coin",
    symbol: "CIC",
    slug: "crypto-indian-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-indian-coin.png",
  },
  {
    name: "Meme Protocol",
    symbol: "MEME",
    slug: "meme-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meme-protocol.png",
  },
  {
    name: "Plato Farm (MARK)",
    symbol: "MARK",
    slug: "plato-farm-mark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plato-farm-mark.png",
  },
  {
    name: "LeapableIO",
    symbol: "LEA",
    slug: "leapableio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leapableio.png",
  },
  {
    name: "SAFE(AnWang)",
    symbol: "SAFE",
    slug: "safe-anwang",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-anwang.png",
  },
  {
    name: "PRISM Protocol",
    symbol: "PRISM",
    slug: "prism-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prism-protocol.png",
  },
  {
    name: "SpritzMoon Crypto Token",
    symbol: "SPRITZMOON",
    slug: "spritzmoon-crypto-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spritzmoon-crypto-token.png",
  },
  {
    name: "Valentine Floki",
    symbol: "FLOV",
    slug: "valentine-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valentine-floki.png",
  },
  {
    name: "Playtrk",
    symbol: "PLAYTRK",
    slug: "playtrk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playtrk.png",
  },
  {
    name: "Arbucks",
    symbol: "BUCK",
    slug: "arbucks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbucks.png",
  },
  {
    name: "DARTH",
    symbol: "DAH",
    slug: "darth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darth.png",
  },
  {
    name: "Carbon Finance",
    symbol: "CARBON",
    slug: "carbon-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbon-finance.png",
  },
  {
    name: "MetaFame",
    symbol: "BMF",
    slug: "metafame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafame.png",
  },
  {
    name: "KING SAMO",
    symbol: "KSAMO",
    slug: "king-samo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-samo.png",
  },
  {
    name: "Charlie Finance",
    symbol: "CHT",
    slug: "charlie-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charlie-finance.png",
  },
  {
    name: "LEDGIS",
    symbol: "LED",
    slug: "ledgis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ledgis.png",
  },
  {
    name: "ZakumiFi",
    symbol: "ZAFI",
    slug: "zakumifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zakumifi.png",
  },
  {
    name: "Dune Token",
    symbol: "DUNE",
    slug: "dune-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dune-token.png",
  },
  {
    name: "PackagePortal",
    symbol: "PORT",
    slug: "packageportal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/packageportal.png",
  },
  {
    name: "GMX",
    symbol: "GMX",
    slug: "gmx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gmx.png",
  },
  {
    name: "Hunter Token",
    symbol: "HNTR",
    slug: "hunter-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hunter-token.png",
  },
  {
    name: "arbee.info",
    symbol: "ARBYS",
    slug: "arbee-info",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbee-info.png",
  },
  {
    name: "ArbiNYAN",
    symbol: "NYAN",
    slug: "arbinyan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbinyan.png",
  },
  {
    name: "Myriad Social",
    symbol: "MYRIA",
    slug: "myriad-social",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myriad-social.png",
  },
  {
    name: "Cryptotem",
    symbol: "TOTEM",
    slug: "cryptotem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptotem.png",
  },
  {
    name: "Floki Meta",
    symbol: "MFLOKI",
    slug: "floki-meta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-meta.png",
  },
  {
    name: "Valencia CF Fan Token",
    symbol: "VCF",
    slug: "valencia-cf-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valencia-cf-fan-token.png",
  },
  {
    name: "CowCoin",
    symbol: "CC",
    slug: "cowcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cowcoin.png",
  },
  {
    name: "ETNA Metabolism",
    symbol: "MTB",
    slug: "etna-metabolism",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etna-metabolism.png",
  },
  {
    name: "BET TOKEN",
    symbol: "BET",
    slug: "bet-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bet-token.png",
  },
  {
    name: "VIRAL",
    symbol: "VIRAL",
    slug: "viral-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viral-token.png",
  },
  {
    name: "Kalissa",
    symbol: "KALI",
    slug: "kalissa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalissa.png",
  },
  {
    name: "mirror",
    symbol: "MIRROR",
    slug: "mirrorfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrorfinance.png",
  },
  {
    name: "IceFlake Finance",
    symbol: "FLAKE",
    slug: "iceflake-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iceflake-finance.png",
  },
  {
    name: "Online Cold Wallet",
    symbol: "OCW",
    slug: "online-cold-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/online-cold-wallet.png",
  },
  {
    name: "Council of Apes",
    symbol: "COAPE",
    slug: "council-of-apes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/council-of-apes.png",
  },
  {
    name: "Bee Inu",
    symbol: "BEEINU",
    slug: "bee-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bee-inu.png",
  },
  {
    name: "Fitmint",
    symbol: "FITT",
    slug: "fitmint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fitmint.png",
  },
  {
    name: "Nerian Network",
    symbol: "NERIAN",
    slug: "nerian-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nerian-network.png",
  },
  {
    name: "MetaGin",
    symbol: "METAGIN",
    slug: "metagin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagin.png",
  },
  {
    name: "Reforestation Mahogany",
    symbol: "RMOG",
    slug: "reforestation-mahogany",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reforestation-mahogany.png",
  },
  {
    name: "Paragon Capital",
    symbol: "PARAGON",
    slug: "paragon-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paragon-capital.png",
  },
  {
    name: "CZbomb",
    symbol: "CZBOMB",
    slug: "czbomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/czbomb.png",
  },
  {
    name: "FUMoney",
    symbol: "FUM",
    slug: "fumoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fumoney.png",
  },
  {
    name: "Materium",
    symbol: "MTRM",
    slug: "materium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/materium.png",
  },
  {
    name: "Mundo",
    symbol: "$MUNDO",
    slug: "mundo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mundo.png",
  },
  {
    name: "Supreme Finance",
    symbol: "HYPES",
    slug: "hypes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hypes.png",
  },
  {
    name: "Stash",
    symbol: "STASH",
    slug: "stash-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stash-capital.png",
  },
  {
    name: "WormFi",
    symbol: "WORM",
    slug: "wormfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wormfi.png",
  },
  {
    name: "CalltoCombat",
    symbol: "CTC",
    slug: "calltocombat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calltocombat.png",
  },
  {
    name: "SafeWhale Games",
    symbol: "SWHAL",
    slug: "safewhale-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safewhale-games.png",
  },
  {
    name: "Otter Finance",
    symbol: "OTR",
    slug: "otter-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otter-finance.png",
  },
  {
    name: "NFTWiki",
    symbol: "NFTK",
    slug: "nftwiki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftwiki.png",
  },
  {
    name: "QuinCoin",
    symbol: "QIN",
    slug: "quincoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quincoin.png",
  },
  {
    name: "Hippo Inu",
    symbol: "HIPPO",
    slug: "hippo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hippo-inu.png",
  },
  {
    name: "Jungle",
    symbol: "JUNGLE",
    slug: "jungle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jungle.png",
  },
  {
    name: "DeltaFlare",
    symbol: "HONR",
    slug: "deltaflare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deltaflare.png",
  },
  {
    name: "WalletNow",
    symbol: "WNOW",
    slug: "walletnow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/walletnow.png",
  },
  {
    name: "Fantom Doge",
    symbol: "RIP",
    slug: "fantom-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantom-doge.png",
  },
  {
    name: "JackpotDoge",
    symbol: "JPD",
    slug: "jackpotdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jackpotdoge.png",
  },
  {
    name: "BUILD",
    symbol: "BUILD",
    slug: "build",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/build.png",
  },
  {
    name: "CoinX",
    symbol: "CNX",
    slug: "coinx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinx.png",
  },
  {
    name: "Curio Stable Coin",
    symbol: "CSC",
    slug: "curio-stable-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/curio-stable-coin.png",
  },
  {
    name: "Dickcoin",
    symbol: "DICK",
    slug: "dickcoinmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dickcoinmoon.png",
  },
  {
    name: "Wolf Town Wool",
    symbol: "WTWOOL",
    slug: "wolf-town-wool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolf-town-wool.png",
  },
  {
    name: "Balisari",
    symbol: "BST",
    slug: "balisari",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balisari.png",
  },
  {
    name: "Pollen Coin",
    symbol: "PCN",
    slug: "pollen-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pollen-coin.png",
  },
  {
    name: "7s",
    symbol: "7S",
    slug: "7s",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/7s.png",
  },
  {
    name: "SuperInu",
    symbol: "SINU",
    slug: "super-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-inu.png",
  },
  {
    name: "H2O",
    symbol: "PSDN",
    slug: "h2o-poseidon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/h2o-poseidon.png",
  },
  {
    name: "Pay It Now",
    symbol: "PIN",
    slug: "pay-it-now",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pay-it-now.png",
  },
  {
    name: "Shibzelda",
    symbol: "SHIBZELDA",
    slug: "shibzelda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibzelda.png",
  },
  {
    name: "FIDELIS",
    symbol: "FDLS",
    slug: "fidelis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fidelis.png",
  },
  {
    name: "Crafty Metaverse",
    symbol: "CMETA",
    slug: "crafty-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crafty-metaverse.png",
  },
  {
    name: "DeepBlueSea",
    symbol: "DBEA",
    slug: "deepbluesea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deepbluesea.png",
  },
  {
    name: "S.C. Corinthians Fan Token",
    symbol: "SCCP",
    slug: "sc-corinthians-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sc-corinthians-fan-token.png",
  },
  {
    name: "Royal Flush Coin",
    symbol: "RFC",
    slug: "royal-flush-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/royal-flush-coin.png",
  },
  {
    name: "TwitterX",
    symbol: "TWITTERX",
    slug: "twitterx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twitterx.png",
  },
  {
    name: "WOW-token",
    symbol: "WOW",
    slug: "wow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wow-token.png",
  },
  {
    name: "DinnersReady",
    symbol: "DINNER",
    slug: "dinnersready",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinnersready.png",
  },
  {
    name: "JoinCoin",
    symbol: "JOIN",
    slug: "join-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/join-coin.png",
  },
  {
    name: "AlgoDAO",
    symbol: "ADAO",
    slug: "algodao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algodao.png",
  },
  {
    name: "Ruby Play Network",
    symbol: "RUBY",
    slug: "ruby-play-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ruby-play-network.png",
  },
  {
    name: "Alanyaspor Fan Token",
    symbol: "ALA",
    slug: "alanyaspor-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alanyaspor-fan-token.png",
  },
  {
    name: "WatchSports",
    symbol: "WSPORTS",
    slug: "watchsports",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/watchsports.png",
  },
  {
    name: "Wrapped Curio Ferrari F12tdf",
    symbol: "WCT1",
    slug: "wrapped-curio-ferrari-f12tdf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-curio-ferrari-f12tdf.png",
  },
  {
    name: "Mirror mARKK Token",
    symbol: "MARKK",
    slug: "mirror-markk-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirror-markk-token.png",
  },
  {
    name: "Rari Games",
    symbol: "RARI",
    slug: "rari-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rari-games.png",
  },
  {
    name: "Travel Care",
    symbol: "TRAVEL",
    slug: "travel-care-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/travel-care-v2.png",
  },
  {
    name: "DogeDead",
    symbol: "DOGEDEAD",
    slug: "dogedead",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogedead.png",
  },
  {
    name: "Parrot Egg",
    symbol: "1PEGG",
    slug: "parrot-egg-harmony",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parrot-egg-harmony.png",
  },
  {
    name: "Minerva Wallet",
    symbol: "MIVA",
    slug: "minerva-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minerva-wallet.png",
  },
  {
    name: "Keeshond Coin",
    symbol: "KSH",
    slug: "keeshond-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keeshond-coin.png",
  },
  {
    name: "Ape Finance",
    symbol: "APEFI",
    slug: "apefinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apefinance.png",
  },
  {
    name: "Blocksport",
    symbol: "BSPT",
    slug: "blocksport",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocksport.png",
  },
  {
    name: "Cronosphere",
    symbol: "SPHERE",
    slug: "cronosphere",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cronosphere.png",
  },
  {
    name: "Dingocoin",
    symbol: "DINGO",
    slug: "dingocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dingocoin.png",
  },
  {
    name: "Immortl",
    symbol: "IMRTL",
    slug: "immortl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/immortl.png",
  },
  {
    name: "Nora Token",
    symbol: "NRA",
    slug: "nora-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nora-token.png",
  },
  {
    name: "LaikaDog",
    symbol: "LAI",
    slug: "laikadog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laikadog.png",
  },
  {
    name: "Entice Coin",
    symbol: "NTIC",
    slug: "entice-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/entice-coin.png",
  },
  {
    name: "Oppa",
    symbol: "OPPA",
    slug: "oppa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oppa.png",
  },
  {
    name: "Retrogression",
    symbol: "RTGN",
    slug: "retrogression",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/retrogression.png",
  },
  {
    name: "Hippo Coin",
    symbol: "HIPPO",
    slug: "hippo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hippo-coin.png",
  },
  {
    name: "Treasury of the City",
    symbol: "TOC",
    slug: "treasury-of-the-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treasury-of-the-city.png",
  },
  {
    name: "Forge Finance",
    symbol: "FORGE",
    slug: "forge-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forge-finance.png",
  },
  {
    name: "Strawberry Share",
    symbol: "$STRAW",
    slug: "strawberry-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strawberry-share.png",
  },
  {
    name: "Rising Sun",
    symbol: "SUN",
    slug: "rising-sun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rising-sun.png",
  },
  {
    name: "Project DogeX",
    symbol: "$DOGEX",
    slug: "project-dogex-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-dogex-v2.png",
  },
  {
    name: "Play To Maximize",
    symbol: "PMX",
    slug: "play-to-maximize",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/play-to-maximize.png",
  },
  {
    name: "SER",
    symbol: "SER",
    slug: "ser-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ser-token.png",
  },
  {
    name: "Warp Bond",
    symbol: "WARP",
    slug: "warp-bond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/warp-bond.png",
  },
  {
    name: "SuperBrain Capital Dao",
    symbol: "$SBC",
    slug: "superbrain-capital-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superbrain-capital-dao.png",
  },
  {
    name: "Indian Shiba Inu",
    symbol: "INDSHIB",
    slug: "indian-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/indian-shiba-inu.png",
  },
  {
    name: "Meta Plane",
    symbol: "MPL",
    slug: "meta-plane",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-plane.png",
  },
  {
    name: "Raid Token",
    symbol: "RAID",
    slug: "raid-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raid-token.png",
  },
  {
    name: "SnoopFlokiDog",
    symbol: "SFD",
    slug: "snoopflokidog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snoopflokidog.png",
  },
  {
    name: "Suuper",
    symbol: "SUUPER",
    slug: "suuper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/suuper.png",
  },
  {
    name: "Exchange Genesis Ethlas Medium",
    symbol: "XGEM",
    slug: "exchange-genesis-ethlas-medium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exchange-genesis-ethlas-medium.png",
  },
  {
    name: "Super Whale DAO",
    symbol: "SWDAO",
    slug: "super-whale-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-whale-dao.png",
  },
  {
    name: "Adventure Inu",
    symbol: "ADINU",
    slug: "adventure-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adventure-inu.png",
  },
  {
    name: "PIZZA NFT",
    symbol: "$PIZZA",
    slug: "pizzanft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pizzanft.png",
  },
  {
    name: "P.Ness Monster",
    symbol: "PESSY",
    slug: "pnessmonster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pnessmonster.png",
  },
  {
    name: "Wrapped NewYorkCoin",
    symbol: "WNYC",
    slug: "wrapped-newyorkcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-newyorkcoin.png",
  },
  {
    name: "Calvex",
    symbol: "CLVX",
    slug: "calvex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calvex.png",
  },
  {
    name: "BAPE Social Club",
    symbol: "BAPE",
    slug: "bored-ape-social-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bored-ape-social-club.png",
  },
  {
    name: "artèQ",
    symbol: "ARTEQ",
    slug: "arteq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arteq.png",
  },
  {
    name: "ZeroFi",
    symbol: "ZERI",
    slug: "zerofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zerofi.png",
  },
  {
    name: "Baby Trader Joe",
    symbol: "BJOE",
    slug: "baby-trader-joe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-trader-joe.png",
  },
  {
    name: "FIFTYONEFIFTY",
    symbol: "$FIFTY",
    slug: "fiftyonefifty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fiftyonefifty.png",
  },
  {
    name: "ZilPay wallet",
    symbol: "ZLP",
    slug: "zilpay-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilpay-wallet.png",
  },
  {
    name: "NVL",
    symbol: "NVL",
    slug: "nvl-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nvl-project.png",
  },
  {
    name: "NFT TOKEN PILOT",
    symbol: "NFTP",
    slug: "nft-token-pilot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-token-pilot.png",
  },
  {
    name: "ethcoin",
    symbol: "ETHCOIN",
    slug: "ethcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethcoin.png",
  },
  {
    name: "Musk Doge",
    symbol: "MKD",
    slug: "musk-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musk-doge.png",
  },
  {
    name: "Abura Farm",
    symbol: "ABU",
    slug: "abura-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abura-farm.png",
  },
  {
    name: "Psyduck Inu",
    symbol: "Psyduck",
    slug: "psyduck-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/psyduck-inu.png",
  },
  {
    name: "Telefy",
    symbol: "TELE",
    slug: "telefy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/telefy.png",
  },
  {
    name: "babyDogeKing",
    symbol: "babyDogeKing",
    slug: "babydogeking",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babydogeking.png",
  },
  {
    name: "Dextera",
    symbol: "DXTA",
    slug: "dextera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dextera.png",
  },
  {
    name: "Sensi",
    symbol: "SENSI",
    slug: "sensi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sensi.png",
  },
  {
    name: "Ancient Warrior",
    symbol: "ANCW",
    slug: "ancient-warrior",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ancient-warrior.png",
  },
  {
    name: "Sakhalin Husky",
    symbol: "SAHU",
    slug: "sakhalin-husky",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sakhalin-husky.png",
  },
  {
    name: "Arbidoge",
    symbol: "ADOGE",
    slug: "arbidoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbidoge.png",
  },
  {
    name: "Wrapped One",
    symbol: "WONE",
    slug: "wrapped-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-one.png",
  },
  {
    name: "SpiceEURO",
    symbol: "EUROS",
    slug: "spiceeuro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spiceeuro.png",
  },
  {
    name: "Defi Warrior",
    symbol: "FIWA",
    slug: "defi-warrior",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-warrior.png",
  },
  {
    name: "XRoad Initiative",
    symbol: "XRI",
    slug: "xroad-initiative",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xroad-initiative.png",
  },
  {
    name: "Parex",
    symbol: "PRX",
    slug: "parex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parex.png",
  },
  {
    name: "Meta Car",
    symbol: "META CAR",
    slug: "meta-car",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-car.png",
  },
  {
    name: "Yoi Shiba Inu",
    symbol: "YOSI",
    slug: "yoi-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yoi-shiba-inu.png",
  },
  {
    name: "OFI.CASH",
    symbol: "OFI",
    slug: "ofi-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ofi-cash.png",
  },
  {
    name: "Renewable Energy",
    symbol: "RET",
    slug: "ret",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ret.png",
  },
  {
    name: "Grok",
    symbol: "GROK",
    slug: "grok",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grok.png",
  },
  {
    name: "Ascend",
    symbol: "ACE",
    slug: "ascend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ascend.png",
  },
  {
    name: "Bikearn",
    symbol: "RTE",
    slug: "bikearn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bikearn.png",
  },
  {
    name: "Brayzin Heist",
    symbol: "BRZH",
    slug: "brayzin-heist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brayzin-heist.png",
  },
  {
    name: "WallStreet Finance",
    symbol: "WSF",
    slug: "wallstreet-finance-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wallstreet-finance-app.png",
  },
  {
    name: "Raven The Game",
    symbol: "RTG",
    slug: "raven-the-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raven-the-game.png",
  },
  {
    name: "SafeApe",
    symbol: "USDA",
    slug: "safeape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeape.png",
  },
  {
    name: "Warlockbnb",
    symbol: "WRLK",
    slug: "warlockbnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/warlockbnb.png",
  },
  {
    name: "DogeQueen",
    symbol: "DOGEQUEEN",
    slug: "dogequeen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogequeen.png",
  },
  {
    name: "Bmail",
    symbol: "BML",
    slug: "bmail",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bmail.png",
  },
  {
    name: "Draco.Finance",
    symbol: "DRACO",
    slug: "draco-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/draco-finance.png",
  },
  {
    name: "TOP ONE",
    symbol: "TOP1",
    slug: "top-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/top-one.png",
  },
  {
    name: "Baby Zoro Inu",
    symbol: "BABYZOROINU",
    slug: "baby-zoro-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-zoro-inu.png",
  },
  {
    name: "Rare Candy",
    symbol: "CANDY",
    slug: "rare-candy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rare-candy.png",
  },
  {
    name: "Zoobit",
    symbol: "ZBT",
    slug: "zoobit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoobit.png",
  },
  {
    name: "Aeterna",
    symbol: "AETERNA",
    slug: "aeterna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aeterna.png",
  },
  {
    name: "ADO.Network",
    symbol: "ADO",
    slug: "ado-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ado-network.png",
  },
  {
    name: "GM Floki",
    symbol: "GMFLOKI",
    slug: "gm-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gm-floki.png",
  },
  {
    name: "Shiba Metaverse",
    symbol: "SHIM",
    slug: "shiba-metaverse-shim",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-metaverse-shim.png",
  },
  {
    name: "LuckyChip",
    symbol: "LC",
    slug: "luckychip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luckychip.png",
  },
  {
    name: "BabelFish",
    symbol: "BABEL",
    slug: "babelfish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babelfish.png",
  },
  {
    name: "Joltify",
    symbol: "JOLT",
    slug: "joltify",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joltify.png",
  },
  {
    name: "GENIE token",
    symbol: "GENIETOKEN",
    slug: "genie-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genie-token.png",
  },
  {
    name: "Bunny King Metaverse",
    symbol: "BKM",
    slug: "bunny-king-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunny-king-metaverse.png",
  },
  {
    name: "MetaTariff",
    symbol: "RIFF",
    slug: "metatariff",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metatariff.png",
  },
  {
    name: "miniShibaToken",
    symbol: "MINISHIB",
    slug: "minishibatoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minishibatoken.png",
  },
  {
    name: "Hackerlabs DAO",
    symbol: "HLD",
    slug: "hackerlabs-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hackerlabs-dao.png",
  },
  {
    name: "Easter Floki",
    symbol: "EFLOKI",
    slug: "easter-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/easter-floki.png",
  },
  {
    name: "GTI Token",
    symbol: "GTI",
    slug: "gti-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gti-token.png",
  },
  {
    name: "Weley Metaverse",
    symbol: "WE",
    slug: "weley",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weley.png",
  },
  {
    name: "HangryBirds",
    symbol: "HANGRY",
    slug: "hangrybirds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hangrybirds.png",
  },
  {
    name: "MoonClimb",
    symbol: "MOONCOIN",
    slug: "moonclimb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonclimb.png",
  },
  {
    name: "WOOP",
    symbol: "WOOP",
    slug: "woop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/woop.png",
  },
  {
    name: "BingDwenDwen",
    symbol: "BingDwenDwen",
    slug: "bingdwendwen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bingdwendwen.png",
  },
  {
    name: "Vconomics - Decentralized Digital Economy",
    symbol: "MICS",
    slug: "vconomics-decentralized-digital-economy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vconomics-decentralized-digital-economy.png",
  },
  {
    name: "MINIX",
    symbol: "MNX",
    slug: "minix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minix.png",
  },
  {
    name: "Exp",
    symbol: "EXP",
    slug: "exp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exp.png",
  },
  {
    name: "Nami Corporation",
    symbol: "NAMI",
    slug: "nami-corporation-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nami-corporation-token.png",
  },
  {
    name: "CHHIPSCOIN",
    symbol: "CHH",
    slug: "chhipscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chhipscoin.png",
  },
  {
    name: "WOLF PUPS",
    symbol: "WOLFIES",
    slug: "wolf-pups",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolf-pups.png",
  },
  {
    name: "Metapplay",
    symbol: "METAP",
    slug: "metapplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metapplay.png",
  },
  {
    name: "TOURIST SHIBA INU",
    symbol: "TOURISTS",
    slug: "tourist-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tourist-shiba-inu.png",
  },
  {
    name: "GrapeVine",
    symbol: "XGRAPE",
    slug: "grapevine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grapevine.png",
  },
  {
    name: "Severe Rise Games Token",
    symbol: "SRGT",
    slug: "severe-rise-games-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/severe-rise-games-token.png",
  },
  {
    name: "Phoneum Green",
    symbol: "PHTG",
    slug: "phoneum-green",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoneum-green.png",
  },
  {
    name: "Hodlers Network",
    symbol: "HDLN",
    slug: "hodlers-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodlers-network.png",
  },
  {
    name: "Sleep Ecosystem",
    symbol: "SLEEP",
    slug: "sleep-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sleep-ecosystem.png",
  },
  {
    name: "REGNUM",
    symbol: "REG",
    slug: "regnum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/regnum.png",
  },
  {
    name: "Beach Token (new)",
    symbol: "BEACH",
    slug: "beach-token-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beach-token-io.png",
  },
  {
    name: "MetaVerse-M",
    symbol: "M",
    slug: "metaverse-m",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-m.png",
  },
  {
    name: "MY UNIVERSE",
    symbol: "MYUNI",
    slug: "my-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-universe.png",
  },
  {
    name: "Banana Bucks",
    symbol: "BAB",
    slug: "banana-bucks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banana-bucks.png",
  },
  {
    name: "Cylum Finance",
    symbol: "CYM",
    slug: "cylum-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cylum-finance.png",
  },
  {
    name: "DBQuest",
    symbol: "DBQ",
    slug: "dbquest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dbquest.png",
  },
  {
    name: "SQUID Finance",
    symbol: "SQUID",
    slug: "squid-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squid-finance.png",
  },
  {
    name: "MetaFooty",
    symbol: "MFY",
    slug: "metafooty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafooty.png",
  },
  {
    name: "Enhance",
    symbol: "ENH",
    slug: "enhancetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enhancetoken.png",
  },
  {
    name: "Metavice",
    symbol: "SERVE",
    slug: "metavice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metavice.png",
  },
  {
    name: "Doshi",
    symbol: "DOSHI",
    slug: "doshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doshi.png",
  },
  {
    name: "CrimeCash",
    symbol: "CCASH",
    slug: "crimecash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crimecash.png",
  },
  {
    name: "Party Dog",
    symbol: "PDog",
    slug: "party-dog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/party-dog.png",
  },
  {
    name: "ElasticSwap",
    symbol: "TIC",
    slug: "elasticswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elasticswap.png",
  },
  {
    name: "GolDInu",
    symbol: "GINU",
    slug: "goldinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldinu.png",
  },
  {
    name: "Bedpage Coin",
    symbol: "BED",
    slug: "bedpage-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bedpage-coin.png",
  },
  {
    name: "BabyApe",
    symbol: "BAPE",
    slug: "babyape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyape.png",
  },
  {
    name: "Generous Robots DAO",
    symbol: "GEAR",
    slug: "generous-robots-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/generous-robots-dao.png",
  },
  {
    name: "Singer Community Coin",
    symbol: "SINGER",
    slug: "singer-community-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/singer-community-coin.png",
  },
  {
    name: "DCVC FINANCE",
    symbol: "DCVC",
    slug: "dcvc-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dcvc-finance.png",
  },
  {
    name: "MiniTesla",
    symbol: "MINITESLA",
    slug: "minitesla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minitesla.png",
  },
  {
    name: "GemUni",
    symbol: "GENIX",
    slug: "genix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genix.png",
  },
  {
    name: "HADO NFT",
    symbol: "HDO",
    slug: "hadonft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hadonft.png",
  },
  {
    name: "rhoUSDC",
    symbol: "RHOUSDC",
    slug: "rhousdc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rhousdc.png",
  },
  {
    name: "Futuball",
    symbol: "FB",
    slug: "futuball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futuball.png",
  },
  {
    name: "Autumn",
    symbol: "AUTUMN",
    slug: "autumn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/autumn.png",
  },
  {
    name: "Prometheus",
    symbol: "PHI",
    slug: "prometheus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prometheus.png",
  },
  {
    name: "AJEVERSE",
    symbol: "AJE",
    slug: "ajeverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ajeverse.png",
  },
  {
    name: "Dx Spot",
    symbol: "DXS",
    slug: "dx-spot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dx-spot.png",
  },
  {
    name: "Ride To Earn",
    symbol: "RD2E",
    slug: "ride-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ride-to-earn.png",
  },
  {
    name: "Famous Fox Federation",
    symbol: "FOXY",
    slug: "famous-fox-federation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/famous-fox-federation.png",
  },
  {
    name: "SafeMoonomics",
    symbol: "SAFEMOONOMICS",
    slug: "safemoonomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemoonomics.png",
  },
  {
    name: "Algomint",
    symbol: "GOETH",
    slug: "goeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goeth.png",
  },
  {
    name: "RedChillies",
    symbol: "REDC",
    slug: "redchillies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redchillies.png",
  },
  {
    name: "oxSOLID",
    symbol: "OXSOLID",
    slug: "oxsolid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oxsolid.png",
  },
  {
    name: "Flokirai",
    symbol: "FLOKIRAI",
    slug: "flokirai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokirai.png",
  },
  {
    name: "VentiSwap Token",
    symbol: "VST",
    slug: "ventiswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ventiswap-token.png",
  },
  {
    name: "Seed Club",
    symbol: "CLUB",
    slug: "seed-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seed-club.png",
  },
  {
    name: "MERLIN COINS",
    symbol: "MERC",
    slug: "merlin-coins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merlin-coins.png",
  },
  {
    name: "SOLACE",
    symbol: "SOLACE",
    slug: "solace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solace.png",
  },
  {
    name: "Spectra",
    symbol: "SPC",
    slug: "spectra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spectra.png",
  },
  {
    name: "PayPDM",
    symbol: "PYD",
    slug: "paypdm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paypdm.png",
  },
  {
    name: "Rogan Coin",
    symbol: "ROGAN",
    slug: "rogan-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rogan-coin.png",
  },
  {
    name: "Lizard Token",
    symbol: "LIZARD",
    slug: "lizard-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lizard-token.png",
  },
  {
    name: "Shera tokens",
    symbol: "SHR",
    slug: "shera-tokens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shera-tokens.png",
  },
  {
    name: "Booby Trap",
    symbol: "BBT",
    slug: "booby-trap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/booby-trap.png",
  },
  {
    name: "Crypto Blocks",
    symbol: "BLOCKS",
    slug: "crypto-blocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-blocks.png",
  },
  {
    name: "JOON",
    symbol: "JOON",
    slug: "joon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joon.png",
  },
  {
    name: "MetaZoon",
    symbol: "MEZ",
    slug: "metazoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metazoon.png",
  },
  {
    name: "Phaeton",
    symbol: "PHAE",
    slug: "phaeton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phaeton.png",
  },
  {
    name: "Best Luck Token",
    symbol: "777 LUCK",
    slug: "best-luck-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/best-luck-token.png",
  },
  {
    name: "Serenity",
    symbol: "SEREN",
    slug: "serenity-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/serenity-token.png",
  },
  {
    name: "SnakeCity",
    symbol: "SNCT",
    slug: "snakecity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snakecity.png",
  },
  {
    name: "Crypto Bank",
    symbol: "CBT",
    slug: "crypto-bank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-bank.png",
  },
  {
    name: "MongooseCoin",
    symbol: "MONGOOSE",
    slug: "mongoosecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mongoosecoin.png",
  },
  {
    name: "Vanguard Real Estate Tokenized Stock Defichain",
    symbol: "DVNQ",
    slug: "vanguard-real-estate-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanguard-real-estate-tokenized-stock-defichain.png",
  },
  {
    name: "Panther Protocol",
    symbol: "ZKP",
    slug: "panther-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/panther-protocol.png",
  },
  {
    name: "LinkSync",
    symbol: "SYNC",
    slug: "linksync",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linksync.png",
  },
  {
    name: "FMONEY FINANCE",
    symbol: "FMON",
    slug: "fmoney-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fmoney-finance.png",
  },
  {
    name: "Uzyth",
    symbol: "ZYTH",
    slug: "uzyth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uzyth.png",
  },
  {
    name: "BuffaloSwap HORN",
    symbol: "HORN",
    slug: "buffaloswap-horn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buffaloswap-horn.png",
  },
  {
    name: "OMM Finance",
    symbol: "OMM",
    slug: "omm-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omm-finance.png",
  },
  {
    name: "Hashtagger.com",
    symbol: "MOOO",
    slug: "hashtagger-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashtagger-com.png",
  },
  {
    name: "Inu Jump and the Temple of Shiba",
    symbol: "INUJUMP",
    slug: "inu-jump-and-the-temple-of-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inu-jump-and-the-temple-of-shiba.png",
  },
  {
    name: "Sportoken",
    symbol: "SPT",
    slug: "sportoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sportoken.png",
  },
  {
    name: "MetaDerby",
    symbol: "DBY",
    slug: "metaderby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaderby.png",
  },
  {
    name: "StakHolders",
    symbol: "SH",
    slug: "stakholders",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakholders.png",
  },
  {
    name: "Buff Samo",
    symbol: "BSAMO",
    slug: "buff-samo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buff-samo.png",
  },
  {
    name: "Interest Protocol",
    symbol: "IPT",
    slug: "interest-protocol-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/interest-protocol-token.png",
  },
  {
    name: "Kocaelispor Fan Token",
    symbol: "KSTT",
    slug: "kocaelispor-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kocaelispor-fan-token.png",
  },
  {
    name: "Capital DAO Protocol",
    symbol: "CDS",
    slug: "capital-dao-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/capital-dao-protocol.png",
  },
  {
    name: "Winner Block",
    symbol: "WBLOCK",
    slug: "winner-block",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winner-block.png",
  },
  {
    name: "One World",
    symbol: "OWO",
    slug: "one-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-world.png",
  },
  {
    name: "TCHALLA",
    symbol: "TCHA",
    slug: "tchalla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tchalla.png",
  },
  {
    name: "REU (BSC)",
    symbol: "REU",
    slug: "reu-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reu-bsc.png",
  },
  {
    name: "Ecoin Finance",
    symbol: "ECOIN",
    slug: "e-coin-finance-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/e-coin-finance-new.png",
  },
  {
    name: "BEAGLE INU",
    symbol: "BEAGLE",
    slug: "beagle-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beagle-inu.png",
  },
  {
    name: "PYRAMIDWALK",
    symbol: "PYRA",
    slug: "pyramidwalk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyramidwalk.png",
  },
  {
    name: "Exalt",
    symbol: "EXALT",
    slug: "exalt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exalt.png",
  },
  {
    name: "Brokkr",
    symbol: "BRO",
    slug: "brokkr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brokkr.png",
  },
  {
    name: "GENRE",
    symbol: "GENRE",
    slug: "genre",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genre.png",
  },
  {
    name: "Shiba Dragon",
    symbol: "SHIBAD",
    slug: "shiba-dragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-dragon.png",
  },
  {
    name: "Choice Coin",
    symbol: "CHOICE",
    slug: "choice-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/choice-coin.png",
  },
  {
    name: "Zest Synthetic Protocol",
    symbol: "ZSP",
    slug: "zest-synthetic-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zest-synthetic-protocol.png",
  },
  {
    name: "MusesNFT",
    symbol: "MSS",
    slug: "musesnft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musesnft.png",
  },
  {
    name: "Wine Protocol",
    symbol: "WINE",
    slug: "wine-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wine-protocol.png",
  },
  {
    name: "MemeNFT V2",
    symbol: "MNFT",
    slug: "memenft-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memenft-new.png",
  },
  {
    name: "Werecoin EV Charging",
    symbol: "WRC",
    slug: "werecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/werecoin.png",
  },
  {
    name: "DigiMetaverse",
    symbol: "DGMV",
    slug: "digimetaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digimetaverse.png",
  },
  {
    name: "Shoppi Coin",
    symbol: "SHOP",
    slug: "shoppi-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shoppi-coin.png",
  },
  {
    name: "SpartaCats",
    symbol: "PURR",
    slug: "spartacats",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spartacats.png",
  },
  {
    name: "Yokai Money",
    symbol: "YOKAI",
    slug: "yokai-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yokai-money.png",
  },
  {
    name: "Urfaspor Token",
    symbol: "URFA",
    slug: "urfaspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/urfaspor-token.png",
  },
  {
    name: "Token Echo",
    symbol: "ECHO",
    slug: "token-echo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/token-echo.png",
  },
  {
    name: "altFINS",
    symbol: "AFINS",
    slug: "altfins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altfins.png",
  },
  {
    name: "Togashi Inu",
    symbol: "TOGASHI",
    slug: "togashi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/togashi-inu.png",
  },
  {
    name: "Ark Of The Universe",
    symbol: "ARKS",
    slug: "ark-of-the-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ark-of-the-universe.png",
  },
  {
    name: "HelpSeed",
    symbol: "HELPS",
    slug: "helpseed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helpseed.png",
  },
  {
    name: "Hesman Shard",
    symbol: "HES",
    slug: "hesman-legend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hesman-legend.png",
  },
  {
    name: "Spookeletons Token",
    symbol: "SPKL",
    slug: "spookeletons-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spookeletons-token.png",
  },
  {
    name: "Moby Dick V2",
    symbol: "MOBY",
    slug: "moby-dick-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moby-dick-new.png",
  },
  {
    name: "Roller Inu",
    symbol: "ROI",
    slug: "roller-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roller-inu.png",
  },
  {
    name: "Ignite",
    symbol: "IGT",
    slug: "ignite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ignite.png",
  },
  {
    name: "Ruffy Coin",
    symbol: "RUFFY",
    slug: "ruffy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ruffy-coin.png",
  },
  {
    name: "Kimetsu Inu",
    symbol: "KIMETSU",
    slug: "kimetsu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kimetsu-inu.png",
  },
  {
    name: "MjackSwap",
    symbol: "MJACK",
    slug: "mjackswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mjackswap.png",
  },
  {
    name: "PRiVCY",
    symbol: "PRIV",
    slug: "privcy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/privcy.png",
  },
  {
    name: "Octaverse Games",
    symbol: "OVG",
    slug: "octaverse-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octaverse-games.png",
  },
  {
    name: "Pool Token",
    symbol: "POOL",
    slug: "pool-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pool-token.png",
  },
  {
    name: "Orne",
    symbol: "ORNE",
    slug: "orne",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orne.png",
  },
  {
    name: "NFT11",
    symbol: "NFT11",
    slug: "nft11",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft11.png",
  },
  {
    name: "Polygon Parrot Egg",
    symbol: "PPEGG",
    slug: "parrot-egg-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parrot-egg-polygon.png",
  },
  {
    name: "Glide Finance",
    symbol: "GLIDE",
    slug: "glide-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glide-finance.png",
  },
  {
    name: "TYCHE Lotto",
    symbol: "TYCHE",
    slug: "tyche-lotto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tyche-lotto.png",
  },
  {
    name: "Skate Metaverse Coin",
    symbol: "SMC",
    slug: "skate-metaverse-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skate-metaverse-coin.png",
  },
  {
    name: "BEPIS",
    symbol: "BEPIS",
    slug: "bepis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bepis.png",
  },
  {
    name: "DaddyBezos",
    symbol: "DJBZ",
    slug: "daddybezos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddybezos.png",
  },
  {
    name: "Quid Token",
    symbol: "QUID",
    slug: "quid-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quid-token.png",
  },
  {
    name: "Brainiac Farm",
    symbol: "BRAINS",
    slug: "brainiac-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brainiac-farm.png",
  },
  {
    name: "ShibYield Inu",
    symbol: "SHIBYIELD",
    slug: "shibyield-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibyield-inu.png",
  },
  {
    name: "ACEToken",
    symbol: "ACE",
    slug: "acetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acetoken.png",
  },
  {
    name: "Compendium Finance",
    symbol: "CMFI",
    slug: "compendium-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compendium-finance.png",
  },
  {
    name: "Meta Course",
    symbol: "COURSE",
    slug: "meta-course",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-course.png",
  },
  {
    name: "Subawu Token",
    symbol: "SUBAWU",
    slug: "subawu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/subawu-token.png",
  },
  {
    name: "Bluca",
    symbol: "BLUC",
    slug: "bluca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bluca.png",
  },
  {
    name: "Krypto Kitty",
    symbol: "KTY",
    slug: "krypto-kitty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krypto-kitty.png",
  },
  {
    name: "Christmas BNB",
    symbol: "XMASBNB",
    slug: "christmas-bnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/christmas-bnb.png",
  },
  {
    name: "Iotex Monster Go",
    symbol: "MTGO",
    slug: "iotex-monster-go",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iotex-monster-go.png",
  },
  {
    name: "OtherDAO",
    symbol: "OTHR",
    slug: "otherdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otherdao.png",
  },
  {
    name: "Shiba wolf",
    symbol: "SHIBAW",
    slug: "shiba-wolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-wolf.png",
  },
  {
    name: "SherlockDoge",
    symbol: "LOCK",
    slug: "sherlockdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sherlockdoge.png",
  },
  {
    name: "No Bull",
    symbol: "NB",
    slug: "no-bull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/no-bull.png",
  },
  {
    name: "Big G Lottery Token",
    symbol: "BGL",
    slug: "big-g-lottery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-g-lottery.png",
  },
  {
    name: "Crypto Stake Token",
    symbol: "CST",
    slug: "crypto-stake-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-stake-token.png",
  },
  {
    name: "PikaVerse",
    symbol: "PIKA",
    slug: "pikaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pikaverse.png",
  },
  {
    name: "CrazySharo",
    symbol: "SHARO",
    slug: "crazysharo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crazysharo.png",
  },
  {
    name: "DiversiFi Blue",
    symbol: "DVFB",
    slug: "diversifi-blue",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diversifi-blue.png",
  },
  {
    name: "Kaizilla",
    symbol: "KAIZILLA",
    slug: "kaizilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaizilla.png",
  },
  {
    name: "AvaOne Finance",
    symbol: "AVAO",
    slug: "avaone-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaone-finance.png",
  },
  {
    name: "Stack Treasury",
    symbol: "STACKT",
    slug: "stack-treasury",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stack-treasury.png",
  },
  {
    name: "Rielcoin",
    symbol: "RIC",
    slug: "rielcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rielcoin.png",
  },
  {
    name: "BuyMainStreet",
    symbol: "$MAINST",
    slug: "buymainstreet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buymainstreet.png",
  },
  {
    name: "World of Farms",
    symbol: "WOF",
    slug: "world-of-farms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-of-farms.png",
  },
  {
    name: "Animal Friends United",
    symbol: "AFU",
    slug: "animal-friends-united",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animal-friends-united.png",
  },
  {
    name: "CryptoHorse",
    symbol: "CHORSE",
    slug: "cryptohorse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptohorse.png",
  },
  {
    name: "LlamaSwap",
    symbol: "LAMA",
    slug: "llamaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/llamaswap.png",
  },
  {
    name: "DODbase",
    symbol: "DODB",
    slug: "dodbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dodbase.png",
  },
  {
    name: "Froggies",
    symbol: "FROGGIES",
    slug: "froggies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/froggies.png",
  },
  {
    name: "AvaPay",
    symbol: "AVAPAY",
    slug: "avapay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avapay.png",
  },
  {
    name: "SnowFloki",
    symbol: "SNOWFLOKI",
    slug: "snowfloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowfloki.png",
  },
  {
    name: "THEORY",
    symbol: "THRY",
    slug: "theory",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/theory.png",
  },
  {
    name: "Solcats",
    symbol: "MEOW",
    slug: "solcats",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solcats.png",
  },
  {
    name: "Blockquare Token",
    symbol: "BST",
    slug: "blocksquare-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocksquare-token.png",
  },
  {
    name: "ALL BEST ICO SATOSHI",
    symbol: "SATSALL",
    slug: "all-best-ico-satoshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/all-best-ico-satoshi.png",
  },
  {
    name: "Winterdog",
    symbol: "WDOG",
    slug: "winterdog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winterdog.png",
  },
  {
    name: "EURONIN",
    symbol: "EURONIN",
    slug: "euronin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/euronin.png",
  },
  {
    name: "Curve.fi gDAI/gUSDC/gUSDT",
    symbol: "G3CRV",
    slug: "curve-fi-gdai-gusdc-gusdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/curve-fi-gdai-gusdc-gusdt.png",
  },
  {
    name: "Popcorn",
    symbol: "POP",
    slug: "popcorn-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/popcorn-network.png",
  },
  {
    name: "Mead",
    symbol: "MEAD",
    slug: "mead",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mead.png",
  },
  {
    name: "BARFIGHT",
    symbol: "BFIGHT",
    slug: "barfight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/barfight.png",
  },
  {
    name: "ABCMETA",
    symbol: "META",
    slug: "abcmeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abcmeta.png",
  },
  {
    name: "Jeet",
    symbol: "JEET",
    slug: "jeet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jeet.png",
  },
  {
    name: "GENZ Token",
    symbol: "GENZ",
    slug: "genz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genz-token.png",
  },
  {
    name: "Solana CUM",
    symbol: "SCUM",
    slug: "solana-cum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solana-cum.png",
  },
  {
    name: "UnityCom",
    symbol: "UNITYCOM",
    slug: "unitycom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unitycom.png",
  },
  {
    name: "Burrow",
    symbol: "BRRR",
    slug: "burrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burrow.png",
  },
  {
    name: "Markhor Meta",
    symbol: "MM",
    slug: "markhor-meta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/markhor-meta.png",
  },
  {
    name: "DiemLibre",
    symbol: "DLB",
    slug: "diemlibre",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diemlibre.png",
  },
  {
    name: "Mirror",
    symbol: "NUL",
    slug: "mirror",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirror.png",
  },
  {
    name: "SuperBNB.Finance",
    symbol: "SPB",
    slug: "superbnb-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superbnb-finance.png",
  },
  {
    name: "Neptune",
    symbol: "NPTUN",
    slug: "neptune",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neptune.png",
  },
  {
    name: "Mystic Poker",
    symbol: "MYP",
    slug: "mystic-poker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mystic-poker.png",
  },
  {
    name: "Doge Cheems",
    symbol: "$DHEEMS",
    slug: "doge-cheems",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-cheems.png",
  },
  {
    name: "MetaMate",
    symbol: "MTM",
    slug: "metamatenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamatenft.png",
  },
  {
    name: "Caesar Finance",
    symbol: "CAESAR",
    slug: "caesar-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caesar-finance.png",
  },
  {
    name: "Tarot",
    symbol: "TAROT",
    slug: "tarot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tarot.png",
  },
  {
    name: "BOHR",
    symbol: "BR",
    slug: "bohr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bohr.png",
  },
  {
    name: "Pyrrho DeFi",
    symbol: "PYO",
    slug: "pyrrho-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyrrho-defi.png",
  },
  {
    name: "Dry Doge Metaverse",
    symbol: "DRYDOGE",
    slug: "dry-doge-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dry-doge-metaverse.png",
  },
  {
    name: "ForeverGrow",
    symbol: "FGROW",
    slug: "forevergrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forevergrow.png",
  },
  {
    name: "Low Float Gem",
    symbol: "LFG",
    slug: "low-float-gem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/low-float-gem.png",
  },
  {
    name: "SonOfSaitama",
    symbol: "SOS",
    slug: "sonofsaitama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonofsaitama.png",
  },
  {
    name: "Moonions",
    symbol: "MOONION",
    slug: "moonions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonions.png",
  },
  {
    name: "City of Dream",
    symbol: "COD",
    slug: "city-of-dream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/city-of-dream.png",
  },
  {
    name: "RatRace",
    symbol: "RATRACE",
    slug: "ratrace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ratrace.png",
  },
  {
    name: "NovaXSolar",
    symbol: "XSLR",
    slug: "novaxsolar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/novaxsolar.png",
  },
  {
    name: "Canadian Inuit Dog",
    symbol: "CADINU",
    slug: "canadian-inuit-dog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/canadian-inuit-dog.png",
  },
  {
    name: "Encyclopedia wTa",
    symbol: "ENCwTa",
    slug: "encyclopedia-wta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/encyclopedia-wta.png",
  },
  {
    name: "Kult of Kek",
    symbol: "KOK",
    slug: "kult-of-kek",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kult-of-kek.png",
  },
  {
    name: "Triveum",
    symbol: "TRV",
    slug: "triveum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/triveum.png",
  },
  {
    name: "Kaiken",
    symbol: "KAIDHT",
    slug: "kaiken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaiken.png",
  },
  {
    name: "D3 Protocol",
    symbol: "DEFI",
    slug: "d3-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/d3-protocol.png",
  },
  {
    name: "Nole NPC",
    symbol: "NPC",
    slug: "nole-npc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nole-npc.png",
  },
  {
    name: "DSC Mix",
    symbol: "MIX",
    slug: "dsc-mix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dsc-mix.png",
  },
  {
    name: "DumpBuster",
    symbol: "GTFO",
    slug: "dumpbuster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dumpbuster.png",
  },
  {
    name: "Bingo Doge",
    symbol: "BDOGE",
    slug: "bingo-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bingo-doge.png",
  },
  {
    name: "Poopsicle",
    symbol: "POOP",
    slug: "poopsicle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poopsicle.png",
  },
  {
    name: "MetaWar Token",
    symbol: "MTWR",
    slug: "metawar-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metawar-token.png",
  },
  {
    name: "Film Crib",
    symbol: "CRI3",
    slug: "film-crib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/film-crib.png",
  },
  {
    name: "DChess",
    symbol: "KING",
    slug: "dchess",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dchess.png",
  },
  {
    name: "First Eleven",
    symbol: "F11",
    slug: "first-eleven",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/first-eleven.png",
  },
  {
    name: "KOMPETE",
    symbol: "KOMPETE",
    slug: "kompete",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kompete.png",
  },
  {
    name: "INFAM",
    symbol: "INF",
    slug: "infam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infam.png",
  },
  {
    name: "USD mars",
    symbol: "USDm",
    slug: "usd-mars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usd-mars.png",
  },
  {
    name: "Moon",
    symbol: "MOON",
    slug: "moon-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-app.png",
  },
  {
    name: "GrimToken",
    symbol: "GRIM",
    slug: "grimtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grimtoken.png",
  },
  {
    name: "PRYZ Token",
    symbol: "PRYZ",
    slug: "pryz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pryz-token.png",
  },
  {
    name: "VICSTEP",
    symbol: "VIC",
    slug: "vicstep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vicstep.png",
  },
  {
    name: "Idle Mystic Token",
    symbol: "IMT",
    slug: "idle-mystic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idle-mystic-token.png",
  },
  {
    name: "Snail Trail",
    symbol: "SLIME",
    slug: "snail-trail",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snail-trail.png",
  },
  {
    name: "Shika Token",
    symbol: "SHIKA",
    slug: "shika-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shika-token.png",
  },
  {
    name: "MEV Repellent",
    symbol: "MEVREPEL",
    slug: "mev-repellent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mev-repellent.png",
  },
  {
    name: "PLS2E.io",
    symbol: "P2E",
    slug: "pls2e",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pls2e.png",
  },
  {
    name: "AirDropper",
    symbol: "AIR",
    slug: "airdropper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/airdropper.png",
  },
  {
    name: "MoonRock V2",
    symbol: "ROCK",
    slug: "moonrock-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonrock-v2.png",
  },
  {
    name: "Magic Of Universe",
    symbol: "CRA",
    slug: "magic-of-universe-cra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-of-universe-cra.png",
  },
  {
    name: "Ascend Node Club",
    symbol: "ASND",
    slug: "ascend-node-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ascend-node-club.png",
  },
  {
    name: "ProjectMars",
    symbol: "MARS",
    slug: "projectmars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/projectmars.png",
  },
  {
    name: "IXO",
    symbol: "IXO",
    slug: "ixo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ixo.png",
  },
  {
    name: "Ethera Black",
    symbol: "ETB",
    slug: "ethera-black",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethera-black.png",
  },
  {
    name: "Coin Helper Token",
    symbol: "CHT",
    slug: "coin-helper-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-helper-token.png",
  },
  {
    name: "HODLX",
    symbol: "HODLX",
    slug: "hodlx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodlx.png",
  },
  {
    name: "AAptitude",
    symbol: "AAPT",
    slug: "aaptitude",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aaptitude.png",
  },
  {
    name: "Syrex",
    symbol: "SRX",
    slug: "syrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/syrex.png",
  },
  {
    name: "Goldenzone",
    symbol: "GLD",
    slug: "goldenzone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldenzone.png",
  },
  {
    name: "Maximus TRIO",
    symbol: "TRIO",
    slug: "maximus-trio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maximus-trio.png",
  },
  {
    name: "VersaGames",
    symbol: "VERSA",
    slug: "versagames",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/versagames.png",
  },
  {
    name: "JETOKEN",
    symbol: "JETS",
    slug: "jetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jetoken.png",
  },
  {
    name: "MyTVchain [New]",
    symbol: "MYTV",
    slug: "mytvchain-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mytvchain-2.png",
  },
  {
    name: "BitGoo",
    symbol: "BTG",
    slug: "bitgoo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitgoo.png",
  },
  {
    name: "Freedom Protocol",
    symbol: "FREE",
    slug: "freedom-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freedom-protocol.png",
  },
  {
    name: "Toy Doge Coin",
    symbol: "TOYDOGE",
    slug: "toy-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toy-doge-coin.png",
  },
  {
    name: "Plateau Finance",
    symbol: "PLT",
    slug: "plateau-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plateau-finance.png",
  },
  {
    name: "XRP Apes",
    symbol: "XRPAPE",
    slug: "xrp-apes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrp-apes.png",
  },
  {
    name: "Kenshi",
    symbol: "KENSHI",
    slug: "kenshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kenshi.png",
  },
  {
    name: "Niob Finance",
    symbol: "NIOB",
    slug: "niob-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niob-finance.png",
  },
  {
    name: "Useless (V3)",
    symbol: "USE",
    slug: "useless-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/useless-new.png",
  },
  {
    name: "Have Fun Staying Poor",
    symbol: "HFSP",
    slug: "have-fun-staying-poor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/have-fun-staying-poor.png",
  },
  {
    name: "Metabolic",
    symbol: "MTBC",
    slug: "metabolic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabolic.png",
  },
  {
    name: "WOLFCOIN",
    symbol: "WOLF",
    slug: "wolfcoin-wolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolfcoin-wolf.png",
  },
  {
    name: "DeFi Land Gold",
    symbol: "GOLDY",
    slug: "defi-land-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-land-gold.png",
  },
  {
    name: "Web3Gold",
    symbol: "WRB3G",
    slug: "web3gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web3gold.png",
  },
  {
    name: "Good Driver Reward Token",
    symbol: "GDRT",
    slug: "good-driver-reward-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/good-driver-reward-token.png",
  },
  {
    name: "Mob Inu",
    symbol: "MOB",
    slug: "mob-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mob-inu.png",
  },
  {
    name: "N-Word Pass",
    symbol: "NWORDPASS",
    slug: "n-word-pass",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/n-word-pass.png",
  },
  {
    name: "PlutoPepe",
    symbol: "PLUTO",
    slug: "plutopepe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plutopepe.png",
  },
  {
    name: "Brigadeiro.Finance V2",
    symbol: "BRIGADEIRO",
    slug: "brigadeiro-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brigadeiro-v2.png",
  },
  {
    name: "Rover Inu Token",
    symbol: "ROVER",
    slug: "rover-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rover-token.png",
  },
  {
    name: "Cricket Star Manager",
    symbol: "CSM",
    slug: "cricket-star-manager",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cricket-star-manager.png",
  },
  {
    name: "Alnassr FC fan token",
    symbol: "NASSR",
    slug: "alnassr-fc-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alnassr-fc-fan-token.png",
  },
  {
    name: "Metria",
    symbol: "METR",
    slug: "metria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metria.png",
  },
  {
    name: "Solana Nut",
    symbol: "SOLNUT",
    slug: "solana-nut",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solana-nut.png",
  },
  {
    name: "NSKSwap",
    symbol: "NSK",
    slug: "nskswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nskswap.png",
  },
  {
    name: "BLACK SHIBA",
    symbol: "$BSHIB",
    slug: "black-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-shiba.png",
  },
  {
    name: "FarmersOnly Onion",
    symbol: "ONION",
    slug: "farmersonly-onion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmersonly-onion.png",
  },
  {
    name: "Crypto Carbon Energy",
    symbol: "CYCE",
    slug: "crypto-carbon-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-carbon-energy.png",
  },
  {
    name: "FanTerra",
    symbol: "FTERRA",
    slug: "fanterra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanterra.png",
  },
  {
    name: "North Pole",
    symbol: "NORTH",
    slug: "north-pole",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/north-pole.png",
  },
  {
    name: "A-NATION",
    symbol: "ANATION",
    slug: "a-nation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/a-nation.png",
  },
  {
    name: "Artizen",
    symbol: "ATNT",
    slug: "artizen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artizen.png",
  },
  {
    name: "Dexalot",
    symbol: "ALOT",
    slug: "dexalot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexalot.png",
  },
  {
    name: "Tales of Xtalnia",
    symbol: "XTAL",
    slug: "tales-of-xtalnia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tales-of-xtalnia.png",
  },
  {
    name: "API INU",
    symbol: "API",
    slug: "api-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/api-inu.png",
  },
  {
    name: "Safebuy",
    symbol: "SBF",
    slug: "safebuy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safebuy.png",
  },
  {
    name: "The Nature Token",
    symbol: "NATURE",
    slug: "the-nature-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-nature-token.png",
  },
  {
    name: "BabyWhale",
    symbol: "BBW",
    slug: "babywhale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babywhale.png",
  },
  {
    name: "Decentralized Liquidity Program",
    symbol: "DLP",
    slug: "decentralized-liquidity-program",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-liquidity-program.png",
  },
  {
    name: "Viral Inu",
    symbol: "VINU",
    slug: "viral-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viral-inu.png",
  },
  {
    name: "Piggy Share",
    symbol: "PSHARE",
    slug: "piggy-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piggy-share.png",
  },
  {
    name: "Sriracha Inu",
    symbol: "SRIRACHA",
    slug: "sriracha-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sriracha-inu.png",
  },
  {
    name: "Tales Of Chain",
    symbol: "TALE",
    slug: "tales-of-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tales-of-chain.png",
  },
  {
    name: "Dignity Gold",
    symbol: "DIGAU",
    slug: "dignity-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dignity-gold.png",
  },
  {
    name: "Dueter",
    symbol: "DUO",
    slug: "dueter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dueter.png",
  },
  {
    name: "OLA",
    symbol: "OLA",
    slug: "ola-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ola-city.png",
  },
  {
    name: "Tikky Inu",
    symbol: "TIKKY",
    slug: "tikky-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tikky-inu.png",
  },
  {
    name: "Nafstars",
    symbol: "NSTARS",
    slug: "nafstars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nafstars.png",
  },
  {
    name: "SafuFide",
    symbol: "SAFEST",
    slug: "safufide",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safufide.png",
  },
  {
    name: "SHIBA LIGHT",
    symbol: "SHIBT",
    slug: "shiba-light",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-light.png",
  },
  {
    name: "Chihuahua In Space",
    symbol: "CIS",
    slug: "chihuahua-in-space",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chihuahua-in-space.png",
  },
  {
    name: "Icel Idman Yurdu Token",
    symbol: "MIY",
    slug: "icel-idman-yurdu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icel-idman-yurdu-token.png",
  },
  {
    name: "StepD",
    symbol: "STEPD",
    slug: "stepd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stepd.png",
  },
  {
    name: "Alligatork",
    symbol: "TORK",
    slug: "alligatork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alligatork.png",
  },
  {
    name: "Luna Inu",
    symbol: "$LUNAINU",
    slug: "luna-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luna-inu.png",
  },
  {
    name: "Leonidas Token",
    symbol: "LEONIDAS",
    slug: "leonidas-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leonidas-token.png",
  },
  {
    name: "Bitwin24",
    symbol: "BWI",
    slug: "bitwin24",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitwin24.png",
  },
  {
    name: "SWERVE Protocol",
    symbol: "SWERVE",
    slug: "swerve-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swerve-protocol.png",
  },
  {
    name: "Asuna INU",
    symbol: "ASUNAINU",
    slug: "asuna-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asuna-inu.png",
  },
  {
    name: "DiversiFi",
    symbol: "DFI",
    slug: "diversifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diversifi.png",
  },
  {
    name: "Bold Point",
    symbol: "BPT",
    slug: "bold-point",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bold-point.png",
  },
  {
    name: "DoggyStyle Coin",
    symbol: "DSC",
    slug: "doggystyle-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doggystyle-coin.png",
  },
  {
    name: "MongolNFT Coin",
    symbol: "MNFT",
    slug: "mongolnft-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mongolnft-coin.png",
  },
  {
    name: "Klaymore Stakehouse",
    symbol: "HOUSE",
    slug: "klaymore-stakehouse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klaymore-stakehouse.png",
  },
  {
    name: "Qube Crypto Space",
    symbol: "QUBE",
    slug: "qube-crypto-space",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qube-crypto-space.png",
  },
  {
    name: "DogeMania",
    symbol: "DOGEMANIA",
    slug: "dogemania",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogemania.png",
  },
  {
    name: "FantomPAD",
    symbol: "FPAD",
    slug: "fantompad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantompad.png",
  },
  {
    name: "MetaAir",
    symbol: "MAIR",
    slug: "metaair",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaair.png",
  },
  {
    name: "BeachBoyz",
    symbol: "BOYZ",
    slug: "beachboyz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beachboyz.png",
  },
  {
    name: "Baskonia Fan Token",
    symbol: "BKN",
    slug: "baskonia-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baskonia-fan-token.png",
  },
  {
    name: "Kitty Solana",
    symbol: "KITTY",
    slug: "kitty-solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitty-solana.png",
  },
  {
    name: "PlutusFi",
    symbol: "PLUT",
    slug: "plutusfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plutusfi.png",
  },
  {
    name: "Axus Coin Project",
    symbol: "AXUS",
    slug: "axus-coin-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axus-coin-project.png",
  },
  {
    name: "MoonForce",
    symbol: "FORCE",
    slug: "moonforce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonforce.png",
  },
  {
    name: "Sugarland",
    symbol: "SUGAR",
    slug: "sugarland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sugarland.png",
  },
  {
    name: "MsgSender",
    symbol: "MSG",
    slug: "msgsender",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/msgsender.png",
  },
  {
    name: "Lazy Shiba",
    symbol: "LAZYSHIBA",
    slug: "lazy-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lazy-shiba.png",
  },
  {
    name: "OryxFi",
    symbol: "ORYX",
    slug: "oryxfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oryxfi.png",
  },
  {
    name: "Apple",
    symbol: "AMB",
    slug: "apple-binemon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apple-binemon.png",
  },
  {
    name: "Cyclepunk",
    symbol: "BIKE",
    slug: "cyclepunk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyclepunk.png",
  },
  {
    name: "Fuck Musk",
    symbol: "FUCKMUSK",
    slug: "fuck-musk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fuck-musk.png",
  },
  {
    name: "Lucky Shinu",
    symbol: "LUSHI",
    slug: "lucky-shinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-shinu.png",
  },
  {
    name: "NerveFlux",
    symbol: "NERVE",
    slug: "nerveflux",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nerveflux.png",
  },
  {
    name: "Balanced Token",
    symbol: "BALN",
    slug: "balanced-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balanced-token.png",
  },
  {
    name: "SOLVIEW",
    symbol: "SOLV",
    slug: "solview",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solview.png",
  },
  {
    name: "Balanced Dollars",
    symbol: "bnUSD",
    slug: "balanced-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balanced-usd.png",
  },
  {
    name: "Staked ICX",
    symbol: "sICX",
    slug: "staked-icx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/staked-icx.png",
  },
  {
    name: "SaucerSwap",
    symbol: "SAUCE",
    slug: "saucerswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saucerswap.png",
  },
  {
    name: "smolting inu",
    symbol: "SMOL",
    slug: "smolting-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smolting-inu.png",
  },
  {
    name: "Ethereum Eagle",
    symbol: "EGL",
    slug: "ethereum-eagle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-eagle.png",
  },
  {
    name: "Sierra",
    symbol: "SRA",
    slug: "sierra-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sierra-dao.png",
  },
  {
    name: "Bontecoin",
    symbol: "BONTE",
    slug: "bontecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bontecoin.png",
  },
  {
    name: "Flappy Shiba Inu",
    symbol: "FSINU",
    slug: "flappy-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flappy-shiba-inu.png",
  },
  {
    name: "Star Chain",
    symbol: "STAR1",
    slug: "star-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/star-chain.png",
  },
  {
    name: "Odin Platform",
    symbol: "ODN",
    slug: "odin-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/odin-platform.png",
  },
  {
    name: "Sexn",
    symbol: "SST",
    slug: "sexn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sexn.png",
  },
  {
    name: "WadzPay Token",
    symbol: "WTK",
    slug: "wadzpay-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wadzpay-token.png",
  },
  {
    name: "AMPLESWAP",
    symbol: "AMPLE",
    slug: "ampleswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ampleswap.png",
  },
  {
    name: "Blockify.Games",
    symbol: "BLOCKIFY",
    slug: "blockify-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockify-games.png",
  },
  {
    name: "NFTMania",
    symbol: "$MANIA",
    slug: "nftmania",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftmania.png",
  },
  {
    name: "Grape",
    symbol: "GRAPE",
    slug: "grapetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grapetoken.png",
  },
  {
    name: "Cryptonite",
    symbol: "CTNT",
    slug: "cryptonite-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptonite-token.png",
  },
  {
    name: "NFT4Play",
    symbol: "4PLAY",
    slug: "nft4play",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft4play.png",
  },
  {
    name: "xDollar",
    symbol: "XDO",
    slug: "xdollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdollar.png",
  },
  {
    name: "Profit Bls",
    symbol: "PROFIT",
    slug: "profit-bls",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/profit-bls.png",
  },
  {
    name: "OtterClam (Old)",
    symbol: "CLAM",
    slug: "otter-clam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otter-clam.png",
  },
  {
    name: "WEB3Token",
    symbol: "WEB3.0",
    slug: "web3token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web3token.png",
  },
  {
    name: "AVX Launchpad",
    symbol: "AVX",
    slug: "avx-launchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avx-launchpad.png",
  },
  {
    name: "EagonSwap Token",
    symbol: "EAGON",
    slug: "eagonswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eagonswap-token.png",
  },
  {
    name: "BabyApeFunClub",
    symbol: "BAFC",
    slug: "babyapefunclub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyapefunclub.png",
  },
  {
    name: "Masterpiece Maker",
    symbol: "MAMA",
    slug: "masterpiece-maker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/masterpiece-maker.png",
  },
  {
    name: "CryptoMines Reborn",
    symbol: "CRUX",
    slug: "cryptomines-reborn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptomines-reborn.png",
  },
  {
    name: "Adamant",
    symbol: "ADDY",
    slug: "adamant-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adamant-finance.png",
  },
  {
    name: "OtherLife",
    symbol: "OTL",
    slug: "otherlife",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otherlife.png",
  },
  {
    name: "Radio Hero",
    symbol: "RAHO",
    slug: "radio-hero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radio-hero.png",
  },
  {
    name: "DisciplesDAO",
    symbol: "DCT",
    slug: "disciplesdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/disciplesdao.png",
  },
  {
    name: "Haki Token",
    symbol: "HAKI",
    slug: "haki-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/haki-token.png",
  },
  {
    name: "Solit",
    symbol: "SLT",
    slug: "solit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solit.png",
  },
  {
    name: "GNFT",
    symbol: "GNFT",
    slug: "gnft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gnft.png",
  },
  {
    name: "Bit Castle War",
    symbol: "BCW",
    slug: "bit-castle-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit-castle-war.png",
  },
  {
    name: "Moon BTC",
    symbol: "MBTC",
    slug: "moon-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-btc.png",
  },
  {
    name: "Hare",
    symbol: "HARE",
    slug: "hare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hare.png",
  },
  {
    name: "WatchDO",
    symbol: "WDO",
    slug: "watchdo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/watchdo.png",
  },
  {
    name: "SpaceRobotDao",
    symbol: "SRD",
    slug: "spacerobotdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacerobotdao.png",
  },
  {
    name: "Bankera V2",
    symbol: "BNK",
    slug: "bankera-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankera-v2.png",
  },
  {
    name: "Tokenplay",
    symbol: "TOP",
    slug: "tokenplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenplay.png",
  },
  {
    name: "ENTERBUTTON",
    symbol: "ENTC",
    slug: "enterbutton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enterbutton.png",
  },
  {
    name: "IMMOPET Token",
    symbol: "IMPT",
    slug: "immopet-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/immopet-token.png",
  },
  {
    name: "VENO",
    symbol: "VENO",
    slug: "veno",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veno.png",
  },
  {
    name: "DreamDAO",
    symbol: "DREAM",
    slug: "dreamdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dreamdao.png",
  },
  {
    name: "Spidey Inu",
    symbol: "SPIDEY INU",
    slug: "spidey-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spidey-inu.png",
  },
  {
    name: "PlayPoseidon NFT",
    symbol: "PPP",
    slug: "playposeidon-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playposeidon-nft.png",
  },
  {
    name: "LAND OF DOGES",
    symbol: "LANDOFDOGES",
    slug: "land-of-doges",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/land-of-doges.png",
  },
  {
    name: "Ripae AVAX",
    symbol: "PAVAX",
    slug: "ripae-avax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ripae-avax.png",
  },
  {
    name: "MetaWeb3Pad",
    symbol: "METAWEB3PA",
    slug: "metaweb3pad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaweb3pad.png",
  },
  {
    name: "ELIS",
    symbol: "XLS",
    slug: "elis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elis.png",
  },
  {
    name: "Vulcano",
    symbol: "VULC",
    slug: "vulcano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vulcano.png",
  },
  {
    name: "Baby Doug",
    symbol: "BABYDOUG",
    slug: "baby-doug",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-doug.png",
  },
  {
    name: "Lydia Finance",
    symbol: "LYD",
    slug: "lydia-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lydia-finance.png",
  },
  {
    name: "Beverage Finance",
    symbol: "DRINK",
    slug: "beverage-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beverage-finance.png",
  },
  {
    name: "Quartz",
    symbol: "QTZ",
    slug: "quartz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quartz-token.png",
  },
  {
    name: "RLDX",
    symbol: "RLDX",
    slug: "rldx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rldx.png",
  },
  {
    name: "XX Platform",
    symbol: "XXP",
    slug: "xx-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xx-platform.png",
  },
  {
    name: "GemBites",
    symbol: "GBTS",
    slug: "gembites",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gembites.png",
  },
  {
    name: "OEC BTC",
    symbol: "BTCK",
    slug: "oec-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oec-btc.png",
  },
  {
    name: "Wrapped OKT",
    symbol: "WOKT",
    slug: "wrapped-okt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-okt.png",
  },
  {
    name: "Multi Strategies Capital",
    symbol: "MSC",
    slug: "multi-strategies-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multi-strategies-capital.png",
  },
  {
    name: "Crypto War",
    symbol: "$GW",
    slug: "crypto-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-war.png",
  },
  {
    name: "ZoomSwap",
    symbol: "ZM",
    slug: "zoomswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoomswap.png",
  },
  {
    name: "Utile Network",
    symbol: "UTL",
    slug: "utile-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utile-network.png",
  },
  {
    name: "Takemichi Inu",
    symbol: "TAKE",
    slug: "takemichi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/takemichi-inu.png",
  },
  {
    name: "PODO",
    symbol: "POD",
    slug: "podo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/podo.png",
  },
  {
    name: "Bankless DeFi Innovation Index",
    symbol: "GMI",
    slug: "bankless-defi-innovation-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankless-defi-innovation-index.png",
  },
  {
    name: "MedikhanaX",
    symbol: "MKX",
    slug: "medikhanax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medikhanax.png",
  },
  {
    name: "Binance Multi-Chain Capital",
    symbol: "BMCC",
    slug: "binance-multi-chain-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binance-multi-chain-capital.png",
  },
  {
    name: "Fudge",
    symbol: "$FUDGE",
    slug: "fudge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fudge.png",
  },
  {
    name: "H2O",
    symbol: "H2O",
    slug: "h2o-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/h2o-token.png",
  },
  {
    name: "KNIGHTMONSTER",
    symbol: "KMONS",
    slug: "knightmonster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knightmonster.png",
  },
  {
    name: "Koinswap",
    symbol: "KST",
    slug: "koinswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koinswap.png",
  },
  {
    name: "Peruvian National Football Team Fan Token",
    symbol: "FPFT",
    slug: "peruvian-national-football-team-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peruvian-national-football-team-fan-token.png",
  },
  {
    name: "Little Tsuki Inu",
    symbol: "LILTK",
    slug: "little-tsuki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/little-tsuki-inu.png",
  },
  {
    name: "WalkN",
    symbol: "WALKN",
    slug: "walkn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/walkn.png",
  },
  {
    name: "AddMeFast",
    symbol: "AMF",
    slug: "addmefast",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/addmefast.png",
  },
  {
    name: "Game X Change Potion",
    symbol: "GXP",
    slug: "game-x-change-potion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game-x-change-potion.png",
  },
  {
    name: "Buff Yooshi",
    symbol: "BUFFYOOSHI",
    slug: "buff-yooshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buff-yooshi.png",
  },
  {
    name: "CryptoFarming",
    symbol: "$CFAR",
    slug: "cryptofarming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptofarming.png",
  },
  {
    name: "Wipe My ASS",
    symbol: "WIPE",
    slug: "wipe-my-ass",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wipe-my-ass.png",
  },
  {
    name: "DiveWallet Token",
    symbol: "DWT",
    slug: "divewallet-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/divewallet-token.png",
  },
  {
    name: "Avaluan",
    symbol: "LUAN",
    slug: "avaluan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaluan.png",
  },
  {
    name: "BITAY Coin",
    symbol: "BITAY",
    slug: "bitay-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitay-coin.png",
  },
  {
    name: "HyFi Token",
    symbol: "HYFI",
    slug: "hyfi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyfi-token.png",
  },
  {
    name: "0xDAO V2",
    symbol: "OXD V2",
    slug: "0xdao-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0xdao-v2.png",
  },
  {
    name: "WEB3Land",
    symbol: "WEB3",
    slug: "web3land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web3land.png",
  },
  {
    name: "Crolon Mars",
    symbol: "CLMRS",
    slug: "crolon-mars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crolon-mars.png",
  },
  {
    name: "Liquid ICP",
    symbol: "LICP",
    slug: "liquid-icp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquid-icp.png",
  },
  {
    name: "Kronos Dao",
    symbol: "KRONOS",
    slug: "kronosdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kronosdao.png",
  },
  {
    name: "Poriverse",
    symbol: "RIGY",
    slug: "rigy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rigy.png",
  },
  {
    name: "Twenty22",
    symbol: "2022",
    slug: "twenty22",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twenty22.png",
  },
  {
    name: "Secret",
    symbol: "SIE",
    slug: "secret-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secret-blockchain.png",
  },
  {
    name: "Ltradex",
    symbol: "LTEX",
    slug: "ltradex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ltradex.png",
  },
  {
    name: "Nowarshiba",
    symbol: "NSHIBA",
    slug: "nowarshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nowarshiba.png",
  },
  {
    name: "MoonRaise",
    symbol: "MRT",
    slug: "moonraise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonraise.png",
  },
  {
    name: "Zoints",
    symbol: "ZEE",
    slug: "zoints",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoints.png",
  },
  {
    name: "Hosky Token",
    symbol: "HOSKY",
    slug: "hosky-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hosky-token.png",
  },
  {
    name: "Speed Star JOC",
    symbol: "JOC",
    slug: "speed-star-joc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/speed-star-joc.png",
  },
  {
    name: "ASIX+",
    symbol: "ASIX+",
    slug: "asix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asix.png",
  },
  {
    name: "MetaFocus",
    symbol: "METAFOCUS",
    slug: "metafocus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafocus.png",
  },
  {
    name: "Turkish Basketball Federation Fan Token",
    symbol: "TBFT",
    slug: "turkish-basketball-federation-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turkish-basketball-federation-fan-token.png",
  },
  {
    name: "Surf Live",
    symbol: "SURF",
    slug: "surf-live",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/surf-live.png",
  },
  {
    name: "UniqueMeta",
    symbol: "UNQM",
    slug: "uniquemeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniquemeta.png",
  },
  {
    name: "Magic Of Universe",
    symbol: "MGC",
    slug: "magic-of-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-of-universe.png",
  },
  {
    name: "VICDAO NELUM",
    symbol: "NELUM",
    slug: "vicdao-nelum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vicdao-nelum.png",
  },
  {
    name: "Roar Soltigers Token",
    symbol: "ROAR",
    slug: "roar-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roar-token.png",
  },
  {
    name: "Erzurumspor Token",
    symbol: "ERZ",
    slug: "erzurumspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/erzurumspor-token.png",
  },
  {
    name: "New Year Token",
    symbol: "NYT",
    slug: "new-year-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-year-token.png",
  },
  {
    name: "KVerse Society",
    symbol: "KVERSE",
    slug: "kverse-society",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kverse-society.png",
  },
  {
    name: "Auditchain",
    symbol: "AUDT",
    slug: "auditchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/auditchain.png",
  },
  {
    name: "WAGMI",
    symbol: "$WAGMI",
    slug: "wagmierc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wagmierc.png",
  },
  {
    name: "The NFT Token",
    symbol: "NFT",
    slug: "the-nft-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-nft-token.png",
  },
  {
    name: "Game of Laughs",
    symbol: "GOL",
    slug: "game-of-laughs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game-of-laughs.png",
  },
  {
    name: "Travel Coin",
    symbol: "TCOIN",
    slug: "travel-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/travel-coin.png",
  },
  {
    name: "aMLP",
    symbol: "AMLP",
    slug: "amlp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amlp.png",
  },
  {
    name: "BiShares",
    symbol: "BISON",
    slug: "bishares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bishares.png",
  },
  {
    name: "Firebird Finance",
    symbol: "HOPE",
    slug: "firebird-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firebird-finance.png",
  },
  {
    name: "Moox",
    symbol: "MOOX",
    slug: "moox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moox.png",
  },
  {
    name: "Halloween Crows",
    symbol: "SCARY",
    slug: "halloween-crows",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/halloween-crows.png",
  },
  {
    name: "DFS MAFIA (V2)",
    symbol: "DFSM",
    slug: "dfs-mafia-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfs-mafia-v2.png",
  },
  {
    name: "Wingswap",
    symbol: "WIS",
    slug: "wingswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wingswap.png",
  },
  {
    name: "UniCat Token",
    symbol: "UNICAT",
    slug: "unicat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicat.png",
  },
  {
    name: "Spike Inu",
    symbol: "SPKI",
    slug: "spike-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spike-inu.png",
  },
  {
    name: "bHIVE",
    symbol: "BHIVE",
    slug: "bhive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bhive.png",
  },
  {
    name: "Assent Protocol",
    symbol: "ASNT",
    slug: "assent-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/assent-protocol.png",
  },
  {
    name: "Celo Brazilian Real",
    symbol: "CREAL",
    slug: "celo-brazilian-real",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celo-brazilian-real.png",
  },
  {
    name: "ConeGame",
    symbol: "CONEGAME",
    slug: "conegame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/conegame.png",
  },
  {
    name: "Museo",
    symbol: "MSE",
    slug: "museo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/museo.png",
  },
  {
    name: "Versatile Finance",
    symbol: "$VERSA",
    slug: "versatile-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/versatile-finance.png",
  },
  {
    name: "CZodiac Stabletoken",
    symbol: "CZUSD",
    slug: "czodiac-stabletoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/czodiac-stabletoken.png",
  },
  {
    name: "Quadency",
    symbol: "QUAD",
    slug: "quadency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quadency.png",
  },
  {
    name: "Elite",
    symbol: "ELITE",
    slug: "elite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elite.png",
  },
  {
    name: "MoonTrustBSC",
    symbol: "MNTTBSC",
    slug: "moontrustbsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moontrustbsc.png",
  },
  {
    name: "LocoMeta",
    symbol: "LOCO",
    slug: "locometa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/locometa.png",
  },
  {
    name: "MYTEAMFINANCE",
    symbol: "MYF",
    slug: "myteamfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myteamfinance.png",
  },
  {
    name: "TravGoPV",
    symbol: "TPV",
    slug: "travgopv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/travgopv.png",
  },
  {
    name: "Wrapped Ampleforth",
    symbol: "WAMPL",
    slug: "wrapped-ampleforth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-ampleforth.png",
  },
  {
    name: "Balıkesirspor Token",
    symbol: "BLKS",
    slug: "balikesirspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balikesirspor-token.png",
  },
  {
    name: "ChilliSwap",
    symbol: "CHLI",
    slug: "chilliswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chilliswap.png",
  },
  {
    name: "ArcanineInu",
    symbol: "ARCANINEINU",
    slug: "arcanineinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcanineinu.png",
  },
  {
    name: "Huny",
    symbol: "HUNY",
    slug: "huny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/huny.png",
  },
  {
    name: "SafeSwap",
    symbol: "SSGTX",
    slug: "safeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeswap.png",
  },
  {
    name: "Starlink Inu",
    symbol: "STARLNK",
    slug: "starlink-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starlink-inu.png",
  },
  {
    name: "RunEarner",
    symbol: "RUNEARN",
    slug: "runearner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/runearner.png",
  },
  {
    name: "UpFinity",
    symbol: "UPF",
    slug: "upfinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upfinity.png",
  },
  {
    name: "Plant Empires",
    symbol: "PEFI",
    slug: "plant-empires",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plant-empires.png",
  },
  {
    name: "Pigeonsol",
    symbol: "PGNT",
    slug: "pigeonsol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pigeonsol.png",
  },
  {
    name: "ApeParkDAO",
    symbol: "APD",
    slug: "apeparkdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apeparkdao.png",
  },
  {
    name: "RoArts Token",
    symbol: "ROAR",
    slug: "roarts-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roarts-token.png",
  },
  {
    name: "Hungry Bees",
    symbol: "HBEE",
    slug: "hungry-bees",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hungry-bees.png",
  },
  {
    name: "WMT",
    symbol: "WMT",
    slug: "wmt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wmt.png",
  },
  {
    name: "TipsyCoin",
    symbol: "$TIPSY",
    slug: "tipsycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tipsycoin.png",
  },
  {
    name: "Unity Network",
    symbol: "UNT",
    slug: "unity-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unity-network.png",
  },
  {
    name: "AlgoStake",
    symbol: "STKE",
    slug: "algostake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algostake.png",
  },
  {
    name: "Movn",
    symbol: "MOV",
    slug: "movn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/movn.png",
  },
  {
    name: "ShibarmyZilla",
    symbol: "SZILLA",
    slug: "shibarmyzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibarmyzilla.png",
  },
  {
    name: "Oobit",
    symbol: "OBT",
    slug: "oobit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oobit.png",
  },
  {
    name: "Strike LINK",
    symbol: "sLINK",
    slug: "strike-chainlink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-chainlink.png",
  },
  {
    name: "SteakHut Finance",
    symbol: "STEAK",
    slug: "steakhut-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steakhut-finance.png",
  },
  {
    name: "Strike SXP",
    symbol: "sSXP",
    slug: "strike-swipe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-swipe.png",
  },
  {
    name: "Robot Warriors",
    symbol: "METABOT",
    slug: "robot-warriors",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robot-warriors.png",
  },
  {
    name: "Shibalana",
    symbol: "SHIBA",
    slug: "shibalana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibalana.png",
  },
  {
    name: "Strike WBTC",
    symbol: "sWBTC",
    slug: "strike-wbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-wbtc.png",
  },
  {
    name: "Speed Star SPEED",
    symbol: "SPEED",
    slug: "speed-star-speed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/speed-star-speed.png",
  },
  {
    name: "Bitget Token",
    symbol: "BGB",
    slug: "bitget-token-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitget-token-new.png",
  },
  {
    name: "Helena Financial",
    symbol: "HELENA",
    slug: "helena-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helena-financial.png",
  },
  {
    name: "Turnt Up Tikis",
    symbol: "TUT",
    slug: "turnt-up-tikis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turnt-up-tikis.png",
  },
  {
    name: "Asgard Dao V2",
    symbol: "ASGARDV2",
    slug: "asgard-dao-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asgard-dao-v2.png",
  },
  {
    name: "Solar Full Cycle",
    symbol: "SFC",
    slug: "solar-full-cycle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solar-full-cycle.png",
  },
  {
    name: "Bondly",
    symbol: "BOND",
    slug: "bondly-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bondly-.png",
  },
  {
    name: "Football Battle",
    symbol: "FBL",
    slug: "football-battle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/football-battle.png",
  },
  {
    name: "Shima Enaga",
    symbol: "SHIMA",
    slug: "shima-enaga",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shima-enaga.png",
  },
  {
    name: "BitValve",
    symbol: "BTV",
    slug: "bitvalve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitvalve.png",
  },
  {
    name: "PolyCub",
    symbol: "POLYCUB",
    slug: "polycub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polycub.png",
  },
  {
    name: "Electronic Mining and Mineral Exchange",
    symbol: "EX",
    slug: "electronic-mining-and-mineral-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electronic-mining-and-mineral-exchange.png",
  },
  {
    name: "Bitcrush",
    symbol: "CRUSH",
    slug: "bitcrush",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcrush.png",
  },
  {
    name: "Crypto Birds",
    symbol: "XCB",
    slug: "crypto-birds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-birds.png",
  },
  {
    name: "VisaMetaFi",
    symbol: "VISA",
    slug: "visametafi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/visametafi.png",
  },
  {
    name: "Panda Coin",
    symbol: "PANDA",
    slug: "panda-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/panda-coin.png",
  },
  {
    name: "MegaDoge",
    symbol: "MEGADOGE",
    slug: "megadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megadoge.png",
  },
  {
    name: "Speed Star STAR",
    symbol: "STAR",
    slug: "speed-star-star",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/speed-star-star.png",
  },
  {
    name: "SarabiChain",
    symbol: "SARABI",
    slug: "sarabichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sarabichain.png",
  },
  {
    name: "Atlantis Coin",
    symbol: "ATC",
    slug: "atlantis-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlantis-coin.png",
  },
  {
    name: "RABBIT HALLOWEEN",
    symbol: "RH31",
    slug: "rabbit-halloween",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rabbit-halloween.png",
  },
  {
    name: "Scary Chain Capital v2",
    symbol: "SCC",
    slug: "scary-chain-capital-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scary-chain-capital-new.png",
  },
  {
    name: "METALUNA",
    symbol: "METALUNA",
    slug: "metaluna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaluna.png",
  },
  {
    name: "Blue Horizon",
    symbol: "BLH",
    slug: "blue-horizon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blue-horizon.png",
  },
  {
    name: "Ava Time",
    symbol: "AVTIME",
    slug: "ava-time",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ava-time.png",
  },
  {
    name: "MetaPlanetZone",
    symbol: "MTP",
    slug: "metaplanetzone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaplanetzone.png",
  },
  {
    name: "KOLO Market",
    symbol: "KOLO",
    slug: "kolo-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kolo-market.png",
  },
  {
    name: "CryptoJukebox",
    symbol: "JUKE",
    slug: "cryptojukebox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptojukebox.png",
  },
  {
    name: "Omnisphere DAO",
    symbol: "OSPD",
    slug: "omnisphere-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omnisphere-dao.png",
  },
  {
    name: "METABULLRAGE",
    symbol: "BERAGE",
    slug: "metabullrage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabullrage.png",
  },
  {
    name: "Frog",
    symbol: "FROG",
    slug: "frog-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frog-token.png",
  },
  {
    name: "ShibaTsuka",
    symbol: "STSUKA",
    slug: "shibatsuka",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibatsuka.png",
  },
  {
    name: "Chikn Fert",
    symbol: "FERT",
    slug: "chikn-fert",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chikn-fert.png",
  },
  {
    name: "ARTE",
    symbol: "ARTE",
    slug: "arte",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arte.png",
  },
  {
    name: "Shib Ninja Token",
    symbol: "SNT",
    slug: "shib-ninja-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shib-ninja-token.png",
  },
  {
    name: "EzcoinMarket",
    symbol: "ECM",
    slug: "ezcoin-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ezcoin-market.png",
  },
  {
    name: "Metaverse DAO",
    symbol: "MDAO",
    slug: "metaverse-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-dao.png",
  },
  {
    name: "Stelia",
    symbol: "STELIA",
    slug: "stelia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stelia.png",
  },
  {
    name: "Bitratoken",
    symbol: "BITRA",
    slug: "bitratoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitratoken.png",
  },
  {
    name: "SkyLands",
    symbol: "SKYLANDS",
    slug: "skylands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skylands.png",
  },
  {
    name: "FitR Exercise Token v2",
    symbol: "FST",
    slug: "fitr-exercise-token2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fitr-exercise-token2.png",
  },
  {
    name: "Digital Genetic Code",
    symbol: "DGC",
    slug: "digital-genetic-code",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-genetic-code.png",
  },
  {
    name: "Tazor",
    symbol: "TAZOR",
    slug: "tazor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tazor.png",
  },
  {
    name: "World of Masters",
    symbol: "WOFM",
    slug: "world-of-masters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-of-masters.png",
  },
  {
    name: "Kermit Inu",
    symbol: "KTI",
    slug: "kermit-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kermit-inu.png",
  },
  {
    name: "Holy Trinity",
    symbol: "HOLY",
    slug: "holy-trinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holy-trinity.png",
  },
  {
    name: "Verify DeFi",
    symbol: "VERIFY",
    slug: "verify-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verify-defi.png",
  },
  {
    name: "humanDAO",
    symbol: "HDAO",
    slug: "humandao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/humandao.png",
  },
  {
    name: "HedgePay",
    symbol: "HPAY",
    slug: "hedgepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedgepay.png",
  },
  {
    name: "Afyonspor Fan Token",
    symbol: "AFYON",
    slug: "afyonspor-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/afyonspor-fan-token.png",
  },
  {
    name: "ShineDAO",
    symbol: "SHN",
    slug: "shinedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinedao.png",
  },
  {
    name: "MinerJoe",
    symbol: "GOLD",
    slug: "minerjoe-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minerjoe-gold.png",
  },
  {
    name: "SNAKE",
    symbol: "GSK",
    slug: "snake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snake.png",
  },
  {
    name: "Yukon",
    symbol: "$YUKON",
    slug: "yukon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yukon.png",
  },
  {
    name: "Exobots",
    symbol: "EXOS",
    slug: "exobots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exobots.png",
  },
  {
    name: "Tradocaps",
    symbol: "TADOC",
    slug: "tradocap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tradocap.png",
  },
  {
    name: "Duzce Token",
    symbol: "DUZCE",
    slug: "duzce-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duzce-token.png",
  },
  {
    name: "Perseus Fintech",
    symbol: "PRS",
    slug: "perseus-fintech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/perseus-fintech.png",
  },
  {
    name: "WOLF PUPS",
    symbol: "WOLFIES",
    slug: "wolf-pups-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolf-pups-new.png",
  },
  {
    name: "Tarality",
    symbol: "TARAL",
    slug: "tarality",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tarality.png",
  },
  {
    name: "Argentine Football Association Fan Token",
    symbol: "ARG",
    slug: "argentinefootballassociationfantoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/argentinefootballassociationfantoken.png",
  },
  {
    name: "Mexican Peso Tether",
    symbol: "MXNT",
    slug: "tether-mxnt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tether-mxnt.png",
  },
  {
    name: "CollegeCoinNetwork",
    symbol: "CCN",
    slug: "collegecoinnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/collegecoinnetwork.png",
  },
  {
    name: "YoleeUniverse",
    symbol: "YUS",
    slug: "yoleeuniverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yoleeuniverse.png",
  },
  {
    name: "OtterClam Finance",
    symbol: "PEARL",
    slug: "otterclam-finance-pearl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otterclam-finance-pearl.png",
  },
  {
    name: "Metaroid",
    symbol: "METAROID",
    slug: "metaroid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaroid.png",
  },
  {
    name: "Swipe (BEP2)",
    symbol: "SXP",
    slug: "swipe-bep2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swipe-bep2.png",
  },
  {
    name: "Nody",
    symbol: "NODY",
    slug: "nody",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nody.png",
  },
  {
    name: "MILLIONSY",
    symbol: "MILLI",
    slug: "millionsy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/millionsy.png",
  },
  {
    name: "Ape In Records",
    symbol: "AIR",
    slug: "ape-in-records",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ape-in-records.png",
  },
  {
    name: "Pegasus PoW",
    symbol: "$PGS",
    slug: "pegasus-pow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pegasus-pow.png",
  },
  {
    name: "Zero Matic",
    symbol: "ZMATIC",
    slug: "zero-matic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zero-matic.png",
  },
  {
    name: "Raider Token",
    symbol: "RAID",
    slug: "raider-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raider-token.png",
  },
  {
    name: "SuperNova Token",
    symbol: "SNT",
    slug: "snt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snt.png",
  },
  {
    name: "Sparrow Token",
    symbol: "SPW",
    slug: "sparrow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sparrow-token.png",
  },
  {
    name: "Kodi Coin",
    symbol: "KODI",
    slug: "kodicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kodicoin.png",
  },
  {
    name: "Movey Token",
    symbol: "MOVEY",
    slug: "movey-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/movey-token.png",
  },
  {
    name: "Universal Pickle",
    symbol: "UPL",
    slug: "universal-pickle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universal-pickle.png",
  },
  {
    name: "Adanaspor Fan Token",
    symbol: "ADANA",
    slug: "adanaspor-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adanaspor-fan-token.png",
  },
  {
    name: "RoboShib",
    symbol: "ROBOSHIB",
    slug: "roboshib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roboshib.png",
  },
  {
    name: "Whale Maker Fund",
    symbol: "WMF",
    slug: "whale-maker-fund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whale-maker-fund.png",
  },
  {
    name: "Vtopia",
    symbol: "VTOPIA",
    slug: "vtopia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vtopia.png",
  },
  {
    name: "BALLS",
    symbol: "BALLS",
    slug: "balls",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balls.png",
  },
  {
    name: "Hodl ETH",
    symbol: "HETH",
    slug: "hodl-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodl-eth.png",
  },
  {
    name: "Goblin",
    symbol: "GOBLIN",
    slug: "goblin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goblin.png",
  },
  {
    name: "Wrapped SOL",
    symbol: "XSOL",
    slug: "wrapped-sol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-sol.png",
  },
  {
    name: "Infinite Arcade",
    symbol: "TIC",
    slug: "infinite-arcade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinite-arcade.png",
  },
  {
    name: "Mistel Finance",
    symbol: "MISTEL",
    slug: "mistel-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mistel-finance.png",
  },
  {
    name: "LondonCoinGold",
    symbol: "LDXG",
    slug: "londoncoingold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/londoncoingold.png",
  },
  {
    name: "Shibmerican",
    symbol: "SHIBMERICAN",
    slug: "shibmerican",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibmerican.png",
  },
  {
    name: "Team Vitality Fan Token",
    symbol: "VIT",
    slug: "team-vitality-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/team-vitality-fan-token.png",
  },
  {
    name: "ToonKing Token",
    symbol: "TKT",
    slug: "toonking-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toonking-token.png",
  },
  {
    name: "Witch Token",
    symbol: "WITCH",
    slug: "witch-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/witch-token.png",
  },
  {
    name: "SafeBank BSC",
    symbol: "SBANK",
    slug: "safebank-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safebank-bsc.png",
  },
  {
    name: "ICC",
    symbol: "ICC",
    slug: "icc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icc.png",
  },
  {
    name: "Moonriver",
    symbol: "MOVR",
    slug: "moonriver",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonriver.png",
  },
  {
    name: "Cryptocurrencies.ai",
    symbol: "CCAI",
    slug: "cryptocurrencies-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptocurrencies-ai.png",
  },
  {
    name: "Essence of Creation",
    symbol: "EOC",
    slug: "essence-of-creation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/essence-of-creation.png",
  },
  {
    name: "WIN A TESLA",
    symbol: "WINATESLA",
    slug: "win-a-tesla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/win-a-tesla.png",
  },
  {
    name: "Rise Of Nebula",
    symbol: "RON",
    slug: "rise-of-nebula",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rise-of-nebula.png",
  },
  {
    name: "HatchyPocket",
    symbol: "HATCHY",
    slug: "hatchypocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hatchypocket.png",
  },
  {
    name: "France Fan Token",
    symbol: "FRA",
    slug: "france-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/france-fan-token.png",
  },
  {
    name: "PHOENIX FORCE",
    symbol: "PHOENIX",
    slug: "phoenix-force",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenix-force.png",
  },
  {
    name: "Ameru DAO",
    symbol: "ADAO",
    slug: "ameru-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ameru-network.png",
  },
  {
    name: "Arabian Doge",
    symbol: "$ADOGE",
    slug: "arabian-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arabian-doge.png",
  },
  {
    name: "Ouranos",
    symbol: "OUR",
    slug: "ouranos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ouranos.png",
  },
  {
    name: "Bifrost Finance",
    symbol: "vETH",
    slug: "bifrost-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bifrost-finance.png",
  },
  {
    name: "Dragon Infinity",
    symbol: "$DI",
    slug: "dragon-infinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-infinity.png",
  },
  {
    name: "Valas Finance",
    symbol: "VALAS",
    slug: "valas-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valas-finance.png",
  },
  {
    name: "DUMP DOGE",
    symbol: "DUMPDOGE",
    slug: "dump-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dump-doge.png",
  },
  {
    name: "ROBBIN HOOD",
    symbol: "ROBBIN",
    slug: "robbin-hood",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robbin-hood.png",
  },
  {
    name: "Rumito",
    symbol: "RUTC",
    slug: "rumito",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rumito.png",
  },
  {
    name: "WalMeta",
    symbol: "WALMETA",
    slug: "walmeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/walmeta.png",
  },
  {
    name: "SHIBA RISE",
    symbol: "SHIBARISE",
    slug: "shiba-rise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-rise.png",
  },
  {
    name: "Akil Coin",
    symbol: "AKL",
    slug: "akil-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akil-coin.png",
  },
  {
    name: "LaunchZone (LZP)",
    symbol: "LZP",
    slug: "launchzone-lzp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/launchzone-lzp.png",
  },
  {
    name: "Paris Inuton",
    symbol: "PRINCESS",
    slug: "paris-inuton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paris-inuton.png",
  },
  {
    name: "Hoppers Game",
    symbol: "FLY",
    slug: "hoppers-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoppers-game.png",
  },
  {
    name: "Boost2",
    symbol: "BOOST2",
    slug: "boost2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boost2.png",
  },
  {
    name: "RastaFinance Token",
    symbol: "RASTA",
    slug: "rasta-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rasta-finance.png",
  },
  {
    name: "MooniWar",
    symbol: "MWAR",
    slug: "mooniwar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mooniwar.png",
  },
  {
    name: "Shiny",
    symbol: "SHINY",
    slug: "shiny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiny.png",
  },
  {
    name: "NO DOGE",
    symbol: "NODOGE",
    slug: "no-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/no-doge.png",
  },
  {
    name: "FirstSHIB",
    symbol: "FIRSTSHIB",
    slug: "firstshib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firstshib.png",
  },
  {
    name: "VITALICK NEUTERIN",
    symbol: "LICK",
    slug: "vitalick-neuterin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vitalick-neuterin.png",
  },
  {
    name: "MetaSetGO",
    symbol: "MSGO",
    slug: "metasetgo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metasetgo.png",
  },
  {
    name: "The MEDIADOGE",
    symbol: "MEDIADOGE",
    slug: "the-mediadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-mediadoge.png",
  },
  {
    name: "Ubeswap",
    symbol: "UBE",
    slug: "ubeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubeswap.png",
  },
  {
    name: "Fenix Inu",
    symbol: "FNIX",
    slug: "fenix-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fenix-inu.png",
  },
  {
    name: "CoinW Token",
    symbol: "CWT",
    slug: "coinw-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinw-token.png",
  },
  {
    name: "CleanOcean (New)",
    symbol: "CLEAN",
    slug: "cleanocean-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cleanocean-new.png",
  },
  {
    name: "DogyRace",
    symbol: "DOR",
    slug: "dogyrace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogyrace.png",
  },
  {
    name: "VESPA SHIBA COIN",
    symbol: "VESPASHIBA",
    slug: "vespa-shiba-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vespa-shiba-coin.png",
  },
  {
    name: "Moonshots Farm",
    symbol: "BONES",
    slug: "moonshots-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonshots-farm.png",
  },
  {
    name: "Alfa Romeo Racing ORLEN Fan Token",
    symbol: "SAUBER",
    slug: "alfa-romeo-racing-orlen-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alfa-romeo-racing-orlen-fan-token.png",
  },
  {
    name: "Frapped USDT",
    symbol: "fUSDT",
    slug: "frapped-usdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frapped-usdt.png",
  },
  {
    name: "COUSIN DOGE COIN",
    symbol: "COUSINDOGE",
    slug: "cousin-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cousin-doge-coin.png",
  },
  {
    name: "Metaderby Hoof",
    symbol: "HOOF",
    slug: "metaderby-hoof",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaderby-hoof.png",
  },
  {
    name: "SolarMineX",
    symbol: "SMX",
    slug: "solarminex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solarminex.png",
  },
  {
    name: "Neuralink",
    symbol: "NEURALINK",
    slug: "neuralink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neuralink.png",
  },
  {
    name: "Stader BNBx",
    symbol: "BNBX",
    slug: "stader-bnbx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stader-bnbx.png",
  },
  {
    name: "Win Space Ticket",
    symbol: "THEMOON",
    slug: "win-space-ticket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/win-space-ticket.png",
  },
  {
    name: "Game Fantasy Token",
    symbol: "GFT",
    slug: "game-fantasy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game-fantasy-token.png",
  },
  {
    name: "Aston Martin Cognizant Fan Token",
    symbol: "AM",
    slug: "aston-martin-cognizant-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aston-martin-cognizant-fan-token.png",
  },
  {
    name: "DreamN",
    symbol: "$DREAMN",
    slug: "dreamn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dreamn.png",
  },
  {
    name: "Vain",
    symbol: "VAIN",
    slug: "vain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vain.png",
  },
  {
    name: "Safe Universe",
    symbol: "SFU",
    slug: "safe-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-universe.png",
  },
  {
    name: "The Cosby Token",
    symbol: "COSBY",
    slug: "the-cosby-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-cosby-token.png",
  },
  {
    name: "MissedEverything",
    symbol: "ME",
    slug: "missedeverything",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/missedeverything.png",
  },
  {
    name: "Polygon HBD",
    symbol: "pHBD",
    slug: "polygon-hbd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polygon-hbd.png",
  },
  {
    name: "Sephiroth Inu",
    symbol: "SEPHI",
    slug: "sephiroth-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sephiroth-inu.png",
  },
  {
    name: "Travel Care",
    symbol: "TRAVEL",
    slug: "travel-care",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/travel-care.png",
  },
  {
    name: "The Boogie Nights",
    symbol: "DIRKDIGGLER",
    slug: "the-boogie-nights",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-boogie-nights.png",
  },
  {
    name: "WIN A LAMBO FINANCE",
    symbol: "WINALAMBO",
    slug: "winalambo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winalambo-finance.png",
  },
  {
    name: "Startup Boost Token",
    symbol: "SUBX",
    slug: "startup-boost-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/startup-boost-token.png",
  },
  {
    name: "Wrapped Syscoin",
    symbol: "WSYS",
    slug: "wrapped-syscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-syscoin.png",
  },
  {
    name: "MELEGA",
    symbol: "MARCO",
    slug: "melega",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/melega.png",
  },
  {
    name: "BERNARD",
    symbol: "BERN",
    slug: "bernard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bernard.png",
  },
  {
    name: "Niftyfuse",
    symbol: "FUSE",
    slug: "niftyfuse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niftyfuse.png",
  },
  {
    name: "Kelpie Inu",
    symbol: "KELPIE",
    slug: "kelpie-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kelpie-inu.png",
  },
  {
    name: "Ruletka",
    symbol: "RTK",
    slug: "ruletka",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ruletka.png",
  },
  {
    name: "Mr Burn Token",
    symbol: "BURNS",
    slug: "mr-burn-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mr-burn-token.png",
  },
  {
    name: "Scoobi Doge",
    symbol: "SCOOBI",
    slug: "scoobi-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scoobi-doge.png",
  },
  {
    name: "Crossing the Yellow Blocks",
    symbol: "CBK",
    slug: "crossing-the-yellow-blocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crossing-the-yellow-blocks.png",
  },
  {
    name: "SolClout",
    symbol: "SCT",
    slug: "solclout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solclout.png",
  },
  {
    name: "Defily Finance",
    symbol: "DFL",
    slug: "defily-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defily-finance.png",
  },
  {
    name: "SolarWind Token",
    symbol: "SLW",
    slug: "solarwind-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solarwind-token.png",
  },
  {
    name: "VeldoraBSC",
    symbol: "VDORA",
    slug: "veldorabsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veldorabsc.png",
  },
  {
    name: "UrGaming",
    symbol: "URG",
    slug: "urgaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/urgaming.png",
  },
  {
    name: "Lucky Meow Token",
    symbol: "LMAO",
    slug: "lucky-meow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-meow-token.png",
  },
  {
    name: "Yieldzilla",
    symbol: "YDZ",
    slug: "yieldzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yieldzilla.png",
  },
  {
    name: "HyperChainX",
    symbol: "HYPER",
    slug: "hyperchainx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperchainx.png",
  },
  {
    name: "KokomoSwap",
    symbol: "KOKOMO",
    slug: "kokomoswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kokomoswap.png",
  },
  {
    name: "FAN8",
    symbol: "FAN8",
    slug: "fan8",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fan8.png",
  },
  {
    name: "Swole Doge",
    symbol: "SWOLE",
    slug: "swole-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swole-doge.png",
  },
  {
    name: "Puff",
    symbol: "PUFF",
    slug: "puff",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puff.png",
  },
  {
    name: "Top Flower",
    symbol: "TPF",
    slug: "top-flower",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/top-flower.png",
  },
  {
    name: "MoonKat",
    symbol: "MKAT",
    slug: "moonkat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonkat.png",
  },
  {
    name: "Aarma",
    symbol: "ARMA",
    slug: "aarma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aarma.png",
  },
  {
    name: "BetSwirl",
    symbol: "BETS",
    slug: "betswirl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betswirl.png",
  },
  {
    name: "VOYR",
    symbol: "VOYRME",
    slug: "voyr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voyr.png",
  },
  {
    name: "EverMars",
    symbol: "EVM",
    slug: "evermars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evermars.png",
  },
  {
    name: "Ideamarket",
    symbol: "IMO",
    slug: "ideamarket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ideamarket.png",
  },
  {
    name: "La Casa De Papel",
    symbol: "LCDP",
    slug: "la-casa-de-papel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/la-casa-de-papel.png",
  },
  {
    name: "DealDough Token",
    symbol: "DEALDOUGH",
    slug: "dealdough-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dealdough-token.png",
  },
  {
    name: "ElonPeg",
    symbol: "ELONPEG",
    slug: "elonpeg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elonpeg.png",
  },
  {
    name: "CloudTx",
    symbol: "CLOUD",
    slug: "cloudtx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cloudtx.png",
  },
  {
    name: "HentaiCoin",
    symbol: "HENTAI",
    slug: "hentaicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hentaicoin.png",
  },
  {
    name: "FireBotToken",
    symbol: "FBX",
    slug: "firebottoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firebottoken.png",
  },
  {
    name: "KissMyMoon",
    symbol: "KISSMYMOON",
    slug: "kissmymoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kissmymoon.png",
  },
  {
    name: "Maximus LUCKY",
    symbol: "LUCKY",
    slug: "maximus-lucky",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maximus-lucky.png",
  },
  {
    name: "Definix",
    symbol: "FINIX",
    slug: "definix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/definix.png",
  },
  {
    name: "CBD Coin",
    symbol: "CBD",
    slug: "cbd-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cbd-coin.png",
  },
  {
    name: "PogCoin",
    symbol: "POG",
    slug: "pogcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pogcoin.png",
  },
  {
    name: "SHUMI",
    symbol: "SHUMI",
    slug: "shumi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shumi.png",
  },
  {
    name: "COIN ORACLE",
    symbol: "ORA",
    slug: "coin-oracle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-oracle.png",
  },
  {
    name: "Brigadeiro.Finance",
    symbol: "BRIGADEIRO",
    slug: "brigadeiro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brigadeiro.png",
  },
  {
    name: "Bored Floki Yacht Club",
    symbol: "BFYC",
    slug: "bored-floki-yacht-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bored-floki-yacht-club.png",
  },
  {
    name: "LuckyMeta Token",
    symbol: "LMT",
    slug: "luckymeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luckymeta.png",
  },
  {
    name: "Recovery Right Token",
    symbol: "RRT",
    slug: "recovery-right-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/recovery-right-token.png",
  },
  {
    name: "BAMBINO NFT",
    symbol: "BAMBI",
    slug: "bambino-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bambino-nft.png",
  },
  {
    name: "MoonPump",
    symbol: "PUMP",
    slug: "moonpump",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonpump.png",
  },
  {
    name: "Flesh Token",
    symbol: "FLESH",
    slug: "flesh-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flesh-token.png",
  },
  {
    name: "WeWon World",
    symbol: "WEWON",
    slug: "wewon-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wewon-world.png",
  },
  {
    name: "Kalissa",
    symbol: "KALI",
    slug: "kalissa-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalissa-v2.png",
  },
  {
    name: "Ken Inu",
    symbol: "KENU",
    slug: "ken-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ken-inu.png",
  },
  {
    name: "Head Football",
    symbol: "HEAD",
    slug: "head-football",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/head-football.png",
  },
  {
    name: "BNBX Finance",
    symbol: "BNBX",
    slug: "bnbx-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbx-finance.png",
  },
  {
    name: "Nothing",
    symbol: "NADA",
    slug: "nothing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nothing.png",
  },
  {
    name: "Statik",
    symbol: "STATIK",
    slug: "statik",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/statik.png",
  },
  {
    name: "miniKishimoto Inu",
    symbol: "MINIKISHIMOTO",
    slug: "minikishimoto-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minikishimoto-inu.png",
  },
  {
    name: "Astra Coin",
    symbol: "ASTRA",
    slug: "astra-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astra-coin.png",
  },
  {
    name: "Green Dildo Finance",
    symbol: "GDILDO",
    slug: "green-dildo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-dildo-finance.png",
  },
  {
    name: "Pirate Inu",
    symbol: "PINU",
    slug: "pirate-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pirate-inu.png",
  },
  {
    name: "Global Adversity Project",
    symbol: "GAP",
    slug: "global-adversity-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-adversity-project.png",
  },
  {
    name: "KishuTama",
    symbol: "KISHUTAMA",
    slug: "kishutama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kishutama.png",
  },
  {
    name: "NFTShiba.Finance",
    symbol: "NFTSHIBA",
    slug: "nftshiba-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftshiba-finance.png",
  },
  {
    name: "AvaxUp",
    symbol: "AVAXUP",
    slug: "avaxup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaxup.png",
  },
  {
    name: "PalmPay",
    symbol: "PALM",
    slug: "palmpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/palmpay.png",
  },
  {
    name: "CoinScan",
    symbol: "SCAN",
    slug: "coinscan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinscan.png",
  },
  {
    name: "MondayClub",
    symbol: "MONDAY",
    slug: "mondayclub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mondayclub.png",
  },
  {
    name: "Zuki",
    symbol: "ZUKI",
    slug: "zuki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zuki.png",
  },
  {
    name: "Base Reward Token",
    symbol: "BRT",
    slug: "base-reward-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/base-reward-token.png",
  },
  {
    name: "CashDog",
    symbol: "CASHDOG",
    slug: "cashdog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashdog.png",
  },
  {
    name: "Baby Pokemoon",
    symbol: "BPM",
    slug: "baby-pokemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-pokemoon.png",
  },
  {
    name: "UndoToken",
    symbol: "UNDO",
    slug: "undotoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/undotoken.png",
  },
  {
    name: "Its Not Art",
    symbol: "$NA",
    slug: "its-not-art-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/its-not-art-v2.png",
  },
  {
    name: "OctaX Finance",
    symbol: "OCTAX",
    slug: "octax-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octax-finance.png",
  },
  {
    name: "GAINZ TOKEN",
    symbol: "GAINZ",
    slug: "gainz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gainz-token.png",
  },
  {
    name: "BoomCoin",
    symbol: "BOOMC",
    slug: "boomcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boomcoin.png",
  },
  {
    name: "Cat Sphynx",
    symbol: "CPX",
    slug: "cat-sphynx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cat-sphynx.png",
  },
  {
    name: "Buhund",
    symbol: "BUH",
    slug: "buhund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buhund.png",
  },
  {
    name: "SpaceToast",
    symbol: "SPACETOAST",
    slug: "spacetoast",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacetoast.png",
  },
  {
    name: "Snowball Snowbank",
    symbol: "SNO",
    slug: "snowballxyz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowballxyz.png",
  },
  {
    name: "CLIT TOKEN PROTOCOL",
    symbol: "CLIT$",
    slug: "clit-token-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clit-token-protocol.png",
  },
  {
    name: "Bill Hwang Finance",
    symbol: "BILL",
    slug: "bill-hwang-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bill-hwang-finance.png",
  },
  {
    name: "Morie INU",
    symbol: "MORIE",
    slug: "morie-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morie-inu.png",
  },
  {
    name: "Modern Liquidity Token",
    symbol: "MLT",
    slug: "modern-liquidity-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/modern-liquidity-token.png",
  },
  {
    name: "Shield Token",
    symbol: "SID",
    slug: "shield-finance-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shield-finance-protocol.png",
  },
  {
    name: "Canary",
    symbol: "CNR",
    slug: "canary",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/canary.png",
  },
  {
    name: "Moonship Finance",
    symbol: "MSP",
    slug: "moonship-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonship-finance.png",
  },
  {
    name: "Turtles Token",
    symbol: "TRTLS",
    slug: "turtles-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turtles-token.png",
  },
  {
    name: "Nirvana prANA",
    symbol: "PRANA",
    slug: "nirvana-prana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nirvana-prana.png",
  },
  {
    name: "Bauble",
    symbol: "BAB",
    slug: "bauble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bauble.png",
  },
  {
    name: "FAIRLIFE",
    symbol: "FAIRLIFE",
    slug: "fairlife",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fairlife.png",
  },
  {
    name: "The Neighbours",
    symbol: "NEIBR",
    slug: "the-neighbours",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-neighbours.png",
  },
  {
    name: "ELYFI",
    symbol: "ELFI",
    slug: "elyfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elyfi.png",
  },
  {
    name: "Ballbag Token",
    symbol: "BALLBAG",
    slug: "ballbag-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ballbag-token.png",
  },
  {
    name: "CATpay",
    symbol: "CATPAY",
    slug: "catpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catpay.png",
  },
  {
    name: "BakerDAO",
    symbol: "BKR",
    slug: "bakerdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bakerdao.png",
  },
  {
    name: "Rice",
    symbol: "RICE",
    slug: "rice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rice.png",
  },
  {
    name: "SafeWin",
    symbol: "SAFEWIN",
    slug: "safewin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safewin.png",
  },
  {
    name: "Elongate Deluxe",
    symbol: "ELONGD",
    slug: "elongate-deluxe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elongate-deluxe.png",
  },
  {
    name: "MoonwayV2",
    symbol: "MW2",
    slug: "moonwayv2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonwayv2.png",
  },
  {
    name: "Gemlink",
    symbol: "GLINK",
    slug: "gemlink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gemlink.png",
  },
  {
    name: "EVERERECTED",
    symbol: "ERECT",
    slug: "evererected",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evererected.png",
  },
  {
    name: "Bitfresh",
    symbol: "BFT",
    slug: "bitfresh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitfresh.png",
  },
  {
    name: "REALLIQ Token",
    symbol: "RLQ",
    slug: "realliq-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realliq-token.png",
  },
  {
    name: "ButterSwap",
    symbol: "BUTTER",
    slug: "butterswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/butterswap.png",
  },
  {
    name: "CosmicSwap",
    symbol: "COSMIC",
    slug: "cosmicswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cosmicswap.png",
  },
  {
    name: "CryptoCart V2",
    symbol: "CCV2",
    slug: "cryptocart-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptocart-v2.png",
  },
  {
    name: "One Token",
    symbol: "ONE",
    slug: "one-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-token.png",
  },
  {
    name: "Metastar",
    symbol: "METASTAR",
    slug: "metastar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metastar.png",
  },
  {
    name: "SureBets Online",
    symbol: "SUREBETS",
    slug: "surebets-online",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/surebets-online.png",
  },
  {
    name: "Lunar Flare",
    symbol: "LFG",
    slug: "lunar-flare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunar-flare.png",
  },
  {
    name: "Hepa Finance",
    symbol: "HEPA",
    slug: "hepa-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hepa-finance.png",
  },
  {
    name: "Rens Token",
    symbol: "RENS",
    slug: "rens-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rens-token.png",
  },
  {
    name: "Avatar Moon",
    symbol: "$AVATAR",
    slug: "avatar-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avatar-moon.png",
  },
  {
    name: "CharityDAO",
    symbol: "CHD",
    slug: "charitydao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charitydao.png",
  },
  {
    name: "BeeSafe",
    symbol: "BSAFE",
    slug: "beesafe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beesafe.png",
  },
  {
    name: "Chinu",
    symbol: "CHINU",
    slug: "chinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chinu.png",
  },
  {
    name: "ORIGYN Foundation",
    symbol: "OGY",
    slug: "origyn-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/origyn-foundation.png",
  },
  {
    name: "Gençlerbirliği Fan Token",
    symbol: "GBSK",
    slug: "genclerbirligi-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genclerbirligi-fan-token.png",
  },
  {
    name: "Bugg Inu",
    symbol: "BUGG",
    slug: "bugg-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bugg-inu.png",
  },
  {
    name: "DragonMaster",
    symbol: "DMT",
    slug: "dragonmaster-dmt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonmaster-dmt.png",
  },
  {
    name: "FlappyDoge",
    symbol: "FLPD",
    slug: "flappydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flappydoge.png",
  },
  {
    name: "Peet DeFi [new]",
    symbol: "PTE",
    slug: "peet-defi-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peet-defi-new.png",
  },
  {
    name: "Cosmic Ape Coin",
    symbol: "CAC",
    slug: "cosmic-ape-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cosmic-ape-coin.png",
  },
  {
    name: "SingularFarm",
    symbol: "SING",
    slug: "singularfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/singularfarm.png",
  },
  {
    name: "Metavault Trade",
    symbol: "MVX",
    slug: "metavault-trade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metavault-trade.png",
  },
  {
    name: "Tulips City",
    symbol: "TULIP₿",
    slug: "tulips-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tulips-city.png",
  },
  {
    name: "LittleGhosts",
    symbol: "ECTO",
    slug: "littleghosts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/littleghosts.png",
  },
  {
    name: "B2X",
    symbol: "B2X",
    slug: "b2x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/b2x.png",
  },
  {
    name: "SafeCity",
    symbol: "SAFECITY",
    slug: "safecity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safecity.png",
  },
  {
    name: "Blue Gold",
    symbol: "BLG",
    slug: "blue-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blue-gold.png",
  },
  {
    name: "Waivlength",
    symbol: "WAIV",
    slug: "waivlength",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waivlength.png",
  },
  {
    name: "Bees",
    symbol: "BEE",
    slug: "bees",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bees.png",
  },
  {
    name: "NvirWorld",
    symbol: "NVIR",
    slug: "nvirworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nvirworld.png",
  },
  {
    name: "Sport Investing",
    symbol: "SITX",
    slug: "sport-investing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sport-investing.png",
  },
  {
    name: "Pug Cash",
    symbol: "PUG",
    slug: "pug-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pug-cash.png",
  },
  {
    name: "1X Short BNB Token",
    symbol: "BNBHEDGE",
    slug: "1x-short-bnb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1x-short-bnb-token.png",
  },
  {
    name: "NftEyez",
    symbol: "EYE",
    slug: "nfteyez",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nfteyez.png",
  },
  {
    name: "SOLAPE Finance",
    symbol: "SOLAPE",
    slug: "solapefinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solapefinance.png",
  },
  {
    name: "TigerQueen",
    symbol: "TQUEEN",
    slug: "tigerqueen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tigerqueen.png",
  },
  {
    name: "MetaPay",
    symbol: "METAPAY",
    slug: "metapay-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metapay-global.png",
  },
  {
    name: "DragonMaster",
    symbol: "TOTEM",
    slug: "dragonmaster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonmaster.png",
  },
  {
    name: "IMM",
    symbol: "IMM",
    slug: "imm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imm.png",
  },
  {
    name: "Bodrumspor Fan Token",
    symbol: "BDRM",
    slug: "bodrumspor-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bodrumspor-fan-token.png",
  },
  {
    name: "rainSPIRIT",
    symbol: "RAINSPIRIT",
    slug: "rainspirit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rainspirit.png",
  },
  {
    name: "Influencer Doge",
    symbol: "IDOGE",
    slug: "influencer-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/influencer-doge.png",
  },
  {
    name: "Jomon Inu",
    symbol: "JINU",
    slug: "jomon-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jomon-inu.png",
  },
  {
    name: "Orclands Metaverse",
    symbol: "ORC",
    slug: "orclands-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orclands-metaverse.png",
  },
  {
    name: "StakeWise",
    symbol: "SWISE",
    slug: "stakewise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakewise.png",
  },
  {
    name: "GoCerberus",
    symbol: "CERBERUS",
    slug: "gocerberus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gocerberus.png",
  },
  {
    name: "Mark Goat",
    symbol: "MARKGOAT",
    slug: "mark-goat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mark-goat.png",
  },
  {
    name: "KSwap",
    symbol: "KST",
    slug: "kswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kswap.png",
  },
  {
    name: "The Witcher Fans",
    symbol: "WICKED",
    slug: "the-witcher-fans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-witcher-fans.png",
  },
  {
    name: "DIAMOND",
    symbol: "DIAMOND",
    slug: "diamond-xrpl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-xrpl.png",
  },
  {
    name: "sEUR",
    symbol: "SEUR",
    slug: "seur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seur.png",
  },
  {
    name: "Orkan",
    symbol: "ORKAN",
    slug: "orkan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orkan.png",
  },
  {
    name: "DriftDelivery.CC",
    symbol: "DRIFT",
    slug: "driftdelivery-cc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/driftdelivery-cc.png",
  },
  {
    name: "Asset of Empires",
    symbol: "AOE",
    slug: "asset-of-empires",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asset-of-empires.png",
  },
  {
    name: "Dota Finance",
    symbol: "DOTA",
    slug: "dota-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dota-finance.png",
  },
  {
    name: "Good Fire Token",
    symbol: "GF",
    slug: "good-fire-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/good-fire-token.png",
  },
  {
    name: "Kuma Inu",
    symbol: "KUMA",
    slug: "kuma-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuma-inu.png",
  },
  {
    name: "Algomint",
    symbol: "GOMINT",
    slug: "gomint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gomint.png",
  },
  {
    name: "Secured Marsupial",
    symbol: "SMARSUP",
    slug: "secured-marsupial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secured-marsupial.png",
  },
  {
    name: "Kitsumon",
    symbol: "$KMC",
    slug: "kitsumon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitsumon.png",
  },
  {
    name: "Fluity USD",
    symbol: "FLUSD",
    slug: "fluity-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fluity-usd.png",
  },
  {
    name: "Solareum Wallet",
    symbol: "XSB",
    slug: "solareum-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solareum-wallet.png",
  },
  {
    name: "Cold Koala",
    symbol: "COLDKOALA",
    slug: "cold-koala",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cold-koala.png",
  },
  {
    name: "Fortuna Sittard Fan Token",
    symbol: "FOR",
    slug: "fortuna-sittard-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fortuna-sittard-fan-token.png",
  },
  {
    name: "Dare Token",
    symbol: "DRE",
    slug: "dare-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dare-token.png",
  },
  {
    name: "Universidad de Chile Fan Token",
    symbol: "UCH",
    slug: "universidad-de-chile-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universidad-de-chile-fan-token.png",
  },
  {
    name: "MEMEX",
    symbol: "MEMEX",
    slug: "memex-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memex-exchange.png",
  },
  {
    name: "CZshares",
    symbol: "CZSHARES",
    slug: "czshares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/czshares.png",
  },
  {
    name: "Solar Company",
    symbol: "SLC",
    slug: "solar-company",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solar-company.png",
  },
  {
    name: "Holder Finance",
    symbol: "HFI",
    slug: "holder-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holder-finance.png",
  },
  {
    name: "MuesliSwap MILK",
    symbol: "MILK",
    slug: "muesliswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muesliswap.png",
  },
  {
    name: "Shiftal",
    symbol: "SFL",
    slug: "shiftal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiftal.png",
  },
  {
    name: "Holder Swap",
    symbol: "HFS",
    slug: "holder-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holder-swap.png",
  },
  {
    name: "LuckyMeta Gold",
    symbol: "LMG",
    slug: "luckymeta-gaming-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luckymeta-gaming-token.png",
  },
  {
    name: "Succor Coin",
    symbol: "SUCCOR",
    slug: "succor-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/succor-coin.png",
  },
  {
    name: "PandaSwap",
    symbol: "PND",
    slug: "pandaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pandaswap.png",
  },
  {
    name: "ProtocolX",
    symbol: "PTX",
    slug: "protocolx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/protocolx.png",
  },
  {
    name: "Cribnb Decentralized Renting and Sharing",
    symbol: "CRB",
    slug: "cribnb-decentralized-renting-and-sharing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cribnb-decentralized-renting-and-sharing.png",
  },
  {
    name: "Buried Bones",
    symbol: "BBT",
    slug: "buried-bones",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buried-bones.png",
  },
  {
    name: "AT Finance",
    symbol: "AT",
    slug: "at-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/at-finance.png",
  },
  {
    name: "Follow Token",
    symbol: "FOLO",
    slug: "follow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/follow-token.png",
  },
  {
    name: "HomeCoin",
    symbol: "HOME",
    slug: "homecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/homecoin.png",
  },
  {
    name: "Vanguard S&P 500 ETF Tokenized Stock Defichain",
    symbol: "DVOO",
    slug: "vanguard-sp-500-etf-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanguard-sp-500-etf-tokenized-stock-defichain.png",
  },
  {
    name: "Stellar Invictus Gaming",
    symbol: "TRYON",
    slug: "stellar-invictus-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stellar-invictus-gaming.png",
  },
  {
    name: "SPIN",
    symbol: "SPIN",
    slug: "spinbsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spinbsc.png",
  },
  {
    name: "TasteNFT",
    symbol: "TASTE",
    slug: "tastenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tastenft.png",
  },
  {
    name: "$HULK",
    symbol: "$HULK",
    slug: "hulk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hulk.png",
  },
  {
    name: "Bidcommerce",
    symbol: "BIDCOM",
    slug: "bidcommerce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bidcommerce.png",
  },
  {
    name: "Vexchange",
    symbol: "VEX",
    slug: "vexchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vexchange.png",
  },
  {
    name: "Doge-1 Mission to the moon",
    symbol: "DOGE-1",
    slug: "doge-1-mission-to-the-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-1-mission-to-the-moon.png",
  },
  {
    name: "Tegridy",
    symbol: "TGDY",
    slug: "tegridy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tegridy.png",
  },
  {
    name: "Predictcoin",
    symbol: "PRED",
    slug: "predictcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/predictcoin.png",
  },
  {
    name: "Save The Kids",
    symbol: "KIDS",
    slug: "save-the-kids",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/save-the-kids.png",
  },
  {
    name: "Saint Token",
    symbol: "SAINT",
    slug: "saint-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saint-token.png",
  },
  {
    name: "X ECOSYSTEM",
    symbol: "XECO",
    slug: "x-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-ecosystem.png",
  },
  {
    name: "BabyQuick",
    symbol: "BABYQUICK",
    slug: "babyquick",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyquick.png",
  },
  {
    name: "KPOP Fan Token",
    symbol: "KPOP",
    slug: "kpop-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kpop-fan-token.png",
  },
  {
    name: "MISO",
    symbol: "MISO",
    slug: "miso",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miso.png",
  },
  {
    name: "Sushiba",
    symbol: "SUSHIBA",
    slug: "sushiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sushiba.png",
  },
  {
    name: "BongWeedCoin",
    symbol: "BWC",
    slug: "bongweedcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bongweedcoin.png",
  },
  {
    name: "TakoDefi",
    symbol: "TAKO",
    slug: "takodefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/takodefi.png",
  },
  {
    name: "Seesaw",
    symbol: "SSW",
    slug: "seesaw",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seesaw.png",
  },
  {
    name: "Doge Yellow Coin",
    symbol: "DOGEY",
    slug: "doge-yellow-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-yellow-coin.png",
  },
  {
    name: "Agro-Matic",
    symbol: "AMT",
    slug: "agro-matic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agro-matic.png",
  },
  {
    name: "IceBreak-R",
    symbol: "ICEBRK",
    slug: "icebreak-r",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icebreak-r.png",
  },
  {
    name: "Silver Coin",
    symbol: "SC",
    slug: "silver-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silver-coin.png",
  },
  {
    name: "Hermes Defi",
    symbol: "IRIS",
    slug: "hermes-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hermes-defi.png",
  },
  {
    name: "Pomskey",
    symbol: "PM",
    slug: "pomskey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pomskey.png",
  },
  {
    name: "Diyarbekirspor Token",
    symbol: "DIYAR",
    slug: "diyarbekirspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diyarbekirspor-token.png",
  },
  {
    name: "SpaceGoat",
    symbol: "SGT",
    slug: "spacegoat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacegoat.png",
  },
  {
    name: "Pizza Pug Coin",
    symbol: "PPUG",
    slug: "pizza-pug-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pizza-pug-coin.png",
  },
  {
    name: "Avocado DAO Token",
    symbol: "AVG",
    slug: "avocado-dao-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avocado-dao-token.png",
  },
  {
    name: "Utopian Protocol",
    symbol: "UTP",
    slug: "utopian-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utopian-protocol.png",
  },
  {
    name: "Smart Token",
    symbol: "SMART",
    slug: "smart-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-token.png",
  },
  {
    name: "Zetta Ethereum Hashrate Token",
    symbol: "ZETH",
    slug: "zetta-ethereum-hashrate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zetta-ethereum-hashrate-token.png",
  },
  {
    name: "Walk Dogs",
    symbol: "WLD",
    slug: "walk-dogs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/walk-dogs.png",
  },
  {
    name: "PepeMoon",
    symbol: "PEPE",
    slug: "pepemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pepemoon.png",
  },
  {
    name: "Liquifi",
    symbol: "LQF",
    slug: "liquifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquifi.png",
  },
  {
    name: "MStation",
    symbol: "MST",
    slug: "mstation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mstation.png",
  },
  {
    name: "Compact",
    symbol: "CPAC",
    slug: "compact",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compact.png",
  },
  {
    name: "KleeKai",
    symbol: "KLEE",
    slug: "kleekai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kleekai.png",
  },
  {
    name: "Degen Token Finance",
    symbol: "ADGNZ",
    slug: "degen-token-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degen-token-finance.png",
  },
  {
    name: "IBEX",
    symbol: "IBEX",
    slug: "ibex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ibex.png",
  },
  {
    name: "Bela Aqua",
    symbol: "AQUA",
    slug: "bela-aqua",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bela-aqua.png",
  },
  {
    name: "International Blockchain Technology",
    symbol: "IBT",
    slug: "international-blockchain-technology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/international-blockchain-technology.png",
  },
  {
    name: "Gunstar Metaverse Currency",
    symbol: "GSC",
    slug: "gunstar-metaverse-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gunstar-metaverse-currency.png",
  },
  {
    name: "GIVE GLOBAL",
    symbol: "GIVE",
    slug: "give-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/give-global.png",
  },
  {
    name: "SIMBA INU",
    symbol: "SIMBAINU",
    slug: "simbainu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simbainu.png",
  },
  {
    name: "PEGONetwork",
    symbol: "OSK-DAO",
    slug: "pegonetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pegonetwork.png",
  },
  {
    name: "SOLIDsex: Tokenized veSOLID",
    symbol: "SOLIDSEX",
    slug: "solidsex-tokenized-vesolid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solidsex-tokenized-vesolid.png",
  },
  {
    name: "Altera",
    symbol: "AEN",
    slug: "altera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altera.png",
  },
  {
    name: "KnightSwap",
    symbol: "KNIGHT",
    slug: "knightswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knightswap.png",
  },
  {
    name: "Mozart Finance",
    symbol: "MELODY",
    slug: "mozart-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mozart-finance.png",
  },
  {
    name: "PitBULLY",
    symbol: "BULLY",
    slug: "pitbully",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pitbully.png",
  },
  {
    name: "1ETH",
    symbol: "1ETH",
    slug: "1eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1eth.png",
  },
  {
    name: "Miaw Token",
    symbol: "MIAW",
    slug: "miaw-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miaw-token.png",
  },
  {
    name: "Charix",
    symbol: "CHARIX TOKEN",
    slug: "charix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charix.png",
  },
  {
    name: "CollectCoin",
    symbol: "CLCT",
    slug: "collectcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/collectcoin.png",
  },
  {
    name: "SpiritSwap",
    symbol: "SPIRIT",
    slug: "spiritswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spiritswap.png",
  },
  {
    name: "Krabots",
    symbol: "KRAC",
    slug: "krabots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krabots.png",
  },
  {
    name: "Shinigami",
    symbol: "$DEATH",
    slug: "shinigami",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinigami.png",
  },
  {
    name: "Adene",
    symbol: "ADEN",
    slug: "adene",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adene.png",
  },
  {
    name: "PathFundV2",
    symbol: "PATH",
    slug: "pathfundv2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pathfundv2.png",
  },
  {
    name: "DiamondHold",
    symbol: "DHOLD",
    slug: "diamondhold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamondhold.png",
  },
  {
    name: "LuxeRacing",
    symbol: "LUXE",
    slug: "luxeracing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luxeracing.png",
  },
  {
    name: "GoldenWspp",
    symbol: "GWSPP",
    slug: "goldenwspp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldenwspp.png",
  },
  {
    name: "GDOGE Finance",
    symbol: "GDOGE",
    slug: "gdoge-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gdoge-finance.png",
  },
  {
    name: "Captain",
    symbol: "CAPT",
    slug: "captain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/captain.png",
  },
  {
    name: "Space Dog",
    symbol: "SPACEDOG",
    slug: "space-dog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-dog.png",
  },
  {
    name: "Growth DeFi",
    symbol: "xGRO",
    slug: "growthdefi-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/growthdefi-new.png",
  },
  {
    name: "Algomint",
    symbol: "GOBTC",
    slug: "algomint-gobtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algomint-gobtc.png",
  },
  {
    name: "Make A Difference Token",
    symbol: "$MAD",
    slug: "make-a-difference-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/make-a-difference-token.png",
  },
  {
    name: "United Doge Finance",
    symbol: "UDOG",
    slug: "united-doge-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/united-doge-finance.png",
  },
  {
    name: "Metalk",
    symbol: "META",
    slug: "metalk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metalk.png",
  },
  {
    name: "Shilling Token",
    symbol: "SHILLING",
    slug: "shilling-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shilling-token.png",
  },
  {
    name: "Phoenix Protocol",
    symbol: "PHX",
    slug: "phoenix-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenix-protocol.png",
  },
  {
    name: "UnSafeMoon",
    symbol: "UNSAFEMOON",
    slug: "unsafemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unsafemoon.png",
  },
  {
    name: "SolCondoms",
    symbol: "CONDOMS",
    slug: "solcondoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solcondoms.png",
  },
  {
    name: "SPACELINK",
    symbol: "SPLINK",
    slug: "spacelink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacelink.png",
  },
  {
    name: "Kawai INU",
    symbol: "KAWAII",
    slug: "kawai-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kawai-inu.png",
  },
  {
    name: "Doraemoon",
    symbol: "DORA",
    slug: "doraemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doraemoon.png",
  },
  {
    name: "Play It Forward DAO",
    symbol: "PIF",
    slug: "play-it-forward-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/play-it-forward-dao.png",
  },
  {
    name: "Hina Inu",
    symbol: "$HINA",
    slug: "hina-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hina-inu.png",
  },
  {
    name: "Pink Panther Lovers",
    symbol: "PPL",
    slug: "pink-panther-lovers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pink-panther-lovers.png",
  },
  {
    name: "SkyBorn",
    symbol: "SKYBORN",
    slug: "sky-born",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sky-born.png",
  },
  {
    name: "Stone Age NFT Marketplace",
    symbol: "GES",
    slug: "stone-age-nft-marketplace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stone-age-nft-marketplace.png",
  },
  {
    name: "Puffydog Coin",
    symbol: "PUFFY",
    slug: "puffydog-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puffydog-coin.png",
  },
  {
    name: "WSB.sh",
    symbol: "WSBT",
    slug: "wsb-sh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wsb-sh.png",
  },
  {
    name: "KimJongMoon",
    symbol: "KIMJ",
    slug: "kimjongmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kimjongmoon.png",
  },
  {
    name: "Urubit",
    symbol: "URUB",
    slug: "urubit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/urubit.png",
  },
  {
    name: "Sustainable Energy Token",
    symbol: "SET",
    slug: "sustainable-energy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sustainable-energy-token.png",
  },
  {
    name: "DinoEGG",
    symbol: "DINOEGG",
    slug: "dinoegg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinoegg.png",
  },
  {
    name: "LunaDoge",
    symbol: "LOGE",
    slug: "lunadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunadoge.png",
  },
  {
    name: "SafeMeme",
    symbol: "SME",
    slug: "safememe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safememe.png",
  },
  {
    name: "Gorilla Inu",
    symbol: "GORILLA INU",
    slug: "gorilla-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gorilla-inu.png",
  },
  {
    name: "PETBloc",
    symbol: "PETB",
    slug: "petbloc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petbloc.png",
  },
  {
    name: "DarleyGo Essence",
    symbol: "DGE",
    slug: "darleygo-essence",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darleygo-essence.png",
  },
  {
    name: "CPLAY Network",
    symbol: "CPLAY",
    slug: "cplay-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cplay-network.png",
  },
  {
    name: "PeepoCoin",
    symbol: "$PEEPO",
    slug: "peepocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peepocoin.png",
  },
  {
    name: "$BOOB",
    symbol: "$BOOB",
    slug: "boob",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boob.png",
  },
  {
    name: "LIMON.GROUP",
    symbol: "LIMON",
    slug: "limon-group",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/limon-group.png",
  },
  {
    name: "Floki Gold",
    symbol: "FLOKIGOLD",
    slug: "floki-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-gold.png",
  },
  {
    name: "KOALA TOKEN",
    symbol: "MKOALA",
    slug: "koala-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koala-token.png",
  },
  {
    name: "Sonic Token",
    symbol: "SONIC",
    slug: "sonic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonic-token.png",
  },
  {
    name: "Green Life Energy",
    symbol: "GLE",
    slug: "green-life-energy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-life-energy-token.png",
  },
  {
    name: "Cryptonaught",
    symbol: "CRYPT",
    slug: "cryptonaught",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptonaught.png",
  },
  {
    name: "Chain Wars",
    symbol: "CWE",
    slug: "chain-wars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain-wars.png",
  },
  {
    name: "Kakashi Sensei",
    symbol: "KAKASHI",
    slug: "kakashi-sensei",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kakashi-sensei.png",
  },
  {
    name: "NEMO",
    symbol: "NEMO",
    slug: "galixcity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galixcity.png",
  },
  {
    name: "RushMoon",
    symbol: "RUSH",
    slug: "rushmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rushmoon.png",
  },
  {
    name: "Aave Ethereum",
    symbol: "aETH",
    slug: "aave-ethereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-ethereum.png",
  },
  {
    name: "MoonRetriever",
    symbol: "FETCH",
    slug: "moonretriever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonretriever.png",
  },
  {
    name: "MetaMonstas",
    symbol: "MONSTA",
    slug: "metamonstas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamonstas.png",
  },
  {
    name: "Aerotyne",
    symbol: "ATYNE",
    slug: "aerotyne",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aerotyne.png",
  },
  {
    name: "Arbis Finance",
    symbol: "ARBIS",
    slug: "arbis-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbis-finance.png",
  },
  {
    name: "Greenheart CBD",
    symbol: "CBD",
    slug: "greenheart-cbd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenheart-cbd.png",
  },
  {
    name: "DOGE WORLD CUP",
    symbol: "DWC",
    slug: "doge-world-cup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-world-cup.png",
  },
  {
    name: "Regiment Finance",
    symbol: "RTF",
    slug: "regiment-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/regiment-finance.png",
  },
  {
    name: "Loge Of The Rings",
    symbol: "LOGE",
    slug: "loge-of-the-rings",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loge-of-the-rings.png",
  },
  {
    name: "POOMOON",
    symbol: "POO",
    slug: "poomoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poomoon.png",
  },
  {
    name: "Elk Finance",
    symbol: "ELK",
    slug: "elk-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elk-finance.png",
  },
  {
    name: "Slushie Capital",
    symbol: "SLUSH",
    slug: "slushie-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slushie-capital.png",
  },
  {
    name: "Lean",
    symbol: "LEAN",
    slug: "lean-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lean-protocol.png",
  },
  {
    name: "Block Forest",
    symbol: "BFT",
    slug: "block-forest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block-forest.png",
  },
  {
    name: "Presale.World",
    symbol: "PRESALE",
    slug: "presale-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/presale-world.png",
  },
  {
    name: "Voltbit",
    symbol: "VBit",
    slug: "voltbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voltbit.png",
  },
  {
    name: "Vanspor Token",
    symbol: "VAN",
    slug: "vanspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanspor-token.png",
  },
  {
    name: "SAFESPACE",
    symbol: "SAFESPACE",
    slug: "safespace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safespace.png",
  },
  {
    name: "CORN DecaSwap",
    symbol: "CORN",
    slug: "corn-decaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corn-decaswap.png",
  },
  {
    name: "Hatayspor Token",
    symbol: "HATAY",
    slug: "hatayspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hatayspor-token.png",
  },
  {
    name: "Miyazaki Inu",
    symbol: "MIYAZAKI",
    slug: "miyazaki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miyazaki-inu.png",
  },
  {
    name: "Radditarium Network",
    symbol: "RADDIT",
    slug: "radditarium-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radditarium-network.png",
  },
  {
    name: "Fayre",
    symbol: "FAYRE",
    slug: "fayre",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fayre.png",
  },
  {
    name: "Viper Protocol",
    symbol: "VIPER",
    slug: "viper-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viper-protocol.png",
  },
  {
    name: "Venusia",
    symbol: "VENUS",
    slug: "venusia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venusia.png",
  },
  {
    name: "egoD",
    symbol: "EGOD",
    slug: "egod",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egod.png",
  },
  {
    name: "Seraphium",
    symbol: "SRPH",
    slug: "seraphium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seraphium.png",
  },
  {
    name: "RichieRich Coin",
    symbol: "$RICH",
    slug: "richierich-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/richierich-coin.png",
  },
  {
    name: "Space Soldier",
    symbol: "SOLDIER",
    slug: "space-soldier",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-soldier.png",
  },
  {
    name: "Headbangers Club",
    symbol: "HEADBANGERS",
    slug: "headbangers-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/headbangers-club.png",
  },
  {
    name: "LINKS Token",
    symbol: "LINKS",
    slug: "links-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/links-token.png",
  },
  {
    name: "CAKECRYPT",
    symbol: "CAKECRYPT",
    slug: "cakecrypt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cakecrypt.png",
  },
  {
    name: "REMI",
    symbol: "REMI",
    slug: "remi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/remi.png",
  },
  {
    name: "World of Waves",
    symbol: "WOW",
    slug: "world-of-waves",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-of-waves.png",
  },
  {
    name: "CakeBaker",
    symbol: "CAKEBAKER",
    slug: "cakebaker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cakebaker.png",
  },
  {
    name: "NFTBOX.fun",
    symbol: "NFTBOX",
    slug: "nftbox-fun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftbox-fun.png",
  },
  {
    name: "Cool Monke Banana",
    symbol: "CMB",
    slug: "cool-monke-banana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cool-monke-banana.png",
  },
  {
    name: "MonkeyCoin",
    symbol: "MKMOON",
    slug: "monkeycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monkeycoin.png",
  },
  {
    name: "Doge Pup Token",
    symbol: "DOGEPUP",
    slug: "doge-pup-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-pup-token.png",
  },
  {
    name: "Chubby Inu",
    symbol: "CHINU",
    slug: "chubby-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chubby-inu.png",
  },
  {
    name: "Tetsu Inu",
    symbol: "TETSU",
    slug: "tetsu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tetsu-inu.png",
  },
  {
    name: "Bitsubishi",
    symbol: "BITSU",
    slug: "bitsubishi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitsubishi.png",
  },
  {
    name: "Infiniti",
    symbol: "INTO",
    slug: "infiniti",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infiniti.png",
  },
  {
    name: "Matrak Fan Token",
    symbol: "MTRK",
    slug: "matrak-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrak-fan-token.png",
  },
  {
    name: "Keisuke Inu",
    symbol: "$KEI",
    slug: "keisuke-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keisuke-inu.png",
  },
  {
    name: "Minter HUB",
    symbol: "HUB",
    slug: "minter-hub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minter-hub.png",
  },
  {
    name: "SKYLARK",
    symbol: "SKYLARK",
    slug: "skylark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skylark.png",
  },
  {
    name: "Polars",
    symbol: "POL",
    slug: "polars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polars.png",
  },
  {
    name: "Chester.Moon",
    symbol: "CTRFI",
    slug: "chester-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chester-moon.png",
  },
  {
    name: "Mars Ecosystem Token",
    symbol: "XMS",
    slug: "mars-ecosystem-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mars-ecosystem-governance-token.png",
  },
  {
    name: "Bat Finance",
    symbol: "BAT",
    slug: "bat-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bat-finance.png",
  },
  {
    name: "Sirius Bond",
    symbol: "SRSB",
    slug: "sirius-bond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sirius-bond.png",
  },
  {
    name: "LYS Capital",
    symbol: "LYS",
    slug: "lys-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lys-capital.png",
  },
  {
    name: "Chibi Inu",
    symbol: "CHIBI",
    slug: "chibi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chibi-inu.png",
  },
  {
    name: "FiboDex",
    symbol: "FIBO",
    slug: "fibodex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fibodex.png",
  },
  {
    name: "RODO",
    symbol: "RODO",
    slug: "rodo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rodo.png",
  },
  {
    name: "Panda Finance",
    symbol: "PAND",
    slug: "panda-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/panda-finance.png",
  },
  {
    name: "GETART",
    symbol: "GAX",
    slug: "getart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/getart.png",
  },
  {
    name: "Agent Shiba I.N.U.",
    symbol: "AGENTSHIBAINU",
    slug: "agent-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agent-shiba-inu.png",
  },
  {
    name: "Fitmin Finance",
    symbol: "FTM",
    slug: "fitmin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fitmin-finance.png",
  },
  {
    name: "Degis",
    symbol: "DEG",
    slug: "degis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degis.png",
  },
  {
    name: "DINGO TOKEN",
    symbol: "DINGO",
    slug: "dingo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dingo-token.png",
  },
  {
    name: "Senator Karen",
    symbol: "KAREN",
    slug: "senator-karen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/senator-karen.png",
  },
  {
    name: "ASHARE",
    symbol: "ASHARE",
    slug: "ashare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ashare.png",
  },
  {
    name: "BuckSwap",
    symbol: "BUCKS",
    slug: "buckswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buckswap.png",
  },
  {
    name: "STARSHIP",
    symbol: "STARSHIP",
    slug: "starship",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starship.png",
  },
  {
    name: "Unilab",
    symbol: "ULAB",
    slug: "unilab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unilab.png",
  },
  {
    name: "Burn1 Coin",
    symbol: "BURN1",
    slug: "burn1-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burn1-coin.png",
  },
  {
    name: "VitaDAO",
    symbol: "VITA",
    slug: "vitadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vitadao.png",
  },
  {
    name: "CenterPrime",
    symbol: "CPX",
    slug: "center-prime-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/center-prime-token.png",
  },
  {
    name: "The Wolf Pack",
    symbol: "PACK",
    slug: "the-wolf-pack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-wolf-pack.png",
  },
  {
    name: "SolChicks Shards",
    symbol: "SHARDS",
    slug: "solchicks-shards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solchicks-shards.png",
  },
  {
    name: "The Chad Token",
    symbol: "CHAD",
    slug: "the-chad-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-chad-project.png",
  },
  {
    name: "Doge Solar",
    symbol: "DSOLAR",
    slug: "doge-solar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-solar.png",
  },
  {
    name: "Chihuahua",
    symbol: "HUA",
    slug: "chihuahua",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chihuahua.png",
  },
  {
    name: "ZooCoin",
    symbol: "ZOO",
    slug: "zoocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoocoin.png",
  },
  {
    name: "Corgi inu",
    symbol: "CORGI",
    slug: "corgi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corgi-inu.png",
  },
  {
    name: "Grim EVO",
    symbol: "GRIM EVO",
    slug: "grim-evo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grim-evo.png",
  },
  {
    name: "Mew Inu",
    symbol: "MEW",
    slug: "mew-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mew-inu.png",
  },
  {
    name: "ShadowFi",
    symbol: "SDF",
    slug: "shadowfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shadowfi.png",
  },
  {
    name: "Kangaroo",
    symbol: "GAR",
    slug: "kangaroo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kangaroo.png",
  },
  {
    name: "PinkElon",
    symbol: "PINKE",
    slug: "pinkelon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pinkelon.png",
  },
  {
    name: "Squidverse 3D",
    symbol: "SVG",
    slug: "squidverse3d",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squidverse3d.png",
  },
  {
    name: "X Doge",
    symbol: "X",
    slug: "x-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-doge.png",
  },
  {
    name: "Atlantis Loans Polygon",
    symbol: "ATLX",
    slug: "atlantis-loans-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlantis-loans-polygon.png",
  },
  {
    name: "X-Token",
    symbol: "X-TOKEN",
    slug: "x-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-token.png",
  },
  {
    name: "BoozeMoon",
    symbol: "BOOZE",
    slug: "boozemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boozemoon.png",
  },
  {
    name: "ILGON",
    symbol: "ILG",
    slug: "ilgon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ilgon.png",
  },
  {
    name: "iZUMi Bond USD",
    symbol: "IUSD",
    slug: "izumi-bond-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/izumi-bond-usd.png",
  },
  {
    name: "Crimecash CGOLD",
    symbol: "CGOLD",
    slug: "crimecash-cgold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crimecash-cgold.png",
  },
  {
    name: "Elon Sperm",
    symbol: "SPERM",
    slug: "esperm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/esperm.png",
  },
  {
    name: "PolyZap Finance",
    symbol: "PZAP",
    slug: "polyzap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyzap-finance.png",
  },
  {
    name: "Twerk Finance",
    symbol: "TWERK",
    slug: "twerk-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twerk-finance.png",
  },
  {
    name: "Bikini Finance",
    symbol: "BIKINI",
    slug: "bikini-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bikini-finance.png",
  },
  {
    name: "Gaptt",
    symbol: "GAPT",
    slug: "gaptt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gaptt.png",
  },
  {
    name: "DracooMaster",
    symbol: "BAS",
    slug: "dracoomaster-bas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dracoomaster-bas.png",
  },
  {
    name: "MILF Token",
    symbol: "MILF",
    slug: "milf-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milf-token.png",
  },
  {
    name: "0xTrade",
    symbol: "0XT",
    slug: "0xtrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0xtrade.png",
  },
  {
    name: "SafeJupiter $SFJP",
    symbol: "SFJP",
    slug: "safejupiter-sfjp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safejupiter-sfjp.png",
  },
  {
    name: "FAHRENHEIT CHAIN",
    symbol: "WFAC",
    slug: "fahrenheit-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fahrenheit-chain.png",
  },
  {
    name: "Purple Floki Inu",
    symbol: "PURPLEFLOKI",
    slug: "purple-floki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/purple-floki-inu.png",
  },
  {
    name: "UPDOG",
    symbol: "UPDOG",
    slug: "updog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/updog.png",
  },
  {
    name: "Moms I'd Like to Fund",
    symbol: "MILF",
    slug: "moms-id-like-to-fund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moms-id-like-to-fund.png",
  },
  {
    name: "GemHUB",
    symbol: "GHUB",
    slug: "gemhub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gemhub.png",
  },
  {
    name: "EZDex",
    symbol: "EZX",
    slug: "ezdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ezdex.png",
  },
  {
    name: "GlobalGive",
    symbol: "GGIVE",
    slug: "globalgive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/globalgive.png",
  },
  {
    name: "DeFiHorse",
    symbol: "DFH",
    slug: "defihorse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defihorse.png",
  },
  {
    name: "DogeDao Finance",
    symbol: "DOGEDAO",
    slug: "dogedao-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogedao-finance.png",
  },
  {
    name: "Healthify",
    symbol: "HTF",
    slug: "healthify",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/healthify.png",
  },
  {
    name: "CAD Coin",
    symbol: "CADC",
    slug: "cad-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cad-coin.png",
  },
  {
    name: "GaiaDAO",
    symbol: "GAIA",
    slug: "gaiadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gaiadao.png",
  },
  {
    name: "REU",
    symbol: "REU",
    slug: "reu-earth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reu-earth.png",
  },
  {
    name: "FEED Token",
    symbol: "FEED",
    slug: "feed-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feed-token.png",
  },
  {
    name: "DollarDoge",
    symbol: "DOLLARDOGE",
    slug: "dollardoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dollardoge.png",
  },
  {
    name: "Sleepy Sloth Finance",
    symbol: "SLEEPY",
    slug: "sleepy-sloth-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sleepy-sloth-finance.png",
  },
  {
    name: "ETHFan Burn",
    symbol: "$EFB",
    slug: "ethfan-burn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethfan-burn.png",
  },
  {
    name: "Soltato FRIES",
    symbol: "FRIES",
    slug: "soltato-fries",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soltato-fries.png",
  },
  {
    name: "PlumCake Finance",
    symbol: "PLUM",
    slug: "plumcake-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plumcake-finance.png",
  },
  {
    name: "Meishu",
    symbol: "MEISHU",
    slug: "meishu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meishu.png",
  },
  {
    name: "Moma Protocol",
    symbol: "MOMAT",
    slug: "moma-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moma-protocol.png",
  },
  {
    name: "Nemesis",
    symbol: "NMS",
    slug: "nms-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nms-token.png",
  },
  {
    name: "Akitavax",
    symbol: "AKITAX",
    slug: "akitavax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akitavax.png",
  },
  {
    name: "Zaddy Inu Token",
    symbol: "ZADDY",
    slug: "zaddy-inu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zaddy-inu-token.png",
  },
  {
    name: "MegaMoon",
    symbol: "MGMOON",
    slug: "megamoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megamoon.png",
  },
  {
    name: "ElonTech",
    symbol: "ETCH",
    slug: "elontech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elontech.png",
  },
  {
    name: "BlossomCoin",
    symbol: "BLOSM",
    slug: "blossomcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blossomcoin.png",
  },
  {
    name: "Trapeza Protocol",
    symbol: "FIDL",
    slug: "trapeza-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trapeza-protocol.png",
  },
  {
    name: "CLOUT",
    symbol: "CLOUT",
    slug: "clout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clout.png",
  },
  {
    name: "CRE8R DAO",
    symbol: "CRE8R",
    slug: "cre8r-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cre8r-dao.png",
  },
  {
    name: "DragonKnight",
    symbol: "DK",
    slug: "dragonknight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonknight.png",
  },
  {
    name: "Cyber City",
    symbol: "CYBR",
    slug: "cyber-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyber-city.png",
  },
  {
    name: "MonoMoney",
    symbol: "MONO",
    slug: "monomoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monomoney.png",
  },
  {
    name: "Aerochain",
    symbol: "AERO",
    slug: "aerochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aerochain.png",
  },
  {
    name: "ShibaPup",
    symbol: "SHIBAPUP",
    slug: "shibapup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibapup.png",
  },
  {
    name: "Rich Santa",
    symbol: "SANTA",
    slug: "rich-santa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rich-santa.png",
  },
  {
    name: "X-Consoles",
    symbol: "GAME",
    slug: "x-consoles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-consoles.png",
  },
  {
    name: "GOAT Zuckerberg",
    symbol: "ZGOAT",
    slug: "goat-zuckerberg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goat-zuckerberg.png",
  },
  {
    name: "PastryPunks",
    symbol: "PASTRYPUNKS",
    slug: "pastrypunks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pastrypunks.png",
  },
  {
    name: "Rabbit token",
    symbol: "RBT",
    slug: "rabbit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rabbit-token.png",
  },
  {
    name: "mStable BTC",
    symbol: "MBTC",
    slug: "mstable-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mstable-btc.png",
  },
  {
    name: "Bastion Protocol",
    symbol: "BSTN",
    slug: "bastion-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bastion-protocol.png",
  },
  {
    name: "Solarfare",
    symbol: "SLF",
    slug: "solarfare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solarfare.png",
  },
  {
    name: "Widercoin",
    symbol: "WDR",
    slug: "widercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/widercoin.png",
  },
  {
    name: "Nowar",
    symbol: "NOWAR",
    slug: "nowar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nowar.png",
  },
  {
    name: "SafeXI",
    symbol: "SXI",
    slug: "safexi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safexi.png",
  },
  {
    name: "Nftfy",
    symbol: "NFTFY",
    slug: "nftfy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftfy.png",
  },
  {
    name: "Wrapped Statera",
    symbol: "WSTA",
    slug: "wrapped-statera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-statera.png",
  },
  {
    name: "DYAKON",
    symbol: "DYN",
    slug: "dyakon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dyakon.png",
  },
  {
    name: "Elena Protocol",
    symbol: "ELENA",
    slug: "elena-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elena-protocol.png",
  },
  {
    name: "Governance ALGO",
    symbol: "gALGO",
    slug: "governance-algo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/governance-algo.png",
  },
  {
    name: "ElenaUSD",
    symbol: "USE",
    slug: "elanausd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elanausd.png",
  },
  {
    name: "Xcavator International",
    symbol: "XCA",
    slug: "xcavator-international",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xcavator-international.png",
  },
  {
    name: "Miracle Universe",
    symbol: "MU",
    slug: "miracle-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miracle-universe.png",
  },
  {
    name: "JaguarSwap",
    symbol: "JAGUAR",
    slug: "jaguarswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jaguarswap.png",
  },
  {
    name: "Nesten",
    symbol: "NIT",
    slug: "nesten",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nesten.png",
  },
  {
    name: "JPG NFT Index",
    symbol: "JPG",
    slug: "jpg-nft-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jpg-nft-index.png",
  },
  {
    name: "CompliFi",
    symbol: "COMFI",
    slug: "complifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/complifi.png",
  },
  {
    name: "Arise Finance",
    symbol: "ARI",
    slug: "arise-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arise-finance.png",
  },
  {
    name: "Baby Shark Tank",
    symbol: "BASHTANK",
    slug: "baby-shark-tank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-shark-tank.png",
  },
  {
    name: "Sunny Side up",
    symbol: "SSU",
    slug: "sunny-side-up",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sunny-side-up.png",
  },
  {
    name: "Tiger Inu Token",
    symbol: "TGRI",
    slug: "tiger-inu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiger-inu-token.png",
  },
  {
    name: "AABC Token",
    symbol: "AABC",
    slug: "aabc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aabc-token.png",
  },
  {
    name: "Tokenfy",
    symbol: "TKNFY",
    slug: "tokenfy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenfy.png",
  },
  {
    name: "Catalina Whales Index",
    symbol: "WHALES",
    slug: "catalina-whales-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catalina-whales-index.png",
  },
  {
    name: "UnitedCrowd",
    symbol: "UCT",
    slug: "unitedcrowd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unitedcrowd.png",
  },
  {
    name: "Anti-Lockdown",
    symbol: "FREE",
    slug: "anti-lockdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anti-lockdown.png",
  },
  {
    name: "Valkyrie Network",
    symbol: "VAL",
    slug: "valkyrie-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valkyrie-network.png",
  },
  {
    name: "Vibe Token",
    symbol: "VIBE",
    slug: "vibe-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vibe-token.png",
  },
  {
    name: "MilkyWay",
    symbol: "MILKY",
    slug: "milkyway",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milkyway.png",
  },
  {
    name: "LaikaCoin",
    symbol: "LAIKA",
    slug: "laikacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laikacoin.png",
  },
  {
    name: "Monstock",
    symbol: "MON",
    slug: "monstock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monstock.png",
  },
  {
    name: "Göztepe S.K. Fan Token",
    symbol: "GOZ",
    slug: "goztepe-sk-fantoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goztepe-sk-fantoken.png",
  },
  {
    name: "Block2Play",
    symbol: "B2P",
    slug: "block2play",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block2play.png",
  },
  {
    name: "Monkey Token V2",
    symbol: "MBY",
    slug: "monkey-token-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monkey-token-v2.png",
  },
  {
    name: "Ara Blocks",
    symbol: "ARA",
    slug: "ara-blocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ara-blocks.png",
  },
  {
    name: "Decentralized Pirates",
    symbol: "DEPI",
    slug: "decentralized-pirates",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-pirates.png",
  },
  {
    name: "OpenBiSea",
    symbol: "OBS",
    slug: "openbisea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/openbisea.png",
  },
  {
    name: "Agave",
    symbol: "AGVE",
    slug: "agave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agave.png",
  },
  {
    name: "MetaLama",
    symbol: "LAMA",
    slug: "metalama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metalama.png",
  },
  {
    name: "T-mac DAO",
    symbol: "TMG",
    slug: "t-mac-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/t-mac-dao.png",
  },
  {
    name: "CrossPad",
    symbol: "CROSS",
    slug: "crosspad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crosspad.png",
  },
  {
    name: "Venus Cardano",
    symbol: "vADA",
    slug: "venus-cardano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-cardano.png",
  },
  {
    name: "Blockchain Adventurers Guild",
    symbol: "BAG",
    slug: "blockchain-adventurers-guild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockchain-adventurers-guild.png",
  },
  {
    name: "Venus Dogecoin",
    symbol: "vDOGE",
    slug: "venus-dogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-dogecoin.png",
  },
  {
    name: "Chicken Zilla",
    symbol: "CHKN",
    slug: "chicken-zilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chicken-zilla.png",
  },
  {
    name: "Ghost Farmer Capital",
    symbol: "GFC",
    slug: "ghost-farmer-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghost-farmer-capital.png",
  },
  {
    name: "The Akragas Decadrachm",
    symbol: "THEAD",
    slug: "the-akragas-decadrachm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-akragas-decadrachm.png",
  },
  {
    name: "Icarus Finance",
    symbol: "ICA",
    slug: "icarus-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icarus-finance.png",
  },
  {
    name: "AltCoin",
    symbol: "ALT",
    slug: "altcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altcoin-token.png",
  },
  {
    name: "Babytoken",
    symbol: "BABY",
    slug: "babytoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babytoken.png",
  },
  {
    name: "MyRichFarm",
    symbol: "RCH",
    slug: "myrichfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myrichfarm.png",
  },
  {
    name: "MoonBoys",
    symbol: "MBS",
    slug: "moonboys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonboys.png",
  },
  {
    name: "TrustRise",
    symbol: "TRISE",
    slug: "trustrise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustrise.png",
  },
  {
    name: "Streamer Inu",
    symbol: "STREAMERINU",
    slug: "streamer-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/streamer-inu.png",
  },
  {
    name: "Bet To Earn",
    symbol: "BTE",
    slug: "bet-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bet-to-earn.png",
  },
  {
    name: "Baby Santa Token v2",
    symbol: "BSTV2",
    slug: "baby-santa-token-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-santa-token-v2.png",
  },
  {
    name: "ZTB",
    symbol: "ZTB",
    slug: "ztb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ztb.png",
  },
  {
    name: "Cosmic Champs",
    symbol: "COSG",
    slug: "cosmic-champs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cosmic-champs.png",
  },
  {
    name: "EmploymentCoin",
    symbol: "EC2",
    slug: "employmentcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/employmentcoin.png",
  },
  {
    name: "Globiance Exchange Token",
    symbol: "GBEX",
    slug: "globiance-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/globiance-exchange-token.png",
  },
  {
    name: "youves",
    symbol: "YOU",
    slug: "you",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/you.png",
  },
  {
    name: "MustangToken",
    symbol: "MUST",
    slug: "mustangtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mustangtoken.png",
  },
  {
    name: "Phantasia Sports",
    symbol: "$FANT",
    slug: "phantasia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phantasia.png",
  },
  {
    name: "The Reaper",
    symbol: "RPR",
    slug: "the-reaper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-reaper.png",
  },
  {
    name: "KILIMANJARO",
    symbol: "KILI",
    slug: "kilimanjaro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kilimanjaro.png",
  },
  {
    name: "BEM",
    symbol: "BEMT",
    slug: "bem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bem.png",
  },
  {
    name: "Portuma",
    symbol: "POR",
    slug: "portuma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/portuma.png",
  },
  {
    name: "QFinance",
    symbol: "QFI",
    slug: "qfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qfinance.png",
  },
  {
    name: "Artrade",
    symbol: "ATR",
    slug: "artrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artrade.png",
  },
  {
    name: "Daxhund",
    symbol: "DXH",
    slug: "daxhund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daxhund.png",
  },
  {
    name: "IMO",
    symbol: "IMO",
    slug: "imo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imo.png",
  },
  {
    name: "Alpha Kongs Club",
    symbol: "AKC",
    slug: "alpha-kongs-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-kongs-club.png",
  },
  {
    name: "Bulldog Token",
    symbol: "BDOG",
    slug: "bulldog-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulldog-token.png",
  },
  {
    name: "Glow Token",
    symbol: "GLOW",
    slug: "glow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glow-token.png",
  },
  {
    name: "Kianite Finance",
    symbol: "KIAN",
    slug: "kianite-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kianite-finance.png",
  },
  {
    name: "Microzillas",
    symbol: "MIZL",
    slug: "microzillas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microzillas.png",
  },
  {
    name: "ziot Coin",
    symbol: "ZIOT",
    slug: "ziot-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ziot-coin.png",
  },
  {
    name: "Louie Duck Token",
    symbol: "LOUIEDUCKT",
    slug: "louie-duck-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/louie-duck-token.png",
  },
  {
    name: "Binacaps",
    symbol: "BCAPS",
    slug: "binacaps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binacaps.png",
  },
  {
    name: "Baby Cat Girl",
    symbol: "BBYCAT",
    slug: "baby-cat-girl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-cat-girl.png",
  },
  {
    name: "Kambria Yield Tuning Engine",
    symbol: "KYTE",
    slug: "kambria-yield-tuning-engine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kambria-yield-tuning-engine.png",
  },
  {
    name: "Veritise",
    symbol: "VTS",
    slug: "veritise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veritise.png",
  },
  {
    name: "ShardingDAO",
    symbol: "SHD",
    slug: "shardingdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shardingdao.png",
  },
  {
    name: "PanicSwap",
    symbol: "PANIC",
    slug: "panicswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/panicswap.png",
  },
  {
    name: "Qawalla",
    symbol: "QWLA",
    slug: "qawalla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qawalla.png",
  },
  {
    name: "B360",
    symbol: "B360",
    slug: "b360",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/b360.png",
  },
  {
    name: "FOHO Coin",
    symbol: "FOHO",
    slug: "foho-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foho-coin.png",
  },
  {
    name: "SEFI",
    symbol: "SEFI",
    slug: "sefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sefi.png",
  },
  {
    name: "RougeCoin",
    symbol: "XRGE",
    slug: "rougecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rougecoin.png",
  },
  {
    name: "Cuffies",
    symbol: "CUFFIES",
    slug: "cuffies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cuffies.png",
  },
  {
    name: "XANALIA",
    symbol: "ALIA",
    slug: "xanalia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xanalia.png",
  },
  {
    name: "ECO",
    symbol: "ECO",
    slug: "eco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eco.png",
  },
  {
    name: "Pumpy farm",
    symbol: "PMP",
    slug: "pumpy-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pumpy-farm.png",
  },
  {
    name: "FansCoin",
    symbol: "FC",
    slug: "fanscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanscoin.png",
  },
  {
    name: "Robo Token",
    symbol: "ROBO",
    slug: "robo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robo-token.png",
  },
  {
    name: "Golden Goal",
    symbol: "GDG",
    slug: "golden-goal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-goal.png",
  },
  {
    name: "Delta Exchange Token",
    symbol: "DETO",
    slug: "delta-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/delta-exchange-token.png",
  },
  {
    name: "Gorilla-Fi",
    symbol: "GFI",
    slug: "gorilla-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gorilla-fi.png",
  },
  {
    name: "ParrotDao",
    symbol: "PARR",
    slug: "parrotdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parrotdao.png",
  },
  {
    name: "Pebble",
    symbol: "PBL",
    slug: "pebble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pebble.png",
  },
  {
    name: "Bit",
    symbol: "BITS",
    slug: "bit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit.png",
  },
  {
    name: "Satoshi",
    symbol: "SATS",
    slug: "satoshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoshi.png",
  },
  {
    name: "XRP-BF2",
    symbol: "XRP-BF2",
    slug: "xrp-bf2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrp-bf2.png",
  },
  {
    name: "Wrapped XDAI",
    symbol: "wxDai",
    slug: "wxdai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wxdai.png",
  },
  {
    name: "AVARA",
    symbol: "AVR",
    slug: "avara",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avara.png",
  },
  {
    name: "AMMYI Coin",
    symbol: "AMI",
    slug: "ammyi-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ammyi-coin.png",
  },
  {
    name: "Kong Defi",
    symbol: "KONG",
    slug: "kong-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kong-defi.png",
  },
  {
    name: "1Shoot Game",
    symbol: "1SH",
    slug: "1shoot-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1shoot-game.png",
  },
  {
    name: "Vegeta Inu",
    symbol: "VEGI",
    slug: "vegeta-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vegeta-inu.png",
  },
  {
    name: "Memes Token",
    symbol: "MEMES",
    slug: "memes-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memes-token.png",
  },
  {
    name: "Solabrador",
    symbol: "SOLAB",
    slug: "solabrador",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solabrador.png",
  },
  {
    name: "Hue",
    symbol: "HUE",
    slug: "hue",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hue.png",
  },
  {
    name: "LNAsolution Coin",
    symbol: "LAS",
    slug: "lnasolution-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lnasolution-coin.png",
  },
  {
    name: "CMF DAO",
    symbol: "CMF",
    slug: "cmf-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cmf-dao.png",
  },
  {
    name: "MYFinance",
    symbol: "MYFI",
    slug: "myfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myfinance.png",
  },
  {
    name: "Sprint Coin",
    symbol: "SPRX",
    slug: "sprint-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sprint-coin.png",
  },
  {
    name: "Reverse Climate Change",
    symbol: "RVRS",
    slug: "reverse-climate-change",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reverse-climate-change.png",
  },
  {
    name: "Rocket Bunny",
    symbol: "BUNNY",
    slug: "rocket-bunny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-bunny.png",
  },
  {
    name: "THANKSGIVING FLOKI",
    symbol: "TFLOKI",
    slug: "thanksgiving-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thanksgiving-floki.png",
  },
  {
    name: "ASI finance",
    symbol: "ASI",
    slug: "asi-finance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asi-finance-token.png",
  },
  {
    name: "Robo Inu Finance",
    symbol: "RBIF",
    slug: "robo-inu-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robo-inu-finance.png",
  },
  {
    name: "RYI Unity",
    symbol: "RYIU",
    slug: "ryi-unity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ryi-unity.png",
  },
  {
    name: "Fomo App",
    symbol: "PAPER",
    slug: "fomo-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fomo-app.png",
  },
  {
    name: "Cheese (V2)",
    symbol: "CHEESE",
    slug: "cheese-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheese-v2.png",
  },
  {
    name: "Fatfi Protocol",
    symbol: "FAT",
    slug: "fatfi-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fatfi-protocol.png",
  },
  {
    name: "X7 Coin",
    symbol: "X7C",
    slug: "x7-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x7-coin.png",
  },
  {
    name: "Aurix",
    symbol: "AUR",
    slug: "aurix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurix.png",
  },
  {
    name: "Meta Club",
    symbol: "CLUB",
    slug: "meta-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-club.png",
  },
  {
    name: "ASI.finance",
    symbol: "BASI",
    slug: "asi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asi-finance.png",
  },
  {
    name: "Definder Network",
    symbol: "DNT",
    slug: "smartlands-network-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartlands-network-new.png",
  },
  {
    name: "The Gemstone",
    symbol: "GST",
    slug: "the-gemstone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-gemstone.png",
  },
  {
    name: "Avaxtars Token",
    symbol: "AVXT",
    slug: "avaxtars-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaxtars-token.png",
  },
  {
    name: "Haze Finance",
    symbol: "HAZE",
    slug: "haze-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/haze-finance.png",
  },
  {
    name: "CheeseSwap",
    symbol: "CHEESE",
    slug: "cheese-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheese-swap.png",
  },
  {
    name: "DKEY BANK",
    symbol: "DKEY",
    slug: "dkey-bank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dkey-bank.png",
  },
  {
    name: "B26 Finance",
    symbol: "B26",
    slug: "b26-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/b26-finance.png",
  },
  {
    name: "DIESEL",
    symbol: "DIESEL",
    slug: "diesel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diesel.png",
  },
  {
    name: "Transmute Protocol",
    symbol: "XPB",
    slug: "transmute-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/transmute-protocol.png",
  },
  {
    name: "Gummy Bull Token",
    symbol: "GUMMY",
    slug: "gummy-bull-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gummy-bull-token.png",
  },
  {
    name: "FlourMix",
    symbol: "FLO",
    slug: "flourmix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flourmix.png",
  },
  {
    name: "Shuffle Monster",
    symbol: "SHUF",
    slug: "shuffle-monster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shuffle-monster.png",
  },
  {
    name: "Prime Whiterock Company",
    symbol: "PWC",
    slug: "prime-whiterock-company",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prime-whiterock-company.png",
  },
  {
    name: "TOKEN SHELBY",
    symbol: "TSY",
    slug: "token-shelby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/token-shelby.png",
  },
  {
    name: "Tanuki Token",
    symbol: "TANUKI",
    slug: "tanuki-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tanuki-token.png",
  },
  {
    name: "AINORI",
    symbol: "AIN",
    slug: "ainori",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ainori.png",
  },
  {
    name: "OBRok Token",
    symbol: "OBROK",
    slug: "obrok-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/obrok-token.png",
  },
  {
    name: "Unique Photo",
    symbol: "FOTO",
    slug: "unique-photo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unique-photo.png",
  },
  {
    name: "Ankr Reward Bearing Staked BNB",
    symbol: "aBNBc",
    slug: "ankr-reward-bearing-staked-bnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ankr-reward-bearing-staked-bnb.png",
  },
  {
    name: "Wealthlocks",
    symbol: "WLT",
    slug: "wealthlocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wealthlocks.png",
  },
  {
    name: "BUGG Finance",
    symbol: "BUGG",
    slug: "bugg-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bugg-finance.png",
  },
  {
    name: "Star Ship Royal",
    symbol: "SSR",
    slug: "star-ship-royal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/star-ship-royal.png",
  },
  {
    name: "AnonToken",
    symbol: "AT",
    slug: "anontoken-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anontoken-v2.png",
  },
  {
    name: "Polkazeck",
    symbol: "ZCK",
    slug: "polkazeck",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkazeck.png",
  },
  {
    name: "BighBull",
    symbol: "BIGB",
    slug: "bighbull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bighbull.png",
  },
  {
    name: "DAWG",
    symbol: "DAWG",
    slug: "dawg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dawg.png",
  },
  {
    name: "Parallel",
    symbol: "PAR",
    slug: "parallel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parallel.png",
  },
  {
    name: "Next Token",
    symbol: "NXT",
    slug: "next-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/next-token.png",
  },
  {
    name: "wanEOS",
    symbol: "WANEOS",
    slug: "waneos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waneos.png",
  },
  {
    name: "Civic Power",
    symbol: "POWER",
    slug: "civic-power",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/civic-power.png",
  },
  {
    name: "Aave Enjin",
    symbol: "aENJ",
    slug: "aave-enjin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-enjin.png",
  },
  {
    name: "MagicBox",
    symbol: "MBT",
    slug: "magicbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magicbox.png",
  },
  {
    name: "BigBoys Industry",
    symbol: "BBI",
    slug: "bigboys-industry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bigboys-industry.png",
  },
  {
    name: "PieDAO DEFI Small Cap",
    symbol: "DEFI+S",
    slug: "piedao-defi-small-cap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piedao-defi-small-cap.png",
  },
  {
    name: "Mad Monkey Guild",
    symbol: "MMG",
    slug: "mad-monkey-guild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mad-monkey-guild.png",
  },
  {
    name: "BlockBlend",
    symbol: "BBL",
    slug: "blockblend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockblend.png",
  },
  {
    name: "Candy Protocol",
    symbol: "CAD",
    slug: "candy-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/candy-protocol.png",
  },
  {
    name: "WhaleRoom",
    symbol: "WHL",
    slug: "whaleroom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whaleroom.png",
  },
  {
    name: "NumisMe",
    symbol: "NUME",
    slug: "numisme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/numisme.png",
  },
  {
    name: "Freedom DeFi",
    symbol: "FREE",
    slug: "freedom-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freedom-defi.png",
  },
  {
    name: "DexMex",
    symbol: "DEXM",
    slug: "dexmex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexmex.png",
  },
  {
    name: "GaStream",
    symbol: "GSTRM",
    slug: "gastream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gastream.png",
  },
  {
    name: "Creator Protocol",
    symbol: "CRE",
    slug: "creator-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creator-protocol.png",
  },
  {
    name: "Rich Maker",
    symbol: "RICH",
    slug: "rich-maker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rich-maker.png",
  },
  {
    name: "Optical Bitcoin",
    symbol: "OBTC",
    slug: "optical-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/optical-bitcoin.png",
  },
  {
    name: "SunShield",
    symbol: "SSHLD",
    slug: "sunshield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sunshield.png",
  },
  {
    name: "Kazama Senshi",
    symbol: "KAZAMA",
    slug: "kazama-senshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kazama-senshi.png",
  },
  {
    name: "CORD.Finance",
    symbol: "CORD",
    slug: "cord-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cord-finance.png",
  },
  {
    name: "FlatQube",
    symbol: "QUBE",
    slug: "flatqube",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flatqube.png",
  },
  {
    name: "VanillaCake",
    symbol: "VCAKE",
    slug: "vanillacake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanillacake.png",
  },
  {
    name: "Crow Finance",
    symbol: "CROW",
    slug: "crow-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crow-finance.png",
  },
  {
    name: "DeepCoin",
    symbol: "DC",
    slug: "deep-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deep-coin.png",
  },
  {
    name: "MetaWhale BTC",
    symbol: "MWBTC",
    slug: "metawhale-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metawhale-btc.png",
  },
  {
    name: "Parsiq Boost",
    symbol: "PRQBOOST",
    slug: "parsiq-boost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parsiq-boost.png",
  },
  {
    name: "PhotoChromic",
    symbol: "PHCR",
    slug: "photochromic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/photochromic.png",
  },
  {
    name: "TAMA EGG NiftyGotchi",
    symbol: "TME",
    slug: "tama-egg-niftygotchi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tama-egg-niftygotchi.png",
  },
  {
    name: "BLACK FRIDAY DOGE",
    symbol: "BLACKFRIDAY",
    slug: "black-friday-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-friday-doge.png",
  },
  {
    name: "Stadium",
    symbol: "STD",
    slug: "stadium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stadium.png",
  },
  {
    name: "DeFiStarter",
    symbol: "DFST",
    slug: "defistarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defistarter.png",
  },
  {
    name: "USDFreeLiquidity",
    symbol: "USDFL",
    slug: "usdfreeliquidity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdfreeliquidity.png",
  },
  {
    name: "Shiba Hunter",
    symbol: "SHUNT",
    slug: "shiba-hunter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-hunter.png",
  },
  {
    name: "Rug Proof",
    symbol: "RPT",
    slug: "rug-proof",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rug-proof.png",
  },
  {
    name: "Atlantis",
    symbol: "ATLAS",
    slug: "atlantis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlantis.png",
  },
  {
    name: "Option Token",
    symbol: "OT",
    slug: "option-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/option-token.png",
  },
  {
    name: "TheToken.Network",
    symbol: "TTN",
    slug: "thetoken-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thetoken-network.png",
  },
  {
    name: "AveFarm",
    symbol: "AVE",
    slug: "avefarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avefarm.png",
  },
  {
    name: "Qroni",
    symbol: "QNI",
    slug: "qroni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qroni.png",
  },
  {
    name: "MoonDayPlus",
    symbol: "MD+",
    slug: "moondayplus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moondayplus.png",
  },
  {
    name: "Roboots",
    symbol: "RBO",
    slug: "roboots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roboots.png",
  },
  {
    name: "Cult DAO",
    symbol: "CULT",
    slug: "cult-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cult-dao.png",
  },
  {
    name: "Beluxuria",
    symbol: "BLUX",
    slug: "beluxuria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beluxuria.png",
  },
  {
    name: "Venus BETH",
    symbol: "VBETH",
    slug: "venus-beth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-beth.png",
  },
  {
    name: "Jito Staked SOL",
    symbol: "JITOSOL",
    slug: "jito-staked-sol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jito-staked-sol.png",
  },
  {
    name: "Civitas Protocol",
    symbol: "CVT",
    slug: "civitas-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/civitas-protocol.png",
  },
  {
    name: "UpStableToken",
    symbol: "USTX",
    slug: "upstabletoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upstabletoken.png",
  },
  {
    name: "USDEX",
    symbol: "USDEX",
    slug: "usdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdex.png",
  },
  {
    name: "BlackBerry tokenized stock FTX",
    symbol: "BB",
    slug: "blackberry-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackberry-tokenized-stock-ftx.png",
  },
  {
    name: "Bitcoin E-wallet",
    symbol: "BITWALLET",
    slug: "bitcoinwallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinwallet.png",
  },
  {
    name: "Algodex Token",
    symbol: "ALGX",
    slug: "algodex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algodex-token.png",
  },
  {
    name: "ACU Platform",
    symbol: "ACU",
    slug: "acu-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acu-platform.png",
  },
  {
    name: "KoaKombat",
    symbol: "KOACOMBAT",
    slug: "koakombat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koakombat.png",
  },
  {
    name: "TECHNOLOGY INNOVATION PROJECT",
    symbol: "TIP",
    slug: "technology-innovation-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/technology-innovation-project.png",
  },
  {
    name: "Xstable.Protocol",
    symbol: "XST",
    slug: "xstable-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xstable-protocol.png",
  },
  {
    name: "Wrapped Fuse",
    symbol: "WFUSE",
    slug: "wrapped-fuse-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-fuse-network.png",
  },
  {
    name: "Moon Rocket Coin",
    symbol: "MRC",
    slug: "moonrocketcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonrocketcoin.png",
  },
  {
    name: "CURE Farm",
    symbol: "CURE",
    slug: "cure-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cure-farm.png",
  },
  {
    name: "SafeShiba",
    symbol: "SAFESHIB",
    slug: "safeshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeshiba.png",
  },
  {
    name: "EOX",
    symbol: "EOX",
    slug: "eox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eox.png",
  },
  {
    name: "Bancambios AX",
    symbol: "BXS",
    slug: "bancambios-ax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bancambios-ax.png",
  },
  {
    name: "PieDAO Yearn Ecosystem Pie",
    symbol: "YPIE",
    slug: "piedao-yearn-ecosystem-pie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piedao-yearn-ecosystem-pie.png",
  },
  {
    name: "interBTC",
    symbol: "IBTC",
    slug: "interbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/interbtc.png",
  },
  {
    name: "Fountain Protocol",
    symbol: "FTP",
    slug: "fountain-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fountain-protocol.png",
  },
  {
    name: "Xbullion",
    symbol: "GOLD",
    slug: "xbullion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xbullion.png",
  },
  {
    name: "POC Blockchain",
    symbol: "POC",
    slug: "poc-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poc-blockchain.png",
  },
  {
    name: "Upload Token",
    symbol: "UPLOAD",
    slug: "upload-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upload-token.png",
  },
  {
    name: "Bitbot Protocol",
    symbol: "BBP",
    slug: "bitbot-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitbot-protocol.png",
  },
  {
    name: "StakeHound Staked Ether",
    symbol: "STETH",
    slug: "stakehound-staked-ether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakehound-staked-ether.png",
  },
  {
    name: "BUSD Buffet",
    symbol: "BBFT",
    slug: "busd-buffet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/busd-buffet.png",
  },
  {
    name: "Ether Cards",
    symbol: "DUST",
    slug: "ether-cards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ether-cards.png",
  },
  {
    name: "Pokeball",
    symbol: "POKE",
    slug: "pokeball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pokeball.png",
  },
  {
    name: "Sakata Inu",
    symbol: "SAKATA",
    slug: "sakata-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sakata-inu.png",
  },
  {
    name: "Mirrored Goldman Sachs Group Inc",
    symbol: "mGS",
    slug: "mirrored-goldman-sachs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-goldman-sachs.png",
  },
  {
    name: "Bomb US Money",
    symbol: "BUSM",
    slug: "bomb-us-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bomb-us-money.png",
  },
  {
    name: "Yearn Shark Finance",
    symbol: "YSKF",
    slug: "yearn-shark-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-shark-finance.png",
  },
  {
    name: "Mindfolk Wood",
    symbol: "$WOOD",
    slug: "mindfolk-wood",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mindfolk-wood.png",
  },
  {
    name: "Hithotx",
    symbol: "HITX",
    slug: "hithotx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hithotx.png",
  },
  {
    name: "Maximus",
    symbol: "MAXI",
    slug: "maximus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maximus.png",
  },
  {
    name: "Fission Cash",
    symbol: "FCX",
    slug: "fission-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fission-cash.png",
  },
  {
    name: "SafeDogecoin",
    symbol: "SAFEDOGE",
    slug: "safedogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safedogecoin.png",
  },
  {
    name: "Tronx Coin",
    symbol: "TRONX",
    slug: "tronx-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tronx-coin.png",
  },
  {
    name: "Metakings",
    symbol: "MTK",
    slug: "metakings",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metakings.png",
  },
  {
    name: "Venus Filecoin",
    symbol: "vFIL",
    slug: "venus-filecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-filecoin.png",
  },
  {
    name: "Delphi Chain Link",
    symbol: "DCL",
    slug: "delphi-chain-link",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/delphi-chain-link.png",
  },
  {
    name: "Definex",
    symbol: "DSWAP",
    slug: "definex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/definex.png",
  },
  {
    name: "BitDNS",
    symbol: "DNS",
    slug: "bitdns",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitdns.png",
  },
  {
    name: "Trism",
    symbol: "TRISM",
    slug: "trism",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trism.png",
  },
  {
    name: "PartyFi",
    symbol: "PFI",
    slug: "partyfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/partyfi.png",
  },
  {
    name: "Gilgamesh ETH",
    symbol: "GIL",
    slug: "gilgamesh-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gilgamesh-eth.png",
  },
  {
    name: "Genyen",
    symbol: "$GYEN",
    slug: "genyen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genyen.png",
  },
  {
    name: "Zipmex",
    symbol: "ZMT",
    slug: "zipmex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zipmex.png",
  },
  {
    name: "Supreme Finance",
    symbol: "HYPE",
    slug: "hype",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hype.png",
  },
  {
    name: "DRC mobility",
    symbol: "DRC",
    slug: "drc-mobility",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drc-mobility.png",
  },
  {
    name: "Diffract Finance",
    symbol: "DFR",
    slug: "diffract-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diffract-finance.png",
  },
  {
    name: "Companion",
    symbol: "CMPN",
    slug: "companion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/companion.png",
  },
  {
    name: "miniSHIB",
    symbol: "MSHIB",
    slug: "minishib-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minishib-token.png",
  },
  {
    name: "DP Token",
    symbol: "DPT",
    slug: "dp-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dp-token.png",
  },
  {
    name: "Sleepy-Shib",
    symbol: "SLEEPY-SHIB",
    slug: "sleepy-shib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sleepy-shib.png",
  },
  {
    name: "MetaRewards",
    symbol: "MRW",
    slug: "metarewards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metarewards.png",
  },
  {
    name: "CryptoBank",
    symbol: "CBANK",
    slug: "cryptobank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobank.png",
  },
  {
    name: "ZUG",
    symbol: "ZUG",
    slug: "etherorcs-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherorcs-token.png",
  },
  {
    name: "Tacoswap",
    symbol: "TACO",
    slug: "tacoswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tacoswap.png",
  },
  {
    name: "Lorde Edge",
    symbol: "EDGELON",
    slug: "lorde-edge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lorde-edge.png",
  },
  {
    name: "DeFiant",
    symbol: "DEFI",
    slug: "defiant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defiant.png",
  },
  {
    name: "Orient",
    symbol: "OFT",
    slug: "orient",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orient.png",
  },
  {
    name: "BlackHoleSwap-Compound DAI/USDC",
    symbol: "BHSC",
    slug: "blackholeswap-compound-dai-usdc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackholeswap-compound-dai-usdc.png",
  },
  {
    name: "Firulais",
    symbol: "FIRU",
    slug: "firulais",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firulais.png",
  },
  {
    name: "BicycleFi",
    symbol: "BCF",
    slug: "bicyclefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bicyclefi.png",
  },
  {
    name: "Max Revive",
    symbol: "MAXR",
    slug: "max-revive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/max-revive.png",
  },
  {
    name: "Rootkit Finance",
    symbol: "ROOT",
    slug: "rootkit-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rootkit-finance.png",
  },
  {
    name: "EverSAFUv2",
    symbol: "ES2",
    slug: "eversafuv2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eversafuv2.png",
  },
  {
    name: "HolderMoon",
    symbol: "HLM",
    slug: "holdermoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holdermoon.png",
  },
  {
    name: "Capital.Finance",
    symbol: "CAP",
    slug: "capital-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/capital-finance.png",
  },
  {
    name: "Cino Games",
    symbol: "CINO",
    slug: "cino-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cino-games.png",
  },
  {
    name: "Edgwin Finance",
    symbol: "EDG",
    slug: "edgwin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edgwin-finance.png",
  },
  {
    name: "Taxi",
    symbol: "TAXI",
    slug: "taxi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taxi.png",
  },
  {
    name: "DefiPlaza",
    symbol: "DFP2",
    slug: "defiplaza",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defiplaza.png",
  },
  {
    name: "Refract",
    symbol: "RFR",
    slug: "refract",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/refract.png",
  },
  {
    name: "Crolend",
    symbol: "CRD",
    slug: "crolend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crolend.png",
  },
  {
    name: "Fast Food Wolf Game",
    symbol: "FFWOOL",
    slug: "fast-food-wolf-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fast-food-wolf-game.png",
  },
  {
    name: "iShares MSCI Emerging Markets ETF Defichain",
    symbol: "DEEM",
    slug: "energy-efficient-mortgage-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energy-efficient-mortgage-tokenized-stock-defichain.png",
  },
  {
    name: "Milk",
    symbol: "MILK",
    slug: "milk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milk.png",
  },
  {
    name: "HLand Token",
    symbol: "HLAND",
    slug: "hland-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hland-token.png",
  },
  {
    name: "DeFiato",
    symbol: "DFIAT",
    slug: "defiato",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defiato.png",
  },
  {
    name: "HeroesOfCrypton",
    symbol: "HEROES",
    slug: "heroesofcrypton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heroesofcrypton.png",
  },
  {
    name: "Carole Baskin Token",
    symbol: "CFB",
    slug: "carole-baskint-oken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carole-baskint-oken.png",
  },
  {
    name: "PIRANHAS",
    symbol: "$PIR",
    slug: "piranhas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piranhas.png",
  },
  {
    name: "HubCoin",
    symbol: "HUB",
    slug: "hubcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hubcoin.png",
  },
  {
    name: "MediconnectUk",
    symbol: "MEDI",
    slug: "mediconnectuk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mediconnectuk.png",
  },
  {
    name: "Natural Farm Union Protocol",
    symbol: "NFUP",
    slug: "natural-farm-union-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/natural-farm-union-protocol.png",
  },
  {
    name: "CZbusd",
    symbol: "CZBUSD",
    slug: "czbusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/czbusd.png",
  },
  {
    name: "SAFE TOKEN",
    symbol: "SAFE",
    slug: "safe-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-token.png",
  },
  {
    name: "Ripio Coin",
    symbol: "RPC",
    slug: "ripio-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ripio-coin.png",
  },
  {
    name: "Predictz",
    symbol: "PRDZ",
    slug: "predictz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/predictz.png",
  },
  {
    name: "CronosVerse",
    symbol: "VRSE",
    slug: "cronosverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cronosverse.png",
  },
  {
    name: "Honey",
    symbol: "HNY",
    slug: "honey-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honey-token.png",
  },
  {
    name: "Neorbit",
    symbol: "NRB",
    slug: "neorbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neorbit.png",
  },
  {
    name: "Energy8",
    symbol: "E8",
    slug: "energy8",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energy8.png",
  },
  {
    name: "Banana",
    symbol: "BANANA",
    slug: "banana-cyberkongz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banana-cyberkongz.png",
  },
  {
    name: "Venus XRP",
    symbol: "vXRP",
    slug: "venus-xrp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-xrp.png",
  },
  {
    name: "Grace Period Token",
    symbol: "GPT",
    slug: "grace-period-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grace-period-token.png",
  },
  {
    name: "A-List Royale",
    symbol: "ALIST",
    slug: "a-list-royale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/a-list-royale.png",
  },
  {
    name: "FOMPOUND",
    symbol: "FOMP",
    slug: "fompound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fompound.png",
  },
  {
    name: "HOME Coin",
    symbol: "HOME",
    slug: "baconcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baconcoin-token.png",
  },
  {
    name: "Thought",
    symbol: "THT",
    slug: "thought",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thought.png",
  },
  {
    name: "Netflix tokenized stock Bittrex",
    symbol: "NFLX",
    slug: "netflix-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netflix-tokenized-stock-bittrex.png",
  },
  {
    name: "GoldenDiamond9",
    symbol: "G9",
    slug: "goldendiamond9",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldendiamond9.png",
  },
  {
    name: "Facebook tokenized stock Bittrex",
    symbol: "FB",
    slug: "facebook-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/facebook-tokenized-stock-bittrex.png",
  },
  {
    name: "Yeni Malatyaspor Token",
    symbol: "YMS",
    slug: "yeni-malatyaspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yeni-malatyaspor-token.png",
  },
  {
    name: "BioNTech tokenized stock Bittrex",
    symbol: "BNTX",
    slug: "biontech-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biontech-tokenized-stock-bittrex.png",
  },
  {
    name: "Beyond Meat Inc tokenized stock Bittrex",
    symbol: "BYND",
    slug: "beyond-meat-inc-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beyond-meat-inc-tokenized-stock-bittrex.png",
  },
  {
    name: "Chain Pet",
    symbol: "CPET",
    slug: "chain-pet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain-pet.png",
  },
  {
    name: "KingSpeed",
    symbol: "KSC",
    slug: "kingspeed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingspeed.png",
  },
  {
    name: "VizslaSwap",
    symbol: "VIZSLASWAP",
    slug: "vizslaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vizslaswap.png",
  },
  {
    name: "BioNTech tokenized stock FTX",
    symbol: "BNTX",
    slug: "biontech-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biontech-tokenized-stock-ftx.png",
  },
  {
    name: "Swiss NFT Fund",
    symbol: "SWISSNFTFUND",
    slug: "swiss-nft-fund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swiss-nft-fund.png",
  },
  {
    name: "Guarded Ether",
    symbol: "GETH",
    slug: "guarded-ether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guarded-ether.png",
  },
  {
    name: "NEXTEP",
    symbol: "NEXTEP",
    slug: "nextep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nextep.png",
  },
  {
    name: "Groovy Finance",
    symbol: "GVY",
    slug: "groovy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/groovy-finance.png",
  },
  {
    name: "Avalanche HoneyBee",
    symbol: "A.BEE",
    slug: "avalanche-honeybee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avalanche-honeybee.png",
  },
  {
    name: "Blue Floki Inu",
    symbol: "BLUEFLOKI",
    slug: "blue-floki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blue-floki-inu.png",
  },
  {
    name: "Kols Offering Token",
    symbol: "KOT",
    slug: "kols-offering-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kols-offering-token.png",
  },
  {
    name: "League of Zodiacs",
    symbol: "LOZ",
    slug: "league-of-zodiacs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/league-of-zodiacs.png",
  },
  {
    name: "Moderna tokenized stock FTX",
    symbol: "MRNA",
    slug: "moderna-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moderna-tokenized-stock-ftx.png",
  },
  {
    name: "CMC Coin",
    symbol: "CMCC",
    slug: "cmc-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cmc-coin.png",
  },
  {
    name: "Beyond Meat tokenized stock FTX",
    symbol: "BYND",
    slug: "beyond-meat-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beyond-meat-tokenized-stock-ftx.png",
  },
  {
    name: "FrogZilla",
    symbol: "FZL",
    slug: "frogzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frogzilla.png",
  },
  {
    name: "GrowMoon",
    symbol: "GM",
    slug: "growmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/growmoon.png",
  },
  {
    name: "RebelTraderToken",
    symbol: "RTT",
    slug: "rebeltradertoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rebeltradertoken.png",
  },
  {
    name: "ECOC Financial Growth",
    symbol: "EFG",
    slug: "ecoc-financial-growth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecoc-financial-growth.png",
  },
  {
    name: "Space Hamster",
    symbol: "HAMS",
    slug: "space-hamster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-hamster.png",
  },
  {
    name: "Degov",
    symbol: "DEGOV",
    slug: "degov",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degov.png",
  },
  {
    name: "Apple Finance",
    symbol: "APLP",
    slug: "apple-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apple-finance.png",
  },
  {
    name: "Arcade",
    symbol: "ARC",
    slug: "arcade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcade.png",
  },
  {
    name: "Bitpumps Token",
    symbol: "BPT",
    slug: "bitpumps-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitpumps-token.png",
  },
  {
    name: "Eggzilla",
    symbol: "EGG",
    slug: "eggzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eggzilla.png",
  },
  {
    name: "Pando Token",
    symbol: "PTX",
    slug: "pando-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pando-token.png",
  },
  {
    name: "LeLeFoodChain",
    symbol: "LELE",
    slug: "lelefoodchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lelefoodchain.png",
  },
  {
    name: "Wolfgirl",
    symbol: "WLFGRL",
    slug: "wolfgirl-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolfgirl-token.png",
  },
  {
    name: "Tradebitpay",
    symbol: "TBP",
    slug: "tradebitpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tradebitpay.png",
  },
  {
    name: "HoldToWin",
    symbol: "7ADD",
    slug: "holdtowin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holdtowin.png",
  },
  {
    name: "Komet",
    symbol: "KOMET",
    slug: "komet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/komet.png",
  },
  {
    name: "HIKOBABA",
    symbol: "HIKO",
    slug: "hikobaba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hikobaba.png",
  },
  {
    name: "Argo Finance",
    symbol: "ARGO",
    slug: "argo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/argo-finance.png",
  },
  {
    name: "TOM Finance",
    symbol: "TOM",
    slug: "tom-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tom-finance.png",
  },
  {
    name: "AFKDAO",
    symbol: "AFK",
    slug: "afkdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/afkdao.png",
  },
  {
    name: "Light Coin Exchange Token",
    symbol: "LCT",
    slug: "light-coin-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/light-coin-exchange-token.png",
  },
  {
    name: "Flash Technologies",
    symbol: "FLASH",
    slug: "flash-technologies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flash-technologies.png",
  },
  {
    name: "Brother Music Platform",
    symbol: "BMP",
    slug: "brother-music-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brother-music-platform.png",
  },
  {
    name: "X Infinity",
    symbol: "XIF",
    slug: "x-infinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-infinity.png",
  },
  {
    name: "xUSD",
    symbol: "XUSD",
    slug: "xusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xusd.png",
  },
  {
    name: "Fromm Car",
    symbol: "FCR",
    slug: "fromm-car",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fromm-car.png",
  },
  {
    name: "Agro Global",
    symbol: "AGRO",
    slug: "agro-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agro-global.png",
  },
  {
    name: "Unbound Dollar",
    symbol: "UND",
    slug: "unbound-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unbound-dollar.png",
  },
  {
    name: "SUP8EME",
    symbol: "SUP8EME",
    slug: "sup8eme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sup8eme.png",
  },
  {
    name: "Chirp",
    symbol: "CHIRP",
    slug: "chirp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chirp.png",
  },
  {
    name: "ixirswap",
    symbol: "IXIR",
    slug: "ixirswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ixirswap.png",
  },
  {
    name: "Studyum",
    symbol: "STUD",
    slug: "studyum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/studyum.png",
  },
  {
    name: "Blueshare Token",
    symbol: "BST1",
    slug: "blueshare-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blueshare-token.png",
  },
  {
    name: "Wrapped Accumulate",
    symbol: "WACME",
    slug: "wrapped-accumulate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-accumulate.png",
  },
  {
    name: "Island Boyz",
    symbol: "$ISLBYZ",
    slug: "island-boyz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/island-boyz.png",
  },
  {
    name: "Space Corsair Key",
    symbol: "SCK",
    slug: "space-corsair-key",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-corsair-key.png",
  },
  {
    name: "Conjee",
    symbol: "CONJ",
    slug: "conjee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/conjee.png",
  },
  {
    name: "MEDPING",
    symbol: "MPG",
    slug: "medping",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medping.png",
  },
  {
    name: "Olympia",
    symbol: "OLP",
    slug: "olympia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olympia.png",
  },
  {
    name: "EduMetrix Coin",
    symbol: "EMC",
    slug: "edumetrix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edumetrix.png",
  },
  {
    name: "DeFi Hunters DAO",
    symbol: "DDAO",
    slug: "defi-hunters-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-hunters-dao.png",
  },
  {
    name: "7Finance",
    symbol: "SVN",
    slug: "7finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/7finance.png",
  },
  {
    name: "The Mars Shiba",
    symbol: "MARSSHIBA",
    slug: "the-mars-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-mars-shiba.png",
  },
  {
    name: "LIQUID",
    symbol: "LIQUID",
    slug: "liquidefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquidefi.png",
  },
  {
    name: "Theca",
    symbol: "THECA",
    slug: "theca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/theca.png",
  },
  {
    name: "XFII",
    symbol: "XFII",
    slug: "xfii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xfii.png",
  },
  {
    name: "AOK",
    symbol: "AOK",
    slug: "aok",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aok.png",
  },
  {
    name: "ToxicGameNFT",
    symbol: "TXC",
    slug: "toxicgamenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toxicgamenft.png",
  },
  {
    name: "Future-Cash Digital",
    symbol: "FCD",
    slug: "future-cash-digital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/future-cash-digital.png",
  },
  {
    name: "Grimace.top",
    symbol: "Grimace",
    slug: "grimace-top",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grimace-top.png",
  },
  {
    name: "wormhole.finance",
    symbol: "WHOLE",
    slug: "wormhole-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wormhole-finance.png",
  },
  {
    name: "Dexioprotocol (new)",
    symbol: "DEXI",
    slug: "dexioprotocol-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexioprotocol-v2.png",
  },
  {
    name: "YFIII",
    symbol: "YFIII",
    slug: "yfiii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfiii.png",
  },
  {
    name: "Monsta Infinite",
    symbol: "STT",
    slug: "stt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stt.png",
  },
  {
    name: "AlternateMoney",
    symbol: "AM",
    slug: "alternatemoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alternatemoney.png",
  },
  {
    name: "PicaArtMoney",
    symbol: "PICA",
    slug: "picaartmoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/picaartmoney.png",
  },
  {
    name: "DCD Ecosystem",
    symbol: "DCD",
    slug: "dcd-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dcd-ecosystem.png",
  },
  {
    name: "Yearn Finance Bit2",
    symbol: "YFB2",
    slug: "yearn-finance-bit2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance-bit2.png",
  },
  {
    name: "FlokiSwap",
    symbol: "FLOKIS",
    slug: "flokiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokiswap.png",
  },
  {
    name: "Dogeon",
    symbol: "DON",
    slug: "dogeon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeon.png",
  },
  {
    name: "Fiola",
    symbol: "FLA",
    slug: "fiola",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fiola.png",
  },
  {
    name: "FaceDAO",
    symbol: "FACEDAO",
    slug: "facedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/facedao.png",
  },
  {
    name: "Arrb Token",
    symbol: "ARRB",
    slug: "arrb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arrb-token.png",
  },
  {
    name: "zHEGIC",
    symbol: "ZHEGIC",
    slug: "zhegic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zhegic.png",
  },
  {
    name: "Kanpeki",
    symbol: "KAE",
    slug: "kanpeki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kanpeki.png",
  },
  {
    name: "Betero",
    symbol: "BTE",
    slug: "betero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betero.png",
  },
  {
    name: "Leve Invest",
    symbol: "LEVE",
    slug: "leve-invest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leve-invest.png",
  },
  {
    name: "CSP DAO",
    symbol: "NEBO",
    slug: "csp-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/csp-dao.png",
  },
  {
    name: "E$P Project",
    symbol: "E$P",
    slug: "esp-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/esp-project.png",
  },
  {
    name: "BabyBanana",
    symbol: "BBNANA",
    slug: "babybanana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babybanana.png",
  },
  {
    name: "SERGS",
    symbol: "SERGS",
    slug: "sergs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sergs.png",
  },
  {
    name: "YYFI.Protocol",
    symbol: "YYFI",
    slug: "yyfi-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yyfi-protocol.png",
  },
  {
    name: "Adalend",
    symbol: "ADAL",
    slug: "adalend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adalend.png",
  },
  {
    name: "HubDao",
    symbol: "HD",
    slug: "hubdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hubdao.png",
  },
  {
    name: "Shiny Ore",
    symbol: "SO",
    slug: "shiny-ore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiny-ore.png",
  },
  {
    name: "Naz Coin",
    symbol: "NAZ",
    slug: "naz-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/naz-coin.png",
  },
  {
    name: "BitOnyx",
    symbol: "BTNYX",
    slug: "bitonyx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitonyx.png",
  },
  {
    name: "DogeCondoms",
    symbol: "DOCO",
    slug: "dogecondoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogecondoms.png",
  },
  {
    name: "FROGGO",
    symbol: "FROGGO",
    slug: "froggo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/froggo.png",
  },
  {
    name: "Enoki Finance",
    symbol: "SPORE",
    slug: "enoki-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enoki-finance.png",
  },
  {
    name: "NFTPunk",
    symbol: "NFTPUNK2.0",
    slug: "nftpunk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftpunk.png",
  },
  {
    name: "BananoDOS",
    symbol: "yBAN",
    slug: "bananodos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bananodos.png",
  },
  {
    name: "Tokenoid",
    symbol: "NOID",
    slug: "tokenoid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenoid.png",
  },
  {
    name: "Kyte.One",
    symbol: "KTE",
    slug: "kyte-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kyte-one.png",
  },
  {
    name: "BABYDRIP",
    symbol: "BABYDRIP",
    slug: "babydrip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babydrip.png",
  },
  {
    name: "McNetworkDefi",
    symbol: "MCN",
    slug: "mcnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcnetwork.png",
  },
  {
    name: "StoneDAO",
    symbol: "SDT",
    slug: "stonedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stonedao.png",
  },
  {
    name: "T99 Token",
    symbol: "TNN",
    slug: "t99-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/t99-token.png",
  },
  {
    name: "Voice Street",
    symbol: "VST",
    slug: "voice-street",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voice-street.png",
  },
  {
    name: "1Move",
    symbol: "1MT",
    slug: "1move",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1move.png",
  },
  {
    name: "Apiary Fund Coin",
    symbol: "AFC",
    slug: "apiary-fund-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apiary-fund-coin.png",
  },
  {
    name: "Max Bidding",
    symbol: "$MAX",
    slug: "max-bidding",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/max-bidding.png",
  },
  {
    name: "Kronos DAO",
    symbol: "KRNO",
    slug: "kronos-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kronos-dao.png",
  },
  {
    name: "Cordium",
    symbol: "CORD",
    slug: "cordium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cordium.png",
  },
  {
    name: "PayUSD",
    symbol: "PUSD",
    slug: "payusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payusd.png",
  },
  {
    name: "yRise Finance",
    symbol: "YRISE",
    slug: "yrise-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yrise-finance.png",
  },
  {
    name: "Bit Financial",
    symbol: "BFC",
    slug: "bit-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit-financial.png",
  },
  {
    name: "Knights & Peasants",
    symbol: "KNIGHT",
    slug: "knights-peasants",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knights-peasants.png",
  },
  {
    name: "Bozkurt Token",
    symbol: "BT",
    slug: "bozkurt-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bozkurt-token.png",
  },
  {
    name: "DogeShiba",
    symbol: "DOSHIB",
    slug: "dogeshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeshiba.png",
  },
  {
    name: "Bitfinex Bitcoin Dominance Perps",
    symbol: "BTCDOM",
    slug: "bitfinex-bitcoin-dominance-perps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitfinex-bitcoin-dominance-perps.png",
  },
  {
    name: "Azzure",
    symbol: "AZZR",
    slug: "azzure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/azzure.png",
  },
  {
    name: "Tavittcoin",
    symbol: "TAVITT",
    slug: "tavittcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tavittcoin.png",
  },
  {
    name: "SelfBar",
    symbol: "SBAR",
    slug: "selfbar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/selfbar.png",
  },
  {
    name: "r/CryptoCurrency Moons",
    symbol: "xMOON",
    slug: "moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon.png",
  },
  {
    name: "Degen Ape Club",
    symbol: "DAC",
    slug: "degen-ape-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degen-ape-club.png",
  },
  {
    name: "deCraft Finance",
    symbol: "CRAFT",
    slug: "decraft-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decraft-finance.png",
  },
  {
    name: "Fish",
    symbol: "FISH",
    slug: "fish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fish.png",
  },
  {
    name: "Atrollcity",
    symbol: "PINE",
    slug: "atrollcity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atrollcity.png",
  },
  {
    name: "CyBloc Battery Token",
    symbol: "CBT",
    slug: "cybloc-battery-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cybloc-battery-token.png",
  },
  {
    name: "WhaleGirl",
    symbol: "WGIRL",
    slug: "whalegirl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whalegirl.png",
  },
  {
    name: "Fridn",
    symbol: "EFAR",
    slug: "fridn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fridn.png",
  },
  {
    name: "DegenX",
    symbol: "DGNX",
    slug: "degenx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degenx.png",
  },
  {
    name: "Scratch",
    symbol: "SCRATCH",
    slug: "scratch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scratch.png",
  },
  {
    name: "Jiviz",
    symbol: "JVZ",
    slug: "jiviz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jiviz.png",
  },
  {
    name: "Effort Economy",
    symbol: "EFFORT",
    slug: "effort-economy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/effort-economy.png",
  },
  {
    name: "QOOBER",
    symbol: "QOOB",
    slug: "qoober",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qoober.png",
  },
  {
    name: "Athos Finance",
    symbol: "ATH",
    slug: "athos-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/athos-finance.png",
  },
  {
    name: "YFPRO Finance",
    symbol: "YFPRO",
    slug: "yfpro-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfpro-finance.png",
  },
  {
    name: "Baby Ripple",
    symbol: "BABYXRP",
    slug: "baby-ripple",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-ripple.png",
  },
  {
    name: "MyTVchain",
    symbol: "MYTV",
    slug: "mytvchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mytvchain.png",
  },
  {
    name: "BagSwap",
    symbol: "BSWAP",
    slug: "bagswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bagswap.png",
  },
  {
    name: "VOYCE TOKEN",
    symbol: "VOYCE",
    slug: "voyce-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voyce-token.png",
  },
  {
    name: "OWL Token (StealthSwap)",
    symbol: "OWL",
    slug: "owl-token-stealthswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/owl-token-stealthswap.png",
  },
  {
    name: "Starfish OS",
    symbol: "IGT",
    slug: "starfish-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starfish-token.png",
  },
  {
    name: "Great Bounty Dealer",
    symbol: "GBD",
    slug: "great-bounty-dealer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/great-bounty-dealer.png",
  },
  {
    name: "colR Coin",
    symbol: "$COLR",
    slug: "colr-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colr-coin.png",
  },
  {
    name: "YFOS.finance",
    symbol: "YFOS",
    slug: "yfos-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfos-finance.png",
  },
  {
    name: "Doge Protocol",
    symbol: "DOGEP",
    slug: "doge-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-protocol.png",
  },
  {
    name: "HunterDoge",
    symbol: "$HD",
    slug: "hunterdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hunterdoge.png",
  },
  {
    name: "Bellevue Network",
    symbol: "BLV",
    slug: "bellevue-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bellevue-network.png",
  },
  {
    name: "Kindness For Soul",
    symbol: "$KFS",
    slug: "kindness-for-soul",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kindness-for-soul.png",
  },
  {
    name: "Vivaion",
    symbol: "VIVAION",
    slug: "vivaion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vivaion.png",
  },
  {
    name: "Boop",
    symbol: "BOOP",
    slug: "boop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boop.png",
  },
  {
    name: "Smol",
    symbol: "SMOL",
    slug: "smol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smol.png",
  },
  {
    name: "xDollar Stablecoin",
    symbol: "XUSD",
    slug: "xdollar-stablecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdollar-stablecoin.png",
  },
  {
    name: "X-HASH",
    symbol: "XSH",
    slug: "x-hash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-hash.png",
  },
  {
    name: "Yearn Finance Ecosystem",
    symbol: "YFIEC",
    slug: "yearn-finance-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance-ecosystem.png",
  },
  {
    name: "Meme Doge Coin",
    symbol: "MEMEDOGE",
    slug: "meme-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meme-doge-coin.png",
  },
  {
    name: "Euphoria",
    symbol: "WAGMI",
    slug: "wagmi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wagmi.png",
  },
  {
    name: "Kompass",
    symbol: "KOMP",
    slug: "kompass",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kompass.png",
  },
  {
    name: "Coinwaycoin",
    symbol: "CAN",
    slug: "coinwaycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinwaycoin.png",
  },
  {
    name: "Serene",
    symbol: "SERENE",
    slug: "serene",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/serene.png",
  },
  {
    name: "inheritance Art",
    symbol: "IAI",
    slug: "inheritance-art",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inheritance-art.png",
  },
  {
    name: "Metric Exchange",
    symbol: "METRIC",
    slug: "metric-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metric-exchange.png",
  },
  {
    name: "Yoink",
    symbol: "YNK",
    slug: "yoink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yoink.png",
  },
  {
    name: "Poseidon Token",
    symbol: "POS",
    slug: "poseidon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poseidon-token.png",
  },
  {
    name: "DoKEN",
    symbol: "DOKEN",
    slug: "doken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doken.png",
  },
  {
    name: "AmericanHorror.Finance",
    symbol: "AHF",
    slug: "americanhorror-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/americanhorror-finance.png",
  },
  {
    name: "Trading Membership Community",
    symbol: "TMC",
    slug: "trading-membership-community",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trading-membership-community.png",
  },
  {
    name: "Yearn Land",
    symbol: "YLAND",
    slug: "yearn-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-land.png",
  },
  {
    name: "Rise2Protocol",
    symbol: "RIPR",
    slug: "rise2protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rise2protocol.png",
  },
  {
    name: "CXN Network",
    symbol: "CXN",
    slug: "cxn-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cxn-network.png",
  },
  {
    name: "Kineko",
    symbol: "KNK",
    slug: "kineko1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kineko1.png",
  },
  {
    name: "CryptoWater",
    symbol: "C2O",
    slug: "cryptowater",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptowater.png",
  },
  {
    name: "Elo Inu",
    symbol: "ELO INU",
    slug: "elo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elo-inu.png",
  },
  {
    name: "Safari",
    symbol: "SFR",
    slug: "safari",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safari.png",
  },
  {
    name: "ANKR Reward Bearing MATIC",
    symbol: "AMATICC",
    slug: "ankr-reward-earning-matic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ankr-reward-earning-matic.png",
  },
  {
    name: "SpokLottery",
    symbol: "SPKL",
    slug: "spoklottery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spoklottery.png",
  },
  {
    name: "Honey",
    symbol: "HNY",
    slug: "honey-hive-investments",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honey-hive-investments.png",
  },
  {
    name: "CY Finance",
    symbol: "CYF",
    slug: "cy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cy-finance.png",
  },
  {
    name: "Combine.finance",
    symbol: "COMB",
    slug: "combine-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/combine-finance.png",
  },
  {
    name: "Tcbcoin",
    symbol: "TCFX",
    slug: "tcbcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tcbcoin.png",
  },
  {
    name: "Contribute",
    symbol: "TRIB",
    slug: "contribute",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/contribute.png",
  },
  {
    name: "Reesykle",
    symbol: "SYKLE",
    slug: "reesykle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reesykle.png",
  },
  {
    name: "Acuity Token",
    symbol: "ACU",
    slug: "acuity-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acuity-token.png",
  },
  {
    name: "AstridDAO Token",
    symbol: "ATID",
    slug: "astriddao-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astriddao-token.png",
  },
  {
    name: "Gire Token",
    symbol: "GET",
    slug: "gire-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gire-token.png",
  },
  {
    name: "HARU",
    symbol: "HARU",
    slug: "haru",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/haru.png",
  },
  {
    name: "concertVR-Token",
    symbol: "CVT",
    slug: "concertvr-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/concertvr-token.png",
  },
  {
    name: "Digital Currency Daily",
    symbol: "DCD",
    slug: "digital-currency-daily",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-currency-daily.png",
  },
  {
    name: "Degree Crypto Token",
    symbol: "DCT",
    slug: "degree-crypto-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degree-crypto-token.png",
  },
  {
    name: "Toshify.finance",
    symbol: "YFT",
    slug: "toshify-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toshify-finance.png",
  },
  {
    name: "Chives Coin",
    symbol: "XCC",
    slug: "chives-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chives-coin.png",
  },
  {
    name: "ROKKIT FUEL",
    symbol: "$ROKK",
    slug: "rokkit-fuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rokkit-fuel.png",
  },
  {
    name: "QI Blockchain",
    symbol: "QIE",
    slug: "qi-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qi-blockchain.png",
  },
  {
    name: "Digipad",
    symbol: "DGP",
    slug: "digipad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digipad.png",
  },
  {
    name: "Ternio-ERC20",
    symbol: "TERN",
    slug: "ternio-erc20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ternio-erc20.png",
  },
  {
    name: "ISTARDUST",
    symbol: "ISDT",
    slug: "istardust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/istardust.png",
  },
  {
    name: "Snowtomb",
    symbol: "STOMB",
    slug: "snowtomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowtomb.png",
  },
  {
    name: "Finswap",
    symbol: "FNSP",
    slug: "finswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/finswap.png",
  },
  {
    name: "iPulse",
    symbol: "PLS",
    slug: "ipulse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ipulse.png",
  },
  {
    name: "Amphorium",
    symbol: "AMH",
    slug: "amphorium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amphorium.png",
  },
  {
    name: "Livenodes Token",
    symbol: "LNOT",
    slug: "livenodes-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/livenodes-token.png",
  },
  {
    name: "Funex",
    symbol: "FUNEX",
    slug: "funex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/funex.png",
  },
  {
    name: "Shill & Win",
    symbol: "PoSH",
    slug: "shill-win",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shill-win.png",
  },
  {
    name: "Dollo Coin",
    symbol: "DOLLO",
    slug: "dollo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dollo-coin.png",
  },
  {
    name: "YFMoonshot",
    symbol: "YFMS",
    slug: "yfmoonshot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfmoonshot.png",
  },
  {
    name: "Tr3zor",
    symbol: "TR3",
    slug: "tr3zor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tr3zor.png",
  },
  {
    name: "QuizDrop",
    symbol: "QDROP",
    slug: "quizdrop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quizdrop.png",
  },
  {
    name: "Litherium",
    symbol: "LITH",
    slug: "litherium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litherium.png",
  },
  {
    name: "Va Na Su",
    symbol: "VNS",
    slug: "va-na-su",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/va-na-su.png",
  },
  {
    name: "Jurassic Crypto",
    symbol: "JRSC",
    slug: "jurassic-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jurassic-crypto.png",
  },
  {
    name: "MatsuSwap",
    symbol: "MATSUSWAP",
    slug: "matsuswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matsuswap.png",
  },
  {
    name: "Musk Melon",
    symbol: "MELON",
    slug: "musk-melon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musk-melon.png",
  },
  {
    name: "Oracolxor",
    symbol: "XOR",
    slug: "oracolxor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oracolxor.png",
  },
  {
    name: "Trace Network Labs",
    symbol: "TRACE",
    slug: "trace-network-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trace-network-labs.png",
  },
  {
    name: "YFarmLand Token",
    symbol: "YFARMER",
    slug: "yfarmland-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfarmland-token.png",
  },
  {
    name: "Pyroworld",
    symbol: "PYRO",
    slug: "pyroworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyroworld.png",
  },
  {
    name: "Bostrom",
    symbol: "BOOT",
    slug: "bostrom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bostrom.png",
  },
  {
    name: "Tribute",
    symbol: "TRBT",
    slug: "tribute",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tribute.png",
  },
  {
    name: "Rolaz Gold",
    symbol: "rGLD",
    slug: "rolaz-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rolaz-gold.png",
  },
  {
    name: "Fan Tokens Football",
    symbol: "FTF",
    slug: "fan-tokens-football",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fan-tokens-football.png",
  },
  {
    name: "Momentum",
    symbol: "XMM",
    slug: "momentum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/momentum.png",
  },
  {
    name: "Energyfi",
    symbol: "EFT",
    slug: "energyfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energyfi.png",
  },
  {
    name: "wLitiDAO",
    symbol: "WLD",
    slug: "wlitidao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wlitidao.png",
  },
  {
    name: "KIRA",
    symbol: "KIRA",
    slug: "kira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kira.png",
  },
  {
    name: "Ymen.Finance",
    symbol: "YMEN",
    slug: "ymen-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ymen-finance.png",
  },
  {
    name: "Ferret",
    symbol: "FRT",
    slug: "ferret",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ferret.png",
  },
  {
    name: "Lottonation",
    symbol: "LNT",
    slug: "lottonation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lottonation.png",
  },
  {
    name: "Shinjurai",
    symbol: "SHINJURAI",
    slug: "shinjurai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinjurai.png",
  },
  {
    name: "Trickle",
    symbol: "H2O",
    slug: "trickle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trickle.png",
  },
  {
    name: "Dragon Ball",
    symbol: "DRAGON",
    slug: "dragon-ball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-ball.png",
  },
  {
    name: "The Next World Coin",
    symbol: "TNC",
    slug: "the-next-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-next-world.png",
  },
  {
    name: "Project X Nodes",
    symbol: "PXT",
    slug: "project-x-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-x-nodes.png",
  },
  {
    name: "OBIC",
    symbol: "OBIC",
    slug: "obic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/obic.png",
  },
  {
    name: "New Year Bull",
    symbol: "NYB",
    slug: "new-year-bull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-year-bull.png",
  },
  {
    name: "Piggy Planet",
    symbol: "PIGI",
    slug: "piggy-planet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piggy-planet.png",
  },
  {
    name: "Predix Network",
    symbol: "PRDX",
    slug: "predix-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/predix-network.png",
  },
  {
    name: "Stadium Pepe",
    symbol: "SPEP",
    slug: "stadium-pepe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stadium-pepe.png",
  },
  {
    name: "ShibaNFT",
    symbol: "SHIBANFT",
    slug: "shibanft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibanft.png",
  },
  {
    name: "Moebius",
    symbol: "MOBI",
    slug: "moebius",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moebius.png",
  },
  {
    name: "LAMEA",
    symbol: "LAMEA",
    slug: "lamea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lamea.png",
  },
  {
    name: "Zenith Token",
    symbol: "ZENX",
    slug: "zenith-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenith-token.png",
  },
  {
    name: "YFIKING,FINANCE",
    symbol: "YFIKING",
    slug: "yfiking-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfiking-finance.png",
  },
  {
    name: "Enceladus Network",
    symbol: "ENCX",
    slug: "enceladus-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enceladus-network.png",
  },
  {
    name: "Parasol",
    symbol: "PSOL",
    slug: "parasol-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parasol-finance.png",
  },
  {
    name: "NFTCircle",
    symbol: "NFTC",
    slug: "nftcircle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftcircle.png",
  },
  {
    name: "BEAR Coin",
    symbol: "BEAR",
    slug: "voytek-bear-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voytek-bear-coin.png",
  },
  {
    name: "Somnium",
    symbol: "SOM",
    slug: "somnium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/somnium.png",
  },
  {
    name: "MetaNations",
    symbol: "MENA",
    slug: "metanations",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metanations.png",
  },
  {
    name: "Duck Punkz Universe Floor Index",
    symbol: "DPUNKZ",
    slug: "duck-punkz-universe-floor-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duck-punkz-universe-floor-index.png",
  },
  {
    name: "Token CashPay",
    symbol: "TCP",
    slug: "token-cashpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/token-cashpay.png",
  },
  {
    name: "Astro Cash",
    symbol: "ASTRO",
    slug: "astro-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astro-cash.png",
  },
  {
    name: "ThunderRacer",
    symbol: "RACERR",
    slug: "thunderracer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thunderracer.png",
  },
  {
    name: "Definitex",
    symbol: "DFX",
    slug: "definitex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/definitex.png",
  },
  {
    name: "CeBioLabs",
    symbol: "CBSL",
    slug: "cebiolabs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cebiolabs.png",
  },
  {
    name: "The Forms",
    symbol: "FRMS",
    slug: "the-forms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-forms.png",
  },
  {
    name: "Ninebot",
    symbol: "NBG",
    slug: "ninebot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninebot.png",
  },
  {
    name: "Meowcoin",
    symbol: "MEWC",
    slug: "meowcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meowcoin.png",
  },
  {
    name: "Yearn Finance Protocol",
    symbol: "YFP",
    slug: "yearn-finance-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance-protocol.png",
  },
  {
    name: "The Underground",
    symbol: "BLOOD",
    slug: "the-underground",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-underground.png",
  },
  {
    name: "G2 Crypto Gaming & Lottery",
    symbol: "G2",
    slug: "g2-crypto-gaming-lottery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/g2-crypto-gaming-lottery.png",
  },
  {
    name: "YFUEL",
    symbol: "YFUEL",
    slug: "yfuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfuel.png",
  },
  {
    name: "RCCCToken",
    symbol: "RCCC",
    slug: "rccctoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rccctoken.png",
  },
  {
    name: "BLOCK-E",
    symbol: "BLOCK-E",
    slug: "block-e",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block-e.png",
  },
  {
    name: "Farmers World Wood",
    symbol: "FWW",
    slug: "farmers-world-wood",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmers-world-wood.png",
  },
  {
    name: "YFA Finance",
    symbol: "YFA",
    slug: "yfa-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfa-finance.png",
  },
  {
    name: "Mirrored Bitcoin",
    symbol: "mBTC",
    slug: "mirrored-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-bitcoin.png",
  },
  {
    name: "Sealem Token",
    symbol: "ST",
    slug: "sealem-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sealem-token.png",
  },
  {
    name: "TRONbetLive",
    symbol: "LIVE",
    slug: "tronbetlive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tronbetlive.png",
  },
  {
    name: "Minereum BSC",
    symbol: "MNEB",
    slug: "minereum-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minereum-bsc.png",
  },
  {
    name: "yfBeta",
    symbol: "YFBETA",
    slug: "yfbeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfbeta.png",
  },
  {
    name: "Atlantis Metaverse",
    symbol: "TAU",
    slug: "atlantis-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlantis-metaverse.png",
  },
  {
    name: "Cheesus",
    symbol: "CHEESUS",
    slug: "cheesus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheesus.png",
  },
  {
    name: "Shockwave Finance",
    symbol: "WAVE",
    slug: "shockwave-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shockwave-finance.png",
  },
  {
    name: "SalmonSwap",
    symbol: "SAL",
    slug: "salmonswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/salmonswap.png",
  },
  {
    name: "Fable Of The Dragon",
    symbol: "TYRANT",
    slug: "fable-of-the-dragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fable-of-the-dragon.png",
  },
  {
    name: "Bast",
    symbol: "BAST",
    slug: "bast",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bast.png",
  },
  {
    name: "EAGLE MINING NETWORK",
    symbol: "EGON",
    slug: "eagle-mining-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eagle-mining-network.png",
  },
  {
    name: "Lucid Lands V2",
    symbol: "LLG",
    slug: "lucid-lands-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucid-lands-v2.png",
  },
  {
    name: "Popcorn",
    symbol: "CORN",
    slug: "popcorn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/popcorn.png",
  },
  {
    name: "Libcoin",
    symbol: "LIB",
    slug: "libcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libcoin.png",
  },
  {
    name: "Refugees Token",
    symbol: "RFG",
    slug: "refugees-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/refugees-token.png",
  },
  {
    name: "SideKick Token",
    symbol: "SK",
    slug: "sidekick-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sidekick-token.png",
  },
  {
    name: "Point Network",
    symbol: "POINT",
    slug: "point-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/point-network.png",
  },
  {
    name: "Poo Doge",
    symbol: "POO DOGE",
    slug: "poo-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poo-doge.png",
  },
  {
    name: "Fee Active Collateral Token",
    symbol: "FACT",
    slug: "fee-active-collateral-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fee-active-collateral-token.png",
  },
  {
    name: "Zomfi",
    symbol: "ZOMFI",
    slug: "zomfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zomfi.png",
  },
  {
    name: "GemPay",
    symbol: "GPAY",
    slug: "gempay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gempay.png",
  },
  {
    name: "GolderGames",
    symbol: "GLDR",
    slug: "goldergames",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldergames.png",
  },
  {
    name: "Rocket Shib",
    symbol: "ROCKETSHIB",
    slug: "rocket-shib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-shib.png",
  },
  {
    name: "CEDARS",
    symbol: "CEDS",
    slug: "cedars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cedars.png",
  },
  {
    name: "Lumiii",
    symbol: "LUMIII",
    slug: "lumiii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lumiii.png",
  },
  {
    name: "Notable",
    symbol: "NBL",
    slug: "notable-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/notable-nft.png",
  },
  {
    name: "The Flash Currency",
    symbol: "TFC",
    slug: "flash-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flash-currency.png",
  },
  {
    name: "Quantbook",
    symbol: "QTBK",
    slug: "quantbook",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quantbook.png",
  },
  {
    name: "SEED",
    symbol: "SEED",
    slug: "seed-flowerpatch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seed-flowerpatch.png",
  },
  {
    name: "Port of DeFi Network",
    symbol: "PDF",
    slug: "port-of-defi-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/port-of-defi-network.png",
  },
  {
    name: "DOFI",
    symbol: "DOO",
    slug: "dofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dofi.png",
  },
  {
    name: "Vision",
    symbol: "VSN",
    slug: "vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vision.png",
  },
  {
    name: "NSUR COIN",
    symbol: "NSUR",
    slug: "nsur-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nsur-coin.png",
  },
  {
    name: "Musk Ito",
    symbol: "MITO",
    slug: "musk-ito",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musk-ito.png",
  },
  {
    name: "Blaze Network",
    symbol: "BLZN",
    slug: "blaze-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blaze-network.png",
  },
  {
    name: "Deploying More Capital",
    symbol: "DMC",
    slug: "deploying-more-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deploying-more-capital.png",
  },
  {
    name: "OC Protocol",
    symbol: "OCP",
    slug: "oc-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oc-protocol.png",
  },
  {
    name: "eEat",
    symbol: "EEAT",
    slug: "eeat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eeat.png",
  },
  {
    name: "New Community Luna",
    symbol: "$CLUNA",
    slug: "new-community-luna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-community-luna.png",
  },
  {
    name: "MIKS COIN",
    symbol: "MIKS",
    slug: "miks-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miks-coin.png",
  },
  {
    name: "Escrow Protocol",
    symbol: "ESCROW",
    slug: "escrow-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/escrow-protocol.png",
  },
  {
    name: "DeFi Omega",
    symbol: "DFIO",
    slug: "defi-omega",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-omega.png",
  },
  {
    name: "Krosscoin",
    symbol: "KSS",
    slug: "krosscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krosscoin.png",
  },
  {
    name: "Porkchop",
    symbol: "CHOP",
    slug: "porkchop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/porkchop.png",
  },
  {
    name: "MetaKeeper",
    symbol: "MKP",
    slug: "metakeeper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metakeeper.png",
  },
  {
    name: "Matrix Protocol",
    symbol: "MTX",
    slug: "matrix-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrix-protocol.png",
  },
  {
    name: "GG TOKEN",
    symbol: "GGTKN",
    slug: "gg-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gg-token.png",
  },
  {
    name: "Kassandra",
    symbol: "KACY",
    slug: "kassandra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kassandra.png",
  },
  {
    name: "AlphaLink",
    symbol: "ANK",
    slug: "alphalink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alphalink.png",
  },
  {
    name: "SDAO",
    symbol: "SDAO",
    slug: "sdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sdao.png",
  },
  {
    name: "Ethereum Vault",
    symbol: "ETHV",
    slug: "ethereum-vault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-vault.png",
  },
  {
    name: "Love Coin",
    symbol: "LOVE",
    slug: "love-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/love-coin.png",
  },
  {
    name: "Community Token",
    symbol: "COM",
    slug: "community-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/community-token.png",
  },
  {
    name: "Lucky Roo",
    symbol: "ROO",
    slug: "lucky-roo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-roo.png",
  },
  {
    name: "Tacos",
    symbol: "TACO",
    slug: "tacos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tacos.png",
  },
  {
    name: "Citizen Finance V2",
    symbol: "CIFI",
    slug: "citizen-finance-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/citizen-finance-v2.png",
  },
  {
    name: "Shiba Elon",
    symbol: "ESHIB",
    slug: "shiba-elon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-elon.png",
  },
  {
    name: "MONEY PARTY",
    symbol: "PARTY",
    slug: "money-party",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/money-party.png",
  },
  {
    name: "Frens Community",
    symbol: "FRENS",
    slug: "frens-community",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frens-community.png",
  },
  {
    name: "ACY FINANCE",
    symbol: "ACY",
    slug: "acy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acy-finance.png",
  },
  {
    name: "ROONEX",
    symbol: "RNX",
    slug: "roonex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roonex.png",
  },
  {
    name: "UniversalEnergyChain",
    symbol: "UENC",
    slug: "universalenergychain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universalenergychain.png",
  },
  {
    name: "Risu",
    symbol: "RISU",
    slug: "risu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/risu.png",
  },
  {
    name: "TRONbetDice",
    symbol: "DICE",
    slug: "tronbetdice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tronbetdice.png",
  },
  {
    name: "Kripto Galaxy Battle",
    symbol: "KABA",
    slug: "kripto-galaxy-battle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kripto-galaxy-battle.png",
  },
  {
    name: "LoveHearts",
    symbol: "LVH",
    slug: "lovehearts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lovehearts.png",
  },
  {
    name: "Hodl Finance",
    symbol: "HFT",
    slug: "hodl-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodl-finance.png",
  },
  {
    name: "Gabur",
    symbol: "GBR",
    slug: "gabur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gabur.png",
  },
  {
    name: "Mones",
    symbol: "MONES",
    slug: "mones",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mones.png",
  },
  {
    name: "KStarCoin",
    symbol: "KSC",
    slug: "kstarcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kstarcoin.png",
  },
  {
    name: "New Origin",
    symbol: "NOC",
    slug: "new-origin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-origin.png",
  },
  {
    name: "ADAM Oracle",
    symbol: "ADAM",
    slug: "adam-oracle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adam-oracle.png",
  },
  {
    name: "Khipu Token",
    symbol: "KIP",
    slug: "khipu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/khipu-token.png",
  },
  {
    name: "Seeded Network",
    symbol: "SEEDED",
    slug: "seeded-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seeded-network.png",
  },
  {
    name: "JUST NETWORK",
    symbol: "JUS",
    slug: "just-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/just-network.png",
  },
  {
    name: "Cards of BSC",
    symbol: "COB",
    slug: "cards-of-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cards-of-bsc.png",
  },
  {
    name: "Galaxy Pool Coin",
    symbol: "GPO",
    slug: "galaxy-pool-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-pool-coin.png",
  },
  {
    name: "Splash",
    symbol: "SPLASH",
    slug: "splash-fun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/splash-fun.png",
  },
  {
    name: "WOLFI",
    symbol: "WOLFI",
    slug: "wolfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolfi.png",
  },
  {
    name: "IDCM Token",
    symbol: "IT",
    slug: "idcm-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idcm-token.png",
  },
  {
    name: "Emocoin",
    symbol: "EMO",
    slug: "emocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emocoin.png",
  },
  {
    name: "Force For Fast",
    symbol: "FFF",
    slug: "force-for-fast",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/force-for-fast.png",
  },
  {
    name: "LUCA",
    symbol: "LUCA",
    slug: "luca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luca.png",
  },
  {
    name: "FANZY",
    symbol: "FX1",
    slug: "fanzy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanzy.png",
  },
  {
    name: "MegaBitcoin",
    symbol: "MBC",
    slug: "megabitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megabitcoin.png",
  },
  {
    name: "Distributed Energy Coin",
    symbol: "DEC",
    slug: "distributed-energy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/distributed-energy-coin.png",
  },
  {
    name: "Shush Club",
    symbol: "SHUSH",
    slug: "shush-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shush-club.png",
  },
  {
    name: "Wrapped Star",
    symbol: "WSTR",
    slug: "wrapped-star",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-star.png",
  },
  {
    name: "DTOP Token",
    symbol: "DTOP",
    slug: "data-trade-on-demand-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/data-trade-on-demand-platform.png",
  },
  {
    name: "KRAUSE",
    symbol: "$KRAUSE",
    slug: "krause",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krause.png",
  },
  {
    name: "PayBolt",
    symbol: "PAY",
    slug: "paybolt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paybolt.png",
  },
  {
    name: "BTS Coin",
    symbol: "BTSC",
    slug: "bts-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bts-coin.png",
  },
  {
    name: "BPOP",
    symbol: "BPOP",
    slug: "bpop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bpop.png",
  },
  {
    name: "SCROOGE",
    symbol: "SCROOGE",
    slug: "scrooge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scrooge.png",
  },
  {
    name: "SCC DIGforIT",
    symbol: "SCC",
    slug: "scc-digforit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scc-digforit.png",
  },
  {
    name: "Duet Protocol",
    symbol: "DUET",
    slug: "duet-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duet-protocol.png",
  },
  {
    name: "Play Royal",
    symbol: "PLAY",
    slug: "play-royal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/play-royal.png",
  },
  {
    name: "BNBeer",
    symbol: "BNBEER",
    slug: "bnbeer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbeer.png",
  },
  {
    name: "Dark Energy Crystals",
    symbol: "DEC",
    slug: "dark-energy-crystals",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dark-energy-crystals.png",
  },
  {
    name: "Iris Ecosystem",
    symbol: "IRISTOKEN",
    slug: "iris-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iris-ecosystem.png",
  },
  {
    name: "HL Chain",
    symbol: "HL",
    slug: "hl-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hl-chain.png",
  },
  {
    name: "Infinity ETH",
    symbol: "IETH",
    slug: "infinity-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-eth.png",
  },
  {
    name: "Yield Farming Token",
    symbol: "YFT",
    slug: "yield-farming-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-farming-token.png",
  },
  {
    name: "BlipCoin",
    symbol: "BPCN",
    slug: "blipcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blipcoin.png",
  },
  {
    name: "Sola Ninja",
    symbol: "SNJ",
    slug: "sola-ninja",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sola-ninja.png",
  },
  {
    name: "TRIBE",
    symbol: "TRIBEX",
    slug: "tribex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tribex.png",
  },
  {
    name: "3X Short Tether Gold Token",
    symbol: "XAUTBEAR",
    slug: "3x-short-tether-gold-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-tether-gold-token.png",
  },
  {
    name: "Shib Generating",
    symbol: "SHG",
    slug: "shib-generating",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shib-generating.png",
  },
  {
    name: "Floki Monk",
    symbol: "FLOKIMONK",
    slug: "floki-monk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-monk.png",
  },
  {
    name: "Adonis",
    symbol: "ADON",
    slug: "adonis-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adonis-token.png",
  },
  {
    name: "MapMetrics",
    symbol: "MMAPS",
    slug: "mapmetrics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mapmetrics.png",
  },
  {
    name: "Diamond Voucher",
    symbol: "DVS",
    slug: "diamond-voucher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-voucher.png",
  },
  {
    name: "ThorusFi",
    symbol: "THO",
    slug: "thorusfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thorusfi.png",
  },
  {
    name: "Bityuan",
    symbol: "BTY",
    slug: "bityuan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bityuan.png",
  },
  {
    name: "SAFEGAME CASH",
    symbol: "SGC",
    slug: "safegame-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safegame-cash.png",
  },
  {
    name: "BR34P",
    symbol: "BR34P",
    slug: "br34p",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/br34p.png",
  },
  {
    name: "TokenBacon",
    symbol: "BAK",
    slug: "tokenbacon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenbacon.png",
  },
  {
    name: "Digipharm",
    symbol: "DPH",
    slug: "digipharm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digipharm.png",
  },
  {
    name: "sBNB",
    symbol: "SBNB",
    slug: "sbnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sbnb.png",
  },
  {
    name: "iBTC (Synthetix)",
    symbol: "IBTC",
    slug: "ibtc-synthetix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ibtc-synthetix.png",
  },
  {
    name: "Doge Gold Floki",
    symbol: "$DGF",
    slug: "doge-gold-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-gold-floki.png",
  },
  {
    name: "sXTZ",
    symbol: "SXTZ",
    slug: "sxtz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sxtz.png",
  },
  {
    name: "SW DAO",
    symbol: "SWD",
    slug: "sw-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sw-dao.png",
  },
  {
    name: "sXAU",
    symbol: "SXAU",
    slug: "sxau",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sxau.png",
  },
  {
    name: "ROBOCOCK UWU",
    symbol: "GKEN",
    slug: "robocock-uwu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robocock-uwu.png",
  },
  {
    name: "sLINK",
    symbol: "sLINK",
    slug: "slink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slink.png",
  },
  {
    name: "OneBit",
    symbol: "1BIT",
    slug: "onebit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onebit.png",
  },
  {
    name: "iETH",
    symbol: "IETH",
    slug: "ieth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ieth.png",
  },
  {
    name: "HNK Orijent 1919",
    symbol: "ORI",
    slug: "hnk-orijent-1919",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hnk-orijent-1919.png",
  },
  {
    name: "LINK Profit Taker Set",
    symbol: "LINKPT",
    slug: "link-profit-taker-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/link-profit-taker-set.png",
  },
  {
    name: "Pixel Doge",
    symbol: "PXDOGE",
    slug: "pixel-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixel-doge.png",
  },
  {
    name: "LINK/ETH RSI Ratio Trading Set",
    symbol: "LINKETHRSI",
    slug: "link-eth-rsi-ratio-trading-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/link-eth-rsi-ratio-trading-set.png",
  },
  {
    name: "Intelligent Ratio Set",
    symbol: "INTRATIO",
    slug: "intelligent-ratio-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intelligent-ratio-set.png",
  },
  {
    name: "Seadog Metaverse",
    symbol: "SEADOG",
    slug: "seadog-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seadog-metaverse.png",
  },
  {
    name: "ETH RSI 60/40 Yield Set",
    symbol: "ETHRSIAPY",
    slug: "eth-rsi-60-40-yield-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-rsi-60-40-yield-set.png",
  },
  {
    name: "WapSwap Finance",
    symbol: "WAP",
    slug: "wapswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wapswap-finance.png",
  },
  {
    name: "ETH RSI 60/40 Crossover Set",
    symbol: "ETHRSI6040",
    slug: "eth-rsi-60-40-crossover-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-rsi-60-40-crossover-set.png",
  },
  {
    name: "Heroes of NFT",
    symbol: "HON",
    slug: "heroes-of-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heroes-of-nft.png",
  },
  {
    name: "DogeKongZilla",
    symbol: "DOGEKONGZILLA",
    slug: "dogekongzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogekongzilla.png",
  },
  {
    name: "Mindcell",
    symbol: "MDC",
    slug: "mindcell",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mindcell.png",
  },
  {
    name: "Shelling",
    symbol: "SHL",
    slug: "shelling",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shelling.png",
  },
  {
    name: "Lustcoins",
    symbol: "LUST",
    slug: "lustcoins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lustcoins.png",
  },
  {
    name: "ETH Price Action Candlestick Set",
    symbol: "ETHPA",
    slug: "eth-price-action-candlestick-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-price-action-candlestick-set.png",
  },
  {
    name: "Coinflect",
    symbol: "CFLT",
    slug: "coinflect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinflect.png",
  },
  {
    name: "DecentralizedUnited",
    symbol: "DCU",
    slug: "decentralizedunited",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralizedunited.png",
  },
  {
    name: "ETH/LINK Price Action Candlestick Set",
    symbol: "LINKETHPA",
    slug: "eth-link-price-action-candlestick-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-link-price-action-candlestick-set.png",
  },
  {
    name: "Sifu Vision",
    symbol: "SIFU",
    slug: "sifu-vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sifu-vision.png",
  },
  {
    name: "BSC BETS",
    symbol: "BETS",
    slug: "bsc-bets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsc-bets.png",
  },
  {
    name: "CrypCade Shares",
    symbol: "CADE",
    slug: "crypcade-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypcade-shares.png",
  },
  {
    name: "ETH/BTC RSI Ratio Trading Set",
    symbol: "ETHBTCRSI",
    slug: "eth-btc-rsi-ratio-trading-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-btc-rsi-ratio-trading-set.png",
  },
  {
    name: "Perpetuum",
    symbol: "PRP",
    slug: "perpetuum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/perpetuum.png",
  },
  {
    name: "Monetas",
    symbol: "MNTG",
    slug: "monetas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monetas.png",
  },
  {
    name: "PoorQUACK.com",
    symbol: "POOR",
    slug: "poorquack-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poorquack-com.png",
  },
  {
    name: "STMAN | Stickman's Battleground NFT Game",
    symbol: "STMAN",
    slug: "stman-stickmans-battleground-nft-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stman-stickmans-battleground-nft-game.png",
  },
  {
    name: "ZYX",
    symbol: "ZYX",
    slug: "zyx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zyx.png",
  },
  {
    name: "ZILLADOGE TOKEN",
    symbol: "ZILLADOGE",
    slug: "zilladoge-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilladoge-token.png",
  },
  {
    name: "ETH 20 Day MA Crossover Yield Set",
    symbol: "ETHMACOAPY",
    slug: "eth-20-day-ma-crossover-yield-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-20-day-ma-crossover-yield-set.png",
  },
  {
    name: "ViralUp",
    symbol: "VIRAL",
    slug: "viralup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viralup.png",
  },
  {
    name: "ETH 20 Day MA Crossover Set",
    symbol: "ETH20SMACO",
    slug: "eth-20-day-ma-crossover-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-20-day-ma-crossover-set.png",
  },
  {
    name: "BTC Network Demand Set II",
    symbol: "BYTE",
    slug: "btc-network-demand-set-ii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/btc-network-demand-set-ii.png",
  },
  {
    name: "Hare Plus",
    symbol: "HARE PLUS",
    slug: "hare-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hare-plus.png",
  },
  {
    name: "Foundry",
    symbol: "FRY",
    slug: "foundry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foundry.png",
  },
  {
    name: "Ricnatum",
    symbol: "RCNT",
    slug: "ricnatum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ricnatum.png",
  },
  {
    name: "KYSC Token",
    symbol: "KYSC",
    slug: "kysc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kysc-token.png",
  },
  {
    name: "CashCow",
    symbol: "COW",
    slug: "cashcow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashcow.png",
  },
  {
    name: "HAPY Coin",
    symbol: "HAPY",
    slug: "hapy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hapy-coin.png",
  },
  {
    name: "Türkiye Motosiklet Federasyonu Fan Token",
    symbol: "TMFT",
    slug: "turkiye-motosiklet-federasyonu-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turkiye-motosiklet-federasyonu-fan-token.png",
  },
  {
    name: "FarmerCrypto",
    symbol: "FCC",
    slug: "farmercrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmercrypto.png",
  },
  {
    name: "Derivex",
    symbol: "DVX",
    slug: "derivex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/derivex.png",
  },
  {
    name: "Khaos Finance",
    symbol: "KHAOS",
    slug: "khaos-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/khaos-finance.png",
  },
  {
    name: "Galaxy Wallet",
    symbol: "GC",
    slug: "galaxy-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-wallet.png",
  },
  {
    name: "Reward Cycle",
    symbol: "RC",
    slug: "reward-cycle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reward-cycle.png",
  },
  {
    name: "Healing Plus",
    symbol: "HP",
    slug: "healing-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/healing-plus.png",
  },
  {
    name: "Metroverse",
    symbol: "MET",
    slug: "metroverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metroverse.png",
  },
  {
    name: "BooCake",
    symbol: "BOOCAKE",
    slug: "boocake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boocake.png",
  },
  {
    name: "Swancake Token",
    symbol: "SWAN",
    slug: "swancake-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swancake-token.png",
  },
  {
    name: "Flag Network",
    symbol: "FLAG",
    slug: "flag-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flag-network.png",
  },
  {
    name: "Riot Racers",
    symbol: "RIOT",
    slug: "riot-racers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/riot-racers.png",
  },
  {
    name: "KVI",
    symbol: "KVI",
    slug: "kvi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kvi.png",
  },
  {
    name: "CronosNode",
    symbol: "CRON",
    slug: "cronosnode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cronosnode.png",
  },
  {
    name: "CoinBene Future Token",
    symbol: "CFT",
    slug: "coinbene-future-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinbene-future-token.png",
  },
  {
    name: "Z Versus Project",
    symbol: "ZVERSUS",
    slug: "z-versus-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/z-versus-project.png",
  },
  {
    name: "Wallet Pay",
    symbol: "XPAY",
    slug: "wallet-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wallet-pay.png",
  },
  {
    name: "Dali",
    symbol: "DALI",
    slug: "dali",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dali.png",
  },
  {
    name: "TOPBTC Token",
    symbol: "TOPB",
    slug: "topb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/topb.png",
  },
  {
    name: "SHUEY RHON RHON",
    symbol: "SRR",
    slug: "shueyrhonrhon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shueyrhonrhon.png",
  },
  {
    name: "ABC Floor Index",
    symbol: "ABC",
    slug: "abc-floor-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abc-floor-index.png",
  },
  {
    name: "ELON BUYS TWITTER",
    symbol: "EBT",
    slug: "elon-buys-twitter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elon-buys-twitter.png",
  },
  {
    name: "DigiMax DGMT",
    symbol: "DGMT",
    slug: "digimax-dgmt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digimax-dgmt.png",
  },
  {
    name: "Bit World Token",
    symbol: "BWB",
    slug: "bw-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bw-token.png",
  },
  {
    name: "Marblecoin",
    symbol: "MBC",
    slug: "marblecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marblecoin.png",
  },
  {
    name: "KAWAII",
    symbol: "KAWAII",
    slug: "kawaii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kawaii.png",
  },
  {
    name: "Fertilizer",
    symbol: "FRT",
    slug: "fertilizer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fertilizer.png",
  },
  {
    name: "Kids Cash",
    symbol: "KASH",
    slug: "kids-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kids-cash.png",
  },
  {
    name: "Teto Inu",
    symbol: "TETOINU",
    slug: "teto-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teto-inu.png",
  },
  {
    name: "Fantom Maker",
    symbol: "FAME",
    slug: "fantom-maker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantom-maker.png",
  },
  {
    name: "KIWI TOKEN",
    symbol: "KIWI",
    slug: "kiwi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiwi-token.png",
  },
  {
    name: "ZeLoop Eco Reward",
    symbol: "ERW",
    slug: "zeloop-eco-reward",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeloop-eco-reward.png",
  },
  {
    name: "IQCOIN",
    symbol: "IQCOIN",
    slug: "iqcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iqcoin.png",
  },
  {
    name: "CatBoy",
    symbol: "CATBOY",
    slug: "catboy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catboy-token.png",
  },
  {
    name: "PayFrequent USD",
    symbol: "PUSD",
    slug: "payfrequent-usd-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payfrequent-usd-2.png",
  },
  {
    name: "Hero Essence",
    symbol: "HES",
    slug: "heroverse-hes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heroverse-hes.png",
  },
  {
    name: "Fwar Finance",
    symbol: "FWT",
    slug: "fwar-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fwar-finance.png",
  },
  {
    name: "Zagent",
    symbol: "ZEG",
    slug: "zagent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zagent.png",
  },
  {
    name: "Son of Shib",
    symbol: "SON",
    slug: "sonofshib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonofshib.png",
  },
  {
    name: "MOTIV Protocol",
    symbol: "MOV",
    slug: "motiv-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/motiv-protocol.png",
  },
  {
    name: "MUD Guild Game",
    symbol: "MGG",
    slug: "mud-guild-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mud-guild-game.png",
  },
  {
    name: "Unicrypt",
    symbol: "UNC",
    slug: "unicrypt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicrypt.png",
  },
  {
    name: "EternalFlow",
    symbol: "EFT",
    slug: "eternalflow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eternalflow.png",
  },
  {
    name: "FITN",
    symbol: "FITN",
    slug: "fitn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fitn.png",
  },
  {
    name: "DEMOS",
    symbol: "DOS",
    slug: "demos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/demos.png",
  },
  {
    name: "Earthling",
    symbol: "ETLG",
    slug: "earthling",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earthling.png",
  },
  {
    name: "Equity",
    symbol: "EQUITY",
    slug: "equity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/equity.png",
  },
  {
    name: "Its Not Art",
    symbol: "NOTART",
    slug: "its-not-art",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/its-not-art.png",
  },
  {
    name: "Slime Royale Cupid Essence",
    symbol: "SCE",
    slug: "slime-royale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slime-royale.png",
  },
  {
    name: "Robonomics Web Services",
    symbol: "RWS",
    slug: "robonomics-web-services",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robonomics-web-services.png",
  },
  {
    name: "DIKE TOKEN",
    symbol: "DIKE",
    slug: "dike-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dike-token.png",
  },
  {
    name: "FeedEveryShiba",
    symbol: "FES",
    slug: "feedeveryshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feedeveryshiba.png",
  },
  {
    name: "JUST $APE",
    symbol: "APE",
    slug: "just-ape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/just-ape.png",
  },
  {
    name: "CoinZoom",
    symbol: "ZOOM",
    slug: "coinzoom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinzoom.png",
  },
  {
    name: "Dot Dot Finance",
    symbol: "DDD",
    slug: "dot-dot-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dot-dot-finance.png",
  },
  {
    name: "Cybercoin",
    symbol: "CBR",
    slug: "cybercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cybercoin.png",
  },
  {
    name: "Snowtomb LOT",
    symbol: "SLOT",
    slug: "snowtomb-lot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowtomb-lot.png",
  },
  {
    name: "Bitcoin True",
    symbol: "BTCT",
    slug: "bitcoin-true",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-true.png",
  },
  {
    name: "Unit Protocol",
    symbol: "COL",
    slug: "unit-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unit-protocol.png",
  },
  {
    name: "DeltaFi",
    symbol: "DELFI",
    slug: "deltafi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deltafi.png",
  },
  {
    name: "NEOBITCOIN",
    symbol: "NBTC",
    slug: "neobitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neobitcoin.png",
  },
  {
    name: "Smart Donation Coin",
    symbol: "SDC",
    slug: "smart-donation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-donation.png",
  },
  {
    name: "Degem",
    symbol: "DGM",
    slug: "degem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degem.png",
  },
  {
    name: "Lobby",
    symbol: "LBY",
    slug: "lobby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lobby.png",
  },
  {
    name: "sXAG",
    symbol: "SXAG",
    slug: "sxag",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sxag.png",
  },
  {
    name: "Chipz",
    symbol: "CHPZ",
    slug: "chipz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chipz.png",
  },
  {
    name: "sDEFI",
    symbol: "SDEFI",
    slug: "sdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sdefi.png",
  },
  {
    name: "Litecoin TRC20",
    symbol: "LTCT",
    slug: "litecoin-trc20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litecoin-trc20.png",
  },
  {
    name: "Bankroll Vault",
    symbol: "VLT",
    slug: "bankroll-vault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankroll-vault.png",
  },
  {
    name: "Paint Swap",
    symbol: "BRUSH",
    slug: "paint-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paint-swap.png",
  },
  {
    name: "Bitpayer Token",
    symbol: "BPT",
    slug: "bitpayer-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitpayer-token.png",
  },
  {
    name: "MAD RABBIT",
    symbol: "MADR",
    slug: "mad-rabbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mad-rabbit.png",
  },
  {
    name: "BookShib",
    symbol: "BOOKSHIB",
    slug: "bookshib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bookshib.png",
  },
  {
    name: "IceCream Shares",
    symbol: "CSHARE",
    slug: "icecream-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icecream-shares.png",
  },
  {
    name: "NoleCoin",
    symbol: "NOLE",
    slug: "nolecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nolecoin.png",
  },
  {
    name: "Rabona",
    symbol: "RA",
    slug: "rabona",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rabona.png",
  },
  {
    name: "G.O.A.T Token",
    symbol: "G.O.A.T",
    slug: "goat-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goat-token.png",
  },
  {
    name: "NarakaToken",
    symbol: "NT",
    slug: "naraka-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/naraka-token.png",
  },
  {
    name: "Crypto Volatility Token",
    symbol: "CVOL",
    slug: "crypto-volatility-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-volatility-token.png",
  },
  {
    name: "Cipher Core Token",
    symbol: "CIPHC",
    slug: "cipher-core-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cipher-core-token.png",
  },
  {
    name: "TFS Token",
    symbol: "TFS",
    slug: "tfs-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tfs-token.png",
  },
  {
    name: "NBOX",
    symbol: "NBOX",
    slug: "nbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nbox.png",
  },
  {
    name: "Freedom 22 DAO",
    symbol: "FREE",
    slug: "freedom-22-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freedom-22-dao.png",
  },
  {
    name: "EtherBone",
    symbol: "ETHBN",
    slug: "etherbone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherbone.png",
  },
  {
    name: "Nokencoin",
    symbol: "NOKN",
    slug: "nokencoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nokencoin.png",
  },
  {
    name: "ITR.ETH Intercoin Investor",
    symbol: "ITR",
    slug: "intercoin-itr-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intercoin-itr-eth.png",
  },
  {
    name: "Nekocoin",
    symbol: "NEKOS",
    slug: "nekocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nekocoin.png",
  },
  {
    name: "Kublaicoin",
    symbol: "KUB",
    slug: "kublaicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kublaicoin.png",
  },
  {
    name: "sADA",
    symbol: "SADA",
    slug: "sada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sada.png",
  },
  {
    name: "JPY Coin",
    symbol: "JPYC",
    slug: "jpy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jpy-coin.png",
  },
  {
    name: "sBTC",
    symbol: "SBTC",
    slug: "sbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sbtc.png",
  },
  {
    name: "Aave DAI",
    symbol: "ADAI",
    slug: "aave-dai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-dai.png",
  },
  {
    name: "Tipsy",
    symbol: "TIPSY",
    slug: "tipsy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tipsy.png",
  },
  {
    name: "Aave BUSD",
    symbol: "ABUSD",
    slug: "aave-busd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-busd.png",
  },
  {
    name: "Junko Inu",
    symbol: "JUNKOINU",
    slug: "junko-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/junko-inu.png",
  },
  {
    name: "Aave BAT",
    symbol: "ABAT",
    slug: "aave-bat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-bat.png",
  },
  {
    name: "Vetter Skylabs",
    symbol: "VSL",
    slug: "vetter-skylabs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vetter-skylabs.png",
  },
  {
    name: "MTVX",
    symbol: "MTVX",
    slug: "mtvx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mtvx.png",
  },
  {
    name: "Kandyland DAO",
    symbol: "KANDY",
    slug: "kandy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kandy.png",
  },
  {
    name: "INME SWAP V2",
    symbol: "INMES",
    slug: "inme-swap-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inme-swap-v2.png",
  },
  {
    name: "Aave MKR",
    symbol: "AMKR",
    slug: "aave-mkr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-mkr.png",
  },
  {
    name: "Wrapped Cardano",
    symbol: "WADA",
    slug: "wrapped-cardano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-cardano.png",
  },
  {
    name: "FanVerse",
    symbol: "FANV",
    slug: "fanverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanverse.png",
  },
  {
    name: "Aave SNX",
    symbol: "ASNX",
    slug: "aave-snx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-snx.png",
  },
  {
    name: "STIMMY",
    symbol: "STIMMY",
    slug: "stimmy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stimmy.png",
  },
  {
    name: "Sanji Inu",
    symbol: "SANJI",
    slug: "sanji-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sanji-inu.png",
  },
  {
    name: "FANG Token",
    symbol: "FANG",
    slug: "fang-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fang-token.png",
  },
  {
    name: "Aave LINK",
    symbol: "ALINK",
    slug: "aave-link",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-link.png",
  },
  {
    name: "Amgen",
    symbol: "AMG",
    slug: "amgen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amgen.png",
  },
  {
    name: "Aave KNC",
    symbol: "AKNC",
    slug: "aave-knc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-knc.png",
  },
  {
    name: "Apple Tokenized Stock Defichain",
    symbol: "DAAPL",
    slug: "apple-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apple-tokenized-stock-defichain.png",
  },
  {
    name: "Aave TUSD",
    symbol: "ATUSD",
    slug: "aave-tusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-tusd.png",
  },
  {
    name: "InnitfortheTECH",
    symbol: "INNIT",
    slug: "innitforthetech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/innitforthetech.png",
  },
  {
    name: "TEN Wallet",
    symbol: "TENW",
    slug: "ten-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ten-wallet.png",
  },
  {
    name: "IOOX System",
    symbol: "IOOX",
    slug: "ioox-system",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ioox-system.png",
  },
  {
    name: "NYCCoin",
    symbol: "NYC",
    slug: "nyccoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nyccoin.png",
  },
  {
    name: "BITCOINHEDGE",
    symbol: "BTCHG",
    slug: "bitcoinhedge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinhedge.png",
  },
  {
    name: "DeGEM",
    symbol: "DGM",
    slug: "degem1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degem1.png",
  },
  {
    name: "ROS Coin",
    symbol: "ROS",
    slug: "ros-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ros-coin.png",
  },
  {
    name: "Solbank Token",
    symbol: "SBNK",
    slug: "solbank-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solbank-token.png",
  },
  {
    name: "Gobble Gobble",
    symbol: "GOBBLE",
    slug: "gobble-gobble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gobble-gobble.png",
  },
  {
    name: "ChainZ Arena",
    symbol: "SOUL",
    slug: "chainz-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainz-arena.png",
  },
  {
    name: "Kaeri",
    symbol: "KAERI",
    slug: "kaeri",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaeri.png",
  },
  {
    name: "Quish Coin",
    symbol: "QTV",
    slug: "quish-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quish-coin.png",
  },
  {
    name: "Barkis Network",
    symbol: "BKS",
    slug: "barkis-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/barkis-network.png",
  },
  {
    name: "Shinjiru Inu",
    symbol: "SHINJI",
    slug: "shinjiru-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinjiru-inu.png",
  },
  {
    name: "FLOKACHU TOKEN",
    symbol: "FLOKACHU",
    slug: "flokachu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokachu-token.png",
  },
  {
    name: "EYES Protocol",
    symbol: "EYES",
    slug: "eyes-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eyes-protocol.png",
  },
  {
    name: "Ninja Squad Token",
    symbol: "NST",
    slug: "ninja-squad-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninja-squad-token.png",
  },
  {
    name: "0xMonero",
    symbol: "0xMR",
    slug: "0xmonero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0xmonero.png",
  },
  {
    name: "Nirvana NIRV",
    symbol: "NIRV",
    slug: "nirvana-nirv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nirvana-nirv.png",
  },
  {
    name: "Space Monkey Token",
    symbol: "MONKE",
    slug: "space-monkey-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-monkey-token.png",
  },
  {
    name: "CryptoBet",
    symbol: "CBET",
    slug: "cryptobet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobet.png",
  },
  {
    name: "Egyptian Mau",
    symbol: "MAU",
    slug: "egyptian-mau",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egyptian-mau.png",
  },
  {
    name: "Kaisen Inu",
    symbol: "KAI",
    slug: "kaisen-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaisen-inu.png",
  },
  {
    name: "Compound Ether",
    symbol: "CETH",
    slug: "compound-ether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-ether.png",
  },
  {
    name: "Covid Cutter",
    symbol: "CVC",
    slug: "covid-cutter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/covid-cutter.png",
  },
  {
    name: "VEHICLE DATA ARTIFICIAL INTELLIGENCE PLATFORM",
    symbol: "VAIP",
    slug: "vehicle-data-artificial-intelligence-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vehicle-data-artificial-intelligence-platform.png",
  },
  {
    name: "Christmas Elf",
    symbol: "CELF",
    slug: "christmas-elf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/christmas-elf.png",
  },
  {
    name: "Integritee Network",
    symbol: "TEER",
    slug: "integritee-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/integritee-network.png",
  },
  {
    name: "ZCore Token",
    symbol: "ZCRT",
    slug: "zcore-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zcore-token.png",
  },
  {
    name: "World Bet Club",
    symbol: "WBT",
    slug: "world-bet-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-bet-club.png",
  },
  {
    name: "ARMTOKEN",
    symbol: "TARM",
    slug: "armtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/armtoken.png",
  },
  {
    name: "Ethereans",
    symbol: "OS",
    slug: "ethereans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereans.png",
  },
  {
    name: "Resfinex Token",
    symbol: "RES",
    slug: "resfinex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/resfinex-token.png",
  },
  {
    name: "Mrs Cheems",
    symbol: "MCINU",
    slug: "mrscheems",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mrscheems.png",
  },
  {
    name: "BeeEx",
    symbol: "BEE",
    slug: "beeex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beeex.png",
  },
  {
    name: "Score Milk",
    symbol: "MILK",
    slug: "score-milk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/score-milk.png",
  },
  {
    name: "Betswamp",
    symbol: "BETS",
    slug: "betswamp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betswamp.png",
  },
  {
    name: "KIKO INU",
    symbol: "KIKO",
    slug: "kiko-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiko-inu.png",
  },
  {
    name: "eToro Euro",
    symbol: "EURX",
    slug: "etoro-euro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etoro-euro.png",
  },
  {
    name: "Karmaverse Zombie",
    symbol: "SERUM",
    slug: "karmaverse-zombie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karmaverse-zombie.png",
  },
  {
    name: "eToro New Zealand Dollar",
    symbol: "NZDX",
    slug: "etoro-new-zealand-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etoro-new-zealand-dollar.png",
  },
  {
    name: "Baby Panda",
    symbol: "BPANDA",
    slug: "baby-panda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-panda.png",
  },
  {
    name: "Two Prime FF1 Token",
    symbol: "FF1",
    slug: "two-prime-ff1-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/two-prime-ff1-token.png",
  },
  {
    name: "DICE INU",
    symbol: "DICE",
    slug: "dice-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dice-inu.png",
  },
  {
    name: "ChinaOM",
    symbol: "COM",
    slug: "chinaom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chinaom.png",
  },
  {
    name: "Metaprediction",
    symbol: "METP",
    slug: "metaprediction",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaprediction.png",
  },
  {
    name: "Florida Man",
    symbol: "FMAN",
    slug: "florida-man",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/florida-man.png",
  },
  {
    name: "AMN RUNE - Rune.Game",
    symbol: "AMN",
    slug: "amn-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amn-rune---rune-game.png",
  },
  {
    name: "MadCredits",
    symbol: "MWR",
    slug: "madcredits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/madcredits.png",
  },
  {
    name: "Avenue University Token",
    symbol: "AUT",
    slug: "avenue-university-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avenue-university-token.png",
  },
  {
    name: "Pandora Spirit",
    symbol: "PSR",
    slug: "pandora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pandora.png",
  },
  {
    name: "Entherfound",
    symbol: "ETF",
    slug: "entherfound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/entherfound.png",
  },
  {
    name: "Swapzilla",
    symbol: "SWZL",
    slug: "swapzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapzilla.png",
  },
  {
    name: "Stakeborg DAO",
    symbol: "STANDARD",
    slug: "stakeborg-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakeborg-dao.png",
  },
  {
    name: "Cicoin",
    symbol: "CICX",
    slug: "cicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cicoin.png",
  },
  {
    name: "Charg Coin",
    symbol: "CHG",
    slug: "charg-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charg-coin.png",
  },
  {
    name: "Helium Inu",
    symbol: "HINU",
    slug: "helium-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helium-inu.png",
  },
  {
    name: "SOL RUNE - Rune.Game",
    symbol: "SOL",
    slug: "sol-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sol-rune---rune-game.png",
  },
  {
    name: "SUPA Foundation",
    symbol: "SUPA",
    slug: "supa-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supa-foundation.png",
  },
  {
    name: "VIP Token",
    symbol: "VIP",
    slug: "vip-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vip-token.png",
  },
  {
    name: "ODE",
    symbol: "ODE",
    slug: "ode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ode.png",
  },
  {
    name: "Cofinex Coin",
    symbol: "CNX",
    slug: "cofinex-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cofinex-coin.png",
  },
  {
    name: "PolyPup Finance",
    symbol: "COLLAR",
    slug: "polypup-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polypup-finance.png",
  },
  {
    name: "Rugpull Prevention",
    symbol: "RUGPULL",
    slug: "rugpull-prevention",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rugpull-prevention.png",
  },
  {
    name: "GermanCoin",
    symbol: "GCX",
    slug: "germancoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/germancoin.png",
  },
  {
    name: "Monkey King",
    symbol: "WUKONG",
    slug: "monkey-king",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monkey-king.png",
  },
  {
    name: "SuperSkynet",
    symbol: "SSN",
    slug: "superskynet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superskynet.png",
  },
  {
    name: "Navigator",
    symbol: "NTTC",
    slug: "navigator",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/navigator.png",
  },
  {
    name: "Asac Coin",
    symbol: "ASAC",
    slug: "asac-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asac-coin.png",
  },
  {
    name: "Winerz",
    symbol: "WNZ",
    slug: "winerz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winerz.png",
  },
  {
    name: "Probably Nothing",
    symbol: "PN",
    slug: "probably-nothing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/probably-nothing.png",
  },
  {
    name: "XStorage",
    symbol: "XSTX",
    slug: "xstorage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xstorage.png",
  },
  {
    name: "BlockRock",
    symbol: "BRO$",
    slug: "blockrock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockrock.png",
  },
  {
    name: "Rodeo Coin",
    symbol: "RODEO",
    slug: "rodeo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rodeo-coin.png",
  },
  {
    name: "Compound SAI",
    symbol: "CSAI",
    slug: "compound-sai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-sai.png",
  },
  {
    name: "Rollbit Coin",
    symbol: "RLB",
    slug: "rollbit-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rollbit-coin.png",
  },
  {
    name: "KONJUNGATE",
    symbol: "KONJ",
    slug: "konjungate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/konjungate.png",
  },
  {
    name: "Wisdom Chain",
    symbol: "WDC",
    slug: "wisdom-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wisdom-chain.png",
  },
  {
    name: "NetZero",
    symbol: "NZERO",
    slug: "nzero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nzero.png",
  },
  {
    name: "Circlepod",
    symbol: "CPX",
    slug: "circlepod",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/circlepod.png",
  },
  {
    name: "Tremendous Coin",
    symbol: "TMDS",
    slug: "tremendouscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tremendouscoin.png",
  },
  {
    name: "Octorand",
    symbol: "OCTO",
    slug: "octorand",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octorand.png",
  },
  {
    name: "AfroDex",
    symbol: "AfroX",
    slug: "afrodex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/afrodex.png",
  },
  {
    name: "Liquinity",
    symbol: "LQNTY",
    slug: "liquinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquinity.png",
  },
  {
    name: "FRED Energy",
    symbol: "FRED",
    slug: "fred-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fred-energy.png",
  },
  {
    name: "Carbon Utility Token",
    symbol: "CUT",
    slug: "carbon-utility-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbon-utility-token.png",
  },
  {
    name: "1AI Token",
    symbol: "1AI",
    slug: "1ai-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1ai-token.png",
  },
  {
    name: "Okidoki Social",
    symbol: "DOKI",
    slug: "okidoki-social",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okidoki-social.png",
  },
  {
    name: "ℓUSD",
    symbol: "LUSD",
    slug: "linear-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linear-finance.png",
  },
  {
    name: "Minto",
    symbol: "BTCMT",
    slug: "minto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minto.png",
  },
  {
    name: "Enzo",
    symbol: "NZO",
    slug: "enzo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enzo.png",
  },
  {
    name: "THE ATLAS COIN",
    symbol: "ATLAS",
    slug: "the-atlas-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-atlas-coin.png",
  },
  {
    name: "Bitteam token",
    symbol: "BTT",
    slug: "bitteam-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitteam-token.png",
  },
  {
    name: "LO RUNE - Rune.Game",
    symbol: "LO",
    slug: "lo-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lo-rune---rune-game.png",
  },
  {
    name: "VOMER",
    symbol: "VMR",
    slug: "vomer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vomer.png",
  },
  {
    name: "Artem Coin",
    symbol: "ARTEM",
    slug: "artem-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artem-coin.png",
  },
  {
    name: "Global Reserve System",
    symbol: "GLOB",
    slug: "global-reserve-system",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-reserve-system.png",
  },
  {
    name: "ElevenToken",
    symbol: "ELVN",
    slug: "11minutes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/11minutes.png",
  },
  {
    name: "Axial Entertainment Digital Asset",
    symbol: "AXL",
    slug: "axial-entertainment-digital-asset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axial-entertainment-digital-asset.png",
  },
  {
    name: "Rizespor Token",
    symbol: "RIZE",
    slug: "rizespor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rizespor-token.png",
  },
  {
    name: "USDA",
    symbol: "USDA",
    slug: "usda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usda.png",
  },
  {
    name: "ARTIC Foundation",
    symbol: "ARTIC",
    slug: "artic-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artic-foundation.png",
  },
  {
    name: "Divine DAO",
    symbol: "DIVINE",
    slug: "divine-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/divine-dao.png",
  },
  {
    name: "BuySell",
    symbol: "BULL",
    slug: "buysell",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buysell.png",
  },
  {
    name: "Gooeys",
    symbol: "GOO",
    slug: "gooeys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gooeys.png",
  },
  {
    name: "WANAMOON",
    symbol: "MOON",
    slug: "wanamoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanamoon.png",
  },
  {
    name: "BollyCoin",
    symbol: "BOLLY",
    slug: "bollycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bollycoin.png",
  },
  {
    name: "Fantasy Token",
    symbol: "FTSY",
    slug: "fantasy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasy-token.png",
  },
  {
    name: "HGH Token",
    symbol: "HGH",
    slug: "hgh-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hgh-token.png",
  },
  {
    name: "Microsoft Tokenized Stock Defichain",
    symbol: "DMSFT",
    slug: "microsoft-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microsoft-tokenized-stock-defichain.png",
  },
  {
    name: "La´eeb",
    symbol: "LA´EEB",
    slug: "la-eeb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/la-eeb.png",
  },
  {
    name: "Sesameseed",
    symbol: "SEED",
    slug: "sesameseed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sesameseed.png",
  },
  {
    name: "Aerotoken",
    symbol: "AET",
    slug: "aerotoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aerotoken.png",
  },
  {
    name: "BlackPoker",
    symbol: "BPKR",
    slug: "blackpoker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackpoker.png",
  },
  {
    name: "UNIUM",
    symbol: "UNM",
    slug: "unium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unium.png",
  },
  {
    name: "CryptoSaga",
    symbol: "SAGA",
    slug: "cryptosaga",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptosaga.png",
  },
  {
    name: "Node Cubed",
    symbol: "N3",
    slug: "node-cubed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/node-cubed.png",
  },
  {
    name: "Ashward",
    symbol: "ASC",
    slug: "ashward",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ashward.png",
  },
  {
    name: "Bankroll Network",
    symbol: "BNKR",
    slug: "bankroll-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankroll-network.png",
  },
  {
    name: "Londex",
    symbol: "LDX",
    slug: "londex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/londex-token.png",
  },
  {
    name: "Bitcoin & Company Network",
    symbol: "BITN",
    slug: "bitcoin-and-company-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-and-company-network.png",
  },
  {
    name: "SOLBIT",
    symbol: "SBT",
    slug: "solbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solbit.png",
  },
  {
    name: "DOL RUNE - Rune.Game",
    symbol: "DOL",
    slug: "dol-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dol-rune---rune-game.png",
  },
  {
    name: "Smarty Pay",
    symbol: "SPY",
    slug: "smarty-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smarty-pay.png",
  },
  {
    name: "BITFXT COIN",
    symbol: "BXT",
    slug: "bitfxt-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitfxt-coin.png",
  },
  {
    name: "TAL RUNE - Rune.Game",
    symbol: "TAL",
    slug: "tal-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tal-rune---rune-game.png",
  },
  {
    name: "SpiceUSD",
    symbol: "USDS",
    slug: "spiceusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spiceusd.png",
  },
  {
    name: "Puff Santa",
    symbol: "PUFFSANTA",
    slug: "puff-santa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puff-santa.png",
  },
  {
    name: "D-Drops",
    symbol: "DOP",
    slug: "d-drops",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/d-drops.png",
  },
  {
    name: "Degenerate Ape Academy Floor Index",
    symbol: "DAPE",
    slug: "degenerate-ape-academy-floor-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degenerate-ape-academy-floor-index.png",
  },
  {
    name: "Dash Cash",
    symbol: "DSC",
    slug: "dash-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dash-cash.png",
  },
  {
    name: "Ridge",
    symbol: "RIDGE",
    slug: "ridge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ridge.png",
  },
  {
    name: "wanETH",
    symbol: "WANETH",
    slug: "waneth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waneth.png",
  },
  {
    name: "DMme",
    symbol: "DMME",
    slug: "dmme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dmme.png",
  },
  {
    name: "SoulSwap Finance",
    symbol: "SOUL",
    slug: "soulswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soulswap-finance.png",
  },
  {
    name: "World Cup Willie",
    symbol: "WILLIE",
    slug: "world-cup-willie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-cup-willie.png",
  },
  {
    name: "Filenet",
    symbol: "FN",
    slug: "filenet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/filenet.png",
  },
  {
    name: "Timerr",
    symbol: "TIMERR",
    slug: "timerr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/timerr.png",
  },
  {
    name: "SundaeSwap",
    symbol: "SUNDAE",
    slug: "sundaeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sundaeswap.png",
  },
  {
    name: "SHAEL RUNE - Rune.Game",
    symbol: "SHAEL",
    slug: "shael-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shael-rune---rune-game.png",
  },
  {
    name: "Solhero Finance",
    symbol: "HERO",
    slug: "solhero-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solhero-finance.png",
  },
  {
    name: "Tesra",
    symbol: "TSR",
    slug: "tesra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tesra.png",
  },
  {
    name: "Fantom USD",
    symbol: "FUSD",
    slug: "fantom-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantom-usd.png",
  },
  {
    name: "Xeonbit Token",
    symbol: "XNS",
    slug: "xeonbit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xeonbit-token.png",
  },
  {
    name: "1irstGold",
    symbol: "1GOLD",
    slug: "1irstgold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1irstgold.png",
  },
  {
    name: "AtEM",
    symbol: "ATEM",
    slug: "atem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atem.png",
  },
  {
    name: "ENHANCE",
    symbol: "ENHANCE",
    slug: "enhance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enhance.png",
  },
  {
    name: "INLOCK",
    symbol: "ILK",
    slug: "inlock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inlock.png",
  },
  {
    name: "THUL RUNE - Rune.Game",
    symbol: "THUL",
    slug: "thul-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thul-rune---rune-game.png",
  },
  {
    name: "Pi Network DeFi",
    symbol: "PI NETWORK DEFI",
    slug: "pi-network-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pi-network-defi.png",
  },
  {
    name: "Versess Coin",
    symbol: "VERS",
    slug: "versess-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/versess-coin.png",
  },
  {
    name: "Otium tech",
    symbol: "OTIUM",
    slug: "otium-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otium-tech.png",
  },
  {
    name: "Sharity",
    symbol: "$SHARI",
    slug: "sharity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sharity.png",
  },
  {
    name: "Tokenize Xchange",
    symbol: "TKX",
    slug: "tokenize-xchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenize-xchange.png",
  },
  {
    name: "Colletrix",
    symbol: "CIPX",
    slug: "colletrix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colletrix.png",
  },
  {
    name: "MinerBlox",
    symbol: "MBLOX",
    slug: "minerblox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minerblox.png",
  },
  {
    name: "CoinHe Token",
    symbol: "CHT",
    slug: "coinhe-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinhe-token.png",
  },
  {
    name: "Mintea",
    symbol: "MINT",
    slug: "mintea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mintea.png",
  },
  {
    name: "Asian-African Capital Chain",
    symbol: "ACC",
    slug: "asian-african-capital-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asian-african-capital-chain.png",
  },
  {
    name: "UnityVentures",
    symbol: "UV",
    slug: "unityventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unityventures.png",
  },
  {
    name: "Ghost Trader",
    symbol: "GTR",
    slug: "ghost-trader",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghost-trader.png",
  },
  {
    name: "TSANGNYON HERUKA",
    symbol: "TSANGNYON",
    slug: "tsangnyon-heruka",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tsangnyon-heruka.png",
  },
  {
    name: "Drawshop Kingdom Reverse",
    symbol: "JOY",
    slug: "drawshop-kingdom-reverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drawshop-kingdom-reverse.png",
  },
  {
    name: "Bitget DeFi Token",
    symbol: "BFT",
    slug: "bitget-defi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitget-defi-token.png",
  },
  {
    name: "Nest Egg",
    symbol: "NEGG",
    slug: "nest-egg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nest-egg.png",
  },
  {
    name: "Sierracoin",
    symbol: "SIERRA",
    slug: "sierracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sierracoin.png",
  },
  {
    name: "RxSeed Coin",
    symbol: "WSOW",
    slug: "rxseed-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rxseed-coin.png",
  },
  {
    name: "Ninjacoin",
    symbol: "NINJA",
    slug: "ninjacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninjacoin.png",
  },
  {
    name: "Dripto",
    symbol: "DRYP",
    slug: "dripto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dripto.png",
  },
  {
    name: "Tchain",
    symbol: "TCH",
    slug: "tchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tchain.png",
  },
  {
    name: "Crystal Wallet",
    symbol: "CRT",
    slug: "crystal-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crystal-wallet.png",
  },
  {
    name: "Sombe",
    symbol: "SBE",
    slug: "sombe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sombe.png",
  },
  {
    name: "HappyLand Reward",
    symbol: "HPW",
    slug: "happyland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/happyland.png",
  },
  {
    name: "CoinFarm (new)",
    symbol: "CFARM",
    slug: "coinfarm-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinfarm-new.png",
  },
  {
    name: "LINK",
    symbol: "LN",
    slug: "link",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/link.png",
  },
  {
    name: "Bolide",
    symbol: "BLID",
    slug: "bolide",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bolide.png",
  },
  {
    name: "DeFi Launch",
    symbol: "DLAUNCH",
    slug: "defi-launch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-launch.png",
  },
  {
    name: "Voltz",
    symbol: "VOLTZ",
    slug: "voltz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voltz.png",
  },
  {
    name: "MAMA DAO",
    symbol: "MAMA",
    slug: "mama-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mama-dao.png",
  },
  {
    name: "Dead Knight Metaverse",
    symbol: "DKM",
    slug: "dead-knight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dead-knight.png",
  },
  {
    name: "StarGod",
    symbol: "STG",
    slug: "stargod",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stargod.png",
  },
  {
    name: "Altbet",
    symbol: "ABET",
    slug: "altbet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altbet.png",
  },
  {
    name: "2030 Floki",
    symbol: "2030FLOKI",
    slug: "2030-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2030-floki.png",
  },
  {
    name: "VENA",
    symbol: "VENA",
    slug: "vena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vena.png",
  },
  {
    name: "ScarySwap.Io",
    symbol: "SCARYSWAP",
    slug: "scaryswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scaryswap.png",
  },
  {
    name: "Electronero",
    symbol: "ETNX",
    slug: "electronero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electronero.png",
  },
  {
    name: "CrazyPanda",
    symbol: "BAMBOO",
    slug: "crazypanda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crazypanda.png",
  },
  {
    name: "Passive Token",
    symbol: "PASSIVE",
    slug: "passive-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/passive-token.png",
  },
  {
    name: "Firework Games",
    symbol: "FIRE",
    slug: "firework-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firework-games.png",
  },
  {
    name: "Korbot",
    symbol: "KBOT",
    slug: "korbot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/korbot.png",
  },
  {
    name: "Silva Token",
    symbol: "SILVA",
    slug: "silva-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silva-token.png",
  },
  {
    name: "YZZ",
    symbol: "YZZ",
    slug: "yzz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yzz.png",
  },
  {
    name: "Bitscoin",
    symbol: "BTCX",
    slug: "bitscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitscoin.png",
  },
  {
    name: "BitcoinSoV",
    symbol: "BSOV",
    slug: "bitcoinsov",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinsov.png",
  },
  {
    name: "Gonewild Token",
    symbol: "GWG",
    slug: "gonewild-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gonewild-token.png",
  },
  {
    name: "FaniTrade",
    symbol: "FANI",
    slug: "fanitrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanitrade.png",
  },
  {
    name: "Crypto Arcade Punk",
    symbol: "C-ARCADE",
    slug: "crypto-arcade-punk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-arcade-punk.png",
  },
  {
    name: "Island Doges",
    symbol: "ISLAND",
    slug: "island-doges",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/island-doges.png",
  },
  {
    name: "IOEX",
    symbol: "IOEX",
    slug: "ioex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ioex.png",
  },
  {
    name: "Lukki Operating Token",
    symbol: "LOT",
    slug: "lukki-operating-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lukki-operating-token.png",
  },
  {
    name: "Magik Finance",
    symbol: "MAGIK",
    slug: "magik-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magik-finance.png",
  },
  {
    name: "Konstellation Network",
    symbol: "DARC",
    slug: "konstellation-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/konstellation-network.png",
  },
  {
    name: "Xenoverse",
    symbol: "XENO",
    slug: "xenoverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xenoverse.png",
  },
  {
    name: "ZillaMatrix",
    symbol: "ZMAX",
    slug: "zillamatrix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zillamatrix.png",
  },
  {
    name: "Arcane Universe",
    symbol: "ARCANE",
    slug: "arcane-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcane-universe.png",
  },
  {
    name: "AnyPrinter",
    symbol: "ANYP",
    slug: "anyprinter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anyprinter.png",
  },
  {
    name: "SO CAL Token",
    symbol: "SCT",
    slug: "so-cal-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/so-cal-token.png",
  },
  {
    name: "Opennity",
    symbol: "OPNN",
    slug: "opennity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opennity.png",
  },
  {
    name: "HKD.com DAO",
    symbol: "HDAO",
    slug: "hkd-com-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hkd-com-dao.png",
  },
  {
    name: "SCRO Holdings",
    symbol: "SCROH",
    slug: "scro-holdings",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scro-holdings.png",
  },
  {
    name: "Punk Shiba",
    symbol: "PUNKS",
    slug: "punk-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/punk-shiba.png",
  },
  {
    name: "Zer-Dex",
    symbol: "ZDX",
    slug: "zer-dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zer-dex.png",
  },
  {
    name: "Glowston",
    symbol: "GLON",
    slug: "glowston",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glowston.png",
  },
  {
    name: "GLOBEX",
    symbol: "GEX",
    slug: "globex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/globex.png",
  },
  {
    name: "GST",
    symbol: "GST",
    slug: "gst",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gst.png",
  },
  {
    name: "MonoX Protocol",
    symbol: "MONO",
    slug: "monox-protocol-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monox-protocol-token.png",
  },
  {
    name: "YottaChain",
    symbol: "YTA",
    slug: "yottachain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yottachain.png",
  },
  {
    name: "Mickey",
    symbol: "MCK",
    slug: "mickey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mickey.png",
  },
  {
    name: "EXOR",
    symbol: "EXOR",
    slug: "exor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exor.png",
  },
  {
    name: "$FiPi",
    symbol: "FIPI",
    slug: "fipi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fipi.png",
  },
  {
    name: "xApe",
    symbol: "XP",
    slug: "xape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xape.png",
  },
  {
    name: "NOIZ",
    symbol: "NOIZ",
    slug: "noizchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noizchain.png",
  },
  {
    name: "Income Island Token",
    symbol: "INCOME",
    slug: "income-island-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/income-island-token.png",
  },
  {
    name: "qiibee",
    symbol: "QBX",
    slug: "qiibee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qiibee.png",
  },
  {
    name: "PDATA",
    symbol: "PDATA",
    slug: "pdata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pdata.png",
  },
  {
    name: "CACTUS",
    symbol: "CACTUS",
    slug: "cactus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cactus.png",
  },
  {
    name: "Baby Casper",
    symbol: "BABYCASPER",
    slug: "baby-casper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-casper.png",
  },
  {
    name: "SpectreSecurityCoin",
    symbol: "XSPC",
    slug: "spectre-security-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spectre-security-coin.png",
  },
  {
    name: "BaconCoin",
    symbol: "BACON",
    slug: "bacon-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bacon-protocol.png",
  },
  {
    name: "Sparkster",
    symbol: "SPRK",
    slug: "sparkster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sparkster.png",
  },
  {
    name: "Rudolph Coin",
    symbol: "RUDOLPH",
    slug: "rudolph-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rudolph-coin.png",
  },
  {
    name: "CashZone",
    symbol: "CASHZ",
    slug: "cashzone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashzone.png",
  },
  {
    name: "SAVEBEE FARM HONEYCOMB",
    symbol: "HC",
    slug: "savebee-farm-honeycomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/savebee-farm-honeycomb.png",
  },
  {
    name: "NKCL Classic",
    symbol: "NKCLC",
    slug: "nkcl-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nkcl-classic.png",
  },
  {
    name: "Coineal Token",
    symbol: "NEAL",
    slug: "coineal-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coineal-token.png",
  },
  {
    name: "VENJOCOIN",
    symbol: "VJC",
    slug: "venjocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venjocoin.png",
  },
  {
    name: "DogeTools",
    symbol: "DTOOLS",
    slug: "dogetools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogetools.png",
  },
  {
    name: "Xtock",
    symbol: "XTX",
    slug: "xtock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtock.png",
  },
  {
    name: "Shibtama",
    symbol: "SHIBTAMA",
    slug: "shibtama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibtama.png",
  },
  {
    name: "Watchtower",
    symbol: "WTW",
    slug: "watchtower",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/watchtower.png",
  },
  {
    name: "DETIK Token",
    symbol: "DTK",
    slug: "detik-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/detik-token.png",
  },
  {
    name: "Esportbits",
    symbol: "HLT",
    slug: "esportbits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/esportbits.png",
  },
  {
    name: "Tsukiverse:Galactic Adventures",
    symbol: "TSUGA",
    slug: "tsukiverse-galactic-adventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tsukiverse-galactic-adventures.png",
  },
  {
    name: "Darüşşafaka Spor Kulübü Token",
    symbol: "DSK",
    slug: "darussafaka-sports-club-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darussafaka-sports-club-token.png",
  },
  {
    name: "RoboCalls",
    symbol: "RC20",
    slug: "robocalls",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robocalls.png",
  },
  {
    name: "EternalGirl",
    symbol: "ETGL",
    slug: "eternalgirl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eternalgirl.png",
  },
  {
    name: "Jinbi Token",
    symbol: "JNB",
    slug: "jinbi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jinbi-token.png",
  },
  {
    name: "Aunite",
    symbol: "AUNIT",
    slug: "aunite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aunite.png",
  },
  {
    name: "ROMToken",
    symbol: "ROM",
    slug: "romtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/romtoken.png",
  },
  {
    name: "Mermaid",
    symbol: "MERMAID",
    slug: "mermaid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mermaid.png",
  },
  {
    name: "Baer Chain",
    symbol: "BRC",
    slug: "baer-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baer-chain.png",
  },
  {
    name: "Santa Shiba",
    symbol: "SANTASHIB",
    slug: "santa-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/santa-shiba.png",
  },
  {
    name: "Bgogo Token",
    symbol: "BGG",
    slug: "bgogo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bgogo-token.png",
  },
  {
    name: "Carbon Coin",
    symbol: "CXRBN",
    slug: "carboncoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carboncoin-token.png",
  },
  {
    name: "Dragon Token",
    symbol: "DT",
    slug: "dragon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-token.png",
  },
  {
    name: "LEMON",
    symbol: "LEMN",
    slug: "lemon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lemon.png",
  },
  {
    name: "Wixlar",
    symbol: "WIX",
    slug: "wixlar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wixlar.png",
  },
  {
    name: "LimeOdysseyM with ITAM",
    symbol: "LOM",
    slug: "limeodyssey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/limeodyssey.png",
  },
  {
    name: "Digital Asset Guarantee Token",
    symbol: "DAGT",
    slug: "digital-asset-guarantee-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-asset-guarantee-token.png",
  },
  {
    name: "Ratio Stable Coin",
    symbol: "USDR",
    slug: "ratio-stable-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ratio-stable-coin.png",
  },
  {
    name: "TRONCLASSIC",
    symbol: "TRXC",
    slug: "tronclassic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tronclassic.png",
  },
  {
    name: "Drover Inu",
    symbol: "DROVERS",
    slug: "drover-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drover-inu.png",
  },
  {
    name: "Creama",
    symbol: "CREAMA",
    slug: "creama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creama.png",
  },
  {
    name: "MobilinkToken",
    symbol: "MOLK",
    slug: "mobilinktoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobilinktoken.png",
  },
  {
    name: "Kekwcoin",
    symbol: "KEKW",
    slug: "kekwcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kekwcoin.png",
  },
  {
    name: "Universal Store of Value",
    symbol: "USV",
    slug: "universal-store-of-value",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universal-store-of-value.png",
  },
  {
    name: "GazeCoin",
    symbol: "GZE",
    slug: "gazecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gazecoin.png",
  },
  {
    name: "Usechain Token",
    symbol: "USE",
    slug: "usechain-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usechain-token.png",
  },
  {
    name: "Musk Metaverse",
    symbol: "METAMUSK",
    slug: "musk-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musk-metaverse.png",
  },
  {
    name: "OJE Token",
    symbol: "OJE",
    slug: "oje-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oje-token.png",
  },
  {
    name: "Sporty",
    symbol: "SPORTY",
    slug: "sporty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sporty.png",
  },
  {
    name: "Many Worlds Token",
    symbol: "MANY",
    slug: "many-worlds-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/many-worlds-token.png",
  },
  {
    name: "ONE TREE ONE LIFE",
    symbol: "TREE",
    slug: "one-tree-one-life",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-tree-one-life.png",
  },
  {
    name: "ABCC Token",
    symbol: "AT",
    slug: "abcc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abcc-token.png",
  },
  {
    name: "PECULIUM",
    symbol: "PCL",
    slug: "peculium-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peculium-v2.png",
  },
  {
    name: "Centaure",
    symbol: "CEN",
    slug: "centaure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centaure.png",
  },
  {
    name: "AppleSwap",
    symbol: "APPLE",
    slug: "appleswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/appleswap-token.png",
  },
  {
    name: "HELL HOUNDS",
    symbol: "SOUL",
    slug: "hell-hounds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hell-hounds.png",
  },
  {
    name: "Traceability Chain",
    symbol: "TAC",
    slug: "traceability-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/traceability-chain.png",
  },
  {
    name: "Ditto Staked Aptos",
    symbol: "stAPT",
    slug: "ditto-staked-aptos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ditto-staked-aptos.png",
  },
  {
    name: "JustCarbon",
    symbol: "JCG",
    slug: "justcarbon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/justcarbon.png",
  },
  {
    name: "Teneo",
    symbol: "TEN",
    slug: "teneo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teneo.png",
  },
  {
    name: "Wallphy",
    symbol: "WALLPHY",
    slug: "wallphy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wallphy.png",
  },
  {
    name: "SOLARR",
    symbol: "SLRR",
    slug: "solarr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solarr.png",
  },
  {
    name: "YUKI",
    symbol: "YUKI",
    slug: "yuki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yuki.png",
  },
  {
    name: "BABİL TOKEN",
    symbol: "BABIL",
    slug: "babil-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babil-token.png",
  },
  {
    name: "Project CareCoin",
    symbol: "CARESV2",
    slug: "project-carecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-carecoin.png",
  },
  {
    name: "SingMon Token",
    symbol: "SM",
    slug: "singmon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/singmon-token.png",
  },
  {
    name: "Obitan Chain",
    symbol: "OBTC",
    slug: "obitan-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/obitan-chain.png",
  },
  {
    name: "Solana INU",
    symbol: "INU",
    slug: "solana-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solana-inu.png",
  },
  {
    name: "Insight Chain",
    symbol: "INB",
    slug: "insight-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insight-chain.png",
  },
  {
    name: "Colizeum",
    symbol: "ZEUM",
    slug: "colizeum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colizeum.png",
  },
  {
    name: "Planet Inu",
    symbol: "PLANETINU",
    slug: "planet-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/planet-inu.png",
  },
  {
    name: "HEL RUNE - Rune.Game",
    symbol: "HEL",
    slug: "hel-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hel-rune---rune-game.png",
  },
  {
    name: "Eng Crypto",
    symbol: "ENG",
    slug: "eng-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eng-crypto.png",
  },
  {
    name: "Endorsit",
    symbol: "EDS",
    slug: "endorsit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/endorsit.png",
  },
  {
    name: "X AE A-12",
    symbol: "XAEA12",
    slug: "x-ae-a-12",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-ae-a-12.png",
  },
  {
    name: "Baby BitBurnReflect",
    symbol: "BBBR",
    slug: "baby-bitburnreflect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-bitburnreflect.png",
  },
  {
    name: "Galaxy Essential",
    symbol: "GXE",
    slug: "galaxy-essential",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-essential.png",
  },
  {
    name: "CakeSwap",
    symbol: "CAKESWAP",
    slug: "cakeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cakeswap.png",
  },
  {
    name: "Experience Token",
    symbol: "EXT",
    slug: "experience-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/experience-token.png",
  },
  {
    name: "Hulk Inu",
    symbol: "HULK",
    slug: "hulk-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hulk-inu.png",
  },
  {
    name: "IOV BlockChain",
    symbol: "IOV",
    slug: "iov-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iov-blockchain.png",
  },
  {
    name: "DWS",
    symbol: "DWS",
    slug: "dws",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dws.png",
  },
  {
    name: "SHIBACK",
    symbol: "SHIBACK",
    slug: "shiback",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiback.png",
  },
  {
    name: "Karatgold Coin",
    symbol: "KBC",
    slug: "karatgold-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karatgold-coin.png",
  },
  {
    name: "Seba",
    symbol: "SEBA",
    slug: "seba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seba.png",
  },
  {
    name: "Coni",
    symbol: "CONI",
    slug: "coni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coni.png",
  },
  {
    name: "India Coin",
    symbol: "INDIA",
    slug: "india-coin-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/india-coin-world.png",
  },
  {
    name: "Ooze",
    symbol: "OOZE",
    slug: "ooze",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ooze.png",
  },
  {
    name: "FiFaSport",
    symbol: "FFS",
    slug: "fifasport",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fifasport.png",
  },
  {
    name: "Pixie Coin",
    symbol: "PXC",
    slug: "pixie-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixie-coin.png",
  },
  {
    name: "Amara Finance",
    symbol: "MARA",
    slug: "amara-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amara-finance.png",
  },
  {
    name: "Wen Lambo",
    symbol: "LAMBO",
    slug: "wen-lambo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wen-lambo.png",
  },
  {
    name: "StockChain",
    symbol: "SCC",
    slug: "stockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stockchain.png",
  },
  {
    name: "Metapocket",
    symbol: "METAPK",
    slug: "metapocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metapocket.png",
  },
  {
    name: "Stakemoon",
    symbol: "SMOON",
    slug: "stakemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakemoon.png",
  },
  {
    name: "Santas War NFT Epic",
    symbol: "SANTAWAR",
    slug: "santas-war-nft-epic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/santas-war-nft-epic.png",
  },
  {
    name: "XL-Moon",
    symbol: "XLMN",
    slug: "xl-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xl-moon.png",
  },
  {
    name: "Kanga Exchange Token",
    symbol: "KNG",
    slug: "kanga-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kanga-exchange-token.png",
  },
  {
    name: "JET8",
    symbol: "J8T",
    slug: "jet8",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jet8.png",
  },
  {
    name: "KlayFi Finance",
    symbol: "KFI",
    slug: "klayfi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klayfi-finance.png",
  },
  {
    name: "Listenify",
    symbol: "AUDIO",
    slug: "listenify",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/listenify.png",
  },
  {
    name: "NitroShiba",
    symbol: "NISHIB",
    slug: "nitroshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nitroshiba.png",
  },
  {
    name: "ValueChain",
    symbol: "VLC",
    slug: "valuechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valuechain.png",
  },
  {
    name: "FootBallGo",
    symbol: "FGSPORT",
    slug: "footballgo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/footballgo.png",
  },
  {
    name: "PressOne",
    symbol: "PRS",
    slug: "pressone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pressone.png",
  },
  {
    name: "Arabic",
    symbol: "ABIC",
    slug: "arabic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arabic.png",
  },
  {
    name: "Maggie",
    symbol: "MAG",
    slug: "maggie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maggie.png",
  },
  {
    name: "Trillium",
    symbol: "TT",
    slug: "trillium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trillium.png",
  },
  {
    name: "IDEX Membership",
    symbol: "IDXM",
    slug: "idex-membership",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idex-membership.png",
  },
  {
    name: "MOAC",
    symbol: "MOAC",
    slug: "moac",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moac.png",
  },
  {
    name: "Meta Hangry Games",
    symbol: "MHG",
    slug: "meta-hangry-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-hangry-games.png",
  },
  {
    name: "Phoenix Unity",
    symbol: "PXU",
    slug: "phoenix-unity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenix-unity.png",
  },
  {
    name: "United Traders Token",
    symbol: "UTT",
    slug: "uttoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uttoken.png",
  },
  {
    name: "Coinlancer",
    symbol: "CL",
    slug: "coinlancer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinlancer.png",
  },
  {
    name: "Baby Shitcoin",
    symbol: "BBYSTC",
    slug: "baby-shitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-shitcoin.png",
  },
  {
    name: "TrustNFT",
    symbol: "TRUSTNFT",
    slug: "trustnft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustnft.png",
  },
  {
    name: "BeGlobal Finance",
    symbol: "GLB",
    slug: "beglobal-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beglobal-finance.png",
  },
  {
    name: "MSD",
    symbol: "MSD",
    slug: "msd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/msd.png",
  },
  {
    name: "Colony Avalanche Index",
    symbol: "CAI",
    slug: "colony-avalanche-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colony-avalanche-index.png",
  },
  {
    name: "Adelphoi",
    symbol: "ADL",
    slug: "adelphoi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adelphoi.png",
  },
  {
    name: "Degen Dex",
    symbol: "DEGN",
    slug: "degen-dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degen-dex.png",
  },
  {
    name: "MMSC PLATFORM",
    symbol: "MMSC",
    slug: "mmsc-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mmsc-platform.png",
  },
  {
    name: "Peerplays",
    symbol: "PPY",
    slug: "peerplays-ppy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peerplays-ppy.png",
  },
  {
    name: "MetaAltPad",
    symbol: "MAP",
    slug: "metaaltpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaaltpad.png",
  },
  {
    name: "Degen Arts",
    symbol: "DAC",
    slug: "degen-arts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degen-arts.png",
  },
  {
    name: "BlazerCoin",
    symbol: "BLAZR",
    slug: "blazercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blazercoin.png",
  },
  {
    name: "Bonded dAMM",
    symbol: "BDAMM",
    slug: "bonded-damm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonded-damm.png",
  },
  {
    name: "Mavro",
    symbol: "MAVRO",
    slug: "mavro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mavro.png",
  },
  {
    name: "Monopolon",
    symbol: "MGM",
    slug: "monopolon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monopolon.png",
  },
  {
    name: "InflationCoin",
    symbol: "IFLT",
    slug: "inflationcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inflationcoin.png",
  },
  {
    name: "KO RUNE - Rune.Game",
    symbol: "KO",
    slug: "ko-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ko-rune---rune-game.png",
  },
  {
    name: "xPTP",
    symbol: "XPTP",
    slug: "xptp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xptp.png",
  },
  {
    name: "THIS",
    symbol: "THIS",
    slug: "this",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/this.png",
  },
  {
    name: "Silvertoken",
    symbol: "SLVT",
    slug: "silvertoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silvertoken.png",
  },
  {
    name: "Aces",
    symbol: "ACES",
    slug: "aces",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aces.png",
  },
  {
    name: "Xverse",
    symbol: "XVC",
    slug: "xverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xverse.png",
  },
  {
    name: "Francs",
    symbol: "FRN",
    slug: "francs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/francs.png",
  },
  {
    name: "BinanceHODL",
    symbol: "BiHODL",
    slug: "binancehodl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binancehodl.png",
  },
  {
    name: "Recession Coin",
    symbol: "ECON",
    slug: "recession-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/recession-coin.png",
  },
  {
    name: "Arable Protocol",
    symbol: "ACRE",
    slug: "arable-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arable-protocol.png",
  },
  {
    name: "AvatarCoin",
    symbol: "AV",
    slug: "avatarcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avatarcoin.png",
  },
  {
    name: "Meta Shield Coin",
    symbol: "SHIELD",
    slug: "meta-shield-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-shield-coin.png",
  },
  {
    name: "ClubCoin",
    symbol: "CLUB",
    slug: "clubcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clubcoin.png",
  },
  {
    name: "Darkness Dollar",
    symbol: "DUSD",
    slug: "darkness-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darkness-dollar.png",
  },
  {
    name: "Axiom",
    symbol: "AXIOM",
    slug: "axiom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axiom.png",
  },
  {
    name: "Retire Token",
    symbol: "RETIRE",
    slug: "retire-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/retire-token.png",
  },
  {
    name: "Hamdan Coin",
    symbol: "HMC",
    slug: "hamdan-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hamdan-coin.png",
  },
  {
    name: "Loaded Nodes",
    symbol: "$LDN",
    slug: "loaded-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loaded-nodes.png",
  },
  {
    name: "ApeBUSD",
    symbol: "APEBUSD",
    slug: "apebusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apebusd.png",
  },
  {
    name: "Bubble",
    symbol: "BUB",
    slug: "bubble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bubble.png",
  },
  {
    name: "FREEMOON ETH",
    symbol: "EFREEMOON",
    slug: "freemoon-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freemoon-eth.png",
  },
  {
    name: "Quotient",
    symbol: "XQN",
    slug: "quotient",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quotient.png",
  },
  {
    name: "Hellsing Inu",
    symbol: "HELLSING",
    slug: "hellsing-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hellsing-inu.png",
  },
  {
    name: "BabyApe",
    symbol: "BABYAPE",
    slug: "babyape-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyape-.png",
  },
  {
    name: "RabbitCoin",
    symbol: "RBBT",
    slug: "rabbitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rabbitcoin.png",
  },
  {
    name: "Vibranium",
    symbol: "VBN",
    slug: "vibranium-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vibranium-token.png",
  },
  {
    name: "Shibacock",
    symbol: "COCK",
    slug: "shibacock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibacock.png",
  },
  {
    name: "HoboNickels",
    symbol: "HBN",
    slug: "hobonickels",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hobonickels.png",
  },
  {
    name: "PeepCoin",
    symbol: "PCN",
    slug: "peepcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peepcoin.png",
  },
  {
    name: "Connect",
    symbol: "CNT",
    slug: "connect-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/connect-token.png",
  },
  {
    name: "HeroBook",
    symbol: "HBG",
    slug: "herobook",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herobook.png",
  },
  {
    name: "InvictusCapital.com Token",
    symbol: "ICAP",
    slug: "invictuscapital-com-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/invictuscapital-com-token.png",
  },
  {
    name: "CryptoBossCoin",
    symbol: "CBC",
    slug: "cryptobosscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobosscoin.png",
  },
  {
    name: "EloniumCoin",
    symbol: "ELNC",
    slug: "eloniumcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eloniumcoin.png",
  },
  {
    name: "ETHEKing",
    symbol: "ETHE",
    slug: "etheking",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etheking.png",
  },
  {
    name: "MyLottoCoin",
    symbol: "MYL",
    slug: "my-lotto-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-lotto-coin.png",
  },
  {
    name: "Marketing Samurai RBXS",
    symbol: "RBXSamurai",
    slug: "matrix-samurai-rbxs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrix-samurai-rbxs.png",
  },
  {
    name: "Clams",
    symbol: "CLAM",
    slug: "clams",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clams.png",
  },
  {
    name: "Shido",
    symbol: "SHIDO",
    slug: "shido-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shido-inu.png",
  },
  {
    name: "Davies",
    symbol: "DVS",
    slug: "davies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/davies.png",
  },
  {
    name: "LINA",
    symbol: "LINA",
    slug: "lina",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lina.png",
  },
  {
    name: "HondaisCoin",
    symbol: "HNDC",
    slug: "hondaiscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hondaiscoin.png",
  },
  {
    name: "Rubycoin",
    symbol: "RBY",
    slug: "rubycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rubycoin.png",
  },
  {
    name: "CakeZilla",
    symbol: "CAKEZILLA",
    slug: "cakezilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cakezilla.png",
  },
  {
    name: "Kawakami",
    symbol: "XKAWA",
    slug: "kawakami",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kawakami.png",
  },
  {
    name: "MultiGenCapital",
    symbol: "MGC",
    slug: "multigencapital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multigencapital.png",
  },
  {
    name: "ACE",
    symbol: "ACE",
    slug: "ace-entertainment",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ace-entertainment.png",
  },
  {
    name: "LibreFreelencer",
    symbol: "LIBREF",
    slug: "librefreelencer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/librefreelencer.png",
  },
  {
    name: "Stretch To Earn",
    symbol: "STE",
    slug: "stretch-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stretch-to-earn.png",
  },
  {
    name: "SuperMinesweeper",
    symbol: "SM",
    slug: "superminesweeper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superminesweeper.png",
  },
  {
    name: "NextEarth",
    symbol: "NXTT",
    slug: "nextearth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nextearth.png",
  },
  {
    name: "Tutor's Diary",
    symbol: "TUDA",
    slug: "tutors-diary",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tutors-diary.png",
  },
  {
    name: "ECC",
    symbol: "ECC",
    slug: "eccoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eccoin.png",
  },
  {
    name: "BecoSwap Token",
    symbol: "BECO",
    slug: "becoswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/becoswap-token.png",
  },
  {
    name: "Unicorn Milk",
    symbol: "UNIM",
    slug: "unicorn-milk-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicorn-milk-token.png",
  },
  {
    name: "DigiSwap",
    symbol: "DIGIS",
    slug: "digiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digiswap.png",
  },
  {
    name: "One DEX",
    symbol: "ODEX",
    slug: "one-dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-dex.png",
  },
  {
    name: "Sudan Gold Coin",
    symbol: "SGC",
    slug: "sudan-gold-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sudan-gold-coin.png",
  },
  {
    name: "Crypto Swap",
    symbol: "CPSP",
    slug: "crypto-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-swap.png",
  },
  {
    name: "Touch Social",
    symbol: "TST",
    slug: "touch-social",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/touch-social.png",
  },
  {
    name: "Token Cheetah",
    symbol: "CHTT",
    slug: "token-cheetah",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/token-cheetah.png",
  },
  {
    name: "Nilu",
    symbol: "NILU",
    slug: "nilu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nilu.png",
  },
  {
    name: "PolyUnity Finance",
    symbol: "UNITY",
    slug: "polyunity-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyunity-finance.png",
  },
  {
    name: "Infinite Ricks",
    symbol: "RICK",
    slug: "infinite-ricks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinite-ricks.png",
  },
  {
    name: "TokenSwap",
    symbol: "TP",
    slug: "tokenswap-dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenswap-dex.png",
  },
  {
    name: "Gold BCR",
    symbol: "GBCR",
    slug: "gold-bcr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-bcr.png",
  },
  {
    name: "Electra",
    symbol: "ECA",
    slug: "electra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electra.png",
  },
  {
    name: "BlockStamp",
    symbol: "BST",
    slug: "blockstamp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockstamp.png",
  },
  {
    name: "Altair",
    symbol: "AIR",
    slug: "altair",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altair.png",
  },
  {
    name: "Samurai Legends",
    symbol: "SMG",
    slug: "samurai-legends",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/samurai-legends.png",
  },
  {
    name: "Megla Doge",
    symbol: "MGD",
    slug: "megla-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megla-doge.png",
  },
  {
    name: "DogDeFiCoin",
    symbol: "DOGDEFI",
    slug: "dogdeficoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogdeficoin.png",
  },
  {
    name: "Kaby Gaming Token",
    symbol: "KGT",
    slug: "kaby-gaming-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaby-gaming-token.png",
  },
  {
    name: "Giveth",
    symbol: "GIV",
    slug: "giveth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/giveth.png",
  },
  {
    name: "LivenPay",
    symbol: "LVN",
    slug: "livenpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/livenpay.png",
  },
  {
    name: "Phuket Holiday Coin",
    symbol: "PHC",
    slug: "phuket-holiday-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phuket-holiday-coin.png",
  },
  {
    name: "Taroverse",
    symbol: "TARO",
    slug: "taroverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taroverse.png",
  },
  {
    name: "MYCE",
    symbol: "YCE",
    slug: "myce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myce.png",
  },
  {
    name: "Netkoin",
    symbol: "NTK",
    slug: "netkoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netkoin.png",
  },
  {
    name: "Jetset",
    symbol: "JTS",
    slug: "jetset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jetset.png",
  },
  {
    name: "Greyhound",
    symbol: "GREYHOUND",
    slug: "greyhound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greyhound.png",
  },
  {
    name: "VERA",
    symbol: "VERA",
    slug: "vera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vera.png",
  },
  {
    name: "wanBTC",
    symbol: "WANBTC",
    slug: "wanbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanbtc.png",
  },
  {
    name: "Dick",
    symbol: "DICK",
    slug: "dick",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dick.png",
  },
  {
    name: "Titania Token",
    symbol: "TITANIA",
    slug: "titania-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/titania-token.png",
  },
  {
    name: "PROUD Money",
    symbol: "PROUD",
    slug: "proud-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proud-money.png",
  },
  {
    name: "La Peseta",
    symbol: "PTA",
    slug: "la-peseta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/la-peseta.png",
  },
  {
    name: "FlipStar",
    symbol: "FLIP",
    slug: "flipstar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flipstar.png",
  },
  {
    name: "Hydranet",
    symbol: "HDX",
    slug: "hydranet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hydranet.png",
  },
  {
    name: "Yaan Launchpad",
    symbol: "YAAN",
    slug: "yaan-launchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yaan-launchpad.png",
  },
  {
    name: "Hiz Finance",
    symbol: "HIZ",
    slug: "hiz-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hiz-finance.png",
  },
  {
    name: "IO RUNE - Rune.Game",
    symbol: "IO",
    slug: "io-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/io-rune---rune-game.png",
  },
  {
    name: "DragonFarm Finance",
    symbol: "DRAGON",
    slug: "dragonfarm-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonfarm-finance.png",
  },
  {
    name: "Infinity Game NFT",
    symbol: "IGN",
    slug: "infinity-game-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-game-nft.png",
  },
  {
    name: "THE Ape",
    symbol: "TA",
    slug: "the-ape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-ape.png",
  },
  {
    name: "Spark Finance",
    symbol: "SPARK",
    slug: "spark-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spark-finance.png",
  },
  {
    name: "Blue Eyes White Doge",
    symbol: "BDOGE",
    slug: "blue-eyes-white-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blue-eyes-white-doge.png",
  },
  {
    name: "Falafel Coin",
    symbol: "FALAFEL",
    slug: "falafel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/falafel.png",
  },
  {
    name: "Vikings Finance",
    symbol: "VAL",
    slug: "vikings-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vikings-finance.png",
  },
  {
    name: "Junsonmingchncoin",
    symbol: "JMC",
    slug: "junsonmingchncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/junsonmingchncoin.png",
  },
  {
    name: "ORBYT Token",
    symbol: "ORBYT",
    slug: "orbyt-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbyt-token.png",
  },
  {
    name: "DFBTC",
    symbol: "AOM",
    slug: "dfbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfbtc.png",
  },
  {
    name: "Cryptolic",
    symbol: "CPTLC",
    slug: "cryptolic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptolic.png",
  },
  {
    name: "Alias",
    symbol: "ALIAS",
    slug: "alias",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alias.png",
  },
  {
    name: "Ripae pMATIC",
    symbol: "PMATIC",
    slug: "ripae-pmatic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ripae-pmatic.png",
  },
  {
    name: "Etheroll",
    symbol: "DICE",
    slug: "etheroll",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etheroll.png",
  },
  {
    name: "Lavabird",
    symbol: "LVBD",
    slug: "lavabird",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lavabird.png",
  },
  {
    name: "MCS Token",
    symbol: "MCS",
    slug: "mcs-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcs-token.png",
  },
  {
    name: "Beauty Bakery Linked Operation Transaction Technology",
    symbol: "LOTT",
    slug: "beauty-bakery-linked-operation-transaction-technology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beauty-bakery-linked-operation-transaction-technology.png",
  },
  {
    name: "dForce USDx",
    symbol: "USDX",
    slug: "dforce-usdx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dforce-usdx.png",
  },
  {
    name: "Devour Token",
    symbol: "RESTAURANTS",
    slug: "devour-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/devour-token.png",
  },
  {
    name: "CoinClaim",
    symbol: "CLM",
    slug: "coinclaim",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinclaim.png",
  },
  {
    name: "Petoverse",
    symbol: "PETO",
    slug: "petoverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petoverse.png",
  },
  {
    name: "wanSUSHI",
    symbol: "WANSUSHI",
    slug: "wansushi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wansushi.png",
  },
  {
    name: "Baby CateCoin",
    symbol: "BABYCATE",
    slug: "baby-catecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-catecoin.png",
  },
  {
    name: "Pitquidity-BSC",
    symbol: "PITQD",
    slug: "pitquidity-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pitquidity-bsc.png",
  },
  {
    name: "Maker Basic-MKB",
    symbol: "MKB",
    slug: "maker-basic-mkb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maker-basic-mkb.png",
  },
  {
    name: "7Eleven",
    symbol: "7E",
    slug: "7eleven",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/7eleven.png",
  },
  {
    name: "SpookyShiba [NEW]",
    symbol: "SPKY",
    slug: "spooky-shiba-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spooky-shiba-new.png",
  },
  {
    name: "TATA Coin",
    symbol: "TATA",
    slug: "tata-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tata-coin.png",
  },
  {
    name: "Food Farmer Finance",
    symbol: "FFF",
    slug: "food-farmer-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/food-farmer-finance.png",
  },
  {
    name: "Custody Token",
    symbol: "CUST",
    slug: "custody-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/custody-token.png",
  },
  {
    name: "I-COIN",
    symbol: "ICN",
    slug: "i-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/i-coin.png",
  },
  {
    name: "MEALS",
    symbol: "MEALS",
    slug: "meals",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meals.png",
  },
  {
    name: "Midas",
    symbol: "MIDAS",
    slug: "midas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/midas.png",
  },
  {
    name: "Burrito Boyz Floor Index",
    symbol: "BURR",
    slug: "burrito-boyz-floor-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burrito-boyz-floor-index.png",
  },
  {
    name: "B ONE PAYMENT",
    symbol: "B1P",
    slug: "b-one-payment",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/b-one-payment.png",
  },
  {
    name: "CelCoin",
    symbol: "CELC",
    slug: "celcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celcoin.png",
  },
  {
    name: "ToxicDeer Share",
    symbol: "XDSHARE",
    slug: "toxicdeer-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toxicdeer-share.png",
  },
  {
    name: "WEBN token",
    symbol: "WEBN",
    slug: "webn-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/webn-token.png",
  },
  {
    name: "Animal Token",
    symbol: "STRAY",
    slug: "animal-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animal-token.png",
  },
  {
    name: "Endless Board Game",
    symbol: "ENG",
    slug: "endless-board-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/endless-board-game.png",
  },
  {
    name: "Mero",
    symbol: "MERO",
    slug: "mero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mero.png",
  },
  {
    name: "RIFI United",
    symbol: "RU",
    slug: "rifi-united",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rifi-united.png",
  },
  {
    name: "Eco Value Coin",
    symbol: "EVC",
    slug: "eco-value-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eco-value-coin.png",
  },
  {
    name: "Sugar Kingdom",
    symbol: "CANDY",
    slug: "sugar-kingdom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sugar-kingdom.png",
  },
  {
    name: "Tiara",
    symbol: "TTI",
    slug: "tiara",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiara.png",
  },
  {
    name: "Muslim Coins",
    symbol: "MUSC",
    slug: "muslim-coins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muslim-coins.png",
  },
  {
    name: "HorizonDollar",
    symbol: "HZD",
    slug: "horizondollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/horizondollar.png",
  },
  {
    name: "GARD Governance Token",
    symbol: "GGT",
    slug: "gard-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gard-governance-token.png",
  },
  {
    name: "Social Send",
    symbol: "SEND",
    slug: "social-send",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/social-send.png",
  },
  {
    name: "LUM RUNE - Rune.Game",
    symbol: "LUM",
    slug: "lum-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lum-rune---rune-game.png",
  },
  {
    name: "Anonverse Gaming Token",
    symbol: "VVV",
    slug: "anonverse-gaming-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anonverse-gaming-token.png",
  },
  {
    name: "Bitsonic",
    symbol: "BSC",
    slug: "bitsonic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitsonic.png",
  },
  {
    name: "Elden Knights",
    symbol: "KNIGHTS",
    slug: "elden-knights",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elden-knights.png",
  },
  {
    name: "SafeOHM",
    symbol: "SOHM",
    slug: "safe-ohm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-ohm.png",
  },
  {
    name: "Dark",
    symbol: "D4RK",
    slug: "dark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dark.png",
  },
  {
    name: "Tratok",
    symbol: "TRAT",
    slug: "tratok",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tratok.png",
  },
  {
    name: "Profile Utility Token",
    symbol: "PUT",
    slug: "profile-utility-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/profile-utility-token.png",
  },
  {
    name: "X-Metaverse",
    symbol: "XMETA",
    slug: "x-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-metaverse.png",
  },
  {
    name: "Securypto",
    symbol: "SCU",
    slug: "securypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/securypto.png",
  },
  {
    name: "INGRESS",
    symbol: "IGR",
    slug: "ingress",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ingress.png",
  },
  {
    name: "Jigen",
    symbol: "JIG",
    slug: "jigen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jigen.png",
  },
  {
    name: "KumaDex Token",
    symbol: "DKUMA",
    slug: "kumadex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kumadex-token.png",
  },
  {
    name: "Evrice",
    symbol: "EVC",
    slug: "evrice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evrice.png",
  },
  {
    name: "RC Celta de Vigo Fan Token",
    symbol: "CFT",
    slug: "rc-celta-de-vigo-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rc-celta-de-vigo-fan-token.png",
  },
  {
    name: "XCredit",
    symbol: "XFYI",
    slug: "xcredit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xcredit.png",
  },
  {
    name: "Raja Inu",
    symbol: "RAJAINU",
    slug: "raja-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raja-inu.png",
  },
  {
    name: "wanLINK",
    symbol: "WANLINK",
    slug: "wanlink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanlink.png",
  },
  {
    name: "Diolaunch",
    symbol: "DLA",
    slug: "diolaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diolaunch.png",
  },
  {
    name: "Slime Royale Gold",
    symbol: "SRG",
    slug: "slime-royale-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slime-royale-gold.png",
  },
  {
    name: "SOLFINA PROTOCOL",
    symbol: "SOLFI",
    slug: "solfina-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solfina-protocol.png",
  },
  {
    name: "LESLARVERSE",
    symbol: "LLVERSE",
    slug: "leslarverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leslarverse.png",
  },
  {
    name: "Talaria Inu",
    symbol: "TALI",
    slug: "talaria-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/talaria-inu.png",
  },
  {
    name: "Richlab Token",
    symbol: "RLE",
    slug: "richlab-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/richlab-token.png",
  },
  {
    name: "Sakaryaspor Token",
    symbol: "SKRY",
    slug: "sakaryaspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sakaryaspor-token.png",
  },
  {
    name: "Lucky1Token",
    symbol: "L1T",
    slug: "lucky1token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky1token.png",
  },
  {
    name: "CPUcoin",
    symbol: "CPU",
    slug: "cpucoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cpucoin.png",
  },
  {
    name: "Momento",
    symbol: "MOMENTO",
    slug: "momento",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/momento.png",
  },
  {
    name: "XQuake",
    symbol: "XQK",
    slug: "xquake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xquake.png",
  },
  {
    name: "Luni",
    symbol: "LUNI",
    slug: "luni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luni.png",
  },
  {
    name: "Colibri Protocol",
    symbol: "CLBR",
    slug: "colibri",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colibri.png",
  },
  {
    name: "AlgoGems",
    symbol: "GEMS",
    slug: "algogems",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algogems.png",
  },
  {
    name: "Buu Inu",
    symbol: "BUU",
    slug: "buu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buu-inu.png",
  },
  {
    name: "THENODE",
    symbol: "THE",
    slug: "thenode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thenode.png",
  },
  {
    name: "Plugin",
    symbol: "PLI",
    slug: "plugin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plugin.png",
  },
  {
    name: "TKN Token",
    symbol: "TKNT",
    slug: "tkn-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tkn-token.png",
  },
  {
    name: "Yearn Loans Finance",
    symbol: "YLFI",
    slug: "yearn-loans-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-loans-finance.png",
  },
  {
    name: "ProjectFeenix",
    symbol: "FEENIXV2",
    slug: "projectfeenix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/projectfeenix.png",
  },
  {
    name: "Fistiana",
    symbol: "FCT",
    slug: "fistiana-circulates",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fistiana-circulates.png",
  },
  {
    name: "Zoro Inu",
    symbol: "ZORO",
    slug: "zoro-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoro-inu.png",
  },
  {
    name: "TTCRYPTO",
    symbol: "TTC",
    slug: "ttcrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ttcrypto.png",
  },
  {
    name: "BigdataCash",
    symbol: "BDCASH",
    slug: "bigdatacash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bigdatacash.png",
  },
  {
    name: "Sivasspor Token",
    symbol: "SIV",
    slug: "sivasspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sivasspor-token.png",
  },
  {
    name: "Supremacy",
    symbol: "SUPS",
    slug: "supremacy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supremacy.png",
  },
  {
    name: "Kepler452b",
    symbol: "452B",
    slug: "kepler452b",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kepler452b.png",
  },
  {
    name: "Everus",
    symbol: "EVR",
    slug: "everus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everus.png",
  },
  {
    name: "Omni Consumer Protocols",
    symbol: "OCP",
    slug: "omni-consumer-protocols",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omni-consumer-protocols.png",
  },
  {
    name: "DeFi Or Die",
    symbol: "DORD",
    slug: "defi-or-die",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-or-die.png",
  },
  {
    name: "Secure Cash",
    symbol: "SCSX",
    slug: "secure-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secure-cash.png",
  },
  {
    name: "Crypto Mushroomz",
    symbol: "SHROOMZ",
    slug: "crypto-mushroomz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-mushroomz.png",
  },
  {
    name: "Rainbow Token",
    symbol: "RBW",
    slug: "rainbowtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rainbowtoken.png",
  },
  {
    name: "O5O",
    symbol: "O5O",
    slug: "o5o",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/o5o.png",
  },
  {
    name: "CitiOs",
    symbol: "R2R",
    slug: "citios",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/citios.png",
  },
  {
    name: "Rug Busters",
    symbol: "RUGBUST",
    slug: "rug-busters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rug-busters.png",
  },
  {
    name: "WETA VR",
    symbol: "WETA",
    slug: "weta-vr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weta-vr.png",
  },
  {
    name: "Gravis Finance",
    symbol: "GRVS",
    slug: "gravis-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gravis-finance.png",
  },
  {
    name: "Husky Brother",
    symbol: "HUSKY",
    slug: "husky-brother",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/husky-brother.png",
  },
  {
    name: "Lucrosus Capital",
    symbol: "LUCA",
    slug: "lucrosus-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucrosus-capital.png",
  },
  {
    name: "Harcomia",
    symbol: "HCA",
    slug: "harcomia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harcomia.png",
  },
  {
    name: "FarmPoly",
    symbol: "POLY",
    slug: "farmpoly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmpoly.png",
  },
  {
    name: "BULLETH",
    symbol: "BULLETH",
    slug: "bulleth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulleth.png",
  },
  {
    name: "P2P",
    symbol: "P2P",
    slug: "p2p",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/p2p.png",
  },
  {
    name: "Elite Swap",
    symbol: "ELT",
    slug: "elite-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elite-swap.png",
  },
  {
    name: "Collector Coin",
    symbol: "AGS",
    slug: "collector-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/collector-coin.png",
  },
  {
    name: "Oppa",
    symbol: "OPPA",
    slug: "oppa-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oppa-token.png",
  },
  {
    name: "FuturoCoin",
    symbol: "FTO",
    slug: "futurocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futurocoin.png",
  },
  {
    name: "Hatch DAO",
    symbol: "HATCH",
    slug: "hatch-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hatch-dao.png",
  },
  {
    name: "Billibilli tokenized stock Bittrex",
    symbol: "BILI",
    slug: "billibilli-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/billibilli-tokenized-stock-bittrex.png",
  },
  {
    name: "Uber tokenized stock FTX",
    symbol: "UBER",
    slug: "uber-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uber-tokenized-stock-ftx.png",
  },
  {
    name: "Unlimited FiscusFYI",
    symbol: "UFFYI",
    slug: "unlimited-fiscusfyi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unlimited-fiscusfyi.png",
  },
  {
    name: "DeFi Insurance Protocol",
    symbol: "DFIP",
    slug: "defi-insurance-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-insurance-protocol.png",
  },
  {
    name: "Me-in",
    symbol: "MEIN",
    slug: "me-in",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/me-in.png",
  },
  {
    name: "SaveToken",
    symbol: "SAVE",
    slug: "savetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/savetoken.png",
  },
  {
    name: "Grave",
    symbol: "GRVE",
    slug: "grave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grave.png",
  },
  {
    name: "CAT.trade Protocol",
    symbol: "CATX",
    slug: "cat-trade-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cat-trade-protocol.png",
  },
  {
    name: "LinkBased",
    symbol: "LBD",
    slug: "linkbased",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkbased.png",
  },
  {
    name: "Red Kishu",
    symbol: "REDKISHU",
    slug: "red-kishu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/red-kishu.png",
  },
  {
    name: "Quoll Finance",
    symbol: "QUO",
    slug: "quoll-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quoll-finance.png",
  },
  {
    name: "Givetime.io",
    symbol: "GTM",
    slug: "givetime-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/givetime-io.png",
  },
  {
    name: "HIDEOUS",
    symbol: "HIDEOUS",
    slug: "hideous",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hideous.png",
  },
  {
    name: "CoralFarm",
    symbol: "CRL",
    slug: "coralfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coralfarm.png",
  },
  {
    name: "BLUEART TOKEN",
    symbol: "BLA",
    slug: "blueart-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blueart-token.png",
  },
  {
    name: "JAIL KWON TOKEN",
    symbol: "JKWON",
    slug: "jail-kwon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jail-kwon-token.png",
  },
  {
    name: "VN Token",
    symbol: "VN",
    slug: "vn-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vn-token.png",
  },
  {
    name: "BURNZ",
    symbol: "BURNZ",
    slug: "burnz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burnz.png",
  },
  {
    name: "UNITi Protocol",
    symbol: "UNIT",
    slug: "uniti-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniti-protocol.png",
  },
  {
    name: "Suteku",
    symbol: "SUTEKU",
    slug: "suteku",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/suteku.png",
  },
  {
    name: "Quadrans Token",
    symbol: "QDT",
    slug: "quadrans-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quadrans-token.png",
  },
  {
    name: "TimeLeap",
    symbol: "TIME",
    slug: "timeleap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/timeleap.png",
  },
  {
    name: "GigaPool",
    symbol: "GIGA",
    slug: "gigapool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gigapool.png",
  },
  {
    name: "SmartFi",
    symbol: "SMTF",
    slug: "smartfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartfi.png",
  },
  {
    name: "Cockapoo",
    symbol: "CPOO",
    slug: "cockapoo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cockapoo.png",
  },
  {
    name: "Gomics",
    symbol: "GOM",
    slug: "gomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gomics.png",
  },
  {
    name: "Bunny Girl Universe",
    symbol: "BNGT",
    slug: "bunny-girl-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunny-girl-universe.png",
  },
  {
    name: "Honey Token",
    symbol: "SWEET",
    slug: "honey-token-sweet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honey-token-sweet.png",
  },
  {
    name: "Fiscus.fyi",
    symbol: "FFYI",
    slug: "fiscus-fyi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fiscus-fyi.png",
  },
  {
    name: "GLOW",
    symbol: "GLOW",
    slug: "glow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glow.png",
  },
  {
    name: "DogeZone",
    symbol: "DGZ",
    slug: "dogezone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogezone.png",
  },
  {
    name: "Wrapped ALGO",
    symbol: "XALGO",
    slug: "wrapped-algo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-algo.png",
  },
  {
    name: "AgaveCoin",
    symbol: "AGVC",
    slug: "agavecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agavecoin.png",
  },
  {
    name: "Shib Taro",
    symbol: "SHIBTARO",
    slug: "shib-taro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shib-taro.png",
  },
  {
    name: "My Points E-Commerce",
    symbol: "MYPO",
    slug: "my-points-e-commerce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-points-e-commerce.png",
  },
  {
    name: "CommunityToken",
    symbol: "CT",
    slug: "communitytoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/communitytoken.png",
  },
  {
    name: "Trendsy",
    symbol: "TRNDZ",
    slug: "trendsy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trendsy.png",
  },
  {
    name: "BullDog Inu",
    symbol: "BULL",
    slug: "bulldog-inu-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulldog-inu-bsc.png",
  },
  {
    name: "xCRX",
    symbol: "XCRX",
    slug: "xcrx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xcrx.png",
  },
  {
    name: "Psychic",
    symbol: "PSY",
    slug: "psychic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/psychic.png",
  },
  {
    name: "WenMoon",
    symbol: "WENMOON",
    slug: "wenmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wenmoon.png",
  },
  {
    name: "The APIS",
    symbol: "API",
    slug: "the-apis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-apis.png",
  },
  {
    name: "ZelaaNFT",
    symbol: "NFTZ",
    slug: "zelaanft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zelaanft.png",
  },
  {
    name: "Ulgen Hash Power",
    symbol: "UHP",
    slug: "ulgen-hash-power",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ulgen-hash-power.png",
  },
  {
    name: "Robin Inu",
    symbol: "ROBIN",
    slug: "robin-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robin-inu.png",
  },
  {
    name: "Simply",
    symbol: "SIMPLY",
    slug: "simply",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simply.png",
  },
  {
    name: "LakeViewMeta",
    symbol: "LVM",
    slug: "lakeviewmeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lakeviewmeta.png",
  },
  {
    name: "Rex Inu",
    symbol: "REX",
    slug: "rexinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rexinu.png",
  },
  {
    name: "Ari10",
    symbol: "ARI10",
    slug: "ari10",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ari10.png",
  },
  {
    name: "Advanced Micro Devices tokenized stock FTX",
    symbol: "AMD",
    slug: "advanced-micro-devices-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/advanced-micro-devices-tokenized-stock-ftx.png",
  },
  {
    name: "Crypto Jackpot",
    symbol: "CJP",
    slug: "crypto-jackpot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-jackpot.png",
  },
  {
    name: "DYOR Token",
    symbol: "DYOR",
    slug: "dyor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dyor-token.png",
  },
  {
    name: "V3S Share",
    symbol: "VSHARE",
    slug: "v3s-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/v3s-share.png",
  },
  {
    name: "PalletOne",
    symbol: "PTN",
    slug: "palletone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/palletone.png",
  },
  {
    name: "Asgard Games",
    symbol: "ASG",
    slug: "asgard-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asgard-games.png",
  },
  {
    name: "Lemur Finance",
    symbol: "LEM",
    slug: "lemur-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lemur-finance.png",
  },
  {
    name: "HODL Vault Token",
    symbol: "HVLT",
    slug: "hodl-vault-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodl-vault-token.png",
  },
  {
    name: "Shiba Inu Billionaire",
    symbol: "SHIBIB",
    slug: "shiba-inu-billionaire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-inu-billionaire.png",
  },
  {
    name: "Depth Token",
    symbol: "DEP",
    slug: "depth-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/depth-token.png",
  },
  {
    name: "ENO",
    symbol: "ENO",
    slug: "eno",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eno.png",
  },
  {
    name: "WonderHero",
    symbol: "HON",
    slug: "wonderhero-hon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wonderhero-hon.png",
  },
  {
    name: "The Hustle App",
    symbol: "HUSL",
    slug: "the-hustle-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-hustle-app.png",
  },
  {
    name: "AurusSILVER",
    symbol: "AWS",
    slug: "aurussilver",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurussilver.png",
  },
  {
    name: "Imsmart",
    symbol: "IMT",
    slug: "imsmart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imsmart.png",
  },
  {
    name: "RocketFi",
    symbol: "ROCKETFI",
    slug: "rocketfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocketfi.png",
  },
  {
    name: "Monsterra NFT Game",
    symbol: "MAG",
    slug: "monsterra-nft-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monsterra-nft-game.png",
  },
  {
    name: "ARK Innovation ETF tokenized stock FTX",
    symbol: "ARKK",
    slug: "ark-innovation-etf-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ark-innovation-etf-tokenized-stock-ftx.png",
  },
  {
    name: "Newsolution",
    symbol: "NST",
    slug: "newsolution",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newsolution.png",
  },
  {
    name: "Himalayan Cat Coin",
    symbol: "HIMA",
    slug: "himalayan-cat-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/himalayan-cat-coin.png",
  },
  {
    name: "MintCoin",
    symbol: "MINT",
    slug: "mintcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mintcoin.png",
  },
  {
    name: "xJEWEL",
    symbol: "XJEWEL",
    slug: "xjewel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xjewel.png",
  },
  {
    name: "Boba Brewery",
    symbol: "BRE",
    slug: "boba-brewery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boba-brewery.png",
  },
  {
    name: "Mixty Finance",
    symbol: "MXF",
    slug: "mixty-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mixty-finance.png",
  },
  {
    name: "YieldPanda Finance",
    symbol: "yPANDA",
    slug: "yieldpanda-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yieldpanda-finance.png",
  },
  {
    name: "Allrunning DAO",
    symbol: "SHOES",
    slug: "allrunning-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/allrunning-dao.png",
  },
  {
    name: "FXPay",
    symbol: "FXP",
    slug: "fxpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fxpay.png",
  },
  {
    name: "Socialswap",
    symbol: "SST",
    slug: "socialswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/socialswap.png",
  },
  {
    name: "Vacus Finance",
    symbol: "VCS",
    slug: "vacus-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vacus-finance.png",
  },
  {
    name: "Football Decentralized",
    symbol: "FBD",
    slug: "football-decentralized",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/football-decentralized.png",
  },
  {
    name: "TIR RUNE - Rune.Game",
    symbol: "TIR",
    slug: "tir-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tir-rune---rune-game.png",
  },
  {
    name: "void.cash",
    symbol: "VCASH",
    slug: "void-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/void-cash.png",
  },
  {
    name: "SorachanCoin",
    symbol: "SORA",
    slug: "sorachancoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sorachancoin.png",
  },
  {
    name: "YFIBusiness",
    symbol: "YFIB",
    slug: "yfibusiness",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfibusiness.png",
  },
  {
    name: "BelecX Protocol",
    symbol: "BEX",
    slug: "belecx-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/belecx-protocol.png",
  },
  {
    name: "SoPay",
    symbol: "SOP",
    slug: "sopay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sopay.png",
  },
  {
    name: "Xtra Fund",
    symbol: "XTRA",
    slug: "xtra-fund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtra-fund.png",
  },
  {
    name: "STFU Labs",
    symbol: "STFU",
    slug: "stfu-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stfu-labs.png",
  },
  {
    name: "EL RUNE - Rune.Game",
    symbol: "EL",
    slug: "el-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/el-rune---rune-game.png",
  },
  {
    name: "Tepleton",
    symbol: "TEP",
    slug: "tepleton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tepleton.png",
  },
  {
    name: "ArcadeNetwork",
    symbol: "ARC",
    slug: "arcadenetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcadenetwork.png",
  },
  {
    name: "Lyra",
    symbol: "LYR",
    slug: "lyra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lyra.png",
  },
  {
    name: "NUDES",
    symbol: "NUDES",
    slug: "nudes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nudes.png",
  },
  {
    name: "1X Short Dogecoin Token",
    symbol: "DOGEHEDGE",
    slug: "1x-short-dogecoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1x-short-dogecoin-token.png",
  },
  {
    name: "COMOS Finance",
    symbol: "COMOS",
    slug: "comos-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comos-finance.png",
  },
  {
    name: "Jeet Detector Bot",
    symbol: "JDB",
    slug: "jeet-detector-bot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jeet-detector-bot.png",
  },
  {
    name: "MetaSpets",
    symbol: "MSP",
    slug: "metaspets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaspets.png",
  },
  {
    name: "ANTcoin",
    symbol: "ANT",
    slug: "antcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antcoin.png",
  },
  {
    name: "Paraverse",
    symbol: "PRVS",
    slug: "paraverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paraverse.png",
  },
  {
    name: "YFE Money",
    symbol: "YFE",
    slug: "yfe-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfe-money.png",
  },
  {
    name: "YFI3.money",
    symbol: "YFI3",
    slug: "yfi3-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfi3-money.png",
  },
  {
    name: "RAL RUNE - Rune.Game",
    symbol: "RAL",
    slug: "ral-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ral-rune---rune-game.png",
  },
  {
    name: "Alpha Coin",
    symbol: "ALPHA",
    slug: "alpha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha.png",
  },
  {
    name: "NewsToken",
    symbol: "NEWOS",
    slug: "newstoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newstoken.png",
  },
  {
    name: "PeaceTokenFinance",
    symbol: "PET",
    slug: "peacetokenfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peacetokenfinance.png",
  },
  {
    name: "Stoner Doge Finance",
    symbol: "STOGE",
    slug: "stoner-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stoner-doge.png",
  },
  {
    name: "YYE Energy",
    symbol: "YYE",
    slug: "yye-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yye-energy.png",
  },
  {
    name: "Euphoria",
    symbol: "EUPH",
    slug: "euphoriagames",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/euphoriagames.png",
  },
  {
    name: "Crypto Cricket Club",
    symbol: "3Cs",
    slug: "crypto-cricket-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-cricket-club.png",
  },
  {
    name: "ShibaMoon",
    symbol: "SHIBAMOON",
    slug: "shibamoon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibamoon-token.png",
  },
  {
    name: "Bullet App",
    symbol: "BLT",
    slug: "bullet-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullet-app.png",
  },
  {
    name: "WaterDefi",
    symbol: "WATER",
    slug: "water-finance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/water-finance-token.png",
  },
  {
    name: "Universal Protocol Token",
    symbol: "UPT",
    slug: "universal-protocol-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universal-protocol-token.png",
  },
  {
    name: "MetaQ",
    symbol: "METAQ",
    slug: "metaq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaq.png",
  },
  {
    name: "Astronos",
    symbol: "ASTRO",
    slug: "astronos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astronos.png",
  },
  {
    name: "INSTANTXRP",
    symbol: "INSTANTXRP",
    slug: "instantxrp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/instantxrp.png",
  },
  {
    name: "Echelon DAO",
    symbol: "ECHO",
    slug: "echelon-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/echelon-dao.png",
  },
  {
    name: "MCOBIT",
    symbol: "MCT",
    slug: "mcobit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcobit.png",
  },
  {
    name: "Global X Change Token",
    symbol: "GXT",
    slug: "global-x-change-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-x-change-token.png",
  },
  {
    name: "Based Token",
    symbol: "BDC",
    slug: "based-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/based-token.png",
  },
  {
    name: "USDQ",
    symbol: "USDQ",
    slug: "usdq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdq.png",
  },
  {
    name: "VALIMARKET",
    symbol: "VALI",
    slug: "valimarket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valimarket.png",
  },
  {
    name: "AscensionArcade",
    symbol: "AAT",
    slug: "ascensionarcade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ascensionarcade.png",
  },
  {
    name: "Tosa Inu",
    symbol: "TOS",
    slug: "tosa-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tosa-inu.png",
  },
  {
    name: "KnoxEdge",
    symbol: "KNX",
    slug: "knoxedge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knoxedge.png",
  },
  {
    name: "BOBAMASK",
    symbol: "BOBA",
    slug: "bobatama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bobatama.png",
  },
  {
    name: "Daily COP",
    symbol: "DLYCOP",
    slug: "daily-cop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daily-cop.png",
  },
  {
    name: "VKF Platform",
    symbol: "VKF",
    slug: "vkf-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vkf-platform.png",
  },
  {
    name: "ZONE",
    symbol: "ZONE",
    slug: "zone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zone.png",
  },
  {
    name: "Samurai",
    symbol: "SAM",
    slug: "samurai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/samurai.png",
  },
  {
    name: "MetaReset",
    symbol: "RESET",
    slug: "metareset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metareset.png",
  },
  {
    name: "Junior Shiba",
    symbol: "JRSHIB",
    slug: "junior-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/junior-shiba.png",
  },
  {
    name: "Hayya Qatar",
    symbol: "HQR",
    slug: "hayya-qatar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hayya-qatar.png",
  },
  {
    name: "Buzzshow",
    symbol: "GLDY",
    slug: "buzzshow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buzzshow.png",
  },
  {
    name: "BNBGlobal V2",
    symbol: "BNBG",
    slug: "bnbglobal-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbglobal-v2.png",
  },
  {
    name: "WindSwap",
    symbol: "WINDY",
    slug: "windswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/windswap.png",
  },
  {
    name: "BXTB Foundation",
    symbol: "BXTB",
    slug: "bxtb-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bxtb-foundation.png",
  },
  {
    name: "ZION",
    symbol: "ZION",
    slug: "zion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zion.png",
  },
  {
    name: "Forrest Pump",
    symbol: "FPUMP",
    slug: "forrest-pump",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forrest-pump.png",
  },
  {
    name: "Aitheon",
    symbol: "ACU",
    slug: "aitheon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aitheon.png",
  },
  {
    name: "Codi Finance",
    symbol: "CODI",
    slug: "codi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/codi-finance.png",
  },
  {
    name: "Swaprol",
    symbol: "SWPRL",
    slug: "swaprol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swaprol.png",
  },
  {
    name: "Counos Coin",
    symbol: "CCA",
    slug: "counos-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/counos-coin.png",
  },
  {
    name: "WPT Investing Corp",
    symbol: "WPT",
    slug: "wpt-investing-corp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wpt-investing-corp.png",
  },
  {
    name: "Kiradoge coin",
    symbol: "KIRADOGE",
    slug: "kiradoge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiradoge-coin.png",
  },
  {
    name: "Sav3Token",
    symbol: "SAV3",
    slug: "sav3token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sav3token.png",
  },
  {
    name: "Ace Cash",
    symbol: "ACEC",
    slug: "ace-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ace-cash.png",
  },
  {
    name: "Galileo",
    symbol: "GALI",
    slug: "galileo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galileo.png",
  },
  {
    name: "OFIN Token",
    symbol: "ON",
    slug: "ofin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ofin-token.png",
  },
  {
    name: "MemeNation",
    symbol: "MEMEN",
    slug: "memenation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memenation.png",
  },
  {
    name: "Kitsune Inu",
    symbol: "KITSUNE",
    slug: "kitsune-inu-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitsune-inu-2.png",
  },
  {
    name: "MetaGold Rewards",
    symbol: "METAGOLD",
    slug: "metagold-rewards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagold-rewards.png",
  },
  {
    name: "Futurov Governance Token",
    symbol: "FTV",
    slug: "futurov-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futurov-governance-token.png",
  },
  {
    name: "Dtube Coin",
    symbol: "DTUBE",
    slug: "dtube-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dtube-coin.png",
  },
  {
    name: "Rematic",
    symbol: "RMTX",
    slug: "rematic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rematic.png",
  },
  {
    name: "01coin",
    symbol: "ZOC",
    slug: "01coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/01coin.png",
  },
  {
    name: "RUSH",
    symbol: "RUC",
    slug: "rush",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rush.png",
  },
  {
    name: "Harmony Play",
    symbol: "HPLAY",
    slug: "harmony-play",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harmony-play.png",
  },
  {
    name: "Dragon Pool",
    symbol: "DP",
    slug: "dragon-pool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-pool.png",
  },
  {
    name: "Tweebaa",
    symbol: "TWEE",
    slug: "tweebaa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tweebaa.png",
  },
  {
    name: "CryptoBharatCoin",
    symbol: "CBC",
    slug: "cryptobharatcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobharatcoin.png",
  },
  {
    name: "Kiki",
    symbol: "KIKI",
    slug: "kiki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiki.png",
  },
  {
    name: "PIggyBankDAO",
    symbol: "PB",
    slug: "piggybankdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piggybankdao.png",
  },
  {
    name: "Rogue West",
    symbol: "ROGUE",
    slug: "rogue-west",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rogue-west.png",
  },
  {
    name: "Pando",
    symbol: "PANDO",
    slug: "pando",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pando.png",
  },
  {
    name: "Codex",
    symbol: "CDEX",
    slug: "codex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/codex.png",
  },
  {
    name: "Good Person Coin",
    symbol: "GPCX",
    slug: "good-person-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/good-person-coin.png",
  },
  {
    name: "EasySwap",
    symbol: "ESWA",
    slug: "easyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/easyswap.png",
  },
  {
    name: "Baby Wakanda inu",
    symbol: "BABYWKD",
    slug: "babywakandainu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babywakandainu.png",
  },
  {
    name: "Alpha Genesis",
    symbol: "AGEN",
    slug: "alpha-genesis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-genesis.png",
  },
  {
    name: "SafePanda",
    symbol: "SPND",
    slug: "safepanda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safepanda.png",
  },
  {
    name: "EcoDollar",
    symbol: "ECOS",
    slug: "ecodollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecodollar.png",
  },
  {
    name: "The Tale of Osaku",
    symbol: "OSAKU",
    slug: "the-tale-of-osaku",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-tale-of-osaku.png",
  },
  {
    name: "Ghostface Shiba",
    symbol: "GFSHIB",
    slug: "ghostface-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghostface-shiba.png",
  },
  {
    name: "Okay Bears Floor Index",
    symbol: "OKAYB",
    slug: "okay-bears-floor-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okay-bears-floor-index.png",
  },
  {
    name: "Cultiplan(CTPL)",
    symbol: "CTPL",
    slug: "cultiplan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cultiplan.png",
  },
  {
    name: "wanUNI",
    symbol: "WANUNI",
    slug: "wanuni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanuni.png",
  },
  {
    name: "GoalTime N",
    symbol: "GTX",
    slug: "goaltime-n",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goaltime-n.png",
  },
  {
    name: "Buddy DAO",
    symbol: "BDY",
    slug: "buddy-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buddy-dao.png",
  },
  {
    name: "HNB Protocol",
    symbol: "HNB",
    slug: "hnb-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hnb-protocol.png",
  },
  {
    name: "Triflex",
    symbol: "TRFX",
    slug: "triflex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/triflex-token.png",
  },
  {
    name: "Safetesla",
    symbol: "SAFETESLA",
    slug: "safetesla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safetesla.png",
  },
  {
    name: "DeCash",
    symbol: "DESH",
    slug: "decash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decash.png",
  },
  {
    name: "Arkadiko Finance",
    symbol: "DIKO",
    slug: "arkadiko-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arkadiko-finance.png",
  },
  {
    name: "Orlando Chain",
    symbol: "ORL",
    slug: "orlando-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orlando-chain.png",
  },
  {
    name: "EcchiCoin",
    symbol: "ECCHI",
    slug: "ecchicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecchicoin.png",
  },
  {
    name: "LegalBlock",
    symbol: "LBK",
    slug: "legalblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legalblock.png",
  },
  {
    name: "BabyFlokiZilla",
    symbol: "BABYFLOKIZILLA",
    slug: "babyflokizilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyflokizilla.png",
  },
  {
    name: "Zurcoin",
    symbol: "ZUR",
    slug: "zurcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zurcoin.png",
  },
  {
    name: "PowerZilla",
    symbol: "POWERZILLA",
    slug: "powerzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/powerzilla.png",
  },
  {
    name: "Panda Inu",
    symbol: "PANDA",
    slug: "panda-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/panda-inu.png",
  },
  {
    name: "BitKAM",
    symbol: "KAM",
    slug: "bitkam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitkam.png",
  },
  {
    name: "ChowDAO",
    symbol: "CHOW",
    slug: "chowdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chowdao.png",
  },
  {
    name: "ApeFund",
    symbol: "APEFUND",
    slug: "apefund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apefund.png",
  },
  {
    name: "Big Fund Capital DAO",
    symbol: "BFC",
    slug: "big-fund-capital-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-fund-capital-dao.png",
  },
  {
    name: "HyperMeteor",
    symbol: "HYMETEOR",
    slug: "hypermeteor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hypermeteor.png",
  },
  {
    name: "MommyUSDT",
    symbol: "MOMMYUSDT",
    slug: "mommyusdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mommyusdt.png",
  },
  {
    name: "Amazon Tokenized Stock Defichain",
    symbol: "DAMZN",
    slug: "amazon-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amazon-tokenized-stock-defichain.png",
  },
  {
    name: "FairLunar",
    symbol: "FLUNAR",
    slug: "fairlunar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fairlunar.png",
  },
  {
    name: "Cheems",
    symbol: "CHEEMS",
    slug: "cheems",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheems.png",
  },
  {
    name: "BitCherry",
    symbol: "BCHC",
    slug: "bitcherry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcherry.png",
  },
  {
    name: "iShares Silver Trust Defichain",
    symbol: "DSLV",
    slug: "silver-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silver-tokenized-stock-defichain.png",
  },
  {
    name: "Yield Yak AVAX",
    symbol: "YYAVAX",
    slug: "yield-yak-avax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-yak-avax.png",
  },
  {
    name: "ShareAt",
    symbol: "XAT",
    slug: "shareat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shareat.png",
  },
  {
    name: "TheBoringToken",
    symbol: "TBT",
    slug: "theboringtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/theboringtoken.png",
  },
  {
    name: "Volt",
    symbol: "ACDC",
    slug: "volt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/volt.png",
  },
  {
    name: "Parallel Finance",
    symbol: "PARA",
    slug: "parallel-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parallel-finance.png",
  },
  {
    name: "MMScash",
    symbol: "MCASH",
    slug: "mmscash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mmscash.png",
  },
  {
    name: "FC Sion Fan Token",
    symbol: "SION",
    slug: "fc-sion-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fc-sion-fan-token.png",
  },
  {
    name: "PlusOneCoin",
    symbol: "PLUS1",
    slug: "plusonecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plusonecoin.png",
  },
  {
    name: "GameYoo",
    symbol: "GYC",
    slug: "gameyoo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gameyoo.png",
  },
  {
    name: "DeFi Gold",
    symbol: "DFGL",
    slug: "defi-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-gold.png",
  },
  {
    name: "ORT RUNE - Rune.Game",
    symbol: "ORT",
    slug: "ort-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ort-rune---rune-game.png",
  },
  {
    name: "AntiscamToken",
    symbol: "AST",
    slug: "antiscamtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antiscamtoken.png",
  },
  {
    name: "ARB Protocol",
    symbol: "ARB",
    slug: "arb-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arb-protocol.png",
  },
  {
    name: "Kimochi Finance",
    symbol: "KIMOCHI",
    slug: "kimochi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kimochi-finance.png",
  },
  {
    name: "Crystl Finance",
    symbol: "CRYSTL",
    slug: "polycrystal-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polycrystal-finance.png",
  },
  {
    name: "Fire Inu",
    symbol: "FINU",
    slug: "fire-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fire-inu.png",
  },
  {
    name: "Valhalla Protocol",
    symbol: "VAL",
    slug: "valhalla-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valhalla-protocol.png",
  },
  {
    name: "Scopuly Coin",
    symbol: "SCOP",
    slug: "scopuly-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scopuly-coin.png",
  },
  {
    name: "NEFTiPEDiA",
    symbol: "NFT",
    slug: "neftipedia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neftipedia.png",
  },
  {
    name: "WeGro Coin",
    symbol: "WEGRO",
    slug: "wegro-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wegro-coin.png",
  },
  {
    name: "Gamma",
    symbol: "GAMMA",
    slug: "gamma-strategies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamma-strategies.png",
  },
  {
    name: "Aquari",
    symbol: "AQUARI",
    slug: "aquari",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aquari.png",
  },
  {
    name: "Rbicycle",
    symbol: "CYCLE",
    slug: "rbicycle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rbicycle.png",
  },
  {
    name: "CMITCOIN",
    symbol: "CMIT",
    slug: "cmitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cmitcoin.png",
  },
  {
    name: "Catena X",
    symbol: "CEX",
    slug: "catena-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catena-x.png",
  },
  {
    name: "Lyra",
    symbol: "LYRA",
    slug: "lyra-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lyra-finance.png",
  },
  {
    name: "HeartBout Pay",
    symbol: "HP",
    slug: "heartbout-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heartbout-pay.png",
  },
  {
    name: "Redux Protocol",
    symbol: "RDX",
    slug: "redux-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redux-protocol.png",
  },
  {
    name: "BITMIC",
    symbol: "BMIC",
    slug: "bitmic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitmic.png",
  },
  {
    name: "Farming Bad",
    symbol: "METH",
    slug: "farming-bad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farming-bad.png",
  },
  {
    name: "Magikarp Finance",
    symbol: "MAGI",
    slug: "magikarp-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magikarp-finance.png",
  },
  {
    name: "Marshal Lion Group Coin",
    symbol: "MLGC",
    slug: "marshal-lion-group-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marshal-lion-group-coin.png",
  },
  {
    name: "Elves Century",
    symbol: "ELVES",
    slug: "elves-century",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elves-century.png",
  },
  {
    name: "Shadetech",
    symbol: "SHD",
    slug: "shadetech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shadetech.png",
  },
  {
    name: "bHBD",
    symbol: "BHBD",
    slug: "bhbd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bhbd.png",
  },
  {
    name: "Coinversation",
    symbol: "CTO",
    slug: "coinversation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinversation.png",
  },
  {
    name: "YFET",
    symbol: "YFET",
    slug: "yfet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfet.png",
  },
  {
    name: "Face Meta 2.0",
    symbol: "FACEMETA",
    slug: "face-meta-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/face-meta-2.png",
  },
  {
    name: "Vera Cruz Coin",
    symbol: "VCCO",
    slug: "vera-cruz-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vera-cruz-coin.png",
  },
  {
    name: "The Seed Farm",
    symbol: "SEED",
    slug: "the-seed-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-seed-farm.png",
  },
  {
    name: "Matic Aave Interest Bearing USDT",
    symbol: "MAUSDT",
    slug: "matic-aave-usdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matic-aave-usdt.png",
  },
  {
    name: "Squoge Coin",
    symbol: "SQC",
    slug: "squoge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squoge-coin.png",
  },
  {
    name: "DeadSoul",
    symbol: "DSL",
    slug: "deadsoul",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deadsoul.png",
  },
  {
    name: "Dig Chain",
    symbol: "DIG",
    slug: "dig-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dig-chain.png",
  },
  {
    name: "SincereDogeDAO",
    symbol: "SDAO",
    slug: "sinceredogedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sinceredogedao.png",
  },
  {
    name: "Yield Yld",
    symbol: "YLD",
    slug: "yield-yld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-yld.png",
  },
  {
    name: "FONE",
    symbol: "FONE",
    slug: "fone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fone.png",
  },
  {
    name: "Xank",
    symbol: "XANK",
    slug: "xank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xank.png",
  },
  {
    name: "OragonX",
    symbol: "ORGN",
    slug: "oragonx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oragonx.png",
  },
  {
    name: "Documentchain",
    symbol: "DMS",
    slug: "documentchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/documentchain.png",
  },
  {
    name: "Staked NEAR",
    symbol: "STNEAR",
    slug: "staked-near",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/staked-near.png",
  },
  {
    name: "The Smokehouse",
    symbol: "SMOKE",
    slug: "the-smokehouse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-smokehouse.png",
  },
  {
    name: "Virtual Gamer",
    symbol: "VGM",
    slug: "virtual-gamer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/virtual-gamer.png",
  },
  {
    name: "Sound BSC",
    symbol: "SOUND",
    slug: "sound-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sound-bsc.png",
  },
  {
    name: "Summer",
    symbol: "SUMMER",
    slug: "summer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/summer.png",
  },
  {
    name: "YearRise",
    symbol: "YRT",
    slug: "yearrise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearrise.png",
  },
  {
    name: "HOMT",
    symbol: "HOMT",
    slug: "homt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/homt.png",
  },
  {
    name: "CV SHOTS",
    symbol: "CVSHOT",
    slug: "cv-shots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cv-shots.png",
  },
  {
    name: "GreenPower",
    symbol: "GRN",
    slug: "greenpower",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenpower.png",
  },
  {
    name: "Crypto Realms War",
    symbol: "YNY",
    slug: "crypto-realms-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-realms-war.png",
  },
  {
    name: "Promise",
    symbol: "PROMISE",
    slug: "promise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/promise.png",
  },
  {
    name: "IdleTreasureParty",
    symbol: "DTEVIL",
    slug: "idletreasureparty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idletreasureparty.png",
  },
  {
    name: "Powabit",
    symbol: "POWA",
    slug: "powabit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/powabit.png",
  },
  {
    name: "MetaBomb",
    symbol: "MTB",
    slug: "metabomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabomb.png",
  },
  {
    name: "BLOCKCLOUT",
    symbol: "CLOUT",
    slug: "blockclout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockclout.png",
  },
  {
    name: "NFTStyle",
    symbol: "NFTSTYLE",
    slug: "nftstyle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftstyle.png",
  },
  {
    name: "Higgs",
    symbol: "HIGGS",
    slug: "higgs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/higgs.png",
  },
  {
    name: "PayPal tokenized stock FTX",
    symbol: "PYPL",
    slug: "paypal-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paypal-tokenized-stock-ftx.png",
  },
  {
    name: "Akira",
    symbol: "AKIRA",
    slug: "akira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akira.png",
  },
  {
    name: "Trinity Protocol",
    symbol: "TRI",
    slug: "trinity-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trinity-protocol.png",
  },
  {
    name: "Apple Network",
    symbol: "ANK",
    slug: "apple-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apple-network.png",
  },
  {
    name: "SHENG",
    symbol: "SHENG",
    slug: "sheng",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sheng.png",
  },
  {
    name: "WealthSecrets",
    symbol: "WSC",
    slug: "wealthsecrets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wealthsecrets.png",
  },
  {
    name: "Stable FIL",
    symbol: "ONEFIL",
    slug: "stable-fil",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stable-fil.png",
  },
  {
    name: "SKINCHAIN",
    symbol: "SKC",
    slug: "skinchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skinchain.png",
  },
  {
    name: "Business Age of Empires",
    symbol: "BAoE",
    slug: "business-age-of-empires",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/business-age-of-empires.png",
  },
  {
    name: "rLoop",
    symbol: "RLOOP",
    slug: "rloop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rloop.png",
  },
  {
    name: "Rebel Bots",
    symbol: "RBLS",
    slug: "rebel-bots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rebel-bots.png",
  },
  {
    name: "Hanacoin",
    symbol: "HANA",
    slug: "hanacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hanacoin.png",
  },
  {
    name: "Flash",
    symbol: "FLASH",
    slug: "flash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flash.png",
  },
  {
    name: "Beast Masters",
    symbol: "MASTER",
    slug: "beast-masters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beast-masters.png",
  },
  {
    name: "Wiki Token",
    symbol: "WIKI",
    slug: "wiki-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wiki-token.png",
  },
  {
    name: "Sense",
    symbol: "SENSE",
    slug: "sense",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sense.png",
  },
  {
    name: "Hydraverse",
    symbol: "HDV",
    slug: "hydraverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hydraverse.png",
  },
  {
    name: "DAYSTARTER",
    symbol: "DST",
    slug: "daystarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daystarter.png",
  },
  {
    name: "WHACKD",
    symbol: "WHACKD",
    slug: "whackd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whackd.png",
  },
  {
    name: "Bonorum",
    symbol: "BONO",
    slug: "bonorum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonorum.png",
  },
  {
    name: "Rayons Energy",
    symbol: "RAYONS",
    slug: "rayons-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rayons-energy.png",
  },
  {
    name: "Demeter Chain",
    symbol: "DMTC",
    slug: "demeter-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/demeter-chain.png",
  },
  {
    name: "Startama Go",
    symbol: "STARTAMAGO",
    slug: "startama-go",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/startama-go.png",
  },
  {
    name: "Gadoshi",
    symbol: "GADOSHI",
    slug: "gadoshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gadoshi.png",
  },
  {
    name: "Speedex",
    symbol: "SPDX",
    slug: "speedex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/speedex.png",
  },
  {
    name: "Kepler Network",
    symbol: "KMW",
    slug: "kepler-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kepler-network.png",
  },
  {
    name: "BIG ETH",
    symbol: "BIGETH",
    slug: "big-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-eth.png",
  },
  {
    name: "PHILLIPS PAY COIN",
    symbol: "PPC",
    slug: "philips-pay-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/philips-pay-coin.png",
  },
  {
    name: "Crypto Vault",
    symbol: "CVT",
    slug: "crypto-vault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-vault.png",
  },
  {
    name: "AxieDoge",
    symbol: "AXSD",
    slug: "axiedoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axiedoge.png",
  },
  {
    name: "Hearn.fi",
    symbol: "HEARN",
    slug: "hearn-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hearn-fi.png",
  },
  {
    name: "DopeWarz",
    symbol: "DRUG",
    slug: "dopewarz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dopewarz.png",
  },
  {
    name: "Homerun Token",
    symbol: "HMRN",
    slug: "homerun-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/homerun-token.png",
  },
  {
    name: "iCherry Finance",
    symbol: "ICH",
    slug: "icherry-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icherry-finance.png",
  },
  {
    name: "Tiger Token",
    symbol: "TGNB",
    slug: "tiger-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiger-token.png",
  },
  {
    name: "Masrelic",
    symbol: "RELIC",
    slug: "masrelic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/masrelic.png",
  },
  {
    name: "ZEUS10000",
    symbol: "ZEUS10000",
    slug: "zeus10000",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeus10000.png",
  },
  {
    name: "MetaMic E-Sports Games",
    symbol: "MEG",
    slug: "metamic-e-sports-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamic-e-sports-games.png",
  },
  {
    name: "Ubique Chain Of Things",
    symbol: "UCT",
    slug: "ubique-chain-of-things",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubique-chain-of-things.png",
  },
  {
    name: "MLAND Token",
    symbol: "MLAND",
    slug: "mland-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mland-token.png",
  },
  {
    name: "BeeSwap",
    symbol: "BEE",
    slug: "beeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beeswap.png",
  },
  {
    name: "eFIN",
    symbol: "EFIN",
    slug: "efin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/efin.png",
  },
  {
    name: "METArcade",
    symbol: "META",
    slug: "metarcade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metarcade.png",
  },
  {
    name: "GIVLY Coin",
    symbol: "GIV",
    slug: "givly-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/givly-coin.png",
  },
  {
    name: "Crypto Tankz",
    symbol: "TANKZ",
    slug: "crypto-tankz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-tankz.png",
  },
  {
    name: "Basix",
    symbol: "BASX",
    slug: "basix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basix.png",
  },
  {
    name: "ETHW ID",
    symbol: "EID",
    slug: "ethw-id",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethw-id.png",
  },
  {
    name: "Chainpay",
    symbol: "CPAY",
    slug: "chainpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainpay.png",
  },
  {
    name: "Oceans Finance",
    symbol: "OCEANS",
    slug: "oceans-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oceans-finance.png",
  },
  {
    name: "ENTONE",
    symbol: "ENTONE",
    slug: "entone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/entone.png",
  },
  {
    name: "Grinbit",
    symbol: "GRBT",
    slug: "grinbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grinbit.png",
  },
  {
    name: "LifetionCoin",
    symbol: "LTP",
    slug: "lifetioncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lifetioncoin.png",
  },
  {
    name: "Google Tokenized Stock Zipmex",
    symbol: "GOOGL",
    slug: "google-tokenized-stock-zipmex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/google-tokenized-stock-zipmex.png",
  },
  {
    name: "Schilling-Coin",
    symbol: "SCH",
    slug: "schilling-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/schilling-coin.png",
  },
  {
    name: "Mochi Inu",
    symbol: "MOCHI",
    slug: "mochi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mochi-inu.png",
  },
  {
    name: "MUFTSWAP",
    symbol: "MSWAP",
    slug: "muftswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muftswap.png",
  },
  {
    name: "Evanesco Network",
    symbol: "EVA",
    slug: "evanesco-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evanesco-network.png",
  },
  {
    name: "TAF Token",
    symbol: "TAF",
    slug: "taf-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taf-token.png",
  },
  {
    name: "Triforce Protocol",
    symbol: "TFC",
    slug: "triforce-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/triforce-protocol.png",
  },
  {
    name: "KOLnet",
    symbol: "KOLNET",
    slug: "kolnet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kolnet.png",
  },
  {
    name: "Dexchain",
    symbol: "DXC",
    slug: "mydexpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mydexpay.png",
  },
  {
    name: "PumpShibaX",
    symbol: "PSHIBAX",
    slug: "pumpshibax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pumpshibax.png",
  },
  {
    name: "Octus Bridge",
    symbol: "BRIDGE",
    slug: "octus-bridge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octus-bridge.png",
  },
  {
    name: "YEARN2.FINANCE",
    symbol: "YFI2",
    slug: "yearn2-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn2-finance.png",
  },
  {
    name: "Recast1 Coin",
    symbol: "R1",
    slug: "recast1-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/recast1-coin.png",
  },
  {
    name: "Vybe",
    symbol: "VYBE",
    slug: "vybe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vybe.png",
  },
  {
    name: "Kashima Inu",
    symbol: "SHIMA",
    slug: "kashima-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kashima-inu.png",
  },
  {
    name: "NEF RUNE - Rune.Game",
    symbol: "NEF",
    slug: "nef-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nef-rune---rune-game.png",
  },
  {
    name: "GOLCOIN",
    symbol: "GOLC",
    slug: "golcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golcoin.png",
  },
  {
    name: "YFII Gold",
    symbol: "YFIIG",
    slug: "yfii-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfii-gold.png",
  },
  {
    name: "MetaFighter",
    symbol: "MF",
    slug: "metafighter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafighter.png",
  },
  {
    name: "Tiny Titans",
    symbol: "TITS",
    slug: "tiny-titans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiny-titans.png",
  },
  {
    name: "Protectors of the Realm",
    symbol: "WER1",
    slug: "protectors-of-the-realm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/protectors-of-the-realm.png",
  },
  {
    name: "Marsan Exchange token",
    symbol: "MRS",
    slug: "marsan-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marsan-exchange-token.png",
  },
  {
    name: "UncleMine",
    symbol: "UM",
    slug: "unclemine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unclemine.png",
  },
  {
    name: "Wettok Market",
    symbol: "WTO",
    slug: "wettok-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wettok-market.png",
  },
  {
    name: "Milktea.finance",
    symbol: "MTF",
    slug: "milktea-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milktea-finance.png",
  },
  {
    name: "Zippie",
    symbol: "ZIPT",
    slug: "zippie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zippie.png",
  },
  {
    name: "DAPPCENTS",
    symbol: "DPC",
    slug: "dappcents",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dappcents.png",
  },
  {
    name: "Tezilla",
    symbol: "TEZILLA",
    slug: "tezilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tezilla.png",
  },
  {
    name: "Azit",
    symbol: "AZIT",
    slug: "azit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/azit.png",
  },
  {
    name: "New Year Resolution",
    symbol: "NYR",
    slug: "new-year-resolution",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-year-resolution.png",
  },
  {
    name: "Stylike Governance",
    symbol: "STYL",
    slug: "stylike-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stylike-governance.png",
  },
  {
    name: "Emanate",
    symbol: "EMT",
    slug: "emanate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emanate.png",
  },
  {
    name: "Defrost Finance",
    symbol: "H2O",
    slug: "defrost-finance-h20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defrost-finance-h20.png",
  },
  {
    name: "ITEN",
    symbol: "ITEN",
    slug: "iten",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iten.png",
  },
  {
    name: "Darkness Share",
    symbol: "NESS",
    slug: "darkness-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darkness-share.png",
  },
  {
    name: "YieldNyan",
    symbol: "NYAN",
    slug: "yieldnyan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yieldnyan.png",
  },
  {
    name: "TattooMoney",
    symbol: "TAT2",
    slug: "tattoomoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tattoomoney.png",
  },
  {
    name: "Bantu",
    symbol: "XBN",
    slug: "bantu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bantu.png",
  },
  {
    name: "TTOKEN",
    symbol: "TTOKEN",
    slug: "ttoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ttoken.png",
  },
  {
    name: "XREATORS",
    symbol: "ORT",
    slug: "xreators",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xreators.png",
  },
  {
    name: "Petrachor",
    symbol: "PTA",
    slug: "petrachor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petrachor.png",
  },
  {
    name: "Tom Coin",
    symbol: "TMC",
    slug: "tom-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tom-coin.png",
  },
  {
    name: "Fantom Frens",
    symbol: "FFS",
    slug: "fantom-frens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantom-frens.png",
  },
  {
    name: "PocketNode",
    symbol: "NODE",
    slug: "pocketnode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocketnode.png",
  },
  {
    name: "MYX Network",
    symbol: "MYX",
    slug: "myx-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myx-network.png",
  },
  {
    name: "Gnome Mines",
    symbol: "GMINES",
    slug: "gnome-mines",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gnome-mines.png",
  },
  {
    name: "GangstaBet",
    symbol: "GBET",
    slug: "gangstabet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gangstabet.png",
  },
  {
    name: "Magic Balancer",
    symbol: "MGB",
    slug: "magic-balancer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-balancer.png",
  },
  {
    name: "Moon Light Night",
    symbol: "MLNT",
    slug: "moon-light-night",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-light-night.png",
  },
  {
    name: "NitroFloki",
    symbol: "NIFLOKI",
    slug: "nitrofloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nitrofloki.png",
  },
  {
    name: "Social Finance",
    symbol: "SOFI",
    slug: "social-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/social-finance.png",
  },
  {
    name: "Bitbook Gambling",
    symbol: "BXK",
    slug: "bitbook-gambling",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitbook-gambling.png",
  },
  {
    name: "OutPost",
    symbol: "OUT",
    slug: "outpost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/outpost.png",
  },
  {
    name: "Kapital DAO",
    symbol: "KAP",
    slug: "kapital-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kapital-dao.png",
  },
  {
    name: "Shikokuaido",
    symbol: "SHOKK",
    slug: "shikokuaido",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shikokuaido.png",
  },
  {
    name: "i9 Coin",
    symbol: "I9C",
    slug: "i9-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/i9-coin.png",
  },
  {
    name: "CRB Coin",
    symbol: "CRB",
    slug: "crb-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crb-coin.png",
  },
  {
    name: "Loot Network",
    symbol: "LOOT",
    slug: "loot-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loot-network.png",
  },
  {
    name: "CloudCoin",
    symbol: "CCE",
    slug: "cloudcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cloudcoin.png",
  },
  {
    name: "Reflector.Finance",
    symbol: "RFCTR",
    slug: "reflector-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reflector-finance.png",
  },
  {
    name: "Node Squared",
    symbol: "N2",
    slug: "node-squared",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/node-squared.png",
  },
  {
    name: "Meta Decentraland",
    symbol: "MDL",
    slug: "meta-decentraland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-decentraland.png",
  },
  {
    name: "Walnut.finance",
    symbol: "WTF",
    slug: "walnut-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/walnut-finance.png",
  },
  {
    name: "Vive La Bouje",
    symbol: "VIVE",
    slug: "vive-la-bouje",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vive-la-bouje.png",
  },
  {
    name: "Shopdi",
    symbol: "SHOD",
    slug: "shopdi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shopdi.png",
  },
  {
    name: "SafeLaunchpad",
    symbol: "SLD",
    slug: "safelaunchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safelaunchpad.png",
  },
  {
    name: "Brosispay",
    symbol: "BSPAY",
    slug: "brosispay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brosispay.png",
  },
  {
    name: "PHIT",
    symbol: "PHIT",
    slug: "phit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phit.png",
  },
  {
    name: "Shipit pro",
    symbol: "SHPP",
    slug: "shipit-pro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shipit-pro.png",
  },
  {
    name: "MarsMission Protocol",
    symbol: "MARSM",
    slug: "marsmission-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marsmission-protocol.png",
  },
  {
    name: "YFIDapp",
    symbol: "YFID",
    slug: "yfidapp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfidapp.png",
  },
  {
    name: "Twoge Inu",
    symbol: "TWOGE",
    slug: "twoge-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twoge-inu.png",
  },
  {
    name: "Revoluzion",
    symbol: "RVZ",
    slug: "revoluzion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revoluzion.png",
  },
  {
    name: "Blurt",
    symbol: "BLURT",
    slug: "blurt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blurt.png",
  },
  {
    name: "Tronipay",
    symbol: "TRP",
    slug: "tronipay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tronipay.png",
  },
  {
    name: "AstroBirdz",
    symbol: "ABZ",
    slug: "astrobirdz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astrobirdz.png",
  },
  {
    name: "Rijent Coin",
    symbol: "RTC",
    slug: "rijent-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rijent-coin.png",
  },
  {
    name: "Charitas",
    symbol: "CHAR",
    slug: "charitas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charitas.png",
  },
  {
    name: "Umbria Network",
    symbol: "UMBR",
    slug: "umbria-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/umbria-network.png",
  },
  {
    name: "Monerium EUR emoney",
    symbol: "EURe",
    slug: "monerium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monerium.png",
  },
  {
    name: "Metars",
    symbol: "MTSC",
    slug: "metars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metars.png",
  },
  {
    name: "UCX FOUNDATION",
    symbol: "UCX",
    slug: "ucx-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ucx-foundation.png",
  },
  {
    name: "eShark Token",
    symbol: "ESHK",
    slug: "eshark-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eshark-token.png",
  },
  {
    name: "NFT POOL",
    symbol: "NFTP",
    slug: "nft-pool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-pool.png",
  },
  {
    name: "Freedom Reserve",
    symbol: "FR",
    slug: "freedom-reserve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freedom-reserve.png",
  },
  {
    name: "RealLink",
    symbol: "REAL",
    slug: "reallink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reallink.png",
  },
  {
    name: "Wolf Girl",
    symbol: "WOLFGIRL",
    slug: "wolfgirl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolfgirl.png",
  },
  {
    name: "Electronero Pulse",
    symbol: "ETNXP",
    slug: "electronero-pulse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electronero-pulse.png",
  },
  {
    name: "Kounotori",
    symbol: "KTO",
    slug: "kounotori",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kounotori.png",
  },
  {
    name: "IceCubes Finance",
    symbol: "ICUBE",
    slug: "icecubes-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icecubes-finance.png",
  },
  {
    name: "Matador Token",
    symbol: "MTDR",
    slug: "matador-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matador-token.png",
  },
  {
    name: "Blitz Labs",
    symbol: "BLITZ",
    slug: "blitz-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blitz-labs.png",
  },
  {
    name: "Marinade",
    symbol: "MNDE",
    slug: "mnde",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mnde.png",
  },
  {
    name: "ThunderSwap",
    symbol: "TNDR",
    slug: "thunderswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thunderswap.png",
  },
  {
    name: "Feast Finance",
    symbol: "FEAST",
    slug: "feast-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feast-finance.png",
  },
  {
    name: "PRELAX SWAP",
    symbol: "PEAX",
    slug: "prelax-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prelax-swap.png",
  },
  {
    name: "SEKUYA",
    symbol: "SKUY",
    slug: "sekuya",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sekuya.png",
  },
  {
    name: "Dune Network",
    symbol: "DUN",
    slug: "dune-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dune-network.png",
  },
  {
    name: "EncrypGen",
    symbol: "DNA",
    slug: "encrypgen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/encrypgen.png",
  },
  {
    name: "OmniaVerse",
    symbol: "OMNIA",
    slug: "omniaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omniaverse.png",
  },
  {
    name: "Australian Crypto Coin Green",
    symbol: "ACCG",
    slug: "australian-crypto-coin-green",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/australian-crypto-coin-green.png",
  },
  {
    name: "Realms of Ruby",
    symbol: "RUBY",
    slug: "realms-of-ruby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realms-of-ruby.png",
  },
  {
    name: "CATO",
    symbol: "CATO",
    slug: "cato",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cato.png",
  },
  {
    name: "STONK",
    symbol: "STONK",
    slug: "stonk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stonk.png",
  },
  {
    name: "Actifit",
    symbol: "AFIT",
    slug: "actifit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/actifit.png",
  },
  {
    name: "Rivermount",
    symbol: "RM",
    slug: "rivermount",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rivermount.png",
  },
  {
    name: "TLabs",
    symbol: "TBS",
    slug: "tlabs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tlabs.png",
  },
  {
    name: "JUMPX",
    symbol: "JUMPX",
    slug: "jumpx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jumpx.png",
  },
  {
    name: "Evagrow Coin",
    symbol: "EVA",
    slug: "evagrow-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evagrow-coin.png",
  },
  {
    name: "Bitcoffeen",
    symbol: "BFF",
    slug: "bitcoffeen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoffeen.png",
  },
  {
    name: "EVEREST RISING",
    symbol: "EVEREST",
    slug: "everest-rising",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everest-rising.png",
  },
  {
    name: "Equus Mining Token",
    symbol: "EQMT",
    slug: "equus-mining-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/equus-mining-token.png",
  },
  {
    name: "KILLTHEZERO",
    symbol: "KTZ",
    slug: "killthezero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/killthezero.png",
  },
  {
    name: "Etherpay",
    symbol: "ETHPY",
    slug: "etherpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherpay.png",
  },
  {
    name: "Crazy Rich Coin",
    symbol: "CRC",
    slug: "crazy-rich-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crazy-rich-coin.png",
  },
  {
    name: "Life Token V2",
    symbol: "LTNV2",
    slug: "life-token-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/life-token-v2.png",
  },
  {
    name: "YFTether",
    symbol: "YFTE",
    slug: "yftether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yftether.png",
  },
  {
    name: "Chow Inu",
    symbol: "CHOW",
    slug: "chow-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chow-inu.png",
  },
  {
    name: "BSCWIN Bulls",
    symbol: "BSCWIN",
    slug: "bscwin-bulls",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bscwin-bulls.png",
  },
  {
    name: "Fanaticos Cash",
    symbol: "FCH",
    slug: "fanaticos-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanaticos-cash.png",
  },
  {
    name: "Punk",
    symbol: "PUNK",
    slug: "punk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/punk.png",
  },
  {
    name: "Favor",
    symbol: "FAVOR",
    slug: "favor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/favor.png",
  },
  {
    name: "Populous XBRL Token",
    symbol: "PXT",
    slug: "populous-xbrl-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/populous-xbrl-token.png",
  },
  {
    name: "Netflix Tokenized Stock Defichain",
    symbol: "DNFLX",
    slug: "netflix-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netflix-tokenized-stock-defichain.png",
  },
  {
    name: "Amy Finance",
    symbol: "AMY",
    slug: "amy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amy-finance.png",
  },
  {
    name: "Markaccy",
    symbol: "MKCY",
    slug: "markaccy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/markaccy.png",
  },
  {
    name: "BYTZ",
    symbol: "BYTZ",
    slug: "bytz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bytz.png",
  },
  {
    name: "QUASA",
    symbol: "QUA",
    slug: "quasa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quasa.png",
  },
  {
    name: "Land Of Realms",
    symbol: "LOR",
    slug: "land-of-realms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/land-of-realms.png",
  },
  {
    name: "BitcoinRegular",
    symbol: "BTRL",
    slug: "bitcoinregular",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinregular.png",
  },
  {
    name: "Peace DAO",
    symbol: "PEACE",
    slug: "peace-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peace-dao.png",
  },
  {
    name: "Hodler Heroes NFT",
    symbol: "HHNFT",
    slug: "hodler-heroes-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodler-heroes-nft.png",
  },
  {
    name: "Amazon tokenized stock Bittrex",
    symbol: "AMZN",
    slug: "amazon-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amazon-tokenized-stock-bittrex.png",
  },
  {
    name: "0cash",
    symbol: "ZCH",
    slug: "0cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0cash.png",
  },
  {
    name: "FireZard",
    symbol: "ZARD",
    slug: "firezard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firezard.png",
  },
  {
    name: "NFTshootout",
    symbol: "SHOO",
    slug: "nftshootout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftshootout.png",
  },
  {
    name: "Sandwich Network",
    symbol: "SANDWICH",
    slug: "sandwich-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sandwich-network.png",
  },
  {
    name: "AVCCOIN",
    symbol: "AVC",
    slug: "avccoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avccoin.png",
  },
  {
    name: "Maggot",
    symbol: "MAGGOT",
    slug: "maggot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maggot.png",
  },
  {
    name: "VoltSwap",
    symbol: "VOLT",
    slug: "voltswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voltswap.png",
  },
  {
    name: "Bankers Dream",
    symbol: "BANK$",
    slug: "bankers-dream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankers-dream.png",
  },
  {
    name: "LoopSwap",
    symbol: "LSWAP",
    slug: "loopswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loopswap.png",
  },
  {
    name: "DXBPay",
    symbol: "DXB",
    slug: "dxbpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dxbpay.png",
  },
  {
    name: "Sheesha Finance [ERC20]",
    symbol: "SHEESHA",
    slug: "sheesha-finance-erc20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sheesha-finance-erc20.png",
  },
  {
    name: "ShinChan Token",
    symbol: "SHINNOSUKE",
    slug: "shinchan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinchan-token.png",
  },
  {
    name: "Dequant",
    symbol: "DEQ",
    slug: "dequant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dequant.png",
  },
  {
    name: "TTcoin",
    symbol: "TC",
    slug: "ttcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ttcoin.png",
  },
  {
    name: "Payship",
    symbol: "PSHP",
    slug: "payship",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payship.png",
  },
  {
    name: "Altimatum",
    symbol: "$ALTI",
    slug: "altimatum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altimatum.png",
  },
  {
    name: "Nebulaprotocol",
    symbol: "SNBL",
    slug: "nebulaprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nebulaprotocol.png",
  },
  {
    name: "HyruleSwap",
    symbol: "RUPEE",
    slug: "hyruleswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyruleswap.png",
  },
  {
    name: "GO2E Token",
    symbol: "GTE",
    slug: "go2e-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/go2e-token.png",
  },
  {
    name: "Yoda Coin Swap",
    symbol: "JEDALS",
    slug: "yoda-coin-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yoda-coin-swap.png",
  },
  {
    name: "Panda Dao",
    symbol: "PDAO",
    slug: "panda-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/panda-dao.png",
  },
  {
    name: "V2 Devour Token",
    symbol: "DPAY",
    slug: "devour-token-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/devour-token-v2.png",
  },
  {
    name: "TRONADO",
    symbol: "TRDO",
    slug: "tronado",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tronado.png",
  },
  {
    name: "Moar Finance",
    symbol: "MOAR",
    slug: "moar-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moar-finance.png",
  },
  {
    name: "Alibaba tokenized stock FTX",
    symbol: "BABA",
    slug: "alibaba-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alibaba-tokenized-stock-ftx.png",
  },
  {
    name: "TranslateMe Network Token",
    symbol: "TMN",
    slug: "translateme-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/translateme-network-token.png",
  },
  {
    name: "Lepasa",
    symbol: "LEPA",
    slug: "lepasa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lepasa.png",
  },
  {
    name: "SaveNode",
    symbol: "SNO",
    slug: "savenode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/savenode.png",
  },
  {
    name: "Defira (Cronos)",
    symbol: "FIRA",
    slug: "defira-cronos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defira-cronos.png",
  },
  {
    name: "SwapShip",
    symbol: "SWSH",
    slug: "swapship",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapship.png",
  },
  {
    name: "Dfinance",
    symbol: "XFI",
    slug: "dfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfinance.png",
  },
  {
    name: "Loop Finance",
    symbol: "LOOP",
    slug: "loop-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loop-finance.png",
  },
  {
    name: "Alpha Capital (New)",
    symbol: "ACAP",
    slug: "alpha-brain-capital-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-brain-capital-2.png",
  },
  {
    name: "Prophet",
    symbol: "PROPHET",
    slug: "prophet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prophet.png",
  },
  {
    name: "Telegram Inu",
    symbol: "TINU",
    slug: "telegram-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/telegram-inu.png",
  },
  {
    name: "More Token",
    symbol: "MORE",
    slug: "moremoney-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moremoney-finance.png",
  },
  {
    name: "Yearn Finance Red Moon",
    symbol: "YFRM",
    slug: "yearn-finance-red-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance-red-moon.png",
  },
  {
    name: "Kaiba Inu",
    symbol: "KAIBA",
    slug: "kaiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaiba-inu.png",
  },
  {
    name: "Centaurify",
    symbol: "CENT",
    slug: "centaurify",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centaurify.png",
  },
  {
    name: "Camp",
    symbol: "CAMP",
    slug: "camp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/camp.png",
  },
  {
    name: "CookieSale",
    symbol: "COOKIE",
    slug: "cookiesale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cookiesale.png",
  },
  {
    name: "AmpliFi",
    symbol: "AMPLIFI",
    slug: "amplifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amplifi.png",
  },
  {
    name: "BlockCDN",
    symbol: "BCDN",
    slug: "blockcdn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockcdn.png",
  },
  {
    name: "Ovato",
    symbol: "OVO",
    slug: "ovato",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ovato.png",
  },
  {
    name: "Hat Swap City",
    symbol: "HTC",
    slug: "hat-swap-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hat-swap-city.png",
  },
  {
    name: "Crypto Accept",
    symbol: "ACPT",
    slug: "crypto-accept",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-accept.png",
  },
  {
    name: "BrandPad Finance",
    symbol: "BRAND",
    slug: "brandpad-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brandpad-finance.png",
  },
  {
    name: "Pagan Gods",
    symbol: "FUR",
    slug: "pagan-gods",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pagan-gods.png",
  },
  {
    name: "Algory Project",
    symbol: "ALG",
    slug: "algory-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algory-project.png",
  },
  {
    name: "Perion",
    symbol: "PERC",
    slug: "perion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/perion.png",
  },
  {
    name: "Niobio",
    symbol: "NBR",
    slug: "niobio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niobio.png",
  },
  {
    name: "NEXT",
    symbol: "NET",
    slug: "next",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/next.png",
  },
  {
    name: "Finance Blocks",
    symbol: "FBX",
    slug: "finance-blocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/finance-blocks.png",
  },
  {
    name: "Hash2O.com",
    symbol: "H2O",
    slug: "hash2o-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hash2o-com.png",
  },
  {
    name: "Torex",
    symbol: "TOR",
    slug: "torex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/torex.png",
  },
  {
    name: "SeaDog Finance",
    symbol: "SEAD",
    slug: "seadog-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seadog-finance.png",
  },
  {
    name: "Bingo Share",
    symbol: "SBGO",
    slug: "bingo-cash-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bingo-cash-finance.png",
  },
  {
    name: "Victory Gem",
    symbol: "VTG",
    slug: "victory-gem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/victory-gem.png",
  },
  {
    name: "YouSwap",
    symbol: "YOU",
    slug: "youswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/youswap.png",
  },
  {
    name: "Astronaut",
    symbol: "NAUT",
    slug: "astronaut",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astronaut.png",
  },
  {
    name: "ZillaCoin",
    symbol: "ZILLACOIN",
    slug: "zillacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zillacoin.png",
  },
  {
    name: "Captain Planet",
    symbol: "CTP",
    slug: "captain-planet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/captain-planet.png",
  },
  {
    name: "Brixel War",
    symbol: "BXS",
    slug: "brixel-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brixel-war.png",
  },
  {
    name: "Asgard finance",
    symbol: "THOR",
    slug: "asgard-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asgard-finance.png",
  },
  {
    name: "Manifest",
    symbol: "MNFST",
    slug: "manifest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manifest.png",
  },
  {
    name: "Erth Point",
    symbol: "ERTH",
    slug: "erth-point",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/erth-point.png",
  },
  {
    name: "YFI CREDITS GROUP",
    symbol: "YFICG",
    slug: "yfi-credits-group",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfi-credits-group.png",
  },
  {
    name: "ROTTSCHILD",
    symbol: "ROTTS",
    slug: "rottschild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rottschild.png",
  },
  {
    name: "Dragonbit",
    symbol: "DRGB",
    slug: "dragonbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonbit.png",
  },
  {
    name: "Nobo Finance",
    symbol: "NOBF",
    slug: "nobo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nobo-finance.png",
  },
  {
    name: "EURxb",
    symbol: "EURXB",
    slug: "eurxb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eurxb.png",
  },
  {
    name: "Kickstarter",
    symbol: "KSR",
    slug: "kickstarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kickstarter.png",
  },
  {
    name: "Colligo",
    symbol: "COTK",
    slug: "colligo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colligo.png",
  },
  {
    name: "Asgardian Aereus",
    symbol: "VOLT",
    slug: "asgardian-aereus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asgardian-aereus.png",
  },
  {
    name: "Tax Haven Inu",
    symbol: "TAXHAVENINU",
    slug: "tax-haven-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tax-haven-inu.png",
  },
  {
    name: "MY Ceremonial Event",
    symbol: "MYCE",
    slug: "my-ceremonial-event",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-ceremonial-event.png",
  },
  {
    name: "Rare Pepe",
    symbol: "RPEPE",
    slug: "rare-pepe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rare-pepe.png",
  },
  {
    name: "Shilly Bar",
    symbol: "SHBAR",
    slug: "shilly-bar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shilly-bar.png",
  },
  {
    name: "Santa Floki",
    symbol: "HOHOHO",
    slug: "santa-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/santa-floki.png",
  },
  {
    name: "Baby o Baby",
    symbol: "BOB",
    slug: "baby-o-baby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-o-baby.png",
  },
  {
    name: "Golden Ratio Coin",
    symbol: "GOLDR",
    slug: "golden-ratio-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-ratio-coin.png",
  },
  {
    name: "SquidGameDeFi",
    symbol: "SGD",
    slug: "squidgamedefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squidgamedefi.png",
  },
  {
    name: "Mirrored Facebook Inc",
    symbol: "mFB",
    slug: "mirrored-facebook",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-facebook.png",
  },
  {
    name: "JPGold Coin",
    symbol: "JPGC",
    slug: "jpgold-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jpgold-coin.png",
  },
  {
    name: "Ripple Alpha",
    symbol: "XLA",
    slug: "ripple-alpha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ripple-alpha.png",
  },
  {
    name: "Meta Brawl",
    symbol: "BRAWL",
    slug: "meta-brawl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-brawl.png",
  },
  {
    name: "Coinage Finance",
    symbol: "CAGE",
    slug: "coinage-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinage-finance.png",
  },
  {
    name: "Shkreli Inu",
    symbol: "SHKI",
    slug: "shkreli-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shkreli-inu.png",
  },
  {
    name: "DeFiat",
    symbol: "DFT",
    slug: "defiat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defiat.png",
  },
  {
    name: "Boltt Coin ",
    symbol: "BOLTT",
    slug: "boltt-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boltt-coin.png",
  },
  {
    name: "Pollo Dollar",
    symbol: "PDO",
    slug: "pollo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pollo.png",
  },
  {
    name: "Supernova",
    symbol: "LFC",
    slug: "supernova-sunday-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supernova-sunday-games.png",
  },
  {
    name: "SPRINK",
    symbol: "SPRINK",
    slug: "sprink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sprink.png",
  },
  {
    name: "Fifa Inu",
    symbol: "FINU",
    slug: "fifa-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fifa-inu.png",
  },
  {
    name: "ValorFoundation",
    symbol: "VALOR",
    slug: "valorfoundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valorfoundation.png",
  },
  {
    name: "Metaverse VR",
    symbol: "MEVR",
    slug: "metaversevr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaversevr.png",
  },
  {
    name: "Demeter",
    symbol: "DEO",
    slug: "demeter-deo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/demeter-deo.png",
  },
  {
    name: "Kingdomswap",
    symbol: "KS",
    slug: "kingdomswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdomswap.png",
  },
  {
    name: "Safeplus",
    symbol: "$SPLUS",
    slug: "safeplus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeplus.png",
  },
  {
    name: "Hero Floki",
    symbol: "HERO",
    slug: "hero-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hero-floki.png",
  },
  {
    name: "Fishgun Token",
    symbol: "FGC",
    slug: "fishgun-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fishgun-token.png",
  },
  {
    name: "Sphere",
    symbol: "SPHR",
    slug: "sphere",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sphere.png",
  },
  {
    name: "CryptoBlast",
    symbol: "CBT",
    slug: "cryptoblast",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoblast.png",
  },
  {
    name: "Spring",
    symbol: "SPRING",
    slug: "spring",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spring.png",
  },
  {
    name: "Bridge$",
    symbol: "BRG.X",
    slug: "bridges",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bridges.png",
  },
  {
    name: "Taco Finance",
    symbol: "TACO",
    slug: "taco-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taco-finance.png",
  },
  {
    name: "Tcoin",
    symbol: "TCO",
    slug: "tcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tcoin-token.png",
  },
  {
    name: "Ethica",
    symbol: "ETHICA",
    slug: "ethica",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethica.png",
  },
  {
    name: "TLChain",
    symbol: "TLC",
    slug: "tlchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tlchain.png",
  },
  {
    name: "UBXS",
    symbol: "UBXS",
    slug: "ubxs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubxs.png",
  },
  {
    name: "MegaShibaZilla",
    symbol: "MSZ",
    slug: "megashibazilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megashibazilla.png",
  },
  {
    name: "Lightyears",
    symbol: "YEAR",
    slug: "lightyears",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lightyears.png",
  },
  {
    name: "Cojam",
    symbol: "CT",
    slug: "cojam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cojam.png",
  },
  {
    name: "XTRM COIN",
    symbol: "XTRM",
    slug: "xtrm-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtrm-coin.png",
  },
  {
    name: "Value Finance",
    symbol: "VFT",
    slug: "value-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/value-finance.png",
  },
  {
    name: "MTI Finance",
    symbol: "MTI",
    slug: "mti-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mti-finance.png",
  },
  {
    name: "FeelCoin",
    symbol: "FEEL",
    slug: "feelcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feelcoin.png",
  },
  {
    name: "Wallet Plus X",
    symbol: "WPX",
    slug: "wallet-plus-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wallet-plus-x.png",
  },
  {
    name: "FairEclipse",
    symbol: "FECLIPSE",
    slug: "faireclipse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/faireclipse.png",
  },
  {
    name: "RocketBUSD",
    symbol: "RocketBUSD",
    slug: "rocketbusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocketbusd.png",
  },
  {
    name: "Volta",
    symbol: "VOLTA",
    slug: "volta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/volta.png",
  },
  {
    name: "Pfizer tokenized stock Bittrex",
    symbol: "PFE",
    slug: "pfizer-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pfizer-tokenized-stock-bittrex.png",
  },
  {
    name: "Sphere Finance",
    symbol: "SPHERE",
    slug: "sphere-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sphere-finance.png",
  },
  {
    name: "Dark Matter",
    symbol: "DKMT",
    slug: "vibranium-dkmt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vibranium-dkmt.png",
  },
  {
    name: "Uranus",
    symbol: "URAC",
    slug: "uranus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uranus.png",
  },
  {
    name: "Crypto Bank Coin",
    symbol: "CBC",
    slug: "crypto-bank-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-bank-coin.png",
  },
  {
    name: "PAMP.CC",
    symbol: "PAMP",
    slug: "pamp-cc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pamp-cc.png",
  },
  {
    name: "BoleToken",
    symbol: "BOLE",
    slug: "boletoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boletoken.png",
  },
  {
    name: "CarboFoot",
    symbol: "CFOOT",
    slug: "carbofoot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbofoot.png",
  },
  {
    name: "Drunk Robots",
    symbol: "METAL",
    slug: "drunk-robots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drunk-robots.png",
  },
  {
    name: "OrcaX",
    symbol: "OX",
    slug: "orcax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orcax.png",
  },
  {
    name: "Cypherium",
    symbol: "CPH",
    slug: "cypherium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cypherium.png",
  },
  {
    name: "Xpool",
    symbol: "XPO",
    slug: "xpool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xpool.png",
  },
  {
    name: "Elf Token",
    symbol: "ELF",
    slug: "elf-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elf-token.png",
  },
  {
    name: "Neumark",
    symbol: "NEU",
    slug: "neumark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neumark.png",
  },
  {
    name: "VersoView",
    symbol: "VVT",
    slug: "versoview",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/versoview.png",
  },
  {
    name: "MetaRare",
    symbol: "MTRA",
    slug: "metarare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metarare.png",
  },
  {
    name: "Twitter tokenized stock FTX",
    symbol: "TWTR",
    slug: "twitter-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twitter-tokenized-stock-ftx.png",
  },
  {
    name: "MoonMoon",
    symbol: "MOONMOON",
    slug: "moonmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonmoon.png",
  },
  {
    name: "SOLBERRY",
    symbol: "SOLBERRY",
    slug: "solberry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solberry.png",
  },
  {
    name: "Wrapped Monero",
    symbol: "WXMR",
    slug: "wrapped-monero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-monero.png",
  },
  {
    name: "Swirl",
    symbol: "SWIRL",
    slug: "swirl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swirl.png",
  },
  {
    name: "The Universal Settlement Coin",
    symbol: "TUSC",
    slug: "the-universal-settlement-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-universal-settlement-coin.png",
  },
  {
    name: "Bearn",
    symbol: "BFI",
    slug: "bearn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bearn.png",
  },
  {
    name: "ORS Group",
    symbol: "ORS",
    slug: "ors-group",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ors-group.png",
  },
  {
    name: "Penrose Finance",
    symbol: "PEN",
    slug: "penrose-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/penrose-finance.png",
  },
  {
    name: "Brainaut Defi",
    symbol: "BRN",
    slug: "brainaut-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brainaut-defi.png",
  },
  {
    name: "srnArtGallery Tokenized Arts",
    symbol: "SISTA",
    slug: "srnartgallery-tokenized-arts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/srnartgallery-tokenized-arts.png",
  },
  {
    name: "FrenCoin",
    symbol: "FREN",
    slug: "frencoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frencoin.png",
  },
  {
    name: "Soju Finance",
    symbol: "SOJU",
    slug: "soju-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soju-finance.png",
  },
  {
    name: "Oceans Swap",
    symbol: "ODEX",
    slug: "oceans-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oceans-swap.png",
  },
  {
    name: "Defiskeletons",
    symbol: "SKELETON",
    slug: "defiskeletons",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defiskeletons.png",
  },
  {
    name: "Grom",
    symbol: "GR",
    slug: "grom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grom.png",
  },
  {
    name: "AladiEx",
    symbol: "ALA",
    slug: "aladiex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aladiex.png",
  },
  {
    name: "Billionaire Plus",
    symbol: "BPLUS",
    slug: "billionaire-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/billionaire-plus.png",
  },
  {
    name: "Space Hoge",
    symbol: "SOGE",
    slug: "space-hoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-hoge.png",
  },
  {
    name: "EPRO TOKEN",
    symbol: "EPRO",
    slug: "ethereum-pro-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-pro-new.png",
  },
  {
    name: "Gem Pad",
    symbol: "GEMS",
    slug: "gem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gem.png",
  },
  {
    name: "Orbit Token",
    symbol: "ORBIT",
    slug: "orbit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbit-token.png",
  },
  {
    name: "Bitcurate",
    symbol: "BTCR",
    slug: "bitcurate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcurate.png",
  },
  {
    name: "Shina Inu",
    symbol: "SHI",
    slug: "shina-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shina-inu.png",
  },
  {
    name: "Vmates",
    symbol: "MATE",
    slug: "vmates",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vmates.png",
  },
  {
    name: "BSC FARM",
    symbol: "BSC",
    slug: "bsc-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsc-farm.png",
  },
  {
    name: "Aludra Network",
    symbol: "ALD",
    slug: "aludra-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aludra-network.png",
  },
  {
    name: "Forus",
    symbol: "FORS",
    slug: "forus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forus.png",
  },
  {
    name: "Redluna",
    symbol: "REDLUNA",
    slug: "redluna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redluna.png",
  },
  {
    name: "The global index chain",
    symbol: "TGIC",
    slug: "the-global-index-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-global-index-chain.png",
  },
  {
    name: "New Ventures",
    symbol: "NEWW",
    slug: "newv-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newv-finance.png",
  },
  {
    name: "Giresunspor Token",
    symbol: "GRS",
    slug: "giresunspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/giresunspor-token.png",
  },
  {
    name: "MITH Cash",
    symbol: "MIC",
    slug: "mith-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mith-cash.png",
  },
  {
    name: "KakashiInuV2",
    symbol: "KKI",
    slug: "kakashiinuv2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kakashiinuv2.png",
  },
  {
    name: "Robotina",
    symbol: "ROX",
    slug: "robotina",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robotina.png",
  },
  {
    name: "GLOBALTRUSTFUND TOKEN",
    symbol: "GTF",
    slug: "globaltrustfund-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/globaltrustfund-token.png",
  },
  {
    name: "ITH RUNE - Rune.Game",
    symbol: "ITH",
    slug: "ith-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ith-rune---rune-game.png",
  },
  {
    name: "Revo Network",
    symbol: "REVO",
    slug: "revo-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revo-network.png",
  },
  {
    name: "DUST Protocol",
    symbol: "DUST",
    slug: "dust-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dust-protocol.png",
  },
  {
    name: "Worthwhile",
    symbol: "WHE",
    slug: "worthwhile",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/worthwhile.png",
  },
  {
    name: "protocol finance",
    symbol: "PFI",
    slug: "protocol-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/protocol-finance.png",
  },
  {
    name: "Hamtaro",
    symbol: "HAMTARO",
    slug: "hamtaro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hamtaro.png",
  },
  {
    name: "Hayate INU",
    symbol: "HINU",
    slug: "hayate-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hayate-inu.png",
  },
  {
    name: "Terra SDT",
    symbol: "SDT",
    slug: "terra-sdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terra-sdt.png",
  },
  {
    name: "Winter",
    symbol: "WINTER",
    slug: "winter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winter.png",
  },
  {
    name: "Dogelana",
    symbol: "DLANA",
    slug: "dogelana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogelana.png",
  },
  {
    name: "MACH Project",
    symbol: "MACH",
    slug: "mach-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mach-project.png",
  },
  {
    name: "Sensei Shib",
    symbol: "SENSEI",
    slug: "sensei-shib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sensei-shib.png",
  },
  {
    name: "Doge Army Token",
    symbol: "DGAT",
    slug: "doge-army-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-army-token.png",
  },
  {
    name: "FIRST INTERCHANGEABLE TOKEN",
    symbol: "FIT",
    slug: "first-interchangeable-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/first-interchangeable-token.png",
  },
  {
    name: "Exohood",
    symbol: "EXO",
    slug: "exohood",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exohood.png",
  },
  {
    name: "CryptoLion",
    symbol: "CLION",
    slug: "cryptolion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptolion.png",
  },
  {
    name: "Boji",
    symbol: "BOJI",
    slug: "boji",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boji.png",
  },
  {
    name: "DragonBite",
    symbol: "$BITE",
    slug: "dragonbite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonbite.png",
  },
  {
    name: "Scholarship Coin",
    symbol: "SCHO",
    slug: "scholarship-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scholarship-coin.png",
  },
  {
    name: "Realital Metaverse",
    symbol: "RETA",
    slug: "realital-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realital-metaverse.png",
  },
  {
    name: "ERA",
    symbol: "ERA",
    slug: "era",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/era.png",
  },
  {
    name: "OEC SHIB",
    symbol: "SHIBK",
    slug: "oec-shib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oec-shib.png",
  },
  {
    name: "Skyrocketing",
    symbol: "SKYROCKETING",
    slug: "skyrocketing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skyrocketing.png",
  },
  {
    name: "Dextrust",
    symbol: "DETS",
    slug: "dextrust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dextrust.png",
  },
  {
    name: "ASSARA",
    symbol: "ASSA",
    slug: "assara",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/assara.png",
  },
  {
    name: "Newtonium",
    symbol: "NEWTON",
    slug: "newtonium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newtonium.png",
  },
  {
    name: "SphynxFi",
    symbol: "SF",
    slug: "sphynxfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sphynxfi.png",
  },
  {
    name: "Zeus Finance",
    symbol: "ZEUS",
    slug: "zeus-finance-org",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeus-finance-org.png",
  },
  {
    name: "Ultra Clear",
    symbol: "UCR",
    slug: "ultra-clear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultra-clear.png",
  },
  {
    name: "Airbnb tokenized stock FTX",
    symbol: "ABNB",
    slug: "airbnb-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/airbnb-tokenized-stock-ftx.png",
  },
  {
    name: "PayDex",
    symbol: "DPAY",
    slug: "paydex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paydex.png",
  },
  {
    name: "trees.finance",
    symbol: "GANJA",
    slug: "trees-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trees-finance.png",
  },
  {
    name: "BitCanna",
    symbol: "BCNA",
    slug: "bitcanna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcanna.png",
  },
  {
    name: "ICHELLO Music & Technology",
    symbol: "ELLO",
    slug: "ichello-music-technology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ichello-music-technology.png",
  },
  {
    name: "The Revolution Token",
    symbol: "TRT",
    slug: "the-revolution-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-revolution-token.png",
  },
  {
    name: "Narwhalswap",
    symbol: "NAR",
    slug: "narwhalswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/narwhalswap.png",
  },
  {
    name: "Duo",
    symbol: "DUO",
    slug: "duo-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duo-2.png",
  },
  {
    name: "MONNFTS",
    symbol: "MON",
    slug: "monnfts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monnfts.png",
  },
  {
    name: "VANCI FINANCE",
    symbol: "VANCII",
    slug: "vanci-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanci-finance.png",
  },
  {
    name: "Foresight",
    symbol: "FORS",
    slug: "foresight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foresight.png",
  },
  {
    name: "Charm",
    symbol: "CHARM",
    slug: "charm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charm.png",
  },
  {
    name: "KLend",
    symbol: "KLT",
    slug: "klend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klend.png",
  },
  {
    name: "Five Star Coin",
    symbol: "FSC",
    slug: "five-star-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/five-star-coin.png",
  },
  {
    name: "OnlyMemes",
    symbol: "OM",
    slug: "onlymemes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onlymemes.png",
  },
  {
    name: "HEADLINE",
    symbol: "HDL",
    slug: "headline-inc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/headline-inc.png",
  },
  {
    name: "Barter",
    symbol: "BRTR",
    slug: "barter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/barter.png",
  },
  {
    name: "FLOKI METAVERSE",
    symbol: "FLMT",
    slug: "floki-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-metaverse.png",
  },
  {
    name: "Xodex",
    symbol: "XODEX",
    slug: "xodex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xodex.png",
  },
  {
    name: "Libera",
    symbol: "LIB",
    slug: "libera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libera.png",
  },
  {
    name: "Island Inu",
    symbol: "ISLAINU",
    slug: "island-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/island-inu.png",
  },
  {
    name: "McDonalds Coin",
    symbol: "MCDC",
    slug: "mcdonalds-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcdonalds-coin.png",
  },
  {
    name: "Native Utility Token",
    symbol: "NUT",
    slug: "native-utility-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/native-utility-token.png",
  },
  {
    name: "Street Runner NFT",
    symbol: "SRG",
    slug: "street-runner-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/street-runner-nft.png",
  },
  {
    name: "DogeVillage",
    symbol: "DOGEV",
    slug: "dogevillage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogevillage.png",
  },
  {
    name: "Curio Governance",
    symbol: "CGT",
    slug: "curio-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/curio-governance.png",
  },
  {
    name: "Apollo Coin",
    symbol: "APX",
    slug: "apollo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apollo-coin.png",
  },
  {
    name: "Adadao",
    symbol: "ADAO",
    slug: "adadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adadao.png",
  },
  {
    name: "PancakeFork Finance",
    symbol: "FCKE",
    slug: "pancakefork-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pancakefork-finance.png",
  },
  {
    name: "Metawhale Gold",
    symbol: "MWG",
    slug: "metawhale-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metawhale-gold.png",
  },
  {
    name: "Waterfall Finance BSC",
    symbol: "WATERFALL",
    slug: "waterfall-finance-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waterfall-finance-bsc.png",
  },
  {
    name: "Dynasty Global Investments",
    symbol: "DYN",
    slug: "dynasty-global-investments",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dynasty-global-investments.png",
  },
  {
    name: "Rice Wallet",
    symbol: "RICE",
    slug: "rice-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rice-wallet.png",
  },
  {
    name: "Jetfuel Finance",
    symbol: "FUEL",
    slug: "jetfuel-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jetfuel-finance.png",
  },
  {
    name: "Shield Network",
    symbol: "SHIELDNET",
    slug: "shield-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shield-network.png",
  },
  {
    name: "SPIDEY FLOKI",
    symbol: "SPIDEYXMAS",
    slug: "spidey-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spidey-floki.png",
  },
  {
    name: "NAGA",
    symbol: "NGC",
    slug: "naga",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/naga.png",
  },
  {
    name: "VTube Token",
    symbol: "VTUBE",
    slug: "vtube-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vtube-token.png",
  },
  {
    name: "Lum Network",
    symbol: "LUM",
    slug: "lum-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lum-network.png",
  },
  {
    name: "Diamond Cash",
    symbol: "DCASH",
    slug: "diamond-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-cash.png",
  },
  {
    name: "Netflix tokenized stock FTX",
    symbol: "NFLX",
    slug: "netflix-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netflix-tokenized-stock-ftx.png",
  },
  {
    name: "SENPAI",
    symbol: "SENPAI",
    slug: "senpai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/senpai.png",
  },
  {
    name: "VIVAL",
    symbol: "VIV",
    slug: "vival",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vival.png",
  },
  {
    name: "Ethanol",
    symbol: "ENOL",
    slug: "ethanol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethanol.png",
  },
  {
    name: "Floki Musk",
    symbol: "FLOKI",
    slug: "floki-musk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-musk.png",
  },
  {
    name: "Church Dao",
    symbol: "CHURCH",
    slug: "church-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/church-dao.png",
  },
  {
    name: "Instinct",
    symbol: "INSTINCT",
    slug: "instinct",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/instinct.png",
  },
  {
    name: "APEcoin",
    symbol: "APE",
    slug: "apecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apecoin.png",
  },
  {
    name: "Multistarter",
    symbol: "MSTART",
    slug: "multistarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multistarter.png",
  },
  {
    name: "DefiDrop Launchpad",
    symbol: "DROPS",
    slug: "defidrop-launchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defidrop-launchpad.png",
  },
  {
    name: "Birthday Cake",
    symbol: "BDAY",
    slug: "birthday-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/birthday-cake.png",
  },
  {
    name: "Weboo Swap",
    symbol: "WEBOO",
    slug: "weboo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weboo-token.png",
  },
  {
    name: "Hero Inu",
    symbol: "HEROS",
    slug: "hero-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hero-inu.png",
  },
  {
    name: "Trendering",
    symbol: "TRND",
    slug: "trendering",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trendering.png",
  },
  {
    name: "sBTC",
    symbol: "sBTC",
    slug: "softbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/softbtc.png",
  },
  {
    name: "Pollen",
    symbol: "POLLEN",
    slug: "pollen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pollen.png",
  },
  {
    name: "CryptoKek",
    symbol: "KEK",
    slug: "cryptokek",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptokek.png",
  },
  {
    name: "HUSH",
    symbol: "HUSH",
    slug: "hush-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hush-network.png",
  },
  {
    name: "intexcoin",
    symbol: "INTX",
    slug: "intexcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intexcoin.png",
  },
  {
    name: "DogeDragon",
    symbol: "DD",
    slug: "dogedragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogedragon.png",
  },
  {
    name: "Meta Billionaires Club",
    symbol: "METABC",
    slug: "meta-billionaires-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-billionaires-club.png",
  },
  {
    name: "SPACE-iZ",
    symbol: "SPIZ",
    slug: "space-iz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-iz.png",
  },
  {
    name: "Battle Of Multiworld",
    symbol: "BMW",
    slug: "battle-of-multiworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-of-multiworld.png",
  },
  {
    name: "LUCY",
    symbol: "LUCY",
    slug: "lucy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucy.png",
  },
  {
    name: "MoveRich",
    symbol: "MVRC",
    slug: "moverich",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moverich.png",
  },
  {
    name: "Benzene",
    symbol: "BZN",
    slug: "benzene",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/benzene.png",
  },
  {
    name: "Sishi Finance",
    symbol: "SISHI",
    slug: "sishi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sishi-finance.png",
  },
  {
    name: "Safle",
    symbol: "SAFLE",
    slug: "safle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safle.png",
  },
  {
    name: "Bond Appetite USD",
    symbol: "USDAP",
    slug: "bond-appetite-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bond-appetite-usd.png",
  },
  {
    name: "Arteon",
    symbol: "ARTEON",
    slug: "arteon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arteon.png",
  },
  {
    name: "Habitat",
    symbol: "HBT",
    slug: "habitat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/habitat.png",
  },
  {
    name: "Solid Protocol",
    symbol: "SOLID",
    slug: "solid-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solid-protocol.png",
  },
  {
    name: "Atlo",
    symbol: "ATLO",
    slug: "atlo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlo.png",
  },
  {
    name: "SafeYield",
    symbol: "SAFEYIELD",
    slug: "safeyield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeyield.png",
  },
  {
    name: "Mean Tamato",
    symbol: "MEANTAMATO",
    slug: "mean-tamato",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mean-tamato.png",
  },
  {
    name: "BabyXape",
    symbol: "BABYX",
    slug: "babyxape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyxape.png",
  },
  {
    name: "Matic Aave Interest Bearing YFI",
    symbol: "MAYFI",
    slug: "matic-aave-yfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matic-aave-yfi.png",
  },
  {
    name: "Doge Alliance",
    symbol: "DOGEALLY",
    slug: "doge-alliance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-alliance.png",
  },
  {
    name: "GEMIT.app",
    symbol: "GEMIT",
    slug: "gemit-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gemit-app.png",
  },
  {
    name: "Shift",
    symbol: "SHIFT",
    slug: "shift",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shift.png",
  },
  {
    name: "Metanoom",
    symbol: "MTN",
    slug: "metanoom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metanoom.png",
  },
  {
    name: "Uniswap Finance",
    symbol: "UNFI",
    slug: "uniswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniswap-finance.png",
  },
  {
    name: "SpacePenguin",
    symbol: "PNGN",
    slug: "spacepenguin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacepenguin.png",
  },
  {
    name: "Yearnlab",
    symbol: "YLB",
    slug: "yearnlab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearnlab.png",
  },
  {
    name: "RaceX",
    symbol: "RACEX",
    slug: "racex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/racex.png",
  },
  {
    name: "Value Set Dollar",
    symbol: "VSD",
    slug: "value-set-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/value-set-dollar.png",
  },
  {
    name: "Rangers Fan Token",
    symbol: "RFT",
    slug: "rangers-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rangers-fan-token.png",
  },
  {
    name: "UniDollar",
    symbol: "UNIUSD",
    slug: "unidollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unidollar.png",
  },
  {
    name: "AppleSwap",
    symbol: "APPLE",
    slug: "appleswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/appleswap.png",
  },
  {
    name: "hodlearn",
    symbol: "HODL",
    slug: "hodlearn-net",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodlearn-net.png",
  },
  {
    name: "LIQUIDCHAIN",
    symbol: "XLC",
    slug: "liquidchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquidchain.png",
  },
  {
    name: "XUSD Stable",
    symbol: "XUSD",
    slug: "xusd-stable",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xusd-stable.png",
  },
  {
    name: "DeFi Kingdoms",
    symbol: "JEWEL",
    slug: "defi-kingdoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-kingdoms.png",
  },
  {
    name: "TWO TWO",
    symbol: "X22",
    slug: "two-two",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/two-two.png",
  },
  {
    name: "Social Rocket",
    symbol: "ROCKS",
    slug: "social-rocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/social-rocket.png",
  },
  {
    name: "Avenue Hamilton Token",
    symbol: "AHT",
    slug: "avenue-hamilton-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avenue-hamilton-token.png",
  },
  {
    name: "French Digital Reserve",
    symbol: "FDR",
    slug: "french-digital-reserve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/french-digital-reserve.png",
  },
  {
    name: "DeuxPad",
    symbol: "DEUX",
    slug: "deuxpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deuxpad.png",
  },
  {
    name: "Coinstox",
    symbol: "CSX",
    slug: "coinstox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinstox.png",
  },
  {
    name: "Taiwan Semiconductor Mfg tokenized stock FTX",
    symbol: "TSM",
    slug: "taiwan-semiconductor-mfg-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taiwan-semiconductor-mfg-tokenized-stock-ftx.png",
  },
  {
    name: "Hub - Human Trust Protocol",
    symbol: "HUB",
    slug: "hub-human-trust-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hub-human-trust-protocol.png",
  },
  {
    name: "EzyStayz",
    symbol: "EZY",
    slug: "ezystayz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ezystayz.png",
  },
  {
    name: "Biscuit Farm Finance",
    symbol: "BCU",
    slug: "biscuit-farm-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biscuit-farm-finance.png",
  },
  {
    name: "RHP",
    symbol: "XRHP",
    slug: "rhp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rhp.png",
  },
  {
    name: "Yeager Inu",
    symbol: "YEAGER",
    slug: "yeager-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yeager-inu.png",
  },
  {
    name: "Nodestats",
    symbol: "NS",
    slug: "nodestats",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nodestats.png",
  },
  {
    name: "Gbox",
    symbol: "GBOX",
    slug: "gbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gbox.png",
  },
  {
    name: "SocialX",
    symbol: "SOSX",
    slug: "socialx-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/socialx-2.png",
  },
  {
    name: "Carillonium finance",
    symbol: "CAROM",
    slug: "carillonium-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carillonium-finance.png",
  },
  {
    name: "TTX METAVERSE",
    symbol: "XMETA",
    slug: "ttx-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ttx-games.png",
  },
  {
    name: "Fedora Gold",
    symbol: "FED",
    slug: "fedora-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fedora-gold.png",
  },
  {
    name: "Thoreum V2",
    symbol: "THOREUM",
    slug: "thoreum-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thoreum-v2.png",
  },
  {
    name: "Athena Money",
    symbol: "ATH",
    slug: "athena-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/athena-money.png",
  },
  {
    name: "Pupper",
    symbol: "PUP",
    slug: "pupper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pupper.png",
  },
  {
    name: "Shiba Hex Pulse",
    symbol: "SEXPLS",
    slug: "shiba-hex-pulse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-hex-pulse.png",
  },
  {
    name: "GrimaceCoinErc.com",
    symbol: "GRIM",
    slug: "grimacecoin-erc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grimacecoin-erc.png",
  },
  {
    name: "Wault Finance (OLD)",
    symbol: "WAULT",
    slug: "wault-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wault-finance.png",
  },
  {
    name: "Sea Token",
    symbol: "SEA",
    slug: "seatoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seatoken.png",
  },
  {
    name: "NXG COIN",
    symbol: "NXG",
    slug: "nxg-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nxg-coin.png",
  },
  {
    name: "GhostBlade Inu",
    symbol: "GHOSTBLADE",
    slug: "ghostblade-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghostblade-inu.png",
  },
  {
    name: "Buxcoin",
    symbol: "BUX",
    slug: "buxcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buxcoin.png",
  },
  {
    name: "LEAP Token",
    symbol: "LEAP",
    slug: "leap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leap-token.png",
  },
  {
    name: "Metacoin",
    symbol: "MTC",
    slug: "metacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metacoin.png",
  },
  {
    name: "Kirobo",
    symbol: "KIRO",
    slug: "kirobo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kirobo.png",
  },
  {
    name: "ZOD RUNE - Rune.Game",
    symbol: "ZOD",
    slug: "zod-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zod-rune---rune-game.png",
  },
  {
    name: "FIFADOGE",
    symbol: "FDOGE",
    slug: "fifadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fifadoge.png",
  },
  {
    name: "Pinkie Inu",
    symbol: "PINKIE",
    slug: "pinkie-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pinkie-inu.png",
  },
  {
    name: "Strip Finance",
    symbol: "STRIP",
    slug: "strip-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strip-finance.png",
  },
  {
    name: "Lenda",
    symbol: "LENDA",
    slug: "lenda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lenda.png",
  },
  {
    name: "Dohrnii",
    symbol: "DHN",
    slug: "dohrnii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dohrnii.png",
  },
  {
    name: "Pofi",
    symbol: "POFI",
    slug: "pofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pofi.png",
  },
  {
    name: "Safemoon Zilla",
    symbol: "SFZ",
    slug: "safemoon-zilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemoon-zilla.png",
  },
  {
    name: "Mover",
    symbol: "MOVE",
    slug: "mover",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mover.png",
  },
  {
    name: "MAIN",
    symbol: "MAIN",
    slug: "main-community",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/main-community.png",
  },
  {
    name: "Gremlins Finance",
    symbol: "GREM",
    slug: "gremlins-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gremlins-finance.png",
  },
  {
    name: "Google tokenized stock Bittrex",
    symbol: "GOOGL",
    slug: "google-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/google-tokenized-stock-bittrex.png",
  },
  {
    name: "GrowingFi",
    symbol: "GROW",
    slug: "growingfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/growingfi.png",
  },
  {
    name: "Autobusd",
    symbol: "ABS",
    slug: "autobusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/autobusd.png",
  },
  {
    name: "UniCAKE",
    symbol: "UCT",
    slug: "unicake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicake.png",
  },
  {
    name: "HELLMOON",
    symbol: "HMOON",
    slug: "hellmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hellmoon.png",
  },
  {
    name: "Official Crypto Cowboy Token",
    symbol: "OCCT",
    slug: "official-crypto-cowboy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/official-crypto-cowboy-token.png",
  },
  {
    name: "Nobelium",
    symbol: "NOBEL",
    slug: "nobelium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nobelium.png",
  },
  {
    name: "Index Chain",
    symbol: "IDX",
    slug: "index-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/index-chain.png",
  },
  {
    name: "Prize Coin",
    symbol: "PRZ",
    slug: "prize-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prize-coin.png",
  },
  {
    name: "Lunar Highway",
    symbol: "LUNAR",
    slug: "lunar-highway",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunar-highway.png",
  },
  {
    name: "LaunchWall",
    symbol: "WALL",
    slug: "launchwall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/launchwall.png",
  },
  {
    name: "YU-GI-KING",
    symbol: "YUGI",
    slug: "yu-gi-king",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yu-gi-king.png",
  },
  {
    name: "ALBEDO",
    symbol: "ALBEDO",
    slug: "albedo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/albedo.png",
  },
  {
    name: "Monster Slayer Share",
    symbol: "MSS",
    slug: "monster-slayer-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-slayer-share.png",
  },
  {
    name: "CSR",
    symbol: "CSR",
    slug: "csr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/csr.png",
  },
  {
    name: "Mad Viking Games",
    symbol: "MVG",
    slug: "mad-viking-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mad-viking-games.png",
  },
  {
    name: "Baby Meta",
    symbol: "BABYMETA",
    slug: "baby-meta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-meta.png",
  },
  {
    name: "Bulk",
    symbol: "BULK",
    slug: "bulk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulk.png",
  },
  {
    name: "MUNI",
    symbol: "MUNI",
    slug: "muni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muni.png",
  },
  {
    name: "Hybrid Bank Cash",
    symbol: "HBC",
    slug: "hybrid-bank-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hybrid-bank-cash.png",
  },
  {
    name: "DarkMagick",
    symbol: "DMGK",
    slug: "darkmagick",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darkmagick.png",
  },
  {
    name: "Star Park",
    symbol: "STARP",
    slug: "star-park",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/star-park.png",
  },
  {
    name: "WePiggy Coin",
    symbol: "WPC",
    slug: "wepiggy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wepiggy.png",
  },
  {
    name: "Oracle Top 5 Tokens Index",
    symbol: "ORCL5",
    slug: "oracle-top-5",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oracle-top-5.png",
  },
  {
    name: "Infinity Rocket Token",
    symbol: "IRT",
    slug: "infinity-rocket-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-rocket-token.png",
  },
  {
    name: "SuperBonds",
    symbol: "SB",
    slug: "superbonds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superbonds.png",
  },
  {
    name: "Uncharted",
    symbol: "UNC",
    slug: "uncharted",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uncharted.png",
  },
  {
    name: "Ecoin",
    symbol: "ECOIN",
    slug: "ecoin-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecoin-2.png",
  },
  {
    name: "100 Days Ventures",
    symbol: "ASTRO",
    slug: "100-days-ventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/100-days-ventures.png",
  },
  {
    name: "Nio tokenized stock FTX",
    symbol: "NIO",
    slug: "nio-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nio-tokenized-stock-ftx.png",
  },
  {
    name: "SafeMoon-AVAX",
    symbol: "$SAFEMOONA",
    slug: "safemoon-avax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemoon-avax.png",
  },
  {
    name: "Maya Preferred",
    symbol: "MAYP",
    slug: "maya-preferred",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maya-preferred.png",
  },
  {
    name: "Solar Bear",
    symbol: "SOLBEAR",
    slug: "solar-bear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solar-bear.png",
  },
  {
    name: "Digital Swiss Franc",
    symbol: "DSFR",
    slug: "digital-swis-franc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-swis-franc.png",
  },
  {
    name: "Marsupilamii",
    symbol: "MARS",
    slug: "marsupilamii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marsupilamii.png",
  },
  {
    name: "ECOChain",
    symbol: "ECOC",
    slug: "ecochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecochain.png",
  },
  {
    name: "Flinch Token",
    symbol: "FLN",
    slug: "flinch-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flinch-token.png",
  },
  {
    name: "PayYoda",
    symbol: "YOT",
    slug: "payyoda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payyoda.png",
  },
  {
    name: "JPool Staked SOL (JSOL)",
    symbol: "JSOL",
    slug: "jpool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jpool.png",
  },
  {
    name: "Yuang Coin",
    symbol: "YUANG",
    slug: "yuang-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yuang-coin.png",
  },
  {
    name: "Tune.FM",
    symbol: "JAM",
    slug: "tune-fm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tune-fm.png",
  },
  {
    name: "Centcex",
    symbol: "CENX",
    slug: "centcex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centcex.png",
  },
  {
    name: "PlayNity",
    symbol: "PLY",
    slug: "playnity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playnity.png",
  },
  {
    name: "MysticBets",
    symbol: "MBT",
    slug: "mysticbets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mysticbets.png",
  },
  {
    name: "Superpanda",
    symbol: "SPANDA",
    slug: "superpanda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superpanda.png",
  },
  {
    name: "Polychart",
    symbol: "PCHART",
    slug: "polychart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polychart.png",
  },
  {
    name: "Astronaut (Polygon)",
    symbol: "pNAUT",
    slug: "astronaut-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astronaut-polygon.png",
  },
  {
    name: "Cairo Finance",
    symbol: "CAIRO",
    slug: "cairo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cairo-finance.png",
  },
  {
    name: "USD Bancor",
    symbol: "USDB",
    slug: "usd-bancor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usd-bancor.png",
  },
  {
    name: "Vegasino",
    symbol: "VEGAS",
    slug: "vegasino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vegasino.png",
  },
  {
    name: "SaluS",
    symbol: "SLS",
    slug: "salus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/salus.png",
  },
  {
    name: "Irena Green Energy",
    symbol: "IRENA",
    slug: "irena-green-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/irena-green-energy.png",
  },
  {
    name: "XFUEL",
    symbol: "XFUEL",
    slug: "xfuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xfuel.png",
  },
  {
    name: "Revolutin",
    symbol: "REV",
    slug: "revolutin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revolutin.png",
  },
  {
    name: "Neuron Chain",
    symbol: "NEURON",
    slug: "neuron-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neuron-chain.png",
  },
  {
    name: "Joke Community",
    symbol: "$JOKE",
    slug: "joke-community",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joke-community.png",
  },
  {
    name: "Ubiner",
    symbol: "UBIN",
    slug: "ubiner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubiner.png",
  },
  {
    name: "Chord Protocol",
    symbol: "CHORD",
    slug: "chord-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chord-protocol.png",
  },
  {
    name: "Qatar Inu Token",
    symbol: "QATAR",
    slug: "qatar-inu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qatar-inu-token.png",
  },
  {
    name: "MMAON",
    symbol: "MMAON",
    slug: "mmaon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mmaon.png",
  },
  {
    name: "Buff Floki Coin",
    symbol: "BUFLOKI",
    slug: "buff-floki-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buff-floki-coin.png",
  },
  {
    name: "Wrapped Elastos",
    symbol: "WELA",
    slug: "wrapped-elastos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-elastos.png",
  },
  {
    name: "Sperax USD",
    symbol: "USDS",
    slug: "sperax-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sperax-usd.png",
  },
  {
    name: "Vanywhere",
    symbol: "VANY",
    slug: "vanywhere",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanywhere.png",
  },
  {
    name: "Lightbeam Courier Coin",
    symbol: "LBCC",
    slug: "lightbeam-courier-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lightbeam-courier-coin.png",
  },
  {
    name: "Dash Diamond",
    symbol: "DASHD",
    slug: "dash-diamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dash-diamond.png",
  },
  {
    name: "Crogecoin",
    symbol: "CROGE",
    slug: "crogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crogecoin.png",
  },
  {
    name: "Whole Earth Coin",
    symbol: "WEC",
    slug: "whole-earth-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whole-earth-coin.png",
  },
  {
    name: "Fragmint",
    symbol: "FRAG",
    slug: "fragmint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fragmint.png",
  },
  {
    name: "PIST TRUST",
    symbol: "PIST",
    slug: "pist-trust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pist-trust.png",
  },
  {
    name: "MONNOS",
    symbol: "MNS",
    slug: "monnos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monnos.png",
  },
  {
    name: "Baby Lil Floki",
    symbol: "BABYLILFLOKI",
    slug: "baby-lil-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-lil-floki.png",
  },
  {
    name: "MacaronSwap",
    symbol: "MCRN",
    slug: "macaronswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/macaronswap.png",
  },
  {
    name: "PoorDoge",
    symbol: "POORDOGE",
    slug: "poordoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poordoge.png",
  },
  {
    name: "Mirror Farm",
    symbol: "MOR",
    slug: "mirror-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirror-farm.png",
  },
  {
    name: "Aura Network",
    symbol: "AURA",
    slug: "aura-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aura-network.png",
  },
  {
    name: "Zuplo",
    symbol: "ZLP",
    slug: "zuplo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zuplo.png",
  },
  {
    name: "dART Insurance",
    symbol: "dART",
    slug: "dart-insurance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dart-insurance.png",
  },
  {
    name: "Gabx Finance",
    symbol: "GABX",
    slug: "gabx-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gabx-finance.png",
  },
  {
    name: "Kalata USD",
    symbol: "KUSD",
    slug: "kalata-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalata-usd.png",
  },
  {
    name: "GuitarSwap",
    symbol: "GUT",
    slug: "guitarswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guitarswap.png",
  },
  {
    name: "AICON",
    symbol: "AICO",
    slug: "aicon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aicon.png",
  },
  {
    name: "Ctomorrow Platform",
    symbol: "CTP",
    slug: "ctomorrow-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ctomorrow-platform.png",
  },
  {
    name: "Wicrypt",
    symbol: "WNT",
    slug: "wicrypt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wicrypt.png",
  },
  {
    name: "MIMOSA",
    symbol: "MIMO",
    slug: "mimosa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mimosa.png",
  },
  {
    name: "Shack Token",
    symbol: "SHACK",
    slug: "shack-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shack-token.png",
  },
  {
    name: "Graviton",
    symbol: "GRAV",
    slug: "grav",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grav.png",
  },
  {
    name: "Yearn Finance DOT",
    symbol: "YFDOT",
    slug: "yearn-finance-dot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance-dot.png",
  },
  {
    name: "Mound Token",
    symbol: "MND",
    slug: "mound-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mound-token.png",
  },
  {
    name: "War of Tribes",
    symbol: "WOTG",
    slug: "war-of-tribes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/war-of-tribes.png",
  },
  {
    name: "CyberTime Finance Token",
    symbol: "CTF",
    slug: "cybertime-finance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cybertime-finance-token.png",
  },
  {
    name: "SouloCoin",
    symbol: "SOULO",
    slug: "soulocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soulocoin.png",
  },
  {
    name: "GYA",
    symbol: "GYA",
    slug: "gya",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gya.png",
  },
  {
    name: "Mushu Finance",
    symbol: "MUSHU",
    slug: "mushu-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mushu-finance.png",
  },
  {
    name: "SILVER (SVS)",
    symbol: "SVS",
    slug: "silver-gateway",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silver-gateway.png",
  },
  {
    name: "MoonDAO",
    symbol: "MNDAO",
    slug: "moondao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moondao.png",
  },
  {
    name: "Tsar Network",
    symbol: "TSAR",
    slug: "tsar-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tsar-network.png",
  },
  {
    name: "Aureus Nummus Gold",
    symbol: "ANG",
    slug: "aureus-nummus-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aureus-nummus-gold.png",
  },
  {
    name: "Kusunoki Samurai",
    symbol: "KUSUNOKI",
    slug: "kusunoki-samurai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kusunoki-samurai.png",
  },
  {
    name: "SHD CASH",
    symbol: "SHDC",
    slug: "shd-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shd-cash.png",
  },
  {
    name: "Bitindi Chain",
    symbol: "BNI",
    slug: "bitindi-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitindi-chain.png",
  },
  {
    name: "SMARTPAD",
    symbol: "PAD",
    slug: "smartpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartpad.png",
  },
  {
    name: "Polly Finance",
    symbol: "POLLY",
    slug: "polly-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polly-finance.png",
  },
  {
    name: "Kings Coin",
    symbol: "KINGS",
    slug: "kings-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kings-coin.png",
  },
  {
    name: "HyperOne",
    symbol: "HOT",
    slug: "hyperone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperone.png",
  },
  {
    name: "Bonus Floki",
    symbol: "BonusFloki",
    slug: "bonus-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonus-floki.png",
  },
  {
    name: "BabyAvengers",
    symbol: "AVNGRS",
    slug: "babyavengers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyavengers.png",
  },
  {
    name: "Guapcoin",
    symbol: "GUAP",
    slug: "guapcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guapcoin.png",
  },
  {
    name: "g9tro Crowdfunding Platform",
    symbol: "G9TRO",
    slug: "g9tro-crowdfunding-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/g9tro-crowdfunding-platform.png",
  },
  {
    name: "VXXL",
    symbol: "VXXL",
    slug: "vxxl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vxxl.png",
  },
  {
    name: "Anime Token",
    symbol: "ANI",
    slug: "anime-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anime-token.png",
  },
  {
    name: "Baby Tiger King",
    symbol: "BABYTK",
    slug: "baby-tiger-king",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-tiger-king.png",
  },
  {
    name: "ODEM",
    symbol: "ODE",
    slug: "odem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/odem.png",
  },
  {
    name: "PolyGamma Finance",
    symbol: "GAMMA",
    slug: "polygamma-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polygamma-finance.png",
  },
  {
    name: "Mixsome",
    symbol: "SOME",
    slug: "mixsome",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mixsome.png",
  },
  {
    name: "Squid Game 2.0",
    symbol: "SQUID",
    slug: "squid-game-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squid-game-2.png",
  },
  {
    name: "SuperStep",
    symbol: "SGMT",
    slug: "superstep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superstep.png",
  },
  {
    name: "BQT",
    symbol: "BQTX",
    slug: "bqt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bqt.png",
  },
  {
    name: "DeVault",
    symbol: "DVT",
    slug: "devault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/devault.png",
  },
  {
    name: "DeFlyBall",
    symbol: "DEFLY",
    slug: "deflyball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deflyball.png",
  },
  {
    name: "MemeWars",
    symbol: "MWAR",
    slug: "memewars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memewars.png",
  },
  {
    name: "DeFinity",
    symbol: "DEFX",
    slug: "definity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/definity.png",
  },
  {
    name: "LaEeb",
    symbol: "LAEEB",
    slug: "laeeb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laeeb.png",
  },
  {
    name: "PolyAlpha Finance",
    symbol: "ALPHA",
    slug: "polyalpha-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyalpha-finance.png",
  },
  {
    name: "Helios Charts",
    symbol: "$SOL",
    slug: "helios-charts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helios-charts.png",
  },
  {
    name: "CleanOcean",
    symbol: "CLEANOCEAN",
    slug: "cleanocean",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cleanocean.png",
  },
  {
    name: "PASV",
    symbol: "PASV",
    slug: "pasv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pasv.png",
  },
  {
    name: "Caketools",
    symbol: "CKT",
    slug: "caketools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caketools.png",
  },
  {
    name: "Rise Of Empire",
    symbol: "ROEMP",
    slug: "rise-of-empire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rise-of-empire.png",
  },
  {
    name: "Gasgains",
    symbol: "GASG",
    slug: "gasgains",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gasgains.png",
  },
  {
    name: "Holiday Token",
    symbol: "HOL",
    slug: "holiday-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holiday-token.png",
  },
  {
    name: "Finminity",
    symbol: "FMT",
    slug: "finminity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/finminity.png",
  },
  {
    name: "Litedex",
    symbol: "LDX",
    slug: "litedex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litedex.png",
  },
  {
    name: "Metti Inu",
    symbol: "METTI",
    slug: "metti-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metti-inu.png",
  },
  {
    name: "Gamers Yield",
    symbol: "$GY",
    slug: "gamers-yield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamers-yield.png",
  },
  {
    name: "SatoShi Monsters",
    symbol: "SSM",
    slug: "satoshi-monsters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoshi-monsters.png",
  },
  {
    name: "Meta Shiba",
    symbol: "MSHIBA",
    slug: "meta-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-shiba.png",
  },
  {
    name: "Absorber Protocol",
    symbol: "ABS",
    slug: "absorber-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/absorber-protocol.png",
  },
  {
    name: "Maximus BASE",
    symbol: "BASE",
    slug: "maximus-base",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maximus-base.png",
  },
  {
    name: "Sad Cat Token",
    symbol: "SCAT",
    slug: "sad-cat-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sad-cat-token.png",
  },
  {
    name: "nSights DeFi Trader",
    symbol: "NSI",
    slug: "nsights-defi-trader",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nsights-defi-trader.png",
  },
  {
    name: "Plunge",
    symbol: "PLG",
    slug: "plunge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plunge.png",
  },
  {
    name: "UKA DOGE COIN",
    symbol: "UDOGE",
    slug: "uka-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uka-doge-coin.png",
  },
  {
    name: "PrimeFinance",
    symbol: "PFI",
    slug: "primefinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/primefinance.png",
  },
  {
    name: "Summit Koda Token",
    symbol: "KODA",
    slug: "summit-koda-token-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/summit-koda-token-new.png",
  },
  {
    name: "Santa Floki v2.0",
    symbol: "HOHOHO V2.0",
    slug: "santa-floki-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/santa-floki-v2.png",
  },
  {
    name: "Sweetpad",
    symbol: "SWT",
    slug: "sweetpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sweetpad.png",
  },
  {
    name: "The LoveChain",
    symbol: "LOV",
    slug: "the-lovechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-lovechain.png",
  },
  {
    name: "Asuka Inu",
    symbol: "ASUKA",
    slug: "asuka-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asuka-inu.png",
  },
  {
    name: "BitcoinV",
    symbol: "BTCV",
    slug: "bitcoinv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinv.png",
  },
  {
    name: "Catjam",
    symbol: "CATJAM",
    slug: "catjam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catjam.png",
  },
  {
    name: "Bitalgo",
    symbol: "ALG",
    slug: "bitalgo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitalgo.png",
  },
  {
    name: "Transhuman Coin",
    symbol: "THC",
    slug: "transhuman-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/transhuman-coin.png",
  },
  {
    name: "Parallel Protocol",
    symbol: "MIMO",
    slug: "parallel-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parallel-protocol.png",
  },
  {
    name: "CRYPTO PHOENIX",
    symbol: "CPHX",
    slug: "crypto-phoenix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-phoenix.png",
  },
  {
    name: "Doge X",
    symbol: "DOGEX",
    slug: "doge-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-x.png",
  },
  {
    name: "yVault LP-yCurve",
    symbol: "yVault LP-yCurve(YYCRV)",
    slug: "yvault-lp-ycurve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yvault-lp-ycurve.png",
  },
  {
    name: "NDB",
    symbol: "NDB",
    slug: "ndb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ndb.png",
  },
  {
    name: "Bird Finance(HECO)",
    symbol: "BIRD",
    slug: "bird-finance-heco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bird-finance-heco.png",
  },
  {
    name: "MetaKombat",
    symbol: "KOMBAT",
    slug: "metakombat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metakombat.png",
  },
  {
    name: "Bullieverse",
    symbol: "$BULL",
    slug: "bullieverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullieverse.png",
  },
  {
    name: "SOWL Token",
    symbol: "SOWL",
    slug: "sowl-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sowl-token.png",
  },
  {
    name: "DiamondQ",
    symbol: "DIQ",
    slug: "diamondq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamondq.png",
  },
  {
    name: "Legend of RPS",
    symbol: "LRPS",
    slug: "legend-of-rps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legend-of-rps.png",
  },
  {
    name: "Blizzard.money",
    symbol: "xBLZD",
    slug: "blizzard-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blizzard-money.png",
  },
  {
    name: "Stable ICHI",
    symbol: "ONEICHI",
    slug: "stable-ichi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stable-ichi.png",
  },
  {
    name: "Sgt.SHIB",
    symbol: "SGTS",
    slug: "sgt-shib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sgt-shib.png",
  },
  {
    name: "AlgoVest",
    symbol: "AVS",
    slug: "algovest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algovest.png",
  },
  {
    name: "Island Girl",
    symbol: "IGIRL",
    slug: "island-girl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/island-girl.png",
  },
  {
    name: "BuffSwap",
    symbol: "BUFFS",
    slug: "buffswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buffswap.png",
  },
  {
    name: "LuckyDragon",
    symbol: "LUCKY",
    slug: "luckydragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luckydragon.png",
  },
  {
    name: "BossDao",
    symbol: "BOSS",
    slug: "bossdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bossdao.png",
  },
  {
    name: "XBN Community Token",
    symbol: "XBC",
    slug: "xbn-community-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xbn-community-token.png",
  },
  {
    name: "Coolmining cooha",
    symbol: "COOHA",
    slug: "coolmining-cooha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coolmining-cooha.png",
  },
  {
    name: "Lykke",
    symbol: "LKK",
    slug: "lykke",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lykke.png",
  },
  {
    name: "Candela Coin",
    symbol: "CLA",
    slug: "candelacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/candelacoin.png",
  },
  {
    name: "MommyETHEREUM",
    symbol: "MMETH",
    slug: "mommyethereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mommyethereum.png",
  },
  {
    name: "Mars Doge",
    symbol: "MARSDOGE",
    slug: "mars-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mars-doge.png",
  },
  {
    name: "CoffeeSwap",
    symbol: "COFE",
    slug: "coffeeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coffeeswap.png",
  },
  {
    name: "PolyQuity",
    symbol: "PYQ",
    slug: "polyquity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyquity.png",
  },
  {
    name: "Galaxy Doge",
    symbol: "GALAXYDOGE",
    slug: "galaxy-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-doge.png",
  },
  {
    name: "LandOrc",
    symbol: "LORC",
    slug: "landorc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/landorc.png",
  },
  {
    name: "Koloop Basic",
    symbol: "KPC",
    slug: "koloop-basic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koloop-basic.png",
  },
  {
    name: "The Lab Finance",
    symbol: "LABO",
    slug: "the-lab-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-lab-finance.png",
  },
  {
    name: "Dirty Finance",
    symbol: "DIRTY",
    slug: "dirty-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dirty-finance.png",
  },
  {
    name: "Polkatrain",
    symbol: "POLT",
    slug: "polkatrain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkatrain.png",
  },
  {
    name: "LITTLE RABBIT",
    symbol: "LTRBT",
    slug: "little-rabbit-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/little-rabbit-v2.png",
  },
  {
    name: "Safeicarus",
    symbol: "SAFEICARUS",
    slug: "safeicarus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeicarus.png",
  },
  {
    name: "Recharge",
    symbol: "RCG",
    slug: "recharge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/recharge.png",
  },
  {
    name: "Good Dog",
    symbol: "HEEL",
    slug: "good-dog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/good-dog.png",
  },
  {
    name: "TILWIKI",
    symbol: "TLW",
    slug: "tilwiki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tilwiki.png",
  },
  {
    name: "MagickDAO",
    symbol: "MAGICK",
    slug: "magickdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magickdao.png",
  },
  {
    name: "BUMO",
    symbol: "BU",
    slug: "bumo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bumo.png",
  },
  {
    name: "Empow",
    symbol: "EM",
    slug: "empow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/empow.png",
  },
  {
    name: "GIVE Token",
    symbol: "GIVE",
    slug: "give-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/give-token.png",
  },
  {
    name: "Collie Inu",
    symbol: "COLLIE",
    slug: "collie-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/collie-inu.png",
  },
  {
    name: "3X Long Tether Gold Token",
    symbol: "XAUTBULL",
    slug: "3x-long-tether-gold-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-tether-gold-token.png",
  },
  {
    name: "BuckHathCoin",
    symbol: "BHIG",
    slug: "buck-hath-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buck-hath-coin.png",
  },
  {
    name: "God Shiba Token",
    symbol: "GSHIB",
    slug: "god-shiba-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/god-shiba-token.png",
  },
  {
    name: "KLAYMETA",
    symbol: "META",
    slug: "klaymeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klaymeta.png",
  },
  {
    name: "Alibaba tokenized stock Bittrex",
    symbol: "BABA",
    slug: "alibaba-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alibaba-tokenized-stock-bittrex.png",
  },
  {
    name: "Slime Finance",
    symbol: "SLME",
    slug: "slime-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slime-finance.png",
  },
  {
    name: "Golden Ratio Per Liquidity",
    symbol: "GRPL",
    slug: "goldenratioperliquidity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldenratioperliquidity.png",
  },
  {
    name: "AMDG Token",
    symbol: "AMDG",
    slug: "amdg-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amdg-token.png",
  },
  {
    name: "Farms of Ryoshi",
    symbol: "NONI",
    slug: "farms-of-ryoshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farms-of-ryoshi.png",
  },
  {
    name: "LuckTogether",
    symbol: "LUCK",
    slug: "lucktogether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucktogether.png",
  },
  {
    name: "MODA DAO",
    symbol: "MODA",
    slug: "moda-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moda-dao.png",
  },
  {
    name: "Davion",
    symbol: "DAVP",
    slug: "davion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/davion.png",
  },
  {
    name: "BlockStream Mining Notes",
    symbol: "BMN",
    slug: "blockstream-mining-notes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockstream-mining-notes.png",
  },
  {
    name: "Scrap",
    symbol: "SCRAP",
    slug: "scrap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scrap.png",
  },
  {
    name: "Teh Golden One",
    symbol: "GOLD 1",
    slug: "teh-golden-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teh-golden-one.png",
  },
  {
    name: "Crex Token",
    symbol: "CREX",
    slug: "crex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crex-token.png",
  },
  {
    name: "Sator",
    symbol: "SAO",
    slug: "sator",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sator.png",
  },
  {
    name: "A2A",
    symbol: "A2A",
    slug: "a2a-50x-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/a2a-50x-com.png",
  },
  {
    name: "VRES",
    symbol: "VRS",
    slug: "vres",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vres.png",
  },
  {
    name: "Hypersonic Finance",
    symbol: "HYPERSONIC",
    slug: "hypersonic-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hypersonic-finance.png",
  },
  {
    name: "Tartarus Finance",
    symbol: "TART",
    slug: "tartarus-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tartarus-finance.png",
  },
  {
    name: "EVE Token",
    symbol: "EVE",
    slug: "eve-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eve-exchange.png",
  },
  {
    name: "Mensa",
    symbol: "MSA",
    slug: "mensa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mensa.png",
  },
  {
    name: "BlowFish",
    symbol: "BLOWF",
    slug: "blowfish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blowfish.png",
  },
  {
    name: "Universal Floki Coin",
    symbol: "UFLOKI",
    slug: "universal-floki-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universal-floki-coin.png",
  },
  {
    name: "EQO",
    symbol: "EQO",
    slug: "equos-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/equos-io.png",
  },
  {
    name: "METAVERSE INDEX",
    symbol: "METAI",
    slug: "metaverse-index-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-index-token.png",
  },
  {
    name: "INME SWAP",
    symbol: "INMES",
    slug: "inme-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inme-swap.png",
  },
  {
    name: "PeerEx",
    symbol: "PERX",
    slug: "peerex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peerex.png",
  },
  {
    name: "DEFIAI",
    symbol: "DFAI",
    slug: "defiai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defiai.png",
  },
  {
    name: "Keysians Network",
    symbol: "KEN",
    slug: "keysians-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keysians-network.png",
  },
  {
    name: "Blanc",
    symbol: "BLANC",
    slug: "blanc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blanc.png",
  },
  {
    name: "Capitol",
    symbol: "CPTL",
    slug: "capitol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/capitol.png",
  },
  {
    name: "Chain Colosseum",
    symbol: "COLOS",
    slug: "chain-colosseum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain-colosseum.png",
  },
  {
    name: "Doggy Swap",
    symbol: "DOGS",
    slug: "doggy-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doggy-swap.png",
  },
  {
    name: "LeverageInu",
    symbol: "LEVI",
    slug: "leverageinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leverageinu.png",
  },
  {
    name: "Era7: Game of Truth",
    symbol: "GOT",
    slug: "era7-game-of-truth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/era7-game-of-truth.png",
  },
  {
    name: "ZenSports",
    symbol: "SPORTS",
    slug: "zensports",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zensports.png",
  },
  {
    name: "Mirrored Galaxy Digital Holdings Ltd",
    symbol: "MGLXY",
    slug: "mirrored-galaxy-digital-holdings-ltd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-galaxy-digital-holdings-ltd.png",
  },
  {
    name: "pETH18C",
    symbol: "pETH18C",
    slug: "peth18c",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peth18c.png",
  },
  {
    name: "Jur",
    symbol: "JUR",
    slug: "jur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jur.png",
  },
  {
    name: "EAC",
    symbol: "EAC",
    slug: "eac",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eac.png",
  },
  {
    name: "Artificial Intelligence",
    symbol: "AI",
    slug: "artificial-intelligence",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artificial-intelligence.png",
  },
  {
    name: "EDGE Activity Token",
    symbol: "EAT",
    slug: "edge-activity-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edge-activity-token.png",
  },
  {
    name: "EverChain",
    symbol: "EC",
    slug: "everchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everchain.png",
  },
  {
    name: "Arty's World",
    symbol: "ARTY",
    slug: "artys-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artys-world.png",
  },
  {
    name: "Metasphere",
    symbol: "MTSP",
    slug: "metasphere",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metasphere.png",
  },
  {
    name: "FLAMA",
    symbol: "FMA",
    slug: "flama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flama.png",
  },
  {
    name: "Square tokenized stock FTX",
    symbol: "SQ",
    slug: "square-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/square-tokenized-stock-ftx.png",
  },
  {
    name: "Wolf Safe Poor People(Polygon)",
    symbol: "WSPP",
    slug: "wolf-safe-poor-people-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolf-safe-poor-people-polygon.png",
  },
  {
    name: "Vlad Finance",
    symbol: "VLAD",
    slug: "vlad-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vlad-finance.png",
  },
  {
    name: "Fight 4 Hope",
    symbol: "F4H",
    slug: "fight-for-hope",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fight-for-hope.png",
  },
  {
    name: "Tycoon Zone",
    symbol: "TYCOON",
    slug: "tycoon-zone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tycoon-zone.png",
  },
  {
    name: "Aptoge",
    symbol: "APTOGE",
    slug: "aptoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aptoge.png",
  },
  {
    name: "PulseMoon",
    symbol: "PULSEMOON",
    slug: "pulsemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pulsemoon.png",
  },
  {
    name: "Blur Finance",
    symbol: "BLR",
    slug: "blur-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blur-finance.png",
  },
  {
    name: "Defi",
    symbol: "DEFI",
    slug: "defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi.png",
  },
  {
    name: "MORK",
    symbol: "MORK",
    slug: "mork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mork.png",
  },
  {
    name: "onXRP",
    symbol: "OXP",
    slug: "onxrp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onxrp.png",
  },
  {
    name: "Ystar",
    symbol: "YSR",
    slug: "ystar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ystar.png",
  },
  {
    name: "Dog Boss",
    symbol: "DOGBOSS",
    slug: "dog-boss",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dog-boss.png",
  },
  {
    name: "Magicland",
    symbol: "MAGIC",
    slug: "magicland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magicland.png",
  },
  {
    name: "Amun Polygon Ecosystem Index",
    symbol: "PECO",
    slug: "amun-polygon-ecosystem-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amun-polygon-ecosystem-index.png",
  },
  {
    name: "Shiba toby",
    symbol: "SHBT",
    slug: "shiba-toby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-toby.png",
  },
  {
    name: "ApeHaven",
    symbol: "APES",
    slug: "apehaven",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apehaven.png",
  },
  {
    name: "Hiroki",
    symbol: "HIRO",
    slug: "hiroki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hiroki.png",
  },
  {
    name: "EarnBet",
    symbol: "BET",
    slug: "earnbet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnbet.png",
  },
  {
    name: "Invesco QQQ Trust Defichain",
    symbol: "DQQQ",
    slug: "qqq-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qqq-tokenized-stock-defichain.png",
  },
  {
    name: "ENERGY Token",
    symbol: "ENERGY",
    slug: "energy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energy-token.png",
  },
  {
    name: "Omega Protocol Money",
    symbol: "OPM",
    slug: "omega-protocol-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omega-protocol-money.png",
  },
  {
    name: "MUU",
    symbol: "$MUU",
    slug: "muu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muu.png",
  },
  {
    name: "Blue Swap",
    symbol: "BLUE",
    slug: "blue-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blue-swap.png",
  },
  {
    name: "Defira",
    symbol: "FIRA",
    slug: "defira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defira.png",
  },
  {
    name: "Facebook tokenized stock FTX",
    symbol: "FB",
    slug: "facebook-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/facebook-tokenized-stock-ftx.png",
  },
  {
    name: "skyup",
    symbol: "SU",
    slug: "skyup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skyup.png",
  },
  {
    name: "Mei Flex",
    symbol: "MF",
    slug: "mei-flex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mei-flex.png",
  },
  {
    name: "Agoras Tokens",
    symbol: "AGRS",
    slug: "agoras-tokens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agoras-tokens.png",
  },
  {
    name: "Minotaur",
    symbol: "MINO",
    slug: "minotaur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minotaur.png",
  },
  {
    name: "BigBang Core",
    symbol: "BBC",
    slug: "bigbang-core",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bigbang-core.png",
  },
  {
    name: "BIZVERSE",
    symbol: "BIVE",
    slug: "bizverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bizverse.png",
  },
  {
    name: "SAFE Community Token",
    symbol: "SAFECOM",
    slug: "safe-community-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-community-token.png",
  },
  {
    name: "STPAY",
    symbol: "STP",
    slug: "stpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stpay.png",
  },
  {
    name: "Fantom Cake",
    symbol: "FATCAKE",
    slug: "fantom-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantom-cake.png",
  },
  {
    name: "WallStreetNinja",
    symbol: "WSN",
    slug: "wallstreetninja",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wallstreetninja.png",
  },
  {
    name: "XRPFarm",
    symbol: "XF",
    slug: "xrpfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrpfarm.png",
  },
  {
    name: "Crypto Excellence",
    symbol: "CE",
    slug: "crypto-excellence",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-excellence.png",
  },
  {
    name: "First Ever NFT",
    symbol: "FEN",
    slug: "first-ever-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/first-ever-nft.png",
  },
  {
    name: "CocktailBar",
    symbol: "COC",
    slug: "cocktail-bar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cocktail-bar.png",
  },
  {
    name: "Tutellus",
    symbol: "TUT",
    slug: "tutellus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tutellus.png",
  },
  {
    name: "Tesla tokenized stock Bittrex",
    symbol: "TSLA",
    slug: "tesla-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tesla-tokenized-stock-bittrex.png",
  },
  {
    name: "888tron",
    symbol: "888",
    slug: "888tron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/888tron.png",
  },
  {
    name: "Hive Vault",
    symbol: "HIV",
    slug: "hive-vault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hive-vault.png",
  },
  {
    name: "SPDR S&P 500 ETF tokenized stock FTX",
    symbol: "SPY",
    slug: "spdr-sp-500-etf-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spdr-sp-500-etf-tokenized-stock-ftx.png",
  },
  {
    name: "Terra Land",
    symbol: "TLAND",
    slug: "terra-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terra-land.png",
  },
  {
    name: "Wrapped NCE",
    symbol: "WNCE",
    slug: "wrapped-nce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-nce.png",
  },
  {
    name: "Charizard Token",
    symbol: "CHZ006",
    slug: "charizardtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charizardtoken.png",
  },
  {
    name: "MetaAxis",
    symbol: "MTA",
    slug: "metaaxis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaaxis.png",
  },
  {
    name: "Octree Finance",
    symbol: "OAK",
    slug: "octree-oak",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octree-oak.png",
  },
  {
    name: "Medifakt",
    symbol: "FAKT",
    slug: "medifakt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medifakt.png",
  },
  {
    name: "Survive",
    symbol: "SURV",
    slug: "survive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/survive.png",
  },
  {
    name: "Tokemon",
    symbol: "TKMN",
    slug: "tokemon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokemon.png",
  },
  {
    name: "Compound Wrapped BTC",
    symbol: "CWBTC",
    slug: "compound-wrapped-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-wrapped-btc.png",
  },
  {
    name: "NIFTY DeFi Protocol",
    symbol: "NFTY",
    slug: "nifty-defi-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nifty-defi-protocol.png",
  },
  {
    name: "SafeComet",
    symbol: "SAFECOMET",
    slug: "safecomet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safecomet.png",
  },
  {
    name: "BlueArk",
    symbol: "BRK",
    slug: "blueark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blueark.png",
  },
  {
    name: "Morphswap",
    symbol: "MS",
    slug: "morphswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morphswap.png",
  },
  {
    name: "HEDGE4.Ai",
    symbol: "HEJJ",
    slug: "hedge4-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedge4-ai.png",
  },
  {
    name: "SpaceXliFe",
    symbol: "SAFE",
    slug: "spacexlife",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacexlife.png",
  },
  {
    name: "Yasha Dao",
    symbol: "YASHA",
    slug: "yasha-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yasha-dao.png",
  },
  {
    name: "NewYorkCoin",
    symbol: "NYC",
    slug: "newyorkcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newyorkcoin.png",
  },
  {
    name: "CyOp Protocol",
    symbol: "CYOP",
    slug: "cyop-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyop-protocol.png",
  },
  {
    name: "MEME KONG",
    symbol: "MKONG",
    slug: "meme-kong",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meme-kong.png",
  },
  {
    name: "Justdobet",
    symbol: "JDB",
    slug: "justdobet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/justdobet.png",
  },
  {
    name: "Pyroblock",
    symbol: "PYR",
    slug: "pyroblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyroblock.png",
  },
  {
    name: "Nomad Exiles",
    symbol: "PRIDE",
    slug: "nomad-exiles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nomad-exiles.png",
  },
  {
    name: "Hydrogentoken",
    symbol: "HGT",
    slug: "hydrogentoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hydrogentoken.png",
  },
  {
    name: "MY IDENTITY COIN",
    symbol: "MYID",
    slug: "my-identity-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-identity-coin.png",
  },
  {
    name: "Game Federation",
    symbol: "GFT",
    slug: "game-federation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game-federation.png",
  },
  {
    name: "Ojamu",
    symbol: "OJA",
    slug: "ojamu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ojamu.png",
  },
  {
    name: "bZx Vesting Token",
    symbol: "VBZRX",
    slug: "vbzrx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vbzrx.png",
  },
  {
    name: "Phonon DAO",
    symbol: "PHONON",
    slug: "phonon-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phonon-dao.png",
  },
  {
    name: "ESG",
    symbol: "ESG",
    slug: "esg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/esg.png",
  },
  {
    name: "JK Coin",
    symbol: "JK",
    slug: "jk-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jk-coin.png",
  },
  {
    name: "Elastic BNB",
    symbol: "XBN",
    slug: "xbn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xbn.png",
  },
  {
    name: "IDL Token",
    symbol: "IDL",
    slug: "idl-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idl-token.png",
  },
  {
    name: "Apricot Finance",
    symbol: "APT",
    slug: "apricot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apricot.png",
  },
  {
    name: "yBEAR.finance",
    symbol: "YBEAR",
    slug: "ybear-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ybear-finance.png",
  },
  {
    name: "Morcilla War",
    symbol: "MOR",
    slug: "morcilla-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morcilla-war.png",
  },
  {
    name: "BOB",
    symbol: "BOB",
    slug: "bob",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bob.png",
  },
  {
    name: "Antalyaspor Token",
    symbol: "AKREP",
    slug: "antalyaspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antalyaspor-token.png",
  },
  {
    name: "28VCK",
    symbol: "VCK",
    slug: "28vck",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/28vck.png",
  },
  {
    name: "META WORLD",
    symbol: "METAD",
    slug: "meta-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-world.png",
  },
  {
    name: "Klondike BTC",
    symbol: "KBTC",
    slug: "klondike-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klondike-btc.png",
  },
  {
    name: "3X Long Dragon Index Token",
    symbol: "DRGNBULL",
    slug: "3x-long-dragon-index-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-dragon-index-token.png",
  },
  {
    name: "Lottery Token",
    symbol: "LOT",
    slug: "lottery-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lottery-token.png",
  },
  {
    name: "FindShibby",
    symbol: "FSHIBBY",
    slug: "findshibby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/findshibby.png",
  },
  {
    name: "YFWorld",
    symbol: "YFW",
    slug: "yfworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfworld.png",
  },
  {
    name: "MyKingdom",
    symbol: "MYK",
    slug: "mykingdom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mykingdom.png",
  },
  {
    name: "SpankChain",
    symbol: "SPANK",
    slug: "spankchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spankchain.png",
  },
  {
    name: "Minato",
    symbol: "MNTO",
    slug: "minato",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minato.png",
  },
  {
    name: "AlloHash",
    symbol: "ALH",
    slug: "allohash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/allohash.png",
  },
  {
    name: "BabyEthereum",
    symbol: "BBETH",
    slug: "babyethereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyethereum.png",
  },
  {
    name: "Chihua Token",
    symbol: "CHIHUA",
    slug: "chihua-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chihua-token.png",
  },
  {
    name: "ToxicDeer Finance",
    symbol: "DEER",
    slug: "toxicdeer-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toxicdeer-finance.png",
  },
  {
    name: "Nowlage Coin",
    symbol: "NAC",
    slug: "nowlage-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nowlage-coin.png",
  },
  {
    name: "ShuttleOne",
    symbol: "wSZO",
    slug: "shuttleone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shuttleone.png",
  },
  {
    name: "JPYC",
    symbol: "JPYC",
    slug: "jpycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jpycoin.png",
  },
  {
    name: "WorldCoin",
    symbol: "WDC",
    slug: "worldcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/worldcoin.png",
  },
  {
    name: "ForeverBlast",
    symbol: "FEB",
    slug: "foreverblast",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foreverblast.png",
  },
  {
    name: "IndoDEX",
    symbol: "IDDX",
    slug: "indodex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/indodex.png",
  },
  {
    name: "PYXIS Network",
    symbol: "PYX",
    slug: "pyxis-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyxis-network.png",
  },
  {
    name: "Historia",
    symbol: "HTA",
    slug: "historia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/historia.png",
  },
  {
    name: "Token dForce USD",
    symbol: "USX",
    slug: "token-force-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/token-force-usd.png",
  },
  {
    name: "Auroracoin",
    symbol: "AUR",
    slug: "auroracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/auroracoin.png",
  },
  {
    name: "Gold Zilla",
    symbol: "GLDZ",
    slug: "gold-zilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-zilla.png",
  },
  {
    name: "GOinfluencer",
    symbol: "GOIN",
    slug: "goinfluencer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goinfluencer.png",
  },
  {
    name: "Martian DAO",
    symbol: "MDAO",
    slug: "martian-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/martian-dao.png",
  },
  {
    name: "KingMoney",
    symbol: "KIM",
    slug: "kingmoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingmoney.png",
  },
  {
    name: "Universe Finance",
    symbol: "UNT",
    slug: "universe-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universe-finance.png",
  },
  {
    name: "EverDot",
    symbol: "EVERDOT",
    slug: "everdot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everdot.png",
  },
  {
    name: "Only Hype Token",
    symbol: "OHT",
    slug: "only-hype-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/only-hype-token.png",
  },
  {
    name: "Aquarius Protocol",
    symbol: "AQU",
    slug: "aquarius-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aquarius-protocol.png",
  },
  {
    name: "Kombai Inu",
    symbol: "KOMBAI",
    slug: "kombai-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kombai-inu.png",
  },
  {
    name: "RIFT Token",
    symbol: "RIFT",
    slug: "rift-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rift-token.png",
  },
  {
    name: "Prude Token",
    symbol: "PRUDE",
    slug: "prude-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prude-token.png",
  },
  {
    name: "Tether Pow",
    symbol: "USDW",
    slug: "tether-pow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tether-pow.png",
  },
  {
    name: "BitCastle",
    symbol: "CASTLE",
    slug: "bitcastle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcastle.png",
  },
  {
    name: "DIKEMEX Network",
    symbol: "DIK",
    slug: "dikemex-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dikemex-network.png",
  },
  {
    name: "iTube",
    symbol: "ITUBE",
    slug: "itube",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/itube.png",
  },
  {
    name: "Yup",
    symbol: "YUP",
    slug: "yup-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yup-token.png",
  },
  {
    name: "Coin of champions",
    symbol: "COC",
    slug: "coin-of-champions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-of-champions.png",
  },
  {
    name: "Floshin Token",
    symbol: "FLOSHIN",
    slug: "floshin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floshin-token.png",
  },
  {
    name: "Community Vote Power",
    symbol: "CVP",
    slug: "community-vote-power",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/community-vote-power.png",
  },
  {
    name: "BNB Bank",
    symbol: "BBK",
    slug: "bnb-bank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnb-bank.png",
  },
  {
    name: "Tsunami finance",
    symbol: "NAMI",
    slug: "tsunami",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tsunami.png",
  },
  {
    name: "GoofyDoge",
    symbol: "GoofyDoge",
    slug: "goofydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goofydoge.png",
  },
  {
    name: "Influencer",
    symbol: "IMI",
    slug: "imi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imi.png",
  },
  {
    name: "TomTomCoin",
    symbol: "TOMS",
    slug: "tomtomcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tomtomcoin.png",
  },
  {
    name: "Shintama",
    symbol: "SHINTAMA",
    slug: "shintama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shintama.png",
  },
  {
    name: "Gastrocoin",
    symbol: "GTC",
    slug: "gastrocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gastrocoin.png",
  },
  {
    name: "Litecoin SV",
    symbol: "LSV",
    slug: "litecoin-sv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litecoin-sv.png",
  },
  {
    name: "Proton Loan",
    symbol: "LOAN",
    slug: "proton-loan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proton-loan.png",
  },
  {
    name: "Jindoge",
    symbol: "JINDOGE",
    slug: "jindoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jindoge.png",
  },
  {
    name: "Low Orbit Crypto Cannon",
    symbol: "LOCC",
    slug: "low-orbit-crypto-cannon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/low-orbit-crypto-cannon.png",
  },
  {
    name: "Zeno Inu",
    symbol: "ZENO",
    slug: "zeno-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeno-inu.png",
  },
  {
    name: "Vangold Token",
    symbol: "VGD",
    slug: "vangold-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vangold-token.png",
  },
  {
    name: "Inuyasha",
    symbol: "INUYASHA",
    slug: "inuyasha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inuyasha.png",
  },
  {
    name: "Eternal Cash",
    symbol: "EC",
    slug: "eternal-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eternal-cash.png",
  },
  {
    name: "Crudeoil Finance",
    symbol: "OIL",
    slug: "crudeoil-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crudeoil-finance.png",
  },
  {
    name: "Restore",
    symbol: "REST",
    slug: "restore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/restore.png",
  },
  {
    name: "fry.world",
    symbol: "FRIES",
    slug: "fryworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fryworld.png",
  },
  {
    name: "Meta Business",
    symbol: "MEFI",
    slug: "meta-business",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-business.png",
  },
  {
    name: "BLACK SHIBA INU",
    symbol: "SHIBB",
    slug: "black-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-shiba-inu.png",
  },
  {
    name: "Comb Finance",
    symbol: "COMB",
    slug: "comb-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comb-finance.png",
  },
  {
    name: "Simba Inu",
    symbol: "SIM",
    slug: "simba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simba-inu.png",
  },
  {
    name: "UnderDog",
    symbol: "DOG",
    slug: "underdog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/underdog.png",
  },
  {
    name: "GainPool",
    symbol: "GAIN",
    slug: "gain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gain.png",
  },
  {
    name: "SafeLight",
    symbol: "SAFELIGHT",
    slug: "safelight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safelight.png",
  },
  {
    name: "Bagus Wallet",
    symbol: "BG",
    slug: "bagus-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bagus-wallet.png",
  },
  {
    name: "AizaWorld",
    symbol: "AIZA",
    slug: "aizaworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aizaworld.png",
  },
  {
    name: "BlockSwap Network",
    symbol: "CBSN",
    slug: "blockswap-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockswap-network.png",
  },
  {
    name: "HtmoonFOMO",
    symbol: "HTMOON",
    slug: "htmoonfomo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/htmoonfomo.png",
  },
  {
    name: "CryptoPunt",
    symbol: "PUN",
    slug: "cryptopunt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptopunt.png",
  },
  {
    name: "Global Game Coin",
    symbol: "GGC",
    slug: "global-game-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-game-coin.png",
  },
  {
    name: "N3RD Finance",
    symbol: "N3RDz",
    slug: "n3rd-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/n3rd-finance.png",
  },
  {
    name: "CryptoGangsters",
    symbol: "CGANG",
    slug: "cryptogangsters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptogangsters.png",
  },
  {
    name: "Soccers Dog",
    symbol: "SD",
    slug: "soccers-dog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soccers-dog.png",
  },
  {
    name: "Verify Token",
    symbol: "VFY",
    slug: "verify-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verify-token.png",
  },
  {
    name: "IDOHunt app",
    symbol: "IDO",
    slug: "idohunt-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idohunt-app.png",
  },
  {
    name: "NOVA",
    symbol: "NOVA",
    slug: "nova",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nova.png",
  },
  {
    name: "AnimeDoge",
    symbol: "ANIMEDOGE",
    slug: "animedoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animedoge.png",
  },
  {
    name: "WorkQuest",
    symbol: "WQT",
    slug: "workquest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/workquest.png",
  },
  {
    name: "Stader sFTMX",
    symbol: "SFTMX",
    slug: "stader-sftmx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stader-sftmx.png",
  },
  {
    name: "MvPad",
    symbol: "MVD",
    slug: "mvpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mvpad.png",
  },
  {
    name: "MetaHome",
    symbol: "METAHOME",
    slug: "metahome",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metahome.png",
  },
  {
    name: "Fridge Token",
    symbol: "FRIDGE",
    slug: "fridge-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fridge-token.png",
  },
  {
    name: "Unknown Fair Object",
    symbol: "UFO",
    slug: "unknown-fair-object",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unknown-fair-object.png",
  },
  {
    name: "AggregatedFinance",
    symbol: "AGFI",
    slug: "aggregatedfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aggregatedfinance.png",
  },
  {
    name: "Williecoin",
    symbol: "WILLIE",
    slug: "williecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/williecoin.png",
  },
  {
    name: "Bonded Cronos",
    symbol: "BCRO",
    slug: "bonded-cronos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonded-cronos.png",
  },
  {
    name: "BNB Fan Token",
    symbol: "BNBFAN",
    slug: "bnbfan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbfan-token.png",
  },
  {
    name: "PREDIQT",
    symbol: "PQT",
    slug: "prediqt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prediqt.png",
  },
  {
    name: "Mozik",
    symbol: "MOZ",
    slug: "mozik",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mozik.png",
  },
  {
    name: "INME Run",
    symbol: "INMER",
    slug: "inme-run",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inme-run.png",
  },
  {
    name: "MetaGalaxy",
    symbol: "MGXY",
    slug: "metagalaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagalaxy.png",
  },
  {
    name: "Piston",
    symbol: "PSTN",
    slug: "piston",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piston.png",
  },
  {
    name: "Matic Aave Interest Bearing LINK",
    symbol: "MALINK",
    slug: "matic-aave-link",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matic-aave-link.png",
  },
  {
    name: "Nil DAO",
    symbol: "NIL",
    slug: "nil-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nil-dao.png",
  },
  {
    name: "Strike UNI",
    symbol: "sUNI",
    slug: "strike-uniswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-uniswap.png",
  },
  {
    name: "ArbiSmart",
    symbol: "RBIS",
    slug: "arbismart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbismart.png",
  },
  {
    name: "BakerGuild",
    symbol: "BAKER",
    slug: "bakerguild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bakerguild.png",
  },
  {
    name: "BitDEFi",
    symbol: "BFI",
    slug: "bitdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitdefi.png",
  },
  {
    name: "Pembrock",
    symbol: "PEM",
    slug: "pembrock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pembrock.png",
  },
  {
    name: "Kindly coin",
    symbol: "KIND",
    slug: "kindly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kindly.png",
  },
  {
    name: "youves uUSD",
    symbol: "UUSD",
    slug: "youves",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/youves.png",
  },
  {
    name: "Amazon tokenized stock FTX",
    symbol: "AMZN",
    slug: "amazon-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amazon-tokenized-stock-ftx.png",
  },
  {
    name: "La Doge de Papel",
    symbol: "LDDP",
    slug: "la-doge-de-papel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/la-doge-de-papel.png",
  },
  {
    name: "Hungry Bear",
    symbol: "HUNGRY",
    slug: "hungry-bear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hungry-bear.png",
  },
  {
    name: "Pixl Coin",
    symbol: "PXLC",
    slug: "pixl-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixl-coin.png",
  },
  {
    name: "Counterparty",
    symbol: "XCP",
    slug: "counterparty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/counterparty.png",
  },
  {
    name: "Zombie Rising NFT",
    symbol: "ZOMB",
    slug: "zombie-rising-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zombie-rising-nft.png",
  },
  {
    name: "Agricoin",
    symbol: "AGX",
    slug: "agri-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agri-coin.png",
  },
  {
    name: "iPay",
    symbol: "IPAY",
    slug: "ipay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ipay.png",
  },
  {
    name: "SOLCash",
    symbol: "SOLCASH",
    slug: "solcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solcash.png",
  },
  {
    name: "Green Life Energy",
    symbol: "GNL",
    slug: "green-life-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-life-energy.png",
  },
  {
    name: "Nyan V2",
    symbol: "NYAN-2",
    slug: "nyan-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nyan-v2.png",
  },
  {
    name: "UNII Finance",
    symbol: "UNII",
    slug: "unii-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unii-finance.png",
  },
  {
    name: "Rezerve",
    symbol: "RZRV",
    slug: "rezerve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rezerve.png",
  },
  {
    name: "Legacy ICHI",
    symbol: "ICHI",
    slug: "legacy-ichi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legacy-ichi.png",
  },
  {
    name: "growth Root Token",
    symbol: "GROOT",
    slug: "growth-root-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/growth-root-token.png",
  },
  {
    name: "Zetta Bitcoin Hashrate Token",
    symbol: "ZBTC",
    slug: "zetta-bitcoin-hashrate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zetta-bitcoin-hashrate-token.png",
  },
  {
    name: "Dragoma",
    symbol: "DMA",
    slug: "dragoma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragoma.png",
  },
  {
    name: "Relaxable",
    symbol: "RELAX",
    slug: "relaxable",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/relaxable.png",
  },
  {
    name: "ThoreNext",
    symbol: "THX",
    slug: "thorenext",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thorenext.png",
  },
  {
    name: "Gameology",
    symbol: "GMYX",
    slug: "gameology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gameology.png",
  },
  {
    name: "DOGUS",
    symbol: "DOGUS",
    slug: "dogus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogus.png",
  },
  {
    name: "Apache",
    symbol: "APACHE",
    slug: "apache",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apache.png",
  },
  {
    name: "Arch Ethereum Web3",
    symbol: "WEB3",
    slug: "arch-ethereum-web3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arch-ethereum-web3.png",
  },
  {
    name: "Luzion Protocol",
    symbol: "LZN",
    slug: "luzion-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luzion-protocol.png",
  },
  {
    name: "Utopia Genesis Foundation",
    symbol: "UOP",
    slug: "utopia-genesis-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utopia-genesis-foundation.png",
  },
  {
    name: "Idexo Token",
    symbol: "IDO",
    slug: "idexo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idexo-token.png",
  },
  {
    name: "Zilswap",
    symbol: "ZWAP",
    slug: "zilswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilswap.png",
  },
  {
    name: "Up Spiral",
    symbol: "SPIRAL",
    slug: "up-spiral-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/up-spiral-v2.png",
  },
  {
    name: "Davincij15 Token",
    symbol: "DJ15",
    slug: "davincij15-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/davincij15-token.png",
  },
  {
    name: "YFIA",
    symbol: "YFIA",
    slug: "yfia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfia.png",
  },
  {
    name: "NebulaToken",
    symbol: "NEBULA",
    slug: "nebulatoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nebulatoken.png",
  },
  {
    name: "DegenVC",
    symbol: "DGVC",
    slug: "degenvc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degenvc.png",
  },
  {
    name: "Compound Augur",
    symbol: "CREP",
    slug: "compound-augur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-augur.png",
  },
  {
    name: "Monster Battle",
    symbol: "MBS",
    slug: "monster-battle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-battle.png",
  },
  {
    name: "Golddoge Sachs",
    symbol: "GDS",
    slug: "golddoge-sachs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golddoge-sachs.png",
  },
  {
    name: "DragonBnB.co",
    symbol: "BNBDRAGON",
    slug: "dragonbnb-co",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonbnb-co.png",
  },
  {
    name: "Mt Pelerin",
    symbol: "MPS",
    slug: "mt-pelerin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mt-pelerin.png",
  },
  {
    name: "Blockster",
    symbol: "BXR",
    slug: "blockster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockster.png",
  },
  {
    name: "APOyield",
    symbol: "SOUL",
    slug: "apoyield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apoyield.png",
  },
  {
    name: "RIZON Blockchain",
    symbol: "ATOLO",
    slug: "rizon-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rizon-blockchain.png",
  },
  {
    name: "Typhoon Cash",
    symbol: "PHOON",
    slug: "typhoon-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/typhoon-cash.png",
  },
  {
    name: "GN",
    symbol: "GN",
    slug: "gn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gn.png",
  },
  {
    name: "GALAXIA",
    symbol: "GXA",
    slug: "galaxia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxia.png",
  },
  {
    name: "BarbecueSwap Finance",
    symbol: "BBQ",
    slug: "barbecueswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/barbecueswap-finance.png",
  },
  {
    name: "Crypto Energy Token",
    symbol: "CET",
    slug: "crypto-energy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-energy-token.png",
  },
  {
    name: "Invest Like Stakeborg Index",
    symbol: "ILSI",
    slug: "invest-like-stakeborg-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/invest-like-stakeborg-index.png",
  },
  {
    name: "Survival Game Online",
    symbol: "SURV",
    slug: "survival-game-online",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/survival-game-online.png",
  },
  {
    name: "NDN Link",
    symbol: "NDN",
    slug: "ndn-link",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ndn-link.png",
  },
  {
    name: "Strong Inu",
    symbol: "SINU",
    slug: "strong-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strong-inu.png",
  },
  {
    name: "WAY-F coin",
    symbol: "WAYF",
    slug: "way-f-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/way-f-coin.png",
  },
  {
    name: "Clientelecoin",
    symbol: "CLT",
    slug: "clientelecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clientelecoin.png",
  },
  {
    name: "Empire Capital Token",
    symbol: "ECC",
    slug: "empire-capital-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/empire-capital-token.png",
  },
  {
    name: "Stacy",
    symbol: "STACY",
    slug: "stacy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stacy.png",
  },
  {
    name: "Elumia Crowns",
    symbol: "ELU",
    slug: "legends-of-elumia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legends-of-elumia.png",
  },
  {
    name: "Phenix Finance",
    symbol: "PHNX",
    slug: "phenix-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phenix-finance.png",
  },
  {
    name: "Bitpower",
    symbol: "BPP",
    slug: "bitpower",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitpower.png",
  },
  {
    name: "FINANCIAL INVESTMENT TOKEN",
    symbol: "FIT",
    slug: "financial-investment-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/financial-investment-token.png",
  },
  {
    name: "Bundles Finance",
    symbol: "BUND",
    slug: "bundles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bundles.png",
  },
  {
    name: "BabyPumpkin Finance",
    symbol: "BUMP",
    slug: "babypumpkin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babypumpkin-finance.png",
  },
  {
    name: "SHUMO",
    symbol: "SHUMO",
    slug: "shumo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shumo.png",
  },
  {
    name: "Shiba Corp",
    symbol: "BSHIBA",
    slug: "shiba-corp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-corp.png",
  },
  {
    name: "Ibiza Token",
    symbol: "IBZ",
    slug: "ibiza-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ibiza-token.png",
  },
  {
    name: "DefHold",
    symbol: "DEFO",
    slug: "defhold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defhold.png",
  },
  {
    name: "Index Coop",
    symbol: "BTC2X-FLI",
    slug: "index-coop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/index-coop.png",
  },
  {
    name: "SOMIDAX",
    symbol: "SMDX",
    slug: "somidax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/somidax.png",
  },
  {
    name: "OEC ETH",
    symbol: "ETHK",
    slug: "ethk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethk.png",
  },
  {
    name: "RCD Espanyol Fan Token",
    symbol: "ENFT",
    slug: "rcd-espanyol-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rcd-espanyol-fan-token.png",
  },
  {
    name: "3X Short Ethereum Classic Token",
    symbol: "ETCBEAR",
    slug: "3x-short-ethereum-classic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-ethereum-classic-token.png",
  },
  {
    name: "MyConstant",
    symbol: "MCT",
    slug: "myconstant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myconstant.png",
  },
  {
    name: "FLEX",
    symbol: "FLEX",
    slug: "flex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flex.png",
  },
  {
    name: "Amoveo",
    symbol: "VEO",
    slug: "amoveo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amoveo.png",
  },
  {
    name: "PooGrow",
    symbol: "POOGROW",
    slug: "poogrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poogrow.png",
  },
  {
    name: "Kayserispor Token",
    symbol: "KYSR",
    slug: "kayserispor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kayserispor-token.png",
  },
  {
    name: "Zada",
    symbol: "ZADA",
    slug: "zada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zada.png",
  },
  {
    name: "Lucy Inu",
    symbol: "LUCY",
    slug: "lucy-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucy-inu.png",
  },
  {
    name: "CleanCarbon",
    symbol: "CARBO",
    slug: "clean-carbon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clean-carbon.png",
  },
  {
    name: "Ashera",
    symbol: "ASH",
    slug: "ashera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ashera.png",
  },
  {
    name: "Digital Bank of Africa",
    symbol: "DBA",
    slug: "digital-bank-of-africa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-bank-of-africa.png",
  },
  {
    name: "Passive Income",
    symbol: "PSI",
    slug: "passive-income",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/passive-income.png",
  },
  {
    name: "Welnance finance",
    symbol: "WEL",
    slug: "welnance-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/welnance-finance.png",
  },
  {
    name: "Strike USDT",
    symbol: "sUSDT",
    slug: "strike-usdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-usdt.png",
  },
  {
    name: "Scientia",
    symbol: "SCIE",
    slug: "scientia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scientia.png",
  },
  {
    name: "Interop",
    symbol: "TROP",
    slug: "interop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/interop.png",
  },
  {
    name: "Toll Free Swap",
    symbol: "TOLL",
    slug: "toll-free-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toll-free-swap.png",
  },
  {
    name: "FANTOM ETHPrinter",
    symbol: "FETHP",
    slug: "fantom-ethprinter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantom-ethprinter.png",
  },
  {
    name: "WorldPlus",
    symbol: "WPL",
    slug: "worldplus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/worldplus.png",
  },
  {
    name: "Eight Hours",
    symbol: "EHRT",
    slug: "eight-hours",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eight-hours.png",
  },
  {
    name: "Axia Protocol",
    symbol: "AXIA",
    slug: "axia-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axia-protocol.png",
  },
  {
    name: "Shibrobi",
    symbol: "SHIBORG",
    slug: "shibrobi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibrobi.png",
  },
  {
    name: "MEX",
    symbol: "MEX",
    slug: "mex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mex.png",
  },
  {
    name: "Helkin",
    symbol: "HK",
    slug: "helkin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helkin.png",
  },
  {
    name: "DogeMoon",
    symbol: "DOGEMOON",
    slug: "dogemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogemoon.png",
  },
  {
    name: "Ratscoin",
    symbol: "RATS",
    slug: "ratscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ratscoin.png",
  },
  {
    name: "MediBit",
    symbol: "MEDIBIT",
    slug: "medibit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medibit.png",
  },
  {
    name: "Apple tokenized stock Bittrex",
    symbol: "AAPL",
    slug: "apple-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apple-tokenized-stock-bittrex.png",
  },
  {
    name: "C Token",
    symbol: "C",
    slug: "c-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/c-token.png",
  },
  {
    name: "XNODE",
    symbol: "XNODE",
    slug: "xnode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xnode.png",
  },
  {
    name: "WHEAT Token",
    symbol: "WHEAT",
    slug: "wheat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wheat.png",
  },
  {
    name: "UFO",
    symbol: "UFO",
    slug: "ufo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ufo.png",
  },
  {
    name: "MetaCash",
    symbol: "META",
    slug: "metacash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metacash.png",
  },
  {
    name: "New Era",
    symbol: "NEC",
    slug: "new-era",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-era.png",
  },
  {
    name: "Yearn Finance Network",
    symbol: "YFN",
    slug: "yearn-finance-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance-network.png",
  },
  {
    name: "OWL",
    symbol: "OWL",
    slug: "owl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/owl.png",
  },
  {
    name: "GameStop tokenized stock FTX",
    symbol: "GME",
    slug: "gamestop-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamestop-tokenized-stock-ftx.png",
  },
  {
    name: "BLOCKIDCOIN",
    symbol: "BID",
    slug: "blockidcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockidcoin.png",
  },
  {
    name: "FingerprintsDAO",
    symbol: "PRINTS",
    slug: "fingerprintsdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fingerprintsdao.png",
  },
  {
    name: "DigiDinar",
    symbol: "DDR",
    slug: "digidinar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digidinar.png",
  },
  {
    name: "Catapult.ac",
    symbol: "CPLT",
    slug: "catapult-ac",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catapult-ac.png",
  },
  {
    name: "ZuFinance",
    symbol: "ZUF",
    slug: "zufinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zufinance.png",
  },
  {
    name: "DEONEX COIN",
    symbol: "DON",
    slug: "deonex-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deonex-coin.png",
  },
  {
    name: "UltrAlpha",
    symbol: "UAT",
    slug: "ultralpha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultralpha.png",
  },
  {
    name: "Frog Inu",
    symbol: "FGI",
    slug: "frog-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frog-inu.png",
  },
  {
    name: "Inverse Bitcoin Volatility Token",
    symbol: "IBVOL",
    slug: "inverse-bitcoin-volatility-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inverse-bitcoin-volatility-token.png",
  },
  {
    name: "ShibArmy",
    symbol: "SHIBARMY",
    slug: "shibarmy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibarmy.png",
  },
  {
    name: "Libera Financial",
    symbol: "LIBERA",
    slug: "libera-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libera-financial.png",
  },
  {
    name: "Dogz",
    symbol: "DOGZ",
    slug: "dogz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogz.png",
  },
  {
    name: "MetaGameHub DAO",
    symbol: "MGH",
    slug: "metagamehub-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagamehub-dao.png",
  },
  {
    name: "MetaBullish",
    symbol: "METAB",
    slug: "metabullish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabullish.png",
  },
  {
    name: "PW-GOLD",
    symbol: "PWG",
    slug: "pw-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pw-gold.png",
  },
  {
    name: "Excavo Finance",
    symbol: "CAVO",
    slug: "excavo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/excavo-finance.png",
  },
  {
    name: "swiss.finance",
    symbol: "SWISS",
    slug: "swiss-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swiss-finance.png",
  },
  {
    name: "Solarmine",
    symbol: "SOLAR",
    slug: "solarmine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solarmine.png",
  },
  {
    name: "Quasarcoin",
    symbol: "QAC",
    slug: "quasarcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quasarcoin.png",
  },
  {
    name: "Strike STRK",
    symbol: "sSTRK",
    slug: "strike-strk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-strk.png",
  },
  {
    name: "CryptoXpress",
    symbol: "XPRESS",
    slug: "cryptoxpress",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoxpress.png",
  },
  {
    name: "Raven X",
    symbol: "RX",
    slug: "raven-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raven-x.png",
  },
  {
    name: "Beach Token",
    symbol: "BEACH",
    slug: "beach-token-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beach-token-bsc.png",
  },
  {
    name: "BITCOIN BOY",
    symbol: "BITBOY",
    slug: "bitcoin-boy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-boy.png",
  },
  {
    name: "Spore Engineering",
    symbol: "SPORE",
    slug: "spore-engineering",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spore-engineering.png",
  },
  {
    name: "CanYaCoin",
    symbol: "CAN",
    slug: "canyacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/canyacoin.png",
  },
  {
    name: "RocketMoon",
    symbol: "RMOON",
    slug: "rocketmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocketmoon.png",
  },
  {
    name: "Floki Millions",
    symbol: "MILLIONS",
    slug: "floki-millions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-millions.png",
  },
  {
    name: "FidoMeta",
    symbol: "FMC",
    slug: "fidometa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fidometa.png",
  },
  {
    name: "Metaple Finance",
    symbol: "MLX",
    slug: "metaple-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaple-finance.png",
  },
  {
    name: "Voice Token",
    symbol: "VOICE",
    slug: "nix-bridge-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nix-bridge-token.png",
  },
  {
    name: "Degens",
    symbol: "DEGENS",
    slug: "degens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degens.png",
  },
  {
    name: "OMEGA FINANCE",
    symbol: "OMG",
    slug: "omega-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omega-finance.png",
  },
  {
    name: "The Red Order",
    symbol: "ORDR",
    slug: "the-red-order",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-red-order.png",
  },
  {
    name: "Boba Fett War",
    symbol: "FETT",
    slug: "boba-fett-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boba-fett-war.png",
  },
  {
    name: "FME",
    symbol: "FME",
    slug: "fme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fme.png",
  },
  {
    name: "FADO Go",
    symbol: "FADO",
    slug: "fado-go",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fado-go.png",
  },
  {
    name: "FlokiCoke",
    symbol: "FLOKICOKE",
    slug: "flokicoke",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokicoke.png",
  },
  {
    name: "YeaFinance",
    symbol: "YEA",
    slug: "yeafinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yeafinance.png",
  },
  {
    name: "HoneyBadger",
    symbol: "HONEYBADGER",
    slug: "honeybadger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honeybadger.png",
  },
  {
    name: "Fame Reward Plus",
    symbol: "FRP",
    slug: "fame-reward-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fame-reward-plus.png",
  },
  {
    name: "DELOT.IO",
    symbol: "DELOT",
    slug: "delot-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/delot-io.png",
  },
  {
    name: "KaleraNFT",
    symbol: "KLN",
    slug: "kaleranft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaleranft.png",
  },
  {
    name: "KRYZA Exchange",
    symbol: "KRX",
    slug: "kryza-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kryza-exchange.png",
  },
  {
    name: "UCROWDME",
    symbol: "UCM",
    slug: "ucrowdme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ucrowdme.png",
  },
  {
    name: "AutoSingle",
    symbol: "AUTOS",
    slug: "auto-single",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/auto-single.png",
  },
  {
    name: "Cyclops Treasure",
    symbol: "CYTR",
    slug: "cyclops-treasure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyclops-treasure.png",
  },
  {
    name: "Shiba Fever",
    symbol: "SHIBAFEVER",
    slug: "shiba-fever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-fever.png",
  },
  {
    name: "Santa Inu",
    symbol: "SANINU",
    slug: "santa-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/santa-inu.png",
  },
  {
    name: "Columbus",
    symbol: "CBS",
    slug: "columbus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/columbus.png",
  },
  {
    name: "DeltaFlip",
    symbol: "DELTAF",
    slug: "deltaflip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deltaflip.png",
  },
  {
    name: "UkraineDAO Flag NFT",
    symbol: "LOVE",
    slug: "ukrainedao-flag-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ukrainedao-flag-nft.png",
  },
  {
    name: "Future",
    symbol: "FTR",
    slug: "future",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/future.png",
  },
  {
    name: "marumaruNFT",
    symbol: "MARU",
    slug: "marumarunft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marumarunft.png",
  },
  {
    name: "Community Business Token",
    symbol: "CBT",
    slug: "community-business-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/community-business-token.png",
  },
  {
    name: "DDDX Protocol",
    symbol: "DDDX",
    slug: "dddx-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dddx-protocol.png",
  },
  {
    name: "YFISCURITY",
    symbol: "YFIS",
    slug: "yfiscurity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfiscurity.png",
  },
  {
    name: "Blockchain Quotations Index Token",
    symbol: "BQT",
    slug: "blockchain-quotations-index-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockchain-quotations-index-token.png",
  },
  {
    name: "DogKage",
    symbol: "DAKE",
    slug: "dogkage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogkage.png",
  },
  {
    name: "Smartchem",
    symbol: "SMAC",
    slug: "smartchem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartchem.png",
  },
  {
    name: "Uniform Fiscal Object",
    symbol: "UFO",
    slug: "uniform-fiscal-object",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniform-fiscal-object.png",
  },
  {
    name: "Mandi Token",
    symbol: "MANDI",
    slug: "mandi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mandi-token.png",
  },
  {
    name: "Notional Finance",
    symbol: "NOTE",
    slug: "notional-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/notional-finance.png",
  },
  {
    name: "PlaceWar",
    symbol: "GEAR",
    slug: "placewar-gear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/placewar-gear.png",
  },
  {
    name: "Granny Shiba",
    symbol: "GSHIBA",
    slug: "granny-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/granny-shiba.png",
  },
  {
    name: "DPiXchange",
    symbol: "$DPIX",
    slug: "dpixchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dpixchange.png",
  },
  {
    name: "Semitoken",
    symbol: "SEMI",
    slug: "semitoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/semitoken.png",
  },
  {
    name: "Kindcow Finance",
    symbol: "KIND",
    slug: "kindcow-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kindcow-finance.png",
  },
  {
    name: "Hatter",
    symbol: "HATTER",
    slug: "hatter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hatter.png",
  },
  {
    name: "Dogira (new)",
    symbol: "DOGIRA",
    slug: "dogira-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogira-new.png",
  },
  {
    name: "Timers",
    symbol: "IPM",
    slug: "timers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/timers.png",
  },
  {
    name: "MR PICKLE NFT",
    symbol: "PICKLE",
    slug: "mr-pickle-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mr-pickle-nft.png",
  },
  {
    name: "Beethoven X",
    symbol: "BEETS",
    slug: "beethoven-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beethoven-x.png",
  },
  {
    name: "RugSeekers",
    symbol: "SEEK",
    slug: "rugseekers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rugseekers.png",
  },
  {
    name: "Ternio",
    symbol: "TERN",
    slug: "ternio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ternio.png",
  },
  {
    name: "Doubloon",
    symbol: "DBL",
    slug: "doubloon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doubloon-token.png",
  },
  {
    name: "Killua Inu",
    symbol: "KILLUA",
    slug: "killua-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/killua-inu.png",
  },
  {
    name: "Consentium",
    symbol: "CSM",
    slug: "consentium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/consentium.png",
  },
  {
    name: "Quam Network",
    symbol: "QUAM",
    slug: "quam-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quam-network.png",
  },
  {
    name: "Keiko",
    symbol: "KEIKO",
    slug: "keiko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keiko.png",
  },
  {
    name: "Vodi X",
    symbol: "VDX",
    slug: "vodi-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vodi-x.png",
  },
  {
    name: "Transient",
    symbol: "TSCT",
    slug: "transient",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/transient.png",
  },
  {
    name: "KingXChain",
    symbol: "KXC",
    slug: "kingxchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingxchain.png",
  },
  {
    name: "Fruits of Ryoshi",
    symbol: "YUZU",
    slug: "fruits-of-ryoshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fruits-of-ryoshi.png",
  },
  {
    name: "ShibaForest",
    symbol: "SHF",
    slug: "shibaforest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibaforest.png",
  },
  {
    name: "Alchemist DeFi Mist",
    symbol: "MIST",
    slug: "alchemist-defi-mist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alchemist-defi-mist.png",
  },
  {
    name: "Llamaverse",
    symbol: "SPIT",
    slug: "llamaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/llamaverse.png",
  },
  {
    name: "ChainPort",
    symbol: "PORTX",
    slug: "chainport",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainport.png",
  },
  {
    name: "R34P",
    symbol: "R34P",
    slug: "r34p",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/r34p.png",
  },
  {
    name: "Bit Trust System",
    symbol: "BIUT",
    slug: "bit-trust-system",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit-trust-system.png",
  },
  {
    name: "POLYSPORTS",
    symbol: "PS1",
    slug: "polysports",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polysports.png",
  },
  {
    name: "FQSwap",
    symbol: "FQS",
    slug: "fqswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fqswap.png",
  },
  {
    name: "Unifund",
    symbol: "IFUND",
    slug: "unifund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unifund.png",
  },
  {
    name: "FAL RUNE - Rune.Game",
    symbol: "FAL",
    slug: "fal-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fal-rune---rune-game.png",
  },
  {
    name: "MASTERCHEF2",
    symbol: "MASTERCHEF2",
    slug: "masterchef2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/masterchef2.png",
  },
  {
    name: "XVIX",
    symbol: "XVIX",
    slug: "xvix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xvix.png",
  },
  {
    name: "YEARNYFI NETWORK",
    symbol: "YNI",
    slug: "yearnyfi-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearnyfi-network.png",
  },
  {
    name: "Binary Cat",
    symbol: "KITTY",
    slug: "binary-cat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binary-cat.png",
  },
  {
    name: "Game X Change",
    symbol: "EXP",
    slug: "game-x-change",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game-x-change.png",
  },
  {
    name: "BIOKKOIN",
    symbol: "BKKG",
    slug: "biokkoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biokkoin.png",
  },
  {
    name: "SuperDoge",
    symbol: "SUPDOG",
    slug: "superdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superdoge.png",
  },
  {
    name: "Euler Tools",
    symbol: "EULER",
    slug: "euler-tools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/euler-tools.png",
  },
  {
    name: "VersalNFT",
    symbol: "VER",
    slug: "versalnft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/versalnft.png",
  },
  {
    name: "DePocket",
    symbol: "DEPO",
    slug: "depocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/depocket.png",
  },
  {
    name: "PoorPleb",
    symbol: "PP",
    slug: "poorpleb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poorpleb.png",
  },
  {
    name: "EurocoinToken",
    symbol: "ECTE",
    slug: "eurocoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eurocoin-token.png",
  },
  {
    name: "TWITTERDOGE",
    symbol: "TDOGE",
    slug: "twitterdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twitterdoge.png",
  },
  {
    name: "Son of God",
    symbol: "GOD",
    slug: "son-of-god",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/son-of-god.png",
  },
  {
    name: "NUT MONEY",
    symbol: "NUT",
    slug: "nut-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nut-money.png",
  },
  {
    name: "Milk and Butter",
    symbol: "MB",
    slug: "milk-and-butter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milk-and-butter.png",
  },
  {
    name: "Spywolf",
    symbol: "$SPY",
    slug: "spywolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spywolf.png",
  },
  {
    name: "DeepCloud AI",
    symbol: "DEEP",
    slug: "deepcloud-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deepcloud-ai.png",
  },
  {
    name: "SafeWages",
    symbol: "SAFEW",
    slug: "safewages",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safewages.png",
  },
  {
    name: "Crazy Bunny Equity Token",
    symbol: "CBUNNY",
    slug: "crazy-bunny-equity-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crazy-bunny-equity-token.png",
  },
  {
    name: "XTblock",
    symbol: "XTT-B20",
    slug: "xtblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtblock.png",
  },
  {
    name: "Turtle",
    symbol: "TURTLE",
    slug: "turtle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turtle.png",
  },
  {
    name: "Arro Social",
    symbol: "ARRO",
    slug: "arro-social",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arro-social.png",
  },
  {
    name: "Leo",
    symbol: "LEO",
    slug: "leo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leo.png",
  },
  {
    name: "Torekko (NEW)",
    symbol: "TRK",
    slug: "torekko-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/torekko-new.png",
  },
  {
    name: "Peach.Finance",
    symbol: "PEECH",
    slug: "peach-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peach-finance.png",
  },
  {
    name: "happy birthday coin",
    symbol: "HBDC",
    slug: "happy-birthday-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/happy-birthday-coin.png",
  },
  {
    name: "Marshmallowdefi",
    symbol: "MASH",
    slug: "marshmallowdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marshmallowdefi.png",
  },
  {
    name: "BurnX",
    symbol: "BurnX",
    slug: "burnx-live",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burnx-live.png",
  },
  {
    name: "Xdef Finance",
    symbol: "XDEF2",
    slug: "xdef-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdef-finance.png",
  },
  {
    name: "Pollux Coin",
    symbol: "POX",
    slug: "pollux-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pollux-coin.png",
  },
  {
    name: "Gambit",
    symbol: "GMT",
    slug: "gambit-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gambit-finance.png",
  },
  {
    name: "Drachma",
    symbol: "DRA",
    slug: "drachma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drachma.png",
  },
  {
    name: "GYM Token",
    symbol: "GYM",
    slug: "gym-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gym-token.png",
  },
  {
    name: "AnonToken",
    symbol: "ANT",
    slug: "anontoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anontoken.png",
  },
  {
    name: "BTA Protocol",
    symbol: "BTAP",
    slug: "bta-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bta-protocol.png",
  },
  {
    name: "Kino Token",
    symbol: "KINO",
    slug: "kino-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kino-token.png",
  },
  {
    name: "ZionTopia",
    symbol: "ZION",
    slug: "ziontopia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ziontopia.png",
  },
  {
    name: "LaikaProtocol",
    symbol: "LAIKA",
    slug: "laikaprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laikaprotocol.png",
  },
  {
    name: "PixelSQUID",
    symbol: "PIXELSQUID",
    slug: "pixelsquid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixelsquid.png",
  },
  {
    name: "ROIMA INC TOKEN",
    symbol: "RMAI",
    slug: "roima-inc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roima-inc-token.png",
  },
  {
    name: "PieDAO DEFI Large Cap",
    symbol: "DEFI+L",
    slug: "piedao-defi-large-cap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piedao-defi-large-cap.png",
  },
  {
    name: "LedgerScore",
    symbol: "LED",
    slug: "ledgerscore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ledgerscore.png",
  },
  {
    name: "KISSAN",
    symbol: "KSN",
    slug: "kissan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kissan.png",
  },
  {
    name: "xUSD Token",
    symbol: "XUSD",
    slug: "xusd-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xusd-token.png",
  },
  {
    name: "Sturgeon Moon",
    symbol: "MOON",
    slug: "sturgeon-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sturgeon-moon.png",
  },
  {
    name: "BaTorrent",
    symbol: "BA",
    slug: "batorrent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/batorrent.png",
  },
  {
    name: "Matrix Token",
    symbol: "MTIX",
    slug: "matrix-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrix-token.png",
  },
  {
    name: "Ledgity",
    symbol: "LTY",
    slug: "ledgity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ledgity.png",
  },
  {
    name: "ComfyToken",
    symbol: "COMFY",
    slug: "comfytoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comfytoken.png",
  },
  {
    name: "Akita Inu",
    symbol: "AKTA",
    slug: "akita-inu-akta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akita-inu-akta.png",
  },
  {
    name: "Gric Coin",
    symbol: "GC",
    slug: "gric-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gric-coin.png",
  },
  {
    name: "Lucidao",
    symbol: "LCD",
    slug: "lucidao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucidao.png",
  },
  {
    name: "xDeFi",
    symbol: "XDEX",
    slug: "xdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdefi.png",
  },
  {
    name: "Genesys Token",
    symbol: "GSYS",
    slug: "genesys-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesys-token.png",
  },
  {
    name: "DOLA",
    symbol: "DOLA",
    slug: "inverse-finance-dola-stablecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inverse-finance-dola-stablecoin.png",
  },
  {
    name: "XCOM",
    symbol: "XC",
    slug: "xcom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xcom.png",
  },
  {
    name: "Dragon Verse",
    symbol: "DRV",
    slug: "dragon-verse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-verse.png",
  },
  {
    name: "Prime Numbers",
    symbol: "PRNT",
    slug: "prime-numbers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prime-numbers.png",
  },
  {
    name: "StellarInu",
    symbol: "STELLARINU",
    slug: "stellarinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stellarinu.png",
  },
  {
    name: "DAOBAO",
    symbol: "BAO",
    slug: "daobao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daobao.png",
  },
  {
    name: "Angel Inu",
    symbol: "ANGEL",
    slug: "angel-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/angel-inu.png",
  },
  {
    name: "ASYAGRO",
    symbol: "ASY",
    slug: "asyagro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asyagro.png",
  },
  {
    name: "FANBI TOKEN",
    symbol: "FBT",
    slug: "fanbi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanbi-token.png",
  },
  {
    name: "BITCOLOJIX",
    symbol: "BTCIX",
    slug: "bitcolojix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcolojix.png",
  },
  {
    name: "UnitedCoins",
    symbol: "UNITS",
    slug: "unitedcoins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unitedcoins.png",
  },
  {
    name: "YFIEXCHANGE.FINANCE",
    symbol: "YFIE",
    slug: "yfiexchange-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfiexchange-finance.png",
  },
  {
    name: "FTMlaunch",
    symbol: "FTML",
    slug: "ftmlaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ftmlaunch.png",
  },
  {
    name: "Lean Management Token",
    symbol: "LEAN",
    slug: "lean-management-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lean-management-token.png",
  },
  {
    name: "Aidi Finance (BSC)",
    symbol: "AIDI",
    slug: "aidi-finance-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aidi-finance-bsc.png",
  },
  {
    name: "Infinity Economics",
    symbol: "XIN",
    slug: "infinity-economics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-economics.png",
  },
  {
    name: "Kaspa",
    symbol: "KAS",
    slug: "kaspa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaspa.png",
  },
  {
    name: "Limestone Network",
    symbol: "LIMEX",
    slug: "limestone-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/limestone-network.png",
  },
  {
    name: "Boulpik Token",
    symbol: "BOULPIK",
    slug: "boulpik-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boulpik-token.png",
  },
  {
    name: "Maximus Coin",
    symbol: "MXZ",
    slug: "maximus-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maximus-coin.png",
  },
  {
    name: "DiamondToken",
    symbol: "DIAMOND",
    slug: "diamondtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamondtoken.png",
  },
  {
    name: "Ushi",
    symbol: "USHI",
    slug: "ushi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ushi.png",
  },
  {
    name: "Big Bang Game Coin",
    symbol: "BBGC",
    slug: "big-bang-game-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-bang-game-coin.png",
  },
  {
    name: "Windfall Token",
    symbol: "WFT",
    slug: "windfall-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/windfall-token.png",
  },
  {
    name: "Yawww",
    symbol: "YAW",
    slug: "yawww",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yawww.png",
  },
  {
    name: "Scooby",
    symbol: "$SBD",
    slug: "scooby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scooby.png",
  },
  {
    name: "AIDUS TOKEN",
    symbol: "AIDUS",
    slug: "aidus-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aidus-token.png",
  },
  {
    name: "VRYNT",
    symbol: "VRYNT",
    slug: "vrynt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vrynt.png",
  },
  {
    name: "SaturnV Gold",
    symbol: "SATVG",
    slug: "saturnv-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saturnv-gold.png",
  },
  {
    name: "AmazonasCoin",
    symbol: "AMZ",
    slug: "amazonascoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amazonascoin.png",
  },
  {
    name: "CryptoBay",
    symbol: "BAY",
    slug: "cryptobay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobay.png",
  },
  {
    name: "Viking Swap",
    symbol: "VIKING",
    slug: "viking-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viking-swap.png",
  },
  {
    name: "Astroport",
    symbol: "ASTRO",
    slug: "astroport",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astroport.png",
  },
  {
    name: "Wow100K",
    symbol: "100K",
    slug: "wow100k",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wow100k.png",
  },
  {
    name: "LOL",
    symbol: "LOL",
    slug: "lol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lol.png",
  },
  {
    name: "indaHash",
    symbol: "IDH",
    slug: "indahash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/indahash.png",
  },
  {
    name: "Alchemist DeFi Aurum",
    symbol: "AURUM",
    slug: "alchemist-defi-aurum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alchemist-defi-aurum.png",
  },
  {
    name: "Kori Inu",
    symbol: "KORI",
    slug: "kori-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kori-inu.png",
  },
  {
    name: "Stable UNI",
    symbol: "ONEUNI",
    slug: "stable-uni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stable-uni.png",
  },
  {
    name: "BunnyPark Game",
    symbol: "BG",
    slug: "bunnypark-bg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunnypark-bg.png",
  },
  {
    name: "DisCas Vision",
    symbol: "DISC",
    slug: "discas-vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/discas-vision.png",
  },
  {
    name: "Time Raiders",
    symbol: "XPND",
    slug: "time-raiders",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/time-raiders.png",
  },
  {
    name: "SpaceRat",
    symbol: "SRAT",
    slug: "spacerat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacerat.png",
  },
  {
    name: "FLOOF",
    symbol: "FLOOF",
    slug: "floof",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floof.png",
  },
  {
    name: "3X Short BNB Token",
    symbol: "BNBBEAR",
    slug: "3x-short-bnb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-bnb-token.png",
  },
  {
    name: "Titi Financial",
    symbol: "TITI",
    slug: "titi-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/titi-financial.png",
  },
  {
    name: "MegaToken",
    symbol: "MEGA",
    slug: "megatoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megatoken.png",
  },
  {
    name: "Evai.io",
    symbol: "EVAI",
    slug: "evai-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evai-io.png",
  },
  {
    name: "Kitty Coin Solana",
    symbol: "KITTY",
    slug: "kitty-coin-solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitty-coin-solana.png",
  },
  {
    name: "Toobcoin",
    symbol: "TOOB",
    slug: "toobemi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toobemi.png",
  },
  {
    name: "PolyMoon",
    symbol: "PolyMoon",
    slug: "polymoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polymoon.png",
  },
  {
    name: "Beep",
    symbol: "BOTZ",
    slug: "beep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beep.png",
  },
  {
    name: "KING OF DEFI",
    symbol: "KODX",
    slug: "king-of-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-of-defi.png",
  },
  {
    name: "PlutusDAO",
    symbol: "PLS",
    slug: "plutusdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plutusdao.png",
  },
  {
    name: "Exponential Capital",
    symbol: "EXPO",
    slug: "exponential-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exponential-capital.png",
  },
  {
    name: "Solomon Defi",
    symbol: "SLM",
    slug: "solomon-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solomon-defi.png",
  },
  {
    name: "Run&Burn",
    symbol: "RBT",
    slug: "runandburn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/runandburn.png",
  },
  {
    name: "PornVerse",
    symbol: "PVERSE",
    slug: "pornverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pornverse.png",
  },
  {
    name: "PYE",
    symbol: "PYE",
    slug: "pye",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pye.png",
  },
  {
    name: "eSwapping",
    symbol: "ESWAP",
    slug: "eswapping",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eswapping.png",
  },
  {
    name: "Biometric Financial",
    symbol: "BIOFI",
    slug: "biometric-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biometric-financial.png",
  },
  {
    name: "Zoom Protocol",
    symbol: "ZOM",
    slug: "zoom-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoom-protocol.png",
  },
  {
    name: "HollaEx Token",
    symbol: "XHT",
    slug: "hollaex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hollaex-token.png",
  },
  {
    name: "Hpdex",
    symbol: "HPD",
    slug: "hpdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hpdex.png",
  },
  {
    name: "CSPC",
    symbol: "CSPC",
    slug: "cspc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cspc.png",
  },
  {
    name: "Sucrecoin",
    symbol: "XSR",
    slug: "sucrecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sucrecoin.png",
  },
  {
    name: "ZUNA",
    symbol: "ZUNA",
    slug: "zuna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zuna.png",
  },
  {
    name: "Financio",
    symbol: "FIN",
    slug: "financio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/financio.png",
  },
  {
    name: "Doge Eat Doge",
    symbol: "OMNOM",
    slug: "doge-eat-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-eat-doge.png",
  },
  {
    name: "BlackDragon",
    symbol: "BDT",
    slug: "blackdragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackdragon.png",
  },
  {
    name: "STEMX",
    symbol: "STEMX",
    slug: "stemx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stemx.png",
  },
  {
    name: "Evolution",
    symbol: "EVO",
    slug: "evolution-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evolution-crypto.png",
  },
  {
    name: "Neton",
    symbol: "NTO",
    slug: "neton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neton.png",
  },
  {
    name: "Elite 1337",
    symbol: "ELITE",
    slug: "elite-1337",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elite-1337.png",
  },
  {
    name: "GM Holding",
    symbol: "GM",
    slug: "gm-holding",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gm-holding.png",
  },
  {
    name: "Graphen",
    symbol: "ELTG",
    slug: "graphen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/graphen.png",
  },
  {
    name: "DoDreamChain",
    symbol: "DRM",
    slug: "dodreamchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dodreamchain.png",
  },
  {
    name: "Bestay",
    symbol: "BSY",
    slug: "bestay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bestay.png",
  },
  {
    name: "ETHP",
    symbol: "ETHP",
    slug: "ethp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethp.png",
  },
  {
    name: "Nunu Spirits",
    symbol: "NNT",
    slug: "nunu-spirits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nunu-spirits.png",
  },
  {
    name: "DIEGO",
    symbol: "DIG",
    slug: "diego",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diego.png",
  },
  {
    name: "Safeswap Governance Token",
    symbol: "SSGT",
    slug: "safeswap-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeswap-governance-token.png",
  },
  {
    name: "AvaNyan",
    symbol: "ANYAN",
    slug: "avanyan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avanyan.png",
  },
  {
    name: "Pitbull Inu",
    symbol: "PITI",
    slug: "pitbull-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pitbull-inu.png",
  },
  {
    name: "Goldcoin",
    symbol: "GLC",
    slug: "goldcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldcoin.png",
  },
  {
    name: "Deswap",
    symbol: "DAW",
    slug: "deswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deswap.png",
  },
  {
    name: "Shitcoin",
    symbol: "STC",
    slug: "shitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shitcoin.png",
  },
  {
    name: "Metaverse Exchange",
    symbol: "METACEX",
    slug: "metaverse-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-exchange.png",
  },
  {
    name: "Crypto Chip Token",
    symbol: "BVC",
    slug: "crypto-chip-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-chip-token.png",
  },
  {
    name: "MetaverseMGL",
    symbol: "MGLC",
    slug: "metaversemgl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaversemgl.png",
  },
  {
    name: "3X Short Algorand Token",
    symbol: "ALGOBEAR",
    slug: "3x-short-algorand-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-algorand-token.png",
  },
  {
    name: "Fenomy",
    symbol: "FENOMY",
    slug: "fenomy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fenomy.png",
  },
  {
    name: "Puriever",
    symbol: "PURE",
    slug: "puriever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puriever.png",
  },
  {
    name: "Easticoin",
    symbol: "ESTI",
    slug: "easticoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/easticoin.png",
  },
  {
    name: "The Silent Sea",
    symbol: "TSSEA",
    slug: "the-silent-sea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-silent-sea.png",
  },
  {
    name: "AnimalHouse",
    symbol: "AHOUSE",
    slug: "animalhouse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animalhouse.png",
  },
  {
    name: "Digital Fitness",
    symbol: "DEFIT",
    slug: "digital-fitness",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-fitness.png",
  },
  {
    name: "Caramel Swap",
    symbol: "MEL",
    slug: "caramel-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caramel-swap.png",
  },
  {
    name: "Runner Land",
    symbol: "RLT",
    slug: "runner-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/runner-land.png",
  },
  {
    name: "ROCK, PAPER, SCISSORS TOKEN",
    symbol: "RPST",
    slug: "rpst",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rpst.png",
  },
  {
    name: "Dinastycoin",
    symbol: "DCY",
    slug: "dinastycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinastycoin.png",
  },
  {
    name: "YCLUB",
    symbol: "SYC",
    slug: "yclub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yclub.png",
  },
  {
    name: "Dice",
    symbol: "DICE",
    slug: "dice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dice.png",
  },
  {
    name: "FluidFi",
    symbol: "FLUID",
    slug: "fluidfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fluidfi.png",
  },
  {
    name: "En-Tan-Mo",
    symbol: "ETM",
    slug: "en-tan-mo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/en-tan-mo.png",
  },
  {
    name: "Early Bird",
    symbol: "EBIRD",
    slug: "early-bird",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/early-bird.png",
  },
  {
    name: "Idle Mystic",
    symbol: "MST",
    slug: "idle-mystic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idle-mystic.png",
  },
  {
    name: "Kardia Info",
    symbol: "INFO",
    slug: "info-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/info-token.png",
  },
  {
    name: "BAEPAY",
    symbol: "BAEPAY",
    slug: "baepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baepay.png",
  },
  {
    name: "JEN COIN",
    symbol: "JEN",
    slug: "jen-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jen-coin.png",
  },
  {
    name: "HOGL finance",
    symbol: "HOGL",
    slug: "hogl-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hogl-finance.png",
  },
  {
    name: "Bribe Protocol",
    symbol: "BRIBE",
    slug: "bribe-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bribe-protocol.png",
  },
  {
    name: "Alchemy",
    symbol: "ACOIN",
    slug: "alchemy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alchemy.png",
  },
  {
    name: "DUSD Network",
    symbol: "DUSD",
    slug: "dusd-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dusd-network.png",
  },
  {
    name: "Vikings Inu",
    symbol: "VIKINGS",
    slug: "vikings-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vikings-inu.png",
  },
  {
    name: "ERAToken",
    symbol: "ERA",
    slug: "eratoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eratoken.png",
  },
  {
    name: "Laeeb Inu",
    symbol: "LAEEB",
    slug: "laeeb-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laeeb-inu.png",
  },
  {
    name: "LEVELG",
    symbol: "LEVELG",
    slug: "levelg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/levelg.png",
  },
  {
    name: "Sponsee",
    symbol: "SPON",
    slug: "sponsee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sponsee.png",
  },
  {
    name: "Sombra Network",
    symbol: "SMBR",
    slug: "sombra-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sombra-network.png",
  },
  {
    name: "Marscoin",
    symbol: "MARS",
    slug: "marscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marscoin.png",
  },
  {
    name: "Honey Deluxe Token",
    symbol: "HONEYD",
    slug: "honey-deluxe-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honey-deluxe-token.png",
  },
  {
    name: "Solar",
    symbol: "SOLAR",
    slug: "solardex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solardex.png",
  },
  {
    name: "Rowket",
    symbol: "KET",
    slug: "rowket-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rowket-market.png",
  },
  {
    name: "VeriBlock",
    symbol: "VBK",
    slug: "veriblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veriblock.png",
  },
  {
    name: "HotMoon Token",
    symbol: "HOTMOON",
    slug: "hotmoon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hotmoon-token.png",
  },
  {
    name: "Stakd Token",
    symbol: "STKD",
    slug: "stakd-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakd-token.png",
  },
  {
    name: "1X Short Ethereum Token",
    symbol: "ETHHEDGE",
    slug: "1x-short-ethereum-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1x-short-ethereum-token.png",
  },
  {
    name: "Jpaw Inu",
    symbol: "JPAW",
    slug: "jpaw-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jpaw-inu.png",
  },
  {
    name: "Golden Duck",
    symbol: "GOLDUCK",
    slug: "golden-duck",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-duck.png",
  },
  {
    name: "Defactor",
    symbol: "FACTR",
    slug: "defactor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defactor.png",
  },
  {
    name: "HbarPad",
    symbol: "HBARP",
    slug: "hbarpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hbarpad.png",
  },
  {
    name: "Chain Estate DAO",
    symbol: "CHES",
    slug: "chain-estate-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain-estate-dao.png",
  },
  {
    name: "Polybius",
    symbol: "PLBT",
    slug: "polybius",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polybius.png",
  },
  {
    name: "Goldex Token",
    symbol: "GLDX",
    slug: "goldex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldex-token.png",
  },
  {
    name: "Panda Multiverse",
    symbol: "PNDMLV",
    slug: "panda-multiverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/panda-multiverse.png",
  },
  {
    name: "UniWhales",
    symbol: "UWL",
    slug: "uniwhales",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniwhales.png",
  },
  {
    name: "Marvin Inu",
    symbol: "MARVIN",
    slug: "marvin-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marvin-inu.png",
  },
  {
    name: "OneRing",
    symbol: "RING",
    slug: "onering",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onering.png",
  },
  {
    name: "Bedrock",
    symbol: "ROCK",
    slug: "bedrock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bedrock.png",
  },
  {
    name: "Dino",
    symbol: "DINO",
    slug: "dino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dino.png",
  },
  {
    name: "Float Protocol: Float",
    symbol: "FLOAT",
    slug: "float-protocol-float",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/float-protocol-float.png",
  },
  {
    name: "Megacosm",
    symbol: "MEGACOSM",
    slug: "megacosm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megacosm.png",
  },
  {
    name: "Credmark",
    symbol: "CMK",
    slug: "credmark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/credmark.png",
  },
  {
    name: "Pawn My NFT",
    symbol: "PNFT",
    slug: "pawn-my-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pawn-my-nft.png",
  },
  {
    name: "SolFarm",
    symbol: "TULIP",
    slug: "solfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solfarm.png",
  },
  {
    name: "aRIA Currency",
    symbol: "RIA",
    slug: "aria-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aria-currency.png",
  },
  {
    name: "The Employment Commons Work Token",
    symbol: "WORK",
    slug: "the-employment-commons-work-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-employment-commons-work-token.png",
  },
  {
    name: "Matic Aave Interest Bearing AAVE",
    symbol: "MAAAVE",
    slug: "matic-aave-aave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matic-aave-aave.png",
  },
  {
    name: "WB-Mining",
    symbol: "WBM",
    slug: "wb-mining",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wb-mining.png",
  },
  {
    name: "AdaSwap",
    symbol: "ASW",
    slug: "adaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adaswap.png",
  },
  {
    name: "Bumper",
    symbol: "BUMP",
    slug: "bumper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bumper.png",
  },
  {
    name: "Saferune",
    symbol: "SAFERUNE",
    slug: "saferune",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saferune.png",
  },
  {
    name: "AMC Entertainment Holdings tokenized stock FTX",
    symbol: "AMC",
    slug: "amc-entertainment-holdings-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amc-entertainment-holdings-tokenized-stock-ftx.png",
  },
  {
    name: "Evolution",
    symbol: "GEN",
    slug: "evolution",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evolution.png",
  },
  {
    name: "RabbitDAO",
    symbol: "RS",
    slug: "rabbitdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rabbitdao.png",
  },
  {
    name: "Project Babel",
    symbol: "PBT",
    slug: "project-babel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-babel.png",
  },
  {
    name: "Swappi",
    symbol: "PPI",
    slug: "swappi-dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swappi-dex.png",
  },
  {
    name: "Page",
    symbol: "PAGE",
    slug: "page",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/page.png",
  },
  {
    name: "VNDC",
    symbol: "VNDC",
    slug: "vndc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vndc.png",
  },
  {
    name: "SORA Synthetic USD",
    symbol: "XSTUSD",
    slug: "sora-synthetic-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sora-synthetic-usd.png",
  },
  {
    name: "DogeZero",
    symbol: "DOGE0",
    slug: "dogezero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogezero.png",
  },
  {
    name: "Game of Dragons",
    symbol: "GOD",
    slug: "game-of-dragons",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game-of-dragons.png",
  },
  {
    name: "Edgecoin",
    symbol: "EDGT",
    slug: "edgecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edgecoin.png",
  },
  {
    name: "The Citadel",
    symbol: "THECITADEL",
    slug: "the-citadel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-citadel.png",
  },
  {
    name: "Shiba Inu Classic",
    symbol: "SHIBIC",
    slug: "shiba-inu-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-inu-classic.png",
  },
  {
    name: "Wrapped BIND",
    symbol: "WBIND",
    slug: "wrapped-bind",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-bind.png",
  },
  {
    name: "TDEX Token",
    symbol: "TT",
    slug: "tdex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tdex-token.png",
  },
  {
    name: "SafeGem.Finance",
    symbol: "GEMS",
    slug: "safegem-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safegem-finance.png",
  },
  {
    name: "DEVITA",
    symbol: "LIFE",
    slug: "devita-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/devita-global.png",
  },
  {
    name: "Pub Finance",
    symbol: "PINT",
    slug: "pub-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pub-finance.png",
  },
  {
    name: "DART Inu",
    symbol: "DART",
    slug: "dart-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dart-inu.png",
  },
  {
    name: "XGOLD COIN",
    symbol: "XGOLD",
    slug: "xgold-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xgold-coin.png",
  },
  {
    name: "Ftribe Fighters (F2 NFT)",
    symbol: "F2C",
    slug: "ftribe-fighters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ftribe-fighters.png",
  },
  {
    name: "Market Ledger",
    symbol: "ML",
    slug: "market-ledger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/market-ledger.png",
  },
  {
    name: "DGPayment",
    symbol: "DGP",
    slug: "dgpayment",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dgpayment.png",
  },
  {
    name: "Kingdom Quest",
    symbol: "KGC",
    slug: "kingdom-quest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdom-quest.png",
  },
  {
    name: "Daily",
    symbol: "DAILY",
    slug: "daily",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daily.png",
  },
  {
    name: "Fanfare",
    symbol: "FAN",
    slug: "fanfare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanfare.png",
  },
  {
    name: "Sentre Protocol",
    symbol: "SNTR",
    slug: "sentre-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sentre-protocol.png",
  },
  {
    name: "Snoop Doge",
    symbol: "SNOGE",
    slug: "snoop-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snoop-doge.png",
  },
  {
    name: "GOAL token",
    symbol: "GOAL",
    slug: "goal-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goal-token.png",
  },
  {
    name: "AK12",
    symbol: "AK12",
    slug: "ak12",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ak12.png",
  },
  {
    name: "CENTERCOIN",
    symbol: "CENT",
    slug: "centercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centercoin.png",
  },
  {
    name: "Balance Network",
    symbol: "BLN",
    slug: "balance-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balance-network.png",
  },
  {
    name: "YFS.FINANCE",
    symbol: "YFS",
    slug: "yfsfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfsfinance.png",
  },
  {
    name: "KirbyX",
    symbol: "KIRBY",
    slug: "kirbyx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kirbyx.png",
  },
  {
    name: "CryptoCoinPay",
    symbol: "CCP",
    slug: "cryptocoinpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptocoinpay.png",
  },
  {
    name: "Nodeseeds",
    symbol: "NDS",
    slug: "nodeseeds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nodeseeds.png",
  },
  {
    name: "Sunder Goverance Token",
    symbol: "Sunder",
    slug: "sunder-goverance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sunder-goverance-token.png",
  },
  {
    name: "HFUEL LAUNCHPAD",
    symbol: "HFUEL",
    slug: "hfuel-launchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hfuel-launchpad.png",
  },
  {
    name: "Made In Real Life",
    symbol: "MIRL",
    slug: "made-in-real-life",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/made-in-real-life.png",
  },
  {
    name: "Tcake",
    symbol: "TCAKE",
    slug: "pancaketools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pancaketools.png",
  },
  {
    name: "Space Misfits",
    symbol: "SMCW",
    slug: "space-misfits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-misfits.png",
  },
  {
    name: "ROBOT SHIB",
    symbol: "RSHIB",
    slug: "robot-shib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robot-shib.png",
  },
  {
    name: "IjasCoin",
    symbol: "IJC",
    slug: "ijascoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ijascoin.png",
  },
  {
    name: "Don-key",
    symbol: "DON",
    slug: "don-key",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/don-key.png",
  },
  {
    name: "Mooni DeFi",
    symbol: "MOONI",
    slug: "mooni-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mooni-defi.png",
  },
  {
    name: "USP",
    symbol: "USP",
    slug: "usp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usp.png",
  },
  {
    name: "UNILAYERX",
    symbol: "LAYERX",
    slug: "unilayerx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unilayerx.png",
  },
  {
    name: "ShinobiVerse",
    symbol: "SHINO",
    slug: "shinobiverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinobiverse.png",
  },
  {
    name: "Global Gaming",
    symbol: "GMNG",
    slug: "global-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-gaming.png",
  },
  {
    name: "GhostMarket",
    symbol: "GM",
    slug: "ghostmarket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghostmarket.png",
  },
  {
    name: "UMI",
    symbol: "UMI",
    slug: "umi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/umi.png",
  },
  {
    name: "Novo",
    symbol: "NOVO",
    slug: "novo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/novo.png",
  },
  {
    name: "Thales",
    symbol: "THALES",
    slug: "thales",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thales.png",
  },
  {
    name: "AurusGOLD",
    symbol: "AWG",
    slug: "aurusgold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurusgold.png",
  },
  {
    name: "ALTER",
    symbol: "ALTER",
    slug: "alter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alter.png",
  },
  {
    name: "Chihuahuax",
    symbol: "CHIHUA",
    slug: "chihuahuax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chihuahuax.png",
  },
  {
    name: "Talent Token",
    symbol: "TTX",
    slug: "talent-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/talent-token.png",
  },
  {
    name: "Players.Art",
    symbol: "PLAYR",
    slug: "players-art",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/players-art.png",
  },
  {
    name: "Rocket Pool ETH",
    symbol: "RETH",
    slug: "rocket-pool-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-pool-eth.png",
  },
  {
    name: "YaySwap",
    symbol: "YAY",
    slug: "yayswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yayswap.png",
  },
  {
    name: "PolyFarm EGG",
    symbol: "EGG",
    slug: "polyfarm-egg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyfarm-egg.png",
  },
  {
    name: "Largo Coin",
    symbol: "LRG",
    slug: "largo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/largo-coin.png",
  },
  {
    name: "Batman",
    symbol: "BATMAN",
    slug: "batman",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/batman.png",
  },
  {
    name: "FreshCut Diamond",
    symbol: "FCD",
    slug: "freshcut-diamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freshcut-diamond.png",
  },
  {
    name: "Grim Finance",
    symbol: "REAPER",
    slug: "grim-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grim-finance.png",
  },
  {
    name: "Learning Star",
    symbol: "LSTAR",
    slug: "learning-star",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/learning-star.png",
  },
  {
    name: "Hoopoe",
    symbol: "HOOP",
    slug: "hoopoe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoopoe.png",
  },
  {
    name: "Secret (ERC20)",
    symbol: "WSCRT",
    slug: "secret-erc20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secret-erc20.png",
  },
  {
    name: "Niros",
    symbol: "NIROS",
    slug: "niros",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niros.png",
  },
  {
    name: "Monster Slayer Cash",
    symbol: "MSC",
    slug: "monster-slayer-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-slayer-finance.png",
  },
  {
    name: "Doge Inu",
    symbol: "DINU",
    slug: "doge-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-inu.png",
  },
  {
    name: "Coreum",
    symbol: "CORE",
    slug: "coreum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coreum.png",
  },
  {
    name: "DogeSwap",
    symbol: "DOG",
    slug: "dogeswap-heco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeswap-heco.png",
  },
  {
    name: "World Cup Pot",
    symbol: "WCP",
    slug: "world-cup-pot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-cup-pot.png",
  },
  {
    name: "PopDoge",
    symbol: "POPDOGE",
    slug: "popdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/popdoge.png",
  },
  {
    name: "Bird Finance",
    symbol: "BIRD",
    slug: "bird-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bird-finance.png",
  },
  {
    name: "Camistry",
    symbol: "CEX",
    slug: "camistry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/camistry.png",
  },
  {
    name: "Polaris Finance Orbital",
    symbol: "ORBITAL",
    slug: "polaris-finance-orbital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polaris-finance-orbital.png",
  },
  {
    name: "Unobtanium",
    symbol: "UNO",
    slug: "unobtanium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unobtanium.png",
  },
  {
    name: "Shelby TOKEN",
    symbol: "SBY",
    slug: "shelby-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shelby-token.png",
  },
  {
    name: "Kuwa Oracle",
    symbol: "KUOR",
    slug: "kuwa-oracle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuwa-oracle.png",
  },
  {
    name: "Papa Shiba",
    symbol: "PHIBA",
    slug: "papa-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/papa-shiba.png",
  },
  {
    name: "CrypWorld",
    symbol: "CWC",
    slug: "crypworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypworld.png",
  },
  {
    name: "Big Dog Coin",
    symbol: "BDOG",
    slug: "big-dog-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-dog-coin.png",
  },
  {
    name: "TrustBase",
    symbol: "TBE",
    slug: "trustbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustbase.png",
  },
  {
    name: "WhaleStreet $hrimp Token",
    symbol: "$HRIMP",
    slug: "whalestreet-shrimp-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whalestreet-shrimp-token.png",
  },
  {
    name: "Glox Finance",
    symbol: "GLOX",
    slug: "glox-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glox-finance.png",
  },
  {
    name: "Dark Knight",
    symbol: "DKNIGHT",
    slug: "darkknight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darkknight.png",
  },
  {
    name: "Digex",
    symbol: "DIGEX",
    slug: "digex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digex.png",
  },
  {
    name: "Andus Chain",
    symbol: "DEB",
    slug: "andus-chain-daon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/andus-chain-daon.png",
  },
  {
    name: "YOKcoin",
    symbol: "YOK",
    slug: "yokcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yokcoin.png",
  },
  {
    name: "Blocktyme",
    symbol: "BTYM",
    slug: "blocktyme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocktyme.png",
  },
  {
    name: "Hero Cat Key",
    symbol: "HCK",
    slug: "herocatkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herocatkey.png",
  },
  {
    name: "UwU Lend",
    symbol: "UWU",
    slug: "uwu-lend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uwu-lend.png",
  },
  {
    name: "Brother of Elon",
    symbol: "BOE",
    slug: "brother-of-elon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brother-of-elon.png",
  },
  {
    name: "Shopping.io",
    symbol: "SHOP",
    slug: "shopping-io-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shopping-io-token.png",
  },
  {
    name: "BOSCore",
    symbol: "BOS",
    slug: "boscore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boscore.png",
  },
  {
    name: "VeraSwap",
    symbol: "VRAP",
    slug: "veraswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veraswap.png",
  },
  {
    name: "Cross-Chain Bridge Token",
    symbol: "BRIDGE",
    slug: "cross-chain-bridge-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cross-chain-bridge-token.png",
  },
  {
    name: "ChadFi",
    symbol: "CHAD",
    slug: "chadfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chadfi.png",
  },
  {
    name: "BasketCoin",
    symbol: "BSKT",
    slug: "basketcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basketcoin.png",
  },
  {
    name: "Fabwelt",
    symbol: "WELT",
    slug: "fabwelt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fabwelt.png",
  },
  {
    name: "AlphaFi",
    symbol: "ALF",
    slug: "alphafi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alphafi.png",
  },
  {
    name: "Legends of Mitra",
    symbol: "MITA",
    slug: "legends-of-mitra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legends-of-mitra.png",
  },
  {
    name: "MetaShib Token",
    symbol: "METASHIB",
    slug: "metashib-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metashib-token.png",
  },
  {
    name: "XFai",
    symbol: "XFIT",
    slug: "xfai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xfai.png",
  },
  {
    name: "Etermon",
    symbol: "ETM",
    slug: "etermon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etermon.png",
  },
  {
    name: "DogeVille",
    symbol: "DVILLE",
    slug: "dogeville",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeville.png",
  },
  {
    name: "FoxDcoin",
    symbol: "FOXD",
    slug: "foxdcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foxdcoin.png",
  },
  {
    name: "StellaSwap",
    symbol: "STELLA",
    slug: "stellaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stellaswap.png",
  },
  {
    name: "50x.com",
    symbol: "50X",
    slug: "50x-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/50x-com.png",
  },
  {
    name: "MetaDancingCrew",
    symbol: "MDC",
    slug: "metadancingcrew",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadancingcrew.png",
  },
  {
    name: "Degen Protocol",
    symbol: "DGN",
    slug: "degen-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degen-protocol.png",
  },
  {
    name: "Weave",
    symbol: "WEAVE",
    slug: "weave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weave.png",
  },
  {
    name: "IQONIQ FanEcoSystem",
    symbol: "IQQ",
    slug: "iqoniq-fanecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iqoniq-fanecosystem.png",
  },
  {
    name: "MYRA AI",
    symbol: "MYRA",
    slug: "myra-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myra-ai.png",
  },
  {
    name: "Itheum",
    symbol: "ITHEUM",
    slug: "itheum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/itheum.png",
  },
  {
    name: "Chumhum",
    symbol: "CHUM",
    slug: "chumhum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chumhum.png",
  },
  {
    name: "Optimus Cat",
    symbol: "OPCAT",
    slug: "optimus-cat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/optimus-cat.png",
  },
  {
    name: "Octans",
    symbol: "OCTA",
    slug: "octans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octans.png",
  },
  {
    name: "BattleVerse",
    symbol: "BVC",
    slug: "battleverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battleverse.png",
  },
  {
    name: "Aurora token",
    symbol: "AURORA",
    slug: "aurora-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurora-token.png",
  },
  {
    name: "Polkadog",
    symbol: "PDOG",
    slug: "polkadog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkadog.png",
  },
  {
    name: "Lithosphere",
    symbol: "LITHO",
    slug: "lithosphere",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lithosphere.png",
  },
  {
    name: "COBAN",
    symbol: "COBAN",
    slug: "coban",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coban.png",
  },
  {
    name: "1x Long Bitcoin Implied Volatility Token",
    symbol: "BVOL",
    slug: "1x-long-bitcoin-implied-volatility-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1x-long-bitcoin-implied-volatility-token.png",
  },
  {
    name: "Tether EURt",
    symbol: "EURT",
    slug: "tether-eurt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tether-eurt.png",
  },
  {
    name: "Ecoreal Estate",
    symbol: "ECOREAL",
    slug: "ecoreal-estate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecoreal-estate.png",
  },
  {
    name: "Fimi Market Inc.",
    symbol: "FIMI",
    slug: "fimi-market-inc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fimi-market-inc.png",
  },
  {
    name: "Zenlink",
    symbol: "ZLK",
    slug: "zenlink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenlink.png",
  },
  {
    name: "Drachma Exchange",
    symbol: "DRA",
    slug: "drachma-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drachma-exchange.png",
  },
  {
    name: "UangMarket",
    symbol: "UANG",
    slug: "uangmarket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uangmarket.png",
  },
  {
    name: "INCOME",
    symbol: "INCOME",
    slug: "income",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/income.png",
  },
  {
    name: "Matic Aave Interest Bearing USDC",
    symbol: "MAUSDC",
    slug: "matic-aave-usdc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matic-aave-usdc.png",
  },
  {
    name: "FlashX Ultra",
    symbol: "FSXU",
    slug: "flashx-ultra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flashx-ultra.png",
  },
  {
    name: "DAR",
    symbol: "DDT",
    slug: "dar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dar.png",
  },
  {
    name: "DECENT Database",
    symbol: "DECENT",
    slug: "decent-database",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decent-database.png",
  },
  {
    name: "SPICE",
    symbol: "SPICE",
    slug: "scifi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scifi-finance.png",
  },
  {
    name: "LinkCoin Token",
    symbol: "LKN",
    slug: "linkcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkcoin-token.png",
  },
  {
    name: "Timechain Swap Token",
    symbol: "TCS",
    slug: "timechain-swap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/timechain-swap-token.png",
  },
  {
    name: "Grey Token",
    symbol: "GREY",
    slug: "grey-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grey-token.png",
  },
  {
    name: "PolyPad",
    symbol: "POLYPAD",
    slug: "polypad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polypad.png",
  },
  {
    name: "FlokiPad",
    symbol: "FLOKIPAD",
    slug: "flokipad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokipad.png",
  },
  {
    name: "SWAPP Protocol",
    symbol: "SWAPP",
    slug: "swapp-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapp-protocol.png",
  },
  {
    name: "Catoshi Nakamoto",
    symbol: "CATS",
    slug: "catoshi-nakamoto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catoshi-nakamoto.png",
  },
  {
    name: "AlRihla",
    symbol: "ALRIHLA",
    slug: "alrihla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alrihla.png",
  },
  {
    name: "FireRocket",
    symbol: "FIREROCKET",
    slug: "firerocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firerocket.png",
  },
  {
    name: "VaporNodes",
    symbol: "VPND",
    slug: "vapornodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vapornodes.png",
  },
  {
    name: "ODIN PROTOCOL",
    symbol: "ODIN",
    slug: "odin-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/odin-protocol.png",
  },
  {
    name: "The meme finance",
    symbol: "MEFI",
    slug: "the-meme-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-meme-finance.png",
  },
  {
    name: "YES||NO",
    symbol: "YON",
    slug: "yes-no",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yes-no.png",
  },
  {
    name: "WingRiders",
    symbol: "WRT",
    slug: "wingriders",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wingriders.png",
  },
  {
    name: "FarSwap",
    symbol: "FAR",
    slug: "farswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farswap.png",
  },
  {
    name: "Sheesha Finance Polygon",
    symbol: "MSHEESHA",
    slug: "sheesha-finance-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sheesha-finance-polygon.png",
  },
  {
    name: "LordToken",
    symbol: "LTT",
    slug: "lordtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lordtoken.png",
  },
  {
    name: "Emrals",
    symbol: "EMRALS",
    slug: "emrals",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emrals.png",
  },
  {
    name: "Aavegotchi ALPHA",
    symbol: "ALPHA",
    slug: "aavegotchi-alpha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aavegotchi-alpha.png",
  },
  {
    name: "Terra Shiba",
    symbol: "TSHIBA",
    slug: "terra-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terra-shiba.png",
  },
  {
    name: "Sadbaby",
    symbol: "SDBY",
    slug: "sadbaby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sadbaby.png",
  },
  {
    name: "SwapAll",
    symbol: "SAP",
    slug: "swapall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapall.png",
  },
  {
    name: "SUPERCATS",
    symbol: "S-CATS",
    slug: "supercats",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supercats.png",
  },
  {
    name: "Digies Coin",
    symbol: "DIGS",
    slug: "digies-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digies-coin.png",
  },
  {
    name: "EQ9",
    symbol: "EQ9",
    slug: "equals9",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/equals9.png",
  },
  {
    name: "GOAT COIN",
    symbol: "GOAT",
    slug: "goat-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goat-coin.png",
  },
  {
    name: "Toshinori Inu",
    symbol: "TOSHINORI",
    slug: "toshinori-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toshinori-inu.png",
  },
  {
    name: "Citrus",
    symbol: "CTS",
    slug: "citrus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/citrus.png",
  },
  {
    name: "JoJos Adventure",
    symbol: "JOJO",
    slug: "jojos-adventure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jojos-adventure.png",
  },
  {
    name: "SWAG Finance",
    symbol: "SWAG",
    slug: "swag-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swag-finance.png",
  },
  {
    name: "CarbonDEFI Finance",
    symbol: "CARBO",
    slug: "carbondefi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbondefi-finance.png",
  },
  {
    name: "Filecoin Standard Full Hashrate Token",
    symbol: "SFIL",
    slug: "filecoin-standard-full-hashrate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/filecoin-standard-full-hashrate-token.png",
  },
  {
    name: "Nelore Coin",
    symbol: "NLC",
    slug: "nelore-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nelore-coin.png",
  },
  {
    name: "ENERGY",
    symbol: "NRGY",
    slug: "energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energy.png",
  },
  {
    name: "Generation",
    symbol: "GEN",
    slug: "generation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/generation.png",
  },
  {
    name: "Diamond Platform Token",
    symbol: "DPT",
    slug: "diamond-platform-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-platform-token.png",
  },
  {
    name: "Kukachu",
    symbol: "KUKA",
    slug: "kukachu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kukachu.png",
  },
  {
    name: "LOLTOKEN",
    symbol: "LOL",
    slug: "loltoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loltoken.png",
  },
  {
    name: "Triall",
    symbol: "TRL",
    slug: "triall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/triall.png",
  },
  {
    name: "TIMEXSPACE",
    symbol: "TXS",
    slug: "timexspace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/timexspace.png",
  },
  {
    name: "ISALCOIN",
    symbol: "ISAL",
    slug: "isalcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/isalcoin.png",
  },
  {
    name: "ESPLASH",
    symbol: "ESPL",
    slug: "esplash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/esplash.png",
  },
  {
    name: "StellarPayGlobal",
    symbol: "XLPG",
    slug: "stellarpayglobal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stellarpayglobal.png",
  },
  {
    name: "ShibaMoon",
    symbol: "SHIBM",
    slug: "shibamoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibamoon.png",
  },
  {
    name: "Momo Protocol",
    symbol: "MOMO",
    slug: "momo-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/momo-protocol.png",
  },
  {
    name: "New Frontier Presents",
    symbol: "NFP",
    slug: "new-frontier-presents",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-frontier-presents.png",
  },
  {
    name: "3X Long OKB Token",
    symbol: "OKBBULL",
    slug: "3x-long-okb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-okb-token.png",
  },
  {
    name: "3X Long Midcap Index Token",
    symbol: "MIDBULL",
    slug: "3x-long-midcap-index-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-midcap-index-token.png",
  },
  {
    name: "ARTL",
    symbol: "ARTL",
    slug: "artl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artl.png",
  },
  {
    name: "Mega Lottery Services Global",
    symbol: "MLR",
    slug: "mega-lottery-services-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mega-lottery-services-global.png",
  },
  {
    name: "TETU",
    symbol: "TETU",
    slug: "tetu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tetu.png",
  },
  {
    name: "META",
    symbol: "META",
    slug: "meta-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-.png",
  },
  {
    name: "Jomon Shiba",
    symbol: "JSHIBA",
    slug: "jomon-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jomon-shiba.png",
  },
  {
    name: "LOA Protocol",
    symbol: "LOA",
    slug: "loa-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loa-protocol.png",
  },
  {
    name: "Beeuda",
    symbol: "BDA",
    slug: "beeuda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beeuda.png",
  },
  {
    name: "iStep",
    symbol: "ISTEP",
    slug: "istep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/istep.png",
  },
  {
    name: "ACCEL",
    symbol: "ACCEL",
    slug: "accel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/accel.png",
  },
  {
    name: "Moola",
    symbol: "MLA",
    slug: "moola",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moola.png",
  },
  {
    name: "Elastic Governance",
    symbol: "EGT",
    slug: "elastic-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elastic-governance.png",
  },
  {
    name: "Glitter Finance",
    symbol: "XGLI",
    slug: "glitter-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glitter-finance.png",
  },
  {
    name: "Dios Finance",
    symbol: "DIOS",
    slug: "dios-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dios-finance.png",
  },
  {
    name: "Kyberdyne",
    symbol: "KBD",
    slug: "kyberdyne",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kyberdyne.png",
  },
  {
    name: "Pasta Finance",
    symbol: "PASTA",
    slug: "pasta-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pasta-finance.png",
  },
  {
    name: "Geojam Token",
    symbol: "JAM",
    slug: "geojam-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geojam-token.png",
  },
  {
    name: "Dhabi Coin",
    symbol: "DBC",
    slug: "dhabi-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dhabi-coin.png",
  },
  {
    name: "Moonshield",
    symbol: "MSHLD",
    slug: "moonshield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonshield.png",
  },
  {
    name: "Beefy Escrowed Fantom",
    symbol: "BEFTM",
    slug: "beefy-escrowed-fantom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beefy-escrowed-fantom.png",
  },
  {
    name: "Attrace",
    symbol: "ATTR",
    slug: "attrace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/attrace.png",
  },
  {
    name: "Pantos",
    symbol: "PAN",
    slug: "pantos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pantos.png",
  },
  {
    name: "ELD RUNE - Rune.Game",
    symbol: "ELD",
    slug: "eld-rune---rune-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eld-rune---rune-game.png",
  },
  {
    name: "CoinMooner",
    symbol: "MOONER",
    slug: "coinmooner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinmooner.png",
  },
  {
    name: "Synesis One",
    symbol: "SNS",
    slug: "synesis-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synesis-one.png",
  },
  {
    name: "Bluejay Finance",
    symbol: "BLU",
    slug: "bluejay-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bluejay-finance.png",
  },
  {
    name: "Avaware USD",
    symbol: "AUSD",
    slug: "avaware-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaware-usd.png",
  },
  {
    name: "SatisFinance Token",
    symbol: "xSAT",
    slug: "satisfinance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satisfinance-token.png",
  },
  {
    name: "Neeo",
    symbol: "NEEO",
    slug: "neeo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neeo.png",
  },
  {
    name: "Decode Coin",
    symbol: "DECODE",
    slug: "decode-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decode-coin.png",
  },
  {
    name: "BSC Mithril",
    symbol: "MITHRIL",
    slug: "bsc-mithril",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsc-mithril.png",
  },
  {
    name: "Adana Demirspor Token",
    symbol: "DEMIR",
    slug: "adana-demirspor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adana-demirspor-token.png",
  },
  {
    name: "Polywolf",
    symbol: "MOON",
    slug: "polywolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polywolf.png",
  },
  {
    name: "Doxed",
    symbol: "DOX",
    slug: "doxed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doxed.png",
  },
  {
    name: "ROPE Token",
    symbol: "ROPE",
    slug: "rope-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rope-token.png",
  },
  {
    name: "Meta Inu Token",
    symbol: "META",
    slug: "meta-inu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-inu-token.png",
  },
  {
    name: "BurnX 2.0",
    symbol: "BURNX20",
    slug: "burnx20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burnx20.png",
  },
  {
    name: "Strike ETH",
    symbol: "sETH",
    slug: "strike-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-eth.png",
  },
  {
    name: "Phenix Finance (NEW)",
    symbol: "PHNX",
    slug: "phenix-finance-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phenix-finance-new.png",
  },
  {
    name: "Ruby Currency",
    symbol: "RBC",
    slug: "ruby-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ruby-currency.png",
  },
  {
    name: "Jetswap.finance",
    symbol: "WINGS",
    slug: "jetswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jetswap-finance.png",
  },
  {
    name: "FishingTownGilToken",
    symbol: "GIL",
    slug: "fishingtowngiltoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fishingtowngiltoken.png",
  },
  {
    name: "Rivex",
    symbol: "RVX",
    slug: "rivex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rivex.png",
  },
  {
    name: "Alkemi Network DAO Token",
    symbol: "ALK",
    slug: "alkemi-network-dao-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alkemi-network-dao-token.png",
  },
  {
    name: "Baby Floki Up",
    symbol: "BFU",
    slug: "baby-floki-up",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-floki-up.png",
  },
  {
    name: "YAM v1",
    symbol: "YAM",
    slug: "yamv1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yamv1.png",
  },
  {
    name: "Gamma",
    symbol: "GAM",
    slug: "metawars-gam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metawars-gam.png",
  },
  {
    name: "Marble Heroes",
    symbol: "MBH",
    slug: "marble-heroes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marble-heroes.png",
  },
  {
    name: "Hierocoin",
    symbol: "BAR",
    slug: "hierocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hierocoin.png",
  },
  {
    name: "Musubi",
    symbol: "MUSUBI",
    slug: "musubi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musubi.png",
  },
  {
    name: "Whirl Finance",
    symbol: "WHIRL",
    slug: "whirl-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whirl-finance.png",
  },
  {
    name: "VicMove",
    symbol: "VIM",
    slug: "vicmove",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vicmove.png",
  },
  {
    name: "BAO",
    symbol: "BAO",
    slug: "bao-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bao-token.png",
  },
  {
    name: "RobustSwap Token",
    symbol: "RBS",
    slug: "robustswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robustswap-token.png",
  },
  {
    name: "Attack Wagon",
    symbol: "ATK",
    slug: "attack-wagon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/attack-wagon.png",
  },
  {
    name: "CroxSwap",
    symbol: "CROX",
    slug: "croxswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/croxswap.png",
  },
  {
    name: "AurusDeFi",
    symbol: "AWX",
    slug: "aurusdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurusdefi.png",
  },
  {
    name: "Volare Network",
    symbol: "VOLR",
    slug: "volare-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/volare-network.png",
  },
  {
    name: "FlokiFrunkPuppy",
    symbol: "FloFru",
    slug: "flokifrunkpuppy-avax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokifrunkpuppy-avax.png",
  },
  {
    name: "Tribalpunk Cryptoverse",
    symbol: "ANTA",
    slug: "tribalpunk-cryptoverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tribalpunk-cryptoverse.png",
  },
  {
    name: "LunaGens",
    symbol: "LUNG",
    slug: "lunagens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunagens.png",
  },
  {
    name: "Gabecoin",
    symbol: "GABECOIN",
    slug: "gabecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gabecoin.png",
  },
  {
    name: "AnteDAO",
    symbol: "ANTE",
    slug: "antedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antedao.png",
  },
  {
    name: "IX Token",
    symbol: "IXT",
    slug: "ix-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ix-token.png",
  },
  {
    name: "SafeEarth",
    symbol: "SAFEEARTH",
    slug: "safeearth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeearth.png",
  },
  {
    name: "Krypto Pets",
    symbol: "KPETS",
    slug: "krypto-pets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krypto-pets.png",
  },
  {
    name: "bDollar Share",
    symbol: "SBDO",
    slug: "bdollar-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bdollar-share.png",
  },
  {
    name: "Fairum",
    symbol: "FAI",
    slug: "fairum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fairum.png",
  },
  {
    name: "Son of Doge",
    symbol: "SOD",
    slug: "son-of-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/son-of-doge.png",
  },
  {
    name: "Aalto Protocol",
    symbol: "AALTO",
    slug: "aalto-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aalto-protocol.png",
  },
  {
    name: "SafePizza",
    symbol: "PIZZA",
    slug: "safepizza",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safepizza.png",
  },
  {
    name: "Zombie World Z",
    symbol: "ZWZ",
    slug: "zombie-world-z",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zombie-world-z.png",
  },
  {
    name: "Lemonn",
    symbol: "LMN",
    slug: "lemonn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lemonn.png",
  },
  {
    name: "snglsDAO",
    symbol: "SGT",
    slug: "snglsdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snglsdao.png",
  },
  {
    name: "NoblessCoin",
    symbol: "NBLS",
    slug: "noblesscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noblesscoin.png",
  },
  {
    name: "PayNet Coin",
    symbol: "PAYN",
    slug: "paynet-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paynet-coin.png",
  },
  {
    name: "HOGT",
    symbol: "HOGT",
    slug: "hogt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hogt.png",
  },
  {
    name: "NFTBomb",
    symbol: "NBP",
    slug: "nftbomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftbomb.png",
  },
  {
    name: "Lillion",
    symbol: "LIL",
    slug: "lillion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lillion.png",
  },
  {
    name: "Minionsinu",
    symbol: "STE",
    slug: "minionsinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minionsinu.png",
  },
  {
    name: "Wallfair.io",
    symbol: "WFAIR",
    slug: "wallfair",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wallfair.png",
  },
  {
    name: "Bitcoin Asset",
    symbol: "BTA",
    slug: "bitcoin-asset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-asset.png",
  },
  {
    name: "TRYC",
    symbol: "TRYC",
    slug: "tryc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tryc.png",
  },
  {
    name: "SnowgeCoin",
    symbol: "SNOWGE",
    slug: "snowgecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowgecoin.png",
  },
  {
    name: "Kitty Finance",
    symbol: "KITTY",
    slug: "kitty-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitty-finance.png",
  },
  {
    name: "Galactic Arena: The NFTverse",
    symbol: "GAN",
    slug: "galatic-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galatic-arena.png",
  },
  {
    name: "Akoin",
    symbol: "AKN",
    slug: "akoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akoin.png",
  },
  {
    name: "MetaRaca",
    symbol: "METAR",
    slug: "metaraca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaraca.png",
  },
  {
    name: "Idle Ninja Online",
    symbol: "NINKY",
    slug: "idle-ninja-online",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idle-ninja-online.png",
  },
  {
    name: "BondAppétit Governance Token",
    symbol: "BAG",
    slug: "bondappetit-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bondappetit-governance-token.png",
  },
  {
    name: "Turtle Finance",
    symbol: "TRE",
    slug: "turtle-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turtle-finance.png",
  },
  {
    name: "EarnBUSD",
    symbol: "EBUSD",
    slug: "earnbusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnbusd.png",
  },
  {
    name: "Wrapped LEO",
    symbol: "WLEO",
    slug: "wrapped-leo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-leo.png",
  },
  {
    name: "Goal",
    symbol: "GOAL",
    slug: "goal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goal.png",
  },
  {
    name: "Incognito",
    symbol: "PRV",
    slug: "incognito",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/incognito.png",
  },
  {
    name: "Fenix Finance",
    symbol: "FENIX",
    slug: "fenix-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fenix-finance.png",
  },
  {
    name: "Astro Token",
    symbol: "ABB",
    slug: "astro-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astro-token.png",
  },
  {
    name: "Stone",
    symbol: "0NE",
    slug: "stone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stone.png",
  },
  {
    name: "Day Of Defeat",
    symbol: "DOD",
    slug: "day-of-defeat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/day-of-defeat.png",
  },
  {
    name: "Aavegotchi KEK",
    symbol: "KEK",
    slug: "aavegotchi-kek",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aavegotchi-kek.png",
  },
  {
    name: "Eggplant Finance",
    symbol: "EGGP",
    slug: "eggplant-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eggplant-finance.png",
  },
  {
    name: "SPDR S&P 500 ETF tokenized stock Bittrex",
    symbol: "SPY",
    slug: "spdr-sp-500-etf-tokenized-stock-bittrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spdr-sp-500-etf-tokenized-stock-bittrex.png",
  },
  {
    name: "Alchemix USD",
    symbol: "ALUSD",
    slug: "alchemix-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alchemix-usd.png",
  },
  {
    name: "BenePit Protocol",
    symbol: "BNP",
    slug: "benepit-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/benepit-protocol.png",
  },
  {
    name: "LUXOCHAIN",
    symbol: "LUXO",
    slug: "luxochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luxochain.png",
  },
  {
    name: "CheeseFry",
    symbol: "CHEESE",
    slug: "chfry-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chfry-finance.png",
  },
  {
    name: "MyOwnItem",
    symbol: "MOI",
    slug: "myownitem-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myownitem-v2.png",
  },
  {
    name: "Earnfinex",
    symbol: "EFX",
    slug: "earnfinex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnfinex.png",
  },
  {
    name: "Kawakami Inu",
    symbol: "KAWA",
    slug: "kawakami-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kawakami-inu.png",
  },
  {
    name: "Kranz Token",
    symbol: "KRZ",
    slug: "kranz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kranz-token.png",
  },
  {
    name: "Learning Cash",
    symbol: "EAD",
    slug: "learning-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/learning-cash.png",
  },
  {
    name: "KillSwitch",
    symbol: "KSW",
    slug: "killswitch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/killswitch.png",
  },
  {
    name: "HASH Token",
    symbol: "HASH",
    slug: "hash-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hash-token.png",
  },
  {
    name: "Minty Art",
    symbol: "MINTY",
    slug: "minty-art",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minty-art.png",
  },
  {
    name: "Fintropy",
    symbol: "FINT",
    slug: "fintropy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fintropy.png",
  },
  {
    name: "Tongtongcoin",
    symbol: "TTC",
    slug: "tongtongcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tongtongcoin.png",
  },
  {
    name: "AnpanSwap",
    symbol: "ANPAN",
    slug: "anpanswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anpanswap-token.png",
  },
  {
    name: "renFIL",
    symbol: "RENFIL",
    slug: "renfil",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/renfil.png",
  },
  {
    name: "Decentralized Activism",
    symbol: "DACT",
    slug: "decentralized-activism",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-activism.png",
  },
  {
    name: "Sonne Finance",
    symbol: "SONNE",
    slug: "sonne-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonne-finance.png",
  },
  {
    name: "SafeBank YES",
    symbol: "SAFEBANK",
    slug: "safebank-yes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safebank-yes.png",
  },
  {
    name: "MDsquare",
    symbol: "TMED",
    slug: "mdsquare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mdsquare.png",
  },
  {
    name: "Broovs Projects",
    symbol: "BRS",
    slug: "broovs-projects",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/broovs-projects.png",
  },
  {
    name: "HornHub finance",
    symbol: "HHUB",
    slug: "hornhub-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hornhub-finance.png",
  },
  {
    name: "Premia",
    symbol: "PREMIA",
    slug: "premia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/premia.png",
  },
  {
    name: "Potato",
    symbol: "POTATO",
    slug: "potato",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/potato.png",
  },
  {
    name: "Bamboo Coin",
    symbol: "BMBO",
    slug: "bamboo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bamboo-coin.png",
  },
  {
    name: "Freight Trust & Clearing Network",
    symbol: "EDI",
    slug: "freight-trust-clearing-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freight-trust-clearing-network.png",
  },
  {
    name: "MCity",
    symbol: "MCT",
    slug: "mcity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcity.png",
  },
  {
    name: "Hachiko Inu",
    symbol: "HACHIKO",
    slug: "hachiko-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hachiko-inu.png",
  },
  {
    name: "Noah's Ark Coin",
    symbol: "NAC",
    slug: "noah-s-ark-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noah-s-ark-coin.png",
  },
  {
    name: "NVIDIA tokenized stock FTX",
    symbol: "NVDA",
    slug: "nvidia-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nvidia-tokenized-stock-ftx.png",
  },
  {
    name: "ApeLab",
    symbol: "APE",
    slug: "apelab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apelab.png",
  },
  {
    name: "Mixin",
    symbol: "XIN",
    slug: "mixin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mixin.png",
  },
  {
    name: "APIDAI",
    symbol: "APIDAI",
    slug: "apidai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apidai.png",
  },
  {
    name: "SLOTHI",
    symbol: "SLTH",
    slug: "slothi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slothi.png",
  },
  {
    name: "IceCream Finance",
    symbol: "CREAM",
    slug: "icecream-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icecream-finance.png",
  },
  {
    name: "Okuru",
    symbol: "XOT",
    slug: "okuru",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okuru.png",
  },
  {
    name: "KichiCoin",
    symbol: "KICH",
    slug: "kichicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kichicoin.png",
  },
  {
    name: "Pube finance",
    symbol: "PUBE",
    slug: "pube-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pube-finance.png",
  },
  {
    name: "FlokiBonk",
    symbol: "FLOBO",
    slug: "flokibonk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokibonk.png",
  },
  {
    name: "ThreeOh DAO",
    symbol: "3OH",
    slug: "threeoh-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/threeoh-dao.png",
  },
  {
    name: "Orion",
    symbol: "ORION",
    slug: "orion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orion.png",
  },
  {
    name: "Dogey-Inu",
    symbol: "DINU",
    slug: "dogey-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogey-inu.png",
  },
  {
    name: "LP 3pool Curve",
    symbol: "3CRV",
    slug: "lp-3pool-curve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lp-3pool-curve.png",
  },
  {
    name: "FirstHare",
    symbol: "FirstHare",
    slug: "firsthare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firsthare.png",
  },
  {
    name: "Guzzler",
    symbol: "GZLR",
    slug: "guzzler",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guzzler.png",
  },
  {
    name: "Saitoki Inu",
    symbol: "SAITOKI",
    slug: "saitoki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saitoki-inu.png",
  },
  {
    name: "WHEN Token",
    symbol: "WHEN",
    slug: "when-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/when-token.png",
  },
  {
    name: "Web Coin Pay",
    symbol: "WEC",
    slug: "web-coin-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web-coin-pay.png",
  },
  {
    name: "Zeronauts",
    symbol: "ZNS",
    slug: "zeronauts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeronauts.png",
  },
  {
    name: "WIVA",
    symbol: "WIVA",
    slug: "wiva",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wiva.png",
  },
  {
    name: "Jewel",
    symbol: "JWL",
    slug: "jewel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jewel.png",
  },
  {
    name: "Tether Avalanche Bridged",
    symbol: "USDTE",
    slug: "tether-avalanche-bridged",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tether-avalanche-bridged.png",
  },
  {
    name: "EdgeSwap",
    symbol: "EGS",
    slug: "edgeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edgeswap.png",
  },
  {
    name: "Herpes",
    symbol: "HERPES",
    slug: "herpes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herpes.png",
  },
  {
    name: "ANS Coin",
    symbol: "ANS",
    slug: "ans-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ans-coin.png",
  },
  {
    name: "Dystopia",
    symbol: "DYST",
    slug: "dystopia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dystopia.png",
  },
  {
    name: "ShibaBNB.org",
    symbol: "SHIBABNB",
    slug: "shibabnb-org",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibabnb-org.png",
  },
  {
    name: "Domi Online",
    symbol: "DOMI",
    slug: "domi-online",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/domi-online.png",
  },
  {
    name: "SPLASH",
    symbol: "SPL",
    slug: "splash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/splash.png",
  },
  {
    name: "CoinRadr",
    symbol: "RADR",
    slug: "coinradr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinradr.png",
  },
  {
    name: "Anortis",
    symbol: "ANORTIS",
    slug: "anortis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anortis.png",
  },
  {
    name: "Dionpay",
    symbol: "DION",
    slug: "dionpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dionpay.png",
  },
  {
    name: "Axolotl Finance",
    symbol: "AXO",
    slug: "axolotl-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axolotl-finance.png",
  },
  {
    name: "Metahub Coin",
    symbol: "MHB",
    slug: "metahub-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metahub-coin.png",
  },
  {
    name: "Aqar Chain",
    symbol: "AQR",
    slug: "aqar-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aqar-chain.png",
  },
  {
    name: "SafeMoon.swap",
    symbol: "SFMS",
    slug: "safemoon-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemoon-swap.png",
  },
  {
    name: "InfinityGaming",
    symbol: "PLAY",
    slug: "infinitygaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitygaming.png",
  },
  {
    name: "Havens Nook",
    symbol: "HXN",
    slug: "havens-nook",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/havens-nook.png",
  },
  {
    name: "Num ARS",
    symbol: "NUARS",
    slug: "num-ars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/num-ars.png",
  },
  {
    name: "Shambala",
    symbol: "BALA",
    slug: "shambala",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shambala.png",
  },
  {
    name: "Space Monkey",
    symbol: "SPMK",
    slug: "space-monkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-monkey.png",
  },
  {
    name: "Xpose Protocol",
    symbol: "XPOSE",
    slug: "xpose",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xpose.png",
  },
  {
    name: "Changer",
    symbol: "CNG",
    slug: "changer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/changer.png",
  },
  {
    name: "Baby Lovely Inu",
    symbol: "BLOVELY",
    slug: "baby-lovely-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-lovely-inu.png",
  },
  {
    name: "oBTC",
    symbol: "OBTC",
    slug: "obtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/obtc.png",
  },
  {
    name: "CatzCoin",
    symbol: "CATZ",
    slug: "catzcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catzcoin.png",
  },
  {
    name: "Roaring Twenties",
    symbol: "ROAR",
    slug: "roaring-twenties",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roaring-twenties.png",
  },
  {
    name: "APPLEB",
    symbol: "APPLEB",
    slug: "appleb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/appleb.png",
  },
  {
    name: "LevelUp Gaming",
    symbol: "LVLUP",
    slug: "levelup-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/levelup-gaming.png",
  },
  {
    name: "Halloween Floki",
    symbol: "FLOH",
    slug: "halloween-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/halloween-floki.png",
  },
  {
    name: "Exchange Payment Coin",
    symbol: "EXP",
    slug: "exchange-payment-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exchange-payment-coin.png",
  },
  {
    name: "Son of Elon",
    symbol: "SOE",
    slug: "son-of-elon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/son-of-elon.png",
  },
  {
    name: "Mango Finance",
    symbol: "MANGO",
    slug: "mango-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mango-finance.png",
  },
  {
    name: "HTMOON",
    symbol: "HTMOON",
    slug: "htmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/htmoon.png",
  },
  {
    name: "Kintsugi BTC",
    symbol: "KBTC",
    slug: "kintsugi-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kintsugi-btc.png",
  },
  {
    name: "Unite Finance",
    symbol: "UNITE",
    slug: "unite-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unite-finance.png",
  },
  {
    name: "E-leven",
    symbol: "ELV",
    slug: "e-leven",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/e-leven.png",
  },
  {
    name: "ACryptoSI",
    symbol: "ACSI",
    slug: "acryptosi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acryptosi.png",
  },
  {
    name: "United",
    symbol: "UTED",
    slug: "united",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/united.png",
  },
  {
    name: "Nfans",
    symbol: "NFS",
    slug: "nfans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nfans.png",
  },
  {
    name: "Doge Father Token",
    symbol: "DOGETF",
    slug: "doge-father-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-father-token.png",
  },
  {
    name: "Chihua Token",
    symbol: "CHIMOM",
    slug: "chihua-chimon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chihua-chimon.png",
  },
  {
    name: "Fortune",
    symbol: "FORTUNE",
    slug: "fortune",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fortune.png",
  },
  {
    name: "Moonpad",
    symbol: "MPAD",
    slug: "moonpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonpad.png",
  },
  {
    name: "Loop X Network",
    symbol: "LOOP",
    slug: "loop-x-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loop-x-network.png",
  },
  {
    name: "Pie Share",
    symbol: "PIE",
    slug: "pie-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pie-share.png",
  },
  {
    name: "Hyper Deflate",
    symbol: "HDFL",
    slug: "hyper-deflate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyper-deflate.png",
  },
  {
    name: "Cryptographic Ultra Money",
    symbol: "CUM",
    slug: "cryptographic-ultra-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptographic-ultra-money.png",
  },
  {
    name: "Stabledoc",
    symbol: "SDT",
    slug: "stabledoc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stabledoc.png",
  },
  {
    name: "QoWatt",
    symbol: "QWT",
    slug: "qowatt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qowatt.png",
  },
  {
    name: "CitaDAO",
    symbol: "KNIGHT",
    slug: "citadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/citadao.png",
  },
  {
    name: "HappinessToken",
    symbol: "HPS",
    slug: "happinesstoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/happinesstoken.png",
  },
  {
    name: "Value Network",
    symbol: "VNTW",
    slug: "value-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/value-network.png",
  },
  {
    name: "DFS MAFIA",
    symbol: "DFSM",
    slug: "dfs-mafia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfs-mafia.png",
  },
  {
    name: "DeHealth",
    symbol: "DHLT",
    slug: "dehealth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dehealth.png",
  },
  {
    name: "Metaverse-Dao",
    symbol: "METADAO",
    slug: "metaversedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaversedao.png",
  },
  {
    name: "3x Long Bitcoin SV Token",
    symbol: "BSVBULL",
    slug: "3x-long-bitcoin-sv-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-bitcoin-sv-token.png",
  },
  {
    name: "City Tycoon Games",
    symbol: "CTG",
    slug: "city-tycoon-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/city-tycoon-games.png",
  },
  {
    name: "Metahamster",
    symbol: "MHAM",
    slug: "metahamster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metahamster.png",
  },
  {
    name: "Defi Coin",
    symbol: "DEFC",
    slug: "defi-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-coin.png",
  },
  {
    name: "Samoyedcoin",
    symbol: "SAMO",
    slug: "somoyedcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/somoyedcoin.png",
  },
  {
    name: "eosBLACK",
    symbol: "BLACK",
    slug: "eosblack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eosblack.png",
  },
  {
    name: "Pulse Token",
    symbol: "PULSE",
    slug: "pulse-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pulse-token.png",
  },
  {
    name: "USDL",
    symbol: "USDL",
    slug: "usdl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdl.png",
  },
  {
    name: "HK Coin",
    symbol: "HKC",
    slug: "hk-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hk-coin.png",
  },
  {
    name: "Tail",
    symbol: "TAIL",
    slug: "tail",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tail.png",
  },
  {
    name: "JoJo Inu",
    symbol: "JOJO",
    slug: "jojo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jojo-inu.png",
  },
  {
    name: "Stellar Gold",
    symbol: "XLMG",
    slug: "stellar-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stellar-gold.png",
  },
  {
    name: "Waffle",
    symbol: "WAF",
    slug: "waffle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waffle.png",
  },
  {
    name: "Xriba",
    symbol: "XRA",
    slug: "xriba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xriba.png",
  },
  {
    name: "Keeps Coin",
    symbol: "KPC",
    slug: "keeps-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keeps-blockchain.png",
  },
  {
    name: "BigShortBets",
    symbol: "BIGSB",
    slug: "bigshortbets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bigshortbets.png",
  },
  {
    name: "HUP.LIFE",
    symbol: "HUP",
    slug: "hup-life",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hup-life.png",
  },
  {
    name: "MultiPlanetary Inus",
    symbol: "INUS",
    slug: "multiplanetary-inus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multiplanetary-inus.png",
  },
  {
    name: "SeahorseChain",
    symbol: "SEAH",
    slug: "seahorsechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seahorsechain.png",
  },
  {
    name: "Phuture",
    symbol: "PHTR",
    slug: "phuture",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phuture.png",
  },
  {
    name: "Thunder Lands",
    symbol: "TNDR",
    slug: "thunder-lands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thunder-lands.png",
  },
  {
    name: "Goatcoin",
    symbol: "GOAT",
    slug: "goatcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goatcoin.png",
  },
  {
    name: "YuzuSwap",
    symbol: "YUZU",
    slug: "yuzuswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yuzuswap.png",
  },
  {
    name: "Space Vikings",
    symbol: "SVT",
    slug: "space-vikings",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-vikings.png",
  },
  {
    name: "StarSlax",
    symbol: "SSLX",
    slug: "starslax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starslax.png",
  },
  {
    name: "PUGLIFE",
    symbol: "PUGL",
    slug: "puglife",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puglife.png",
  },
  {
    name: "Two Monkey Juice Bar",
    symbol: "TMON",
    slug: "two-monkey-juice-bar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/two-monkey-juice-bar.png",
  },
  {
    name: "Unique One",
    symbol: "RARE",
    slug: "unique-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unique-one.png",
  },
  {
    name: "TNNS",
    symbol: "TNNS",
    slug: "tnns",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tnns.png",
  },
  {
    name: "SaunaFinance Token",
    symbol: "SAUNA",
    slug: "saunafinance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saunafinance-token.png",
  },
  {
    name: "Glitchy",
    symbol: "GLITCHY",
    slug: "glitchy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glitchy.png",
  },
  {
    name: "Scotty Beam",
    symbol: "SCOTTY",
    slug: "scottybeam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scottybeam.png",
  },
  {
    name: "BNBBack",
    symbol: "BNBBACK",
    slug: "bnbback",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbback.png",
  },
  {
    name: "COLD FINANCE",
    symbol: "COLD",
    slug: "cold-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cold-finance.png",
  },
  {
    name: "Silverway",
    symbol: "SLV",
    slug: "silverway",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silverway.png",
  },
  {
    name: "Bingus Network",
    symbol: "BINGUS",
    slug: "bingus-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bingus-network.png",
  },
  {
    name: "SMART SHIBA",
    symbol: "SMARTSHIB",
    slug: "smart-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-shiba.png",
  },
  {
    name: "DeFi100",
    symbol: "D100",
    slug: "defi100",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi100.png",
  },
  {
    name: "Wiggly Finance",
    symbol: "WGL",
    slug: "wiggly-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wiggly-finance.png",
  },
  {
    name: "Raptoreum",
    symbol: "RTM",
    slug: "raptoreum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raptoreum.png",
  },
  {
    name: "IOU",
    symbol: "IOUX",
    slug: "iou",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iou.png",
  },
  {
    name: "Dii Coin",
    symbol: "DIIC",
    slug: "dii-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dii-coin.png",
  },
  {
    name: "KOROMARU",
    symbol: "KOROMARU",
    slug: "koromaru",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koromaru.png",
  },
  {
    name: "Coinbase tokenized stock FTX",
    symbol: "COIN",
    slug: "coinbase-pre-ipo-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinbase-pre-ipo-tokenized-stock-ftx.png",
  },
  {
    name: "T1 Racing",
    symbol: "T1",
    slug: "t1-racing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/t1-racing.png",
  },
  {
    name: "Heal The World",
    symbol: "HEAL",
    slug: "heal-the-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heal-the-world.png",
  },
  {
    name: "Project: One Whale",
    symbol: "POW",
    slug: "project-one-whale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-one-whale.png",
  },
  {
    name: "chikn feed",
    symbol: "FEED",
    slug: "chikn-feed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chikn-feed.png",
  },
  {
    name: "CryptoFantasy",
    symbol: "CFC",
    slug: "cryptofantasy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptofantasy.png",
  },
  {
    name: "VELOREX",
    symbol: "VEX",
    slug: "velorex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/velorex.png",
  },
  {
    name: "NeoFi",
    symbol: "NEOFI",
    slug: "neofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neofi.png",
  },
  {
    name: "Trade Butler Bot",
    symbol: "TBB",
    slug: "trade-butler-bot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trade-butler-bot.png",
  },
  {
    name: "SkyX Token",
    symbol: "SKYX",
    slug: "skyx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skyx-token.png",
  },
  {
    name: "Boorio",
    symbol: "ORIO",
    slug: "boorio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boorio.png",
  },
  {
    name: "YouCoin Metaverse",
    symbol: "UCON",
    slug: "youcoin-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/youcoin-metaverse.png",
  },
  {
    name: "E1337",
    symbol: "1337",
    slug: "e1337",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/e1337.png",
  },
  {
    name: "3X Long VeChain Token",
    symbol: "VETBULL",
    slug: "3x-long-vechain-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-vechain-token.png",
  },
  {
    name: "Pulsar Token",
    symbol: "$PULSAR",
    slug: "pulsar-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pulsar-token.png",
  },
  {
    name: "LassoCoin",
    symbol: "LASSO",
    slug: "lassocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lassocoin.png",
  },
  {
    name: "3x Short Bitcoin SV Token",
    symbol: "BSVBEAR",
    slug: "3x-short-bitcoin-sv-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-bitcoin-sv-token.png",
  },
  {
    name: "forbitspace",
    symbol: "FBS",
    slug: "forbitspace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forbitspace.png",
  },
  {
    name: "DigiDinar Token",
    symbol: "DDRT",
    slug: "digidinar-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digidinar-token.png",
  },
  {
    name: "Catcoin",
    symbol: "CATS",
    slug: "cat-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cat-coin.png",
  },
  {
    name: "YetiSwap",
    symbol: "YTS",
    slug: "yetiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yetiswap.png",
  },
  {
    name: "Project Plutus",
    symbol: "PLU",
    slug: "project-plutus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-plutus.png",
  },
  {
    name: "Bolt Dollar",
    symbol: "BTD",
    slug: "bat-true-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bat-true-dollar.png",
  },
  {
    name: "Death Token",
    symbol: "DEATH",
    slug: "death-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/death-token.png",
  },
  {
    name: "Schrodinger",
    symbol: "KITTY DINGER",
    slug: "schrodinger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/schrodinger.png",
  },
  {
    name: "Wenlambo",
    symbol: "WENLAMBO",
    slug: "wenlambo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wenlambo.png",
  },
  {
    name: "JustLiquidity Binance",
    symbol: "JULB",
    slug: "justliquidity-binance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/justliquidity-binance.png",
  },
  {
    name: "Digifit",
    symbol: "DGI",
    slug: "digifit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digifit.png",
  },
  {
    name: "Metarix",
    symbol: "MTRX",
    slug: "metarix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metarix.png",
  },
  {
    name: "Swirl Cash",
    symbol: "SWIRL",
    slug: "swirl-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swirl-cash.png",
  },
  {
    name: "Maximus Token",
    symbol: "MAXI",
    slug: "maximus-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maximus-token.png",
  },
  {
    name: "Geopoly",
    symbol: "GEO$",
    slug: "geopoly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geopoly.png",
  },
  {
    name: "Sunflower Land",
    symbol: "SFL",
    slug: "sunflower-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sunflower-land.png",
  },
  {
    name: "MetaDubai",
    symbol: "MDB",
    slug: "metadubai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadubai.png",
  },
  {
    name: "HebeBlock",
    symbol: "HEBE",
    slug: "hebeblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hebeblock.png",
  },
  {
    name: "Dreamr",
    symbol: "DMR",
    slug: "dreamr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dreamr.png",
  },
  {
    name: "Billibilli Inc tokenized stock FTX",
    symbol: "BILI",
    slug: "billibilli-inc-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/billibilli-inc-tokenized-stock-ftx.png",
  },
  {
    name: "Baby Pig Token",
    symbol: "BABYPIG",
    slug: "baby-pig-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-pig-token.png",
  },
  {
    name: "PoliceDOGE",
    symbol: "POLICEDOGE",
    slug: "policedoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/policedoge.png",
  },
  {
    name: "BitcoinBR",
    symbol: "BTCBR",
    slug: "bitcoinbr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinbr.png",
  },
  {
    name: "IoTex Pad",
    symbol: "TEX",
    slug: "iotex-pad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iotex-pad.png",
  },
  {
    name: "SaitaRealty",
    symbol: "SRLTY",
    slug: "saitarealty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saitarealty.png",
  },
  {
    name: "RYI Platinum",
    symbol: "RYIP",
    slug: "ryi-platinum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ryi-platinum.png",
  },
  {
    name: "Alpaca USD",
    symbol: "AUSD",
    slug: "alpaca-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpaca-usd.png",
  },
  {
    name: "GOMAx",
    symbol: "GOMAX",
    slug: "gomax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gomax.png",
  },
  {
    name: "ProperSix",
    symbol: "PSIX",
    slug: "propersix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/propersix.png",
  },
  {
    name: "Teddy Cash",
    symbol: "TEDDY",
    slug: "teddy-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teddy-cash.png",
  },
  {
    name: "Fractionalized SMB-2367",
    symbol: "DAOJONES",
    slug: "fractionalized-smb-2367",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fractionalized-smb-2367.png",
  },
  {
    name: "3X Short Cardano Token",
    symbol: "ADABEAR",
    slug: "3x-short-cardano-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-cardano-token.png",
  },
  {
    name: "Yofune Nushi",
    symbol: "KOYO",
    slug: "yofune-nushi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yofune-nushi.png",
  },
  {
    name: "Starbound",
    symbol: "SBD",
    slug: "starbound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starbound.png",
  },
  {
    name: "NFTTONE",
    symbol: "TONE",
    slug: "nfttone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nfttone.png",
  },
  {
    name: "Blockasset",
    symbol: "BLOCK",
    slug: "blockasset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockasset.png",
  },
  {
    name: "Empire Token",
    symbol: "EMPIRE",
    slug: "empire-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/empire-token.png",
  },
  {
    name: "Advanced Internet Blocks",
    symbol: "AIB",
    slug: "advanced-internet-blocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/advanced-internet-blocks.png",
  },
  {
    name: "LittleDoge",
    symbol: "LITTLEDOGE",
    slug: "littledoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/littledoge.png",
  },
  {
    name: "ALFweb3Project",
    symbol: "ALFW3",
    slug: "alfweb3project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alfweb3project.png",
  },
  {
    name: "SaitaMars",
    symbol: "SARS",
    slug: "saitamars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saitamars.png",
  },
  {
    name: "ZudgeZury",
    symbol: "ZZC",
    slug: "zudgezury",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zudgezury.png",
  },
  {
    name: "Honey Defi",
    symbol: "HONEY",
    slug: "honey-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honey-defi.png",
  },
  {
    name: "Winlambo",
    symbol: "WINLAMBO",
    slug: "winlambo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winlambo.png",
  },
  {
    name: "Tenet",
    symbol: "TEN",
    slug: "tenet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tenet.png",
  },
  {
    name: "SCARDust",
    symbol: "SCARD",
    slug: "scardust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scardust.png",
  },
  {
    name: "Viva Classic",
    symbol: "VIVA",
    slug: "viva-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viva-classic.png",
  },
  {
    name: "Coinsale",
    symbol: "CSF",
    slug: "coinsale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinsale.png",
  },
  {
    name: "Rank Token",
    symbol: "RANK",
    slug: "rank-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rank-token.png",
  },
  {
    name: "D-Skyscraper",
    symbol: "DSG",
    slug: "d-skyscraper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/d-skyscraper.png",
  },
  {
    name: "MOBIX",
    symbol: "MOBX",
    slug: "mobix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobix.png",
  },
  {
    name: "OkLetsPlay",
    symbol: "OKLP",
    slug: "okletsplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okletsplay.png",
  },
  {
    name: "Deflect",
    symbol: "DEFLCT",
    slug: "deflect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deflect.png",
  },
  {
    name: "Dog Collar",
    symbol: "COLLAR",
    slug: "dog-collar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dog-collar.png",
  },
  {
    name: "Rigel Protocol",
    symbol: "RGP",
    slug: "rigel-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rigel-protocol.png",
  },
  {
    name: "GEM PROTOCOL",
    symbol: "GEM",
    slug: "gem-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gem-protocol.png",
  },
  {
    name: "Matic Aave Interest Bearing UNI",
    symbol: "MAUNI",
    slug: "matic-aave-uni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matic-aave-uni.png",
  },
  {
    name: "Mafagafo",
    symbol: "MAFA",
    slug: "mafagafo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mafagafo.png",
  },
  {
    name: "SUNI",
    symbol: "SUNI",
    slug: "suni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/suni.png",
  },
  {
    name: "PulseApeCoin",
    symbol: "$PLSA",
    slug: "pulseapecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pulseapecoin.png",
  },
  {
    name: "TOKE.N",
    symbol: "TOKE.N",
    slug: "thetokenproject",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thetokenproject.png",
  },
  {
    name: "Yeti Finance",
    symbol: "YETI",
    slug: "yeti-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yeti-finance.png",
  },
  {
    name: "Tag Protocol",
    symbol: "TAG",
    slug: "tag-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tag-protocol.png",
  },
  {
    name: "Decurian",
    symbol: "ECU",
    slug: "decurian",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decurian.png",
  },
  {
    name: "MillionMonke",
    symbol: "MIMO",
    slug: "millionmonke",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/millionmonke.png",
  },
  {
    name: "Pfizer tokenized stock FTX",
    symbol: "PFE",
    slug: "pfizer-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pfizer-tokenized-stock-ftx.png",
  },
  {
    name: "Milky Token",
    symbol: "MILKY",
    slug: "milky-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milky-token.png",
  },
  {
    name: "AVAXTARS",
    symbol: "AVXT",
    slug: "avaxtars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaxtars.png",
  },
  {
    name: "Asset Backed Protocol",
    symbol: "ABP",
    slug: "asset-backed-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asset-backed-protocol.png",
  },
  {
    name: "MIDA",
    symbol: "MIDA",
    slug: "mida",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mida.png",
  },
  {
    name: "Capybara",
    symbol: "CAPY",
    slug: "capybara",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/capybara.png",
  },
  {
    name: "Baby FPS Token",
    symbol: "BFPS",
    slug: "baby-fps-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-fps-token.png",
  },
  {
    name: "Arcadia Token",
    symbol: "$ARC",
    slug: "arcadia-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcadia-token.png",
  },
  {
    name: "MP4",
    symbol: "MP4",
    slug: "mp4",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mp4.png",
  },
  {
    name: "GOGO.finance",
    symbol: "GOGO",
    slug: "gogo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gogo-finance.png",
  },
  {
    name: "Papp Mobile",
    symbol: "PAPP",
    slug: "papp-mobile",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/papp-mobile.png",
  },
  {
    name: "Vault Hill City",
    symbol: "VHC",
    slug: "vault-hill-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vault-hill-city.png",
  },
  {
    name: "WORKIT",
    symbol: "WKIT",
    slug: "workit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/workit.png",
  },
  {
    name: "SatelStar",
    symbol: "STSR",
    slug: "satelstar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satelstar.png",
  },
  {
    name: "Dogefather",
    symbol: "DOGEFATHER",
    slug: "dogefather",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogefather.png",
  },
  {
    name: "Starcoin",
    symbol: "STC",
    slug: "starcoin-stc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starcoin-stc.png",
  },
  {
    name: "We Are Nasty",
    symbol: "NASTY",
    slug: "we-are-nasty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/we-are-nasty.png",
  },
  {
    name: "Galaxy Finance",
    symbol: "GLF",
    slug: "galaxy-finance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-finance-token.png",
  },
  {
    name: "Fundum Capital",
    symbol: "FND",
    slug: "fundum-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fundum-capital.png",
  },
  {
    name: "MetamonkeyAi",
    symbol: "MMAI",
    slug: "metamonkeyai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamonkeyai.png",
  },
  {
    name: "Traverse",
    symbol: "VERSE",
    slug: "traverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/traverse.png",
  },
  {
    name: "NPC Coin",
    symbol: "NPC",
    slug: "npc-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/npc-coin.png",
  },
  {
    name: "Digible",
    symbol: "DIGI",
    slug: "digible",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digible.png",
  },
  {
    name: "TokoNFT",
    symbol: "TKN",
    slug: "tokonft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokonft.png",
  },
  {
    name: "FlokiGrow",
    symbol: "FLOKIGROW",
    slug: "flokigrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokigrow.png",
  },
  {
    name: "HaloDAO",
    symbol: "RNBW",
    slug: "halodao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/halodao.png",
  },
  {
    name: "ShapePay",
    symbol: "SPP",
    slug: "shapepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shapepay.png",
  },
  {
    name: "Bomberman",
    symbol: "BOMB",
    slug: "bomberman",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bomberman.png",
  },
  {
    name: "Dominium",
    symbol: "DOM",
    slug: "dominium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dominium.png",
  },
  {
    name: "Micro Bitcoin Finance",
    symbol: "MBTC",
    slug: "micro-bitcoin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/micro-bitcoin-finance.png",
  },
  {
    name: "GSENetwork",
    symbol: "GSE",
    slug: "gsenetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gsenetwork.png",
  },
  {
    name: "SHINJIRO",
    symbol: "SHOX",
    slug: "shinjiro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinjiro.png",
  },
  {
    name: "KOKA INU",
    symbol: "INU",
    slug: "koka-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koka-inu.png",
  },
  {
    name: "King Arthur",
    symbol: "BKING",
    slug: "king-arthur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-arthur.png",
  },
  {
    name: "Baby Clifford Inu",
    symbol: "BBCLIFF",
    slug: "baby-clifford-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-clifford-inu.png",
  },
  {
    name: "xSPECTAR",
    symbol: "XSPECTAR",
    slug: "xspectar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xspectar.png",
  },
  {
    name: "2044 Nuclear Apocalypse",
    symbol: "2044",
    slug: "2044-nuclear-apocalypse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2044-nuclear-apocalypse.png",
  },
  {
    name: "KangarooCake",
    symbol: "KCAKE",
    slug: "kangaroocake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kangaroocake.png",
  },
  {
    name: "MetaZilla",
    symbol: "MZ",
    slug: "metazilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metazilla.png",
  },
  {
    name: "HubGame",
    symbol: "HUB",
    slug: "hubgame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hubgame.png",
  },
  {
    name: "Stopelon",
    symbol: "STOPELON",
    slug: "stopelon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stopelon.png",
  },
  {
    name: "AMPnet Asset Platform and Exchange",
    symbol: "AAPX",
    slug: "ampnet-asset-platform-and-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ampnet-asset-platform-and-exchange.png",
  },
  {
    name: "panKUKU",
    symbol: "KUKU",
    slug: "pankuku",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pankuku.png",
  },
  {
    name: "Moonlight Token",
    symbol: "MOONLIGHT",
    slug: "moonlight-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonlight-token.png",
  },
  {
    name: "SolidETH",
    symbol: "SOLIDETH",
    slug: "solideth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solideth.png",
  },
  {
    name: "Avaxworld",
    symbol: "AXW",
    slug: "avaxworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaxworld.png",
  },
  {
    name: "Bistroo",
    symbol: "BIST",
    slug: "bistroo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bistroo.png",
  },
  {
    name: "Dexflow",
    symbol: "DEXFLOW",
    slug: "dexflow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexflow.png",
  },
  {
    name: "Lux Bio Cell",
    symbol: "LBXC",
    slug: "lux-bio-cell",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lux-bio-cell.png",
  },
  {
    name: "BabyShibby Inu",
    symbol: "BABYSHIB",
    slug: "babyshibby-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyshibby-inu.png",
  },
  {
    name: "EggPlus",
    symbol: "EGGPLUS",
    slug: "eggplus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eggplus.png",
  },
  {
    name: "LilCheems",
    symbol: "LCINU",
    slug: "lilcheems",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lilcheems.png",
  },
  {
    name: "MetaDoge",
    symbol: "METADOGE",
    slug: "metadoge-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadoge-token.png",
  },
  {
    name: "Modex",
    symbol: "MODEX",
    slug: "modex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/modex.png",
  },
  {
    name: "Feudalz Goldz",
    symbol: "GOLDZ",
    slug: "feudalz-goldz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feudalz-goldz.png",
  },
  {
    name: "Smart World Union",
    symbol: "SWU",
    slug: "smart-world-union",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-world-union.png",
  },
  {
    name: "CoreStarter",
    symbol: "CSTR",
    slug: "corestarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corestarter.png",
  },
  {
    name: "ARTH Shares",
    symbol: "ARTHX",
    slug: "arthx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arthx.png",
  },
  {
    name: "LunaLand",
    symbol: "LLN",
    slug: "lunaland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunaland.png",
  },
  {
    name: "Cubics",
    symbol: "CUBIC",
    slug: "cubics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cubics.png",
  },
  {
    name: "SportX",
    symbol: "SX",
    slug: "sportx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sportx.png",
  },
  {
    name: "Shiberus Inu",
    symbol: "SHIBERUS",
    slug: "shiberus-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiberus-inu.png",
  },
  {
    name: "YodeSwap",
    symbol: "YODE",
    slug: "yodeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yodeswap.png",
  },
  {
    name: "BunnyVerse",
    symbol: "BNV",
    slug: "bunnyverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunnyverse.png",
  },
  {
    name: "AKITSUKI",
    symbol: "AKI",
    slug: "akitsuki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akitsuki.png",
  },
  {
    name: "Enedex",
    symbol: "ENE",
    slug: "enedex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enedex.png",
  },
  {
    name: "SCARY",
    symbol: "SCY",
    slug: "scary-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scary-games.png",
  },
  {
    name: "Tatcoin",
    symbol: "TAT",
    slug: "tatcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tatcoin.png",
  },
  {
    name: "yBEARSwap",
    symbol: "SBEAR",
    slug: "ybearswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ybearswap.png",
  },
  {
    name: "Everlens",
    symbol: "ELEN",
    slug: "everlens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everlens.png",
  },
  {
    name: "WETUX",
    symbol: "WETUX",
    slug: "wetux",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wetux.png",
  },
  {
    name: "Metaloop Tech",
    symbol: "MLT",
    slug: "metaloop-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaloop-tech.png",
  },
  {
    name: "ALYATTES",
    symbol: "ALYA",
    slug: "alyattes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alyattes.png",
  },
  {
    name: "ESR Coin",
    symbol: "ESRC",
    slug: "esr-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/esr-coin.png",
  },
  {
    name: "NFT SolPad",
    symbol: "NFTSOL",
    slug: "nft-solpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-solpad.png",
  },
  {
    name: "TitsV2",
    symbol: "TITS",
    slug: "titsv2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/titsv2.png",
  },
  {
    name: "Talent",
    symbol: "TNT",
    slug: "talent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/talent.png",
  },
  {
    name: "LIQ Protocol",
    symbol: "LIQ",
    slug: "liq-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liq-protocol.png",
  },
  {
    name: "Flits",
    symbol: "FLS",
    slug: "flits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flits.png",
  },
  {
    name: "Safe Protocol",
    symbol: "SAFEP",
    slug: "safe-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-protocol.png",
  },
  {
    name: "Panda Girl",
    symbol: "PGIRL",
    slug: "panda-girl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/panda-girl.png",
  },
  {
    name: "Metahero Universe (POW)",
    symbol: "POW",
    slug: "metahero-universe-pow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metahero-universe-pow.png",
  },
  {
    name: "SteakBankFinance",
    symbol: "SBF",
    slug: "steakbankfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steakbankfinance.png",
  },
  {
    name: "ReSource Protocol",
    symbol: "SOURCE",
    slug: "resource-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/resource-protocol.png",
  },
  {
    name: "URG University",
    symbol: "URG-U",
    slug: "urg-university",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/urg-university.png",
  },
  {
    name: "Hillstone",
    symbol: "HSF",
    slug: "hillstone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hillstone.png",
  },
  {
    name: "GFORCE",
    symbol: "GFCE",
    slug: "gforce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gforce.png",
  },
  {
    name: "UNIVERSE ISLAND",
    symbol: "UIM",
    slug: "universe-island",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universe-island.png",
  },
  {
    name: "NCAT Token",
    symbol: "NCAT",
    slug: "ncat-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ncat-token.png",
  },
  {
    name: "Decubate",
    symbol: "DCB",
    slug: "decubate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decubate.png",
  },
  {
    name: "Congruent",
    symbol: "GAAS",
    slug: "congruent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/congruent.png",
  },
  {
    name: "METALANDZ",
    symbol: "$METAZ",
    slug: "metalandz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metalandz.png",
  },
  {
    name: "ZeroX",
    symbol: "ZEROX",
    slug: "zerox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zerox.png",
  },
  {
    name: "Collateral Pay Governance",
    symbol: "COLLG",
    slug: "collateral-pay-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/collateral-pay-governance.png",
  },
  {
    name: "MoonJuice",
    symbol: "MOJO",
    slug: "moonjuice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonjuice.png",
  },
  {
    name: "Weecoins",
    symbol: "WCS",
    slug: "weecoins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weecoins.png",
  },
  {
    name: "Meta Kongz",
    symbol: "MKC",
    slug: "meta-kongz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-kongz.png",
  },
  {
    name: "Aimedis",
    symbol: "AIMX",
    slug: "aimedis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aimedis.png",
  },
  {
    name: "Ponzu Inu",
    symbol: "PONZU",
    slug: "ponzu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ponzu-inu.png",
  },
  {
    name: "COFFE Multichain",
    symbol: "CFF",
    slug: "coffe-multichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coffe-multichain.png",
  },
  {
    name: "Floki Rocket",
    symbol: "RLOKI",
    slug: "floki-rocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-rocket.png",
  },
  {
    name: "Siamese Neko",
    symbol: "SIAM",
    slug: "siamese-neko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/siamese-neko.png",
  },
  {
    name: "iDeFiYieldProtocol",
    symbol: "IDYP",
    slug: "idefiyieldprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idefiyieldprotocol.png",
  },
  {
    name: "Dexioprotocol",
    symbol: "DEXI",
    slug: "dexioprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexioprotocol.png",
  },
  {
    name: "Craft network",
    symbol: "CFT",
    slug: "craft-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/craft-network.png",
  },
  {
    name: "EverSAFU",
    symbol: "EVERSAFU",
    slug: "eversafu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eversafu.png",
  },
  {
    name: "BUNSCAKE",
    symbol: "BSCAKE",
    slug: "bunscake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunscake.png",
  },
  {
    name: "Dotmoovs",
    symbol: "MOOV",
    slug: "dotmoovs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dotmoovs.png",
  },
  {
    name: "Burn",
    symbol: "BURN",
    slug: "burn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burn.png",
  },
  {
    name: "Mello Token",
    symbol: "MELLO",
    slug: "mello-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mello-token.png",
  },
  {
    name: "X7DAO",
    symbol: "X7DAO",
    slug: "x7dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x7dao.png",
  },
  {
    name: "FEG Wrapped USDT",
    symbol: "fUSDT",
    slug: "feg-wrapped-usdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feg-wrapped-usdt.png",
  },
  {
    name: "CropBytes",
    symbol: "CBX",
    slug: "cropbytes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cropbytes.png",
  },
  {
    name: "Oracle System",
    symbol: "ORC",
    slug: "oracle-system",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oracle-system.png",
  },
  {
    name: "Magpie",
    symbol: "MGP",
    slug: "magpie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magpie.png",
  },
  {
    name: "ThunderETH",
    symbol: "THUNDERETH",
    slug: "thundereth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thundereth.png",
  },
  {
    name: "Numitor",
    symbol: "NUMI",
    slug: "numitor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/numitor.png",
  },
  {
    name: "Eroverse",
    symbol: "ERO",
    slug: "eroverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eroverse.png",
  },
  {
    name: "Latamcash",
    symbol: "LMCH",
    slug: "latamcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/latamcash.png",
  },
  {
    name: "Shinjutsu",
    symbol: "SHINJUTSU",
    slug: "shinjutsu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinjutsu.png",
  },
  {
    name: "GALAXY NETWORK",
    symbol: "GNC",
    slug: "galaxy-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-network.png",
  },
  {
    name: "AdaKong",
    symbol: "AKONG",
    slug: "adakong",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adakong.png",
  },
  {
    name: "Lokum Finance",
    symbol: "LKM",
    slug: "lokum-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lokum-finance.png",
  },
  {
    name: "Thors Mead",
    symbol: "MEAD",
    slug: "thors-mead",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thors-mead.png",
  },
  {
    name: "CityStates: Medieval",
    symbol: "CSM",
    slug: "citystates-medieval",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/citystates-medieval.png",
  },
  {
    name: "Gr33n",
    symbol: "BUILD",
    slug: "gr33n",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gr33n.png",
  },
  {
    name: "Doni Coin",
    symbol: "DONI",
    slug: "doni-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doni-coin.png",
  },
  {
    name: "Axial",
    symbol: "AXIAL",
    slug: "axial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axial.png",
  },
  {
    name: "Crypto Classic",
    symbol: "CRC",
    slug: "crypto-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-classic.png",
  },
  {
    name: "OKEYCOIN",
    symbol: "OKEY",
    slug: "okeycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okeycoin.png",
  },
  {
    name: "N1CE",
    symbol: "N1CE",
    slug: "n1ce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/n1ce.png",
  },
  {
    name: "Wiki Cat",
    symbol: "WKC",
    slug: "wiki-cat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wiki-cat.png",
  },
  {
    name: "Black Market Gaming",
    symbol: "BMG",
    slug: "black-market-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-market-gaming.png",
  },
  {
    name: "Cheecoin",
    symbol: "CHEE",
    slug: "cheecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheecoin.png",
  },
  {
    name: "kCoin",
    symbol: "kCoin",
    slug: "kalata-coinbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalata-coinbase.png",
  },
  {
    name: "PowerBalt",
    symbol: "PWRB",
    slug: "powerbalt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/powerbalt.png",
  },
  {
    name: "BITCOIN ADDITIONAL",
    symbol: "BTCA",
    slug: "bitcoin-additional",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-additional.png",
  },
  {
    name: "Piratera",
    symbol: "PIRA",
    slug: "piratera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piratera.png",
  },
  {
    name: "Wise Token",
    symbol: "WISB",
    slug: "wise-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wise-token.png",
  },
  {
    name: "Vanity",
    symbol: "VNY",
    slug: "vanity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanity.png",
  },
  {
    name: "Carnomaly",
    symbol: "CARR",
    slug: "carnomaly-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carnomaly-new.png",
  },
  {
    name: "PEANUTS",
    symbol: "PEANUTS",
    slug: "peanuts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peanuts.png",
  },
  {
    name: "Custodiy",
    symbol: "CTY",
    slug: "custodiy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/custodiy.png",
  },
  {
    name: "Rocket Launchpad",
    symbol: "RCKT",
    slug: "rocket-launchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-launchpad.png",
  },
  {
    name: "Dexlab",
    symbol: "DXL",
    slug: "dexlab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexlab.png",
  },
  {
    name: "ZABAKU INU",
    symbol: "ZABAKU",
    slug: "zabaku-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zabaku-inu.png",
  },
  {
    name: "Wrapped Conceal",
    symbol: "WCCX",
    slug: "wrapped-conceal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-conceal.png",
  },
  {
    name: "Catchy",
    symbol: "CATCHY",
    slug: "catchy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catchy.png",
  },
  {
    name: "DecaSwap",
    symbol: "DECA",
    slug: "decaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decaswap.png",
  },
  {
    name: "NoLimitCoin",
    symbol: "NLC2",
    slug: "nolimitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nolimitcoin.png",
  },
  {
    name: "Shiba Inu Pay",
    symbol: "SHIBAY",
    slug: "shiba-inu-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-inu-pay.png",
  },
  {
    name: "EmirateSwap",
    symbol: "EMC",
    slug: "emirateswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emirateswap.png",
  },
  {
    name: "BNBHunter",
    symbol: "BHT",
    slug: "bnbhunter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbhunter.png",
  },
  {
    name: "Totoro Inu",
    symbol: "TOTORO",
    slug: "totoro-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/totoro-inu.png",
  },
  {
    name: "Tardigrades.Finance (ETH)",
    symbol: "TRDG",
    slug: "tardigrades-finance-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tardigrades-finance-eth.png",
  },
  {
    name: "SuperMegaHyperDoge",
    symbol: "SMHDOGE",
    slug: "supermegahyperdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supermegahyperdoge.png",
  },
  {
    name: "MDB Plus",
    symbol: "MDB+",
    slug: "mdb-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mdb-plus.png",
  },
  {
    name: "Dogewhale",
    symbol: "DOGEWHALE",
    slug: "dogewhale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogewhale.png",
  },
  {
    name: "TOR",
    symbol: "TOR",
    slug: "tor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tor.png",
  },
  {
    name: "ENNO Cash",
    symbol: "ENNO",
    slug: "enno-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enno-cash.png",
  },
  {
    name: "MiniDog Finance",
    symbol: "TINIDAWG",
    slug: "minidog-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minidog-finance.png",
  },
  {
    name: "Whalemap",
    symbol: "WMP",
    slug: "whalemap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whalemap.png",
  },
  {
    name: "AAG Ventures",
    symbol: "AAG",
    slug: "aag-ventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aag-ventures.png",
  },
  {
    name: "UNIMOON",
    symbol: "UNIMOON",
    slug: "unimoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unimoon.png",
  },
  {
    name: "Crypto Rewards Studio",
    symbol: "CRS",
    slug: "crypto-rewards-studio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-rewards-studio.png",
  },
  {
    name: "OROS.finance",
    symbol: "OROS",
    slug: "oros-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oros-finance.png",
  },
  {
    name: "Hoodler",
    symbol: "HOOD",
    slug: "hoodler",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoodler.png",
  },
  {
    name: "Bankless BED Index",
    symbol: "BED",
    slug: "bankless-bed-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankless-bed-index.png",
  },
  {
    name: "Privapp Network",
    symbol: "bPRIVA",
    slug: "privapp-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/privapp-network.png",
  },
  {
    name: "Pomeranian",
    symbol: "POM",
    slug: "pomeranian",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pomeranian.png",
  },
  {
    name: "Ravendex",
    symbol: "RAVE",
    slug: "ravendex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ravendex.png",
  },
  {
    name: "No COVID19",
    symbol: "CVD19",
    slug: "no-covid19",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/no-covid19.png",
  },
  {
    name: "Mirrored Square",
    symbol: "MSQ",
    slug: "mirrored-square",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-square.png",
  },
  {
    name: "Hoodrat Finance",
    symbol: "HOODRAT",
    slug: "hoodrat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoodrat.png",
  },
  {
    name: "Baddest Alpha Ape Bundle",
    symbol: "APED",
    slug: "baddest-alpha-ape-bundle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baddest-alpha-ape-bundle.png",
  },
  {
    name: "renZEC",
    symbol: "RENZEC",
    slug: "renzec",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/renzec.png",
  },
  {
    name: "Endgame Token",
    symbol: "END",
    slug: "endgame-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/endgame-token.png",
  },
  {
    name: "Miku",
    symbol: "MIKU",
    slug: "miku",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miku.png",
  },
  {
    name: "Mystic Warrior",
    symbol: "MYSTIC",
    slug: "mystic-warrior",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mystic-warrior.png",
  },
  {
    name: "Delta",
    symbol: "DELTA",
    slug: "delta-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/delta-finance.png",
  },
  {
    name: "Tegro",
    symbol: "TGR",
    slug: "tegro-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tegro-io.png",
  },
  {
    name: "ccFound",
    symbol: "FOUND",
    slug: "ccfound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ccfound.png",
  },
  {
    name: "TrustRiseV2",
    symbol: "TRV2",
    slug: "trustrisev2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustrisev2.png",
  },
  {
    name: "MM Token",
    symbol: "MM",
    slug: "mm-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mm-token.png",
  },
  {
    name: "Waste Digital Coin",
    symbol: "WACO",
    slug: "waste-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waste-coin.png",
  },
  {
    name: "GenomesDao",
    symbol: "GENE",
    slug: "genomesdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genomesdao.png",
  },
  {
    name: "Demodyfi",
    symbol: "DMOD",
    slug: "demodyfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/demodyfi.png",
  },
  {
    name: "LooBr",
    symbol: "LOOBR",
    slug: "loobr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loobr.png",
  },
  {
    name: "bUKHI",
    symbol: "BUKH",
    slug: "bukh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bukh.png",
  },
  {
    name: "Stacker Ventures",
    symbol: "STACK",
    slug: "stacker-ventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stacker-ventures.png",
  },
  {
    name: "SWASS.FINANCE",
    symbol: "SWASS",
    slug: "swass-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swass-finance.png",
  },
  {
    name: "Rotten Floki",
    symbol: "ROTTEN",
    slug: "rotten-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rotten-floki.png",
  },
  {
    name: "Burnt Cake",
    symbol: "BCAKE",
    slug: "burnt-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burnt-cake.png",
  },
  {
    name: "X (By SpaceGrime)",
    symbol: "X",
    slug: "x-by-spacegrime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-by-spacegrime.png",
  },
  {
    name: "FrogSwap",
    symbol: "FROG",
    slug: "frogswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frogswap.png",
  },
  {
    name: "The Last McAfee Token",
    symbol: "MCAFEE",
    slug: "the-last-mcafee-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-last-mcafee-token.png",
  },
  {
    name: "UniCandy",
    symbol: "UCD",
    slug: "unicandy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicandy.png",
  },
  {
    name: "ScareCrow",
    symbol: "SCARE",
    slug: "scarecrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scarecrow.png",
  },
  {
    name: "Aspire",
    symbol: "ASP",
    slug: "aspire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aspire.png",
  },
  {
    name: "VR Blocks",
    symbol: "VRBLOCKS",
    slug: "vr-blocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vr-blocks.png",
  },
  {
    name: "Bakumatsu Swap Finance",
    symbol: "RYMA",
    slug: "bakumatsu-swap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bakumatsu-swap-finance.png",
  },
  {
    name: "DashSports",
    symbol: "DASS",
    slug: "dashsports",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dashsports.png",
  },
  {
    name: "New Chance",
    symbol: "NCE",
    slug: "new-chance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-chance.png",
  },
  {
    name: "WaterDrop",
    symbol: "WDP",
    slug: "waterdrop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waterdrop.png",
  },
  {
    name: "Meme Games",
    symbol: "MGAMES",
    slug: "meme-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meme-games.png",
  },
  {
    name: "Coin Sack",
    symbol: "CS",
    slug: "coin-sack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-sack.png",
  },
  {
    name: "Viagra Token",
    symbol: "VIAGRA",
    slug: "viagra-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viagra-token.png",
  },
  {
    name: "Xtremcoin",
    symbol: "XTR",
    slug: "xtremcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtremcoin.png",
  },
  {
    name: "SappChat",
    symbol: "APP",
    slug: "sappchat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sappchat.png",
  },
  {
    name: "Full Send",
    symbol: "FULLSEND",
    slug: "full-send",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/full-send.png",
  },
  {
    name: "SMEGMARS",
    symbol: "SMGM",
    slug: "smegmars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smegmars.png",
  },
  {
    name: "Basket Legends",
    symbol: "BBL",
    slug: "basket-legends",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basket-legends.png",
  },
  {
    name: "AscentPad",
    symbol: "ASP",
    slug: "ascentpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ascentpad.png",
  },
  {
    name: "Mycro",
    symbol: "MYO",
    slug: "mycro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mycro.png",
  },
  {
    name: "MELD",
    symbol: "MELD",
    slug: "meld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meld.png",
  },
  {
    name: "DeChart",
    symbol: "DCH",
    slug: "dechart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dechart.png",
  },
  {
    name: "Moonbase",
    symbol: "MBBASED",
    slug: "moonbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonbase.png",
  },
  {
    name: "SYLTARE",
    symbol: "SYL",
    slug: "syltare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/syltare.png",
  },
  {
    name: "KingCorgi Chain",
    symbol: "KCC",
    slug: "kingcorgi-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingcorgi-chain.png",
  },
  {
    name: "IZE",
    symbol: "IZE",
    slug: "ize",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ize.png",
  },
  {
    name: "DEPO",
    symbol: "DEPO",
    slug: "depo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/depo.png",
  },
  {
    name: "Safe Shield",
    symbol: "SFSHLD",
    slug: "safe-shield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-shield.png",
  },
  {
    name: "Crystal Pro",
    symbol: "CRPRO",
    slug: "crystal-pro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crystal-pro.png",
  },
  {
    name: "RiskMoon",
    symbol: "RISKMOON",
    slug: "riskmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/riskmoon.png",
  },
  {
    name: "My Farm Pet",
    symbol: "MYFARMPET",
    slug: "my-farm-pet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-farm-pet.png",
  },
  {
    name: "Ziktalk",
    symbol: "ZIK",
    slug: "ziktalk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ziktalk.png",
  },
  {
    name: "EverFight",
    symbol: "EF",
    slug: "everfight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everfight.png",
  },
  {
    name: "UMetaWorld",
    symbol: "UMW",
    slug: "umetaworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/umetaworld.png",
  },
  {
    name: "Block Ape Scissors",
    symbol: "BAS",
    slug: "block-ape-scissors",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block-ape-scissors.png",
  },
  {
    name: "Jackpot",
    symbol: "777",
    slug: "jackpot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jackpot.png",
  },
  {
    name: "Defly Token",
    symbol: "DEFLY",
    slug: "defly-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defly-token.png",
  },
  {
    name: "Frosty Floki",
    symbol: "FROSTYFLOKI",
    slug: "frosty-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frosty-floki.png",
  },
  {
    name: "KNUCKLES",
    symbol: "KNUCKLES",
    slug: "knuckles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knuckles.png",
  },
  {
    name: "XDAG",
    symbol: "XDAG",
    slug: "xdag",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdag.png",
  },
  {
    name: "FirstDoge",
    symbol: "FIRSTDOGE",
    slug: "firstdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firstdoge.png",
  },
  {
    name: "Gods and Legends",
    symbol: "GNLR",
    slug: "gods-and-legends",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gods-and-legends.png",
  },
  {
    name: "Dopple Finance",
    symbol: "DOP",
    slug: "dopple-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dopple-finance.png",
  },
  {
    name: "Rich DAO",
    symbol: "RICH",
    slug: "rich-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rich-dao.png",
  },
  {
    name: "Communifty",
    symbol: "CNFT",
    slug: "communifty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/communifty.png",
  },
  {
    name: "Nydronia",
    symbol: "NIA",
    slug: "nydronia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nydronia.png",
  },
  {
    name: "Stronghold Token",
    symbol: "SHX",
    slug: "stronghold-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stronghold-token.png",
  },
  {
    name: "Pear Token",
    symbol: "PEAR",
    slug: "pear-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pear-token.png",
  },
  {
    name: "StrongHands Finance",
    symbol: "ISHND",
    slug: "stronghands-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stronghands-finance.png",
  },
  {
    name: "Koduro",
    symbol: "KODURO",
    slug: "koduro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koduro.png",
  },
  {
    name: "Acumen",
    symbol: "ACM",
    slug: "aucume",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aucume.png",
  },
  {
    name: "ENV Finance",
    symbol: "ENV",
    slug: "env-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/env-finance.png",
  },
  {
    name: "LovePot Token",
    symbol: "LOVE",
    slug: "lovepot-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lovepot-token.png",
  },
  {
    name: "Hyper Credit Network",
    symbol: "HPAY",
    slug: "hyper-credit-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyper-credit-network.png",
  },
  {
    name: "ECOMI",
    symbol: "OMI",
    slug: "ecomi-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecomi-new.png",
  },
  {
    name: "Muu Inu",
    symbol: "MINU",
    slug: "muu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muu-inu.png",
  },
  {
    name: "Eject",
    symbol: "EJECT",
    slug: "eject",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eject.png",
  },
  {
    name: "Nectar",
    symbol: "NEC",
    slug: "nectar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nectar.png",
  },
  {
    name: "Yooshiba Inu",
    symbol: "$YSHIBAINU",
    slug: "yooshiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yooshiba-inu.png",
  },
  {
    name: "UME Token",
    symbol: "UME",
    slug: "ume-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ume-token.png",
  },
  {
    name: "Swap TC",
    symbol: "SWAPTC",
    slug: "swap-tc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swap-tc.png",
  },
  {
    name: "Orbit Bridge Klaytn USDC",
    symbol: "OUSDC",
    slug: "orbit-bridge-klaytn-usdc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbit-bridge-klaytn-usdc.png",
  },
  {
    name: "TrueGBP",
    symbol: "TGBP",
    slug: "truegbp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/truegbp.png",
  },
  {
    name: "Pofid Dao",
    symbol: "PFID",
    slug: "pofid-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pofid-dao.png",
  },
  {
    name: "Super Floki",
    symbol: "SLOKI",
    slug: "super-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-floki.png",
  },
  {
    name: "Dawn Wars",
    symbol: "DW",
    slug: "dawn-wars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dawn-wars.png",
  },
  {
    name: "Altbase",
    symbol: "ALTB",
    slug: "altbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altbase.png",
  },
  {
    name: "CoW Protocol",
    symbol: "COW",
    slug: "cow-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cow-protocol.png",
  },
  {
    name: "BNBTiger",
    symbol: "BNBTIGER",
    slug: "bnbtiger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbtiger.png",
  },
  {
    name: "3x Short Bitcoin Cash Token",
    symbol: "BCHBEAR",
    slug: "3x-short-bitcoin-cash-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-bitcoin-cash-token.png",
  },
  {
    name: "GADGETWAR",
    symbol: "GWAR",
    slug: "gadgetwar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gadgetwar.png",
  },
  {
    name: "LumosMetaverse",
    symbol: "LUMOSX",
    slug: "lumosmetaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lumosmetaverse.png",
  },
  {
    name: "MP3",
    symbol: "MP3",
    slug: "mp3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mp3.png",
  },
  {
    name: "MyOwnItem",
    symbol: "MOI",
    slug: "myownitem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myownitem.png",
  },
  {
    name: "Millonarios FC Fan Token",
    symbol: "MFC",
    slug: "millonarios-fc-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/millonarios-fc-fan-token.png",
  },
  {
    name: "Ninja Protocol",
    symbol: "NINJA",
    slug: "ninja-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninja-protocol.png",
  },
  {
    name: "InsureDAO",
    symbol: "INSURE",
    slug: "insuredao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insuredao.png",
  },
  {
    name: "Limited USD",
    symbol: "LUSD",
    slug: "limited-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/limited-usd.png",
  },
  {
    name: "Moby Dick",
    symbol: "WOT",
    slug: "moby-dick",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moby-dick.png",
  },
  {
    name: "PROXI",
    symbol: "CREDIT",
    slug: "proxi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proxi.png",
  },
  {
    name: "SwapTracker",
    symbol: "SWPT",
    slug: "swaptracker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swaptracker.png",
  },
  {
    name: "HappyCoin",
    symbol: "HAPPY",
    slug: "thehappycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thehappycoin.png",
  },
  {
    name: "Skillchain",
    symbol: "SKI",
    slug: "skillchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skillchain.png",
  },
  {
    name: "ElonsPets",
    symbol: "ELP",
    slug: "elonspets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elonspets.png",
  },
  {
    name: "Little Fish Moon Token",
    symbol: "LTFM",
    slug: "little-fish-moon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/little-fish-moon-token.png",
  },
  {
    name: "Crypto Gaming United",
    symbol: "CGU",
    slug: "crypto-gaming-united",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-gaming-united.png",
  },
  {
    name: "CARAT",
    symbol: "CARAT",
    slug: "carat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carat.png",
  },
  {
    name: "DaftCoin",
    symbol: "DAFT",
    slug: "daftcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daftcoin.png",
  },
  {
    name: "Manifold Finance",
    symbol: "FOLD",
    slug: "manifold-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manifold-finance.png",
  },
  {
    name: "BrowniesSwap",
    symbol: "BROWN",
    slug: "browniesswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/browniesswap.png",
  },
  {
    name: "Baby Shiba",
    symbol: "BHIBA",
    slug: "baby-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-shiba.png",
  },
  {
    name: "Battle For Giostone",
    symbol: "BFG",
    slug: "battle-for-giostone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-for-giostone.png",
  },
  {
    name: "Saturna",
    symbol: "SAT",
    slug: "saturna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saturna.png",
  },
  {
    name: "Superbid",
    symbol: "SUPERBID",
    slug: "superbid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superbid.png",
  },
  {
    name: "RED PILL",
    symbol: "RPILL",
    slug: "red-pill",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/red-pill.png",
  },
  {
    name: "Harmony Token",
    symbol: "HARM",
    slug: "harmony-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harmony-token.png",
  },
  {
    name: "Zoo Labs",
    symbol: "ZOO",
    slug: "zoo-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoo-labs.png",
  },
  {
    name: "Darwinia Crab Network",
    symbol: "CRING",
    slug: "darwinia-crab-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darwinia-crab-network.png",
  },
  {
    name: "DRIVENx",
    symbol: "DVX",
    slug: "drivenx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drivenx.png",
  },
  {
    name: "SEED",
    symbol: "SEED",
    slug: "seed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seed.png",
  },
  {
    name: "Ninenoble",
    symbol: "NNN",
    slug: "ninenoble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninenoble.png",
  },
  {
    name: "Let's Go Brandon",
    symbol: "LGB",
    slug: "lets-go-brandon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lets-go-brandon.png",
  },
  {
    name: "Pheonix Protocol Dao",
    symbol: "PP",
    slug: "pheonix-protocol-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pheonix-protocol-dao.png",
  },
  {
    name: "ARize",
    symbol: "ARZ",
    slug: "arize",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arize.png",
  },
  {
    name: "Yield",
    symbol: "YLD",
    slug: "yield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield.png",
  },
  {
    name: "Meta Miner",
    symbol: "MINER",
    slug: "meta-miner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-miner.png",
  },
  {
    name: "Moonlana",
    symbol: "MOLA",
    slug: "moonlana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonlana.png",
  },
  {
    name: "MESSIER",
    symbol: "M87",
    slug: "messier",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/messier.png",
  },
  {
    name: "RUG RADIO",
    symbol: "RUG",
    slug: "rug-radio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rug-radio.png",
  },
  {
    name: "Solcubator",
    symbol: "SOLC",
    slug: "solcubator",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solcubator.png",
  },
  {
    name: "EsportsPro",
    symbol: "ESPRO",
    slug: "esportspro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/esportspro.png",
  },
  {
    name: "Alpha Brain Capital",
    symbol: "ABC",
    slug: "alpha-brain-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-brain-capital.png",
  },
  {
    name: "HecoFi",
    symbol: "HFI",
    slug: "hecofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hecofi.png",
  },
  {
    name: "Brewlabs",
    symbol: "BREWLABS",
    slug: "brewlabs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brewlabs.png",
  },
  {
    name: "Daefrom",
    symbol: "DAE",
    slug: "daefrom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daefrom.png",
  },
  {
    name: "Recharge Finance",
    symbol: "R3FI",
    slug: "r3fi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/r3fi-finance.png",
  },
  {
    name: "Spooky Uni",
    symbol: "SPKU",
    slug: "spooky-uni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spooky-uni.png",
  },
  {
    name: "Inumaki",
    symbol: "DAWG",
    slug: "inumaki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inumaki.png",
  },
  {
    name: "Bikerush",
    symbol: "BRT",
    slug: "bikerush",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bikerush.png",
  },
  {
    name: "Husky",
    symbol: "HUSKY",
    slug: "husky-vc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/husky-vc.png",
  },
  {
    name: "RO Slayers",
    symbol: "SLYR",
    slug: "ro-slayers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ro-slayers.png",
  },
  {
    name: "MeroeChain",
    symbol: "MRC",
    slug: "meroechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meroechain.png",
  },
  {
    name: "ThunderBNB.app",
    symbol: "THUNDERBNB",
    slug: "thunderbnb-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thunderbnb-app.png",
  },
  {
    name: "Baby Symbiote",
    symbol: "BSMB",
    slug: "babysymbiote",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babysymbiote.png",
  },
  {
    name: "Gouki Inu",
    symbol: "GOUKI",
    slug: "gouki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gouki-inu.png",
  },
  {
    name: "DOGEY STYLE",
    symbol: "DOGEY",
    slug: "dogey-style",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogey-style.png",
  },
  {
    name: "MotionWreck Games",
    symbol: "MWG",
    slug: "motionwreck-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/motionwreck-games.png",
  },
  {
    name: "xShare",
    symbol: "XSHARE",
    slug: "xshare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xshare.png",
  },
  {
    name: "Baby Doge Cash",
    symbol: "BABYDOGECASH",
    slug: "baby-doge-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-doge-cash.png",
  },
  {
    name: "MotoGP Fan Token",
    symbol: "MGPT",
    slug: "motogp-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/motogp-fan-token.png",
  },
  {
    name: "Project Merge",
    symbol: "MERGE",
    slug: "project-merge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-merge.png",
  },
  {
    name: "SIRIO",
    symbol: "SIR",
    slug: "sirio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sirio.png",
  },
  {
    name: "Prepayway",
    symbol: "InBit",
    slug: "prepayway",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prepayway.png",
  },
  {
    name: "Alora",
    symbol: "ALORA",
    slug: "alora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alora.png",
  },
  {
    name: "LiveNFT",
    symbol: "LIVENFT",
    slug: "livenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/livenft.png",
  },
  {
    name: "LIF3",
    symbol: "LIF3",
    slug: "lif3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lif3.png",
  },
  {
    name: "Metaficial World",
    symbol: "MW",
    slug: "metaficial-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaficial-world.png",
  },
  {
    name: "VeraOne",
    symbol: "VRO",
    slug: "veraone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veraone.png",
  },
  {
    name: "McLaren F1 Fan Token",
    symbol: "MCL",
    slug: "mclaren-f1-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mclaren-f1-fan-token.png",
  },
  {
    name: "Anomus",
    symbol: "ANOM",
    slug: "anomus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anomus.png",
  },
  {
    name: "Test Token",
    symbol: "TEST",
    slug: "test-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/test-token.png",
  },
  {
    name: "Cowboy Snake",
    symbol: "COWS",
    slug: "cowboy-snake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cowboy-snake.png",
  },
  {
    name: "TUNE TOKEN",
    symbol: "TUNE",
    slug: "tune-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tune-token.png",
  },
  {
    name: "Spooky Shiba",
    symbol: "SPOOKYSHIBA",
    slug: "spooky-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spooky-shiba.png",
  },
  {
    name: "random",
    symbol: "RND",
    slug: "random",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/random.png",
  },
  {
    name: "1BOX",
    symbol: "1BOX",
    slug: "1box",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1box.png",
  },
  {
    name: "Munch Token",
    symbol: "MUNCH",
    slug: "munch-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/munch-token.png",
  },
  {
    name: "Saitanobi",
    symbol: "SAITANOBI",
    slug: "saitanobi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saitanobi.png",
  },
  {
    name: "TouchFuture",
    symbol: "TF",
    slug: "touchfuture",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/touchfuture.png",
  },
  {
    name: "POLYX",
    symbol: "PXT",
    slug: "polyx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyx.png",
  },
  {
    name: "3x Short Litecoin Token",
    symbol: "LTCBEAR",
    slug: "3x-short-litecoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-litecoin-token.png",
  },
  {
    name: "Sanin Inu",
    symbol: "SANI",
    slug: "sanin-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sanin-inu.png",
  },
  {
    name: "Medi Token",
    symbol: "MEDI",
    slug: "medi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medi-token.png",
  },
  {
    name: "BabylonDAO",
    symbol: "BBY",
    slug: "babylondao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babylondao.png",
  },
  {
    name: "Oiler Network",
    symbol: "OIL",
    slug: "oiler-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oiler-network.png",
  },
  {
    name: "Cardiocoin",
    symbol: "CRDC",
    slug: "cardiocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cardiocoin.png",
  },
  {
    name: "ACDX Exchange Governance Token",
    symbol: "ACXT",
    slug: "acdx-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acdx-exchange-token.png",
  },
  {
    name: "Aelin",
    symbol: "AELIN",
    slug: "aelin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aelin.png",
  },
  {
    name: "aiRight",
    symbol: "AIRI",
    slug: "airight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/airight.png",
  },
  {
    name: "IC DEFI",
    symbol: "ICD",
    slug: "icdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icdefi.png",
  },
  {
    name: "DeversiFi",
    symbol: "DVF",
    slug: "deversifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deversifi.png",
  },
  {
    name: "ManuFactory",
    symbol: "MNFT",
    slug: "manufactory-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manufactory-2.png",
  },
  {
    name: "HashPanda",
    symbol: "PANDA",
    slug: "hashpanda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashpanda.png",
  },
  {
    name: "DoubleDice",
    symbol: "DODI",
    slug: "doubledice-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doubledice-token.png",
  },
  {
    name: "Rekt",
    symbol: "RKT",
    slug: "rekt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rekt.png",
  },
  {
    name: "MLOKY",
    symbol: "MLOKY",
    slug: "mloky",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mloky.png",
  },
  {
    name: "Omlira",
    symbol: "OML",
    slug: "omlira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omlira.png",
  },
  {
    name: "Last Survivor",
    symbol: "LSC",
    slug: "last-survivor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/last-survivor.png",
  },
  {
    name: "Sarcophagus",
    symbol: "SARCO",
    slug: "sarcophagus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sarcophagus.png",
  },
  {
    name: "Interest Bearing ETH",
    symbol: "ibETH",
    slug: "ibeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ibeth.png",
  },
  {
    name: "KingdomX",
    symbol: "KT",
    slug: "kingdomx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdomx.png",
  },
  {
    name: "Shade Protocol",
    symbol: "SHD",
    slug: "shade-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shade-protocol.png",
  },
  {
    name: "MoonToken",
    symbol: "MOONTOKEN",
    slug: "moon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-token.png",
  },
  {
    name: "Solfire Protocol",
    symbol: "FIRE",
    slug: "solfire-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solfire-protocol.png",
  },
  {
    name: "Divert Finance",
    symbol: "DEVE",
    slug: "divert-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/divert-finance.png",
  },
  {
    name: "Cricket Foundation",
    symbol: "CRIC",
    slug: "cricket-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cricket-foundation.png",
  },
  {
    name: "DaddyYorkie",
    symbol: "DDY",
    slug: "daddyyorkie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddyyorkie.png",
  },
  {
    name: "Brave",
    symbol: "BRAVE",
    slug: "brave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brave.png",
  },
  {
    name: "Shibnaki",
    symbol: "SHAKI",
    slug: "shibnaki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibnaki.png",
  },
  {
    name: "Bnext Token",
    symbol: "B3X",
    slug: "bnext-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnext-token.png",
  },
  {
    name: "ViceToken",
    symbol: "VICEX",
    slug: "vicetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vicetoken.png",
  },
  {
    name: "Universa",
    symbol: "UTNP",
    slug: "universa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universa.png",
  },
  {
    name: "Solcial",
    symbol: "SLCL",
    slug: "solcial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solcial.png",
  },
  {
    name: "Zenith Coin",
    symbol: "ZENITH",
    slug: "zenith-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenith-coin.png",
  },
  {
    name: "South African Tether",
    symbol: "xZAR",
    slug: "south-african-tether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/south-african-tether.png",
  },
  {
    name: "ELONBALLS",
    symbol: "ELONBALLS",
    slug: "elonballs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elonballs.png",
  },
  {
    name: "Pampther",
    symbol: "PAMPTHER",
    slug: "pampther",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pampther.png",
  },
  {
    name: "Regular Presale",
    symbol: "REGU",
    slug: "regular-presale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/regular-presale.png",
  },
  {
    name: "LOVE EARTH COIN",
    symbol: "LEC",
    slug: "love-earth-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/love-earth-coin.png",
  },
  {
    name: "Meme Machine",
    symbol: "MeMa",
    slug: "meme-machine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meme-machine.png",
  },
  {
    name: "SpaceVikings",
    symbol: "SVT",
    slug: "spacevikings",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacevikings.png",
  },
  {
    name: "Iron",
    symbol: "IRON",
    slug: "iron-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iron-finance.png",
  },
  {
    name: "DuxDoge",
    symbol: "DDC",
    slug: "duxdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duxdoge.png",
  },
  {
    name: "LunaFi",
    symbol: "LFI",
    slug: "lunafi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunafi.png",
  },
  {
    name: "Pochi Inu",
    symbol: "POCHI",
    slug: "pochi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pochi-inu.png",
  },
  {
    name: "BH Network",
    symbol: "BHAT",
    slug: "bh-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bh-network.png",
  },
  {
    name: "Butter TOken",
    symbol: "BUTTER",
    slug: "butter-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/butter-token.png",
  },
  {
    name: "SAFETREES",
    symbol: "TREES",
    slug: "safetrees",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safetrees.png",
  },
  {
    name: "Agrofarm",
    symbol: "FARM",
    slug: "agrofarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agrofarm.png",
  },
  {
    name: "Sirius Finance",
    symbol: "SRS",
    slug: "sirius-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sirius-finance.png",
  },
  {
    name: "Flokimars",
    symbol: "FLOM",
    slug: "flokimars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokimars.png",
  },
  {
    name: "ShieldEX",
    symbol: "SLD",
    slug: "shieldex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shieldex.png",
  },
  {
    name: "Prism Network",
    symbol: "PRISM",
    slug: "prism-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prism-network.png",
  },
  {
    name: "Meteorite.network",
    symbol: "METEOR",
    slug: "meteorite-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meteorite-network.png",
  },
  {
    name: "Floki Elon",
    symbol: "FLOKIELON",
    slug: "floki-elon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-elon.png",
  },
  {
    name: "MicroStrategy tokenized stock FTX",
    symbol: "MSTR",
    slug: "microstrategy-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microstrategy-tokenized-stock-ftx.png",
  },
  {
    name: "Belon DAO",
    symbol: "BE",
    slug: "belon-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/belon-dao.png",
  },
  {
    name: "Mithril Share",
    symbol: "MIS",
    slug: "mithril-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mithril-share.png",
  },
  {
    name: "RetroCade",
    symbol: "RC",
    slug: "retrocade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/retrocade.png",
  },
  {
    name: "Mechashiba",
    symbol: "MEC",
    slug: "mechashiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mechashiba.png",
  },
  {
    name: "Shining Crystal Shard",
    symbol: "SCS",
    slug: "shining-crystal-shard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shining-crystal-shard.png",
  },
  {
    name: "Pmail",
    symbol: "PML",
    slug: "pmail",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pmail.png",
  },
  {
    name: "Oculus Vision",
    symbol: "OCV",
    slug: "oculus-vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oculus-vision.png",
  },
  {
    name: "Shib Army",
    symbol: "SHIBARMY",
    slug: "shib-army",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shib-army.png",
  },
  {
    name: "JPEG",
    symbol: "JPEG",
    slug: "jpegvault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jpegvault.png",
  },
  {
    name: "TAKI",
    symbol: "TAKI",
    slug: "taki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taki.png",
  },
  {
    name: "CEEZEE SAFU",
    symbol: "SAFU",
    slug: "ceezee-safu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ceezee-safu.png",
  },
  {
    name: "Eska",
    symbol: "ESK",
    slug: "eska",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eska.png",
  },
  {
    name: "Make Cities Great Again",
    symbol: "MAGACITY",
    slug: "make-cities-great-again",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/make-cities-great-again.png",
  },
  {
    name: "Octree",
    symbol: "OCT",
    slug: "octree",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octree.png",
  },
  {
    name: "SeaChain",
    symbol: "SEACHAIN",
    slug: "seachain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seachain.png",
  },
  {
    name: "Zilla Shares 2.0",
    symbol: "ZSHARE",
    slug: "zilla-shares-2-0",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilla-shares-2-0.png",
  },
  {
    name: "PolyDragon",
    symbol: "DGOLD",
    slug: "polydragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polydragon.png",
  },
  {
    name: "Trisolaris",
    symbol: "TRI",
    slug: "trisolaris",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trisolaris.png",
  },
  {
    name: "Fat Satoshi",
    symbol: "FATOSHI",
    slug: "fat-satoshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fat-satoshi.png",
  },
  {
    name: "Mini Hollywood Doge",
    symbol: "MOLLYDOGE ⭐",
    slug: "mini-hollywood-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-hollywood-doge.png",
  },
  {
    name: "Class Coin",
    symbol: "CLASS",
    slug: "class-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/class-coin.png",
  },
  {
    name: "TOMCAT INU",
    symbol: "TOMCAT",
    slug: "tomcat-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tomcat-inu.png",
  },
  {
    name: "CAT",
    symbol: "CAT",
    slug: "cat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cat.png",
  },
  {
    name: "BullDog Coin",
    symbol: "BULLDOG",
    slug: "bulldog-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulldog-coin.png",
  },
  {
    name: "Galaxium",
    symbol: "GLXM",
    slug: "galaxium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxium.png",
  },
  {
    name: "FLIGHTCLUPCOIN",
    symbol: "FLIGHT",
    slug: "flightclupcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flightclupcoin.png",
  },
  {
    name: "VirtualMeta",
    symbol: "VMA",
    slug: "virtualmeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/virtualmeta.png",
  },
  {
    name: "Promotion Coin",
    symbol: "PC",
    slug: "promotion-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/promotion-coin.png",
  },
  {
    name: "American Shiba",
    symbol: "USHIBA",
    slug: "american-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/american-shiba.png",
  },
  {
    name: "Cashera",
    symbol: "CSR",
    slug: "cashera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashera.png",
  },
  {
    name: "80085 Token",
    symbol: "BOOBS",
    slug: "80085-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/80085-token.png",
  },
  {
    name: "Magic Ethereum Money",
    symbol: "MEM",
    slug: "magic-ethereum-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-ethereum-money.png",
  },
  {
    name: "Ocavu Network Token",
    symbol: "OCAVU",
    slug: "ocavu-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ocavu-network-token.png",
  },
  {
    name: "Trade.win",
    symbol: "TWI",
    slug: "trade-win",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trade-win.png",
  },
  {
    name: "Trustworks",
    symbol: "TRUST",
    slug: "trustworks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustworks.png",
  },
  {
    name: "ThoreCoin",
    symbol: "THR",
    slug: "thorecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thorecoin.png",
  },
  {
    name: "StepG",
    symbol: "STEPG",
    slug: "stepg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stepg.png",
  },
  {
    name: "REALPAY",
    symbol: "RAP",
    slug: "realpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realpay.png",
  },
  {
    name: "Scar Token",
    symbol: "SCAR",
    slug: "scar-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scar-token.png",
  },
  {
    name: "YFBitcoin",
    symbol: "YFBTC",
    slug: "yfbitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfbitcoin.png",
  },
  {
    name: "Senspark",
    symbol: "SEN",
    slug: "senspark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/senspark.png",
  },
  {
    name: "JINDO INU",
    symbol: "JIND",
    slug: "jindo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jindo-inu.png",
  },
  {
    name: "Holonus",
    symbol: "HLN",
    slug: "holonus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holonus.png",
  },
  {
    name: "ICY MONEY",
    symbol: "ICY",
    slug: "icy-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icy-money.png",
  },
  {
    name: "Strike Compound",
    symbol: "sCOMP",
    slug: "strike-compound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-compound.png",
  },
  {
    name: "MECHAZILLA",
    symbol: "MECHAZ",
    slug: "mechazilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mechazilla.png",
  },
  {
    name: "XPToken.io",
    symbol: "XPT",
    slug: "xptoken-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xptoken-io.png",
  },
  {
    name: "HAY",
    symbol: "HAY",
    slug: "helio-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helio-money.png",
  },
  {
    name: "AZ World SocialFi",
    symbol: "AZW",
    slug: "az-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/az-world.png",
  },
  {
    name: "ImmortalDAO Finance",
    symbol: "IMMO",
    slug: "immortaldao-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/immortaldao-finance.png",
  },
  {
    name: "IterationSyndicate",
    symbol: "ITS",
    slug: "iterationsyndicate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iterationsyndicate.png",
  },
  {
    name: "888 INFINITY",
    symbol: "888",
    slug: "888-infinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/888-infinity.png",
  },
  {
    name: "DiarrheaCoin",
    symbol: "DIAH",
    slug: "diarrheacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diarrheacoin.png",
  },
  {
    name: "Treasure",
    symbol: "TREASURE",
    slug: "treasure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treasure.png",
  },
  {
    name: "TOZEX",
    symbol: "TOZ",
    slug: "tozex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tozex.png",
  },
  {
    name: "HerityNetwork",
    symbol: "HER",
    slug: "heritynetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heritynetwork.png",
  },
  {
    name: "AntNetworX",
    symbol: "ANTX",
    slug: "antnetworx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antnetworx.png",
  },
  {
    name: "Order of the Black Rose",
    symbol: "OBR",
    slug: "order-of-the-black-rose",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/order-of-the-black-rose.png",
  },
  {
    name: "pSwampy",
    symbol: "PSWAMP",
    slug: "pswampy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pswampy.png",
  },
  {
    name: "JDI Yield",
    symbol: "JDI",
    slug: "jdi-yield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jdi-yield.png",
  },
  {
    name: "MiniBTC",
    symbol: "MINIBTC",
    slug: "minibtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minibtc.png",
  },
  {
    name: "ImminentVerse",
    symbol: "IMV",
    slug: "imminentverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imminentverse.png",
  },
  {
    name: "ELF Wallet",
    symbol: "ELF",
    slug: "elf-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elf-wallet.png",
  },
  {
    name: "YUI Token",
    symbol: "YUI",
    slug: "yui-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yui-token.png",
  },
  {
    name: "MetaOneVerse",
    symbol: "M1VERSE",
    slug: "metaoneverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaoneverse.png",
  },
  {
    name: "FloKishu",
    symbol: "FLISHU",
    slug: "flokishu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokishu.png",
  },
  {
    name: "Starly",
    symbol: "$STARLY",
    slug: "starly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starly.png",
  },
  {
    name: "EverEarn",
    symbol: "EARN",
    slug: "everearn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everearn.png",
  },
  {
    name: "MoonWay",
    symbol: "MOONWAY",
    slug: "moonway",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonway.png",
  },
  {
    name: "Cronospad",
    symbol: "CPAD",
    slug: "cronospad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cronospad.png",
  },
  {
    name: "Centralex",
    symbol: "CENX",
    slug: "centralex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centralex.png",
  },
  {
    name: "Dipper Network",
    symbol: "DIP",
    slug: "dipper-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dipper-network.png",
  },
  {
    name: "Revolotto",
    symbol: "RVL",
    slug: "revolotto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revolotto.png",
  },
  {
    name: "GreenTek",
    symbol: "GTE",
    slug: "greentek",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greentek.png",
  },
  {
    name: "JumpToken",
    symbol: "JMPT",
    slug: "jumptoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jumptoken.png",
  },
  {
    name: "Axioms",
    symbol: "AXI",
    slug: "axioms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axioms.png",
  },
  {
    name: "Creature Hunters",
    symbol: "CHTS",
    slug: "creature-hunters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creature-hunters.png",
  },
  {
    name: "CircleSwap",
    symbol: "CIR",
    slug: "circleswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/circleswap.png",
  },
  {
    name: "Restore Truth Token",
    symbol: "RTT",
    slug: "restoretruthtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/restoretruthtoken.png",
  },
  {
    name: "Healing Potion",
    symbol: "HPPOT",
    slug: "healing-potion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/healing-potion.png",
  },
  {
    name: "DAO.vc",
    symbol: "DAOVC",
    slug: "dao-vc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dao-vc.png",
  },
  {
    name: "Niubi Swap",
    symbol: "NIU",
    slug: "niubiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niubiswap.png",
  },
  {
    name: "DeathRoad",
    symbol: "xDRACE",
    slug: "x-deathroad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-deathroad.png",
  },
  {
    name: "Wrapped Fantom",
    symbol: "WFTM",
    slug: "wrapped-fantom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-fantom.png",
  },
  {
    name: "Sportium",
    symbol: "SPRT",
    slug: "sportium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sportium.png",
  },
  {
    name: "Scary Chain Capital",
    symbol: "SCC",
    slug: "scary-chain-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scary-chain-capital.png",
  },
  {
    name: "CroMoon",
    symbol: "MOON",
    slug: "cromoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cromoon.png",
  },
  {
    name: "SaharaDAO",
    symbol: "MNG",
    slug: "saharadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saharadao.png",
  },
  {
    name: "Lonelyfans",
    symbol: "LOF",
    slug: "lonelyfans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lonelyfans.png",
  },
  {
    name: "Kazoku INU",
    symbol: "KAZOKU",
    slug: "kazoku-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kazoku-inu.png",
  },
  {
    name: "ATTN",
    symbol: "ATTN",
    slug: "attn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/attn.png",
  },
  {
    name: "Vee Finance",
    symbol: "VEE",
    slug: "vee-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vee-finance.png",
  },
  {
    name: "Forint Token",
    symbol: "FORINT",
    slug: "forint-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forint-token.png",
  },
  {
    name: "Famcentral",
    symbol: "FAM",
    slug: "famcentral",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/famcentral.png",
  },
  {
    name: "Mondo Community Coin",
    symbol: "MNDCC",
    slug: "mondo-community-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mondo-community-coin.png",
  },
  {
    name: "Aqua Pig",
    symbol: "AQUAPIG",
    slug: "aqua-pig",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aqua-pig.png",
  },
  {
    name: "Pegaxy",
    symbol: "PGX",
    slug: "pegaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pegaxy.png",
  },
  {
    name: "Mirai",
    symbol: "MIRAI",
    slug: "mirai-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirai-labs.png",
  },
  {
    name: "City Coin",
    symbol: "CITY",
    slug: "city-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/city-coin.png",
  },
  {
    name: "eBlockStock",
    symbol: "EBSO",
    slug: "eblockstock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eblockstock.png",
  },
  {
    name: "CARBON",
    symbol: "GEMS",
    slug: "carbon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbon-token.png",
  },
  {
    name: "The Philosophers Stone",
    symbol: "TPOS",
    slug: "the-philosophers-stone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-philosophers-stone.png",
  },
  {
    name: "Perra",
    symbol: "PERRA",
    slug: "perra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/perra.png",
  },
  {
    name: "Fox Finance V2",
    symbol: "FOX",
    slug: "fox-finance-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fox-finance-new.png",
  },
  {
    name: "TabTrader Token",
    symbol: "TTT",
    slug: "tabtrader-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tabtrader-token.png",
  },
  {
    name: "InvestDex",
    symbol: "INVEST",
    slug: "investdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/investdex.png",
  },
  {
    name: "Saltmarble",
    symbol: "SML",
    slug: "saltmarble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saltmarble.png",
  },
  {
    name: "Level01",
    symbol: "LVX",
    slug: "level01",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/level01.png",
  },
  {
    name: "Anon Inu",
    symbol: "AINU",
    slug: "anon-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anon-inu.png",
  },
  {
    name: "Nidhi Dao",
    symbol: "GURU",
    slug: "nidhi-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nidhi-dao.png",
  },
  {
    name: "KelVPN",
    symbol: "KEL",
    slug: "kelvpn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kelvpn.png",
  },
  {
    name: "Swanlana",
    symbol: "SWAN",
    slug: "swanlana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swanlana.png",
  },
  {
    name: "Quantfury Token",
    symbol: "QTF",
    slug: "quantfury-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quantfury-token.png",
  },
  {
    name: "Belrium",
    symbol: "BEL",
    slug: "belrium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/belrium.png",
  },
  {
    name: "Multichain",
    symbol: "MULTI",
    slug: "multichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multichain.png",
  },
  {
    name: "UniDex",
    symbol: "UNIDX",
    slug: "unidex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unidex.png",
  },
  {
    name: "XList",
    symbol: "XLIST",
    slug: "xlist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xlist.png",
  },
  {
    name: "Satoru Inu",
    symbol: "SATO",
    slug: "satoru-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoru-inu.png",
  },
  {
    name: "SamuraiBattle",
    symbol: "SMB",
    slug: "samuraibattle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/samuraibattle.png",
  },
  {
    name: "Gulag Token",
    symbol: "GULAG",
    slug: "gulag-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gulag-token.png",
  },
  {
    name: "rhoBUSD",
    symbol: "RHOBUSD",
    slug: "rhobusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rhobusd.png",
  },
  {
    name: "Tardigrades Finance (BSC)",
    symbol: "TRDG",
    slug: "tardigrades-finance-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tardigrades-finance-bsc.png",
  },
  {
    name: "BNB Diamond",
    symbol: "BNBD",
    slug: "bnb-diamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnb-diamond.png",
  },
  {
    name: "The Sprint Token",
    symbol: "TST",
    slug: "the-sprint-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-sprint-token.png",
  },
  {
    name: "Penky",
    symbol: "PENKY",
    slug: "penky",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/penky.png",
  },
  {
    name: "Universe",
    symbol: "UNIV",
    slug: "universe-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universe-2.png",
  },
  {
    name: "Future Of Finance Fund",
    symbol: "FFF",
    slug: "future-of-finance-fund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/future-of-finance-fund.png",
  },
  {
    name: "Born To Race",
    symbol: "BRT",
    slug: "born-to-race",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/born-to-race.png",
  },
  {
    name: "2local",
    symbol: "2LC",
    slug: "2local",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2local.png",
  },
  {
    name: "DOGEFOOD",
    symbol: "DOGEFOOD",
    slug: "dogefood",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogefood.png",
  },
  {
    name: "Unkai",
    symbol: "UNKAI",
    slug: "unkai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unkai.png",
  },
  {
    name: "Liquid KSM",
    symbol: "LKSM",
    slug: "liquid-ksm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquid-ksm.png",
  },
  {
    name: "BitoPro Exchange Token",
    symbol: "BITO",
    slug: "bitopro-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitopro-exchange-token.png",
  },
  {
    name: "Hey",
    symbol: "HEY",
    slug: "hey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hey.png",
  },
  {
    name: "CasperPad",
    symbol: "CSPD",
    slug: "casperpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/casperpad.png",
  },
  {
    name: "World Football",
    symbol: "WOFO",
    slug: "world-football",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-football.png",
  },
  {
    name: "PresaleDAO",
    symbol: "AF-PRESALEDAO",
    slug: "presaledao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/presaledao.png",
  },
  {
    name: "TruePNL",
    symbol: "PNL",
    slug: "truepnl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/truepnl.png",
  },
  {
    name: "Seiren Games Network",
    symbol: "SERG",
    slug: "seiren-games-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seiren-games-network.png",
  },
  {
    name: "Palantir Tokenized Stock Defichain",
    symbol: "DPLTR",
    slug: "palantir-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/palantir-tokenized-stock-defichain.png",
  },
  {
    name: "The Spartans",
    symbol: "TSP",
    slug: "the-spartans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-spartans.png",
  },
  {
    name: "Life Token",
    symbol: "LTN",
    slug: "life-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/life-token.png",
  },
  {
    name: "AlgOil",
    symbol: "AGOLP",
    slug: "algoil",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algoil.png",
  },
  {
    name: "Pallas Finance",
    symbol: "PALLAS",
    slug: "pallas-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pallas-finance.png",
  },
  {
    name: "CarbonEco",
    symbol: "C0",
    slug: "carboneco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carboneco.png",
  },
  {
    name: "SmashCash",
    symbol: "SMASH",
    slug: "smashcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smashcash.png",
  },
  {
    name: "Bingus Token",
    symbol: "BINGUS",
    slug: "bingus-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bingus-token.png",
  },
  {
    name: "Burn Floki",
    symbol: "BFLOKI",
    slug: "burn-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burn-floki.png",
  },
  {
    name: "Nftime",
    symbol: "NFTM",
    slug: "nftime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftime.png",
  },
  {
    name: "ITSMYNE",
    symbol: "MYNE",
    slug: "itsmyne",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/itsmyne.png",
  },
  {
    name: "CryptoClans",
    symbol: "COC",
    slug: "cryptoclans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoclans.png",
  },
  {
    name: "GODL",
    symbol: "GODL",
    slug: "godl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/godl.png",
  },
  {
    name: "RadioShack",
    symbol: "RADIO",
    slug: "radioshack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radioshack.png",
  },
  {
    name: "AFEN Blockchain",
    symbol: "AFEN",
    slug: "afen-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/afen-blockchain.png",
  },
  {
    name: "Quartz",
    symbol: "QUARTZ",
    slug: "sandclock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sandclock.png",
  },
  {
    name: "KaiFloki",
    symbol: "MIYAGI",
    slug: "kaifloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaifloki.png",
  },
  {
    name: "AtomPad",
    symbol: "ATPAD",
    slug: "atompad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atompad.png",
  },
  {
    name: "Flux",
    symbol: "FLX",
    slug: "flux-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flux-dao.png",
  },
  {
    name: "Sensible.Finance",
    symbol: "SENSI",
    slug: "sensible-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sensible-finance.png",
  },
  {
    name: "CodexToken",
    symbol: "CODEX",
    slug: "codextoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/codextoken.png",
  },
  {
    name: "Eclipse",
    symbol: "ECP",
    slug: "eclipse-ecp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eclipse-ecp.png",
  },
  {
    name: "Intelligent Mining",
    symbol: "IM",
    slug: "intelligent-mining",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intelligent-mining.png",
  },
  {
    name: "Tena [new]",
    symbol: "TENA",
    slug: "tena-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tena-new.png",
  },
  {
    name: "Metis",
    symbol: "MTS",
    slug: "metis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metis.png",
  },
  {
    name: "Laeeb Inu",
    symbol: "LAEEB",
    slug: "laeebinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laeebinu.png",
  },
  {
    name: "XIDR",
    symbol: "XIDR",
    slug: "straitsx-indonesia-rupiah",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/straitsx-indonesia-rupiah.png",
  },
  {
    name: "Misty Inu",
    symbol: "MISTY",
    slug: "misty-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/misty-inu.png",
  },
  {
    name: "Bishu Finance",
    symbol: "BISHUFI",
    slug: "bishu-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bishu-finance.png",
  },
  {
    name: "Vision Network",
    symbol: "VSN",
    slug: "vision-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vision-network.png",
  },
  {
    name: "Equilibria",
    symbol: "XEQ",
    slug: "equilibria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/equilibria.png",
  },
  {
    name: "PayFlow",
    symbol: "PFT",
    slug: "payflow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payflow.png",
  },
  {
    name: "Etherconnect",
    symbol: "ECC",
    slug: "etherconnect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherconnect.png",
  },
  {
    name: "Stader MaticX",
    symbol: "MATICX",
    slug: "stader-maticx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stader-maticx.png",
  },
  {
    name: "Amatsu-Mikaboshi",
    symbol: "MIKABOSHI",
    slug: "amatsu-mikaboshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amatsu-mikaboshi.png",
  },
  {
    name: "exeno coin",
    symbol: "EXN",
    slug: "exeno-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exeno-coin.png",
  },
  {
    name: "EarnGuild",
    symbol: "EARN",
    slug: "earnguild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnguild.png",
  },
  {
    name: "Soteria",
    symbol: "WSOTE",
    slug: "soteria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soteria.png",
  },
  {
    name: "Metagochi",
    symbol: "MGCHI",
    slug: "metagochi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagochi.png",
  },
  {
    name: "H2Finance",
    symbol: "YFIH2",
    slug: "h2finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/h2finance.png",
  },
  {
    name: "Plutonians",
    symbol: "RPC",
    slug: "plutonians",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plutonians.png",
  },
  {
    name: "NTON",
    symbol: "NTON",
    slug: "nton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nton.png",
  },
  {
    name: "Merchant Token",
    symbol: "MTO",
    slug: "merchant-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merchant-token.png",
  },
  {
    name: "MoonRabbit",
    symbol: "MOONRABBIT",
    slug: "moonrabbit-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonrabbit-money.png",
  },
  {
    name: "B-cube.ai",
    symbol: "BCUBE",
    slug: "b-cube-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/b-cube-ai.png",
  },
  {
    name: "EduCoin",
    symbol: "EDU",
    slug: "edu-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edu-coin.png",
  },
  {
    name: "Vires Finance",
    symbol: "VIRES",
    slug: "vires-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vires-finance.png",
  },
  {
    name: "OriginDAO",
    symbol: "OG",
    slug: "origindao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/origindao.png",
  },
  {
    name: "Game1Network",
    symbol: "GAME1",
    slug: "game1network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game1network.png",
  },
  {
    name: "Decentralized Reit",
    symbol: "DRT",
    slug: "decentralized-reit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-reit.png",
  },
  {
    name: "Avoteo",
    symbol: "AVO",
    slug: "avoteo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avoteo.png",
  },
  {
    name: "ASIMI",
    symbol: "ASIMI",
    slug: "asimi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asimi.png",
  },
  {
    name: "GoSwapp",
    symbol: "GOFI",
    slug: "goswapp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goswapp.png",
  },
  {
    name: "FitR Exercise Token",
    symbol: "FXT",
    slug: "fitr-exercise-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fitr-exercise-token.png",
  },
  {
    name: "Aavegotchi FUD",
    symbol: "FUD",
    slug: "aavegotchi-fud",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aavegotchi-fud.png",
  },
  {
    name: "Chintai",
    symbol: "CHEX",
    slug: "chex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chex-token.png",
  },
  {
    name: "Entropyfi",
    symbol: "ERP",
    slug: "entropyfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/entropyfi.png",
  },
  {
    name: "SimpleChain",
    symbol: "SIMPLE",
    slug: "simplechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simplechain.png",
  },
  {
    name: "LuckyDoge",
    symbol: "LUCKYDOGE",
    slug: "luckydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luckydoge.png",
  },
  {
    name: "Yarloo",
    symbol: "YARL",
    slug: "yarloo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yarloo.png",
  },
  {
    name: "Fuse Dollar",
    symbol: "FUSD",
    slug: "fuse-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fuse-dollar.png",
  },
  {
    name: "COIL",
    symbol: "COIL",
    slug: "coil",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coil.png",
  },
  {
    name: "Meanfi",
    symbol: "MEAN",
    slug: "meanfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meanfi.png",
  },
  {
    name: "Gift-Coin",
    symbol: "GIFT",
    slug: "gift-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gift-coin.png",
  },
  {
    name: "YachtX",
    symbol: "YACHTX",
    slug: "yachtx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yachtx.png",
  },
  {
    name: "Shibking Inu",
    symbol: "SHIBKING",
    slug: "shibking-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibking-inu.png",
  },
  {
    name: "Starship Inu",
    symbol: "STARINU",
    slug: "starship-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starship-inu.png",
  },
  {
    name: "Diamonds Alaska Malamuted",
    symbol: "DAM",
    slug: "diamonds-alaska-malamuted",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamonds-alaska-malamuted.png",
  },
  {
    name: "SUPE",
    symbol: "SUPE",
    slug: "supe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supe.png",
  },
  {
    name: "TecraCoin",
    symbol: "TCR",
    slug: "tecracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tecracoin.png",
  },
  {
    name: "The Meebits",
    symbol: "NMEEBITS",
    slug: "the-meebits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-meebits.png",
  },
  {
    name: "INFBUNDLE",
    symbol: "INF",
    slug: "infbundle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infbundle.png",
  },
  {
    name: "Dragonmusk",
    symbol: "DMUSK",
    slug: "dragonmusk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonmusk.png",
  },
  {
    name: "ROBOT SHIB SWAP",
    symbol: "RBSHIB",
    slug: "robot-shib-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robot-shib-swap.png",
  },
  {
    name: "K-Tune",
    symbol: "KTT",
    slug: "k-tune",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/k-tune.png",
  },
  {
    name: "Influencer Finance",
    symbol: "INFLUENCE",
    slug: "influencer-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/influencer-finance.png",
  },
  {
    name: "COLONY",
    symbol: "CLY",
    slug: "colony",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colony.png",
  },
  {
    name: "BoomBaby.io",
    symbol: "BOOMB",
    slug: "boombaby-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boombaby-io.png",
  },
  {
    name: "Blockmine",
    symbol: "NUGGET",
    slug: "blockmine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockmine.png",
  },
  {
    name: "CRYPTORG",
    symbol: "CTG",
    slug: "cryptorg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptorg.png",
  },
  {
    name: "FRAT",
    symbol: "FRAT",
    slug: "frat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frat.png",
  },
  {
    name: "Vibraniums",
    symbol: "VIBRA",
    slug: "vibraniums",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vibraniums.png",
  },
  {
    name: "Wrapped VeChain",
    symbol: "WVET",
    slug: "wrapped-vechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-vechain.png",
  },
  {
    name: "Toksi",
    symbol: "TOI",
    slug: "toksi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toksi.png",
  },
  {
    name: "Baby Samo Coin",
    symbol: "BABY",
    slug: "baby-samo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-samo-coin.png",
  },
  {
    name: "WALTERINU",
    symbol: "$WINU",
    slug: "walterinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/walterinu.png",
  },
  {
    name: "MintySwap",
    symbol: "MINTYS",
    slug: "mintyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mintyswap.png",
  },
  {
    name: "DEXTF Protocol",
    symbol: "DEXTF",
    slug: "dextf-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dextf-protocol.png",
  },
  {
    name: "PIXL",
    symbol: "PXL",
    slug: "pixl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixl.png",
  },
  {
    name: "FatCake",
    symbol: "FATCAKE",
    slug: "fatcake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fatcake.png",
  },
  {
    name: "InFi",
    symbol: "8FI",
    slug: "infi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infi.png",
  },
  {
    name: "Pledge Coin",
    symbol: "PLG",
    slug: "pledge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pledge-coin.png",
  },
  {
    name: "Wrapped FCT",
    symbol: "WFCT",
    slug: "wrapped-fct",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-fct.png",
  },
  {
    name: "Ethereum Vault",
    symbol: "EVAULT",
    slug: "ethereum--vault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum--vault.png",
  },
  {
    name: "DEHR Network",
    symbol: "DHR",
    slug: "dehr-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dehr-network.png",
  },
  {
    name: "Elxis",
    symbol: "LEX",
    slug: "elxis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elxis.png",
  },
  {
    name: "Fat Cat Killer",
    symbol: "KILLER",
    slug: "fat-cat-killer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fat-cat-killer.png",
  },
  {
    name: "SaferMoon",
    symbol: "SAFERMOON",
    slug: "safermoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safermoon.png",
  },
  {
    name: "Dragon Crypto Aurum",
    symbol: "DCAU",
    slug: "dragon-crypto-aurum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-crypto-aurum.png",
  },
  {
    name: "nitroDOGE",
    symbol: "NITRODOGE",
    slug: "nitrodoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nitrodoge.png",
  },
  {
    name: "Synthetify",
    symbol: "SNY",
    slug: "synthetify",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synthetify.png",
  },
  {
    name: "Kripto koin",
    symbol: "KRIPTO",
    slug: "kripto-koin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kripto-koin.png",
  },
  {
    name: "YmplePay",
    symbol: "YMPA",
    slug: "ymplepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ymplepay.png",
  },
  {
    name: "Kishiburbo",
    symbol: "KISHIBURNO",
    slug: "kishiburbo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kishiburbo.png",
  },
  {
    name: "Farm Space",
    symbol: "SPACE",
    slug: "farm-space",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farm-space.png",
  },
  {
    name: "Hololoot",
    symbol: "HOL",
    slug: "hololoot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hololoot.png",
  },
  {
    name: "PieDAO Balanced Crypto Pie",
    symbol: "BCP",
    slug: "piedao-balanced-crypto-pie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piedao-balanced-crypto-pie.png",
  },
  {
    name: "Crypto Soccer",
    symbol: "CSC",
    slug: "crypto-soccer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-soccer.png",
  },
  {
    name: "EnviDa",
    symbol: "EDAT",
    slug: "envida",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/envida.png",
  },
  {
    name: "Alephium",
    symbol: "ALPH",
    slug: "alephium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alephium.png",
  },
  {
    name: "808TA",
    symbol: "808TA",
    slug: "808ta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/808ta.png",
  },
  {
    name: "Tesseract",
    symbol: "TESS",
    slug: "teseract",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teseract.png",
  },
  {
    name: "Mad Hatter Society",
    symbol: "MADHAT",
    slug: "mad-hatter-society",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mad-hatter-society.png",
  },
  {
    name: "TheFutbolCoin",
    symbol: "TFC",
    slug: "thefutbolcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thefutbolcoin.png",
  },
  {
    name: "Erotica",
    symbol: "EROTICA",
    slug: "erotica-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/erotica-token.png",
  },
  {
    name: "Smart Wallet Token",
    symbol: "SWT",
    slug: "smart-wallet-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-wallet-token.png",
  },
  {
    name: "Neeva Defi",
    symbol: "NVA",
    slug: "neeva-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neeva-defi.png",
  },
  {
    name: "Baby Clifford Inu",
    symbol: "BABYCLIFF",
    slug: "baby-clifford-inu-babycliff",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-clifford-inu-babycliff.png",
  },
  {
    name: "Sovi Finance",
    symbol: "SOVI",
    slug: "sovi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sovi-finance.png",
  },
  {
    name: "Spook Inu",
    symbol: "SINU",
    slug: "spook-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spook-inu.png",
  },
  {
    name: "EXRT Network",
    symbol: "EXRT",
    slug: "exrt-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exrt-network.png",
  },
  {
    name: "Kalamint",
    symbol: "KALAM",
    slug: "kalamint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalamint.png",
  },
  {
    name: "Torah Network",
    symbol: "VP",
    slug: "torah-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/torah-network.png",
  },
  {
    name: "Extend Finance",
    symbol: "EXF",
    slug: "extend-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/extend-finance.png",
  },
  {
    name: "Aquarius",
    symbol: "AQUA",
    slug: "aquarius",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aquarius.png",
  },
  {
    name: "Eterna",
    symbol: "EHX",
    slug: "eterna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eterna.png",
  },
  {
    name: "CRODEX",
    symbol: "CRX",
    slug: "crodex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crodex.png",
  },
  {
    name: "Slougi",
    symbol: "SLOUGI",
    slug: "slougi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slougi.png",
  },
  {
    name: "The Path Of Light",
    symbol: "YORI",
    slug: "the-path-of-light",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-path-of-light.png",
  },
  {
    name: "Polar",
    symbol: "POLARV3",
    slug: "polar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polar.png",
  },
  {
    name: "haildraconis",
    symbol: "RPTC",
    slug: "haildraconis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/haildraconis.png",
  },
  {
    name: "Dragon War",
    symbol: "DRAW",
    slug: "dragon-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-war.png",
  },
  {
    name: "Spockchain Network",
    symbol: "SPOK",
    slug: "spockchain-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spockchain-network.png",
  },
  {
    name: "Baitcoin",
    symbol: "BAIT",
    slug: "baitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baitcoin.png",
  },
  {
    name: "Crypto Piece",
    symbol: "BELLY",
    slug: "crypto-piece",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-piece.png",
  },
  {
    name: "Binamars Game",
    symbol: "BGAME",
    slug: "binamars-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binamars-game.png",
  },
  {
    name: "Liquid Staking Dot",
    symbol: "LDOT",
    slug: "liquid-staking-dot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquid-staking-dot.png",
  },
  {
    name: "Secure",
    symbol: "SCR",
    slug: "secure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secure.png",
  },
  {
    name: "Husky",
    symbol: "HUSKY",
    slug: "husky",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/husky.png",
  },
  {
    name: "IndiGG",
    symbol: "INDI",
    slug: "indigg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/indigg.png",
  },
  {
    name: "Gamesafe.io",
    symbol: "GAMESAFE",
    slug: "gamesafe-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamesafe-io.png",
  },
  {
    name: "Ethernal Finance",
    symbol: "ETHFIN",
    slug: "ethernal-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethernal-finance.png",
  },
  {
    name: "Alium Finance",
    symbol: "ALM",
    slug: "alium-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alium-finance.png",
  },
  {
    name: "StormBringer",
    symbol: "STB",
    slug: "stormbringer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stormbringer.png",
  },
  {
    name: "Steel",
    symbol: "STEEL",
    slug: "steel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steel.png",
  },
  {
    name: "Wrapped Staked HEC",
    symbol: "WSHEC",
    slug: "wrapped-staked-hec",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-staked-hec.png",
  },
  {
    name: "Cryptoindex.com 100",
    symbol: "CIX100",
    slug: "cryptoindex-com-100",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoindex-com-100.png",
  },
  {
    name: "Monkey Token",
    symbol: "MBY",
    slug: "monkey-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monkey-token.png",
  },
  {
    name: "Euro Coin",
    symbol: "EUROC",
    slug: "euro-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/euro-coin.png",
  },
  {
    name: "Marketing Samurai",
    symbol: "MXS",
    slug: "matrix-samurai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrix-samurai.png",
  },
  {
    name: "POP",
    symbol: "POP!",
    slug: "pop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pop.png",
  },
  {
    name: "Qommodity",
    symbol: "QAA",
    slug: "qommodity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qommodity.png",
  },
  {
    name: "SheepToken",
    symbol: "SHEEP",
    slug: "sheeptoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sheeptoken.png",
  },
  {
    name: "CrossFi",
    symbol: "CRFI",
    slug: "crossfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crossfi.png",
  },
  {
    name: "Prince Floki V2",
    symbol: "PrinceFloki",
    slug: "princefloki-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/princefloki-v2.png",
  },
  {
    name: "Dot Arcade",
    symbol: "ADT",
    slug: "dot-arcade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dot-arcade.png",
  },
  {
    name: "NFT-QR",
    symbol: "NFTQR",
    slug: "nft-qr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-qr.png",
  },
  {
    name: "NOA PLAY",
    symbol: "NOA",
    slug: "noa-play",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noa-play.png",
  },
  {
    name: "Chain of Legends",
    symbol: "CLEG",
    slug: "chain-of-legends",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain-of-legends.png",
  },
  {
    name: "SOCIETY OF GALACTIC EXPLORATION",
    symbol: "SGE",
    slug: "society-of-galactic-exploration",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/society-of-galactic-exploration.png",
  },
  {
    name: "Combo",
    symbol: "COMB",
    slug: "combo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/combo.png",
  },
  {
    name: "Bitnity",
    symbol: "BTNTY",
    slug: "bitnity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitnity.png",
  },
  {
    name: "Budbo",
    symbol: "BUBO",
    slug: "budbo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/budbo.png",
  },
  {
    name: "Meta Shiba",
    symbol: "METASHIB",
    slug: "meta-shibaa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-shibaa.png",
  },
  {
    name: "KaraStar UMY",
    symbol: "UMY",
    slug: "karastar-umy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karastar-umy.png",
  },
  {
    name: "Rally (Solana)",
    symbol: "SRLY",
    slug: "rally-solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rally-solana.png",
  },
  {
    name: "VKENAF",
    symbol: "VKNF",
    slug: "vkenaf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vkenaf.png",
  },
  {
    name: "Empower",
    symbol: "MPWR",
    slug: "empower",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/empower.png",
  },
  {
    name: "Rewards Bunny",
    symbol: "RBUNNY",
    slug: "rewards-bunny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rewards-bunny.png",
  },
  {
    name: "Litecash",
    symbol: "CASH",
    slug: "litecash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litecash.png",
  },
  {
    name: "Gbrick",
    symbol: "GBX",
    slug: "gbrick",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gbrick.png",
  },
  {
    name: "Gatsby Inu",
    symbol: "GATSBYINU",
    slug: "gatsby-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gatsby-inu.png",
  },
  {
    name: "Ryze Inu",
    symbol: "$RYZEINU",
    slug: "ryze-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ryze-inu.png",
  },
  {
    name: "UNQclub",
    symbol: "UNQ",
    slug: "unqclub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unqclub.png",
  },
  {
    name: "Milk Token",
    symbol: "MILK",
    slug: "milk-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milk-token.png",
  },
  {
    name: "YetiCoin",
    symbol: "YETIC",
    slug: "yeticoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yeticoin.png",
  },
  {
    name: "Multi-Farm Capital",
    symbol: "MFC",
    slug: "multi-farm-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multi-farm-capital.png",
  },
  {
    name: "Real Trump Token V2",
    symbol: "RTTV2",
    slug: "real-trump-token-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/real-trump-token-v2.png",
  },
  {
    name: "Potentiam",
    symbol: "PTM",
    slug: "potentiam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/potentiam.png",
  },
  {
    name: "PARTY",
    symbol: "PARTY",
    slug: "party",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/party.png",
  },
  {
    name: "ShibElon",
    symbol: "SHIBELON",
    slug: "shibelon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibelon.png",
  },
  {
    name: "Midas Dollar Share",
    symbol: "MDS",
    slug: "midas-dollar-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/midas-dollar-share.png",
  },
  {
    name: "GinSpirit",
    symbol: "GINSPIRIT",
    slug: "ginspirit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ginspirit.png",
  },
  {
    name: "Cia Protocol",
    symbol: "CIA",
    slug: "cia-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cia-protocol.png",
  },
  {
    name: "BMAX",
    symbol: "BMAX",
    slug: "bmax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bmax.png",
  },
  {
    name: "Aircoins",
    symbol: "AIRX",
    slug: "aircoins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aircoins.png",
  },
  {
    name: "MoonRock",
    symbol: "ROCK",
    slug: "moonrock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonrock.png",
  },
  {
    name: "Cherry Token",
    symbol: "YT",
    slug: "cherry-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cherry-token.png",
  },
  {
    name: "M7V2",
    symbol: "M7V2",
    slug: "m7v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/m7v2.png",
  },
  {
    name: "Reflex",
    symbol: "RFX",
    slug: "reflex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reflex.png",
  },
  {
    name: "Scope",
    symbol: "SCP",
    slug: "scope",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scope.png",
  },
  {
    name: "Bitcoin Legend",
    symbol: "BCL",
    slug: "bitcoin-legend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-legend.png",
  },
  {
    name: "GHOSTFACE",
    symbol: "GHOSTFACE",
    slug: "ghostface",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghostface.png",
  },
  {
    name: "Bitspawn Protocol",
    symbol: "SPWN",
    slug: "bitspawn-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitspawn-protocol.png",
  },
  {
    name: "Lil Floki",
    symbol: "LILFLOKI",
    slug: "lil-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lil-floki.png",
  },
  {
    name: "Global Coin Research",
    symbol: "GCR",
    slug: "global-coin-research",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-coin-research.png",
  },
  {
    name: "Dragon X",
    symbol: "DAX",
    slug: "dragon-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-x.png",
  },
  {
    name: "iNFTspace",
    symbol: "INS",
    slug: "inftspace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inftspace.png",
  },
  {
    name: "PiratesKing",
    symbol: "PKT",
    slug: "piratesking",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piratesking.png",
  },
  {
    name: "Utilies Cryptocurrency eXchange",
    symbol: "UCX",
    slug: "utilies-cryptocurrency-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utilies-cryptocurrency-exchange.png",
  },
  {
    name: "iTrust.Finance",
    symbol: "ITG",
    slug: "itrust-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/itrust-finance.png",
  },
  {
    name: "Super CoinView Token",
    symbol: "SCV",
    slug: "super-coinview",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-coinview.png",
  },
  {
    name: "Vodra",
    symbol: "VDR",
    slug: "vodra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vodra.png",
  },
  {
    name: "Wheat Token (AVAX)",
    symbol: "WHEAT",
    slug: "wheat-token-avax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wheat-token-avax.png",
  },
  {
    name: "xPERPS",
    symbol: "XPERPS",
    slug: "xperps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xperps.png",
  },
  {
    name: "AceD",
    symbol: "ACED",
    slug: "aced-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aced-token.png",
  },
  {
    name: "MetaSportsToken",
    symbol: "MST",
    slug: "metasportstoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metasportstoken.png",
  },
  {
    name: "Salary",
    symbol: "SLR",
    slug: "salary",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/salary.png",
  },
  {
    name: "Weentar",
    symbol: "$WNTR",
    slug: "weentar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weentar.png",
  },
  {
    name: "Dopple Finance",
    symbol: "DOPX",
    slug: "dopple-finance-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dopple-finance-.png",
  },
  {
    name: "iBNB",
    symbol: "IBNB",
    slug: "i-bnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/i-bnb.png",
  },
  {
    name: "Wrapped EthereumPoW",
    symbol: "WETHW",
    slug: "wrapped-ethereum-pow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-ethereum-pow.png",
  },
  {
    name: "Velhalla",
    symbol: "SCAR",
    slug: "velhalla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/velhalla.png",
  },
  {
    name: "ROCKSTAR DOGE",
    symbol: "ROCKSTAR",
    slug: "rockstar-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rockstar-doge.png",
  },
  {
    name: "Shillit App",
    symbol: "SHILL",
    slug: "shillit-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shillit-app.png",
  },
  {
    name: "Bali Coin",
    symbol: "BALI",
    slug: "bali-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bali-coin.png",
  },
  {
    name: "CoinGhost",
    symbol: "GST",
    slug: "coinghost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinghost.png",
  },
  {
    name: "Vancat",
    symbol: "VANCAT",
    slug: "vancat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vancat.png",
  },
  {
    name: "M7 VAULT",
    symbol: "VAULT",
    slug: "m7-vault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/m7-vault.png",
  },
  {
    name: "MetaDress",
    symbol: "MTD",
    slug: "metadress",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadress.png",
  },
  {
    name: "Grayscale Bitcoin Trust tokenized stock FTX",
    symbol: "GBTC",
    slug: "grayscale-bitcoin-trust-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grayscale-bitcoin-trust-tokenized-stock-ftx.png",
  },
  {
    name: "Poolotto.finance",
    symbol: "PLT",
    slug: "poolotto-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poolotto-finance.png",
  },
  {
    name: "SecretSky.finance",
    symbol: "SSF",
    slug: "secretsky-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secretsky-finance.png",
  },
  {
    name: "Wasder",
    symbol: "WAS",
    slug: "wasder",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wasder.png",
  },
  {
    name: "Nacho Finance",
    symbol: "NACHO",
    slug: "nacho-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nacho-finance.png",
  },
  {
    name: "Croking",
    symbol: "CRK",
    slug: "croking",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/croking.png",
  },
  {
    name: "SILK",
    symbol: "SILK",
    slug: "spider-tanks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spider-tanks.png",
  },
  {
    name: "PresidentDoge",
    symbol: "PRESIDENTDOGE",
    slug: "presidentdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/presidentdoge.png",
  },
  {
    name: "CakeDAO",
    symbol: "CAKE",
    slug: "cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cake.png",
  },
  {
    name: "MicroDexWallet",
    symbol: "MICRO",
    slug: "microdexwallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microdexwallet.png",
  },
  {
    name: "Vendit",
    symbol: "VNDT",
    slug: "vendit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vendit.png",
  },
  {
    name: "governance ZIL",
    symbol: "GZIL",
    slug: "governance-zil",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/governance-zil.png",
  },
  {
    name: "4-Stock",
    symbol: "4STC",
    slug: "4-stock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/4-stock.png",
  },
  {
    name: "Neos Credits",
    symbol: "NCR",
    slug: "neos-credits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neos-credits.png",
  },
  {
    name: "MusicVerse",
    symbol: "MV",
    slug: "musicverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musicverse.png",
  },
  {
    name: "sETH",
    symbol: "SETH",
    slug: "seth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seth.png",
  },
  {
    name: "Saddle",
    symbol: "SDL",
    slug: "saddle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saddle.png",
  },
  {
    name: "we love gm",
    symbol: "GM",
    slug: "we-love-gm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/we-love-gm.png",
  },
  {
    name: "rhoUSDT",
    symbol: "RHOUSDT",
    slug: "rhousdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rhousdt.png",
  },
  {
    name: "Able Finance",
    symbol: "ABLE",
    slug: "able-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/able-finance.png",
  },
  {
    name: "CLIMB TOKEN FINANCE",
    symbol: "CLIMB",
    slug: "climb-token-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/climb-token-finance.png",
  },
  {
    name: "cryingJORDAN Token",
    symbol: "JORDAN",
    slug: "cryingjordan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryingjordan-token.png",
  },
  {
    name: "OwlDAO",
    symbol: "OWL",
    slug: "owldao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/owldao.png",
  },
  {
    name: "Metavie",
    symbol: "METAVIE",
    slug: "metavie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metavie.png",
  },
  {
    name: "AdMonkey",
    symbol: "ADMONKEY",
    slug: "admonkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/admonkey.png",
  },
  {
    name: "Lootex",
    symbol: "LOOT",
    slug: "lootex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lootex.png",
  },
  {
    name: "Rush DeFi",
    symbol: "RUSH",
    slug: "rush-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rush-defi.png",
  },
  {
    name: "Everyonescrypto",
    symbol: "EOC",
    slug: "everyonescrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everyonescrypto.png",
  },
  {
    name: "BFK Warzone",
    symbol: "BFK",
    slug: "bfk-warzone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bfk-warzone.png",
  },
  {
    name: "Vow",
    symbol: "VOW",
    slug: "vow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vow.png",
  },
  {
    name: "Uzumaki Inu",
    symbol: "UZUMAKI",
    slug: "uzumaki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uzumaki-inu.png",
  },
  {
    name: "NanoMeter Bitcoin",
    symbol: "NMBTC",
    slug: "nanometer-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nanometer-bitcoin.png",
  },
  {
    name: "XXT-Token",
    symbol: "XXT",
    slug: "xxt-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xxt-token.png",
  },
  {
    name: "DEUS Finance",
    symbol: "DEUS",
    slug: "deus-finance-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deus-finance-2.png",
  },
  {
    name: "FRZSwap",
    symbol: "FRZW",
    slug: "frzswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frzswap.png",
  },
  {
    name: "Champion",
    symbol: "CHAM",
    slug: "champion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/champion.png",
  },
  {
    name: "Adventure Token",
    symbol: "TWA",
    slug: "adventure-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adventure-token.png",
  },
  {
    name: "SaltSwap Finance",
    symbol: "SALT",
    slug: "saltswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saltswap-finance.png",
  },
  {
    name: "Pomicoin",
    symbol: "POMI",
    slug: "pomicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pomicoin.png",
  },
  {
    name: "Nucleus",
    symbol: "NUCLEUS",
    slug: "nucleus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nucleus.png",
  },
  {
    name: "Etherprint",
    symbol: "ETHP",
    slug: "etherprint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherprint.png",
  },
  {
    name: "Museum of Crypto Art",
    symbol: "MOCA",
    slug: "museum-of-crypto-art",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/museum-of-crypto-art.png",
  },
  {
    name: "Versus Farm",
    symbol: "VERSUS",
    slug: "versus-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/versus-farm.png",
  },
  {
    name: "gAsp",
    symbol: "GASP",
    slug: "gasp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gasp.png",
  },
  {
    name: "Flux Protocol",
    symbol: "FLUX",
    slug: "flux-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flux-protocol.png",
  },
  {
    name: "Shira inu",
    symbol: "SHR",
    slug: "shira-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shira-inu.png",
  },
  {
    name: "ExzoCoin 2.0",
    symbol: "EXZO",
    slug: "exzocoin-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exzocoin-2.png",
  },
  {
    name: "XRE Global",
    symbol: "XRE",
    slug: "xre-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xre-global.png",
  },
  {
    name: "MUSO Finance",
    symbol: "MUSO",
    slug: "muso-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muso-finance.png",
  },
  {
    name: "BabyMatic",
    symbol: "BABYMATIC",
    slug: "babymatic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babymatic.png",
  },
  {
    name: "Signum",
    symbol: "SIGNA",
    slug: "signum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/signum.png",
  },
  {
    name: "Picipo",
    symbol: "PICIPO",
    slug: "picipo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/picipo.png",
  },
  {
    name: "Vendetta Finance",
    symbol: "VEN",
    slug: "vendetta-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vendetta-finance.png",
  },
  {
    name: "Lilly Finance",
    symbol: "Ly",
    slug: "lillyfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lillyfinance.png",
  },
  {
    name: "BurningMoon",
    symbol: "BM",
    slug: "burningmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burningmoon.png",
  },
  {
    name: "YouLive Coin",
    symbol: "UC",
    slug: "youlive-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/youlive-coin.png",
  },
  {
    name: "Hiko Inu",
    symbol: "HIKO",
    slug: "hiko-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hiko-inu.png",
  },
  {
    name: "Polkainsure Finance",
    symbol: "PIS",
    slug: "polkainsure-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkainsure-finance.png",
  },
  {
    name: "Metadogeswap",
    symbol: "MDS",
    slug: "metadogeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadogeswap.png",
  },
  {
    name: "ZeroHybrid Network",
    symbol: "ZHT",
    slug: "zerohybrid-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zerohybrid-network.png",
  },
  {
    name: "GOLDMONEY",
    symbol: "GDM",
    slug: "goldmoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldmoney.png",
  },
  {
    name: "Snake Token",
    symbol: "SNK",
    slug: "snake-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snake-token.png",
  },
  {
    name: "Mugen Finance",
    symbol: "MGN",
    slug: "mugen-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mugen-finance.png",
  },
  {
    name: "Cryption Network",
    symbol: "CNT",
    slug: "cryption-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryption-network.png",
  },
  {
    name: "Sphynx ETH",
    symbol: "SPHYNX",
    slug: "sphynx-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sphynx-eth.png",
  },
  {
    name: "Hesh.Fi",
    symbol: "HESH",
    slug: "hesh-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hesh-fi.png",
  },
  {
    name: "WaultSwap Polygon",
    symbol: "WEXPOLY",
    slug: "waultswap-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waultswap-polygon.png",
  },
  {
    name: "De-Fi Apes",
    symbol: "$DAPES",
    slug: "de-fi-apes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/de-fi-apes.png",
  },
  {
    name: "Day By Day",
    symbol: "DBD",
    slug: "day-by-day",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/day-by-day.png",
  },
  {
    name: "BabySpaceFloki",
    symbol: "BSF",
    slug: "babyspacefloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyspacefloki.png",
  },
  {
    name: "Trusted Node",
    symbol: "TNODE",
    slug: "trusted-node",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trusted-node.png",
  },
  {
    name: "ITEMVERSE",
    symbol: "ITEM",
    slug: "itemverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/itemverse.png",
  },
  {
    name: "Exen Coin",
    symbol: "EXEN",
    slug: "exen-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exen-coin.png",
  },
  {
    name: "Bankcoin Reserve",
    symbol: "BCR",
    slug: "bankcoin-reserve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankcoin-reserve.png",
  },
  {
    name: "AVATA Network",
    symbol: "AVAT",
    slug: "avata-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avata-network.png",
  },
  {
    name: "ERTH Token",
    symbol: "ERTH",
    slug: "erth-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/erth-token.png",
  },
  {
    name: "HyperonChain",
    symbol: "HPN",
    slug: "hyperonchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperonchain.png",
  },
  {
    name: "JIMNGAME",
    symbol: "JIMN",
    slug: "jimngame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jimngame.png",
  },
  {
    name: "Woozoo Music",
    symbol: "WZM",
    slug: "woozoo-music",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/woozoo-music.png",
  },
  {
    name: "upBNB",
    symbol: "UPBNB",
    slug: "upbnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upbnb.png",
  },
  {
    name: "Sewer Rat Social Club CHIZ Token",
    symbol: "CHIZ",
    slug: "sewer-rat-social-club-chiz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sewer-rat-social-club-chiz-token.png",
  },
  {
    name: "KENKA METAVERSE",
    symbol: "KENKA",
    slug: "kenka-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kenka-metaverse.png",
  },
  {
    name: "Cybereits",
    symbol: "CRE",
    slug: "cybereits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cybereits.png",
  },
  {
    name: "CryptoMafia",
    symbol: "$CMF",
    slug: "cryptomafia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptomafia.png",
  },
  {
    name: "KunoichiX",
    symbol: "KUNO",
    slug: "kunoichix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kunoichix.png",
  },
  {
    name: "The Wasted Lands",
    symbol: "WAL",
    slug: "the-wasted-lands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-wasted-lands.png",
  },
  {
    name: "AzeusX",
    symbol: "AZX",
    slug: "azeusx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/azeusx.png",
  },
  {
    name: "iLayer",
    symbol: "ILAYER",
    slug: "ilayer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ilayer.png",
  },
  {
    name: "PINK PANTHER",
    symbol: "PINK",
    slug: "pink-panther",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pink-panther.png",
  },
  {
    name: "JOE",
    symbol: "JOE",
    slug: "joe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joe.png",
  },
  {
    name: "Memeverse",
    symbol: "MEME",
    slug: "memeverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memeverse.png",
  },
  {
    name: "Money of Tomorrow, Today",
    symbol: "MTTCOIN",
    slug: "money-of-tomorrow-today",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/money-of-tomorrow-today.png",
  },
  {
    name: "Matic Aave Interest Bearing WETH",
    symbol: "MAWETH",
    slug: "matic-aave-weth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matic-aave-weth.png",
  },
  {
    name: "$OFC Coin",
    symbol: "OFC",
    slug: "ofc-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ofc-coin.png",
  },
  {
    name: "NOSHIT",
    symbol: "NSH",
    slug: "noshit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noshit.png",
  },
  {
    name: "BnbRain",
    symbol: "BRAIN",
    slug: "bnbrain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbrain.png",
  },
  {
    name: "CryptoForSpeed",
    symbol: "CFS",
    slug: "cryptoforspeed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoforspeed.png",
  },
  {
    name: "Coral Swap",
    symbol: "CORAL",
    slug: "coral-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coral-swap.png",
  },
  {
    name: "KING FOREVER",
    symbol: "KFR",
    slug: "king-forever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-forever.png",
  },
  {
    name: "Optimus",
    symbol: "OPTCM",
    slug: "optimus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/optimus.png",
  },
  {
    name: "META LAND",
    symbol: "VML",
    slug: "meta-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-land.png",
  },
  {
    name: "ClassZZ",
    symbol: "CZZ",
    slug: "classzz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/classzz.png",
  },
  {
    name: "Lady Uni",
    symbol: "LUNI",
    slug: "lady-uni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lady-uni.png",
  },
  {
    name: "Super Bitcoin",
    symbol: "SBTC",
    slug: "super-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-bitcoin.png",
  },
  {
    name: "Solar Energy",
    symbol: "SEG",
    slug: "solar-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solar-energy.png",
  },
  {
    name: "Zero",
    symbol: "ZERO",
    slug: "zero-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zero-tech.png",
  },
  {
    name: "KRED",
    symbol: "KRED",
    slug: "kred",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kred.png",
  },
  {
    name: "KridaFans",
    symbol: "KRIDA",
    slug: "kridafans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kridafans.png",
  },
  {
    name: "Gallant",
    symbol: "GAL",
    slug: "gallant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gallant.png",
  },
  {
    name: "AssetMantle",
    symbol: "MNTL",
    slug: "assetmantle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/assetmantle.png",
  },
  {
    name: "CARMA COIN",
    symbol: "CARMA",
    slug: "carma-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carma-coin.png",
  },
  {
    name: "Vegaswap",
    symbol: "VGA",
    slug: "vegaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vegaswap.png",
  },
  {
    name: "HealthFi",
    symbol: "HEFI",
    slug: "healthfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/healthfi.png",
  },
  {
    name: "Terra World Token",
    symbol: "TWD",
    slug: "terra-world-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terra-world-token.png",
  },
  {
    name: "BAHA",
    symbol: "BA",
    slug: "baha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baha.png",
  },
  {
    name: "AI Network",
    symbol: "AIN",
    slug: "ai-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ai-network.png",
  },
  {
    name: "Encrypter",
    symbol: "ERPT",
    slug: "encrypter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/encrypter.png",
  },
  {
    name: "HRDCOIN",
    symbol: "HRD",
    slug: "hrdcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hrdcoin.png",
  },
  {
    name: "Algebra",
    symbol: "ALGB",
    slug: "algebra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algebra.png",
  },
  {
    name: "DexBrowser",
    symbol: "BRO",
    slug: "dexbrowser",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexbrowser.png",
  },
  {
    name: "Mars Panda World",
    symbol: "MPT",
    slug: "mars-panda-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mars-panda-world.png",
  },
  {
    name: "3X Short TRX Token",
    symbol: "TRXBEAR",
    slug: "3x-short-trx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-trx-token.png",
  },
  {
    name: "Heavenland",
    symbol: "HTO",
    slug: "heavenland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heavenland.png",
  },
  {
    name: "MAMADOGE",
    symbol: "MAMADOGE",
    slug: "mamadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mamadoge.png",
  },
  {
    name: "Kintsugi",
    symbol: "KINT",
    slug: "kintsugi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kintsugi.png",
  },
  {
    name: "CoinMerge BSC",
    symbol: "CMERGE",
    slug: "coinmerge-bsc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinmerge-bsc-token.png",
  },
  {
    name: "zkTube Protocol",
    symbol: "ZKT",
    slug: "zktube-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zktube-protocol.png",
  },
  {
    name: "Game Of DeFi",
    symbol: "GOD",
    slug: "game-of-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game-of-defi.png",
  },
  {
    name: "Perfect World",
    symbol: "PFW",
    slug: "perfect-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/perfect-world.png",
  },
  {
    name: "Mini Tiger",
    symbol: "MINITIGER",
    slug: "minitiger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minitiger.png",
  },
  {
    name: "Merlin",
    symbol: "MERL",
    slug: "merlin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merlin.png",
  },
  {
    name: "Crystal Kingdoms",
    symbol: "CKG",
    slug: "crystal-kingdoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crystal-kingdoms.png",
  },
  {
    name: "Plethori",
    symbol: "PLE",
    slug: "plethori",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plethori.png",
  },
  {
    name: "Libonomy",
    symbol: "LBY",
    slug: "libonomy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libonomy.png",
  },
  {
    name: "Sway Social Protocol",
    symbol: "SWAY",
    slug: "sway-social-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sway-social-protocol.png",
  },
  {
    name: "OTCBTC Token",
    symbol: "OTB",
    slug: "otcbtc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otcbtc-token.png",
  },
  {
    name: "SafeAffinity",
    symbol: "AFFINITY",
    slug: "safeaffinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeaffinity.png",
  },
  {
    name: "GAMETREE",
    symbol: "GTCOIN",
    slug: "gametree",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gametree.png",
  },
  {
    name: "SafeETH",
    symbol: "SAFEETH",
    slug: "safeeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeeth.png",
  },
  {
    name: "FEICHANG NIU",
    symbol: "FCN",
    slug: "feichang-niu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feichang-niu.png",
  },
  {
    name: "Newsolution2.0",
    symbol: "NSTE",
    slug: "newsolution-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newsolution-token.png",
  },
  {
    name: "MarsX",
    symbol: "MX",
    slug: "marsx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marsx.png",
  },
  {
    name: "GoldFinX",
    symbol: "GIX",
    slug: "goldfinx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldfinx.png",
  },
  {
    name: "Kostren Finance",
    symbol: "KTN",
    slug: "kostren-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kostren-finance.png",
  },
  {
    name: "FlameMetaverse",
    symbol: "FMV",
    slug: "flamemetaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flamemetaverse.png",
  },
  {
    name: "Emblem",
    symbol: "EMB",
    slug: "emblem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emblem.png",
  },
  {
    name: "Demeter",
    symbol: "DUSD",
    slug: "demeter-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/demeter-usd.png",
  },
  {
    name: "Propel",
    symbol: "PEL",
    slug: "propel-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/propel-token.png",
  },
  {
    name: "United Bitcoin",
    symbol: "UBTC",
    slug: "united-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/united-bitcoin.png",
  },
  {
    name: "Filecoin Standard Hashrate Token",
    symbol: "FILST",
    slug: "filecoin-standard-hashrate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/filecoin-standard-hashrate-token.png",
  },
  {
    name: "META GROW",
    symbol: "META",
    slug: "meta-grow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-grow.png",
  },
  {
    name: "LabraCoin",
    symbol: "LABRA",
    slug: "labracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/labracoin.png",
  },
  {
    name: "KubeCoin",
    symbol: "KUBE",
    slug: "kubecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kubecoin.png",
  },
  {
    name: "Monfter",
    symbol: "MON",
    slug: "monfter-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monfter-token.png",
  },
  {
    name: "NNB Token",
    symbol: "NNB",
    slug: "nnb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nnb-token.png",
  },
  {
    name: "MoneySwap",
    symbol: "MSWAP",
    slug: "moneyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moneyswap.png",
  },
  {
    name: "Nirvana ANA",
    symbol: "ANA",
    slug: "nirvana-ana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nirvana-ana.png",
  },
  {
    name: "Crystal",
    symbol: "CRYSTAL",
    slug: "crystal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crystal.png",
  },
  {
    name: "MOCHISWAP",
    symbol: "MOCHI",
    slug: "mochiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mochiswap.png",
  },
  {
    name: "Nimbus Governance Token",
    symbol: "GNBU",
    slug: "nimbus-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nimbus-governance-token.png",
  },
  {
    name: "Viblos",
    symbol: "VIBLO",
    slug: "viblos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viblos.png",
  },
  {
    name: "HBTC Captain Token",
    symbol: "HBC",
    slug: "hbtc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hbtc-token.png",
  },
  {
    name: "Bali Social Integrated",
    symbol: "BSI",
    slug: "bali-social-integrated",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bali-social-integrated.png",
  },
  {
    name: "SoMee Advertising Token",
    symbol: "SAT",
    slug: "somee-advertising-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/somee-advertising-token.png",
  },
  {
    name: "QToken",
    symbol: "QTO",
    slug: "lobstex-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lobstex-new.png",
  },
  {
    name: "ARC Governance",
    symbol: "ARCX",
    slug: "arcx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcx-token.png",
  },
  {
    name: "ASSAPLAY",
    symbol: "ASSA",
    slug: "assaplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/assaplay.png",
  },
  {
    name: "XRdoge",
    symbol: "XRDOGE",
    slug: "xrdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrdoge.png",
  },
  {
    name: "Cydotori",
    symbol: "DOTR",
    slug: "cydotori",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cydotori.png",
  },
  {
    name: "IDEAS",
    symbol: "IDS",
    slug: "ideas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ideas.png",
  },
  {
    name: "Hubble Protocol",
    symbol: "HBB",
    slug: "hubble-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hubble-protocol.png",
  },
  {
    name: "Mini Baby Doge",
    symbol: "MINIBABYDOGE",
    slug: "mini-baby-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-baby-doge.png",
  },
  {
    name: "NotSafeMoon",
    symbol: "NOTSAFEMOON",
    slug: "notsafemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/notsafemoon.png",
  },
  {
    name: "Kiba",
    symbol: "KIBA",
    slug: "kiba-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiba-bsc.png",
  },
  {
    name: "Fandom",
    symbol: "FDM",
    slug: "fandom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fandom.png",
  },
  {
    name: "BELIEVER",
    symbol: "BLVR",
    slug: "believer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/believer.png",
  },
  {
    name: "MetaversePay",
    symbol: "MVP",
    slug: "metaversepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaversepay.png",
  },
  {
    name: "Ultra NFT",
    symbol: "UNFT",
    slug: "ultra-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultra-nft.png",
  },
  {
    name: "ZENFI AI",
    symbol: "ZENFI",
    slug: "zenfi-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenfi-ai.png",
  },
  {
    name: "Escrowed Illuvium",
    symbol: "SILV",
    slug: "escrowed-illuvium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/escrowed-illuvium.png",
  },
  {
    name: "Square Token",
    symbol: "SQUA",
    slug: "square-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/square-token.png",
  },
  {
    name: "Prophecy",
    symbol: "PRY",
    slug: "prophecy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prophecy.png",
  },
  {
    name: "RBIZ",
    symbol: "RBIZ",
    slug: "rbiz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rbiz.png",
  },
  {
    name: "Crypto Shield",
    symbol: "SHIELD",
    slug: "crypto-shield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-shield.png",
  },
  {
    name: "FIFA-Laeeb",
    symbol: "LAEEB",
    slug: "laeeb-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laeeb-.png",
  },
  {
    name: "HNC COIN",
    symbol: "HNC",
    slug: "hnccoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hnccoin.png",
  },
  {
    name: "iCommunity Labs",
    symbol: "ICOM",
    slug: "icommunity-blockchain-solutions-ibs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icommunity-blockchain-solutions-ibs.png",
  },
  {
    name: "MocktailSwap",
    symbol: "MOK",
    slug: "mocktailswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mocktailswap.png",
  },
  {
    name: "BITONE",
    symbol: "BIO",
    slug: "bitone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitone.png",
  },
  {
    name: "Doom Hero Game",
    symbol: "DHG",
    slug: "doom-hero-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doom-hero-game.png",
  },
  {
    name: "XSwap Treasure",
    symbol: "XTT",
    slug: "xswap-treasure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xswap-treasure.png",
  },
  {
    name: "3X Long Algorand Token",
    symbol: "ALGOBULL",
    slug: "3x-long-algorand-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-algorand-token.png",
  },
  {
    name: "Dark Matter Defi",
    symbol: "DMD",
    slug: "dark-matter-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dark-matter-defi.png",
  },
  {
    name: "Safe money",
    symbol: "SAFEMONEY",
    slug: "safe-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-money.png",
  },
  {
    name: "Mu Continent",
    symbol: "MU",
    slug: "mu-continent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mu-continent.png",
  },
  {
    name: "Money Heist",
    symbol: "MHT",
    slug: "money-heist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/money-heist.png",
  },
  {
    name: "Silo Finance",
    symbol: "SILO",
    slug: "silo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silo-finance.png",
  },
  {
    name: "Lunr Token",
    symbol: "LUNR",
    slug: "lunr-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunr-token.png",
  },
  {
    name: "Wrapped ILCOIN",
    symbol: "WILC",
    slug: "wrapped-ilcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-ilcoin.png",
  },
  {
    name: "Busy DAO",
    symbol: "BUSY",
    slug: "busy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/busy.png",
  },
  {
    name: "Bankless DAO",
    symbol: "BANK",
    slug: "bankless-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankless-dao.png",
  },
  {
    name: "OSCARCOIN",
    symbol: "OSCAR",
    slug: "oscarcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oscarcoin.png",
  },
  {
    name: "Scorefam",
    symbol: "SFT",
    slug: "scorefam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scorefam.png",
  },
  {
    name: "Shinomics",
    symbol: "SHIN",
    slug: "shinomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinomics.png",
  },
  {
    name: "BitcoinX",
    symbol: "BTCX",
    slug: "bitcoinxgen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinxgen.png",
  },
  {
    name: "INU",
    symbol: "INU",
    slug: "inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inu.png",
  },
  {
    name: "EnreachDAO",
    symbol: "NRCH",
    slug: "enreachdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enreachdao.png",
  },
  {
    name: "Infinity Token",
    symbol: "IT",
    slug: "infinity-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-token.png",
  },
  {
    name: "Bafe io",
    symbol: "BAFE",
    slug: "bafe-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bafe-io.png",
  },
  {
    name: "NairaX",
    symbol: "NIRX",
    slug: "nairax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nairax.png",
  },
  {
    name: "BuyMORE",
    symbol: "MORE",
    slug: "buymore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buymore.png",
  },
  {
    name: "Tethys",
    symbol: "TETHYS",
    slug: "tethys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tethys.png",
  },
  {
    name: "Beast NFT Token",
    symbol: "BNFT",
    slug: "beast-nft-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beast-nft-token.png",
  },
  {
    name: "Autoglyph #271",
    symbol: "AGLYPH",
    slug: "autoglyph-271",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/autoglyph-271.png",
  },
  {
    name: "Brise Paradise",
    symbol: "PRDS",
    slug: "brise-paradise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brise-paradise.png",
  },
  {
    name: "TreasureKey",
    symbol: "PIRATE",
    slug: "treasurekey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treasurekey.png",
  },
  {
    name: "Green Shiba Inu (new)",
    symbol: "GINUX",
    slug: "green-shiba-inu-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-shiba-inu-new.png",
  },
  {
    name: "ORE Token",
    symbol: "ORE",
    slug: "ore-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ore-token.png",
  },
  {
    name: "CheckerChain",
    symbol: "CHECKR",
    slug: "checkerchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/checkerchain.png",
  },
  {
    name: "ThingsOperatingSystem",
    symbol: "TOS",
    slug: "thingsoperatingsystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thingsoperatingsystem.png",
  },
  {
    name: "Waifer",
    symbol: "WAIFER",
    slug: "waifer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waifer.png",
  },
  {
    name: "Pocket Arena",
    symbol: "POC",
    slug: "pocket-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocket-arena.png",
  },
  {
    name: "Coalculus",
    symbol: "COAL",
    slug: "coalculus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coalculus.png",
  },
  {
    name: "Panda DAO",
    symbol: "PANDA",
    slug: "pandadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pandadao.png",
  },
  {
    name: "The Neko",
    symbol: "NEKO",
    slug: "the-neko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-neko.png",
  },
  {
    name: "Baby Lambo Inu",
    symbol: "BLINU",
    slug: "baby-lambo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-lambo-inu.png",
  },
  {
    name: "Unidef",
    symbol: "U",
    slug: "unidef",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unidef.png",
  },
  {
    name: "Tinville",
    symbol: "TINV",
    slug: "tinville",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tinville.png",
  },
  {
    name: "BitcoMine Token",
    symbol: "BME",
    slug: "bitcomine-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcomine-token.png",
  },
  {
    name: "Nano Doge Token",
    symbol: "NANODOGE",
    slug: "nano-doge-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nano-doge-token.png",
  },
  {
    name: "GamingShiba",
    symbol: "GAMINGSHIBA",
    slug: "gamingshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamingshiba.png",
  },
  {
    name: "TangoSwap",
    symbol: "TANGO",
    slug: "tangoswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tangoswap.png",
  },
  {
    name: "Fringe Finance",
    symbol: "FRIN",
    slug: "fringe-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fringe-finance.png",
  },
  {
    name: "LOT.TRADE",
    symbol: "LOTT",
    slug: "lot-trade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lot-trade.png",
  },
  {
    name: "Bee Token",
    symbol: "BGC",
    slug: "bee-gift-card",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bee-gift-card.png",
  },
  {
    name: "SURF Finance",
    symbol: "SURF",
    slug: "surf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/surf.png",
  },
  {
    name: "linSpirit",
    symbol: "LINSPIRIT",
    slug: "linspirit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linspirit.png",
  },
  {
    name: "Million Dollar Baby",
    symbol: "MDB",
    slug: "million-dollar-baby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/million-dollar-baby.png",
  },
  {
    name: "Chicken",
    symbol: "KFC",
    slug: "chicken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chicken.png",
  },
  {
    name: "Defiville",
    symbol: "ISLA",
    slug: "defiville",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defiville.png",
  },
  {
    name: "Shiba Viking",
    symbol: "SHIBKING",
    slug: "shiba-viking",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-viking.png",
  },
  {
    name: "DragonsGameFi",
    symbol: "$DRAGONS",
    slug: "dragonsgamefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonsgamefi.png",
  },
  {
    name: "Got Guaranteed",
    symbol: "GOTG",
    slug: "got-guaranteed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/got-guaranteed.png",
  },
  {
    name: "Nadeshiko",
    symbol: "NDSK",
    slug: "nadeshiko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nadeshiko.png",
  },
  {
    name: "IKOLF",
    symbol: "IKOLF",
    slug: "ikolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ikolf.png",
  },
  {
    name: "Top Cat inu",
    symbol: "TCAT",
    slug: "top-cat-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/top-cat-inu.png",
  },
  {
    name: "Around Network",
    symbol: "ART",
    slug: "around-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/around-network.png",
  },
  {
    name: "MaticPad",
    symbol: "MATPAD",
    slug: "maticpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maticpad.png",
  },
  {
    name: "Vira-lata Finance",
    symbol: "REAU",
    slug: "vira-lata-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vira-lata-finance.png",
  },
  {
    name: "VaultDeFi",
    symbol: "VAULT",
    slug: "vaultdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vaultdefi.png",
  },
  {
    name: "RARA",
    symbol: "RARA",
    slug: "rara",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rara.png",
  },
  {
    name: "Quoth",
    symbol: "QUOTH",
    slug: "quoth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quoth.png",
  },
  {
    name: "CoviCoin",
    symbol: "CVC",
    slug: "covicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/covicoin.png",
  },
  {
    name: "Foobee",
    symbol: "FBE",
    slug: "foobee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foobee.png",
  },
  {
    name: "SYL",
    symbol: "SYL",
    slug: "xsl-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xsl-labs.png",
  },
  {
    name: "BurgerBurn",
    symbol: "BRGB",
    slug: "burgerburn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burgerburn.png",
  },
  {
    name: "SLINK LABS",
    symbol: "SLAB",
    slug: "slink-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slink-labs.png",
  },
  {
    name: "ARTH [polygon]",
    symbol: "ARTH",
    slug: "arth-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arth-new.png",
  },
  {
    name: "EVRYNET",
    symbol: "EVRY",
    slug: "evrynet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evrynet.png",
  },
  {
    name: "Hoff Coin",
    symbol: "HOFF",
    slug: "hoff-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoff-coin.png",
  },
  {
    name: "DICKcoin",
    symbol: "DICK",
    slug: "dickcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dickcoin.png",
  },
  {
    name: "Polyroll",
    symbol: "ROLL",
    slug: "polyroll",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyroll.png",
  },
  {
    name: "bitcci Cash",
    symbol: "BITCCA",
    slug: "bitcci-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcci-cash.png",
  },
  {
    name: "Dextoken",
    symbol: "DEXG",
    slug: "dextoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dextoken.png",
  },
  {
    name: "Upfire",
    symbol: "UPR",
    slug: "upfire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upfire.png",
  },
  {
    name: "HeroPark",
    symbol: "HP",
    slug: "heropark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heropark.png",
  },
  {
    name: "Caliente",
    symbol: "CAL",
    slug: "caliente",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caliente.png",
  },
  {
    name: "Coinscope",
    symbol: "COINSCOPE",
    slug: "coinscope",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinscope.png",
  },
  {
    name: "FEED SYSTEM",
    symbol: "FEEDTK",
    slug: "feed-system",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feed-system.png",
  },
  {
    name: "Stoned Shiba",
    symbol: "STONED",
    slug: "stoned-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stoned-shiba.png",
  },
  {
    name: "Shibby",
    symbol: "SHIBBY",
    slug: "shibby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibby.png",
  },
  {
    name: "Tempo DAO",
    symbol: "TEMPO",
    slug: "tempo-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tempo-dao.png",
  },
  {
    name: "DecentraBNB",
    symbol: "DBNB",
    slug: "decentrabnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentrabnb.png",
  },
  {
    name: "Evulus Token",
    symbol: "EVU",
    slug: "evulus-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evulus-token.png",
  },
  {
    name: "3X Long Shitcoin Index Token",
    symbol: "BULLSHIT",
    slug: "3x-long-shitcoin-index-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-shitcoin-index-token.png",
  },
  {
    name: "7Pixels",
    symbol: "7PXS",
    slug: "7pixels",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/7pixels.png",
  },
  {
    name: "ApeXit Finance",
    symbol: "APEX",
    slug: "apexit-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apexit-finance.png",
  },
  {
    name: "YoHero",
    symbol: "YHC",
    slug: "yoherocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yoherocoin.png",
  },
  {
    name: "Yolllo",
    symbol: "YOLLLO",
    slug: "yolllo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yolllo.png",
  },
  {
    name: "Vulture Peak",
    symbol: "VPK",
    slug: "vulture-peak",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vulture-peak.png",
  },
  {
    name: "Bitlocus",
    symbol: "BTL",
    slug: "bitlocus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitlocus.png",
  },
  {
    name: "JFIN",
    symbol: "JFC",
    slug: "jfin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jfin.png",
  },
  {
    name: "NFTL Token",
    symbol: "NFTL",
    slug: "nftl-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftl-token.png",
  },
  {
    name: "DefiBay",
    symbol: "DBAY",
    slug: "defibay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defibay.png",
  },
  {
    name: "KEY",
    symbol: "KEY",
    slug: "key",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/key.png",
  },
  {
    name: "DarkMatter",
    symbol: "DMT",
    slug: "darkmatter-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darkmatter-token.png",
  },
  {
    name: "ARTM",
    symbol: "ARTM",
    slug: "artm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artm.png",
  },
  {
    name: "Polka Classic",
    symbol: "DOTC",
    slug: "polka-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polka-classic.png",
  },
  {
    name: "PhoenxiDefi Finance",
    symbol: "PNIXS",
    slug: "phoenxidefi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenxidefi-finance.png",
  },
  {
    name: "Nausicaa-Inu",
    symbol: "NAUSICAA",
    slug: "nausicaa-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nausicaa-inu.png",
  },
  {
    name: "WEI",
    symbol: "WEI",
    slug: "wei",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wei.png",
  },
  {
    name: "MetaX",
    symbol: "X1",
    slug: "metax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metax.png",
  },
  {
    name: "DEIP Protocol",
    symbol: "DEIP",
    slug: "deip-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deip-protocol.png",
  },
  {
    name: "Verse",
    symbol: "VERSE",
    slug: "verse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verse.png",
  },
  {
    name: "Warrior Token",
    symbol: "WAR",
    slug: "warrior-token-spartan-casino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/warrior-token-spartan-casino.png",
  },
  {
    name: "iBNB (new)",
    symbol: "IBNB",
    slug: "i-bnb-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/i-bnb-new.png",
  },
  {
    name: "Stabilize Token",
    symbol: "SET",
    slug: "stabilize-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stabilize-token.png",
  },
  {
    name: "Metaverse Capital",
    symbol: "MVC",
    slug: "metaverse-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-capital.png",
  },
  {
    name: "3X Long TomoChain Token",
    symbol: "TOMOBULL",
    slug: "3x-long-tomochain-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-tomochain-token.png",
  },
  {
    name: "Scaleswap",
    symbol: "SCA",
    slug: "scaleswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scaleswap.png",
  },
  {
    name: "AltSwitch",
    symbol: "ALTS",
    slug: "altswitch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altswitch.png",
  },
  {
    name: "Ludena Protocol",
    symbol: "LDN",
    slug: "ludena-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ludena-protocol.png",
  },
  {
    name: "Freeliquid",
    symbol: "FL",
    slug: "freeliquid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freeliquid.png",
  },
  {
    name: "XProject",
    symbol: "XPRO",
    slug: "xproject",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xproject.png",
  },
  {
    name: "BOXCASINO",
    symbol: "BOXC",
    slug: "boxcasino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boxcasino.png",
  },
  {
    name: "AVENGERSCOIN",
    symbol: "AVC",
    slug: "avengerscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avengerscoin.png",
  },
  {
    name: "T.OS",
    symbol: "TOSC",
    slug: "t-os",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/t-os.png",
  },
  {
    name: "Liquidifty",
    symbol: "LQT",
    slug: "liquidifty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquidifty.png",
  },
  {
    name: "Soccer Galaxy",
    symbol: "SOG",
    slug: "soccer-galaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soccer-galaxy.png",
  },
  {
    name: "Olecoin",
    symbol: "OLE",
    slug: "olecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olecoin.png",
  },
  {
    name: "Armada",
    symbol: "ARMD",
    slug: "armada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/armada.png",
  },
  {
    name: "2 Chains",
    symbol: "2CHAINLINKS",
    slug: "2-chains",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2-chains.png",
  },
  {
    name: "ROI Token",
    symbol: "ROI",
    slug: "roi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roi-token.png",
  },
  {
    name: "Galaxy Surge",
    symbol: "GALS",
    slug: "galaxy-surge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-surge.png",
  },
  {
    name: "Hyper Pay",
    symbol: "HPY",
    slug: "hyper-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyper-pay.png",
  },
  {
    name: "BARREL",
    symbol: "BARREL",
    slug: "barrel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/barrel.png",
  },
  {
    name: "Amorswap",
    symbol: "AMOR",
    slug: "amorswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amorswap.png",
  },
  {
    name: "DOOR",
    symbol: "DOOR",
    slug: "door",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/door.png",
  },
  {
    name: "SheBollETH Commerce",
    symbol: "SBECOM",
    slug: "shebolleth-commerce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shebolleth-commerce.png",
  },
  {
    name: "Zasset zUSD",
    symbol: "ZUSD",
    slug: "zasset-zusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zasset-zusd.png",
  },
  {
    name: "NetFlowCoin",
    symbol: "NFC",
    slug: "netflowcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netflowcoin.png",
  },
  {
    name: "The Crypt Space",
    symbol: "CRYPT",
    slug: "the-crypt-space",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-crypt-space.png",
  },
  {
    name: "UXD Protocol",
    symbol: "UXP",
    slug: "uxd-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uxd-protocol.png",
  },
  {
    name: "NIFDO Protocol",
    symbol: "NFD",
    slug: "nifdo-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nifdo-protocol.png",
  },
  {
    name: "tEXO",
    symbol: "TEXO",
    slug: "texo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/texo.png",
  },
  {
    name: "TradeStars",
    symbol: "TSX",
    slug: "tradestars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tradestars.png",
  },
  {
    name: "Portal",
    symbol: "PORTAL",
    slug: "portal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/portal.png",
  },
  {
    name: "Brickchain Finance",
    symbol: "BRICK",
    slug: "brickchain-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brickchain-finance.png",
  },
  {
    name: "Token TKX",
    symbol: "TKX",
    slug: "token-tkx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/token-tkx.png",
  },
  {
    name: "ChangeX",
    symbol: "CHANGE",
    slug: "changex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/changex.png",
  },
  {
    name: "Meta Floki",
    symbol: "MFLOKI",
    slug: "meta-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-floki.png",
  },
  {
    name: "Simba Empire",
    symbol: "SIM",
    slug: "simba-empire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simba-empire.png",
  },
  {
    name: "NFTD Protocol",
    symbol: "NFTD",
    slug: "nftd-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftd-protocol.png",
  },
  {
    name: "Baby Bali",
    symbol: "BB",
    slug: "baby-bali",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-bali.png",
  },
  {
    name: "Medano",
    symbol: "MDO",
    slug: "medano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medano.png",
  },
  {
    name: "LockTrip",
    symbol: "LOC",
    slug: "lockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lockchain.png",
  },
  {
    name: "LittleMouse",
    symbol: "LTMS",
    slug: "littlemouse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/littlemouse.png",
  },
  {
    name: "Shibu Life",
    symbol: "SHIBU",
    slug: "shibu-life",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibu-life.png",
  },
  {
    name: "ETH Fan Token",
    symbol: "EFT",
    slug: "eth-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-fan-token.png",
  },
  {
    name: "SymVerse",
    symbol: "SYM",
    slug: "symverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/symverse.png",
  },
  {
    name: "USD Open Dollar",
    symbol: "USDO",
    slug: "usd-open-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usd-open-dollar.png",
  },
  {
    name: "Dogelana",
    symbol: "DGLN",
    slug: "dogelana-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogelana-com.png",
  },
  {
    name: "Bitblocks Fire",
    symbol: "BFIRE",
    slug: "bitblocks-fire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitblocks-fire.png",
  },
  {
    name: "Token Runner",
    symbol: "TKRN",
    slug: "token-runner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/token-runner.png",
  },
  {
    name: "Lido for Solana",
    symbol: "STSOL",
    slug: "lido-for-solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lido-for-solana.png",
  },
  {
    name: "LIF3 LSHARE",
    symbol: "LSHARE",
    slug: "lif3-lshare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lif3-lshare.png",
  },
  {
    name: "Vacay",
    symbol: "VACAY",
    slug: "vacay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vacay.png",
  },
  {
    name: "Ambire Wallet",
    symbol: "WALLET",
    slug: "ambire-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ambire-wallet.png",
  },
  {
    name: "Kirby",
    symbol: "KIRBY",
    slug: "kirby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kirby.png",
  },
  {
    name: "HyperGraph",
    symbol: "HGT",
    slug: "hypergraph",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hypergraph.png",
  },
  {
    name: "Pokelon.Finance",
    symbol: "POKELON",
    slug: "pokelon-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pokelon-finance.png",
  },
  {
    name: "OpenAlexa Protocol",
    symbol: "OAP",
    slug: "openalexa-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/openalexa-protocol.png",
  },
  {
    name: "REV3AL",
    symbol: "REV3L",
    slug: "rev3al",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rev3al.png",
  },
  {
    name: "Plastiks",
    symbol: "PLASTIK",
    slug: "plastiks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plastiks.png",
  },
  {
    name: "Page Network",
    symbol: "PGX",
    slug: "page-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/page-network.png",
  },
  {
    name: "Zilla Finance",
    symbol: "ZILLA",
    slug: "zilla-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilla-finance.png",
  },
  {
    name: "Infinity Doge",
    symbol: "INFINITYDOGE",
    slug: "infinity-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-doge.png",
  },
  {
    name: "Louvre Finance",
    symbol: "LOUVRE",
    slug: "louvre-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/louvre-finance.png",
  },
  {
    name: "RoboHero",
    symbol: "ROBO",
    slug: "robohero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robohero.png",
  },
  {
    name: "EvoCardano",
    symbol: "EVOC",
    slug: "evocardano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evocardano.png",
  },
  {
    name: "Tribeland",
    symbol: "TRBL",
    slug: "tribeland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tribeland.png",
  },
  {
    name: "Lost Worlds",
    symbol: "LOST",
    slug: "lost-worlds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lost-worlds.png",
  },
  {
    name: "KuramaInu",
    symbol: "KUNU",
    slug: "kuramainu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuramainu.png",
  },
  {
    name: "QURAS",
    symbol: "XQC",
    slug: "quras",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quras.png",
  },
  {
    name: "Fungie DAO",
    symbol: "FNG",
    slug: "fungie-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fungie-dao.png",
  },
  {
    name: "Stilton Musk",
    symbol: "STILTON",
    slug: "stilton-musk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stilton-musk.png",
  },
  {
    name: "DogeMan",
    symbol: "DGMAN",
    slug: "dogeman",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeman.png",
  },
  {
    name: "Thaicoin",
    symbol: "TAC",
    slug: "thaicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thaicoin.png",
  },
  {
    name: "pTokens LTC",
    symbol: "PLTC",
    slug: "ptokens-ltc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ptokens-ltc.png",
  },
  {
    name: "KAI INU",
    symbol: "KAIINU",
    slug: "kai-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kai-inu.png",
  },
  {
    name: "C2X",
    symbol: "CTX",
    slug: "c2x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/c2x.png",
  },
  {
    name: "BSCBAY",
    symbol: "BSCB",
    slug: "bscbay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bscbay.png",
  },
  {
    name: "OpenLink",
    symbol: "OLINK",
    slug: "openlink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/openlink.png",
  },
  {
    name: "HappyFans",
    symbol: "HAPPY",
    slug: "happy-fans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/happy-fans.png",
  },
  {
    name: "YFIX Finance",
    symbol: "YFIX",
    slug: "yfix-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfix-finance.png",
  },
  {
    name: "1X Short XRP Token",
    symbol: "XRPHEDGE",
    slug: "1x-short-xrp-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1x-short-xrp-token.png",
  },
  {
    name: "Bincentive",
    symbol: "BCNT",
    slug: "bincentive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bincentive.png",
  },
  {
    name: "Green Eyed Monster",
    symbol: "GEM",
    slug: "green-eyed-monster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-eyed-monster.png",
  },
  {
    name: "Dwagon",
    symbol: "BABYTSUKA",
    slug: "dwagon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dwagon.png",
  },
  {
    name: "Safe Trip Finance",
    symbol: "STF",
    slug: "safe-trip-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-trip-finance.png",
  },
  {
    name: "MAD Bucks",
    symbol: "MAD",
    slug: "mad-bucks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mad-bucks.png",
  },
  {
    name: "Fire Protocol",
    symbol: "FIRE",
    slug: "fire-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fire-protocol.png",
  },
  {
    name: "CARROT STABLE COIN",
    symbol: "CARROT",
    slug: "carrot-stable-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carrot-stable-coin.png",
  },
  {
    name: "Meta Cloth",
    symbol: "META CLOTH",
    slug: "meta-cloth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-cloth.png",
  },
  {
    name: "Animal Adoption Advocacy",
    symbol: "PAWS",
    slug: "animal-adoption-advocacy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animal-adoption-advocacy.png",
  },
  {
    name: "JUSTFARM",
    symbol: "JFM",
    slug: "justfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/justfarm.png",
  },
  {
    name: "KList Protocol",
    symbol: "LIST",
    slug: "klist-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klist-protocol.png",
  },
  {
    name: "First Doge Finance",
    symbol: "FDOGE",
    slug: "first-doge-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/first-doge-finance.png",
  },
  {
    name: "PAPPAY",
    symbol: "PAPPAY",
    slug: "pappay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pappay.png",
  },
  {
    name: "Alphawolf Finance",
    symbol: "AWF",
    slug: "alphawolf-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alphawolf-finance.png",
  },
  {
    name: "Jefe Token",
    symbol: "JEFE",
    slug: "jefe-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jefe-token.png",
  },
  {
    name: "Decentralized Mining Exchange",
    symbol: "DMC",
    slug: "decentralized-mining-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-mining-exchange.png",
  },
  {
    name: "MetaVegas",
    symbol: "METAVEGAS",
    slug: "metavegas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metavegas.png",
  },
  {
    name: "Embr",
    symbol: "EMBR",
    slug: "embr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/embr.png",
  },
  {
    name: "BitValley",
    symbol: "BITV",
    slug: "bitvalley",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitvalley.png",
  },
  {
    name: "Acadex Network",
    symbol: "ACDX",
    slug: "acadex-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acadex-network.png",
  },
  {
    name: "B.Protocol",
    symbol: "BPRO",
    slug: "b-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/b-protocol.png",
  },
  {
    name: "Black Phoenix",
    symbol: "BPX",
    slug: "black-phoenix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-phoenix.png",
  },
  {
    name: "BuffaloSwap",
    symbol: "BUFF",
    slug: "buffaloswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buffaloswap.png",
  },
  {
    name: "Sake",
    symbol: "SAK3",
    slug: "sake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sake.png",
  },
  {
    name: "OXO.Farm",
    symbol: "OXO",
    slug: "oxo-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oxo-farm.png",
  },
  {
    name: "JEDSTAR",
    symbol: "JED",
    slug: "jedstar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jedstar.png",
  },
  {
    name: "Godzilla",
    symbol: "GODZ",
    slug: "godzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/godzilla.png",
  },
  {
    name: "INFINITYADA",
    symbol: "INFINITYADA",
    slug: "infinityada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinityada.png",
  },
  {
    name: "Moss Carbon Credit",
    symbol: "MCO2",
    slug: "moss-carbon-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moss-carbon-credit.png",
  },
  {
    name: "BabyNFT ART",
    symbol: "BBNFT",
    slug: "babynft-art",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babynft-art.png",
  },
  {
    name: "Metablackout",
    symbol: "MBT",
    slug: "metablackout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metablackout.png",
  },
  {
    name: "toad.network",
    symbol: "TOAD",
    slug: "toad-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toad-network.png",
  },
  {
    name: "RewardsCoin",
    symbol: "RWSC",
    slug: "rewardscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rewardscoin.png",
  },
  {
    name: "ERON",
    symbol: "ERON",
    slug: "eron-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eron-project.png",
  },
  {
    name: "AUTZ Token",
    symbol: "AUTZ",
    slug: "autz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/autz-token.png",
  },
  {
    name: "TechTrees",
    symbol: "TTC",
    slug: "techtreescoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/techtreescoin.png",
  },
  {
    name: "Defi Shopping Stake",
    symbol: "DSS",
    slug: "defi-shopping-stake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-shopping-stake.png",
  },
  {
    name: "Yuse",
    symbol: "YUSE",
    slug: "yuse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yuse.png",
  },
  {
    name: "MERCI",
    symbol: "MERCI",
    slug: "merci",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merci.png",
  },
  {
    name: "TYV Coin",
    symbol: "TYV",
    slug: "tyv-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tyv-coin.png",
  },
  {
    name: "LoopNetwork",
    symbol: "LOOP",
    slug: "loopnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loopnetwork.png",
  },
  {
    name: "Aerdrop",
    symbol: "AER",
    slug: "aerdrop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aerdrop.png",
  },
  {
    name: "Inu Inu",
    symbol: "INUINU",
    slug: "inu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inu-inu.png",
  },
  {
    name: "Smart Block Chain City",
    symbol: "SBCC",
    slug: "smart-block-chain-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-block-chain-city.png",
  },
  {
    name: "Yearn Cash",
    symbol: "YFIC",
    slug: "yearn-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-cash.png",
  },
  {
    name: "HUH Token",
    symbol: "HUH",
    slug: "huh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/huh.png",
  },
  {
    name: "Alpha Labz",
    symbol: "$ALPHA",
    slug: "alpha-labz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-labz.png",
  },
  {
    name: "QChi Chain",
    symbol: "QHC",
    slug: "qchi-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qchi-chain.png",
  },
  {
    name: "CoinMetro Token",
    symbol: "XCM",
    slug: "coinmetro-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinmetro-token.png",
  },
  {
    name: "Ross Inu",
    symbol: "$ROSS",
    slug: "ross-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ross-inu.png",
  },
  {
    name: "DesireNFT",
    symbol: "DESIRE",
    slug: "desirenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/desirenft.png",
  },
  {
    name: "Clear Water",
    symbol: "$CLEAR",
    slug: "clear-water",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clear-water.png",
  },
  {
    name: "BEAVISANDBUTTHEAD",
    symbol: "BBH",
    slug: "beavisandbutthead",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beavisandbutthead.png",
  },
  {
    name: "MetaFlokiMon Go",
    symbol: "METAFLOKIMG",
    slug: "metaflokimon-go",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaflokimon-go.png",
  },
  {
    name: "Mirrored Robinhood Markets",
    symbol: "MHOOD",
    slug: "mirrored-robinhood-markets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-robinhood-markets.png",
  },
  {
    name: "Silver Token",
    symbol: "SILVER",
    slug: "silver-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silver-token.png",
  },
  {
    name: "Stellar Diamond",
    symbol: "XLD",
    slug: "stellar-diamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stellar-diamond.png",
  },
  {
    name: "Kingdom Coin",
    symbol: "KDC",
    slug: "kingdom-coin-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdom-coin-.png",
  },
  {
    name: "DogeMusk",
    symbol: "DGMUSK",
    slug: "dogemusk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogemusk.png",
  },
  {
    name: "Monster of God",
    symbol: "MONX",
    slug: "monster-of-god",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-of-god.png",
  },
  {
    name: "YoCoin YOCO",
    symbol: "YOCO",
    slug: "yocoin-yoco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yocoin-yoco.png",
  },
  {
    name: "ECHO TECH COIN",
    symbol: "ECOT",
    slug: "echo-tech-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/echo-tech-coin.png",
  },
  {
    name: "Bitcoin and Ethereum Standard Token",
    symbol: "BEST",
    slug: "bitcoin-and-ethereum-standard-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-and-ethereum-standard-token.png",
  },
  {
    name: "Phoswap",
    symbol: "PHO",
    slug: "phoswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoswap.png",
  },
  {
    name: "Gstcoin",
    symbol: "GST",
    slug: "gstcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gstcoin.png",
  },
  {
    name: "1peco",
    symbol: "1PECO",
    slug: "1peco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1peco.png",
  },
  {
    name: "Alita Finance",
    symbol: "ALI",
    slug: "alita-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alita-finance.png",
  },
  {
    name: "Simbcoin Swap",
    symbol: "SMBSWAP",
    slug: "simbcoin-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simbcoin-swap.png",
  },
  {
    name: "Mini Saitama",
    symbol: "MINISAITAMA",
    slug: "mini-saitama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-saitama.png",
  },
  {
    name: "Massive Protocol",
    symbol: "MAV",
    slug: "massive-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/massive-protocol.png",
  },
  {
    name: "Meter Governance mapped by Meter.io",
    symbol: "eMTRG",
    slug: "meter-governance-mapped-by-meter-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meter-governance-mapped-by-meter-io.png",
  },
  {
    name: "UBGToken",
    symbol: "UBG",
    slug: "ubgtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubgtoken.png",
  },
  {
    name: "Safe Drive",
    symbol: "DRIVE",
    slug: "safe-drive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-drive.png",
  },
  {
    name: "Vectorspace AI",
    symbol: "VXV",
    slug: "vectorspace-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vectorspace-ai.png",
  },
  {
    name: "SafeHamsters",
    symbol: "SAFEHAMSTERS",
    slug: "safehamsters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safehamsters.png",
  },
  {
    name: "UCO Network",
    symbol: "UCOIL",
    slug: "uco-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uco-network.png",
  },
  {
    name: "Bone",
    symbol: "BONE",
    slug: "bone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bone.png",
  },
  {
    name: "MandoX",
    symbol: "MANDOX",
    slug: "mandox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mandox.png",
  },
  {
    name: "Capital Aggregator Token",
    symbol: "CAT",
    slug: "capital-aggregator-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/capital-aggregator-token.png",
  },
  {
    name: "Meta Apes",
    symbol: "MAPES",
    slug: "meta-apes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-apes.png",
  },
  {
    name: "OneSwap DAO Token",
    symbol: "ONES",
    slug: "oneswap-dao-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oneswap-dao-token.png",
  },
  {
    name: "Happiness",
    symbol: "HPNS",
    slug: "happiness",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/happiness.png",
  },
  {
    name: "Live Crypto Party",
    symbol: "LCP",
    slug: "live-crypto-party",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/live-crypto-party.png",
  },
  {
    name: "TrueBurn",
    symbol: "TRUE",
    slug: "trueburn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trueburn.png",
  },
  {
    name: "Freecash",
    symbol: "FCH",
    slug: "freecash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freecash.png",
  },
  {
    name: "Dynamic Set Dollar",
    symbol: "DSD",
    slug: "dynamic-set-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dynamic-set-dollar.png",
  },
  {
    name: "TerraUSD (Wormhole)",
    symbol: "UST",
    slug: "terrausd-wormhole",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terrausd-wormhole.png",
  },
  {
    name: "MetaFootball",
    symbol: "MTF",
    slug: "metafootball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafootball.png",
  },
  {
    name: "Elysian",
    symbol: "ELS",
    slug: "elysian-els",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elysian-els.png",
  },
  {
    name: "Pippi Finance",
    symbol: "PIPI",
    slug: "pippi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pippi-finance.png",
  },
  {
    name: "Metastrike",
    symbol: "MTS",
    slug: "metastrike",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metastrike.png",
  },
  {
    name: "AFRICA RISE TOKEN",
    symbol: "AFRICA",
    slug: "africa-rise-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/africa-rise-token.png",
  },
  {
    name: "Solanyx",
    symbol: "SYX",
    slug: "solanyx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solanyx.png",
  },
  {
    name: "LYFE",
    symbol: "LYFE",
    slug: "lyfe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lyfe.png",
  },
  {
    name: "Doge Run",
    symbol: "DRUN",
    slug: "doge-run",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-run.png",
  },
  {
    name: "ETHVault",
    symbol: "ETHVAULT",
    slug: "ethvault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethvault.png",
  },
  {
    name: "PYROMATIC",
    symbol: "PYRO",
    slug: "pyromatic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyromatic.png",
  },
  {
    name: "PLT Token",
    symbol: "PLT",
    slug: "plt-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plt-token.png",
  },
  {
    name: "Dentrocoin",
    symbol: "DENTRO",
    slug: "dentrocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dentrocoin.png",
  },
  {
    name: "Lion Token",
    symbol: "LION",
    slug: "lion-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lion-token.png",
  },
  {
    name: "Art Rino",
    symbol: "ARTRINO",
    slug: "art-rino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/art-rino.png",
  },
  {
    name: "BullRun2.0",
    symbol: "BR2.0",
    slug: "bullrun2-0",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullrun2-0.png",
  },
  {
    name: "GalaxyBUSD",
    symbol: "GALAXY",
    slug: "galaxybusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxybusd.png",
  },
  {
    name: "PorkSwap",
    symbol: "PSWAP",
    slug: "porkswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/porkswap.png",
  },
  {
    name: "CatBonk",
    symbol: "CABO",
    slug: "catbonk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catbonk.png",
  },
  {
    name: "Sins Finance",
    symbol: "SIN",
    slug: "sins-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sins-finance.png",
  },
  {
    name: "TeraBlock",
    symbol: "TBC",
    slug: "terablock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terablock.png",
  },
  {
    name: "BabyBoo",
    symbol: "BABYBOO",
    slug: "babyboo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyboo.png",
  },
  {
    name: "Coinpad",
    symbol: "CP",
    slug: "coinpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinpad.png",
  },
  {
    name: "Vault-S",
    symbol: "VAULT-S",
    slug: "vault-s",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vault-s.png",
  },
  {
    name: "Supermoon",
    symbol: "OSM",
    slug: "supermoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supermoon.png",
  },
  {
    name: "Dark Planet",
    symbol: "DP",
    slug: "dark-planet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dark-planet.png",
  },
  {
    name: "Yucreat",
    symbol: "YUCT",
    slug: "yucreat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yucreat.png",
  },
  {
    name: "GenCoin Capital",
    symbol: "GENCAP",
    slug: "gencoin-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gencoin-capital.png",
  },
  {
    name: "Bullshit Inu",
    symbol: "BULL",
    slug: "bullshit-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullshit-inu.png",
  },
  {
    name: "Lockness",
    symbol: "LKN",
    slug: "lockness",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lockness.png",
  },
  {
    name: "Pussy Financial",
    symbol: "PUSSY",
    slug: "pussy-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pussy-financial.png",
  },
  {
    name: "FirstDog",
    symbol: "FSD",
    slug: "firstdog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firstdog.png",
  },
  {
    name: "Zild Finance",
    symbol: "ZILD",
    slug: "zild-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zild-finance.png",
  },
  {
    name: "BNSD Finance",
    symbol: "BNSD",
    slug: "bnsd-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnsd-finance.png",
  },
  {
    name: "Reflecto",
    symbol: "REFLECTO",
    slug: "reflecto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reflecto.png",
  },
  {
    name: "Grand Exchange",
    symbol: "GP",
    slug: "grand-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grand-exchange.png",
  },
  {
    name: "Mofi Finance",
    symbol: "MOFI",
    slug: "mofi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mofi-finance.png",
  },
  {
    name: "PocMon",
    symbol: "MON",
    slug: "pocmon-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocmon-new.png",
  },
  {
    name: "Forever Burn",
    symbol: "FBURN",
    slug: "forever-burn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forever-burn.png",
  },
  {
    name: "BankRoll",
    symbol: "BANKR",
    slug: "bankroll",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankroll.png",
  },
  {
    name: "Cryptochrome",
    symbol: "CHM",
    slug: "cryptochrome",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptochrome.png",
  },
  {
    name: "The Web3 Project",
    symbol: "WEB3",
    slug: "the-web3-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-web3-project.png",
  },
  {
    name: "Strike USDC",
    symbol: "sUSDC",
    slug: "strike-usdc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-usdc.png",
  },
  {
    name: "Mercurial Finance",
    symbol: "MER",
    slug: "mercurial-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mercurial-finance.png",
  },
  {
    name: "Yinbi",
    symbol: "YINBI",
    slug: "yinbi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yinbi.png",
  },
  {
    name: "Shibonk",
    symbol: "SHIBO",
    slug: "shibonk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibonk.png",
  },
  {
    name: "Kitty",
    symbol: "KIT",
    slug: "dontplaywithkitty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dontplaywithkitty.png",
  },
  {
    name: "Klear Finance",
    symbol: "KLEAR",
    slug: "klear-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klear-finance.png",
  },
  {
    name: "Paragen",
    symbol: "RGEN",
    slug: "paragen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paragen.png",
  },
  {
    name: "Converter.Finance",
    symbol: "CON",
    slug: "converter-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/converter-finance.png",
  },
  {
    name: "DOOS TOKEN",
    symbol: "DOOS",
    slug: "doos-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doos-token.png",
  },
  {
    name: "CHIBA INU",
    symbol: "CHIBA",
    slug: "chiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chiba-inu.png",
  },
  {
    name: "Moonwell",
    symbol: "MFAM",
    slug: "moonwell",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonwell.png",
  },
  {
    name: "Smile Coin",
    symbol: "SMILE",
    slug: "smile-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smile-coin.png",
  },
  {
    name: "Swing DAO",
    symbol: "SWING",
    slug: "swing-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swing-dao.png",
  },
  {
    name: "PRüF Protocol",
    symbol: "PRUF",
    slug: "pruf-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pruf-protocol.png",
  },
  {
    name: "Save Your Assets",
    symbol: "SYA",
    slug: "save-your-assets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/save-your-assets.png",
  },
  {
    name: "HAYFEVER",
    symbol: "HAY",
    slug: "hayfever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hayfever.png",
  },
  {
    name: "LaunchX",
    symbol: "LNCHX",
    slug: "launchx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/launchx.png",
  },
  {
    name: "Defender of Doge",
    symbol: "DOD",
    slug: "defender-of-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defender-of-doge.png",
  },
  {
    name: "CheckDot",
    symbol: "CDT",
    slug: "checkdot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/checkdot.png",
  },
  {
    name: "SKYPlay",
    symbol: "SKP",
    slug: "skyplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skyplay.png",
  },
  {
    name: "Koinos",
    symbol: "KOIN",
    slug: "koinos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koinos.png",
  },
  {
    name: "Source Token",
    symbol: "SRCX",
    slug: "source-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/source-token.png",
  },
  {
    name: "Don't Buy Inu",
    symbol: "DBI",
    slug: "don-t-buy-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/don-t-buy-inu.png",
  },
  {
    name: "Echelon",
    symbol: "ECH",
    slug: "echelon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/echelon.png",
  },
  {
    name: "DailySwap Token",
    symbol: "DAILYS",
    slug: "dailyswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dailyswap-token.png",
  },
  {
    name: "BBS Network",
    symbol: "BBS",
    slug: "bbs-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bbs-network.png",
  },
  {
    name: "Global DeFi",
    symbol: "GDEFI",
    slug: "global-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-defi.png",
  },
  {
    name: "MidasDAO (New)",
    symbol: "CROWN",
    slug: "midasdao-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/midasdao-new.png",
  },
  {
    name: "Wall Street Capital",
    symbol: "WSC",
    slug: "wall-street-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wall-street-capital.png",
  },
  {
    name: "3x Long Bitcoin Cash Token",
    symbol: "BCHBULL",
    slug: "3x-long-bitcoin-cash-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-bitcoin-cash-token.png",
  },
  {
    name: "四皇 - Four Emperors",
    symbol: "YONKŌ",
    slug: "four-emperors",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/four-emperors.png",
  },
  {
    name: "DEVA TOKEN",
    symbol: "DEVA",
    slug: "deva-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deva-token.png",
  },
  {
    name: "Secretum",
    symbol: "SER",
    slug: "secretum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secretum.png",
  },
  {
    name: "MIM",
    symbol: "MIM",
    slug: "mim",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mim.png",
  },
  {
    name: "Xeebster",
    symbol: "XEEB",
    slug: "xeebster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xeebster.png",
  },
  {
    name: "poundtoken",
    symbol: "GBPT",
    slug: "poundtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poundtoken.png",
  },
  {
    name: "BETFURY",
    symbol: "BFG",
    slug: "betfury",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betfury.png",
  },
  {
    name: "Tipinu",
    symbol: "TIPINU",
    slug: "tipinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tipinu.png",
  },
  {
    name: "Future1coin",
    symbol: "F1C",
    slug: "future1coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/future1coin.png",
  },
  {
    name: "Galaxy Force X",
    symbol: "GFX",
    slug: "galaxy-force-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-force-x.png",
  },
  {
    name: "PEACOCKCOIN",
    symbol: "PEKC",
    slug: "peacockcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peacockcoin.png",
  },
  {
    name: "DAOLand",
    symbol: "DLD",
    slug: "daoland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daoland.png",
  },
  {
    name: "Aloha",
    symbol: "ALOHA",
    slug: "aloha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aloha.png",
  },
  {
    name: "Sektor Token",
    symbol: "SEKTOR",
    slug: "sektor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sektor-token.png",
  },
  {
    name: "MoneyShow",
    symbol: "MYS",
    slug: "moneyshow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moneyshow.png",
  },
  {
    name: "Global Trading Xenocurrency",
    symbol: "GTX",
    slug: "global-trading-xenocurrency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-trading-xenocurrency.png",
  },
  {
    name: "Media Network",
    symbol: "MEDIA",
    slug: "media-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/media-network.png",
  },
  {
    name: "Story",
    symbol: "STORY",
    slug: "story",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/story.png",
  },
  {
    name: "Ryoshimoto",
    symbol: "RYOSHIMOTO",
    slug: "ryoshimoto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ryoshimoto.png",
  },
  {
    name: "Libfx",
    symbol: "LIBFX",
    slug: "libfx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libfx.png",
  },
  {
    name: "MetaSwap",
    symbol: "MSC",
    slug: "metaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaswap.png",
  },
  {
    name: "ElonDoge DAO",
    symbol: "EDAO",
    slug: "elondoge-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elondoge-dao.png",
  },
  {
    name: "Altrucoin",
    symbol: "ALTRU",
    slug: "altrucoin-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altrucoin-new.png",
  },
  {
    name: "WinNow",
    symbol: "WNNW",
    slug: "winnow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winnow.png",
  },
  {
    name: "Hakuna Matata",
    symbol: "MATATA",
    slug: "hakuna-matata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hakuna-matata.png",
  },
  {
    name: "MOREAL",
    symbol: "$MOR",
    slug: "moreal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moreal.png",
  },
  {
    name: "RiseUp",
    symbol: "RISEUP",
    slug: "riseup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/riseup.png",
  },
  {
    name: "Doren",
    symbol: "DRE",
    slug: "doren",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doren.png",
  },
  {
    name: "LiveTrade Token",
    symbol: "LTD",
    slug: "livetrade-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/livetrade-token.png",
  },
  {
    name: "SHINU",
    symbol: "SHINU",
    slug: "shinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinu.png",
  },
  {
    name: "RimauSwap",
    symbol: "RIMAU",
    slug: "rimauswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rimauswap.png",
  },
  {
    name: "MoonFarmer",
    symbol: "MFM",
    slug: "moonfarmer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonfarmer.png",
  },
  {
    name: "Kronobit",
    symbol: "KNB",
    slug: "kronobit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kronobit.png",
  },
  {
    name: "Woofy",
    symbol: "WOOFY",
    slug: "woofy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/woofy.png",
  },
  {
    name: "Strudel Finance",
    symbol: "$TRDL",
    slug: "strudel-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strudel-finance.png",
  },
  {
    name: "Martin Shkreli Inu",
    symbol: "MSI",
    slug: "martin-shkreli-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/martin-shkreli-inu.png",
  },
  {
    name: "Golden Age",
    symbol: "GA",
    slug: "golden-age",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-age.png",
  },
  {
    name: "Calypso",
    symbol: "CYO",
    slug: "calypso",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calypso.png",
  },
  {
    name: "BLOCKMAX",
    symbol: "OCB",
    slug: "blockmax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockmax.png",
  },
  {
    name: "Primal",
    symbol: "PRM",
    slug: "primal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/primal.png",
  },
  {
    name: "Let's Go Brandon!",
    symbol: "FJB",
    slug: "letsgobrandoncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/letsgobrandoncoin.png",
  },
  {
    name: "PiFinance",
    symbol: "PIFI",
    slug: "pifinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pifinance.png",
  },
  {
    name: "Vemate",
    symbol: "VMT",
    slug: "vemate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vemate.png",
  },
  {
    name: "MetaFinance",
    symbol: "MF1",
    slug: "metafinance-1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafinance-1.png",
  },
  {
    name: "Partial Share",
    symbol: "PSHARE",
    slug: "partial-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/partial-share.png",
  },
  {
    name: "One Get Coin",
    symbol: "OGC",
    slug: "one-get-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-get-coin.png",
  },
  {
    name: "Green Floki",
    symbol: "GREENFLOKI",
    slug: "green-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-floki.png",
  },
  {
    name: "Sheesha Finance [BEP20]",
    symbol: "SHEESHA",
    slug: "sheesha-finance-bep20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sheesha-finance-bep20.png",
  },
  {
    name: "CODE",
    symbol: "CODE",
    slug: "developer-dao-code",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/developer-dao-code.png",
  },
  {
    name: "OCRA",
    symbol: "OCRA",
    slug: "ocra-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ocra-bsc.png",
  },
  {
    name: "Hachiko Inu",
    symbol: "INU",
    slug: "hachikoinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hachikoinu.png",
  },
  {
    name: "NuriFootBall",
    symbol: "NRFB",
    slug: "nurifootball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nurifootball.png",
  },
  {
    name: "CATFISH DOGE",
    symbol: "CATFISH",
    slug: "catfish-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catfish-doge.png",
  },
  {
    name: "BeatBind",
    symbol: "BBND",
    slug: "beatbind",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beatbind.png",
  },
  {
    name: "MicroPee",
    symbol: "PEE",
    slug: "micropee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/micropee.png",
  },
  {
    name: "wanUSDC",
    symbol: "WANUSDC",
    slug: "wanusdc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanusdc.png",
  },
  {
    name: "Power Block",
    symbol: "POW",
    slug: "power-block",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/power-block.png",
  },
  {
    name: "Mute",
    symbol: "MUTE",
    slug: "mute",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mute.png",
  },
  {
    name: "Investin",
    symbol: "IVN",
    slug: "investin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/investin.png",
  },
  {
    name: "Bigwinner",
    symbol: "BIG",
    slug: "bigwinner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bigwinner.png",
  },
  {
    name: "Kauri",
    symbol: "KAU",
    slug: "kauri-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kauri-crypto.png",
  },
  {
    name: "MetaSpace REIT",
    symbol: "MREIT",
    slug: "metaspace-reit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaspace-reit.png",
  },
  {
    name: "Meta Rewards Token",
    symbol: "MRT",
    slug: "meta-rewards-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-rewards-token.png",
  },
  {
    name: "Meta Ruffy",
    symbol: "MR",
    slug: "meta-ruffy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-ruffy.png",
  },
  {
    name: "Paribu Net",
    symbol: "PRB",
    slug: "paribu-net",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paribu-net.png",
  },
  {
    name: "DSYS",
    symbol: "DSYS",
    slug: "dsys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dsys.png",
  },
  {
    name: "GoldeFy",
    symbol: "GOD",
    slug: "goldefy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldefy.png",
  },
  {
    name: "Disco Burn Token",
    symbol: "DBT",
    slug: "disco-burn-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/disco-burn-token.png",
  },
  {
    name: "VSolidus",
    symbol: "VSOL",
    slug: "vsolidus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vsolidus.png",
  },
  {
    name: "DragonMoon",
    symbol: "DMOON",
    slug: "dragonmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonmoon.png",
  },
  {
    name: "PathDAO",
    symbol: "PATH",
    slug: "pathdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pathdao.png",
  },
  {
    name: "GAMINGDOGE",
    symbol: "GAMINGDOGE",
    slug: "gamingdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamingdoge.png",
  },
  {
    name: "UCoin",
    symbol: "UCOIN",
    slug: "u-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/u-coin.png",
  },
  {
    name: "Tempus",
    symbol: "TEMP",
    slug: "tempus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tempus.png",
  },
  {
    name: "GrimaceBsc.com",
    symbol: "GRIMACE",
    slug: "grimace-net",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grimace-net.png",
  },
  {
    name: "Shiba Fantom",
    symbol: "SHIBA",
    slug: "shiba-fantom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-fantom.png",
  },
  {
    name: "MetaShiba",
    symbol: "MSHIBA",
    slug: "metashiba-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metashiba-token.png",
  },
  {
    name: "HyperChain",
    symbol: "HYPER",
    slug: "hyperchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperchain.png",
  },
  {
    name: "ToyDOGE",
    symbol: "TDG",
    slug: "toydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toydoge.png",
  },
  {
    name: "Art Blocks Curated Fidenza #855",
    symbol: "FDNZA",
    slug: "art-blocks-curated-fidenza-855",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/art-blocks-curated-fidenza-855.png",
  },
  {
    name: "Richie",
    symbol: "RICH",
    slug: "richie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/richie.png",
  },
  {
    name: "SHAGGY INU",
    symbol: "SHAG",
    slug: "shaggy-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shaggy-inu.png",
  },
  {
    name: "Unslashed Finance",
    symbol: "USF",
    slug: "unslashed-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unslashed-finance.png",
  },
  {
    name: "NFTSocial",
    symbol: "NSC",
    slug: "nftsocial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftsocial.png",
  },
  {
    name: "Kanagawa Nami",
    symbol: "OKINAMI",
    slug: "kanagawa-nami",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kanagawa-nami.png",
  },
  {
    name: "BlueSparrow Token",
    symbol: "BLUESPARROW",
    slug: "bluesparrow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bluesparrow-token.png",
  },
  {
    name: "Bogecoin",
    symbol: "BOGE",
    slug: "bogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bogecoin.png",
  },
  {
    name: "Griffin Land",
    symbol: "GLAND",
    slug: "griffin-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/griffin-land.png",
  },
  {
    name: "DogeBack",
    symbol: "DOGEBACK",
    slug: "dogeback",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeback.png",
  },
  {
    name: "Sacred Tails",
    symbol: "ST",
    slug: "sacred-tails",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sacred-tails.png",
  },
  {
    name: "Korea Entertainment Education & Shopping",
    symbol: "KEES",
    slug: "korea-entertainment-education-shopping",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/korea-entertainment-education-shopping.png",
  },
  {
    name: "Renascent Finance",
    symbol: "RENASCENT",
    slug: "renascent-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/renascent-finance.png",
  },
  {
    name: "Intersola",
    symbol: "ISOLA",
    slug: "intersola",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intersola.png",
  },
  {
    name: "Bitecoin",
    symbol: "$BITC",
    slug: "bitecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitecoin.png",
  },
  {
    name: "Mirrored Coinbase",
    symbol: "MCOIN",
    slug: "mirrored-coinbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-coinbase.png",
  },
  {
    name: "Mr Bigglesworth",
    symbol: "BIGGLES",
    slug: "mr-bigglesworth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mr-bigglesworth.png",
  },
  {
    name: "Anji",
    symbol: "ANJI",
    slug: "anji",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anji.png",
  },
  {
    name: "ZoidPay",
    symbol: "ZPAY",
    slug: "zoidpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoidpay.png",
  },
  {
    name: "Plebe Gaming",
    symbol: "PLEB",
    slug: "plebe-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plebe-gaming.png",
  },
  {
    name: "Dapp Token",
    symbol: "DAPPT",
    slug: "dapp-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dapp-token.png",
  },
  {
    name: "Militia Games",
    symbol: "MILIT",
    slug: "militia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/militia.png",
  },
  {
    name: "FlexQ",
    symbol: "FLQ",
    slug: "flexq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flexq.png",
  },
  {
    name: "Christmas Floki X",
    symbol: "CFloki X",
    slug: "christmas-floki-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/christmas-floki-x.png",
  },
  {
    name: "Rabet",
    symbol: "RBT",
    slug: "rabet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rabet.png",
  },
  {
    name: "X",
    symbol: "X",
    slug: "x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x.png",
  },
  {
    name: "metaverse world",
    symbol: "WORLD",
    slug: "metaverse-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-world.png",
  },
  {
    name: "Planet Finance",
    symbol: "AQUA",
    slug: "planet-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/planet-finance.png",
  },
  {
    name: "TrustFi Network",
    symbol: "TFI",
    slug: "trustfi-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustfi-network.png",
  },
  {
    name: "Gamesta",
    symbol: "GSG",
    slug: "gamesta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamesta.png",
  },
  {
    name: "Electric Cash",
    symbol: "ELCASH",
    slug: "electric-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electric-cash.png",
  },
  {
    name: "ZOOPAD",
    symbol: "ZOOPAD",
    slug: "zoopad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoopad.png",
  },
  {
    name: "3X Short Cosmos Token",
    symbol: "ATOMBEAR",
    slug: "3x-short-cosmos-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-cosmos-token.png",
  },
  {
    name: "PantherSwap",
    symbol: "PANTHER",
    slug: "pantherswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pantherswap.png",
  },
  {
    name: "Retromoon",
    symbol: "RETRO",
    slug: "retromoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/retromoon.png",
  },
  {
    name: "SOLA Token",
    symbol: "SOLA",
    slug: "sola",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sola.png",
  },
  {
    name: "KlayCity",
    symbol: "ORB",
    slug: "klaycity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klaycity.png",
  },
  {
    name: "Savage",
    symbol: "SAVG",
    slug: "savage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/savage.png",
  },
  {
    name: "Hungarian Vizsla Inu",
    symbol: "HVI",
    slug: "hungarian-vizsla-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hungarian-vizsla-inu.png",
  },
  {
    name: "FomoDota",
    symbol: "FDOTA",
    slug: "fomodota",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fomodota.png",
  },
  {
    name: "LaEeb Token",
    symbol: "LAEEB",
    slug: "laeeb-token-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laeeb-token-2.png",
  },
  {
    name: "Emp Money",
    symbol: "EMP",
    slug: "emp-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emp-money.png",
  },
  {
    name: "Try.Finance",
    symbol: "TRY",
    slug: "try-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/try-finance.png",
  },
  {
    name: "BidenOneTrillionCoin",
    symbol: "B1TC",
    slug: "bidenonetrillioncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bidenonetrillioncoin.png",
  },
  {
    name: "Dream",
    symbol: "$DREAM",
    slug: "dream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dream.png",
  },
  {
    name: "Peak AVAX",
    symbol: "PEAKAVAX",
    slug: "peak-avax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peak-avax.png",
  },
  {
    name: "TideBit Token",
    symbol: "TBT",
    slug: "tidebit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tidebit-token.png",
  },
  {
    name: "D Community",
    symbol: "DILI",
    slug: "d-community",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/d-community.png",
  },
  {
    name: "Cloak",
    symbol: "CLOAK",
    slug: "cloak",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cloak.png",
  },
  {
    name: "Kyoto Protocol",
    symbol: "KYOTO",
    slug: "kyoto-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kyoto-protocol.png",
  },
  {
    name: "Yieldly",
    symbol: "YLDY",
    slug: "yieldly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yieldly.png",
  },
  {
    name: "NFTSwaps",
    symbol: "SWAPS",
    slug: "nftswaps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftswaps.png",
  },
  {
    name: "Midas Miner",
    symbol: "MMI",
    slug: "midas-miner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/midas-miner.png",
  },
  {
    name: "Waterfall Finance",
    symbol: "WATERFALL",
    slug: "waterfall-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waterfall-finance.png",
  },
  {
    name: "Law Blocks",
    symbol: "LBT",
    slug: "law-blocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/law-blocks.png",
  },
  {
    name: "Wault USD",
    symbol: "WUSD",
    slug: "wault-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wault-usd.png",
  },
  {
    name: "Gains Farm",
    symbol: "GFARM2",
    slug: "gains-farm-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gains-farm-v2.png",
  },
  {
    name: "LUFFY INU",
    symbol: "LUFFY",
    slug: "luffy-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luffy-inu.png",
  },
  {
    name: "GameZilla",
    symbol: "GZILA",
    slug: "gamezilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamezilla.png",
  },
  {
    name: "AstroFarms Finance",
    symbol: "LEO",
    slug: "astrofarms-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astrofarms-finance.png",
  },
  {
    name: "Minds",
    symbol: "MINDS",
    slug: "minds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minds.png",
  },
  {
    name: "NinjaFloki",
    symbol: "NJF",
    slug: "ninjafloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninjafloki.png",
  },
  {
    name: "Shield Finance",
    symbol: "SHLD",
    slug: "shield-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shield-finance.png",
  },
  {
    name: "LAW TOKEN",
    symbol: "LAW",
    slug: "law-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/law-token.png",
  },
  {
    name: "Chimeras",
    symbol: "CHIM",
    slug: "chimeras",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chimeras.png",
  },
  {
    name: "Mirrored Ether",
    symbol: "mETH",
    slug: "mirrored-ether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-ether.png",
  },
  {
    name: "Kaizen Inu",
    symbol: "KZN",
    slug: "kaizen-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaizen-inu.png",
  },
  {
    name: "The Coop Network",
    symbol: "GMD",
    slug: "the-coop-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-coop-network.png",
  },
  {
    name: "Crypto Fight Club",
    symbol: "FIGHT",
    slug: "crypto-fight-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-fight-club.png",
  },
  {
    name: "AirCoin",
    symbol: "AIR",
    slug: "air",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/air.png",
  },
  {
    name: "Devil Finance",
    symbol: "DEVIL",
    slug: "devil-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/devil-finance.png",
  },
  {
    name: "BIZZCOIN",
    symbol: "BIZZ",
    slug: "bizzcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bizzcoin.png",
  },
  {
    name: "Xpansion Game",
    symbol: "XPS",
    slug: "xpansion-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xpansion-game.png",
  },
  {
    name: "Hanzo Inu",
    symbol: "HNZO",
    slug: "hanzo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hanzo-inu.png",
  },
  {
    name: "Yield Hunt",
    symbol: "GEM",
    slug: "yield-hunt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-hunt.png",
  },
  {
    name: "SpaceDawgs",
    symbol: "DAWGS",
    slug: "spacedawgs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacedawgs.png",
  },
  {
    name: "Coinerr",
    symbol: "ERR",
    slug: "coinerr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinerr.png",
  },
  {
    name: "COIN",
    symbol: "$COIN",
    slug: "coin-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-defi.png",
  },
  {
    name: "PicArtNFT",
    symbol: "PANFT",
    slug: "picartnft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/picartnft.png",
  },
  {
    name: "FXG",
    symbol: "FXG",
    slug: "fxg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fxg.png",
  },
  {
    name: "Infinity Skies",
    symbol: "ISKY",
    slug: "infinity-skies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-skies.png",
  },
  {
    name: "Three Kingdoms",
    symbol: "RTK",
    slug: "three-kingdoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/three-kingdoms.png",
  },
  {
    name: "Pocket Bomb",
    symbol: "PBOM",
    slug: "pocket-bomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocket-bomb.png",
  },
  {
    name: "Tiger Cub",
    symbol: "TCUB",
    slug: "tiger-cub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiger-cub.png",
  },
  {
    name: "DPK",
    symbol: "DPK TOKEN",
    slug: "dpk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dpk.png",
  },
  {
    name: "iShares MSCI World ETF Tokenized Stock Defichain",
    symbol: "DURTH",
    slug: "ishares-msci-world-etf-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ishares-msci-world-etf-tokenized-stock-defichain.png",
  },
  {
    name: "Zinari",
    symbol: "ZINA",
    slug: "zinari",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zinari.png",
  },
  {
    name: "Waves.Exchange Token",
    symbol: "WX",
    slug: "waves-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waves-exchange-token.png",
  },
  {
    name: "KarenCoin",
    symbol: "KAREN",
    slug: "karencoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karencoin.png",
  },
  {
    name: "BattleRoyaleToken",
    symbol: "BRTK",
    slug: "battleroyaletoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battleroyaletoken.png",
  },
  {
    name: "Cantina Royale",
    symbol: "CRT",
    slug: "cantina-royale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cantina-royale.png",
  },
  {
    name: "Hundred Finance",
    symbol: "HND",
    slug: "hundred-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hundred-finance.png",
  },
  {
    name: "Less Network",
    symbol: "LESS",
    slug: "less-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/less-network.png",
  },
  {
    name: "Mewn Inu",
    symbol: "MEWN",
    slug: "mewn-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mewn-inu.png",
  },
  {
    name: "Xcel Swap",
    symbol: "XLD",
    slug: "xcel-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xcel-swap.png",
  },
  {
    name: "Chickenkebab Finance",
    symbol: "CHIK",
    slug: "chickenkebab-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chickenkebab-finance.png",
  },
  {
    name: "Luminos Mining Protocol",
    symbol: "LUMI",
    slug: "luminos-mining-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luminos-mining-protocol.png",
  },
  {
    name: "Hunger Token",
    symbol: "HUNGER",
    slug: "hunger-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hunger-token.png",
  },
  {
    name: "RisingSun",
    symbol: "RSUN",
    slug: "risingsun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/risingsun.png",
  },
  {
    name: "IceSlush Finance",
    symbol: "SLUSH",
    slug: "iceslush-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iceslush-finance.png",
  },
  {
    name: "HotDoge",
    symbol: "HOTDOGE",
    slug: "hotdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hotdoge.png",
  },
  {
    name: "Rentible",
    symbol: "RNB",
    slug: "rentible",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rentible.png",
  },
  {
    name: "Uplift",
    symbol: "LIFT",
    slug: "uplift",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uplift.png",
  },
  {
    name: "Jackpot Universe",
    symbol: "JUNI",
    slug: "jackpot-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jackpot-universe.png",
  },
  {
    name: "Winwinwin Project",
    symbol: "WWIN",
    slug: "winwinwin-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winwinwin-project.png",
  },
  {
    name: "Monster Galaxy",
    symbol: "GGM",
    slug: "monster-galaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-galaxy.png",
  },
  {
    name: "InfinityCake",
    symbol: "INCAKE",
    slug: "infinitycake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitycake.png",
  },
  {
    name: "Intelligent Investment Chain",
    symbol: "IIC",
    slug: "intelligent-investment-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intelligent-investment-chain.png",
  },
  {
    name: "RBX",
    symbol: "RBX",
    slug: "rbx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rbx.png",
  },
  {
    name: "ARTi Project",
    symbol: "ARTI",
    slug: "arti-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arti-project.png",
  },
  {
    name: "Catgirl",
    symbol: "CATGIRL",
    slug: "catgirl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catgirl.png",
  },
  {
    name: "Strike BUSD",
    symbol: "sBUSD",
    slug: "strike-busd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike-busd.png",
  },
  {
    name: "KP2R.Network",
    symbol: "KP2R",
    slug: "kp2r-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kp2r-network.png",
  },
  {
    name: "EtherSmart",
    symbol: "ETM",
    slug: "ethersmart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethersmart.png",
  },
  {
    name: "Mirrored SPDR S&P 500",
    symbol: "MSPY",
    slug: "mirrored-spdr-s-p-500",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-spdr-s-p-500.png",
  },
  {
    name: "Tower Defense Titans",
    symbol: "TITANS",
    slug: "tower-defense-titans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tower-defense-titans.png",
  },
  {
    name: "3X Long Altcoin Index Token",
    symbol: "ALTBULL",
    slug: "3x-long-altcoin-index-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-altcoin-index-token.png",
  },
  {
    name: "Engine",
    symbol: "ENGN",
    slug: "engine-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/engine-token.png",
  },
  {
    name: "Snapy",
    symbol: "SPY",
    slug: "snapy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snapy.png",
  },
  {
    name: "EarnHub",
    symbol: "EHB",
    slug: "earnhub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnhub.png",
  },
  {
    name: "Koisan",
    symbol: "KIC",
    slug: "koisan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koisan.png",
  },
  {
    name: "Rocket Token",
    symbol: "RKTN",
    slug: "rocket-token-space",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-token-space.png",
  },
  {
    name: "Genius Coin",
    symbol: "GENIUS",
    slug: "genius-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genius-coin.png",
  },
  {
    name: "YumYumFarm",
    symbol: "YUM",
    slug: "yumyumfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yumyumfarm.png",
  },
  {
    name: "Okaleido",
    symbol: "OKA",
    slug: "okaleido",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okaleido.png",
  },
  {
    name: "Alien Inu",
    symbol: "ALIEN",
    slug: "alien-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alien-inu.png",
  },
  {
    name: "SaveTheWorld",
    symbol: "SAVE",
    slug: "savetheworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/savetheworld.png",
  },
  {
    name: "Crypto Inu",
    symbol: "ABCD",
    slug: "crypto-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-inu.png",
  },
  {
    name: "LimoCoin Swap",
    symbol: "LMCSWAP",
    slug: "limocoin-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/limocoin-swap.png",
  },
  {
    name: "Bankcoin",
    symbol: "BANK",
    slug: "the-bank-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-bank-coin.png",
  },
  {
    name: "Meliodas",
    symbol: "MELIODAS",
    slug: "meliodas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meliodas.png",
  },
  {
    name: "ChinaZilla",
    symbol: "CHINAZILLA",
    slug: "chinazilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chinazilla.png",
  },
  {
    name: "MetaXPass",
    symbol: "MXP",
    slug: "metaxpass",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaxpass.png",
  },
  {
    name: "Kryxivia",
    symbol: "KXA",
    slug: "kryxivia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kryxivia.png",
  },
  {
    name: "Fino DAO",
    symbol: "FINO",
    slug: "fino-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fino-dao.png",
  },
  {
    name: "Bull Run Finance",
    symbol: "BR",
    slug: "bull-run-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bull-run-finance.png",
  },
  {
    name: "Crypto Raiders",
    symbol: "AURUM",
    slug: "crypto-raiders",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-raiders.png",
  },
  {
    name: "Morpher",
    symbol: "MPH",
    slug: "morpher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morpher.png",
  },
  {
    name: "Theos",
    symbol: "THEOS",
    slug: "theos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/theos.png",
  },
  {
    name: "FOCV",
    symbol: "FOCV",
    slug: "focv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/focv.png",
  },
  {
    name: "Champion Bet Coins",
    symbol: "CBC",
    slug: "champion-bet-coins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/champion-bet-coins.png",
  },
  {
    name: "VICEWRLD",
    symbol: "VICE",
    slug: "vicewrld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vicewrld.png",
  },
  {
    name: "A2DAO",
    symbol: "ATD",
    slug: "a2dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/a2dao.png",
  },
  {
    name: "Crypto Royale",
    symbol: "ROY",
    slug: "crypto-royale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-royale.png",
  },
  {
    name: "GINZA NETWORK",
    symbol: "GINZA",
    slug: "ginza-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ginza-network.png",
  },
  {
    name: "Project Quantum",
    symbol: "QBIT",
    slug: "project-quantum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-quantum.png",
  },
  {
    name: "Lunar",
    symbol: "LNR",
    slug: "lunar-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunar-v2.png",
  },
  {
    name: "Aave WBTC",
    symbol: "AWBTC",
    slug: "aave-wbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-wbtc.png",
  },
  {
    name: "EverReflect",
    symbol: "EVRF",
    slug: "everreflect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everreflect.png",
  },
  {
    name: "SafeBank ETH",
    symbol: "SBANK",
    slug: "safebank-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safebank-eth.png",
  },
  {
    name: "Revenue Coin",
    symbol: "RVC",
    slug: "revenue-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revenue-coin.png",
  },
  {
    name: "Studio Shibli",
    symbol: "SHIBLI",
    slug: "studio-shibli",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/studio-shibli.png",
  },
  {
    name: "Small dogecoin",
    symbol: "SDOG",
    slug: "small-dogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/small-dogecoin.png",
  },
  {
    name: "YSL.IO",
    symbol: "YSL",
    slug: "ysl-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ysl-io.png",
  },
  {
    name: "Terbo Game Coin",
    symbol: "TGC",
    slug: "terbo-game-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terbo-game-coin.png",
  },
  {
    name: "Poochain",
    symbol: "POOP",
    slug: "poochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poochain.png",
  },
  {
    name: "Hudi",
    symbol: "HUDI",
    slug: "hudi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hudi.png",
  },
  {
    name: "ElonDoge",
    symbol: "EDOGE",
    slug: "elondoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elondoge.png",
  },
  {
    name: "Secured MoonRat Token",
    symbol: "SMRAT",
    slug: "secured-moonrat-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secured-moonrat-token.png",
  },
  {
    name: "ShopNEXT",
    symbol: "NEXT",
    slug: "shopnext",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shopnext.png",
  },
  {
    name: "Unicly Genesis MoonCats Collection",
    symbol: "UGMC",
    slug: "unicly-genesis-mooncats-collection",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicly-genesis-mooncats-collection.png",
  },
  {
    name: "IDM Token",
    symbol: "IDM",
    slug: "idm-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idm-token.png",
  },
  {
    name: "Eternal Oasis",
    symbol: "ETOS",
    slug: "eternal-oasis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eternal-oasis.png",
  },
  {
    name: "Quidax",
    symbol: "QDX",
    slug: "quidax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quidax.png",
  },
  {
    name: "CorgiNFTGame",
    symbol: "COR",
    slug: "corginftgame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corginftgame.png",
  },
  {
    name: "PokeDX",
    symbol: "PDX",
    slug: "pokedx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pokedx.png",
  },
  {
    name: "SIMBA Storage Token",
    symbol: "SST",
    slug: "simba-storage-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simba-storage-token.png",
  },
  {
    name: "Magic Power",
    symbol: "MGP",
    slug: "magic-power",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-power.png",
  },
  {
    name: "Sombra",
    symbol: "SMBR",
    slug: "sombra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sombra.png",
  },
  {
    name: "Project21",
    symbol: "P21",
    slug: "project21",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project21.png",
  },
  {
    name: "HarryPotterObamaSonic10Inu",
    symbol: "BITCOIN",
    slug: "harrypotterobamasonic10inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harrypotterobamasonic10inu.png",
  },
  {
    name: "GoldFarm",
    symbol: "GOLD",
    slug: "goldfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldfarm.png",
  },
  {
    name: "Shar Pei",
    symbol: "SHARPEI",
    slug: "shar-pei",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shar-pei.png",
  },
  {
    name: "Juicebox",
    symbol: "JBX",
    slug: "juicebox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/juicebox.png",
  },
  {
    name: "Daikicoin",
    symbol: "DIC",
    slug: "daikicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daikicoin.png",
  },
  {
    name: "EXMOC",
    symbol: "EXMOC",
    slug: "exmoc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exmoc.png",
  },
  {
    name: "Shiba Floki",
    symbol: "FLOKI",
    slug: "shiba-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-floki.png",
  },
  {
    name: "BambooDeFi",
    symbol: "BAMBOO",
    slug: "bamboo-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bamboo-defi.png",
  },
  {
    name: "HeroesTD",
    symbol: "CGC",
    slug: "heroestd-cgc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heroestd-cgc.png",
  },
  {
    name: "Echidna",
    symbol: "ECD",
    slug: "echidna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/echidna.png",
  },
  {
    name: "Texas Protocol",
    symbol: "TXS",
    slug: "texas-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/texas-protocol.png",
  },
  {
    name: "DeFIRE",
    symbol: "CWAP",
    slug: "defire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defire.png",
  },
  {
    name: "EVERSOL",
    symbol: "ESOL",
    slug: "eversol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eversol.png",
  },
  {
    name: "Lets Go Brandon",
    symbol: "LETSGO",
    slug: "lgb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lgb.png",
  },
  {
    name: "Varen",
    symbol: "VRN",
    slug: "varen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/varen.png",
  },
  {
    name: "Eternal Spire V2",
    symbol: "ENSP V2",
    slug: "eternal-spire-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eternal-spire-v2.png",
  },
  {
    name: "τDoge",
    symbol: "ΤDOGE",
    slug: "tdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tdoge.png",
  },
  {
    name: "Enrex",
    symbol: "ENRX",
    slug: "enrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enrex.png",
  },
  {
    name: "Traxx",
    symbol: "TRAXX",
    slug: "traxx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/traxx.png",
  },
  {
    name: "Lith Token",
    symbol: "LITH",
    slug: "lith-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lith-token.png",
  },
  {
    name: "Starfish Finance",
    symbol: "SEAN",
    slug: "starfish-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starfish-finance.png",
  },
  {
    name: "Wizardium",
    symbol: "WIZZY",
    slug: "wizardium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wizardium.png",
  },
  {
    name: "Greenex",
    symbol: "GNX",
    slug: "greenex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenex.png",
  },
  {
    name: "Polygen",
    symbol: "PGEN",
    slug: "polygen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polygen.png",
  },
  {
    name: "Coupon Chain",
    symbol: "CCT",
    slug: "coupon-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coupon-chain.png",
  },
  {
    name: "OEC FIL",
    symbol: "FILK",
    slug: "oec-fil",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oec-fil.png",
  },
  {
    name: "EIDOS",
    symbol: "EIDOS",
    slug: "eidos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eidos.png",
  },
  {
    name: "Echain Network",
    symbol: "ECT",
    slug: "echain-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/echain-network.png",
  },
  {
    name: "Playermon",
    symbol: "PYM",
    slug: "playermon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playermon.png",
  },
  {
    name: "Bandot Protocol",
    symbol: "BDT",
    slug: "bandot-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bandot-protocol.png",
  },
  {
    name: "Apes Token",
    symbol: "APES",
    slug: "apes-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apes-token.png",
  },
  {
    name: "Artwork NFT",
    symbol: "ANFT",
    slug: "artwork-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artwork-nft.png",
  },
  {
    name: "Zatcoin",
    symbol: "ZATCOIN",
    slug: "zatcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zatcoin.png",
  },
  {
    name: "Pawthereum",
    symbol: "PAWTH",
    slug: "pawthereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pawthereum.png",
  },
  {
    name: "HashBridge Oracle",
    symbol: "HBO",
    slug: "hashbridge-oracle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashbridge-oracle.png",
  },
  {
    name: "BullionFx",
    symbol: "BULL",
    slug: "bullionfx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullionfx.png",
  },
  {
    name: "New Paradigm Assets Solution",
    symbol: "NPAS",
    slug: "new-paradigm-assets-solution",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-paradigm-assets-solution.png",
  },
  {
    name: "Solidray Finance",
    symbol: "SRT",
    slug: "solidray-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solidray-finance.png",
  },
  {
    name: "AnchorUST",
    symbol: "AUST",
    slug: "anchorust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anchorust.png",
  },
  {
    name: "AxeDAO",
    symbol: "AXE",
    slug: "axedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axedao.png",
  },
  {
    name: "Binance Smart Chain Girl",
    symbol: "BSCGIRL",
    slug: "binance-smart-chain-girl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binance-smart-chain-girl.png",
  },
  {
    name: "hodlME",
    symbol: "HODM",
    slug: "hodlme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodlme.png",
  },
  {
    name: "Oath",
    symbol: "OATH",
    slug: "oath",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oath.png",
  },
  {
    name: "Artverse Token",
    symbol: "AVT",
    slug: "artverse-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artverse-token.png",
  },
  {
    name: "LemoChain",
    symbol: "LEMO",
    slug: "lemochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lemochain.png",
  },
  {
    name: "BOY X HIGHSPEED",
    symbol: "BXH",
    slug: "boy-x-highspeed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boy-x-highspeed.png",
  },
  {
    name: "BonezYard",
    symbol: "BNZ",
    slug: "bonezyard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonezyard.png",
  },
  {
    name: "PremiumBlock",
    symbol: "PRB",
    slug: "premiumblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/premiumblock.png",
  },
  {
    name: "Grape Finance",
    symbol: "GRAPE",
    slug: "grape-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grape-finance.png",
  },
  {
    name: "SafeZone",
    symbol: "SAFEZONE",
    slug: "safezone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safezone.png",
  },
  {
    name: "Ghospers Game",
    symbol: "GHSPR",
    slug: "ghospers-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghospers-game.png",
  },
  {
    name: "ITAM CUBE",
    symbol: "ITAMCUBE",
    slug: "itam-cube",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/itam-cube.png",
  },
  {
    name: "Amplify Protocol",
    symbol: "AMPT",
    slug: "amplify-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amplify-protocol.png",
  },
  {
    name: "Meland.ai",
    symbol: "MELD",
    slug: "meland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meland.png",
  },
  {
    name: "Lucky Property Development Invest",
    symbol: "LPDI",
    slug: "lucky-property-development-invest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-property-development-invest.png",
  },
  {
    name: "NADA Protocol Token",
    symbol: "NADA",
    slug: "nada-protocol-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nada-protocol-token.png",
  },
  {
    name: "Experiencer",
    symbol: "EXPR",
    slug: "experiencer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/experiencer.png",
  },
  {
    name: "Ramifi Protocol",
    symbol: "RAM",
    slug: "ramifi-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ramifi-protocol.png",
  },
  {
    name: "Kromatika",
    symbol: "KROM",
    slug: "kromatika",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kromatika.png",
  },
  {
    name: "My Shiba Academia",
    symbol: "MSA",
    slug: "my-shiba-academia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-shiba-academia.png",
  },
  {
    name: "ChainCade",
    symbol: "CHAINCADE",
    slug: "chaincade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chaincade.png",
  },
  {
    name: "Snowdog",
    symbol: "SDOG",
    slug: "snowdog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowdog.png",
  },
  {
    name: "Partial",
    symbol: "PARTIAL",
    slug: "partial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/partial.png",
  },
  {
    name: "SatoShiba",
    symbol: "SATO",
    slug: "satoshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoshiba.png",
  },
  {
    name: "Tiny Coin",
    symbol: "TINC",
    slug: "tiny-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiny-coin.png",
  },
  {
    name: "1x Short Bitcoin Token",
    symbol: "HEDGE",
    slug: "1x-short-bitcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1x-short-bitcoin-token.png",
  },
  {
    name: "PlayAndLike",
    symbol: "PAL",
    slug: "playandlike",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playandlike.png",
  },
  {
    name: "Puppy Doge",
    symbol: "PUPPY",
    slug: "puppy-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puppy-doge.png",
  },
  {
    name: "Lunaverse",
    symbol: "LUV",
    slug: "lunaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunaverse.png",
  },
  {
    name: "Voltage",
    symbol: "VOLT",
    slug: "voltage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voltage.png",
  },
  {
    name: "37Protocol",
    symbol: "37C",
    slug: "37protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/37protocol.png",
  },
  {
    name: "Uhive",
    symbol: "HVE2",
    slug: "uhive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uhive.png",
  },
  {
    name: "PetsHelp Coin",
    symbol: "PETH",
    slug: "petshelp-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petshelp-coin.png",
  },
  {
    name: "Pontoon",
    symbol: "TOON",
    slug: "pontoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pontoon.png",
  },
  {
    name: "APWine Finance",
    symbol: "APW",
    slug: "apwine-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apwine-finance.png",
  },
  {
    name: "Binance Wrapped DOT",
    symbol: "BDOT",
    slug: "binance-wrapped-dot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binance-wrapped-dot.png",
  },
  {
    name: "Ratio Finance",
    symbol: "RATIO",
    slug: "ratio-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ratio-finance.png",
  },
  {
    name: "MetaSnooker",
    symbol: "MSR",
    slug: "metasnooker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metasnooker.png",
  },
  {
    name: "BNS Token",
    symbol: "BNS",
    slug: "bns-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bns-token.png",
  },
  {
    name: "Dukecoin",
    symbol: "DKC",
    slug: "dukecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dukecoin.png",
  },
  {
    name: "Branaverse",
    symbol: "BRANA",
    slug: "branaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/branaverse.png",
  },
  {
    name: "Basilisk",
    symbol: "BSX",
    slug: "basilisk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basilisk.png",
  },
  {
    name: "Shiba World Cup",
    symbol: "SWC",
    slug: "shiba-world-cup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-world-cup.png",
  },
  {
    name: "Master Floki",
    symbol: "MASTER",
    slug: "master-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/master-floki.png",
  },
  {
    name: "RichQUACK.com",
    symbol: "QUACK",
    slug: "richquack-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/richquack-com.png",
  },
  {
    name: "CoinOne Token",
    symbol: "CONE",
    slug: "coinone-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinone-token.png",
  },
  {
    name: "Doge Floki Coin",
    symbol: "DOFI",
    slug: "doge-floki-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-floki-coin.png",
  },
  {
    name: "Space Token",
    symbol: "SPACE",
    slug: "space-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-token.png",
  },
  {
    name: "NanTrade",
    symbol: "NAN",
    slug: "nantrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nantrade.png",
  },
  {
    name: "Supercars",
    symbol: "CAR",
    slug: "supercars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supercars.png",
  },
  {
    name: "ZCore Finance",
    symbol: "ZEFI",
    slug: "zcore-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zcore-finance.png",
  },
  {
    name: "Earnable v2",
    symbol: "EARN",
    slug: "earnable-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnable-v2.png",
  },
  {
    name: "Pacific DeFi",
    symbol: "PACIFIC",
    slug: "pacific-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pacific-defi.png",
  },
  {
    name: "DogeFather",
    symbol: "FATHER",
    slug: "dogefather-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogefather-token.png",
  },
  {
    name: "Fat Doge",
    symbol: "FOGE",
    slug: "fat-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fat-doge.png",
  },
  {
    name: "Akt.io",
    symbol: "AKTIO",
    slug: "akt-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akt-io.png",
  },
  {
    name: "Nimbus",
    symbol: "NBU",
    slug: "nimbus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nimbus.png",
  },
  {
    name: "Squidanomics",
    symbol: "SQUID",
    slug: "squidanomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squidanomics.png",
  },
  {
    name: "Micro Bitcoin",
    symbol: "MB",
    slug: "micro-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/micro-bitcoin.png",
  },
  {
    name: "POP Network Token",
    symbol: "POP",
    slug: "pop-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pop-network-token.png",
  },
  {
    name: "DOEX",
    symbol: "DOEX",
    slug: "doex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doex.png",
  },
  {
    name: "SB GROUP",
    symbol: "SB",
    slug: "dragonsb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonsb.png",
  },
  {
    name: "Scorpion Finance",
    symbol: "SCORPFIN",
    slug: "scorpion-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scorpion-finance.png",
  },
  {
    name: "Shibtoro",
    symbol: "SHIBTORO",
    slug: "shibtoro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibtoro.png",
  },
  {
    name: "Dina",
    symbol: "DINA",
    slug: "dina",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dina.png",
  },
  {
    name: "Profit Bank",
    symbol: "PBK",
    slug: "profit-bank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/profit-bank.png",
  },
  {
    name: "VPEX Exchange",
    symbol: "VPX",
    slug: "vpex-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vpex-exchange.png",
  },
  {
    name: "UNION Protocol Governance Token",
    symbol: "UNN",
    slug: "union-protocol-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/union-protocol-governance-token.png",
  },
  {
    name: "Aurora",
    symbol: "AURORA",
    slug: "aurora-near",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurora-near.png",
  },
  {
    name: "CraneMiners.co",
    symbol: "CRANE",
    slug: "craneminers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/craneminers.png",
  },
  {
    name: "MILC Platform",
    symbol: "MLT",
    slug: "milc-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milc-platform.png",
  },
  {
    name: "WCAPES",
    symbol: "WCA",
    slug: "wcapes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wcapes.png",
  },
  {
    name: "Ideanet Token",
    symbol: "INET",
    slug: "ideanet-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ideanet-token.png",
  },
  {
    name: "Moonarch.app",
    symbol: "MOONARCH",
    slug: "moonarch-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonarch-app.png",
  },
  {
    name: "Son of Babydoge",
    symbol: "SOB",
    slug: "son-of-babydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/son-of-babydoge.png",
  },
  {
    name: "The Essential Coin",
    symbol: "ESC",
    slug: "the-essential-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-essential-coin.png",
  },
  {
    name: "ThePiggyGarden",
    symbol: "TPG",
    slug: "thepiggygarden",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thepiggygarden.png",
  },
  {
    name: "NFTOPIA",
    symbol: "TOPIA",
    slug: "nftopia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftopia.png",
  },
  {
    name: "GreatDane",
    symbol: "GREATDANE",
    slug: "greatdane",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greatdane.png",
  },
  {
    name: "Sheesh it is bussin bussin",
    symbol: "SHEESH",
    slug: "sheesh-it-is-bussin-bussin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sheesh-it-is-bussin-bussin.png",
  },
  {
    name: "99DEFI.NETWORK",
    symbol: "99DEFI",
    slug: "99defi-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/99defi-network.png",
  },
  {
    name: "RFOX Finance",
    symbol: "VFOX",
    slug: "rfox-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rfox-finance.png",
  },
  {
    name: "SolDoge",
    symbol: "SDOGE",
    slug: "soldoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soldoge.png",
  },
  {
    name: "Musashi Finance",
    symbol: "MUS",
    slug: "musashi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musashi-finance.png",
  },
  {
    name: "WingShop",
    symbol: "WING",
    slug: "wingshop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wingshop.png",
  },
  {
    name: "meh",
    symbol: "MEH",
    slug: "meh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meh.png",
  },
  {
    name: "Interest Protocol (USDI)",
    symbol: "USDI",
    slug: "interest-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/interest-protocol.png",
  },
  {
    name: "Elephant Money",
    symbol: "ELEPHANT",
    slug: "elephant-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elephant-money.png",
  },
  {
    name: "9D NFT",
    symbol: "COGI",
    slug: "9d-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/9d-nft.png",
  },
  {
    name: "YTizer",
    symbol: "YTZ",
    slug: "ytizer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ytizer.png",
  },
  {
    name: "Moon Stop",
    symbol: "MNSTP",
    slug: "moon-stop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-stop.png",
  },
  {
    name: "LITTLE RABBIT",
    symbol: "LTRBT",
    slug: "little-rabbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/little-rabbit.png",
  },
  {
    name: "Instrumental Finance",
    symbol: "STRM",
    slug: "instrumental-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/instrumental-finance.png",
  },
  {
    name: "Qitchain Network",
    symbol: "QTC",
    slug: "qitchain-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qitchain-network.png",
  },
  {
    name: "Zeitgeist",
    symbol: "ZTG",
    slug: "zeitgeist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeitgeist.png",
  },
  {
    name: "Donkey",
    symbol: "DONK",
    slug: "donkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/donkey.png",
  },
  {
    name: "X7R",
    symbol: "X7R",
    slug: "x7r",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x7r.png",
  },
  {
    name: "Octafarm",
    symbol: "OCTF",
    slug: "octafarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octafarm.png",
  },
  {
    name: "Polly DeFi nest",
    symbol: "NDEFI",
    slug: "polly-defi-nest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polly-defi-nest.png",
  },
  {
    name: "BIGFOOT",
    symbol: "FOOT",
    slug: "bigfoot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bigfoot.png",
  },
  {
    name: "HaggleX",
    symbol: "HAG",
    slug: "hagglex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hagglex.png",
  },
  {
    name: "Cure Token",
    symbol: "CURE",
    slug: "cure-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cure-token.png",
  },
  {
    name: "TABANK",
    symbol: "TAB",
    slug: "tabank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tabank.png",
  },
  {
    name: "LITTLE BABY DOGE",
    symbol: "LBD",
    slug: "little-baby-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/little-baby-doge.png",
  },
  {
    name: "Maison Capital",
    symbol: "MSN",
    slug: "maison-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maison-capital.png",
  },
  {
    name: "KONG Land",
    symbol: "$CITIZEN",
    slug: "kong-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kong-land.png",
  },
  {
    name: "PlayChip",
    symbol: "PLA",
    slug: "playchip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playchip.png",
  },
  {
    name: "Millionaire Land",
    symbol: "TOK",
    slug: "millionaire-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/millionaire-land.png",
  },
  {
    name: "Bullrise",
    symbol: "BULL",
    slug: "bullrise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullrise.png",
  },
  {
    name: "DnD Metaverse",
    symbol: "DNDB",
    slug: "dnd-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dnd-metaverse.png",
  },
  {
    name: "LABEL Foundation",
    symbol: "LBL",
    slug: "label-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/label-foundation.png",
  },
  {
    name: "Ridotto",
    symbol: "RDT",
    slug: "ridotto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ridotto.png",
  },
  {
    name: "AmazingDoge",
    symbol: "ADOGE",
    slug: "amazingdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amazingdoge.png",
  },
  {
    name: "Ally Direct Token",
    symbol: "DRCT",
    slug: "ally-direct-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ally-direct-token.png",
  },
  {
    name: "MetaMatrix",
    symbol: "MTX",
    slug: "metamatrix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamatrix.png",
  },
  {
    name: "GreenFuel",
    symbol: "GREENFUEL",
    slug: "greenfuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenfuel.png",
  },
  {
    name: "CyberTime Finance (DAOS)",
    symbol: "DAOS",
    slug: "cybertime-finance-daos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cybertime-finance-daos.png",
  },
  {
    name: "A4",
    symbol: "A4",
    slug: "a4",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/a4.png",
  },
  {
    name: "Shibooster",
    symbol: "SHIBOOST",
    slug: "shibooster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibooster.png",
  },
  {
    name: "GPEX",
    symbol: "GPX",
    slug: "gpex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gpex.png",
  },
  {
    name: "Colony Network Token",
    symbol: "CLNY",
    slug: "colony-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colony-network-token.png",
  },
  {
    name: "Olive.Cash",
    symbol: "OLIVE",
    slug: "olive-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olive-cash.png",
  },
  {
    name: "PirateDAO",
    symbol: "JOLLY",
    slug: "piratedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piratedao.png",
  },
  {
    name: "Coffin Dollar",
    symbol: "COUSD",
    slug: "coffin-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coffin-dollar.png",
  },
  {
    name: "The Web3 Project (new)",
    symbol: "TWEP",
    slug: "the-web3-project-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-web3-project-new.png",
  },
  {
    name: "Kiwi Finance",
    symbol: "KIWI",
    slug: "kiwi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiwi-finance.png",
  },
  {
    name: "Solminter",
    symbol: "SMRT",
    slug: "solminter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solminter.png",
  },
  {
    name: "Global Digital Content",
    symbol: "GDC",
    slug: "global-digital-content",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-digital-content.png",
  },
  {
    name: "Carrot",
    symbol: "CRT",
    slug: "carrot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carrot.png",
  },
  {
    name: "Orca AVAI",
    symbol: "AVAI",
    slug: "orca-avai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orca-avai.png",
  },
  {
    name: "MarsDAO",
    symbol: "MDAO",
    slug: "marsdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marsdao.png",
  },
  {
    name: "MetaGaming Guild",
    symbol: "MGG",
    slug: "metagaming-guild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagaming-guild.png",
  },
  {
    name: "Shiba BSC",
    symbol: "SHIBSC",
    slug: "shiba-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-bsc.png",
  },
  {
    name: "Shibird",
    symbol: "SHIRD",
    slug: "shibird",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibird.png",
  },
  {
    name: "Gooreo",
    symbol: "GOOREO",
    slug: "gooreo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gooreo.png",
  },
  {
    name: "ApeJet",
    symbol: "JET",
    slug: "apejet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apejet.png",
  },
  {
    name: "InfinityDOT",
    symbol: "IDOT",
    slug: "infinitydot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitydot.png",
  },
  {
    name: "Wish Doge Dragon",
    symbol: "WISHDOGE",
    slug: "wish-doge-dragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wish-doge-dragon.png",
  },
  {
    name: "MagnetGold",
    symbol: "MTG",
    slug: "magnet-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magnet-gold.png",
  },
  {
    name: "Mirrored Nio",
    symbol: "MNIO",
    slug: "mirrored-nio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-nio.png",
  },
  {
    name: "Electric Arena",
    symbol: "EARENA",
    slug: "electric-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electric-arena.png",
  },
  {
    name: "AGAME",
    symbol: "AG",
    slug: "agame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agame.png",
  },
  {
    name: "Web3 Finance",
    symbol: "WEB",
    slug: "web3-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web3-finance.png",
  },
  {
    name: "SpaceCorgi",
    symbol: "SCORGI",
    slug: "spacecorgi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacecorgi.png",
  },
  {
    name: "Floki Adventure",
    symbol: "FIAT",
    slug: "floki-adventure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-adventure.png",
  },
  {
    name: "PEACOCKCOIN (ERC)",
    symbol: "PEKC",
    slug: "peacockcoin-erc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peacockcoin-erc.png",
  },
  {
    name: "BENQI Liquid Staked AVAX",
    symbol: "SAVAX",
    slug: "benqi-liquid-staked-avax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/benqi-liquid-staked-avax.png",
  },
  {
    name: "Mazzuma",
    symbol: "MAZ",
    slug: "mazzuma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mazzuma.png",
  },
  {
    name: "mKitty",
    symbol: "MKITTY",
    slug: "mkitty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mkitty.png",
  },
  {
    name: "Spellfire",
    symbol: "SPELLFIRE",
    slug: "spellfire-re-master-the-magic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spellfire-re-master-the-magic.png",
  },
  {
    name: "Mars Space X",
    symbol: "MPX",
    slug: "mars-space-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mars-space-x.png",
  },
  {
    name: "Mewtwo Inu",
    symbol: "MEWTWO",
    slug: "mewtwo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mewtwo-inu.png",
  },
  {
    name: "TurboTrix Finance",
    symbol: "TTF",
    slug: "turbotrix-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turbotrix-finance.png",
  },
  {
    name: "InfinityGame",
    symbol: "IFG",
    slug: "infinitygame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitygame.png",
  },
  {
    name: "Green Satoshi Token (ETH)",
    symbol: "GST",
    slug: "green-satoshi-token-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-satoshi-token-eth.png",
  },
  {
    name: "AVME",
    symbol: "AVME",
    slug: "avme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avme.png",
  },
  {
    name: "VinDax Coin",
    symbol: "VD",
    slug: "vindax-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vindax-coin.png",
  },
  {
    name: "Vidiachange",
    symbol: "VIDA",
    slug: "vidiachange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vidiachange.png",
  },
  {
    name: "Musk Gold",
    symbol: "MUSK",
    slug: "musk-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musk-gold.png",
  },
  {
    name: "ELONS RABBIT",
    symbol: "ERABBIT",
    slug: "elonsrabbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elonsrabbit.png",
  },
  {
    name: "Dogira",
    symbol: "DOGIRA",
    slug: "dogira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogira.png",
  },
  {
    name: "Millionaire Maker",
    symbol: "MILLION",
    slug: "millionaire-maker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/millionaire-maker.png",
  },
  {
    name: "OneRare",
    symbol: "ORARE",
    slug: "onerare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onerare.png",
  },
  {
    name: "bitCEO",
    symbol: "BCEO",
    slug: "bitceo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitceo.png",
  },
  {
    name: "Uniswap Finance [new]",
    symbol: "UNFI",
    slug: "uniswap-finance-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniswap-finance-new.png",
  },
  {
    name: "UniDexGas",
    symbol: "UNDG",
    slug: "unidexgas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unidexgas.png",
  },
  {
    name: "Shepherd Inu",
    symbol: "SINU",
    slug: "shepherd-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shepherd-inu.png",
  },
  {
    name: "Shihtzu Exchange",
    symbol: "STZU",
    slug: "shihtzu-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shihtzu-exchange.png",
  },
  {
    name: "SHIRO INU",
    symbol: "SHIR",
    slug: "shiro-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiro-inu.png",
  },
  {
    name: "Project202",
    symbol: "P202",
    slug: "project202",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project202.png",
  },
  {
    name: "AirNFTs",
    symbol: "AIRT",
    slug: "airnfts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/airnfts.png",
  },
  {
    name: "Stable 1inch",
    symbol: "ONE1INCH",
    slug: "stable-1inch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stable-1inch.png",
  },
  {
    name: "BUSDX Fuel",
    symbol: "XFUEL",
    slug: "busdx-fuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/busdx-fuel.png",
  },
  {
    name: "DAO1",
    symbol: "DAO1",
    slug: "dao1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dao1.png",
  },
  {
    name: "Karmaverse",
    symbol: "KNOT",
    slug: "karmaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karmaverse.png",
  },
  {
    name: "Crypto Rocket Launch",
    symbol: "CRL",
    slug: "crypto-rocket-launch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-rocket-launch.png",
  },
  {
    name: "Loot Token",
    symbol: "LOOT",
    slug: "loot-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loot-token.png",
  },
  {
    name: "Moonwell Artemis",
    symbol: "WELL",
    slug: "moonwell-artemis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonwell-artemis.png",
  },
  {
    name: "Hubii Network",
    symbol: "HBT",
    slug: "hubii-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hubii-network.png",
  },
  {
    name: "Shiwa",
    symbol: "SHIWA",
    slug: "shiwa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiwa.png",
  },
  {
    name: "OSK",
    symbol: "OSK",
    slug: "osk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/osk.png",
  },
  {
    name: "MiniSwap",
    symbol: "MINI",
    slug: "miniswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miniswap.png",
  },
  {
    name: "MetaBrands",
    symbol: "MAGE",
    slug: "metabrands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabrands.png",
  },
  {
    name: "Animal Concerts",
    symbol: "ANML",
    slug: "animal-concerts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animal-concerts.png",
  },
  {
    name: "Interfinex",
    symbol: "IFEX",
    slug: "interfinex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/interfinex.png",
  },
  {
    name: "Bee Capital",
    symbol: "BEE",
    slug: "bee-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bee-capital.png",
  },
  {
    name: "Solberg",
    symbol: "SLB",
    slug: "solberg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solberg.png",
  },
  {
    name: "12Ships",
    symbol: "TSHP",
    slug: "12ships",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/12ships.png",
  },
  {
    name: "Baby Floki Doge",
    symbol: "BABYFD",
    slug: "baby-floki-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-floki-doge.png",
  },
  {
    name: "LuxFi",
    symbol: "LXF",
    slug: "luxfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luxfi.png",
  },
  {
    name: "Chopper Inu",
    symbol: "CHOPPER",
    slug: "chopper-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chopper-inu.png",
  },
  {
    name: "MoonPirate",
    symbol: "MOONPIRATE",
    slug: "moonpirate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonpirate.png",
  },
  {
    name: "Catge coin",
    symbol: "CATGE",
    slug: "catge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catge-coin.png",
  },
  {
    name: "Takamaka Green Coin",
    symbol: "TKG",
    slug: "takamaka-green-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/takamaka-green-coin.png",
  },
  {
    name: "Nation3",
    symbol: "NATION",
    slug: "nation3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nation3.png",
  },
  {
    name: "ARTX Trading",
    symbol: "ARTX",
    slug: "artx-trading",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artx-trading.png",
  },
  {
    name: "AlgoPad",
    symbol: "ALGOPAD",
    slug: "algopad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algopad.png",
  },
  {
    name: "Free Speech",
    symbol: "1AMD",
    slug: "free-speech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/free-speech.png",
  },
  {
    name: "ETHER TERRESTRIAL",
    symbol: "ET",
    slug: "ether-terrestrial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ether-terrestrial.png",
  },
  {
    name: "Snap Token",
    symbol: "SNAP",
    slug: "snapex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snapex.png",
  },
  {
    name: "Liquidity Dividends Protocol",
    symbol: "LID",
    slug: "liquidity-dividends-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquidity-dividends-protocol.png",
  },
  {
    name: "Kitty Coin",
    symbol: "KITTY",
    slug: "kitty-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitty-coin.png",
  },
  {
    name: "Dymmax",
    symbol: "DMX",
    slug: "dymmax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dymmax.png",
  },
  {
    name: "Plutonians",
    symbol: "PLD",
    slug: "plutonians-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plutonians-tech.png",
  },
  {
    name: "Umami Finance",
    symbol: "UMAMI",
    slug: "umami-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/umami-finance.png",
  },
  {
    name: "Shikoku Inu",
    symbol: "SHIKO",
    slug: "shikoku-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shikoku-inu.png",
  },
  {
    name: "Shiba Watch",
    symbol: "SHIBAW",
    slug: "shiba-watch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-watch.png",
  },
  {
    name: "KogeCoin.io",
    symbol: "KOGECOIN",
    slug: "kogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kogecoin.png",
  },
  {
    name: "Singularity",
    symbol: "SGLY",
    slug: "singularity-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/singularity-gold.png",
  },
  {
    name: "Geckolands",
    symbol: "GEC",
    slug: "geckolands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geckolands.png",
  },
  {
    name: "LunchDAO",
    symbol: "LUNCH",
    slug: "lunchdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunchdao.png",
  },
  {
    name: "Your Finance Decentralized",
    symbol: "YFD",
    slug: "yfdfi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfdfi-finance.png",
  },
  {
    name: "Water Reminder",
    symbol: "WATER",
    slug: "water-reminder",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/water-reminder.png",
  },
  {
    name: "Diamond Hands Token",
    symbol: "DHC",
    slug: "diamond-hands-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-hands-token.png",
  },
  {
    name: "Castello Coin",
    symbol: "CAST",
    slug: "castello-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/castello-coin.png",
  },
  {
    name: "SnoopDAO",
    symbol: "SNOOP",
    slug: "snoopdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snoopdao.png",
  },
  {
    name: "Moonfarm Finance",
    symbol: "MFO",
    slug: "moonfarm-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonfarm-finance.png",
  },
  {
    name: "Lido wstETH",
    symbol: "WSTETH",
    slug: "lido-finance-wsteth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lido-finance-wsteth.png",
  },
  {
    name: "NFCore",
    symbol: "NFCR",
    slug: "nfcore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nfcore.png",
  },
  {
    name: "Copiosa Coin",
    symbol: "COP",
    slug: "copiosa-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/copiosa-coin.png",
  },
  {
    name: "Asgard Dao",
    symbol: "ASGARD",
    slug: "asgard-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asgard-dao.png",
  },
  {
    name: "BNBPot",
    symbol: "BNBP",
    slug: "bnbpot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbpot.png",
  },
  {
    name: "NAFTY",
    symbol: "NAFTY",
    slug: "nafty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nafty.png",
  },
  {
    name: "ThunderADA.app",
    symbol: "THUNDERADA",
    slug: "thunderada-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thunderada-app.png",
  },
  {
    name: "Nintia Estate",
    symbol: "NINTI",
    slug: "nintia-estate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nintia-estate.png",
  },
  {
    name: "Rewardeum",
    symbol: "REUM",
    slug: "rewardeum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rewardeum.png",
  },
  {
    name: "LeisureMeta",
    symbol: "LM",
    slug: "leisuremeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leisuremeta.png",
  },
  {
    name: "Hashmasks",
    symbol: "MASK20",
    slug: "hashmasks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashmasks.png",
  },
  {
    name: "Ludos Protocol",
    symbol: "LUD",
    slug: "ludos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ludos.png",
  },
  {
    name: "$LONDON",
    symbol: "LONDON",
    slug: "london",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/london.png",
  },
  {
    name: "Baby Cheems Inu",
    symbol: "BCI",
    slug: "baby-cheems-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-cheems-inu.png",
  },
  {
    name: "Upper Pound",
    symbol: "GBPU",
    slug: "upper-pound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upper-pound.png",
  },
  {
    name: "OUD",
    symbol: "OUD",
    slug: "oud",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oud.png",
  },
  {
    name: "TetraHedra",
    symbol: "TTH",
    slug: "tetrahedra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tetrahedra.png",
  },
  {
    name: "Dough",
    symbol: "DOUGH",
    slug: "dough",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dough.png",
  },
  {
    name: "Atlas Cloud",
    symbol: "ATLAS",
    slug: "atlas-cloud",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlas-cloud.png",
  },
  {
    name: "MM Optimizer",
    symbol: "MMO",
    slug: "mm-optimizer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mm-optimizer.png",
  },
  {
    name: "Mainston",
    symbol: "STON",
    slug: "mainston",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mainston.png",
  },
  {
    name: "SKY FRONTIER",
    symbol: "GSKY",
    slug: "sky-frontier",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sky-frontier.png",
  },
  {
    name: "Volatility Protocol Token",
    symbol: "VOL",
    slug: "volatility-protocol-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/volatility-protocol-token.png",
  },
  {
    name: "DEGEN Index",
    symbol: "DEGEN",
    slug: "degen-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degen-index.png",
  },
  {
    name: "LORDS",
    symbol: "LORDS",
    slug: "lords",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lords.png",
  },
  {
    name: "Hachiko",
    symbol: "HACHIKO",
    slug: "hachiko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hachiko.png",
  },
  {
    name: "Myōbu",
    symbol: "MYOBU",
    slug: "myobu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myobu.png",
  },
  {
    name: "Chronic Token",
    symbol: "CHT",
    slug: "chronic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chronic-token.png",
  },
  {
    name: "DeFIL",
    symbol: "DFL",
    slug: "defil",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defil.png",
  },
  {
    name: "Hurrian Network",
    symbol: "MLD",
    slug: "hurrian-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hurrian-network.png",
  },
  {
    name: "Decimal",
    symbol: "DEL",
    slug: "decimal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decimal.png",
  },
  {
    name: "Moonbet",
    symbol: "MBET",
    slug: "moonbet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonbet.png",
  },
  {
    name: "GLYPH Vault (NFTX)",
    symbol: "GLYPH",
    slug: "glyph-vault-nftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glyph-vault-nftx.png",
  },
  {
    name: "NFTY Network",
    symbol: "NFTY",
    slug: "nfty-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nfty-network.png",
  },
  {
    name: "CrossSwap",
    symbol: "CSWAP",
    slug: "crossswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crossswap.png",
  },
  {
    name: "Wildfire Token",
    symbol: "WILDF",
    slug: "wildfire-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wildfire-token.png",
  },
  {
    name: "CoinMeet",
    symbol: "MEET",
    slug: "coinmeet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinmeet.png",
  },
  {
    name: "sYSL",
    symbol: "SYSL",
    slug: "ysl-sysl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ysl-sysl.png",
  },
  {
    name: "Kaizen Finance",
    symbol: "KZEN",
    slug: "kaizen-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaizen-finance.png",
  },
  {
    name: "ETH 2x Flexible Leverage Index (Polygon)",
    symbol: "ETH2X-FLI-P",
    slug: "eth-2x-flexible-leverage-index-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-2x-flexible-leverage-index-polygon.png",
  },
  {
    name: "Light Defi",
    symbol: "LIGHT",
    slug: "light-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/light-defi.png",
  },
  {
    name: "Omni Real Estate Token",
    symbol: "ORT",
    slug: "omni-real-estate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omni-real-estate-token.png",
  },
  {
    name: "Antique Zombie Shards",
    symbol: "ZOMB",
    slug: "antique-zombie-shards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antique-zombie-shards.png",
  },
  {
    name: "ShibaRocket",
    symbol: "SHIBAROCKET",
    slug: "shibarocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibarocket.png",
  },
  {
    name: "BNFTX Token",
    symbol: "BNFTT",
    slug: "bnftx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnftx-token.png",
  },
  {
    name: "Memenopoly",
    symbol: "MNOP",
    slug: "memenopoly-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memenopoly-money.png",
  },
  {
    name: "Fox Finance",
    symbol: "FOX",
    slug: "fox-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fox-finance.png",
  },
  {
    name: "Swift Finance",
    symbol: "SWIFT",
    slug: "swift-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swift-finance.png",
  },
  {
    name: "MINDOL",
    symbol: "MIN",
    slug: "mindol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mindol.png",
  },
  {
    name: "UltraChad",
    symbol: "UCHAD",
    slug: "ultrachad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultrachad.png",
  },
  {
    name: "Dione Protocol",
    symbol: "DIONE",
    slug: "dione-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dione-protocol.png",
  },
  {
    name: "Zambesigold",
    symbol: "ZGD",
    slug: "zambesigold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zambesigold.png",
  },
  {
    name: "Pomeranian ETH",
    symbol: "POM",
    slug: "pomeranian-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pomeranian-eth.png",
  },
  {
    name: "Unicly",
    symbol: "UNIC",
    slug: "unicly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicly.png",
  },
  {
    name: "BudaCoin",
    symbol: "BUDA",
    slug: "budacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/budacoin.png",
  },
  {
    name: "FrenChain",
    symbol: "FREN",
    slug: "frenchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frenchain.png",
  },
  {
    name: "Loon Network",
    symbol: "LOON",
    slug: "loon-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loon-network.png",
  },
  {
    name: "Megatech",
    symbol: "MGT",
    slug: "megatech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megatech.png",
  },
  {
    name: "Community Doge Coin",
    symbol: "CCDOGE",
    slug: "community-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/community-doge-coin.png",
  },
  {
    name: "FlavorsBSC",
    symbol: "FLVR",
    slug: "flavorsbsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flavorsbsc.png",
  },
  {
    name: "Green Chart",
    symbol: "GREEN",
    slug: "green-chart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-chart.png",
  },
  {
    name: "World Stream Finance",
    symbol: "$TREAM",
    slug: "world-stream-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-stream-finance.png",
  },
  {
    name: "WasabiX",
    symbol: "WASABI",
    slug: "wasabix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wasabix.png",
  },
  {
    name: "Dexsport",
    symbol: "DESU",
    slug: "dexsport",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexsport.png",
  },
  {
    name: "LinkPool",
    symbol: "LPL",
    slug: "linkpool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkpool.png",
  },
  {
    name: "Universe Coin",
    symbol: "UNIS",
    slug: "universe-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universe-coin.png",
  },
  {
    name: "STA",
    symbol: "STA",
    slug: "sta-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sta-token.png",
  },
  {
    name: "KeKChain",
    symbol: "KEK",
    slug: "kekchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kekchain.png",
  },
  {
    name: "3X Short Sushi Token",
    symbol: "SUSHIBEAR",
    slug: "3x-short-sushi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-sushi-token.png",
  },
  {
    name: "Xolo Inu",
    symbol: "XL",
    slug: "xolo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xolo-inu.png",
  },
  {
    name: "Alcazar",
    symbol: "ALCAZAR",
    slug: "alcazar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alcazar.png",
  },
  {
    name: "Metaverse Doge",
    symbol: "$MVDOGE",
    slug: "metaverse-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-doge.png",
  },
  {
    name: "BABYFEG",
    symbol: "BBFEG",
    slug: "babyfeg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyfeg.png",
  },
  {
    name: "CheeseSwap",
    symbol: "CHS",
    slug: "cheeseswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheeseswap.png",
  },
  {
    name: "BITCOIN INTERNATIONAL",
    symbol: "BTCI",
    slug: "bitcoin-international",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-international.png",
  },
  {
    name: "Shibanomi",
    symbol: "SHIO",
    slug: "shibanomi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibanomi.png",
  },
  {
    name: "DACC",
    symbol: "DACC",
    slug: "dacc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dacc.png",
  },
  {
    name: "Alpha Nodes",
    symbol: "$ALPHA",
    slug: "alpha-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-nodes.png",
  },
  {
    name: "Baby Poocoin",
    symbol: "BABYPOO",
    slug: "baby-poocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-poocoin.png",
  },
  {
    name: "BNbitcoin",
    symbol: "BNBTC",
    slug: "bnbitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbitcoin.png",
  },
  {
    name: "Utility NFT Coin",
    symbol: "UNC",
    slug: "utility-nft-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utility-nft-coin.png",
  },
  {
    name: "GameFi Collection",
    symbol: "GFC",
    slug: "gamefi-collection",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamefi-collection.png",
  },
  {
    name: "vBSWAP",
    symbol: "VBSWAP",
    slug: "vbswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vbswap.png",
  },
  {
    name: "BabyDogeZilla",
    symbol: "BABYDOGEZILLA",
    slug: "babydogezilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babydogezilla.png",
  },
  {
    name: "Cream ETH 2",
    symbol: "CRETH2",
    slug: "cream-eth2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cream-eth2.png",
  },
  {
    name: "AstroGrow",
    symbol: "ATG",
    slug: "astrogrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astrogrow.png",
  },
  {
    name: "Unus Dao",
    symbol: "UDO",
    slug: "unus-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unus-dao.png",
  },
  {
    name: "Pinknode",
    symbol: "PNODE",
    slug: "pinknode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pinknode.png",
  },
  {
    name: "Derived",
    symbol: "DVDX",
    slug: "derived",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/derived.png",
  },
  {
    name: "BasketDAO",
    symbol: "BASK",
    slug: "basketdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basketdao.png",
  },
  {
    name: "Posschain",
    symbol: "POSS",
    slug: "posschain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/posschain.png",
  },
  {
    name: "Inescoin",
    symbol: "INES",
    slug: "inescoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inescoin.png",
  },
  {
    name: "MCFinance",
    symbol: "MCF",
    slug: "mcfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcfinance.png",
  },
  {
    name: "No one",
    symbol: "NOONE",
    slug: "no-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/no-one.png",
  },
  {
    name: "MoonStar",
    symbol: "MOONSTAR",
    slug: "moonstar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonstar.png",
  },
  {
    name: "Mojito Markets",
    symbol: "MOJO",
    slug: "mojito-markets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mojito-markets.png",
  },
  {
    name: "AxieZilla",
    symbol: "AXZ",
    slug: "axiezilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axiezilla.png",
  },
  {
    name: "EGOH Finance",
    symbol: "EGOH",
    slug: "egoh-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egoh-finance.png",
  },
  {
    name: "Arenum",
    symbol: "ARN",
    slug: "arenum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arenum.png",
  },
  {
    name: "MetFi",
    symbol: "MFI",
    slug: "metfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metfi.png",
  },
  {
    name: "JD Coin",
    symbol: "JDC",
    slug: "jd-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jd-coin.png",
  },
  {
    name: "Bimp.Finance",
    symbol: "BIMP",
    slug: "bimp-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bimp-finance.png",
  },
  {
    name: "Virtual Tourist",
    symbol: "VT",
    slug: "virtual-tourist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/virtual-tourist.png",
  },
  {
    name: "UNIFI DeFi",
    symbol: "UNIFI",
    slug: "unifi-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unifi-defi.png",
  },
  {
    name: "Umi Digital",
    symbol: "UMI",
    slug: "umi-digital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/umi-digital.png",
  },
  {
    name: "MetaWear",
    symbol: "WEAR",
    slug: "metawear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metawear.png",
  },
  {
    name: "Nyantereum International",
    symbol: "NYANTE",
    slug: "nyantereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nyantereum.png",
  },
  {
    name: "Equilibrium",
    symbol: "EQ",
    slug: "eq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eq.png",
  },
  {
    name: "Asia Coin",
    symbol: "ASIA",
    slug: "asia-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asia-coin.png",
  },
  {
    name: "Boxer Inu",
    symbol: "BOXER",
    slug: "boxer-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boxer-inu.png",
  },
  {
    name: "Artmeta",
    symbol: "MART",
    slug: "artmeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artmeta.png",
  },
  {
    name: "Humanize",
    symbol: "$HMT",
    slug: "humanize",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/humanize.png",
  },
  {
    name: "Behodler",
    symbol: "EYE",
    slug: "beholder",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beholder.png",
  },
  {
    name: "SideShift Token",
    symbol: "XAI",
    slug: "sideshift-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sideshift-token.png",
  },
  {
    name: "Metawar",
    symbol: "METAWAR",
    slug: "metawar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metawar.png",
  },
  {
    name: "Inflex Finance",
    symbol: "INFLEX",
    slug: "inflex-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inflex-finance.png",
  },
  {
    name: "CCO Metaverse",
    symbol: "CCOM",
    slug: "cco-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cco-metaverse.png",
  },
  {
    name: "ShineChain",
    symbol: "SHE",
    slug: "shinechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinechain.png",
  },
  {
    name: "OUSE Token",
    symbol: "OUSE",
    slug: "ouse-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ouse-token.png",
  },
  {
    name: "RETRO DEFI - RCUBE",
    symbol: "RCUBE",
    slug: "retro-defi-rcube",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/retro-defi-rcube.png",
  },
  {
    name: "Cycle Finance",
    symbol: "CYCLE",
    slug: "cycle-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cycle-finance.png",
  },
  {
    name: "Banana Task Force Ape",
    symbol: "BTFA",
    slug: "banana-task-force-ape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banana-task-force-ape.png",
  },
  {
    name: "Plutos Network",
    symbol: "PLUT",
    slug: "plutos-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plutos-network.png",
  },
  {
    name: "Icosa",
    symbol: "ICSA",
    slug: "icosa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icosa.png",
  },
  {
    name: "Lox Network",
    symbol: "LOX",
    slug: "lox-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lox-network.png",
  },
  {
    name: "Cpos Cloud Payment",
    symbol: "CPOS",
    slug: "cpos-cloud-payment",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cpos-cloud-payment.png",
  },
  {
    name: "TokenAsset",
    symbol: "NTB",
    slug: "tokenasset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenasset.png",
  },
  {
    name: "Horny Doge",
    symbol: "HORNY",
    slug: "horny-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/horny-doge.png",
  },
  {
    name: "Adamant",
    symbol: "ADMC",
    slug: "adamant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adamant.png",
  },
  {
    name: "Rocky Inu",
    symbol: "ROCKY",
    slug: "rocky-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocky-inu.png",
  },
  {
    name: "MDUKEY",
    symbol: "MDU",
    slug: "mdu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mdu.png",
  },
  {
    name: "Aonea Coin",
    symbol: "A1A",
    slug: "aonea-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aonea-coin.png",
  },
  {
    name: "Transaction Service fee",
    symbol: "TSF",
    slug: "transaction-service-fee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/transaction-service-fee.png",
  },
  {
    name: "Cosplay Token",
    symbol: "COT",
    slug: "cosplay-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cosplay-token.png",
  },
  {
    name: "LimitSwap",
    symbol: "LIMIT",
    slug: "limitswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/limitswap.png",
  },
  {
    name: "Club Donkey",
    symbol: "CDONK",
    slug: "club-donkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/club-donkey.png",
  },
  {
    name: "Stratos",
    symbol: "STOS",
    slug: "stratos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stratos.png",
  },
  {
    name: "InsuranceFi",
    symbol: "IF",
    slug: "insurancefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insurancefi.png",
  },
  {
    name: "Metaxiz",
    symbol: "MEXI",
    slug: "metaxiz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaxiz.png",
  },
  {
    name: "CyberFM",
    symbol: "CYFM",
    slug: "cyberfm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyberfm.png",
  },
  {
    name: "MetaPlayers.gg",
    symbol: "FPS",
    slug: "metaplayers-gg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaplayers-gg.png",
  },
  {
    name: "The Realm Defenders",
    symbol: "TRD",
    slug: "the-realm-defenders",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-realm-defenders.png",
  },
  {
    name: "Symbull",
    symbol: "SYMBULL",
    slug: "symbull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/symbull.png",
  },
  {
    name: "MOBLAND",
    symbol: "SYNR",
    slug: "syn-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/syn-city.png",
  },
  {
    name: "Scarab Finance",
    symbol: "SCARAB",
    slug: "scarab-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scarab-finance.png",
  },
  {
    name: "PizzaSwap",
    symbol: "PIZZA",
    slug: "pizzaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pizzaswap.png",
  },
  {
    name: "pSTAKE Staked BNB",
    symbol: "STKBNB",
    slug: "stkbnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stkbnb.png",
  },
  {
    name: "Fren",
    symbol: "FREN",
    slug: "fren-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fren-token.png",
  },
  {
    name: "The Famous Token",
    symbol: "TFT",
    slug: "the-famous-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-famous-token.png",
  },
  {
    name: "Nippon Lagoon",
    symbol: "NLC",
    slug: "nippon-lagoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nippon-lagoon.png",
  },
  {
    name: "NFracTion",
    symbol: "NFTA",
    slug: "nfraction",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nfraction.png",
  },
  {
    name: "BRN Metaverse",
    symbol: "BRN",
    slug: "brn-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brn-metaverse.png",
  },
  {
    name: "SUBX FINANCE LAB",
    symbol: "SFX",
    slug: "subx-finance-lab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/subx-finance-lab.png",
  },
  {
    name: "DeHub",
    symbol: "DEHUB",
    slug: "dehub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dehub.png",
  },
  {
    name: "BerrySwap",
    symbol: "BERRY",
    slug: "berryswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/berryswap.png",
  },
  {
    name: "Nota",
    symbol: "USNOTA",
    slug: "nota",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nota.png",
  },
  {
    name: "Kesef Finance",
    symbol: "KSF",
    slug: "kesef-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kesef-finance.png",
  },
  {
    name: "Goal Champion",
    symbol: "GC",
    slug: "goal-champion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goal-champion.png",
  },
  {
    name: "ALTPAY FINANCE",
    symbol: "ALTPAY",
    slug: "altpay-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altpay-finance.png",
  },
  {
    name: "Yetucoin",
    symbol: "YETU",
    slug: "yetucoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yetucoin.png",
  },
  {
    name: "Savanna",
    symbol: "SVN",
    slug: "savanna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/savanna.png",
  },
  {
    name: "ElonFlokiInu",
    symbol: "EFloki",
    slug: "elonflokiinu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elonflokiinu-token.png",
  },
  {
    name: "WHITEX",
    symbol: "WHX",
    slug: "whitex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whitex.png",
  },
  {
    name: "Ape In",
    symbol: "APEIN",
    slug: "ape-in",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ape-in.png",
  },
  {
    name: "DogeDrinks",
    symbol: "DOGEDRINKS",
    slug: "dogedrinks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogedrinks.png",
  },
  {
    name: "FitR",
    symbol: "FMT",
    slug: "fitr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fitr.png",
  },
  {
    name: "Doogee.io",
    symbol: "DOOGEE",
    slug: "doogee-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doogee-io.png",
  },
  {
    name: "Pige Inu",
    symbol: "PINU",
    slug: "pige-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pige-inu.png",
  },
  {
    name: "Kaiken Inu",
    symbol: "KAIKEN",
    slug: "kaiken-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaiken-inu.png",
  },
  {
    name: "HAKURAI",
    symbol: "RAI",
    slug: "hakurai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hakurai.png",
  },
  {
    name: "ONSTON",
    symbol: "ONSTON",
    slug: "onston",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onston.png",
  },
  {
    name: "Dbook Platform",
    symbol: "DBK",
    slug: "dbook-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dbook-platform.png",
  },
  {
    name: "Amnext",
    symbol: "AMC",
    slug: "amnext",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amnext.png",
  },
  {
    name: "Kingdom Karnage",
    symbol: "KKT",
    slug: "kingdom-karnage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdom-karnage.png",
  },
  {
    name: "MaticLaunch",
    symbol: "MTCL",
    slug: "maticlaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maticlaunch.png",
  },
  {
    name: "KYCCOIN",
    symbol: "KYCC",
    slug: "kyccoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kyccoin.png",
  },
  {
    name: "AutoMaticUp",
    symbol: "ATMUP",
    slug: "automaticup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/automaticup.png",
  },
  {
    name: "AfterBack",
    symbol: "AFTRBCK",
    slug: "afterback",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/afterback.png",
  },
  {
    name: "Meta Spatial",
    symbol: "SPAT",
    slug: "meta-spatial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-spatial.png",
  },
  {
    name: "Scan DeFi",
    symbol: "SCAN",
    slug: "scan-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scan-defi.png",
  },
  {
    name: "DeFi Factory Token",
    symbol: "DEFT",
    slug: "defi-factory-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-factory-token.png",
  },
  {
    name: "Alpha Shiba Inu",
    symbol: "ALPHASHIB",
    slug: "alpha-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-shiba-inu.png",
  },
  {
    name: "Ether Matrix",
    symbol: "ETHMATRIX",
    slug: "ether-matrix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ether-matrix.png",
  },
  {
    name: "Maximus DECI",
    symbol: "DECI",
    slug: "maximus-deci",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maximus-deci.png",
  },
  {
    name: "PizzaBucks",
    symbol: "PIZZAB",
    slug: "pizzabucks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pizzabucks.png",
  },
  {
    name: "Quantic",
    symbol: "QUANTIC",
    slug: "quantic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quantic.png",
  },
  {
    name: "Butterfly Protocol",
    symbol: "BFLY",
    slug: "butterfly-protocol-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/butterfly-protocol-2.png",
  },
  {
    name: "BullBankers",
    symbol: "BANKERS",
    slug: "bullbankers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullbankers.png",
  },
  {
    name: "BreederDAO",
    symbol: "BREED",
    slug: "breederdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/breederdao.png",
  },
  {
    name: "Boryoku Dragonz",
    symbol: "BOKU",
    slug: "boku",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boku.png",
  },
  {
    name: "Baby Alpaca",
    symbol: "BALPAC",
    slug: "baby-alpaca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-alpaca.png",
  },
  {
    name: "CatBoy",
    symbol: "CTB",
    slug: "catboy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catboy.png",
  },
  {
    name: "Fortuneum",
    symbol: "FORTUNE",
    slug: "fortuneum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fortuneum.png",
  },
  {
    name: "Tie Dai",
    symbol: "TIEDAI",
    slug: "tie-dai-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tie-dai-token.png",
  },
  {
    name: "Unicap.finance",
    symbol: "UCAP",
    slug: "unicap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicap-finance.png",
  },
  {
    name: "LeisurePay",
    symbol: "LPY",
    slug: "leisurepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leisurepay.png",
  },
  {
    name: "Chedda Token",
    symbol: "CHEDDA",
    slug: "chedda-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chedda-token.png",
  },
  {
    name: "SatoExchange Token",
    symbol: "SATX",
    slug: "satoexchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoexchange-token.png",
  },
  {
    name: "Oxbull Solana",
    symbol: "OXS",
    slug: "oxbull-solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oxbull-solana.png",
  },
  {
    name: "HiFi Gaming Society",
    symbol: "HIFI",
    slug: "hifi-gaming-society",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hifi-gaming-society.png",
  },
  {
    name: "Pittys",
    symbol: "PITTYS",
    slug: "pittys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pittys.png",
  },
  {
    name: "Rowan Token",
    symbol: "RWN",
    slug: "rowan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rowan-token.png",
  },
  {
    name: "LuckDao",
    symbol: "LUCK",
    slug: "luckdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luckdao.png",
  },
  {
    name: "Voxel X Network",
    symbol: "VXL",
    slug: "voxel-x-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voxel-x-network.png",
  },
  {
    name: "Akamaru Inu",
    symbol: "AKAMARU",
    slug: "akamaru-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akamaru-inu.png",
  },
  {
    name: "DAIN",
    symbol: "DAIN",
    slug: "dain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dain.png",
  },
  {
    name: "xcDOT",
    symbol: "XCDOT",
    slug: "xcdot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xcdot.png",
  },
  {
    name: "xToken",
    symbol: "XTK",
    slug: "xtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtoken.png",
  },
  {
    name: "Global Innovative Solutions",
    symbol: "GSI",
    slug: "global-innovative-solutions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-innovative-solutions.png",
  },
  {
    name: "Turtle Racing",
    symbol: "TURT",
    slug: "turtle-racing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turtle-racing.png",
  },
  {
    name: "Bakery Tools",
    symbol: "TBAKE",
    slug: "bakery-tools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bakery-tools.png",
  },
  {
    name: "XETA",
    symbol: "XETA",
    slug: "xeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xeta.png",
  },
  {
    name: "AtPay",
    symbol: "ATPAY",
    slug: "atpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atpay.png",
  },
  {
    name: "ShibRWD",
    symbol: "SRWD",
    slug: "shibrwd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibrwd.png",
  },
  {
    name: "Big Mouth Monster",
    symbol: "BMM",
    slug: "big-mouth-monster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-mouth-monster.png",
  },
  {
    name: "Wrapped Arweave",
    symbol: "WAR",
    slug: "wrapped-arweave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-arweave.png",
  },
  {
    name: "Para",
    symbol: "PARA",
    slug: "parainu-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parainu-v2.png",
  },
  {
    name: "SafeBull",
    symbol: "SAFEBULL",
    slug: "safebull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safebull.png",
  },
  {
    name: "ClassicDoge",
    symbol: "XDOGE",
    slug: "classicdoge-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/classicdoge-io.png",
  },
  {
    name: "BurnDoge",
    symbol: "BURNDOGE",
    slug: "burndoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burndoge.png",
  },
  {
    name: "EthicHub",
    symbol: "ETHIX",
    slug: "ethichub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethichub.png",
  },
  {
    name: "Ejection Moon",
    symbol: "ETJ",
    slug: "ejection-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ejection-moon.png",
  },
  {
    name: "Redacted",
    symbol: "BTRFLY",
    slug: "redacted",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redacted.png",
  },
  {
    name: "Shrek",
    symbol: "SHK",
    slug: "shrek",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shrek.png",
  },
  {
    name: "junca Cash",
    symbol: "JCC",
    slug: "junca-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/junca-cash.png",
  },
  {
    name: "EverETH",
    symbol: "EVERETH",
    slug: "evereth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evereth.png",
  },
  {
    name: "ArkWorld",
    symbol: "AKW",
    slug: "arkworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arkworld.png",
  },
  {
    name: "Frosty Floki V2",
    symbol: "FROSTYFLOKI",
    slug: "frosty-floki-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frosty-floki-v2.png",
  },
  {
    name: "10x.gg",
    symbol: "XGG",
    slug: "10xgg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/10xgg.png",
  },
  {
    name: "Gold Sonic",
    symbol: "GSONIC",
    slug: "gold-sonic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-sonic.png",
  },
  {
    name: "Midas Dollar",
    symbol: "MDO",
    slug: "midas-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/midas-dollar.png",
  },
  {
    name: "Zionomics",
    symbol: "ZIOX",
    slug: "zionomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zionomics.png",
  },
  {
    name: "ADACash",
    symbol: "ADACASH",
    slug: "adacash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adacash.png",
  },
  {
    name: "Shiba X",
    symbol: "SHIBAX",
    slug: "shiba-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-x.png",
  },
  {
    name: "VROOMGO",
    symbol: "VRGX",
    slug: "vroomgo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vroomgo.png",
  },
  {
    name: "BSC Gold",
    symbol: "BSCGOLD",
    slug: "bsc-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsc-gold.png",
  },
  {
    name: "Sentiment Token",
    symbol: "SENT",
    slug: "sentiment-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sentiment-token.png",
  },
  {
    name: "MetaBET",
    symbol: "MBET",
    slug: "metabet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabet.png",
  },
  {
    name: "DEI",
    symbol: "DEI",
    slug: "dei",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dei.png",
  },
  {
    name: "Bispex",
    symbol: "BPX",
    slug: "bispex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bispex.png",
  },
  {
    name: "ShibChain",
    symbol: "SC",
    slug: "shibchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibchain.png",
  },
  {
    name: "Balkari",
    symbol: "BKR",
    slug: "balkari",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balkari.png",
  },
  {
    name: "Raptor Token",
    symbol: "RAPTR",
    slug: "raptor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raptor-token.png",
  },
  {
    name: "Fuji",
    symbol: "FJT",
    slug: "fuji",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fuji.png",
  },
  {
    name: "BinaHero",
    symbol: "HERO",
    slug: "binahero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binahero.png",
  },
  {
    name: "Cryptofifa",
    symbol: "FFA",
    slug: "cryptofifa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptofifa.png",
  },
  {
    name: "REDi",
    symbol: "REDI",
    slug: "redi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redi.png",
  },
  {
    name: "Local Traders",
    symbol: "LCT",
    slug: "local-traders",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/local-traders.png",
  },
  {
    name: "Theoscoin",
    symbol: "THS",
    slug: "theoscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/theoscoin.png",
  },
  {
    name: "Affyn",
    symbol: "FYN",
    slug: "affyn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/affyn.png",
  },
  {
    name: "Interlay",
    symbol: "INTR",
    slug: "interlay-intr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/interlay-intr.png",
  },
  {
    name: "iShares 20+ Year Treasury Bond ETF Defichain",
    symbol: "DTLT",
    slug: "treasury-bond-eth-tokenized-stock-defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treasury-bond-eth-tokenized-stock-defichain.png",
  },
  {
    name: "Inu Wars",
    symbol: "IWR",
    slug: "inu-wars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inu-wars.png",
  },
  {
    name: "HakunaMatata (new)",
    symbol: "TATA",
    slug: "hakunamatata-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hakunamatata-new.png",
  },
  {
    name: "Summit Koda Token",
    symbol: "KODA",
    slug: "summit-koda-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/summit-koda-token.png",
  },
  {
    name: "Leon Token",
    symbol: "LEON",
    slug: "leon-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leon-token.png",
  },
  {
    name: "Wine Shares",
    symbol: "WINE",
    slug: "wine-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wine-shares.png",
  },
  {
    name: "LinkToken",
    symbol: "LTK",
    slug: "linktoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linktoken.png",
  },
  {
    name: "SpongeBob Square",
    symbol: "SPONGS",
    slug: "spongebob-square",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spongebob-square.png",
  },
  {
    name: "JustYours",
    symbol: "JUST",
    slug: "justyours",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/justyours.png",
  },
  {
    name: "Jarvis Synthetic Euro",
    symbol: "JEUR",
    slug: "jarvis-synthetic-euro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jarvis-synthetic-euro.png",
  },
  {
    name: "POLKADOGE",
    symbol: "PDOGE",
    slug: "polkadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkadoge.png",
  },
  {
    name: "KiLLZiLL",
    symbol: "KZ",
    slug: "killzill",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/killzill.png",
  },
  {
    name: "Zytara dollar",
    symbol: "ZUSD",
    slug: "zytara-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zytara-dollar.png",
  },
  {
    name: "BabyETH V2",
    symbol: "BabyETHV2",
    slug: "babyeth-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyeth-v2.png",
  },
  {
    name: "BerylBit",
    symbol: "BRB",
    slug: "berylbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/berylbit.png",
  },
  {
    name: "Freedom",
    symbol: "FDM",
    slug: "freedom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freedom.png",
  },
  {
    name: "Shibaverse",
    symbol: "VERSE",
    slug: "shibaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibaverse.png",
  },
  {
    name: "CryptoDogs",
    symbol: "CRD",
    slug: "cryptodogs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptodogs.png",
  },
  {
    name: "Rare",
    symbol: "RARE",
    slug: "rare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rare.png",
  },
  {
    name: "Chow Chow",
    symbol: "CHOW",
    slug: "chow-chow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chow-chow.png",
  },
  {
    name: "Yeti Coin",
    symbol: "YETI COIN",
    slug: "yeti-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yeti-coin.png",
  },
  {
    name: "PlatinX",
    symbol: "PTX",
    slug: "platinx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/platinx.png",
  },
  {
    name: "FlatSwap",
    symbol: "FSWAP",
    slug: "flatswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flatswap.png",
  },
  {
    name: "Verlux",
    symbol: "VLX",
    slug: "verlux",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verlux.png",
  },
  {
    name: "H-Space Metaverse",
    symbol: "HKSM",
    slug: "h-space-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/h-space-metaverse.png",
  },
  {
    name: "Micro Gaming Protocol",
    symbol: "MGP",
    slug: "micro-gaming-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/micro-gaming-protocol.png",
  },
  {
    name: "Luxy",
    symbol: "LUXY",
    slug: "luxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luxy.png",
  },
  {
    name: "Rici Elon",
    symbol: "RICI",
    slug: "rici-elon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rici-elon.png",
  },
  {
    name: "1TRONIC Network",
    symbol: "1TRC",
    slug: "1tronic-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1tronic-network.png",
  },
  {
    name: "Exotix",
    symbol: "EXOTIX",
    slug: "exotix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exotix.png",
  },
  {
    name: "Quid Ika",
    symbol: "QUID",
    slug: "quid-ika",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quid-ika.png",
  },
  {
    name: "Nezuko Inu",
    symbol: "NEZUKO",
    slug: "nezuko-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nezuko-inu.png",
  },
  {
    name: "PLAYA3ULL GAMES",
    symbol: "3ULL",
    slug: "playa3ull-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playa3ull-games.png",
  },
  {
    name: "EFUN",
    symbol: "EFUN",
    slug: "e-fun-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/e-fun-token.png",
  },
  {
    name: "Reimagined Finance",
    symbol: "REFI",
    slug: "refi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/refi.png",
  },
  {
    name: "BscArmy",
    symbol: "BARMY",
    slug: "bscarmy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bscarmy.png",
  },
  {
    name: "Inusanity",
    symbol: "INUSANITY",
    slug: "inusanity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inusanity.png",
  },
  {
    name: "MetalSwap",
    symbol: "XMT",
    slug: "metalswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metalswap.png",
  },
  {
    name: "Quiz Arena",
    symbol: "QZA",
    slug: "quiz-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quiz-arena.png",
  },
  {
    name: "TacoCat Token",
    symbol: "TCT",
    slug: "tacocat-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tacocat-token.png",
  },
  {
    name: "Black Whale",
    symbol: "BLK",
    slug: "black-whale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-whale.png",
  },
  {
    name: "Joys Digital",
    symbol: "JOYS",
    slug: "joys-digital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joys-digital.png",
  },
  {
    name: "Crime Cash Game",
    symbol: "CRIME",
    slug: "crime-cash-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crime-cash-game.png",
  },
  {
    name: "GenshinFlokiInu",
    symbol: "GFloki",
    slug: "genshinflokiinu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genshinflokiinu-token.png",
  },
  {
    name: "Bitcoin Banana",
    symbol: "BIBA",
    slug: "bitcoin-banana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-banana.png",
  },
  {
    name: "Sasuke Inu",
    symbol: "SINU",
    slug: "sasuke-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sasuke-inu.png",
  },
  {
    name: "Support Doge",
    symbol: "SUPD",
    slug: "support-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/support-doge.png",
  },
  {
    name: "Jubi Token",
    symbol: "JT",
    slug: "jubi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jubi-token.png",
  },
  {
    name: "MetaMars",
    symbol: "METAM",
    slug: "metamars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamars.png",
  },
  {
    name: "The Plant Dao",
    symbol: "SPROUT",
    slug: "the-plant-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-plant-dao.png",
  },
  {
    name: "Egoras (New)",
    symbol: "EGR",
    slug: "egoras-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egoras-new.png",
  },
  {
    name: "DarkShield Games Studio",
    symbol: "DKS",
    slug: "darkshield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darkshield.png",
  },
  {
    name: "TCGcoin",
    symbol: "TCGCOIN",
    slug: "tcgcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tcgcoin.png",
  },
  {
    name: "DAO Invest",
    symbol: "VEST",
    slug: "dao-invest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dao-invest.png",
  },
  {
    name: "Aquaris",
    symbol: "AQS",
    slug: "aquaris",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aquaris.png",
  },
  {
    name: "TetherBlack",
    symbol: "TTB",
    slug: "tetherblack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tetherblack.png",
  },
  {
    name: "Serum Ecosystem Token",
    symbol: "SECO",
    slug: "serum-ecosystem-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/serum-ecosystem-token.png",
  },
  {
    name: "Drip Network",
    symbol: "DRIP",
    slug: "drip-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drip-network.png",
  },
  {
    name: "TigerInu2022",
    symbol: "TIGERINU2022",
    slug: "tigerinu2022",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tigerinu2022.png",
  },
  {
    name: "Baanx",
    symbol: "BXX",
    slug: "baanx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baanx.png",
  },
  {
    name: "CAGE",
    symbol: "C4G3",
    slug: "cage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cage.png",
  },
  {
    name: "Miss Ape Yacht Club",
    symbol: "APE",
    slug: "miss-ape-yacht-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miss-ape-yacht-club.png",
  },
  {
    name: "TrGold",
    symbol: "TRGO",
    slug: "trgold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trgold.png",
  },
  {
    name: "DIAMOND HANDS",
    symbol: "DIAMONDHANDS",
    slug: "diamond-hands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-hands.png",
  },
  {
    name: "Punk Panda Messenger",
    symbol: "PPM",
    slug: "punk-panda-messenger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/punk-panda-messenger.png",
  },
  {
    name: "UPFI Network",
    symbol: "UPS",
    slug: "upfi-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upfi-network.png",
  },
  {
    name: "Philcoin",
    symbol: "PHL",
    slug: "philcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/philcoin.png",
  },
  {
    name: "Basis Dollar Share",
    symbol: "BSDS",
    slug: "basis-dollar-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basis-dollar-share.png",
  },
  {
    name: "GLOW V2",
    symbol: "GLOW V2",
    slug: "glow-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glow-v2.png",
  },
  {
    name: "Proof Of Memes",
    symbol: "POM",
    slug: "proof-of-memes-pomchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proof-of-memes-pomchain.png",
  },
  {
    name: "Finance.Vote",
    symbol: "FVT",
    slug: "finance-vote",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/finance-vote.png",
  },
  {
    name: "Shuna Inuverse",
    symbol: "SHUNAV",
    slug: "shuna-inuverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shuna-inuverse.png",
  },
  {
    name: "Terareum",
    symbol: "TERA",
    slug: "terareum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terareum.png",
  },
  {
    name: "STREETH",
    symbol: "STREETH",
    slug: "streeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/streeth.png",
  },
  {
    name: "Unity Protocol",
    symbol: "UNITY",
    slug: "unity-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unity-protocol.png",
  },
  {
    name: "Decentralized Nations",
    symbol: "DENA",
    slug: "decentralized-nations",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-nations.png",
  },
  {
    name: "Abachi",
    symbol: "ABI",
    slug: "abachi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abachi.png",
  },
  {
    name: "LE CALIENTE",
    symbol: "LECLIENTE",
    slug: "le-caliente",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/le-caliente.png",
  },
  {
    name: "Monster Slayer",
    symbol: "MS",
    slug: "monsterslayer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monsterslayer.png",
  },
  {
    name: "Excursion Token",
    symbol: "EXC",
    slug: "excursion-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/excursion-token.png",
  },
  {
    name: "Magic Birds Token",
    symbol: "MBT",
    slug: "magic-birds-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-birds-token.png",
  },
  {
    name: "CheersLand",
    symbol: "CHEERS",
    slug: "cheersland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheersland.png",
  },
  {
    name: "dexIRA",
    symbol: "DEX",
    slug: "dexira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexira.png",
  },
  {
    name: "War Bond Token",
    symbol: "WBOND",
    slug: "tank-wars-zone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tank-wars-zone.png",
  },
  {
    name: "Spirit Orb Pets Care Token",
    symbol: "CARE",
    slug: "spirit-orb-pets-care-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spirit-orb-pets-care-token.png",
  },
  {
    name: "ZodiacDAO",
    symbol: "ZD",
    slug: "zodiacdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zodiacdao.png",
  },
  {
    name: "EpochToken",
    symbol: "EPH",
    slug: "epochtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/epochtoken.png",
  },
  {
    name: "Pilot",
    symbol: "PTD",
    slug: "pilot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pilot.png",
  },
  {
    name: "HunnyDAO",
    symbol: "LOVE",
    slug: "hunnydao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hunnydao.png",
  },
  {
    name: "Gamer Arena",
    symbol: "GAU",
    slug: "gamer-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamer-arena.png",
  },
  {
    name: "Earncraft",
    symbol: "PLOT",
    slug: "earncraft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earncraft.png",
  },
  {
    name: "Pizza Game",
    symbol: "PIZZA",
    slug: "pizza-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pizza-game.png",
  },
  {
    name: "Liti Capital",
    symbol: "WLITI",
    slug: "liti-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liti-capital.png",
  },
  {
    name: "Totem New Earth Systems",
    symbol: "CTZN",
    slug: "totem-new-earth-systems",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/totem-new-earth-systems.png",
  },
  {
    name: "NEXTYPE",
    symbol: "NT",
    slug: "nextype",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nextype.png",
  },
  {
    name: "Baby Shiba Coin",
    symbol: "BABYSHIBA",
    slug: "baby-shiba-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-shiba-coin.png",
  },
  {
    name: "BitANT",
    symbol: "BITANT",
    slug: "bitant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitant.png",
  },
  {
    name: "Vinci",
    symbol: "VINCI",
    slug: "vinci",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vinci.png",
  },
  {
    name: "Solvent",
    symbol: "SVT",
    slug: "solvent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solvent.png",
  },
  {
    name: "Virgo",
    symbol: "VGO",
    slug: "virgo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/virgo.png",
  },
  {
    name: "United Farmers Finance",
    symbol: "UFF",
    slug: "united-farmers-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/united-farmers-finance.png",
  },
  {
    name: "Dragonrace",
    symbol: "DRAGACE",
    slug: "dragonrace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonrace.png",
  },
  {
    name: "USN",
    symbol: "USN",
    slug: "usn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usn.png",
  },
  {
    name: "DeCus.io",
    symbol: "SATS",
    slug: "decus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decus.png",
  },
  {
    name: "Evodefi",
    symbol: "GENX",
    slug: "evodefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evodefi.png",
  },
  {
    name: "Gain Protocol",
    symbol: "GAIN",
    slug: "gain-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gain-protocol.png",
  },
  {
    name: "RoyalADA",
    symbol: "ROYALADA",
    slug: "royalada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/royalada.png",
  },
  {
    name: "Tigerfinance",
    symbol: "TIGER",
    slug: "tigerfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tigerfinance.png",
  },
  {
    name: "Hodl4Gold",
    symbol: "H4G",
    slug: "hodl4gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodl4gold.png",
  },
  {
    name: "LynKey",
    symbol: "LYNK",
    slug: "lynkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lynkey.png",
  },
  {
    name: "ShibaPad",
    symbol: "SBP",
    slug: "shibapad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibapad.png",
  },
  {
    name: "Wakanda Inu",
    symbol: "WKD",
    slug: "wakanda-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wakanda-inu.png",
  },
  {
    name: "BitUP Token",
    symbol: "BUT",
    slug: "bitup-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitup-token.png",
  },
  {
    name: "Bully Inu",
    symbol: "BINU",
    slug: "bully-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bully-inu.png",
  },
  {
    name: "Harmonylauncher",
    symbol: "HARL",
    slug: "harmonylauncher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harmonylauncher.png",
  },
  {
    name: "Jax Network",
    symbol: "WJXN",
    slug: "jax-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jax-network.png",
  },
  {
    name: "Topshelf Finance",
    symbol: "LIQR",
    slug: "topshelf-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/topshelf-finance.png",
  },
  {
    name: "Knight War The Holy Trio",
    symbol: "MRE",
    slug: "knight-war-the-holy-trio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knight-war-the-holy-trio.png",
  },
  {
    name: "BotOcean",
    symbol: "BOTS",
    slug: "botocean",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/botocean.png",
  },
  {
    name: "Flourish Coin",
    symbol: "FLRS",
    slug: "flourish-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flourish-coin.png",
  },
  {
    name: "Retreeb",
    symbol: "TREEB",
    slug: "retreeb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/retreeb.png",
  },
  {
    name: "Seedling",
    symbol: "SDLN",
    slug: "seedling",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seedling.png",
  },
  {
    name: "AXIA Coin",
    symbol: "AXC",
    slug: "axia-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axia-coin.png",
  },
  {
    name: "ORO",
    symbol: "ORO",
    slug: "oro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oro.png",
  },
  {
    name: "Pesabase",
    symbol: "PESA",
    slug: "pesabase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pesabase.png",
  },
  {
    name: "GOKU INU",
    symbol: "GOKU",
    slug: "goku-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goku-inu.png",
  },
  {
    name: "dHealth",
    symbol: "DHP",
    slug: "dhealth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dhealth.png",
  },
  {
    name: "Engine",
    symbol: "EGCC",
    slug: "engine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/engine.png",
  },
  {
    name: "FarmersOnly",
    symbol: "CORN",
    slug: "farmersonly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmersonly.png",
  },
  {
    name: "Pacoca",
    symbol: "PACOCA",
    slug: "pacoca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pacoca.png",
  },
  {
    name: "JokerManor Metaverse",
    symbol: "JKT",
    slug: "jokermanor-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jokermanor-metaverse.png",
  },
  {
    name: "Mr.FOX TOKEN",
    symbol: "MRFOX",
    slug: "mr-fox-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mr-fox-token.png",
  },
  {
    name: "GOGOcoin",
    symbol: "GOGO",
    slug: "gogocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gogocoin.png",
  },
  {
    name: "Lucky Irish Inu",
    symbol: "LUCKYCLOVER",
    slug: "lucky-irish-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-irish-inu.png",
  },
  {
    name: "The Rare Antiquities Token",
    symbol: "RAT",
    slug: "the-rare-antiquities-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-rare-antiquities-token.png",
  },
  {
    name: "WAIC Care",
    symbol: "WAIV",
    slug: "waic-care",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waic-care.png",
  },
  {
    name: "Cybertronchain",
    symbol: "CTC",
    slug: "cybertronchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cybertronchain.png",
  },
  {
    name: "DAOLaunch",
    symbol: "DAL",
    slug: "daolaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daolaunch.png",
  },
  {
    name: "IVOGEL",
    symbol: "IVG",
    slug: "ivogel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ivogel.png",
  },
  {
    name: "PRYZ",
    symbol: "PRYZ",
    slug: "pryz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pryz.png",
  },
  {
    name: "Ourglass",
    symbol: "GLASS",
    slug: "ourglass",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ourglass.png",
  },
  {
    name: "Saber",
    symbol: "SBR",
    slug: "saber",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saber.png",
  },
  {
    name: "Faith Tribe",
    symbol: "$FTRB",
    slug: "faith-tribe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/faith-tribe.png",
  },
  {
    name: "LinkDao Network",
    symbol: "LKD",
    slug: "linkdao-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkdao-network.png",
  },
  {
    name: "Neoxa",
    symbol: "NEOX",
    slug: "neoxa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neoxa.png",
  },
  {
    name: "Friends With Benefits Pro",
    symbol: "FWB",
    slug: "friends-with-benefits-pro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/friends-with-benefits-pro.png",
  },
  {
    name: "Babylon Finance",
    symbol: "BABL",
    slug: "babylon-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babylon-finance.png",
  },
  {
    name: "QUINT",
    symbol: "QUINT",
    slug: "quint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quint.png",
  },
  {
    name: "FantomMoon",
    symbol: "FMF",
    slug: "fantommoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantommoon.png",
  },
  {
    name: "DUO Network Token",
    symbol: "DUO",
    slug: "duo-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duo-network-token.png",
  },
  {
    name: "Dexit Finance",
    symbol: "DXT",
    slug: "dexit-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexit-finance.png",
  },
  {
    name: "Klaydice",
    symbol: "DICE",
    slug: "klaydice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klaydice.png",
  },
  {
    name: "DR1VER",
    symbol: "DR1$",
    slug: "dr1ver",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dr1ver.png",
  },
  {
    name: "MyMetaX",
    symbol: "MMX",
    slug: "mymetax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mymetax.png",
  },
  {
    name: "Smart Trade Coin",
    symbol: "TRADE",
    slug: "smart-trade-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-trade-coin.png",
  },
  {
    name: "Booster BSC",
    symbol: "BOOST",
    slug: "booster-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/booster-bsc.png",
  },
  {
    name: "Eterland",
    symbol: "ETER",
    slug: "eterland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eterland.png",
  },
  {
    name: "3x Long Litecoin Token",
    symbol: "LTCBULL",
    slug: "3x-long-litecoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-litecoin-token.png",
  },
  {
    name: "Neopin",
    symbol: "NPT",
    slug: "neopin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neopin.png",
  },
  {
    name: "BUSDX",
    symbol: "BUSDX",
    slug: "busdx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/busdx.png",
  },
  {
    name: "ToolApe",
    symbol: "TAPE",
    slug: "toolape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toolape.png",
  },
  {
    name: "Meshswap Protocol",
    symbol: "MESH",
    slug: "meshswap-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meshswap-protocol.png",
  },
  {
    name: "GSPI Shopping.io Governance",
    symbol: "GSPI",
    slug: "gspi-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gspi-governance.png",
  },
  {
    name: "SwapX",
    symbol: "XWAP",
    slug: "swapx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapx.png",
  },
  {
    name: "Medamon",
    symbol: "MON",
    slug: "medamon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medamon.png",
  },
  {
    name: "SuperWalk",
    symbol: "GRND",
    slug: "superwalk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superwalk.png",
  },
  {
    name: "PinkMoon",
    symbol: "PINKM",
    slug: "pinkmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pinkmoon.png",
  },
  {
    name: "MangoMan Intelligent",
    symbol: "MMIT",
    slug: "mangoman-intelligent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mangoman-intelligent.png",
  },
  {
    name: "SafeBreast Inu",
    symbol: "BREAST",
    slug: "safebreast-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safebreast-inu.png",
  },
  {
    name: "TeddyDog",
    symbol: "TDG",
    slug: "teddydog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teddydog.png",
  },
  {
    name: "Palmswap",
    symbol: "PALM",
    slug: "palmswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/palmswap.png",
  },
  {
    name: "The Sun Rises",
    symbol: "SUNRISE",
    slug: "the-sun-rises",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-sun-rises.png",
  },
  {
    name: "bDollar",
    symbol: "BDO",
    slug: "bdollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bdollar.png",
  },
  {
    name: "BULL FINANCE",
    symbol: "BULL",
    slug: "bull-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bull-finance.png",
  },
  {
    name: "In Meta Travel",
    symbol: "IMT",
    slug: "in-meta-travel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/in-meta-travel.png",
  },
  {
    name: "Goldario",
    symbol: "GLD",
    slug: "goldario",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldario.png",
  },
  {
    name: "PinkPanda",
    symbol: "PINKPANDA",
    slug: "pinkpanda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pinkpanda.png",
  },
  {
    name: "RobiniaSwap Token",
    symbol: "RBS",
    slug: "robiniaswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robiniaswap-token.png",
  },
  {
    name: "BrightyPad",
    symbol: "BYP",
    slug: "brightypad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brightypad.png",
  },
  {
    name: "BabySwap",
    symbol: "BABY",
    slug: "babyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyswap.png",
  },
  {
    name: "Smashchain",
    symbol: "SAS",
    slug: "smashchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smashchain.png",
  },
  {
    name: "Zeedex",
    symbol: "ZDEX",
    slug: "zeedex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeedex.png",
  },
  {
    name: "Mammoth",
    symbol: "MMT",
    slug: "mammoth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mammoth.png",
  },
  {
    name: "xYSL",
    symbol: "XYSL",
    slug: "xysl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xysl.png",
  },
  {
    name: "Lucky Unicorn Token",
    symbol: "L99",
    slug: "lucky-unicorn-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-unicorn-token.png",
  },
  {
    name: "tudaBirds",
    symbol: "BURD",
    slug: "tudabirds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tudabirds.png",
  },
  {
    name: "YFFS Finance",
    symbol: "YFFS",
    slug: "yffs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yffs.png",
  },
  {
    name: "Minimals",
    symbol: "MMS",
    slug: "minimals",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minimals.png",
  },
  {
    name: "Kitty Cake",
    symbol: "KCAKE",
    slug: "kitty-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitty-cake.png",
  },
  {
    name: "CluCoin",
    symbol: "CLU",
    slug: "clucoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clucoin.png",
  },
  {
    name: "Power Cash",
    symbol: "PRCH",
    slug: "power-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/power-cash.png",
  },
  {
    name: "LovesSwap",
    symbol: "LOVES",
    slug: "lovesswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lovesswap.png",
  },
  {
    name: "BINGO",
    symbol: "BG",
    slug: "bingo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bingo.png",
  },
  {
    name: "Aurigami",
    symbol: "PLY",
    slug: "aurigami",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurigami.png",
  },
  {
    name: "Pluto",
    symbol: "PLUT",
    slug: "pluto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pluto.png",
  },
  {
    name: "Million Pixel",
    symbol: "XIX",
    slug: "million-pixel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/million-pixel.png",
  },
  {
    name: "APEmove",
    symbol: "BAPE",
    slug: "apemove-bape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apemove-bape.png",
  },
  {
    name: "Shibosu",
    symbol: "SHIBOSU",
    slug: "shibosu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibosu.png",
  },
  {
    name: "RamenSwap",
    symbol: "RAMEN",
    slug: "ramenswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ramenswap.png",
  },
  {
    name: "Zin Finance",
    symbol: "ZIN",
    slug: "zin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zin-finance.png",
  },
  {
    name: "Agrocash X",
    symbol: "XAGC",
    slug: "agrocash-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agrocash-x.png",
  },
  {
    name: "GovWorld",
    symbol: "GOV",
    slug: "govworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/govworld.png",
  },
  {
    name: "basis.markets",
    symbol: "BASIS",
    slug: "basis-markets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basis-markets.png",
  },
  {
    name: "QiSwap",
    symbol: "QI",
    slug: "qiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qiswap.png",
  },
  {
    name: "GOMA Finance",
    symbol: "GOMA",
    slug: "goma-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goma-finance.png",
  },
  {
    name: "DOGAMÍ",
    symbol: "DOGA",
    slug: "dogami",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogami.png",
  },
  {
    name: "MaskDoge",
    symbol: "MASKDOGE",
    slug: "maskdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maskdoge.png",
  },
  {
    name: "X-Block",
    symbol: "IX",
    slug: "x-block",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-block.png",
  },
  {
    name: "SHIB CAKE",
    symbol: "SHIBCAKE",
    slug: "shib-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shib-cake.png",
  },
  {
    name: "Wraith Protocol",
    symbol: "WRAITH",
    slug: "wraith-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wraith-protocol.png",
  },
  {
    name: "Egod The Savior",
    symbol: "$SAVIOR",
    slug: "egod-the-savior",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egod-the-savior.png",
  },
  {
    name: "Decentra-Lotto",
    symbol: "DELO",
    slug: "decentra-lotto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentra-lotto.png",
  },
  {
    name: "TSA NFT",
    symbol: "TSA",
    slug: "tsa-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tsa-nft.png",
  },
  {
    name: "Crosschain IOTX",
    symbol: "CIOTX",
    slug: "crosschain-iotx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crosschain-iotx.png",
  },
  {
    name: "FRMx Token",
    symbol: "FRMX",
    slug: "frmx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frmx-token.png",
  },
  {
    name: "Aurum",
    symbol: "AUR",
    slug: "aurum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurum.png",
  },
  {
    name: "The Sandlot",
    symbol: "HUSTLE",
    slug: "the-sandlot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-sandlot.png",
  },
  {
    name: "DragonSea",
    symbol: "DGE",
    slug: "dragonsea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonsea.png",
  },
  {
    name: "Nitro League",
    symbol: "NITRO",
    slug: "nitro-league",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nitro-league.png",
  },
  {
    name: "Thirm Protocol",
    symbol: "THIRM",
    slug: "thirm-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thirm-protocol.png",
  },
  {
    name: "CatNIP",
    symbol: "NIP",
    slug: "catnip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catnip.png",
  },
  {
    name: "Wrapped Rose",
    symbol: "wROSE",
    slug: "wrapped-rose",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-rose.png",
  },
  {
    name: "Space Crypto (SPE)",
    symbol: "SPE",
    slug: "space-crypto-spe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-crypto-spe.png",
  },
  {
    name: "Cake Monster",
    symbol: "MONSTA",
    slug: "cake-monster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cake-monster.png",
  },
  {
    name: "YAS",
    symbol: "YAS",
    slug: "yas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yas.png",
  },
  {
    name: "Reward Cycle 2",
    symbol: "RC2",
    slug: "reward-cycle-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reward-cycle-2.png",
  },
  {
    name: "BearHunt",
    symbol: "BHUNT",
    slug: "bearhunt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bearhunt.png",
  },
  {
    name: "SteveDoge",
    symbol: "WINIPHONE",
    slug: "stevedoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stevedoge.png",
  },
  {
    name: "BitShiba",
    symbol: "SHIBA",
    slug: "bitshiba-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitshiba-token.png",
  },
  {
    name: "Agrinoble",
    symbol: "AGN",
    slug: "agrinoble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agrinoble.png",
  },
  {
    name: "Tank Battle",
    symbol: "TBL",
    slug: "tank-battle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tank-battle.png",
  },
  {
    name: "Hey Bitcoin",
    symbol: "HYBN",
    slug: "hey-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hey-bitcoin.png",
  },
  {
    name: "BlueBenx",
    symbol: "BENX",
    slug: "bluebenx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bluebenx.png",
  },
  {
    name: "MGC Token",
    symbol: "MGC",
    slug: "mgc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mgc-token.png",
  },
  {
    name: "ApeX Protocol",
    symbol: "APEX",
    slug: "apex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apex-token.png",
  },
  {
    name: "BeagleInu",
    symbol: "BIC",
    slug: "beagleinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beagleinu.png",
  },
  {
    name: "BLACKHOLE PROTOCOL",
    symbol: "BLACK",
    slug: "blackhole-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackhole-protocol.png",
  },
  {
    name: "SmartLOX",
    symbol: "SMARTLOX",
    slug: "smartlox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartlox.png",
  },
  {
    name: "MetaniaGames",
    symbol: "METANIA",
    slug: "metaniagames",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaniagames.png",
  },
  {
    name: "Smartpayment",
    symbol: "SPAY",
    slug: "smartpayment",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartpayment.png",
  },
  {
    name: "Daisy Launch Pad",
    symbol: "DAISY",
    slug: "daisy-launch-pad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daisy-launch-pad.png",
  },
  {
    name: "PetKingdom",
    symbol: "PKD",
    slug: "petkingdom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petkingdom.png",
  },
  {
    name: "DefiCliq",
    symbol: "CLIQ",
    slug: "deficliq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deficliq.png",
  },
  {
    name: "Vibing",
    symbol: "VBG",
    slug: "vibing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vibing.png",
  },
  {
    name: "OKBoomer Token",
    symbol: "OKBOOMER",
    slug: "okboomer-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okboomer-token.png",
  },
  {
    name: "Nest Arcade",
    symbol: "NESTA",
    slug: "nest-arcade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nest-arcade.png",
  },
  {
    name: "Fanspel",
    symbol: "FAN",
    slug: "fanspel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanspel.png",
  },
  {
    name: "ROND",
    symbol: "ROND",
    slug: "rond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rond.png",
  },
  {
    name: "Nifty League",
    symbol: "NFTL",
    slug: "nifty-league",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nifty-league.png",
  },
  {
    name: "Web3 Inu",
    symbol: "WEB3",
    slug: "web3-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web3-inu.png",
  },
  {
    name: "Galaxy Arena Metaverse",
    symbol: "ESNC",
    slug: "galaxy-arena-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-arena-metaverse.png",
  },
  {
    name: "SugarBounce",
    symbol: "TIP",
    slug: "sugarbounce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sugarbounce.png",
  },
  {
    name: "PhoenixDefi.Finance",
    symbol: "PNIX",
    slug: "phoenixdefi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenixdefi-finance.png",
  },
  {
    name: "AquaFi",
    symbol: "AQUA",
    slug: "aquafi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aquafi.png",
  },
  {
    name: "Socaverse",
    symbol: "SOCA",
    slug: "socaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/socaverse.png",
  },
  {
    name: "Prime Chain",
    symbol: "PMC",
    slug: "prime-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prime-chain.png",
  },
  {
    name: "CAKEPUNKS",
    symbol: "CAKEPUNKS",
    slug: "cakepunks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cakepunks.png",
  },
  {
    name: "FoxGirl",
    symbol: "FOXGIRL",
    slug: "foxgirl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foxgirl.png",
  },
  {
    name: "Crypto Village Accelerator",
    symbol: "CVAG",
    slug: "new-crypto-village-accelerator",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-crypto-village-accelerator.png",
  },
  {
    name: "Zodiacs",
    symbol: "ZDC",
    slug: "zodiacs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zodiacs.png",
  },
  {
    name: "XMINE",
    symbol: "XMN",
    slug: "xmine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xmine.png",
  },
  {
    name: "Corsac",
    symbol: "CSCT",
    slug: "corsac",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corsac.png",
  },
  {
    name: "Monverse",
    symbol: "MONSTR",
    slug: "monverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monverse.png",
  },
  {
    name: "REWARD HUNTERS TOKEN",
    symbol: "RHT",
    slug: "reward-hunters-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reward-hunters-token.png",
  },
  {
    name: "MOON",
    symbol: "MOON",
    slug: "moontoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moontoken.png",
  },
  {
    name: "XEUS",
    symbol: "XEUS",
    slug: "xeus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xeus.png",
  },
  {
    name: "FesBNB",
    symbol: "FESBNB",
    slug: "fesbnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fesbnb.png",
  },
  {
    name: "Blockchain Exchange Alliance",
    symbol: "BXA",
    slug: "blockchain-exchange-alliance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockchain-exchange-alliance.png",
  },
  {
    name: "MilkshakeSwap",
    symbol: "Milk",
    slug: "milkshakeswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milkshakeswap-finance.png",
  },
  {
    name: "BleuFi",
    symbol: "BLEU",
    slug: "bluefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bluefi.png",
  },
  {
    name: "Utopia",
    symbol: "UTOPIA",
    slug: "utopia-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utopia-bsc.png",
  },
  {
    name: "LEOPARD",
    symbol: "LEOPARD",
    slug: "leopard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leopard.png",
  },
  {
    name: "yffi finance",
    symbol: "YFFI",
    slug: "yffi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yffi-finance.png",
  },
  {
    name: "Meta Capital",
    symbol: "MCAP",
    slug: "meta-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-capital.png",
  },
  {
    name: "Satozhi",
    symbol: "SATOZ",
    slug: "satozhi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satozhi.png",
  },
  {
    name: "EvilSquidGame",
    symbol: "EVILSQUID",
    slug: "evilsquidgame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evilsquidgame.png",
  },
  {
    name: "Experty Wisdom Token",
    symbol: "WIS",
    slug: "experty-wisdom-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/experty-wisdom-token.png",
  },
  {
    name: "BHAX Token",
    symbol: "BHAX",
    slug: "bithashex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bithashex.png",
  },
  {
    name: "Baby WOJ",
    symbol: "BWJ",
    slug: "baby-woj",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-woj.png",
  },
  {
    name: "Galaxy Finance",
    symbol: "GFT",
    slug: "galaxy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-finance.png",
  },
  {
    name: "LinkArt",
    symbol: "LAR",
    slug: "linkart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkart.png",
  },
  {
    name: "Squawk",
    symbol: "SQUAWK",
    slug: "squawk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squawk.png",
  },
  {
    name: "Shibgeki",
    symbol: "SHIBGEKI",
    slug: "shibgeki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibgeki.png",
  },
  {
    name: "Lightning",
    symbol: "LIGHT",
    slug: "lightning",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lightning.png",
  },
  {
    name: "Sweeptoken",
    symbol: "SWEEP",
    slug: "sweeptoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sweeptoken.png",
  },
  {
    name: "Mint Marble",
    symbol: "MIM",
    slug: "mint-marble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mint-marble.png",
  },
  {
    name: "Minted",
    symbol: "MTD",
    slug: "minted",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minted.png",
  },
  {
    name: "The Last War",
    symbol: "TLW",
    slug: "the-last-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-last-war.png",
  },
  {
    name: "Ginoa",
    symbol: "GINOA",
    slug: "ginoa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ginoa.png",
  },
  {
    name: "Hiblocks",
    symbol: "HIBS",
    slug: "hiblocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hiblocks.png",
  },
  {
    name: "Foxboy",
    symbol: "FBB",
    slug: "foxboy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foxboy.png",
  },
  {
    name: "Niftify",
    symbol: "NIFT",
    slug: "niftify",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niftify.png",
  },
  {
    name: "MoonEdge",
    symbol: "MOONED",
    slug: "moonedge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonedge.png",
  },
  {
    name: "Perp Inu",
    symbol: "PERPI",
    slug: "perp-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/perp-inu.png",
  },
  {
    name: "Griffin Art",
    symbol: "GART",
    slug: "griffin-art",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/griffin-art.png",
  },
  {
    name: "Rake Finance",
    symbol: "RAK",
    slug: "rake-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rake-finance.png",
  },
  {
    name: "SafeMoon",
    symbol: "SFM",
    slug: "safemoon-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemoon-v2.png",
  },
  {
    name: "KUN",
    symbol: "KUN",
    slug: "qian-kun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qian-kun.png",
  },
  {
    name: "AniFi World",
    symbol: "ANIFI",
    slug: "anifi-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anifi-world.png",
  },
  {
    name: "Lever Token",
    symbol: "LEV",
    slug: "lever-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lever-token.png",
  },
  {
    name: "Asian Fintech",
    symbol: "AFIN",
    slug: "asian-fintech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asian-fintech.png",
  },
  {
    name: "Mooncake Token",
    symbol: "MOON",
    slug: "mooncake-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mooncake-token.png",
  },
  {
    name: "Bullswap Exchange",
    symbol: "BVL",
    slug: "bullswap-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullswap-exchange.png",
  },
  {
    name: "GeniuX",
    symbol: "IUX",
    slug: "geniux",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geniux.png",
  },
  {
    name: "Asia Reserve Currency Coin",
    symbol: "ARCC",
    slug: "asia-reserve-currency-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asia-reserve-currency-coin.png",
  },
  {
    name: "MetaSafeMoon",
    symbol: "METASFM",
    slug: "metasafemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metasafemoon.png",
  },
  {
    name: "METAFLIP",
    symbol: "METAFLIP",
    slug: "metaflip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaflip.png",
  },
  {
    name: "Octaplex Network",
    symbol: "PLX",
    slug: "octaplex-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octaplex-network.png",
  },
  {
    name: "Single Finance",
    symbol: "SINGLE",
    slug: "single-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/single-finance.png",
  },
  {
    name: "AquaGoat.Finance",
    symbol: "AQUAGOAT",
    slug: "aquagoat-finance-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aquagoat-finance-new.png",
  },
  {
    name: "X3Swap",
    symbol: "X3S",
    slug: "x3swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x3swap.png",
  },
  {
    name: "SolDate Token",
    symbol: "DATE",
    slug: "soldate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soldate-token.png",
  },
  {
    name: "DeFi Kingdoms Crystal",
    symbol: "CRYSTAL",
    slug: "defi-kingdoms-crystal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-kingdoms-crystal.png",
  },
  {
    name: "Delos Defi",
    symbol: "DELOS",
    slug: "delos-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/delos-defi.png",
  },
  {
    name: "FRANCE REV FINANCE",
    symbol: "FRF",
    slug: "france-rev-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/france-rev-finance.png",
  },
  {
    name: "Crypto Price Index",
    symbol: "CPI",
    slug: "crypto-price-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-price-index.png",
  },
  {
    name: "Crafting Finance",
    symbol: "CRF",
    slug: "crafting-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crafting-finance.png",
  },
  {
    name: "Shibance",
    symbol: "WOOF",
    slug: "shibance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibance.png",
  },
  {
    name: "Kitty Ninja",
    symbol: "KINJA",
    slug: "kitty-ninja",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitty-ninja.png",
  },
  {
    name: "Elitheum",
    symbol: "ELTHM",
    slug: "elitheum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elitheum.png",
  },
  {
    name: "bRing.Finance",
    symbol: "BRNG",
    slug: "bring-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bring-finance.png",
  },
  {
    name: "AnonymousBSC",
    symbol: "ANON",
    slug: "anonymousbsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anonymousbsc.png",
  },
  {
    name: "Arbitrage Token",
    symbol: "RBTR",
    slug: "arbitrage-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbitrage-token.png",
  },
  {
    name: "Wish Finance",
    symbol: "WISH",
    slug: "wish-finance-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wish-finance-2.png",
  },
  {
    name: "GameVerse",
    symbol: "GMV",
    slug: "gameverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gameverse.png",
  },
  {
    name: "PUML Better Health",
    symbol: "PUML",
    slug: "puml-better-health",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puml-better-health.png",
  },
  {
    name: "APCH",
    symbol: "APCH",
    slug: "apch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apch.png",
  },
  {
    name: "ZOMBIE SKRAT",
    symbol: "ZSKRAT",
    slug: "zombie-skrat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zombie-skrat.png",
  },
  {
    name: "Secured Ship",
    symbol: "SHIP",
    slug: "secured-ship",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secured-ship.png",
  },
  {
    name: "iOWN Token",
    symbol: "iOWN",
    slug: "iown-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iown-token.png",
  },
  {
    name: "ArcaneLeague",
    symbol: "ARCANELEAGUE",
    slug: "arcaneleague",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcaneleague.png",
  },
  {
    name: "Apes of Empires",
    symbol: "AOE",
    slug: "apes-of-empires",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apes-of-empires.png",
  },
  {
    name: "Upper Dollar",
    symbol: "USDU",
    slug: "upper-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upper-dollar.png",
  },
  {
    name: "Treat DAO [new]",
    symbol: "TREAT",
    slug: "treat-dao-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treat-dao-new.png",
  },
  {
    name: "Harmonized App",
    symbol: "HMZ",
    slug: "harmomized-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harmomized-app.png",
  },
  {
    name: "SuperFuel",
    symbol: "SFUEL",
    slug: "superfuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superfuel.png",
  },
  {
    name: "Arix",
    symbol: "ARIX",
    slug: "arix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arix.png",
  },
  {
    name: "Malinka",
    symbol: "MLNK",
    slug: "malinka",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/malinka.png",
  },
  {
    name: "Moonday Finance",
    symbol: "MOONDAY",
    slug: "moonday-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonday-finance.png",
  },
  {
    name: "Upper Euro",
    symbol: "EURU",
    slug: "upper-euro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upper-euro.png",
  },
  {
    name: "HONEYPAD",
    symbol: "$HONEY",
    slug: "honeypad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honeypad.png",
  },
  {
    name: "SOLOXCOIN",
    symbol: "SL",
    slug: "soloxcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soloxcoin.png",
  },
  {
    name: "MOR",
    symbol: "MOR",
    slug: "mor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mor.png",
  },
  {
    name: "battle esports coin",
    symbol: "BES",
    slug: "battle-esports-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-esports-coin.png",
  },
  {
    name: "Z7DAO",
    symbol: "Z7",
    slug: "z7dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/z7dao.png",
  },
  {
    name: "Nitroex",
    symbol: "NTX",
    slug: "nitroex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nitroex.png",
  },
  {
    name: "XY Finance",
    symbol: "XY",
    slug: "xy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xy-finance.png",
  },
  {
    name: "BlackPool",
    symbol: "BPT",
    slug: "blackpool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackpool.png",
  },
  {
    name: "Mammon",
    symbol: "MMON",
    slug: "mammon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mammon.png",
  },
  {
    name: "MangoChain",
    symbol: "MGP",
    slug: "mangochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mangochain.png",
  },
  {
    name: "Cap",
    symbol: "CAP",
    slug: "cap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cap.png",
  },
  {
    name: "HobbsNetworkToken",
    symbol: "HNW",
    slug: "hobbsnetworktoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hobbsnetworktoken.png",
  },
  {
    name: "Lego Coin",
    symbol: "LEGO",
    slug: "lego-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lego-coin.png",
  },
  {
    name: "Ultimate Nft",
    symbol: "UNFT",
    slug: "ultimate-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultimate-nft.png",
  },
  {
    name: "The Dynasty",
    symbol: "DYT",
    slug: "the-dynasty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-dynasty.png",
  },
  {
    name: "TopG Coin",
    symbol: "TOPG",
    slug: "topg-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/topg-coin.png",
  },
  {
    name: "MetaMoon",
    symbol: "METAMOON",
    slug: "metamoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamoon.png",
  },
  {
    name: "Evergreen token",
    symbol: "EGT",
    slug: "evergreen-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evergreen-token.png",
  },
  {
    name: "TombPrinter",
    symbol: "TOMBP",
    slug: "tombprinter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tombprinter.png",
  },
  {
    name: "ESWAP.TUBE",
    symbol: "TUBE2",
    slug: "tube2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tube2.png",
  },
  {
    name: "Fantasy Girl",
    symbol: "FMEV2",
    slug: "fantasy-girl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasy-girl.png",
  },
  {
    name: "BabyWhiteTiger",
    symbol: "BWT",
    slug: "babywhitetiger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babywhitetiger.png",
  },
  {
    name: "DAOhaus",
    symbol: "HAUS",
    slug: "daohaus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daohaus.png",
  },
  {
    name: "Binance VND",
    symbol: "BVND",
    slug: "binance-vnd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binance-vnd.png",
  },
  {
    name: "KaraStar",
    symbol: "KARA",
    slug: "karastar-kara",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karastar-kara.png",
  },
  {
    name: "Golden Ball",
    symbol: "GLB",
    slug: "golden-ball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-ball.png",
  },
  {
    name: "Data Economy Index",
    symbol: "DATA",
    slug: "data-economy-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/data-economy-index.png",
  },
  {
    name: "BETCOIN",
    symbol: "BET",
    slug: "betcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betcoin.png",
  },
  {
    name: "Web3 Doge",
    symbol: "WEB3",
    slug: "web3-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/web3-doge.png",
  },
  {
    name: "Blueshift",
    symbol: "BLUES",
    slug: "blueshift",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blueshift.png",
  },
  {
    name: "Fastest Alerts",
    symbol: "FA",
    slug: "fastest-alerts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fastest-alerts.png",
  },
  {
    name: "Apollo DAO",
    symbol: "APOLLO",
    slug: "apollo-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apollo-dao.png",
  },
  {
    name: "Wolfystreetbets",
    symbol: "WOLFY",
    slug: "wolfystreetbets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolfystreetbets.png",
  },
  {
    name: "CaribMarsX",
    symbol: "CMX",
    slug: "caribmarsx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caribmarsx.png",
  },
  {
    name: "Wifedoge",
    symbol: "WIFEDOGE",
    slug: "wifedoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wifedoge.png",
  },
  {
    name: "Amasa",
    symbol: "AMAS",
    slug: "amasa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amasa.png",
  },
  {
    name: "Metaworld",
    symbol: "MW",
    slug: "metaworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaworld.png",
  },
  {
    name: "ApeBoys",
    symbol: "APEBOYS",
    slug: "apeboys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apeboys.png",
  },
  {
    name: "Warena",
    symbol: "WARE",
    slug: "warena-ware",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/warena-ware.png",
  },
  {
    name: "AllStars Digital",
    symbol: "ASX",
    slug: "allstars-digital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/allstars-digital.png",
  },
  {
    name: "AvocadoCoin",
    symbol: "AVDO",
    slug: "avocadocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avocadocoin.png",
  },
  {
    name: "Tether Gold",
    symbol: "XAUT",
    slug: "tether-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tether-gold.png",
  },
  {
    name: "OnX Finance",
    symbol: "ONX",
    slug: "onx-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onx-finance.png",
  },
  {
    name: "AstroDoggy",
    symbol: "ADOG",
    slug: "astrodoggy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astrodoggy.png",
  },
  {
    name: "Rubix",
    symbol: "RBT",
    slug: "rubix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rubix.png",
  },
  {
    name: "Doge Superbowl",
    symbol: "DSBOWL",
    slug: "doge-superbowl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-superbowl.png",
  },
  {
    name: "Kripton",
    symbol: "LPK",
    slug: "kripton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kripton.png",
  },
  {
    name: "Energy Ledger",
    symbol: "ELX",
    slug: "energy-ledger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energy-ledger.png",
  },
  {
    name: "LeagueDAO",
    symbol: "LEAG",
    slug: "leaguedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leaguedao.png",
  },
  {
    name: "Mate",
    symbol: "MATE",
    slug: "mate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mate.png",
  },
  {
    name: "Swampy",
    symbol: "SWAMP",
    slug: "swampy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swampy.png",
  },
  {
    name: "BlockchainSpace",
    symbol: "GUILD",
    slug: "blockchainspace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockchainspace.png",
  },
  {
    name: "Style",
    symbol: "STYLE",
    slug: "style",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/style.png",
  },
  {
    name: "DogemonGo Solana",
    symbol: "DOGO",
    slug: "dogemongo-solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogemongo-solana.png",
  },
  {
    name: "SoccerInu",
    symbol: "SOCCER",
    slug: "soccerinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soccerinu.png",
  },
  {
    name: "ALLPAYCOIN",
    symbol: "APCG",
    slug: "allpaycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/allpaycoin.png",
  },
  {
    name: "Cenfura Token",
    symbol: "XCF",
    slug: "cenfura-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cenfura-token.png",
  },
  {
    name: "AutoShark DEX",
    symbol: "FINS",
    slug: "autoshark-dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/autoshark-dex.png",
  },
  {
    name: "Calo App",
    symbol: "CALO",
    slug: "calo-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calo-app.png",
  },
  {
    name: "Total Crypto Market Cap Token",
    symbol: "TCAP",
    slug: "total-crypto-market-cap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/total-crypto-market-cap-token.png",
  },
  {
    name: "WAGMI Game",
    symbol: "WAGMIGAMES",
    slug: "wagmi-game-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wagmi-game-2.png",
  },
  {
    name: "Lucid Lands",
    symbol: "LLG",
    slug: "lucid-lands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucid-lands.png",
  },
  {
    name: "KickPad",
    symbol: "KPAD",
    slug: "kickpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kickpad.png",
  },
  {
    name: "USACOIN",
    symbol: "USACOIN",
    slug: "usacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usacoin.png",
  },
  {
    name: "GUARDIAN",
    symbol: "GUARD",
    slug: "guardian",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guardian.png",
  },
  {
    name: "Crypto Island",
    symbol: "CISLA",
    slug: "crypto-island",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-island.png",
  },
  {
    name: "Contents Shopper Token",
    symbol: "CST",
    slug: "contents-shopper-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/contents-shopper-token.png",
  },
  {
    name: "Mint Club",
    symbol: "MINT",
    slug: "mint-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mint-club.png",
  },
  {
    name: "Gafa",
    symbol: "GAFA",
    slug: "gafa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gafa.png",
  },
  {
    name: "NFTmall",
    symbol: "GEM",
    slug: "nftmall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftmall.png",
  },
  {
    name: "CashBackPro",
    symbol: "CBP",
    slug: "cashbackpro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashbackpro.png",
  },
  {
    name: "Silk Road",
    symbol: "SILKROAD",
    slug: "silk-road",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silk-road.png",
  },
  {
    name: "Foliowatch",
    symbol: "FWATCH",
    slug: "foliowatch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foliowatch.png",
  },
  {
    name: "Luna Inu",
    symbol: "LINU",
    slug: "luna-inu-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luna-inu-.png",
  },
  {
    name: "SmartNFT",
    symbol: "SMARTNFT",
    slug: "smartnft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartnft.png",
  },
  {
    name: "EXIP",
    symbol: "EXIP",
    slug: "exip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exip.png",
  },
  {
    name: "Farming Paradise",
    symbol: "FPG",
    slug: "farming-paradise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farming-paradise.png",
  },
  {
    name: "delta.theta",
    symbol: "DLTA",
    slug: "delta-theta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/delta-theta.png",
  },
  {
    name: "Cryptocurrency Top 10 Tokens Index",
    symbol: "CC10",
    slug: "cryptocurrency-top-10-tokens-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptocurrency-top-10-tokens-index.png",
  },
  {
    name: "Star Wars Cat",
    symbol: "SWCAT",
    slug: "star-wars-cat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/star-wars-cat.png",
  },
  {
    name: "Lulu Market",
    symbol: "LUCK",
    slug: "lulu-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lulu-market.png",
  },
  {
    name: "lasrever",
    symbol: "LSVR",
    slug: "lasrever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lasrever.png",
  },
  {
    name: "Culture Ticket Chain",
    symbol: "CTC",
    slug: "culture-ticket-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/culture-ticket-chain.png",
  },
  {
    name: "Wolverine",
    symbol: "WLVR",
    slug: "wolverine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolverine.png",
  },
  {
    name: "Onyx",
    symbol: "ONYX",
    slug: "onyx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onyx.png",
  },
  {
    name: "ThunderVerse",
    symbol: "THUNDER",
    slug: "thunderverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thunderverse.png",
  },
  {
    name: "Satopay Network",
    symbol: "STOP",
    slug: "satopay-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satopay-network.png",
  },
  {
    name: "Google tokenized stock FTX",
    symbol: "GOOGL",
    slug: "google-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/google-tokenized-stock-ftx.png",
  },
  {
    name: "Pieme",
    symbol: "PIE",
    slug: "pieme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pieme.png",
  },
  {
    name: "TinyBits",
    symbol: "TINY",
    slug: "tiny-colony",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiny-colony.png",
  },
  {
    name: "Oviex",
    symbol: "OVI",
    slug: "oviex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oviex.png",
  },
  {
    name: "Oction",
    symbol: "OCTI",
    slug: "oction",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oction.png",
  },
  {
    name: "Create",
    symbol: "CT",
    slug: "create",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/create.png",
  },
  {
    name: "KittenFinance",
    symbol: "KIF",
    slug: "kittenfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kittenfinance.png",
  },
  {
    name: "REDMARS",
    symbol: "RMARS",
    slug: "redmars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redmars.png",
  },
  {
    name: "Baby Pi Network",
    symbol: "BPI",
    slug: "baby-pi-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-pi-network.png",
  },
  {
    name: "Knit Finance",
    symbol: "KFT",
    slug: "knit-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knit-finance.png",
  },
  {
    name: "i Money Crypto",
    symbol: "IMC",
    slug: "i-money-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/i-money-crypto.png",
  },
  {
    name: "PMXX",
    symbol: "PMXX",
    slug: "pmxx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pmxx.png",
  },
  {
    name: "YMAX",
    symbol: "YMAX",
    slug: "ymax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ymax.png",
  },
  {
    name: "WoopMoney",
    symbol: "WMW",
    slug: "woopmoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/woopmoney.png",
  },
  {
    name: "Show Me the Money",
    symbol: "WINNINGS",
    slug: "show-me-the-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/show-me-the-money.png",
  },
  {
    name: "Alaya",
    symbol: "ATP",
    slug: "alaya",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alaya.png",
  },
  {
    name: "WAGMI Game",
    symbol: "WAGMI",
    slug: "wagmi-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wagmi-game.png",
  },
  {
    name: "Baby Bitcoin",
    symbol: "BBTC",
    slug: "baby-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-bitcoin.png",
  },
  {
    name: "DocuChain",
    symbol: "DCCT",
    slug: "docuchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/docuchain.png",
  },
  {
    name: "OREN Game",
    symbol: "OREN",
    slug: "oren-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oren-game.png",
  },
  {
    name: "SmurfsINU",
    symbol: "SMURF",
    slug: "smurfsinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smurfsinu.png",
  },
  {
    name: "Bloggercoin",
    symbol: "BLOGGER",
    slug: "bloggercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bloggercoin.png",
  },
  {
    name: "BIXBCOIN",
    symbol: "BIXB",
    slug: "bixbcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bixbcoin.png",
  },
  {
    name: "DOOiT Token",
    symbol: "DOO",
    slug: "dooit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dooit-token.png",
  },
  {
    name: "PIMRIDE",
    symbol: "PIM",
    slug: "pimride",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pimride.png",
  },
  {
    name: "MetaVice",
    symbol: "METAVICE",
    slug: "metavice-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metavice-token.png",
  },
  {
    name: "Mantis",
    symbol: "MNTIS",
    slug: "mantis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mantis.png",
  },
  {
    name: "Genesis Pool",
    symbol: "GPOOL",
    slug: "genesis-pool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesis-pool.png",
  },
  {
    name: "Dexfin",
    symbol: "DXF",
    slug: "dexfin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexfin.png",
  },
  {
    name: "REVOLAND TOKEN",
    symbol: "REVO",
    slug: "revoland-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revoland-token.png",
  },
  {
    name: "MetaPirates",
    symbol: "PIRATES",
    slug: "metapirates",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metapirates.png",
  },
  {
    name: "STEP",
    symbol: "STEP",
    slug: "step",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/step.png",
  },
  {
    name: "The Dragon Gate",
    symbol: "KOI",
    slug: "the-dragon-gate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-dragon-gate.png",
  },
  {
    name: "Vanilla",
    symbol: "VNL",
    slug: "vanilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanilla.png",
  },
  {
    name: "Duckereum",
    symbol: "DUCKER",
    slug: "duckereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duckereum.png",
  },
  {
    name: "Quantum Assets",
    symbol: "QA",
    slug: "quantum-assets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quantum-assets.png",
  },
  {
    name: "Arcane Token",
    symbol: "ARCANE",
    slug: "arcane-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcane-token.png",
  },
  {
    name: "MoMo KEY",
    symbol: "KEY",
    slug: "momo-key",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/momo-key.png",
  },
  {
    name: "Relictum Pro",
    symbol: "GTN",
    slug: "relictum-pro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/relictum-pro.png",
  },
  {
    name: "DOGEDI",
    symbol: "DOGEDI",
    slug: "dogedi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogedi.png",
  },
  {
    name: "Defi Connect",
    symbol: "DFC",
    slug: "defi-connect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-connect.png",
  },
  {
    name: "DangerMoon",
    symbol: "DANGERMOON",
    slug: "dangermoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dangermoon.png",
  },
  {
    name: "LoTerra",
    symbol: "LOTA",
    slug: "loterra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loterra.png",
  },
  {
    name: "HummingBird Finance",
    symbol: "HMNG",
    slug: "hummingbird-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hummingbird-finance.png",
  },
  {
    name: "Vulkania",
    symbol: "VLK",
    slug: "vulkania",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vulkania.png",
  },
  {
    name: "Shinobi Inu",
    symbol: "SHIN",
    slug: "shinobi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinobi-inu.png",
  },
  {
    name: "Moonkafe Finance",
    symbol: "KAFE",
    slug: "moonkafe-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonkafe-finance.png",
  },
  {
    name: "OtterClam (New)",
    symbol: "CLAM",
    slug: "otterclam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otterclam.png",
  },
  {
    name: "Skyrim Finance",
    symbol: "SKYRIM",
    slug: "skyrim-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skyrim-finance.png",
  },
  {
    name: "Ryoshi Token",
    symbol: "RYOSHI",
    slug: "ryoshi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ryoshi-token.png",
  },
  {
    name: "AXIS Token",
    symbol: "AXIS",
    slug: "axis-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axis-token.png",
  },
  {
    name: "Iron Bank",
    symbol: "IB",
    slug: "iron-bank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iron-bank.png",
  },
  {
    name: "RIMAUNANGIS",
    symbol: "RXT",
    slug: "rimaunangis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rimaunangis.png",
  },
  {
    name: "NFTBlackMarket",
    symbol: "NBM",
    slug: "nftblackmarket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftblackmarket.png",
  },
  {
    name: "Dobermann",
    symbol: "DOBE",
    slug: "dobermann",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dobermann.png",
  },
  {
    name: "SportsIcon",
    symbol: "ICONS",
    slug: "sportsicon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sportsicon.png",
  },
  {
    name: "AMATERAS",
    symbol: "AMT",
    slug: "amateras",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amateras.png",
  },
  {
    name: "WeStarter",
    symbol: "WAR",
    slug: "westarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/westarter.png",
  },
  {
    name: "Bubblegum",
    symbol: "BUBBLEGUM",
    slug: "bubblegum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bubblegum.png",
  },
  {
    name: "BabyLondon",
    symbol: "BABYLONDON",
    slug: "babylondon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babylondon.png",
  },
  {
    name: "DOG",
    symbol: "DOG",
    slug: "dog",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dog.png",
  },
  {
    name: "iBG Finance",
    symbol: "IBG",
    slug: "ibg-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ibg-finance.png",
  },
  {
    name: "RXCGames",
    symbol: "REXC",
    slug: "rxcgames",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rxcgames.png",
  },
  {
    name: "Baby Cat Coin",
    symbol: "BABYCATS",
    slug: "baby-cat-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-cat-coin.png",
  },
  {
    name: "Metaverse Miner",
    symbol: "META",
    slug: "metaverse-miner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-miner.png",
  },
  {
    name: "ShibaZilla",
    symbol: "SHIBAZILLA",
    slug: "shibazillacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibazillacoin.png",
  },
  {
    name: "InPoker",
    symbol: "INP",
    slug: "inpoker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inpoker.png",
  },
  {
    name: "SANS Token",
    symbol: "SANS",
    slug: "sans-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sans-token.png",
  },
  {
    name: "Netzcoin",
    symbol: "NETZ",
    slug: "netzcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netzcoin.png",
  },
  {
    name: "Sherpa",
    symbol: "SHERPA",
    slug: "sherpa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sherpa.png",
  },
  {
    name: "AlgoBlocks",
    symbol: "ALGOBLK",
    slug: "algoblocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algoblocks.png",
  },
  {
    name: "Feyorra",
    symbol: "FEY",
    slug: "feyorra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feyorra.png",
  },
  {
    name: "Moon Warriors",
    symbol: "MWAR",
    slug: "moon-warriors",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-warriors.png",
  },
  {
    name: "Open Rights Exchange",
    symbol: "ORE",
    slug: "open-rights-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/open-rights-exchange.png",
  },
  {
    name: "Inverse Finance",
    symbol: "INV",
    slug: "inverse-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inverse-finance.png",
  },
  {
    name: "SpacePi",
    symbol: "SPACEPI",
    slug: "spacepi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacepi.png",
  },
  {
    name: "XTime",
    symbol: "XTM",
    slug: "xtime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtime.png",
  },
  {
    name: "Chip",
    symbol: "CHIP",
    slug: "chip-shambala",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chip-shambala.png",
  },
  {
    name: "Paladin dao",
    symbol: "PAL",
    slug: "paladin-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paladin-dao.png",
  },
  {
    name: "VARC",
    symbol: "VARC",
    slug: "varc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/varc.png",
  },
  {
    name: "UniFi Protocol",
    symbol: "UP",
    slug: "unifi-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unifi-protocol.png",
  },
  {
    name: "Bitsol Finance",
    symbol: "BTSL",
    slug: "bitsol-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitsol-finance.png",
  },
  {
    name: "Bit Hotel",
    symbol: "BTH",
    slug: "bit-hotel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit-hotel.png",
  },
  {
    name: "Manyswap",
    symbol: "MANY",
    slug: "manyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manyswap.png",
  },
  {
    name: "Music Infinity Token",
    symbol: "MIT",
    slug: "music-infinity-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/music-infinity-token.png",
  },
  {
    name: "DAOSquare",
    symbol: "RICE",
    slug: "daosquare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daosquare.png",
  },
  {
    name: "Baby Shark",
    symbol: "SHARK",
    slug: "baby-shark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-shark.png",
  },
  {
    name: "Feed Pups",
    symbol: "FUPS",
    slug: "feed-pups",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feed-pups.png",
  },
  {
    name: "Battle In Verse",
    symbol: "BTT",
    slug: "battle-in-verse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-in-verse.png",
  },
  {
    name: "CryptoBattles",
    symbol: "CBT",
    slug: "cryptobattles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobattles.png",
  },
  {
    name: "CamelToken",
    symbol: "CMLT",
    slug: "cameltoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cameltoken.png",
  },
  {
    name: "Shibal Inu Moon",
    symbol: "SHIBAL",
    slug: "shibal-inu-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibal-inu-moon.png",
  },
  {
    name: "GYSR",
    symbol: "GYSR",
    slug: "gysr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gysr.png",
  },
  {
    name: "Legends of Aria",
    symbol: "ARIA",
    slug: "legends-of-aria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legends-of-aria.png",
  },
  {
    name: "ApeSwap Finance",
    symbol: "BANANA",
    slug: "apeswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apeswap-finance.png",
  },
  {
    name: "Betterment Digital",
    symbol: "BEMD",
    slug: "betterment-digital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betterment-digital.png",
  },
  {
    name: "Titano",
    symbol: "TITANO",
    slug: "titano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/titano.png",
  },
  {
    name: "Wrapped IoTeX",
    symbol: "WIOTX",
    slug: "wrapped-iotex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-iotex.png",
  },
  {
    name: "Bruce Non Fungible Token",
    symbol: "BNFT",
    slug: "bruce-non-fungible-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bruce-non-fungible-token.png",
  },
  {
    name: "DFX Finance",
    symbol: "DFX",
    slug: "dfx-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfx-finance.png",
  },
  {
    name: "Menzy",
    symbol: "MNZ",
    slug: "menzy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/menzy.png",
  },
  {
    name: "Prism",
    symbol: "PRISM",
    slug: "prism",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prism.png",
  },
  {
    name: "GogolCoin",
    symbol: "GOL",
    slug: "gogolcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gogolcoin.png",
  },
  {
    name: "XRPayNet",
    symbol: "XRPAYNET",
    slug: "xrpaynet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrpaynet.png",
  },
  {
    name: "CumInu",
    symbol: "CUMINU",
    slug: "cuminu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cuminu.png",
  },
  {
    name: "Oogear",
    symbol: "OG",
    slug: "oogear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oogear.png",
  },
  {
    name: "SolidityLabs",
    symbol: "SOLIDITYLABS",
    slug: "soliditylabs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soliditylabs.png",
  },
  {
    name: "Rich Doge Coin",
    symbol: "RichDoge",
    slug: "rich-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rich-doge-coin.png",
  },
  {
    name: "RimsofLegend",
    symbol: "ROL",
    slug: "rimsoflegend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rimsoflegend.png",
  },
  {
    name: "StarkMeta",
    symbol: "SMETA",
    slug: "starkmeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starkmeta.png",
  },
  {
    name: "pDollar Share",
    symbol: "SPDO",
    slug: "pdollar-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pdollar-share.png",
  },
  {
    name: "DigiCol",
    symbol: "DGCL",
    slug: "digicol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digicol.png",
  },
  {
    name: "Decentralized Community Investment Protocol",
    symbol: "DCIP",
    slug: "decentralized-community-investment-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-community-investment-protocol.png",
  },
  {
    name: "Stabilize USD",
    symbol: "SUSD",
    slug: "stabilize-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stabilize-usd.png",
  },
  {
    name: "Krypton Galaxy Coin",
    symbol: "KGC",
    slug: "krypton-galaxy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krypton-galaxy-coin.png",
  },
  {
    name: "Vortex DAO",
    symbol: "SPACE",
    slug: "vortex-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vortex-dao.png",
  },
  {
    name: "DogePepsi",
    symbol: "DPT",
    slug: "dogepepsi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogepepsi.png",
  },
  {
    name: "unilock.network",
    symbol: "UNL",
    slug: "unilock-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unilock-network.png",
  },
  {
    name: "MarsFlkinu",
    symbol: "FLOKI",
    slug: "marsflkinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marsflkinu.png",
  },
  {
    name: "Seedify NFT Space",
    symbol: "SNFTS",
    slug: "seedify-snfts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seedify-snfts.png",
  },
  {
    name: "FlappyRats",
    symbol: "FRATS",
    slug: "flappyrats",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flappyrats.png",
  },
  {
    name: "Endless Battlefield",
    symbol: "EB",
    slug: "endless-battlefield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/endless-battlefield.png",
  },
  {
    name: "Ownly",
    symbol: "OWN",
    slug: "ownly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ownly.png",
  },
  {
    name: "DogZVerse",
    symbol: "DGZV",
    slug: "dogzverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogzverse.png",
  },
  {
    name: "MetaSoldier",
    symbol: "MSD",
    slug: "metasoldier",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metasoldier.png",
  },
  {
    name: "The Pablo Token",
    symbol: "PABLO",
    slug: "the-pablo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-pablo-token.png",
  },
  {
    name: "Sting Defi",
    symbol: "SDFI",
    slug: "sting-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sting-defi.png",
  },
  {
    name: "MoonsDust",
    symbol: "MOOND",
    slug: "moonsdust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonsdust.png",
  },
  {
    name: "Shibnobi",
    symbol: "SHINJA",
    slug: "shibnobi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibnobi.png",
  },
  {
    name: "Ferro",
    symbol: "FER",
    slug: "ferro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ferro.png",
  },
  {
    name: "Londefy",
    symbol: "LDF",
    slug: "londefy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/londefy.png",
  },
  {
    name: "ArchAngel Token",
    symbol: "ARCHA",
    slug: "archangel-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/archangel-token.png",
  },
  {
    name: "Solex Finance",
    symbol: "SLX",
    slug: "solex-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solex-finance.png",
  },
  {
    name: "EthereumPay",
    symbol: "EPAY",
    slug: "ethereumpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereumpay.png",
  },
  {
    name: "Dreamverse",
    symbol: "DV",
    slug: "dreamverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dreamverse.png",
  },
  {
    name: "Charizard Inu",
    symbol: "CHARIZARD",
    slug: "charizard-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charizard-inu.png",
  },
  {
    name: "BSC TOOLS",
    symbol: "TOOLS",
    slug: "bsc-tools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsc-tools.png",
  },
  {
    name: "PDX Coin",
    symbol: "PDX",
    slug: "pdx-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pdx-coin.png",
  },
  {
    name: "Eiichiro Oda Inu",
    symbol: "ODA",
    slug: "eiichiro-oda-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eiichiro-oda-inu.png",
  },
  {
    name: "Krill",
    symbol: "KRILL",
    slug: "krill",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krill.png",
  },
  {
    name: "JACYWAYA",
    symbol: "JACY",
    slug: "jacywaya",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jacywaya.png",
  },
  {
    name: "Cloudname",
    symbol: "CNAME",
    slug: "cloudname",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cloudname.png",
  },
  {
    name: "Yield Parrot",
    symbol: "LORY",
    slug: "yield-parrot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-parrot.png",
  },
  {
    name: "METAVERSE FACE",
    symbol: "MEFA",
    slug: "metaverse-face",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-face.png",
  },
  {
    name: "Wizardia",
    symbol: "WZRD",
    slug: "wizardia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wizardia.png",
  },
  {
    name: "Baby Moon Wolf",
    symbol: "BABYWOLF",
    slug: "baby-moon-wolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-moon-wolf.png",
  },
  {
    name: "MuskSwap",
    symbol: "MUSK",
    slug: "muskswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muskswap.png",
  },
  {
    name: "NSHARE",
    symbol: "NSHARE",
    slug: "nshare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nshare.png",
  },
  {
    name: "Acet",
    symbol: "ACT",
    slug: "acet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acet.png",
  },
  {
    name: "Inflation Hedging Coin",
    symbol: "IHC",
    slug: "inflation-hedging-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inflation-hedging-coin.png",
  },
  {
    name: "IFOSwap Token",
    symbol: "H2O",
    slug: "ifoswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ifoswap-token.png",
  },
  {
    name: "FuruKuru",
    symbol: "FUKU",
    slug: "furukuru",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/furukuru.png",
  },
  {
    name: "Zeppelin DAO",
    symbol: "ZEP",
    slug: "zeppelin-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeppelin-dao.png",
  },
  {
    name: "KCC GO",
    symbol: "KCCGO",
    slug: "kcc-go",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kcc-go.png",
  },
  {
    name: "Zone of Avoidance",
    symbol: "ZOA",
    slug: "zone-of-avoidance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zone-of-avoidance.png",
  },
  {
    name: "Realfinance Network",
    symbol: "REFI",
    slug: "realfinance-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realfinance-network.png",
  },
  {
    name: "MoneydefiSwap",
    symbol: "MSD",
    slug: "moneydefiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moneydefiswap.png",
  },
  {
    name: "Elon GOAT",
    symbol: "EGT",
    slug: "elon-goat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elon-goat.png",
  },
  {
    name: "SPACE SIP",
    symbol: "SIP",
    slug: "space-sip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-sip.png",
  },
  {
    name: "SuperPlayer World",
    symbol: "HONOR",
    slug: "superplayer-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superplayer-world.png",
  },
  {
    name: "Basis Dollar",
    symbol: "BSD",
    slug: "basis-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basis-dollar.png",
  },
  {
    name: "Blizzard Network",
    symbol: "BLIZZ",
    slug: "blizzard-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blizzard-network.png",
  },
  {
    name: "UpOnly",
    symbol: "UPO",
    slug: "uponly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uponly.png",
  },
  {
    name: "Shiba Metaverse",
    symbol: "SHIBMETA",
    slug: "shiba-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-metaverse.png",
  },
  {
    name: "PolkaParty",
    symbol: "POLP",
    slug: "polkaparty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkaparty.png",
  },
  {
    name: "XBE Token",
    symbol: "XBE",
    slug: "xbe-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xbe-token.png",
  },
  {
    name: "StrongNode Edge",
    symbol: "SNE",
    slug: "strongnode-edge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strongnode-edge.png",
  },
  {
    name: "PearZap",
    symbol: "PEAR",
    slug: "pearzap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pearzap.png",
  },
  {
    name: "Booster",
    symbol: "BOO",
    slug: "booster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/booster.png",
  },
  {
    name: "INU Token",
    symbol: "INU",
    slug: "inu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inu-token.png",
  },
  {
    name: "YieldWars",
    symbol: "WAR",
    slug: "yieldwars-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yieldwars-com.png",
  },
  {
    name: "Swapfolio",
    symbol: "SWFL",
    slug: "swapfolio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapfolio.png",
  },
  {
    name: "SPORT",
    symbol: "SPORT",
    slug: "sport",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sport.png",
  },
  {
    name: "Axion",
    symbol: "AXN",
    slug: "axion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axion.png",
  },
  {
    name: "Meta Musk",
    symbol: "META",
    slug: "meta-musk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-musk.png",
  },
  {
    name: "Dopex",
    symbol: "RDPX",
    slug: "dopex-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dopex-.png",
  },
  {
    name: "Genesis Mana",
    symbol: "MANA",
    slug: "genesis-mana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesis-mana.png",
  },
  {
    name: "BabyDogeX",
    symbol: "BDOGEX",
    slug: "babydogex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babydogex.png",
  },
  {
    name: "Miners Defi",
    symbol: "MINERS",
    slug: "miners-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miners-defi.png",
  },
  {
    name: "Fantom Oasis",
    symbol: "FTMO",
    slug: "fantom-oasis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantom-oasis.png",
  },
  {
    name: "YFi Management",
    symbol: "YEFIM",
    slug: "yfi-management",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfi-management.png",
  },
  {
    name: "Corgiswap",
    symbol: "CORIS",
    slug: "corgiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corgiswap.png",
  },
  {
    name: "moonwolf.io",
    symbol: "WOLF",
    slug: "moonwolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonwolf.png",
  },
  {
    name: "Ghost Block",
    symbol: "ECTO",
    slug: "ghost-block",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghost-block.png",
  },
  {
    name: "PMG Coin",
    symbol: "PMG",
    slug: "pmg-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pmg-coin.png",
  },
  {
    name: "e-Money EUR",
    symbol: "EEUR",
    slug: "e-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/e-money.png",
  },
  {
    name: "Fidira",
    symbol: "FID",
    slug: "fidira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fidira.png",
  },
  {
    name: "iCrypto World",
    symbol: "ICW",
    slug: "icrypto-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icrypto-world.png",
  },
  {
    name: "Spice",
    symbol: "SPICE",
    slug: "spice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spice.png",
  },
  {
    name: "AetherV2",
    symbol: "ATH",
    slug: "aetherv2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aetherv2.png",
  },
  {
    name: "Baby Bonfire",
    symbol: "FIRE",
    slug: "baby-bonfire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-bonfire.png",
  },
  {
    name: "Wizarre Scroll",
    symbol: "SCRL",
    slug: "wizarre",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wizarre.png",
  },
  {
    name: "Bancor Governance Token",
    symbol: "VBNT",
    slug: "bancor-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bancor-governance-token.png",
  },
  {
    name: "MetaGaming",
    symbol: "MTGM",
    slug: "metagaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagaming.png",
  },
  {
    name: "Xpose Protocol",
    symbol: "XP",
    slug: "xpose-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xpose-protocol.png",
  },
  {
    name: "Ainu Token",
    symbol: "AINU",
    slug: "ainu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ainu-token.png",
  },
  {
    name: "DaddyShiba",
    symbol: "DADDYSHIBA",
    slug: "daddyshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddyshiba.png",
  },
  {
    name: "MetaSoccer",
    symbol: "MSU",
    slug: "metasoccer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metasoccer.png",
  },
  {
    name: "Bit.Store",
    symbol: "STORE",
    slug: "bit-store",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit-store.png",
  },
  {
    name: "PancakePoll",
    symbol: "PPOLL",
    slug: "pancakepoll",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pancakepoll.png",
  },
  {
    name: "NiftyNFT",
    symbol: "NIFTY",
    slug: "niftynft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niftynft.png",
  },
  {
    name: "Health Potion",
    symbol: "HEP",
    slug: "health-potion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/health-potion.png",
  },
  {
    name: "Egoras Credit",
    symbol: "EGC",
    slug: "egoras-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egoras-credit.png",
  },
  {
    name: "Prostarter",
    symbol: "PROT",
    slug: "prostarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prostarter.png",
  },
  {
    name: "HyperBoost",
    symbol: "HYPERBOOST",
    slug: "hyperboost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperboost.png",
  },
  {
    name: "Unreal Finance",
    symbol: "UGT",
    slug: "unreal-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unreal-finance.png",
  },
  {
    name: "OPX Finance",
    symbol: "OPX",
    slug: "opx-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opx-finance.png",
  },
  {
    name: "Credit Suisse Inu",
    symbol: "CSI",
    slug: "credit-suisse-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/credit-suisse-inu.png",
  },
  {
    name: "Charli3",
    symbol: "C3",
    slug: "charli3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charli3.png",
  },
  {
    name: "WifeChangingMoney",
    symbol: "WCM",
    slug: "wifechangingmoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wifechangingmoney.png",
  },
  {
    name: "Pinecone Finance",
    symbol: "PCT",
    slug: "pinecone-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pinecone-finance.png",
  },
  {
    name: "PocMon",
    symbol: "PMON",
    slug: "pocmon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocmon.png",
  },
  {
    name: "MiniUSDC",
    symbol: "MINIUSDC",
    slug: "miniusdc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miniusdc.png",
  },
  {
    name: "StarbaseUniverse",
    symbol: "SUNI",
    slug: "starbaseuniverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starbaseuniverse.png",
  },
  {
    name: "SatoshiCrypto",
    symbol: "SATO",
    slug: "satoshicrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoshicrypto.png",
  },
  {
    name: "Dot-Matrix",
    symbol: "DOTMATRIX",
    slug: "dot-matrix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dot-matrix.png",
  },
  {
    name: "XIDO FINANCE",
    symbol: "XIDO",
    slug: "xido-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xido-finance.png",
  },
  {
    name: "Jointer",
    symbol: "JNTR",
    slug: "jointer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jointer.png",
  },
  {
    name: "BuffedShiba",
    symbol: "BSHIB",
    slug: "buffedshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buffedshiba.png",
  },
  {
    name: "Dachshund",
    symbol: "DSD",
    slug: "dachshund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dachshund.png",
  },
  {
    name: "Mad USD",
    symbol: "MUSD",
    slug: "mad-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mad-usd.png",
  },
  {
    name: "Grill.Farm",
    symbol: "GRILL",
    slug: "grill-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grill-farm.png",
  },
  {
    name: "QIAN Second Generation Dollar",
    symbol: "QSD",
    slug: "qian-second-generation-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qian-second-generation-dollar.png",
  },
  {
    name: "Hourglass",
    symbol: "WAIT",
    slug: "hourglass",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hourglass.png",
  },
  {
    name: "Hidigital btc",
    symbol: "HDBTC",
    slug: "hidigital-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hidigital-btc.png",
  },
  {
    name: "Cashcow Finance",
    symbol: "CCF",
    slug: "cashcow-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashcow-finance.png",
  },
  {
    name: "ForceCowBoy",
    symbol: "FCB",
    slug: "forcecowboy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forcecowboy.png",
  },
  {
    name: "AstroElon",
    symbol: "ELONONE",
    slug: "astroelon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astroelon.png",
  },
  {
    name: "Phat Doge Givings",
    symbol: "GIVING",
    slug: "phat-doge-givings",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phat-doge-givings.png",
  },
  {
    name: "Axienomics",
    symbol: "AXIN",
    slug: "axienomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axienomics.png",
  },
  {
    name: "NFT Wars",
    symbol: "WAR",
    slug: "nft-wars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-wars.png",
  },
  {
    name: "Reflex Finance",
    symbol: "REFLEX",
    slug: "reflex-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reflex-finance.png",
  },
  {
    name: "STIMA",
    symbol: "STIMA",
    slug: "stima",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stima.png",
  },
  {
    name: "HyperVerse",
    symbol: "HVT",
    slug: "hyperverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperverse.png",
  },
  {
    name: "TriipMiles",
    symbol: "TIIM",
    slug: "triipmiles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/triipmiles.png",
  },
  {
    name: "Landshare",
    symbol: "LAND",
    slug: "landshare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/landshare.png",
  },
  {
    name: "ZigZag",
    symbol: "ZZ",
    slug: "zigzag",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zigzag.png",
  },
  {
    name: "PolyDoge",
    symbol: "POLYDOGE",
    slug: "polydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polydoge.png",
  },
  {
    name: "Kyrrex",
    symbol: "KRRX",
    slug: "kyrrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kyrrex.png",
  },
  {
    name: "Pangolinswap",
    symbol: "PANGOLIN",
    slug: "pangolin-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pangolin-swap.png",
  },
  {
    name: "Apple tokenized stock FTX",
    symbol: "AAPL",
    slug: "apple-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apple-tokenized-stock-ftx.png",
  },
  {
    name: "Hedge Finance",
    symbol: "HEDGE",
    slug: "hedge-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedge-finance.png",
  },
  {
    name: "MeebitsDAO Pool",
    symbol: "MBBT",
    slug: "meebitsdao-pool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meebitsdao-pool.png",
  },
  {
    name: "RatBoy BSC",
    symbol: "WENTXN",
    slug: "ratboy-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ratboy-bsc.png",
  },
  {
    name: "Elpis Battle",
    symbol: "EBA",
    slug: "elpis-battle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elpis-battle.png",
  },
  {
    name: "BitcoinX",
    symbol: "BCX",
    slug: "bitcoinx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinx.png",
  },
  {
    name: "Durham Inu",
    symbol: "RBI",
    slug: "durham-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/durham-inu.png",
  },
  {
    name: "Tractor Joe",
    symbol: "TRACTOR",
    slug: "tractor-joe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tractor-joe.png",
  },
  {
    name: "AnonyDoxx",
    symbol: "ADXX",
    slug: "anonydoxx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anonydoxx.png",
  },
  {
    name: "Lwazi Project",
    symbol: "LWAZI",
    slug: "lwazi-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lwazi-project.png",
  },
  {
    name: "Token TT-Swap",
    symbol: "TTS",
    slug: "token-tt-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/token-tt-swap.png",
  },
  {
    name: "Bombcrypto Coin",
    symbol: "BOMB",
    slug: "bombcrypto-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bombcrypto-coin.png",
  },
  {
    name: "FomoETH",
    symbol: "FomoETH",
    slug: "fomoeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fomoeth.png",
  },
  {
    name: "Tessla Coin",
    symbol: "TSLA",
    slug: "tessla-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tessla-coin.png",
  },
  {
    name: "LunarSwap",
    symbol: "LUNAR",
    slug: "lunarswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunarswap.png",
  },
  {
    name: "REVIVAL",
    symbol: "RVL",
    slug: "revival",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revival.png",
  },
  {
    name: "BODA Token",
    symbol: "BODAV2",
    slug: "boda-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boda-token.png",
  },
  {
    name: "Thunder Run",
    symbol: "THUNDRR",
    slug: "thunder-run",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thunder-run.png",
  },
  {
    name: "Olympus Inu Dao",
    symbol: "OHMINU",
    slug: "olympus-inu-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olympus-inu-dao.png",
  },
  {
    name: "Rebasing Liquidity",
    symbol: "DELTA-RLP",
    slug: "rebasing-liquidity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rebasing-liquidity.png",
  },
  {
    name: "Vigorus",
    symbol: "VIS",
    slug: "vigorus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vigorus.png",
  },
  {
    name: "Farmland Protocol",
    symbol: "FAR",
    slug: "farmland-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmland-protocol.png",
  },
  {
    name: "Catch Up",
    symbol: "CU",
    slug: "catch-up",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catch-up.png",
  },
  {
    name: "Mermaid",
    symbol: "MERD",
    slug: "mermaid-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mermaid-swap.png",
  },
  {
    name: "Rewards Token",
    symbol: "REWARDS",
    slug: "rewards-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rewards-token.png",
  },
  {
    name: "Crazy Internet Coin",
    symbol: "CIC",
    slug: "crazy-internet-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crazy-internet-coin.png",
  },
  {
    name: "StarDust",
    symbol: "SD",
    slug: "stardust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stardust.png",
  },
  {
    name: "Fashion Coin",
    symbol: "FSHN",
    slug: "fashion-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fashion-coin.png",
  },
  {
    name: "Origin Dollar Governance",
    symbol: "OGV",
    slug: "origin-dollar-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/origin-dollar-governance.png",
  },
  {
    name: "ZURRENCY",
    symbol: "ZURR",
    slug: "zurrency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zurrency.png",
  },
  {
    name: "Blastoise Inu",
    symbol: "BLAST",
    slug: "blastoise-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blastoise-inu.png",
  },
  {
    name: "Sekuritance",
    symbol: "SKRT",
    slug: "sekuritance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sekuritance.png",
  },
  {
    name: "Cypherdog Token",
    symbol: "CDOG",
    slug: "cypherdog-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cypherdog-token.png",
  },
  {
    name: "CryptoJetski",
    symbol: "CJET",
    slug: "cryptojetski",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptojetski.png",
  },
  {
    name: "Bitcoin File",
    symbol: "BIFI",
    slug: "bitcoin-file",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-file.png",
  },
  {
    name: "Interest Bearing Defi Pulse Index",
    symbol: "BDPI",
    slug: "interest-bearing-dpi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/interest-bearing-dpi.png",
  },
  {
    name: "Gold Rush Community",
    symbol: "GRUSH",
    slug: "gold-rush-community",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-rush-community.png",
  },
  {
    name: "Omicron",
    symbol: "OMIC",
    slug: "omicron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omicron.png",
  },
  {
    name: "ViCA Token",
    symbol: "VICA",
    slug: "vica-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vica-token.png",
  },
  {
    name: "Bat True Share",
    symbol: "BTS",
    slug: "bat-true-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bat-true-share.png",
  },
  {
    name: "Alethea Artificial Liquid Intelligence Token",
    symbol: "ALI",
    slug: "alethea-artificial-liquid-intelligence-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alethea-artificial-liquid-intelligence-token.png",
  },
  {
    name: "Solster Finance",
    symbol: "STR",
    slug: "solster-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solster-finance.png",
  },
  {
    name: "Bitpaid Token",
    symbol: "BTP",
    slug: "bitpaid-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitpaid-token.png",
  },
  {
    name: "DNFT Protocol",
    symbol: "DNF",
    slug: "dnft-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dnft-protocol.png",
  },
  {
    name: "Cleeps",
    symbol: "CLPS",
    slug: "cleeps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cleeps.png",
  },
  {
    name: "Inari",
    symbol: "INARI",
    slug: "inari",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inari.png",
  },
  {
    name: "MetaShooter",
    symbol: "MHUNT",
    slug: "metashooter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metashooter.png",
  },
  {
    name: "Save Planet Earth",
    symbol: "SPE",
    slug: "save-planet-earth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/save-planet-earth.png",
  },
  {
    name: "MineCrypto",
    symbol: "MCR",
    slug: "minecrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minecrypto.png",
  },
  {
    name: "LavaCake Finance",
    symbol: "LAVA",
    slug: "lavacake-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lavacake-finance.png",
  },
  {
    name: "RichCity",
    symbol: "RICH",
    slug: "richcity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/richcity.png",
  },
  {
    name: "Crypto Cavemen Club",
    symbol: "CAVE",
    slug: "crypto-cavemen-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-cavemen-club.png",
  },
  {
    name: "CANTO",
    symbol: "CANTO",
    slug: "canto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/canto.png",
  },
  {
    name: "Cryptonite (CRT)",
    symbol: "CRT",
    slug: "cryptonite-crt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptonite-crt.png",
  },
  {
    name: "KIKU INU",
    symbol: "KINU",
    slug: "kiku-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiku-inu.png",
  },
  {
    name: "BinaHunter",
    symbol: "BHUNT",
    slug: "binahunter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binahunter.png",
  },
  {
    name: "Crypteriumcoin",
    symbol: "CCOIN",
    slug: "crypteriumcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypteriumcoin.png",
  },
  {
    name: "Credefi",
    symbol: "CREDI",
    slug: "credefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/credefi.png",
  },
  {
    name: "Bitburn",
    symbol: "BURN",
    slug: "bitburn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitburn.png",
  },
  {
    name: "3X Long Cosmos Token",
    symbol: "ATOMBULL",
    slug: "3x-long-cosmos-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-cosmos-token.png",
  },
  {
    name: "PlayPad",
    symbol: "PPAD",
    slug: "playpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playpad.png",
  },
  {
    name: "Kamiland",
    symbol: "KAMI",
    slug: "kamiland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kamiland.png",
  },
  {
    name: "QUAI DAO",
    symbol: "QUAI",
    slug: "quai-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quai-dao.png",
  },
  {
    name: "Monopoly Millionaire Game",
    symbol: "MMG",
    slug: "monopoly-millionaire-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monopoly-millionaire-game.png",
  },
  {
    name: "XSwap Protocol",
    symbol: "XSP",
    slug: "xswap-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xswap-protocol.png",
  },
  {
    name: "Hintchain",
    symbol: "HINT",
    slug: "hintchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hintchain.png",
  },
  {
    name: "Elan",
    symbol: "ELAN",
    slug: "elan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elan.png",
  },
  {
    name: "WATTTON",
    symbol: "WATT",
    slug: "wattton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wattton.png",
  },
  {
    name: "Torum",
    symbol: "XTM",
    slug: "torum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/torum.png",
  },
  {
    name: "Shikage",
    symbol: "SHKG",
    slug: "shikage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shikage.png",
  },
  {
    name: "Chikn Egg",
    symbol: "EGG",
    slug: "chikn-egg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chikn-egg.png",
  },
  {
    name: "Meta Cat",
    symbol: "MCAT",
    slug: "meta-cat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-cat.png",
  },
  {
    name: "POPKON",
    symbol: "POPK",
    slug: "popkon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/popkon.png",
  },
  {
    name: "Artex",
    symbol: "ARTEX",
    slug: "artex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artex.png",
  },
  {
    name: "strong bull",
    symbol: "BULL",
    slug: "strong-bull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strong-bull.png",
  },
  {
    name: "MMPRO Token",
    symbol: "MMPRO",
    slug: "mmpro-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mmpro-token.png",
  },
  {
    name: "StarMiner",
    symbol: "ORE",
    slug: "starminer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starminer.png",
  },
  {
    name: "My Pandaverse",
    symbol: "PANDAVS",
    slug: "my-pandaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-pandaverse.png",
  },
  {
    name: "Karma DAO",
    symbol: "KARMA",
    slug: "karma-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karma-dao.png",
  },
  {
    name: "Bake Coin",
    symbol: "BAKECOIN",
    slug: "bake-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bake-coin.png",
  },
  {
    name: "LIT",
    symbol: "LIT",
    slug: "lit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lit.png",
  },
  {
    name: "VegasDoge",
    symbol: "VEGAS",
    slug: "vegasdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vegasdoge.png",
  },
  {
    name: "CAPTAIN SHIBARROW",
    symbol: "SHIBARROW",
    slug: "captain-shibarrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/captain-shibarrow.png",
  },
  {
    name: "Freela",
    symbol: "FREL",
    slug: "freela",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freela.png",
  },
  {
    name: "SMPCOIN",
    symbol: "SMPC",
    slug: "smpcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smpcoin.png",
  },
  {
    name: "B21 Invest",
    symbol: "B21",
    slug: "b21-invest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/b21-invest.png",
  },
  {
    name: "Eloin",
    symbol: "ELOIN",
    slug: "eloin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eloin.png",
  },
  {
    name: "YFOX FINANCE",
    symbol: "YFOX",
    slug: "yfox-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfox-finance.png",
  },
  {
    name: "CryptoWar xBlade",
    symbol: "XBLADE",
    slug: "cryptowar-xblade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptowar-xblade.png",
  },
  {
    name: "QueenShiba",
    symbol: "QUEEN",
    slug: "queenshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/queenshiba.png",
  },
  {
    name: "Banana Index",
    symbol: "BANDEX",
    slug: "banana-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banana-index.png",
  },
  {
    name: "FlokiGravity",
    symbol: "FLOKIG",
    slug: "flokigravity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokigravity.png",
  },
  {
    name: "TAIYO",
    symbol: "TAIYO",
    slug: "taiyo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taiyo.png",
  },
  {
    name: "Desmos",
    symbol: "DSM",
    slug: "desmos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/desmos.png",
  },
  {
    name: "XXX Anime NFT",
    symbol: "XXXANIME",
    slug: "xxx-anime-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xxx-anime-nft.png",
  },
  {
    name: "Keep3r BSC Network",
    symbol: "KP3RB",
    slug: "keep3r-bsc-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keep3r-bsc-network.png",
  },
  {
    name: "Sugarland",
    symbol: "SUGAR",
    slug: "sugarland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sugarland.png",
  },
  {
    name: "WATCHMEN",
    symbol: "WTM",
    slug: "watchmen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/watchmen.png",
  },
  {
    name: "AlphaDEX",
    symbol: "ROAR",
    slug: "alphadex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alphadex.png",
  },
  {
    name: "PayRue (Propel)",
    symbol: "PROPEL",
    slug: "payrue-propel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payrue-propel.png",
  },
  {
    name: "STEED",
    symbol: "STD",
    slug: "steed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steed.png",
  },
  {
    name: "Starchi",
    symbol: "ELIXIR",
    slug: "starchi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starchi.png",
  },
  {
    name: "GMCoin",
    symbol: "GMCOIN",
    slug: "gmcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gmcoin.png",
  },
  {
    name: "LaunchMyNFT",
    symbol: "MYNFT",
    slug: "launchmynft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/launchmynft.png",
  },
  {
    name: "EFK Token",
    symbol: "EFK",
    slug: "efk-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/efk-token.png",
  },
  {
    name: "ParagonsDAO",
    symbol: "PDT",
    slug: "paragonsdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paragonsdao.png",
  },
  {
    name: "ScarFace Lion",
    symbol: "SFL",
    slug: "scarface-lion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scarface-lion.png",
  },
  {
    name: "ChimpySwap Token",
    symbol: "CHIMPY",
    slug: "chimpyswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chimpyswap-token.png",
  },
  {
    name: "SafeMoonCash",
    symbol: "SAFEMOONCASH",
    slug: "safemooncash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemooncash.png",
  },
  {
    name: "Baby Moon Floki",
    symbol: "FLOKI",
    slug: "baby-moon-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-moon-floki.png",
  },
  {
    name: "Marginswap",
    symbol: "MFI",
    slug: "marginswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marginswap.png",
  },
  {
    name: "New Earth Order Money",
    symbol: "NEOM",
    slug: "new-earth-order-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-earth-order-money.png",
  },
  {
    name: "Battle Saga",
    symbol: "BTL",
    slug: "battlesaga",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battlesaga.png",
  },
  {
    name: "Techpay Coin",
    symbol: "TPC",
    slug: "techpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/techpay.png",
  },
  {
    name: "The Hash Speed",
    symbol: "THS",
    slug: "the-hash-speed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-hash-speed.png",
  },
  {
    name: "Hedron",
    symbol: "HDRN",
    slug: "hedron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedron.png",
  },
  {
    name: "Glasscoin",
    symbol: "GLS",
    slug: "glass-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glass-coin.png",
  },
  {
    name: "Uniris",
    symbol: "UCO",
    slug: "uniris",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniris.png",
  },
  {
    name: "Coinfresh",
    symbol: "CFRESH",
    slug: "coinfresh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinfresh.png",
  },
  {
    name: "ISKRA Token",
    symbol: "ISK",
    slug: "iskra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iskra.png",
  },
  {
    name: "Promodio",
    symbol: "PMD",
    slug: "promodio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/promodio.png",
  },
  {
    name: "Goji Crypto",
    symbol: "HANU",
    slug: "goji-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goji-crypto.png",
  },
  {
    name: "Flare Token",
    symbol: "1FLR",
    slug: "flare-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flare-token.png",
  },
  {
    name: "Fire Token",
    symbol: "FIRE",
    slug: "fire-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fire-token.png",
  },
  {
    name: "Fegnomics",
    symbol: "FEGN",
    slug: "fegnomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fegnomics.png",
  },
  {
    name: "Aki Inu",
    symbol: "AKI",
    slug: "aki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aki-inu.png",
  },
  {
    name: "ForthBox",
    symbol: "FBX",
    slug: "forthbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forthbox.png",
  },
  {
    name: "Pornstar",
    symbol: "STAR",
    slug: "pornstar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pornstar.png",
  },
  {
    name: "PIDAO",
    symbol: "PID",
    slug: "pidao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pidao.png",
  },
  {
    name: "Elvantis",
    symbol: "ELV",
    slug: "elvantis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elvantis.png",
  },
  {
    name: "Dcoin Token",
    symbol: "DT",
    slug: "dcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dcoin-token.png",
  },
  {
    name: "Deku Inu",
    symbol: "DEKU",
    slug: "deku-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deku-inu.png",
  },
  {
    name: "UvToken",
    symbol: "UVT",
    slug: "uvtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uvtoken.png",
  },
  {
    name: "CrossWallet",
    symbol: "CWT",
    slug: "crosswallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crosswallet.png",
  },
  {
    name: "KUY Token",
    symbol: "KUY",
    slug: "kuy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuy-token.png",
  },
  {
    name: "GemDao",
    symbol: "GEMDAO",
    slug: "gemdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gemdao.png",
  },
  {
    name: "Zombie Runner",
    symbol: "ZOMBIE",
    slug: "zombie-runner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zombie-runner.png",
  },
  {
    name: "Galaxy Heroes",
    symbol: "GHC",
    slug: "galaxy-heroes-coin-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-heroes-coin-new.png",
  },
  {
    name: "Beanstalk",
    symbol: "BEAN",
    slug: "beanstalk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beanstalk.png",
  },
  {
    name: "Wrapped CrescoFin",
    symbol: "wCRES",
    slug: "wrapped-crescofin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-crescofin.png",
  },
  {
    name: "FarmerDoge",
    symbol: "CROP",
    slug: "farmerdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmerdoge.png",
  },
  {
    name: "BIG League",
    symbol: "BGLG",
    slug: "big-league",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-league.png",
  },
  {
    name: "The Mask",
    symbol: "DMASK",
    slug: "the-mask",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-mask.png",
  },
  {
    name: "Unvest",
    symbol: "UNV",
    slug: "unvest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unvest.png",
  },
  {
    name: "Flokimooni",
    symbol: "FLOKIM",
    slug: "flokimooni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokimooni.png",
  },
  {
    name: "Zeptagram",
    symbol: "ZPTC",
    slug: "zeptagram",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeptagram.png",
  },
  {
    name: "Doge Racing",
    symbol: "RDOGE",
    slug: "doge-racing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-racing.png",
  },
  {
    name: "FarmHero",
    symbol: "HONOR",
    slug: "farm-hero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farm-hero.png",
  },
  {
    name: "Pi INU",
    symbol: "PINU",
    slug: "pi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pi-inu.png",
  },
  {
    name: "AstroSpaces.io",
    symbol: "SPACES",
    slug: "astrospaces",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astrospaces.png",
  },
  {
    name: "Gravity Finance",
    symbol: "GFI",
    slug: "gravity-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gravity-finance.png",
  },
  {
    name: "Truth Technology",
    symbol: "TRUTH",
    slug: "truth-technology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/truth-technology.png",
  },
  {
    name: "HELLO",
    symbol: "HELLO",
    slug: "hello-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hello-labs.png",
  },
  {
    name: "Spacelens",
    symbol: "SPACE",
    slug: "spacelens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacelens.png",
  },
  {
    name: "Fuma Finance",
    symbol: "FUMA",
    slug: "fuma-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fuma-finance.png",
  },
  {
    name: "Electric Vehicle Direct Currency",
    symbol: "EVDC",
    slug: "electric-vehicle-direct-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electric-vehicle-direct-currency.png",
  },
  {
    name: "MiniBNB",
    symbol: "MINIBNB",
    slug: "minibnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minibnb.png",
  },
  {
    name: "Pikaster",
    symbol: "RBP",
    slug: "pikaster-rbp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pikaster-rbp.png",
  },
  {
    name: "IlliquidDAO",
    symbol: "JPEGS",
    slug: "illiquiddao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/illiquiddao.png",
  },
  {
    name: "Yeld Finance",
    symbol: "YELD",
    slug: "yeld-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yeld-finance.png",
  },
  {
    name: "RedDoge",
    symbol: "REDDOGE",
    slug: "reddoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reddoge.png",
  },
  {
    name: "mCat",
    symbol: "MCAT",
    slug: "mcat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcat.png",
  },
  {
    name: "RoboFi",
    symbol: "VICS",
    slug: "robofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robofi.png",
  },
  {
    name: "DefiSportsCoin",
    symbol: "DSC",
    slug: "defisportscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defisportscoin.png",
  },
  {
    name: "Green Satoshi Token (BSC)",
    symbol: "GST",
    slug: "green-satoshi-token-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-satoshi-token-bsc.png",
  },
  {
    name: "Hoichi",
    symbol: "HOICHI",
    slug: "hoichi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoichi.png",
  },
  {
    name: "Cross Chain Farming",
    symbol: "CCF",
    slug: "cross-chain-farming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cross-chain-farming.png",
  },
  {
    name: "xDAI",
    symbol: "xDAI",
    slug: "xdaistable",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdaistable.png",
  },
  {
    name: "World Cup Token",
    symbol: "WCT",
    slug: "world-cup-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-cup-token.png",
  },
  {
    name: "Kling",
    symbol: "KLING",
    slug: "kling",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kling.png",
  },
  {
    name: "PLUSPAD",
    symbol: "PLUS",
    slug: "pluspad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pluspad.png",
  },
  {
    name: "GigaSwap",
    symbol: "GIGA",
    slug: "gigaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gigaswap.png",
  },
  {
    name: "SafeBTC",
    symbol: "SAFEBTC",
    slug: "safebtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safebtc.png",
  },
  {
    name: "Chronicum",
    symbol: "CHRO",
    slug: "chronicum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chronicum.png",
  },
  {
    name: "Annex Finance",
    symbol: "ANN",
    slug: "annex-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/annex-finance.png",
  },
  {
    name: "Order of the apeverse",
    symbol: "OAV",
    slug: "order-of-the-apeverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/order-of-the-apeverse.png",
  },
  {
    name: "WELD MONEY",
    symbol: "WELD",
    slug: "weld-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weld-money.png",
  },
  {
    name: "Stronger",
    symbol: "STRNGR",
    slug: "stronger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stronger.png",
  },
  {
    name: "Alpaca City",
    symbol: "ALPA",
    slug: "alpaca-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpaca-city.png",
  },
  {
    name: "Connector Coin",
    symbol: "CTCO",
    slug: "connector-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/connector-coin.png",
  },
  {
    name: "Sashimi",
    symbol: "SASHIMI",
    slug: "sashimi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sashimi.png",
  },
  {
    name: "BitBase Token",
    symbol: "BTBS",
    slug: "bitbase-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitbase-token.png",
  },
  {
    name: "CryptoRockets",
    symbol: "CROCKET",
    slug: "cryptorockets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptorockets.png",
  },
  {
    name: "Mogu",
    symbol: "MOGX",
    slug: "mogu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mogu.png",
  },
  {
    name: "FIFA World Cup Fans",
    symbol: "FIFA",
    slug: "fifa-world-cup-fans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fifa-world-cup-fans.png",
  },
  {
    name: "Bend DAO",
    symbol: "BEND",
    slug: "bend-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bend-dao.png",
  },
  {
    name: "pDollar",
    symbol: "PDO",
    slug: "pdollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pdollar.png",
  },
  {
    name: "Walrus",
    symbol: "WLRS",
    slug: "walrus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/walrus.png",
  },
  {
    name: "CoinSwap Space",
    symbol: "CSS",
    slug: "coinswap-space",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinswap-space.png",
  },
  {
    name: "Meter Stable",
    symbol: "MTR",
    slug: "meter-stable",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meter-stable.png",
  },
  {
    name: "MoonX",
    symbol: "MoonX",
    slug: "moonx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonx.png",
  },
  {
    name: "Librium Tech",
    symbol: "LIB",
    slug: "librium-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/librium-tech.png",
  },
  {
    name: "Kodachi Token",
    symbol: "KODACHI",
    slug: "kodachi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kodachi-token.png",
  },
  {
    name: "Zoo Token",
    symbol: "ZOOT",
    slug: "zoo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoo-token.png",
  },
  {
    name: "Childhoods End",
    symbol: "O",
    slug: "childhoods-end",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/childhoods-end.png",
  },
  {
    name: "Banana Coin",
    symbol: "$BANANA",
    slug: "banana-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banana-coin.png",
  },
  {
    name: "Orbitau Taureum",
    symbol: "TAUM",
    slug: "orbitau",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbitau.png",
  },
  {
    name: "Metaverse NFT Index",
    symbol: "PLAY",
    slug: "metaverse-nft-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-nft-index.png",
  },
  {
    name: "DeFido",
    symbol: "DEFIDO",
    slug: "defido",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defido.png",
  },
  {
    name: "Lelouch Lamperouge",
    symbol: "LELOUCH",
    slug: "lelouch-lamperouge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lelouch-lamperouge.png",
  },
  {
    name: "CheemsBillionaire",
    symbol: "CHEEMSBILLIONAIRE",
    slug: "cheemsbillionaire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheemsbillionaire.png",
  },
  {
    name: "Mouse Haunt Token",
    symbol: "MHT",
    slug: "mouse-haunt-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mouse-haunt-token.png",
  },
  {
    name: "3X Long Huobi Token Token",
    symbol: "HTBULL",
    slug: "3x-long-huobi-token-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-huobi-token-token.png",
  },
  {
    name: "StorX Network",
    symbol: "SRX",
    slug: "storx-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/storx-network.png",
  },
  {
    name: "Liquid Collectibles",
    symbol: "LICO",
    slug: "liquid-collectibles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquid-collectibles.png",
  },
  {
    name: "MMACOIN",
    symbol: "MMA",
    slug: "mmacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mmacoin.png",
  },
  {
    name: "NELO Metaverse",
    symbol: "NELO",
    slug: "nelo-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nelo-metaverse.png",
  },
  {
    name: "Hare Token",
    symbol: "HARE",
    slug: "hare-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hare-token.png",
  },
  {
    name: "BURNACE",
    symbol: "ACE",
    slug: "burnace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burnace.png",
  },
  {
    name: "BlueSparrow Token",
    symbol: "BlueSparrow",
    slug: "bluesparrow-token-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bluesparrow-token-new.png",
  },
  {
    name: "BridgeCoin",
    symbol: "BRC",
    slug: "bridge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bridge-coin.png",
  },
  {
    name: "SeedOn",
    symbol: "SEON",
    slug: "seedon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seedon.png",
  },
  {
    name: "Terkehh",
    symbol: "TERK",
    slug: "terkehh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terkehh.png",
  },
  {
    name: "Electrinity",
    symbol: "ELIT",
    slug: "electrinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electrinity.png",
  },
  {
    name: "Buy-Sell",
    symbol: "BSE",
    slug: "buy-sell",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buy-sell.png",
  },
  {
    name: "ZEDXION",
    symbol: "ZEDXION",
    slug: "zedxion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zedxion.png",
  },
  {
    name: "Shiba Shogun",
    symbol: "SHIBAGUN",
    slug: "shiba-shogun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-shogun.png",
  },
  {
    name: "NXD Next",
    symbol: "NXDT",
    slug: "nxd-next",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nxd-next.png",
  },
  {
    name: "Meta World Game",
    symbol: "MTW",
    slug: "meta-world-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-world-game.png",
  },
  {
    name: "Tesla tokenized stock FTX",
    symbol: "TSLA",
    slug: "tesla-tokenized-stock-ftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tesla-tokenized-stock-ftx.png",
  },
  {
    name: "ForeverBNB",
    symbol: "FBNB",
    slug: "foreverbnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foreverbnb.png",
  },
  {
    name: "MemeFlate",
    symbol: "$MFLATE",
    slug: "memeflate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memeflate.png",
  },
  {
    name: "Rhythm",
    symbol: "RHYTHM",
    slug: "rhythm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rhythm.png",
  },
  {
    name: "Peoplez",
    symbol: "LEZ",
    slug: "peoplez",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peoplez.png",
  },
  {
    name: "1Sol",
    symbol: "1SOL",
    slug: "1sol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1sol.png",
  },
  {
    name: "INUCEPTION",
    symbol: "INCEPTION",
    slug: "inuception",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inuception.png",
  },
  {
    name: "EveryApe",
    symbol: "EVAPE",
    slug: "everyape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everyape.png",
  },
  {
    name: "Armor NXM",
    symbol: "arNXM",
    slug: "armor-nxm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/armor-nxm.png",
  },
  {
    name: "KATZ Token",
    symbol: "KATZ",
    slug: "katz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/katz-token.png",
  },
  {
    name: "SandMan",
    symbol: "SANDMAN",
    slug: "sandman",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sandman.png",
  },
  {
    name: "Mimir Token",
    symbol: "MIMIR",
    slug: "mimir-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mimir-token.png",
  },
  {
    name: "TREASURE SEEDS",
    symbol: "SEEDS",
    slug: "treasure-seeds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treasure-seeds.png",
  },
  {
    name: "WardenSwap",
    symbol: "WAD",
    slug: "wardenswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wardenswap.png",
  },
  {
    name: "Soonaverse",
    symbol: "SOON",
    slug: "soonaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soonaverse.png",
  },
  {
    name: "Allbridge",
    symbol: "ABR",
    slug: "allbridge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/allbridge.png",
  },
  {
    name: "Donnie Finance",
    symbol: "DON",
    slug: "donnie-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/donnie-finance.png",
  },
  {
    name: "Math-e-MATIC",
    symbol: "MMATIC",
    slug: "math-e-matic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/math-e-matic.png",
  },
  {
    name: "Mars",
    symbol: "Mars",
    slug: "mars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mars.png",
  },
  {
    name: "CryptoMotorcycle",
    symbol: "CMC",
    slug: "cryptomotorcycle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptomotorcycle.png",
  },
  {
    name: "MiniGame",
    symbol: "MINIGAME",
    slug: "minigame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minigame.png",
  },
  {
    name: "ACA Token",
    symbol: "ACA",
    slug: "aca-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aca-token.png",
  },
  {
    name: "Zyro",
    symbol: "ZYRO",
    slug: "zyro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zyro.png",
  },
  {
    name: "AshSwap",
    symbol: "ASH",
    slug: "ashswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ashswap.png",
  },
  {
    name: "LogiTron",
    symbol: "LTR",
    slug: "logitron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/logitron.png",
  },
  {
    name: "GACUBE",
    symbol: "GAC",
    slug: "gacube",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gacube.png",
  },
  {
    name: "DaFIN",
    symbol: "DAF",
    slug: "dafin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dafin.png",
  },
  {
    name: "Binopoly",
    symbol: "BINO",
    slug: "binopoly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binopoly.png",
  },
  {
    name: "Sovryn",
    symbol: "SOV",
    slug: "sovryn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sovryn.png",
  },
  {
    name: "Ethos Project",
    symbol: "ETHOS",
    slug: "ethos-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethos-project.png",
  },
  {
    name: "Lord Arena",
    symbol: "LORDA",
    slug: "lord-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lord-arena.png",
  },
  {
    name: "GreenTrust",
    symbol: "GNT",
    slug: "greentrust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greentrust.png",
  },
  {
    name: "SMD COIN",
    symbol: "SMD",
    slug: "smd-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smd-coin.png",
  },
  {
    name: "CrowdSwap",
    symbol: "CROWD",
    slug: "crowdswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crowdswap.png",
  },
  {
    name: "FUTURECOIN",
    symbol: "FUTURE",
    slug: "futurecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futurecoin.png",
  },
  {
    name: "HITOP",
    symbol: "HITOP",
    slug: "hitop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hitop.png",
  },
  {
    name: "Wanderlust",
    symbol: "WANDER",
    slug: "wanderlust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanderlust.png",
  },
  {
    name: "RaceFi",
    symbol: "RACEFI",
    slug: "racefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/racefi.png",
  },
  {
    name: "Crypto Holding Frank Token",
    symbol: "CHFT",
    slug: "crypto-holding-frank-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-holding-frank-token.png",
  },
  {
    name: "FOX TOKEN",
    symbol: "FOX",
    slug: "fox-coxswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fox-coxswap.png",
  },
  {
    name: "MuscleX",
    symbol: "M-X",
    slug: "musclex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/musclex.png",
  },
  {
    name: "BNBeanstalk",
    symbol: "BEANS",
    slug: "bnbeanstalk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbeanstalk.png",
  },
  {
    name: "Basis Share",
    symbol: "BAS",
    slug: "basis-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basis-share.png",
  },
  {
    name: "Inferno Pay",
    symbol: "IFO",
    slug: "inferno-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inferno-pay.png",
  },
  {
    name: "Spice DAO",
    symbol: "SPICE",
    slug: "spice-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spice-dao.png",
  },
  {
    name: "LTCDOWN",
    symbol: "LTCDOWN",
    slug: "ltcdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ltcdown.png",
  },
  {
    name: "Soy Finance",
    symbol: "SOY",
    slug: "soy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soy-finance.png",
  },
  {
    name: "VYNK CHAIN",
    symbol: "VYNC",
    slug: "vynk-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vynk-chain.png",
  },
  {
    name: "Elexir",
    symbol: "ELXR",
    slug: "elexir",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elexir.png",
  },
  {
    name: "Relevant",
    symbol: "REL",
    slug: "relevant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/relevant.png",
  },
  {
    name: "APEX Protocol",
    symbol: "APXP",
    slug: "apex-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apex-protocol.png",
  },
  {
    name: "Bunicorn",
    symbol: "BUNI",
    slug: "bunicorn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunicorn.png",
  },
  {
    name: "BabyUSDT",
    symbol: "BABYUSDT",
    slug: "babyusdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyusdt.png",
  },
  {
    name: "P2P Taxi Token",
    symbol: "P2PTXT",
    slug: "p2p-taxi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/p2p-taxi-token.png",
  },
  {
    name: "Useless",
    symbol: "USELESS",
    slug: "useless",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/useless.png",
  },
  {
    name: "AMAUROT",
    symbol: "AMA",
    slug: "amaurot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amaurot.png",
  },
  {
    name: "SpaceN",
    symbol: "SN",
    slug: "spacen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacen.png",
  },
  {
    name: "Meta Apes",
    symbol: "SHELL",
    slug: "meta-apes-shell",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-apes-shell.png",
  },
  {
    name: "Flashloans.com",
    symbol: "FLASH",
    slug: "flashloans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flashloans.png",
  },
  {
    name: "Talkado",
    symbol: "TALK",
    slug: "talkado",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/talkado.png",
  },
  {
    name: "Synchrony",
    symbol: "SCY",
    slug: "synchrony",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synchrony.png",
  },
  {
    name: "JBOX",
    symbol: "JBX",
    slug: "jboxcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jboxcoin.png",
  },
  {
    name: "VIRVIA online shopping",
    symbol: "VDV",
    slug: "virvia-online-shopping",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/virvia-online-shopping.png",
  },
  {
    name: "OLYMPUS",
    symbol: "OLYMPUS",
    slug: "olympus-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olympus-token.png",
  },
  {
    name: "Pyroworld",
    symbol: "PYRO",
    slug: "pyroworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyroworld.png",
  },
  {
    name: "Super Music League (SML)",
    symbol: "SML",
    slug: "super-music-league-sml",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-music-league-sml.png",
  },
  {
    name: "Beach Token",
    symbol: "BEACH",
    slug: "beach-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beach-token.png",
  },
  {
    name: "Monopoly Meta",
    symbol: "MPM",
    slug: "monopoly-meta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monopoly-meta.png",
  },
  {
    name: "Biswap",
    symbol: "BSW",
    slug: "biswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biswap.png",
  },
  {
    name: "Manchester United Fan Token",
    symbol: "MUFC",
    slug: "manchester-united-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manchester-united-fan-token.png",
  },
  {
    name: "Advar Protocol",
    symbol: "ADVAR",
    slug: "advar-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/advar-protocol.png",
  },
  {
    name: "xxxNifty",
    symbol: "NSFW",
    slug: "xxxnifty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xxxnifty.png",
  },
  {
    name: "SIMARGL",
    symbol: "SIMA",
    slug: "simargl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simargl.png",
  },
  {
    name: "QATAR 2022 TOKEN",
    symbol: "FWC",
    slug: "qatar-2022-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qatar-2022-token.png",
  },
  {
    name: "Emcis Network",
    symbol: "EMC1",
    slug: "emcis-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emcis-network.png",
  },
  {
    name: "Star Foxx",
    symbol: "FOXX",
    slug: "star-foxx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/star-foxx.png",
  },
  {
    name: "NFTinder",
    symbol: "NFTNDR",
    slug: "nftinder",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftinder.png",
  },
  {
    name: "Covenant",
    symbol: "COVN",
    slug: "covenant-child",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/covenant-child.png",
  },
  {
    name: "Cub Finance",
    symbol: "CUB",
    slug: "cub-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cub-finance.png",
  },
  {
    name: "MetaBUSDCoin",
    symbol: "MBC",
    slug: "metabusdcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabusdcoin.png",
  },
  {
    name: "MyWorld",
    symbol: "MWT",
    slug: "myworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myworld.png",
  },
  {
    name: "Byepix",
    symbol: "EPIX",
    slug: "byepix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/byepix.png",
  },
  {
    name: "WiBX",
    symbol: "WBX",
    slug: "wibx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wibx.png",
  },
  {
    name: "MultiPad",
    symbol: "MPAD",
    slug: "multipad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multipad.png",
  },
  {
    name: "Creation Energy Join International",
    symbol: "CEJI",
    slug: "creation-energy-join-international",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creation-energy-join-international.png",
  },
  {
    name: "SeamlessSwap",
    symbol: "SEAMLESS",
    slug: "seamlessswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seamlessswap.png",
  },
  {
    name: "Holographic Doge",
    symbol: "HODO",
    slug: "holographic-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holographic-doge.png",
  },
  {
    name: "0xPAD",
    symbol: "0XPAD",
    slug: "0xpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0xpad.png",
  },
  {
    name: "Chronoly",
    symbol: "CRNO",
    slug: "chronoly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chronoly.png",
  },
  {
    name: "Nico Robin Inu",
    symbol: "ROBIN",
    slug: "nico-robin-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nico-robin-inu.png",
  },
  {
    name: "PolkaTrail",
    symbol: "TRAIL",
    slug: "polkatrail",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkatrail.png",
  },
  {
    name: "Farm Planet",
    symbol: "FPL",
    slug: "farm-planet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farm-planet.png",
  },
  {
    name: "RATSCOIN TEAM DAO",
    symbol: "RATSDAO",
    slug: "ratscoin-team-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ratscoin-team-dao.png",
  },
  {
    name: "Zugacoin",
    symbol: "SZC",
    slug: "zugacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zugacoin.png",
  },
  {
    name: "Holdex Finance",
    symbol: "HOLDEX",
    slug: "holdex-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holdex-finance.png",
  },
  {
    name: "Mommy Doge Coin",
    symbol: "MOMMYDOGE",
    slug: "mommy-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mommy-doge-coin.png",
  },
  {
    name: "Cramer Coin",
    symbol: "CRAMER",
    slug: "cramer-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cramer-coin.png",
  },
  {
    name: "BUSTA",
    symbol: "BUST",
    slug: "busta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/busta.png",
  },
  {
    name: "BKING Finance",
    symbol: "BKF",
    slug: "bking-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bking-finance.png",
  },
  {
    name: "Captain Inu",
    symbol: "CPTINU",
    slug: "captain-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/captain-inu.png",
  },
  {
    name: "Kintaman",
    symbol: "KINTA",
    slug: "kintaman",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kintaman.png",
  },
  {
    name: "ETG Finance",
    symbol: "ETGF",
    slug: "etg-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etg-finance.png",
  },
  {
    name: "Icointoo",
    symbol: "ICT",
    slug: "icointoo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icointoo.png",
  },
  {
    name: "Wraith",
    symbol: "WRAITH",
    slug: "wraith",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wraith.png",
  },
  {
    name: "Ginza Eternity",
    symbol: "GER",
    slug: "ginza-eternity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ginza-eternity.png",
  },
  {
    name: "Shield Protocol Token",
    symbol: "SHIELD",
    slug: "shield-protocol-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shield-protocol-token.png",
  },
  {
    name: "Tengu",
    symbol: "TENGU",
    slug: "tengu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tengu.png",
  },
  {
    name: "NFT STARS",
    symbol: "NFTS",
    slug: "nft-stars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-stars.png",
  },
  {
    name: "WIMI",
    symbol: "WIMI",
    slug: "wimi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wimi.png",
  },
  {
    name: "Future Star",
    symbol: "FSTAR",
    slug: "future-star",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/future-star.png",
  },
  {
    name: "Utopia USD",
    symbol: "UUSD",
    slug: "utopia-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utopia-usd.png",
  },
  {
    name: "HoDooi",
    symbol: "HOD",
    slug: "hodooi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodooi.png",
  },
  {
    name: "SafeBlast",
    symbol: "BLAST",
    slug: "safeblast",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeblast.png",
  },
  {
    name: "StaySAFU",
    symbol: "SAFU",
    slug: "staysafu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/staysafu.png",
  },
  {
    name: "Mother of Memes",
    symbol: "MOM",
    slug: "mother-of-memes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mother-of-memes.png",
  },
  {
    name: "Neoteric",
    symbol: "NTRC",
    slug: "neoteric",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neoteric.png",
  },
  {
    name: "Zaigar Finance",
    symbol: "ZAIF",
    slug: "zaigar-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zaigar-finance.png",
  },
  {
    name: "Connect Financial",
    symbol: "CNFI",
    slug: "connect-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/connect-financial.png",
  },
  {
    name: "Coco Swap",
    symbol: "COCO",
    slug: "coco-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coco-swap.png",
  },
  {
    name: "Green Energy Coin",
    symbol: "GEC",
    slug: "green-energy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-energy-coin.png",
  },
  {
    name: "Rocket Venture",
    symbol: "RKTV",
    slug: "rocket-venture",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-venture.png",
  },
  {
    name: "Santa Dash",
    symbol: "SANTADASH",
    slug: "santa-dash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/santa-dash.png",
  },
  {
    name: "BeeKan",
    symbol: "BKBT",
    slug: "beekan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beekan.png",
  },
  {
    name: "DBX Digital Ecosystem",
    symbol: "DBX",
    slug: "dbx-digital-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dbx-digital-ecosystem.png",
  },
  {
    name: "DEUS Finance",
    symbol: "DEUS",
    slug: "deus-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deus-finance.png",
  },
  {
    name: "OnlyCam",
    symbol: "$ONLY",
    slug: "onlycam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onlycam.png",
  },
  {
    name: "Baby Starlink Doge",
    symbol: "STARLINKDOGE",
    slug: "baby-starlink-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-starlink-doge.png",
  },
  {
    name: "SoMee.Social",
    symbol: "SOMEE",
    slug: "somee-social",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/somee-social.png",
  },
  {
    name: "Metagame Arena",
    symbol: "MGA",
    slug: "metagame-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagame-arena.png",
  },
  {
    name: "DoraemonInu",
    symbol: "DORAEMONINU",
    slug: "doraemoninu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doraemoninu.png",
  },
  {
    name: "Black Lemon",
    symbol: "BOM",
    slug: "black-lemon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-lemon.png",
  },
  {
    name: "Rocket Raccoon",
    symbol: "RON",
    slug: "rocket-raccoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-raccoon.png",
  },
  {
    name: "SpaceGrime",
    symbol: "GRIMEX",
    slug: "spacegrime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacegrime.png",
  },
  {
    name: "DEFFECT AI",
    symbol: "DEF",
    slug: "deffect-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deffect-ai.png",
  },
  {
    name: "Defrost Finance",
    symbol: "MELT",
    slug: "defrost-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defrost-finance.png",
  },
  {
    name: "UNCL",
    symbol: "UNCL",
    slug: "uncl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uncl.png",
  },
  {
    name: "Interlude",
    symbol: "ISH",
    slug: "interlude",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/interlude.png",
  },
  {
    name: "Bruce Lee token",
    symbol: "1INCHPUNCH",
    slug: "bruce-lee-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bruce-lee-token.png",
  },
  {
    name: "FRAKT Token",
    symbol: "FRKT",
    slug: "frakt-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frakt-token.png",
  },
  {
    name: "Rapids",
    symbol: "RPD",
    slug: "rapids",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rapids.png",
  },
  {
    name: "Acala Dollar(Karura)",
    symbol: "AUSD",
    slug: "acala-dollar-karura",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acala-dollar-karura.png",
  },
  {
    name: "Zcon Protocol",
    symbol: "ZCON",
    slug: "zcon-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zcon-protocol.png",
  },
  {
    name: "FarmHero",
    symbol: "HERO",
    slug: "farmhero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farmhero.png",
  },
  {
    name: "bloXmove (ERC20)",
    symbol: "BLXM",
    slug: "bloxmove-erc20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bloxmove-erc20.png",
  },
  {
    name: "Mindsync",
    symbol: "MAI",
    slug: "mindsync",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mindsync.png",
  },
  {
    name: "Hermes Shares",
    symbol: "HSHARES",
    slug: "hermes-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hermes-shares.png",
  },
  {
    name: "Open Proprietary Protocol",
    symbol: "OPP",
    slug: "open-proprietary-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/open-proprietary-protocol.png",
  },
  {
    name: "Kevacoin",
    symbol: "KVA",
    slug: "kevacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kevacoin.png",
  },
  {
    name: "Amazy Move Token",
    symbol: "AMT",
    slug: "amazy-move-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amazy-move-token.png",
  },
  {
    name: "Crypto International",
    symbol: "CRI",
    slug: "crypto-international",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-international.png",
  },
  {
    name: "Art Blocks Curated Full Set",
    symbol: "ABC123",
    slug: "art-blocks-curated-full-set",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/art-blocks-curated-full-set.png",
  },
  {
    name: "Jumbo",
    symbol: "JUB",
    slug: "jumbo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jumbo.png",
  },
  {
    name: "Bill Murray Inu",
    symbol: "$BMINU",
    slug: "bill-murray-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bill-murray-inu.png",
  },
  {
    name: "Impermax",
    symbol: "IMX",
    slug: "impermax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/impermax.png",
  },
  {
    name: "Cheese",
    symbol: "CHEESE",
    slug: "cheese",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheese.png",
  },
  {
    name: "Fantasy Fox",
    symbol: "FF",
    slug: "fantasy-fox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasy-fox.png",
  },
  {
    name: "CROWD",
    symbol: "CWD",
    slug: "crowd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crowd.png",
  },
  {
    name: "Eutaria",
    symbol: "EUT",
    slug: "eutaria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eutaria.png",
  },
  {
    name: "Golden Token",
    symbol: "GOLD",
    slug: "golden-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-token.png",
  },
  {
    name: "HALO COIN",
    symbol: "HALO",
    slug: "halo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/halo-coin.png",
  },
  {
    name: "HODL 2.0",
    symbol: "HODL",
    slug: "hodl-2-0",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodl-2-0.png",
  },
  {
    name: "FunFi",
    symbol: "FNF",
    slug: "funfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/funfi.png",
  },
  {
    name: "1NFT",
    symbol: "1NFT",
    slug: "1nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1nft.png",
  },
  {
    name: "3x Short XRP Token",
    symbol: "XRPBEAR",
    slug: "3x-short-xrp-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-xrp-token.png",
  },
  {
    name: "Goons of Balatroon",
    symbol: "GOB",
    slug: "goons-of-balatroon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goons-of-balatroon.png",
  },
  {
    name: "The Phoenix",
    symbol: "FIRE",
    slug: "the-phoenix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-phoenix.png",
  },
  {
    name: "Deesse",
    symbol: "LOVE",
    slug: "deesse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deesse.png",
  },
  {
    name: "Baby DeFido",
    symbol: "BabyDeFido",
    slug: "baby-defido",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-defido.png",
  },
  {
    name: "SafeMoneyBSC",
    symbol: "SAFEMONEY",
    slug: "safemoneybsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemoneybsc.png",
  },
  {
    name: "Lucro",
    symbol: "LCR",
    slug: "lucro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucro.png",
  },
  {
    name: "CryptoGamez",
    symbol: "CGAZ",
    slug: "cryptogamez",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptogamez.png",
  },
  {
    name: "Comodo Coin",
    symbol: "CMD",
    slug: "comodo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comodo-coin.png",
  },
  {
    name: "Memecoin",
    symbol: "MEM",
    slug: "meme-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meme-coin.png",
  },
  {
    name: "Pocket Doge",
    symbol: "PDOGE",
    slug: "pocket-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocket-doge.png",
  },
  {
    name: "MetaVisa Protocol",
    symbol: "MESA",
    slug: "metavisa-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metavisa-protocol.png",
  },
  {
    name: "DEUS Finance DEA",
    symbol: "DEA",
    slug: "deus-finance-dea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deus-finance-dea.png",
  },
  {
    name: "Qatar Inu",
    symbol: "QATAR",
    slug: "qatar-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qatar-inu.png",
  },
  {
    name: "CORN",
    symbol: "CORN",
    slug: "corn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corn.png",
  },
  {
    name: "Protocon",
    symbol: "PEN",
    slug: "protocon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/protocon.png",
  },
  {
    name: "TonCoin",
    symbol: "TONCOIN",
    slug: "toncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toncoin.png",
  },
  {
    name: "MetaGods",
    symbol: "MGOD",
    slug: "metagods",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagods.png",
  },
  {
    name: "WeWay",
    symbol: "WWY",
    slug: "weway",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weway.png",
  },
  {
    name: "Cornucopias",
    symbol: "COPI",
    slug: "cornucopias",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cornucopias.png",
  },
  {
    name: "StarLink",
    symbol: "SLNV2",
    slug: "starlink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starlink.png",
  },
  {
    name: "PolkaPets",
    symbol: "PETS",
    slug: "polkapets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkapets.png",
  },
  {
    name: "Metastocks",
    symbol: "MTSKS",
    slug: "metastocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metastocks.png",
  },
  {
    name: "Pteria",
    symbol: "PTERIA",
    slug: "pteria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pteria.png",
  },
  {
    name: "PolygonFarm Finance",
    symbol: "SPADE",
    slug: "polygonfarm-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polygonfarm-finance.png",
  },
  {
    name: "Solidex",
    symbol: "SEX",
    slug: "solidex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solidex.png",
  },
  {
    name: "Puppies Network",
    symbol: "PPN",
    slug: "puppies-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puppies-network.png",
  },
  {
    name: "ENVOY",
    symbol: "ENV",
    slug: "envoy-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/envoy-network.png",
  },
  {
    name: "FOMO LAB",
    symbol: "FOMO",
    slug: "fomo-lab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fomo-lab.png",
  },
  {
    name: "Floki Pup",
    symbol: "FLOKIPUP",
    slug: "floki-pup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-pup.png",
  },
  {
    name: "RxC",
    symbol: "RXC",
    slug: "rxc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rxc.png",
  },
  {
    name: "BankSocial",
    symbol: "BSOCIAL",
    slug: "banksocial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banksocial.png",
  },
  {
    name: "Ki",
    symbol: "XKI",
    slug: "ki-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ki-foundation.png",
  },
  {
    name: "8Bit Doge",
    symbol: "BITD",
    slug: "8bit-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/8bit-doge.png",
  },
  {
    name: "iNFT Platform",
    symbol: "INFT",
    slug: "inft-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inft-platform.png",
  },
  {
    name: "Futureswap",
    symbol: "FST",
    slug: "futureswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futureswap.png",
  },
  {
    name: "Lotus",
    symbol: "Lotus",
    slug: "lotus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lotus.png",
  },
  {
    name: "MetaUniverse",
    symbol: "METAUNIVERSE",
    slug: "metauniverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metauniverse.png",
  },
  {
    name: "Basis Gold",
    symbol: "BAG",
    slug: "bag",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bag.png",
  },
  {
    name: "Puli Inu",
    symbol: "PULI",
    slug: "puli-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puli-inu.png",
  },
  {
    name: "Samurinu",
    symbol: "SAMINU",
    slug: "samurinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/samurinu.png",
  },
  {
    name: "Hive Dollar",
    symbol: "HBD",
    slug: "hive-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hive-dollar.png",
  },
  {
    name: "FireFlame Inu",
    symbol: "FIRE",
    slug: "fireflame-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fireflame-inu.png",
  },
  {
    name: "3X Long TRX Token",
    symbol: "TRXBULL",
    slug: "3x-long-trx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-trx-token.png",
  },
  {
    name: "DeFi11",
    symbol: "D11",
    slug: "defi11",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi11.png",
  },
  {
    name: "Frz Solar System",
    symbol: "FRZSS",
    slug: "frz-solar-system",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frz-solar-system.png",
  },
  {
    name: "Wula",
    symbol: "WULA",
    slug: "wula",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wula.png",
  },
  {
    name: "Multi-Stake Capital",
    symbol: "MSC",
    slug: "multi-stake-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multi-stake-capital.png",
  },
  {
    name: "Neko Network",
    symbol: "NEKO",
    slug: "neko-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neko-network.png",
  },
  {
    name: "Royal Protocol",
    symbol: "ROY",
    slug: "royal-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/royal-protocol.png",
  },
  {
    name: "Cryptotaxis Token",
    symbol: "CTAX",
    slug: "cryptotaxis-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptotaxis-token.png",
  },
  {
    name: "SafeGalaxy",
    symbol: "SAFEGALAXY",
    slug: "safegalaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safegalaxy.png",
  },
  {
    name: "Crystal Dust",
    symbol: "CSD",
    slug: "crystal-dust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crystal-dust.png",
  },
  {
    name: "MAST",
    symbol: "MAST",
    slug: "mast",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mast.png",
  },
  {
    name: "Direwolf",
    symbol: "DIREWOLF",
    slug: "direwolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/direwolf.png",
  },
  {
    name: "Biblecoin",
    symbol: "BIBL",
    slug: "biblecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biblecoin.png",
  },
  {
    name: "Polysage",
    symbol: "SAGE",
    slug: "polysage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polysage.png",
  },
  {
    name: "GreenMoonZilla",
    symbol: "GRMZilla",
    slug: "greenmoonzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenmoonzilla.png",
  },
  {
    name: "ADAM",
    symbol: "ADAM",
    slug: "adam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adam.png",
  },
  {
    name: "Wrapped Moonbeam",
    symbol: "WGLMR",
    slug: "wrapped-moonbeam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-moonbeam.png",
  },
  {
    name: "Stater",
    symbol: "STR",
    slug: "stater",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stater.png",
  },
  {
    name: "ShibaJump",
    symbol: "SHIBJUMP",
    slug: "shibajump",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibajump.png",
  },
  {
    name: "Saint Inu",
    symbol: "SAINT",
    slug: "saint-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saint-inu.png",
  },
  {
    name: "IDall",
    symbol: "IDALL",
    slug: "idall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idall.png",
  },
  {
    name: "Frontrow",
    symbol: "FRR",
    slug: "frontrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frontrow.png",
  },
  {
    name: "Koreadoge",
    symbol: "KDOGE",
    slug: "koreadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koreadoge.png",
  },
  {
    name: "Swop",
    symbol: "SWOP",
    slug: "swop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swop.png",
  },
  {
    name: "Power Nodes",
    symbol: "POWER",
    slug: "power-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/power-nodes.png",
  },
  {
    name: "MetaNet",
    symbol: "MNET",
    slug: "metanet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metanet.png",
  },
  {
    name: "Peakmines PEAK",
    symbol: "PEAK",
    slug: "peakmines-peak",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peakmines-peak.png",
  },
  {
    name: "Nova finance",
    symbol: "NOVA",
    slug: "nova-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nova-finance.png",
  },
  {
    name: "Covid Token",
    symbol: "COVIDTOKEN",
    slug: "covid-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/covid-token.png",
  },
  {
    name: "PL^Gnet",
    symbol: "PLUG",
    slug: "plgnet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plgnet.png",
  },
  {
    name: "EHash",
    symbol: "EHASH",
    slug: "ehash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ehash.png",
  },
  {
    name: "Omni Cash",
    symbol: "OCA$H",
    slug: "omni-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omni-cash.png",
  },
  {
    name: "AlvareNet",
    symbol: "ALVN",
    slug: "alvarenet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alvarenet.png",
  },
  {
    name: "Epanus",
    symbol: "EPS",
    slug: "epanus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/epanus.png",
  },
  {
    name: "MetaNFT",
    symbol: "MNFT",
    slug: "metanft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metanft.png",
  },
  {
    name: "ShibaNova",
    symbol: "NOVA",
    slug: "shibanova",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibanova.png",
  },
  {
    name: "Siren",
    symbol: "SI",
    slug: "siren",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/siren.png",
  },
  {
    name: "SafuYield Protocol",
    symbol: "SAFUYIELD",
    slug: "safuyield-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safuyield-protocol.png",
  },
  {
    name: "ROCKI",
    symbol: "ROCKS",
    slug: "rocki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocki.png",
  },
  {
    name: "Dosa",
    symbol: "$DOSA",
    slug: "dosa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dosa.png",
  },
  {
    name: "Jack Token",
    symbol: "JACK",
    slug: "jack-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jack-token.png",
  },
  {
    name: "TAHU",
    symbol: "TAHU",
    slug: "tahu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tahu.png",
  },
  {
    name: "Doxxed Santa",
    symbol: "DXSANTA",
    slug: "doxxed-santa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doxxed-santa.png",
  },
  {
    name: "Covid Slice",
    symbol: "COVID19",
    slug: "covid-slice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/covid-slice.png",
  },
  {
    name: "MiamiCoin",
    symbol: "MIA",
    slug: "miamicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miamicoin.png",
  },
  {
    name: "Bonfire",
    symbol: "BONFIRE",
    slug: "bonfire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonfire.png",
  },
  {
    name: "3air",
    symbol: "3AIR",
    slug: "3air",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3air.png",
  },
  {
    name: "Stader",
    symbol: "SD",
    slug: "stader",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stader.png",
  },
  {
    name: "MarsRise",
    symbol: "MARSRISE",
    slug: "marsrise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marsrise.png",
  },
  {
    name: "Bitcoin Bam",
    symbol: "BTCBAM",
    slug: "bitcoin-bam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-bam.png",
  },
  {
    name: "Acquire.Fi",
    symbol: "ACQ",
    slug: "acquire-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acquire-fi.png",
  },
  {
    name: "World of Cryptoids",
    symbol: "CAC",
    slug: "world-of-cryptoids",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-of-cryptoids.png",
  },
  {
    name: "BlockBank",
    symbol: "BBANK",
    slug: "blockbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockbank.png",
  },
  {
    name: "KRoot",
    symbol: "KROOT",
    slug: "k-root",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/k-root.png",
  },
  {
    name: "GScarab",
    symbol: "GSCARAB",
    slug: "gscarab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gscarab.png",
  },
  {
    name: "Little Angry Bunny v2",
    symbol: "LAB V2",
    slug: "little-angry-bunny-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/little-angry-bunny-v2.png",
  },
  {
    name: "ByteEx",
    symbol: "BX",
    slug: "byteex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/byteex.png",
  },
  {
    name: "STING",
    symbol: "STN",
    slug: "sting",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sting.png",
  },
  {
    name: "Witcher Inu",
    symbol: "WINU",
    slug: "witcher-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/witcher-inu.png",
  },
  {
    name: "Libero Financial",
    symbol: "LIBERO",
    slug: "libero-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libero-financial.png",
  },
  {
    name: "Ten Best Coins",
    symbol: "TBC",
    slug: "ten-best-coins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ten-best-coins.png",
  },
  {
    name: "Paybswap",
    symbol: "PAYB",
    slug: "paybswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paybswap.png",
  },
  {
    name: "Contribute DAO",
    symbol: "TDAO",
    slug: "contribute-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/contribute-dao.png",
  },
  {
    name: "GameFi Shiba",
    symbol: "GAMEFI",
    slug: "gamefi-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamefi-shiba.png",
  },
  {
    name: "GazeTV",
    symbol: "GAZE",
    slug: "gazetv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gazetv.png",
  },
  {
    name: "OHO",
    symbol: "OHO",
    slug: "oho",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oho.png",
  },
  {
    name: "DoneSwap",
    symbol: "DO",
    slug: "doneswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doneswap.png",
  },
  {
    name: "CrownSterling",
    symbol: "WCSOV",
    slug: "crownsterling",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crownsterling.png",
  },
  {
    name: "Atomic Token",
    symbol: "ATMC",
    slug: "atomic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atomic-token.png",
  },
  {
    name: "Xrpalike Gene",
    symbol: "XAG",
    slug: "xrpalike-gene",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrpalike-gene.png",
  },
  {
    name: "Billion Token",
    symbol: "BLL",
    slug: "billion-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/billion-token.png",
  },
  {
    name: "Bonus Cake",
    symbol: "BonusCake",
    slug: "bonus-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonus-cake.png",
  },
  {
    name: "GranX Chain",
    symbol: "GRANX",
    slug: "cranx-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cranx-chain.png",
  },
  {
    name: "ENTERMETAWORLD",
    symbol: "ENTERMETA",
    slug: "entermetaworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/entermetaworld.png",
  },
  {
    name: "Whole Network",
    symbol: "NODE",
    slug: "whole-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whole-network.png",
  },
  {
    name: "Angle",
    symbol: "ANGLE",
    slug: "angle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/angle.png",
  },
  {
    name: "CropperFinance",
    symbol: "CRP",
    slug: "cropperfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cropperfinance.png",
  },
  {
    name: "MonstaVerse",
    symbol: "MONSTR",
    slug: "monstaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monstaverse.png",
  },
  {
    name: "Grape Network",
    symbol: "GRAPE",
    slug: "grape-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grape-network.png",
  },
  {
    name: "World Mobile Token",
    symbol: "WMT",
    slug: "world-mobile-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-mobile-token.png",
  },
  {
    name: "We Save Moon",
    symbol: "SAVEMOON",
    slug: "we-save-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/we-save-moon.png",
  },
  {
    name: "Polkaswap",
    symbol: "PSWAP",
    slug: "polkaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkaswap.png",
  },
  {
    name: "Quickswap[New]",
    symbol: "QUICK",
    slug: "quickswap-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quickswap-new.png",
  },
  {
    name: "ChainSwaps",
    symbol: "CHAIN",
    slug: "chainswaps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainswaps.png",
  },
  {
    name: "BUILD Finance",
    symbol: "BUILD",
    slug: "build-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/build-finance.png",
  },
  {
    name: "Lendefi (new)",
    symbol: "LDFI",
    slug: "lendefi-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lendefi-new.png",
  },
  {
    name: "Chiwawa",
    symbol: "CHIWA",
    slug: "chiwawa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chiwawa.png",
  },
  {
    name: "Starbots",
    symbol: "BOT",
    slug: "starbots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starbots.png",
  },
  {
    name: "dFuture",
    symbol: "DFT",
    slug: "dfuture",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfuture.png",
  },
  {
    name: "FIT Token",
    symbol: "FIT",
    slug: "calo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calo.png",
  },
  {
    name: "Real Realm",
    symbol: "REAL",
    slug: "real-realm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/real-realm.png",
  },
  {
    name: "Powpad",
    symbol: "PP",
    slug: "powpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/powpad.png",
  },
  {
    name: "MilkyWayEx",
    symbol: "MILKY",
    slug: "milkywayex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milkywayex.png",
  },
  {
    name: "BNB Superheroes",
    symbol: "BSH",
    slug: "bnb-superheroes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnb-superheroes.png",
  },
  {
    name: "Trice",
    symbol: "TRI",
    slug: "trice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trice.png",
  },
  {
    name: "Nyan Finance",
    symbol: "NYAN",
    slug: "nyan-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nyan-finance.png",
  },
  {
    name: "Kill Doge",
    symbol: "KILLDOGE",
    slug: "kill-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kill-doge.png",
  },
  {
    name: "BOT",
    symbol: "BOT",
    slug: "bot-planet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bot-planet.png",
  },
  {
    name: "Sugar Cardano",
    symbol: "DADA",
    slug: "sugar-cardano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sugar-cardano.png",
  },
  {
    name: "XDEFI Wallet",
    symbol: "XDEFI",
    slug: "xdefi-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdefi-wallet.png",
  },
  {
    name: "FastSwap (BSC)",
    symbol: "FAST",
    slug: "fastswap-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fastswap-bsc.png",
  },
  {
    name: "VeUSD",
    symbol: "VEUSD",
    slug: "veusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veusd.png",
  },
  {
    name: "ShibaCash",
    symbol: "SHIBACASH",
    slug: "shibacash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibacash.png",
  },
  {
    name: "BikeN",
    symbol: "BKN",
    slug: "biken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biken.png",
  },
  {
    name: "ParaInu",
    symbol: "PARAINU",
    slug: "parainu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parainu.png",
  },
  {
    name: "J9CASINO",
    symbol: "J9BC",
    slug: "j9casino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/j9casino.png",
  },
  {
    name: "microNFT",
    symbol: "MNT",
    slug: "micronft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/micronft.png",
  },
  {
    name: "Onooks",
    symbol: "OOKS",
    slug: "onooks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onooks.png",
  },
  {
    name: "TemplarDAO",
    symbol: "TEM",
    slug: "templardao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/templardao.png",
  },
  {
    name: "Pastel",
    symbol: "PSL",
    slug: "pastel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pastel.png",
  },
  {
    name: "Shaman King Inu",
    symbol: "SHAMAN",
    slug: "shaman-king-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shaman-king-inu.png",
  },
  {
    name: "Football INU",
    symbol: "FOOTBALL",
    slug: "football-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/football-inu.png",
  },
  {
    name: "HeroDoge",
    symbol: "HERODOGE",
    slug: "herodoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herodoge.png",
  },
  {
    name: "PulseDoge",
    symbol: "PULSEDOGE",
    slug: "pulsedoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pulsedoge.png",
  },
  {
    name: "AngryFloki",
    symbol: "$AFLOKI",
    slug: "angryfloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/angryfloki.png",
  },
  {
    name: "KRYZA Network",
    symbol: "KRN",
    slug: "kryza-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kryza-network.png",
  },
  {
    name: "Shibaken Finance",
    symbol: "SHIBAKEN",
    slug: "shibaken-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibaken-finance.png",
  },
  {
    name: "Revolver Token",
    symbol: "$RVLVR",
    slug: "revolver-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revolver-token.png",
  },
  {
    name: "HoneyBee",
    symbol: "BEE",
    slug: "honeybee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honeybee.png",
  },
  {
    name: "Magic beasties",
    symbol: "BSTS",
    slug: "magic-beasties",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-beasties.png",
  },
  {
    name: "Goldblock",
    symbol: "GBK",
    slug: "goldblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldblock.png",
  },
  {
    name: "Dink Doink",
    symbol: "DINK",
    slug: "dink-doink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dink-doink.png",
  },
  {
    name: "Wrapped DucatusX",
    symbol: "WDUCX",
    slug: "wrapped-ducatusx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-ducatusx.png",
  },
  {
    name: "Ndau",
    symbol: "NDAU",
    slug: "ndau",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ndau.png",
  },
  {
    name: "Gym Network",
    symbol: "GYMNET",
    slug: "gym-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gym-network.png",
  },
  {
    name: "LEMONCHAIN",
    symbol: "LEMC",
    slug: "lemonchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lemonchain.png",
  },
  {
    name: "Lucky Block (V2)",
    symbol: "LBLOCK",
    slug: "lucky-block-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-block-v2.png",
  },
  {
    name: "The Rocks Game",
    symbol: "THEROCKS",
    slug: "the-rocks-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-rocks-game.png",
  },
  {
    name: "Moneta",
    symbol: "MON",
    slug: "defi-franc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-franc.png",
  },
  {
    name: "Chiku Inu",
    symbol: "CHIKUINU",
    slug: "chiku-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chiku-inu.png",
  },
  {
    name: "ASAN VERSE",
    symbol: "ASAN",
    slug: "asan-verse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asan-verse.png",
  },
  {
    name: "Monster",
    symbol: "MST",
    slug: "monster-metaland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-metaland.png",
  },
  {
    name: "IFToken",
    symbol: "IFT",
    slug: "iftoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iftoken.png",
  },
  {
    name: "RailNode",
    symbol: "TRAIN",
    slug: "railnode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/railnode.png",
  },
  {
    name: "Blocks Space",
    symbol: "BLS",
    slug: "blockspace-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockspace-token.png",
  },
  {
    name: "DeVolution",
    symbol: "DEVO",
    slug: "devolution",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/devolution.png",
  },
  {
    name: "Final Frontier",
    symbol: "FRNT",
    slug: "final-frontier",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/final-frontier.png",
  },
  {
    name: "Black Diamond Rating",
    symbol: "HZT",
    slug: "black-diamond-rating",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-diamond-rating.png",
  },
  {
    name: "Xenoverse Crypto",
    symbol: "XENOX",
    slug: "xenoverse-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xenoverse-crypto.png",
  },
  {
    name: "Lumenswap",
    symbol: "LSP",
    slug: "lumenswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lumenswap.png",
  },
  {
    name: "Babybnb",
    symbol: "BABYBNB",
    slug: "babybnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babybnb.png",
  },
  {
    name: "Twelve Legions",
    symbol: "CTL",
    slug: "twelve-legions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twelve-legions.png",
  },
  {
    name: "The Three Kingdoms",
    symbol: "CHI",
    slug: "the-three-kingdoms-chi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-three-kingdoms-chi.png",
  },
  {
    name: "Bananatok",
    symbol: "BNA",
    slug: "bananatok",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bananatok.png",
  },
  {
    name: "PolyBeta Finance",
    symbol: "BETA",
    slug: "polybeta-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polybeta-finance.png",
  },
  {
    name: "Coin To Fish",
    symbol: "CTFT",
    slug: "coin-to-fish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-to-fish.png",
  },
  {
    name: "Ninja Fantasy Token",
    symbol: "NFS",
    slug: "ninja-fantasy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninja-fantasy-token.png",
  },
  {
    name: "MOONGAME",
    symbol: "MGT",
    slug: "moongame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moongame.png",
  },
  {
    name: "Carbon Seed",
    symbol: "CARBON",
    slug: "carbon-seed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbon-seed.png",
  },
  {
    name: "Gogeta Inu",
    symbol: "GOGETA",
    slug: "gogeta-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gogeta-inu.png",
  },
  {
    name: "Mytrade",
    symbol: "MYT",
    slug: "mytrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mytrade.png",
  },
  {
    name: "Wirtual",
    symbol: "WIRTUAL",
    slug: "wirtual",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wirtual.png",
  },
  {
    name: "1eco",
    symbol: "1ECO",
    slug: "1eco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1eco.png",
  },
  {
    name: "Pledge",
    symbol: "PLGR",
    slug: "pledge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pledge.png",
  },
  {
    name: "Republic of Dogs",
    symbol: "ROD",
    slug: "republic-of-dogs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/republic-of-dogs.png",
  },
  {
    name: "TEAL",
    symbol: "TEAT",
    slug: "teal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teal.png",
  },
  {
    name: "Bouje Token",
    symbol: "BOUJE",
    slug: "bouje-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bouje-token.png",
  },
  {
    name: "Themis",
    symbol: "MIS",
    slug: "themis-oracle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/themis-oracle.png",
  },
  {
    name: "Scouthub",
    symbol: "HUB",
    slug: "scouthub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scouthub.png",
  },
  {
    name: "Ninneko (Mata)",
    symbol: "MATA",
    slug: "ninneko-mata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninneko-mata.png",
  },
  {
    name: "Money Tree",
    symbol: "MONEY",
    slug: "money-tree",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/money-tree.png",
  },
  {
    name: "Baby Fantom",
    symbol: "BFTM",
    slug: "baby-fantom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-fantom.png",
  },
  {
    name: "Five7",
    symbol: "F7",
    slug: "five7",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/five7.png",
  },
  {
    name: "Mercor Finance",
    symbol: "MRCR",
    slug: "mercor-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mercor-finance.png",
  },
  {
    name: "Revuto",
    symbol: "REVU",
    slug: "revuto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revuto.png",
  },
  {
    name: "Waterfall DeFi",
    symbol: "WTF",
    slug: "waterfall-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waterfall-defi.png",
  },
  {
    name: "3X Long Theta Network Token",
    symbol: "THETABULL",
    slug: "3x-long-theta-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-theta-network-token.png",
  },
  {
    name: "PartyBoard",
    symbol: "PAB(BSC)",
    slug: "partyboard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/partyboard.png",
  },
  {
    name: "METOO Token",
    symbol: "METOO",
    slug: "metoo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metoo-token.png",
  },
  {
    name: "Little Bunny Rocket",
    symbol: "LBR",
    slug: "little-bunny-rocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/little-bunny-rocket.png",
  },
  {
    name: "FileStar",
    symbol: "STAR",
    slug: "filestar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/filestar.png",
  },
  {
    name: "Naughtydoge",
    symbol: "NDoge",
    slug: "naughtydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/naughtydoge.png",
  },
  {
    name: "GenshinShibInu",
    symbol: "GSHIB",
    slug: "genshinshibinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genshinshibinu.png",
  },
  {
    name: "Piggy Bank Token",
    symbol: "PIGGY",
    slug: "piggy-bank-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piggy-bank-token.png",
  },
  {
    name: "THORWallet",
    symbol: "TGT",
    slug: "thorwallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thorwallet.png",
  },
  {
    name: "Gummy Beans",
    symbol: "GUMMIE",
    slug: "gummy-beans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gummy-beans.png",
  },
  {
    name: "Bagels Finance",
    symbol: "BAGEL",
    slug: "bagels-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bagels-finance.png",
  },
  {
    name: "Itchiro Games",
    symbol: "JEM",
    slug: "itchiro-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/itchiro-games.png",
  },
  {
    name: "USD+",
    symbol: "USD+",
    slug: "usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usd.png",
  },
  {
    name: "3X Long DeFi Index Token",
    symbol: "DEFIBULL",
    slug: "3x-long-defi-index-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-defi-index-token.png",
  },
  {
    name: "Kulupu",
    symbol: "KLP",
    slug: "kulupu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kulupu.png",
  },
  {
    name: "All Coins Yield Capital",
    symbol: "ACYC",
    slug: "all-coins-yield-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/all-coins-yield-capital.png",
  },
  {
    name: "Calaswap",
    symbol: "CLS",
    slug: "calaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calaswap.png",
  },
  {
    name: "Findora",
    symbol: "FRA",
    slug: "findora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/findora.png",
  },
  {
    name: "SAVE CARDANO",
    symbol: "SADA",
    slug: "save-cardano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/save-cardano.png",
  },
  {
    name: "SwinCoin",
    symbol: "SWIN",
    slug: "swincoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swincoin.png",
  },
  {
    name: "Dragon Crypto Argenti",
    symbol: "DCAR",
    slug: "dragon-crypto-argenti",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-crypto-argenti.png",
  },
  {
    name: "PAWGcoin",
    symbol: "$PAWG",
    slug: "pawgcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pawgcoin.png",
  },
  {
    name: "Arcade Kingdoms",
    symbol: "ACK",
    slug: "arcade-kingdoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcade-kingdoms.png",
  },
  {
    name: "JOHNNY DOGE",
    symbol: "GRILLZ",
    slug: "johnny-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/johnny-doge.png",
  },
  {
    name: "Persia",
    symbol: "PERSIA",
    slug: "persia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/persia.png",
  },
  {
    name: "Skeb Coin",
    symbol: "SKEB",
    slug: "skeb-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skeb-coin.png",
  },
  {
    name: "Baby Cuban",
    symbol: "BABYCUBAN",
    slug: "baby-cuban",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-cuban.png",
  },
  {
    name: "FUFU",
    symbol: "FUFU",
    slug: "fufu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fufu.png",
  },
  {
    name: "LatteSwap",
    symbol: "LATTE",
    slug: "latteswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/latteswap.png",
  },
  {
    name: "Moonsta's Revenge",
    symbol: "MTR",
    slug: "moonstas-revenge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonstas-revenge.png",
  },
  {
    name: "AEN Smart Token",
    symbol: "AENS",
    slug: "aen-smart-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aen-smart-token.png",
  },
  {
    name: "Zabu Finance",
    symbol: "ZABU",
    slug: "zabu-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zabu-finance.png",
  },
  {
    name: "CardanoEvo",
    symbol: "CEVO",
    slug: "cardanoevo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cardanoevo.png",
  },
  {
    name: "Cyber Crystal",
    symbol: "CRYSTAL",
    slug: "cyber-crystal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyber-crystal.png",
  },
  {
    name: "Fibswap DEx",
    symbol: "FIBO",
    slug: "fibswap-dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fibswap-dex.png",
  },
  {
    name: "Mirrored Google",
    symbol: "mGOOGL",
    slug: "mirrored-google",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-google.png",
  },
  {
    name: "POLARNODES",
    symbol: "POLAR",
    slug: "polar-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polar-finance.png",
  },
  {
    name: "KwikTrust",
    symbol: "KTX",
    slug: "kwiktrust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kwiktrust.png",
  },
  {
    name: "SENATE DAO",
    symbol: "SENATE",
    slug: "sidus-heroes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sidus-heroes.png",
  },
  {
    name: "AngelHeart Token",
    symbol: "AHT",
    slug: "angelheart-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/angelheart-token.png",
  },
  {
    name: "Shih Tzu",
    symbol: "SHIH",
    slug: "shih-tzu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shih-tzu.png",
  },
  {
    name: "H3RO3S",
    symbol: "H3RO3S",
    slug: "h3ro3s",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/h3ro3s.png",
  },
  {
    name: "NanoByte Token",
    symbol: "NBT",
    slug: "nanobyte-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nanobyte-token.png",
  },
  {
    name: "DogeWarrior",
    symbol: "DWR",
    slug: "dogewarrior",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogewarrior.png",
  },
  {
    name: "Mobipad",
    symbol: "MBP",
    slug: "mobipad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobipad.png",
  },
  {
    name: "Sheep Game",
    symbol: "AWOOL",
    slug: "sheep-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sheep-game.png",
  },
  {
    name: "NFT Champions",
    symbol: "CHAMP",
    slug: "nft-champions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-champions.png",
  },
  {
    name: "Etherlite",
    symbol: "ETL",
    slug: "etherlite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherlite.png",
  },
  {
    name: "Shardus",
    symbol: "ULT",
    slug: "shardus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shardus.png",
  },
  {
    name: "xx network",
    symbol: "XX",
    slug: "xx-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xx-network.png",
  },
  {
    name: "Big Digital Shares",
    symbol: "BDS",
    slug: "big-digital-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-digital-shares.png",
  },
  {
    name: "Ponyo-Inu",
    symbol: "PONYO",
    slug: "ponyo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ponyo-inu.png",
  },
  {
    name: "Robot Wars",
    symbol: "WAR",
    slug: "robot-wars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robot-wars.png",
  },
  {
    name: "Naxar",
    symbol: "NAXAR",
    slug: "naxar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/naxar.png",
  },
  {
    name: "Soldex",
    symbol: "SOLX",
    slug: "soldex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soldex.png",
  },
  {
    name: "Andromeda",
    symbol: "M31",
    slug: "andromeda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/andromeda.png",
  },
  {
    name: "Astra Guild Ventures",
    symbol: "AGV",
    slug: "astra-guild-ventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astra-guild-ventures.png",
  },
  {
    name: "ShibaCorgi",
    symbol: "SHICO",
    slug: "shibacorgi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibacorgi.png",
  },
  {
    name: "3X Long Cardano Token",
    symbol: "ADABULL",
    slug: "3x-long-cardano-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-cardano-token.png",
  },
  {
    name: "PumpETH",
    symbol: "PETH",
    slug: "pumpeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pumpeth.png",
  },
  {
    name: "Kingaru",
    symbol: "KRU",
    slug: "kingaru",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingaru.png",
  },
  {
    name: "Crypto Bike Riders",
    symbol: "RIDERS",
    slug: "crypto-bike-riders",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-bike-riders.png",
  },
  {
    name: "DogePepsi",
    symbol: "DOGEPEPSI",
    slug: "doge-pepsi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-pepsi.png",
  },
  {
    name: "Launchpad TG DAO 3.0",
    symbol: "TGDAO",
    slug: "launchpad-tg-dao-3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/launchpad-tg-dao-3.png",
  },
  {
    name: "SpacePort Coin",
    symbol: "SPC",
    slug: "spaceport-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spaceport-coin.png",
  },
  {
    name: "STARK CHAIN",
    symbol: "STARK",
    slug: "stark-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stark-chain.png",
  },
  {
    name: "DEEPSPACE",
    symbol: "DPS",
    slug: "deepspace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deepspace.png",
  },
  {
    name: "Astro Gold",
    symbol: "ASTROGOLD",
    slug: "astro-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astro-gold.png",
  },
  {
    name: "Sakura Neko",
    symbol: "NEKO",
    slug: "sakura-neko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sakura-neko.png",
  },
  {
    name: "Steam Exchange",
    symbol: "STEAMX",
    slug: "steam-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steam-exchange.png",
  },
  {
    name: "Outer Ring MMO (GQ)",
    symbol: "GQ",
    slug: "outer-ring-mmo-gq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/outer-ring-mmo-gq.png",
  },
  {
    name: "WallStreetBets DApp",
    symbol: "WSB",
    slug: "wallstreetbets-dapp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wallstreetbets-dapp.png",
  },
  {
    name: "CREAMPYE",
    symbol: "PYE",
    slug: "creampye",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creampye.png",
  },
  {
    name: "Gaj Finance",
    symbol: "GAJ",
    slug: "gaj-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gaj-finance.png",
  },
  {
    name: "Red Inu",
    symbol: "REDINU",
    slug: "red-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/red-inu.png",
  },
  {
    name: "The HUSL",
    symbol: "HUSL",
    slug: "the-husl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-husl.png",
  },
  {
    name: "Financial Intelligence Group",
    symbol: "ATBFIG",
    slug: "financial-intelligence-group",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/financial-intelligence-group.png",
  },
  {
    name: "ZILLION AAKAR XO",
    symbol: "ZILLIONXO",
    slug: "zillion-aakar-xo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zillion-aakar-xo.png",
  },
  {
    name: "RagnarokDAO",
    symbol: "RGK",
    slug: "ragnarokdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ragnarokdao.png",
  },
  {
    name: "SpiderDAO",
    symbol: "SPDR",
    slug: "spiderdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spiderdao.png",
  },
  {
    name: "BLOKPAD",
    symbol: "BPAD",
    slug: "blokpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blokpad.png",
  },
  {
    name: "Foxi Equilibrium",
    symbol: "FOXI",
    slug: "foxi-equilibrium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foxi-equilibrium.png",
  },
  {
    name: "RunNode",
    symbol: "RUN",
    slug: "runnode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/runnode.png",
  },
  {
    name: "AquaGoat.Finance",
    symbol: "AQUAGOAT",
    slug: "aquagoat-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aquagoat-finance.png",
  },
  {
    name: "FolkWarriors",
    symbol: "FOLK",
    slug: "folkwarriors",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/folkwarriors.png",
  },
  {
    name: "INTERSTELLAR DOMAIN ORDER",
    symbol: "IDO",
    slug: "interstellar-domain-order",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/interstellar-domain-order.png",
  },
  {
    name: "Staked Olympus",
    symbol: "SOHM",
    slug: "staked-olympus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/staked-olympus.png",
  },
  {
    name: "Position Exchange",
    symbol: "POSI",
    slug: "position-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/position-exchange.png",
  },
  {
    name: "Yfi.mobi",
    symbol: "YFIM",
    slug: "yfi-mobi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfi-mobi.png",
  },
  {
    name: "Elon's Marvin",
    symbol: "MARVIN",
    slug: "elons-marvin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elons-marvin.png",
  },
  {
    name: "BabyArmy",
    symbol: "BARMY",
    slug: "babyarmy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyarmy.png",
  },
  {
    name: "Boss Token",
    symbol: "BOSS",
    slug: "boss-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boss-token.png",
  },
  {
    name: "Elements Game",
    symbol: "ELM",
    slug: "elements-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elements-game.png",
  },
  {
    name: "4ART Coin",
    symbol: "4ART",
    slug: "4artechnologies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/4artechnologies.png",
  },
  {
    name: "Hakuryu",
    symbol: "$RYU",
    slug: "haku-ryu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/haku-ryu.png",
  },
  {
    name: "WingStep",
    symbol: "WST",
    slug: "wingstep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wingstep.png",
  },
  {
    name: "WeiUp",
    symbol: "WEIUP",
    slug: "weiup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weiup.png",
  },
  {
    name: "Brig Finance",
    symbol: "BRIG",
    slug: "brig-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brig-finance.png",
  },
  {
    name: "MyToken",
    symbol: "MT",
    slug: "mytoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mytoken.png",
  },
  {
    name: "xWIN Finance",
    symbol: "XWIN",
    slug: "xwin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xwin-finance.png",
  },
  {
    name: "Avaware",
    symbol: "AVE",
    slug: "avaware",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaware.png",
  },
  {
    name: "CELEBPLUS",
    symbol: "CELEB",
    slug: "celebplus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celebplus.png",
  },
  {
    name: "GalaxyGoggle DAO",
    symbol: "GG",
    slug: "galaxygoggle-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxygoggle-dao.png",
  },
  {
    name: "ETH Shiba",
    symbol: "ETHSHIB",
    slug: "eth-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-shiba.png",
  },
  {
    name: "NFTeez",
    symbol: "NFTEEZ",
    slug: "nfteez",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nfteez.png",
  },
  {
    name: "Arker",
    symbol: "ARKER",
    slug: "arker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arker.png",
  },
  {
    name: "BabyDoge ETH",
    symbol: "BABYDOGE",
    slug: "babydoge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babydoge-coin.png",
  },
  {
    name: "BankEth",
    symbol: "BANKETH",
    slug: "banketh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banketh.png",
  },
  {
    name: "Polygonum Online",
    symbol: "POG",
    slug: "polygonum-online",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polygonum-online.png",
  },
  {
    name: "EpicHero 3D NFT",
    symbol: "EPICHERO",
    slug: "epichero-3d-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/epichero-3d-nft.png",
  },
  {
    name: "Gold Retriever",
    symbol: "GLDN",
    slug: "gold-retriever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-retriever.png",
  },
  {
    name: "OBORTECH",
    symbol: "OBOT",
    slug: "obortech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/obortech.png",
  },
  {
    name: "DaddyUSDT",
    symbol: "DADDYUSDT",
    slug: "daddyusdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddyusdt.png",
  },
  {
    name: "MosterIsland",
    symbol: "MI",
    slug: "mosterisland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mosterisland.png",
  },
  {
    name: "Hamster",
    symbol: "HAM",
    slug: "hamster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hamster.png",
  },
  {
    name: "ByteDex",
    symbol: "BEXT",
    slug: "bytedex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bytedex.png",
  },
  {
    name: "FastSwap",
    symbol: "FAST",
    slug: "fastswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fastswap.png",
  },
  {
    name: "Shiboshi",
    symbol: "SHIBOSHI",
    slug: "shiboshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiboshi.png",
  },
  {
    name: "Mini Avalanche",
    symbol: "MAVAX",
    slug: "mini-avalanche",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-avalanche.png",
  },
  {
    name: "Octane Finance",
    symbol: "OCTANE",
    slug: "octane-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octane-finance.png",
  },
  {
    name: "Best Fintech Investment Coin",
    symbol: "BFIC",
    slug: "best-fintech-investment-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/best-fintech-investment-coin.png",
  },
  {
    name: "Buffer Finance",
    symbol: "IBFR",
    slug: "buffer-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buffer-finance.png",
  },
  {
    name: "Comet Nodes",
    symbol: "COMET",
    slug: "comet-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comet-nodes.png",
  },
  {
    name: "BuildUp",
    symbol: "BUP",
    slug: "buildup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buildup.png",
  },
  {
    name: "FIA Protocol",
    symbol: "FIA",
    slug: "fia-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fia-protocol.png",
  },
  {
    name: "Crystal Of Dragon",
    symbol: "COD",
    slug: "crystal-of-dragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crystal-of-dragon.png",
  },
  {
    name: "BiLira",
    symbol: "TRYB",
    slug: "bilira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bilira.png",
  },
  {
    name: "SocialNFT Marketplace",
    symbol: "SNFT",
    slug: "socialnft-marketplace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/socialnft-marketplace.png",
  },
  {
    name: "Blin Metaverse",
    symbol: "BLIN",
    slug: "blin-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blin-metaverse.png",
  },
  {
    name: "YoHero",
    symbol: "YO",
    slug: "yohero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yohero.png",
  },
  {
    name: "Black Diamond",
    symbol: "DIAMONDS",
    slug: "black-diamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-diamond.png",
  },
  {
    name: "Falcon 9",
    symbol: "F9",
    slug: "falcon-9",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/falcon-9.png",
  },
  {
    name: "Stay In Destiny World",
    symbol: "SIW",
    slug: "stay-in-destiny-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stay-in-destiny-world.png",
  },
  {
    name: "NFT Global",
    symbol: "NFTG",
    slug: "nft-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-global.png",
  },
  {
    name: "Vision Metaverse",
    symbol: "VS",
    slug: "vision-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vision-metaverse.png",
  },
  {
    name: "Somax",
    symbol: "SMX",
    slug: "somax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/somax.png",
  },
  {
    name: "Reltime",
    symbol: "RTC",
    slug: "reltime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reltime.png",
  },
  {
    name: "Boring Protocol",
    symbol: "BOP",
    slug: "boring-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boring-protocol.png",
  },
  {
    name: "Mineral",
    symbol: "MNR",
    slug: "mineral",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mineral.png",
  },
  {
    name: "SunglassesDoge",
    symbol: "SUNGLASSESDOGE",
    slug: "sunglassesdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sunglassesdoge.png",
  },
  {
    name: "Tiger22",
    symbol: "Tiger22",
    slug: "tiger22",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiger22.png",
  },
  {
    name: "Pitbull",
    symbol: "PIT",
    slug: "pitbull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pitbull.png",
  },
  {
    name: "Lucky Fun Games",
    symbol: "LFG",
    slug: "lucky-fun-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-fun-games.png",
  },
  {
    name: "WORLD CUP INU",
    symbol: "WCI",
    slug: "world-cup-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-cup-inu.png",
  },
  {
    name: "One Cash",
    symbol: "ONC",
    slug: "one-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-cash.png",
  },
  {
    name: "StepWatch",
    symbol: "SWP",
    slug: "stepwatch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stepwatch.png",
  },
  {
    name: "Pirate X Pirate",
    symbol: "PXP",
    slug: "pirate-x-pirate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pirate-x-pirate.png",
  },
  {
    name: "Tectonic",
    symbol: "TONIC",
    slug: "tectonic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tectonic.png",
  },
  {
    name: "Magic Manor",
    symbol: "MGC",
    slug: "magic-manor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-manor.png",
  },
  {
    name: "Happy Land",
    symbol: "HPL",
    slug: "happy-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/happy-land.png",
  },
  {
    name: "Crypto Media Network",
    symbol: "CMN",
    slug: "crypto-media-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-media-network.png",
  },
  {
    name: "Swapz",
    symbol: "SWAPZ",
    slug: "swapz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapz.png",
  },
  {
    name: "Elongrab",
    symbol: "ELONGRAB",
    slug: "elongrab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elongrab.png",
  },
  {
    name: "Euro Shiba Inu",
    symbol: "ESHIB",
    slug: "euro-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/euro-shiba-inu.png",
  },
  {
    name: "Ethereum Wrapped Filecoin",
    symbol: "EFIL",
    slug: "ethereum-wrapped-filecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-wrapped-filecoin.png",
  },
  {
    name: "Invitoken",
    symbol: "INVI",
    slug: "invitoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/invitoken.png",
  },
  {
    name: "SUP",
    symbol: "SUP",
    slug: "sup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sup.png",
  },
  {
    name: "Wolf Game Wool",
    symbol: "WOOL",
    slug: "wolf-game-wool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolf-game-wool.png",
  },
  {
    name: "Hero Blaze: Three Kingdoms",
    symbol: "MUDOL2",
    slug: "hero-blaze-three-kingdoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hero-blaze-three-kingdoms.png",
  },
  {
    name: "Magic Cake",
    symbol: "MAGICCAKE",
    slug: "magic-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-cake.png",
  },
  {
    name: "Ms Moona Rewards",
    symbol: "MOONA",
    slug: "ms-moona-rewards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ms-moona-rewards.png",
  },
  {
    name: "Temtum",
    symbol: "TEM",
    slug: "temtum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/temtum.png",
  },
  {
    name: "Okex Fly",
    symbol: "OKFLY",
    slug: "okex-fly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okex-fly.png",
  },
  {
    name: "BEUROP",
    symbol: "BEPR",
    slug: "beurop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beurop.png",
  },
  {
    name: "Betswap.gg",
    symbol: "BSGG",
    slug: "betswap-gg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betswap-gg.png",
  },
  {
    name: "Laser Eyes",
    symbol: "LASEREYES",
    slug: "laser-eyes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laser-eyes.png",
  },
  {
    name: "Himo World",
    symbol: "HIMO",
    slug: "himo-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/himo-world.png",
  },
  {
    name: "Jenny Metaverse DAO Token",
    symbol: "UJENNY",
    slug: "jenny-metaverse-dao-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jenny-metaverse-dao-token.png",
  },
  {
    name: "3X Long Ethereum Classic Token",
    symbol: "ETCBULL",
    slug: "3x-long-ethereum-classic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-ethereum-classic-token.png",
  },
  {
    name: "Inubis",
    symbol: "INUBIS",
    slug: "inubis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inubis.png",
  },
  {
    name: "Coin Discovery",
    symbol: "$CODI",
    slug: "coin-discovery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-discovery.png",
  },
  {
    name: "MetaCat",
    symbol: "METACAT",
    slug: "metacat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metacat.png",
  },
  {
    name: "Dogmoon",
    symbol: "DOGMOON",
    slug: "dogmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogmoon.png",
  },
  {
    name: "Ride2Earn",
    symbol: "RDN",
    slug: "ride2earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ride2earn.png",
  },
  {
    name: "GrafSound",
    symbol: "GSMT",
    slug: "grafsound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grafsound.png",
  },
  {
    name: "Cindrum",
    symbol: "CIND",
    slug: "cindrum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cindrum.png",
  },
  {
    name: "Loki Variants Fan",
    symbol: "VARIANTS",
    slug: "loki-variants-fan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loki-variants-fan.png",
  },
  {
    name: "MeetPle",
    symbol: "MPT",
    slug: "meetple",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meetple.png",
  },
  {
    name: "GemUni",
    symbol: "GENI",
    slug: "gemuni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gemuni.png",
  },
  {
    name: "Wonderful Memories",
    symbol: "WMEMO",
    slug: "wrapped-memory",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-memory.png",
  },
  {
    name: "CatBread",
    symbol: "CATBREAD",
    slug: "catbread",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catbread.png",
  },
  {
    name: "Crypto Legions Bloodstone",
    symbol: "BLST",
    slug: "crypto-legions-bloodstone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-legions-bloodstone.png",
  },
  {
    name: "Floki Marvin",
    symbol: "PALS",
    slug: "floki-marvin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-marvin.png",
  },
  {
    name: "YAMv2",
    symbol: "YAMV2",
    slug: "yam-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yam-v2.png",
  },
  {
    name: "Froyo Games",
    symbol: "FROYO",
    slug: "froyo-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/froyo-games.png",
  },
  {
    name: "ethArt",
    symbol: "ARTE",
    slug: "ethart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethart.png",
  },
  {
    name: "Mason Token",
    symbol: "MASON",
    slug: "mason-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mason-token.png",
  },
  {
    name: "Ariadne",
    symbol: "ARDN",
    slug: "ariadne",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ariadne.png",
  },
  {
    name: "Welups Blockchain",
    symbol: "WELUPS",
    slug: "welups-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/welups-blockchain.png",
  },
  {
    name: "Community Token",
    symbol: "COMT",
    slug: "community-token-comt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/community-token-comt.png",
  },
  {
    name: "REBIT",
    symbol: "KEYT",
    slug: "rebit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rebit.png",
  },
  {
    name: "Lyca Island",
    symbol: "LYCA",
    slug: "lyca-island",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lyca-island.png",
  },
  {
    name: "PixelVerse",
    symbol: "PIXEL",
    slug: "pixelverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixelverse.png",
  },
  {
    name: "Nami Inu",
    symbol: "NAMI",
    slug: "nami-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nami-inu.png",
  },
  {
    name: "Y-5 FINANCE",
    symbol: "Y-5",
    slug: "y-5-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/y-5-finance.png",
  },
  {
    name: "Sincere Doge",
    symbol: "SDOGE",
    slug: "sincere-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sincere-doge.png",
  },
  {
    name: "RiceFarm Token",
    symbol: "RICE",
    slug: "ricefarm-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ricefarm-token.png",
  },
  {
    name: "DiamondShiba",
    symbol: "DS$",
    slug: "diamond-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-shiba.png",
  },
  {
    name: "Mars Token",
    symbol: "MRST",
    slug: "mars-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mars-token.png",
  },
  {
    name: "MeconCash",
    symbol: "MCH",
    slug: "meconcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meconcash.png",
  },
  {
    name: "NuNet",
    symbol: "NTX",
    slug: "nunet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nunet.png",
  },
  {
    name: "Concordium",
    symbol: "CCD",
    slug: "concordium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/concordium.png",
  },
  {
    name: "Botto",
    symbol: "BOTTO",
    slug: "botto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/botto.png",
  },
  {
    name: "Metaxy",
    symbol: "MXY",
    slug: "metaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaxy.png",
  },
  {
    name: "Horde Token",
    symbol: "$HORDE",
    slug: "horde-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/horde-token.png",
  },
  {
    name: "Zero Exchange",
    symbol: "ZERO",
    slug: "zero-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zero-exchange.png",
  },
  {
    name: "VIMworld",
    symbol: "VEED",
    slug: "vimworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vimworld.png",
  },
  {
    name: "The People's Coin",
    symbol: "PEEPS",
    slug: "the-peoples-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-peoples-coin.png",
  },
  {
    name: "SMART MONEY COIN",
    symbol: "SMC",
    slug: "smart-money-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-money-coin.png",
  },
  {
    name: "BitAU",
    symbol: "BAU",
    slug: "bitau",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitau.png",
  },
  {
    name: "Shinji Inu",
    symbol: "SHINJI",
    slug: "shinji-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shinji-inu.png",
  },
  {
    name: "SAFESUN",
    symbol: "SAFESUN",
    slug: "safesun-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safesun-crypto.png",
  },
  {
    name: "Italian National Football Team Fan Token",
    symbol: "ITA",
    slug: "italian-national-football-team-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/italian-national-football-team-fan-token.png",
  },
  {
    name: "Spintop",
    symbol: "SPIN",
    slug: "spintop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spintop.png",
  },
  {
    name: "Spring Game",
    symbol: "SPR",
    slug: "spring-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spring-game.png",
  },
  {
    name: "Bright Union",
    symbol: "BRIGHT",
    slug: "bright-union",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bright-union.png",
  },
  {
    name: "Lanceria",
    symbol: "LANC",
    slug: "lanceria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lanceria.png",
  },
  {
    name: "The Luxury",
    symbol: "TLX",
    slug: "the-luxury",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-luxury.png",
  },
  {
    name: "Mini Cardano",
    symbol: "MADA",
    slug: "mini-cardano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-cardano.png",
  },
  {
    name: "Catverse",
    symbol: "CATVERSE",
    slug: "catverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catverse.png",
  },
  {
    name: "New World Order",
    symbol: "STATE",
    slug: "new-world-order",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-world-order.png",
  },
  {
    name: "The Luxury Coin",
    symbol: "TLB",
    slug: "the-luxury-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-luxury-coin.png",
  },
  {
    name: "Kitty Inu",
    symbol: "KITTY",
    slug: "kitty-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitty-inu.png",
  },
  {
    name: "RISQ Protocol",
    symbol: "RISQ",
    slug: "risq-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/risq-protocol.png",
  },
  {
    name: "Galaxy Fight Club",
    symbol: "GCOIN",
    slug: "galaxy-fight-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-fight-club.png",
  },
  {
    name: "The Big Five Token",
    symbol: "BFT",
    slug: "the-big-five-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-big-five-token.png",
  },
  {
    name: "Edain",
    symbol: "EAI",
    slug: "edain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edain.png",
  },
  {
    name: "Xenon Play",
    symbol: "XPLAY",
    slug: "xenon-play",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xenon-play.png",
  },
  {
    name: "GreenMoon",
    symbol: "GRM",
    slug: "greenmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenmoon.png",
  },
  {
    name: "One Basis Cash",
    symbol: "OBS",
    slug: "one-basis-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-basis-cash.png",
  },
  {
    name: "Upper Swiss Franc",
    symbol: "CHFU",
    slug: "upper-swiss-franc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upper-swiss-franc.png",
  },
  {
    name: "PolyGod",
    symbol: "GULL",
    slug: "polygod",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polygod.png",
  },
  {
    name: "SafeCap Token",
    symbol: "SFC",
    slug: "safecap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safecap-token.png",
  },
  {
    name: "Joker Coin",
    symbol: "JOKER",
    slug: "jokercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jokercoin.png",
  },
  {
    name: "Astro Verse",
    symbol: "ASV",
    slug: "astro-verse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astro-verse.png",
  },
  {
    name: "Boom Shiba",
    symbol: "BOOMSHIBA",
    slug: "boom-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boom-shiba.png",
  },
  {
    name: "PRIVATEUM INITIATIVE",
    symbol: "PVM",
    slug: "privateum-initiative",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/privateum-initiative.png",
  },
  {
    name: "EFT.finance",
    symbol: "EFT",
    slug: "eft-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eft-finance.png",
  },
  {
    name: "Ichigo Inu",
    symbol: "ICHIGO",
    slug: "ichigo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ichigo-inu.png",
  },
  {
    name: "CasinoXMetaverse",
    symbol: "$CASIO",
    slug: "casinoxmetaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/casinoxmetaverse.png",
  },
  {
    name: "ECOx",
    symbol: "ECOX",
    slug: "ecox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecox.png",
  },
  {
    name: "Mind Music",
    symbol: "MND",
    slug: "mind-music",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mind-music.png",
  },
  {
    name: "Proof Of Memes",
    symbol: "ETH2.0",
    slug: "proof-of-memes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proof-of-memes.png",
  },
  {
    name: "Nobility",
    symbol: "NBL",
    slug: "nobility",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nobility.png",
  },
  {
    name: "MaidCoin",
    symbol: "$MAID",
    slug: "maidcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maidcoin.png",
  },
  {
    name: "Rose Finance",
    symbol: "ROF",
    slug: "rose-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rose-finance.png",
  },
  {
    name: "Save Ralph",
    symbol: "RALPH",
    slug: "save-ralph",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/save-ralph.png",
  },
  {
    name: "MarketMove",
    symbol: "MOVE",
    slug: "marketmove",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marketmove.png",
  },
  {
    name: "DAppNode",
    symbol: "NODE",
    slug: "dappnode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dappnode.png",
  },
  {
    name: "MetaDog Racing",
    symbol: "DOG$",
    slug: "metadog-racing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadog-racing.png",
  },
  {
    name: "SonarWatch",
    symbol: "SONAR",
    slug: "sonarwatch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonarwatch.png",
  },
  {
    name: "Elonomics",
    symbol: "$ELONOM",
    slug: "elonomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elonomics.png",
  },
  {
    name: "MixTrust",
    symbol: "MXT",
    slug: "mixtrust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mixtrust.png",
  },
  {
    name: "SnowCrash Token",
    symbol: "NORA",
    slug: "snowcrash-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowcrash-token.png",
  },
  {
    name: "Angel Dust",
    symbol: "AD",
    slug: "angel-dust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/angel-dust.png",
  },
  {
    name: "French Connection Finance",
    symbol: "FCF",
    slug: "french-connection-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/french-connection-finance.png",
  },
  {
    name: "INOFI",
    symbol: "FON",
    slug: "inofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inofi.png",
  },
  {
    name: "VIIIDA Gold",
    symbol: "AUV",
    slug: "viiida-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viiida-gold.png",
  },
  {
    name: "Reach DAO",
    symbol: "$READ",
    slug: "reach-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reach-dao.png",
  },
  {
    name: "3X Short Chainlink Token",
    symbol: "LINKBEAR",
    slug: "3x-short-chainlink-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-chainlink-token.png",
  },
  {
    name: "Keys Token",
    symbol: "KEYS",
    slug: "keys-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keys-token.png",
  },
  {
    name: "Frax Price Index Share",
    symbol: "FPIS",
    slug: "frax-price-index-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frax-price-index-share.png",
  },
  {
    name: "EscoinToken",
    symbol: "ELG",
    slug: "escointoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/escointoken.png",
  },
  {
    name: "VATICAN FINANCE",
    symbol: "VAT",
    slug: "vatican-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vatican-finance.png",
  },
  {
    name: "Khalifa Finance",
    symbol: "KHALIFA",
    slug: "khalifa-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/khalifa-finance.png",
  },
  {
    name: "ELEF  WORLD",
    symbol: "ELEF",
    slug: "elef--world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elef--world.png",
  },
  {
    name: "Everest Token",
    symbol: "EVRT",
    slug: "everest-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everest-token.png",
  },
  {
    name: "EtherBack",
    symbol: "ETHBACK",
    slug: "etherback",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherback.png",
  },
  {
    name: "Double Bubble",
    symbol: "DBUBBLE",
    slug: "double-bubble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/double-bubble.png",
  },
  {
    name: "Shiba Finance",
    symbol: "SHIBAFI",
    slug: "shiba-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-finance.png",
  },
  {
    name: "Baby Saitama Inu",
    symbol: "BABYSAITAMA",
    slug: "baby-saitama-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-saitama-inu.png",
  },
  {
    name: "DekBox",
    symbol: "DEK",
    slug: "dekbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dekbox.png",
  },
  {
    name: "CareCoin",
    symbol: "CARES",
    slug: "carecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carecoin.png",
  },
  {
    name: "Concern Poverty Chain",
    symbol: "CHY",
    slug: "concern-poverty-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/concern-poverty-chain.png",
  },
  {
    name: "Kyoko",
    symbol: "KYOKO",
    slug: "kyoko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kyoko.png",
  },
  {
    name: "RickMortyDoxx",
    symbol: "RICKMORTYDOXX",
    slug: "rickmortydoxx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rickmortydoxx.png",
  },
  {
    name: "Sleep Future",
    symbol: "SLEEPEE",
    slug: "sleep-future",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sleep-future.png",
  },
  {
    name: "Matrixswap",
    symbol: "MATRIX",
    slug: "matrixswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrixswap.png",
  },
  {
    name: "Klima DAO",
    symbol: "KLIMA",
    slug: "klima-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klima-dao.png",
  },
  {
    name: "Dinoland",
    symbol: "DNL",
    slug: "dinoland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinoland.png",
  },
  {
    name: "BULLS",
    symbol: "BULLS",
    slug: "bulls",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulls.png",
  },
  {
    name: "Prelax",
    symbol: "PEA",
    slug: "prelax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prelax.png",
  },
  {
    name: "Pera Finance",
    symbol: "PERA",
    slug: "pera-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pera-finance.png",
  },
  {
    name: "Meta Knight",
    symbol: "METAKNIGHT",
    slug: "meta-knight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-knight.png",
  },
  {
    name: "Pink Shiba Inu",
    symbol: "PINKINU",
    slug: "pink-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pink-shiba-inu.png",
  },
  {
    name: "CrazyTime",
    symbol: "CRAZYTIME",
    slug: "crazytime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crazytime.png",
  },
  {
    name: "Zeropay Finance",
    symbol: "ZEROPAY",
    slug: "zeropay-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeropay-finance.png",
  },
  {
    name: "Bzzone",
    symbol: "BZZONE",
    slug: "bzzone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bzzone.png",
  },
  {
    name: "Corgidoge real estate payment",
    symbol: "CORGI",
    slug: "corgidoge-real-estate-payment",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corgidoge-real-estate-payment.png",
  },
  {
    name: "Envelop",
    symbol: "NIFTSY",
    slug: "envelop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/envelop.png",
  },
  {
    name: "MRHB DeFi",
    symbol: "MRHB",
    slug: "marhabadefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marhabadefi.png",
  },
  {
    name: "Kawai Shiba",
    symbol: "KSHIBA",
    slug: "kawai-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kawai-shiba.png",
  },
  {
    name: "MiniSportZilla",
    symbol: "MINISPORTZ",
    slug: "minisportzilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minisportzilla.png",
  },
  {
    name: "Nobunaga Token, NBNG",
    symbol: "NBNG",
    slug: "nobunaga-token-nbng",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nobunaga-token-nbng.png",
  },
  {
    name: "Fidlecoin",
    symbol: "FIDLE",
    slug: "fidlecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fidlecoin.png",
  },
  {
    name: "OrcaDAO",
    symbol: "ORCA",
    slug: "orcadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orcadao.png",
  },
  {
    name: "Squid Game",
    symbol: "SQUID",
    slug: "squid-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squid-game.png",
  },
  {
    name: "MetaElfLand",
    symbol: "MELT",
    slug: "metaelfland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaelfland.png",
  },
  {
    name: "CryptoCart",
    symbol: "CC",
    slug: "cryptocart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptocart.png",
  },
  {
    name: "DogeWhisky",
    symbol: "DOGEWHISKY",
    slug: "dogewhisky",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogewhisky.png",
  },
  {
    name: "Life DAO",
    symbol: "LF",
    slug: "life-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/life-dao.png",
  },
  {
    name: "Mensa Protocol",
    symbol: "MENSA",
    slug: "mensa-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mensa-protocol.png",
  },
  {
    name: "Dragon Mainland Shards",
    symbol: "DMS",
    slug: "dragon-mainland-shards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-mainland-shards.png",
  },
  {
    name: "Sienna (ERC20)",
    symbol: "wSIENNA",
    slug: "sienna-erc20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sienna-erc20.png",
  },
  {
    name: "Comdex",
    symbol: "CMDX",
    slug: "comdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comdex.png",
  },
  {
    name: "Dogenomics",
    symbol: "$DOGEN",
    slug: "dogenomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogenomics.png",
  },
  {
    name: "Coffin Finance",
    symbol: "COFFIN",
    slug: "coffin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coffin-finance.png",
  },
  {
    name: "Safe Star",
    symbol: "SAFESTAR",
    slug: "safe-star",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-star.png",
  },
  {
    name: "SUCCESS INU",
    symbol: "SUCCESS",
    slug: "success-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/success-inu.png",
  },
  {
    name: "BoozeDoge",
    symbol: "BODO",
    slug: "boozedoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boozedoge.png",
  },
  {
    name: "WhenLambo",
    symbol: "WHENLAMBO",
    slug: "whenlambo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whenlambo.png",
  },
  {
    name: "SUPERLAUNCH",
    symbol: "SLA",
    slug: "superlaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superlaunch.png",
  },
  {
    name: "Gaming Stars",
    symbol: "GAMES",
    slug: "gaming-stars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gaming-stars.png",
  },
  {
    name: "Didcoin",
    symbol: "DID",
    slug: "didcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/didcoin.png",
  },
  {
    name: "Trustpad",
    symbol: "TPAD",
    slug: "trustpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustpad.png",
  },
  {
    name: "Based Finance",
    symbol: "BASED",
    slug: "based-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/based-finance.png",
  },
  {
    name: "STAT",
    symbol: "STAT",
    slug: "stat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stat.png",
  },
  {
    name: "DFOhub",
    symbol: "BUIDL",
    slug: "dfohub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfohub.png",
  },
  {
    name: "BabyKitty",
    symbol: "BABYKITTY",
    slug: "babykitty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babykitty.png",
  },
  {
    name: "Imperium Empires",
    symbol: "IME",
    slug: "imperium-empires",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imperium-empires.png",
  },
  {
    name: "ExchangeCoin",
    symbol: "EXCC",
    slug: "exchange-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exchange-coin.png",
  },
  {
    name: "Abell Coin",
    symbol: "ABC",
    slug: "abell-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abell-coin.png",
  },
  {
    name: "Edufex",
    symbol: "EDUX",
    slug: "edufex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edufex.png",
  },
  {
    name: "Polystarter.net",
    symbol: "POLR",
    slug: "polystarter-net",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polystarter-net.png",
  },
  {
    name: "Gains Network",
    symbol: "GNS",
    slug: "gains-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gains-network.png",
  },
  {
    name: "BNB Cash",
    symbol: "BNBCH",
    slug: "bnb-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnb-cash.png",
  },
  {
    name: "Ekta",
    symbol: "EKTA",
    slug: "ekta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ekta.png",
  },
  {
    name: "Maximizer",
    symbol: "MAXI",
    slug: "maximizer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maximizer.png",
  },
  {
    name: "BSocial",
    symbol: "BINS",
    slug: "bsocial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsocial.png",
  },
  {
    name: "Parma Token",
    symbol: "PARMA",
    slug: "parma-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parma-token.png",
  },
  {
    name: "Alpha5",
    symbol: "A5T",
    slug: "alpha5",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha5.png",
  },
  {
    name: "P2P Solutions foundation",
    symbol: "P2PS",
    slug: "p2p-solutions-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/p2p-solutions-foundation.png",
  },
  {
    name: "MarvinInu",
    symbol: "MARVIN",
    slug: "marvininu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marvininu.png",
  },
  {
    name: "OptionPanda",
    symbol: "OPA",
    slug: "option-panda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/option-panda.png",
  },
  {
    name: "CoShi Inu",
    symbol: "COSHI",
    slug: "coshi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coshi-inu.png",
  },
  {
    name: "Bomb Money",
    symbol: "BOMB",
    slug: "bomb-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bomb-money.png",
  },
  {
    name: "ULAND",
    symbol: "ULAND",
    slug: "uland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uland.png",
  },
  {
    name: "StrikeCoin",
    symbol: "STRIKE",
    slug: "strikecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strikecoin.png",
  },
  {
    name: "Identity",
    symbol: "IDTT",
    slug: "identity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/identity.png",
  },
  {
    name: "Frozen Walrus Share",
    symbol: "WSHARE",
    slug: "frozen-walrus-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frozen-walrus-share.png",
  },
  {
    name: "BeerusCat",
    symbol: "BCAT",
    slug: "beeruscat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beeruscat.png",
  },
  {
    name: "Game Coin",
    symbol: "GMEX",
    slug: "game-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game-coin.png",
  },
  {
    name: "DOJO Coin",
    symbol: "DOJO COIN",
    slug: "dojo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dojo-coin.png",
  },
  {
    name: "Graviton Zero",
    symbol: "GRAV",
    slug: "graviton-zero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/graviton-zero.png",
  },
  {
    name: "Yearn Classic Finance",
    symbol: "EARN",
    slug: "yearn-classic-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-classic-finance.png",
  },
  {
    name: "dFund",
    symbol: "DFND",
    slug: "dfund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfund.png",
  },
  {
    name: "Avocado",
    symbol: "AVO",
    slug: "avocado",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avocado.png",
  },
  {
    name: "GameStation",
    symbol: "GAMER",
    slug: "gamestation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamestation.png",
  },
  {
    name: "Rainbow Token",
    symbol: "RAINBOW",
    slug: "rainbow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rainbow-token.png",
  },
  {
    name: "PURR Vault (NFTX)",
    symbol: "PURR",
    slug: "purr-vault-nftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/purr-vault-nftx.png",
  },
  {
    name: "GET",
    symbol: "GET",
    slug: "get",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/get.png",
  },
  {
    name: "ACryptoS",
    symbol: "ACS",
    slug: "acryptos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acryptos.png",
  },
  {
    name: "PayAccept",
    symbol: "PAYT",
    slug: "payaccept",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payaccept.png",
  },
  {
    name: "MoonBear.finance",
    symbol: "MBF",
    slug: "moonbear-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonbear-finance.png",
  },
  {
    name: "EdenLoop",
    symbol: "ELT",
    slug: "edenloop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edenloop.png",
  },
  {
    name: "Blockmason Link",
    symbol: "BLINK",
    slug: "blockmason-link",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockmason-link.png",
  },
  {
    name: "Trip Leverage Token",
    symbol: "TLT",
    slug: "trip-leverage-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trip-leverage-token.png",
  },
  {
    name: "Battle Inu",
    symbol: "BINU",
    slug: "battle-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-inu.png",
  },
  {
    name: "DefyDefi",
    symbol: "DEFY",
    slug: "defydefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defydefi.png",
  },
  {
    name: "The Tokenized Bitcoin",
    symbol: "imBTC",
    slug: "the-tokenized-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-tokenized-bitcoin.png",
  },
  {
    name: "Metaverse Future",
    symbol: "METAF",
    slug: "metaverse-future",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-future.png",
  },
  {
    name: "Wheelers",
    symbol: "WHEEL",
    slug: "wheelers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wheelers.png",
  },
  {
    name: "Hurricane NFT",
    symbol: "NHCT",
    slug: "hurricane-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hurricane-nft.png",
  },
  {
    name: "KLEVA Protocol",
    symbol: "KLEVA",
    slug: "kleva-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kleva-protocol.png",
  },
  {
    name: "Lovelace World",
    symbol: "LACE",
    slug: "lovelace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lovelace.png",
  },
  {
    name: "Popcoin",
    symbol: "POP",
    slug: "popcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/popcoin.png",
  },
  {
    name: "Afrep",
    symbol: "AFREP",
    slug: "afrep",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/afrep.png",
  },
  {
    name: "COPS FINANCE",
    symbol: "COPS",
    slug: "cops-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cops-finance.png",
  },
  {
    name: "Nyx Token",
    symbol: "NYXT",
    slug: "nyx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nyx-token.png",
  },
  {
    name: "CarsAutoFinance",
    symbol: "CAF",
    slug: "carsautofinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carsautofinance.png",
  },
  {
    name: "B20",
    symbol: "B20",
    slug: "b20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/b20.png",
  },
  {
    name: "Islander",
    symbol: "ISA",
    slug: "islander",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/islander.png",
  },
  {
    name: "Fragments of arker",
    symbol: "FOA",
    slug: "fragments-of-arker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fragments-of-arker.png",
  },
  {
    name: "Intergalactic Cockroach",
    symbol: "ICC",
    slug: "intergalactic-cockroach",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intergalactic-cockroach.png",
  },
  {
    name: "Maxonrow",
    symbol: "MXW",
    slug: "maxonrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maxonrow.png",
  },
  {
    name: "GloryDoge",
    symbol: "GLORYD",
    slug: "glorydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glorydoge.png",
  },
  {
    name: "Oly Sport",
    symbol: "OLY",
    slug: "oly-sport",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oly-sport.png",
  },
  {
    name: "EverestCoin",
    symbol: "EVCOIN",
    slug: "everestcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everestcoin.png",
  },
  {
    name: "Okratech Token",
    symbol: "ORT",
    slug: "okratech-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okratech-token.png",
  },
  {
    name: "Mamasan Inu",
    symbol: "MAMASAN",
    slug: "mamasan-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mamasan-inu.png",
  },
  {
    name: "Pintu Token",
    symbol: "PTU",
    slug: "pintu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pintu-token.png",
  },
  {
    name: "NIL Coin",
    symbol: "NIL",
    slug: "nil-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nil-coin.png",
  },
  {
    name: "MARBLEX",
    symbol: "MBX",
    slug: "marblex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marblex.png",
  },
  {
    name: "Froge Finance",
    symbol: "FROGE",
    slug: "froge-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/froge-finance.png",
  },
  {
    name: "beFITTER",
    symbol: "HEE",
    slug: "befitter-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/befitter-token.png",
  },
  {
    name: "Velox",
    symbol: "VLX",
    slug: "velox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/velox.png",
  },
  {
    name: "Millennium Sapphire",
    symbol: "MSTO",
    slug: "millennium-sapphire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/millennium-sapphire.png",
  },
  {
    name: "Ardana",
    symbol: "DANA",
    slug: "ardana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ardana.png",
  },
  {
    name: "Dacxi",
    symbol: "DACXI",
    slug: "dacxi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dacxi.png",
  },
  {
    name: "PlentyCoin",
    symbol: "PLENTYCOIN",
    slug: "plentycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plentycoin.png",
  },
  {
    name: "The Youth Pay",
    symbol: "TYP",
    slug: "the-youth-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-youth-pay.png",
  },
  {
    name: "Fame MMA",
    symbol: "FAME",
    slug: "fame-mma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fame-mma.png",
  },
  {
    name: "GAMI World",
    symbol: "GAMI",
    slug: "gami-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gami-world.png",
  },
  {
    name: "Robust Protocol",
    symbol: "RBT",
    slug: "robust-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robust-protocol.png",
  },
  {
    name: "OS",
    symbol: "OS",
    slug: "os",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/os.png",
  },
  {
    name: "Wrapped WDOGE",
    symbol: "WWDOGE",
    slug: "wrapped-wdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-wdoge.png",
  },
  {
    name: "Export Mortos Platform",
    symbol: "EMP",
    slug: "export-mortos-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/export-mortos-platform.png",
  },
  {
    name: "Flag Media",
    symbol: "FLAG",
    slug: "flag-media",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flag-media.png",
  },
  {
    name: "V-Dimension",
    symbol: "VOLLAR",
    slug: "v-dimension",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/v-dimension.png",
  },
  {
    name: "SolanaSail",
    symbol: "SAIL",
    slug: "solanasail",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solanasail.png",
  },
  {
    name: "CAPITAL X CELL",
    symbol: "CXC",
    slug: "capital-x-cell",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/capital-x-cell.png",
  },
  {
    name: "Chainflix",
    symbol: "CFXT",
    slug: "chainflix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainflix.png",
  },
  {
    name: "Magnet DAO",
    symbol: "MAG",
    slug: "magnet-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magnet-dao.png",
  },
  {
    name: "Safechaintoken",
    symbol: "SCT",
    slug: "safechaintoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safechaintoken.png",
  },
  {
    name: "Green Climate World",
    symbol: "WGC",
    slug: "green-climate-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-climate-world.png",
  },
  {
    name: "SHIBORG INU",
    symbol: "SHIBORG",
    slug: "shiborg-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiborg-inu.png",
  },
  {
    name: "One Share",
    symbol: "ONS",
    slug: "one-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-share.png",
  },
  {
    name: "Ethersocks",
    symbol: "SOX",
    slug: "ethersocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethersocks.png",
  },
  {
    name: "Pett Network",
    symbol: "PETT",
    slug: "pett-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pett-network.png",
  },
  {
    name: "Bitcoin Asia",
    symbol: "BTCA",
    slug: "bitcoin-asia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-asia.png",
  },
  {
    name: "Wrapped Staked Olympus",
    symbol: "WSOHM",
    slug: "wrapped-staked-olympus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-staked-olympus.png",
  },
  {
    name: "CateCoin",
    symbol: "CATE",
    slug: "catecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catecoin.png",
  },
  {
    name: "nOFTEN",
    symbol: "NOF",
    slug: "noften",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noften.png",
  },
  {
    name: "Continuum Finance",
    symbol: "CTN",
    slug: "continuum-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/continuum-finance.png",
  },
  {
    name: "Monaco Planet",
    symbol: "MONA",
    slug: "mona",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mona.png",
  },
  {
    name: "Sperax",
    symbol: "SPA",
    slug: "sperax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sperax.png",
  },
  {
    name: "NirvanaMeta",
    symbol: "MNU",
    slug: "nirvanameta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nirvanameta.png",
  },
  {
    name: "The Troller Coin",
    symbol: "TROLLER",
    slug: "the-troller-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-troller-coin.png",
  },
  {
    name: "Kitten Coin",
    symbol: "KITTENS",
    slug: "kitten-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kitten-coin.png",
  },
  {
    name: "Fruit Fighters",
    symbol: "FOOFIGHT",
    slug: "fruit-fighters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fruit-fighters.png",
  },
  {
    name: "TeslaSafe",
    symbol: "TESLASAFE",
    slug: "teslasafe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teslasafe.png",
  },
  {
    name: "HeroCatGamefi",
    symbol: "HCT",
    slug: "herocatgamefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herocatgamefi.png",
  },
  {
    name: "CVIP",
    symbol: "CVIP",
    slug: "cvip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cvip.png",
  },
  {
    name: "Answerly",
    symbol: "ANSR",
    slug: "answerly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/answerly.png",
  },
  {
    name: "Pika",
    symbol: "PIKA",
    slug: "pika",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pika.png",
  },
  {
    name: "DEFY",
    symbol: "DEFY",
    slug: "defy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defy.png",
  },
  {
    name: "JackPool.finance",
    symbol: "JFI",
    slug: "jackpool-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jackpool-finance.png",
  },
  {
    name: "Xfinite Entertainment Token",
    symbol: "XET",
    slug: "xfinite-entertainment-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xfinite-entertainment-token.png",
  },
  {
    name: "ArmzLegends",
    symbol: "PROT",
    slug: "armzlegends",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/armzlegends.png",
  },
  {
    name: "BabyPenguin",
    symbol: "BPENG",
    slug: "babypenguin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babypenguin.png",
  },
  {
    name: "Legend of Fantasy War",
    symbol: "LFW",
    slug: "legend-of-fantasy-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legend-of-fantasy-war.png",
  },
  {
    name: "CryptoPlants Club",
    symbol: "CPC",
    slug: "cryptoplants",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoplants.png",
  },
  {
    name: "KamPay",
    symbol: "KAMPAY",
    slug: "kampay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kampay.png",
  },
  {
    name: "Ecochaintoken",
    symbol: "ECT",
    slug: "ecochaintoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecochaintoken.png",
  },
  {
    name: "Linkka",
    symbol: "IKA",
    slug: "linkka",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkka.png",
  },
  {
    name: "Snowbear",
    symbol: "SBR",
    slug: "snowbear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowbear.png",
  },
  {
    name: "DISCIPLINA",
    symbol: "DSCPL",
    slug: "disciplina",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/disciplina.png",
  },
  {
    name: "PlanetWatch",
    symbol: "PLANETS",
    slug: "planetwatch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/planetwatch.png",
  },
  {
    name: "Doge Universe",
    symbol: "SPACEXDOGE",
    slug: "doge-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-universe.png",
  },
  {
    name: "LunaOne",
    symbol: "XLN",
    slug: "lunaone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunaone.png",
  },
  {
    name: "SpiritDAO Ghost",
    symbol: "GHOST",
    slug: "spiritdao-ghost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spiritdao-ghost.png",
  },
  {
    name: "Polker",
    symbol: "PKR",
    slug: "polker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polker.png",
  },
  {
    name: "ROGin AI",
    symbol: "ROG",
    slug: "rogin-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rogin-ai.png",
  },
  {
    name: "Fate Token",
    symbol: "FATE",
    slug: "fate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fate-token.png",
  },
  {
    name: "Evolution Finance",
    symbol: "EVN",
    slug: "evolution-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evolution-finance.png",
  },
  {
    name: "Rhinos Finance",
    symbol: "RHO",
    slug: "rhinos-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rhinos-finance.png",
  },
  {
    name: "OBToken",
    symbol: "OBT",
    slug: "obtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/obtoken.png",
  },
  {
    name: "HUGHUG Coin",
    symbol: "HGHG",
    slug: "hughug-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hughug-coin.png",
  },
  {
    name: "Metapad",
    symbol: "MPD",
    slug: "metapad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metapad.png",
  },
  {
    name: "BlockNoteX",
    symbol: "BNOX",
    slug: "blocknotex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocknotex.png",
  },
  {
    name: "ONE PIECE",
    symbol: "ONEPIECE",
    slug: "one-piece",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/one-piece.png",
  },
  {
    name: "Torekko",
    symbol: "TRK",
    slug: "torekko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/torekko.png",
  },
  {
    name: "BulleX",
    symbol: "BLX",
    slug: "bullex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullex.png",
  },
  {
    name: "HeartK",
    symbol: "HEARTK",
    slug: "heartk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heartk.png",
  },
  {
    name: "OpenLive NFT",
    symbol: "OPV",
    slug: "openlive-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/openlive-nft.png",
  },
  {
    name: "Spherium",
    symbol: "SPHRI",
    slug: "spherium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spherium.png",
  },
  {
    name: "MyBricks",
    symbol: "$BRICKS",
    slug: "mybricks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mybricks.png",
  },
  {
    name: "LCG",
    symbol: "LCG",
    slug: "lcg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lcg.png",
  },
  {
    name: "GMD Protocol",
    symbol: "GMD",
    slug: "gmd-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gmd-protocol.png",
  },
  {
    name: "Up Spiral",
    symbol: "SPIRAL",
    slug: "up-spiral",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/up-spiral.png",
  },
  {
    name: "EcoFi",
    symbol: "ECO",
    slug: "ecofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecofi.png",
  },
  {
    name: "Redlight Node District",
    symbol: "PLAYMATES",
    slug: "redlight-node-district",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redlight-node-district.png",
  },
  {
    name: "Suneku",
    symbol: "SUNEKU",
    slug: "suneku",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/suneku.png",
  },
  {
    name: "The Truth",
    symbol: "UFO",
    slug: "the-truth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-truth.png",
  },
  {
    name: "MarioFloki",
    symbol: "MRFLOKI",
    slug: "mariofloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mariofloki.png",
  },
  {
    name: "LoserChick",
    symbol: "CHICK",
    slug: "loserchick",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loserchick.png",
  },
  {
    name: "BSB Token",
    symbol: "BSBT",
    slug: "bsb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsb-token.png",
  },
  {
    name: "Unbound",
    symbol: "UNB",
    slug: "unbound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unbound.png",
  },
  {
    name: "NFTrade",
    symbol: "NFTD",
    slug: "nftrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftrade.png",
  },
  {
    name: "Artery Network",
    symbol: "ARTR",
    slug: "artery-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artery-network.png",
  },
  {
    name: "UltronGlow",
    symbol: "UTG",
    slug: "ultronglow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultronglow.png",
  },
  {
    name: "Volt Inu",
    symbol: "VOLT",
    slug: "volt-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/volt-inu.png",
  },
  {
    name: "Trade Fighter",
    symbol: "TDF",
    slug: "trade-fighter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trade-fighter.png",
  },
  {
    name: "Billion",
    symbol: "BILL",
    slug: "billion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/billion.png",
  },
  {
    name: "Tamy Token",
    symbol: "TMT",
    slug: "tamy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tamy-token.png",
  },
  {
    name: "Zoe Cash",
    symbol: "ZOE",
    slug: "zoe-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoe-cash.png",
  },
  {
    name: "CYCAN NETWORK",
    symbol: "CYN",
    slug: "cycan-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cycan-network.png",
  },
  {
    name: "Pikaster",
    symbol: "MLS",
    slug: "pikaster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pikaster.png",
  },
  {
    name: "Kephi Gallery",
    symbol: "KPHI",
    slug: "kephi-gallery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kephi-gallery.png",
  },
  {
    name: "Doxxed",
    symbol: "DOX",
    slug: "doxxed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doxxed.png",
  },
  {
    name: "MemePad",
    symbol: "MEPAD",
    slug: "memepad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memepad.png",
  },
  {
    name: "Dinosaureggs",
    symbol: "DSG",
    slug: "dinosaureggs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinosaureggs.png",
  },
  {
    name: "Mooney",
    symbol: "MOONEY",
    slug: "mooney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mooney.png",
  },
  {
    name: "VIDT DAO",
    symbol: "VIDT",
    slug: "vidt-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vidt-dao.png",
  },
  {
    name: "GooseFX",
    symbol: "GOFX",
    slug: "goosefx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goosefx.png",
  },
  {
    name: "WEDEX TOKEN V2",
    symbol: "DEX",
    slug: "wedex-token-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wedex-token-v2.png",
  },
  {
    name: "Only 1 Token",
    symbol: "O1T",
    slug: "only-1-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/only-1-token.png",
  },
  {
    name: "MiniFlokiADA",
    symbol: "MFLOKIADA",
    slug: "miniflokiada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miniflokiada.png",
  },
  {
    name: "Opyn Squeeth",
    symbol: "OSQTH",
    slug: "opyn-squeeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opyn-squeeth.png",
  },
  {
    name: "Luna-Pad",
    symbol: "LUNAPAD",
    slug: "luna-pad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luna-pad.png",
  },
  {
    name: "pTokens BTC",
    symbol: "PBTC",
    slug: "ptokens-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ptokens-btc.png",
  },
  {
    name: "HashLand Coin",
    symbol: "HC",
    slug: "hashland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashland.png",
  },
  {
    name: "Teleport",
    symbol: "PORT",
    slug: "teleport",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teleport.png",
  },
  {
    name: "Kava Swap",
    symbol: "SWP",
    slug: "kava-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kava-swap.png",
  },
  {
    name: "Atlas FC Fan Token",
    symbol: "ATLAS",
    slug: "atlas-fc-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlas-fc-fan-token.png",
  },
  {
    name: "Ispolink",
    symbol: "ISP",
    slug: "ispolink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ispolink.png",
  },
  {
    name: "Saudi Shiba Inu",
    symbol: "SAUDISHIBA",
    slug: "saudishibainu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saudishibainu.png",
  },
  {
    name: "BSYS",
    symbol: "BSYS",
    slug: "bsys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsys.png",
  },
  {
    name: "Platypus Finance",
    symbol: "PTP",
    slug: "platypus-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/platypus-finance.png",
  },
  {
    name: "Fusion Heroes",
    symbol: "FSH",
    slug: "fusion-heroes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fusion-heroes.png",
  },
  {
    name: "Moneyrain Finance",
    symbol: "MONEYRAIN",
    slug: "moneyrain-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moneyrain-finance.png",
  },
  {
    name: "3X Long BNB Token",
    symbol: "BNBBULL",
    slug: "3x-long-bnb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-bnb-token.png",
  },
  {
    name: "MetaSpace",
    symbol: "MSPACE",
    slug: "metaspace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaspace.png",
  },
  {
    name: "Race Kingdom",
    symbol: "ATOZ",
    slug: "race-kingdom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/race-kingdom.png",
  },
  {
    name: "Fit&Beat",
    symbol: "FTB",
    slug: "fit-beat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fit-beat.png",
  },
  {
    name: "Battle Infinity",
    symbol: "IBAT",
    slug: "battle-infinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-infinity.png",
  },
  {
    name: "Coldbank",
    symbol: "COLD",
    slug: "coldbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coldbank.png",
  },
  {
    name: "Snowball",
    symbol: "SNOB",
    slug: "snowball-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowball-finance.png",
  },
  {
    name: "GreenZoneX",
    symbol: "GZX",
    slug: "greenzonex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenzonex.png",
  },
  {
    name: "Metaseer",
    symbol: "METAS",
    slug: "metaseer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaseer.png",
  },
  {
    name: "ASH",
    symbol: "ASH",
    slug: "ash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ash.png",
  },
  {
    name: "Romeo Doge",
    symbol: "ROMEODOGE",
    slug: "romeo-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/romeo-doge.png",
  },
  {
    name: "Aetherius",
    symbol: "AETH",
    slug: "aetherius",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aetherius.png",
  },
  {
    name: "MoonLift Protocol",
    symbol: "MLTP",
    slug: "moonlift-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonlift-protocol.png",
  },
  {
    name: "NFTDAO",
    symbol: "NAO",
    slug: "nftdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftdao.png",
  },
  {
    name: "CDzExchange",
    symbol: "CDZ",
    slug: "cdzexchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cdzexchange.png",
  },
  {
    name: "Xircus",
    symbol: "XIRCUS",
    slug: "xircus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xircus.png",
  },
  {
    name: "BabySun",
    symbol: "BabySun",
    slug: "babysun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babysun.png",
  },
  {
    name: "SONM (BEP-20)",
    symbol: "SNM",
    slug: "sonm-bep20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonm-bep20.png",
  },
  {
    name: "Plenty DeFi",
    symbol: "PLENTY",
    slug: "plenty-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plenty-dao.png",
  },
  {
    name: "Futura Finance",
    symbol: "FFT",
    slug: "futura-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futura-finance.png",
  },
  {
    name: "QMALL TOKEN",
    symbol: "QMALL",
    slug: "qmall-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qmall-token.png",
  },
  {
    name: "LavaSwap",
    symbol: "LAVA",
    slug: "lavaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lavaswap.png",
  },
  {
    name: "Nuts Gaming",
    symbol: "NUTSG",
    slug: "nuts-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nuts-gaming.png",
  },
  {
    name: "Falcon Swaps",
    symbol: "FLNS",
    slug: "falcon-swaps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/falcon-swaps.png",
  },
  {
    name: "EnterDAO",
    symbol: "ENTR",
    slug: "enterdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enterdao.png",
  },
  {
    name: "Eros Token",
    symbol: "EROS",
    slug: "eros-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eros-token.png",
  },
  {
    name: "Genie Protocol",
    symbol: "GNP",
    slug: "genie-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genie-protocol.png",
  },
  {
    name: "Herobattle",
    symbol: "HRB",
    slug: "herobattle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herobattle.png",
  },
  {
    name: "xNFT Protocol",
    symbol: "XNFT",
    slug: "xnft-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xnft-protocol.png",
  },
  {
    name: "FutureRealEstateToken",
    symbol: "FRET",
    slug: "futurerealestatetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futurerealestatetoken.png",
  },
  {
    name: "PooCoin",
    symbol: "POOCOIN",
    slug: "poocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poocoin.png",
  },
  {
    name: "PulseFloki",
    symbol: "PLSF",
    slug: "pulsefloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pulsefloki.png",
  },
  {
    name: "Bitsten Token",
    symbol: "BST",
    slug: "bitsten-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitsten-token.png",
  },
  {
    name: "BovineVerse Token",
    symbol: "BVT",
    slug: "bovineverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bovineverse.png",
  },
  {
    name: "Furio",
    symbol: "$FUR",
    slug: "furio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/furio.png",
  },
  {
    name: "Corite",
    symbol: "CO",
    slug: "corite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corite.png",
  },
  {
    name: "Nxtech Network",
    symbol: "NX",
    slug: "nxtech-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nxtech-network.png",
  },
  {
    name: "Whale Loans",
    symbol: "HUMP",
    slug: "humpback",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/humpback.png",
  },
  {
    name: "GAMER",
    symbol: "GMR",
    slug: "gamer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamer.png",
  },
  {
    name: "Anchor bETH Token",
    symbol: "BETH",
    slug: "anchor-beth-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anchor-beth-token.png",
  },
  {
    name: "Marshall Rogan Inu",
    symbol: "MRI",
    slug: "marshall-rogan-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marshall-rogan-inu.png",
  },
  {
    name: "SafeVault",
    symbol: "VAULT",
    slug: "safevault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safevault.png",
  },
  {
    name: "Globalvillage Ecosystem",
    symbol: "GVE",
    slug: "globalvillage-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/globalvillage-ecosystem.png",
  },
  {
    name: "Mission Helios",
    symbol: "HELIOS",
    slug: "mission-helios",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mission-helios.png",
  },
  {
    name: "ICEBERG",
    symbol: "ICEBERG",
    slug: "iceberg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iceberg.png",
  },
  {
    name: "YoRocket",
    symbol: "$YO",
    slug: "yorocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yorocket.png",
  },
  {
    name: "GRN",
    symbol: "G",
    slug: "grn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grn.png",
  },
  {
    name: "DogeBTC",
    symbol: "DOGEBTC",
    slug: "dogebtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogebtc.png",
  },
  {
    name: "CRIR MSH",
    symbol: "MSH",
    slug: "crir-msh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crir-msh.png",
  },
  {
    name: "KCCPAD",
    symbol: "KCCPAD",
    slug: "kccpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kccpad.png",
  },
  {
    name: "Gold Guaranteed Coin Mining",
    symbol: "GGCM",
    slug: "gold-guaranteed-coin-mining",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-guaranteed-coin-mining.png",
  },
  {
    name: "SuperNova",
    symbol: "SHARE",
    slug: "supernova",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supernova.png",
  },
  {
    name: "Handy",
    symbol: "HANDY",
    slug: "handy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/handy.png",
  },
  {
    name: "TMETA",
    symbol: "TMT",
    slug: "tmeta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tmeta.png",
  },
  {
    name: "Metaland DAO",
    symbol: "META",
    slug: "metaland-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaland-dao.png",
  },
  {
    name: "Spaceship War",
    symbol: "SPW",
    slug: "spaceship-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spaceship-war.png",
  },
  {
    name: "Instaraise",
    symbol: "INSTA",
    slug: "instaraise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/instaraise.png",
  },
  {
    name: "USDH Hubble Stablecoin",
    symbol: "USDH",
    slug: "usdh-hubble-stablecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdh-hubble-stablecoin.png",
  },
  {
    name: "Animal Farm Pigs",
    symbol: "AFP",
    slug: "animal-farm-pigs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animal-farm-pigs.png",
  },
  {
    name: "Vero Farm",
    symbol: "VERO",
    slug: "vero-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vero-farm.png",
  },
  {
    name: "OOGI",
    symbol: "OOGI",
    slug: "oogi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oogi.png",
  },
  {
    name: "Safe Energy",
    symbol: "ENERGYX",
    slug: "safe-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-energy.png",
  },
  {
    name: "DefiDollar",
    symbol: "DUSD",
    slug: "defidollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defidollar.png",
  },
  {
    name: "Symbiosis Finance",
    symbol: "SIS",
    slug: "symbiosis-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/symbiosis-finance.png",
  },
  {
    name: "Waggle Network",
    symbol: "WAG",
    slug: "waggle-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waggle-network.png",
  },
  {
    name: "Polar Sync",
    symbol: "POLAR",
    slug: "polar-sync",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polar-sync.png",
  },
  {
    name: "Baby Schrodinger Coin",
    symbol: "BABYDINGER",
    slug: "baby-schrodinger-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-schrodinger-coin.png",
  },
  {
    name: "TEN",
    symbol: "TENFI",
    slug: "ten",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ten.png",
  },
  {
    name: "Release Project",
    symbol: "REL",
    slug: "release-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/release-project.png",
  },
  {
    name: "Chihuahua",
    symbol: "HUAHUA",
    slug: "chihuahua-wtf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chihuahua-wtf.png",
  },
  {
    name: "XenophonDAO",
    symbol: "XPH",
    slug: "xenophondao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xenophondao.png",
  },
  {
    name: "APY Vision",
    symbol: "VISION",
    slug: "apy-vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apy-vision.png",
  },
  {
    name: "SatoshiSwap",
    symbol: "SWAP",
    slug: "satoshiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoshiswap.png",
  },
  {
    name: "Tokenplace",
    symbol: "TOK",
    slug: "tokenplace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenplace.png",
  },
  {
    name: "MicroPets",
    symbol: "PETS",
    slug: "micropets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/micropets.png",
  },
  {
    name: "AutoShark",
    symbol: "JAWS",
    slug: "autoshark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/autoshark.png",
  },
  {
    name: "Ztranzit Coin",
    symbol: "ZTNZ",
    slug: "ztranzit-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ztranzit-coin.png",
  },
  {
    name: "BullishApes",
    symbol: "BULLISH",
    slug: "bullishapes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullishapes.png",
  },
  {
    name: "Element.Black",
    symbol: "ELT",
    slug: "element-black",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/element-black.png",
  },
  {
    name: "TokenBot",
    symbol: "TKB",
    slug: "tokenbot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenbot.png",
  },
  {
    name: "REBorn",
    symbol: "RB",
    slug: "reborn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reborn.png",
  },
  {
    name: "Werewolves Game",
    symbol: "WOLF",
    slug: "werewolves-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/werewolves-game.png",
  },
  {
    name: "King Floki V2",
    symbol: "KING",
    slug: "king-floki-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-floki-v2.png",
  },
  {
    name: "SANGKARA MISA",
    symbol: "MISA",
    slug: "sangkara-misa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sangkara-misa.png",
  },
  {
    name: "Londex",
    symbol: "LDX",
    slug: "londex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/londex.png",
  },
  {
    name: "Proof Of Degen",
    symbol: "BNB2.0",
    slug: "proof-of-degen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proof-of-degen.png",
  },
  {
    name: "JejuDogeBSC",
    symbol: "JEJUDOGE",
    slug: "jejudogebsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jejudogebsc.png",
  },
  {
    name: "Egg N Partners",
    symbol: "EGGT",
    slug: "egg-n-partners",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egg-n-partners.png",
  },
  {
    name: "YearnAgnostic Finance",
    symbol: "YFIAG",
    slug: "yearnagnostic-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearnagnostic-finance.png",
  },
  {
    name: "tBTC",
    symbol: "TBTC",
    slug: "tbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tbtc.png",
  },
  {
    name: "Nexus Crypto Services",
    symbol: "$NEXUS",
    slug: "nexus-folio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nexus-folio.png",
  },
  {
    name: "Baby shiba rocket",
    symbol: "$BSHIBR",
    slug: "baby-shiba-rocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-shiba-rocket.png",
  },
  {
    name: "Cryptogram",
    symbol: "CRYPTOGRAM",
    slug: "cryptogram",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptogram.png",
  },
  {
    name: "SNAP!",
    symbol: "SNAP",
    slug: "snap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snap-token.png",
  },
  {
    name: "RaDAO",
    symbol: "RA",
    slug: "radao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radao.png",
  },
  {
    name: "Ethereum Lightning",
    symbol: "ETLT",
    slug: "ethereumlightning",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereumlightning.png",
  },
  {
    name: "Caash",
    symbol: "CASH",
    slug: "caash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caash.png",
  },
  {
    name: "JOJO",
    symbol: "JOJO",
    slug: "jojo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jojo.png",
  },
  {
    name: "Greed",
    symbol: "$GREED",
    slug: "greed-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greed-ecosystem.png",
  },
  {
    name: "Howl City",
    symbol: "HWL",
    slug: "howl-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/howl-city.png",
  },
  {
    name: "DexGame",
    symbol: "DXGM",
    slug: "dexgame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexgame.png",
  },
  {
    name: "Norse Finance",
    symbol: "NFI",
    slug: "norse-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/norse-finance.png",
  },
  {
    name: "X Protocol",
    symbol: "POT",
    slug: "x-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-protocol.png",
  },
  {
    name: "Sphynx Token",
    symbol: "SPHYNX",
    slug: "sphynx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sphynx-token.png",
  },
  {
    name: "ApeRocket",
    symbol: "SPACE",
    slug: "aperocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aperocket.png",
  },
  {
    name: "Projekt Diamond",
    symbol: "DIAMND",
    slug: "projekt-diamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/projekt-diamond.png",
  },
  {
    name: "SIU2022",
    symbol: "SIU",
    slug: "siu2022",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/siu2022.png",
  },
  {
    name: "Solanax",
    symbol: "SOLD",
    slug: "solanax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solanax.png",
  },
  {
    name: "BSCEX",
    symbol: "BSCX",
    slug: "bscex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bscex.png",
  },
  {
    name: "867",
    symbol: "867",
    slug: "867",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/867.png",
  },
  {
    name: "GoMeat",
    symbol: "GOMT",
    slug: "gomeat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gomeat.png",
  },
  {
    name: "IOTEN",
    symbol: "IOTN",
    slug: "ioten",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ioten.png",
  },
  {
    name: "DeHero",
    symbol: "HEROES",
    slug: "dehero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dehero.png",
  },
  {
    name: "Polylastic",
    symbol: "POLX",
    slug: "polylastic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polylastic.png",
  },
  {
    name: "Wild Island Game",
    symbol: "WILD",
    slug: "wild-island-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wild-island-game.png",
  },
  {
    name: "Fluffy Coin",
    symbol: "FLUF",
    slug: "fluffy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fluffy-coin.png",
  },
  {
    name: "Witnet",
    symbol: "WIT",
    slug: "witnet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/witnet.png",
  },
  {
    name: "Your Future Exchange",
    symbol: "YFX",
    slug: "your-future-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/your-future-exchange.png",
  },
  {
    name: "Anarchy",
    symbol: "ANARCHY",
    slug: "anarchy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anarchy.png",
  },
  {
    name: "UltimoGG",
    symbol: "ULTGG",
    slug: "ultimogg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultimogg.png",
  },
  {
    name: "EarthFund",
    symbol: "1EARTH",
    slug: "earthfund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earthfund.png",
  },
  {
    name: "Proof Of Apes",
    symbol: "POA",
    slug: "proof-of-apes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proof-of-apes.png",
  },
  {
    name: "Kilo Shiba Inu",
    symbol: "KSHIB",
    slug: "kilo-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kilo-shiba-inu.png",
  },
  {
    name: "cheqd",
    symbol: "CHEQ",
    slug: "cheqd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheqd.png",
  },
  {
    name: "Avakus",
    symbol: "AVAK",
    slug: "avakus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avakus.png",
  },
  {
    name: "Rocket Boys",
    symbol: "RBOYS",
    slug: "rocket-boys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-boys.png",
  },
  {
    name: "XTRA Token",
    symbol: "XTRA",
    slug: "xtra-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtra-token.png",
  },
  {
    name: "Block Farm",
    symbol: "BFC",
    slug: "block-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block-farm.png",
  },
  {
    name: "FIFA Champs",
    symbol: "CHAMPS",
    slug: "fifa-champs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fifa-champs.png",
  },
  {
    name: "JUNO",
    symbol: "JUNO",
    slug: "juno",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/juno.png",
  },
  {
    name: "EverBNB",
    symbol: "EVERBNB",
    slug: "everbnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everbnb.png",
  },
  {
    name: "SaveBritney",
    symbol: "SBRT",
    slug: "savebritney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/savebritney.png",
  },
  {
    name: "Frenchie Network",
    symbol: "FREN",
    slug: "frenchie-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frenchie-network.png",
  },
  {
    name: "EgoPlatform",
    symbol: "EGO",
    slug: "egoplatform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egoplatform.png",
  },
  {
    name: "BRCP TOKEN",
    symbol: "BRCP",
    slug: "brcp-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brcp-token.png",
  },
  {
    name: "pFTM",
    symbol: "PFTM",
    slug: "pftm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pftm.png",
  },
  {
    name: "TAPME Token",
    symbol: "TAP",
    slug: "taptoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taptoken.png",
  },
  {
    name: "Melo Token",
    symbol: "MELO",
    slug: "melo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/melo-token.png",
  },
  {
    name: "Traders coin",
    symbol: "TRDC",
    slug: "traders-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/traders-coin.png",
  },
  {
    name: "H2O Securities",
    symbol: "H2ON",
    slug: "h2o-securities",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/h2o-securities.png",
  },
  {
    name: "ZodiacsV2",
    symbol: "ZDCV2",
    slug: "zodiacs-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zodiacs-v2.png",
  },
  {
    name: "Universe.XYZ",
    symbol: "XYZ",
    slug: "universe-xyz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universe-xyz.png",
  },
  {
    name: "TryHards",
    symbol: "TRY",
    slug: "tryhards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tryhards.png",
  },
  {
    name: "Xiden",
    symbol: "XDEN",
    slug: "xiden",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xiden.png",
  },
  {
    name: "GeroWallet",
    symbol: "GERO",
    slug: "gerowallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gerowallet.png",
  },
  {
    name: "Treasure Under Sea",
    symbol: "TUS",
    slug: "treasure-under-sea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treasure-under-sea.png",
  },
  {
    name: "Avaterra",
    symbol: "TERRA",
    slug: "avaterra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaterra.png",
  },
  {
    name: "EMOGI Network",
    symbol: "LOL",
    slug: "emogi-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emogi-network.png",
  },
  {
    name: "Monkey Claus Game",
    symbol: "MCG",
    slug: "monkey-claus-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monkey-claus-game.png",
  },
  {
    name: "OASISBloc",
    symbol: "OSB",
    slug: "oasisbloc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oasisbloc.png",
  },
  {
    name: "CATCOIN",
    symbol: "CATS",
    slug: "catcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catcoin-token.png",
  },
  {
    name: "Golden Eagle",
    symbol: "GDE",
    slug: "golden-eagle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-eagle.png",
  },
  {
    name: "NiiFi",
    symbol: "NIIFI",
    slug: "niifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niifi.png",
  },
  {
    name: "ARTII Token",
    symbol: "ARTII",
    slug: "artii-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artii-token.png",
  },
  {
    name: "Cubo",
    symbol: "CUBO",
    slug: "cubo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cubo.png",
  },
  {
    name: "League of Ancients",
    symbol: "LOA",
    slug: "league-of-ancients",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/league-of-ancients.png",
  },
  {
    name: "SOUNI",
    symbol: "SON",
    slug: "sovi-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sovi-universe.png",
  },
  {
    name: "April",
    symbol: "APRIL",
    slug: "april",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/april.png",
  },
  {
    name: "PowerADA",
    symbol: "POW",
    slug: "powerada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/powerada.png",
  },
  {
    name: "Jet Protocol",
    symbol: "JET",
    slug: "jet-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jet-protocol.png",
  },
  {
    name: "WidiLand",
    symbol: "WIDI",
    slug: "widiland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/widiland.png",
  },
  {
    name: "PolkaPlay",
    symbol: "POLO",
    slug: "polkaplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkaplay.png",
  },
  {
    name: "Baby Fort Knox",
    symbol: "BFK",
    slug: "babyfortknox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyfortknox.png",
  },
  {
    name: "Plant2Earn",
    symbol: "P2E",
    slug: "plant2earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plant2earn.png",
  },
  {
    name: "Ballswap",
    symbol: "BSP",
    slug: "ballswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ballswap.png",
  },
  {
    name: "USDEX",
    symbol: "USDEX",
    slug: "usdex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdex-token.png",
  },
  {
    name: "Ruyi",
    symbol: "RYB",
    slug: "ruyi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ruyi.png",
  },
  {
    name: "PsyOptions",
    symbol: "PSY",
    slug: "psy-options",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/psy-options.png",
  },
  {
    name: "Daikokuten Sama",
    symbol: "DKKS",
    slug: "daikokuten-sama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daikokuten-sama.png",
  },
  {
    name: "Meta Utopia",
    symbol: "LAND",
    slug: "meta-utopia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-utopia.png",
  },
  {
    name: "Liquid Finance",
    symbol: "LIQD",
    slug: "liquid-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquid-finance.png",
  },
  {
    name: "Strite",
    symbol: "STRI",
    slug: "strite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strite.png",
  },
  {
    name: "MetaPlanet",
    symbol: "MPL",
    slug: "metaplanet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaplanet.png",
  },
  {
    name: "SmartX",
    symbol: "SAT",
    slug: "smartxone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartxone.png",
  },
  {
    name: "ShitGame (POOP)",
    symbol: "POOP",
    slug: "poop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poop.png",
  },
  {
    name: "Minisoccer",
    symbol: "MINISOCCER",
    slug: "minisoccer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minisoccer.png",
  },
  {
    name: "DogeBull",
    symbol: "DOGEBULL",
    slug: "dogebull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogebull.png",
  },
  {
    name: "BNPL Pay",
    symbol: "BNPL",
    slug: "bnpl-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnpl-pay.png",
  },
  {
    name: "100xCoin",
    symbol: "100X",
    slug: "100xcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/100xcoin.png",
  },
  {
    name: "Nemesis Wealth Projects BSC",
    symbol: "NMS",
    slug: "nemesis-wealth-projects-bsc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nemesis-wealth-projects-bsc.png",
  },
  {
    name: "BUMooN",
    symbol: "BUMN",
    slug: "bumoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bumoon.png",
  },
  {
    name: "STOA Network",
    symbol: "STA",
    slug: "defi-stoa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-stoa.png",
  },
  {
    name: "Orica",
    symbol: "ORI",
    slug: "orica",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orica.png",
  },
  {
    name: "Octopus Protocol",
    symbol: "OPS",
    slug: "octopus-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octopus-protocol.png",
  },
  {
    name: "MIBR Fan Token",
    symbol: "MIBR",
    slug: "mibr-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mibr-fan-token.png",
  },
  {
    name: "NASDEX",
    symbol: "NSDX",
    slug: "nasdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nasdex.png",
  },
  {
    name: "Afrostar",
    symbol: "AFRO",
    slug: "afrostar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/afrostar.png",
  },
  {
    name: "BabyBUSD",
    symbol: "BABYBUSD",
    slug: "babybusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babybusd.png",
  },
  {
    name: "Cryptopia Land Dollar",
    symbol: "CLD",
    slug: "cryptopia-land-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptopia-land-dollar.png",
  },
  {
    name: "Trivians",
    symbol: "TRIVIA",
    slug: "trivians",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trivians.png",
  },
  {
    name: "Digital Financial Exchange",
    symbol: "DIFX",
    slug: "digital-financial-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-financial-exchange.png",
  },
  {
    name: "Dawn Of Gods",
    symbol: "DAGO",
    slug: "dawn-of-gods",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dawn-of-gods.png",
  },
  {
    name: "Art of War",
    symbol: "$AOW",
    slug: "art-of-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/art-of-war.png",
  },
  {
    name: "Wanda Exchange",
    symbol: "WE",
    slug: "wanda-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanda-exchange.png",
  },
  {
    name: "Club Santos Laguna Fan Token",
    symbol: "SAN",
    slug: "club-santos-laguna-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/club-santos-laguna-fan-token.png",
  },
  {
    name: "FantOHM",
    symbol: "FHM",
    slug: "fantohm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantohm.png",
  },
  {
    name: "Hdac",
    symbol: "HDAC",
    slug: "hdac",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hdac.png",
  },
  {
    name: "Marnotaur",
    symbol: "TAUR",
    slug: "marnotaur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marnotaur.png",
  },
  {
    name: "Fanfury",
    symbol: "FURY",
    slug: "fanfury",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanfury.png",
  },
  {
    name: "Balloon-X",
    symbol: "BLX",
    slug: "balloon-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balloon-x.png",
  },
  {
    name: "Apollo Inu",
    symbol: "APOLLO",
    slug: "apollo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apollo-inu.png",
  },
  {
    name: "Snowy Owl",
    symbol: "SNO",
    slug: "snowy-owl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowy-owl.png",
  },
  {
    name: "Frosted Cake",
    symbol: "FROSTEDCAKE",
    slug: "frostedcake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frostedcake.png",
  },
  {
    name: "BitBook",
    symbol: "BBT",
    slug: "bitbook",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitbook.png",
  },
  {
    name: "XBIT",
    symbol: "XBT",
    slug: "4xbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/4xbit.png",
  },
  {
    name: "2G CARBON COIN",
    symbol: "2GCC",
    slug: "2g-carbon-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2g-carbon-coin.png",
  },
  {
    name: "Wrapped Centrifuge",
    symbol: "WCFG",
    slug: "wrapped-centrifuge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-centrifuge.png",
  },
  {
    name: "Wombex Finance",
    symbol: "WMX",
    slug: "wombex-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wombex-finance.png",
  },
  {
    name: "ASIX Token",
    symbol: "ASIX",
    slug: "asix-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asix-token.png",
  },
  {
    name: "MetaBridge",
    symbol: "MTB",
    slug: "metabridge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabridge.png",
  },
  {
    name: "Spinada.cash",
    symbol: "SPIN",
    slug: "spinada-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spinada-cash.png",
  },
  {
    name: "Beer Inu",
    symbol: "BEER",
    slug: "beer-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beer-inu.png",
  },
  {
    name: "Silent Notary",
    symbol: "UBSN",
    slug: "silent-notary",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silent-notary.png",
  },
  {
    name: "blockWRK",
    symbol: "WRK",
    slug: "blockwrk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockwrk.png",
  },
  {
    name: "Chirpley",
    symbol: "CHRP",
    slug: "chirpley",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chirpley.png",
  },
  {
    name: "BabyBitcoin",
    symbol: "BABYBITC",
    slug: "babybitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babybitcoin.png",
  },
  {
    name: "MoonWilly",
    symbol: "MNWL",
    slug: "moonwilly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonwilly.png",
  },
  {
    name: "Tortuga Finance Aptos",
    symbol: "tAPT",
    slug: "tortuga-finance-aptos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tortuga-finance-aptos.png",
  },
  {
    name: "Queen of Shiba",
    symbol: "QUEENSHIBA",
    slug: "queen-of-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/queen-of-shiba.png",
  },
  {
    name: "Facebook Metaverse",
    symbol: "FACEMETA",
    slug: "facebook-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/facebook-metaverse.png",
  },
  {
    name: "BITGATTI",
    symbol: "BITGATTI",
    slug: "bitgatti",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitgatti.png",
  },
  {
    name: "Atlas DEX",
    symbol: "ATS",
    slug: "atlas-dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlas-dex.png",
  },
  {
    name: "CoinMerge",
    symbol: "CMERGE",
    slug: "coinmerge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinmerge.png",
  },
  {
    name: "Chainbing",
    symbol: "CBG",
    slug: "chainbing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainbing.png",
  },
  {
    name: "CoinAlpha",
    symbol: "ALP",
    slug: "coinalpha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinalpha.png",
  },
  {
    name: "Fluffy Inu",
    symbol: "FLUFFY",
    slug: "fluffy-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fluffy-inu.png",
  },
  {
    name: "ULTRON",
    symbol: "ULX",
    slug: "ultron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultron.png",
  },
  {
    name: "Tracer DAO",
    symbol: "TCR",
    slug: "tracer-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tracer-dao.png",
  },
  {
    name: "FNK wallet",
    symbol: "FNK",
    slug: "fnk-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fnk-wallet.png",
  },
  {
    name: "KAINET",
    symbol: "KAINET",
    slug: "kainet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kainet.png",
  },
  {
    name: "Optimus",
    symbol: "OPT",
    slug: "optimus-opt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/optimus-opt.png",
  },
  {
    name: "Clavis",
    symbol: "CLAVIS",
    slug: "clavis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clavis.png",
  },
  {
    name: "XCarnival",
    symbol: "XCV",
    slug: "xcarnival",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xcarnival.png",
  },
  {
    name: "Boo Finance",
    symbol: "BOOFI",
    slug: "boo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boo-finance.png",
  },
  {
    name: "Devia8",
    symbol: "DEVIA8",
    slug: "devia8",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/devia8.png",
  },
  {
    name: "Lunatics",
    symbol: "LUNAT",
    slug: "lunatics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunatics.png",
  },
  {
    name: "BitClout",
    symbol: "CLOUT",
    slug: "bitclout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitclout.png",
  },
  {
    name: "Mythos",
    symbol: "MYTH",
    slug: "mythos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mythos.png",
  },
  {
    name: "Tenshi",
    symbol: "TENSHI",
    slug: "tenshi-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tenshi-new.png",
  },
  {
    name: "Global Utility Smart Digital Token",
    symbol: "GUSDT",
    slug: "gusd-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gusd-token.png",
  },
  {
    name: "Dirty Doge",
    symbol: "DDOGE",
    slug: "dirty-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dirty-doge.png",
  },
  {
    name: "Cujo Inu",
    symbol: "CUJO",
    slug: "cujo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cujo-inu.png",
  },
  {
    name: "SaylorCake",
    symbol: "SCAKE",
    slug: "saylorcake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saylorcake.png",
  },
  {
    name: "hi Dollar",
    symbol: "HI",
    slug: "hi-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hi-dollar.png",
  },
  {
    name: "Kodi",
    symbol: "KODI",
    slug: "kodi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kodi.png",
  },
  {
    name: "Lofi Defi",
    symbol: "LOFI",
    slug: "lofi-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lofi-defi.png",
  },
  {
    name: "REX",
    symbol: "XRX",
    slug: "rex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rex-token.png",
  },
  {
    name: "ADAFlect",
    symbol: "ADAFLECT",
    slug: "adaflect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adaflect.png",
  },
  {
    name: "DefiDollar DAO",
    symbol: "DFD",
    slug: "defidollar-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defidollar-dao.png",
  },
  {
    name: "StartFi",
    symbol: "STFI",
    slug: "startfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/startfi.png",
  },
  {
    name: "Gode Chain",
    symbol: "GODE",
    slug: "gode-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gode-chain.png",
  },
  {
    name: "ClearCryptos",
    symbol: "CCX",
    slug: "clearcryptos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clearcryptos.png",
  },
  {
    name: "BunnyRocket",
    symbol: "BUNNYROCKET",
    slug: "bunnyrocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunnyrocket.png",
  },
  {
    name: "CNNS",
    symbol: "CNNS",
    slug: "cnns",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cnns.png",
  },
  {
    name: "Celo Euro",
    symbol: "CEUR",
    slug: "celo-euro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celo-euro.png",
  },
  {
    name: "PlaceWar",
    symbol: "PLACE",
    slug: "placewar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/placewar.png",
  },
  {
    name: "InfinitX",
    symbol: "INX",
    slug: "infinitx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitx.png",
  },
  {
    name: "aggle.io",
    symbol: "AGGL",
    slug: "aggle-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aggle-io.png",
  },
  {
    name: "Kurobi",
    symbol: "KURO",
    slug: "kurobi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kurobi.png",
  },
  {
    name: "0x_nodes",
    symbol: "BIOS",
    slug: "0x-nodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0x-nodes.png",
  },
  {
    name: "QAtar",
    symbol: "QATAR",
    slug: "qatar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qatar.png",
  },
  {
    name: "WitcherVerse",
    symbol: "WCH",
    slug: "witcherverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/witcherverse.png",
  },
  {
    name: "Blatform",
    symbol: "BFORM",
    slug: "blatform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blatform.png",
  },
  {
    name: "Marvelous NFTs (Bad Days)",
    symbol: "MNFT",
    slug: "marvelous-nfts-bad-days",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marvelous-nfts-bad-days.png",
  },
  {
    name: "CoFiX",
    symbol: "COFI",
    slug: "cofix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cofix.png",
  },
  {
    name: "Diviner Protocol",
    symbol: "DPT",
    slug: "diviner-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diviner-protocol.png",
  },
  {
    name: "Treecle",
    symbol: "TRCL",
    slug: "treecle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treecle.png",
  },
  {
    name: "Hawksight",
    symbol: "HAWK",
    slug: "hawksight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hawksight.png",
  },
  {
    name: "ChargeDefi",
    symbol: "CHARGE",
    slug: "chargedefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chargedefi.png",
  },
  {
    name: "PinkSale",
    symbol: "PINKSALE",
    slug: "pinksale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pinksale.png",
  },
  {
    name: "Save Planet Earth",
    symbol: "SPE",
    slug: "saveplanetearth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saveplanetearth.png",
  },
  {
    name: "X world Games",
    symbol: "XWG",
    slug: "x-world-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-world-games.png",
  },
  {
    name: "WAXE",
    symbol: "WAXE",
    slug: "waxe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waxe.png",
  },
  {
    name: "DOGE SPACEX",
    symbol: "DOGESX",
    slug: "doge-spacex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-spacex.png",
  },
  {
    name: "EA Token",
    symbol: "EA",
    slug: "ea-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ea-token.png",
  },
  {
    name: "Dexigas",
    symbol: "DXG",
    slug: "dexigas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexigas.png",
  },
  {
    name: "GINCOIN (Global  Interest  Rate)",
    symbol: "GIN",
    slug: "gincoin-global-interest-rate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gincoin-global-interest-rate.png",
  },
  {
    name: "DogeKing",
    symbol: "DOGEKING",
    slug: "dogeking",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeking.png",
  },
  {
    name: "PEPEGOLD",
    symbol: "PEPE",
    slug: "pepegold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pepegold.png",
  },
  {
    name: "Mobius Finance",
    symbol: "MOT",
    slug: "mobius-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobius-finance.png",
  },
  {
    name: "AGE OF CRYPTOLOGY",
    symbol: "AUR",
    slug: "age-of-cryptology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/age-of-cryptology.png",
  },
  {
    name: "Blockcloud",
    symbol: "BLOC",
    slug: "blockcloud",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockcloud.png",
  },
  {
    name: "Royal Gold",
    symbol: "RGOLD",
    slug: "royal-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/royal-gold.png",
  },
  {
    name: "Uno Re",
    symbol: "UNO",
    slug: "unore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unore.png",
  },
  {
    name: "Coin Gabbar Token",
    symbol: "CGT",
    slug: "coin-gabbar-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-gabbar-token.png",
  },
  {
    name: "CumStar",
    symbol: "CUMSTAR",
    slug: "cumstar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cumstar.png",
  },
  {
    name: "CryptosTribe",
    symbol: "CSTC",
    slug: "cryptostribe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptostribe.png",
  },
  {
    name: "Doge Dash",
    symbol: "DOGEDASH",
    slug: "doge-dash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-dash.png",
  },
  {
    name: "Immutable",
    symbol: "DARA",
    slug: "immutable",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/immutable.png",
  },
  {
    name: "Roseon Finance",
    symbol: "ROSN",
    slug: "roseon-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roseon-finance.png",
  },
  {
    name: "Genshin Impact Token",
    symbol: "GenIm",
    slug: "genshinimpact",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genshinimpact.png",
  },
  {
    name: "MADworld",
    symbol: "UMAD",
    slug: "madworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/madworld.png",
  },
  {
    name: "Military Finance",
    symbol: "MIL",
    slug: "military-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/military-finance.png",
  },
  {
    name: "Galabet Casino",
    symbol: "GALABET",
    slug: "galabet-casino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galabet-casino.png",
  },
  {
    name: "DeHorizon",
    symbol: "DEVT",
    slug: "dehorizon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dehorizon.png",
  },
  {
    name: "Libre DeFi",
    symbol: "LIBRE",
    slug: "libre-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libre-defi.png",
  },
  {
    name: "UniPower",
    symbol: "POWER",
    slug: "unipower",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unipower.png",
  },
  {
    name: "Arctic Finance",
    symbol: "AURORA",
    slug: "arctic-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arctic-finance.png",
  },
  {
    name: "Antex",
    symbol: "ANTEX",
    slug: "antex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antex.png",
  },
  {
    name: "Totally A Rug Pull",
    symbol: "TARP",
    slug: "totally-a-rug-pull",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/totally-a-rug-pull.png",
  },
  {
    name: "Eleven Finance",
    symbol: "ELE",
    slug: "eleven-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eleven-finance.png",
  },
  {
    name: "Decentralized data crypto system",
    symbol: "DCS",
    slug: "decentralized-data-crypto-system",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-data-crypto-system.png",
  },
  {
    name: "ShibaZilla",
    symbol: "SHIBZ",
    slug: "shibazilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibazilla.png",
  },
  {
    name: "Round Dollar",
    symbol: "RD",
    slug: "round-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/round-dollar.png",
  },
  {
    name: "Governance OHM",
    symbol: "GOHM",
    slug: "governance-ohm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/governance-ohm.png",
  },
  {
    name: "LoserChick EGG",
    symbol: "EGG",
    slug: "loserchick-egg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loserchick-egg.png",
  },
  {
    name: "99Starz",
    symbol: "STZ",
    slug: "99starz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/99starz.png",
  },
  {
    name: "CZFarm",
    symbol: "CZF",
    slug: "czfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/czfarm.png",
  },
  {
    name: "DeFi City",
    symbol: "DFC",
    slug: "defi-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-city.png",
  },
  {
    name: "GameDAO",
    symbol: "DAO",
    slug: "game-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game-dao.png",
  },
  {
    name: "Cats Claw",
    symbol: "CLAW",
    slug: "cats-claw",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cats-claw.png",
  },
  {
    name: "Obsidium",
    symbol: "OBS",
    slug: "obsidium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/obsidium.png",
  },
  {
    name: "Woonkly Power",
    symbol: "WOOP",
    slug: "woonkly-power",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/woonkly-power.png",
  },
  {
    name: "Toshimon",
    symbol: "TOSHI",
    slug: "toshimon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toshimon.png",
  },
  {
    name: "Cocktail",
    symbol: "COCKTAIL",
    slug: "cocktail",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cocktail.png",
  },
  {
    name: "SuperWhale",
    symbol: "WAROO",
    slug: "superwhale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superwhale.png",
  },
  {
    name: "GamesPad",
    symbol: "GMPD",
    slug: "gamespad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamespad.png",
  },
  {
    name: "SEOR",
    symbol: "SEOR",
    slug: "seor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seor.png",
  },
  {
    name: "AIPRO",
    symbol: "AIPRO",
    slug: "aipro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aipro.png",
  },
  {
    name: "Cometh",
    symbol: "MUST",
    slug: "cometh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cometh.png",
  },
  {
    name: "AmazingTeamDAO",
    symbol: "AMAZINGTEAM",
    slug: "amazingteamdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amazingteamdao.png",
  },
  {
    name: "ChargeDeFi Static",
    symbol: "STATIC",
    slug: "chargedefi-static",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chargedefi-static.png",
  },
  {
    name: "Rooster Battle",
    symbol: "RICE",
    slug: "rooster-battle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rooster-battle.png",
  },
  {
    name: "Hibiki Finance",
    symbol: "HIBIKI",
    slug: "hibiki-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hibiki-finance.png",
  },
  {
    name: "Luto Cash",
    symbol: "LUTO",
    slug: "luto-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luto-cash.png",
  },
  {
    name: "eHive",
    symbol: "EHIVE",
    slug: "ehive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ehive.png",
  },
  {
    name: "HZM Coin",
    symbol: "HZM",
    slug: "hzm-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hzm-coin.png",
  },
  {
    name: "Xiglute Coin",
    symbol: "XGC",
    slug: "xiglute-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xiglute-coin.png",
  },
  {
    name: "Vari-Stable Capital",
    symbol: "VSC",
    slug: "vari-stable-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vari-stable-capital.png",
  },
  {
    name: "Dogecoin 2.0",
    symbol: "DOGE2",
    slug: "dogecoin-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogecoin-2.png",
  },
  {
    name: "Decentral Games Governance (xDG)",
    symbol: "xDG",
    slug: "decentral-games-governance-xdg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentral-games-governance-xdg.png",
  },
  {
    name: "Cacti Club",
    symbol: "CACTI",
    slug: "cacti-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cacti-club.png",
  },
  {
    name: "Doge Back",
    symbol: "DOGEBACK",
    slug: "doge-back",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-back.png",
  },
  {
    name: "PapaCake",
    symbol: "PAPACAKE",
    slug: "papacake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/papacake.png",
  },
  {
    name: "ONINO",
    symbol: "ONI",
    slug: "oni-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oni-token.png",
  },
  {
    name: "Penguin Finance",
    symbol: "PEFI",
    slug: "penguin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/penguin-finance.png",
  },
  {
    name: "ROBINOS",
    symbol: "RBN",
    slug: "robinos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robinos.png",
  },
  {
    name: "Ethera",
    symbol: "ETA",
    slug: "ethera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethera.png",
  },
  {
    name: "BabyEgg",
    symbol: "BABYEGG",
    slug: "babyegg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyegg.png",
  },
  {
    name: "SUPER MARIO",
    symbol: "MARIO",
    slug: "super-mario",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-mario.png",
  },
  {
    name: "Fortress Lending",
    symbol: "FTS",
    slug: "fortress-lending",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fortress-lending.png",
  },
  {
    name: "XPLA",
    symbol: "XPLA",
    slug: "xpla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xpla.png",
  },
  {
    name: "Squid Moon",
    symbol: "SQM",
    slug: "squid-moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squid-moon.png",
  },
  {
    name: "XDoge Network",
    symbol: "XDOGE",
    slug: "xdoge-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdoge-network.png",
  },
  {
    name: "Bitcoin Avalanche Bridged",
    symbol: "BTC.b",
    slug: "bitcoin-avalanche-bridged",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-avalanche-bridged.png",
  },
  {
    name: "Next Cardano",
    symbol: "NADA",
    slug: "next-cardano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/next-cardano.png",
  },
  {
    name: "Hypersign identity",
    symbol: "HID",
    slug: "hypersign-identity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hypersign-identity.png",
  },
  {
    name: "MicroChains Gov Token",
    symbol: "MCG",
    slug: "microchains-gov-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microchains-gov-token.png",
  },
  {
    name: "Zeus Node Finance",
    symbol: "ZEUS",
    slug: "zeus-node-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeus-node-finance.png",
  },
  {
    name: "SHIBA2K22",
    symbol: "SHIBA22",
    slug: "shiba2k22",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba2k22.png",
  },
  {
    name: "King Cardano",
    symbol: "KADA",
    slug: "king-cardano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-cardano.png",
  },
  {
    name: "Blue Baikal",
    symbol: "BBC",
    slug: "blue-baikal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blue-baikal.png",
  },
  {
    name: "CherryPick",
    symbol: "CHERRY",
    slug: "cherrypick",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cherrypick.png",
  },
  {
    name: "VNX Gold",
    symbol: "VNXAU",
    slug: "vnx-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vnx-gold.png",
  },
  {
    name: "MemeNFT",
    symbol: "MNFT",
    slug: "memenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memenft.png",
  },
  {
    name: "Lunar",
    symbol: "LNR",
    slug: "lunar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunar.png",
  },
  {
    name: "Natiol Infinity",
    symbol: "NAI",
    slug: "natiol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/natiol.png",
  },
  {
    name: "HeadStarter",
    symbol: "HST",
    slug: "headstarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/headstarter.png",
  },
  {
    name: "Waves Ducks",
    symbol: "EGG",
    slug: "waves-ducks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waves-ducks.png",
  },
  {
    name: "LITUNI",
    symbol: "LITO",
    slug: "lituni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lituni.png",
  },
  {
    name: "CyberDragon Gold",
    symbol: "GOLD",
    slug: "cyberdragon-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyberdragon-gold.png",
  },
  {
    name: "PulseFeg",
    symbol: "PULSEFEG",
    slug: "pulsefeg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pulsefeg.png",
  },
  {
    name: "SafeX",
    symbol: "SFEX",
    slug: "safelaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safelaunch.png",
  },
  {
    name: "Wault [New]",
    symbol: "WAULTX",
    slug: "wault-finance-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wault-finance-new.png",
  },
  {
    name: "Kart Racing League",
    symbol: "KRL",
    slug: "kart-racing-league",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kart-racing-league.png",
  },
  {
    name: "MOTHEREARTH",
    symbol: "MOT",
    slug: "motherearth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/motherearth.png",
  },
  {
    name: "ALL.ART protocol",
    symbol: "AART",
    slug: "all-art-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/all-art-protocol.png",
  },
  {
    name: "Goes Up Higher",
    symbol: "GUH",
    slug: "goes-up-higher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goes-up-higher.png",
  },
  {
    name: "Baby ADA",
    symbol: "BABYADA",
    slug: "baby-ada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-ada.png",
  },
  {
    name: "reBaked",
    symbol: "BAKED",
    slug: "rebaked",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rebaked.png",
  },
  {
    name: "Wombat Web 3 Gaming Platform",
    symbol: "WOMBAT",
    slug: "wombat-web-3-gaming-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wombat-web-3-gaming-platform.png",
  },
  {
    name: "Lend Flare",
    symbol: "LFT",
    slug: "lend-flare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lend-flare.png",
  },
  {
    name: "Morpheus Token",
    symbol: "MORPH",
    slug: "morpheus-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morpheus-token.png",
  },
  {
    name: "JERITEX",
    symbol: "JRIT",
    slug: "jeritex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jeritex.png",
  },
  {
    name: "ritestream",
    symbol: "RITE",
    slug: "ritestream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ritestream.png",
  },
  {
    name: "Totem Finance",
    symbol: "TOTEM",
    slug: "totem-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/totem-finance.png",
  },
  {
    name: "DrunkDoge",
    symbol: "DRUNK",
    slug: "drunkdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drunkdoge.png",
  },
  {
    name: "XELS",
    symbol: "XELS",
    slug: "xels",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xels.png",
  },
  {
    name: "PhiFi Finance",
    symbol: "PHIFIV2",
    slug: "phifi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phifi-finance.png",
  },
  {
    name: "Afreum",
    symbol: "AFR",
    slug: "afreum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/afreum.png",
  },
  {
    name: "Quantum",
    symbol: "QUA",
    slug: "quantum-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quantum-tech.png",
  },
  {
    name: "CatCoin",
    symbol: "CATCOIN",
    slug: "catcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catcoin.png",
  },
  {
    name: "Step Hero Soul",
    symbol: "STEP",
    slug: "step-hero-step",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/step-hero-step.png",
  },
  {
    name: "Rinnegan",
    symbol: "REN",
    slug: "rinnegan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rinnegan.png",
  },
  {
    name: "Kalmar",
    symbol: "KALM",
    slug: "kalmar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalmar.png",
  },
  {
    name: "Floki New Year",
    symbol: "FLOKINY",
    slug: "floki-new-year",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-new-year.png",
  },
  {
    name: "Crypto Perx",
    symbol: "CPRX",
    slug: "crypto-perx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-perx.png",
  },
  {
    name: "Charged Particles",
    symbol: "IONX",
    slug: "charged-particles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/charged-particles.png",
  },
  {
    name: "Buffaloswap RED",
    symbol: "REDBUFF",
    slug: "buffaloswap-red",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buffaloswap-red.png",
  },
  {
    name: "Footie Plus",
    symbol: "FOOTIE",
    slug: "footie-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/footie-plus.png",
  },
  {
    name: "Yuan",
    symbol: "YUAN",
    slug: "yuan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yuan.png",
  },
  {
    name: "Paralink Network",
    symbol: "PARA",
    slug: "paralink-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paralink-network.png",
  },
  {
    name: "Shiba Lite",
    symbol: "SHIBLITE",
    slug: "shiba-lite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-lite.png",
  },
  {
    name: "SolChicks Token",
    symbol: "CHICKS",
    slug: "chicks-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chicks-token.png",
  },
  {
    name: "Monsoon Finance",
    symbol: "MCASH",
    slug: "monsoon-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monsoon-finance.png",
  },
  {
    name: "NFT Worlds",
    symbol: "WRLD",
    slug: "nft-worlds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-worlds.png",
  },
  {
    name: "Melody",
    symbol: "SGS",
    slug: "melody-sgs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/melody-sgs.png",
  },
  {
    name: "Altrucoin",
    symbol: "ALTRUCOIN",
    slug: "altrucoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altrucoin.png",
  },
  {
    name: "Shita-kiri Suzume",
    symbol: "SUZUME",
    slug: "shita-kiri-suzume",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shita-kiri-suzume.png",
  },
  {
    name: "Papel Token",
    symbol: "PAPEL",
    slug: "papel-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/papel-token.png",
  },
  {
    name: "DinoExchange",
    symbol: "DINO",
    slug: "dinoexchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinoexchange.png",
  },
  {
    name: "Night Life Crypto",
    symbol: "NLIFE",
    slug: "night-life-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/night-life-crypto.png",
  },
  {
    name: "Shih Tzu Inu",
    symbol: "SHIH-TZU",
    slug: "shih-tzu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shih-tzu-inu.png",
  },
  {
    name: "Candylad",
    symbol: "CANDYLAD",
    slug: "candylad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/candylad.png",
  },
  {
    name: "Lightcoin",
    symbol: "LHC",
    slug: "lightcoin-lhc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lightcoin-lhc.png",
  },
  {
    name: "Evoverse Power",
    symbol: "EPW",
    slug: "evoverse-power",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evoverse-power.png",
  },
  {
    name: "UniArts",
    symbol: "UART",
    slug: "uniarts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniarts.png",
  },
  {
    name: "PrivacySwap",
    symbol: "PRV",
    slug: "privacyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/privacyswap.png",
  },
  {
    name: "Point Coin",
    symbol: "POINT",
    slug: "point-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/point-coin.png",
  },
  {
    name: "Spain National Fan Token",
    symbol: "SNFT",
    slug: "spain-national-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spain-national-fan-token.png",
  },
  {
    name: "Earnable",
    symbol: "EARN",
    slug: "earnable",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnable.png",
  },
  {
    name: "Binance8",
    symbol: "B8",
    slug: "binance8",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binance8.png",
  },
  {
    name: "Banana.finance",
    symbol: "BANANA",
    slug: "banana-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banana-finance.png",
  },
  {
    name: "Mecha Morphing",
    symbol: "MAPE",
    slug: "mecha-morphing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mecha-morphing.png",
  },
  {
    name: "Farm Finance",
    symbol: "FFT",
    slug: "farm-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/farm-finance.png",
  },
  {
    name: "LONG COIN",
    symbol: "LONG",
    slug: "long-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/long-coin.png",
  },
  {
    name: "Stable One Rocket",
    symbol: "SROCKET",
    slug: "stable-one-rocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stable-one-rocket.png",
  },
  {
    name: "Tenshi",
    symbol: "TENSHI",
    slug: "tenshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tenshi.png",
  },
  {
    name: "Colawork",
    symbol: "COLA",
    slug: "colawork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colawork.png",
  },
  {
    name: "Yoshi.exchange",
    symbol: "YOSHI",
    slug: "yoshi-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yoshi-exchange.png",
  },
  {
    name: "TruBadger",
    symbol: "TRUBGR",
    slug: "trubadger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trubadger.png",
  },
  {
    name: "AVNRich Token",
    symbol: "AVN",
    slug: "avnrich-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avnrich-token.png",
  },
  {
    name: "Lumerin",
    symbol: "LMR",
    slug: "lumerin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lumerin.png",
  },
  {
    name: "MetFX Watch To Earn",
    symbol: "MFX",
    slug: "metfx-watch-to-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metfx-watch-to-earn.png",
  },
  {
    name: "Asia Pacific Electronic Coin",
    symbol: "APECOIN",
    slug: "asia-pacific-electronic-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asia-pacific-electronic-coin.png",
  },
  {
    name: "Crypto Kombat",
    symbol: "KOMBAT",
    slug: "crypto-kombat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-kombat.png",
  },
  {
    name: "ArenaPlay",
    symbol: "APC",
    slug: "arenaplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arenaplay.png",
  },
  {
    name: "Gemie",
    symbol: "GEM",
    slug: "gemie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gemie.png",
  },
  {
    name: "BitMEX Token",
    symbol: "BMEX",
    slug: "bitmex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitmex-token.png",
  },
  {
    name: "Pacific",
    symbol: "PAF",
    slug: "pacific",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pacific.png",
  },
  {
    name: "SoldierNodes",
    symbol: "SLD",
    slug: "soldiernodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soldiernodes.png",
  },
  {
    name: "ANTIS INU",
    symbol: "ANTIS",
    slug: "antis-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antis-inu.png",
  },
  {
    name: "Rocket Raccoon",
    symbol: "ROC",
    slug: "rocket-raccoon-roc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-raccoon-roc.png",
  },
  {
    name: "Xenon Pay",
    symbol: "X2P",
    slug: "xenon-pay-ii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xenon-pay-ii.png",
  },
  {
    name: "300FIT NETWORK",
    symbol: "FIT",
    slug: "300fit-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/300fit-network.png",
  },
  {
    name: "Optimus OPT2",
    symbol: "OPT2",
    slug: "optimus-opt2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/optimus-opt2.png",
  },
  {
    name: "ysoy chain farm",
    symbol: "YSOY",
    slug: "ysoy-chain-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ysoy-chain-farm.png",
  },
  {
    name: "Pkoin",
    symbol: "PKOIN",
    slug: "pocketnet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocketnet.png",
  },
  {
    name: "Koji",
    symbol: "KOJI",
    slug: "koji",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koji.png",
  },
  {
    name: "PAXW",
    symbol: "PAXW",
    slug: "pax-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pax-world.png",
  },
  {
    name: "MissDoge",
    symbol: "MDOGE",
    slug: "missdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/missdoge.png",
  },
  {
    name: "Unvaxxed Sperm",
    symbol: "NUBTC",
    slug: "unvaxxed-sperm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unvaxxed-sperm.png",
  },
  {
    name: "Zelda Inu",
    symbol: "ZLDA",
    slug: "zelda-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zelda-inu.png",
  },
  {
    name: "BabyEth",
    symbol: "BABYETH",
    slug: "babyeth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyeth.png",
  },
  {
    name: "QuiverX",
    symbol: "QRX",
    slug: "quiverx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quiverx.png",
  },
  {
    name: "PoodleFi",
    symbol: "PFI",
    slug: "poodlefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poodlefi.png",
  },
  {
    name: "Coin of Nature",
    symbol: "CON",
    slug: "coin-of-nature",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-of-nature.png",
  },
  {
    name: "PathFund",
    symbol: "PATH",
    slug: "pathfund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pathfund.png",
  },
  {
    name: "Liquid Driver",
    symbol: "LQDR",
    slug: "liquid-driver",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquid-driver.png",
  },
  {
    name: "ReadFi",
    symbol: "$RDF",
    slug: "readfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/readfi.png",
  },
  {
    name: "ARCS",
    symbol: "ARX",
    slug: "arcs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcs.png",
  },
  {
    name: "Evmos",
    symbol: "EVMOS",
    slug: "evmos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evmos.png",
  },
  {
    name: "COXSWAP",
    symbol: "COX",
    slug: "coxswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coxswap.png",
  },
  {
    name: "DappRadar",
    symbol: "RADAR",
    slug: "dappradar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dappradar.png",
  },
  {
    name: "Privi PIX",
    symbol: "PIX",
    slug: "privi-pix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/privi-pix.png",
  },
  {
    name: "PetWorld",
    symbol: "PW",
    slug: "petworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petworld.png",
  },
  {
    name: "Yuan Chain Coin",
    symbol: "YCC",
    slug: "yuan-chain-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yuan-chain-coin.png",
  },
  {
    name: "IPVERSE",
    symbol: "IPV",
    slug: "ipverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ipverse.png",
  },
  {
    name: "TRONPAD",
    symbol: "TRONPAD",
    slug: "tronpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tronpad.png",
  },
  {
    name: "Galaxy Adventure",
    symbol: "GLA",
    slug: "galaxy-adventure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-adventure.png",
  },
  {
    name: "Light Year",
    symbol: "LC",
    slug: "light-year",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/light-year.png",
  },
  {
    name: "Tapcoin",
    symbol: "TTT",
    slug: "tapcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tapcoin.png",
  },
  {
    name: "Walken",
    symbol: "WLKN",
    slug: "walken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/walken.png",
  },
  {
    name: "TribeOne",
    symbol: "HAKA",
    slug: "tribe-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tribe-one.png",
  },
  {
    name: "Lung Protocol",
    symbol: "L2P",
    slug: "lung-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lung-protocol.png",
  },
  {
    name: "Compound Uni",
    symbol: "CUNI",
    slug: "compound-uniswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-uniswap.png",
  },
  {
    name: "Serum SER",
    symbol: "SER",
    slug: "serum-ser",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/serum-ser.png",
  },
  {
    name: "MakiSwap",
    symbol: "MAKI",
    slug: "makiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/makiswap.png",
  },
  {
    name: "CakeUp",
    symbol: "CAKEUP",
    slug: "cakeup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cakeup.png",
  },
  {
    name: "KlayUniverse",
    symbol: "KUT",
    slug: "klayuniverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klayuniverse.png",
  },
  {
    name: "Super Squid Grow",
    symbol: "SUPERSQUID",
    slug: "super-squid-grow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-squid-grow.png",
  },
  {
    name: "Lucky Lion",
    symbol: "LUCKY",
    slug: "lucky-lion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-lion.png",
  },
  {
    name: "Big Brain Capital DAO",
    symbol: "BBC DAO",
    slug: "big-brain-capital-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-brain-capital-dao.png",
  },
  {
    name: "Pecora Network",
    symbol: "PEN",
    slug: "pecora-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pecora-network.png",
  },
  {
    name: "Lucky Coin",
    symbol: "LKC",
    slug: "lucky-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-coin.png",
  },
  {
    name: "DataHighway",
    symbol: "DHX",
    slug: "datahighway",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/datahighway.png",
  },
  {
    name: "xHunter",
    symbol: "XHT",
    slug: "xhunter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xhunter.png",
  },
  {
    name: "Metanept",
    symbol: "NEPT",
    slug: "metanept",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metanept.png",
  },
  {
    name: "Txbit Token",
    symbol: "TXBIT",
    slug: "txbit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/txbit-token.png",
  },
  {
    name: "TOMI",
    symbol: "TOMI",
    slug: "tomi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tomi.png",
  },
  {
    name: "PACT community token",
    symbol: "PACT",
    slug: "pact-community-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pact-community-token.png",
  },
  {
    name: "RugZombie",
    symbol: "ZMBE",
    slug: "rugzombie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rugzombie.png",
  },
  {
    name: "Fanverse",
    symbol: "FT",
    slug: "fanverse-",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanverse-.png",
  },
  {
    name: "Ecowatt",
    symbol: "EWT",
    slug: "ecowatt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecowatt.png",
  },
  {
    name: "Flare Finance",
    symbol: "EXFI",
    slug: "flare-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flare-finance.png",
  },
  {
    name: "Mars Network",
    symbol: "MARS",
    slug: "mars-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mars-network.png",
  },
  {
    name: "PLC Ultima",
    symbol: "PLCU",
    slug: "plc-ultima",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plc-ultima.png",
  },
  {
    name: "Hatoken",
    symbol: "HATOK",
    slug: "hatoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hatoken.png",
  },
  {
    name: "wanUSDT",
    symbol: "WANUSDT",
    slug: "wanusdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanusdt.png",
  },
  {
    name: "Nexus Protocol",
    symbol: "PSI",
    slug: "nexus-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nexus-protocol.png",
  },
  {
    name: "Ninja Doge",
    symbol: "$NINJADOGE",
    slug: "ninja-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninja-doge.png",
  },
  {
    name: "The Genesis Block",
    symbol: "GENBLOK",
    slug: "the-genesis-block",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-genesis-block.png",
  },
  {
    name: "CRIPCO",
    symbol: "IP3",
    slug: "cripco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cripco.png",
  },
  {
    name: "Phoenixchain",
    symbol: "PCN",
    slug: "phoenixchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenixchain.png",
  },
  {
    name: "The Crypto You",
    symbol: "MILK",
    slug: "the-crypto-you",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-crypto-you.png",
  },
  {
    name: "Cook Protocol",
    symbol: "COOK",
    slug: "cook-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cook-protocol.png",
  },
  {
    name: "Wisteria Swap",
    symbol: "WST",
    slug: "wisteria-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wisteria-swap.png",
  },
  {
    name: "Akropolis Delphi",
    symbol: "ADEL",
    slug: "akropolis-delphi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akropolis-delphi.png",
  },
  {
    name: "BEAGLE CAKE",
    symbol: "BEAGLECAKE",
    slug: "beaglecake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beaglecake.png",
  },
  {
    name: "Dynamix",
    symbol: "DYNA",
    slug: "dynamix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dynamix.png",
  },
  {
    name: "Carbon Coin",
    symbol: "CBC",
    slug: "carbon-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbon-coin.png",
  },
  {
    name: "Cross Chain Capital",
    symbol: "CCC",
    slug: "cross-chain-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cross-chain-capital.png",
  },
  {
    name: "BlowUP",
    symbol: "$BLOW",
    slug: "blowup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blowup.png",
  },
  {
    name: "4JNET",
    symbol: "4JNET",
    slug: "4jnet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/4jnet.png",
  },
  {
    name: "Liquid Crowdloan DOT",
    symbol: "LCDOT",
    slug: "liquid-crowdloan-dot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquid-crowdloan-dot.png",
  },
  {
    name: "Sportemon-Go",
    symbol: "SGO",
    slug: "sportemon-go",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sportemon-go.png",
  },
  {
    name: "Metars Genesis",
    symbol: "MRS",
    slug: "metars-genesis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metars-genesis.png",
  },
  {
    name: "Occam.Fi",
    symbol: "OCC",
    slug: "occamfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/occamfi.png",
  },
  {
    name: "OVERLORD GAME",
    symbol: "OVL",
    slug: "overlord-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/overlord-game.png",
  },
  {
    name: "Fileshare Platform",
    symbol: "FSC",
    slug: "fileshare-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fileshare-platform.png",
  },
  {
    name: "Shabu Shabu Finance",
    symbol: "KOBE",
    slug: "shabu-shabu-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shabu-shabu-finance.png",
  },
  {
    name: "Brazil National Football Team Fan Token",
    symbol: "BFT",
    slug: "brazil-national-football-team-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brazil-national-football-team-fan-token.png",
  },
  {
    name: "PolkaEx",
    symbol: "PKEX",
    slug: "polkaex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkaex.png",
  },
  {
    name: "Webfour",
    symbol: "WEBFOUR",
    slug: "webfour",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/webfour.png",
  },
  {
    name: "The Killbox",
    symbol: "KBOX",
    slug: "the-killbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-killbox.png",
  },
  {
    name: "Dragon Warrior",
    symbol: "GON+",
    slug: "dragon-warrior",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-warrior.png",
  },
  {
    name: "Wrapped WAN",
    symbol: "WWAN",
    slug: "wrapped-wan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-wan.png",
  },
  {
    name: "RunBlox",
    symbol: "RUX",
    slug: "runblox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/runblox.png",
  },
  {
    name: "GameGuru",
    symbol: "GGT",
    slug: "gameguru",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gameguru.png",
  },
  {
    name: "Baby Floki (BSC)",
    symbol: "BABYFLOKI",
    slug: "baby-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-floki.png",
  },
  {
    name: "SafeMoon Inu",
    symbol: "SMI",
    slug: "safemoon-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemoon-inu.png",
  },
  {
    name: "CoinViewCap",
    symbol: "CVC",
    slug: "coinviewcap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinviewcap.png",
  },
  {
    name: "KAKA NFT World",
    symbol: "KAKA",
    slug: "kaka-nft-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaka-nft-world.png",
  },
  {
    name: "Bored Museum",
    symbol: "BORED",
    slug: "bored-museum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bored-museum.png",
  },
  {
    name: "Moonshot",
    symbol: "MOONSHOT",
    slug: "moonshot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonshot.png",
  },
  {
    name: "CryptoTanks",
    symbol: "TANK",
    slug: "cryptotanks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptotanks.png",
  },
  {
    name: "E-coin Finance",
    symbol: "ECOIN",
    slug: "e-coin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/e-coin-finance.png",
  },
  {
    name: "PulseDogecoin",
    symbol: "PLSD",
    slug: "pulsedogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pulsedogecoin.png",
  },
  {
    name: "Block Creatures",
    symbol: "MOOLAH",
    slug: "block-creatures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block-creatures.png",
  },
  {
    name: "GreenCoin.AI",
    symbol: "GRC",
    slug: "greencoin-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greencoin-ai.png",
  },
  {
    name: "TAP FANTASY",
    symbol: "TAP",
    slug: "tap-fantasy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tap-fantasy.png",
  },
  {
    name: "GoldRushToken",
    symbol: "GRUSH",
    slug: "goldrushtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldrushtoken.png",
  },
  {
    name: "Pikachu Inu",
    symbol: "PIKACHU",
    slug: "pikachu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pikachu-inu.png",
  },
  {
    name: "CaliCoin",
    symbol: "CALI",
    slug: "calicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calicoin.png",
  },
  {
    name: "TCGCoin 2.0",
    symbol: "TCG2",
    slug: "tcgcoin-2-0",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tcgcoin-2-0.png",
  },
  {
    name: "MetaBullRun",
    symbol: "MBR",
    slug: "metabullrun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabullrun.png",
  },
  {
    name: "The Grand Banks",
    symbol: "GRAND",
    slug: "the-grand-banks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-grand-banks.png",
  },
  {
    name: "Ape-X",
    symbol: "APE-X",
    slug: "ape-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ape-x.png",
  },
  {
    name: "DRIVEZ",
    symbol: "DRIV",
    slug: "drivez",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drivez.png",
  },
  {
    name: "BladeWarrior",
    symbol: "BLADE",
    slug: "bladewarrior",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bladewarrior.png",
  },
  {
    name: "Tiki Token",
    symbol: "TIKI",
    slug: "tiki-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiki-token.png",
  },
  {
    name: "FoodChain Global",
    symbol: "FOOD",
    slug: "foodchain-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foodchain-global.png",
  },
  {
    name: "BeFasterHolderToken",
    symbol: "BFHT",
    slug: "befasterholdertoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/befasterholdertoken.png",
  },
  {
    name: "Decentral Games ICE",
    symbol: "ICE",
    slug: "decentral-games-ice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentral-games-ice.png",
  },
  {
    name: "KuSwap",
    symbol: "KUS",
    slug: "kuswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuswap.png",
  },
  {
    name: "Starnodes",
    symbol: "STRZ",
    slug: "starnodes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starnodes.png",
  },
  {
    name: "Binanomics",
    symbol: "BNM",
    slug: "binanomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binanomics.png",
  },
  {
    name: "QITMEER NETWORK",
    symbol: "MEER",
    slug: "qitmeer-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qitmeer-network.png",
  },
  {
    name: "Metaverser",
    symbol: "MTVT",
    slug: "metaverser",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverser.png",
  },
  {
    name: "LocalTrade",
    symbol: "LTT",
    slug: "localtrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/localtrade.png",
  },
  {
    name: "SOS Foundation",
    symbol: "SOS",
    slug: "sos-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sos-foundation.png",
  },
  {
    name: "Teslafan",
    symbol: "TESLF",
    slug: "teslafan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teslafan.png",
  },
  {
    name: "Lil Doge Floki",
    symbol: "LDF",
    slug: "lil-doge-floki-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lil-doge-floki-token.png",
  },
  {
    name: "Radix Protocol",
    symbol: "XRD",
    slug: "radix-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radix-protocol.png",
  },
  {
    name: "Qubism",
    symbol: "QUB",
    slug: "qubism",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qubism.png",
  },
  {
    name: "TendieSwap",
    symbol: "TENDIE",
    slug: "tendieswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tendieswap.png",
  },
  {
    name: "Infomatix",
    symbol: "INFO",
    slug: "informatix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/informatix.png",
  },
  {
    name: "Baby Floki Billionaire",
    symbol: "BabyFB",
    slug: "baby-floki-billionaire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-floki-billionaire.png",
  },
  {
    name: "Bubblefong",
    symbol: "BBF",
    slug: "bubblefong",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bubblefong.png",
  },
  {
    name: "Orbler",
    symbol: "ORBR",
    slug: "orbler",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbler.png",
  },
  {
    name: "TRXDOWN",
    symbol: "TRXDOWN",
    slug: "trxdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trxdown.png",
  },
  {
    name: "Streakk",
    symbol: "STKK",
    slug: "streakk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/streakk.png",
  },
  {
    name: "PWAY",
    symbol: "PWAY",
    slug: "pway",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pway.png",
  },
  {
    name: "Plato Farm (PLATO)",
    symbol: "PLATO",
    slug: "plato-farm-plato",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plato-farm-plato.png",
  },
  {
    name: "BAE",
    symbol: "BAE",
    slug: "bae",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bae.png",
  },
  {
    name: "TOKOK",
    symbol: "TOK",
    slug: "tokok",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokok.png",
  },
  {
    name: "Delio DSP",
    symbol: "DSP",
    slug: "delio-dsp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/delio-dsp.png",
  },
  {
    name: "RooCoin",
    symbol: "ROO",
    slug: "roocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roocoin.png",
  },
  {
    name: "Moola Celo USD",
    symbol: "mCUSD",
    slug: "moola-celo-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moola-celo-usd.png",
  },
  {
    name: "SafeMars",
    symbol: "SMARS",
    slug: "smars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smars.png",
  },
  {
    name: "METAF1",
    symbol: "F1T",
    slug: "metaf1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaf1.png",
  },
  {
    name: "Baby Floki Coin",
    symbol: "BABYFLOKICOIN",
    slug: "baby-floki-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-floki-coin.png",
  },
  {
    name: "CanaryX",
    symbol: "CNYX",
    slug: "canaryx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/canaryx.png",
  },
  {
    name: "WanSwap",
    symbol: "WASP",
    slug: "wanswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanswap.png",
  },
  {
    name: "Golden Roots",
    symbol: "GDR",
    slug: "golden-roots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-roots.png",
  },
  {
    name: "BankerDoge",
    symbol: "BANKER",
    slug: "bankerdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankerdoge.png",
  },
  {
    name: "Asva",
    symbol: "ASVA",
    slug: "asva-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asva-finance.png",
  },
  {
    name: "White Whale",
    symbol: "WHALE",
    slug: "white-whale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/white-whale.png",
  },
  {
    name: "PolyShield Finance",
    symbol: "SHI3LD",
    slug: "polyshield-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyshield-finance.png",
  },
  {
    name: "Cube Network",
    symbol: "CUBE",
    slug: "cube-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cube-network.png",
  },
  {
    name: "Meta Islands",
    symbol: "IGO",
    slug: "meta-islands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-islands.png",
  },
  {
    name: "UpCake",
    symbol: "UPC",
    slug: "upcake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upcake.png",
  },
  {
    name: "TRAVA.FINANCE",
    symbol: "TRAVA",
    slug: "trava-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trava-finance.png",
  },
  {
    name: "Tsuki Inu",
    symbol: "TKINU",
    slug: "tsuki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tsuki-inu.png",
  },
  {
    name: "TKBToken",
    symbol: "TKB",
    slug: "tkbtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tkbtoken.png",
  },
  {
    name: "Shrew",
    symbol: "SHREW",
    slug: "shrew",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shrew.png",
  },
  {
    name: "SpaceMine",
    symbol: "MINE",
    slug: "spacemine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacemine.png",
  },
  {
    name: "Nobility [new]",
    symbol: "NBL",
    slug: "nobility-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nobility-new.png",
  },
  {
    name: "Index Cooperative",
    symbol: "INDEX",
    slug: "index-cooperative",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/index-cooperative.png",
  },
  {
    name: "Jones DAO",
    symbol: "JONES",
    slug: "jones-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jones-dao.png",
  },
  {
    name: "Windex",
    symbol: "WDEX",
    slug: "windex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/windex.png",
  },
  {
    name: "BinStarter",
    symbol: "BSR",
    slug: "binstarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binstarter.png",
  },
  {
    name: "MarX",
    symbol: "MARX",
    slug: "marx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marx.png",
  },
  {
    name: "Fishy Tank Token",
    symbol: "FTE",
    slug: "fishy-tank-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fishy-tank-token.png",
  },
  {
    name: "Sipher",
    symbol: "SIPHER",
    slug: "sipher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sipher.png",
  },
  {
    name: "Moonpoly",
    symbol: "CMP",
    slug: "moonpoly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonpoly.png",
  },
  {
    name: "Unit Protocol Duck",
    symbol: "DUCK",
    slug: "unit-protocol-duck",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unit-protocol-duck.png",
  },
  {
    name: "Wrapped PKT",
    symbol: "WPKT",
    slug: "wrapped-pkt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-pkt.png",
  },
  {
    name: "Metarun",
    symbol: "MRUN",
    slug: "metarun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metarun.png",
  },
  {
    name: "Pup Doge",
    symbol: "PUPDOGE",
    slug: "pup-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pup-doge.png",
  },
  {
    name: "Wateenswap",
    symbol: "WTN",
    slug: "wateenswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wateenswap.png",
  },
  {
    name: "Battle Pets",
    symbol: "PET",
    slug: "battle-pets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-pets.png",
  },
  {
    name: "FM Gallery",
    symbol: "FMG",
    slug: "fm-gallery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fm-gallery.png",
  },
  {
    name: "Shakita Inu",
    symbol: "SHAK",
    slug: "shakita-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shakita-inu.png",
  },
  {
    name: "SIDUS HEROES",
    symbol: "SIDUS",
    slug: "sidus-heroes-sidus-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sidus-heroes-sidus-token.png",
  },
  {
    name: "Buni Universal Reward",
    symbol: "BUR",
    slug: "buni-universal-reward",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buni-universal-reward.png",
  },
  {
    name: "Ethbox",
    symbol: "EBOX",
    slug: "ethbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethbox.png",
  },
  {
    name: "Verve",
    symbol: "VERVE",
    slug: "verve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verve.png",
  },
  {
    name: "New BTC",
    symbol: "NBTC",
    slug: "new-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-btc.png",
  },
  {
    name: "Kite Sync",
    symbol: "KITE",
    slug: "kite-sync",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kite-sync.png",
  },
  {
    name: "Convex CRV",
    symbol: "CVXCRV",
    slug: "convex-crv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/convex-crv.png",
  },
  {
    name: "Compound Basic Attention Token",
    symbol: "CBAT",
    slug: "compound-basic-attention-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-basic-attention-token.png",
  },
  {
    name: "Shiba Link",
    symbol: "SLINK",
    slug: "shiba-link",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-link.png",
  },
  {
    name: "Pikaster",
    symbol: "SRBP",
    slug: "pikaster-srbp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pikaster-srbp.png",
  },
  {
    name: "Byakko",
    symbol: "BYK",
    slug: "byakko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/byakko.png",
  },
  {
    name: "LunaChow",
    symbol: "LUCHOW",
    slug: "lunachow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunachow.png",
  },
  {
    name: "DrakeBall Token",
    symbol: "DBALL",
    slug: "drakeball-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drakeball-token.png",
  },
  {
    name: "Fusotao Protocol",
    symbol: "TAO",
    slug: "fusotao-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fusotao-protocol.png",
  },
  {
    name: "Steak Token",
    symbol: "STEAK",
    slug: "steak-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steak-token.png",
  },
  {
    name: "KonPay",
    symbol: "KON",
    slug: "konpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/konpay.png",
  },
  {
    name: "MagicCraft",
    symbol: "MCRT",
    slug: "magiccraft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magiccraft.png",
  },
  {
    name: "Stargaze",
    symbol: "STARS",
    slug: "stargaze",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stargaze.png",
  },
  {
    name: "Connectico",
    symbol: "CON",
    slug: "connectico",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/connectico.png",
  },
  {
    name: "Crazy Treasure",
    symbol: "CTT",
    slug: "crazy-treasure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crazy-treasure.png",
  },
  {
    name: "Etherrock#72",
    symbol: "PEBBLE",
    slug: "etherrock-72",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherrock-72.png",
  },
  {
    name: "ETHERLAND",
    symbol: "ELAND",
    slug: "etherland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherland.png",
  },
  {
    name: "ACCESSLAUNCHER",
    symbol: "ACX",
    slug: "accesslauncher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/accesslauncher.png",
  },
  {
    name: "CryptoZoo",
    symbol: "ZOO",
    slug: "cryptozoo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptozoo.png",
  },
  {
    name: "Small Fish Cookie",
    symbol: "SFC",
    slug: "small-fish-cookie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/small-fish-cookie.png",
  },
  {
    name: "Megaweapon",
    symbol: "$WEAPON",
    slug: "megaweapon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megaweapon.png",
  },
  {
    name: "Bilaxy Token",
    symbol: "BIA",
    slug: "bilaxy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bilaxy-token.png",
  },
  {
    name: "Doge Raca",
    symbol: "DOCA",
    slug: "doge-raca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-raca.png",
  },
  {
    name: "Vanesse",
    symbol: "VNES",
    slug: "vanesse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanesse.png",
  },
  {
    name: "Canon Crypto",
    symbol: "CATO",
    slug: "canon-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/canon-crypto.png",
  },
  {
    name: "ChronoBase",
    symbol: "TIK",
    slug: "chronobase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chronobase.png",
  },
  {
    name: "Netvrk",
    symbol: "NTVRK",
    slug: "netvrk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netvrk.png",
  },
  {
    name: "Doge-TV",
    symbol: "$DGTV",
    slug: "doge-tv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-tv.png",
  },
  {
    name: "Sports 2K75",
    symbol: "S2K",
    slug: "sports-2k75",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sports-2k75.png",
  },
  {
    name: "TRXUP",
    symbol: "TRXUP",
    slug: "trxup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trxup.png",
  },
  {
    name: "Gold Nugget",
    symbol: "NGT",
    slug: "cryptomines-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptomines-classic.png",
  },
  {
    name: "MooMonster",
    symbol: "MOO",
    slug: "moomonster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moomonster.png",
  },
  {
    name: "Monster Adventure Token",
    symbol: "MAT",
    slug: "monster-adventure-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-adventure-token.png",
  },
  {
    name: "Hectagon",
    symbol: "HECTA",
    slug: "hectagon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hectagon.png",
  },
  {
    name: "Domain Coin",
    symbol: "DMN",
    slug: "domain-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/domain-coin.png",
  },
  {
    name: "HEdpAY",
    symbol: "HDP.ф",
    slug: "hedpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedpay.png",
  },
  {
    name: "Piggy Finance",
    symbol: "PIGGY",
    slug: "piggy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piggy-finance.png",
  },
  {
    name: "Doom Hero Dao",
    symbol: "DHD",
    slug: "doom-hero-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doom-hero-dao.png",
  },
  {
    name: "Nether NFT",
    symbol: "NTR",
    slug: "nether-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nether-nft.png",
  },
  {
    name: "GoldMiner",
    symbol: "GM",
    slug: "goldminer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldminer.png",
  },
  {
    name: "MetaFabric",
    symbol: "FABRIC",
    slug: "metafabric",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafabric.png",
  },
  {
    name: "DoveSwap Finance",
    symbol: "DOVE",
    slug: "doveswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doveswap-finance.png",
  },
  {
    name: "ok.lets.go.",
    symbol: "OKLG",
    slug: "ok-lets-go",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ok-lets-go.png",
  },
  {
    name: "Erugo World Coin",
    symbol: "EWC",
    slug: "erugo-world-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/erugo-world-coin.png",
  },
  {
    name: "Binance Crunch",
    symbol: "CRUNCH",
    slug: "binance-crunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binance-crunch.png",
  },
  {
    name: "Nosana",
    symbol: "NOS",
    slug: "nosana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nosana.png",
  },
  {
    name: "Joystick Games",
    symbol: "JOY",
    slug: "joystick-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joystick-games.png",
  },
  {
    name: "Thoreum",
    symbol: "THOREUM",
    slug: "thoreum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thoreum.png",
  },
  {
    name: "Omax Token",
    symbol: "OMAX",
    slug: "omax-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omax-token.png",
  },
  {
    name: "DogeCola",
    symbol: "DOGECOLA",
    slug: "dogecola",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogecola.png",
  },
  {
    name: "SocialGood",
    symbol: "SG",
    slug: "socialgood",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/socialgood.png",
  },
  {
    name: "Die Protocol",
    symbol: "DIE",
    slug: "die-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/die-protocol.png",
  },
  {
    name: "EMP Shares",
    symbol: "ESHARE",
    slug: "emp-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emp-shares.png",
  },
  {
    name: "MetaDogecolony",
    symbol: "DOGECO",
    slug: "dogecolony",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogecolony.png",
  },
  {
    name: "ElonGate",
    symbol: "ELONGATE",
    slug: "elongate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elongate.png",
  },
  {
    name: "DeFiPie",
    symbol: "PIE",
    slug: "defipie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defipie.png",
  },
  {
    name: "Syfin",
    symbol: "SYF",
    slug: "syfin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/syfin.png",
  },
  {
    name: "CryptoShips",
    symbol: "CSHIP",
    slug: "cryptoships",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoships.png",
  },
  {
    name: "Rocket",
    symbol: "ROCKET",
    slug: "rocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket.png",
  },
  {
    name: "JungleKing TigerCoin",
    symbol: "TIGER",
    slug: "jungleking-tigercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jungleking-tigercoin.png",
  },
  {
    name: "GoCryptoMe",
    symbol: "GCME",
    slug: "gocryptome",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gocryptome.png",
  },
  {
    name: "MetaDrace",
    symbol: "DRACE",
    slug: "metadrace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadrace.png",
  },
  {
    name: "MINI SAFEMOON INU",
    symbol: "MSMI",
    slug: "mini-safemoon-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-safemoon-inu.png",
  },
  {
    name: "Giftedhands",
    symbol: "GHD",
    slug: "giftedhands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/giftedhands.png",
  },
  {
    name: "Spool DAO",
    symbol: "SPOOL",
    slug: "spool-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spool-dao.png",
  },
  {
    name: "ZUZ Protocol",
    symbol: "ZUZ",
    slug: "zuz-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zuz-protocol.png",
  },
  {
    name: "I will poop it NFT",
    symbol: "SHIT",
    slug: "i-will-poop-it-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/i-will-poop-it-nft.png",
  },
  {
    name: "Tate Token",
    symbol: "TOPG",
    slug: "tate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tate-token.png",
  },
  {
    name: "BlockWarrior",
    symbol: "BLWA",
    slug: "blockwarrior",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockwarrior.png",
  },
  {
    name: "DeFi Forge",
    symbol: "FORGE",
    slug: "defi-forge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-forge.png",
  },
  {
    name: "AssangeDAO",
    symbol: "JUSTICE",
    slug: "assangedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/assangedao.png",
  },
  {
    name: "2022MOON",
    symbol: "2022M",
    slug: "2022moon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2022moon.png",
  },
  {
    name: "Crypto Bay",
    symbol: "PEARL",
    slug: "crypto-bay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-bay.png",
  },
  {
    name: "GAIA EVERWorld",
    symbol: "GAIA",
    slug: "gaia-everworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gaia-everworld.png",
  },
  {
    name: "Dimitra Token",
    symbol: "DMTR",
    slug: "dimitra-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dimitra-token.png",
  },
  {
    name: "Moonbird",
    symbol: "MBIRD",
    slug: "moonbird",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonbird.png",
  },
  {
    name: "DEOR",
    symbol: "DEOR",
    slug: "deor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deor.png",
  },
  {
    name: "Ethereum Chain Token",
    symbol: "ECT",
    slug: "ethereum-chain-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-chain-token.png",
  },
  {
    name: "FlyPaper",
    symbol: "STICKY",
    slug: "flypaper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flypaper.png",
  },
  {
    name: "SokuSwap",
    symbol: "SOKU",
    slug: "sokuswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sokuswap.png",
  },
  {
    name: "Wrapped Celo",
    symbol: "WCELO",
    slug: "wrapped-celo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-celo.png",
  },
  {
    name: "Seek Tiger",
    symbol: "STI",
    slug: "seek-tiger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seek-tiger.png",
  },
  {
    name: "Atlantic Finance Token",
    symbol: "ATFI",
    slug: "atlantic-finance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlantic-finance-token.png",
  },
  {
    name: "Tanks For Playing",
    symbol: "TANKS",
    slug: "tanks-for-playing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tanks-for-playing.png",
  },
  {
    name: "Nemesis DAO",
    symbol: "NMS",
    slug: "nemesis-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nemesis-dao.png",
  },
  {
    name: "Metoshi",
    symbol: "METO",
    slug: "metoshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metoshi.png",
  },
  {
    name: "BitSong",
    symbol: "BTSG",
    slug: "bitsong",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitsong.png",
  },
  {
    name: "Continuum World",
    symbol: "UM",
    slug: "continuum-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/continuum-world.png",
  },
  {
    name: "Cheems Inu",
    symbol: "CINU",
    slug: "cheems-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheems-inu.png",
  },
  {
    name: "COMMUNIQUE",
    symbol: "CMQ",
    slug: "communique",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/communique.png",
  },
  {
    name: "Chibi Dinos",
    symbol: "HOOP",
    slug: "chibi-dinos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chibi-dinos.png",
  },
  {
    name: "COAL",
    symbol: "MLB",
    slug: "coal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coal.png",
  },
  {
    name: "OEC UNI",
    symbol: "UNIK",
    slug: "oec-uni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oec-uni.png",
  },
  {
    name: "ShibaDoge",
    symbol: "SHIBDOGE",
    slug: "shibadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibadoge.png",
  },
  {
    name: "Golden Kitty Cake",
    symbol: "GKCAKE",
    slug: "golden-kitty-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-kitty-cake.png",
  },
  {
    name: "AcknoLedger",
    symbol: "ACK",
    slug: "acknoledger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acknoledger.png",
  },
  {
    name: "Bridge Network",
    symbol: "BRDG",
    slug: "bridge-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bridge-network.png",
  },
  {
    name: "Font",
    symbol: "FONT",
    slug: "font",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/font.png",
  },
  {
    name: "MARIO WORLD",
    symbol: "SHROOMS",
    slug: "mario-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mario-world.png",
  },
  {
    name: "Pixie",
    symbol: "PIX",
    slug: "pixie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixie.png",
  },
  {
    name: "Shiba Inu Mother",
    symbol: "SHIBM",
    slug: "shiba-inu-mother",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-inu-mother.png",
  },
  {
    name: "Kunci Coin",
    symbol: "KUNCI",
    slug: "kunci-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kunci-coin.png",
  },
  {
    name: "Dungeon",
    symbol: "DGN",
    slug: "dungeon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dungeon.png",
  },
  {
    name: "Polka Ventures",
    symbol: "POLVEN",
    slug: "polka-ventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polka-ventures.png",
  },
  {
    name: "Bitcoin Latinum",
    symbol: "LTNM",
    slug: "bitcoin-latinum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-latinum.png",
  },
  {
    name: "EverGrowCoin",
    symbol: "EGC",
    slug: "evergrowcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evergrowcoin.png",
  },
  {
    name: "SifChain",
    symbol: "erowan",
    slug: "sifchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sifchain.png",
  },
  {
    name: "Crypto Snack",
    symbol: "SNACK",
    slug: "crypto-snack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-snack.png",
  },
  {
    name: "AngelsCreed",
    symbol: "ANGEL",
    slug: "angelscreed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/angelscreed.png",
  },
  {
    name: "StarFish OS",
    symbol: "SFO",
    slug: "starfish-os",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starfish-os.png",
  },
  {
    name: "Pepedex",
    symbol: "PPDEX",
    slug: "pepedex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pepedex.png",
  },
  {
    name: "AutoCrypto",
    symbol: "AU",
    slug: "autocrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/autocrypto.png",
  },
  {
    name: "NPICK BLOCK",
    symbol: "NPICK",
    slug: "npick-block",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/npick-block.png",
  },
  {
    name: "Aleph Zero",
    symbol: "AZERO",
    slug: "aleph-zero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aleph-zero.png",
  },
  {
    name: "ShibaWallet",
    symbol: "SHWA",
    slug: "shiba-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-wallet.png",
  },
  {
    name: "Meblox Protocol",
    symbol: "MEB",
    slug: "meblox-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meblox-protocol.png",
  },
  {
    name: "Mini Kishu",
    symbol: "MINIKISHU",
    slug: "mini-kishu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-kishu.png",
  },
  {
    name: "MicroTuber",
    symbol: "MCT",
    slug: "microtuber",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microtuber.png",
  },
  {
    name: "Diamond Boyz Coin",
    symbol: "DBZ",
    slug: "diamond-boyz-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-boyz-coin.png",
  },
  {
    name: "YIN Finance",
    symbol: "YIN",
    slug: "yin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yin-finance.png",
  },
  {
    name: "Thunder Brawl",
    symbol: "THB",
    slug: "thunder-brawl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thunder-brawl.png",
  },
  {
    name: "GensoKishi Metaverse",
    symbol: "MV",
    slug: "gensokishis-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gensokishis-metaverse.png",
  },
  {
    name: "Katsumi",
    symbol: "KATSUMI",
    slug: "katsumi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/katsumi.png",
  },
  {
    name: "Liquidus",
    symbol: "LIQ",
    slug: "liquidus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquidus.png",
  },
  {
    name: "Dexfolio",
    symbol: "DEXF",
    slug: "dexfolio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexfolio.png",
  },
  {
    name: "Raresama",
    symbol: "POOP",
    slug: "raresama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raresama.png",
  },
  {
    name: "Royal BNB",
    symbol: "RB",
    slug: "royal-bnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/royal-bnb.png",
  },
  {
    name: "Sypool",
    symbol: "SYP",
    slug: "sypool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sypool.png",
  },
  {
    name: "MetaverseX",
    symbol: "METAX",
    slug: "meraversex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meraversex.png",
  },
  {
    name: "TosDis",
    symbol: "DIS",
    slug: "tosdis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tosdis.png",
  },
  {
    name: "FOMO Chronicles Manga",
    symbol: "OTAKU",
    slug: "fomo-chronicles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fomo-chronicles.png",
  },
  {
    name: "Creditum",
    symbol: "CREDIT",
    slug: "creditum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creditum.png",
  },
  {
    name: "Bytus",
    symbol: "BYTS",
    slug: "bytus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bytus.png",
  },
  {
    name: "Cogecoin",
    symbol: "COGE",
    slug: "cogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cogecoin.png",
  },
  {
    name: "Compound 0x",
    symbol: "CZRX",
    slug: "compound-0x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-0x.png",
  },
  {
    name: "DogeHouse Capital",
    symbol: "DOGEX",
    slug: "dogehouse-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogehouse-capital.png",
  },
  {
    name: "Coinhunters",
    symbol: "CHTR",
    slug: "coinhunters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinhunters.png",
  },
  {
    name: "Solpad Finance",
    symbol: "SOLPAD",
    slug: "solpad-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solpad-finance.png",
  },
  {
    name: "The Forbidden Forest",
    symbol: "FORESTPLUS",
    slug: "the-forbidden-forest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-forbidden-forest.png",
  },
  {
    name: "3QT",
    symbol: "3QT",
    slug: "3qt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3qt.png",
  },
  {
    name: "Duelist King",
    symbol: "DKT",
    slug: "duelist-king",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duelist-king.png",
  },
  {
    name: "Echoin",
    symbol: "EC",
    slug: "echoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/echoin.png",
  },
  {
    name: "Okse",
    symbol: "OKSE",
    slug: "okse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okse.png",
  },
  {
    name: "Orakuru",
    symbol: "ORK",
    slug: "orakuru",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orakuru.png",
  },
  {
    name: "fantomGO",
    symbol: "FTG",
    slug: "fantomgo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantomgo.png",
  },
  {
    name: "VCGamers",
    symbol: "VCG",
    slug: "vcgamers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vcgamers.png",
  },
  {
    name: "dexSHARE",
    symbol: "DEXSHARE",
    slug: "dexshare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexshare.png",
  },
  {
    name: "DyzToken",
    symbol: "DYZ",
    slug: "dyztoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dyztoken.png",
  },
  {
    name: "Sunflower Farm",
    symbol: "SFF",
    slug: "sunflower-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sunflower-farm.png",
  },
  {
    name: "YEL.Finance",
    symbol: "YEL",
    slug: "yel-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yel-finance.png",
  },
  {
    name: "DCTDAO",
    symbol: "DCTD",
    slug: "dctdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dctdao.png",
  },
  {
    name: "BTRIPS",
    symbol: "BTR",
    slug: "btrips",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/btrips.png",
  },
  {
    name: "Incube Chain",
    symbol: "ICB",
    slug: "incube-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/incube-chain.png",
  },
  {
    name: "CryptoSkates",
    symbol: "CST",
    slug: "cryptoskates",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoskates.png",
  },
  {
    name: "Flourishing AI",
    symbol: "AI",
    slug: "flourishing-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flourishing-ai.png",
  },
  {
    name: "Deez Nuts",
    symbol: "DEEZNUTS",
    slug: "deez-nuts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deez-nuts.png",
  },
  {
    name: "Galeon",
    symbol: "GALEON",
    slug: "galeon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galeon.png",
  },
  {
    name: "Bullish AF",
    symbol: "BULLAF",
    slug: "bullish-af",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullish-af.png",
  },
  {
    name: "Widi Soul",
    symbol: "WSO",
    slug: "wso",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wso.png",
  },
  {
    name: "MetaMerce",
    symbol: "MERCE",
    slug: "metamerce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamerce.png",
  },
  {
    name: "BLOCKS",
    symbol: "BLOCKS",
    slug: "blocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocks.png",
  },
  {
    name: "SaitamaX",
    symbol: "SAITAX",
    slug: "saitamax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saitamax.png",
  },
  {
    name: "Mandala Exchange Token",
    symbol: "MDX",
    slug: "mandala-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mandala-exchange-token.png",
  },
  {
    name: "Hope",
    symbol: "HOPE",
    slug: "hope-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hope-token.png",
  },
  {
    name: "Rebuschain",
    symbol: "REBUS",
    slug: "rebuschain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rebuschain.png",
  },
  {
    name: "King Shiba",
    symbol: "KINGSHIB",
    slug: "king-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-shiba.png",
  },
  {
    name: "Decred-Next",
    symbol: "DCRN",
    slug: "decred-next",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decred-next.png",
  },
  {
    name: "Melody",
    symbol: "SNS",
    slug: "melody",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/melody.png",
  },
  {
    name: "Kurai MetaVerse",
    symbol: "KURAI",
    slug: "kurai-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kurai-metaverse.png",
  },
  {
    name: "Ginga Finance",
    symbol: "GIN",
    slug: "ginga-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ginga-finance.png",
  },
  {
    name: "Unique Network",
    symbol: "UNQ",
    slug: "unique-unq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unique-unq.png",
  },
  {
    name: "Sphynx Network",
    symbol: "SPH",
    slug: "sphynx-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sphynx-network.png",
  },
  {
    name: "Anypad",
    symbol: "APAD",
    slug: "anypad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anypad.png",
  },
  {
    name: "Metapay",
    symbol: "MPAY",
    slug: "metapay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metapay.png",
  },
  {
    name: "FreeRiver",
    symbol: "FREE",
    slug: "freeriver",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freeriver.png",
  },
  {
    name: "Slam Token",
    symbol: "SLAM",
    slug: "slam-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/slam-token.png",
  },
  {
    name: "MousePad",
    symbol: "MPAD",
    slug: "mousepad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mousepad.png",
  },
  {
    name: "YinYang",
    symbol: "YY",
    slug: "yinyang",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yinyang.png",
  },
  {
    name: "Sona Network",
    symbol: "SONA",
    slug: "sona-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sona-network.png",
  },
  {
    name: "Dark Matter",
    symbol: "DMT",
    slug: "dark-matter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dark-matter.png",
  },
  {
    name: "pBTC35A",
    symbol: "pBTC35A",
    slug: "pbtc35a",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pbtc35a.png",
  },
  {
    name: "ImpactXP",
    symbol: "IMPACTXP",
    slug: "impactxp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/impactxp.png",
  },
  {
    name: "Project TXA",
    symbol: "TXA",
    slug: "project-txa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-txa.png",
  },
  {
    name: "Squid Grow",
    symbol: "SQUIDGROW",
    slug: "squid-grow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squid-grow.png",
  },
  {
    name: "ISLAMICOIN",
    symbol: "ISLAMI",
    slug: "islamicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/islamicoin.png",
  },
  {
    name: "Bored Ark",
    symbol: "BARK",
    slug: "bored-ark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bored-ark.png",
  },
  {
    name: "MiniSports Token",
    symbol: "MINISPORTS",
    slug: "minisports-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minisports-token.png",
  },
  {
    name: "Nano Dogecoin",
    symbol: "NDC",
    slug: "nano-dogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nano-dogecoin.png",
  },
  {
    name: "Wrapped Solana",
    symbol: "SOL",
    slug: "wrapped-solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-solana.png",
  },
  {
    name: "Starter",
    symbol: "START",
    slug: "bscstarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bscstarter.png",
  },
  {
    name: "Pokmonsters",
    symbol: "POK",
    slug: "pokmonsters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pokmonsters.png",
  },
  {
    name: "Bulldogswap",
    symbol: "BUDG",
    slug: "bulldogswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulldogswap.png",
  },
  {
    name: "peachfolio",
    symbol: "PCHF",
    slug: "peachfolio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peachfolio.png",
  },
  {
    name: "KCC MemePad",
    symbol: "KCCM",
    slug: "kcc-memepad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kcc-memepad.png",
  },
  {
    name: "Idle Cyber",
    symbol: "AFK",
    slug: "iddle-cyber",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iddle-cyber.png",
  },
  {
    name: "DUKE INU TOKEN",
    symbol: "DUKE",
    slug: "duke-inu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duke-inu-token.png",
  },
  {
    name: "Umee",
    symbol: "UMEE",
    slug: "umee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/umee.png",
  },
  {
    name: "Dragon Battles",
    symbol: "DBR",
    slug: "dragon-battles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-battles.png",
  },
  {
    name: "Jigstack",
    symbol: "STAK",
    slug: "jigstack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jigstack.png",
  },
  {
    name: "1Doge",
    symbol: "1DOGE",
    slug: "1doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1doge.png",
  },
  {
    name: "Snoshares",
    symbol: "SNOSHARE",
    slug: "snoshares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snoshares.png",
  },
  {
    name: "PAPA DAO",
    symbol: "PAPA",
    slug: "papa-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/papa-dao.png",
  },
  {
    name: "MobiFi",
    symbol: "MoFi",
    slug: "mobi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobi-finance.png",
  },
  {
    name: "Angle Protocol",
    symbol: "AGEUR",
    slug: "angle-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/angle-protocol.png",
  },
  {
    name: "Freedom God Dao",
    symbol: "FGD",
    slug: "freedom-god-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freedom-god-dao.png",
  },
  {
    name: "Sensitrust",
    symbol: "SETS",
    slug: "sensitrust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sensitrust.png",
  },
  {
    name: "eaglecoin",
    symbol: "ELC",
    slug: "eagle-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eagle-coin.png",
  },
  {
    name: "The Monopolist",
    symbol: "MONO",
    slug: "the-monopolist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-monopolist.png",
  },
  {
    name: "MM Finance",
    symbol: "MMF",
    slug: "mm-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mm-finance.png",
  },
  {
    name: "DRAC Token",
    symbol: "DRAC",
    slug: "drac-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drac-token.png",
  },
  {
    name: "Meliora",
    symbol: "MORA",
    slug: "meliora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meliora.png",
  },
  {
    name: "Thor",
    symbol: "THOR",
    slug: "thor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thor.png",
  },
  {
    name: "GameX",
    symbol: "GMX",
    slug: "gamex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamex.png",
  },
  {
    name: "X2",
    symbol: "X2",
    slug: "x-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-2.png",
  },
  {
    name: "The Doge World",
    symbol: "TDW",
    slug: "the-doge-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-doge-world.png",
  },
  {
    name: "blocsport.one",
    symbol: "BLS",
    slug: "blocsport-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocsport-one.png",
  },
  {
    name: "New Order",
    symbol: "NEWO",
    slug: "new-order",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-order.png",
  },
  {
    name: "Spooky Inu",
    symbol: "SPOOK",
    slug: "spooky-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spooky-inu.png",
  },
  {
    name: "MobieCoin",
    symbol: "MBX",
    slug: "mobiepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobiepay.png",
  },
  {
    name: "Metacraft",
    symbol: "MCT",
    slug: "metacraft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metacraft.png",
  },
  {
    name: "Universe Crystal Gene",
    symbol: "UCG",
    slug: "universe-crystal-gene",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universe-crystal-gene.png",
  },
  {
    name: "Beast Token",
    symbol: "BEAST",
    slug: "beast-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beast-token.png",
  },
  {
    name: "Dogs Token",
    symbol: "DOGS",
    slug: "dogs-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogs-token.png",
  },
  {
    name: "QUIDD",
    symbol: "QUIDD",
    slug: "quidd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quidd.png",
  },
  {
    name: "ALTAVA",
    symbol: "TAVA",
    slug: "altava",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altava.png",
  },
  {
    name: "Ronin",
    symbol: "RON",
    slug: "ronin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ronin.png",
  },
  {
    name: "Minebase",
    symbol: "MBASE",
    slug: "minebase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minebase.png",
  },
  {
    name: "Nasa Doge",
    symbol: "NASADOGE",
    slug: "nasa-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nasa-doge.png",
  },
  {
    name: "MerchDAO",
    symbol: "MRCH",
    slug: "merchdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merchdao.png",
  },
  {
    name: "TopChain",
    symbol: "TOPC",
    slug: "topchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/topchain.png",
  },
  {
    name: "iinjaz",
    symbol: "IJZ",
    slug: "iinjaz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iinjaz.png",
  },
  {
    name: "UNIREALCHAIN",
    symbol: "UNR",
    slug: "unirealchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unirealchain.png",
  },
  {
    name: "Don't KYC",
    symbol: "DKYC",
    slug: "dont-kyc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dont-kyc.png",
  },
  {
    name: "BiTToken",
    symbol: "BITT",
    slug: "bittoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bittoken.png",
  },
  {
    name: "Wrapped Moonriver",
    symbol: "WMOVR",
    slug: "wrapped-moonriver",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-moonriver.png",
  },
  {
    name: "FREEMOON",
    symbol: "FREEMOON",
    slug: "freemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freemoon.png",
  },
  {
    name: "RAKUN",
    symbol: "RAKU",
    slug: "rakun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rakun.png",
  },
  {
    name: "Wanaka Farm WAIRERE Token",
    symbol: "WAI",
    slug: "wairere-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wairere-token.png",
  },
  {
    name: "Cryptomeda",
    symbol: "TECH",
    slug: "cryptomeda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptomeda.png",
  },
  {
    name: "Metan Evolutions",
    symbol: "METAN",
    slug: "metan-evolutions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metan-evolutions.png",
  },
  {
    name: "Locklet",
    symbol: "LKT",
    slug: "locklet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/locklet.png",
  },
  {
    name: "Hedge USD",
    symbol: "USH",
    slug: "hedge-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedge-usd.png",
  },
  {
    name: "Calcifer",
    symbol: "CALCIFER",
    slug: "calcifer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calcifer.png",
  },
  {
    name: "Lien",
    symbol: "LIEN",
    slug: "lien",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lien.png",
  },
  {
    name: "Dibs Share",
    symbol: "DSHARE",
    slug: "dibs-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dibs-share.png",
  },
  {
    name: "Kaiken Shiba",
    symbol: "KSHIB",
    slug: "kaiken-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaiken-shiba.png",
  },
  {
    name: "Titan Hunters",
    symbol: "TITA",
    slug: "titan-hunters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/titan-hunters.png",
  },
  {
    name: "Ape Fun Token",
    symbol: "AFT",
    slug: "ape-fun-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ape-fun-token.png",
  },
  {
    name: "MBD Financials",
    symbol: "MBD",
    slug: "mbd-financials",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mbd-financials.png",
  },
  {
    name: "Coinracer",
    symbol: "CRACE",
    slug: "coinracer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinracer.png",
  },
  {
    name: "Pirate Coin Games",
    symbol: "PirateCoin☠",
    slug: "pirate-coin-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pirate-coin-games.png",
  },
  {
    name: "iMe Lab",
    symbol: "LIME",
    slug: "ime-lab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ime-lab.png",
  },
  {
    name: "Polkasocial Network",
    symbol: "PSN",
    slug: "polkasocial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkasocial.png",
  },
  {
    name: "Gamerse",
    symbol: "LFG",
    slug: "gamerse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamerse.png",
  },
  {
    name: "OpenBlox",
    symbol: "OBX",
    slug: "openblox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/openblox.png",
  },
  {
    name: "BabyDot",
    symbol: "BDOT",
    slug: "babydot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babydot.png",
  },
  {
    name: "Planet NFT",
    symbol: "PNFT",
    slug: "planet-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/planet-nft.png",
  },
  {
    name: "Tycoon",
    symbol: "TYC",
    slug: "tycoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tycoon.png",
  },
  {
    name: "Pigs Token",
    symbol: "PIGS",
    slug: "pigs-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pigs-token.png",
  },
  {
    name: "MoonSwap",
    symbol: "MSWAP",
    slug: "moonswap-moonriver",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonswap-moonriver.png",
  },
  {
    name: "Shibanomics",
    symbol: "SHIBIN",
    slug: "shibanomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibanomics.png",
  },
  {
    name: "myMessage",
    symbol: "MESA",
    slug: "mymessage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mymessage.png",
  },
  {
    name: "CoinWind",
    symbol: "COW",
    slug: "coinwind",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinwind.png",
  },
  {
    name: "Gamestarter",
    symbol: "GAME",
    slug: "gamestarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamestarter.png",
  },
  {
    name: "Wojak Finance",
    symbol: "WOJ",
    slug: "wojak-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wojak-finance.png",
  },
  {
    name: "Pollchain",
    symbol: "POLL",
    slug: "pollchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pollchain.png",
  },
  {
    name: "Mini Floki",
    symbol: "MINIFLOKI",
    slug: "mini-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-floki.png",
  },
  {
    name: "Round X",
    symbol: "RNDX",
    slug: "round-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/round-x.png",
  },
  {
    name: "AnchorSwap",
    symbol: "ANCHOR",
    slug: "anchorswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anchorswap.png",
  },
  {
    name: "EVERY GAME",
    symbol: "EGAME",
    slug: "every-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/every-game.png",
  },
  {
    name: "LTCUP",
    symbol: "LTCUP",
    slug: "ltcup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ltcup.png",
  },
  {
    name: "Merkle Network",
    symbol: "MERKLE",
    slug: "merkle-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merkle-network.png",
  },
  {
    name: "ForeverFOMO",
    symbol: "FOREVERFOMO",
    slug: "foreverfomo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foreverfomo.png",
  },
  {
    name: "Inter Milan Fan Token",
    symbol: "INTER",
    slug: "inter-milan-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inter-milan-fan-token.png",
  },
  {
    name: "Channels",
    symbol: "CAN",
    slug: "channels",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/channels.png",
  },
  {
    name: "ProspectorCrane",
    symbol: "CRANE$",
    slug: "prospectorcrane",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prospectorcrane.png",
  },
  {
    name: "Piccolo Inu",
    symbol: "PINU",
    slug: "piccolo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piccolo-inu.png",
  },
  {
    name: "Atlantis Loans",
    symbol: "ATL",
    slug: "atlantis-loans",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlantis-loans.png",
  },
  {
    name: "BabyUni",
    symbol: "BABYUNI",
    slug: "babyuni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyuni.png",
  },
  {
    name: "Metafish",
    symbol: "FISH",
    slug: "metafish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafish.png",
  },
  {
    name: "Shiba Girlfriend",
    symbol: "SHIBGF",
    slug: "shiba-girlfriend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-girlfriend.png",
  },
  {
    name: "Invictus",
    symbol: "IN",
    slug: "invictus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/invictus.png",
  },
  {
    name: "Metaplace",
    symbol: "MPC",
    slug: "metaplace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaplace.png",
  },
  {
    name: "3X Short Ethereum Token",
    symbol: "ETHBEAR",
    slug: "3x-short-ethereum-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-ethereum-token.png",
  },
  {
    name: "Soakmont",
    symbol: "SOAK",
    slug: "soakmont",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soakmont.png",
  },
  {
    name: "Dream Soccer",
    symbol: "DSOCCER",
    slug: "dream-soccer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dream-soccer.png",
  },
  {
    name: "2omb Finance",
    symbol: "2OMB",
    slug: "2omb-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2omb-finance.png",
  },
  {
    name: "1INCHDOWN",
    symbol: "1INCHDOWN",
    slug: "1inchdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1inchdown.png",
  },
  {
    name: "BaconDAO",
    symbol: "BACON",
    slug: "bacondao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bacondao.png",
  },
  {
    name: "ReFork",
    symbol: "EFK",
    slug: "refork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/refork.png",
  },
  {
    name: "Wolf Game DAO(BSC)",
    symbol: "WOOL",
    slug: "wolf-game-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolf-game-dao.png",
  },
  {
    name: "Linework Coin",
    symbol: "LWC",
    slug: "linework-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linework-coin.png",
  },
  {
    name: "MojitoSwap",
    symbol: "MJT",
    slug: "mojitoswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mojitoswap.png",
  },
  {
    name: "Bogged Finance",
    symbol: "BOG",
    slug: "bogged-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bogged-finance.png",
  },
  {
    name: "R U Generous",
    symbol: "RUG",
    slug: "r-u-generous",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/r-u-generous.png",
  },
  {
    name: "PuddingSwap",
    symbol: "PUD",
    slug: "puddingswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/puddingswap.png",
  },
  {
    name: "Qatar World Cup",
    symbol: "QATAR",
    slug: "qatar-world-cup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qatar-world-cup.png",
  },
  {
    name: "MetaGear",
    symbol: "GEAR",
    slug: "metagear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagear.png",
  },
  {
    name: "MetaRim",
    symbol: "RIM",
    slug: "metarim",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metarim.png",
  },
  {
    name: "Relay Token",
    symbol: "RELAY",
    slug: "relay-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/relay-token.png",
  },
  {
    name: "Chiva Token",
    symbol: "CHIV",
    slug: "chiva-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chiva-token.png",
  },
  {
    name: "Multicoin",
    symbol: "MTCN",
    slug: "multicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multicoin.png",
  },
  {
    name: "Orca",
    symbol: "ORCA",
    slug: "orca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orca.png",
  },
  {
    name: "PrinceFloki",
    symbol: "PrinceFloki",
    slug: "princefloki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/princefloki.png",
  },
  {
    name: "Mars Inu",
    symbol: "MARSINU",
    slug: "mars-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mars-inu.png",
  },
  {
    name: "Son of Doge V2",
    symbol: "SODV2",
    slug: "son-of-doge-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/son-of-doge-v2.png",
  },
  {
    name: "BunnyGirl",
    symbol: "BUNNYGIRL",
    slug: "bunnygirl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunnygirl.png",
  },
  {
    name: "Storage area network anywhere",
    symbol: "SANA",
    slug: "storage-area-network-anywhere",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/storage-area-network-anywhere.png",
  },
  {
    name: "Nahmii",
    symbol: "NII",
    slug: "nahmii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nahmii.png",
  },
  {
    name: "AGORIC",
    symbol: "BLD",
    slug: "agoric",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agoric.png",
  },
  {
    name: "Lobis",
    symbol: "LOBI",
    slug: "lobis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lobis.png",
  },
  {
    name: "Dejitaru Tsuka",
    symbol: "TSUKA",
    slug: "dejitaru-tsuka",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dejitaru-tsuka.png",
  },
  {
    name: "Franklin",
    symbol: "FLY",
    slug: "franklin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/franklin.png",
  },
  {
    name: "OCEANA Waters",
    symbol: "WATER",
    slug: "oceana-waters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oceana-waters.png",
  },
  {
    name: "3OMB Token",
    symbol: "3OMB",
    slug: "30mb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/30mb-token.png",
  },
  {
    name: "The Token Kennel",
    symbol: "KENNEL",
    slug: "the-token-kennel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-token-kennel.png",
  },
  {
    name: "Comtech Gold",
    symbol: "CGO",
    slug: "comtech-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comtech-gold.png",
  },
  {
    name: "CORE MultiChain",
    symbol: "CMCX",
    slug: "core-multichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/core-multichain.png",
  },
  {
    name: "Zamzam",
    symbol: "ZAMZAM",
    slug: "zamzam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zamzam.png",
  },
  {
    name: "FrontFanz",
    symbol: "FANZ",
    slug: "frontfanz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frontfanz.png",
  },
  {
    name: "TreeDefi",
    symbol: "SEED",
    slug: "treedefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treedefi.png",
  },
  {
    name: "LBK",
    symbol: "LBK",
    slug: "lbk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lbk.png",
  },
  {
    name: "Chumbi Valley",
    symbol: "CHMB",
    slug: "chumbi-valley",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chumbi-valley.png",
  },
  {
    name: "Fisher Vs Pirate",
    symbol: "FVP",
    slug: "fisher-vs-pirate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fisher-vs-pirate.png",
  },
  {
    name: "Dogereversed",
    symbol: "EGOD",
    slug: "dogereversed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogereversed.png",
  },
  {
    name: "Safe Earn",
    symbol: "SAFEARN",
    slug: "safe-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-earn.png",
  },
  {
    name: "Kommunitas",
    symbol: "KOM",
    slug: "kommunitas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kommunitas.png",
  },
  {
    name: "SpacePort Universe",
    symbol: "SPU",
    slug: "spaceport-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spaceport-universe.png",
  },
  {
    name: "Dogebonk",
    symbol: "DOBO",
    slug: "dogebonk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogebonk.png",
  },
  {
    name: "Fortress",
    symbol: "FORT",
    slug: "fortressdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fortressdao.png",
  },
  {
    name: "Blind Boxes",
    symbol: "BLES",
    slug: "blind-boxes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blind-boxes.png",
  },
  {
    name: "CouponBay",
    symbol: "CUP",
    slug: "couponbay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/couponbay.png",
  },
  {
    name: "VikingsChain",
    symbol: "VIKC",
    slug: "vikingschain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vikingschain.png",
  },
  {
    name: "King Floki",
    symbol: "KING",
    slug: "king-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-floki.png",
  },
  {
    name: "PERI Finance",
    symbol: "PERI",
    slug: "peri-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peri-finance.png",
  },
  {
    name: "Empire Warriors",
    symbol: "EMP",
    slug: "empire-warriors",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/empire-warriors.png",
  },
  {
    name: "SparkLab",
    symbol: "Spark",
    slug: "sparklab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sparklab.png",
  },
  {
    name: "GravitX",
    symbol: "GRX",
    slug: "gravitx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gravitx.png",
  },
  {
    name: "YUMMY",
    symbol: "YUMMY",
    slug: "yummy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yummy.png",
  },
  {
    name: "Gateway Protocol",
    symbol: "GWP",
    slug: "gateway-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gateway-protocol.png",
  },
  {
    name: "DaoVerse",
    symbol: "DVRS",
    slug: "daoverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daoverse.png",
  },
  {
    name: "Spartacus",
    symbol: "SPA",
    slug: "spartacus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spartacus.png",
  },
  {
    name: "Digichain",
    symbol: "DIGICHAIN",
    slug: "digichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digichain.png",
  },
  {
    name: "IMPACTXPRIME",
    symbol: "IXP",
    slug: "impactxprime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/impactxprime.png",
  },
  {
    name: "Shiba Dollars",
    symbol: "SHIBADOLLARS",
    slug: "shiba-dollars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-dollars.png",
  },
  {
    name: "ForeverPump",
    symbol: "FOREVERPUMP",
    slug: "foreverpump",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foreverpump.png",
  },
  {
    name: "Cherry",
    symbol: "CHER",
    slug: "cherry-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cherry-network.png",
  },
  {
    name: "THUNDERCAKE.APP",
    symbol: "THUNDERCAKE",
    slug: "thundercake-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thundercake-app.png",
  },
  {
    name: "Cratos",
    symbol: "CRTS",
    slug: "cratos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cratos.png",
  },
  {
    name: "xFund",
    symbol: "XFUND",
    slug: "xfund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xfund.png",
  },
  {
    name: "Wolf Safe Poor People",
    symbol: "WSPP",
    slug: "wolf-safe-poor-people",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolf-safe-poor-people.png",
  },
  {
    name: "Floki Chain",
    symbol: "FLOKICHAIN",
    slug: "floki-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-chain.png",
  },
  {
    name: "Battle of Guardians",
    symbol: "BGS",
    slug: "battle-of-guardians",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-of-guardians.png",
  },
  {
    name: "UnderMineGold",
    symbol: "UMG",
    slug: "underinegold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/underinegold.png",
  },
  {
    name: "3Share",
    symbol: "3SHARE",
    slug: "3shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3shares.png",
  },
  {
    name: "Splyt",
    symbol: "SHOPX",
    slug: "splyt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/splyt.png",
  },
  {
    name: "Vodka Token",
    symbol: "VODKA",
    slug: "vodka-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vodka-token.png",
  },
  {
    name: "Mononoke Inu",
    symbol: "Mononoke-Inu",
    slug: "mononoke-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mononoke-inu.png",
  },
  {
    name: "Metaverse.Network Pioneer",
    symbol: "NEER",
    slug: "bitcountry-neer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcountry-neer.png",
  },
  {
    name: "GamerCoin",
    symbol: "GHX",
    slug: "gamercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamercoin.png",
  },
  {
    name: "MELI",
    symbol: "MELI",
    slug: "meli-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meli-games.png",
  },
  {
    name: "Fancy Games",
    symbol: "FNC",
    slug: "fancy-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fancy-games.png",
  },
  {
    name: "Mancium",
    symbol: "MANC",
    slug: "mancium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mancium.png",
  },
  {
    name: "Mycelium",
    symbol: "MYC",
    slug: "mycelium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mycelium.png",
  },
  {
    name: "RUSH COIN",
    symbol: "RUSH",
    slug: "rush-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rush-coin.png",
  },
  {
    name: "Buff Doge Coin",
    symbol: "DOGECOIN",
    slug: "buff-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buff-doge-coin.png",
  },
  {
    name: "Multiverse Capital",
    symbol: "MVC",
    slug: "multiverse-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multiverse-capital.png",
  },
  {
    name: "Cake Bank",
    symbol: "CAKEBANK",
    slug: "cake-bank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cake-bank.png",
  },
  {
    name: "JSB FOUNDATION",
    symbol: "JSB",
    slug: "jsb-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jsb-foundation.png",
  },
  {
    name: "Fantasy Tales",
    symbol: "FTS",
    slug: "fantasy-tales",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasy-tales.png",
  },
  {
    name: "MetaMUI",
    symbol: "MMUI",
    slug: "metamui",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamui.png",
  },
  {
    name: "CryptoRPG",
    symbol: "RPG",
    slug: "cryptorpg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptorpg.png",
  },
  {
    name: "KILT Protocol",
    symbol: "KILT",
    slug: "kiltprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiltprotocol.png",
  },
  {
    name: "SmartCoin (SMRTr)",
    symbol: "SMRTR",
    slug: "smartcoin-farm-smrtr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartcoin-farm-smrtr.png",
  },
  {
    name: "Lucky Block",
    symbol: "LBLOCK",
    slug: "lucky-block",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucky-block.png",
  },
  {
    name: "BT.Finance",
    symbol: "BT",
    slug: "bt-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bt-finance.png",
  },
  {
    name: "BabyPunks",
    symbol: "BPUNKS",
    slug: "babypunks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babypunks.png",
  },
  {
    name: "FOMO BABY",
    symbol: "FOMOBABY",
    slug: "fomobaby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fomobaby.png",
  },
  {
    name: "Age of Tanks",
    symbol: "A.O.T",
    slug: "age-of-tanks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/age-of-tanks.png",
  },
  {
    name: "GameFi Protocol",
    symbol: "GFI",
    slug: "gamefi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamefi-token.png",
  },
  {
    name: "Yearn Finance Passive Income",
    symbol: "YFPI",
    slug: "yearn-finance-passive-income",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance-passive-income.png",
  },
  {
    name: "StarSharks SEA",
    symbol: "SEA",
    slug: "starsharks-sea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starsharks-sea.png",
  },
  {
    name: "Xeta Reality",
    symbol: "XETA",
    slug: "xeta-reality",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xeta-reality.png",
  },
  {
    name: "Crystal Powder",
    symbol: "CP",
    slug: "crystal-powder",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crystal-powder.png",
  },
  {
    name: "BullPerks",
    symbol: "BLP",
    slug: "bullperks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bullperks.png",
  },
  {
    name: "Infinity Angel",
    symbol: "ING",
    slug: "infinity-angel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-angel.png",
  },
  {
    name: "Fruits Eco",
    symbol: "FRTS",
    slug: "fruits-eco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fruits-eco.png",
  },
  {
    name: "FloorDAO",
    symbol: "FLOOR",
    slug: "floordao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floordao.png",
  },
  {
    name: "Reflexer Ungovernance Token",
    symbol: "FLX",
    slug: "reflexer-ungovernance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reflexer-ungovernance-token.png",
  },
  {
    name: "Sienna",
    symbol: "SIENNA",
    slug: "sienna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sienna.png",
  },
  {
    name: "Gold Fever",
    symbol: "NGL",
    slug: "gold-fever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-fever.png",
  },
  {
    name: "MINE Network",
    symbol: "MNET",
    slug: "mine-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mine-network.png",
  },
  {
    name: "Choise.com",
    symbol: "CHO",
    slug: "choise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/choise.png",
  },
  {
    name: "DxSale Network",
    symbol: "SALE",
    slug: "dxsale-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dxsale-network.png",
  },
  {
    name: "Rikkei Finance",
    symbol: "RIFI",
    slug: "rikkei-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rikkei-finance.png",
  },
  {
    name: "Bone ShibaSwap",
    symbol: "BONE",
    slug: "bone-shibaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bone-shibaswap.png",
  },
  {
    name: "Daruma",
    symbol: "DARUMA",
    slug: "daruma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daruma.png",
  },
  {
    name: "2SHARE",
    symbol: "2SHARES",
    slug: "2share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2share.png",
  },
  {
    name: "Baby Squid Game",
    symbol: "BSG",
    slug: "baby-squid-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-squid-game.png",
  },
  {
    name: "Bitcoin Vault",
    symbol: "BTCV",
    slug: "bitcoin-vault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-vault.png",
  },
  {
    name: "Acala Dollar",
    symbol: "AUSD",
    slug: "acala-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acala-dollar.png",
  },
  {
    name: "UXD Stablecoin",
    symbol: "UXD",
    slug: "uxd-stablecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uxd-stablecoin.png",
  },
  {
    name: "Nominex Token",
    symbol: "NMX",
    slug: "nominex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nominex-token.png",
  },
  {
    name: "RoboDoge Coin",
    symbol: "ROBODOGE",
    slug: "robodoge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robodoge-coin.png",
  },
  {
    name: "Artube",
    symbol: "ATT",
    slug: "artube",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artube.png",
  },
  {
    name: "Yeon",
    symbol: "YEON",
    slug: "yeon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yeon.png",
  },
  {
    name: "MVP Coin",
    symbol: "MVP",
    slug: "mvp-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mvp-coin.png",
  },
  {
    name: "Milo Inu",
    symbol: "MILO",
    slug: "milo-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milo-inu.png",
  },
  {
    name: "Bitcashpay (new)",
    symbol: "BCP",
    slug: "bitcashpay-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcashpay-new.png",
  },
  {
    name: "hiOD",
    symbol: "HIOD",
    slug: "hiod",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hiod.png",
  },
  {
    name: "GizaDao",
    symbol: "GIZA",
    slug: "gizadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gizadao.png",
  },
  {
    name: "PIGX",
    symbol: "PIGX",
    slug: "pigx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pigx.png",
  },
  {
    name: "Hummingbird Egg Token",
    symbol: "HEGG",
    slug: "hummingbird-egg-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hummingbird-egg-token.png",
  },
  {
    name: "Vader Protocol",
    symbol: "VADER",
    slug: "vader-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vader-protocol.png",
  },
  {
    name: "WEMIX",
    symbol: "WEMIX",
    slug: "wemix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wemix.png",
  },
  {
    name: "WGMI",
    symbol: "WGMI",
    slug: "wgmi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wgmi.png",
  },
  {
    name: "Altered State Token",
    symbol: "ASTO",
    slug: "altered-state-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altered-state-token.png",
  },
  {
    name: "Bitcoin SB",
    symbol: "BSB",
    slug: "bitcoin-sb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-sb.png",
  },
  {
    name: "Starpunk",
    symbol: "SRP",
    slug: "starpunk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starpunk.png",
  },
  {
    name: "Punk Vault (NFTX)",
    symbol: "PUNK",
    slug: "punk-vault-nftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/punk-vault-nftx.png",
  },
  {
    name: "Metaegg",
    symbol: "MEGG",
    slug: "metaegg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaegg.png",
  },
  {
    name: "Magic Metaverse",
    symbol: "MAC",
    slug: "magic-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-metaverse.png",
  },
  {
    name: "Globe Derivative Exchange",
    symbol: "GDT",
    slug: "globe-derivative-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/globe-derivative-exchange.png",
  },
  {
    name: "Ryoma",
    symbol: "RYOMA",
    slug: "ryoma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ryoma.png",
  },
  {
    name: "Galaxy Coin",
    symbol: "GALAXY",
    slug: "galaxycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxycoin.png",
  },
  {
    name: "Land Of Conquest",
    symbol: "SLG",
    slug: "land-of-conquest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/land-of-conquest.png",
  },
  {
    name: "Metafluence",
    symbol: "METO",
    slug: "metafluence",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafluence.png",
  },
  {
    name: "Mechaverse",
    symbol: "MC",
    slug: "mechaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mechaverse.png",
  },
  {
    name: "DefPace",
    symbol: "DPACE",
    slug: "defpace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defpace.png",
  },
  {
    name: "Ryoshis Vision",
    symbol: "RYOSHI",
    slug: "ryoshis-vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ryoshis-vision.png",
  },
  {
    name: "Dot Finance",
    symbol: "PINK",
    slug: "dot-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dot-finance.png",
  },
  {
    name: "Monopoly Millionaire Control",
    symbol: "MMC",
    slug: "monopoly-millionaire-control",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monopoly-millionaire-control.png",
  },
  {
    name: "MICROCOSM",
    symbol: "MIC",
    slug: "microcosm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microcosm.png",
  },
  {
    name: "UNITED EMIRATE DECENTRALIZED COIN.",
    symbol: "UEDC",
    slug: "united-emirate-decentralized-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/united-emirate-decentralized-coin.png",
  },
  {
    name: "Bright Token",
    symbol: "BRIGHT",
    slug: "bright-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bright-token.png",
  },
  {
    name: "Gather",
    symbol: "GTH",
    slug: "gather",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gather.png",
  },
  {
    name: "Win a MAGA Tour Package",
    symbol: "TRUMPTOUR",
    slug: "win-a-maga-tour-package",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/win-a-maga-tour-package.png",
  },
  {
    name: "POTENT",
    symbol: "PTT`",
    slug: "potent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/potent.png",
  },
  {
    name: "CASE CLOSED",
    symbol: "CASECLOSED",
    slug: "case-closed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/case-closed.png",
  },
  {
    name: "Katana Inu",
    symbol: "KATA",
    slug: "katana-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/katana-inu.png",
  },
  {
    name: "Giddy",
    symbol: "GDDY",
    slug: "giddy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/giddy.png",
  },
  {
    name: "KICK.IO",
    symbol: "KICK",
    slug: "kick-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kick-io.png",
  },
  {
    name: "Leonicorn Swap",
    symbol: "LEOS",
    slug: "leonicorn-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leonicorn-swap.png",
  },
  {
    name: "ZomaInfinity",
    symbol: "ZIN",
    slug: "zomainfinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zomainfinity.png",
  },
  {
    name: "AutoMatic Network",
    symbol: "AUMI",
    slug: "automatic-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/automatic-network.png",
  },
  {
    name: "MeowSwap",
    symbol: "MEOW",
    slug: "meowswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meowswap.png",
  },
  {
    name: "Doge Digger",
    symbol: "DOGEDIGGER",
    slug: "doge-digger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-digger.png",
  },
  {
    name: "Blizz Finance",
    symbol: "BLZZ",
    slug: "blizz-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blizz-finance.png",
  },
  {
    name: "Meme Lordz",
    symbol: "$LORDZ",
    slug: "meme-lordz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meme-lordz.png",
  },
  {
    name: "IRON Titanium Token",
    symbol: "TITAN",
    slug: "iron-titanium-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iron-titanium-token.png",
  },
  {
    name: "Railgun",
    symbol: "RAIL",
    slug: "railgun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/railgun.png",
  },
  {
    name: "MetaPlay",
    symbol: "MPLAY",
    slug: "metaplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaplay.png",
  },
  {
    name: "Revest Finance",
    symbol: "RVST",
    slug: "revest-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revest-finance.png",
  },
  {
    name: "Life Crypto",
    symbol: "LIFE",
    slug: "life-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/life-crypto.png",
  },
  {
    name: "Chihuahua Token",
    symbol: "CHH",
    slug: "chihuahua-token-19fcd0de-eb4d-4fd7-bc4a-a202247dfdbb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chihuahua-token-19fcd0de-eb4d-4fd7-bc4a-a202247dfdbb.png",
  },
  {
    name: "Nexum",
    symbol: "NEXM",
    slug: "nexum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nexum.png",
  },
  {
    name: "Mrweb Finance V2",
    symbol: "AMA",
    slug: "mrweb-finance-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mrweb-finance-v2.png",
  },
  {
    name: "Kikswap",
    symbol: "KIK",
    slug: "kikswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kikswap.png",
  },
  {
    name: "Tianyu Finance",
    symbol: "TYC",
    slug: "tianyu-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tianyu-finance.png",
  },
  {
    name: "DAOFi",
    symbol: "DAOFI",
    slug: "daofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daofi.png",
  },
  {
    name: "Metal Backed Money",
    symbol: "MBMX",
    slug: "metal-backed-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metal-backed-money.png",
  },
  {
    name: "Husky Avax",
    symbol: "HUSKY",
    slug: "husky-avax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/husky-avax.png",
  },
  {
    name: "Police and Thief Game",
    symbol: "LOOT",
    slug: "police-and-thief-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/police-and-thief-game.png",
  },
  {
    name: "GamiFi.GG",
    symbol: "GMI",
    slug: "gamifi-gg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamifi-gg.png",
  },
  {
    name: "NFTLaunch",
    symbol: "NFTL",
    slug: "nftlaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftlaunch.png",
  },
  {
    name: "SpaceY",
    symbol: "SPAY",
    slug: "spacey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacey.png",
  },
  {
    name: "KINGDOGE",
    symbol: "KDOGE",
    slug: "kingdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdoge.png",
  },
  {
    name: "Duckie Land",
    symbol: "MMETA",
    slug: "duckie-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duckie-land.png",
  },
  {
    name: "BitUp",
    symbol: "BitUp",
    slug: "bitup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitup.png",
  },
  {
    name: "Hot Cross",
    symbol: "HOTCROSS",
    slug: "hot-cross",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hot-cross.png",
  },
  {
    name: "MXM Token",
    symbol: "MXM",
    slug: "mxm-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mxm-token.png",
  },
  {
    name: "MetaReserve.Finance",
    symbol: "POWER",
    slug: "metareserve-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metareserve-finance.png",
  },
  {
    name: "Future Of Fintech",
    symbol: "FOF",
    slug: "future-of-fintech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/future-of-fintech.png",
  },
  {
    name: "BabySafeMoon",
    symbol: "BSFM",
    slug: "babysafemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babysafemoon.png",
  },
  {
    name: "Luxurious Pro Network Token",
    symbol: "LPNT",
    slug: "luxurious-pro-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luxurious-pro-network-token.png",
  },
  {
    name: "Crypto Legions V3",
    symbol: "BLV3",
    slug: "crypto-legions-v3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-legions-v3.png",
  },
  {
    name: "Drachen Lord",
    symbol: "DRAG",
    slug: "drachen-lord",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drachen-lord.png",
  },
  {
    name: "HalfPizza",
    symbol: "PIZA",
    slug: "halfpizza",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/halfpizza.png",
  },
  {
    name: "Bxmi Token",
    symbol: "BXMI",
    slug: "bxmi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bxmi-token.png",
  },
  {
    name: "CellETF",
    symbol: "ECELL",
    slug: "consensus-cell-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/consensus-cell-network.png",
  },
  {
    name: "Red Floki",
    symbol: "REDFLOKI",
    slug: "red-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/red-floki.png",
  },
  {
    name: "MirArc Chain",
    symbol: "MAT",
    slug: "mirarc-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirarc-chain.png",
  },
  {
    name: "Clam Island",
    symbol: "GEM",
    slug: "clam-island",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clam-island.png",
  },
  {
    name: "Melalie",
    symbol: "MEL",
    slug: "melalie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/melalie.png",
  },
  {
    name: "VESQ",
    symbol: "VESQ",
    slug: "vesq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vesq.png",
  },
  {
    name: "3x Short EOS Token",
    symbol: "EOSBEAR",
    slug: "3x-short-eos-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-eos-token.png",
  },
  {
    name: "ThetaDrop",
    symbol: "TDROP",
    slug: "thetadrop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thetadrop.png",
  },
  {
    name: "Sunflower Finance",
    symbol: "SFO",
    slug: "sunflower-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sunflower-finance.png",
  },
  {
    name: "RPS LEAGUE",
    symbol: "RPS",
    slug: "rps-league",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rps-league.png",
  },
  {
    name: "Humans.ai",
    symbol: "HEART",
    slug: "humans-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/humans-ai.png",
  },
  {
    name: "Bomb Money",
    symbol: "BSHARE",
    slug: "bshare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bshare.png",
  },
  {
    name: "Safemars",
    symbol: "SAFEMARS",
    slug: "safemars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemars.png",
  },
  {
    name: "Magic Internet Money",
    symbol: "MIM",
    slug: "magic-internet-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-internet-money.png",
  },
  {
    name: "Chellitcoin",
    symbol: "CHLT",
    slug: "chellitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chellitcoin.png",
  },
  {
    name: "MegaCryptoPolis",
    symbol: "MEGA",
    slug: "megacryptopolis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megacryptopolis.png",
  },
  {
    name: "Bent Finance",
    symbol: "BENT",
    slug: "bent-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bent-finance.png",
  },
  {
    name: "Bitrise Token",
    symbol: "BRISE",
    slug: "bitrise-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitrise-token.png",
  },
  {
    name: "BALI TOKEN",
    symbol: "BLI",
    slug: "bali-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bali-token.png",
  },
  {
    name: "Rainmaker Games",
    symbol: "RAIN",
    slug: "rainmaker-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rainmaker-games.png",
  },
  {
    name: "CryptoSummoner",
    symbol: "SMNR",
    slug: "cryptosummoner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptosummoner.png",
  },
  {
    name: "WaykiChain Governance Coin",
    symbol: "WGRT",
    slug: "waykichain-governance-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waykichain-governance-coin.png",
  },
  {
    name: "CryptoSword",
    symbol: "SWD",
    slug: "cryptosword",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptosword.png",
  },
  {
    name: "MetaPets",
    symbol: "METAPETS",
    slug: "metapets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metapets.png",
  },
  {
    name: "Pylon Eco Token",
    symbol: "PETN",
    slug: "pylon-eco-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pylon-eco-token.png",
  },
  {
    name: "Ecio",
    symbol: "ECIO",
    slug: "ecio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecio.png",
  },
  {
    name: "1INCHUP",
    symbol: "1INCHUP",
    slug: "1inchup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1inchup.png",
  },
  {
    name: "YFIUP",
    symbol: "YFIUP",
    slug: "yfiup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfiup.png",
  },
  {
    name: "WiseAvax",
    symbol: "WISE",
    slug: "wiseavax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wiseavax.png",
  },
  {
    name: "LaserEyes",
    symbol: "LSR",
    slug: "lasereyes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lasereyes.png",
  },
  {
    name: "NearPad",
    symbol: "PAD",
    slug: "nearpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nearpad.png",
  },
  {
    name: "BitcoinVend",
    symbol: "BCVT",
    slug: "bitcoinvend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinvend.png",
  },
  {
    name: "Mytheria",
    symbol: "MYRA",
    slug: "mytheria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mytheria.png",
  },
  {
    name: "Cosmic Music",
    symbol: "CSMC",
    slug: "cosmic-music",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cosmic-music.png",
  },
  {
    name: "LunaFox",
    symbol: "LUFX",
    slug: "lunafox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunafox.png",
  },
  {
    name: "Gamebox",
    symbol: "GAMEBOX",
    slug: "gamebox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamebox.png",
  },
  {
    name: "Pegazus finance",
    symbol: "PEG",
    slug: "pegazus-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pegazus-finance.png",
  },
  {
    name: "SandBox Pro",
    symbol: "SANDPRO",
    slug: "sandbox-pro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sandbox-pro.png",
  },
  {
    name: "GameStar",
    symbol: "GMS",
    slug: "gamestar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamestar.png",
  },
  {
    name: "Ancient Kingdom",
    symbol: "DOM",
    slug: "ancient-kingdom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ancient-kingdom.png",
  },
  {
    name: "Mercenary",
    symbol: "MGOLD",
    slug: "mercenary",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mercenary.png",
  },
  {
    name: "Australian Safe Shepherd",
    symbol: "ASS",
    slug: "australian-safe-shepherd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/australian-safe-shepherd.png",
  },
  {
    name: "CryptoVsZombie",
    symbol: "CVZ",
    slug: "cryptovszombie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptovszombie.png",
  },
  {
    name: "Ark Rivals",
    symbol: "ARKN",
    slug: "ark-rivals",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ark-rivals.png",
  },
  {
    name: "WTF Token",
    symbol: "WTF",
    slug: "wtf-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wtf-token.png",
  },
  {
    name: "GameNFT",
    symbol: "GNFT",
    slug: "gamenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamenft.png",
  },
  {
    name: "Doont Buy",
    symbol: "DBUY",
    slug: "doont-buy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doont-buy.png",
  },
  {
    name: "Port Finance",
    symbol: "PORT",
    slug: "port-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/port-finance.png",
  },
  {
    name: "WEYU",
    symbol: "WEYU",
    slug: "weyu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weyu.png",
  },
  {
    name: "Exchange Union",
    symbol: "XUC",
    slug: "exchange-union",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exchange-union.png",
  },
  {
    name: "SurfMoon",
    symbol: "SURFMOON",
    slug: "surfmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/surfmoon.png",
  },
  {
    name: "nUSD (HotBit)",
    symbol: "nUSD",
    slug: "nusd-token-hb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nusd-token-hb.png",
  },
  {
    name: "Hotbit Token",
    symbol: "HTB",
    slug: "hotbit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hotbit-token.png",
  },
  {
    name: "DogeZoo",
    symbol: "DZOO",
    slug: "dogezoo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogezoo.png",
  },
  {
    name: "Nabox",
    symbol: "NABOX",
    slug: "nabox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nabox.png",
  },
  {
    name: "JOCK",
    symbol: "JOCK",
    slug: "jock-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jock-token.png",
  },
  {
    name: "Xiasi Inu",
    symbol: "XIASI",
    slug: "xiasi-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xiasi-inu.png",
  },
  {
    name: "Treat",
    symbol: "TREAT",
    slug: "treat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treat.png",
  },
  {
    name: "Multi-Chain Capital (new)",
    symbol: "MCC",
    slug: "multi-chain-capital-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multi-chain-capital-new.png",
  },
  {
    name: "Gunstar Metaverse",
    symbol: "GST",
    slug: "gunstar-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gunstar-metaverse.png",
  },
  {
    name: "Nafter",
    symbol: "NAFT",
    slug: "nafter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nafter.png",
  },
  {
    name: "MetaDoge",
    symbol: "METADOGE",
    slug: "metadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadoge.png",
  },
  {
    name: "MatrixETF",
    symbol: "MDF",
    slug: "matrixetf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrixetf.png",
  },
  {
    name: "SpaceX Floki",
    symbol: "XFLOKI",
    slug: "spacex-floki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacex-floki.png",
  },
  {
    name: "My Liquidity Partner",
    symbol: "MLP",
    slug: "my-liquidity-partner",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-liquidity-partner.png",
  },
  {
    name: "Pitch Finance",
    symbol: "PFT",
    slug: "pitch-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pitch-finance.png",
  },
  {
    name: "DefiXBet",
    symbol: "DXB",
    slug: "defixbet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defixbet.png",
  },
  {
    name: "UniWorld",
    symbol: "UNW",
    slug: "uniworld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniworld.png",
  },
  {
    name: "ProSwap",
    symbol: "PROS",
    slug: "proswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proswap.png",
  },
  {
    name: "Outrace",
    symbol: "ORE",
    slug: "outrace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/outrace.png",
  },
  {
    name: "Sulgecoin",
    symbol: "SUG",
    slug: "sulgecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sulgecoin.png",
  },
  {
    name: "Zafira",
    symbol: "ZFAI",
    slug: "zafira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zafira.png",
  },
  {
    name: "FreeRossDAO",
    symbol: "FREE",
    slug: "freerossdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freerossdao.png",
  },
  {
    name: "Gains Associates",
    symbol: "GAINS",
    slug: "gains-associates",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gains-associates.png",
  },
  {
    name: "Eagle Vision",
    symbol: "EVI",
    slug: "eagle-vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eagle-vision.png",
  },
  {
    name: "Luna Rush",
    symbol: "LUS",
    slug: "luna-rush",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luna-rush.png",
  },
  {
    name: "HOPR",
    symbol: "HOPR",
    slug: "hopr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hopr.png",
  },
  {
    name: "Spume Protocol",
    symbol: "SPUME",
    slug: "spume",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spume.png",
  },
  {
    name: "QiDao",
    symbol: "QI",
    slug: "qidao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qidao.png",
  },
  {
    name: "veDAO",
    symbol: "WEVE",
    slug: "vedao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vedao.png",
  },
  {
    name: "EverRise",
    symbol: "RISE",
    slug: "everrise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everrise.png",
  },
  {
    name: "Storm Token",
    symbol: "STORM",
    slug: "storm-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/storm-token.png",
  },
  {
    name: "Euler Finance",
    symbol: "EUL",
    slug: "euler-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/euler-finance.png",
  },
  {
    name: "Idea Chain Coin",
    symbol: "ICH",
    slug: "idea-chain-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idea-chain-coin.png",
  },
  {
    name: "Moonscape",
    symbol: "MSCP",
    slug: "moonscape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonscape.png",
  },
  {
    name: "Copycat Finance",
    symbol: "COPYCAT",
    slug: "copycat-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/copycat-finance.png",
  },
  {
    name: "Block Duelers NFT Battles",
    symbol: "BDT",
    slug: "block-duelers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block-duelers.png",
  },
  {
    name: "Dibs Money",
    symbol: "DIBS",
    slug: "dibs-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dibs-money.png",
  },
  {
    name: "Louverture",
    symbol: "LVT",
    slug: "louverture",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/louverture.png",
  },
  {
    name: "Bobcoin",
    symbol: "BOBC",
    slug: "bobcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bobcoin.png",
  },
  {
    name: "MetaVPad",
    symbol: "METAV",
    slug: "metavpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metavpad.png",
  },
  {
    name: "Good Games Guild",
    symbol: "GGG",
    slug: "good-games-guild",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/good-games-guild.png",
  },
  {
    name: "Guild of Guardians",
    symbol: "GOG",
    slug: "guild-of-guardians",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guild-of-guardians.png",
  },
  {
    name: "Kryptobellion",
    symbol: "KRYPTO",
    slug: "kryptobellion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kryptobellion.png",
  },
  {
    name: "Auric Network",
    symbol: "AUSCM",
    slug: "auric-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/auric-network.png",
  },
  {
    name: "Shiba Puppy",
    symbol: "ShibaPuppy",
    slug: "shiba-puppy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-puppy.png",
  },
  {
    name: "Zombie Inu",
    symbol: "ZINU",
    slug: "zombie-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zombie-inu.png",
  },
  {
    name: "SaylorMoon",
    symbol: "SMOON",
    slug: "saylormoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saylormoon.png",
  },
  {
    name: "Baby Doge Money Maker",
    symbol: "BABYDOGEMM",
    slug: "baby-doge-money-maker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-doge-money-maker.png",
  },
  {
    name: "Transparent Token",
    symbol: "TRANSPARENT",
    slug: "transparent-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/transparent-token.png",
  },
  {
    name: "3X Long Chainlink Token",
    symbol: "LINKBULL",
    slug: "3x-long-chainlink-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-chainlink-token.png",
  },
  {
    name: "Cryptostone",
    symbol: "CPS",
    slug: "cryptostone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptostone.png",
  },
  {
    name: "LuckyPig",
    symbol: "LuckyPig",
    slug: "luckypig",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luckypig.png",
  },
  {
    name: "Solyard Finance",
    symbol: "YARD",
    slug: "solyard-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solyard-finance.png",
  },
  {
    name: "Ethereum Pro",
    symbol: "EPRO",
    slug: "ethereum-pro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-pro.png",
  },
  {
    name: "World of Cryptia",
    symbol: "CRYPT",
    slug: "world-of-cryptia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-of-cryptia.png",
  },
  {
    name: "Universal Basic Income",
    symbol: "UBI",
    slug: "universal-basic-income",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universal-basic-income.png",
  },
  {
    name: "CoinsPaid",
    symbol: "CPD",
    slug: "coinspaid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinspaid.png",
  },
  {
    name: "Yieldification",
    symbol: "YDF",
    slug: "yieldification",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yieldification.png",
  },
  {
    name: "Laika",
    symbol: "LAIKA",
    slug: "laika",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laika.png",
  },
  {
    name: "ImpulseVen",
    symbol: "VEN",
    slug: "impulseven",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/impulseven.png",
  },
  {
    name: "Nano Shiba Inu",
    symbol: "NANOSHIBA",
    slug: "nano-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nano-shiba-inu.png",
  },
  {
    name: "GroupDao",
    symbol: "GDO",
    slug: "groupdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/groupdao.png",
  },
  {
    name: "Undead Blocks",
    symbol: "UNDEAD",
    slug: "undead-blocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/undead-blocks.png",
  },
  {
    name: "Clifford Inu",
    symbol: "CLIFF",
    slug: "clifford-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clifford-inu.png",
  },
  {
    name: "International Squid Games",
    symbol: "SQUIDGAMES",
    slug: "international-squid-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/international-squid-games.png",
  },
  {
    name: "Kokoswap",
    symbol: "KOKO",
    slug: "kokoswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kokoswap.png",
  },
  {
    name: "STABLE ASSET",
    symbol: "STA",
    slug: "stable-asset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stable-asset.png",
  },
  {
    name: "handleFOREX",
    symbol: "FOREX",
    slug: "handle-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/handle-fi.png",
  },
  {
    name: "THORSwap",
    symbol: "THOR",
    slug: "thorswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thorswap.png",
  },
  {
    name: "Teddy Doge",
    symbol: "TEDDY",
    slug: "teddydoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teddydoge.png",
  },
  {
    name: "Lovely Inu",
    symbol: "LOVELY",
    slug: "lovely-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lovely-inu.png",
  },
  {
    name: "Metasens",
    symbol: "MSU",
    slug: "metasens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metasens.png",
  },
  {
    name: "Pancake Games",
    symbol: "GCAKE",
    slug: "pancake-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pancake-games.png",
  },
  {
    name: "3X Long Dogecoin Token",
    symbol: "DOGEBULL",
    slug: "3x-long-dogecoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-dogecoin-token.png",
  },
  {
    name: "Youclout",
    symbol: "YCT",
    slug: "youclout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/youclout.png",
  },
  {
    name: "EC Bet Network",
    symbol: "ECN",
    slug: "ec-bet-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ec-bet-network.png",
  },
  {
    name: "TopManager",
    symbol: "TMT",
    slug: "topmanager",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/topmanager.png",
  },
  {
    name: "BSC MemePad",
    symbol: "BSCM",
    slug: "bsc-memepad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsc-memepad.png",
  },
  {
    name: "DeCredit",
    symbol: "CDTC",
    slug: "decredit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decredit.png",
  },
  {
    name: "DeFi of Thrones",
    symbol: "DOTX",
    slug: "deli-of-thrones",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deli-of-thrones.png",
  },
  {
    name: "TABOO TOKEN",
    symbol: "TABOO",
    slug: "taboo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taboo-token.png",
  },
  {
    name: "hiPunks",
    symbol: "HIPUNKS",
    slug: "hipunks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hipunks.png",
  },
  {
    name: "Eagle Token",
    symbol: "EAGLE",
    slug: "eagle-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eagle-token.png",
  },
  {
    name: "Poken",
    symbol: "PKN",
    slug: "poken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poken.png",
  },
  {
    name: "CryptoZoo  (new)",
    symbol: "ZOO",
    slug: "zoo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoo.png",
  },
  {
    name: "Pluton Chain",
    symbol: "PLC",
    slug: "pluton-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pluton-chain.png",
  },
  {
    name: "Calamari Network",
    symbol: "KMA",
    slug: "calamari-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calamari-network.png",
  },
  {
    name: "MonkeyBall",
    symbol: "MBS",
    slug: "monkeyball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monkeyball.png",
  },
  {
    name: "Clearpool",
    symbol: "CPOOL",
    slug: "clearpool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clearpool.png",
  },
  {
    name: "Loud Market",
    symbol: "LOUD",
    slug: "loud-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loud-market.png",
  },
  {
    name: "Dragonairenfts",
    symbol: "DEAR",
    slug: "dragonairenfts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonairenfts.png",
  },
  {
    name: "Peppa Network",
    symbol: "PEPPA",
    slug: "peppa-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peppa-network.png",
  },
  {
    name: "Hector DAO",
    symbol: "HEC",
    slug: "hector-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hector-dao.png",
  },
  {
    name: "0xWallet Token",
    symbol: "0XW",
    slug: "0xwallet-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0xwallet-token.png",
  },
  {
    name: "Stone DeFi",
    symbol: "STN",
    slug: "stone-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stone-token.png",
  },
  {
    name: "Hayya",
    symbol: "HAYYA",
    slug: "hayya",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hayya.png",
  },
  {
    name: "Sportsverse",
    symbol: "SV",
    slug: "sportverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sportverse.png",
  },
  {
    name: "Uniqly",
    symbol: "UNIQ",
    slug: "uniqly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniqly.png",
  },
  {
    name: "Terra Name Service",
    symbol: "TNS",
    slug: "terra-name-service",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terra-name-service.png",
  },
  {
    name: "Vector Finance",
    symbol: "VTX",
    slug: "vector-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vector-finance.png",
  },
  {
    name: "Heroes Chained",
    symbol: "HEC",
    slug: "heroes-chained",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heroes-chained.png",
  },
  {
    name: "miniSHIB",
    symbol: "MINISHIB",
    slug: "minishib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minishib.png",
  },
  {
    name: "EmiSwap",
    symbol: "ESW",
    slug: "emiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emiswap.png",
  },
  {
    name: "Hydra",
    symbol: "HYDRA",
    slug: "hydra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hydra.png",
  },
  {
    name: "AXL INU",
    symbol: "AXL",
    slug: "axl-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axl-inu.png",
  },
  {
    name: "MILKY FINANCE",
    symbol: "MIFI",
    slug: "milky-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milky-finance.png",
  },
  {
    name: "VLaunch",
    symbol: "VPAD",
    slug: "vlaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vlaunch.png",
  },
  {
    name: "Metal Blockchain",
    symbol: "METAL",
    slug: "metal-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metal-blockchain.png",
  },
  {
    name: "Galaxy War",
    symbol: "GWT",
    slug: "galaxy-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-war.png",
  },
  {
    name: "Kujira",
    symbol: "KUJI",
    slug: "kujira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kujira.png",
  },
  {
    name: "TAI",
    symbol: "TAI",
    slug: "tai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tai.png",
  },
  {
    name: "Solum",
    symbol: "SOLUM",
    slug: "solum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solum.png",
  },
  {
    name: "Supersonic Finance",
    symbol: "SSN",
    slug: "supersonic-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supersonic-finance.png",
  },
  {
    name: "Thrupenny",
    symbol: "TPY",
    slug: "thrupenny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thrupenny.png",
  },
  {
    name: "Astar",
    symbol: "ASTR",
    slug: "astar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astar.png",
  },
  {
    name: "MOVE Network",
    symbol: "MOVD",
    slug: "move-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/move-network.png",
  },
  {
    name: "Revault Network",
    symbol: "REVA",
    slug: "revault-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revault-network.png",
  },
  {
    name: "Wrapped ECOMI",
    symbol: "WOMI",
    slug: "wrapped-ecomi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-ecomi.png",
  },
  {
    name: "Manga Token",
    symbol: "$MANGA",
    slug: "manga-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manga-token.png",
  },
  {
    name: "Shiboki",
    symbol: "SHIBOKI",
    slug: "shiboki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiboki.png",
  },
  {
    name: "Ultrasafe",
    symbol: "ULTRA",
    slug: "ultrasafe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultrasafe.png",
  },
  {
    name: "Pancakelock",
    symbol: "PLOCK",
    slug: "pancakelock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pancakelock.png",
  },
  {
    name: "SafeWolf",
    symbol: "SW",
    slug: "safewolf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safewolf.png",
  },
  {
    name: "Civilization",
    symbol: "CIV",
    slug: "civilization",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/civilization.png",
  },
  {
    name: "Gulf Coin",
    symbol: "GULF",
    slug: "gulf-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gulf-coin.png",
  },
  {
    name: "CherrySwap",
    symbol: "CHE",
    slug: "cherryswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cherryswap.png",
  },
  {
    name: "Launchpool",
    symbol: "LPOOL",
    slug: "launchpool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/launchpool.png",
  },
  {
    name: "Lumi Credits",
    symbol: "LUMI",
    slug: "lumi-credits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lumi-credits.png",
  },
  {
    name: "Only Up",
    symbol: "$UP",
    slug: "onlyup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onlyup.png",
  },
  {
    name: "WPP TOKEN",
    symbol: "WPP",
    slug: "wpp-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wpp-token.png",
  },
  {
    name: "Fish Crypto",
    symbol: "FICO",
    slug: "fish-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fish-crypto.png",
  },
  {
    name: "RING Financial",
    symbol: "RING",
    slug: "ring-financial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ring-financial.png",
  },
  {
    name: "Nexus Dubai",
    symbol: "NXD",
    slug: "nexus-dubai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nexus-dubai.png",
  },
  {
    name: "Hello Art",
    symbol: "HTT",
    slug: "hello-art",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hello-art.png",
  },
  {
    name: "Black Eye Galaxy",
    symbol: "BYG",
    slug: "black-eye-galaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-eye-galaxy.png",
  },
  {
    name: "2crazyNFT",
    symbol: "2CRZ",
    slug: "2crazynft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2crazynft.png",
  },
  {
    name: "Eqifi",
    symbol: "EQX",
    slug: "eqifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eqifi.png",
  },
  {
    name: "MOOCHII",
    symbol: "MOOCHII",
    slug: "moochii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moochii.png",
  },
  {
    name: "CryptoDrop",
    symbol: "CDROP",
    slug: "cryptodrop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptodrop.png",
  },
  {
    name: "xMARK",
    symbol: "XMARK",
    slug: "xmark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xmark.png",
  },
  {
    name: "Mongoose",
    symbol: "MONGOOSE",
    slug: "mongoose",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mongoose.png",
  },
  {
    name: "PIAS",
    symbol: "PIAS",
    slug: "pias",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pias.png",
  },
  {
    name: "IAGON",
    symbol: "IAG",
    slug: "iagon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iagon.png",
  },
  {
    name: "MetaFinance",
    symbol: "MFI",
    slug: "metafinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metafinance.png",
  },
  {
    name: "DiamondDAO",
    symbol: "DMND",
    slug: "diamonddao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamonddao.png",
  },
  {
    name: "Poodl Token",
    symbol: "POODL",
    slug: "poodle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poodle.png",
  },
  {
    name: "MemeKiller",
    symbol: "KILL",
    slug: "memekiller",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memekiller.png",
  },
  {
    name: "safeswap.online",
    symbol: "SWAP",
    slug: "safeswaponline",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeswaponline.png",
  },
  {
    name: "Project X",
    symbol: "XIL",
    slug: "projectx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/projectx.png",
  },
  {
    name: "Realy",
    symbol: "REAL",
    slug: "realy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realy.png",
  },
  {
    name: "CDbio",
    symbol: "MCD",
    slug: "cdbio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cdbio.png",
  },
  {
    name: "HOPPY",
    symbol: "HOP",
    slug: "hoppy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoppy.png",
  },
  {
    name: "Gondola Finance",
    symbol: "GDL",
    slug: "gondola-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gondola-finance.png",
  },
  {
    name: "Synapse Network",
    symbol: "SNP",
    slug: "synapse-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synapse-network.png",
  },
  {
    name: "MCDEX",
    symbol: "MCB",
    slug: "mcdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcdex.png",
  },
  {
    name: "Joystick",
    symbol: "JOY",
    slug: "joystick",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joystick.png",
  },
  {
    name: "CasinoCoin",
    symbol: "CSC",
    slug: "casinocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/casinocoin.png",
  },
  {
    name: "Hop Protocol",
    symbol: "HOP",
    slug: "hop-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hop-exchange.png",
  },
  {
    name: "Loomi",
    symbol: "LOOMI",
    slug: "loomi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loomi.png",
  },
  {
    name: "Multiverse",
    symbol: "MVS",
    slug: "multiverse-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multiverse-token.png",
  },
  {
    name: "BlockAura",
    symbol: "TBAC",
    slug: "blockaura",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockaura.png",
  },
  {
    name: "AMC FIGHT NIGHT",
    symbol: "AMC",
    slug: "amc-fight-night",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amc-fight-night.png",
  },
  {
    name: "InpulseX",
    symbol: "IPX",
    slug: "inpulsex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inpulsex.png",
  },
  {
    name: "EthereumMax",
    symbol: "EMAX",
    slug: "ethereummax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereummax.png",
  },
  {
    name: "Beau Cat",
    symbol: "BUC",
    slug: "beau-cat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beau-cat.png",
  },
  {
    name: "NFT Tech",
    symbol: "NFTT",
    slug: "nft-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-tech.png",
  },
  {
    name: "Zodium",
    symbol: "ZODI",
    slug: "zodium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zodium.png",
  },
  {
    name: "Koinomo",
    symbol: "KMO",
    slug: "koinomo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koinomo.png",
  },
  {
    name: "Adadex Tools",
    symbol: "ADAT",
    slug: "adadex-tools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adadex-tools.png",
  },
  {
    name: "ZED Token",
    symbol: "ZED",
    slug: "zed-run-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zed-run-token.png",
  },
  {
    name: "Keanu Inu",
    symbol: "KEANU",
    slug: "keanu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keanu-inu.png",
  },
  {
    name: "GreenAir",
    symbol: "GREEN",
    slug: "greenair",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/greenair.png",
  },
  {
    name: "CYBER SOCCER",
    symbol: "COCA",
    slug: "cyber-soccer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyber-soccer.png",
  },
  {
    name: "Decentralized USD(Defichain)",
    symbol: "DUSD",
    slug: "decentralized-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-usd.png",
  },
  {
    name: "Nodle",
    symbol: "NODL",
    slug: "nodleiot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nodleiot.png",
  },
  {
    name: "Gambler Shiba",
    symbol: "GSHIBA",
    slug: "gambler-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gambler-shiba.png",
  },
  {
    name: "Kollect",
    symbol: "KOL",
    slug: "kollect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kollect.png",
  },
  {
    name: "BabyHarmony",
    symbol: "BABYHARMONY",
    slug: "babyharmony",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyharmony.png",
  },
  {
    name: "FlokiFrunkPuppy",
    symbol: "FLOKIFRUNKPUPPY",
    slug: "flokifrunkpuppy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokifrunkpuppy.png",
  },
  {
    name: "Women Empowerment Token",
    symbol: "WEMP",
    slug: "women-empowerment-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/women-empowerment-token.png",
  },
  {
    name: "Bidao",
    symbol: "BID",
    slug: "bidao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bidao.png",
  },
  {
    name: "Meta Speed Game",
    symbol: "MTSG",
    slug: "meta-speed-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-speed-game.png",
  },
  {
    name: "Legion Network",
    symbol: "LGX",
    slug: "legion-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legion-network.png",
  },
  {
    name: "Pallapay",
    symbol: "PALLA",
    slug: "pallapay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pallapay.png",
  },
  {
    name: "MAGA Coin",
    symbol: "MAGA",
    slug: "maga-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maga-coin.png",
  },
  {
    name: "CryptoTrains",
    symbol: "CTRAIN",
    slug: "cryptotrains",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptotrains.png",
  },
  {
    name: "Hoard",
    symbol: "HRD",
    slug: "hoard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoard.png",
  },
  {
    name: "Green Beli",
    symbol: "GRBE",
    slug: "green-beli",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-beli.png",
  },
  {
    name: "pSTAKE Finance",
    symbol: "PSTAKE",
    slug: "pstake-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pstake-finance.png",
  },
  {
    name: "Funder One Capital",
    symbol: "FUNDX",
    slug: "funder-one-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/funder-one-capital.png",
  },
  {
    name: "Rise of Defenders",
    symbol: "RDR",
    slug: "rise-of-defenders",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rise-of-defenders.png",
  },
  {
    name: "Aegis Launchpad",
    symbol: "AGSPAD",
    slug: "aegis-launchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aegis-launchpad.png",
  },
  {
    name: "Parrot Protocol",
    symbol: "PRT",
    slug: "parrot-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parrot-protocol.png",
  },
  {
    name: "DeFi Wizard",
    symbol: "DWZ",
    slug: "defi-wizard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-wizard.png",
  },
  {
    name: "Xolo Metaverse",
    symbol: "XOLO",
    slug: "xolo-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xolo-metaverse.png",
  },
  {
    name: "MM Optimizer (Polygon)",
    symbol: "MMO",
    slug: "mm-optimizer-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mm-optimizer-polygon.png",
  },
  {
    name: "NFT Art Finance",
    symbol: "NFTART",
    slug: "nft-art-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-art-finance.png",
  },
  {
    name: "TripCandy",
    symbol: "CANDY",
    slug: "tripcandy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tripcandy.png",
  },
  {
    name: "Revolve Games",
    symbol: "RPG",
    slug: "revolve-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revolve-games.png",
  },
  {
    name: "StarSharks (SSS)",
    symbol: "SSS",
    slug: "starsharks-sss",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starsharks-sss.png",
  },
  {
    name: "NFMonsters",
    symbol: "NFMON",
    slug: "nfmonsters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nfmonsters.png",
  },
  {
    name: "Mops",
    symbol: "MOPS",
    slug: "mops",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mops.png",
  },
  {
    name: "MilliMeter",
    symbol: "MM",
    slug: "millimeter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/millimeter.png",
  },
  {
    name: "CorgiCoin",
    symbol: "CORGI",
    slug: "corgicoin-net",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corgicoin-net.png",
  },
  {
    name: "Sakura Planet",
    symbol: "SAK",
    slug: "sakura-planet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sakura-planet.png",
  },
  {
    name: "cCOMP",
    symbol: "CCOMP",
    slug: "ccomp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ccomp.png",
  },
  {
    name: "TORG",
    symbol: "TORG",
    slug: "torg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/torg.png",
  },
  {
    name: "Qfora",
    symbol: "QUROZ",
    slug: "qfora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qfora.png",
  },
  {
    name: "Manarium",
    symbol: "ARI",
    slug: "manarium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manarium.png",
  },
  {
    name: "Arowana Token",
    symbol: "ARW",
    slug: "arowana-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arowana-token.png",
  },
  {
    name: "MonsterQuest",
    symbol: "MQST",
    slug: "monsterquest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monsterquest.png",
  },
  {
    name: "XRUN",
    symbol: "XRUN",
    slug: "xrun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrun.png",
  },
  {
    name: "MetaWars",
    symbol: "WARS",
    slug: "metawars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metawars.png",
  },
  {
    name: "Hugo Finance",
    symbol: "HUGO",
    slug: "hugo-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hugo-finance.png",
  },
  {
    name: "Tao Te Ching",
    symbol: "TTC",
    slug: "tao-te-ching",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tao-te-ching.png",
  },
  {
    name: "BabyCare",
    symbol: "BABYCARE",
    slug: "babycare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babycare.png",
  },
  {
    name: "The Debt Box",
    symbol: "DEBT",
    slug: "the-debt-box",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-debt-box.png",
  },
  {
    name: "WagyuSwap",
    symbol: "WAG",
    slug: "wagyuswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wagyuswap.png",
  },
  {
    name: "USDP Stablecoin",
    symbol: "USDP",
    slug: "usdp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdp.png",
  },
  {
    name: "Vaulty Finance",
    symbol: "$VLTY",
    slug: "vaulty-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vaulty-finance.png",
  },
  {
    name: "CakeWSwap",
    symbol: "CAKEW",
    slug: "cakewswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cakewswap.png",
  },
  {
    name: "Reserve",
    symbol: "RSV",
    slug: "reserve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reserve.png",
  },
  {
    name: "Multi-Chain Capital",
    symbol: "MCC",
    slug: "multi-chain-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multi-chain-capital.png",
  },
  {
    name: "Gorilla Diamond",
    symbol: "GDT",
    slug: "gorilla-diamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gorilla-diamond.png",
  },
  {
    name: "World of Defish",
    symbol: "WOD",
    slug: "world-of-defish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-of-defish.png",
  },
  {
    name: "Infinite Ecosystem",
    symbol: "INFINITY",
    slug: "infinite-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinite-ecosystem.png",
  },
  {
    name: "Flokinomics",
    symbol: "FLOKIN",
    slug: "flokinomics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flokinomics.png",
  },
  {
    name: "Smart Reward Token",
    symbol: "SRT",
    slug: "smart-reward-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-reward-token.png",
  },
  {
    name: "DADDY CAKE",
    symbol: "DADDYCAKE",
    slug: "daddy-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddy-cake.png",
  },
  {
    name: "Chainlist",
    symbol: "CLIST",
    slug: "chainlist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainlist.png",
  },
  {
    name: "Taxa Token",
    symbol: "TXT",
    slug: "taxa-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taxa-token.png",
  },
  {
    name: "InfinitUp",
    symbol: "IUP",
    slug: "infinitup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitup.png",
  },
  {
    name: "RankerDAO",
    symbol: "RANKER",
    slug: "rankerdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rankerdao.png",
  },
  {
    name: "Black Box",
    symbol: "BBOX",
    slug: "black-box",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/black-box.png",
  },
  {
    name: "Cellframe",
    symbol: "CELL",
    slug: "cellframe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cellframe.png",
  },
  {
    name: "SIL.FINANCE",
    symbol: "SIL",
    slug: "sil-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sil-finance.png",
  },
  {
    name: "WOOF",
    symbol: "WOOF",
    slug: "woof",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/woof.png",
  },
  {
    name: "Gari Network",
    symbol: "GARI",
    slug: "gari",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gari.png",
  },
  {
    name: "MAGIC",
    symbol: "MAGIC",
    slug: "magic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magic-token.png",
  },
  {
    name: "xHashtag",
    symbol: "XTAG",
    slug: "xhashtag",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xhashtag.png",
  },
  {
    name: "PANCAKE HUNNY",
    symbol: "HUNNY",
    slug: "pancake-hunny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pancake-hunny.png",
  },
  {
    name: "Medacoin",
    symbol: "MEDA",
    slug: "medacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medacoin.png",
  },
  {
    name: "CFX Quantum",
    symbol: "CFXQ",
    slug: "cfx-quantum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cfx-quantum.png",
  },
  {
    name: "X2Y2",
    symbol: "X2Y2",
    slug: "x2y2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x2y2.png",
  },
  {
    name: "PolyPlay",
    symbol: "PLAY",
    slug: "polyplay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyplay.png",
  },
  {
    name: "MSQUARE GLOBAL",
    symbol: "MSQ",
    slug: "msquare-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/msquare-global.png",
  },
  {
    name: "Swaperry",
    symbol: "PERRY",
    slug: "swaperry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swaperry.png",
  },
  {
    name: "EDDASwap",
    symbol: "EDDA",
    slug: "eddaswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eddaswap.png",
  },
  {
    name: "Tsukuyomi-no-Mikoto",
    symbol: "MIKOTO",
    slug: "tsukuyomi-no-mikoto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tsukuyomi-no-mikoto.png",
  },
  {
    name: "WOLVERINU",
    symbol: "WOLVERINU",
    slug: "wolverinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolverinu.png",
  },
  {
    name: "BABY DOGE INU",
    symbol: "$BABYDOGEINU",
    slug: "baby-doge-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-doge-inu.png",
  },
  {
    name: "GridZone",
    symbol: "ZONE",
    slug: "gridzone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gridzone.png",
  },
  {
    name: "Squid Game Protocol",
    symbol: "SGPRO",
    slug: "squid-game-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squid-game-protocol.png",
  },
  {
    name: "All.me",
    symbol: "ME",
    slug: "all-me",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/all-me.png",
  },
  {
    name: "Tsuzuki Inu",
    symbol: "TZKI",
    slug: "tsuzuki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tsuzuki-inu.png",
  },
  {
    name: "Blockearth",
    symbol: "BLET",
    slug: "blockearth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockearth.png",
  },
  {
    name: "CAW(A Hunters Dream)",
    symbol: "CAW",
    slug: "caw",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caw.png",
  },
  {
    name: "Larix",
    symbol: "LARIX",
    slug: "larix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/larix.png",
  },
  {
    name: "Raku Coin",
    symbol: "RAKUC",
    slug: "raku-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raku-coin.png",
  },
  {
    name: "Multigame",
    symbol: "MULTI",
    slug: "multigame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multigame.png",
  },
  {
    name: "Laqira Protocol",
    symbol: "LQR",
    slug: "laqira-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/laqira-protocol.png",
  },
  {
    name: "Harambe Protocol",
    symbol: "RIPH",
    slug: "harambe-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harambe-protocol.png",
  },
  {
    name: "Orange Cat Token",
    symbol: "OCAT",
    slug: "orange-cat-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orange-cat-token.png",
  },
  {
    name: "Zamio",
    symbol: "ZAM",
    slug: "zamio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zamio.png",
  },
  {
    name: "CrossX",
    symbol: "CRX",
    slug: "crossx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crossx.png",
  },
  {
    name: "MagicDOGE",
    symbol: "MAGICDOGE",
    slug: "magicdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/magicdoge.png",
  },
  {
    name: "WAM",
    symbol: "WAM",
    slug: "wam-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wam-app.png",
  },
  {
    name: "Crypto Raiders",
    symbol: "RAIDER",
    slug: "crypto-raider",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-raider.png",
  },
  {
    name: "PREMA",
    symbol: "PRMX",
    slug: "prema",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prema.png",
  },
  {
    name: "ParadiseHotel NFT",
    symbol: "PHT",
    slug: "paradisehotel-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paradisehotel-nft.png",
  },
  {
    name: "hiSAND33",
    symbol: "HISAND33",
    slug: "hisand33",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hisand33.png",
  },
  {
    name: "The Everlasting Parachain",
    symbol: "ELP",
    slug: "the-everlasting-parachain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-everlasting-parachain.png",
  },
  {
    name: "ACENT",
    symbol: "ACE",
    slug: "acent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acent.png",
  },
  {
    name: "JPEG'd",
    symbol: "JPEG",
    slug: "jpeg-d",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jpeg-d.png",
  },
  {
    name: "ShowTime Potocol",
    symbol: "STP",
    slug: "showtime-potocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/showtime-potocol.png",
  },
  {
    name: "OpenLeverage",
    symbol: "OLE",
    slug: "openleverage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/openleverage.png",
  },
  {
    name: "TrusterCoin",
    symbol: "TSC",
    slug: "trustercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustercoin.png",
  },
  {
    name: "Monsterra",
    symbol: "MSTR",
    slug: "monsterra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monsterra.png",
  },
  {
    name: "MetaRacers",
    symbol: "MRS",
    slug: "metaracers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaracers.png",
  },
  {
    name: "VidyX",
    symbol: "VIDYX",
    slug: "vidyx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vidyx.png",
  },
  {
    name: "Skylight (New)",
    symbol: "SLTN",
    slug: "skylight-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skylight-new.png",
  },
  {
    name: "Melos Studio",
    symbol: "MELOS",
    slug: "melos-studio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/melos-studio.png",
  },
  {
    name: "Libartysharetoken",
    symbol: "LST",
    slug: "libartysharetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libartysharetoken.png",
  },
  {
    name: "Metagalaxy Land",
    symbol: "MEGALAND",
    slug: "metagalaxy-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagalaxy-land.png",
  },
  {
    name: "Paper DAO",
    symbol: "PPR",
    slug: "paper-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paper-dao.png",
  },
  {
    name: "gotEM",
    symbol: "GOTEM",
    slug: "gotem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gotem.png",
  },
  {
    name: "Soulsaver",
    symbol: "SOUL",
    slug: "soulsaver",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soulsaver.png",
  },
  {
    name: "Multiverse",
    symbol: "AI",
    slug: "multiverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multiverse.png",
  },
  {
    name: "BunnyCake",
    symbol: "BUNNYCAKE",
    slug: "bunnycake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunnycake.png",
  },
  {
    name: "Wizards And Dragons",
    symbol: "GP",
    slug: "wizards-and-dragons",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wizards-and-dragons.png",
  },
  {
    name: "sETH2",
    symbol: "SETH2",
    slug: "seth2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seth2.png",
  },
  {
    name: "Solidly",
    symbol: "SOLID",
    slug: "solidly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solidly.png",
  },
  {
    name: "Roxe Cash",
    symbol: "ROC",
    slug: "roxe-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roxe-cash.png",
  },
  {
    name: "BizAuto",
    symbol: "BIZA",
    slug: "bizauto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bizauto.png",
  },
  {
    name: "Napoli Fan Token",
    symbol: "NAP",
    slug: "napoli-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/napoli-fan-token.png",
  },
  {
    name: "Bonded Luna",
    symbol: "bLUNA",
    slug: "bonded-luna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonded-luna.png",
  },
  {
    name: "Thetan Coin",
    symbol: "THC",
    slug: "thetan-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thetan-coin.png",
  },
  {
    name: "XLMUP",
    symbol: "XLMUP",
    slug: "xlmup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xlmup.png",
  },
  {
    name: "CryptoGuards",
    symbol: "CGAR",
    slug: "cryptoguards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoguards.png",
  },
  {
    name: "HeroFi (ROFI)",
    symbol: "ROFI",
    slug: "rofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rofi.png",
  },
  {
    name: "CloudChat",
    symbol: "CC",
    slug: "cloudchat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cloudchat.png",
  },
  {
    name: "Ladyminers",
    symbol: "YTTRIUM",
    slug: "ladyminers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ladyminers.png",
  },
  {
    name: "RedShiba",
    symbol: "REDSHIBA",
    slug: "redshiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redshiba.png",
  },
  {
    name: "Plug Chain",
    symbol: "PLUGCN",
    slug: "plug-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plug-chain.png",
  },
  {
    name: "DoragonLand",
    symbol: "DOR",
    slug: "doragonland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doragonland.png",
  },
  {
    name: "BeforeCoinMarketCap",
    symbol: "BCMC1",
    slug: "beforecoinmarketcap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beforecoinmarketcap.png",
  },
  {
    name: "Zooshi",
    symbol: "ZOOSHI",
    slug: "zooshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zooshi.png",
  },
  {
    name: "Wonderland",
    symbol: "TIME",
    slug: "wonderland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wonderland.png",
  },
  {
    name: "CoinxPad",
    symbol: "CXPAD",
    slug: "coinxpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinxpad.png",
  },
  {
    name: "Ultimate Champions",
    symbol: "CHAMP",
    slug: "ultimate-champions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultimate-champions.png",
  },
  {
    name: "Animaltycoon",
    symbol: "AMT",
    slug: "animaltycoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animaltycoon.png",
  },
  {
    name: "TRVL",
    symbol: "TRVL",
    slug: "trvl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trvl.png",
  },
  {
    name: "Caduceus",
    symbol: "CMP",
    slug: "caduceus-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caduceus-foundation.png",
  },
  {
    name: "Medicalveda",
    symbol: "MVEDA",
    slug: "medicalveda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medicalveda.png",
  },
  {
    name: "BullDoge Chain",
    symbol: "WBDC",
    slug: "bulldoge-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulldoge-chain.png",
  },
  {
    name: "Revolution",
    symbol: "REV",
    slug: "revolution",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revolution.png",
  },
  {
    name: "DenDomains",
    symbol: "DDN",
    slug: "dendomains",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dendomains.png",
  },
  {
    name: "Izumi Finance",
    symbol: "IZI",
    slug: "izumi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/izumi-finance.png",
  },
  {
    name: "NFTBooks",
    symbol: "NFTBS",
    slug: "nftbooks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftbooks.png",
  },
  {
    name: "KingPad",
    symbol: "CROWN",
    slug: "kingpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingpad.png",
  },
  {
    name: "Kiba Inu",
    symbol: "KIBA",
    slug: "kiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiba-inu.png",
  },
  {
    name: "Cere Network",
    symbol: "CERE",
    slug: "cere-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cere-network.png",
  },
  {
    name: "Spores Network",
    symbol: "SPO",
    slug: "spores-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spores-network.png",
  },
  {
    name: "Meme Inu",
    symbol: "MEME",
    slug: "meme-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meme-inu.png",
  },
  {
    name: "Nevada",
    symbol: "NEVADA",
    slug: "nevada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nevada.png",
  },
  {
    name: "LYO Credit",
    symbol: "LYO",
    slug: "lyo-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lyo-credit.png",
  },
  {
    name: "Snowbank",
    symbol: "SB",
    slug: "snowbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowbank.png",
  },
  {
    name: "Pylon Protocol",
    symbol: "MINE",
    slug: "pylon-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pylon-protocol.png",
  },
  {
    name: "PolkaFantasy",
    symbol: "XP",
    slug: "polkafantasy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkafantasy.png",
  },
  {
    name: "Unlock Protocol",
    symbol: "UDT",
    slug: "unlock-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unlock-protocol.png",
  },
  {
    name: "Ice DAO",
    symbol: "ICE",
    slug: "ice-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ice-dao.png",
  },
  {
    name: "Morphie Network",
    symbol: "MRFI",
    slug: "morphie-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morphie-network.png",
  },
  {
    name: "Rare FND",
    symbol: "FND",
    slug: "rare-fnd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rare-fnd.png",
  },
  {
    name: "KingDeFi",
    symbol: "KRW",
    slug: "kingdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdefi.png",
  },
  {
    name: "ClearDAO",
    symbol: "CLH",
    slug: "cleardao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cleardao.png",
  },
  {
    name: "Somnium Space Cubes",
    symbol: "CUBE",
    slug: "somnium-space-cubes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/somnium-space-cubes.png",
  },
  {
    name: "Artificial Intelligence Technology Network",
    symbol: "AITN",
    slug: "artificial-intelligence-technology-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artificial-intelligence-technology-network.png",
  },
  {
    name: "Pocket Network",
    symbol: "POKT",
    slug: "pocket-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocket-network.png",
  },
  {
    name: "Polylauncher",
    symbol: "ANGEL",
    slug: "polylauncher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polylauncher.png",
  },
  {
    name: "Nuketoken",
    symbol: "NUKE",
    slug: "nuketoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nuketoken.png",
  },
  {
    name: "Portify",
    symbol: "PFY",
    slug: "portify",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/portify.png",
  },
  {
    name: "Jejudoge",
    symbol: "JEJUDOGE",
    slug: "jejudoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jejudoge.png",
  },
  {
    name: "CrazyMiner",
    symbol: "PWR",
    slug: "crazyminer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crazyminer.png",
  },
  {
    name: "Spectrum Token",
    symbol: "SPEC",
    slug: "spectrum-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spectrum-token.png",
  },
  {
    name: "Vega Protocol",
    symbol: "VEGA",
    slug: "vegaprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vegaprotocol.png",
  },
  {
    name: "CrypterToken",
    symbol: "CRYPT",
    slug: "cryptertoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptertoken.png",
  },
  {
    name: "bloXmove (BEP20)",
    symbol: "BLXM",
    slug: "bloxmove-bep20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bloxmove-bep20.png",
  },
  {
    name: "Bitkub Coin",
    symbol: "KUB",
    slug: "bitkub-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitkub-coin.png",
  },
  {
    name: "Everdome",
    symbol: "DOME",
    slug: "everdome",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everdome.png",
  },
  {
    name: "SupremeX",
    symbol: "SXC",
    slug: "supremex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supremex.png",
  },
  {
    name: "StepEx",
    symbol: "SPEX",
    slug: "stepex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stepex.png",
  },
  {
    name: "DAOventures",
    symbol: "DVG",
    slug: "daoventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daoventures.png",
  },
  {
    name: "Threshold",
    symbol: "T",
    slug: "threshold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/threshold.png",
  },
  {
    name: "Acala Token",
    symbol: "ACA",
    slug: "acala",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acala.png",
  },
  {
    name: "3x Long Tezos Token",
    symbol: "XTZBULL",
    slug: "3x-long-tezos-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-tezos-token.png",
  },
  {
    name: "Enjinstarter",
    symbol: "EJS",
    slug: "enjinstarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enjinstarter.png",
  },
  {
    name: "The Winkyverse",
    symbol: "WNK",
    slug: "the-winkyverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-winkyverse.png",
  },
  {
    name: "Feeder.finance",
    symbol: "FEED",
    slug: "feeder-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feeder-finance.png",
  },
  {
    name: "GenesysGo Shadow",
    symbol: "SHDW",
    slug: "genesysgo-shadow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesysgo-shadow.png",
  },
  {
    name: "AgeOfGods",
    symbol: "AOG",
    slug: "ageofgods",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ageofgods.png",
  },
  {
    name: "UniX Gaming",
    symbol: "UNIX",
    slug: "unix-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unix-gaming.png",
  },
  {
    name: "Popsicle Finance",
    symbol: "ICE",
    slug: "popsicle-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/popsicle-finance.png",
  },
  {
    name: "Tribal Credit",
    symbol: "TRIBL",
    slug: "tribal-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tribal-credit.png",
  },
  {
    name: "0xDAO",
    symbol: "OXD",
    slug: "0xdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0xdao.png",
  },
  {
    name: "Ertha",
    symbol: "ERTHA",
    slug: "ertha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ertha.png",
  },
  {
    name: "Beldex",
    symbol: "BDX",
    slug: "beldex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beldex.png",
  },
  {
    name: "Surge Inu",
    symbol: "SURGE",
    slug: "surge-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/surge-inu.png",
  },
  {
    name: "HorseDrace",
    symbol: "HORSEDRACE",
    slug: "horsedrace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/horsedrace.png",
  },
  {
    name: "Fantasy World Gold",
    symbol: "FWG",
    slug: "fantasy-world-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasy-world-gold.png",
  },
  {
    name: "Gas Token Two",
    symbol: "GST2",
    slug: "gas-token-two",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gas-token-two.png",
  },
  {
    name: "MetaDoge",
    symbol: "MTDU",
    slug: "metadoge-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadoge-ai.png",
  },
  {
    name: "Zerogoki",
    symbol: "REI",
    slug: "zerogoki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zerogoki.png",
  },
  {
    name: "NEO FANTASY",
    symbol: "ERT",
    slug: "neo-fantasy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neo-fantasy.png",
  },
  {
    name: "Decentralized Eternal Virtual Traveller",
    symbol: "DEVT",
    slug: "decentralized-eternal-virtual-traveller",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-eternal-virtual-traveller.png",
  },
  {
    name: "Pumpkin Inu",
    symbol: "PUMPKIN",
    slug: "pumpkin-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pumpkin-inu.png",
  },
  {
    name: "Wifi Coin",
    symbol: "WIFI",
    slug: "wifi-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wifi-coin.png",
  },
  {
    name: "Tomb Shares",
    symbol: "TSHARE",
    slug: "tomb-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tomb-shares.png",
  },
  {
    name: "HUPAYX",
    symbol: "HPX",
    slug: "hupayx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hupayx.png",
  },
  {
    name: "Goat Gang",
    symbol: "GGT",
    slug: "goat-gang",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goat-gang.png",
  },
  {
    name: "Solice",
    symbol: "SLC",
    slug: "solice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solice.png",
  },
  {
    name: "Dungeonswap",
    symbol: "DND",
    slug: "dungeonswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dungeonswap.png",
  },
  {
    name: "Tomb",
    symbol: "TOMB",
    slug: "tomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tomb.png",
  },
  {
    name: "Cerberus",
    symbol: "3DOG",
    slug: "cerburus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cerburus.png",
  },
  {
    name: "hiBAYC",
    symbol: "HIBAYC",
    slug: "hibayc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hibayc.png",
  },
  {
    name: "CardSwap",
    symbol: "CSWAP",
    slug: "cardswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cardswap.png",
  },
  {
    name: "Chipstars",
    symbol: "CHIPS",
    slug: "chipstars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chipstars.png",
  },
  {
    name: "Safuu",
    symbol: "SAFUU",
    slug: "safuu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safuu.png",
  },
  {
    name: "Eco DeFi",
    symbol: "ECOP",
    slug: "eco-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eco-defi.png",
  },
  {
    name: "Bitcicoin",
    symbol: "BITCI",
    slug: "bitcicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcicoin.png",
  },
  {
    name: "KittyCake",
    symbol: "KCAKE",
    slug: "kittycake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kittycake.png",
  },
  {
    name: "Vetter Token",
    symbol: "VETTER",
    slug: "vetter-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vetter-token.png",
  },
  {
    name: "Bitcoiva",
    symbol: "BCA",
    slug: "bitcoiva",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoiva.png",
  },
  {
    name: "LooksRare",
    symbol: "LOOKS",
    slug: "looksrare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/looksrare.png",
  },
  {
    name: "hiMEEBITS",
    symbol: "HIMEEBITS",
    slug: "himeebits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/himeebits.png",
  },
  {
    name: "WeBuy",
    symbol: "WE",
    slug: "webuy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/webuy.png",
  },
  {
    name: "CryptoBike",
    symbol: "CB",
    slug: "cryptobike",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobike.png",
  },
  {
    name: "Avalaunch",
    symbol: "XAVA",
    slug: "avalaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avalaunch.png",
  },
  {
    name: "Meta MVRS",
    symbol: "MVRS",
    slug: "meta-mvrs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-mvrs.png",
  },
  {
    name: "Madagascar",
    symbol: "$TIME",
    slug: "madagascar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/madagascar.png",
  },
  {
    name: "NYM",
    symbol: "NYM",
    slug: "nym",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nym.png",
  },
  {
    name: "Non-Fungible TOKE",
    symbol: "TOKE",
    slug: "non-fungible-toke",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/non-fungible-toke.png",
  },
  {
    name: "LaunchZone",
    symbol: "LZ",
    slug: "launchzone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/launchzone.png",
  },
  {
    name: "Bitsz",
    symbol: "BITSZ",
    slug: "bitsz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitsz.png",
  },
  {
    name: "Infinite Launch",
    symbol: "ILA",
    slug: "infinite-launch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinite-launch.png",
  },
  {
    name: "SquidGameToken",
    symbol: "SGT",
    slug: "squidgametoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squidgametoken.png",
  },
  {
    name: "Bitcoin Networks",
    symbol: "BTCN",
    slug: "bitcoin-networks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-networks.png",
  },
  {
    name: "ZEDXION",
    symbol: "USDZ",
    slug: "usdz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdz.png",
  },
  {
    name: "CBET Token",
    symbol: "CBET",
    slug: "cbet-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cbet-token.png",
  },
  {
    name: "ApolloX",
    symbol: "APX",
    slug: "apollox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apollox.png",
  },
  {
    name: "Pixel Shiba Inu",
    symbol: "PSHIB",
    slug: "pixel-shibainu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixel-shibainu.png",
  },
  {
    name: "Operon Origins",
    symbol: "ORO",
    slug: "operon-origins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/operon-origins.png",
  },
  {
    name: "Blocto Token",
    symbol: "BLT",
    slug: "blocto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocto.png",
  },
  {
    name: "Valkyrio",
    symbol: "VALK",
    slug: "valkyrio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valkyrio.png",
  },
  {
    name: "Thorstarter",
    symbol: "XRUNE",
    slug: "thorstarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thorstarter.png",
  },
  {
    name: "Creator Platform",
    symbol: "CTR",
    slug: "creator-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creator-platform.png",
  },
  {
    name: "Kaiju Worlds",
    symbol: "KAIJU",
    slug: "kaiju-worlds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaiju-worlds.png",
  },
  {
    name: "INERY",
    symbol: "INR",
    slug: "inery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inery.png",
  },
  {
    name: "Elemon",
    symbol: "ELMON",
    slug: "elemon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elemon.png",
  },
  {
    name: "Olympus v1",
    symbol: "OHM",
    slug: "olympus-v1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olympus-v1.png",
  },
  {
    name: "Graphene",
    symbol: "GFN",
    slug: "graphene",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/graphene.png",
  },
  {
    name: "HODL",
    symbol: "HODL",
    slug: "hodl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodl.png",
  },
  {
    name: "Radiant Capital",
    symbol: "RDNT",
    slug: "radiant-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radiant-capital.png",
  },
  {
    name: "FANC",
    symbol: "FANC",
    slug: "fanc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanc.png",
  },
  {
    name: "BABYXRP",
    symbol: "BBYXRP",
    slug: "babyxrp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babyxrp.png",
  },
  {
    name: "BULL BTC CLUB",
    symbol: "BBC",
    slug: "bull-btc-club",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bull-btc-club.png",
  },
  {
    name: "CoinBurp",
    symbol: "BURP",
    slug: "coinburp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinburp.png",
  },
  {
    name: "Scream",
    symbol: "SCREAM",
    slug: "scream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scream.png",
  },
  {
    name: "Proof Of Liquidity",
    symbol: "POL",
    slug: "proof-of-liquidity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proof-of-liquidity.png",
  },
  {
    name: "Knight War The Holy Trio",
    symbol: "KWS",
    slug: "knight-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knight-war.png",
  },
  {
    name: "Saitama V2",
    symbol: "SAITAMA",
    slug: "saitama-inu-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saitama-inu-new.png",
  },
  {
    name: "1-UP",
    symbol: "1-UP",
    slug: "1-up",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1-up.png",
  },
  {
    name: "Ivar Coin",
    symbol: "IVAR",
    slug: "ivar-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ivar-coin.png",
  },
  {
    name: "Kasta",
    symbol: "KASTA",
    slug: "kasta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kasta.png",
  },
  {
    name: "Boba Network",
    symbol: "BOBA",
    slug: "boba-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boba-network.png",
  },
  {
    name: "Exodia",
    symbol: "EXOD",
    slug: "exodia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exodia.png",
  },
  {
    name: "Tokyo AU",
    symbol: "TOKAU",
    slug: "tokyo-au",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokyo-au.png",
  },
  {
    name: "Space Crypto",
    symbol: "SPG",
    slug: "space-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-crypto.png",
  },
  {
    name: "The Parallel",
    symbol: "PRL",
    slug: "the-parallel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-parallel.png",
  },
  {
    name: "3x Long XRP Token",
    symbol: "XRPBULL",
    slug: "3x-long-xrp-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-xrp-token.png",
  },
  {
    name: "MidasDAO",
    symbol: "CROWN",
    slug: "midasdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/midasdao.png",
  },
  {
    name: "Bholdus",
    symbol: "BHO",
    slug: "bholdus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bholdus.png",
  },
  {
    name: "BNB Hero Token",
    symbol: "BNBH",
    slug: "bnb-hero-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnb-hero-token.png",
  },
  {
    name: "Anonverse",
    symbol: "ANON",
    slug: "anonverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anonverse.png",
  },
  {
    name: "Carnomaly",
    symbol: "CARR",
    slug: "carnomaly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carnomaly.png",
  },
  {
    name: "Coinweb",
    symbol: "CWEB",
    slug: "coinweb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinweb.png",
  },
  {
    name: "MetaOctagon",
    symbol: "MOTG",
    slug: "metaoctagon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaoctagon.png",
  },
  {
    name: "Ref Finance",
    symbol: "REF",
    slug: "ref-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ref-finance.png",
  },
  {
    name: "TitsGrow",
    symbol: "TITS",
    slug: "titsgrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/titsgrow.png",
  },
  {
    name: "BlockXpress",
    symbol: "BX",
    slug: "blockxpress",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockxpress.png",
  },
  {
    name: "King Swap",
    symbol: "$KING",
    slug: "kingswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingswap.png",
  },
  {
    name: "Volt Inu V2",
    symbol: "VOLT",
    slug: "volt-inu-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/volt-inu-v2.png",
  },
  {
    name: "Cryptowolf Finance",
    symbol: "$CWOLF",
    slug: "cryptowolf-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptowolf-finance.png",
  },
  {
    name: "Celestial",
    symbol: "CELT",
    slug: "celestial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celestial.png",
  },
  {
    name: "Ixinium",
    symbol: "XXA",
    slug: "ixinium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ixinium.png",
  },
  {
    name: "FishingTown",
    symbol: "FHTN",
    slug: "fishingtown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fishingtown.png",
  },
  {
    name: "Galaxer",
    symbol: "GLX",
    slug: "galaxer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxer.png",
  },
  {
    name: "MoonRise",
    symbol: "MOONRISE",
    slug: "moonrise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonrise.png",
  },
  {
    name: "Ookeenga",
    symbol: "OKG",
    slug: "ookeenga",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ookeenga.png",
  },
  {
    name: "CHAINFI",
    symbol: "CNFI",
    slug: "chainfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainfi.png",
  },
  {
    name: "Orakler",
    symbol: "ORKL",
    slug: "orakler",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orakler.png",
  },
  {
    name: "USD Coin Bridged",
    symbol: "USDCE",
    slug: "usd-coin-avalanche-bridged-usdc-e",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usd-coin-avalanche-bridged-usdc-e.png",
  },
  {
    name: "StarLaunch",
    symbol: "STARS",
    slug: "starlaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starlaunch.png",
  },
  {
    name: "Gas DAO",
    symbol: "GAS",
    slug: "gas-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gas-dao.png",
  },
  {
    name: "KuCoin LaunchPad",
    symbol: "KCLP",
    slug: "kucoin-launchpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kucoin-launchpad.png",
  },
  {
    name: "Shibamon",
    symbol: "SHIBAMON",
    slug: "shibamon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibamon.png",
  },
  {
    name: "Rangers Protocol",
    symbol: "RPG",
    slug: "rangers-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rangers-protocol.png",
  },
  {
    name: "METAWORLD",
    symbol: "METAWORLD",
    slug: "metaworld-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaworld-token.png",
  },
  {
    name: "BoringDAO",
    symbol: "BORING",
    slug: "boringdao-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boringdao-new.png",
  },
  {
    name: "BlackPearl Token",
    symbol: "BPLC",
    slug: "blackpearl-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackpearl-chain.png",
  },
  {
    name: "Dotori",
    symbol: "DTR",
    slug: "dotori",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dotori.png",
  },
  {
    name: "DOSE",
    symbol: "DOSE",
    slug: "dose",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dose.png",
  },
  {
    name: "Heroes TD",
    symbol: "HTD",
    slug: "heroes-td",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heroes-td.png",
  },
  {
    name: "LPI DAO",
    symbol: "LPI",
    slug: "lpi-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lpi-dao.png",
  },
  {
    name: "Valkyrie Protocol",
    symbol: "VKR",
    slug: "valkyrie-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valkyrie-protocol.png",
  },
  {
    name: "Pearl",
    symbol: "PEARL",
    slug: "pearl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pearl.png",
  },
  {
    name: "SHILL Token",
    symbol: "SHILL",
    slug: "project-seed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-seed.png",
  },
  {
    name: "Redacted Cartel",
    symbol: "BTRFLY",
    slug: "butterflydao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/butterflydao.png",
  },
  {
    name: "MARS4",
    symbol: "MARS4",
    slug: "mars4",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mars4.png",
  },
  {
    name: "Tranchess",
    symbol: "CHESS",
    slug: "tranchess",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tranchess.png",
  },
  {
    name: "Rabbit Finance",
    symbol: "RABBIT",
    slug: "rabbit-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rabbit-finance.png",
  },
  {
    name: "Dope Wars Paper",
    symbol: "PAPER",
    slug: "dope-wars-paper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dope-wars-paper.png",
  },
  {
    name: "Cannumo",
    symbol: "CANU",
    slug: "cannumo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cannumo.png",
  },
  {
    name: "Kanaloa Network",
    symbol: "KANA",
    slug: "kanaloa-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kanaloa-network.png",
  },
  {
    name: "VerseWar",
    symbol: "VERSE",
    slug: "versewar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/versewar.png",
  },
  {
    name: "Age Of Knights",
    symbol: "GEM",
    slug: "age-of-knights",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/age-of-knights.png",
  },
  {
    name: "PolkaCipher",
    symbol: "CPHR",
    slug: "polkacipher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkacipher.png",
  },
  {
    name: "ShibX",
    symbol: "$ShibX",
    slug: "shibx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibx.png",
  },
  {
    name: "Fidance",
    symbol: "FDC",
    slug: "fidance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fidance.png",
  },
  {
    name: "Duel Network",
    symbol: "DUEL",
    slug: "duel-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duel-network.png",
  },
  {
    name: "Pexcoin",
    symbol: "PEX",
    slug: "pexcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pexcoin.png",
  },
  {
    name: "Meta Apes",
    symbol: "PEEL",
    slug: "meta-apes-peel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta-apes-peel.png",
  },
  {
    name: "Metaverse Index",
    symbol: "MVI",
    slug: "metaverse-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-index.png",
  },
  {
    name: "Octopus Network",
    symbol: "OCT",
    slug: "octopus-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octopus-network.png",
  },
  {
    name: "XLMDOWN",
    symbol: "XLMDOWN",
    slug: "xlmdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xlmdown.png",
  },
  {
    name: "Tari World",
    symbol: "TARI",
    slug: "tari-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tari-world.png",
  },
  {
    name: "Vera Defi",
    symbol: "VERA",
    slug: "vera-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vera-defi.png",
  },
  {
    name: "Metatoken",
    symbol: "MTK",
    slug: "metatoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metatoken.png",
  },
  {
    name: "Summit Defi",
    symbol: "SUMMIT",
    slug: "summit-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/summit-defi.png",
  },
  {
    name: "MoonStarter",
    symbol: "MNST",
    slug: "moonstarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonstarter.png",
  },
  {
    name: "ZORT",
    symbol: "ZORT",
    slug: "zort",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zort.png",
  },
  {
    name: "NewB.Farm",
    symbol: "NEWB",
    slug: "newb-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newb-farm.png",
  },
  {
    name: "CFl 365 Finance",
    symbol: "CFL365",
    slug: "cfl-365-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cfl-365-finance.png",
  },
  {
    name: "HoneyMoon Finance",
    symbol: "MOON",
    slug: "honeymoon-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honeymoon-finance.png",
  },
  {
    name: "Swash",
    symbol: "SWASH",
    slug: "swash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swash.png",
  },
  {
    name: "Jade Protocol",
    symbol: "JADE",
    slug: "jade-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jade-protocol.png",
  },
  {
    name: "Cardence.io",
    symbol: "$CRDN",
    slug: "cardence-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cardence-io.png",
  },
  {
    name: "RAI Finance",
    symbol: "SOFI",
    slug: "rai-finance-sofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rai-finance-sofi.png",
  },
  {
    name: "Monster Grand Prix Token",
    symbol: "MGPX",
    slug: "monster-grand-prix-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-grand-prix-token.png",
  },
  {
    name: "Wrapped KuCoin Token",
    symbol: "WKCS",
    slug: "wrapped-kucoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-kucoin-token.png",
  },
  {
    name: "Forest Knight",
    symbol: "KNIGHT",
    slug: "forest-knight",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forest-knight.png",
  },
  {
    name: "Heart Rate",
    symbol: "HTR",
    slug: "heart-rate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heart-rate.png",
  },
  {
    name: "PointPay",
    symbol: "PXP",
    slug: "pointpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pointpay.png",
  },
  {
    name: "Tenset",
    symbol: "10SET",
    slug: "tenset",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tenset.png",
  },
  {
    name: "GIBX Swap",
    symbol: "X",
    slug: "gibx-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gibx-swap.png",
  },
  {
    name: "Surviving Soldiers",
    symbol: "SSG",
    slug: "surviving-soldiers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/surviving-soldiers.png",
  },
  {
    name: "Integral",
    symbol: "ITGR",
    slug: "integral",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/integral.png",
  },
  {
    name: "TaleCraft",
    symbol: "CRAFT",
    slug: "talecraft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/talecraft.png",
  },
  {
    name: "Chainsquare",
    symbol: "CHS",
    slug: "chainsquare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainsquare.png",
  },
  {
    name: "Baby Shiba Inu",
    symbol: "BABYSHIBAINU",
    slug: "baby-shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-shiba-inu.png",
  },
  {
    name: "Ooki Protocol",
    symbol: "OOKI",
    slug: "ooki-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ooki-protocol.png",
  },
  {
    name: "Aurory",
    symbol: "AURY",
    slug: "aurory",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurory.png",
  },
  {
    name: "AGOV (ANSWER Governance)",
    symbol: "AGOV",
    slug: "agov-answer-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agov-answer-governance.png",
  },
  {
    name: "Genopets",
    symbol: "GENE",
    slug: "genopets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genopets.png",
  },
  {
    name: "Parabolic",
    symbol: "PARA",
    slug: "parabolic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parabolic.png",
  },
  {
    name: "Fracton Protocol",
    symbol: "FT",
    slug: "fracton-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fracton-protocol.png",
  },
  {
    name: "Ellipsis",
    symbol: "EPX",
    slug: "ellipsis-epx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ellipsis-epx.png",
  },
  {
    name: "Solend",
    symbol: "SLND",
    slug: "solend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solend.png",
  },
  {
    name: "Voxies",
    symbol: "VOXEL",
    slug: "voxies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voxies.png",
  },
  {
    name: "Uncle Doge",
    symbol: "UDOGE",
    slug: "uncle-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uncle-doge.png",
  },
  {
    name: "VVS Finance",
    symbol: "VVS",
    slug: "vvs-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vvs-finance.png",
  },
  {
    name: "Genshiro",
    symbol: "GENS",
    slug: "genshiro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genshiro.png",
  },
  {
    name: "Saint Ligne",
    symbol: "STLE",
    slug: "saint-ligne",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saint-ligne.png",
  },
  {
    name: "IMOV",
    symbol: "IMT",
    slug: "imov",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imov.png",
  },
  {
    name: "PORNROCKET",
    symbol: "PORNROCKET",
    slug: "pornrocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pornrocket.png",
  },
  {
    name: "Geegoopuzzle",
    symbol: "GGP",
    slug: "geegoopuzzle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geegoopuzzle.png",
  },
  {
    name: "MiningNFT",
    symbol: "MIT",
    slug: "miningnft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miningnft.png",
  },
  {
    name: "MetaversePRO",
    symbol: "META",
    slug: "metaversepro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaversepro.png",
  },
  {
    name: "Redlight Chain",
    symbol: "REDLC",
    slug: "redlight-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redlight-chain.png",
  },
  {
    name: "HLP Token",
    symbol: "HLP",
    slug: "help-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/help-coin.png",
  },
  {
    name: "Demole",
    symbol: "DMLG",
    slug: "demole",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/demole.png",
  },
  {
    name: "Thinkium",
    symbol: "TKM",
    slug: "thinkium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thinkium.png",
  },
  {
    name: "XANA",
    symbol: "XETA",
    slug: "xana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xana.png",
  },
  {
    name: "Floki Shiba",
    symbol: "FSHIB",
    slug: "floki-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-shiba.png",
  },
  {
    name: "Beyondpay",
    symbol: "BPAY",
    slug: "beyondpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beyondpay.png",
  },
  {
    name: "hiENS4",
    symbol: "HIENS4",
    slug: "hiens4",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hiens4.png",
  },
  {
    name: "CryptoDiceHero",
    symbol: "HRO",
    slug: "cryptodicehero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptodicehero.png",
  },
  {
    name: "Arcona",
    symbol: "ARCONA",
    slug: "arcona",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcona.png",
  },
  {
    name: "StreamCoin",
    symbol: "STRM",
    slug: "stream-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stream-coin.png",
  },
  {
    name: "SOULS OF META",
    symbol: "SOM",
    slug: "souls-of-meta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/souls-of-meta.png",
  },
  {
    name: "Project DogeX",
    symbol: "DOGEX",
    slug: "project-dogex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-dogex.png",
  },
  {
    name: "ETHPoS (IOU)",
    symbol: "ETHS",
    slug: "ethereum-pos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-pos.png",
  },
  {
    name: "HeroFi",
    symbol: "HEROEGG",
    slug: "herofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herofi.png",
  },
  {
    name: "NFTMart Token",
    symbol: "NMT",
    slug: "nftmart-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftmart-token.png",
  },
  {
    name: "Good Bridging",
    symbol: "GB",
    slug: "good-bridging",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/good-bridging.png",
  },
  {
    name: "Playmusic",
    symbol: "PLAY",
    slug: "playmusic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playmusic.png",
  },
  {
    name: "DogeDealer",
    symbol: "DOGEDEALER",
    slug: "dogedealer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogedealer.png",
  },
  {
    name: "ViteX Coin",
    symbol: "VX",
    slug: "vitex-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vitex-coin.png",
  },
  {
    name: "Pre-retogeum",
    symbol: "PRTG",
    slug: "pre-retogeum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pre-retogeum.png",
  },
  {
    name: "EOSDOWN",
    symbol: "EOSDOWN",
    slug: "eosdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eosdown.png",
  },
  {
    name: "Crypto Hounds",
    symbol: "CTH",
    slug: "crypto-hounds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-hounds.png",
  },
  {
    name: "Grizzly Honey",
    symbol: "GHNY",
    slug: "grizzlyfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grizzlyfi.png",
  },
  {
    name: "StarTerra",
    symbol: "STT",
    slug: "starterra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starterra.png",
  },
  {
    name: "Newinu",
    symbol: "NEWINU",
    slug: "newinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newinu.png",
  },
  {
    name: "HitBTC Token",
    symbol: "HIT",
    slug: "hitbtc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hitbtc-token.png",
  },
  {
    name: "Songbird",
    symbol: "SGB",
    slug: "songbird",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/songbird.png",
  },
  {
    name: "Cryptofi",
    symbol: "CFI",
    slug: "cryptofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptofi.png",
  },
  {
    name: "ShoeFy",
    symbol: "SHOE",
    slug: "shoefy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shoefy.png",
  },
  {
    name: "ADAPad",
    symbol: "ADAPAD",
    slug: "adapad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adapad.png",
  },
  {
    name: "SHKOOBY INU",
    symbol: "SHKOOBY",
    slug: "shkooby-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shkooby-inu.png",
  },
  {
    name: "Art Gobblers Goo",
    symbol: "GOO",
    slug: "goo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goo.png",
  },
  {
    name: "Dark Frontiers",
    symbol: "DARK",
    slug: "dark-frontiers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dark-frontiers.png",
  },
  {
    name: "Lite USD",
    symbol: "LITE",
    slug: "lite-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lite-usd.png",
  },
  {
    name: "ETHPoW (IOU)",
    symbol: "ETHW",
    slug: "ethereum-pow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-pow.png",
  },
  {
    name: "HIPPO TOKEN",
    symbol: "HIP",
    slug: "hippo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hippo-token.png",
  },
  {
    name: "OSK",
    symbol: "OSK",
    slug: "osk-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/osk-token.png",
  },
  {
    name: "DfiStarter",
    symbol: "DFI",
    slug: "dfistarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfistarter.png",
  },
  {
    name: "DotOracle",
    symbol: "DTO",
    slug: "dotoracle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dotoracle.png",
  },
  {
    name: "Highstreet",
    symbol: "HIGH",
    slug: "highstreet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/highstreet.png",
  },
  {
    name: "Biconomy Exchange Token",
    symbol: "BIT",
    slug: "biconomy-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biconomy-token.png",
  },
  {
    name: "Cryptogodz",
    symbol: "GODZ",
    slug: "cryptogodz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptogodz.png",
  },
  {
    name: "DeltaHub Community",
    symbol: "DHC",
    slug: "deltahub-community",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deltahub-community.png",
  },
  {
    name: "NFT2STAKE",
    symbol: "NFT2$",
    slug: "nft2stake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft2stake.png",
  },
  {
    name: "Cryowar",
    symbol: "CWAR",
    slug: "cryowar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryowar.png",
  },
  {
    name: "3x Short Tezos Token",
    symbol: "XTZBEAR",
    slug: "3x-short-tezos-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-tezos-token.png",
  },
  {
    name: "Victoria VR",
    symbol: "VR",
    slug: "victoria-vr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/victoria-vr.png",
  },
  {
    name: "Tundra Token",
    symbol: "TUNDRA",
    slug: "tundra-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tundra-token.png",
  },
  {
    name: "Bezoge Earth",
    symbol: "BEZOGE",
    slug: "bezoge-earth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bezoge-earth.png",
  },
  {
    name: "Pinkslip Finance",
    symbol: "PSLIP",
    slug: "pinkslip-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pinkslip-finance.png",
  },
  {
    name: "Boost Coin",
    symbol: "BOOST",
    slug: "boost-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boost-coin.png",
  },
  {
    name: "Moon Nation Game",
    symbol: "MNG",
    slug: "moon-nation-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-nation-game.png",
  },
  {
    name: "ONBUFF",
    symbol: "ONIT",
    slug: "onbuff",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onbuff.png",
  },
  {
    name: "Divergence",
    symbol: "DIVER",
    slug: "divergence",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/divergence.png",
  },
  {
    name: "BetU",
    symbol: "BETU",
    slug: "betu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betu.png",
  },
  {
    name: "Visor.Finance",
    symbol: "VISR",
    slug: "visor-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/visor-finance.png",
  },
  {
    name: "TaiChi",
    symbol: "TAC",
    slug: "taichi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taichi.png",
  },
  {
    name: "Instadapp",
    symbol: "INST",
    slug: "instadapp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/instadapp.png",
  },
  {
    name: "InfinityPad",
    symbol: "INFP",
    slug: "infinitypad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitypad.png",
  },
  {
    name: "Step App",
    symbol: "FITFI",
    slug: "step-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/step-app.png",
  },
  {
    name: "SIMP Token",
    symbol: "SIMP",
    slug: "simp-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simp-token.png",
  },
  {
    name: "YFIONE",
    symbol: "YFO",
    slug: "yfione",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfione.png",
  },
  {
    name: "DeSpace Protocol",
    symbol: "DES",
    slug: "despace-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/despace-protocol.png",
  },
  {
    name: "hiFIDENZA",
    symbol: "HIFIDENZA",
    slug: "hifidenza",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hifidenza.png",
  },
  {
    name: "MotherBTC",
    symbol: "MOBTC",
    slug: "motherbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/motherbtc.png",
  },
  {
    name: "Vabble",
    symbol: "VAB",
    slug: "vabble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vabble.png",
  },
  {
    name: "GM Wagmi",
    symbol: "GM",
    slug: "gm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gm.png",
  },
  {
    name: "Dinger Token",
    symbol: "DINGER",
    slug: "dinger-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinger-token.png",
  },
  {
    name: "DDS.Store",
    symbol: "DDS",
    slug: "dds-store",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dds-store.png",
  },
  {
    name: "Amazy",
    symbol: "AZY",
    slug: "amazy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amazy.png",
  },
  {
    name: "MUSE ENT NFT",
    symbol: "MSCT",
    slug: "muse-ent-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muse-ent-nft.png",
  },
  {
    name: "DogemonGo",
    symbol: "DOGO",
    slug: "dogemon-go",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogemon-go.png",
  },
  {
    name: "OMNI - People Driven",
    symbol: "OMN",
    slug: "omni-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omni-token.png",
  },
  {
    name: "DeepSpace Token",
    symbol: "DXO",
    slug: "deepspace-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deepspace-token.png",
  },
  {
    name: "BICONOMY (BICO)",
    symbol: "BICO",
    slug: "biconomy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biconomy.png",
  },
  {
    name: "Wrapped CRO",
    symbol: "WCRO",
    slug: "wrapped-cro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-cro.png",
  },
  {
    name: "AlchemyToys",
    symbol: "GAT",
    slug: "alchemytoys",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alchemytoys.png",
  },
  {
    name: "XT Stablecoin XTUSD",
    symbol: "XTUSD",
    slug: "xtusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtusd.png",
  },
  {
    name: "Mech Master",
    symbol: "MECH",
    slug: "mech-master",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mech-master.png",
  },
  {
    name: "Ulti Arena",
    symbol: "ULTI",
    slug: "ulti-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ulti-arena.png",
  },
  {
    name: "Clam Island",
    symbol: "SHELL",
    slug: "shell-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shell-token.png",
  },
  {
    name: "WonderHero",
    symbol: "WND",
    slug: "wonderhero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wonderhero.png",
  },
  {
    name: "dAppstore",
    symbol: "DAPPX",
    slug: "dappstore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dappstore.png",
  },
  {
    name: "Fistbump",
    symbol: "FIST",
    slug: "fistbump-vip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fistbump-vip.png",
  },
  {
    name: "Lingose",
    symbol: "LING",
    slug: "lingose",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lingose.png",
  },
  {
    name: "Unizen",
    symbol: "ZCX",
    slug: "unizen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unizen.png",
  },
  {
    name: "Grove",
    symbol: "GVR",
    slug: "grove",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grove.png",
  },
  {
    name: "GasBlock",
    symbol: "GSBL",
    slug: "gasblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gasblock.png",
  },
  {
    name: "Herbee",
    symbol: "BEE",
    slug: "herbee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herbee.png",
  },
  {
    name: "Cryptex Finance",
    symbol: "CTX",
    slug: "cryptex-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptex-finance.png",
  },
  {
    name: "CLAIM",
    symbol: "CLAIM",
    slug: "claim",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/claim.png",
  },
  {
    name: "Gelato",
    symbol: "GEL",
    slug: "gelato",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gelato.png",
  },
  {
    name: "Yobit Token",
    symbol: "YO",
    slug: "yobit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yobit-token.png",
  },
  {
    name: "PUMLx",
    symbol: "PUMLX",
    slug: "pumlx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pumlx.png",
  },
  {
    name: "Kawaii Islands",
    symbol: "KWT",
    slug: "kawaii-islands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kawaii-islands.png",
  },
  {
    name: "MM Finance(Polygon)",
    symbol: "MMF",
    slug: "mm-finance-polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mm-finance-polygon.png",
  },
  {
    name: "NFTNetwork",
    symbol: "NFTN",
    slug: "nftnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftnetwork.png",
  },
  {
    name: "Virtual Reality Game World",
    symbol: "VRGW",
    slug: "virtual-reality-game-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/virtual-reality-game-world.png",
  },
  {
    name: "SatoshiStreetBets Token",
    symbol: "SSB",
    slug: "satoshistreetbets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoshistreetbets.png",
  },
  {
    name: "Floki Inu",
    symbol: "FLOKI",
    slug: "floki-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/floki-inu.png",
  },
  {
    name: "CryptoArt.Ai",
    symbol: "CART",
    slug: "cryptoart-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoart-ai.png",
  },
  {
    name: "Kishimoto Inu",
    symbol: "KISHIMOTO",
    slug: "kishimoto-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kishimoto-inu.png",
  },
  {
    name: "YFIDOWN",
    symbol: "YFIDOWN",
    slug: "yfidown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfidown.png",
  },
  {
    name: "ROCO FINANCE",
    symbol: "ROCO",
    slug: "roco-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roco-finance.png",
  },
  {
    name: "SoccerHub",
    symbol: "SCH",
    slug: "soccerhub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soccerhub.png",
  },
  {
    name: "Alchemist",
    symbol: "MIST",
    slug: "alchemist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alchemist.png",
  },
  {
    name: "Optimism",
    symbol: "OP",
    slug: "optimism-ethereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/optimism-ethereum.png",
  },
  {
    name: "BitOrbit",
    symbol: "BITORB",
    slug: "bitorbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitorbit.png",
  },
  {
    name: "OpenSwap",
    symbol: "OSWAP",
    slug: "openswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/openswap.png",
  },
  {
    name: "Planet Sandbox",
    symbol: "PSB",
    slug: "planet-sandbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/planet-sandbox.png",
  },
  {
    name: "Betherchip",
    symbol: "BEC",
    slug: "betherchip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/betherchip.png",
  },
  {
    name: "PokerFI.Finance",
    symbol: "POKERFI",
    slug: "pokerfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pokerfi.png",
  },
  {
    name: "Bit2me B2M Token",
    symbol: "B2M",
    slug: "bit2me-b2m-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit2me-b2m-token.png",
  },
  {
    name: "DogeZilla",
    symbol: "DOGEZILLA",
    slug: "dogezilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogezilla.png",
  },
  {
    name: "HurricaneSwap Token",
    symbol: "HCT",
    slug: "hurricaneswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hurricaneswap-token.png",
  },
  {
    name: "Previse",
    symbol: "PRVS",
    slug: "previse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/previse.png",
  },
  {
    name: "Monster Valley",
    symbol: "MONSTER",
    slug: "monster-valley",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monster-valley.png",
  },
  {
    name: "ConstitutionDAO",
    symbol: "PEOPLE",
    slug: "constitutiondao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/constitutiondao.png",
  },
  {
    name: "Nirvana Chain",
    symbol: "NAC",
    slug: "nirvana-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nirvana-chain.png",
  },
  {
    name: "Polaris Share",
    symbol: "POLA",
    slug: "polaris-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polaris-share.png",
  },
  {
    name: "BIT GAME VERSE TOKEN",
    symbol: "BGVT",
    slug: "bit-game-verse-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit-game-verse-token.png",
  },
  {
    name: "Shirtum",
    symbol: "SHI",
    slug: "shirtum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shirtum.png",
  },
  {
    name: "Union Fair Coin",
    symbol: "UFC",
    slug: "union-fair-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/union-fair-coin.png",
  },
  {
    name: "Brokoli Network",
    symbol: "BRKL",
    slug: "brokoli-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brokoli-network.png",
  },
  {
    name: "Babylons",
    symbol: "BABI",
    slug: "babylons",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babylons.png",
  },
  {
    name: "3x Long EOS Token",
    symbol: "EOSBULL",
    slug: "3x-long-eos-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-eos-token.png",
  },
  {
    name: "Moniwar",
    symbol: "MOWA",
    slug: "moniwar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moniwar.png",
  },
  {
    name: "CryptoBlades Kingdoms",
    symbol: "KING",
    slug: "cryptoblades-kingdoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoblades-kingdoms.png",
  },
  {
    name: "Sanshu Inu",
    symbol: "SANSHU",
    slug: "sanshu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sanshu-inu.png",
  },
  {
    name: "SolRazr",
    symbol: "SOLR",
    slug: "solrazr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solrazr.png",
  },
  {
    name: "xSUSHI",
    symbol: "XSUSHI",
    slug: "xsushi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xsushi.png",
  },
  {
    name: "HoneyFarm",
    symbol: "BEAR",
    slug: "honeyfarm-bear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honeyfarm-bear.png",
  },
  {
    name: "SXPUP",
    symbol: "SXPUP",
    slug: "sxpup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sxpup.png",
  },
  {
    name: "Scallop",
    symbol: "SCLP",
    slug: "scallop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scallop.png",
  },
  {
    name: "MANAGER PRO",
    symbol: "MPRO",
    slug: "manager-pro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manager-pro.png",
  },
  {
    name: "Polytrade",
    symbol: "TRADE",
    slug: "polytrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polytrade.png",
  },
  {
    name: "KSM Starter",
    symbol: "KST",
    slug: "ksm-starter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ksm-starter.png",
  },
  {
    name: "Genesis Finance",
    symbol: "GEFI",
    slug: "genesis-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesis-finance.png",
  },
  {
    name: "Whale Fall",
    symbol: "Whale",
    slug: "whale-fall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whale-fall.png",
  },
  {
    name: "AquaTank",
    symbol: "AQUA",
    slug: "aquatank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aquatank.png",
  },
  {
    name: "Bitcoin God",
    symbol: "GOD",
    slug: "bitcoin-god",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-god.png",
  },
  {
    name: "HeroVerse",
    symbol: "HER",
    slug: "heroverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heroverse.png",
  },
  {
    name: "Bitcoin Pay",
    symbol: "BTCPAY",
    slug: "bitcoin-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-pay.png",
  },
  {
    name: "KACO Finance",
    symbol: "KAC",
    slug: "kaco-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaco-finance.png",
  },
  {
    name: "Cirus Foundation",
    symbol: "CIRUS",
    slug: "cirus-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cirus-foundation.png",
  },
  {
    name: "FNDZ",
    symbol: "FNDZ",
    slug: "fndz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fndz.png",
  },
  {
    name: "VAIOT",
    symbol: "VAI",
    slug: "vaiot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vaiot.png",
  },
  {
    name: "Baby Satoshi",
    symbol: "SATS",
    slug: "babysatoshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babysatoshi.png",
  },
  {
    name: "XTZDOWN",
    symbol: "XTZDOWN",
    slug: "xtzdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtzdown.png",
  },
  {
    name: "Bombcrypto",
    symbol: "BCOIN",
    slug: "bombcrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bombcrypto.png",
  },
  {
    name: "Bafi Finance",
    symbol: "BAFI",
    slug: "bafi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bafi-finance.png",
  },
  {
    name: "SquidDao",
    symbol: "SQUID",
    slug: "squiddao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squiddao.png",
  },
  {
    name: "Qubit",
    symbol: "QBT",
    slug: "qubit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qubit.png",
  },
  {
    name: "Crypto Cars World",
    symbol: "CARS",
    slug: "crypto-cars-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-cars-world.png",
  },
  {
    name: "My Master War",
    symbol: "MAT",
    slug: "my-master-war",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-master-war.png",
  },
  {
    name: "Avatly",
    symbol: "AVA",
    slug: "avatly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avatly.png",
  },
  {
    name: "Crypto Gladiator Shards",
    symbol: "CGS",
    slug: "crypto-gladiator-shards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-gladiator-shards.png",
  },
  {
    name: "DogeGF",
    symbol: "DOGEGF",
    slug: "dogegf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogegf.png",
  },
  {
    name: "Medium",
    symbol: "MDM",
    slug: "medium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medium.png",
  },
  {
    name: "Bifrost (BNC)",
    symbol: "BNC",
    slug: "bifrost-bnc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bifrost-bnc.png",
  },
  {
    name: "Sunny Aggregator",
    symbol: "SUNNY",
    slug: "sunny-aggregator",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sunny-aggregator.png",
  },
  {
    name: "CryptoTycoon",
    symbol: "CTT",
    slug: "cryptotycoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptotycoon.png",
  },
  {
    name: "My Pet Social",
    symbol: "MPS",
    slug: "my-pet-social",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-pet-social.png",
  },
  {
    name: "Lendhub",
    symbol: "LHB",
    slug: "lendhub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lendhub.png",
  },
  {
    name: "Only1",
    symbol: "LIKE",
    slug: "only1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/only1.png",
  },
  {
    name: "Phantom Protocol",
    symbol: "PHM",
    slug: "phantom-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phantom-protocol.png",
  },
  {
    name: "WhiteBIT Token",
    symbol: "WBT",
    slug: "whitebit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whitebit-token.png",
  },
  {
    name: "Gem Guardian",
    symbol: "GEMG",
    slug: "gemguardian",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gemguardian.png",
  },
  {
    name: "UBU",
    symbol: "UBU",
    slug: "ubu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubu.png",
  },
  {
    name: "DecentraWeb",
    symbol: "DWEB",
    slug: "decentraweb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentraweb.png",
  },
  {
    name: "Lemond",
    symbol: "LEMD",
    slug: "lemond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lemond.png",
  },
  {
    name: "CBFINU",
    symbol: "CBFINU",
    slug: "cbfinu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cbfinu.png",
  },
  {
    name: "Scientix",
    symbol: "SCIX",
    slug: "scientix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scientix.png",
  },
  {
    name: "Monsters Clan",
    symbol: "MONS",
    slug: "monsters-clan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monsters-clan.png",
  },
  {
    name: "Wrapped LUNA Token",
    symbol: "WLUNA",
    slug: "wrapped-luna-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-luna-token.png",
  },
  {
    name: "Altura",
    symbol: "ALU",
    slug: "altura",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altura.png",
  },
  {
    name: "Oh! Finance",
    symbol: "OH",
    slug: "oh-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oh-finance.png",
  },
  {
    name: "BDCC Bitica COIN",
    symbol: "BDCC",
    slug: "bdcc-bitica-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bdcc-bitica-coin.png",
  },
  {
    name: "Santa Coin",
    symbol: "SANTA",
    slug: "santa-coin-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/santa-coin-2.png",
  },
  {
    name: "Hokkaidu Inu",
    symbol: "HOKK",
    slug: "hokkaido-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hokkaido-inu.png",
  },
  {
    name: "Glimpse",
    symbol: "GLMS",
    slug: "glimpse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glimpse.png",
  },
  {
    name: "Age of Zalmoxis",
    symbol: "KOSON",
    slug: "age-of-zalmoxis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/age-of-zalmoxis.png",
  },
  {
    name: "Battle Hero",
    symbol: "BATH",
    slug: "battle-hero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/battle-hero.png",
  },
  {
    name: "QUEENBEE",
    symbol: "QBZ",
    slug: "queenbee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/queenbee.png",
  },
  {
    name: "Nakamoto Games",
    symbol: "NAKA",
    slug: "nakamoto-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nakamoto-games.png",
  },
  {
    name: "Proxy",
    symbol: "PRXY",
    slug: "proxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proxy.png",
  },
  {
    name: "Torii Finance",
    symbol: "TORII",
    slug: "torii-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/torii-finance.png",
  },
  {
    name: "Geist Finance",
    symbol: "GEIST",
    slug: "geist-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geist-finance.png",
  },
  {
    name: "LEXIT",
    symbol: "LEXI",
    slug: "lexit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lexit.png",
  },
  {
    name: "NFT Gallery",
    symbol: "NFG",
    slug: "nft-gallery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-gallery.png",
  },
  {
    name: "Hero Arena",
    symbol: "HERA",
    slug: "hero-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hero-arena.png",
  },
  {
    name: "MobileCoin",
    symbol: "MOB",
    slug: "mobilecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobilecoin.png",
  },
  {
    name: "LFGSwap Finance",
    symbol: "LFG",
    slug: "lfgswap-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lfgswap-finance.png",
  },
  {
    name: "FILUP",
    symbol: "FILUP",
    slug: "filup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/filup.png",
  },
  {
    name: "ProjectOasis",
    symbol: "OASIS",
    slug: "projectoasis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/projectoasis.png",
  },
  {
    name: "Block Commerce Protocol",
    symbol: "BCP",
    slug: "block-commerce-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block-commerce-protocol.png",
  },
  {
    name: "Wise Token",
    symbol: "WISE",
    slug: "wise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wise.png",
  },
  {
    name: "Kalao",
    symbol: "KLO",
    slug: "kalao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalao.png",
  },
  {
    name: "Draken",
    symbol: "DRK",
    slug: "draken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/draken.png",
  },
  {
    name: "OVR",
    symbol: "OVR",
    slug: "ovr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ovr.png",
  },
  {
    name: "Binapet",
    symbol: "BPET",
    slug: "binapet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binapet.png",
  },
  {
    name: "Starmon Metaverse",
    symbol: "SMON",
    slug: "starmon-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starmon-metaverse.png",
  },
  {
    name: "FEG Token",
    symbol: "FEG",
    slug: "fegtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fegtoken.png",
  },
  {
    name: "LOGOS",
    symbol: "LOG",
    slug: "logos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/logos.png",
  },
  {
    name: "Cyclos",
    symbol: "CYS",
    slug: "cyclos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyclos.png",
  },
  {
    name: "Alvey Chain",
    symbol: "WALV",
    slug: "alvey-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alvey-chain.png",
  },
  {
    name: "Cryptopolis",
    symbol: "CPO",
    slug: "cryptopolis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptopolis.png",
  },
  {
    name: "WaultSwap",
    symbol: "WEX",
    slug: "waultswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waultswap.png",
  },
  {
    name: "Sin City Token",
    symbol: "SIN",
    slug: "sincity-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sincity-token.png",
  },
  {
    name: "Alpha Kombat",
    symbol: "ALKOM",
    slug: "alpha-kombat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-kombat.png",
  },
  {
    name: "Yaki Gold",
    symbol: "YAG",
    slug: "yaki-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yaki-gold.png",
  },
  {
    name: "Dreams Quest",
    symbol: "DREAMS",
    slug: "dreams-quest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dreams-quest.png",
  },
  {
    name: "Ethermon",
    symbol: "EMON",
    slug: "ethermon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethermon.png",
  },
  {
    name: "Coinzo Token",
    symbol: "CNZ",
    slug: "coinzo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinzo-token.png",
  },
  {
    name: "Wild Credit",
    symbol: "WILD",
    slug: "wild-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wild-credit.png",
  },
  {
    name: "GetKicks",
    symbol: "KICKS",
    slug: "getkicks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/getkicks.png",
  },
  {
    name: "Orion Money",
    symbol: "ORION",
    slug: "orion-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orion-money.png",
  },
  {
    name: "Warena",
    symbol: "RENA",
    slug: "warena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/warena.png",
  },
  {
    name: "Firdaos",
    symbol: "FDO",
    slug: "firdaos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firdaos.png",
  },
  {
    name: "GameFi",
    symbol: "GAFI",
    slug: "gamefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamefi.png",
  },
  {
    name: "Coinbase Wrapped Staked ETH",
    symbol: "CBETH",
    slug: "coinbase-wrapped-staked-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinbase-wrapped-staked-eth.png",
  },
  {
    name: "Flurry Finance",
    symbol: "FLURRY",
    slug: "flurry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flurry.png",
  },
  {
    name: "MetaBeat",
    symbol: "BEAT",
    slug: "metabeat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metabeat.png",
  },
  {
    name: "HarmonyPad",
    symbol: "HPAD",
    slug: "harmonypad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harmonypad.png",
  },
  {
    name: "Pandora Protocol",
    symbol: "PNDR",
    slug: "pandora-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pandora-protocol.png",
  },
  {
    name: "The Three Kingdoms",
    symbol: "TTK",
    slug: "the-three-kingdoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-three-kingdoms.png",
  },
  {
    name: "3X Long Matic Token",
    symbol: "MATICBULL",
    slug: "3x-long-matic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-matic-token.png",
  },
  {
    name: "HoneyFarm Finance",
    symbol: "HONEY",
    slug: "honeyfarm-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honeyfarm-finance.png",
  },
  {
    name: "Shimmer",
    symbol: "SMR",
    slug: "shimmer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shimmer.png",
  },
  {
    name: "ArtWallet",
    symbol: "1ART",
    slug: "artwallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artwallet.png",
  },
  {
    name: "3X Long Sushi Token",
    symbol: "SUSHIBULL",
    slug: "3x-long-sushi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-sushi-token.png",
  },
  {
    name: "Strips Finance",
    symbol: "STRP",
    slug: "strips-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strips-finance.png",
  },
  {
    name: "Gro DAO Token",
    symbol: "GRO",
    slug: "gro-dao-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gro-dao-token.png",
  },
  {
    name: "Crusaders of Crypto",
    symbol: "CRUSADER",
    slug: "crusaders-of-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crusaders-of-crypto.png",
  },
  {
    name: "Frutti Dino",
    symbol: "FDT",
    slug: "frutti-dino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frutti-dino.png",
  },
  {
    name: "Block Monsters",
    symbol: "MNSTRS",
    slug: "block-monsters",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block-monsters.png",
  },
  {
    name: "OpenOcean",
    symbol: "OOE",
    slug: "openocean",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/openocean.png",
  },
  {
    name: "XTZUP",
    symbol: "XTZUP",
    slug: "xtzup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtzup.png",
  },
  {
    name: "BABY DOGE BILLIONAIRE",
    symbol: "BABYDB",
    slug: "baby-doge-billionaire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-doge-billionaire.png",
  },
  {
    name: "FXT Token",
    symbol: "FXT",
    slug: "fxt-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fxt-token.png",
  },
  {
    name: "XDAO",
    symbol: "XDAO",
    slug: "xdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdao.png",
  },
  {
    name: "PalGold",
    symbol: "PALG",
    slug: "palgold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/palgold.png",
  },
  {
    name: "Satoshi Island",
    symbol: "STC",
    slug: "satoshi-island",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satoshi-island.png",
  },
  {
    name: "Beta Finance",
    symbol: "BETA",
    slug: "beta-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beta-finance.png",
  },
  {
    name: "Football Stars",
    symbol: "FootballStars",
    slug: "football-stars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/football-stars.png",
  },
  {
    name: "Kingdom Raids",
    symbol: "KRS",
    slug: "kingdom-raids",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdom-raids.png",
  },
  {
    name: "Filda",
    symbol: "FILDA",
    slug: "filda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/filda.png",
  },
  {
    name: "Qredo",
    symbol: "QRDO",
    slug: "qredo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qredo.png",
  },
  {
    name: "Rewards",
    symbol: "RWD",
    slug: "rewards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rewards.png",
  },
  {
    name: "Polkacity",
    symbol: "POLC",
    slug: "polkacity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkacity.png",
  },
  {
    name: "Vent Finance",
    symbol: "VENT",
    slug: "vent-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vent-finance.png",
  },
  {
    name: "CryptoMines",
    symbol: "ETERNAL",
    slug: "cryptomines",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptomines.png",
  },
  {
    name: "Gravitoken",
    symbol: "GRV",
    slug: "gravitoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gravitoken.png",
  },
  {
    name: "GMR Finance",
    symbol: "GMR",
    slug: "gmr-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gmr-finance.png",
  },
  {
    name: "Fisco Coin",
    symbol: "FSCC",
    slug: "fisco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fisco.png",
  },
  {
    name: "FireStarter",
    symbol: "FLAME",
    slug: "firestarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firestarter.png",
  },
  {
    name: "Dogechain",
    symbol: "DC",
    slug: "dogechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogechain.png",
  },
  {
    name: "HistoryDAO",
    symbol: "HAO",
    slug: "historydao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/historydao.png",
  },
  {
    name: "Gold Secured Currency",
    symbol: "GSX",
    slug: "gold-secured-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-secured-currency.png",
  },
  {
    name: "KCAL",
    symbol: "KCAL",
    slug: "kcal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kcal.png",
  },
  {
    name: "Coinary Token",
    symbol: "CYT",
    slug: "coinary-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinary-token.png",
  },
  {
    name: "Snook",
    symbol: "SNK",
    slug: "snook",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snook.png",
  },
  {
    name: "Vectorium",
    symbol: "VECT",
    slug: "vectorium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vectorium.png",
  },
  {
    name: "Tiger King",
    symbol: "TKING",
    slug: "tiger-king",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tiger-king.png",
  },
  {
    name: "XP NETWORK",
    symbol: "XPNET",
    slug: "xp-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xp-network.png",
  },
  {
    name: "hiSQUIGGLE",
    symbol: "HISQUIGGLE",
    slug: "hisquiggle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hisquiggle.png",
  },
  {
    name: "DEFI Top 5 Tokens Index",
    symbol: "DEFI5",
    slug: "defi-top-5-tokens-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-top-5-tokens-index.png",
  },
  {
    name: "Overlord",
    symbol: "LORD",
    slug: "overlord",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/overlord.png",
  },
  {
    name: "Crypto Puffs",
    symbol: "PUFFS",
    slug: "crypto-puffs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-puffs.png",
  },
  {
    name: "Synapse",
    symbol: "SYN",
    slug: "synapse-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synapse-2.png",
  },
  {
    name: "SwapDEX",
    symbol: "SDX",
    slug: "swapdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapdex.png",
  },
  {
    name: "SumSwap",
    symbol: "SUM",
    slug: "sumswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sumswap.png",
  },
  {
    name: "The Protocol",
    symbol: "THE",
    slug: "the-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-protocol.png",
  },
  {
    name: "FolgoryUSD",
    symbol: "USDF",
    slug: "folgoryusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/folgoryusd.png",
  },
  {
    name: "PoolTogether",
    symbol: "POOL",
    slug: "pooltogether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pooltogether.png",
  },
  {
    name: "DareNFT",
    symbol: "DNFT",
    slug: "darenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darenft.png",
  },
  {
    name: "Folder Protocol",
    symbol: "FOL",
    slug: "folder-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/folder-protocol.png",
  },
  {
    name: "CALO INDOOR",
    symbol: "IFIT",
    slug: "calo-indoor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/calo-indoor.png",
  },
  {
    name: "SmartCoin",
    symbol: "SMRT",
    slug: "smartcoin-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartcoin-farm.png",
  },
  {
    name: "Chronicle",
    symbol: "XNL",
    slug: "chronicle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chronicle.png",
  },
  {
    name: "XCAD Network",
    symbol: "XCAD",
    slug: "xcad-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xcad-network.png",
  },
  {
    name: "Aidi Finance",
    symbol: "AIDI",
    slug: "aidi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aidi-finance.png",
  },
  {
    name: "TBCC Labs",
    symbol: "TBCC",
    slug: "tbcc-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tbcc-labs.png",
  },
  {
    name: "Gyro",
    symbol: "GYRO",
    slug: "gyro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gyro.png",
  },
  {
    name: "DRIFE",
    symbol: "DRF",
    slug: "drife",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drife.png",
  },
  {
    name: "GGDApp",
    symbol: "GGTK",
    slug: "ggdapp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ggdapp.png",
  },
  {
    name: "Wombat Exchange",
    symbol: "WOM",
    slug: "wombat-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wombat-exchange.png",
  },
  {
    name: "Thetan Arena",
    symbol: "THG",
    slug: "thetan-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thetan-arena.png",
  },
  {
    name: "MContent",
    symbol: "MCONTENT",
    slug: "mcontent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcontent.png",
  },
  {
    name: "DeathRoad",
    symbol: "DRACE",
    slug: "deathroad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deathroad.png",
  },
  {
    name: "Earn Defi Coin",
    symbol: "EDC",
    slug: "earn-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earn-defi.png",
  },
  {
    name: "Opulous",
    symbol: "OPUL",
    slug: "opulous",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opulous.png",
  },
  {
    name: "Metaplex",
    symbol: "MPLX",
    slug: "metaplex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaplex.png",
  },
  {
    name: "Dragon Slayer",
    symbol: "DRS",
    slug: "dragon-slayer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-slayer.png",
  },
  {
    name: "nHBTC",
    symbol: "N0001",
    slug: "nhbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nhbtc.png",
  },
  {
    name: "The Paradox Metaverse",
    symbol: "PARADOX",
    slug: "paradox-metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paradox-metaverse.png",
  },
  {
    name: "Bitzen.Space",
    symbol: "BZEN",
    slug: "bitzen-space",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitzen-space.png",
  },
  {
    name: "IX Swap",
    symbol: "IXS",
    slug: "ix-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ix-swap.png",
  },
  {
    name: "Toucan Protocol: Base Carbon Tonne",
    symbol: "BCT",
    slug: "toucan-protocol-base-carbon-tonne",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/toucan-protocol-base-carbon-tonne.png",
  },
  {
    name: "CRYPTOKKI",
    symbol: "TOKKI",
    slug: "cryptokki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptokki.png",
  },
  {
    name: "CryptoCars",
    symbol: "CCAR",
    slug: "cryptocars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptocars.png",
  },
  {
    name: "FUTUREXCRYPTO",
    symbol: "FXC",
    slug: "futurexcrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futurexcrypto.png",
  },
  {
    name: "Spell Token",
    symbol: "SPELL",
    slug: "spell-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spell-token.png",
  },
  {
    name: "Metababy",
    symbol: "BABY",
    slug: "metababy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metababy.png",
  },
  {
    name: "Uberstate RIT 2.0",
    symbol: "RIT20",
    slug: "uberstate-inc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uberstate-inc.png",
  },
  {
    name: "Polycat Finance",
    symbol: "FISH",
    slug: "polycat-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polycat-finance.png",
  },
  {
    name: "hiMAYC",
    symbol: "HIMAYC",
    slug: "himayc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/himayc.png",
  },
  {
    name: "MicroSHIB",
    symbol: "MICROSHIB",
    slug: "microshib",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microshib.png",
  },
  {
    name: "Minifootball",
    symbol: "MINIFOOTBALL",
    slug: "minifootball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minifootball.png",
  },
  {
    name: "MiniDOGE",
    symbol: "MINIDOGE",
    slug: "minidoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minidoge.png",
  },
  {
    name: "AvaXlauncher",
    symbol: "AVXL",
    slug: "avaxlauncher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avaxlauncher.png",
  },
  {
    name: "FILDOWN",
    symbol: "FILDOWN",
    slug: "fildown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fildown.png",
  },
  {
    name: "RedFEG",
    symbol: "REDFEG",
    slug: "redfeg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redfeg.png",
  },
  {
    name: "Moonpot",
    symbol: "POTS",
    slug: "moonpot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonpot.png",
  },
  {
    name: "hiODBS",
    symbol: "HIODBS",
    slug: "hiodbs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hiodbs.png",
  },
  {
    name: "Neutral Dollar",
    symbol: "nUSD",
    slug: "neutral-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neutral-dollar.png",
  },
  {
    name: "Vention",
    symbol: "VENTION",
    slug: "vention",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vention.png",
  },
  {
    name: "Moonie NFT",
    symbol: "MNY",
    slug: "moonie-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonie-nft.png",
  },
  {
    name: "Lazio Fan Token",
    symbol: "LAZIO",
    slug: "lazio-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lazio-fan-token.png",
  },
  {
    name: "BKEX Token",
    symbol: "BKK",
    slug: "bkex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bkex-token.png",
  },
  {
    name: "Crypto ARC",
    symbol: "ARC",
    slug: "crypto-arc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-arc.png",
  },
  {
    name: "Pundi X[old]",
    symbol: "NPXS",
    slug: "pundi-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pundi-x.png",
  },
  {
    name: "ZFMCOIN",
    symbol: "ZFM",
    slug: "zfmcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zfmcoin.png",
  },
  {
    name: "MiraQle",
    symbol: "MQL",
    slug: "miraqle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/miraqle.png",
  },
  {
    name: "FODL Finance",
    symbol: "FODL",
    slug: "fodl-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fodl-finance.png",
  },
  {
    name: "Centrifuge",
    symbol: "CFG",
    slug: "centrifuge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centrifuge.png",
  },
  {
    name: "Unipilot",
    symbol: "PILOT",
    slug: "unipilot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unipilot.png",
  },
  {
    name: "Realm",
    symbol: "REALM",
    slug: "realm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realm.png",
  },
  {
    name: "Seeder Finance",
    symbol: "LEAF",
    slug: "seeder-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seeder-finance.png",
  },
  {
    name: "AlinX",
    symbol: "ALIX",
    slug: "alinx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alinx.png",
  },
  {
    name: "SBF Goes to Prison",
    symbol: "SBFP",
    slug: "sbf-goes-to-prison",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sbf-goes-to-prison.png",
  },
  {
    name: "ITSBLOC",
    symbol: "ITSB",
    slug: "itsbloc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/itsbloc.png",
  },
  {
    name: "3X Short Bitcoin Token",
    symbol: "BEAR",
    slug: "3x-short-bitcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-short-bitcoin-token.png",
  },
  {
    name: "Spookyswap",
    symbol: "BOO",
    slug: "spookyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spookyswap.png",
  },
  {
    name: "Yield Yak",
    symbol: "YAK",
    slug: "yield-yak",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-yak.png",
  },
  {
    name: "eCash",
    symbol: "XEC",
    slug: "ecash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecash.png",
  },
  {
    name: "Genebank Token",
    symbol: "GNBT",
    slug: "genebank-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genebank-token.png",
  },
  {
    name: "Braintrust",
    symbol: "BTRST",
    slug: "braintrust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/braintrust.png",
  },
  {
    name: "hiENS3",
    symbol: "HIENS3",
    slug: "hiens3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hiens3.png",
  },
  {
    name: "IDK",
    symbol: "IDK",
    slug: "idk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idk.png",
  },
  {
    name: "Berry",
    symbol: "BERRY",
    slug: "berry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/berry.png",
  },
  {
    name: "DeFi Pulse Index",
    symbol: "DPI",
    slug: "defi-pulse-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-pulse-index.png",
  },
  {
    name: "Heroes & Empires",
    symbol: "HE",
    slug: "heroes-and-empires",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heroes-and-empires.png",
  },
  {
    name: "YDragon",
    symbol: "YDR",
    slug: "ydragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ydragon.png",
  },
  {
    name: "Monsta Infinite",
    symbol: "MONI",
    slug: "monsta-infinite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monsta-infinite.png",
  },
  {
    name: "GRAP",
    symbol: "GRAP",
    slug: "grap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grap.png",
  },
  {
    name: "Paribus",
    symbol: "PBX",
    slug: "paribus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paribus.png",
  },
  {
    name: "UNIUP",
    symbol: "UNIUP",
    slug: "uniup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniup.png",
  },
  {
    name: "Papa Doge Coin",
    symbol: "PAPADOGE",
    slug: "papa-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/papa-doge-coin.png",
  },
  {
    name: "Pocoland",
    symbol: "POCO",
    slug: "pocoland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pocoland.png",
  },
  {
    name: "The Doge NFT",
    symbol: "DOG",
    slug: "the-doge-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-doge-nft.png",
  },
  {
    name: "Double Swap Token",
    symbol: "DST",
    slug: "double-swap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/double-swap-token.png",
  },
  {
    name: "YAY Games",
    symbol: "YAY",
    slug: "yay-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yay-games.png",
  },
  {
    name: "SXPDOWN",
    symbol: "SXPDOWN",
    slug: "sxpdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sxpdown.png",
  },
  {
    name: "TimeCoinProtocol",
    symbol: "TMCN",
    slug: "timecoinprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/timecoinprotocol.png",
  },
  {
    name: "THORChain (ERC20)",
    symbol: "RUNE",
    slug: "thorchain-erc20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thorchain-erc20.png",
  },
  {
    name: "ExenPay Token",
    symbol: "EXENP",
    slug: "exenpay-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exenpay-token.png",
  },
  {
    name: "Dopex",
    symbol: "DPX",
    slug: "dopex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dopex.png",
  },
  {
    name: "SingularityDAO",
    symbol: "SDAO",
    slug: "singularitydao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/singularitydao.png",
  },
  {
    name: "EthereumFair",
    symbol: "ETF",
    slug: "ethereumfair",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereumfair.png",
  },
  {
    name: "Wrapped NCG",
    symbol: "WNCG",
    slug: "wrapped-ncg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-ncg.png",
  },
  {
    name: "RealFevr",
    symbol: "FEVR",
    slug: "realfevr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realfevr.png",
  },
  {
    name: "Talken",
    symbol: "TALK",
    slug: "talken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/talken.png",
  },
  {
    name: "ADAX",
    symbol: "ADAX",
    slug: "adax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adax.png",
  },
  {
    name: "GoMining token",
    symbol: "GMT",
    slug: "gomining-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gomining-token.png",
  },
  {
    name: "AladdinDAO",
    symbol: "ALD",
    slug: "aladdindao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aladdindao.png",
  },
  {
    name: "Paypolitan Token",
    symbol: "EPAN",
    slug: "paypolitan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paypolitan-token.png",
  },
  {
    name: "Star Atlas DAO",
    symbol: "POLIS",
    slug: "star-atlas-polis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/star-atlas-polis.png",
  },
  {
    name: "NFTPad",
    symbol: "NFTPAD",
    slug: "nftpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftpad.png",
  },
  {
    name: "DOJO",
    symbol: "DOJO",
    slug: "dojo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dojo.png",
  },
  {
    name: "Cope",
    symbol: "COPE",
    slug: "cope",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cope.png",
  },
  {
    name: "hiDOODLES",
    symbol: "HIDOODLES",
    slug: "hidoodles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hidoodles.png",
  },
  {
    name: "Bitcoin TRC20",
    symbol: "BTCT",
    slug: "bitcoin-trc20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-trc20.png",
  },
  {
    name: "Kaby Arena",
    symbol: "KABY",
    slug: "kaby-arena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kaby-arena.png",
  },
  {
    name: "G999",
    symbol: "G999",
    slug: "g999",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/g999.png",
  },
  {
    name: "XEN Crypto",
    symbol: "XEN",
    slug: "xen-crypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xen-crypto.png",
  },
  {
    name: "LINKDOWN",
    symbol: "LINKDOWN",
    slug: "linkdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkdown.png",
  },
  {
    name: "Hoo Token",
    symbol: "HOO",
    slug: "hoo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoo-token.png",
  },
  {
    name: "00 Token",
    symbol: "00",
    slug: "00-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/00-token.png",
  },
  {
    name: "Alkimi",
    symbol: "$ADS",
    slug: "alkimi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alkimi.png",
  },
  {
    name: "3X Long Bitcoin Token",
    symbol: "BULL",
    slug: "3x-long-bitcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-bitcoin-token.png",
  },
  {
    name: "EIFI FINANCE",
    symbol: "EIFI",
    slug: "eifi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eifi-finance.png",
  },
  {
    name: "BENQI",
    symbol: "QI",
    slug: "benqi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/benqi.png",
  },
  {
    name: "pGALA",
    symbol: "PGALA",
    slug: "pgala",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pgala.png",
  },
  {
    name: "SubGame",
    symbol: "SGB",
    slug: "subgame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/subgame.png",
  },
  {
    name: "Privi TRAX",
    symbol: "TRAX",
    slug: "privi-trax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/privi-trax.png",
  },
  {
    name: "MiniCake",
    symbol: "MINICAKE",
    slug: "minicake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minicake.png",
  },
  {
    name: "LINKUP",
    symbol: "LINKUP",
    slug: "linkup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkup.png",
  },
  {
    name: "PUNK Floor",
    symbol: "FLOOR",
    slug: "punk-floor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/punk-floor.png",
  },
  {
    name: "Sweat Economy",
    symbol: "SWEAT",
    slug: "sweat-economy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sweat-economy.png",
  },
  {
    name: "Angola",
    symbol: "AGLA",
    slug: "angola",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/angola.png",
  },
  {
    name: "AOS",
    symbol: "AOS",
    slug: "aos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aos.png",
  },
  {
    name: "Based Gold",
    symbol: "BGLD",
    slug: "based-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/based-gold.png",
  },
  {
    name: "UNIDOWN",
    symbol: "UNIDOWN",
    slug: "unidown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unidown.png",
  },
  {
    name: "VEROX",
    symbol: "VRX",
    slug: "verox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verox.png",
  },
  {
    name: "Convex Finance",
    symbol: "CVX",
    slug: "convex-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/convex-finance.png",
  },
  {
    name: "Arena Token",
    symbol: "ARENA",
    slug: "arena-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arena-token.png",
  },
  {
    name: "Sukhavati Network",
    symbol: "SKT",
    slug: "sukhavati-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sukhavati-network.png",
  },
  {
    name: "New BitShares",
    symbol: "NBS",
    slug: "new-bitshares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/new-bitshares.png",
  },
  {
    name: "Infinitee Finance",
    symbol: "INFTEE",
    slug: "infinitee-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitee-finance.png",
  },
  {
    name: "Adappter Token",
    symbol: "ADP",
    slug: "adappter-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adappter-token.png",
  },
  {
    name: "My DeFi Legends",
    symbol: "DLEGENDS",
    slug: "my-defi-legends",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-defi-legends.png",
  },
  {
    name: "KINE",
    symbol: "KINE",
    slug: "kine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kine.png",
  },
  {
    name: "Star Atlas",
    symbol: "ATLAS",
    slug: "star-atlas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/star-atlas.png",
  },
  {
    name: "Wrapped AVAX",
    symbol: "WAVAX",
    slug: "wavax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wavax.png",
  },
  {
    name: "YooShi",
    symbol: "YOOSHI",
    slug: "yooshi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yooshi.png",
  },
  {
    name: "My Crypto Heroes",
    symbol: "MCHC",
    slug: "my-crypto-heroes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-crypto-heroes.png",
  },
  {
    name: "Brazilian Digital Token",
    symbol: "BRZ",
    slug: "brz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/brz.png",
  },
  {
    name: "Wallet Swap",
    symbol: "WSWAP",
    slug: "wallet-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wallet-swap.png",
  },
  {
    name: "WIZARD",
    symbol: "WIZARD",
    slug: "wizard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wizard.png",
  },
  {
    name: "vEmpire DDAO",
    symbol: "VEMP",
    slug: "vempire-ddao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vempire-ddao.png",
  },
  {
    name: "Daddy Doge",
    symbol: "DADDYDOGE",
    slug: "daddy-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daddy-doge.png",
  },
  {
    name: "Business Platform Tomato Coin",
    symbol: "BPTC",
    slug: "business-platform-tomato-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/business-platform-tomato-coin.png",
  },
  {
    name: "Content Value Network",
    symbol: "CVNT",
    slug: "content-value-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/content-value-network.png",
  },
  {
    name: "Axelar",
    symbol: "AXL",
    slug: "axelar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axelar.png",
  },
  {
    name: "Cavapoo",
    symbol: "CAVA",
    slug: "cavapoo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cavapoo.png",
  },
  {
    name: "DinoLFG",
    symbol: "DINO",
    slug: "dinolfg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinolfg.png",
  },
  {
    name: "Velo",
    symbol: "VELO",
    slug: "velo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/velo.png",
  },
  {
    name: "ANIVERSE",
    symbol: "ANV",
    slug: "aniverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aniverse.png",
  },
  {
    name: "Mango Markets",
    symbol: "MNGO",
    slug: "mango-markets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mango-markets.png",
  },
  {
    name: "People's Punk",
    symbol: "DDDD",
    slug: "peoples-punk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peoples-punk.png",
  },
  {
    name: "Rai Reflex Index",
    symbol: "RAI",
    slug: "rai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rai.png",
  },
  {
    name: "Pet Games",
    symbol: "PETG",
    slug: "pet-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pet-games.png",
  },
  {
    name: "NFT Starter",
    symbol: "NST",
    slug: "nft-starter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-starter.png",
  },
  {
    name: "CheCoin",
    symbol: "CHECOIN",
    slug: "checoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/checoin.png",
  },
  {
    name: "Rune",
    symbol: "RUNE",
    slug: "rune",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rune.png",
  },
  {
    name: "Tamadoge",
    symbol: "TAMA",
    slug: "tamadoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tamadoge.png",
  },
  {
    name: "Karura",
    symbol: "KAR",
    slug: "karura",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karura.png",
  },
  {
    name: "3X Long Ethereum Token",
    symbol: "ETHBULL",
    slug: "3x-long-ethereum-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3x-long-ethereum-token.png",
  },
  {
    name: "CyberDoge",
    symbol: "CybrrrDOGE",
    slug: "cyberdoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyberdoge.png",
  },
  {
    name: "PlatON",
    symbol: "LAT",
    slug: "platon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/platon.png",
  },
  {
    name: "Metahero",
    symbol: "HERO",
    slug: "metahero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metahero.png",
  },
  {
    name: "miMatic",
    symbol: "MIMATIC",
    slug: "mimatic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mimatic.png",
  },
  {
    name: "Optimus OPT3",
    symbol: "OPT3",
    slug: "optimus-opt3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/optimus-opt3.png",
  },
  {
    name: "XRPUP",
    symbol: "XRPUP",
    slug: "xrpup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrpup.png",
  },
  {
    name: "Wanaka Farm",
    symbol: "WANA",
    slug: "wanaka-farm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanaka-farm.png",
  },
  {
    name: "Citizen Finance",
    symbol: "CIFI",
    slug: "citizen-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/citizen-finance.png",
  },
  {
    name: "LOCGame",
    symbol: "LOCG",
    slug: "locgame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/locgame.png",
  },
  {
    name: "CardWallet",
    symbol: "CW",
    slug: "cardwallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cardwallet.png",
  },
  {
    name: "ALP Coin",
    symbol: "ALP",
    slug: "alp-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alp-coin.png",
  },
  {
    name: "Chain Games",
    symbol: "CHAIN",
    slug: "chain-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain-games.png",
  },
  {
    name: "Defi For You",
    symbol: "DFY",
    slug: "defi-for-you",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-for-you.png",
  },
  {
    name: "Terran Coin",
    symbol: "TRR",
    slug: "terran-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terran-coin.png",
  },
  {
    name: "Jasmy",
    symbol: "JASMY",
    slug: "jasmy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jasmy.png",
  },
  {
    name: "Moon Rabbit",
    symbol: "AAA",
    slug: "moon-rabbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moon-rabbit.png",
  },
  {
    name: "ADAUP",
    symbol: "ADAUP",
    slug: "adaup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adaup.png",
  },
  {
    name: "Shiden Network",
    symbol: "SDN",
    slug: "shiden-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiden-network.png",
  },
  {
    name: "Pig Finance",
    symbol: "PIG",
    slug: "pig-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pig-finance.png",
  },
  {
    name: "Lithium",
    symbol: "LITH",
    slug: "lithium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lithium.png",
  },
  {
    name: "CARD.STARTER",
    symbol: "CARDS",
    slug: "card-starter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/card-starter.png",
  },
  {
    name: "PlantVsUndead",
    symbol: "PVU",
    slug: "plantvsundead",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plantvsundead.png",
  },
  {
    name: "EverRise",
    symbol: "RISE",
    slug: "everrise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everrise.png",
  },
  {
    name: "Australian Dollar Token",
    symbol: "AUDT",
    slug: "australian-dollar-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/australian-dollar-token.png",
  },
  {
    name: "Kishu Inu",
    symbol: "KISHU",
    slug: "kishu-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kishu-inu.png",
  },
  {
    name: "DOTDOWN",
    symbol: "DOTDOWN",
    slug: "dotdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dotdown.png",
  },
  {
    name: "Fenerbahçe Token",
    symbol: "FB",
    slug: "fenerbahce-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fenerbahce-token.png",
  },
  {
    name: "Mini Shiba",
    symbol: "MINISHIBA",
    slug: "mini-shiba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mini-shiba.png",
  },
  {
    name: "DigiFinexToken",
    symbol: "DFT",
    slug: "digifinextoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digifinextoken.png",
  },
  {
    name: "PayProtocol",
    symbol: "PCI",
    slug: "payprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payprotocol.png",
  },
  {
    name: "IOI Token",
    symbol: "IOI",
    slug: "trade-race-manager",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trade-race-manager.png",
  },
  {
    name: "Value Liquidity",
    symbol: "VALUE",
    slug: "value-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/value-defi.png",
  },
  {
    name: "UniWswap",
    symbol: "UNIW",
    slug: "uniwswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniwswap.png",
  },
  {
    name: "EPIK Prime",
    symbol: "EPIK",
    slug: "epik-prime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/epik-prime.png",
  },
  {
    name: "Kryptomon",
    symbol: "KMON",
    slug: "kryptomon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kryptomon.png",
  },
  {
    name: "Sonar",
    symbol: "PING",
    slug: "sonar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonar.png",
  },
  {
    name: "Golden Doge",
    symbol: "GDOGE",
    slug: "golden-doge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-doge.png",
  },
  {
    name: "AAVEUP",
    symbol: "AAVEUP",
    slug: "aave-up",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-up.png",
  },
  {
    name: "XRPDOWN",
    symbol: "XRPDOWN",
    slug: "xrpdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrpdown.png",
  },
  {
    name: "TON Crystal",
    symbol: "TON",
    slug: "ton-crystal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ton-crystal.png",
  },
  {
    name: "SUSHIUP",
    symbol: "SUSHIUP",
    slug: "sushiup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sushiup.png",
  },
  {
    name: "Feisty Doge NFT",
    symbol: "NFD",
    slug: "feisty-doge-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feisty-doge-nft.png",
  },
  {
    name: "Birb",
    symbol: "BIRB",
    slug: "birb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/birb.png",
  },
  {
    name: "DOTUP",
    symbol: "DOTUP",
    slug: "dotup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dotup.png",
  },
  {
    name: "sKLAY",
    symbol: "SKLAY",
    slug: "sklay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sklay.png",
  },
  {
    name: "PureFi Protocol",
    symbol: "UFI",
    slug: "purefi-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/purefi-protocol.png",
  },
  {
    name: "FinexboxToken",
    symbol: "FNB",
    slug: "finexboxtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/finexboxtoken.png",
  },
  {
    name: "Basis Gold Share",
    symbol: "BAGS",
    slug: "basis-gold-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basis-gold-share.png",
  },
  {
    name: "DMD",
    symbol: "DMD",
    slug: "dmd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dmd.png",
  },
  {
    name: "ETHPad",
    symbol: "ETHPAD",
    slug: "ethpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethpad.png",
  },
  {
    name: "Mrweb Finance",
    symbol: "AMA",
    slug: "mrweb-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mrweb-finance.png",
  },
  {
    name: "Soda Coin",
    symbol: "SOC",
    slug: "soda-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soda-coin.png",
  },
  {
    name: "APWars",
    symbol: "WGOLD",
    slug: "apwars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apwars.png",
  },
  {
    name: "Doge Killer",
    symbol: "LEASH",
    slug: "doge-killer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-killer.png",
  },
  {
    name: "Fear NFTs",
    symbol: "FEAR",
    slug: "fear-nfts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fear-nfts.png",
  },
  {
    name: "Xeno Token",
    symbol: "XNO",
    slug: "xeno-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xeno-token.png",
  },
  {
    name: "Akita Inu",
    symbol: "AKITA",
    slug: "akita-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akita-inu.png",
  },
  {
    name: "Truebit",
    symbol: "TRU",
    slug: "truebit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/truebit.png",
  },
  {
    name: "Human",
    symbol: "HMT",
    slug: "human",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/human.png",
  },
  {
    name: "Binamars",
    symbol: "BMARS",
    slug: "binamars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binamars.png",
  },
  {
    name: "Saitama Inu",
    symbol: "SAITAMA",
    slug: "saitama-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saitama-inu.png",
  },
  {
    name: "The Crypto Prophecies",
    symbol: "TCP",
    slug: "the-crypto-prophecies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-crypto-prophecies.png",
  },
  {
    name: "Radio Caca",
    symbol: "RACA",
    slug: "radio-caca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radio-caca.png",
  },
  {
    name: "Revomon",
    symbol: "REVO",
    slug: "revomon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revomon.png",
  },
  {
    name: "Beacon ETH",
    symbol: "BETH",
    slug: "beacon-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beacon-eth.png",
  },
  {
    name: "ADADOWN",
    symbol: "ADADOWN",
    slug: "adadown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adadown.png",
  },
  {
    name: "Sakura",
    symbol: "SKU",
    slug: "sakura",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sakura.png",
  },
  {
    name: "Million",
    symbol: "MM",
    slug: "million",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/million.png",
  },
  {
    name: "ETH 2x Flexible Leverage Index",
    symbol: "ETH2X-FLI",
    slug: "eth-2x-flexible-leverage-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-2x-flexible-leverage-index.png",
  },
  {
    name: "AAVEDOWN",
    symbol: "AAVEDOWN",
    slug: "aave-down",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave-down.png",
  },
  {
    name: "DinoSwap",
    symbol: "DINO",
    slug: "dinoswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinoswap.png",
  },
  {
    name: "Olympus",
    symbol: "OHM",
    slug: "olympus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olympus.png",
  },
  {
    name: "DNAxCAT Token",
    symbol: "DXCT",
    slug: "dnaxcat-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dnaxcat-token.png",
  },
  {
    name: "CryptoZoon",
    symbol: "ZOON",
    slug: "cryptozoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptozoon.png",
  },
  {
    name: "Amun Bitcoin 3x Daily Long",
    symbol: "BTC3L",
    slug: "amun-bitcoin-3x-daily-long",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amun-bitcoin-3x-daily-long.png",
  },
  {
    name: "ScooterCoin",
    symbol: "SCOOT",
    slug: "scootercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scootercoin.png",
  },
  {
    name: "DefiBox",
    symbol: "BOX",
    slug: "defibox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defibox.png",
  },
  {
    name: "Kabosu",
    symbol: "KABOSU",
    slug: "kabosu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kabosu.png",
  },
  {
    name: "PlayDapp",
    symbol: "PLA",
    slug: "playdapp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playdapp.png",
  },
  {
    name: "PolkaMonster",
    symbol: "PKMON",
    slug: "polkamonster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkamonster.png",
  },
  {
    name: "BitDAO",
    symbol: "BIT",
    slug: "bitdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitdao.png",
  },
  {
    name: "Fanadise",
    symbol: "FAN",
    slug: "fanadise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanadise.png",
  },
  {
    name: "Amun Bitcoin 3x Daily Short",
    symbol: "BTC3S",
    slug: "amun-bitcoin-3x-daily-short",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amun-bitcoin-3x-daily-short.png",
  },
  {
    name: "Wrapped Huobi Token",
    symbol: "WHT",
    slug: "wrapped-huobi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-huobi-token.png",
  },
  {
    name: "Step Hero",
    symbol: "HERO",
    slug: "step-hero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/step-hero.png",
  },
  {
    name: "Amun Ether 3x Daily Short",
    symbol: "ETH3S",
    slug: "amun-ether-3x-daily-short",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amun-ether-3x-daily-short.png",
  },
  {
    name: "CPCoin",
    symbol: "CPC",
    slug: "cpcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cpcoin.png",
  },
  {
    name: "Tokemak",
    symbol: "TOKE",
    slug: "tokemak",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokemak.png",
  },
  {
    name: "MileVerse",
    symbol: "MVC",
    slug: "mileverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mileverse.png",
  },
  {
    name: "CoPuppy",
    symbol: "CP",
    slug: "copuppy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/copuppy.png",
  },
  {
    name: "ITAM Games",
    symbol: "ITAM",
    slug: "itam-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/itam-games.png",
  },
  {
    name: "Assemble Protocol",
    symbol: "ASM",
    slug: "assemble-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/assemble-protocol.png",
  },
  {
    name: "Compound USDT",
    symbol: "CUSDT",
    slug: "compound-usdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-usdt.png",
  },
  {
    name: "MultiMillion",
    symbol: "MMM",
    slug: "multimillion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multimillion.png",
  },
  {
    name: "Medican Coin",
    symbol: "MCAN",
    slug: "medican-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medican-coin.png",
  },
  {
    name: "Swarm",
    symbol: "BZZ",
    slug: "ethereum-swarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-swarm.png",
  },
  {
    name: "Define",
    symbol: "DFA",
    slug: "define",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/define.png",
  },
  {
    name: "BNBUP",
    symbol: "BNBUP",
    slug: "bnbup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbup.png",
  },
  {
    name: "StarLink",
    symbol: "STARL",
    slug: "star-link",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/star-link.png",
  },
  {
    name: "OKExChain",
    symbol: "OKT",
    slug: "okexchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okexchain.png",
  },
  {
    name: "ARCx (old)",
    symbol: "ARC",
    slug: "arcx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcx.png",
  },
  {
    name: "DeRace",
    symbol: "DERC",
    slug: "derace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/derace.png",
  },
  {
    name: "Gera Coin",
    symbol: "GERA",
    slug: "gera-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gera-coin.png",
  },
  {
    name: "BNBDOWN",
    symbol: "BNBDOWN",
    slug: "bnbdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbdown.png",
  },
  {
    name: "ETHDOWN",
    symbol: "ETHDOWN",
    slug: "eth-down",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eth-down.png",
  },
  {
    name: "DinoX",
    symbol: "DNXC",
    slug: "dinox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinox.png",
  },
  {
    name: "O3Swap",
    symbol: "O3",
    slug: "o3swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/o3swap.png",
  },
  {
    name: "Splinterlands",
    symbol: "SPS",
    slug: "splinterlands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/splinterlands.png",
  },
  {
    name: "Pyram Token",
    symbol: "PYRAM",
    slug: "pyram-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyram-token.png",
  },
  {
    name: "Yield Guild Games",
    symbol: "YGG",
    slug: "yield-guild-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-guild-games.png",
  },
  {
    name: "Golden Ratio Token",
    symbol: "GRT",
    slug: "golden-ratio-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-ratio-token.png",
  },
  {
    name: "Baby Doge Coin",
    symbol: "BabyDoge",
    slug: "baby-doge-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-doge-coin.png",
  },
  {
    name: "ZOO - Crypto World",
    symbol: "ZOO",
    slug: "zoo-crypto-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoo-crypto-world.png",
  },
  {
    name: "SOMESING",
    symbol: "SSX",
    slug: "somesing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/somesing.png",
  },
  {
    name: "Linkflow Finance",
    symbol: "LF",
    slug: "linkflow-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkflow-finance.png",
  },
  {
    name: "Baby Cake",
    symbol: "BABYCAKE",
    slug: "baby-cake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baby-cake.png",
  },
  {
    name: "BTCUP",
    symbol: "BTCUP",
    slug: "btcup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/btcup.png",
  },
  {
    name: "BunnyPark",
    symbol: "BP",
    slug: "bunnypark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bunnypark.png",
  },
  {
    name: "Binamon",
    symbol: "BMON",
    slug: "binamon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binamon.png",
  },
  {
    name: "Amun Ether 3x Daily Long",
    symbol: "ETH3L",
    slug: "amun-ether-3x-daily-long",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amun-ether-3x-daily-long.png",
  },
  {
    name: "Wrapped Matic",
    symbol: "WMATIC",
    slug: "wmatic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wmatic.png",
  },
  {
    name: "MinePlex",
    symbol: "PLEX",
    slug: "mineplex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mineplex.png",
  },
  {
    name: "Chia Network",
    symbol: "XCH",
    slug: "chia-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chia-network.png",
  },
  {
    name: "ETHUP",
    symbol: "ETHUP",
    slug: "ethup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethup.png",
  },
  {
    name: "Atletico De Madrid Fan Token",
    symbol: "ATM",
    slug: "atletico-de-madrid-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atletico-de-madrid-fan-token.png",
  },
  {
    name: "Gold Coin Reserve",
    symbol: "GCR",
    slug: "gold-coin-reserve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-coin-reserve.png",
  },
  {
    name: "BTCDOWN",
    symbol: "BTCDOWN",
    slug: "btcdown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/btcdown.png",
  },
  {
    name: "Global AEX Token",
    symbol: "GAT",
    slug: "global-aex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-aex-token.png",
  },
  {
    name: "Misbloc",
    symbol: "MSB",
    slug: "misbloc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/misbloc.png",
  },
  {
    name: "Compound USD Coin",
    symbol: "CUSDC",
    slug: "compound-usd-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-usd-coin.png",
  },
  {
    name: "Compound Dai",
    symbol: "CDAI",
    slug: "compound-dai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-dai.png",
  },
  {
    name: "Global China Cash",
    symbol: "CNC",
    slug: "global-china-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-china-cash.png",
  },
  {
    name: "SXC Token",
    symbol: "SXC",
    slug: "sxc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sxc.png",
  },
  {
    name: "XT.com Token",
    symbol: "XT",
    slug: "xtcom-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtcom-token.png",
  },
  {
    name: "WETH",
    symbol: "WETH",
    slug: "weth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weth.png",
  },
  {
    name: "Agrolot",
    symbol: "AGLT",
    slug: "agrolot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agrolot.png",
  },
  {
    name: "Nibble",
    symbol: "NBXC",
    slug: "nibble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nibble.png",
  },
  {
    name: "Innovative Bioresearch Classic",
    symbol: "INNBCL",
    slug: "innovative-bioresearch-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/innovative-bioresearch-classic.png",
  },
  {
    name: "Money Plant Token",
    symbol: "MPT",
    slug: "money-plant-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/money-plant-token.png",
  },
  {
    name: "Benz",
    symbol: "BENZ",
    slug: "benz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/benz.png",
  },
  {
    name: "DABANKING",
    symbol: "DAB",
    slug: "dabanking",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dabanking.png",
  },
  {
    name: "Storeum",
    symbol: "STO",
    slug: "storeum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/storeum.png",
  },
  {
    name: "HEIDI",
    symbol: "HDI",
    slug: "heidi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heidi.png",
  },
  {
    name: "Flowchain",
    symbol: "FLC",
    slug: "flowchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flowchain.png",
  },
  {
    name: "Concoin",
    symbol: "CONX",
    slug: "concoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/concoin.png",
  },
  {
    name: "Baz Token",
    symbol: "BAZT",
    slug: "baz-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baz-token.png",
  },
  {
    name: "Stakinglab",
    symbol: "LABX",
    slug: "stakinglab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakinglab.png",
  },
  {
    name: "WXCOINS",
    symbol: "WXC",
    slug: "wxcoins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wxcoins.png",
  },
  {
    name: "KZ Cash",
    symbol: "KZC",
    slug: "kz-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kz-cash.png",
  },
  {
    name: "Argus",
    symbol: "ARGUS",
    slug: "argus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/argus.png",
  },
  {
    name: "PlatinumBAR",
    symbol: "XPTX",
    slug: "platinumbar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/platinumbar.png",
  },
  {
    name: "ECOSC",
    symbol: "ECU",
    slug: "ecosc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecosc.png",
  },
  {
    name: "MoX",
    symbol: "MOX",
    slug: "mox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mox.png",
  },
  {
    name: "Helpico",
    symbol: "HELP",
    slug: "helpico",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helpico.png",
  },
  {
    name: "Qbic",
    symbol: "QBIC",
    slug: "qbic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qbic.png",
  },
  {
    name: "Crystal Token",
    symbol: "CYL",
    slug: "crystal-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crystal-token.png",
  },
  {
    name: "MNPCoin",
    symbol: "MNP",
    slug: "mnpcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mnpcoin.png",
  },
  {
    name: "PonziCoin",
    symbol: "PONZI",
    slug: "ponzicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ponzicoin.png",
  },
  {
    name: "Abulaba",
    symbol: "AAA",
    slug: "abulaba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abulaba.png",
  },
  {
    name: "Project-X",
    symbol: "NANOX",
    slug: "project-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-x.png",
  },
  {
    name: "Bitcoinus",
    symbol: "BITS",
    slug: "bitcoinus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinus.png",
  },
  {
    name: "Staker",
    symbol: "STR",
    slug: "staker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/staker.png",
  },
  {
    name: "Galactrum",
    symbol: "ORE",
    slug: "galactrum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galactrum.png",
  },
  {
    name: "Mirai",
    symbol: "MRI",
    slug: "mirai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirai.png",
  },
  {
    name: "Alpha Coin",
    symbol: "APC",
    slug: "alpha-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-coin.png",
  },
  {
    name: "Noah Coin",
    symbol: "NOAHP",
    slug: "noah-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noah-coin.png",
  },
  {
    name: "Save and Gain",
    symbol: "SANDG",
    slug: "save-and-gain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/save-and-gain.png",
  },
  {
    name: "LiteCoin Ultra",
    symbol: "LTCU",
    slug: "litecoin-ultra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litecoin-ultra.png",
  },
  {
    name: "iBank",
    symbol: "IBANK",
    slug: "ibank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ibank.png",
  },
  {
    name: "LitecoinToken",
    symbol: "LTK",
    slug: "litecoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litecoin-token.png",
  },
  {
    name: "XDNA",
    symbol: "XDNA",
    slug: "xdna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdna.png",
  },
  {
    name: "Diligence",
    symbol: "IRA",
    slug: "diligence",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diligence.png",
  },
  {
    name: "Netrum",
    symbol: "NTR",
    slug: "netrum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netrum.png",
  },
  {
    name: "Dash Green",
    symbol: "DASHG",
    slug: "dash-green",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dash-green.png",
  },
  {
    name: "SharedStake",
    symbol: "SGT",
    slug: "sharedstake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sharedstake.png",
  },
  {
    name: "BitMoney",
    symbol: "BIT",
    slug: "bitmoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitmoney.png",
  },
  {
    name: "Bitvolt",
    symbol: "VOLT",
    slug: "bitvolt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitvolt.png",
  },
  {
    name: "IBStoken",
    symbol: "IBS",
    slug: "ibstoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ibstoken.png",
  },
  {
    name: "Wild Beast Block",
    symbol: "WBB",
    slug: "wild-beast-block",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wild-beast-block.png",
  },
  {
    name: "ALL BEST ICO",
    symbol: "ALLBI",
    slug: "all-best-ico",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/all-best-ico.png",
  },
  {
    name: "PLNcoin",
    symbol: "PLNC",
    slug: "plncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plncoin.png",
  },
  {
    name: "Coinonat",
    symbol: "CXT",
    slug: "coinonat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinonat.png",
  },
  {
    name: "PAXEX",
    symbol: "PAXEX",
    slug: "paxex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paxex.png",
  },
  {
    name: "Dinero",
    symbol: "DIN",
    slug: "dinero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinero.png",
  },
  {
    name: "Iconic",
    symbol: "ICON",
    slug: "iconic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iconic.png",
  },
  {
    name: "Akroma",
    symbol: "AKA",
    slug: "akroma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akroma.png",
  },
  {
    name: "Bulleon",
    symbol: "BUL",
    slug: "bulleon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bulleon.png",
  },
  {
    name: "SocialCoin",
    symbol: "SOCC",
    slug: "socialcoin-socc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/socialcoin-socc.png",
  },
  {
    name: "CaluraCoin",
    symbol: "CLC",
    slug: "caluracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caluracoin.png",
  },
  {
    name: "Ragnarok",
    symbol: "RAGNA",
    slug: "ragnarok",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ragnarok.png",
  },
  {
    name: "MktCoin",
    symbol: "MLM",
    slug: "mktcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mktcoin.png",
  },
  {
    name: "Cannation",
    symbol: "CNNC",
    slug: "cannation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cannation.png",
  },
  {
    name: "ETHPlus",
    symbol: "ETHP",
    slug: "ethplus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethplus.png",
  },
  {
    name: "Electrum Dark",
    symbol: "ELD",
    slug: "electrum-dark-eld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electrum-dark-eld.png",
  },
  {
    name: "Helex",
    symbol: "HLX",
    slug: "helex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helex.png",
  },
  {
    name: "SpeedCash",
    symbol: "SCS",
    slug: "speedcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/speedcash.png",
  },
  {
    name: "Bionic",
    symbol: "BNC",
    slug: "bionic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bionic.png",
  },
  {
    name: "Kemacoin",
    symbol: "KEMA",
    slug: "kemacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kemacoin.png",
  },
  {
    name: "Independent Money System",
    symbol: "IMS",
    slug: "independent-money-system",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/independent-money-system.png",
  },
  {
    name: "WeShow Token",
    symbol: "WET",
    slug: "weshow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weshow-token.png",
  },
  {
    name: "Kabberry Coin",
    symbol: "KKC",
    slug: "kabberry-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kabberry-coin.png",
  },
  {
    name: "JavaScript Token",
    symbol: "JS",
    slug: "javascript-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/javascript-token.png",
  },
  {
    name: "CCUniverse",
    symbol: "UVU",
    slug: "ccuniverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ccuniverse.png",
  },
  {
    name: "XMCT",
    symbol: "XMCT",
    slug: "xmct",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xmct.png",
  },
  {
    name: "Abitshadow Token",
    symbol: "ABST",
    slug: "abitshadow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abitshadow-token.png",
  },
  {
    name: "DOWCOIN",
    symbol: "DOW",
    slug: "dowcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dowcoin.png",
  },
  {
    name: "SONO",
    symbol: "SONO",
    slug: "altcommunity-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/altcommunity-coin.png",
  },
  {
    name: "Guider",
    symbol: "GDR",
    slug: "guider",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guider.png",
  },
  {
    name: "Cabbage",
    symbol: "CAB",
    slug: "cabbage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cabbage.png",
  },
  {
    name: "Gossip Coin",
    symbol: "GOSS",
    slug: "gossipcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gossipcoin.png",
  },
  {
    name: "ARbit",
    symbol: "ARB",
    slug: "arbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbit.png",
  },
  {
    name: "SongCoin",
    symbol: "SONG",
    slug: "songcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/songcoin.png",
  },
  {
    name: "Galilel",
    symbol: "GALI",
    slug: "galilel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galilel.png",
  },
  {
    name: "Quebecoin",
    symbol: "QBC",
    slug: "quebecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quebecoin.png",
  },
  {
    name: "EagleX",
    symbol: "EGX",
    slug: "eaglex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eaglex.png",
  },
  {
    name: "Gold Poker",
    symbol: "GPKR",
    slug: "gold-poker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gold-poker.png",
  },
  {
    name: "Comet",
    symbol: "CMT",
    slug: "comet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/comet.png",
  },
  {
    name: "YoloCash",
    symbol: "YLC",
    slug: "yolocash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yolocash.png",
  },
  {
    name: "Blakecoin",
    symbol: "BLC",
    slug: "blakecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blakecoin.png",
  },
  {
    name: "Nekonium",
    symbol: "NUKO",
    slug: "nekonium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nekonium.png",
  },
  {
    name: "CrevaCoin",
    symbol: "CREVA",
    slug: "crevacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crevacoin.png",
  },
  {
    name: "VectorAI",
    symbol: "VEC2",
    slug: "vector",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vector.png",
  },
  {
    name: "Hyper Speed Network",
    symbol: "HSN",
    slug: "hyper-speed-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyper-speed-network.png",
  },
  {
    name: "Eurocoin",
    symbol: "EUC",
    slug: "eurocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eurocoin.png",
  },
  {
    name: "SeChain",
    symbol: "SNN",
    slug: "sechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sechain.png",
  },
  {
    name: "IGToken",
    symbol: "IG",
    slug: "igtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/igtoken.png",
  },
  {
    name: "GoHelpFund",
    symbol: "HELP",
    slug: "gohelpfund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gohelpfund.png",
  },
  {
    name: "Aeron",
    symbol: "ARNX",
    slug: "aeron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aeron.png",
  },
  {
    name: "Datacoin",
    symbol: "DTC",
    slug: "datacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/datacoin.png",
  },
  {
    name: "Quantis Network",
    symbol: "QUAN",
    slug: "quantis-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quantis-network.png",
  },
  {
    name: "MDtoken",
    symbol: "MDTK",
    slug: "mdtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mdtoken.png",
  },
  {
    name: "Klimatas",
    symbol: "KTS",
    slug: "klimatas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klimatas.png",
  },
  {
    name: "MustangCoin",
    symbol: "MST",
    slug: "mustangcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mustangcoin.png",
  },
  {
    name: "Acoin",
    symbol: "ACOIN",
    slug: "acoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acoin.png",
  },
  {
    name: "Connect Coin",
    symbol: "XCON",
    slug: "connect-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/connect-coin.png",
  },
  {
    name: "SnodeCoin",
    symbol: "SND",
    slug: "snodecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snodecoin.png",
  },
  {
    name: "Squorum",
    symbol: "SQR",
    slug: "squorum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squorum.png",
  },
  {
    name: "CRDT",
    symbol: "CRDT",
    slug: "crdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crdt.png",
  },
  {
    name: "Prime-XI",
    symbol: "PXI",
    slug: "prime-xi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prime-xi.png",
  },
  {
    name: "Compound Coin",
    symbol: "COMP",
    slug: "compound-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound-coin.png",
  },
  {
    name: "Castle",
    symbol: "CSTL",
    slug: "castle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/castle.png",
  },
  {
    name: "ANON",
    symbol: "ANON",
    slug: "anon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anon.png",
  },
  {
    name: "Vivid Coin",
    symbol: "VIVID",
    slug: "vivid-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vivid-coin.png",
  },
  {
    name: "BLAST",
    symbol: "BLAST",
    slug: "blast",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blast.png",
  },
  {
    name: "S.Finance",
    symbol: "SFG",
    slug: "s-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/s-finance.png",
  },
  {
    name: "Luna Coin",
    symbol: "LUNA",
    slug: "luna-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luna-coin.png",
  },
  {
    name: "AmsterdamCoin",
    symbol: "AMS",
    slug: "amsterdamcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amsterdamcoin.png",
  },
  {
    name: "Feellike",
    symbol: "FLL",
    slug: "feellike",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feellike.png",
  },
  {
    name: "Mining Core Coin",
    symbol: "MCC",
    slug: "mining-core",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mining-core.png",
  },
  {
    name: "SWYFT",
    symbol: "SWYFTT",
    slug: "swyft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swyft.png",
  },
  {
    name: "Exosis",
    symbol: "EXO",
    slug: "exosis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exosis.png",
  },
  {
    name: "High Voltage",
    symbol: "HVCO",
    slug: "high-voltage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/high-voltage.png",
  },
  {
    name: "Veltor",
    symbol: "VLT",
    slug: "veltor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veltor.png",
  },
  {
    name: "ImageCash",
    symbol: "IMGC",
    slug: "imagecash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imagecash.png",
  },
  {
    name: "Californium",
    symbol: "CF",
    slug: "californium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/californium.png",
  },
  {
    name: "Shivers",
    symbol: "SHVR",
    slug: "shivers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shivers.png",
  },
  {
    name: "Halving Token",
    symbol: "HALV",
    slug: "halving-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/halving-coin.png",
  },
  {
    name: "Dollarcoin",
    symbol: "DLC",
    slug: "dollarcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dollarcoin.png",
  },
  {
    name: "SpreadCoin",
    symbol: "SPR",
    slug: "spreadcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spreadcoin.png",
  },
  {
    name: "Swirge",
    symbol: "SWG",
    slug: "swirge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swirge.png",
  },
  {
    name: "iBTC",
    symbol: "IBTC",
    slug: "ibtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ibtc.png",
  },
  {
    name: "onLEXpa",
    symbol: "onLEXpa",
    slug: "onlexpa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onlexpa.png",
  },
  {
    name: "BitRewards",
    symbol: "BIT",
    slug: "bitrewards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitrewards.png",
  },
  {
    name: "Apollon",
    symbol: "XAP",
    slug: "apollon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apollon.png",
  },
  {
    name: "DistX",
    symbol: "DISTX",
    slug: "distx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/distx.png",
  },
  {
    name: "Litecred",
    symbol: "LTCR",
    slug: "litecred",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litecred.png",
  },
  {
    name: "Obee Network",
    symbol: "OBEE",
    slug: "obee-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/obee-network.png",
  },
  {
    name: "Payfair",
    symbol: "PFR",
    slug: "payfair",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/payfair.png",
  },
  {
    name: "Zayedcoin",
    symbol: "ZYD",
    slug: "zayedcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zayedcoin.png",
  },
  {
    name: "Celeum",
    symbol: "CLX",
    slug: "celeum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celeum.png",
  },
  {
    name: "ROIyal Coin",
    symbol: "ROCO",
    slug: "roiyal-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roiyal-coin.png",
  },
  {
    name: "Rewardiqa",
    symbol: "REW",
    slug: "rewardiqa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rewardiqa.png",
  },
  {
    name: "Bitcoin Zero",
    symbol: "BZX",
    slug: "bitcoin-zero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-zero.png",
  },
  {
    name: "HyperQuant",
    symbol: "HQT",
    slug: "hyperquant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperquant.png",
  },
  {
    name: "Giant",
    symbol: "GIC",
    slug: "giant-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/giant-coin.png",
  },
  {
    name: "Zuflo Coin",
    symbol: "ZFL",
    slug: "zuflo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zuflo-coin.png",
  },
  {
    name: "Sparkle Loyalty",
    symbol: "SPRKL",
    slug: "sparkle-loyalty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sparkle-loyalty.png",
  },
  {
    name: "APR Coin",
    symbol: "APR",
    slug: "apr-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apr-coin.png",
  },
  {
    name: "Paytomat",
    symbol: "PTI",
    slug: "paytomat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paytomat.png",
  },
  {
    name: "SkyHub Coin",
    symbol: "SHB",
    slug: "skyhub-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skyhub-coin.png",
  },
  {
    name: "GravityCoin",
    symbol: "GXX",
    slug: "gravitycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gravitycoin.png",
  },
  {
    name: "TajCoin",
    symbol: "TAJ",
    slug: "tajcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tajcoin.png",
  },
  {
    name: "Ignition",
    symbol: "IC",
    slug: "ignition",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ignition.png",
  },
  {
    name: "Intelligent Trading Foundation",
    symbol: "ITT",
    slug: "intelligent-trading-foundation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intelligent-trading-foundation.png",
  },
  {
    name: "Newton Coin Project",
    symbol: "NCP",
    slug: "newton-coin-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newton-coin-project.png",
  },
  {
    name: "FUTURAX",
    symbol: "FTXT",
    slug: "futurax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/futurax.png",
  },
  {
    name: "SparksPay",
    symbol: "SPK",
    slug: "sparkspay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sparkspay.png",
  },
  {
    name: "Italo",
    symbol: "XTA",
    slug: "italo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/italo.png",
  },
  {
    name: "ARAW",
    symbol: "ARAW",
    slug: "araw",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/araw.png",
  },
  {
    name: "Phantomx",
    symbol: "PNX",
    slug: "phantomx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phantomx.png",
  },
  {
    name: "GINcoin",
    symbol: "GIN",
    slug: "gincoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gincoin.png",
  },
  {
    name: "aWSB",
    symbol: "aWSB",
    slug: "awsb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/awsb.png",
  },
  {
    name: "Kalkulus",
    symbol: "KLKS",
    slug: "kalkulus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalkulus.png",
  },
  {
    name: "Spectrum",
    symbol: "SPT",
    slug: "spectrum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spectrum.png",
  },
  {
    name: "Yearn Finance Bit",
    symbol: "YFBT",
    slug: "yearn-finance-bit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance-bit.png",
  },
  {
    name: "X-Coin",
    symbol: "XCO",
    slug: "x-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-coin.png",
  },
  {
    name: "FUZE Token",
    symbol: "FUZE",
    slug: "fuze-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fuze-token.png",
  },
  {
    name: "Cream",
    symbol: "CRM",
    slug: "cream",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cream.png",
  },
  {
    name: "Centurion",
    symbol: "CNT",
    slug: "centurion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centurion.png",
  },
  {
    name: "Global Crypto Alliance",
    symbol: "CALL",
    slug: "global-crypto-alliance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-crypto-alliance.png",
  },
  {
    name: "Carebit",
    symbol: "CARE",
    slug: "carebit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carebit.png",
  },
  {
    name: "Bonpay",
    symbol: "BON",
    slug: "bonpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonpay.png",
  },
  {
    name: "Boolberry",
    symbol: "BBR",
    slug: "boolberry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boolberry.png",
  },
  {
    name: "DNotes",
    symbol: "NOTE",
    slug: "dnotes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dnotes.png",
  },
  {
    name: "UChain",
    symbol: "UCN",
    slug: "uchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uchain.png",
  },
  {
    name: "HashNet BitEco",
    symbol: "HNB",
    slug: "hashnet-biteco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashnet-biteco.png",
  },
  {
    name: "Desire",
    symbol: "DSR",
    slug: "desire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/desire.png",
  },
  {
    name: "noob.finance",
    symbol: "$NOOB",
    slug: "noob-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noob-finance.png",
  },
  {
    name: "LuckySevenToken",
    symbol: "LST",
    slug: "luckyseventoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luckyseventoken.png",
  },
  {
    name: "MarteXcoin",
    symbol: "MXT",
    slug: "martexcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/martexcoin.png",
  },
  {
    name: "Bancacy",
    symbol: "BNY",
    slug: "bancacy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bancacy.png",
  },
  {
    name: "Atheios",
    symbol: "ATH",
    slug: "atheios",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atheios.png",
  },
  {
    name: "Havy",
    symbol: "HAVY",
    slug: "havy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/havy.png",
  },
  {
    name: "Paypex",
    symbol: "PAYX",
    slug: "paypex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paypex.png",
  },
  {
    name: "TravelNote",
    symbol: "TVNT",
    slug: "travelnote",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/travelnote.png",
  },
  {
    name: "ParallelCoin",
    symbol: "DUO",
    slug: "parallelcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parallelcoin.png",
  },
  {
    name: "BitCoal",
    symbol: "COAL",
    slug: "bitcoal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoal.png",
  },
  {
    name: "Mobile Crypto Pay Coin",
    symbol: "MCPC",
    slug: "mobile-crypto-pay-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobile-crypto-pay-coin.png",
  },
  {
    name: "Horde",
    symbol: "HOR",
    slug: "horde",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/horde.png",
  },
  {
    name: "Aeryus",
    symbol: "AER",
    slug: "aeryus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aeryus.png",
  },
  {
    name: "Bitcoin 21",
    symbol: "XBTC21",
    slug: "bitcoin-21",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-21.png",
  },
  {
    name: "SCRIV NETWORK",
    symbol: "SCRIV",
    slug: "scriv-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scriv-network.png",
  },
  {
    name: "EDRCoin",
    symbol: "EDRC",
    slug: "edrcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edrcoin.png",
  },
  {
    name: "VEKTOR",
    symbol: "VEKTOR",
    slug: "vektor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vektor.png",
  },
  {
    name: "PosEx",
    symbol: "PEX",
    slug: "posex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/posex.png",
  },
  {
    name: "MedicCoin",
    symbol: "MEDIC",
    slug: "mediccoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mediccoin.png",
  },
  {
    name: "TEAM (TokenStars)",
    symbol: "TEAM",
    slug: "tokenstars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenstars.png",
  },
  {
    name: "Unify",
    symbol: "UNIFY",
    slug: "unify",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unify.png",
  },
  {
    name: "ebakus",
    symbol: "EBK",
    slug: "ebakus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ebakus.png",
  },
  {
    name: "Zealium",
    symbol: "NZL",
    slug: "zealium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zealium.png",
  },
  {
    name: "Ccore",
    symbol: "CCO",
    slug: "ccore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ccore.png",
  },
  {
    name: "BitGuild PLAT",
    symbol: "PLAT",
    slug: "bitguild-plat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitguild-plat.png",
  },
  {
    name: "Ether Kingdoms Token",
    symbol: "IMP",
    slug: "ether-kingdoms-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ether-kingdoms-token.png",
  },
  {
    name: "BowsCoin",
    symbol: "BSC",
    slug: "bowscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bowscoin.png",
  },
  {
    name: "ETHplode",
    symbol: "ETHPLO",
    slug: "ethplode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethplode.png",
  },
  {
    name: "Donu",
    symbol: "DONU",
    slug: "donu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/donu.png",
  },
  {
    name: "Mainstream For The Underground",
    symbol: "MFTU",
    slug: "mainstream-for-the-underground",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mainstream-for-the-underground.png",
  },
  {
    name: "Datawallet",
    symbol: "DXT",
    slug: "datawallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/datawallet.png",
  },
  {
    name: "MESEFA",
    symbol: "SEFA",
    slug: "mesefa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mesefa.png",
  },
  {
    name: "WinCash",
    symbol: "WCC",
    slug: "wincash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wincash.png",
  },
  {
    name: "Theresa May Coin",
    symbol: "MAY",
    slug: "theresa-may-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/theresa-may-coin.png",
  },
  {
    name: "Arion",
    symbol: "ARION",
    slug: "arion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arion.png",
  },
  {
    name: "Trexcoin",
    symbol: "TREX",
    slug: "trexcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trexcoin.png",
  },
  {
    name: "Narrative",
    symbol: "NRVE",
    slug: "narrative",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/narrative.png",
  },
  {
    name: "Natmin Pure Escrow",
    symbol: "NAT",
    slug: "natmin-pure-escrow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/natmin-pure-escrow.png",
  },
  {
    name: "Nyerium",
    symbol: "NYEX",
    slug: "nyerium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nyerium.png",
  },
  {
    name: "Cryptrust",
    symbol: "CTRT",
    slug: "cryptrust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptrust.png",
  },
  {
    name: "ZINC",
    symbol: "ZINC",
    slug: "zinc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zinc.png",
  },
  {
    name: "pulltherug.finance",
    symbol: "RUGZ",
    slug: "rugz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rugz.png",
  },
  {
    name: "Vortex Defi",
    symbol: "VTX",
    slug: "vortex-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vortex-defi.png",
  },
  {
    name: "ArbitrageCT",
    symbol: "ARCT",
    slug: "arbitragect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbitragect.png",
  },
  {
    name: "OptionRoom",
    symbol: "ROOM",
    slug: "optionroom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/optionroom.png",
  },
  {
    name: "Bitcoiin",
    symbol: "B2G",
    slug: "bitcoiin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoiin.png",
  },
  {
    name: "Ultragate",
    symbol: "ULG",
    slug: "ultragate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultragate.png",
  },
  {
    name: "OREO",
    symbol: "ORE",
    slug: "oreo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oreo.png",
  },
  {
    name: "Digital Money Bits",
    symbol: "DMB",
    slug: "digital-money-bits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-money-bits.png",
  },
  {
    name: "Global Currency Reserve",
    symbol: "GCR",
    slug: "global-currency-reserve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-currency-reserve.png",
  },
  {
    name: "ICOBID",
    symbol: "ICOB",
    slug: "icobid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icobid.png",
  },
  {
    name: "Onix",
    symbol: "ONX",
    slug: "onix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onix.png",
  },
  {
    name: "Decentralized Asset Trading Platform",
    symbol: "DATP",
    slug: "decentralized-asset-trading-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-asset-trading-platform.png",
  },
  {
    name: "Tourist Token",
    symbol: "TOTO",
    slug: "tourist-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tourist-token.png",
  },
  {
    name: "ParkByte",
    symbol: "PKB",
    slug: "parkbyte",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parkbyte.png",
  },
  {
    name: "XOVBank",
    symbol: "XOV",
    slug: "xovbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xovbank.png",
  },
  {
    name: "Zenswap Network Token",
    symbol: "ZNT",
    slug: "zenswap-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenswap-network-token.png",
  },
  {
    name: "KWHCoin",
    symbol: "KWH",
    slug: "kwhcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kwhcoin.png",
  },
  {
    name: "BoatPilot Token",
    symbol: "NAVY",
    slug: "boat-pilot-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boat-pilot-token.png",
  },
  {
    name: "Joint Ventures",
    symbol: "JOINT",
    slug: "joint-ventures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/joint-ventures.png",
  },
  {
    name: "FuzzBalls",
    symbol: "FUZZ",
    slug: "fuzzballs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fuzzballs.png",
  },
  {
    name: "Provoco Token",
    symbol: "VOCO",
    slug: "provoco-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/provoco-token.png",
  },
  {
    name: "PARETO Rewards",
    symbol: "PARETO",
    slug: "pareto-rewards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pareto-rewards.png",
  },
  {
    name: "PolypuX",
    symbol: "PUX",
    slug: "polypux",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polypux.png",
  },
  {
    name: "InterCrone",
    symbol: "ICR",
    slug: "intercrone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intercrone.png",
  },
  {
    name: "Streamit Coin",
    symbol: "STREAM",
    slug: "streamit-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/streamit-coin.png",
  },
  {
    name: "GlobalToken",
    symbol: "GLT",
    slug: "globaltoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/globaltoken.png",
  },
  {
    name: "FirstCoin",
    symbol: "FRST",
    slug: "firstcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firstcoin.png",
  },
  {
    name: "PureVidz",
    symbol: "VIDZ",
    slug: "purevidz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/purevidz.png",
  },
  {
    name: "Arepacoin",
    symbol: "AREPA",
    slug: "arepacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arepacoin.png",
  },
  {
    name: "Scanetchain",
    symbol: "SWC",
    slug: "scanetchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scanetchain.png",
  },
  {
    name: "TriumphX",
    symbol: "TRIX",
    slug: "triumphx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/triumphx.png",
  },
  {
    name: "PostCoin",
    symbol: "POST",
    slug: "postcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/postcoin.png",
  },
  {
    name: "Yield Stake Finance",
    symbol: "YI12",
    slug: "yield-stake-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-stake-finance.png",
  },
  {
    name: "BTC Lite",
    symbol: "BTCL",
    slug: "btc-lite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/btc-lite.png",
  },
  {
    name: "VoteCoin",
    symbol: "VOT",
    slug: "votecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/votecoin.png",
  },
  {
    name: "STRAKS",
    symbol: "STAK",
    slug: "straks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/straks.png",
  },
  {
    name: "Biotron",
    symbol: "BTRN",
    slug: "biotron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biotron.png",
  },
  {
    name: "XPA",
    symbol: "XPA",
    slug: "xpa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xpa.png",
  },
  {
    name: "Webflix Token",
    symbol: "WFX",
    slug: "webflix-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/webflix-token.png",
  },
  {
    name: "Plus-Coin",
    symbol: "NPLC",
    slug: "plus-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plus-coin.png",
  },
  {
    name: "Aigang",
    symbol: "AIX",
    slug: "aigang",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aigang.png",
  },
  {
    name: "Micromines",
    symbol: "MICRO",
    slug: "micromines",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/micromines.png",
  },
  {
    name: "4NEW",
    symbol: "KWATT",
    slug: "4new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/4new.png",
  },
  {
    name: "Datarius Credit",
    symbol: "DTRC",
    slug: "datarius-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/datarius-credit.png",
  },
  {
    name: "Fivebalance",
    symbol: "FBN",
    slug: "fivebalance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fivebalance.png",
  },
  {
    name: "Ouroboros",
    symbol: "OURO",
    slug: "ouroboros",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ouroboros.png",
  },
  {
    name: "Blockburn",
    symbol: "BURN",
    slug: "blockburn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockburn.png",
  },
  {
    name: "Martkist",
    symbol: "MARTK",
    slug: "martkist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/martkist.png",
  },
  {
    name: "FantasyGold",
    symbol: "FGC",
    slug: "fantasygold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasygold.png",
  },
  {
    name: "ZeusNetwork",
    symbol: "ZEUS",
    slug: "zeusnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeusnetwork.png",
  },
  {
    name: "MultiCoinCasino",
    symbol: "MCC",
    slug: "multicoincasino",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multicoincasino.png",
  },
  {
    name: "BSClaunch",
    symbol: "BSL",
    slug: "bsclaunch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsclaunch.png",
  },
  {
    name: "Thore Cash",
    symbol: "TCH",
    slug: "thore-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thore-cash.png",
  },
  {
    name: "EvenCoin",
    symbol: "EVN",
    slug: "evencoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evencoin.png",
  },
  {
    name: "Suretly",
    symbol: "SUR",
    slug: "suretly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/suretly.png",
  },
  {
    name: "The Currency Analytics",
    symbol: "TCAT",
    slug: "the-currency-analytics",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-currency-analytics.png",
  },
  {
    name: "BERNcash",
    symbol: "BERN",
    slug: "berncash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/berncash.png",
  },
  {
    name: "Thingschain",
    symbol: "TIC",
    slug: "thingschain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thingschain.png",
  },
  {
    name: "AceD",
    symbol: "ACED",
    slug: "aced",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aced.png",
  },
  {
    name: "Raise",
    symbol: "RAISE",
    slug: "raise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raise.png",
  },
  {
    name: "DraftCoin",
    symbol: "DFT",
    slug: "draftcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/draftcoin.png",
  },
  {
    name: "Bitcoin CZ",
    symbol: "BCZ",
    slug: "bitcoin-cz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-cz.png",
  },
  {
    name: "Digiwage",
    symbol: "WAGE",
    slug: "digiwage",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digiwage.png",
  },
  {
    name: "MoneroV ",
    symbol: "XMV",
    slug: "monero-v",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monero-v.png",
  },
  {
    name: "Bitradio",
    symbol: "BRO",
    slug: "bitradio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitradio.png",
  },
  {
    name: "PengolinCoin",
    symbol: "PGO",
    slug: "pengolincoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pengolincoin.png",
  },
  {
    name: "Worldcore",
    symbol: "WRC",
    slug: "worldcore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/worldcore.png",
  },
  {
    name: "StaysBASE",
    symbol: "SBS",
    slug: "staysbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/staysbase.png",
  },
  {
    name: "Kebab Token",
    symbol: "KEBAB",
    slug: "kebab-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kebab-token.png",
  },
  {
    name: "CPUchain",
    symbol: "CPU",
    slug: "cpuchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cpuchain.png",
  },
  {
    name: "Arionum",
    symbol: "ARO",
    slug: "arionum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arionum.png",
  },
  {
    name: "StarterCoin",
    symbol: "STAC",
    slug: "startercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/startercoin.png",
  },
  {
    name: "Webcoin",
    symbol: "WEB",
    slug: "webcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/webcoin.png",
  },
  {
    name: "SF Capital",
    symbol: "SFCP",
    slug: "sf-capital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sf-capital.png",
  },
  {
    name: "Grimm",
    symbol: "GRIMM",
    slug: "grimm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grimm.png",
  },
  {
    name: "KUN",
    symbol: "KUN",
    slug: "kun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kun.png",
  },
  {
    name: "Crowd Machine",
    symbol: "CMCT",
    slug: "crowd-machine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crowd-machine.png",
  },
  {
    name: "Levante U.D. Fan Token",
    symbol: "LEV",
    slug: "levante-ud-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/levante-ud-fan-token.png",
  },
  {
    name: "GenesisX",
    symbol: "XGS",
    slug: "genesisx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesisx.png",
  },
  {
    name: "Okschain",
    symbol: "OKS",
    slug: "okschain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okschain.png",
  },
  {
    name: "Asura Coin",
    symbol: "ASA",
    slug: "asura-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asura-coin.png",
  },
  {
    name: "Panda Yield",
    symbol: "BBOO",
    slug: "panda-yield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/panda-yield.png",
  },
  {
    name: "YFIVE FINANCE",
    symbol: "YFIVE",
    slug: "yfive-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfive-finance.png",
  },
  {
    name: "EnterCoin",
    symbol: "ENTRC",
    slug: "entercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/entercoin.png",
  },
  {
    name: "Pyrk",
    symbol: "PYRK",
    slug: "pyrk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyrk.png",
  },
  {
    name: "Carboneum [C8] Token",
    symbol: "C8",
    slug: "carboneum-c8-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carboneum-c8-token.png",
  },
  {
    name: "Bitcoin Classic",
    symbol: "BXC",
    slug: "bitcoin-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-classic.png",
  },
  {
    name: "MojoCoin",
    symbol: "MOJO",
    slug: "mojocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mojocoin.png",
  },
  {
    name: "ELTCOIN",
    symbol: "ELTCOIN",
    slug: "eltcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eltcoin.png",
  },
  {
    name: "Rupee",
    symbol: "RUP",
    slug: "rupee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rupee.png",
  },
  {
    name: "Fox Trading",
    symbol: "FOXT",
    slug: "fox-trading",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fox-trading.png",
  },
  {
    name: "BitcoiNote",
    symbol: "BTCN",
    slug: "bitcoinote",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinote.png",
  },
  {
    name: "SteepCoin",
    symbol: "STEEP",
    slug: "steepcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steepcoin.png",
  },
  {
    name: "Bitcoin Adult",
    symbol: "BTAD",
    slug: "bitcoin-adult",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-adult.png",
  },
  {
    name: "imbrex",
    symbol: "REX",
    slug: "imbrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imbrex.png",
  },
  {
    name: "Commercium",
    symbol: "CMM",
    slug: "commercium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/commercium.png",
  },
  {
    name: "Eroscoin",
    symbol: "ERO",
    slug: "eroscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eroscoin.png",
  },
  {
    name: "Rubies",
    symbol: "RBIES",
    slug: "rubies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rubies.png",
  },
  {
    name: "Xuez",
    symbol: "XUEZ",
    slug: "xuez",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xuez.png",
  },
  {
    name: "SynLev",
    symbol: "SYN",
    slug: "synlev",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synlev.png",
  },
  {
    name: "EUNOMIA",
    symbol: "ENTS",
    slug: "eunomia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eunomia.png",
  },
  {
    name: "CyberMusic",
    symbol: "CYMT",
    slug: "cybermusic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cybermusic.png",
  },
  {
    name: "ShowHand",
    symbol: "HAND",
    slug: "showhand",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/showhand.png",
  },
  {
    name: "Dreamcoin",
    symbol: "DRM",
    slug: "dreamcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dreamcoin.png",
  },
  {
    name: "Kingdom Game 4.0",
    symbol: "KDG",
    slug: "kingdom-game-4",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kingdom-game-4.png",
  },
  {
    name: "Pirl",
    symbol: "PIRL",
    slug: "pirl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pirl.png",
  },
  {
    name: "Uptrennd",
    symbol: "1UP",
    slug: "uptrennd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uptrennd.png",
  },
  {
    name: "TouchCon",
    symbol: "TOC",
    slug: "touchcon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/touchcon.png",
  },
  {
    name: "NevaCoin",
    symbol: "NEVA",
    slug: "nevacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nevacoin.png",
  },
  {
    name: "CBDAO",
    symbol: "BREE",
    slug: "cbdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cbdao.png",
  },
  {
    name: "BBSCoin",
    symbol: "BBS",
    slug: "bbscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bbscoin.png",
  },
  {
    name: "Privatix",
    symbol: "PRIX",
    slug: "privatix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/privatix.png",
  },
  {
    name: "Rigel Finance",
    symbol: "RIGEL",
    slug: "rigel-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rigel-finance.png",
  },
  {
    name: "Veles",
    symbol: "VLS",
    slug: "veles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veles.png",
  },
  {
    name: "SovranoCoin",
    symbol: "SVR",
    slug: "sovranocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sovranocoin.png",
  },
  {
    name: "Simple Software Solutions",
    symbol: "SSS",
    slug: "simple-software-solutions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/simple-software-solutions.png",
  },
  {
    name: "Baguette Token",
    symbol: "BGTT",
    slug: "baguette-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baguette-token.png",
  },
  {
    name: "GoldBlocks",
    symbol: "GB",
    slug: "goldblocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldblocks.png",
  },
  {
    name: "YFFII Finance",
    symbol: "YFFII",
    slug: "yffii-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yffii-finance.png",
  },
  {
    name: "IFX24",
    symbol: "IFX24",
    slug: "ifx24",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ifx24.png",
  },
  {
    name: "SafeCapital",
    symbol: "SCAP",
    slug: "safecapital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safecapital.png",
  },
  {
    name: "POPCHAIN",
    symbol: "PCH",
    slug: "popchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/popchain.png",
  },
  {
    name: "AiLink Token",
    symbol: "ALI",
    slug: "ailink-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ailink-token.png",
  },
  {
    name: "GCN Coin",
    symbol: "GCN",
    slug: "gcn-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gcn-coin.png",
  },
  {
    name: "Bitzeny",
    symbol: "ZNY",
    slug: "bitzeny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitzeny.png",
  },
  {
    name: "Impleum",
    symbol: "IMPL",
    slug: "impleum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/impleum.png",
  },
  {
    name: "Gentarium",
    symbol: "GTM",
    slug: "gentarium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gentarium.png",
  },
  {
    name: "WeBlock",
    symbol: "WON",
    slug: "weblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/weblock.png",
  },
  {
    name: "Coin2.1",
    symbol: "C2",
    slug: "coin2-1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin2-1.png",
  },
  {
    name: "UNICORN Token",
    symbol: "UNI",
    slug: "unicorn-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicorn-token.png",
  },
  {
    name: "StrongHands Masternode",
    symbol: "SHMN",
    slug: "stronghands-masternode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stronghands-masternode.png",
  },
  {
    name: "Ellaism",
    symbol: "ELLA",
    slug: "ellaism",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ellaism.png",
  },
  {
    name: "Truegame",
    symbol: "TGAME",
    slug: "tgame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tgame.png",
  },
  {
    name: "Bitcoin Incognito",
    symbol: "XBI",
    slug: "bitcoin-incognito",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-incognito.png",
  },
  {
    name: "Liquidity Network",
    symbol: "LQD",
    slug: "liquidity-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquidity-network.png",
  },
  {
    name: "Tutti Frutti",
    symbol: "TFF",
    slug: "tutti-frutti",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tutti-frutti.png",
  },
  {
    name: "BitWhite",
    symbol: "BTW",
    slug: "bitwhite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitwhite.png",
  },
  {
    name: "Unicly Genesis Collection",
    symbol: "UUNICLY",
    slug: "unicly-genesis-collection",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicly-genesis-collection.png",
  },
  {
    name: "Ratecoin",
    symbol: "XRA",
    slug: "ratecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ratecoin.png",
  },
  {
    name: "INRToken",
    symbol: "INRT",
    slug: "inrtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inrtoken.png",
  },
  {
    name: "Waletoken",
    symbol: "WTN",
    slug: "waletoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waletoken.png",
  },
  {
    name: "Italian Lira",
    symbol: "ITL",
    slug: "italian-lira",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/italian-lira.png",
  },
  {
    name: "Herbalist Token",
    symbol: "HERB",
    slug: "herbalist-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herbalist-token.png",
  },
  {
    name: "SiaCashCoin",
    symbol: "SCC",
    slug: "siacashcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/siacashcoin.png",
  },
  {
    name: "BitSend",
    symbol: "BSD",
    slug: "bitsend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitsend.png",
  },
  {
    name: "Koi Network",
    symbol: "KOI",
    slug: "koi-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koi-network.png",
  },
  {
    name: "ATBCoin",
    symbol: "ATB",
    slug: "atbcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atbcoin.png",
  },
  {
    name: "PAYCENT",
    symbol: "PYN",
    slug: "paycent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paycent.png",
  },
  {
    name: "Neural Protocol",
    symbol: "NRP",
    slug: "neural-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neural-protocol.png",
  },
  {
    name: "PopularCoin",
    symbol: "POP",
    slug: "popularcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/popularcoin.png",
  },
  {
    name: "Bintex Futures",
    symbol: "BNTX",
    slug: "bintex-futures",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bintex-futures.png",
  },
  {
    name: "Fireball",
    symbol: "FIRE",
    slug: "fireball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fireball.png",
  },
  {
    name: "Connectome",
    symbol: "CNTM",
    slug: "connectome",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/connectome.png",
  },
  {
    name: "HakunaMatata",
    symbol: "TATA",
    slug: "hakunamatata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hakunamatata.png",
  },
  {
    name: "PayCoin",
    symbol: "XPY",
    slug: "paycoin2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paycoin2.png",
  },
  {
    name: "TagCoin",
    symbol: "TAG",
    slug: "tagcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tagcoin.png",
  },
  {
    name: "Quoxent",
    symbol: "QUO",
    slug: "quoxent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quoxent.png",
  },
  {
    name: "Universal Currency",
    symbol: "UNIT",
    slug: "universal-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/universal-currency.png",
  },
  {
    name: "Bitcoin Interest",
    symbol: "BCI",
    slug: "bitcoin-interest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-interest.png",
  },
  {
    name: "ROAD",
    symbol: "ROAD",
    slug: "road",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/road.png",
  },
  {
    name: "JUIICE",
    symbol: "JUI",
    slug: "juiice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/juiice.png",
  },
  {
    name: "GeyserCoin",
    symbol: "GSR",
    slug: "geysercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geysercoin.png",
  },
  {
    name: "Ruler Protocol",
    symbol: "RULER",
    slug: "ruler-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ruler-protocol.png",
  },
  {
    name: "Bolivarcoin",
    symbol: "BOLI",
    slug: "bolivarcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bolivarcoin.png",
  },
  {
    name: "Florin",
    symbol: "XFL",
    slug: "florin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/florin.png",
  },
  {
    name: "Insight Protocol",
    symbol: "INX",
    slug: "insight-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insight-protocol.png",
  },
  {
    name: "Aditus",
    symbol: "ADI",
    slug: "aditus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aditus.png",
  },
  {
    name: "Bigbom",
    symbol: "BBO",
    slug: "bigbom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bigbom.png",
  },
  {
    name: "Photon",
    symbol: "PHO",
    slug: "photon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/photon.png",
  },
  {
    name: "Defis",
    symbol: "XGM",
    slug: "defis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defis.png",
  },
  {
    name: "Heron Asia",
    symbol: "HERON",
    slug: "heron-asia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heron-asia.png",
  },
  {
    name: "Adzcoin",
    symbol: "ADZ",
    slug: "adzcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adzcoin.png",
  },
  {
    name: "MEXC Token",
    symbol: "MEXC",
    slug: "mexc-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mexc-token.png",
  },
  {
    name: "Merebel",
    symbol: "MERI",
    slug: "merebel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merebel.png",
  },
  {
    name: "Helix",
    symbol: "HLIX",
    slug: "helix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helix.png",
  },
  {
    name: "SHIELD",
    symbol: "XSH",
    slug: "shield-xsh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shield-xsh.png",
  },
  {
    name: "More Coin",
    symbol: "MORE",
    slug: "more-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/more-coin.png",
  },
  {
    name: "Matrexcoin",
    symbol: "MAC",
    slug: "matrexcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrexcoin.png",
  },
  {
    name: "Wrapped Virgin Gen-0 CryptoKitties",
    symbol: "WVG0",
    slug: "wrapped-virgin-gen-0-cryptokitties",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-virgin-gen-0-cryptokitties.png",
  },
  {
    name: "MFCoin",
    symbol: "MFC",
    slug: "mfcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mfcoin.png",
  },
  {
    name: "YENTEN",
    symbol: "YTN",
    slug: "yenten",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yenten.png",
  },
  {
    name: "Fantasy Sports",
    symbol: "DFS",
    slug: "fantasy-sports",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantasy-sports.png",
  },
  {
    name: "Semux",
    symbol: "SEM",
    slug: "semux",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/semux.png",
  },
  {
    name: "SuperCoin",
    symbol: "SUPER",
    slug: "supercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/supercoin.png",
  },
  {
    name: "Mchain",
    symbol: "MAR",
    slug: "mchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mchain.png",
  },
  {
    name: "Fortuna",
    symbol: "FOTA",
    slug: "fortuna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fortuna.png",
  },
  {
    name: "ICE ROCK MINING",
    symbol: "ROCK2",
    slug: "ice-rock-mining",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ice-rock-mining.png",
  },
  {
    name: "Litecoin Plus",
    symbol: "LCP",
    slug: "litecoin-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litecoin-plus.png",
  },
  {
    name: "NPCoin",
    symbol: "NPC",
    slug: "npcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/npcoin.png",
  },
  {
    name: "Kind Ads Token",
    symbol: "KIND",
    slug: "kind-ads-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kind-ads-token.png",
  },
  {
    name: "WABnetwork",
    symbol: "WAB",
    slug: "wabnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wabnetwork.png",
  },
  {
    name: "Renewable Electronic Energy Coin",
    symbol: "REEC",
    slug: "electronic-energy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electronic-energy-coin.png",
  },
  {
    name: "BidiPass",
    symbol: "BDP",
    slug: "bidipass",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bidipass.png",
  },
  {
    name: "BlockMesh",
    symbol: "BMH",
    slug: "blockmesh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockmesh.png",
  },
  {
    name: "Block-Logic",
    symbol: "BLTG",
    slug: "block-logic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/block-logic.png",
  },
  {
    name: "GuccioneCoin",
    symbol: "GCC",
    slug: "guccionecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guccionecoin.png",
  },
  {
    name: "PiplCoin",
    symbol: "PIPL",
    slug: "piplcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piplcoin.png",
  },
  {
    name: "Cash Tech",
    symbol: "CATE",
    slug: "cash-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cash-tech.png",
  },
  {
    name: "Nasdacoin",
    symbol: "NSD",
    slug: "nasdacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nasdacoin.png",
  },
  {
    name: "Energycoin",
    symbol: "ENRG",
    slug: "energycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energycoin.png",
  },
  {
    name: "ZUM TOKEN",
    symbol: "ZUM",
    slug: "zum-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zum-token.png",
  },
  {
    name: "Bata",
    symbol: "BTA",
    slug: "bata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bata.png",
  },
  {
    name: "Meridian Network",
    symbol: "LOCK",
    slug: "meridian-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meridian-network.png",
  },
  {
    name: "Alchemint Standards",
    symbol: "SDS",
    slug: "alchemint-standards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alchemint-standards.png",
  },
  {
    name: "Knekted",
    symbol: "KNT",
    slug: "knekted",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knekted.png",
  },
  {
    name: "rASKO",
    symbol: "RASKO",
    slug: "rasko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rasko.png",
  },
  {
    name: "Rimbit",
    symbol: "RBT",
    slug: "rimbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rimbit.png",
  },
  {
    name: "Fire Lotto",
    symbol: "FLOT",
    slug: "fire-lotto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fire-lotto.png",
  },
  {
    name: "Cheesecoin",
    symbol: "CHEESE",
    slug: "cheesecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheesecoin.png",
  },
  {
    name: "ModiHost",
    symbol: "AIM",
    slug: "modihost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/modihost.png",
  },
  {
    name: "ProxyNode",
    symbol: "PRX",
    slug: "proxynode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proxynode.png",
  },
  {
    name: "EtherInc",
    symbol: "ETI",
    slug: "etherinc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherinc.png",
  },
  {
    name: "Ethereum Gold Project",
    symbol: "ETGP",
    slug: "ethereum-gold-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-gold-project.png",
  },
  {
    name: "Transcodium",
    symbol: "TNS",
    slug: "transcodium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/transcodium.png",
  },
  {
    name: "BLOC.MONEY",
    symbol: "BLOC",
    slug: "bloc-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bloc-money.png",
  },
  {
    name: "HeartBout",
    symbol: "HB",
    slug: "heartbout",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heartbout.png",
  },
  {
    name: "Opal",
    symbol: "OPAL",
    slug: "opal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opal.png",
  },
  {
    name: "Cyber Movie Chain",
    symbol: "CMCT",
    slug: "cyber-movie-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyber-movie-chain.png",
  },
  {
    name: "BitBlocks Finance",
    symbol: "BBKFI",
    slug: "bitblocks-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitblocks-finance.png",
  },
  {
    name: "Elementeum",
    symbol: "ELET",
    slug: "elementeum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elementeum.png",
  },
  {
    name: "Chronologic",
    symbol: "DAY",
    slug: "chronologic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chronologic.png",
  },
  {
    name: "GoNetwork",
    symbol: "GOT",
    slug: "gonetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gonetwork.png",
  },
  {
    name: "FLIP",
    symbol: "FLP",
    slug: "flip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flip.png",
  },
  {
    name: "Dynamite",
    symbol: "DYNMT",
    slug: "dynamite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dynamite.png",
  },
  {
    name: "Neutron",
    symbol: "NTRN",
    slug: "neutron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neutron.png",
  },
  {
    name: "Evil Coin",
    symbol: "EVIL",
    slug: "evil-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evil-coin.png",
  },
  {
    name: "Samusky",
    symbol: "SAMU",
    slug: "samusky",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/samusky.png",
  },
  {
    name: "Civitas",
    symbol: "CIV",
    slug: "civitas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/civitas.png",
  },
  {
    name: "SkinCoin",
    symbol: "SKIN",
    slug: "skincoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skincoin.png",
  },
  {
    name: "ITO Utility Token",
    symbol: "IUT",
    slug: "ito-utility-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ito-utility-token.png",
  },
  {
    name: "1MillionNFTs",
    symbol: "1MIL",
    slug: "1millionnfts",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1millionnfts.png",
  },
  {
    name: "CustomContractNetwork",
    symbol: "CCN",
    slug: "customcontractnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/customcontractnetwork.png",
  },
  {
    name: "Cryptojacks",
    symbol: "CJ",
    slug: "cryptojacks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptojacks.png",
  },
  {
    name: "Cobinhood",
    symbol: "COB",
    slug: "cobinhood",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cobinhood.png",
  },
  {
    name: "MicroMoney",
    symbol: "AMM",
    slug: "micromoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/micromoney.png",
  },
  {
    name: "Thrive Token",
    symbol: "THRT",
    slug: "thrive-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thrive-token.png",
  },
  {
    name: "LHT",
    symbol: "LHT",
    slug: "lighthouse-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lighthouse-token.png",
  },
  {
    name: "Taklimakan Network",
    symbol: "TAN",
    slug: "taklimakan-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taklimakan-network.png",
  },
  {
    name: "Gravity",
    symbol: "GZRO",
    slug: "gravity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gravity.png",
  },
  {
    name: "IONChain",
    symbol: "IONC",
    slug: "ionchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ionchain.png",
  },
  {
    name: "Playgroundz",
    symbol: "IOG",
    slug: "playgroundz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playgroundz.png",
  },
  {
    name: "NFX Coin",
    symbol: "NFXC",
    slug: "nfx-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nfx-coin.png",
  },
  {
    name: "Bitcoin Token",
    symbol: "BTCT",
    slug: "bitcoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-token.png",
  },
  {
    name: "Castweet",
    symbol: "CTT",
    slug: "castweet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/castweet.png",
  },
  {
    name: "Switch",
    symbol: "ESH",
    slug: "switch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/switch.png",
  },
  {
    name: "Bitgesell",
    symbol: "BGL",
    slug: "bitgesell",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitgesell.png",
  },
  {
    name: "wave edu coin",
    symbol: "WEC",
    slug: "wave-edu-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wave-edu-coin.png",
  },
  {
    name: "TRAXIA",
    symbol: "TM2",
    slug: "traxia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/traxia.png",
  },
  {
    name: "Ubricoin",
    symbol: "UBN",
    slug: "ubricoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubricoin.png",
  },
  {
    name: "Ormeus Cash",
    symbol: "OMC",
    slug: "ormeus-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ormeus-cash.png",
  },
  {
    name: "Cipher",
    symbol: "CPR",
    slug: "cipher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cipher.png",
  },
  {
    name: "Roush Fenway Racing Fan Token",
    symbol: "ROUSH",
    slug: "roush-fenway-racing-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roush-fenway-racing-fan-token.png",
  },
  {
    name: "Playkey",
    symbol: "PKT",
    slug: "playkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playkey.png",
  },
  {
    name: "Bela",
    symbol: "BELA",
    slug: "belacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/belacoin.png",
  },
  {
    name: "CryptoEnergy",
    symbol: "CNRG",
    slug: "cryptoenergy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoenergy.png",
  },
  {
    name: "EXMR FDN",
    symbol: "EXMR",
    slug: "exmr-fdn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exmr-fdn.png",
  },
  {
    name: "Bitnation",
    symbol: "XPAT",
    slug: "bitnation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitnation.png",
  },
  {
    name: "SafeInsure",
    symbol: "SINS",
    slug: "safeinsure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safeinsure.png",
  },
  {
    name: "OptiToken",
    symbol: "OPTI",
    slug: "optitoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/optitoken.png",
  },
  {
    name: "Felixo Coin",
    symbol: "FLX",
    slug: "felixo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/felixo-coin.png",
  },
  {
    name: "Stabilize",
    symbol: "STBZ",
    slug: "stabilize",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stabilize.png",
  },
  {
    name: "Elysian",
    symbol: "ELY",
    slug: "elysian",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elysian.png",
  },
  {
    name: "Vidulum",
    symbol: "VDL",
    slug: "vidulum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vidulum.png",
  },
  {
    name: "Scrypta",
    symbol: "LYRA",
    slug: "scrypta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scrypta.png",
  },
  {
    name: "Cubiex Power",
    symbol: "CBIX-P",
    slug: "cubiex-power",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cubiex-power.png",
  },
  {
    name: "Spaceswap SHAKE",
    symbol: "SHAKE",
    slug: "shake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shake.png",
  },
  {
    name: "Lition",
    symbol: "LIT",
    slug: "lition",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lition.png",
  },
  {
    name: "megaBONK",
    symbol: "MBONK",
    slug: "bonk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonk.png",
  },
  {
    name: "MORPHOSE",
    symbol: "MORPH",
    slug: "morphose",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morphose.png",
  },
  {
    name: "FUD.finance",
    symbol: "FUD",
    slug: "fudfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fudfinance.png",
  },
  {
    name: "Yfscience",
    symbol: "YFSI",
    slug: "yfscience",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfscience.png",
  },
  {
    name: "Deutsche eMark",
    symbol: "DEM",
    slug: "deutsche-emark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deutsche-emark.png",
  },
  {
    name: "UBU Finance",
    symbol: "UBU",
    slug: "ubu-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubu-finance.png",
  },
  {
    name: "Escroco Emerald",
    symbol: "ESCE",
    slug: "escroco-emerald",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/escroco-emerald.png",
  },
  {
    name: "FSBT API Token",
    symbol: "FSBT",
    slug: "fsbt-api-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fsbt-api-token.png",
  },
  {
    name: "Blocktix",
    symbol: "TIX",
    slug: "blocktix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocktix.png",
  },
  {
    name: "PYRO Network",
    symbol: "PYRO",
    slug: "pyro-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pyro-network.png",
  },
  {
    name: "Creo Engine",
    symbol: "CREO",
    slug: "creo-engine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creo-engine.png",
  },
  {
    name: "IQ.cash",
    symbol: "IQ",
    slug: "iqcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iqcash.png",
  },
  {
    name: "Beetle Coin",
    symbol: "BEET",
    slug: "beetle-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beetle-coin.png",
  },
  {
    name: "GoldFund",
    symbol: "GFUN",
    slug: "goldfund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldfund.png",
  },
  {
    name: "Kurrent",
    symbol: "KURT",
    slug: "kurrent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kurrent.png",
  },
  {
    name: "Parkgene",
    symbol: "GENE",
    slug: "parkgene",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parkgene.png",
  },
  {
    name: "ZelaaPayAE",
    symbol: "ZPAE",
    slug: "zelaapayae",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zelaapayae.png",
  },
  {
    name: "Kora Network Token",
    symbol: "KNT",
    slug: "kora-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kora-network-token.png",
  },
  {
    name: "W Green Pay",
    symbol: "WGP",
    slug: "w-green-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/w-green-pay.png",
  },
  {
    name: "EthereumX",
    symbol: "ETX",
    slug: "ethereumx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereumx.png",
  },
  {
    name: "Thisoption",
    symbol: "TONS",
    slug: "thisoption",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thisoption.png",
  },
  {
    name: "VouchForMe",
    symbol: "IPL",
    slug: "insurepal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insurepal.png",
  },
  {
    name: "Decentralized Crypto Token",
    symbol: "DCTO",
    slug: "decentralized-crypto-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-crypto-token.png",
  },
  {
    name: "Neuromorphic.io",
    symbol: "NMP",
    slug: "neuromorphic-io",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neuromorphic-io.png",
  },
  {
    name: "2GIVE",
    symbol: "2GIVE",
    slug: "2give",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2give.png",
  },
  {
    name: "KnoxFS (new)",
    symbol: "KFX",
    slug: "knoxfs-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/knoxfs-new.png",
  },
  {
    name: "Bridge Protocol",
    symbol: "BRDG",
    slug: "bridge-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bridge-protocol.png",
  },
  {
    name: "Decentralized Machine Learning",
    symbol: "DML",
    slug: "decentralized-machine-learning",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-machine-learning.png",
  },
  {
    name: "Iridium",
    symbol: "IRD",
    slug: "iridium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iridium.png",
  },
  {
    name: "DEX",
    symbol: "DEX",
    slug: "dex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dex.png",
  },
  {
    name: "MorCrypto Coin",
    symbol: "MOR",
    slug: "morcrypto-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morcrypto-coin.png",
  },
  {
    name: "Sentinel Chain",
    symbol: "SENC",
    slug: "sentinel-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sentinel-chain.png",
  },
  {
    name: "MODEL-X-coin",
    symbol: "MODX",
    slug: "model-x-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/model-x-coin.png",
  },
  {
    name: "PEPS Coin",
    symbol: "PEPS",
    slug: "peps-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peps-coin.png",
  },
  {
    name: "Rublix",
    symbol: "RBLX",
    slug: "rublix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rublix.png",
  },
  {
    name: "TCASH",
    symbol: "TCASH",
    slug: "tcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tcash.png",
  },
  {
    name: "HeroNode",
    symbol: "HER",
    slug: "heronode",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heronode.png",
  },
  {
    name: "Agora",
    symbol: "VOTE",
    slug: "agora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agora.png",
  },
  {
    name: "GoldMint",
    symbol: "MNTP",
    slug: "goldmint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldmint.png",
  },
  {
    name: "BZEdge",
    symbol: "BZE",
    slug: "bzedge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bzedge.png",
  },
  {
    name: "Kush Finance",
    symbol: "KSEED",
    slug: "kush-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kush-finance.png",
  },
  {
    name: "AC3",
    symbol: "AC3",
    slug: "ac3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ac3.png",
  },
  {
    name: "Graft",
    symbol: "GRFT",
    slug: "graft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/graft.png",
  },
  {
    name: "Bitether",
    symbol: "BTR",
    slug: "bitether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitether.png",
  },
  {
    name: "3DCoin",
    symbol: "3DC",
    slug: "3dcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/3dcoin.png",
  },
  {
    name: "Gems ",
    symbol: "GEM",
    slug: "gems-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gems-protocol.png",
  },
  {
    name: "EveriToken",
    symbol: "EVT",
    slug: "everitoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everitoken.png",
  },
  {
    name: "Emerald Crypto",
    symbol: "EMD",
    slug: "emerald",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emerald.png",
  },
  {
    name: "InvestFeed",
    symbol: "IFT",
    slug: "investfeed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/investfeed.png",
  },
  {
    name: "WandX",
    symbol: "WAND",
    slug: "wandx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wandx.png",
  },
  {
    name: "Datamine FLUX",
    symbol: "FLUX",
    slug: "flux",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flux.png",
  },
  {
    name: "SwiftCash",
    symbol: "SWIFT",
    slug: "swiftcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swiftcash.png",
  },
  {
    name: "Dinamo Zagreb Fan Token",
    symbol: "DZG",
    slug: "dinamo-zagreb-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dinamo-zagreb-fan-token.png",
  },
  {
    name: "Tokenbox",
    symbol: "TBX",
    slug: "tokenbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenbox.png",
  },
  {
    name: "InsaneCoin",
    symbol: "INSN",
    slug: "insanecoin-insn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insanecoin-insn.png",
  },
  {
    name: "Zeusshield",
    symbol: "ZSC",
    slug: "zeusshield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeusshield.png",
  },
  {
    name: "PKG Token",
    symbol: "PKG",
    slug: "pkg-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pkg-token.png",
  },
  {
    name: "Scorum Coins",
    symbol: "SCR",
    slug: "scorum-coins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scorum-coins.png",
  },
  {
    name: "LunchMoney",
    symbol: "LMY",
    slug: "lunchmoney",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunchmoney.png",
  },
  {
    name: "Bounty0x",
    symbol: "BNTY",
    slug: "bounty0x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bounty0x.png",
  },
  {
    name: "Ethereum Gold",
    symbol: "ETG",
    slug: "ethereum-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-gold.png",
  },
  {
    name: "Unicly Doki Doki Collection",
    symbol: "UDOKI",
    slug: "unicly-doki-doki-collection",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicly-doki-doki-collection.png",
  },
  {
    name: "Rivetz",
    symbol: "RVT",
    slug: "rivetz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rivetz.png",
  },
  {
    name: "Polkally",
    symbol: "KALLY",
    slug: "polkally",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkally.png",
  },
  {
    name: "Fesschain",
    symbol: "FESS",
    slug: "fesschain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fesschain.png",
  },
  {
    name: "UpToken",
    symbol: "UP",
    slug: "uptoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uptoken.png",
  },
  {
    name: "Rentberry",
    symbol: "BERRY",
    slug: "rentberry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rentberry.png",
  },
  {
    name: "Axe",
    symbol: "AXE",
    slug: "axe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axe.png",
  },
  {
    name: "Vox.Finance",
    symbol: "VOX",
    slug: "vox-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vox-finance.png",
  },
  {
    name: "CROAT",
    symbol: "CROAT",
    slug: "croat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/croat.png",
  },
  {
    name: "DeFinition",
    symbol: "DZI",
    slug: "definition",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/definition.png",
  },
  {
    name: "Color Platform",
    symbol: "CLR",
    slug: "color-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/color-platform.png",
  },
  {
    name: "HyperAlloy",
    symbol: "ALLOY",
    slug: "hyper-alloy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyper-alloy.png",
  },
  {
    name: "Anoncoin",
    symbol: "ANC",
    slug: "anoncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anoncoin.png",
  },
  {
    name: "MemeCoin Factory",
    symbol: "FACTORY",
    slug: "memecoin-factory",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memecoin-factory.png",
  },
  {
    name: "Bidesk",
    symbol: "BDK",
    slug: "bidesk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bidesk.png",
  },
  {
    name: "MidasProtocol",
    symbol: "MAS",
    slug: "midasprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/midasprotocol.png",
  },
  {
    name: "CorionX",
    symbol: "CORX",
    slug: "corionx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corionx.png",
  },
  {
    name: "ZCore",
    symbol: "ZCR",
    slug: "zcore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zcore.png",
  },
  {
    name: "TOKPIE",
    symbol: "TKP",
    slug: "tokpie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokpie.png",
  },
  {
    name: "SBank",
    symbol: "STS",
    slug: "sbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sbank.png",
  },
  {
    name: "RMPL",
    symbol: "RMPL",
    slug: "rmpl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rmpl.png",
  },
  {
    name: "Netko",
    symbol: "NETKO",
    slug: "netko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netko.png",
  },
  {
    name: "Wrapped Basic CryptoKitties",
    symbol: "WCK",
    slug: "wrapped-cryptokitties",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-cryptokitties.png",
  },
  {
    name: "Samsunspor Fan Token",
    symbol: "SAM",
    slug: "samsunspor-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/samsunspor-fan-token.png",
  },
  {
    name: "Bondly",
    symbol: "BONDLY",
    slug: "bondly",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bondly.png",
  },
  {
    name: "Fusible",
    symbol: "FUSII",
    slug: "fusible",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fusible.png",
  },
  {
    name: "Unicly Chris McCann Collection",
    symbol: "UCM",
    slug: "unicly-chris-mccann-collection",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicly-chris-mccann-collection.png",
  },
  {
    name: "CheesecakeSwap Token",
    symbol: "CCAKE",
    slug: "cheesecakeswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cheesecakeswap-token.png",
  },
  {
    name: "CUE Protocol",
    symbol: "CUE",
    slug: "cue-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cue-protocol.png",
  },
  {
    name: "Sociall",
    symbol: "SCL",
    slug: "sociall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sociall.png",
  },
  {
    name: "8PAY",
    symbol: "8PAY",
    slug: "8pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/8pay.png",
  },
  {
    name: "EtherGem",
    symbol: "EGEM",
    slug: "ethergem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethergem.png",
  },
  {
    name: "Internet of People",
    symbol: "IOP",
    slug: "internet-of-people",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/internet-of-people.png",
  },
  {
    name: "NeoWorld Cash",
    symbol: "NASH",
    slug: "neoworld-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neoworld-cash.png",
  },
  {
    name: "Digitalcoin",
    symbol: "DGC",
    slug: "digitalcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digitalcoin.png",
  },
  {
    name: "HelloGold",
    symbol: "HGT",
    slug: "hellogold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hellogold.png",
  },
  {
    name: "BSCView",
    symbol: "BSCV",
    slug: "bscview",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bscview.png",
  },
  {
    name: "Bezop",
    symbol: "BEZ",
    slug: "bezop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bezop.png",
  },
  {
    name: "PRIA",
    symbol: "PRIA",
    slug: "pria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pria.png",
  },
  {
    name: "Storiqa",
    symbol: "STQ",
    slug: "storiqa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/storiqa.png",
  },
  {
    name: "YVS.Finance",
    symbol: "YVS",
    slug: "yvs-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yvs-finance.png",
  },
  {
    name: "SPINDLE",
    symbol: "SPD",
    slug: "spindle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spindle.png",
  },
  {
    name: "Freicoin",
    symbol: "FRC",
    slug: "freicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freicoin.png",
  },
  {
    name: "Cryptobuyer",
    symbol: "XPT",
    slug: "cryptobuyer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobuyer.png",
  },
  {
    name: "Zilla",
    symbol: "ZLA",
    slug: "zilla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilla.png",
  },
  {
    name: "Opus",
    symbol: "OPT",
    slug: "opus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opus.png",
  },
  {
    name: "Collective",
    symbol: "CO2",
    slug: "collective",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/collective.png",
  },
  {
    name: "Cryptonite",
    symbol: "XCN",
    slug: "cryptonite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptonite.png",
  },
  {
    name: "FinNexus",
    symbol: "FNX",
    slug: "finnexus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/finnexus.png",
  },
  {
    name: "Sora Validator Token",
    symbol: "VAL",
    slug: "sora-validator-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sora-validator-token.png",
  },
  {
    name: "PlayGame",
    symbol: "PXG",
    slug: "playgame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playgame.png",
  },
  {
    name: "Tornado",
    symbol: "TCORE",
    slug: "tornado",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tornado.png",
  },
  {
    name: "DAOBet",
    symbol: "BET",
    slug: "daobet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daobet.png",
  },
  {
    name: "Alpha Token",
    symbol: "A",
    slug: "alpha-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-token.png",
  },
  {
    name: "MyBit",
    symbol: "MYB",
    slug: "mybit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mybit.png",
  },
  {
    name: "Yap Stone",
    symbol: "YAP",
    slug: "yap-stone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yap-stone.png",
  },
  {
    name: "Solaris",
    symbol: "XLR",
    slug: "solaris",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solaris.png",
  },
  {
    name: "ChartEx",
    symbol: "CHART",
    slug: "chartex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chartex.png",
  },
  {
    name: "Devery",
    symbol: "EVE",
    slug: "devery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/devery.png",
  },
  {
    name: "SolarCoin",
    symbol: "SLR",
    slug: "solarcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solarcoin.png",
  },
  {
    name: "MesChain",
    symbol: "MES",
    slug: "meschain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meschain.png",
  },
  {
    name: "Winco",
    symbol: "WCO",
    slug: "winco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winco.png",
  },
  {
    name: "Swarm City",
    symbol: "SWT",
    slug: "swarm-city",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swarm-city.png",
  },
  {
    name: "NeoNomad Finance",
    symbol: "NNI",
    slug: "neonomad-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neonomad-finance.png",
  },
  {
    name: "ZeuxCoin",
    symbol: "ZUC",
    slug: "zeuxcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeuxcoin.png",
  },
  {
    name: "InvestDigital",
    symbol: "IDT",
    slug: "investdigital",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/investdigital.png",
  },
  {
    name: "Twinci",
    symbol: "TWIN",
    slug: "twinci",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/twinci.png",
  },
  {
    name: "Modern Investment Coin",
    symbol: "MODIC",
    slug: "modern-investment-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/modern-investment-coin.png",
  },
  {
    name: "Yocoin",
    symbol: "YOC",
    slug: "yocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yocoin.png",
  },
  {
    name: "Innovative Bioresearch Coin",
    symbol: "INNBC",
    slug: "innovative-bioresearch-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/innovative-bioresearch-coin.png",
  },
  {
    name: "NestEGG Coin",
    symbol: "EGG",
    slug: "nestegg-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nestegg-coin.png",
  },
  {
    name: "Crypto Sports",
    symbol: "CSPN",
    slug: "crypto-sports",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-sports.png",
  },
  {
    name: "adToken",
    symbol: "ADT",
    slug: "adtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adtoken.png",
  },
  {
    name: "Advanced Technology Coin",
    symbol: "ARC",
    slug: "arcticcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcticcoin.png",
  },
  {
    name: "Rotten",
    symbol: "ROT",
    slug: "rotten",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rotten.png",
  },
  {
    name: "Ormeus Coin",
    symbol: "ORMEUS",
    slug: "ormeus-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ormeus-coin.png",
  },
  {
    name: "Swing",
    symbol: "SWING",
    slug: "swing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swing.png",
  },
  {
    name: "Sportcash One",
    symbol: "SCONEX",
    slug: "sportcash-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sportcash-one.png",
  },
  {
    name: "Synergy",
    symbol: "SNRG",
    slug: "synergy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synergy.png",
  },
  {
    name: "Savix",
    symbol: "SVX",
    slug: "savix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/savix.png",
  },
  {
    name: "Ritocoin",
    symbol: "RITO",
    slug: "ritocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ritocoin.png",
  },
  {
    name: "DeFi Bids",
    symbol: "BID",
    slug: "defi-bids",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-bids.png",
  },
  {
    name: "Ether Zero",
    symbol: "ETZ",
    slug: "ether-zero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ether-zero.png",
  },
  {
    name: "DopeCoin",
    symbol: "DOPE",
    slug: "dopecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dopecoin.png",
  },
  {
    name: "Citadel",
    symbol: "CTL",
    slug: "citadel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/citadel.png",
  },
  {
    name: "Ethernaal",
    symbol: "NAAL",
    slug: "ethernaal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethernaal.png",
  },
  {
    name: "Stobox Token",
    symbol: "STBU",
    slug: "stobox-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stobox-token.png",
  },
  {
    name: "RUN TOGETHER",
    symbol: "RUN",
    slug: "run-together",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/run-together.png",
  },
  {
    name: "Motocoin",
    symbol: "MOTO",
    slug: "motocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/motocoin.png",
  },
  {
    name: "Cashhand",
    symbol: "CHND",
    slug: "cashhand",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashhand.png",
  },
  {
    name: "PluraCoin",
    symbol: "PLURA",
    slug: "pluracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pluracoin.png",
  },
  {
    name: "Trinity Network Credit",
    symbol: "TNC",
    slug: "trinity-network-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trinity-network-credit.png",
  },
  {
    name: "Boosted Finance",
    symbol: "BOOST",
    slug: "boosted-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boosted-finance.png",
  },
  {
    name: "Alt.Estate token",
    symbol: "ALT",
    slug: "alt-estate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alt-estate-token.png",
  },
  {
    name: "Maecenas",
    symbol: "ART",
    slug: "maecenas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maecenas.png",
  },
  {
    name: "PoSW Coin",
    symbol: "POSW",
    slug: "posw-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/posw-coin.png",
  },
  {
    name: "Cosmo Coin",
    symbol: "COSM",
    slug: "cosmo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cosmo-coin.png",
  },
  {
    name: "EveryCoin ",
    symbol: "EVY",
    slug: "everycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everycoin.png",
  },
  {
    name: "SmartCoin",
    symbol: "SMC",
    slug: "smartcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartcoin.png",
  },
  {
    name: "MMOCoin",
    symbol: "MMO",
    slug: "mmocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mmocoin.png",
  },
  {
    name: "Master Contract Token",
    symbol: "MCT",
    slug: "master-contract-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/master-contract-token.png",
  },
  {
    name: "Membrana",
    symbol: "MBN",
    slug: "membrana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/membrana.png",
  },
  {
    name: "BitCash",
    symbol: "BITC",
    slug: "bitcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcash.png",
  },
  {
    name: "DECENT",
    symbol: "DCT",
    slug: "decent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decent.png",
  },
  {
    name: "AquariusCoin",
    symbol: "ARCO",
    slug: "aquariuscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aquariuscoin.png",
  },
  {
    name: "Innova",
    symbol: "INN",
    slug: "innova",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/innova.png",
  },
  {
    name: "Zetacoin",
    symbol: "ZET",
    slug: "zetacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zetacoin.png",
  },
  {
    name: "ION",
    symbol: "ION",
    slug: "ion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ion.png",
  },
  {
    name: "Spaceswap MILK2",
    symbol: "MILK2",
    slug: "spaceswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spaceswap.png",
  },
  {
    name: "Bobo Cash",
    symbol: "BOBO",
    slug: "bobo-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bobo-cash.png",
  },
  {
    name: "CryptoVerificationCoin",
    symbol: "CVCC",
    slug: "cryptoverificationcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoverificationcoin.png",
  },
  {
    name: "Novacoin",
    symbol: "NVC",
    slug: "novacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/novacoin.png",
  },
  {
    name: "ImageCoin",
    symbol: "IMG",
    slug: "imagecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/imagecoin.png",
  },
  {
    name: "keyTango",
    symbol: "TANGO",
    slug: "keytango",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keytango.png",
  },
  {
    name: "Megacoin",
    symbol: "MEC",
    slug: "megacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/megacoin.png",
  },
  {
    name: "MU DANK",
    symbol: "DANK",
    slug: "mu-dank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mu-dank.png",
  },
  {
    name: "Maxcoin",
    symbol: "MAX",
    slug: "maxcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maxcoin.png",
  },
  {
    name: "ATN",
    symbol: "ATN",
    slug: "atn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atn.png",
  },
  {
    name: "Chi Gastoken",
    symbol: "CHI",
    slug: "chi-gastoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chi-gastoken.png",
  },
  {
    name: "Swap",
    symbol: "XWP",
    slug: "swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swap.png",
  },
  {
    name: "PTON",
    symbol: "PTON",
    slug: "pton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pton.png",
  },
  {
    name: "Pylon Network",
    symbol: "PYLNT",
    slug: "pylon-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pylon-network.png",
  },
  {
    name: "Save Environment Token",
    symbol: "SET",
    slug: "save-environment-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/save-environment-token.png",
  },
  {
    name: "Zeepin",
    symbol: "ZPT",
    slug: "zeepin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeepin.png",
  },
  {
    name: "Kuverit",
    symbol: "KUV",
    slug: "kuverit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuverit.png",
  },
  {
    name: "Ethereum Yield",
    symbol: "ETHY",
    slug: "ethereum-yield",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-yield.png",
  },
  {
    name: "PWR Coin",
    symbol: "PWR",
    slug: "powercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/powercoin.png",
  },
  {
    name: "Lunyr",
    symbol: "LUN",
    slug: "lunyr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunyr.png",
  },
  {
    name: "Stably USD",
    symbol: "USDS",
    slug: "stableusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stableusd.png",
  },
  {
    name: "Bitstar",
    symbol: "BITS",
    slug: "bitstar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitstar.png",
  },
  {
    name: "Arqma",
    symbol: "ARQ",
    slug: "arqma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arqma.png",
  },
  {
    name: "Arbidex",
    symbol: "ABX",
    slug: "arbidex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arbidex.png",
  },
  {
    name: "Niobium Coin",
    symbol: "NBC",
    slug: "niobium-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niobium-coin.png",
  },
  {
    name: "Kwikswap Protocol",
    symbol: "KWIK",
    slug: "kwikswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kwikswap.png",
  },
  {
    name: "OracleChain",
    symbol: "OCT",
    slug: "oraclechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oraclechain.png",
  },
  {
    name: "Zennies",
    symbol: "ZENI",
    slug: "zennies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zennies.png",
  },
  {
    name: "HYCON",
    symbol: "HYC",
    slug: "hycon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hycon.png",
  },
  {
    name: "Kuende",
    symbol: "KUE",
    slug: "kuende",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuende.png",
  },
  {
    name: "BITTO",
    symbol: "BITTO",
    slug: "bitto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitto.png",
  },
  {
    name: "Blockmason Credit Protocol",
    symbol: "BCPT",
    slug: "blockmason",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockmason.png",
  },
  {
    name: "HyperExchange",
    symbol: "HX",
    slug: "hyperexchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperexchange.png",
  },
  {
    name: "vSlice",
    symbol: "VSL",
    slug: "vslice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vslice.png",
  },
  {
    name: "Startcoin",
    symbol: "START",
    slug: "startcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/startcoin.png",
  },
  {
    name: "PolkaDomain",
    symbol: "NAME",
    slug: "polkadomain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkadomain.png",
  },
  {
    name: "Xion Finance",
    symbol: "XGT",
    slug: "xion-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xion-finance.png",
  },
  {
    name: "Silver Stonks",
    symbol: "SSTX",
    slug: "silver-stonks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/silver-stonks.png",
  },
  {
    name: "Crypton",
    symbol: "CRP",
    slug: "utopia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utopia.png",
  },
  {
    name: "Open Predict Token",
    symbol: "OPT",
    slug: "open-predict-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/open-predict-token.png",
  },
  {
    name: "BlitzPick",
    symbol: "XBP",
    slug: "blitzpredict",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blitzpredict.png",
  },
  {
    name: "Bitblocks",
    symbol: "BBK",
    slug: "bitblocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitblocks.png",
  },
  {
    name: "Delphy",
    symbol: "DPY",
    slug: "delphy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/delphy.png",
  },
  {
    name: "İstanbul Başakşehir Fan Token",
    symbol: "IBFK",
    slug: "istanbul-basaksehir-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/istanbul-basaksehir-fan-token.png",
  },
  {
    name: "Trodl",
    symbol: "TRO",
    slug: "trodl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trodl.png",
  },
  {
    name: "TON Token",
    symbol: "TON",
    slug: "tontoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tontoken.png",
  },
  {
    name: "Shadow Token",
    symbol: "SHDW",
    slug: "shadow-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shadow-token.png",
  },
  {
    name: "TrumpCoin",
    symbol: "TRUMP",
    slug: "trumpcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trumpcoin.png",
  },
  {
    name: "Keep4r",
    symbol: "KP4R",
    slug: "keep4r",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keep4r.png",
  },
  {
    name: "Debitum",
    symbol: "DEB",
    slug: "debitum-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/debitum-network.png",
  },
  {
    name: "Woodcoin",
    symbol: "LOG",
    slug: "woodcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/woodcoin.png",
  },
  {
    name: "DomRaider",
    symbol: "DRT",
    slug: "domraider",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/domraider.png",
  },
  {
    name: "HashCoin",
    symbol: "HSC",
    slug: "hashcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashcoin.png",
  },
  {
    name: "HOQU",
    symbol: "HQX",
    slug: "hoqu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoqu.png",
  },
  {
    name: "1Million Token",
    symbol: "1MT",
    slug: "1million-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1million-token.png",
  },
  {
    name: "MIB Coin",
    symbol: "MIB",
    slug: "mib-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mib-coin.png",
  },
  {
    name: "BoutsPro",
    symbol: "BOUTS",
    slug: "boutspro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boutspro.png",
  },
  {
    name: "Fundamenta",
    symbol: "FMTA",
    slug: "fundamenta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fundamenta.png",
  },
  {
    name: "Ninneko",
    symbol: "NINO",
    slug: "ninneko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ninneko.png",
  },
  {
    name: "Throne",
    symbol: "THN",
    slug: "throne",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/throne.png",
  },
  {
    name: "Cubiex",
    symbol: "CBIX",
    slug: "cubiex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cubiex.png",
  },
  {
    name: "BlueCoin",
    symbol: "BLU",
    slug: "bluecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bluecoin.png",
  },
  {
    name: "Bitcloud",
    symbol: "BTDX",
    slug: "bitcloud",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcloud.png",
  },
  {
    name: "Ink",
    symbol: "INK",
    slug: "ink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ink.png",
  },
  {
    name: "Qbao",
    symbol: "QBT",
    slug: "qbao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qbao.png",
  },
  {
    name: "Noir",
    symbol: "NOR",
    slug: "noir",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noir.png",
  },
  {
    name: "Coinsuper Ecosystem Network",
    symbol: "CEN",
    slug: "coinsuper-ecosystem-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinsuper-ecosystem-network.png",
  },
  {
    name: "Sharpay",
    symbol: "S",
    slug: "sharpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sharpay.png",
  },
  {
    name: "NFTX Hashmasks Index",
    symbol: "MASK",
    slug: "nftx-hashmasks-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftx-hashmasks-index.png",
  },
  {
    name: "Heart Number",
    symbol: "HTN",
    slug: "heartnumber",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heartnumber.png",
  },
  {
    name: "Warp Finance",
    symbol: "WARP",
    slug: "warp-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/warp-finance.png",
  },
  {
    name: "GoByte",
    symbol: "GBX",
    slug: "gobyte",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gobyte.png",
  },
  {
    name: "Wrapped Gen-0 CryptoKitties",
    symbol: "WG0",
    slug: "wrapped-gen-0-cryptokitties",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-gen-0-cryptokitties.png",
  },
  {
    name: "Electrify.Asia",
    symbol: "ELEC",
    slug: "electrifyasia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electrifyasia.png",
  },
  {
    name: "OneRoot Network",
    symbol: "RNT",
    slug: "oneroot-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oneroot-network.png",
  },
  {
    name: "Po.et",
    symbol: "POE",
    slug: "poet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poet.png",
  },
  {
    name: "Gene Source Code Chain",
    symbol: "GENE",
    slug: "gene-source-code-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gene-source-code-chain.png",
  },
  {
    name: "GMB",
    symbol: "GMB",
    slug: "gmb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gmb.png",
  },
  {
    name: "AllSafe",
    symbol: "ASAFE",
    slug: "allsafe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/allsafe.png",
  },
  {
    name: "Space Cow Boy",
    symbol: "SCB",
    slug: "space-cow-boy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/space-cow-boy.png",
  },
  {
    name: "Pakcoin",
    symbol: "PAK",
    slug: "pakcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pakcoin.png",
  },
  {
    name: "ATC Coin",
    symbol: "ATCC",
    slug: "atc-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atc-coin.png",
  },
  {
    name: "Equal",
    symbol: "EQL",
    slug: "equal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/equal.png",
  },
  {
    name: "Artfinity",
    symbol: "AT",
    slug: "artfinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/artfinity.png",
  },
  {
    name: "Peseta Digital",
    symbol: "PTD",
    slug: "pesetacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pesetacoin.png",
  },
  {
    name: "bAlpha",
    symbol: "BALPHA",
    slug: "balpha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balpha.png",
  },
  {
    name: "Eternity",
    symbol: "ENT",
    slug: "eternity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eternity.png",
  },
  {
    name: "CoinFi",
    symbol: "COFI",
    slug: "coinfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinfi.png",
  },
  {
    name: "FuzeX",
    symbol: "FXT",
    slug: "fuzex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fuzex.png",
  },
  {
    name: "Sessia",
    symbol: "KICKS",
    slug: "sessia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sessia.png",
  },
  {
    name: "Limitless VIP",
    symbol: "VIP",
    slug: "limitless-vip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/limitless-vip.png",
  },
  {
    name: "Legia Warsaw Fan Token",
    symbol: "LEG",
    slug: "legia-warsaw-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/legia-warsaw-fan-token.png",
  },
  {
    name: "LYNC Network",
    symbol: "LYNC",
    slug: "lync-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lync-network.png",
  },
  {
    name: "BITTUP",
    symbol: "BTU",
    slug: "bittup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bittup.png",
  },
  {
    name: "Edgeless",
    symbol: "EDG",
    slug: "edgeless",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edgeless.png",
  },
  {
    name: "NFT Alley",
    symbol: "ALLEY",
    slug: "nft-alley",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-alley.png",
  },
  {
    name: "SIBCoin",
    symbol: "SIB",
    slug: "sibcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sibcoin.png",
  },
  {
    name: "MintMe.com Coin",
    symbol: "MINTME",
    slug: "mintme-com-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mintme-com-coin.png",
  },
  {
    name: "Golos Blockchain",
    symbol: "GLS",
    slug: "golos-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golos-blockchain.png",
  },
  {
    name: "BUZZCoin",
    symbol: "BUZZ",
    slug: "buzzcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buzzcoin.png",
  },
  {
    name: "Showcase",
    symbol: "SHO",
    slug: "showcase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/showcase.png",
  },
  {
    name: "Yearn Secure",
    symbol: "YSEC",
    slug: "yearn-secure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-secure.png",
  },
  {
    name: "Datamine",
    symbol: "DAM",
    slug: "datamine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/datamine.png",
  },
  {
    name: "TrustDAO",
    symbol: "TRUST",
    slug: "trustdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustdao.png",
  },
  {
    name: "VikkyToken",
    symbol: "VIKKY",
    slug: "vikkytoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vikkytoken.png",
  },
  {
    name: "ChessCoin",
    symbol: "CHESS",
    slug: "chesscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chesscoin.png",
  },
  {
    name: "Nerva",
    symbol: "XNV",
    slug: "nerva",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nerva.png",
  },
  {
    name: "DeHive",
    symbol: "DHV",
    slug: "dehive",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dehive.png",
  },
  {
    name: "MetaMorph",
    symbol: "METM",
    slug: "metamorph",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metamorph.png",
  },
  {
    name: "Phoneum",
    symbol: "PHT",
    slug: "phoneum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoneum.png",
  },
  {
    name: "TrueDeck",
    symbol: "TDP",
    slug: "truedeck",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/truedeck.png",
  },
  {
    name: "StrongHands",
    symbol: "SHND",
    slug: "stronghands",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stronghands.png",
  },
  {
    name: "Xiotri",
    symbol: "XIOT",
    slug: "xiotri",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xiotri.png",
  },
  {
    name: "Omnitude",
    symbol: "ECOM",
    slug: "omnitude",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omnitude.png",
  },
  {
    name: "TerraCredit",
    symbol: "CREDIT",
    slug: "credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/credit.png",
  },
  {
    name: "The ChampCoin",
    symbol: "TCC",
    slug: "the-champcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-champcoin.png",
  },
  {
    name: "Sether",
    symbol: "SETH",
    slug: "sether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sether.png",
  },
  {
    name: "ESBC",
    symbol: "ESBC",
    slug: "esbc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/esbc.png",
  },
  {
    name: "PegNet",
    symbol: "PEG",
    slug: "pegnet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pegnet.png",
  },
  {
    name: "Kobocoin",
    symbol: "KOBO",
    slug: "kobocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kobocoin.png",
  },
  {
    name: "MoonTools",
    symbol: "MOONS",
    slug: "moontools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moontools.png",
  },
  {
    name: "SophiaTX",
    symbol: "SPHTX",
    slug: "sophiatx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sophiatx.png",
  },
  {
    name: "x42 Protocol",
    symbol: "X42",
    slug: "x42-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x42-protocol.png",
  },
  {
    name: "FNB Protocol",
    symbol: "FNB",
    slug: "fnb-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fnb-protocol.png",
  },
  {
    name: "CryptoAds Marketplace",
    symbol: "CRAD",
    slug: "cryptoads-marketplace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoads-marketplace.png",
  },
  {
    name: "BiblePay",
    symbol: "BBP",
    slug: "biblepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biblepay.png",
  },
  {
    name: "TrezarCoin",
    symbol: "TZC",
    slug: "trezarcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trezarcoin.png",
  },
  {
    name: "The Corgi of PolkaBridge",
    symbol: "CORGIB",
    slug: "the-corgi-of-polkabridge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-corgi-of-polkabridge.png",
  },
  {
    name: "iDealCash",
    symbol: "DEAL",
    slug: "idealcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idealcash.png",
  },
  {
    name: "DOGEFI",
    symbol: "DOGEFI",
    slug: "dogefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogefi.png",
  },
  {
    name: "Birdchain",
    symbol: "BIRD",
    slug: "birdchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/birdchain.png",
  },
  {
    name: "LiteDoge",
    symbol: "LDOGE",
    slug: "litedoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litedoge.png",
  },
  {
    name: "Primalbase Token",
    symbol: "PBT",
    slug: "primalbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/primalbase.png",
  },
  {
    name: "Rope",
    symbol: "$ROPE",
    slug: "rope",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rope.png",
  },
  {
    name: "OWNDATA",
    symbol: "OWN",
    slug: "owndata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/owndata.png",
  },
  {
    name: "2gather",
    symbol: "TWO",
    slug: "2gather",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2gather.png",
  },
  {
    name: "CryptoFlow",
    symbol: "CFL",
    slug: "cryptoflow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoflow.png",
  },
  {
    name: "Teloscoin",
    symbol: "TELOS",
    slug: "teloscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/teloscoin.png",
  },
  {
    name: "Insula",
    symbol: "ISLA",
    slug: "insula",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insula.png",
  },
  {
    name: "SmileyCoin",
    symbol: "SMLY",
    slug: "smileycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smileycoin.png",
  },
  {
    name: "DACSEE",
    symbol: "DACS",
    slug: "dacsee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dacsee.png",
  },
  {
    name: "Rage Fan",
    symbol: "RAGE",
    slug: "rage-fan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rage-fan.png",
  },
  {
    name: "MAX Exchange Token",
    symbol: "MAX",
    slug: "max-exchange-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/max-exchange-token.png",
  },
  {
    name: "Island Coin",
    symbol: "ISLE",
    slug: "island-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/island-coin.png",
  },
  {
    name: "CryptoTask",
    symbol: "CTASK",
    slug: "cryptotask",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptotask.png",
  },
  {
    name: "Smartshare",
    symbol: "SSP",
    slug: "smartshare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartshare.png",
  },
  {
    name: "Project Inverse",
    symbol: "XIV",
    slug: "project-inverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-inverse.png",
  },
  {
    name: "REAL",
    symbol: "REAL",
    slug: "real",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/real.png",
  },
  {
    name: "Zoracles",
    symbol: "ZORA",
    slug: "zoracles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zoracles.png",
  },
  {
    name: "Rocket Vault",
    symbol: "RVF",
    slug: "rocket-vault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-vault.png",
  },
  {
    name: "RED",
    symbol: "RED",
    slug: "red",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/red.png",
  },
  {
    name: "Blue Whale EXchange",
    symbol: "BWX",
    slug: "blue-whale-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blue-whale-exchange.png",
  },
  {
    name: "Terracoin",
    symbol: "TRC",
    slug: "terracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terracoin.png",
  },
  {
    name: "Cat Token",
    symbol: "CAT",
    slug: "cat-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cat-token.png",
  },
  {
    name: "Masari",
    symbol: "MSR",
    slug: "masari",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/masari.png",
  },
  {
    name: "Cajutel",
    symbol: "CAJ",
    slug: "cajutel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cajutel.png",
  },
  {
    name: "Vid",
    symbol: "VI",
    slug: "vid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vid.png",
  },
  {
    name: "Digital Insurance Token",
    symbol: "DIT",
    slug: "digital-insurance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-insurance-token.png",
  },
  {
    name: "Jetcoin",
    symbol: "JET",
    slug: "jetcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jetcoin.png",
  },
  {
    name: "ArGo",
    symbol: "ARGO",
    slug: "argoapp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/argoapp.png",
  },
  {
    name: "Stox",
    symbol: "STX",
    slug: "stox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stox.png",
  },
  {
    name: "Tendies",
    symbol: "TEND",
    slug: "tendies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tendies.png",
  },
  {
    name: "Indorse Token",
    symbol: "IND",
    slug: "indorse-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/indorse-token.png",
  },
  {
    name: "BitDegree",
    symbol: "BDG",
    slug: "bitdegree",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitdegree.png",
  },
  {
    name: "KIMCHI.finance",
    symbol: "KIMCHI",
    slug: "kimchi-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kimchi-finance.png",
  },
  {
    name: "Blue Protocol",
    symbol: "BLUE",
    slug: "ethereum-blue",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-blue.png",
  },
  {
    name: "NEXT",
    symbol: "NEXT",
    slug: "next-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/next-coin.png",
  },
  {
    name: "QUINADS",
    symbol: "QUIN",
    slug: "quinads",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quinads.png",
  },
  {
    name: "Typerium",
    symbol: "TYPE",
    slug: "typerium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/typerium.png",
  },
  {
    name: "Bitcoin Private",
    symbol: "BTCP",
    slug: "bitcoin-private",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-private.png",
  },
  {
    name: "Ink Protocol",
    symbol: "XNK",
    slug: "ink-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ink-protocol.png",
  },
  {
    name: "Polinate",
    symbol: "POLI",
    slug: "polinate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polinate.png",
  },
  {
    name: "srnArt Gallery",
    symbol: "SACT",
    slug: "srnartgallery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/srnartgallery.png",
  },
  {
    name: "Lucretius",
    symbol: "LUC",
    slug: "lucretius",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lucretius.png",
  },
  {
    name: "Landbox",
    symbol: "LAND",
    slug: "landbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/landbox.png",
  },
  {
    name: "Wonderman Nation",
    symbol: "WNDR",
    slug: "wonderman-nation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wonderman-nation.png",
  },
  {
    name: "Power Index Pool Token",
    symbol: "PIPT",
    slug: "power-index-pool-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/power-index-pool-token.png",
  },
  {
    name: "ZPER",
    symbol: "ZPR",
    slug: "zper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zper.png",
  },
  {
    name: "Jade Currency",
    symbol: "JADE",
    slug: "jade-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jade-currency.png",
  },
  {
    name: "Earnbase",
    symbol: "ENB",
    slug: "earnbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnbase.png",
  },
  {
    name: "GlobalBoost-Y",
    symbol: "BSTY",
    slug: "globalboost-y",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/globalboost-y.png",
  },
  {
    name: "Alphr finance",
    symbol: "ALPHR",
    slug: "alphr-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alphr-finance.png",
  },
  {
    name: "ShipChain",
    symbol: "SHIP",
    slug: "shipchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shipchain.png",
  },
  {
    name: "ExclusiveCoin",
    symbol: "EXCL",
    slug: "exclusivecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exclusivecoin.png",
  },
  {
    name: "Method Finance",
    symbol: "MTHD",
    slug: "method-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/method-finance.png",
  },
  {
    name: "AstroTools",
    symbol: "ASTRO",
    slug: "astrotools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astrotools.png",
  },
  {
    name: "DecentBet",
    symbol: "DBET",
    slug: "decent-bet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decent-bet.png",
  },
  {
    name: "EventChain",
    symbol: "EVC",
    slug: "eventchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eventchain.png",
  },
  {
    name: "DoYourTip",
    symbol: "DYT",
    slug: "doyourtip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doyourtip.png",
  },
  {
    name: "ByteNext",
    symbol: "BNU",
    slug: "bytenext",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bytenext.png",
  },
  {
    name: "extraDNA",
    symbol: "XDNA",
    slug: "extradna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/extradna.png",
  },
  {
    name: "Secure Pad",
    symbol: "SEPA",
    slug: "secure-pad-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secure-pad-token.png",
  },
  {
    name: "renDOGE",
    symbol: "RENDOGE",
    slug: "rendoge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rendoge.png",
  },
  {
    name: "MicroBitcoin",
    symbol: "MBC",
    slug: "microbitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/microbitcoin.png",
  },
  {
    name: "Beamswap",
    symbol: "GLINT",
    slug: "beamswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beamswap.png",
  },
  {
    name: "Orbitcoin",
    symbol: "ORB",
    slug: "orbitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbitcoin.png",
  },
  {
    name: "Hyper Finance",
    symbol: "HYFI",
    slug: "hyper-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyper-finance.png",
  },
  {
    name: "PetroDollar",
    symbol: "XPD",
    slug: "petrodollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petrodollar.png",
  },
  {
    name: "Blockpass",
    symbol: "PASS",
    slug: "blockpass",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockpass.png",
  },
  {
    name: "Yellow Road",
    symbol: "ROAD",
    slug: "yellow-road",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yellow-road.png",
  },
  {
    name: "Zen Protocol",
    symbol: "ZP",
    slug: "zen-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zen-protocol.png",
  },
  {
    name: "Drops Ownership Power",
    symbol: "DOP",
    slug: "drops",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drops.png",
  },
  {
    name: "Zero Utility Token",
    symbol: "ZUT",
    slug: "zero-utility-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zero-utility-token.png",
  },
  {
    name: "DogeCash",
    symbol: "DOGEC",
    slug: "dogecash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogecash.png",
  },
  {
    name: "SHPING",
    symbol: "SHPING",
    slug: "shping",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shping.png",
  },
  {
    name: "Royale Finance",
    symbol: "ROYA",
    slug: "royale-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/royale-finance.png",
  },
  {
    name: "InterValue",
    symbol: "INVE",
    slug: "intervalue",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intervalue.png",
  },
  {
    name: "CryptoSoul",
    symbol: "SOUL",
    slug: "cryptosoul",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptosoul.png",
  },
  {
    name: "Spectre.ai Utility Token",
    symbol: "SXUT",
    slug: "spectre-utility",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spectre-utility.png",
  },
  {
    name: "Bottos",
    symbol: "BTO",
    slug: "bottos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bottos.png",
  },
  {
    name: "Precium",
    symbol: "PCM",
    slug: "precium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/precium.png",
  },
  {
    name: "ADA BOY",
    symbol: "ADABOY",
    slug: "ada-boy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ada-boy.png",
  },
  {
    name: "CoinDeal Token",
    symbol: "CDL",
    slug: "coindeal-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coindeal-token.png",
  },
  {
    name: "Posscoin",
    symbol: "POSS",
    slug: "posscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/posscoin.png",
  },
  {
    name: "Clash Token",
    symbol: "SCT",
    slug: "clash-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clash-token.png",
  },
  {
    name: "HashBX",
    symbol: "HBX",
    slug: "hashsbx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashsbx.png",
  },
  {
    name: "GoWithMi",
    symbol: "GMAT",
    slug: "gowithmi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gowithmi.png",
  },
  {
    name: "COVA",
    symbol: "COVA",
    slug: "cova",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cova.png",
  },
  {
    name: "Beer Money",
    symbol: "BEER",
    slug: "beer-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beer-money.png",
  },
  {
    name: "Decentralized Vulnerability Platform",
    symbol: "DVP",
    slug: "decentralized-vulnerability-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentralized-vulnerability-platform.png",
  },
  {
    name: "Oikos",
    symbol: "OKS",
    slug: "oikos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oikos.png",
  },
  {
    name: "Wrapped Origin Axie",
    symbol: "WOA",
    slug: "wrapped-origin-axie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-origin-axie.png",
  },
  {
    name: "Streamity",
    symbol: "STM",
    slug: "streamity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/streamity.png",
  },
  {
    name: "Jarvis+",
    symbol: "JAR",
    slug: "jarvis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jarvis.png",
  },
  {
    name: "ACoconut",
    symbol: "AC",
    slug: "acoconut",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acoconut.png",
  },
  {
    name: "StakerDAO",
    symbol: "STKR",
    slug: "stakerdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakerdao.png",
  },
  {
    name: "Coin Artist",
    symbol: "COIN",
    slug: "coin-artist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin-artist.png",
  },
  {
    name: "FansTime",
    symbol: "FTI",
    slug: "fanstime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fanstime.png",
  },
  {
    name: "Axis DeFi",
    symbol: "AXIS",
    slug: "axis-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axis-defi.png",
  },
  {
    name: "Friendz",
    symbol: "FDZ",
    slug: "friends",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/friends.png",
  },
  {
    name: "POLKARARE",
    symbol: "PRARE",
    slug: "polkarare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkarare.png",
  },
  {
    name: "Typhoon Network",
    symbol: "TYPH",
    slug: "typhoon-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/typhoon-network.png",
  },
  {
    name: "4THPILLAR TECHNOLOGIES",
    symbol: "FOUR",
    slug: "4thpillar-technologies",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/4thpillar-technologies.png",
  },
  {
    name: "Rise",
    symbol: "RISE",
    slug: "rise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rise.png",
  },
  {
    name: "Step Finance",
    symbol: "STEP",
    slug: "step-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/step-finance.png",
  },
  {
    name: "VisionGame",
    symbol: "VISION",
    slug: "vision-game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vision-game.png",
  },
  {
    name: "QChi",
    symbol: "QCH",
    slug: "qchi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qchi.png",
  },
  {
    name: "DeFi Degen Land",
    symbol: "DDL",
    slug: "defi-degen-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-degen-land.png",
  },
  {
    name: "Snetwork",
    symbol: "SNET",
    slug: "snetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snetwork.png",
  },
  {
    name: "Novara Calcio Fan Token",
    symbol: "NOV",
    slug: "novara-calcio-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/novara-calcio-fan-token.png",
  },
  {
    name: "Stipend",
    symbol: "SPD",
    slug: "stipend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stipend.png",
  },
  {
    name: "Bloom",
    symbol: "BLT",
    slug: "bloomtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bloomtoken.png",
  },
  {
    name: "Bitgear",
    symbol: "GEAR",
    slug: "bitgear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitgear.png",
  },
  {
    name: "PutinCoin",
    symbol: "PUT",
    slug: "putincoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/putincoin.png",
  },
  {
    name: "Catex Token",
    symbol: "CATT",
    slug: "catex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catex-token.png",
  },
  {
    name: "Etherparty",
    symbol: "FUEL",
    slug: "etherparty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherparty.png",
  },
  {
    name: "Ethereum Stake",
    symbol: "ETHYS",
    slug: "ethereum-stake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-stake.png",
  },
  {
    name: "Nuco.cloud",
    symbol: "NCDT",
    slug: "nuco-cloud",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nuco-cloud.png",
  },
  {
    name: "Asch",
    symbol: "XAS",
    slug: "asch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asch.png",
  },
  {
    name: "MoonRadar",
    symbol: "MRF",
    slug: "moonradar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonradar.png",
  },
  {
    name: "Moneynet",
    symbol: "MNC",
    slug: "moneynet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moneynet.png",
  },
  {
    name: "Aston Villa Fan Token",
    symbol: "AVL",
    slug: "aston-villa-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aston-villa-fan-token.png",
  },
  {
    name: "WeTrust",
    symbol: "TRST",
    slug: "trust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trust.png",
  },
  {
    name: "Nework",
    symbol: "NKC",
    slug: "nework",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nework.png",
  },
  {
    name: "LUXCoin",
    symbol: "LUX",
    slug: "luxcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/luxcoin.png",
  },
  {
    name: "CREA",
    symbol: "CREA",
    slug: "crea",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crea.png",
  },
  {
    name: "Mercury",
    symbol: "MER",
    slug: "mercury",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mercury.png",
  },
  {
    name: "TigerCash",
    symbol: "TCH",
    slug: "tigercash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tigercash.png",
  },
  {
    name: "Sakura Bloom",
    symbol: "SKB",
    slug: "sakura-bloom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sakura-bloom.png",
  },
  {
    name: "SOAR.FI",
    symbol: "SOAR",
    slug: "soar-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/soar-fi.png",
  },
  {
    name: "ODUWA",
    symbol: "OWC",
    slug: "oduwa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oduwa.png",
  },
  {
    name: "Gourmet Galaxy",
    symbol: "GUM",
    slug: "gourmet-galaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gourmet-galaxy.png",
  },
  {
    name: "DEJAVE",
    symbol: "DJV",
    slug: "dejave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dejave.png",
  },
  {
    name: "Phantasma Energy",
    symbol: "KCAL",
    slug: "phantasma-energy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phantasma-energy.png",
  },
  {
    name: "Libertas Token",
    symbol: "LIBERTAS",
    slug: "libertas-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libertas-token.png",
  },
  {
    name: "DPRating",
    symbol: "RATING",
    slug: "dprating",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dprating.png",
  },
  {
    name: "Karbo",
    symbol: "KRB",
    slug: "karbo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karbo.png",
  },
  {
    name: "XTRABYTES",
    symbol: "XBY",
    slug: "xtrabytes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xtrabytes.png",
  },
  {
    name: "Incent",
    symbol: "INCNT",
    slug: "incent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/incent.png",
  },
  {
    name: "Curio",
    symbol: "CUR",
    slug: "curio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/curio.png",
  },
  {
    name: "Ormeus Ecosystem",
    symbol: "ECO",
    slug: "ormeus-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ormeus-ecosystem.png",
  },
  {
    name: "Expanse",
    symbol: "EXP",
    slug: "expanse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/expanse.png",
  },
  {
    name: "IXT",
    symbol: "IXT",
    slug: "ixledger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ixledger.png",
  },
  {
    name: "8X8 PROTOCOL",
    symbol: "EXE",
    slug: "8x8-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/8x8-protocol.png",
  },
  {
    name: "DMarket",
    symbol: "DMT",
    slug: "dmarket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dmarket.png",
  },
  {
    name: "Polis",
    symbol: "POLIS",
    slug: "polis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polis.png",
  },
  {
    name: "eXPerience Chain",
    symbol: "XPC",
    slug: "experience-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/experience-chain.png",
  },
  {
    name: "VIG",
    symbol: "VIG",
    slug: "vig",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vig.png",
  },
  {
    name: "STK",
    symbol: "STK",
    slug: "stk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stk.png",
  },
  {
    name: "Subme",
    symbol: "SUB",
    slug: "subme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/subme.png",
  },
  {
    name: "ATLANT",
    symbol: "ATL",
    slug: "atlant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlant.png",
  },
  {
    name: "Banca",
    symbol: "BANCA",
    slug: "banca",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banca.png",
  },
  {
    name: "TotemFi",
    symbol: "TOTM",
    slug: "totemfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/totemfi.png",
  },
  {
    name: "Safex Cash",
    symbol: "SFX",
    slug: "safex-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safex-cash.png",
  },
  {
    name: "Lethean",
    symbol: "LTHN",
    slug: "lethean",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lethean.png",
  },
  {
    name: "GamyFi Platform",
    symbol: "GFX",
    slug: "gamyfi-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamyfi-platform.png",
  },
  {
    name: "NFTLootBox",
    symbol: "LOOT",
    slug: "nftlootbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftlootbox.png",
  },
  {
    name: "LanaCoin",
    symbol: "LANA",
    slug: "lanacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lanacoin.png",
  },
  {
    name: "IHT Real Estate Protocol",
    symbol: "IHT",
    slug: "iht-real-estate-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iht-real-estate-protocol.png",
  },
  {
    name: "GokuMarket Credit",
    symbol: "GMC",
    slug: "gokumarket-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gokumarket-credit.png",
  },
  {
    name: "Manna",
    symbol: "MANNA",
    slug: "manna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manna.png",
  },
  {
    name: "Grumpy.finance",
    symbol: "GRUMPY",
    slug: "grumpy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grumpy-finance.png",
  },
  {
    name: "EchoLink",
    symbol: "EKO",
    slug: "echolink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/echolink.png",
  },
  {
    name: "RigoBlock",
    symbol: "GRG",
    slug: "rigoblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rigoblock.png",
  },
  {
    name: "CrowdWiz",
    symbol: "WIZ",
    slug: "crowdwiz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crowdwiz.png",
  },
  {
    name: "Everton Fan Token",
    symbol: "EFC",
    slug: "everton-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everton-fan-token.png",
  },
  {
    name: "NeuroChain",
    symbol: "NCC",
    slug: "neurochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neurochain.png",
  },
  {
    name: "Rapidz",
    symbol: "RPZX",
    slug: "rapidz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rapidz.png",
  },
  {
    name: "Maincoin",
    symbol: "MNC",
    slug: "maincoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maincoin.png",
  },
  {
    name: "xBTC",
    symbol: "xBTC",
    slug: "xbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xbtc.png",
  },
  {
    name: "NANJCOIN",
    symbol: "NANJ",
    slug: "nanjcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nanjcoin.png",
  },
  {
    name: "Tapmydata",
    symbol: "TAP",
    slug: "tapmydata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tapmydata.png",
  },
  {
    name: "Ethverse",
    symbol: "ETHV",
    slug: "ethverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethverse.png",
  },
  {
    name: "OLXA",
    symbol: "OLXA",
    slug: "olxa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olxa.png",
  },
  {
    name: "MONK",
    symbol: "MONK",
    slug: "monk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monk.png",
  },
  {
    name: "Clash of Lilliput",
    symbol: "COL",
    slug: "clash-of-lilliput",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clash-of-lilliput.png",
  },
  {
    name: "0xcert",
    symbol: "ZXC",
    slug: "0xcert",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0xcert.png",
  },
  {
    name: "Name Change Token",
    symbol: "NCT",
    slug: "name-change-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/name-change-token.png",
  },
  {
    name: "Petals",
    symbol: "PTS",
    slug: "petals",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/petals.png",
  },
  {
    name: "Actinium",
    symbol: "ACM",
    slug: "actinium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/actinium.png",
  },
  {
    name: "Fountain",
    symbol: "FTN",
    slug: "fountain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fountain.png",
  },
  {
    name: "Bitswift",
    symbol: "BITS",
    slug: "bitswift",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitswift.png",
  },
  {
    name: "GoldenPyrex",
    symbol: "GPYX",
    slug: "goldenpyrex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldenpyrex.png",
  },
  {
    name: "BonFi",
    symbol: "BNF",
    slug: "bonfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonfi.png",
  },
  {
    name: "GeoCoin",
    symbol: "GEO",
    slug: "geocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geocoin.png",
  },
  {
    name: "Matryx",
    symbol: "MTX",
    slug: "matryx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matryx.png",
  },
  {
    name: "Cryptonovae",
    symbol: "YAE",
    slug: "cryptonovae",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptonovae.png",
  },
  {
    name: "FintruX Network",
    symbol: "FTX",
    slug: "fintrux-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fintrux-network.png",
  },
  {
    name: "Rate3",
    symbol: "RTE",
    slug: "rate3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rate3.png",
  },
  {
    name: "LocalCoinSwap",
    symbol: "LCS",
    slug: "local-coin-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/local-coin-swap.png",
  },
  {
    name: "Origin Sport",
    symbol: "ORS",
    slug: "origin-sport",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/origin-sport.png",
  },
  {
    name: "BillionHappiness",
    symbol: "BHC",
    slug: "billionhappiness",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/billionhappiness.png",
  },
  {
    name: "IntelliShare",
    symbol: "INE",
    slug: "intellishare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/intellishare.png",
  },
  {
    name: "BackPacker Coin",
    symbol: "BPC",
    slug: "backpacker-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/backpacker-coin.png",
  },
  {
    name: "yTSLA Finance",
    symbol: "yTSLA",
    slug: "ytsla-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ytsla-finance.png",
  },
  {
    name: "Qwertycoin",
    symbol: "QWC",
    slug: "qwertycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qwertycoin.png",
  },
  {
    name: "Eureka Coin",
    symbol: "ERK",
    slug: "eureka-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eureka-coin.png",
  },
  {
    name: "Nuggets",
    symbol: "NUG",
    slug: "nuggets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nuggets.png",
  },
  {
    name: "Fera",
    symbol: "FERA",
    slug: "fera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fera.png",
  },
  {
    name: "ZrCoin",
    symbol: "ZRC",
    slug: "zrcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zrcoin.png",
  },
  {
    name: "Portugal National Team Fan Token",
    symbol: "POR",
    slug: "portugal-national-team-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/portugal-national-team-fan-token.png",
  },
  {
    name: "AtromG8",
    symbol: "AG8",
    slug: "atromg8",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atromg8.png",
  },
  {
    name: "ZumCoin",
    symbol: "ZUM",
    slug: "zumcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zumcoin.png",
  },
  {
    name: "Seigniorage Shares",
    symbol: "SHARE",
    slug: "seigniorage-shares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seigniorage-shares.png",
  },
  {
    name: "Sharder",
    symbol: "SS",
    slug: "sharder",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sharder.png",
  },
  {
    name: "Naka Bodhi Token",
    symbol: "NBOT",
    slug: "naka-bodhi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/naka-bodhi-token.png",
  },
  {
    name: "X8X Token",
    symbol: "X8X",
    slug: "x8x-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x8x-token.png",
  },
  {
    name: "Alliance Fan Token",
    symbol: "ALL",
    slug: "alliance-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alliance-fan-token.png",
  },
  {
    name: "Sao Paulo FC Fan Token",
    symbol: "SPFC",
    slug: "sao-paulo-fc-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sao-paulo-fc-fan-token.png",
  },
  {
    name: "Tolar",
    symbol: "TOL",
    slug: "tolar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tolar.png",
  },
  {
    name: "Aluna.Social",
    symbol: "ALN",
    slug: "aluna-social",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aluna-social.png",
  },
  {
    name: "Base Protocol",
    symbol: "BASE",
    slug: "base-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/base-protocol.png",
  },
  {
    name: "BitScreener Token",
    symbol: "BITX",
    slug: "bitscreener-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitscreener-token.png",
  },
  {
    name: "Dether",
    symbol: "DTH",
    slug: "dether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dether.png",
  },
  {
    name: "Azuki",
    symbol: "AZUKI",
    slug: "azuki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/azuki.png",
  },
  {
    name: "Coinsbit Token",
    symbol: "CNB",
    slug: "coinsbit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinsbit-token.png",
  },
  {
    name: "Upfiring",
    symbol: "UFR",
    slug: "upfiring",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upfiring.png",
  },
  {
    name: "DATx",
    symbol: "DATX",
    slug: "datx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/datx.png",
  },
  {
    name: "FYDcoin",
    symbol: "FYD",
    slug: "fydcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fydcoin.png",
  },
  {
    name: "FlypMe",
    symbol: "FYP",
    slug: "flypme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flypme.png",
  },
  {
    name: "Relite Finance",
    symbol: "RELI",
    slug: "relite-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/relite-finance.png",
  },
  {
    name: "Krios",
    symbol: "GIG",
    slug: "krios",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krios.png",
  },
  {
    name: "Launchblock.com",
    symbol: "LBP",
    slug: "launchblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/launchblock.png",
  },
  {
    name: "Kangal",
    symbol: "KANGAL",
    slug: "kangal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kangal.png",
  },
  {
    name: "Blackmoon",
    symbol: "BMC",
    slug: "blackmoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackmoon.png",
  },
  {
    name: "BarterTrade",
    symbol: "BART",
    slug: "bartertrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bartertrade.png",
  },
  {
    name: "uPlexa",
    symbol: "UPX",
    slug: "uplexa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uplexa.png",
  },
  {
    name: "CryptoBonusMiles",
    symbol: "CBM",
    slug: "cryptobonusmiles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptobonusmiles.png",
  },
  {
    name: "WeOwn",
    symbol: "CHX",
    slug: "we-own",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/we-own.png",
  },
  {
    name: "Coreto",
    symbol: "COR",
    slug: "coreto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coreto.png",
  },
  {
    name: "reflect.finance",
    symbol: "RFI",
    slug: "reflect-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reflect-finance.png",
  },
  {
    name: "Decentrahub Coin",
    symbol: "DCNTR",
    slug: "decentrahub-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentrahub-coin.png",
  },
  {
    name: "Lightstreams",
    symbol: "PHT",
    slug: "lightstreams",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lightstreams.png",
  },
  {
    name: "Yield Protocol",
    symbol: "YIELD",
    slug: "yield-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-protocol.png",
  },
  {
    name: "Antiample",
    symbol: "XAMP",
    slug: "antiample",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antiample.png",
  },
  {
    name: "Minswap",
    symbol: "MIN",
    slug: "minswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minswap.png",
  },
  {
    name: "Primas",
    symbol: "PST",
    slug: "primas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/primas.png",
  },
  {
    name: "Meeb Master",
    symbol: "MEEB",
    slug: "meeb-master",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meeb-master.png",
  },
  {
    name: "Katalyo",
    symbol: "KTLYO",
    slug: "katalyo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/katalyo.png",
  },
  {
    name: "Ubex",
    symbol: "UBEX",
    slug: "ubex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubex.png",
  },
  {
    name: "Bitcoin Confidential",
    symbol: "BC",
    slug: "bitcoin-confidential",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-confidential.png",
  },
  {
    name: "VNX Exchange",
    symbol: "VNXLU",
    slug: "vnx-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vnx-exchange.png",
  },
  {
    name: "Berry Data",
    symbol: "BRY",
    slug: "berry-data",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/berry-data.png",
  },
  {
    name: "AudioCoin",
    symbol: "ADC",
    slug: "audiocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/audiocoin.png",
  },
  {
    name: "Themis",
    symbol: "GET",
    slug: "themis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/themis.png",
  },
  {
    name: "Stake DAO",
    symbol: "SDT",
    slug: "stake-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stake-dao.png",
  },
  {
    name: "DDKoin",
    symbol: "DDK",
    slug: "ddkoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ddkoin.png",
  },
  {
    name: "PirateCash",
    symbol: "PIRATE",
    slug: "piratecash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piratecash.png",
  },
  {
    name: "Donut",
    symbol: "DONUT",
    slug: "donut",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/donut.png",
  },
  {
    name: "Pivot Token",
    symbol: "PVT",
    slug: "pivot-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pivot-token.png",
  },
  {
    name: "Smaugs NFT",
    symbol: "SMG",
    slug: "smaugs-nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smaugs-nft.png",
  },
  {
    name: "Zero",
    symbol: "ZER",
    slug: "zero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zero.png",
  },
  {
    name: "Clever DeFi",
    symbol: "CLVA",
    slug: "clever-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clever-defi.png",
  },
  {
    name: "PieDAO DEFI++",
    symbol: "DEFI++",
    slug: "piedao-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piedao-defi.png",
  },
  {
    name: "NFTify",
    symbol: "N1",
    slug: "nftify",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftify.png",
  },
  {
    name: "KanadeCoin",
    symbol: "KNDC",
    slug: "kanadecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kanadecoin.png",
  },
  {
    name: "Goose Finance",
    symbol: "EGG",
    slug: "goose-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goose-finance.png",
  },
  {
    name: "Furucombo",
    symbol: "COMBO",
    slug: "furucombo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/furucombo.png",
  },
  {
    name: "Dragon Coins",
    symbol: "DRG",
    slug: "dragon-coins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-coins.png",
  },
  {
    name: "Polkalokr",
    symbol: "LKR",
    slug: "polkalokr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkalokr.png",
  },
  {
    name: "SureRemit",
    symbol: "RMT",
    slug: "sureremit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sureremit.png",
  },
  {
    name: "Carboncoin",
    symbol: "CARBON",
    slug: "carboncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carboncoin.png",
  },
  {
    name: "UREEQA",
    symbol: "URQA",
    slug: "ureeqa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ureeqa.png",
  },
  {
    name: "Centric Swap",
    symbol: "CNS",
    slug: "centric-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centric-swap.png",
  },
  {
    name: "Dogeswap",
    symbol: "DOGES",
    slug: "dogeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogeswap.png",
  },
  {
    name: "Unicly Aavegotchi Astronauts Collection",
    symbol: "UGOTCHI",
    slug: "unicly-aavegotchi-astronauts-collection",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicly-aavegotchi-astronauts-collection.png",
  },
  {
    name: "MonetaryUnit",
    symbol: "MUE",
    slug: "monetaryunit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monetaryunit.png",
  },
  {
    name: "Ryo Currency",
    symbol: "RYO",
    slug: "ryo-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ryo-currency.png",
  },
  {
    name: "Crowny",
    symbol: "CRWNY",
    slug: "crowny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crowny.png",
  },
  {
    name: "Bob's Repair",
    symbol: "BOB",
    slug: "bobs-repair",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bobs-repair.png",
  },
  {
    name: "Wolves of Wall Street",
    symbol: "WOWS",
    slug: "wolves-of-wall-street",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wolves-of-wall-street.png",
  },
  {
    name: "NIX",
    symbol: "NIX",
    slug: "nix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nix.png",
  },
  {
    name: "Defis Network",
    symbol: "DFS",
    slug: "defis-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defis-network.png",
  },
  {
    name: "CannabisCoin",
    symbol: "CANN",
    slug: "cannabiscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cannabiscoin.png",
  },
  {
    name: "SpectrumCash",
    symbol: "XSM",
    slug: "spectrumcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spectrumcash.png",
  },
  {
    name: "SAFE DEAL",
    symbol: "SFD",
    slug: "safe-deal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-deal.png",
  },
  {
    name: "Fyooz",
    symbol: "FYZ",
    slug: "fyooz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fyooz.png",
  },
  {
    name: "disBalancer",
    symbol: "DDOS",
    slug: "disbalancer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/disbalancer.png",
  },
  {
    name: "Max Property Group",
    symbol: "MPG",
    slug: "max-property-group",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/max-property-group.png",
  },
  {
    name: "TrueFlip",
    symbol: "TFL",
    slug: "trueflip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trueflip.png",
  },
  {
    name: "EDC Blockchain",
    symbol: "EDC",
    slug: "edc-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edc-blockchain.png",
  },
  {
    name: "Add.xyz",
    symbol: "ADD",
    slug: "add-xyz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/add-xyz.png",
  },
  {
    name: "DAPS Coin",
    symbol: "DAPS",
    slug: "daps-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daps-coin.png",
  },
  {
    name: "Patientory",
    symbol: "PTOY",
    slug: "patientory",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/patientory.png",
  },
  {
    name: "ADAMANT Messenger",
    symbol: "ADM",
    slug: "adamant-messenger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adamant-messenger.png",
  },
  {
    name: "Veil",
    symbol: "VEIL",
    slug: "veil",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veil.png",
  },
  {
    name: "Xensor",
    symbol: "XSR",
    slug: "xensor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xensor.png",
  },
  {
    name: "HollyGold",
    symbol: "HGOLD",
    slug: "hollygold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hollygold.png",
  },
  {
    name: "MenaPay",
    symbol: "MPAY",
    slug: "menapay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/menapay.png",
  },
  {
    name: "AI Doctor",
    symbol: "AIDOC",
    slug: "aidoc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aidoc.png",
  },
  {
    name: "MoonTrust",
    symbol: "MNTT",
    slug: "moontrust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moontrust.png",
  },
  {
    name: "JustBet",
    symbol: "WINR",
    slug: "justbet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/justbet.png",
  },
  {
    name: "Bitcashpay",
    symbol: "BCP",
    slug: "bitcashpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcashpay.png",
  },
  {
    name: "TurtleNetwork",
    symbol: "TN",
    slug: "turtlenetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turtlenetwork.png",
  },
  {
    name: "HOMIHELP",
    symbol: "HOMI",
    slug: "homihelp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/homihelp.png",
  },
  {
    name: "Phoenixcoin",
    symbol: "PXC",
    slug: "phoenixcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenixcoin.png",
  },
  {
    name: "Substratum",
    symbol: "SUB",
    slug: "substratum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/substratum.png",
  },
  {
    name: "Pepemon Pepeballs",
    symbol: "PPBLZ",
    slug: "pepemon-pepeballs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pepemon-pepeballs.png",
  },
  {
    name: "Atmos",
    symbol: "ATMOS",
    slug: "atmos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atmos.png",
  },
  {
    name: "BonusCloud",
    symbol: "BXC",
    slug: "bonuscloud",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonuscloud.png",
  },
  {
    name: "Hush",
    symbol: "HUSH",
    slug: "hush",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hush.png",
  },
  {
    name: "XcelToken Plus",
    symbol: "XLAB",
    slug: "xceltoken-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xceltoken-plus.png",
  },
  {
    name: "Remme",
    symbol: "REM",
    slug: "remme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/remme.png",
  },
  {
    name: "TENT",
    symbol: "TENT",
    slug: "tent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tent.png",
  },
  {
    name: "pEOS",
    symbol: "PEOS",
    slug: "peos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peos.png",
  },
  {
    name: "Bean Cash",
    symbol: "BITB",
    slug: "bean-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bean-cash.png",
  },
  {
    name: "Treat DAO",
    symbol: "TREAT",
    slug: "treat-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/treat-dao.png",
  },
  {
    name: "Azbit",
    symbol: "AZ",
    slug: "azbit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/azbit.png",
  },
  {
    name: "Trollcoin",
    symbol: "TROLL",
    slug: "trollcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trollcoin.png",
  },
  {
    name: "PHI Token",
    symbol: "PHI",
    slug: "phi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phi-token.png",
  },
  {
    name: "Bitcoin Atom",
    symbol: "BCA",
    slug: "bitcoin-atom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-atom.png",
  },
  {
    name: "FujiCoin",
    symbol: "FJC",
    slug: "fujicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fujicoin.png",
  },
  {
    name: "Minereum",
    symbol: "MNE",
    slug: "minereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minereum.png",
  },
  {
    name: "Jupiter",
    symbol: "JUP",
    slug: "jupiter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jupiter.png",
  },
  {
    name: "Scry.info",
    symbol: "DDD",
    slug: "scryinfo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scryinfo.png",
  },
  {
    name: "Jarvis Network",
    symbol: "JRT",
    slug: "jarvis-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jarvis-network.png",
  },
  {
    name: "Genesis Shards",
    symbol: "GS",
    slug: "genesis-shards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesis-shards.png",
  },
  {
    name: "Pizza",
    symbol: "PIZZA",
    slug: "pizza",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pizza.png",
  },
  {
    name: "VegaWallet Token",
    symbol: "VGW",
    slug: "vegawallet-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vegawallet-token.png",
  },
  {
    name: "Tap",
    symbol: "XTP",
    slug: "tap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tap.png",
  },
  {
    name: "Alphacat",
    symbol: "ACAT",
    slug: "alphacat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alphacat.png",
  },
  {
    name: "Pigeoncoin",
    symbol: "PGN",
    slug: "pigeoncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pigeoncoin.png",
  },
  {
    name: "Lobstex",
    symbol: "LOBS",
    slug: "lobstex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lobstex.png",
  },
  {
    name: "DAV Coin",
    symbol: "DAV",
    slug: "dav-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dav-coin.png",
  },
  {
    name: "Insured Finance",
    symbol: "INFI",
    slug: "insured-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insured-finance.png",
  },
  {
    name: "KeyFi",
    symbol: "KEYFI",
    slug: "keyfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keyfi.png",
  },
  {
    name: "Creaticles",
    symbol: "CRE8",
    slug: "creaticles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creaticles.png",
  },
  {
    name: "Open Governance Token",
    symbol: "OPEN",
    slug: "open-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/open-governance-token.png",
  },
  {
    name: "Auxilium",
    symbol: "AUX",
    slug: "auxilium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/auxilium.png",
  },
  {
    name: "Chainswap",
    symbol: "ASAP",
    slug: "chainswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainswap.png",
  },
  {
    name: "Lepricon",
    symbol: "L3P",
    slug: "lepricon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lepricon.png",
  },
  {
    name: "Nsure.Network",
    symbol: "NSURE",
    slug: "nsure-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nsure-network.png",
  },
  {
    name: "Titan Coin",
    symbol: "TTN",
    slug: "titan-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/titan-coin.png",
  },
  {
    name: "ColossusXT",
    symbol: "COLX",
    slug: "colossusxt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/colossusxt.png",
  },
  {
    name: "Professional Fighters League Fan Token",
    symbol: "PFL",
    slug: "professional-fighters-league-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/professional-fighters-league-fan-token.png",
  },
  {
    name: "Global Cryptocurrency",
    symbol: "GCC",
    slug: "global-cryptocurrency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-cryptocurrency.png",
  },
  {
    name: "Hashgard",
    symbol: "GARD",
    slug: "hashgard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashgard.png",
  },
  {
    name: "ZENZO",
    symbol: "ZNZ",
    slug: "zenzo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenzo.png",
  },
  {
    name: "Hyperion",
    symbol: "HYN",
    slug: "hyperion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperion.png",
  },
  {
    name: "Beaxy",
    symbol: "BXY",
    slug: "beaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beaxy.png",
  },
  {
    name: "ChatCoin",
    symbol: "CHAT",
    slug: "chatcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chatcoin.png",
  },
  {
    name: "Kineko",
    symbol: "KKO",
    slug: "kineko",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kineko.png",
  },
  {
    name: "Lendefi",
    symbol: "LDFI",
    slug: "lendefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lendefi.png",
  },
  {
    name: "ZClassic",
    symbol: "ZCL",
    slug: "zclassic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zclassic.png",
  },
  {
    name: "RealTract",
    symbol: "RET",
    slug: "realtract",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realtract.png",
  },
  {
    name: "EOS TRUST",
    symbol: "EOST",
    slug: "eos-trust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eos-trust.png",
  },
  {
    name: "Curecoin",
    symbol: "CURE",
    slug: "curecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/curecoin.png",
  },
  {
    name: "Smoothy",
    symbol: "SMTY",
    slug: "smoothy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smoothy.png",
  },
  {
    name: "Venus BCH",
    symbol: "vBCH",
    slug: "venus-bch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-bch.png",
  },
  {
    name: "Shield Protocol",
    symbol: "SHIELD",
    slug: "shield-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shield-protocol.png",
  },
  {
    name: "DiFy.Finance",
    symbol: "YFIII",
    slug: "dify-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dify-finance.png",
  },
  {
    name: "Fluity",
    symbol: "FLTY",
    slug: "fluity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fluity.png",
  },
  {
    name: "MotaCoin",
    symbol: "MOTA",
    slug: "motacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/motacoin.png",
  },
  {
    name: "Multiplier",
    symbol: "BMXX",
    slug: "bmultiplier",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bmultiplier.png",
  },
  {
    name: "fyeth.finance",
    symbol: "YETH",
    slug: "fyeth-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fyeth-finance.png",
  },
  {
    name: "BOMB",
    symbol: "BOMB",
    slug: "bomb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bomb.png",
  },
  {
    name: "Penta",
    symbol: "PNT",
    slug: "penta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/penta.png",
  },
  {
    name: "ParkinGo",
    symbol: "GOT",
    slug: "parkingo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parkingo.png",
  },
  {
    name: "KIWIGO",
    symbol: "KGO",
    slug: "kiwigo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kiwigo.png",
  },
  {
    name: "Humaniq",
    symbol: "HMQ",
    slug: "humaniq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/humaniq.png",
  },
  {
    name: "Nexalt",
    symbol: "XLT",
    slug: "nexalt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nexalt.png",
  },
  {
    name: "Xaurum",
    symbol: "XAUR",
    slug: "xaurum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xaurum.png",
  },
  {
    name: "Enigma",
    symbol: "ENG",
    slug: "enigma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enigma.png",
  },
  {
    name: "Lead Wallet",
    symbol: "LEAD",
    slug: "lead-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lead-wallet.png",
  },
  {
    name: "DexKit",
    symbol: "KIT",
    slug: "dexkit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexkit.png",
  },
  {
    name: "REPO",
    symbol: "REPO",
    slug: "repo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/repo.png",
  },
  {
    name: "Acute Angle Cloud",
    symbol: "AAC",
    slug: "acute-angle-cloud",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/acute-angle-cloud.png",
  },
  {
    name: "I/O Coin",
    symbol: "IOC",
    slug: "iocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iocoin.png",
  },
  {
    name: "Aryacoin",
    symbol: "AYA",
    slug: "aryacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aryacoin.png",
  },
  {
    name: "Swapcoinz",
    symbol: "SPAZ",
    slug: "swapcoinz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swapcoinz.png",
  },
  {
    name: "Governor DAO",
    symbol: "GDAO",
    slug: "governor-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/governor-dao.png",
  },
  {
    name: "Moneytoken",
    symbol: "IMT",
    slug: "moneytoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moneytoken.png",
  },
  {
    name: "Graviocoin",
    symbol: "GIO",
    slug: "graviocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/graviocoin.png",
  },
  {
    name: "ZUSD",
    symbol: "ZUSD",
    slug: "zusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zusd.png",
  },
  {
    name: "CryptEx",
    symbol: "CRX",
    slug: "cryptex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptex.png",
  },
  {
    name: "Plair",
    symbol: "PLA",
    slug: "plair",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plair.png",
  },
  {
    name: "MEET.ONE",
    symbol: "MEETONE",
    slug: "meetone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meetone.png",
  },
  {
    name: "xSigma",
    symbol: "SIG",
    slug: "xsigma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xsigma.png",
  },
  {
    name: "AidCoin",
    symbol: "AID",
    slug: "aidcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aidcoin.png",
  },
  {
    name: "nYFI",
    symbol: "N0031",
    slug: "nyfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nyfi.png",
  },
  {
    name: "Starbase",
    symbol: "STAR",
    slug: "starbase",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starbase.png",
  },
  {
    name: "adbank",
    symbol: "ADB",
    slug: "adbank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adbank.png",
  },
  {
    name: "AmonD",
    symbol: "AMON",
    slug: "amond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amond.png",
  },
  {
    name: "Cornichon",
    symbol: "CORN",
    slug: "cornichon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cornichon.png",
  },
  {
    name: "AICHAIN",
    symbol: "AIT",
    slug: "aichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aichain.png",
  },
  {
    name: "HiCoin",
    symbol: "XHI",
    slug: "hicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hicoin.png",
  },
  {
    name: "VeriCoin",
    symbol: "VRC",
    slug: "vericoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vericoin.png",
  },
  {
    name: "Bitcoin Plus",
    symbol: "XBC",
    slug: "bitcoin-plus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-plus.png",
  },
  {
    name: "Machi X",
    symbol: "MCX",
    slug: "machix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/machix.png",
  },
  {
    name: "1X2 COIN",
    symbol: "1X2",
    slug: "1x2-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1x2-coin.png",
  },
  {
    name: "e-Gulden",
    symbol: "EFL",
    slug: "e-gulden",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/e-gulden.png",
  },
  {
    name: "PIXEL",
    symbol: "PXL",
    slug: "pixel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pixel.png",
  },
  {
    name: "Genesis Worlds",
    symbol: "GENESIS",
    slug: "genesis-worlds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesis-worlds.png",
  },
  {
    name: "HodlTree",
    symbol: "HTRE",
    slug: "hodltree",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hodltree.png",
  },
  {
    name: "STATERA",
    symbol: "STA",
    slug: "statera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/statera.png",
  },
  {
    name: "Memetic / PepeCoin",
    symbol: "MEME",
    slug: "memetic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/memetic.png",
  },
  {
    name: "Yield Optimization Platform & Protocol",
    symbol: "YOP",
    slug: "yop",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yop.png",
  },
  {
    name: "2key.network",
    symbol: "2KEY",
    slug: "2key-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/2key-network.png",
  },
  {
    name: "BeatzCoin",
    symbol: "BTZC",
    slug: "beatzcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beatzcoin.png",
  },
  {
    name: "Ceres",
    symbol: "CERES",
    slug: "ceres",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ceres.png",
  },
  {
    name: "CloakCoin",
    symbol: "CLOAK",
    slug: "cloakcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cloakcoin.png",
  },
  {
    name: "WinStars.live",
    symbol: "WNL",
    slug: "winstars-live",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/winstars-live.png",
  },
  {
    name: "SparkPoint Fuel",
    symbol: "SFUEL",
    slug: "sparkpoint-fuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sparkpoint-fuel.png",
  },
  {
    name: "PANTHEON X",
    symbol: "XPN",
    slug: "pantheon-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pantheon-x.png",
  },
  {
    name: "WOWswap",
    symbol: "WOW",
    slug: "wowswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wowswap.png",
  },
  {
    name: "Internxt",
    symbol: "INXT",
    slug: "internxt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/internxt.png",
  },
  {
    name: "Tokes",
    symbol: "TKS",
    slug: "tokes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokes.png",
  },
  {
    name: "Ariva",
    symbol: "ARV",
    slug: "ariva",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ariva.png",
  },
  {
    name: "Evedo",
    symbol: "EVED",
    slug: "evedo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evedo.png",
  },
  {
    name: "Isiklar Coin",
    symbol: "ISIKC",
    slug: "isiklar-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/isiklar-coin.png",
  },
  {
    name: "StakedZEN",
    symbol: "STZEN",
    slug: "stakedzen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakedzen.png",
  },
  {
    name: "eosDAC",
    symbol: "EOSDAC",
    slug: "eosdac",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eosdac.png",
  },
  {
    name: "Perth Mint Gold Token",
    symbol: "PMGT",
    slug: "perth-mint-gold-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/perth-mint-gold-token.png",
  },
  {
    name: "SnowSwap",
    symbol: "SNOW",
    slug: "snowswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/snowswap.png",
  },
  {
    name: "ZooKeeper",
    symbol: "ZOO",
    slug: "zookeeper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zookeeper.png",
  },
  {
    name: "Young Boys Fan Token",
    symbol: "YBO",
    slug: "young-boys-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/young-boys-fan-token.png",
  },
  {
    name: "TokenPay",
    symbol: "TPAY",
    slug: "tokenpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenpay.png",
  },
  {
    name: "ORAO Network",
    symbol: "ORAO",
    slug: "orao-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orao-network.png",
  },
  {
    name: "Ronin Gamez",
    symbol: "RONINGMZ",
    slug: "ronin-gamez",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ronin-gamez.png",
  },
  {
    name: "Club Atletico Independiente",
    symbol: "CAI",
    slug: "club-atletico-independiente",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/club-atletico-independiente.png",
  },
  {
    name: "AMATEN",
    symbol: "AMA",
    slug: "amaten",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amaten.png",
  },
  {
    name: "CHADS VC",
    symbol: "CHADS",
    slug: "chads-vc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chads-vc.png",
  },
  {
    name: "Digital Reserve Currency",
    symbol: "DRC",
    slug: "digital-reserve-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digital-reserve-currency.png",
  },
  {
    name: "DePay",
    symbol: "DEPAY",
    slug: "depay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/depay.png",
  },
  {
    name: "Buying.com",
    symbol: "WBUY",
    slug: "buying-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/buying-com.png",
  },
  {
    name: "YEE",
    symbol: "YEE",
    slug: "yee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yee.png",
  },
  {
    name: "ScPrime",
    symbol: "SCP",
    slug: "scprime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scprime.png",
  },
  {
    name: "Node Runners",
    symbol: "NDR",
    slug: "node-runners",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/node-runners.png",
  },
  {
    name: "Sumokoin",
    symbol: "SUMO",
    slug: "sumokoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sumokoin.png",
  },
  {
    name: "Dimecoin",
    symbol: "DIME",
    slug: "dimecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dimecoin.png",
  },
  {
    name: "Cappasity",
    symbol: "CAPP",
    slug: "cappasity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cappasity.png",
  },
  {
    name: "Zebi Token",
    symbol: "ZEBI",
    slug: "zebi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zebi-token.png",
  },
  {
    name: "Phoenix Token",
    symbol: "PHX",
    slug: "phoenix-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenix-token.png",
  },
  {
    name: "DMScript",
    symbol: "DMST",
    slug: "dmscript",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dmscript.png",
  },
  {
    name: "GHOSTPRISM",
    symbol: "GHOST",
    slug: "ghostprism",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghostprism.png",
  },
  {
    name: "Hydro",
    symbol: "HYDRO",
    slug: "hydro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hydro.png",
  },
  {
    name: "Ideaology",
    symbol: "IDEA",
    slug: "ideaology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ideaology.png",
  },
  {
    name: "Signata",
    symbol: "SATA",
    slug: "signata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/signata.png",
  },
  {
    name: "GAPS",
    symbol: "GAP",
    slug: "gaps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gaps.png",
  },
  {
    name: "Darwinia Commitment Token",
    symbol: "KTON",
    slug: "darwinia-commitment-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darwinia-commitment-token.png",
  },
  {
    name: "Collateral Pay",
    symbol: "COLL",
    slug: "collateral-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/collateral-pay.png",
  },
  {
    name: "Golden Goose",
    symbol: "GOLD",
    slug: "golden-goose",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golden-goose.png",
  },
  {
    name: "Clube Atlético Mineiro Fan Token",
    symbol: "GALO",
    slug: "clube-atletico-mineiro-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clube-atletico-mineiro-fan-token.png",
  },
  {
    name: "Ulord",
    symbol: "UT",
    slug: "ulord",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ulord.png",
  },
  {
    name: "QuadrantProtocol",
    symbol: "EQUAD",
    slug: "quadrantprotocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quadrantprotocol.png",
  },
  {
    name: "Kuai Token",
    symbol: "KT",
    slug: "kuai-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kuai-token.png",
  },
  {
    name: "CryptoPing",
    symbol: "PING",
    slug: "cryptoping",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoping.png",
  },
  {
    name: "BitCapitalVendor",
    symbol: "BCV",
    slug: "bitcapitalvendor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcapitalvendor.png",
  },
  {
    name: "Crown",
    symbol: "CRW",
    slug: "crown",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crown.png",
  },
  {
    name: "VAULT",
    symbol: "VAULT",
    slug: "vault",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vault.png",
  },
  {
    name: "Skrumble Network",
    symbol: "SKM",
    slug: "skrumble-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skrumble-network.png",
  },
  {
    name: "CPChain",
    symbol: "CPC",
    slug: "cpchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cpchain.png",
  },
  {
    name: "xRhodium",
    symbol: "XRC",
    slug: "xrhodium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrhodium.png",
  },
  {
    name: "Mochimo",
    symbol: "MCM",
    slug: "mochimo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mochimo.png",
  },
  {
    name: "Datum",
    symbol: "DAT",
    slug: "datum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/datum.png",
  },
  {
    name: "Leeds United Fan Token",
    symbol: "LUFC",
    slug: "leeds-united-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leeds-united-fan-token.png",
  },
  {
    name: "Internet of Energy Network",
    symbol: "IOEN",
    slug: "internet-of-energy-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/internet-of-energy-network.png",
  },
  {
    name: "UTU Protocol",
    symbol: "UTU",
    slug: "utu-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utu-protocol.png",
  },
  {
    name: "FairCoin",
    symbol: "FAIR",
    slug: "faircoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/faircoin.png",
  },
  {
    name: "NFT Index",
    symbol: "NFTI",
    slug: "nft-index",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft-index.png",
  },
  {
    name: "Public Mint",
    symbol: "MINT",
    slug: "public-mint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/public-mint.png",
  },
  {
    name: "Bismuth",
    symbol: "BIS",
    slug: "bismuth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bismuth.png",
  },
  {
    name: "NativeCoin",
    symbol: "N8V",
    slug: "native-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/native-coin.png",
  },
  {
    name: "PolkaBridge",
    symbol: "PBR",
    slug: "polkabridge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkabridge.png",
  },
  {
    name: "BitcoinPoS",
    symbol: "BPS",
    slug: "bitcoinpos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinpos.png",
  },
  {
    name: "MeDIA eYe",
    symbol: "EYE",
    slug: "media-eye",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/media-eye.png",
  },
  {
    name: "BitTube",
    symbol: "TUBE",
    slug: "bit-tube",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit-tube.png",
  },
  {
    name: "Infinity Esaham",
    symbol: "INFS",
    slug: "infinity-esaham",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-esaham.png",
  },
  {
    name: "PLANET",
    symbol: "PLA",
    slug: "planet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/planet.png",
  },
  {
    name: "Lisk Machine Learning",
    symbol: "LML",
    slug: "lisk-machine-learning",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lisk-machine-learning.png",
  },
  {
    name: "Signature Chain",
    symbol: "SIGN",
    slug: "signature-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/signature-chain.png",
  },
  {
    name: "Lunes",
    symbol: "LUNES",
    slug: "lunes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lunes.png",
  },
  {
    name: "ZMINE",
    symbol: "ZMN",
    slug: "zmine",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zmine.png",
  },
  {
    name: "Lossless",
    symbol: "LSS",
    slug: "lossless",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lossless.png",
  },
  {
    name: "AntiMatter",
    symbol: "MATTER",
    slug: "antimatter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/antimatter.png",
  },
  {
    name: "Beacon",
    symbol: "BECN",
    slug: "beacon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beacon.png",
  },
  {
    name: "Team Heretics Fan Token",
    symbol: "TH",
    slug: "team-heretics-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/team-heretics-fan-token.png",
  },
  {
    name: "ETHA Lend",
    symbol: "ETHA",
    slug: "etha-lend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etha-lend.png",
  },
  {
    name: "Zerogoki USD",
    symbol: "zUSD",
    slug: "zerogoki-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zerogoki-usd.png",
  },
  {
    name: "Polyient Games Governance Token",
    symbol: "PGT",
    slug: "polyient-games-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyient-games-governance-token.png",
  },
  {
    name: "Content Neutrality Network",
    symbol: "CNN",
    slug: "content-neutrality-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/content-neutrality-network.png",
  },
  {
    name: "Mcashchain",
    symbol: "MCASH",
    slug: "mcashchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mcashchain.png",
  },
  {
    name: "Waves Community Token",
    symbol: "WCT",
    slug: "waves-community-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waves-community-token.png",
  },
  {
    name: "MobileGo",
    symbol: "MGO",
    slug: "mobilego",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobilego.png",
  },
  {
    name: "QANplatform",
    symbol: "QARK",
    slug: "qanplatform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qanplatform.png",
  },
  {
    name: "Aventus",
    symbol: "AVT",
    slug: "aventus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aventus.png",
  },
  {
    name: "Patron",
    symbol: "PAT",
    slug: "patron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/patron.png",
  },
  {
    name: "KuboCoin",
    symbol: "KUBO",
    slug: "kubocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kubocoin.png",
  },
  {
    name: "HEAT",
    symbol: "HEAT",
    slug: "heat-ledger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/heat-ledger.png",
  },
  {
    name: "Tidex Token",
    symbol: "TDX",
    slug: "tidex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tidex-token.png",
  },
  {
    name: "Shadows",
    symbol: "DOWS",
    slug: "shadows",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shadows.png",
  },
  {
    name: "Playcent",
    symbol: "PCNT",
    slug: "playcent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playcent.png",
  },
  {
    name: "Honest",
    symbol: "HNST",
    slug: "honest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/honest.png",
  },
  {
    name: "Ixcoin",
    symbol: "IXC",
    slug: "ixcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ixcoin.png",
  },
  {
    name: "PayPie",
    symbol: "PPP",
    slug: "paypie",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paypie.png",
  },
  {
    name: "Inex Project",
    symbol: "INEX",
    slug: "inex-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/inex-project.png",
  },
  {
    name: "Kalata",
    symbol: "KALA",
    slug: "kalata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kalata.png",
  },
  {
    name: "Squirrel Finance",
    symbol: "NUTS",
    slug: "squirrel-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/squirrel-finance.png",
  },
  {
    name: "Pascal",
    symbol: "PASC",
    slug: "pascal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pascal.png",
  },
  {
    name: "SmartCredit Token",
    symbol: "SMARTCREDIT",
    slug: "smartcredit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartcredit-token.png",
  },
  {
    name: "Denarius",
    symbol: "D",
    slug: "denarius-d",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/denarius-d.png",
  },
  {
    name: "OKCash",
    symbol: "OK",
    slug: "okcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okcash.png",
  },
  {
    name: "CEEK VR",
    symbol: "CEEK",
    slug: "ceek-vr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ceek-vr.png",
  },
  {
    name: "Verso Token",
    symbol: "VSO",
    slug: "verso-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verso-token.png",
  },
  {
    name: "LINKA",
    symbol: "LINKA",
    slug: "linka",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linka.png",
  },
  {
    name: "Omni",
    symbol: "OMNI",
    slug: "omni",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omni.png",
  },
  {
    name: "Fabric",
    symbol: "FAB",
    slug: "fabric",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fabric.png",
  },
  {
    name: "Hertz Network",
    symbol: "HTZ",
    slug: "hertz-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hertz-network.png",
  },
  {
    name: "Falconswap",
    symbol: "FSW",
    slug: "fsw-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fsw-token.png",
  },
  {
    name: "Pinkcoin",
    symbol: "PINK",
    slug: "pinkcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pinkcoin.png",
  },
  {
    name: "MyWish",
    symbol: "WISH",
    slug: "mywish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mywish.png",
  },
  {
    name: "Emercoin",
    symbol: "EMC",
    slug: "emercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emercoin.png",
  },
  {
    name: "Nyancoin",
    symbol: "NYAN",
    slug: "nyancoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nyancoin.png",
  },
  {
    name: "BiFi",
    symbol: "BIFI",
    slug: "bifi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bifi.png",
  },
  {
    name: "Pylon Finance",
    symbol: "PYLON",
    slug: "pylon-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pylon-finance.png",
  },
  {
    name: "Doki Doki Finance",
    symbol: "DOKI",
    slug: "doki-doki-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doki-doki-finance.png",
  },
  {
    name: "BitGreen",
    symbol: "BITG",
    slug: "bitgreen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitgreen.png",
  },
  {
    name: "Vipstar Coin",
    symbol: "VIPS",
    slug: "vipstar-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vipstar-coin.png",
  },
  {
    name: "ILCOIN",
    symbol: "ILC",
    slug: "ilcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ilcoin.png",
  },
  {
    name: "Mochi Market",
    symbol: "MOMA",
    slug: "mochi-market",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mochi-market.png",
  },
  {
    name: "SOTA Finance",
    symbol: "SOTA",
    slug: "sota-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sota-finance.png",
  },
  {
    name: "Amon",
    symbol: "AMN",
    slug: "amon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amon.png",
  },
  {
    name: "Experty",
    symbol: "EXY",
    slug: "experty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/experty.png",
  },
  {
    name: "Faceter",
    symbol: "FACE",
    slug: "faceter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/faceter.png",
  },
  {
    name: "ChangeNOW Token",
    symbol: "NOW",
    slug: "now-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/now-token.png",
  },
  {
    name: "NuShares",
    symbol: "NSR",
    slug: "nushares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nushares.png",
  },
  {
    name: "Origo",
    symbol: "OGO",
    slug: "origo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/origo.png",
  },
  {
    name: "Carbon",
    symbol: "CRBN",
    slug: "carbon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbon.png",
  },
  {
    name: "CryptoFranc",
    symbol: "XCHF",
    slug: "cryptofranc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptofranc.png",
  },
  {
    name: "Apollon Limassol",
    symbol: "APL",
    slug: "apollon-limassol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apollon-limassol.png",
  },
  {
    name: "Energo",
    symbol: "TSL",
    slug: "energo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energo.png",
  },
  {
    name: "Fractal",
    symbol: "FCL",
    slug: "fractal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fractal.png",
  },
  {
    name: "MASQ",
    symbol: "MASQ",
    slug: "masq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/masq.png",
  },
  {
    name: "Citadel.one",
    symbol: "XCT",
    slug: "citadel-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/citadel-one.png",
  },
  {
    name: "DMM: Governance",
    symbol: "DMG",
    slug: "dmm-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dmm-governance.png",
  },
  {
    name: "Crust Shadow",
    symbol: "CSM",
    slug: "crust-shadow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crust-shadow.png",
  },
  {
    name: "BOX Token",
    symbol: "BOX",
    slug: "box-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/box-token.png",
  },
  {
    name: "Falcon Project",
    symbol: "FNT",
    slug: "falcon-project",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/falcon-project.png",
  },
  {
    name: "Digitex",
    symbol: "DGTX",
    slug: "digitex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digitex.png",
  },
  {
    name: "UGAS",
    symbol: "UGAS",
    slug: "ugas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ugas.png",
  },
  {
    name: "UniFarm",
    symbol: "UFARM",
    slug: "unifarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unifarm.png",
  },
  {
    name: "Galaxy Blitz",
    symbol: "MIT",
    slug: "galaxy-blitz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxy-blitz.png",
  },
  {
    name: "PolkaWar",
    symbol: "PWAR",
    slug: "polkawar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkawar.png",
  },
  {
    name: "Tadpole Finance",
    symbol: "TAD",
    slug: "tadpole-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tadpole-finance.png",
  },
  {
    name: "SuperLauncher",
    symbol: "LAUNCH",
    slug: "superlauncher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superlauncher.png",
  },
  {
    name: "Cred",
    symbol: "LBA",
    slug: "libra-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/libra-credit.png",
  },
  {
    name: "Education Ecosystem",
    symbol: "LEDU",
    slug: "education-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/education-ecosystem.png",
  },
  {
    name: "Chainge",
    symbol: "CHNG",
    slug: "chainge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainge.png",
  },
  {
    name: "Smart MFG",
    symbol: "MFG",
    slug: "smart-mfg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smart-mfg.png",
  },
  {
    name: "TopBidder",
    symbol: "BID",
    slug: "topbidder",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/topbidder.png",
  },
  {
    name: "VINchain",
    symbol: "VIN",
    slug: "vinchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vinchain.png",
  },
  {
    name: "AXPR",
    symbol: "AXPR",
    slug: "axpr-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axpr-token.png",
  },
  {
    name: "DFSocial Gaming",
    symbol: "DFSOCIAL",
    slug: "defisocial-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defisocial-gaming.png",
  },
  {
    name: "Flixxo",
    symbol: "FLIXX",
    slug: "flixxo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flixxo.png",
  },
  {
    name: "Dracula Token",
    symbol: "DRC",
    slug: "dracula-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dracula-token.png",
  },
  {
    name: "ContentBox",
    symbol: "BOX",
    slug: "contentbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/contentbox.png",
  },
  {
    name: "Catheon Gaming",
    symbol: "CATHEON",
    slug: "catheon-gaming",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/catheon-gaming.png",
  },
  {
    name: "Coldstack",
    symbol: "CLS",
    slug: "coldstack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coldstack.png",
  },
  {
    name: "Xfinance",
    symbol: "XFI",
    slug: "xfinance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xfinance.png",
  },
  {
    name: "Umbrella Network",
    symbol: "UMB",
    slug: "umbrella-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/umbrella-network.png",
  },
  {
    name: "FedoraCoin",
    symbol: "TIPS",
    slug: "fedoracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fedoracoin.png",
  },
  {
    name: "FairGame",
    symbol: "FAIR",
    slug: "fairgame",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fairgame.png",
  },
  {
    name: "XMON",
    symbol: "XMON",
    slug: "xmon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xmon.png",
  },
  {
    name: "YGGDRASH",
    symbol: "YEED",
    slug: "yeed",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yeed.png",
  },
  {
    name: "Redpanda Earth",
    symbol: "REDPANDA",
    slug: "redpanda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redpanda.png",
  },
  {
    name: "YOU COIN",
    symbol: "YOU",
    slug: "you-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/you-coin.png",
  },
  {
    name: "iEthereum",
    symbol: "IETH",
    slug: "iethereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iethereum.png",
  },
  {
    name: "Bird.Money",
    symbol: "BIRD",
    slug: "bird-money",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bird-money.png",
  },
  {
    name: "SINOVATE",
    symbol: "SIN",
    slug: "sinovate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sinovate.png",
  },
  {
    name: "Raze Network",
    symbol: "RAZE",
    slug: "raze-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raze-network.png",
  },
  {
    name: "Indexed Finance",
    symbol: "NDX",
    slug: "indexed-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/indexed-finance.png",
  },
  {
    name: "EOSDT",
    symbol: "EOSDT",
    slug: "eosdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eosdt.png",
  },
  {
    name: "Peanut",
    symbol: "NUX",
    slug: "peanut",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peanut.png",
  },
  {
    name: "SEEN",
    symbol: "SEEN",
    slug: "seen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seen.png",
  },
  {
    name: "Waifu Token",
    symbol: "WAIF",
    slug: "waifu-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waifu-token.png",
  },
  {
    name: "BlackCoin",
    symbol: "BLK",
    slug: "blackcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackcoin.png",
  },
  {
    name: "APYSwap",
    symbol: "APYS",
    slug: "apyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apyswap.png",
  },
  {
    name: "Gem Exchange And Trading",
    symbol: "GXT",
    slug: "gem-exchange-and-trading",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gem-exchange-and-trading.png",
  },
  {
    name: "DFSocial Gaming",
    symbol: "DFSG",
    slug: "defisocial-gaming-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defisocial-gaming-new.png",
  },
  {
    name: "TheForce Trade",
    symbol: "FOC",
    slug: "theforce-trade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/theforce-trade.png",
  },
  {
    name: "Tokoin",
    symbol: "TOKO",
    slug: "tokoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokoin.png",
  },
  {
    name: "0xBitcoin",
    symbol: "0xBTC",
    slug: "0xbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0xbtc.png",
  },
  {
    name: "World Token",
    symbol: "WORLD",
    slug: "world-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/world-token.png",
  },
  {
    name: "Standard Protocol",
    symbol: "STND",
    slug: "standard-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/standard-protocol.png",
  },
  {
    name: "HempCoin",
    symbol: "THC",
    slug: "hempcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hempcoin.png",
  },
  {
    name: "Netbox Coin",
    symbol: "NBX",
    slug: "netbox-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/netbox-coin.png",
  },
  {
    name: "DeFi Yield Protocol",
    symbol: "DYP",
    slug: "defi-yield-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-yield-protocol.png",
  },
  {
    name: "Golff",
    symbol: "GOF",
    slug: "golff",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golff.png",
  },
  {
    name: "Multiplier",
    symbol: "MXX",
    slug: "multiplier",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multiplier.png",
  },
  {
    name: "SoMee.Social",
    symbol: "ONG",
    slug: "ongsocial",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ongsocial.png",
  },
  {
    name: "Vetri",
    symbol: "VLD",
    slug: "vetri",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vetri.png",
  },
  {
    name: "Growth DeFi",
    symbol: "GRO",
    slug: "growthdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/growthdefi.png",
  },
  {
    name: "YFDAI.FINANCE",
    symbol: "YF-DAI",
    slug: "yfdai-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yfdai-finance.png",
  },
  {
    name: "Filecash",
    symbol: "FIC",
    slug: "filecash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/filecash.png",
  },
  {
    name: "Pawtocol",
    symbol: "UPI",
    slug: "pawtocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pawtocol.png",
  },
  {
    name: "Unido EP",
    symbol: "UDO",
    slug: "unido",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unido.png",
  },
  {
    name: "TenUp",
    symbol: "TUP",
    slug: "tenup",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tenup.png",
  },
  {
    name: "Iconic Token",
    symbol: "ICNQ",
    slug: "iconic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iconic-token.png",
  },
  {
    name: "PhoenixDAO",
    symbol: "PHNX",
    slug: "phoenixdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenixdao.png",
  },
  {
    name: "KoHo Chain",
    symbol: "KHC",
    slug: "koho-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/koho-chain.png",
  },
  {
    name: "Cloudbric",
    symbol: "CLBK",
    slug: "cloudbric",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cloudbric.png",
  },
  {
    name: "Chonk",
    symbol: "CHONK",
    slug: "chonk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chonk.png",
  },
  {
    name: "EarnX",
    symbol: "EARNX",
    slug: "earnx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earnx.png",
  },
  {
    name: "Centaur",
    symbol: "CNTR",
    slug: "centaur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centaur.png",
  },
  {
    name: "Aurox",
    symbol: "URUS",
    slug: "urus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/urus.png",
  },
  {
    name: "Fuse Network",
    symbol: "FUSE",
    slug: "fuse-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fuse-network.png",
  },
  {
    name: "PlayFuel",
    symbol: "PLF",
    slug: "playfuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/playfuel.png",
  },
  {
    name: "Xend Finance",
    symbol: "XEND",
    slug: "xend-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xend-finance.png",
  },
  {
    name: "Essentia",
    symbol: "ESS",
    slug: "essentia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/essentia.png",
  },
  {
    name: "SonoCoin",
    symbol: "SONO",
    slug: "sonocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sonocoin.png",
  },
  {
    name: "UCA Coin",
    symbol: "UCA",
    slug: "uca-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uca-coin.png",
  },
  {
    name: "yAxis",
    symbol: "YAXIS",
    slug: "yaxis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yaxis.png",
  },
  {
    name: "Cryptaur",
    symbol: "CPT",
    slug: "cryptaur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptaur.png",
  },
  {
    name: "Parachute",
    symbol: "PAR",
    slug: "parachute",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parachute.png",
  },
  {
    name: "FidexToken",
    symbol: "FEX",
    slug: "fidex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fidex-token.png",
  },
  {
    name: "Moontography",
    symbol: "MTGY",
    slug: "moontography",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moontography.png",
  },
  {
    name: "Atlas Protocol",
    symbol: "ATP",
    slug: "atlas-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atlas-protocol.png",
  },
  {
    name: "Open Platform",
    symbol: "OPEN",
    slug: "open-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/open-platform.png",
  },
  {
    name: "Fastcoin",
    symbol: "FST",
    slug: "fastcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fastcoin.png",
  },
  {
    name: "Litex",
    symbol: "LXT",
    slug: "litex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litex.png",
  },
  {
    name: "SafeCoin",
    symbol: "SAFE",
    slug: "safecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safecoin.png",
  },
  {
    name: "ETNA Network",
    symbol: "ETNA",
    slug: "etna-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etna-network.png",
  },
  {
    name: "Ravencoin Classic",
    symbol: "RVC",
    slug: "ravencoin-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ravencoin-classic.png",
  },
  {
    name: "CoTrader",
    symbol: "COT",
    slug: "cotrader",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cotrader.png",
  },
  {
    name: "Q DAO Governance token v1.0",
    symbol: "QDAO",
    slug: "q-dao-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/q-dao-governance-token.png",
  },
  {
    name: "BitcoinZ",
    symbol: "BTCZ",
    slug: "bitcoinz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinz.png",
  },
  {
    name: "Portion",
    symbol: "PRT",
    slug: "portion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/portion.png",
  },
  {
    name: "EXRNchain",
    symbol: "EXRN",
    slug: "exrnchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exrnchain.png",
  },
  {
    name: "Endor Protocol",
    symbol: "EDR",
    slug: "endor-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/endor-protocol.png",
  },
  {
    name: "Natus Vincere Fan Token",
    symbol: "NAVI",
    slug: "natus-vincere-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/natus-vincere-fan-token.png",
  },
  {
    name: "FOTA - Fight Of The Ages",
    symbol: "FOTA",
    slug: "fota",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fota.png",
  },
  {
    name: "APIX",
    symbol: "APIX",
    slug: "apix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apix.png",
  },
  {
    name: "XMax",
    symbol: "XMX",
    slug: "xmax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xmax.png",
  },
  {
    name: "Argon",
    symbol: "ARGON",
    slug: "argon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/argon.png",
  },
  {
    name: "Bezant",
    symbol: "BZNT",
    slug: "bezant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bezant.png",
  },
  {
    name: "DragonVein",
    symbol: "DVC",
    slug: "dragonvein",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonvein.png",
  },
  {
    name: "Deri Protocol",
    symbol: "DERI",
    slug: "deri-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deri-protocol.png",
  },
  {
    name: "Flamengo Fan Token",
    symbol: "MENGO",
    slug: "flamengo-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flamengo-fan-token.png",
  },
  {
    name: "Eden",
    symbol: "EDN",
    slug: "eden",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eden.png",
  },
  {
    name: "Zenfuse",
    symbol: "ZEFU",
    slug: "zenfuse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenfuse.png",
  },
  {
    name: "PUBLISH",
    symbol: "NEWS",
    slug: "publish",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/publish.png",
  },
  {
    name: "OTOCASH",
    symbol: "OTO",
    slug: "otocash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/otocash.png",
  },
  {
    name: "Taraxa",
    symbol: "TARA",
    slug: "taraxa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/taraxa.png",
  },
  {
    name: "SolanaSail Governance Token",
    symbol: "GSAIL",
    slug: "solanasail-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solanasail-governance-token.png",
  },
  {
    name: "1World",
    symbol: "1WO",
    slug: "1world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1world.png",
  },
  {
    name: "AMEPAY",
    symbol: "AME",
    slug: "amepay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amepay.png",
  },
  {
    name: "SaTT",
    symbol: "SATT",
    slug: "satt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/satt.png",
  },
  {
    name: "Stream Protocol",
    symbol: "STPL",
    slug: "stream-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stream-protocol.png",
  },
  {
    name: "SunContract",
    symbol: "SNC",
    slug: "suncontract",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/suncontract.png",
  },
  {
    name: "Unitrade",
    symbol: "TRADE",
    slug: "unitrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unitrade.png",
  },
  {
    name: "Tidal Finance",
    symbol: "TIDAL",
    slug: "tidal-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tidal-finance.png",
  },
  {
    name: "Conceal",
    symbol: "CCX",
    slug: "conceal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/conceal.png",
  },
  {
    name: "Dev Protocol",
    symbol: "DEV",
    slug: "dev-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dev-protocol.png",
  },
  {
    name: "Unicly Hashmasks Collection",
    symbol: "UMASK",
    slug: "unicly-hashmasks-collection",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicly-hashmasks-collection.png",
  },
  {
    name: "42-coin",
    symbol: "42",
    slug: "42-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/42-coin.png",
  },
  {
    name: "Ethereum Meta",
    symbol: "ETHM",
    slug: "ethereum-meta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-meta.png",
  },
  {
    name: "Tripio",
    symbol: "TRIO",
    slug: "tripio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tripio.png",
  },
  {
    name: "Shar Pei",
    symbol: "PUPPY",
    slug: "shar-pei-puppy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shar-pei-puppy.png",
  },
  {
    name: "Offshift",
    symbol: "XFT",
    slug: "offshift",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/offshift.png",
  },
  {
    name: "AGAr",
    symbol: "AGAR",
    slug: "agar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agar.png",
  },
  {
    name: "τBitcoin",
    symbol: "ΤBTC",
    slug: "t-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/t-bitcoin.png",
  },
  {
    name: "DOC.COM",
    symbol: "MTC",
    slug: "doc-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doc-com.png",
  },
  {
    name: "Ycash",
    symbol: "YEC",
    slug: "ycash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ycash.png",
  },
  {
    name: "Sint-Truidense Voetbalvereniging Fan Token",
    symbol: "STV",
    slug: "sint-truidense-voetbalvereniging",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sint-truidense-voetbalvereniging.png",
  },
  {
    name: "PotCoin",
    symbol: "POT",
    slug: "potcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/potcoin.png",
  },
  {
    name: "NextDAO",
    symbol: "NAX",
    slug: "nextdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nextdao.png",
  },
  {
    name: "BlackHat",
    symbol: "BLKC",
    slug: "blackhat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blackhat.png",
  },
  {
    name: "DEXA COIN",
    symbol: "DEXA",
    slug: "dexa-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexa-coin.png",
  },
  {
    name: "Stealth",
    symbol: "XST",
    slug: "stealth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stealth.png",
  },
  {
    name: "Project WITH",
    symbol: "WIKEN",
    slug: "project-with",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-with.png",
  },
  {
    name: "Mettalex",
    symbol: "MTLX",
    slug: "mettalex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mettalex.png",
  },
  {
    name: "CafeSwap Token",
    symbol: "BREW",
    slug: "cafeswap-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cafeswap-token.png",
  },
  {
    name: "Alpaca Finance",
    symbol: "ALPACA",
    slug: "alpaca-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpaca-finance.png",
  },
  {
    name: "ASKO",
    symbol: "ASKO",
    slug: "askobar-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/askobar-network.png",
  },
  {
    name: "TERA",
    symbol: "TERA",
    slug: "tera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tera.png",
  },
  {
    name: "Lightning Bitcoin",
    symbol: "LBTC",
    slug: "lightning-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lightning-bitcoin.png",
  },
  {
    name: "SakeToken",
    symbol: "SAKE",
    slug: "sake-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sake-token.png",
  },
  {
    name: "Raven Protocol",
    symbol: "RAVEN",
    slug: "raven-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raven-protocol.png",
  },
  {
    name: "Arsenal Fan Token",
    symbol: "AFC",
    slug: "arsenal-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arsenal-fan-token.png",
  },
  {
    name: "Ares Protocol",
    symbol: "ARES",
    slug: "ares-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ares-protocol.png",
  },
  {
    name: "ThreeFold",
    symbol: "TFT",
    slug: "threefold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/threefold.png",
  },
  {
    name: "Spheroid Universe",
    symbol: "SPH",
    slug: "spheroid-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spheroid-universe.png",
  },
  {
    name: "Ditto",
    symbol: "DITTO",
    slug: "ditto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ditto.png",
  },
  {
    name: "Medicalchain",
    symbol: "MTN",
    slug: "medical-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medical-chain.png",
  },
  {
    name: "BitBall",
    symbol: "BTB",
    slug: "bitball",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitball.png",
  },
  {
    name: "EDUCare",
    symbol: "EKT",
    slug: "educare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/educare.png",
  },
  {
    name: "carVertical",
    symbol: "CV",
    slug: "carvertical",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carvertical.png",
  },
  {
    name: "Birake",
    symbol: "BIR",
    slug: "birake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/birake.png",
  },
  {
    name: "Auctus",
    symbol: "AUC",
    slug: "auctus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/auctus.png",
  },
  {
    name: "Arata",
    symbol: "ARATA",
    slug: "arata",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arata.png",
  },
  {
    name: "DAOstack",
    symbol: "GEN",
    slug: "daostack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daostack.png",
  },
  {
    name: "Pluton",
    symbol: "PLU",
    slug: "pluton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pluton.png",
  },
  {
    name: "Global Social Chain",
    symbol: "GSC",
    slug: "global-social-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/global-social-chain.png",
  },
  {
    name: "TransferCoin",
    symbol: "TX",
    slug: "transfercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/transfercoin.png",
  },
  {
    name: "Game.com",
    symbol: "GTC",
    slug: "game",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/game.png",
  },
  {
    name: "Defina Finance",
    symbol: "FINA",
    slug: "defina-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defina-finance.png",
  },
  {
    name: "Scala",
    symbol: "XLA",
    slug: "scala",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/scala.png",
  },
  {
    name: "Garlicoin",
    symbol: "GRLC",
    slug: "garlicoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/garlicoin.png",
  },
  {
    name: "Virtue Poker",
    symbol: "VPP",
    slug: "virtue-poker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/virtue-poker.png",
  },
  {
    name: "Databroker",
    symbol: "DTX",
    slug: "databroker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/databroker.png",
  },
  {
    name: "Seele-N",
    symbol: "SEELE",
    slug: "seele",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seele.png",
  },
  {
    name: "Merculet",
    symbol: "MVP",
    slug: "merculet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/merculet.png",
  },
  {
    name: "Swace",
    symbol: "SWACE",
    slug: "swace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swace.png",
  },
  {
    name: "Genaro Network",
    symbol: "GNX",
    slug: "genaro-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genaro-network.png",
  },
  {
    name: "U Network",
    symbol: "UUU",
    slug: "u-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/u-network.png",
  },
  {
    name: "Forefront",
    symbol: "FF",
    slug: "forefront",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forefront.png",
  },
  {
    name: "OIN Finance",
    symbol: "OIN",
    slug: "oin-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oin-finance.png",
  },
  {
    name: "Metis",
    symbol: "METIS",
    slug: "metisdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metisdao.png",
  },
  {
    name: "BOLT",
    symbol: "BOLT",
    slug: "bolt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bolt.png",
  },
  {
    name: "NAOS Finance",
    symbol: "NAOS",
    slug: "naos-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/naos-finance.png",
  },
  {
    name: "RING X PLATFORM",
    symbol: "RINGX",
    slug: "ring-x-platform",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ring-x-platform.png",
  },
  {
    name: "GeoDB",
    symbol: "GEO",
    slug: "geodb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geodb.png",
  },
  {
    name: "Big Data Protocol",
    symbol: "BDP",
    slug: "big-data-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/big-data-protocol.png",
  },
  {
    name: "Poolz Finance",
    symbol: "POOLZ",
    slug: "poolz-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poolz-finance.png",
  },
  {
    name: "Primecoin",
    symbol: "XPM",
    slug: "primecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/primecoin.png",
  },
  {
    name: "FantomStarter",
    symbol: "FS",
    slug: "fantomstarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantomstarter.png",
  },
  {
    name: "Maple",
    symbol: "MPL",
    slug: "maple",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maple.png",
  },
  {
    name: "CONUN",
    symbol: "CON",
    slug: "conun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/conun.png",
  },
  {
    name: "Insureum",
    symbol: "ISR",
    slug: "insureum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insureum.png",
  },
  {
    name: "DAEX",
    symbol: "DAX",
    slug: "daex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/daex.png",
  },
  {
    name: "Beyond Finance",
    symbol: "BYN",
    slug: "beyond-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beyond-finance.png",
  },
  {
    name: "Etho Protocol",
    symbol: "ETHO",
    slug: "ether-1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ether-1.png",
  },
  {
    name: "Wings",
    symbol: "WINGS",
    slug: "wings",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wings.png",
  },
  {
    name: "MetagamZ",
    symbol: "METAG",
    slug: "metagamz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metagamz.png",
  },
  {
    name: "Hiveterminal Token",
    symbol: "HVN",
    slug: "hiveterminal-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hiveterminal-token.png",
  },
  {
    name: "Bonded Finance",
    symbol: "BOND",
    slug: "bonded-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonded-finance.png",
  },
  {
    name: "SYNC Network",
    symbol: "SYNC",
    slug: "sync-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sync-network.png",
  },
  {
    name: "S4FE",
    symbol: "S4F",
    slug: "s4fe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/s4fe.png",
  },
  {
    name: "Rune Shards",
    symbol: "RXS",
    slug: "rune-shards",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rune-shards.png",
  },
  {
    name: "MIR COIN",
    symbol: "MIR",
    slug: "mir-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mir-coin.png",
  },
  {
    name: "HorusPay",
    symbol: "HORUS",
    slug: "horuspay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/horuspay.png",
  },
  {
    name: "Unicly Mystic Axies Collection",
    symbol: "UAXIE",
    slug: "unicly-mystic-axies-collection",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicly-mystic-axies-collection.png",
  },
  {
    name: "Razor Network",
    symbol: "RAZOR",
    slug: "razor-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/razor-network.png",
  },
  {
    name: "eBoost",
    symbol: "EBST",
    slug: "eboostcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eboostcoin.png",
  },
  {
    name: "BIKI",
    symbol: "BIKI",
    slug: "biki",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biki.png",
  },
  {
    name: "Digix Gold Token",
    symbol: "DGX",
    slug: "digix-gold-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digix-gold-token.png",
  },
  {
    name: "PlotX",
    symbol: "PLOT",
    slug: "plotx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plotx.png",
  },
  {
    name: "LCMS",
    symbol: "LCMS",
    slug: "lcms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lcms.png",
  },
  {
    name: "Gridcoin",
    symbol: "GRC",
    slug: "gridcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gridcoin.png",
  },
  {
    name: "Primate",
    symbol: "PRIMATE",
    slug: "primate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/primate.png",
  },
  {
    name: "Footballcoin",
    symbol: "XFC",
    slug: "footballcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/footballcoin.png",
  },
  {
    name: "NFTb",
    symbol: "NFTB",
    slug: "nftb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftb.png",
  },
  {
    name: "Ruff",
    symbol: "RUFF",
    slug: "ruff",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ruff.png",
  },
  {
    name: "CACHE Gold",
    symbol: "CGT",
    slug: "cache-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cache-gold.png",
  },
  {
    name: "yieldwatch",
    symbol: "WATCH",
    slug: "yieldwatch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yieldwatch.png",
  },
  {
    name: "Monavale",
    symbol: "MONA",
    slug: "monavale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monavale.png",
  },
  {
    name: "Aitra",
    symbol: "AITRA",
    slug: "aitra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aitra.png",
  },
  {
    name: "VeriDocGlobal",
    symbol: "VDG",
    slug: "veridocglobal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veridocglobal.png",
  },
  {
    name: "SmartMesh",
    symbol: "SMT",
    slug: "smartmesh",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartmesh.png",
  },
  {
    name: "MandoX V2",
    symbol: "MANDOX",
    slug: "mandox-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mandox-v2.png",
  },
  {
    name: "Nyzo",
    symbol: "NYZO",
    slug: "nyzo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nyzo.png",
  },
  {
    name: "NaPoleonX",
    symbol: "NPX",
    slug: "napoleonx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/napoleonx.png",
  },
  {
    name: "Alchemy Pay",
    symbol: "ACH",
    slug: "alchemy-pay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alchemy-pay.png",
  },
  {
    name: "Polkacover",
    symbol: "CVR",
    slug: "polkacover",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkacover.png",
  },
  {
    name: "FIBOS",
    symbol: "FO",
    slug: "fibos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fibos.png",
  },
  {
    name: "MurAll",
    symbol: "PAINT",
    slug: "murall",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/murall.png",
  },
  {
    name: "Eminer",
    symbol: "EM",
    slug: "eminer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eminer.png",
  },
  {
    name: "beFITTER",
    symbol: "FIU",
    slug: "befitter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/befitter.png",
  },
  {
    name: "Unification",
    symbol: "FUND",
    slug: "unification",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unification.png",
  },
  {
    name: "Basis Cash",
    symbol: "BAC",
    slug: "basis-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basis-cash.png",
  },
  {
    name: "Spore",
    symbol: "SPORE",
    slug: "spore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spore.png",
  },
  {
    name: "YF Link",
    symbol: "YFL",
    slug: "yflink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yflink.png",
  },
  {
    name: "Kira Network",
    symbol: "KEX",
    slug: "kira-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kira-network.png",
  },
  {
    name: "FortKnoxster",
    symbol: "FKX",
    slug: "fortknoxster",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fortknoxster.png",
  },
  {
    name: "Blockzero Labs",
    symbol: "XIO",
    slug: "blockzerolabs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockzerolabs.png",
  },
  {
    name: "INT",
    symbol: "INT",
    slug: "int-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/int-chain.png",
  },
  {
    name: "Quark",
    symbol: "QRK",
    slug: "quark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quark.png",
  },
  {
    name: "BinaryX",
    symbol: "BNX",
    slug: "binaryx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binaryx.png",
  },
  {
    name: "ASPO World",
    symbol: "ASPO",
    slug: "aspo-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aspo-world.png",
  },
  {
    name: "xSuter",
    symbol: "XSUTER",
    slug: "xsuter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xsuter.png",
  },
  {
    name: "Duck DAO (DLP Duck Token)",
    symbol: "DUCK",
    slug: "duck-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duck-dao.png",
  },
  {
    name: "Pundi X NEM",
    symbol: "NPXSXEM",
    slug: "pundi-x-nem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pundi-x-nem.png",
  },
  {
    name: "MahaDAO",
    symbol: "MAHA",
    slug: "mahadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mahadao.png",
  },
  {
    name: "Spiking",
    symbol: "SPIKE",
    slug: "spiking",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spiking.png",
  },
  {
    name: "Gleec",
    symbol: "GLEEC",
    slug: "gleec",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gleec.png",
  },
  {
    name: "HitChain",
    symbol: "HIT",
    slug: "hitchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hitchain.png",
  },
  {
    name: "HEROcoin",
    symbol: "PLAY",
    slug: "herocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/herocoin.png",
  },
  {
    name: "Kcash",
    symbol: "KCASH",
    slug: "kcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kcash.png",
  },
  {
    name: "Blockchain Cuties Universe Governance",
    symbol: "BCUG",
    slug: "blockchain-cuties-universe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockchain-cuties-universe.png",
  },
  {
    name: "Phore",
    symbol: "PHR",
    slug: "phore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phore.png",
  },
  {
    name: "OceanEx Token",
    symbol: "OCE",
    slug: "oceanex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oceanex-token.png",
  },
  {
    name: "UniLayer",
    symbol: "LAYER",
    slug: "unilayer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unilayer.png",
  },
  {
    name: "Airbloc",
    symbol: "ABL",
    slug: "airbloc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/airbloc.png",
  },
  {
    name: "bitCNY",
    symbol: "BITCNY",
    slug: "bitcny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcny.png",
  },
  {
    name: "Non-Fungible Yearn",
    symbol: "NFY",
    slug: "non-fungible-yearn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/non-fungible-yearn.png",
  },
  {
    name: "hybrix",
    symbol: "HY",
    slug: "hybrix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hybrix.png",
  },
  {
    name: "SynchroBitcoin",
    symbol: "SNB",
    slug: "synchrobitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synchrobitcoin.png",
  },
  {
    name: "Prosper",
    symbol: "PROS",
    slug: "prosper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prosper.png",
  },
  {
    name: "CRD Network",
    symbol: "CRD",
    slug: "crdnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crdnetwork.png",
  },
  {
    name: "LABS Group",
    symbol: "LABS",
    slug: "labs-group",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/labs-group.png",
  },
  {
    name: "Starname",
    symbol: "IOV",
    slug: "starname",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/starname.png",
  },
  {
    name: "Meter Governance",
    symbol: "MTRG",
    slug: "meter-governance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meter-governance.png",
  },
  {
    name: "OneLedger",
    symbol: "OLT",
    slug: "oneledger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oneledger.png",
  },
  {
    name: "Astrafer",
    symbol: "ASTRAFER",
    slug: "astrafer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astrafer.png",
  },
  {
    name: "Fatcoin",
    symbol: "FAT",
    slug: "fatcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fatcoin.png",
  },
  {
    name: "Breezecoin",
    symbol: "BRZE",
    slug: "breezecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/breezecoin.png",
  },
  {
    name: "VestChain",
    symbol: "VEST",
    slug: "vestchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vestchain.png",
  },
  {
    name: "Venus DOT",
    symbol: "vDOT",
    slug: "venus-dot",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-dot.png",
  },
  {
    name: "Burency",
    symbol: "BUY",
    slug: "burency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burency.png",
  },
  {
    name: "Matrix AI Network",
    symbol: "MAN",
    slug: "matrix-ai-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/matrix-ai-network.png",
  },
  {
    name: "SingularDTV",
    symbol: "SNGLS",
    slug: "singulardtv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/singulardtv.png",
  },
  {
    name: "SHIBAVAX",
    symbol: "SHIBX",
    slug: "shibavax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shibavax.png",
  },
  {
    name: "Swarm",
    symbol: "SWM",
    slug: "swarm-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swarm-network.png",
  },
  {
    name: "Nord Finance",
    symbol: "NORD",
    slug: "nord-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nord-finance.png",
  },
  {
    name: "Geeq",
    symbol: "GEEQ",
    slug: "geeq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/geeq.png",
  },
  {
    name: "QunQun",
    symbol: "QUN",
    slug: "qunqun",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qunqun.png",
  },
  {
    name: "UniMex Network",
    symbol: "UMX",
    slug: "unimex-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unimex-network.png",
  },
  {
    name: "CryptoPlanes",
    symbol: "CPAN",
    slug: "cryptoplanes",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoplanes.png",
  },
  {
    name: "LuaSwap",
    symbol: "LUA",
    slug: "lua-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lua-token.png",
  },
  {
    name: "SIRIN LABS Token",
    symbol: "SRN",
    slug: "sirin-labs-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sirin-labs-token.png",
  },
  {
    name: "BOOM",
    symbol: "BOOM",
    slug: "boom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boom.png",
  },
  {
    name: "Green Ben",
    symbol: "EBEN",
    slug: "green-ben",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-ben.png",
  },
  {
    name: "TronEuropeRewardCoin",
    symbol: "TERC",
    slug: "troneuroperewardcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/troneuroperewardcoin.png",
  },
  {
    name: "BLink",
    symbol: "BLINK",
    slug: "blink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blink.png",
  },
  {
    name: "Idena",
    symbol: "IDNA",
    slug: "idena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idena.png",
  },
  {
    name: "Hyve",
    symbol: "HYVE",
    slug: "hyve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyve.png",
  },
  {
    name: "Lendingblock",
    symbol: "LND",
    slug: "lendingblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lendingblock.png",
  },
  {
    name: "DOS Network",
    symbol: "DOS",
    slug: "dos-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dos-network.png",
  },
  {
    name: "Horizon Protocol",
    symbol: "HZN",
    slug: "horizon-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/horizon-protocol.png",
  },
  {
    name: "Shard",
    symbol: "SHARD",
    slug: "shard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shard.png",
  },
  {
    name: "Feathercoin",
    symbol: "FTC",
    slug: "feathercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/feathercoin.png",
  },
  {
    name: "Leverj Gluon",
    symbol: "L2",
    slug: "leverj-gluon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/leverj-gluon.png",
  },
  {
    name: "ZIMBOCASH",
    symbol: "ZASH",
    slug: "zimbocash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zimbocash.png",
  },
  {
    name: "Credits",
    symbol: "CS",
    slug: "credits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/credits.png",
  },
  {
    name: "Blocery",
    symbol: "BLY",
    slug: "blocery",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocery.png",
  },
  {
    name: "YOYOW",
    symbol: "YOYOW",
    slug: "yoyow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yoyow.png",
  },
  {
    name: "NuBits",
    symbol: "USNBT",
    slug: "nubits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nubits.png",
  },
  {
    name: "Seedify.fund",
    symbol: "SFUND",
    slug: "seedify-fund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/seedify-fund.png",
  },
  {
    name: "SwftCoin",
    symbol: "SWFTC",
    slug: "swftcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swftcoin.png",
  },
  {
    name: "Neurotoken",
    symbol: "NTK",
    slug: "neurotoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neurotoken.png",
  },
  {
    name: "Dragon Kart",
    symbol: "KART",
    slug: "dragon-kart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragon-kart.png",
  },
  {
    name: "Bibox Token",
    symbol: "BIX",
    slug: "bibox-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bibox-token.png",
  },
  {
    name: "WebDollar",
    symbol: "WEBD",
    slug: "webdollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/webdollar.png",
  },
  {
    name: "Apron Network",
    symbol: "APN",
    slug: "apron-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apron-network.png",
  },
  {
    name: "Effect.AI",
    symbol: "EFX",
    slug: "effect-ai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/effect-ai.png",
  },
  {
    name: "DigitalNote",
    symbol: "XDN",
    slug: "digitalnote",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digitalnote.png",
  },
  {
    name: "BitNautic Token",
    symbol: "BTNT",
    slug: "bitnautic-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitnautic-token.png",
  },
  {
    name: "Circuits of Value",
    symbol: "COVAL",
    slug: "circuits-of-value",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/circuits-of-value.png",
  },
  {
    name: "LinkEye",
    symbol: "LET",
    slug: "linkeye",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linkeye.png",
  },
  {
    name: "Qitmeer",
    symbol: "PMEER",
    slug: "qitmeer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qitmeer.png",
  },
  {
    name: "Rotharium",
    symbol: "RTH",
    slug: "rotharium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rotharium.png",
  },
  {
    name: "IoT Chain",
    symbol: "ITC",
    slug: "iot-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iot-chain.png",
  },
  {
    name: "Graphlinq Protocol",
    symbol: "GLQ",
    slug: "graphlinq-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/graphlinq-protocol.png",
  },
  {
    name: "EvidenZ",
    symbol: "BCDT",
    slug: "evidenz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/evidenz.png",
  },
  {
    name: "Plian",
    symbol: "PI",
    slug: "pchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pchain.png",
  },
  {
    name: "Galaxy Heroes Coin",
    symbol: "GHC",
    slug: "galaxyheroescoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galaxyheroescoin.png",
  },
  {
    name: "DAFI Protocol",
    symbol: "DAFI",
    slug: "dafi-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dafi-protocol.png",
  },
  {
    name: "Adora Token",
    symbol: "ARA",
    slug: "adora-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adora-token.png",
  },
  {
    name: "DATA",
    symbol: "DTA",
    slug: "data",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/data.png",
  },
  {
    name: "CoinPoker",
    symbol: "CHP",
    slug: "coinpoker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinpoker.png",
  },
  {
    name: "OG Fan Token",
    symbol: "OG",
    slug: "og-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/og-fan-token.png",
  },
  {
    name: "ROOBEE",
    symbol: "ROOBEE",
    slug: "roobee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/roobee.png",
  },
  {
    name: "Nestree",
    symbol: "EGG",
    slug: "nestree",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nestree.png",
  },
  {
    name: "NFT",
    symbol: "NFT",
    slug: "nft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nft.png",
  },
  {
    name: "BSC Station",
    symbol: "BSCS",
    slug: "bsc-station",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bsc-station.png",
  },
  {
    name: "DeepOnion",
    symbol: "ONION",
    slug: "deeponion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deeponion.png",
  },
  {
    name: "Solanium",
    symbol: "SLIM",
    slug: "solanium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solanium.png",
  },
  {
    name: "Mysterium",
    symbol: "MYST",
    slug: "mysterium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mysterium.png",
  },
  {
    name: "Valor Token",
    symbol: "VALOR",
    slug: "valor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valor-token.png",
  },
  {
    name: "Linker Coin",
    symbol: "LNC",
    slug: "linker-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linker-coin.png",
  },
  {
    name: "CWV Chain",
    symbol: "CWV",
    slug: "cwv-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cwv-chain.png",
  },
  {
    name: "WePower",
    symbol: "WPR",
    slug: "wepower",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wepower.png",
  },
  {
    name: "Cryptopay",
    symbol: "CPAY",
    slug: "cryptopay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptopay.png",
  },
  {
    name: "SpaceFalcon",
    symbol: "FCON",
    slug: "spacefalcon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacefalcon.png",
  },
  {
    name: "ProximaX",
    symbol: "XPX",
    slug: "proximax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proximax.png",
  },
  {
    name: "Hakka.Finance",
    symbol: "HAKKA",
    slug: "hakka-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hakka-finance.png",
  },
  {
    name: "Egretia",
    symbol: "EGT",
    slug: "egretia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egretia.png",
  },
  {
    name: "THEKEY",
    symbol: "TKY",
    slug: "thekey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thekey.png",
  },
  {
    name: "PumaPay",
    symbol: "PMA",
    slug: "pumapay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pumapay.png",
  },
  {
    name: "Rupiah Token",
    symbol: "IDRT",
    slug: "rupiah-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rupiah-token.png",
  },
  {
    name: "Venus DAI",
    symbol: "vDAI",
    slug: "venus-dai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-dai.png",
  },
  {
    name: "Xaya",
    symbol: "CHI",
    slug: "xaya",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xaya.png",
  },
  {
    name: "Monolith",
    symbol: "TKN",
    slug: "monolith",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monolith.png",
  },
  {
    name: "Earneo",
    symbol: "RNO",
    slug: "earneo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/earneo.png",
  },
  {
    name: "Hord",
    symbol: "HORD",
    slug: "hord",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hord.png",
  },
  {
    name: "Innovation Blockchain Payment",
    symbol: "IBP",
    slug: "innovation-blockchain-payment",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/innovation-blockchain-payment.png",
  },
  {
    name: "Mogul Productions",
    symbol: "STARS",
    slug: "mogul-productions",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mogul-productions.png",
  },
  {
    name: "Decentr",
    symbol: "DEC",
    slug: "decentr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentr.png",
  },
  {
    name: "Epic Cash",
    symbol: "EPIC",
    slug: "epic-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/epic-cash.png",
  },
  {
    name: "smARTOFGIVING",
    symbol: "AOG",
    slug: "smartofgiving",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartofgiving.png",
  },
  {
    name: "HyperDAO",
    symbol: "HDAO",
    slug: "hyperdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyperdao.png",
  },
  {
    name: "MediShares",
    symbol: "MDS",
    slug: "medishares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medishares.png",
  },
  {
    name: "Vexanium",
    symbol: "VEX",
    slug: "vexanium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vexanium.png",
  },
  {
    name: "Trias Token (new)",
    symbol: "TRIAS",
    slug: "trias-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trias-token.png",
  },
  {
    name: "Cyclone Protocol",
    symbol: "CYC",
    slug: "cyclone-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyclone-protocol.png",
  },
  {
    name: "PolkaFoundry",
    symbol: "PKF",
    slug: "polkafoundry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkafoundry.png",
  },
  {
    name: "QLC Chain",
    symbol: "QLC",
    slug: "qlink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qlink.png",
  },
  {
    name: "Unistake",
    symbol: "UNISTAKE",
    slug: "unistake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unistake.png",
  },
  {
    name: "ExNetwork Token",
    symbol: "EXNT",
    slug: "exnetwork-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exnetwork-token.png",
  },
  {
    name: "AAX Token",
    symbol: "AAB",
    slug: "aax-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aax-token.png",
  },
  {
    name: "Einsteinium",
    symbol: "EMC2",
    slug: "einsteinium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/einsteinium.png",
  },
  {
    name: "Safex Token",
    symbol: "SFT",
    slug: "safex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safex-token.png",
  },
  {
    name: "Mobilian Coin",
    symbol: "MBN",
    slug: "mobilian-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobilian-coin.png",
  },
  {
    name: "Abyss",
    symbol: "ABYSS",
    slug: "abyss",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abyss.png",
  },
  {
    name: "Plasma Finance",
    symbol: "PPAY",
    slug: "plasma-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/plasma-finance.png",
  },
  {
    name: "Kattana",
    symbol: "KTN",
    slug: "kattana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kattana.png",
  },
  {
    name: "ownix",
    symbol: "ONX",
    slug: "ownix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ownix.png",
  },
  {
    name: "Orient Walt",
    symbol: "HTDF",
    slug: "orient-walt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orient-walt.png",
  },
  {
    name: "StackOs",
    symbol: "STACK",
    slug: "stackos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stackos.png",
  },
  {
    name: "Vidya",
    symbol: "VIDYA",
    slug: "vidya",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vidya.png",
  },
  {
    name: "Zuki Moba",
    symbol: "ZUKI",
    slug: "zuki-moba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zuki-moba.png",
  },
  {
    name: "TrustVerse",
    symbol: "TRV",
    slug: "trustverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustverse.png",
  },
  {
    name: "PieDAO DOUGH v2",
    symbol: "DOUGH",
    slug: "piedao-dough-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/piedao-dough-v2.png",
  },
  {
    name: "Equalizer",
    symbol: "EQZ",
    slug: "equalizer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/equalizer.png",
  },
  {
    name: "Myriad",
    symbol: "XMY",
    slug: "myriad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myriad.png",
  },
  {
    name: "BNBPay",
    symbol: "BPAY",
    slug: "bnbpay",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnbpay.png",
  },
  {
    name: "K21",
    symbol: "K21",
    slug: "k21",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/k21.png",
  },
  {
    name: "Peony",
    symbol: "PNY",
    slug: "peony",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peony.png",
  },
  {
    name: "Wall Street Games",
    symbol: "WSG",
    slug: "wall-street-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wall-street-games.png",
  },
  {
    name: "Noku",
    symbol: "NOKU",
    slug: "noku",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/noku.png",
  },
  {
    name: "Zloadr",
    symbol: "ZDR",
    slug: "zloadr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zloadr.png",
  },
  {
    name: "UnlimitedIP",
    symbol: "UIP",
    slug: "unlimitedip",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unlimitedip.png",
  },
  {
    name: "EXMO Coin",
    symbol: "EXM",
    slug: "exmo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exmo-coin.png",
  },
  {
    name: "Loser Coin",
    symbol: "LOWB",
    slug: "loser-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loser-coin.png",
  },
  {
    name: "Pepe Cash",
    symbol: "PEPECASH",
    slug: "pepe-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pepe-cash.png",
  },
  {
    name: "PowerTrade Fuel",
    symbol: "PTF",
    slug: "powertrade-fuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/powertrade-fuel.png",
  },
  {
    name: "Curate",
    symbol: "XCUR",
    slug: "curate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/curate.png",
  },
  {
    name: "Ambrosus",
    symbol: "AMB",
    slug: "amber",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amber.png",
  },
  {
    name: "UFC Fan Token",
    symbol: "UFC",
    slug: "ufc-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ufc-fan-token.png",
  },
  {
    name: "Opium",
    symbol: "OPIUM",
    slug: "opium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opium.png",
  },
  {
    name: "Oddz",
    symbol: "ODDZ",
    slug: "oddz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oddz.png",
  },
  {
    name: "OctoFi",
    symbol: "OCTO",
    slug: "octofi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/octofi.png",
  },
  {
    name: "Blockchain Monster Hunt",
    symbol: "BCMC",
    slug: "blockchain-monster-hunt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockchain-monster-hunt.png",
  },
  {
    name: "IG Gold",
    symbol: "IGG",
    slug: "ig-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ig-gold.png",
  },
  {
    name: "Monero Classic",
    symbol: "XMC",
    slug: "monero-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monero-classic.png",
  },
  {
    name: "Trittium",
    symbol: "TRTT",
    slug: "trittium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trittium.png",
  },
  {
    name: "Whiteheart",
    symbol: "WHITE",
    slug: "whiteheart",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whiteheart.png",
  },
  {
    name: "StakeCubeCoin",
    symbol: "SCC",
    slug: "stakecubecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakecubecoin.png",
  },
  {
    name: "TurtleCoin",
    symbol: "TRTL",
    slug: "turtlecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/turtlecoin.png",
  },
  {
    name: "Jobchain",
    symbol: "JOB",
    slug: "jobchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/jobchain.png",
  },
  {
    name: "Viberate",
    symbol: "VIB",
    slug: "viberate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viberate.png",
  },
  {
    name: "BitCore",
    symbol: "BTX",
    slug: "bitcore",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcore.png",
  },
  {
    name: "SENSO",
    symbol: "SENSO",
    slug: "senso",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/senso.png",
  },
  {
    name: "Lamden",
    symbol: "TAU",
    slug: "lamden",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lamden.png",
  },
  {
    name: "Opacity",
    symbol: "OPCT",
    slug: "opacity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opacity.png",
  },
  {
    name: "MoonSwap",
    symbol: "MOON",
    slug: "moonswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonswap.png",
  },
  {
    name: "WOM Protocol",
    symbol: "WOM",
    slug: "wom-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wom-protocol.png",
  },
  {
    name: "Autonio",
    symbol: "NIOX",
    slug: "autonio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/autonio.png",
  },
  {
    name: "Elamachain",
    symbol: "ELAMA",
    slug: "elamachain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elamachain.png",
  },
  {
    name: "Pillar",
    symbol: "PLR",
    slug: "pillar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pillar.png",
  },
  {
    name: "Hydro Protocol",
    symbol: "HOT",
    slug: "hydro-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hydro-protocol.png",
  },
  {
    name: "Dora Factory",
    symbol: "DORA",
    slug: "dora-factory",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dora-factory.png",
  },
  {
    name: "Agrello",
    symbol: "DLT",
    slug: "agrello-delta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/agrello-delta.png",
  },
  {
    name: "ReapChain",
    symbol: "REAP",
    slug: "reapchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reapchain.png",
  },
  {
    name: "YAM",
    symbol: "YAM",
    slug: "yam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yam.png",
  },
  {
    name: "Covesting",
    symbol: "COV",
    slug: "covesting",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/covesting.png",
  },
  {
    name: "Hedget",
    symbol: "HGET",
    slug: "hedget",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedget.png",
  },
  {
    name: "Ethereum Push Notification Service",
    symbol: "PUSH",
    slug: "epns",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/epns.png",
  },
  {
    name: "Gate",
    symbol: "GATE",
    slug: "gate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gate.png",
  },
  {
    name: "Saito",
    symbol: "SAITO",
    slug: "saito",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saito.png",
  },
  {
    name: "Wagerr",
    symbol: "WGR",
    slug: "wagerr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wagerr.png",
  },
  {
    name: "Observer",
    symbol: "OBSR",
    slug: "observer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/observer.png",
  },
  {
    name: "High Performance Blockchain",
    symbol: "HPB",
    slug: "high-performance-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/high-performance-blockchain.png",
  },
  {
    name: "Gamium",
    symbol: "GMM",
    slug: "gamium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamium.png",
  },
  {
    name: "EasyFi",
    symbol: "EASY",
    slug: "easyfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/easyfi.png",
  },
  {
    name: "Konomi Network",
    symbol: "KONO",
    slug: "konomi-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/konomi-network.png",
  },
  {
    name: "Grid+",
    symbol: "GRID",
    slug: "grid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grid.png",
  },
  {
    name: "Vanilla Network",
    symbol: "VNLA",
    slug: "vanilla-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vanilla-network.png",
  },
  {
    name: "Insights Network",
    symbol: "INSTAR",
    slug: "insights-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insights-network.png",
  },
  {
    name: "Kambria",
    symbol: "KAT",
    slug: "kambria",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kambria.png",
  },
  {
    name: "Caspian",
    symbol: "CSP",
    slug: "caspian",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/caspian.png",
  },
  {
    name: "YUSRA",
    symbol: "YUSRA",
    slug: "yusra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yusra.png",
  },
  {
    name: "Realio Network",
    symbol: "RIO",
    slug: "realio-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/realio-network.png",
  },
  {
    name: "Ripio Credit Network",
    symbol: "RCN",
    slug: "ripio-credit-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ripio-credit-network.png",
  },
  {
    name: "Achain",
    symbol: "ACT",
    slug: "achain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/achain.png",
  },
  {
    name: "Formation Fi",
    symbol: "FORM",
    slug: "formation-fi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/formation-fi.png",
  },
  {
    name: "Zigcoin",
    symbol: "ZIG",
    slug: "zigcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zigcoin.png",
  },
  {
    name: "AMLT",
    symbol: "AMLT",
    slug: "amlt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amlt.png",
  },
  {
    name: "Diamond",
    symbol: "DMD",
    slug: "diamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond.png",
  },
  {
    name: "CyberMiles",
    symbol: "CMT",
    slug: "cybermiles",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cybermiles.png",
  },
  {
    name: "Dogs Of Elon",
    symbol: "DOE",
    slug: "dogs-of-elon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogs-of-elon.png",
  },
  {
    name: "POA",
    symbol: "POA",
    slug: "poa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poa.png",
  },
  {
    name: "Peculium",
    symbol: "PCL",
    slug: "peculium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peculium.png",
  },
  {
    name: "Ghost",
    symbol: "GHOST",
    slug: "ghost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ghost.png",
  },
  {
    name: "Monetha",
    symbol: "MTH",
    slug: "monetha",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monetha.png",
  },
  {
    name: "Odyssey",
    symbol: "OCN",
    slug: "odyssey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/odyssey.png",
  },
  {
    name: "AnimalGo",
    symbol: "GOM2",
    slug: "animalgo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/animalgo.png",
  },
  {
    name: "HTMLCOIN",
    symbol: "HTML",
    slug: "html-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/html-coin.png",
  },
  {
    name: "Blocknet",
    symbol: "BLOCK",
    slug: "blocknet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blocknet.png",
  },
  {
    name: "Polkamarkets",
    symbol: "POLK",
    slug: "polkamarkets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkamarkets.png",
  },
  {
    name: "Wilder World",
    symbol: "WILD",
    slug: "wilder-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wilder-world.png",
  },
  {
    name: "BioPassport Token",
    symbol: "BIOT",
    slug: "biopassport-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/biopassport-token.png",
  },
  {
    name: "Wownero",
    symbol: "WOW",
    slug: "wownero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wownero.png",
  },
  {
    name: "USDX [Lighthouse]",
    symbol: "USDX",
    slug: "usdx-lighthouse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdx-lighthouse.png",
  },
  {
    name: "CyberFi Token",
    symbol: "CFi",
    slug: "cyberfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyberfi.png",
  },
  {
    name: "Everex",
    symbol: "EVX",
    slug: "everex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everex.png",
  },
  {
    name: "Swarm Markets",
    symbol: "SMT",
    slug: "swarm-markets",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swarm-markets.png",
  },
  {
    name: "Zebec Protocol",
    symbol: "ZBC",
    slug: "zebec-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zebec-protocol.png",
  },
  {
    name: "Pancake Bunny",
    symbol: "BUNNY",
    slug: "pancakebunny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pancakebunny.png",
  },
  {
    name: "UnMarshal",
    symbol: "MARSH",
    slug: "unmarshal-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unmarshal-token.png",
  },
  {
    name: "Kryll",
    symbol: "KRL",
    slug: "kryll",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kryll.png",
  },
  {
    name: "Idle",
    symbol: "IDLE",
    slug: "idle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idle.png",
  },
  {
    name: "AS Roma Fan Token",
    symbol: "ASR",
    slug: "as-roma-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/as-roma-fan-token.png",
  },
  {
    name: "OST",
    symbol: "OST",
    slug: "ost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ost.png",
  },
  {
    name: "Float Protocol",
    symbol: "BANK",
    slug: "float-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/float-protocol.png",
  },
  {
    name: "Tachyon Protocol",
    symbol: "IPX",
    slug: "tachyon-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tachyon-protocol.png",
  },
  {
    name: "Forta",
    symbol: "FORT",
    slug: "forta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/forta.png",
  },
  {
    name: "QuickX Protocol",
    symbol: "QCX",
    slug: "quickx-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quickx-protocol.png",
  },
  {
    name: "CONTRACOIN",
    symbol: "CTCN",
    slug: "contracoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/contracoin.png",
  },
  {
    name: "Eauric",
    symbol: "EAURIC",
    slug: "eauric",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eauric.png",
  },
  {
    name: "EUNO",
    symbol: "EUNO",
    slug: "euno",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/euno.png",
  },
  {
    name: "GAMEE",
    symbol: "GMEE",
    slug: "gamee",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamee.png",
  },
  {
    name: "DIGG",
    symbol: "DIGG",
    slug: "digg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digg.png",
  },
  {
    name: "Dovu",
    symbol: "DOV",
    slug: "dovu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dovu.png",
  },
  {
    name: "CoinUs",
    symbol: "CNUS",
    slug: "coinus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinus.png",
  },
  {
    name: "Adshares",
    symbol: "ADS",
    slug: "adshares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adshares.png",
  },
  {
    name: "Newton",
    symbol: "NEW",
    slug: "newton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newton.png",
  },
  {
    name: "VIBE",
    symbol: "VIBE",
    slug: "vibe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vibe.png",
  },
  {
    name: "Solrise Finance",
    symbol: "SLRS",
    slug: "solrise-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solrise-finance.png",
  },
  {
    name: "Blank Wallet",
    symbol: "BLANK",
    slug: "blank-wallet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blank-wallet.png",
  },
  {
    name: "Folgory Coin",
    symbol: "FLG",
    slug: "folgory-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/folgory-coin.png",
  },
  {
    name: "PolySwarm",
    symbol: "NCT",
    slug: "polyswarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polyswarm.png",
  },
  {
    name: "SmartCash",
    symbol: "SMART",
    slug: "smartcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartcash.png",
  },
  {
    name: "DECOIN",
    symbol: "DTEP",
    slug: "decoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decoin.png",
  },
  {
    name: "Tixl",
    symbol: "TXL",
    slug: "tixl-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tixl-new.png",
  },
  {
    name: "ETHAX",
    symbol: "ETHAX",
    slug: "ethax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethax.png",
  },
  {
    name: "DuckDaoDime",
    symbol: "DDIM",
    slug: "duckdaodime",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/duckdaodime.png",
  },
  {
    name: "Dynamic",
    symbol: "DYN",
    slug: "dynamic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dynamic.png",
  },
  {
    name: "MOVEZ",
    symbol: "MOVEZ",
    slug: "movez",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/movez.png",
  },
  {
    name: "Bridge Oracle",
    symbol: "BRG",
    slug: "bridge-oracle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bridge-oracle.png",
  },
  {
    name: "Phoenix Global (new)",
    symbol: "PHB",
    slug: "phoenix-global-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenix-global-new.png",
  },
  {
    name: "AGA Token",
    symbol: "AGA",
    slug: "aga",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aga.png",
  },
  {
    name: "Ubiq",
    symbol: "UBQ",
    slug: "ubiq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubiq.png",
  },
  {
    name: "DeFiner",
    symbol: "FIN",
    slug: "definer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/definer.png",
  },
  {
    name: "NerveNetwork",
    symbol: "NVT",
    slug: "nervenetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nervenetwork.png",
  },
  {
    name: "X-CASH",
    symbol: "XCASH",
    slug: "x-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/x-cash.png",
  },
  {
    name: "Helmet.insure",
    symbol: "HELMET",
    slug: "helmet-insure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helmet-insure.png",
  },
  {
    name: "Litecoin Cash",
    symbol: "LCC",
    slug: "litecoin-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litecoin-cash.png",
  },
  {
    name: "Mobius",
    symbol: "MOBI",
    slug: "mobius",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobius.png",
  },
  {
    name: "Refinable",
    symbol: "FINE",
    slug: "refinable",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/refinable.png",
  },
  {
    name: "Gameswap",
    symbol: "GSWAP",
    slug: "gameswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gameswap.png",
  },
  {
    name: "Arcblock",
    symbol: "ABT",
    slug: "arcblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arcblock.png",
  },
  {
    name: "Zap",
    symbol: "ZAP",
    slug: "zap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zap.png",
  },
  {
    name: "HAPI",
    symbol: "HAPI",
    slug: "hapi-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hapi-one.png",
  },
  {
    name: "ZBG Token",
    symbol: "ZT",
    slug: "zbg-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zbg-token.png",
  },
  {
    name: "CyberVein",
    symbol: "CVT",
    slug: "cybervein",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cybervein.png",
  },
  {
    name: "Zynecoin",
    symbol: "ZYN",
    slug: "zynecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zynecoin.png",
  },
  {
    name: "PearDAO",
    symbol: "PEX",
    slug: "peardao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peardao.png",
  },
  {
    name: "BOScoin",
    symbol: "BOS",
    slug: "boscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boscoin.png",
  },
  {
    name: "Origin Dollar",
    symbol: "OUSD",
    slug: "origin-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/origin-dollar.png",
  },
  {
    name: "Aeon",
    symbol: "AEON",
    slug: "aeon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aeon.png",
  },
  {
    name: "Rubic",
    symbol: "RBC",
    slug: "rubic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rubic.png",
  },
  {
    name: "suterusu",
    symbol: "SUTER",
    slug: "suterusu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/suterusu.png",
  },
  {
    name: "Swerve",
    symbol: "SWRV",
    slug: "swerve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swerve.png",
  },
  {
    name: "Blockchain Brawlers",
    symbol: "BRWL",
    slug: "blockchain-brawlers",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockchain-brawlers.png",
  },
  {
    name: "Metrix Coin",
    symbol: "MRX",
    slug: "metrix-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metrix-coin.png",
  },
  {
    name: "TOP",
    symbol: "TOP",
    slug: "top",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/top.png",
  },
  {
    name: "Trabzonspor Fan Token",
    symbol: "TRA",
    slug: "trabzonspor-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trabzonspor-fan-token.png",
  },
  {
    name: "BUX Token",
    symbol: "BUX",
    slug: "bux-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bux-token.png",
  },
  {
    name: "ZeroSwap",
    symbol: "ZEE",
    slug: "zeroswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeroswap.png",
  },
  {
    name: "Idavoll Network",
    symbol: "IDV",
    slug: "idavoll-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idavoll-network.png",
  },
  {
    name: "Presearch",
    symbol: "PRE",
    slug: "presearch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/presearch.png",
  },
  {
    name: "GAMB",
    symbol: "GMB",
    slug: "gamb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamb.png",
  },
  {
    name: "OAX",
    symbol: "OAX",
    slug: "oax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oax.png",
  },
  {
    name: "UniCrypt",
    symbol: "UNCX",
    slug: "uncx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uncx.png",
  },
  {
    name: "Stafi",
    symbol: "FIS",
    slug: "stafi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stafi.png",
  },
  {
    name: "Time New Bank",
    symbol: "TNB",
    slug: "time-new-bank",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/time-new-bank.png",
  },
  {
    name: "Safe Haven",
    symbol: "SHA",
    slug: "safe-haven",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe-haven.png",
  },
  {
    name: "TrueFeedBack",
    symbol: "TFBX",
    slug: "truefeedback",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/truefeedback.png",
  },
  {
    name: "Mist",
    symbol: "MIST",
    slug: "mist",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mist.png",
  },
  {
    name: "TrueChain",
    symbol: "TRUE",
    slug: "truechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/truechain.png",
  },
  {
    name: "ClinTex CTi",
    symbol: "CTI",
    slug: "clintex-cti",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clintex-cti.png",
  },
  {
    name: "Era Swap",
    symbol: "ES",
    slug: "era-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/era-swap.png",
  },
  {
    name: "PKT",
    symbol: "PKT",
    slug: "pkt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pkt.png",
  },
  {
    name: "TenX",
    symbol: "PAY",
    slug: "tenx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tenx.png",
  },
  {
    name: "Gulden",
    symbol: "NLG",
    slug: "gulden",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gulden.png",
  },
  {
    name: "Tower",
    symbol: "TOWER",
    slug: "tower-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tower-token.png",
  },
  {
    name: "Cobak Token",
    symbol: "CBK",
    slug: "cobak-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cobak-token.png",
  },
  {
    name: "UpBots",
    symbol: "UBXT",
    slug: "upbots",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/upbots.png",
  },
  {
    name: "apM Coin",
    symbol: "APM",
    slug: "apm-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apm-coin.png",
  },
  {
    name: "Howdoo",
    symbol: "UDOO",
    slug: "hyprr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hyprr.png",
  },
  {
    name: "Metaverse ETP",
    symbol: "ETP",
    slug: "metaverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse.png",
  },
  {
    name: "ICHI",
    symbol: "ICHI",
    slug: "ichi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ichi.png",
  },
  {
    name: "Receive Access Ecosystem",
    symbol: "RAE",
    slug: "receive-access-ecosystem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/receive-access-ecosystem.png",
  },
  {
    name: "Bread",
    symbol: "BRD",
    slug: "bread",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bread.png",
  },
  {
    name: "Spartan Protocol",
    symbol: "SPARTA",
    slug: "spartan-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spartan-protocol.png",
  },
  {
    name: "Beowulf",
    symbol: "BWF",
    slug: "beowulf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beowulf.png",
  },
  {
    name: "IQeon",
    symbol: "IQN",
    slug: "iqeon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iqeon.png",
  },
  {
    name: "Anchor",
    symbol: "ANCT",
    slug: "anchor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anchor.png",
  },
  {
    name: "Decimated",
    symbol: "DIO",
    slug: "decimated",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decimated.png",
  },
  {
    name: "BitcoinHD",
    symbol: "BHD",
    slug: "bitcoinhd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoinhd.png",
  },
  {
    name: "RioDeFi",
    symbol: "RFUEL",
    slug: "rio-defi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rio-defi.png",
  },
  {
    name: "Viacoin",
    symbol: "VIA",
    slug: "viacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/viacoin.png",
  },
  {
    name: "Sylo",
    symbol: "SYLO",
    slug: "sylo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sylo.png",
  },
  {
    name: "Oraichain Token",
    symbol: "ORAI",
    slug: "oraichain-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oraichain-token.png",
  },
  {
    name: "BEMIL Coin",
    symbol: "BEM",
    slug: "bemil-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bemil-coin.png",
  },
  {
    name: "Venus LINK",
    symbol: "vLINK",
    slug: "venus-link",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-link.png",
  },
  {
    name: "Pendle",
    symbol: "PENDLE",
    slug: "pendle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pendle.png",
  },
  {
    name: "e-Money",
    symbol: "NGM",
    slug: "e-money-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/e-money-coin.png",
  },
  {
    name: "Venus LTC",
    symbol: "vLTC",
    slug: "venus-ltc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-ltc.png",
  },
  {
    name: "CVCoin",
    symbol: "CVN",
    slug: "cvcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cvcoin.png",
  },
  {
    name: "Ternoa",
    symbol: "CAPS",
    slug: "ternoa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ternoa.png",
  },
  {
    name: "Nucleus Vision",
    symbol: "NCASH",
    slug: "nucleus-vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nucleus-vision.png",
  },
  {
    name: "The Midas Touch Gold",
    symbol: "TMTG",
    slug: "the-midas-touch-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-midas-touch-gold.png",
  },
  {
    name: "Carbon Credit",
    symbol: "CCT",
    slug: "carbon-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carbon-credit.png",
  },
  {
    name: "Era Token (Era7)",
    symbol: "ERA",
    slug: "era-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/era-token.png",
  },
  {
    name: "Lympo",
    symbol: "LYM",
    slug: "lympo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lympo.png",
  },
  {
    name: "SpaceChain",
    symbol: "SPC",
    slug: "spacechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/spacechain.png",
  },
  {
    name: "BitForex Token",
    symbol: "BF",
    slug: "bitforex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitforex-token.png",
  },
  {
    name: "CBC.network",
    symbol: "CBC",
    slug: "casino-betting-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/casino-betting-coin.png",
  },
  {
    name: "Benchmark Protocol",
    symbol: "MARK",
    slug: "benchmark-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/benchmark-protocol.png",
  },
  {
    name: "FREE Coin",
    symbol: "FREE",
    slug: "free-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/free-coin.png",
  },
  {
    name: "FOAM",
    symbol: "FOAM",
    slug: "foam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/foam.png",
  },
  {
    name: "dHedge DAO",
    symbol: "DHT",
    slug: "dhedge-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dhedge-dao.png",
  },
  {
    name: "Bitcoin 2",
    symbol: "BTC2",
    slug: "bitcoin2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin2.png",
  },
  {
    name: "Pickle Finance",
    symbol: "PICKLE",
    slug: "pickle-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pickle-finance.png",
  },
  {
    name: "Aragon Court",
    symbol: "ANJ",
    slug: "aragon-court",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aragon-court.png",
  },
  {
    name: "Lympo Market Token",
    symbol: "LMT",
    slug: "lympo-market-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lympo-market-token.png",
  },
  {
    name: "Crypterium",
    symbol: "CRPT",
    slug: "crpt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crpt.png",
  },
  {
    name: "Moeda Loyalty Points",
    symbol: "MDA",
    slug: "moeda-loyalty-points",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moeda-loyalty-points.png",
  },
  {
    name: "Krypton DAO",
    symbol: "KRD",
    slug: "krypton-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/krypton-dao.png",
  },
  {
    name: "Davinci Coin",
    symbol: "DAC",
    slug: "davinci-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/davinci-coin.png",
  },
  {
    name: "JulSwap",
    symbol: "JULD",
    slug: "julswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/julswap.png",
  },
  {
    name: "ELYSIA",
    symbol: "EL",
    slug: "elysia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elysia.png",
  },
  {
    name: "Chrono.tech",
    symbol: "TIME",
    slug: "chrono-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chrono-tech.png",
  },
  {
    name: "RSS3",
    symbol: "RSS3",
    slug: "rss3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rss3.png",
  },
  {
    name: "Dentacoin",
    symbol: "DCN",
    slug: "dentacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dentacoin.png",
  },
  {
    name: "Modefi",
    symbol: "MOD",
    slug: "modefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/modefi.png",
  },
  {
    name: "Santos FC Fan Token",
    symbol: "SANTOS",
    slug: "santos-fc-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/santos-fc-fan-token.png",
  },
  {
    name: "ALLY",
    symbol: "ALY",
    slug: "ally",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ally.png",
  },
  {
    name: "Impossible Decentralized Incubator Access",
    symbol: "IDIA",
    slug: "impossible-decentralized-incubator-access",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/impossible-decentralized-incubator-access.png",
  },
  {
    name: "STEM CELL COIN",
    symbol: "SCC",
    slug: "stem-cell-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stem-cell-coin.png",
  },
  {
    name: "ProBit Token",
    symbol: "PROB",
    slug: "probit-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/probit-token.png",
  },
  {
    name: "MultiVAC",
    symbol: "MTV",
    slug: "multivac",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multivac.png",
  },
  {
    name: "FC Porto",
    symbol: "PORTO",
    slug: "fc-porto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fc-porto.png",
  },
  {
    name: "Trias (old)",
    symbol: "TRY",
    slug: "trias",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trias.png",
  },
  {
    name: "Unicly CryptoPunks Collection",
    symbol: "UPUNK",
    slug: "unicly-cryptopunks-collection",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unicly-cryptopunks-collection.png",
  },
  {
    name: "Nxt",
    symbol: "NXT",
    slug: "nxt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nxt.png",
  },
  {
    name: "Levolution",
    symbol: "LEVL",
    slug: "levolution",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/levolution.png",
  },
  {
    name: "TENA [old]",
    symbol: "TENA",
    slug: "tena-old",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tena-old.png",
  },
  {
    name: "MixMarvel",
    symbol: "MIX",
    slug: "mixmarvel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mixmarvel.png",
  },
  {
    name: "Oxbull.tech",
    symbol: "OXB",
    slug: "oxbull-tech",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oxbull-tech.png",
  },
  {
    name: "Anchor Neural World",
    symbol: "ANW",
    slug: "anchor-neural-world",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anchor-neural-world.png",
  },
  {
    name: "88mph",
    symbol: "MPH",
    slug: "88mph",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/88mph.png",
  },
  {
    name: "Validity",
    symbol: "VAL",
    slug: "validity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/validity.png",
  },
  {
    name: "Aurora",
    symbol: "AOA",
    slug: "aurora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aurora.png",
  },
  {
    name: "Muse",
    symbol: "MUSE",
    slug: "muse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/muse.png",
  },
  {
    name: "AppCoins",
    symbol: "APPC",
    slug: "appcoins",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/appcoins.png",
  },
  {
    name: "Public Index Network",
    symbol: "PIN",
    slug: "public-index-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/public-index-network.png",
  },
  {
    name: "Rainicorn",
    symbol: "RAINI",
    slug: "rainicorn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rainicorn.png",
  },
  {
    name: "Ducato Protocol Token",
    symbol: "DUCATO",
    slug: "ducato-protocol-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ducato-protocol-token.png",
  },
  {
    name: "BHPCoin",
    symbol: "BHP",
    slug: "bhp-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bhp-coin.png",
  },
  {
    name: "Meme",
    symbol: "MEME",
    slug: "degenerator-meme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degenerator-meme.png",
  },
  {
    name: "mStable Governance Token: Meta (MTA)",
    symbol: "MTA",
    slug: "meta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meta.png",
  },
  {
    name: "Venus SXP",
    symbol: "vSXP",
    slug: "vsxp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vsxp.png",
  },
  {
    name: "InsurAce",
    symbol: "INSUR",
    slug: "insurace",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insurace.png",
  },
  {
    name: "TEMCO",
    symbol: "TEMCO",
    slug: "temco",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/temco.png",
  },
  {
    name: "PRCY Coin",
    symbol: "PRCY",
    slug: "prcy-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prcy-coin.png",
  },
  {
    name: "GoCrypto Token",
    symbol: "GOC",
    slug: "gocrypto-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gocrypto-token.png",
  },
  {
    name: "AIOZ Network",
    symbol: "AIOZ",
    slug: "aioz-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aioz-network.png",
  },
  {
    name: "Ondori",
    symbol: "RSTR",
    slug: "ondori",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ondori.png",
  },
  {
    name: "Bithao",
    symbol: "BHAO",
    slug: "bithao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bithao.png",
  },
  {
    name: "Crowns",
    symbol: "CWS",
    slug: "crowns",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crowns.png",
  },
  {
    name: "Propy",
    symbol: "PRO",
    slug: "propy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/propy.png",
  },
  {
    name: "TE-FOOD",
    symbol: "TONE",
    slug: "te-food",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/te-food.png",
  },
  {
    name: "Robonomics.network",
    symbol: "XRT",
    slug: "robonomics-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/robonomics-network.png",
  },
  {
    name: "Factom",
    symbol: "FCT",
    slug: "factom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/factom.png",
  },
  {
    name: "Ignis",
    symbol: "IGNIS",
    slug: "ignis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ignis.png",
  },
  {
    name: "Banano",
    symbol: "BAN",
    slug: "banano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/banano.png",
  },
  {
    name: "Infinitecoin",
    symbol: "IFC",
    slug: "infinitecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinitecoin.png",
  },
  {
    name: "Polkadex",
    symbol: "PDEX",
    slug: "polkadex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkadex.png",
  },
  {
    name: "Niftyx Protocol",
    symbol: "SHROOM",
    slug: "niftyx-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/niftyx-protocol.png",
  },
  {
    name: "Juventus Fan Token",
    symbol: "JUV",
    slug: "juventus-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/juventus-fan-token.png",
  },
  {
    name: "BnkToTheFuture",
    symbol: "BFT",
    slug: "bnktothefuture",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bnktothefuture.png",
  },
  {
    name: "Exeedme",
    symbol: "XED",
    slug: "exeedme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/exeedme.png",
  },
  {
    name: "Locus Chain",
    symbol: "LOCUS",
    slug: "locus-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/locus-chain.png",
  },
  {
    name: "Vitae",
    symbol: "VITAE",
    slug: "vitae",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vitae.png",
  },
  {
    name: "LBRY Credits",
    symbol: "LBC",
    slug: "library-credit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/library-credit.png",
  },
  {
    name: "Santiment Network Token",
    symbol: "SAN",
    slug: "santiment",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/santiment.png",
  },
  {
    name: "Doge Token",
    symbol: "DOGET",
    slug: "doge-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doge-token.png",
  },
  {
    name: "Aleph.im",
    symbol: "ALEPH",
    slug: "aleph-im",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aleph-im.png",
  },
  {
    name: "APY.Finance",
    symbol: "APY",
    slug: "apy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apy-finance.png",
  },
  {
    name: "Glitch",
    symbol: "GLCH",
    slug: "glitch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glitch.png",
  },
  {
    name: "MAP Protocol",
    symbol: "MAP",
    slug: "map-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/map-protocol.png",
  },
  {
    name: "FLETA",
    symbol: "FLETA",
    slug: "fleta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fleta.png",
  },
  {
    name: "BigONE Token",
    symbol: "ONE",
    slug: "bigone-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bigone-token.png",
  },
  {
    name: "LGO Token",
    symbol: "LGO",
    slug: "lgo-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lgo-token.png",
  },
  {
    name: "PulsePad",
    symbol: "PLSPAD",
    slug: "pulsepad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pulsepad.png",
  },
  {
    name: "Chain Guardians",
    symbol: "CGG",
    slug: "chain-guardians",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain-guardians.png",
  },
  {
    name: "Enecuum",
    symbol: "ENQ",
    slug: "enecuum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enecuum.png",
  },
  {
    name: "DXdao",
    symbol: "DXD",
    slug: "dxdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dxdao.png",
  },
  {
    name: "Infinity PAD",
    symbol: "IPAD",
    slug: "infinity-pad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/infinity-pad.png",
  },
  {
    name: "BoringDAO",
    symbol: "BOR",
    slug: "boringdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boringdao.png",
  },
  {
    name: "DOGGY",
    symbol: "DOGGY",
    slug: "doggy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doggy.png",
  },
  {
    name: "Blox",
    symbol: "CDT",
    slug: "blox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blox.png",
  },
  {
    name: "Swingby",
    symbol: "SWINGBY",
    slug: "swingby",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swingby.png",
  },
  {
    name: "USDJ",
    symbol: "USDJ",
    slug: "usdj",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdj.png",
  },
  {
    name: "Wabi",
    symbol: "WABI",
    slug: "wabi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wabi.png",
  },
  {
    name: "EpiK Protocol",
    symbol: "EPK",
    slug: "epik-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/epik-protocol.png",
  },
  {
    name: "Corra.Finance",
    symbol: "CORA",
    slug: "corra-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/corra-finance.png",
  },
  {
    name: "CYCLUB",
    symbol: "CYCLUB",
    slug: "cyclub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cyclub.png",
  },
  {
    name: "Lambda",
    symbol: "LAMB",
    slug: "lambda",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lambda.png",
  },
  {
    name: "BaaSid",
    symbol: "BAAS",
    slug: "baasid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/baasid.png",
  },
  {
    name: "Emirex Token",
    symbol: "EMRX",
    slug: "emirex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/emirex-token.png",
  },
  {
    name: "SALT",
    symbol: "SALT",
    slug: "salt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/salt.png",
  },
  {
    name: "LiquidApps",
    symbol: "DAPP",
    slug: "liquid-apps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquid-apps.png",
  },
  {
    name: "Ferrum Network",
    symbol: "FRM",
    slug: "ferrum-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ferrum-network.png",
  },
  {
    name: "Electric Vehicle Zone",
    symbol: "EVZ",
    slug: "electric-vehicle-zone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electric-vehicle-zone.png",
  },
  {
    name: "Atomic Wallet Coin",
    symbol: "AWC",
    slug: "atomic-wallet-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atomic-wallet-coin.png",
  },
  {
    name: "1irstcoin",
    symbol: "FST",
    slug: "1irstcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1irstcoin.png",
  },
  {
    name: "Measurable Data Token",
    symbol: "MDT",
    slug: "measurable-data-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/measurable-data-token.png",
  },
  {
    name: "MOBOX",
    symbol: "MBOX",
    slug: "mobox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mobox.png",
  },
  {
    name: "BSCPAD",
    symbol: "BSCPAD",
    slug: "bscpad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bscpad.png",
  },
  {
    name: "BIDR",
    symbol: "BIDR",
    slug: "binance-idr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binance-idr.png",
  },
  {
    name: "Waves Enterprise",
    symbol: "WEST",
    slug: "waves-enterprise",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waves-enterprise.png",
  },
  {
    name: "0Chain",
    symbol: "ZCN",
    slug: "0chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0chain.png",
  },
  {
    name: "Callisto Network",
    symbol: "CLO",
    slug: "callisto-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/callisto-network.png",
  },
  {
    name: "Obyte",
    symbol: "GBYTE",
    slug: "obyte",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/obyte.png",
  },
  {
    name: "Quantum Resistant Ledger",
    symbol: "QRL",
    slug: "quantum-resistant-ledger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quantum-resistant-ledger.png",
  },
  {
    name: "Stakenet",
    symbol: "XSN",
    slug: "stakenet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stakenet.png",
  },
  {
    name: "Morpheus Labs",
    symbol: "MITX",
    slug: "morpheus-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morpheus-labs.png",
  },
  {
    name: "LikeCoin",
    symbol: "LIKE",
    slug: "likecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/likecoin.png",
  },
  {
    name: "Minter Network",
    symbol: "BIP",
    slug: "minter-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/minter-network.png",
  },
  {
    name: "VIDY",
    symbol: "VIDY",
    slug: "vidy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vidy.png",
  },
  {
    name: "Tokenomy",
    symbol: "TEN",
    slug: "tokenomy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenomy.png",
  },
  {
    name: "Hermez Network",
    symbol: "HEZ",
    slug: "hermez-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hermez-network.png",
  },
  {
    name: "Keep3rV1",
    symbol: "KP3R",
    slug: "keep3rv1",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keep3rv1.png",
  },
  {
    name: "Cryptocean",
    symbol: "CRON",
    slug: "cryptocean",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptocean.png",
  },
  {
    name: "King DAG",
    symbol: "KDAG",
    slug: "king-dag",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/king-dag.png",
  },
  {
    name: "AC Milan Fan Token",
    symbol: "ACM",
    slug: "ac-milan-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ac-milan-fan-token.png",
  },
  {
    name: "TokenClub",
    symbol: "TCT",
    slug: "tokenclub",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenclub.png",
  },
  {
    name: "Darwinia Network",
    symbol: "RING",
    slug: "darwinia-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darwinia-network.png",
  },
  {
    name: "Quiztok",
    symbol: "QTCON",
    slug: "quiztok",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quiztok.png",
  },
  {
    name: "FirmaChain",
    symbol: "FCT",
    slug: "firmachain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firmachain.png",
  },
  {
    name: "Wirex Token",
    symbol: "WXT",
    slug: "wirex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wirex-token.png",
  },
  {
    name: "KickToken [new]",
    symbol: "KICK",
    slug: "kick-token-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kick-token-new.png",
  },
  {
    name: "Kylin",
    symbol: "KYL",
    slug: "kylin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kylin.png",
  },
  {
    name: "Edge",
    symbol: "EDGE",
    slug: "edge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edge.png",
  },
  {
    name: "Attila",
    symbol: "ATT",
    slug: "attila",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/attila.png",
  },
  {
    name: "MAPS",
    symbol: "MAPS",
    slug: "maps",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maps.png",
  },
  {
    name: "BitZ Token",
    symbol: "BZ",
    slug: "bit-z-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bit-z-token.png",
  },
  {
    name: "Bitrue Coin",
    symbol: "BTR",
    slug: "bitrue-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitrue-coin.png",
  },
  {
    name: "Genesis Vision",
    symbol: "GVT",
    slug: "genesis-vision",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/genesis-vision.png",
  },
  {
    name: "Particl",
    symbol: "PART",
    slug: "particl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/particl.png",
  },
  {
    name: "Tranche Finance",
    symbol: "SLICE",
    slug: "tranche-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tranche-finance.png",
  },
  {
    name: "LCX",
    symbol: "LCX",
    slug: "lcx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lcx.png",
  },
  {
    name: "VelasPad",
    symbol: "VLXPAD",
    slug: "velaspad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/velaspad.png",
  },
  {
    name: "BABB",
    symbol: "BAX",
    slug: "babb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/babb.png",
  },
  {
    name: "Phantasma",
    symbol: "SOUL",
    slug: "phantasma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phantasma.png",
  },
  {
    name: "SIX",
    symbol: "SIX",
    slug: "six",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/six.png",
  },
  {
    name: "Neblio",
    symbol: "NEBL",
    slug: "neblio",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neblio.png",
  },
  {
    name: "GameCredits",
    symbol: "GAME",
    slug: "gamecredits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamecredits.png",
  },
  {
    name: "GameZone",
    symbol: "GZONE",
    slug: "gamezone",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gamezone.png",
  },
  {
    name: "ForTube",
    symbol: "FOR",
    slug: "the-force-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-force-protocol.png",
  },
  {
    name: "Mirrored ProShares VIX",
    symbol: "mVIXY",
    slug: "mirrored-proshares-vix-short-term-futures-etf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-proshares-vix-short-term-futures-etf.png",
  },
  {
    name: "Covalent",
    symbol: "CQT",
    slug: "covalent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/covalent.png",
  },
  {
    name: "Unisocks",
    symbol: "SOCKS",
    slug: "unisocks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unisocks.png",
  },
  {
    name: "UBIX.Network",
    symbol: "UBX",
    slug: "ubix-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ubix-network.png",
  },
  {
    name: "COVER Protocol",
    symbol: "COVER",
    slug: "cover-protocol-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cover-protocol-new.png",
  },
  {
    name: "Skycoin",
    symbol: "SKY",
    slug: "skycoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skycoin.png",
  },
  {
    name: "Tokamak Network",
    symbol: "TON",
    slug: "tokamak-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokamak-network.png",
  },
  {
    name: "Nebulas",
    symbol: "NAS",
    slug: "nebulas-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nebulas-token.png",
  },
  {
    name: "Namecoin",
    symbol: "NMC",
    slug: "namecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/namecoin.png",
  },
  {
    name: "Cardstack",
    symbol: "CARD",
    slug: "cardstack",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cardstack.png",
  },
  {
    name: "ParaSwap",
    symbol: "PSP",
    slug: "paraswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paraswap.png",
  },
  {
    name: "Electra Protocol",
    symbol: "XEP",
    slug: "electra-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electra-protocol.png",
  },
  {
    name: "Revolt 2 Earn",
    symbol: "RVLT",
    slug: "revolt-2-earn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revolt-2-earn.png",
  },
  {
    name: "Proton",
    symbol: "XPR",
    slug: "proton",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/proton.png",
  },
  {
    name: "Props Token",
    symbol: "PROPS",
    slug: "props",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/props.png",
  },
  {
    name: "ERC20",
    symbol: "ERC20",
    slug: "erc20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/erc20.png",
  },
  {
    name: "Graviton",
    symbol: "GTON",
    slug: "graviton-one",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/graviton-one.png",
  },
  {
    name: "EOS Force",
    symbol: "EOSC",
    slug: "eos-force",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eos-force.png",
  },
  {
    name: "Govi",
    symbol: "GOVI",
    slug: "govi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/govi.png",
  },
  {
    name: "AirSwap",
    symbol: "AST",
    slug: "airswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/airswap.png",
  },
  {
    name: "Bankera",
    symbol: "BNK",
    slug: "bankera",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bankera.png",
  },
  {
    name: "Mushroom",
    symbol: "MUSH",
    slug: "mushroom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mushroom.png",
  },
  {
    name: "Project Pai",
    symbol: "PAI",
    slug: "project-pai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-pai.png",
  },
  {
    name: "Router Protocol",
    symbol: "ROUTE",
    slug: "router-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/router-protocol.png",
  },
  {
    name: "Numbers Protocol",
    symbol: "NUM",
    slug: "numbers-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/numbers-protocol.png",
  },
  {
    name: "VIDT Datalink",
    symbol: "VIDT",
    slug: "vidt-datalink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vidt-datalink.png",
  },
  {
    name: "Convergence",
    symbol: "CONV",
    slug: "convergence",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/convergence.png",
  },
  {
    name: "Pangolin",
    symbol: "PNG",
    slug: "pangolin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pangolin.png",
  },
  {
    name: "GET Protocol",
    symbol: "GET",
    slug: "get-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/get-protocol.png",
  },
  {
    name: "SOLVE",
    symbol: "SOLVE",
    slug: "solve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solve.png",
  },
  {
    name: "UniLend",
    symbol: "UFT",
    slug: "unilend",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unilend.png",
  },
  {
    name: "BitKan",
    symbol: "KAN",
    slug: "bitkan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitkan.png",
  },
  {
    name: "Olyseum",
    symbol: "OLY",
    slug: "olyseum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/olyseum.png",
  },
  {
    name: "Juggernaut",
    symbol: "JGN",
    slug: "juggernaut",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/juggernaut.png",
  },
  {
    name: "Flux",
    symbol: "FLUX",
    slug: "zel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zel.png",
  },
  {
    name: "TrueFi",
    symbol: "TRU",
    slug: "truefi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/truefi-token.png",
  },
  {
    name: "Archer DAO Governance Token",
    symbol: "ARCH",
    slug: "archer-dao-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/archer-dao-governance-token.png",
  },
  {
    name: "GYEN",
    symbol: "GYEN",
    slug: "gyen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gyen.png",
  },
  {
    name: "Etherisc DIP Token",
    symbol: "DIP",
    slug: "etherisc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/etherisc.png",
  },
  {
    name: "CoinLoan",
    symbol: "CLT",
    slug: "coinloan",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinloan.png",
  },
  {
    name: "pNetwork",
    symbol: "PNT",
    slug: "pnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pnetwork.png",
  },
  {
    name: "DeepBrain Chain",
    symbol: "DBC",
    slug: "deepbrain-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deepbrain-chain.png",
  },
  {
    name: "Alpha Impact",
    symbol: "IMPACT",
    slug: "alpha-impact",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-impact.png",
  },
  {
    name: "Cindicator",
    symbol: "CND",
    slug: "cindicator",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cindicator.png",
  },
  {
    name: "NEST Protocol",
    symbol: "NEST",
    slug: "nest-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nest-protocol.png",
  },
  {
    name: "Goldfinch",
    symbol: "GFI",
    slug: "goldfinch-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/goldfinch-protocol.png",
  },
  {
    name: "ARMOR",
    symbol: "ARMOR",
    slug: "armor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/armor.png",
  },
  {
    name: "Revolution Populi",
    symbol: "RVP",
    slug: "revolution-populi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revolution-populi.png",
  },
  {
    name: "Restart Energy MWAT",
    symbol: "MWAT",
    slug: "restart-energy-mwat",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/restart-energy-mwat.png",
  },
  {
    name: "ArdCoin",
    symbol: "ARDX",
    slug: "ardcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ardcoin.png",
  },
  {
    name: "Radicle",
    symbol: "RAD",
    slug: "radicle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radicle.png",
  },
  {
    name: "SUKU",
    symbol: "SUKU",
    slug: "suku",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/suku.png",
  },
  {
    name: "FaraLand",
    symbol: "FARA",
    slug: "faraland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/faraland.png",
  },
  {
    name: "Decentral Games [new]",
    symbol: "DG",
    slug: "decentral-games-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentral-games-new.png",
  },
  {
    name: "PAID Network",
    symbol: "PAID",
    slug: "paid-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paid-network.png",
  },
  {
    name: "DeGate",
    symbol: "DG",
    slug: "degate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/degate.png",
  },
  {
    name: "Arianee",
    symbol: "ARIA20",
    slug: "arianee-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arianee-protocol.png",
  },
  {
    name: "Finxflo",
    symbol: "FXF",
    slug: "finxflo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/finxflo.png",
  },
  {
    name: "GoChain",
    symbol: "GO",
    slug: "gochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gochain.png",
  },
  {
    name: "Phoenix Global",
    symbol: "PHB",
    slug: "phoenix-global",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phoenix-global.png",
  },
  {
    name: "PIBBLE",
    symbol: "PIB",
    slug: "pibble",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pibble.png",
  },
  {
    name: "AnRKey X",
    symbol: "$ANRX",
    slug: "anrkey-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anrkey-x.png",
  },
  {
    name: "Grin",
    symbol: "GRIN",
    slug: "grin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/grin.png",
  },
  {
    name: "Binemon",
    symbol: "BIN",
    slug: "binemon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binemon.png",
  },
  {
    name: "Mirrored iShares Gold Trust",
    symbol: "mIAU",
    slug: "mirrored-ishares-gold-trust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-ishares-gold-trust.png",
  },
  {
    name: "Quantstamp",
    symbol: "QSP",
    slug: "quantstamp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quantstamp.png",
  },
  {
    name: "Raiden Network Token",
    symbol: "RDN",
    slug: "raiden-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raiden-network-token.png",
  },
  {
    name: "CUTcoin",
    symbol: "CUT",
    slug: "cutcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cutcoin.png",
  },
  {
    name: "QASH",
    symbol: "QASH",
    slug: "qash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qash.png",
  },
  {
    name: "Edgeware",
    symbol: "EDG",
    slug: "edgeware",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/edgeware.png",
  },
  {
    name: "Nerve Finance",
    symbol: "NRV",
    slug: "nerve-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nerve-finance.png",
  },
  {
    name: "Peercoin",
    symbol: "PPC",
    slug: "peercoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peercoin.png",
  },
  {
    name: "Unifty",
    symbol: "NIF",
    slug: "unifty",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unifty.png",
  },
  {
    name: "DEXTools",
    symbol: "DEXT",
    slug: "dextools",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dextools.png",
  },
  {
    name: "Gifto",
    symbol: "GTO",
    slug: "gifto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gifto.png",
  },
  {
    name: "Switcheo",
    symbol: "SWTH",
    slug: "switcheo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/switcheo.png",
  },
  {
    name: "Cortex",
    symbol: "CTXC",
    slug: "cortex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cortex.png",
  },
  {
    name: "Metaverse Dualchain Network Architecture",
    symbol: "DNA",
    slug: "metaverse-dualchain-network-architecture",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metaverse-dualchain-network-architecture.png",
  },
  {
    name: "Smartlands Network",
    symbol: "SLT",
    slug: "smartlands-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartlands-network.png",
  },
  {
    name: "Freeway Token",
    symbol: "FWT",
    slug: "freeway-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/freeway-token.png",
  },
  {
    name: "dForce",
    symbol: "DF",
    slug: "dforce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dforce.png",
  },
  {
    name: "Permission Coin",
    symbol: "ASK",
    slug: "permission-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/permission-coin.png",
  },
  {
    name: "AhaToken",
    symbol: "AHT",
    slug: "ahatoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ahatoken.png",
  },
  {
    name: "Vesper",
    symbol: "VSP",
    slug: "vesper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vesper.png",
  },
  {
    name: "League of Kingdoms Arena",
    symbol: "LOKA",
    slug: "league-of-kingdoms",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/league-of-kingdoms.png",
  },
  {
    name: "Dogelon Mars",
    symbol: "ELON",
    slug: "dogelon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogelon.png",
  },
  {
    name: "IDEX",
    symbol: "IDEX",
    slug: "idex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/idex.png",
  },
  {
    name: "Homeros",
    symbol: "HMR",
    slug: "homeros",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/homeros.png",
  },
  {
    name: "Mirrored Tesla",
    symbol: "mTSLA",
    slug: "mirrored-tesla",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-tesla.png",
  },
  {
    name: "Cashaa",
    symbol: "CAS",
    slug: "cashaa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cashaa.png",
  },
  {
    name: "SmartKey",
    symbol: "SKEY",
    slug: "smartkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/smartkey.png",
  },
  {
    name: "VNT Chain",
    symbol: "VNT",
    slug: "vnt-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vnt-chain.png",
  },
  {
    name: "Dfyn Network",
    symbol: "DFYN",
    slug: "dfyn-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dfyn-network.png",
  },
  {
    name: "Lotto",
    symbol: "LOTTO",
    slug: "lotto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lotto.png",
  },
  {
    name: "Alpine F1 Team Fan Token",
    symbol: "ALPINE",
    slug: "alpine-f1-team-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpine-f1-team-fan-token.png",
  },
  {
    name: "All Sports",
    symbol: "SOC",
    slug: "all-sports",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/all-sports.png",
  },
  {
    name: "Mirrored Alibaba",
    symbol: "mBABA",
    slug: "mirrored-alibaba",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-alibaba.png",
  },
  {
    name: "AMO Coin",
    symbol: "AMO",
    slug: "amo-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amo-coin.png",
  },
  {
    name: "BTSE",
    symbol: "BTSE",
    slug: "btse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/btse.png",
  },
  {
    name: "PRIZM",
    symbol: "PZM",
    slug: "prizm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prizm.png",
  },
  {
    name: "Bridge Mutual",
    symbol: "BMI",
    slug: "bridge-mutual",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bridge-mutual.png",
  },
  {
    name: "VITE",
    symbol: "VITE",
    slug: "vite",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vite.png",
  },
  {
    name: "SparkPoint",
    symbol: "SRK",
    slug: "sparkpoint",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sparkpoint.png",
  },
  {
    name: "DEAPcoin",
    symbol: "DEP",
    slug: "deapcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deapcoin.png",
  },
  {
    name: "Aidos Kuneen",
    symbol: "ADK",
    slug: "aidos-kuneen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aidos-kuneen.png",
  },
  {
    name: "Bao Finance",
    symbol: "BAO",
    slug: "bao-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bao-finance.png",
  },
  {
    name: "Vertcoin",
    symbol: "VTC",
    slug: "vertcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vertcoin.png",
  },
  {
    name: "Impossible Finance",
    symbol: "IF",
    slug: "impossible-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/impossible-finance.png",
  },
  {
    name: "Mirrored United States Oil Fund",
    symbol: "mUSO",
    slug: "mirrored-united-states-oil-fund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-united-states-oil-fund.png",
  },
  {
    name: "#MetaHash",
    symbol: "MHC",
    slug: "metahash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metahash.png",
  },
  {
    name: "MEVerse",
    symbol: "MEV",
    slug: "meverse",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/meverse.png",
  },
  {
    name: "Fusion",
    symbol: "FSN",
    slug: "fusion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fusion.png",
  },
  {
    name: "Polychain Monsters",
    symbol: "PMON",
    slug: "polkamon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkamon.png",
  },
  {
    name: "Safe",
    symbol: "SAFE",
    slug: "safe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safe.png",
  },
  {
    name: "Shiba Predator",
    symbol: "QOM",
    slug: "shiba-predator",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-predator.png",
  },
  {
    name: "Mirrored Netflix",
    symbol: "mNFLX",
    slug: "mirrored-netflix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-netflix.png",
  },
  {
    name: "LGCY Network",
    symbol: "LGCY",
    slug: "lgcy-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lgcy-network.png",
  },
  {
    name: "Zano",
    symbol: "ZANO",
    slug: "zano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zano.png",
  },
  {
    name: "TNC Coin",
    symbol: "TNC",
    slug: "tnc-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tnc-coin.png",
  },
  {
    name: "Drep [new]",
    symbol: "DREP",
    slug: "drep-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/drep-new.png",
  },
  {
    name: "Lattice Token",
    symbol: "LTX",
    slug: "lattice-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lattice-token.png",
  },
  {
    name: "AstroSwap",
    symbol: "ASTRO",
    slug: "astroswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/astroswap.png",
  },
  {
    name: "Shopping",
    symbol: "SPI",
    slug: "shopping",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shopping.png",
  },
  {
    name: "Navcoin",
    symbol: "NAV",
    slug: "nav-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nav-coin.png",
  },
  {
    name: "BOSAGORA",
    symbol: "BOA",
    slug: "bosagora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bosagora.png",
  },
  {
    name: "Anyswap",
    symbol: "ANY",
    slug: "anyswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anyswap.png",
  },
  {
    name: "WaykiChain",
    symbol: "WICC",
    slug: "waykichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waykichain.png",
  },
  {
    name: "Mirrored Twitter",
    symbol: "mTWTR",
    slug: "mirrored-twitter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-twitter.png",
  },
  {
    name: "PERL.eco",
    symbol: "PERL",
    slug: "perlin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/perlin.png",
  },
  {
    name: "Mirrored Apple",
    symbol: "mAAPL",
    slug: "mirrored-apple",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-apple.png",
  },
  {
    name: "XSGD",
    symbol: "XSGD",
    slug: "xsgd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xsgd.png",
  },
  {
    name: "Mirrored Invesco QQQ Trust",
    symbol: "mQQQ",
    slug: "mirrored-invesco-qqq-trust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-invesco-qqq-trust.png",
  },
  {
    name: "Huobi Pool Token",
    symbol: "HPT",
    slug: "huobi-pool-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/huobi-pool-token.png",
  },
  {
    name: "DSLA Protocol",
    symbol: "DSLA",
    slug: "dsla-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dsla-protocol.png",
  },
  {
    name: "HUNT",
    symbol: "HUNT",
    slug: "hunt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hunt.png",
  },
  {
    name: "USDK",
    symbol: "USDK",
    slug: "usdk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdk.png",
  },
  {
    name: "BASIC",
    symbol: "BASIC",
    slug: "basic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basic.png",
  },
  {
    name: "RAI Finance",
    symbol: "RAI",
    slug: "rai-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rai-finance.png",
  },
  {
    name: "ONUS",
    symbol: "ONUS",
    slug: "onus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/onus.png",
  },
  {
    name: "HyperCash",
    symbol: "HC",
    slug: "hypercash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hypercash.png",
  },
  {
    name: "Venus Reward Token",
    symbol: "VRT",
    slug: "venus-reward-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-reward-token.png",
  },
  {
    name: "VideoCoin",
    symbol: "VID",
    slug: "videocoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/videocoin.png",
  },
  {
    name: "ChainX",
    symbol: "PCX",
    slug: "chainx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainx.png",
  },
  {
    name: "Everest",
    symbol: "ID",
    slug: "everest",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everest.png",
  },
  {
    name: "PLATINCOIN",
    symbol: "PLC",
    slug: "platincoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/platincoin.png",
  },
  {
    name: "Sentivate",
    symbol: "SNTVT",
    slug: "sentivate",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sentivate.png",
  },
  {
    name: "Nash",
    symbol: "NEX",
    slug: "nash-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nash-exchange.png",
  },
  {
    name: "PowerPool",
    symbol: "CVP",
    slug: "powerpool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/powerpool.png",
  },
  {
    name: "Boson Protocol",
    symbol: "BOSON",
    slug: "boson-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/boson-protocol.png",
  },
  {
    name: "Mirrored Microsoft",
    symbol: "mMSFT",
    slug: "mirrored-microsoft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-microsoft.png",
  },
  {
    name: "JustLiquidity",
    symbol: "JUL",
    slug: "justliquidity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/justliquidity.png",
  },
  {
    name: "TiFi Token",
    symbol: "TIFI",
    slug: "tifi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tifi-token.png",
  },
  {
    name: "Paris Saint-Germain Fan Token",
    symbol: "PSG",
    slug: "paris-saint-germain-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paris-saint-germain-fan-token.png",
  },
  {
    name: "Tornado Cash",
    symbol: "TORN",
    slug: "torn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/torn.png",
  },
  {
    name: "Cocos-BCX",
    symbol: "COCOS",
    slug: "cocos-bcx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cocos-bcx.png",
  },
  {
    name: "GXChain",
    symbol: "GXC",
    slug: "gxchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gxchain.png",
  },
  {
    name: "Bifrost (BFC)",
    symbol: "BFC",
    slug: "bifrost",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bifrost.png",
  },
  {
    name: "Venus USDT",
    symbol: "vUSDT",
    slug: "venus-usdt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-usdt.png",
  },
  {
    name: "Defigram",
    symbol: "DFG",
    slug: "defigram",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defigram.png",
  },
  {
    name: "Mirrored iShares Silver Trust",
    symbol: "mSLV",
    slug: "mirrored-ishares-silver-trust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-ishares-silver-trust.png",
  },
  {
    name: "SifChain",
    symbol: "ROWAN",
    slug: "sifchain-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sifchain-token.png",
  },
  {
    name: "Selfkey",
    symbol: "KEY",
    slug: "selfkey",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/selfkey.png",
  },
  {
    name: "Strong",
    symbol: "STRONG",
    slug: "strong",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strong.png",
  },
  {
    name: "Veritaseum",
    symbol: "VERI",
    slug: "veritaseum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veritaseum.png",
  },
  {
    name: "Wing",
    symbol: "WING",
    slug: "wing",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wing.png",
  },
  {
    name: "Aeternity",
    symbol: "AE",
    slug: "aeternity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aeternity.png",
  },
  {
    name: "BitMart Token",
    symbol: "BMX",
    slug: "bitmart-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitmart-token.png",
  },
  {
    name: "Moss Coin",
    symbol: "MOC",
    slug: "moss-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moss-coin.png",
  },
  {
    name: "Dragonchain",
    symbol: "DRGN",
    slug: "dragonchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dragonchain.png",
  },
  {
    name: "Morpheus.Network",
    symbol: "MRPH",
    slug: "morpheus-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/morpheus-network.png",
  },
  {
    name: "CargoX",
    symbol: "CXO",
    slug: "cargox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cargox.png",
  },
  {
    name: "Liquity",
    symbol: "LQTY",
    slug: "liquity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquity.png",
  },
  {
    name: "Mirrored Amazon",
    symbol: "mAMZN",
    slug: "mirrored-amazon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirrored-amazon.png",
  },
  {
    name: "CUMROCKET",
    symbol: "CUMMIES",
    slug: "cumrocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cumrocket.png",
  },
  {
    name: "Maro",
    symbol: "MARO",
    slug: "maro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maro.png",
  },
  {
    name: "LATOKEN",
    symbol: "LA",
    slug: "latoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/latoken.png",
  },
  {
    name: "Nimiq",
    symbol: "NIM",
    slug: "nimiq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nimiq.png",
  },
  {
    name: "BLOCKv",
    symbol: "VEE",
    slug: "blockv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/blockv.png",
  },
  {
    name: "Telos",
    symbol: "TLOS",
    slug: "telos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/telos.png",
  },
  {
    name: "PIVX",
    symbol: "PIVX",
    slug: "pivx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pivx.png",
  },
  {
    name: "Nexus",
    symbol: "NXS",
    slug: "nexus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nexus.png",
  },
  {
    name: "Frontier",
    symbol: "FRONT",
    slug: "frontier",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frontier.png",
  },
  {
    name: "Student Coin",
    symbol: "STC",
    slug: "student-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/student-coin.png",
  },
  {
    name: "Shapeshift FOX Token",
    symbol: "FOX",
    slug: "fox-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fox-token.png",
  },
  {
    name: "CUDOS",
    symbol: "CUDOS",
    slug: "cudos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cudos.png",
  },
  {
    name: "DigixDAO",
    symbol: "DGD",
    slug: "digixdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digixdao.png",
  },
  {
    name: "NULS",
    symbol: "NULS",
    slug: "nuls",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nuls.png",
  },
  {
    name: "Metronome",
    symbol: "MET",
    slug: "metronome",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metronome.png",
  },
  {
    name: "NFTX",
    symbol: "NFTX",
    slug: "nftx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nftx.png",
  },
  {
    name: "xDai",
    symbol: "STAKE",
    slug: "xdai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xdai.png",
  },
  {
    name: "CoinEx Token",
    symbol: "CET",
    slug: "coinex-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coinex-token.png",
  },
  {
    name: "ARPA Chain",
    symbol: "ARPA",
    slug: "arpa-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arpa-chain.png",
  },
  {
    name: "Crabada",
    symbol: "CRA",
    slug: "crabada",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crabada.png",
  },
  {
    name: "Hacken Token",
    symbol: "HAI",
    slug: "hackenai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hackenai.png",
  },
  {
    name: "MARINADE STAKED SOL",
    symbol: "MSOL",
    slug: "marinade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marinade.png",
  },
  {
    name: "TerraKRW",
    symbol: "KRT",
    slug: "terra-krw",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terra-krw.png",
  },
  {
    name: "saffron.finance",
    symbol: "SFI",
    slug: "saffron-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/saffron-finance.png",
  },
  {
    name: "Valobit",
    symbol: "VBIT",
    slug: "valobit",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/valobit.png",
  },
  {
    name: "Galatasaray Fan Token",
    symbol: "GAL",
    slug: "galatasaray-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/galatasaray-fan-token.png",
  },
  {
    name: "Auto",
    symbol: "AUTO",
    slug: "auto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/auto.png",
  },
  {
    name: "Aergo",
    symbol: "AERGO",
    slug: "aergo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aergo.png",
  },
  {
    name: "Empty Set Dollar",
    symbol: "ESD",
    slug: "empty-set-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/empty-set-dollar.png",
  },
  {
    name: "BEPRO Network",
    symbol: "BEPRO",
    slug: "bepro-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bepro-network.png",
  },
  {
    name: "MANTRA DAO",
    symbol: "OM",
    slug: "mantra-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mantra-dao.png",
  },
  {
    name: "Wrapped KardiaChain",
    symbol: "WKAI",
    slug: "wrapped-kardiachain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-kardiachain.png",
  },
  {
    name: "Apollo Currency",
    symbol: "APL",
    slug: "apollo-currency",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apollo-currency.png",
  },
  {
    name: "PlatonCoin",
    symbol: "PLTC",
    slug: "platoncoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/platoncoin.png",
  },
  {
    name: "v.systems",
    symbol: "VSYS",
    slug: "v-systems",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/v-systems.png",
  },
  {
    name: "Mithril",
    symbol: "MITH",
    slug: "mithril",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mithril.png",
  },
  {
    name: "Verasity",
    symbol: "VRA",
    slug: "verasity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verasity.png",
  },
  {
    name: "Dusk Network",
    symbol: "DUSK",
    slug: "dusk-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dusk-network.png",
  },
  {
    name: "Hoge Finance",
    symbol: "HOGE",
    slug: "hoge-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hoge-finance.png",
  },
  {
    name: "MovieBloc",
    symbol: "MBL",
    slug: "moviebloc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moviebloc.png",
  },
  {
    name: "Decentral Games",
    symbol: "DG",
    slug: "decentral-games",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentral-games.png",
  },
  {
    name: "Invictus Hyperion Fund",
    symbol: "IHF",
    slug: "invictus-hyperion-fund",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/invictus-hyperion-fund.png",
  },
  {
    name: "Marlin",
    symbol: "POND",
    slug: "marlin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/marlin.png",
  },
  {
    name: "Unifi Protocol DAO",
    symbol: "UNFI",
    slug: "unifi-protocol-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unifi-protocol-dao.png",
  },
  {
    name: "BTU Protocol",
    symbol: "BTU",
    slug: "btu-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/btu-protocol.png",
  },
  {
    name: "Waltonchain",
    symbol: "WTC",
    slug: "waltonchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waltonchain.png",
  },
  {
    name: "REVV",
    symbol: "REVV",
    slug: "revv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revv.png",
  },
  {
    name: "Contentos",
    symbol: "COS",
    slug: "contentos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/contentos.png",
  },
  {
    name: "mStable USD",
    symbol: "MUSD",
    slug: "mstable-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mstable-usd.png",
  },
  {
    name: "AVINOC",
    symbol: "AVINOC",
    slug: "avinoc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avinoc.png",
  },
  {
    name: "VerusCoin",
    symbol: "VRSC",
    slug: "veruscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/veruscoin.png",
  },
  {
    name: "Elastos",
    symbol: "ELA",
    slug: "elastos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elastos.png",
  },
  {
    name: "ShareToken",
    symbol: "SHR",
    slug: "sharetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sharetoken.png",
  },
  {
    name: "Beam",
    symbol: "BEAM",
    slug: "beam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beam.png",
  },
  {
    name: "Oxen",
    symbol: "OXEN",
    slug: "oxen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oxen.png",
  },
  {
    name: "Bluzelle",
    symbol: "BLZ",
    slug: "bluzelle",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bluzelle.png",
  },
  {
    name: "Dock",
    symbol: "DOCK",
    slug: "dock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dock.png",
  },
  {
    name: "YIELD App",
    symbol: "YLD",
    slug: "yield-app",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yield-app.png",
  },
  {
    name: "Humanscape",
    symbol: "HUM",
    slug: "humanscape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/humanscape.png",
  },
  {
    name: "Zenon",
    symbol: "ZNN",
    slug: "zenon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zenon.png",
  },
  {
    name: "Lido DAO Token",
    symbol: "LDO",
    slug: "lido-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lido-dao.png",
  },
  {
    name: "Atari Token",
    symbol: "ATRI",
    slug: "atari-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/atari-token.png",
  },
  {
    name: "Refereum",
    symbol: "RFR",
    slug: "refereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/refereum.png",
  },
  {
    name: "Kadena",
    symbol: "KDA",
    slug: "kadena",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kadena.png",
  },
  {
    name: "StableXSwap",
    symbol: "STAX",
    slug: "stablexswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stablexswap.png",
  },
  {
    name: "Crust Network",
    symbol: "CRU",
    slug: "crustnetwork",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crustnetwork.png",
  },
  {
    name: "Bitball Treasure",
    symbol: "BTRS",
    slug: "bitball-treasure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitball-treasure.png",
  },
  {
    name: "KARMA",
    symbol: "KARMA",
    slug: "karma-eos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/karma-eos.png",
  },
  {
    name: "ASTA",
    symbol: "ASTA",
    slug: "asta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/asta.png",
  },
  {
    name: "LTO Network",
    symbol: "LTO",
    slug: "lto-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lto-network.png",
  },
  {
    name: "Manchester City Fan Token",
    symbol: "CITY",
    slug: "manchester-city-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/manchester-city-fan-token.png",
  },
  {
    name: "GuildFi",
    symbol: "GF",
    slug: "guildfi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/guildfi.png",
  },
  {
    name: "WHALE",
    symbol: "WHALE",
    slug: "whale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whale.png",
  },
  {
    name: "Belt Finance",
    symbol: "BELT",
    slug: "belt",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/belt.png",
  },
  {
    name: "API3",
    symbol: "API3",
    slug: "api3",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/api3.png",
  },
  {
    name: "Ultiledger",
    symbol: "ULT",
    slug: "ultiledger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultiledger.png",
  },
  {
    name: "HARD Protocol",
    symbol: "HARD",
    slug: "hard-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hard-protocol.png",
  },
  {
    name: "Standard Tokenization Protocol",
    symbol: "STPT",
    slug: "standard-tokenization-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/standard-tokenization-protocol.png",
  },
  {
    name: "Bounce Token",
    symbol: "AUCTION",
    slug: "bounce-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bounce-token.png",
  },
  {
    name: "MimbleWimbleCoin",
    symbol: "MWC",
    slug: "mimblewimblecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mimblewimblecoin.png",
  },
  {
    name: "Haven Protocol",
    symbol: "XHV",
    slug: "haven-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/haven-protocol.png",
  },
  {
    name: "Aavegotchi",
    symbol: "GHST",
    slug: "aavegotchi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aavegotchi.png",
  },
  {
    name: "Request",
    symbol: "REQ",
    slug: "request",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/request.png",
  },
  {
    name: "REI Network",
    symbol: "REI",
    slug: "rei-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rei-network.png",
  },
  {
    name: "Poseidon Network",
    symbol: "QQQ",
    slug: "poseidon-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/poseidon-network.png",
  },
  {
    name: "ReddCoin",
    symbol: "RDD",
    slug: "redd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redd.png",
  },
  {
    name: "Groestlcoin",
    symbol: "GRS",
    slug: "groestlcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/groestlcoin.png",
  },
  {
    name: "Rarible",
    symbol: "RARI",
    slug: "rarible",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rarible.png",
  },
  {
    name: "APENFT",
    symbol: "NFT",
    slug: "apenft",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apenft.png",
  },
  {
    name: "Neutrino Token",
    symbol: "NSBT",
    slug: "neutrino-system-base-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neutrino-system-base-token.png",
  },
  {
    name: "Deeper Network",
    symbol: "DPR",
    slug: "deeper-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/deeper-network.png",
  },
  {
    name: "DIA",
    symbol: "DIA",
    slug: "dia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dia.png",
  },
  {
    name: "CertiK",
    symbol: "CTK",
    slug: "certik",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/certik.png",
  },
  {
    name: "My DeFi Pet",
    symbol: "DPET",
    slug: "my-defi-pet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/my-defi-pet.png",
  },
  {
    name: "QuickSwap",
    symbol: "QUICK",
    slug: "quickswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quickswap.png",
  },
  {
    name: "unFederalReserve",
    symbol: "eRSDL",
    slug: "unfederalreserve",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unfederalreserve.png",
  },
  {
    name: "LeverFi",
    symbol: "LEVER",
    slug: "lever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lever.png",
  },
  {
    name: "RedFOX Labs",
    symbol: "RFOX",
    slug: "redfox-labs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/redfox-labs.png",
  },
  {
    name: "Bella Protocol",
    symbol: "BEL",
    slug: "bella-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bella-protocol.png",
  },
  {
    name: "Harvest Finance",
    symbol: "FARM",
    slug: "harvest-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harvest-finance.png",
  },
  {
    name: "PARSIQ",
    symbol: "PRQ",
    slug: "parsiq",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/parsiq.png",
  },
  {
    name: "Celo Dollar",
    symbol: "CUSD",
    slug: "celo-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celo-dollar.png",
  },
  {
    name: "Sora",
    symbol: "XOR",
    slug: "sora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sora.png",
  },
  {
    name: "Steem Dollars",
    symbol: "SBD",
    slug: "steem-dollars",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steem-dollars.png",
  },
  {
    name: "MX Token",
    symbol: "MX",
    slug: "mx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mx-token.png",
  },
  {
    name: "Mask Network",
    symbol: "MASK",
    slug: "mask-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mask-network.png",
  },
  {
    name: "AXEL",
    symbol: "AXEL",
    slug: "axel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axel.png",
  },
  {
    name: "H2O DAO",
    symbol: "H2O",
    slug: "h2o-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/h2o-dao.png",
  },
  {
    name: "Burger Swap",
    symbol: "BURGER",
    slug: "burger-swap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/burger-swap.png",
  },
  {
    name: "Frax Share",
    symbol: "FXS",
    slug: "frax-share",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frax-share.png",
  },
  {
    name: "Alitas",
    symbol: "ALT",
    slug: "alitas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alitas.png",
  },
  {
    name: "Vulcan Forged PYR",
    symbol: "PYR",
    slug: "vulcan-forged-pyr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vulcan-forged-pyr.png",
  },
  {
    name: "Terra Virtua Kolect",
    symbol: "TVK",
    slug: "terra-virtua-kolect",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terra-virtua-kolect.png",
  },
  {
    name: "bZx Protocol",
    symbol: "BZRX",
    slug: "bzx-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bzx-protocol.png",
  },
  {
    name: "Dvision Network",
    symbol: "DVI",
    slug: "dvision-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dvision-network.png",
  },
  {
    name: "Flamingo",
    symbol: "FLM",
    slug: "flamingo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flamingo.png",
  },
  {
    name: "Firo",
    symbol: "FIRO",
    slug: "firo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/firo.png",
  },
  {
    name: "Rari Governance Token",
    symbol: "RGT",
    slug: "rari-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rari-governance-token.png",
  },
  {
    name: "Efinity",
    symbol: "EFI",
    slug: "efinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/efinity.png",
  },
  {
    name: "DAD",
    symbol: "DAD",
    slug: "dad",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dad.png",
  },
  {
    name: "Venus BUSD",
    symbol: "vBUSD",
    slug: "venus-busd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-busd.png",
  },
  {
    name: "DxChain Token",
    symbol: "DX",
    slug: "dxchain-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dxchain-token.png",
  },
  {
    name: "DerivaDAO",
    symbol: "DDX",
    slug: "derivadao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/derivadao.png",
  },
  {
    name: "DAO Maker",
    symbol: "DAO",
    slug: "dao-maker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dao-maker.png",
  },
  {
    name: "Aion",
    symbol: "AION",
    slug: "aion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aion.png",
  },
  {
    name: "Loom Network",
    symbol: "LOOM",
    slug: "loom-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loom-network.png",
  },
  {
    name: "Beefy.Finance",
    symbol: "BIFI",
    slug: "beefy-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beefy-finance.png",
  },
  {
    name: "Bloomzed Loyalty Club Ticket",
    symbol: "BLCT",
    slug: "bloomzed-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bloomzed-token.png",
  },
  {
    name: "AdEx Network",
    symbol: "ADX",
    slug: "adx-net",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adx-net.png",
  },
  {
    name: "Tellor",
    symbol: "TRB",
    slug: "tellor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tellor.png",
  },
  {
    name: "Carry",
    symbol: "CRE",
    slug: "carry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/carry.png",
  },
  {
    name: "Thunder Token",
    symbol: "TT",
    slug: "thunder-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thunder-token.png",
  },
  {
    name: "FIO Protocol",
    symbol: "FIO",
    slug: "fio-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fio-protocol.png",
  },
  {
    name: "Gods Unchained",
    symbol: "GODS",
    slug: "gods-unchained",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gods-unchained.png",
  },
  {
    name: "AllianceBlock",
    symbol: "ALBT",
    slug: "allianceblock",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/allianceblock.png",
  },
  {
    name: "ankrETH",
    symbol: "aEth",
    slug: "ankreth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ankreth.png",
  },
  {
    name: "Alpha Quark Token",
    symbol: "AQT",
    slug: "alpha-quark-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-quark-token.png",
  },
  {
    name: "Sentinel",
    symbol: "DVPN",
    slug: "sentinel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sentinel.png",
  },
  {
    name: "GNY",
    symbol: "GNY",
    slug: "gny",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gny.png",
  },
  {
    name: "RAMP",
    symbol: "RAMP",
    slug: "ramp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ramp.png",
  },
  {
    name: "Molecular Future",
    symbol: "MOF",
    slug: "molecular-future",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/molecular-future.png",
  },
  {
    name: "TROY",
    symbol: "TROY",
    slug: "troy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/troy.png",
  },
  {
    name: "Shyft Network",
    symbol: "SHFT",
    slug: "shyft-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shyft-network.png",
  },
  {
    name: "cVault.finance",
    symbol: "CORE",
    slug: "cvault-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cvault-finance.png",
  },
  {
    name: "MXC",
    symbol: "MXC",
    slug: "mxc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mxc.png",
  },
  {
    name: "FC Barcelona Fan Token",
    symbol: "BAR",
    slug: "fc-barcelona-fan-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fc-barcelona-fan-token.png",
  },
  {
    name: "Akropolis",
    symbol: "AKRO",
    slug: "akropolis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akropolis.png",
  },
  {
    name: "Casper",
    symbol: "CSPR",
    slug: "casper",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/casper.png",
  },
  {
    name: "Chimpion",
    symbol: "BNANA",
    slug: "chimpion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chimpion.png",
  },
  {
    name: "Handshake",
    symbol: "HNS",
    slug: "handshake",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/handshake.png",
  },
  {
    name: "GlitzKoin",
    symbol: "GTN",
    slug: "glitzkoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/glitzkoin.png",
  },
  {
    name: "Automata Network",
    symbol: "ATA",
    slug: "automata-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/automata-network.png",
  },
  {
    name: "Gas",
    symbol: "GAS",
    slug: "gas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gas.png",
  },
  {
    name: "Darma Cash",
    symbol: "DMCH",
    slug: "darma-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/darma-cash.png",
  },
  {
    name: "DeXe",
    symbol: "DEXE",
    slug: "dexe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dexe.png",
  },
  {
    name: "Function X",
    symbol: "FX",
    slug: "function-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/function-x.png",
  },
  {
    name: "Secret",
    symbol: "SCRT",
    slug: "secret",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/secret.png",
  },
  {
    name: "XYO",
    symbol: "XYO",
    slug: "xyo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xyo.png",
  },
  {
    name: "Bonfida",
    symbol: "FIDA",
    slug: "bonfida",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bonfida.png",
  },
  {
    name: "Sentinel Protocol",
    symbol: "UPP",
    slug: "sentinel-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sentinel-protocol.png",
  },
  {
    name: "SuperFarm",
    symbol: "SUPER",
    slug: "superfarm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superfarm.png",
  },
  {
    name: "SuperRare",
    symbol: "RARE",
    slug: "superrare",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/superrare.png",
  },
  {
    name: "RSK Smart Bitcoin",
    symbol: "RBTC",
    slug: "rsk-smart-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rsk-smart-bitcoin.png",
  },
  {
    name: "Stargate Finance",
    symbol: "STG",
    slug: "stargate-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stargate-finance.png",
  },
  {
    name: "WELL",
    symbol: "WELL",
    slug: "well-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/well-token.png",
  },
  {
    name: "TokenPocket",
    symbol: "TPT",
    slug: "tokenpocket",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenpocket.png",
  },
  {
    name: "Litentry",
    symbol: "LIT",
    slug: "litentry",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litentry.png",
  },
  {
    name: "Polkastarter",
    symbol: "POLS",
    slug: "polkastarter",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkastarter.png",
  },
  {
    name: "PEAKDEFI",
    symbol: "PEAK",
    slug: "peakdefi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/peakdefi.png",
  },
  {
    name: "Qcash",
    symbol: "QC",
    slug: "qcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qcash.png",
  },
  {
    name: "Ellipsis",
    symbol: "EPS",
    slug: "ellipsis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ellipsis.png",
  },
  {
    name: "Energi",
    symbol: "NRG",
    slug: "energi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energi.png",
  },
  {
    name: "TrustSwap",
    symbol: "SWAP",
    slug: "trustswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trustswap.png",
  },
  {
    name: "IRISnet",
    symbol: "IRIS",
    slug: "irisnet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/irisnet.png",
  },
  {
    name: "Hegic",
    symbol: "HEGIC",
    slug: "hegic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hegic.png",
  },
  {
    name: "Basid Coin",
    symbol: "BASID",
    slug: "basid-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basid-coin.png",
  },
  {
    name: "DigitalBits",
    symbol: "XDB",
    slug: "digitalbits",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digitalbits.png",
  },
  {
    name: "Dego Finance",
    symbol: "DEGO",
    slug: "dego-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dego-finance.png",
  },
  {
    name: "DeFi Land",
    symbol: "DFL",
    slug: "defi-land",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defi-land.png",
  },
  {
    name: "Super Zero Protocol",
    symbol: "SERO",
    slug: "super-zero-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/super-zero-protocol.png",
  },
  {
    name: "district0x",
    symbol: "DNT",
    slug: "district0x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/district0x.png",
  },
  {
    name: "MiL.k",
    symbol: "MLK",
    slug: "milk-alliance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/milk-alliance.png",
  },
  {
    name: "Hathor",
    symbol: "HTR",
    slug: "hathor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hathor.png",
  },
  {
    name: "EFFORCE",
    symbol: "WOZX",
    slug: "efforce",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/efforce.png",
  },
  {
    name: "Oxygen",
    symbol: "OXY",
    slug: "oxygen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oxygen.png",
  },
  {
    name: "Power Ledger",
    symbol: "POWR",
    slug: "power-ledger",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/power-ledger.png",
  },
  {
    name: "Kin",
    symbol: "KIN",
    slug: "kin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kin.png",
  },
  {
    name: "Streamr",
    symbol: "DATA",
    slug: "streamr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/streamr.png",
  },
  {
    name: "KeeperDAO",
    symbol: "ROOK",
    slug: "keeperdao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keeperdao.png",
  },
  {
    name: "Trust Wallet Token",
    symbol: "TWT",
    slug: "trust-wallet-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trust-wallet-token.png",
  },
  {
    name: "Syscoin",
    symbol: "SYS",
    slug: "syscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/syscoin.png",
  },
  {
    name: "SSV Network",
    symbol: "SSV",
    slug: "ssv-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ssv-network.png",
  },
  {
    name: "OriginTrail",
    symbol: "TRAC",
    slug: "origintrail",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/origintrail.png",
  },
  {
    name: "Venus XVS",
    symbol: "vXVS",
    slug: "venus-xvs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-xvs.png",
  },
  {
    name: "Alchemix",
    symbol: "ALCX",
    slug: "alchemix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alchemix.png",
  },
  {
    name: "COTI",
    symbol: "COTI",
    slug: "coti",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coti.png",
  },
  {
    name: "Vai",
    symbol: "VAI",
    slug: "vai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vai.png",
  },
  {
    name: "Rally",
    symbol: "RLY",
    slug: "rally",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rally.png",
  },
  {
    name: "Hifi Finance",
    symbol: "MFT",
    slug: "mainframe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mainframe.png",
  },
  {
    name: "Komodo",
    symbol: "KMD",
    slug: "komodo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/komodo.png",
  },
  {
    name: "Kleros",
    symbol: "PNK",
    slug: "kleros",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kleros.png",
  },
  {
    name: "USDX [Kava]",
    symbol: "USDX",
    slug: "usdx-kava",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdx-kava.png",
  },
  {
    name: "NewYork Exchange",
    symbol: "NYE",
    slug: "newyork-exchange",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newyork-exchange.png",
  },
  {
    name: "BarnBridge",
    symbol: "BOND",
    slug: "barnbridge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/barnbridge.png",
  },
  {
    name: "Velas",
    symbol: "VLX",
    slug: "velas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/velas.png",
  },
  {
    name: "MonaCoin",
    symbol: "MONA",
    slug: "monacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monacoin.png",
  },
  {
    name: "Ribbon Finance",
    symbol: "RBN",
    slug: "ribbon-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ribbon-finance.png",
  },
  {
    name: "Wrapped NXM",
    symbol: "WNXM",
    slug: "wrapped-nxm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-nxm.png",
  },
  {
    name: "Tokenlon Network Token",
    symbol: "LON",
    slug: "tokenlon-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokenlon-network-token.png",
  },
  {
    name: "CryptoBlades",
    symbol: "SKILL",
    slug: "cryptoblades",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cryptoblades.png",
  },
  {
    name: "Bytom",
    symbol: "BTM",
    slug: "bytom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bytom.png",
  },
  {
    name: "PAC Protocol",
    symbol: "PAC",
    slug: "pac-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pac-protocol.png",
  },
  {
    name: "SafePal",
    symbol: "SFP",
    slug: "safepal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safepal.png",
  },
  {
    name: "Newscrypto",
    symbol: "NWC",
    slug: "newscrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/newscrypto.png",
  },
  {
    name: "Badger DAO",
    symbol: "BADGER",
    slug: "badger-dao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/badger-dao.png",
  },
  {
    name: "Populous",
    symbol: "PPT",
    slug: "populous",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/populous.png",
  },
  {
    name: "Syntropy",
    symbol: "NOIA",
    slug: "syntropy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/syntropy.png",
  },
  {
    name: "Elitium",
    symbol: "EUM",
    slug: "elitium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elitium.png",
  },
  {
    name: "Sport and Leisure",
    symbol: "SNL",
    slug: "sport-and-leisure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sport-and-leisure.png",
  },
  {
    name: "Venus ETH",
    symbol: "vETH",
    slug: "venus-eth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-eth.png",
  },
  {
    name: "Ethernity Chain",
    symbol: "ERN",
    slug: "ethernity-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethernity-chain.png",
  },
  {
    name: "Gitcoin",
    symbol: "GTC",
    slug: "gitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gitcoin.png",
  },
  {
    name: "Illuvium",
    symbol: "ILV",
    slug: "illuvium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/illuvium.png",
  },
  {
    name: "Travala.com",
    symbol: "AVA",
    slug: "travala",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/travala.png",
  },
  {
    name: "JUST",
    symbol: "JST",
    slug: "just",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/just.png",
  },
  {
    name: "Dero",
    symbol: "DERO",
    slug: "dero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dero.png",
  },
  {
    name: "Centrality",
    symbol: "CENNZ",
    slug: "centrality",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/centrality.png",
  },
  {
    name: "Utrust",
    symbol: "UTK",
    slug: "utrust",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/utrust.png",
  },
  {
    name: "Massnet",
    symbol: "MASS",
    slug: "massnet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/massnet.png",
  },
  {
    name: "dKargo",
    symbol: "DKA",
    slug: "dkargo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dkargo.png",
  },
  {
    name: "BORA",
    symbol: "BORA",
    slug: "bora",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bora.png",
  },
  {
    name: "Oasis Network",
    symbol: "ROSE",
    slug: "oasis-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/oasis-network.png",
  },
  {
    name: "RChain",
    symbol: "REV",
    slug: "rchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rchain.png",
  },
  {
    name: "ZEON",
    symbol: "ZEON",
    slug: "zeon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zeon.png",
  },
  {
    name: "QuarkChain",
    symbol: "QKC",
    slug: "quarkchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quarkchain.png",
  },
  {
    name: "DFI.Money",
    symbol: "YFII",
    slug: "yearn-finance-ii",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance-ii.png",
  },
  {
    name: "Wanchain",
    symbol: "WAN",
    slug: "wanchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wanchain.png",
  },
  {
    name: "Divi",
    symbol: "DIVI",
    slug: "divi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/divi.png",
  },
  {
    name: "Venus USDC",
    symbol: "vUSDC",
    slug: "venus-usdc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-usdc.png",
  },
  {
    name: "OpenDAO",
    symbol: "SOS",
    slug: "opendao",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/opendao.png",
  },
  {
    name: "Cream Finance",
    symbol: "CREAM",
    slug: "cream-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cream-finance.png",
  },
  {
    name: "aelf",
    symbol: "ELF",
    slug: "aelf",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aelf.png",
  },
  {
    name: "XeniosCoin",
    symbol: "XNC",
    slug: "xenioscoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xenioscoin.png",
  },
  {
    name: "STASIS EURO",
    symbol: "EURS",
    slug: "stasis-euro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stasis-euro.png",
  },
  {
    name: "Radix",
    symbol: "EXRD",
    slug: "radix",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/radix.png",
  },
  {
    name: "Everipedia",
    symbol: "IQ",
    slug: "everipedia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/everipedia.png",
  },
  {
    name: "inSure DeFi",
    symbol: "SURE",
    slug: "insure",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/insure.png",
  },
  {
    name: "Metal",
    symbol: "MTL",
    slug: "metal",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metal.png",
  },
  {
    name: "CRYPTO20",
    symbol: "C20",
    slug: "c20",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/c20.png",
  },
  {
    name: "PAX Gold",
    symbol: "PAXG",
    slug: "pax-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pax-gold.png",
  },
  {
    name: "LUKSO",
    symbol: "LYXe",
    slug: "lukso",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lukso.png",
  },
  {
    name: "MATH",
    symbol: "MATH",
    slug: "math",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/math.png",
  },
  {
    name: "Wrapped Velas",
    symbol: "WVLX",
    slug: "wrapped-velas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-velas.png",
  },
  {
    name: "Rocket Pool",
    symbol: "RPL",
    slug: "rocket-pool",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rocket-pool.png",
  },
  {
    name: "Rakon",
    symbol: "RKN",
    slug: "rakon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rakon.png",
  },
  {
    name: "Sun (New)",
    symbol: "SUN",
    slug: "sun-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sun-token.png",
  },
  {
    name: "KLAYswap Protocol",
    symbol: "KSP",
    slug: "klayswap-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klayswap-protocol.png",
  },
  {
    name: "Ampleforth Governance Token",
    symbol: "FORTH",
    slug: "ampleforth-governance-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ampleforth-governance-token.png",
  },
  {
    name: "Gala",
    symbol: "GALA",
    slug: "gala",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gala.png",
  },
  {
    name: "Render Token",
    symbol: "RNDR",
    slug: "render-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/render-token.png",
  },
  {
    name: "Linear",
    symbol: "LINA",
    slug: "linear",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/linear.png",
  },
  {
    name: "Adventure Gold",
    symbol: "AGLD",
    slug: "adventure-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/adventure-gold.png",
  },
  {
    name: "Orion Protocol",
    symbol: "ORN",
    slug: "orion-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orion-protocol.png",
  },
  {
    name: "Sapphire",
    symbol: "SAPP",
    slug: "sapphire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sapphire.png",
  },
  {
    name: "KardiaChain",
    symbol: "KAI",
    slug: "kardiachain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kardiachain.png",
  },
  {
    name: "Mines of Dalarnia",
    symbol: "DAR",
    slug: "mines-of-dalarnia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mines-of-dalarnia.png",
  },
  {
    name: "Kyber Network Crystal v2",
    symbol: "KNC",
    slug: "kyber-network-crystal-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kyber-network-crystal-v2.png",
  },
  {
    name: "Tribe",
    symbol: "TRIBE",
    slug: "tribe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tribe.png",
  },
  {
    name: "Keep Network",
    symbol: "KEEP",
    slug: "keep-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keep-network.png",
  },
  {
    name: "Venus BTC",
    symbol: "vBTC",
    slug: "venus-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-btc.png",
  },
  {
    name: "Project Galaxy",
    symbol: "GAL",
    slug: "project-galaxy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/project-galaxy.png",
  },
  {
    name: "Clover Finance",
    symbol: "CLV",
    slug: "clover",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/clover.png",
  },
  {
    name: "Diamond Launch",
    symbol: "DLC",
    slug: "diamond-launch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/diamond-launch.png",
  },
  {
    name: "Klever",
    symbol: "KLV",
    slug: "klever",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klever.png",
  },
  {
    name: "Audius",
    symbol: "AUDIO",
    slug: "audius",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/audius.png",
  },
  {
    name: "Electroneum",
    symbol: "ETN",
    slug: "electroneum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/electroneum.png",
  },
  {
    name: "Anchor Protocol",
    symbol: "ANC",
    slug: "anchor-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/anchor-protocol.png",
  },
  {
    name: "Raydium",
    symbol: "RAY",
    slug: "raydium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/raydium.png",
  },
  {
    name: "Wrapped Everscale",
    symbol: "WEVER",
    slug: "wrapped-everscale",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-everscale.png",
  },
  {
    name: "Hashflow",
    symbol: "HFT",
    slug: "hashflow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hashflow.png",
  },
  {
    name: "Toko Token",
    symbol: "TKO",
    slug: "tokocrypto",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tokocrypto.png",
  },
  {
    name: "Doctors Coin",
    symbol: "DRS",
    slug: "doctors-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/doctors-coin.png",
  },
  {
    name: "Bytecoin",
    symbol: "BCN",
    slug: "bytecoin-bcn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bytecoin-bcn.png",
  },
  {
    name: "Sologenic",
    symbol: "SOLO",
    slug: "sologenic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sologenic.png",
  },
  {
    name: "Akash Network",
    symbol: "AKT",
    slug: "akash-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/akash-network.png",
  },
  {
    name: "Persistence",
    symbol: "XPRT",
    slug: "persistence",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/persistence.png",
  },
  {
    name: "sUSD",
    symbol: "SUSD",
    slug: "susd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/susd.png",
  },
  {
    name: "Mirror Protocol",
    symbol: "MIR",
    slug: "mirror-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mirror-protocol.png",
  },
  {
    name: "KOK",
    symbol: "KOK",
    slug: "keystone-of-opportunity-knowledge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/keystone-of-opportunity-knowledge.png",
  },
  {
    name: "Frax",
    symbol: "FRAX",
    slug: "frax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/frax.png",
  },
  {
    name: "Dawn Protocol",
    symbol: "DAWN",
    slug: "dawn-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dawn-protocol.png",
  },
  {
    name: "Bitpanda Ecosystem Token",
    symbol: "BEST",
    slug: "bitpanda-ecosystem-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitpanda-ecosystem-token.png",
  },
  {
    name: "Coin98",
    symbol: "C98",
    slug: "coin98",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/coin98.png",
  },
  {
    name: "Pundi X[new]",
    symbol: "PUNDIX",
    slug: "pundix-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pundix-new.png",
  },
  {
    name: "Alien Worlds",
    symbol: "TLM",
    slug: "alien-worlds",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alien-worlds.png",
  },
  {
    name: "HedgeTrade",
    symbol: "HEDG",
    slug: "hedgetrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedgetrade.png",
  },
  {
    name: "Beyond Protocol",
    symbol: "BP",
    slug: "beyond-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/beyond-protocol.png",
  },
  {
    name: "Terra",
    symbol: "LUNA",
    slug: "terra-luna-v2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terra-luna-v2.png",
  },
  {
    name: "Orbit Chain",
    symbol: "ORC",
    slug: "orbit-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbit-chain.png",
  },
  {
    name: "Perpetual Protocol",
    symbol: "PERP",
    slug: "perpetual-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/perpetual-protocol.png",
  },
  {
    name: "TitanSwap",
    symbol: "TITAN",
    slug: "titanswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/titanswap.png",
  },
  {
    name: "Pirate Chain",
    symbol: "ARRR",
    slug: "pirate-chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pirate-chain.png",
  },
  {
    name: "ECOMI",
    symbol: "OMI",
    slug: "ecomi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ecomi.png",
  },
  {
    name: "Zelwin",
    symbol: "ZLW",
    slug: "zelwin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zelwin.png",
  },
  {
    name: "Bitcoin Cash ABC",
    symbol: "BCHA",
    slug: "bitcoin-cash-abc-2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-cash-abc-2.png",
  },
  {
    name: "dYdX",
    symbol: "DYDX",
    slug: "dydx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dydx.png",
  },
  {
    name: "Venus BNB",
    symbol: "vBNB",
    slug: "venus-bnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus-bnb.png",
  },
  {
    name: "NXM",
    symbol: "NXM",
    slug: "nxm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nxm.png",
  },
  {
    name: "Symbol",
    symbol: "XYM",
    slug: "symbol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/symbol.png",
  },
  {
    name: "DeFiChain",
    symbol: "DFI",
    slug: "defichain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/defichain.png",
  },
  {
    name: "Moonbeam",
    symbol: "GLMR",
    slug: "moonbeam",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/moonbeam.png",
  },
  {
    name: "yOUcash",
    symbol: "YOUC",
    slug: "youcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/youcash.png",
  },
  {
    name: "Liquity USD",
    symbol: "LUSD",
    slug: "liquity-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/liquity-usd.png",
  },
  {
    name: "WhiteCoin",
    symbol: "XWC",
    slug: "whitecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/whitecoin.png",
  },
  {
    name: "INO COIN",
    symbol: "INO",
    slug: "ino-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ino-coin.png",
  },
  {
    name: "The Transfer Token",
    symbol: "TTT",
    slug: "the-transfer-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-transfer-token.png",
  },
  {
    name: "Ethereum Name Service",
    symbol: "ENS",
    slug: "ethereum-name-service",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-name-service.png",
  },
  {
    name: "Creditcoin",
    symbol: "CTC",
    slug: "creditcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/creditcoin.png",
  },
  {
    name: "Huobi BTC",
    symbol: "HBTC",
    slug: "huobi-btc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/huobi-btc.png",
  },
  {
    name: "stETH (Lido)",
    symbol: "STETH",
    slug: "steth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steth.png",
  },
  {
    name: "SafeMoon",
    symbol: "SAFEMOON",
    slug: "safemoon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/safemoon.png",
  },
  {
    name: "Egoras",
    symbol: "EGR",
    slug: "egoras",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/egoras.png",
  },
  {
    name: "Chain",
    symbol: "XCN",
    slug: "chain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chain.png",
  },
  {
    name: "Counos X",
    symbol: "CCXX",
    slug: "counos-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/counos-x.png",
  },
  {
    name: "BitTorrent (new)",
    symbol: "BTT",
    slug: "bittorrent-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bittorrent-new.png",
  },
  {
    name: "Fei Protocol",
    symbol: "FEI",
    slug: "fei-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fei-protocol.png",
  },
  {
    name: "Wrapped BNB",
    symbol: "WBNB",
    slug: "wbnb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wbnb.png",
  },
  {
    name: "Wrapped TRON",
    symbol: "WTRX",
    slug: "wrapped-tron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-tron.png",
  },
  {
    name: "botXcoin",
    symbol: "BOTX",
    slug: "botxcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/botxcoin.png",
  },
  {
    name: "HEX",
    symbol: "HEX",
    slug: "hex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hex.png",
  },
  {
    name: "DODO",
    symbol: "DODO",
    slug: "dodo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dodo.png",
  },
  {
    name: "Hxro",
    symbol: "HXRO",
    slug: "hxro",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hxro.png",
  },
  {
    name: "Strike",
    symbol: "STRK",
    slug: "strike",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/strike.png",
  },
  {
    name: "BitShares",
    symbol: "BTS",
    slug: "bitshares",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitshares.png",
  },
  {
    name: "Ark",
    symbol: "ARK",
    slug: "ark",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ark.png",
  },
  {
    name: "Phala Network",
    symbol: "PHA",
    slug: "phala-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/phala-network.png",
  },
  {
    name: "Polymath",
    symbol: "POLY",
    slug: "polymath-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polymath-network.png",
  },
  {
    name: "Metadium",
    symbol: "META",
    slug: "metadium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/metadium.png",
  },
  {
    name: "RSK Infrastructure Framework",
    symbol: "RIF",
    slug: "rsk-infrastructure-framework",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rsk-infrastructure-framework.png",
  },
  {
    name: "ZKSwap",
    symbol: "ZKS",
    slug: "zkswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zkswap.png",
  },
  {
    name: "Enzyme",
    symbol: "MLN",
    slug: "enzyme",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enzyme.png",
  },
  {
    name: "Balancer",
    symbol: "BAL",
    slug: "balancer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/balancer.png",
  },
  {
    name: "Hive",
    symbol: "HIVE",
    slug: "hive-blockchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hive-blockchain.png",
  },
  {
    name: "ZB Token",
    symbol: "ZB",
    slug: "zb-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zb-token.png",
  },
  {
    name: "NuCypher",
    symbol: "NU",
    slug: "nucypher",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nucypher.png",
  },
  {
    name: "Ultra",
    symbol: "UOS",
    slug: "ultra",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ultra.png",
  },
  {
    name: "MCO",
    symbol: "MCO",
    slug: "crypto-com",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-com.png",
  },
  {
    name: "MVL",
    symbol: "MVL",
    slug: "mvl",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mvl.png",
  },
  {
    name: "Orbs",
    symbol: "ORBS",
    slug: "orbs",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orbs.png",
  },
  {
    name: "Ardor",
    symbol: "ARDR",
    slug: "ardor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ardor.png",
  },
  {
    name: "Aragon",
    symbol: "ANT",
    slug: "aragon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aragon.png",
  },
  {
    name: "Civic",
    symbol: "CVC",
    slug: "civic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/civic.png",
  },
  {
    name: "Serum",
    symbol: "SRM",
    slug: "serum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/serum.png",
  },
  {
    name: "Uquid Coin",
    symbol: "UQC",
    slug: "uquid-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uquid-coin.png",
  },
  {
    name: "Cartesi",
    symbol: "CTSI",
    slug: "cartesi",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cartesi.png",
  },
  {
    name: "Steem",
    symbol: "STEEM",
    slug: "steem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/steem.png",
  },
  {
    name: "Small Love Potion",
    symbol: "SLP",
    slug: "small-love-potion",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/small-love-potion.png",
  },
  {
    name: "NKN",
    symbol: "NKN",
    slug: "nkn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nkn.png",
  },
  {
    name: "FUNToken",
    symbol: "FUN",
    slug: "funtoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/funtoken.png",
  },
  {
    name: "Ergo",
    symbol: "ERG",
    slug: "ergo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ergo.png",
  },
  {
    name: "Celer Network",
    symbol: "CELR",
    slug: "celer-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celer-network.png",
  },
  {
    name: "SingularityNET",
    symbol: "AGIX",
    slug: "singularitynet",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/singularitynet.png",
  },
  {
    name: "StormX",
    symbol: "STMX",
    slug: "stormx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stormx.png",
  },
  {
    name: "Chromia",
    symbol: "CHR",
    slug: "chromia",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chromia.png",
  },
  {
    name: "Bitcoin Standard Hashrate Token",
    symbol: "BTCST",
    slug: "btc-standard-hashrate-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/btc-standard-hashrate-token.png",
  },
  {
    name: "Numeraire",
    symbol: "NMR",
    slug: "numeraire",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/numeraire.png",
  },
  {
    name: "Energy Web Token",
    symbol: "EWT",
    slug: "energy-web-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/energy-web-token.png",
  },
  {
    name: "Swipe",
    symbol: "SXP",
    slug: "swipe",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swipe.png",
  },
  {
    name: "Reef",
    symbol: "REEF",
    slug: "reef",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reef.png",
  },
  {
    name: "ABBC Coin",
    symbol: "ABBC",
    slug: "abbc-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/abbc-coin.png",
  },
  {
    name: "Injective Protocol",
    symbol: "INJ",
    slug: "injective-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/injective-protocol.png",
  },
  {
    name: "Unibright",
    symbol: "UBT",
    slug: "unibright",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unibright.png",
  },
  {
    name: "Orchid",
    symbol: "OXT",
    slug: "orchid",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/orchid.png",
  },
  {
    name: "Band Protocol",
    symbol: "BAND",
    slug: "band-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/band-protocol.png",
  },
  {
    name: "Venus",
    symbol: "XVS",
    slug: "venus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/venus.png",
  },
  {
    name: "IoTeX",
    symbol: "IOTX",
    slug: "iotex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iotex.png",
  },
  {
    name: "Conflux Network",
    symbol: "CFX",
    slug: "conflux-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/conflux-network.png",
  },
  {
    name: "Ontology Gas",
    symbol: "ONG",
    slug: "ontology-gas",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ontology-gas.png",
  },
  {
    name: "VeThor Token",
    symbol: "VTHO",
    slug: "vethor-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vethor-token.png",
  },
  {
    name: "iExec RLC",
    symbol: "RLC",
    slug: "rlc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/rlc.png",
  },
  {
    name: "Alpha Finance Lab",
    symbol: "ALPHA",
    slug: "alpha-finance-lab",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/alpha-finance-lab.png",
  },
  {
    name: "Prometeus",
    symbol: "PROM",
    slug: "prometeus",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/prometeus.png",
  },
  {
    name: "Stratis",
    symbol: "STRAX",
    slug: "stratis",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stratis.png",
  },
  {
    name: "MyNeighborAlice",
    symbol: "ALICE",
    slug: "myneighboralice",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/myneighboralice.png",
  },
  {
    name: "Dent",
    symbol: "DENT",
    slug: "dent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dent.png",
  },
  {
    name: "Ampleforth",
    symbol: "AMPL",
    slug: "ampleforth",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ampleforth.png",
  },
  {
    name: "Constellation",
    symbol: "DAG",
    slug: "constellation",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/constellation.png",
  },
  {
    name: "TomoChain",
    symbol: "TOMO",
    slug: "tomochain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tomochain.png",
  },
  {
    name: "Status",
    symbol: "SNT",
    slug: "status",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/status.png",
  },
  {
    name: "Mina",
    symbol: "MINA",
    slug: "mina",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mina.png",
  },
  {
    name: "WAX",
    symbol: "WAXP",
    slug: "wax",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wax.png",
  },
  {
    name: "Wootrade",
    symbol: "WOO",
    slug: "wootrade",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wootrade.png",
  },
  {
    name: "MediBloc",
    symbol: "MED",
    slug: "medibloc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/medibloc.png",
  },
  {
    name: "GateToken",
    symbol: "GT",
    slug: "gatetoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gatetoken.png",
  },
  {
    name: "Storj",
    symbol: "STORJ",
    slug: "storj",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/storj.png",
  },
  {
    name: "Fetch.ai",
    symbol: "FET",
    slug: "fetch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fetch.png",
  },
  {
    name: "Ocean Protocol",
    symbol: "OCEAN",
    slug: "ocean-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ocean-protocol.png",
  },
  {
    name: "Origin Protocol",
    symbol: "OGN",
    slug: "origin-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/origin-protocol.png",
  },
  {
    name: "WINkLink",
    symbol: "WIN",
    slug: "wink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wink.png",
  },
  {
    name: "Livepeer",
    symbol: "LPT",
    slug: "livepeer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/livepeer.png",
  },
  {
    name: "ASD",
    symbol: "BTMX",
    slug: "bitmax-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitmax-token.png",
  },
  {
    name: "Kyber Network Crystal Legacy",
    symbol: "KNC",
    slug: "kyber-network-crystal-legacy",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kyber-network-crystal-legacy.png",
  },
  {
    name: "MaidSafeCoin",
    symbol: "MAID",
    slug: "maidsafecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maidsafecoin.png",
  },
  {
    name: "Loopring",
    symbol: "LRC",
    slug: "loopring",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/loopring.png",
  },
  {
    name: "Nervos Network",
    symbol: "CKB",
    slug: "nervos-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nervos-network.png",
  },
  {
    name: "Gnosis",
    symbol: "GNO",
    slug: "gnosis-gno",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gnosis-gno.png",
  },
  {
    name: "SKALE Network",
    symbol: "SKL",
    slug: "skale-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/skale-network.png",
  },
  {
    name: "Arweave",
    symbol: "AR",
    slug: "arweave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/arweave.png",
  },
  {
    name: "Kava.io",
    symbol: "KAVA",
    slug: "kava",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kava.png",
  },
  {
    name: "WazirX",
    symbol: "WRX",
    slug: "wazirx",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wazirx.png",
  },
  {
    name: "Golem",
    symbol: "GLM",
    slug: "golem-network-tokens",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/golem-network-tokens.png",
  },
  {
    name: "Lisk",
    symbol: "LSK",
    slug: "lisk",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/lisk.png",
  },
  {
    name: "Verge",
    symbol: "XVG",
    slug: "verge",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/verge.png",
  },
  {
    name: "Gemini Dollar",
    symbol: "GUSD",
    slug: "gemini-dollar",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/gemini-dollar.png",
  },
  {
    name: "Bitcoin Diamond",
    symbol: "BCD",
    slug: "bitcoin-diamond",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-diamond.png",
  },
  {
    name: "Ren",
    symbol: "REN",
    slug: "ren",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ren.png",
  },
  {
    name: "Reserve Rights",
    symbol: "RSR",
    slug: "reserve-rights",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/reserve-rights.png",
  },
  {
    name: "Augur",
    symbol: "REP",
    slug: "augur",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/augur.png",
  },
  {
    name: "1inch",
    symbol: "1INCH",
    slug: "1inch",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/1inch.png",
  },
  {
    name: "IOST",
    symbol: "IOST",
    slug: "iostoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iostoken.png",
  },
  {
    name: "Neutrino USD",
    symbol: "USDN",
    slug: "neutrino-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neutrino-usd.png",
  },
  {
    name: "Voyager Token",
    symbol: "VGX",
    slug: "voyager-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/voyager-token.png",
  },
  {
    name: "renBTC",
    symbol: "RENBTC",
    slug: "renbtc",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/renbtc.png",
  },
  {
    name: "Ankr",
    symbol: "ANKR",
    slug: "ankr",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ankr.png",
  },
  {
    name: "Fantom",
    symbol: "FTM",
    slug: "fantom",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/fantom.png",
  },
  {
    name: "The Sandbox",
    symbol: "SAND",
    slug: "the-sandbox",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-sandbox.png",
  },
  {
    name: "UMA",
    symbol: "UMA",
    slug: "uma",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uma.png",
  },
  {
    name: "Nano",
    symbol: "NANO",
    slug: "nano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nano.png",
  },
  {
    name: "OMG Network",
    symbol: "OMG",
    slug: "omg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/omg.png",
  },
  {
    name: "ICON",
    symbol: "ICX",
    slug: "icon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/icon.png",
  },
  {
    name: "Ravencoin",
    symbol: "RVN",
    slug: "ravencoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ravencoin.png",
  },
  {
    name: "BakeryToken",
    symbol: "BAKE",
    slug: "bakerytoken",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bakerytoken.png",
  },
  {
    name: "Siacoin",
    symbol: "SC",
    slug: "siacoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/siacoin.png",
  },
  {
    name: "Revain",
    symbol: "REV",
    slug: "revain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/revain.png",
  },
  {
    name: "0x",
    symbol: "ZRX",
    slug: "0x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/0x.png",
  },
  {
    name: "Curve DAO Token",
    symbol: "CRV",
    slug: "curve-dao-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/curve-dao-token.png",
  },
  {
    name: "Horizen",
    symbol: "ZEN",
    slug: "horizen",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/horizen.png",
  },
  {
    name: "HUSD",
    symbol: "HUSD",
    slug: "husd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/husd.png",
  },
  {
    name: "Ontology",
    symbol: "ONT",
    slug: "ontology",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ontology.png",
  },
  {
    name: "Qtum",
    symbol: "QTUM",
    slug: "qtum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/qtum.png",
  },
  {
    name: "SwissBorg",
    symbol: "CHSB",
    slug: "swissborg",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/swissborg.png",
  },
  {
    name: "DigiByte",
    symbol: "DGB",
    slug: "digibyte",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/digibyte.png",
  },
  {
    name: "Celo",
    symbol: "CELO",
    slug: "celo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celo.png",
  },
  {
    name: "Mdex",
    symbol: "MDX",
    slug: "mdex",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/mdex.png",
  },
  {
    name: "Bancor",
    symbol: "BNT",
    slug: "bancor",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bancor.png",
  },
  {
    name: "Bitcoin Gold",
    symbol: "BTG",
    slug: "bitcoin-gold",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-gold.png",
  },
  {
    name: "Harmony",
    symbol: "ONE",
    slug: "harmony",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/harmony.png",
  },
  {
    name: "KuCoin Token",
    symbol: "KCS",
    slug: "kucoin-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kucoin-token.png",
  },
  {
    name: "Immutable X",
    symbol: "IMX",
    slug: "immutable-x",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/immutable-x.png",
  },
  {
    name: "Basic Attention Token",
    symbol: "BAT",
    slug: "basic-attention-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/basic-attention-token.png",
  },
  {
    name: "THORChain",
    symbol: "RUNE",
    slug: "thorchain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/thorchain.png",
  },
  {
    name: "OKB",
    symbol: "OKB",
    slug: "okb",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/okb.png",
  },
  {
    name: "Zilliqa",
    symbol: "ZIL",
    slug: "zilliqa",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zilliqa.png",
  },
  {
    name: "Nexo",
    symbol: "NEXO",
    slug: "nexo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nexo.png",
  },
  {
    name: "NEAR Protocol",
    symbol: "NEAR",
    slug: "near-protocol",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/near-protocol.png",
  },
  {
    name: "Holo",
    symbol: "HOT",
    slug: "holo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/holo.png",
  },
  {
    name: "Paxos Standard",
    symbol: "PAX",
    slug: "paxos-standard",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/paxos-standard.png",
  },
  {
    name: "XinFin Network",
    symbol: "XDC",
    slug: "xinfin-network",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xinfin-network.png",
  },
  {
    name: "Synthetix",
    symbol: "SNX",
    slug: "synthetix-network-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/synthetix-network-token.png",
  },
  {
    name: "Flow",
    symbol: "FLOW",
    slug: "flow",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/flow.png",
  },
  {
    name: "STEPN",
    symbol: "GMT",
    slug: "green-metaverse-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/green-metaverse-token.png",
  },
  {
    name: "SushiSwap",
    symbol: "SUSHI",
    slug: "sushiswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/sushiswap.png",
  },
  {
    name: "Telcoin",
    symbol: "TEL",
    slug: "telcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/telcoin.png",
  },
  {
    name: "yearn.finance",
    symbol: "YFI",
    slug: "yearn-finance",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/yearn-finance.png",
  },
  {
    name: "Helium",
    symbol: "HNT",
    slug: "helium",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/helium.png",
  },
  {
    name: "Enjin Coin",
    symbol: "ENJ",
    slug: "enjin-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/enjin-coin.png",
  },
  {
    name: "Quant",
    symbol: "QNT",
    slug: "quant",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/quant.png",
  },
  {
    name: "Decentraland",
    symbol: "MANA",
    slug: "decentraland",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decentraland.png",
  },
  {
    name: "Zcash",
    symbol: "ZEC",
    slug: "zcash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/zcash.png",
  },
  {
    name: "USDD",
    symbol: "USDD",
    slug: "usdd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/usdd.png",
  },
  {
    name: "TrueUSD",
    symbol: "TUSD",
    slug: "trueusd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/trueusd.png",
  },
  {
    name: "NEM",
    symbol: "XEM",
    slug: "nem",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/nem.png",
  },
  {
    name: "Stacks",
    symbol: "STX",
    slug: "stacks",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stacks.png",
  },
  {
    name: "Celsius",
    symbol: "CEL",
    slug: "celsius",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/celsius.png",
  },
  {
    name: "Dash",
    symbol: "DASH",
    slug: "dash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dash.png",
  },
  {
    name: "Chiliz",
    symbol: "CHZ",
    slug: "chiliz",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chiliz.png",
  },
  {
    name: "BitTorrent",
    symbol: "BTT",
    slug: "bittorrent",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bittorrent.png",
  },
  {
    name: "Kusama",
    symbol: "KSM",
    slug: "kusama",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/kusama.png",
  },
  {
    name: "The Graph",
    symbol: "GRT",
    slug: "the-graph",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/the-graph.png",
  },
  {
    name: "Aptos",
    symbol: "APT",
    slug: "aptos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aptos.png",
  },
  {
    name: "Huobi Token",
    symbol: "HT",
    slug: "huobi-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/huobi-token.png",
  },
  {
    name: "Hedera Hashgraph",
    symbol: "HBAR",
    slug: "hedera-hashgraph",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/hedera-hashgraph.png",
  },
  {
    name: "Elrond",
    symbol: "EGLD",
    slug: "elrond-egld",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/elrond-egld.png",
  },
  {
    name: "Waves",
    symbol: "WAVES",
    slug: "waves",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/waves.png",
  },
  {
    name: "Decred",
    symbol: "DCR",
    slug: "decred",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/decred.png",
  },
  {
    name: "Theta Fuel",
    symbol: "TFUEL",
    slug: "theta-fuel",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/theta-fuel.png",
  },
  {
    name: "Avalanche",
    symbol: "AVAX",
    slug: "avalanche",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/avalanche.png",
  },
  {
    name: "IOTA",
    symbol: "MIOTA",
    slug: "iota",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/iota.png",
  },
  {
    name: "TerraUSD",
    symbol: "UST",
    slug: "terrausd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terrausd.png",
  },
  {
    name: "Compound",
    symbol: "COMP",
    slug: "compound",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/compound.png",
  },
  {
    name: "Neo",
    symbol: "NEO",
    slug: "neo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/neo.png",
  },
  {
    name: "Tezos",
    symbol: "XTZ",
    slug: "tezos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tezos.png",
  },
  {
    name: "Bitcoin SV",
    symbol: "BSV",
    slug: "bitcoin-sv",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-sv.png",
  },
  {
    name: "Klaytn",
    symbol: "KLAY",
    slug: "klaytn",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/klaytn.png",
  },
  {
    name: "Cosmos",
    symbol: "ATOM",
    slug: "cosmos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cosmos.png",
  },
  {
    name: "Maker",
    symbol: "MKR",
    slug: "maker",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/maker.png",
  },
  {
    name: "SHIBA INU",
    symbol: "SHIB",
    slug: "shiba-inu",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/shiba-inu.png",
  },
  {
    name: "Bitcoin BEP2",
    symbol: "BTCB",
    slug: "bitcoin-bep2",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-bep2.png",
  },
  {
    name: "Axie Infinity",
    symbol: "AXS",
    slug: "axie-infinity",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/axie-infinity.png",
  },
  {
    name: "Amp",
    symbol: "AMP",
    slug: "amp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/amp.png",
  },
  {
    name: "PancakeSwap",
    symbol: "CAKE",
    slug: "pancakeswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/pancakeswap.png",
  },
  {
    name: "Algorand",
    symbol: "ALGO",
    slug: "algorand",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/algorand.png",
  },
  {
    name: "UNUS SED LEO",
    symbol: "LEO",
    slug: "unus-sed-leo",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/unus-sed-leo.png",
  },
  {
    name: "ApeCoin",
    symbol: "APE",
    slug: "apecoin-ape",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/apecoin-ape.png",
  },
  {
    name: "FTX Token",
    symbol: "FTT",
    slug: "ftx-token",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ftx-token.png",
  },
  {
    name: "Crypto.com Coin",
    symbol: "CRO",
    slug: "crypto-com-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/crypto-com-coin.png",
  },
  {
    name: "Terra",
    symbol: "LUNA",
    slug: "terra-luna",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/terra-luna.png",
  },
  {
    name: "EOS",
    symbol: "EOS",
    slug: "eos",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/eos.png",
  },
  {
    name: "Aave",
    symbol: "AAVE",
    slug: "aave",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/aave.png",
  },
  {
    name: "Monero",
    symbol: "XMR",
    slug: "monero",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/monero.png",
  },
  {
    name: "TRON",
    symbol: "TRX",
    slug: "tron",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tron.png",
  },
  {
    name: "Filecoin",
    symbol: "FIL",
    slug: "filecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/filecoin.png",
  },
  {
    name: "VeChain",
    symbol: "VET",
    slug: "vechain",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/vechain.png",
  },
  {
    name: "Dai",
    symbol: "DAI",
    slug: "multi-collateral-dai",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/multi-collateral-dai.png",
  },
  {
    name: "Internet Computer",
    symbol: "ICP",
    slug: "internet-computer",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/internet-computer.png",
  },
  {
    name: "Polygon",
    symbol: "MATIC",
    slug: "polygon",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polygon.png",
  },
  {
    name: "Stellar",
    symbol: "XLM",
    slug: "xlm",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/stellar.png",
  },
  {
    name: "Ethereum Classic",
    symbol: "ETC",
    slug: "ethereum-classic",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum-classic.png",
  },
  {
    name: "THETA",
    symbol: "THETA",
    slug: "theta",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/theta.png",
  },
  {
    name: "Wrapped Bitcoin",
    symbol: "WBTC",
    slug: "wrapped-bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/wrapped-bitcoin.png",
  },
  {
    name: "Chainlink",
    symbol: "LINK",
    slug: "chainlink",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/chainlink.png",
  },
  {
    name: "Solana",
    symbol: "SOL",
    slug: "solana",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/solana.png",
  },
  {
    name: "Litecoin",
    symbol: "LTC",
    slug: "litecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/litecoin.png",
  },
  {
    name: "Bitcoin Cash",
    symbol: "BCH",
    slug: "bitcoin-cash",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin-cash.png",
  },
  {
    name: "Uniswap",
    symbol: "UNI",
    slug: "uniswap",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/uniswap.png",
  },
  {
    name: "Binance USD",
    symbol: "BUSD",
    slug: "binance-usd",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binance-usd.png",
  },
  {
    name: "Polkadot",
    symbol: "DOT",
    slug: "polkadot-new",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/polkadot-new.png",
  },
  {
    name: "Dogecoin",
    symbol: "DOGE",
    slug: "dogecoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/dogecoin.png",
  },
  {
    name: "USD Coin",
    symbol: "USDC",
    slug: "usd-coin",
    img_url: "https://cryptologos.cc/logos/thumbs/usd-coin.png?v=024",
  },
  {
    name: "XRP",
    symbol: "XRP",
    slug: "xrp",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/xrp.png",
  },
  {
    name: "Cardano",
    symbol: "ADA",
    slug: "cardano",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/cardano.png",
  },
  {
    name: "Binance Coin",
    symbol: "BNB",
    slug: "binance-coin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/binance-coin.png",
  },
  {
    name: "Tether",
    symbol: "USDT",
    slug: "tether",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/tether.png",
  },
  {
    name: "Ethereum",
    symbol: "ETH",
    slug: "ethereum",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/ethereum.png",
  },
  {
    name: "Bitcoin",
    symbol: "BTC",
    slug: "bitcoin",
    img_url:
      "https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/bitcoin.png",
  },
];

export default coinList;
