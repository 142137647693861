<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
export default {
  name: "App",
  computed: {
    ...mapGetters(['getCurrentTheme']),
    isLightTheme() {
      return this.getCurrentTheme === 'light';
    }
  },
  mounted() {
    if (!this.isLightTheme) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.add("light");
    }
  },

}
</script>