<template>
  <div class="nx-fs-loader">
    <div class="nx-fs-loader__item">
      <img
        :src="`${
          isLightTheme
            ? require('@/assets/img/home/logo_primary.svg')
            : require('@/assets/img/home/logo_white.svg')
        }`"
        alt="EFIswap img item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FullScreenLoader",
  computed: {
    ...mapGetters(["getCurrentTheme"]),
    isLightTheme() {
      return this.getCurrentTheme === "light";
    },
  },
};
</script>

<style scoped>
.nx-fs-loader {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nx-fs-loader .nx-fs-loader__item {
  position: relative;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.nx-fs-loader .nx-fs-loader__item img {
  width: 95%;
}

.nx-fs-loader .nx-fs-loader__item::before {
  content: "";
  position: absolute;
  border: 2px solid #f3f3f3;
  border-top: 2px solid var(--nxPrimary);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
