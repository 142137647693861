export const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "EFISwap - Home",
    },
  },
  {
    path: "/app",
    name: "MainApp",
    component: () => import("../views/app/Index.vue"),
    meta: {
      title: "EFISwap - App",
    },
    children: [
      {
        path: "/app",
        name: "AppHome",
        component: () => import("../views/app/Index/Index.vue"),
      },
      {
        path: "/app/swap",
        name: "SwapIndex",
        component: () => import("../views/app/Swap/Index.vue"),
        meta: {
          title: "EFISwap - Swap",
        },
      },
      {
        path: "/app/market",
        name: "MarketIndex",
        component: () => import("../views/app/Market/MarketIndex.vue"),
        meta: {
          title: "EFISwap - Market",
        },
      },
      {
        path: "/app/tokens",
        name: "TokensIndex",
        component: () => import("../views/app/Tokens/TokensIndex.vue"),
        meta: {
          title: "EFISwap - Tokens",
        },
      },
      {
        path: "/app/tokens/:id",
        name: "TokenDetails",
        component: () => import("../views/app/Tokens/TokenDetails.vue"),
        meta: {
          title: "EFISwap - Token",
        },
      },
    ],
  },
];
