import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/imports";
import "@/styles";
import Card from "@/components/Global/Card.vue";
import CryptoSelect from "@/components/Global/CryptoSelect.vue";
import ModalWrapper from "@/components/Global/ModalWrapper.vue";
import Checkbox from "@/components/Global/Checkbox.vue";
import InlineLoader from "@/components/Global/InlineLoader.vue";
import AppLoader from "@/components/Global/AppLoader.vue";
import Toast from "vue-toastification";
import LottieAnimation from "lottie-web-vue";
import { numeric, imgLoaded, maxlength } from "@/directives";
const options = {
  timeout: 8000,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  position: "top-center",
  transition: "Vue-Toastification__fade",
};
const app = createApp(App);
app.use(store);
app.use(router);
app.use(Toast, options);
app.component("card", Card);
app.component("crypto-select", CryptoSelect);
app.component("modal-wrapper", ModalWrapper);
app.component("Checkbox", Checkbox);
app.component("InlineLoader", InlineLoader);
app.component("AppLoader", AppLoader);
app.directive("numeric", numeric);
app.directive("img-loaded", imgLoaded);
app.directive("maxlength", maxlength);
app.use(LottieAnimation);
app.mount("#app");
