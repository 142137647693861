import axios from "axios";
import { tokens } from "./tokens";
const etherScan = `https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=DUM4WIARIE3JM962WCZFHBHGQW3MUI87SC `;
const baseUrl = "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2";
const { coinList } = require("./coinList");

var myHeaders = new Headers();
myHeaders.append("x-access-token", "goldapi-11pp960irlh6nrmfw-io");
myHeaders.append("Content-Type", "application/json");

var requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

export const getGoldPrice = async () =>
  await fetch("https://www.goldapi.io/api/XAU/USD", requestOptions).then(
    (response) => response.json()
  );

class TokenList {
  // axios = axios.create({ baseUrl, headers: { "Content-Type": "application/json" } })
  constructor() { }

  async query(type) {
    let query = `{}`;

    if (type == "tokens") {
      return `
            {
                tokens(orderBy: volumeUSD, orderDirection: desc first:100){
                id
                name
                symbol
                    decimals
                volume
                volumeUSD
                    totalSupply
                    feesUSD
                    txCount
                    poolCount
                    totalValueLockedUSD
                    totalValueLocked
                    derivedETH
                }
                factories(first: 5) { id poolCount txCount totalVolumeUSD } bundles(first: 5) { id ethPriceUSD }
            }`;
    }

    return query;
  }

  /**
   * Send Request
   * @param {*} query
   * @returns
   */
  async sendRequest(
    query = {},
    url = "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2",
    method = "POST"
  ) {
    try {
      const response = (await axios.post(url, { query })).data;
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getTokens(total = 50, page = 0, query = `{}`) {
    let coinGecko = [];
    try {
      const resp = await fetch(`https://api.efiswapapp.com/api/v1/tokenHistories`).then(resp => resp.json())

      // console.log(resp[0])

      coinGecko = (resp[0].tokens)
    }
    catch (e) {
    }// console.log(tokenList.errors)

    var date = new Date();

    date; //# => Fri Apr 01 2011 11:14:50 GMT+0200 (CEST)

    date.setDate(date.getDate() - 20);
    const startTime = Number(
      parseFloat(new Date(date).getTime() / 1000).toFixed(0)
    );
    const stringQuery = `
    {
      tokens(
        orderBy: tradeVolumeUSD
        orderDirection: desc
        first: ${total}
        skip: ${page * total}
        where: ${query}
      ) {
        id
        name
        symbol
        decimals
        tradeVolume
        tradeVolumeUSD
        totalSupply
        txCount
        derivedETH
        tokenDayData(where: {date_gt: ${startTime}}, orderBy: date, orderDirection: desc) {
          date
          priceUSD
          dailyVolumeToken
          dailyVolumeUSD
        }
      }
      uniswapFactories(first: 5) {
        id
        pairCount
        txCount
        totalVolumeUSD
      }
      bundles(first: 5) {
        id
        ethPrice
      }
    }`

    const tokenList = await this.sendRequest(stringQuery);

    // console.log(coinGecko)

    const tokens = tokenList.data.tokens.map((token) => {
      // console.log(coinGecko)
      const foundHistory = coinGecko.find(coin => {
        if ((token.symbol == 'WETH' && coin.symbol == 'eth')) {
          console.log(coin.symbol, token.symbol)
          return coin;
        }
        if (coin.symbol?.toLowerCase() == token.symbol?.toLowerCase()) return coin;
      })
      const firstVol = token.tokenDayData[0]?.priceUSD || 0;
      const secondVol = token.tokenDayData[1]?.priceUSD || 0;

      // console.log(foundHistory ? foundHistory.price_change_percentage_24h : ((firstVol - secondVol) / firstVol) * 100, foundHistory?.symbol)
      return {
        ...token,
        price: tokenList.data.bundles[0]?.ethPriceUSD * token.derivedETH,
        "24HourVolume":
          token.tokenDayData?.reverse()?.pop()?.volumeUSD || token.volumeUSD,
        percentageChange: foundHistory ? (foundHistory.price_change_percentage_1h_in_currency || foundHistory.price_change_percentage_24h) : ((firstVol - secondVol) / firstVol) * 100,
        coingecko: foundHistory
      };
    });
    const foundWeth = tokens.find((token) => token.name == "Wrapped Ether");
    tokens.unshift({
      ...foundWeth,
      id: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      name: "Ethereum",
      symbol: "ETH",
    });
    return tokens.filter(token => token.id != '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39');
  }

  async getHistoricalData(address) {
    if (address == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE")
      address = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";

    var date = new Date();

    date; //# => Fri Apr 01 2011 11:14:50 GMT+0200 (CEST)

    date.setDate(date.getDate() - 7);
    const startTime = Number(
      parseFloat(new Date(date).getTime() / 1000).toFixed(0)
    );

    let requestData = JSON.stringify({
      query: `query tokenHourDatas($startTime: Int!, $skip: Int!, $address: Bytes!) {
      tokenHourDatas(
        first: 100
        skip: 0
        where: { token: $address, periodStartUnix_gt: $startTime }
        orderBy: periodStartUnix
        orderDirection: asc
      ) {
        periodStartUnix
        high
        low
        open
        close
        __typename
      }
    }`,
      variables: { address, startTime, skip: 0 },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2",
      headers: {
        "Content-Type": "application/json",
      },
      data: requestData,
    };

    return await (
      await axios.request(config)
    ).data?.data?.tokenHourDatas;
  }

  async getToken(id) {
    const stringQuery = `
    {
      tokens(
        orderBy: tradeVolumeUSD
        orderDirection: desc
        where: {id:"${id?.toLowerCase()}"}
      ) {
        id
        name
        symbol
        decimals
        tradeVolume
        tradeVolumeUSD
        totalSupply
        txCount
        derivedETH
      }
      uniswapFactories(first: 5) {
        id
        pairCount
        txCount
        totalVolumeUSD
      }
      bundles(first: 5) {
        id
        ethPrice
      }
    }`
    /*
      const stringQuery = `{
              tokens(orderBy: volumeUSD orderDirection: desc where:{id:"${id?.toLowerCase()}"}){
              id
              name
              symbol
                  decimals
              volume
              volumeUSD
                  totalSupply
                  feesUSD
                  txCount
                  poolCount
                  totalValueLockedUSD
                  totalValueLocked
                  derivedETH
              }
              factories(first: 5) { id poolCount txCount totalVolumeUSD } bundles(first: 5) { id ethPriceUSD }
          }`;
          */
    const tokenList = await this.sendRequest(stringQuery);

    if (tokenList.data.tokens.length == 0)
      throw new Error("Unable to find asset.");
    return tokenList.data.tokens.map((token) => {
      return {
        ...token,
        price: tokenList.data.bundles[0]?.ethPriceUSD * token.derivedETH,
      };
    })[0];
  }

  async gasTrack() {
    const data = await (
      await fetch("https://nyxex-swap-backend.bankytechs.com/api/v1/gasTracks")
    ).json();
    const { low, average, fast } = data[0];
    return { low, average, fast };
  }

  async searchToken(name, total = 50, page = 0) {
    return await this.getTokens(
      total,
      page,
      `{ name_contains_nocase: "${name}" }`
    );
  }

  async topTokens(total = 50, page = 0) {
    return await this.getTokens(total, page);
  }

  async bottomTokens(total = 50, page = 0) {
    return (await this.getTokens(total, page)).reverse();
  }

  getLogo(symbol) {
    return coinList.find(
      (coin) => coin.symbol?.toLowerCase() == symbol?.toLowerCase()
    )?.img_url;
  }
}
export const Token = new TokenList();
