<template>
  <div
    class="nx-card-container"
    :class="{
      'has--shadow1': hasShadow,
      'uses-gradient': usesGradient,
      'min-height': minHeight,
    }"
  >
    <img
      :src="[
        isLightTheme
          ? require('@/assets/bg/card_edge_white.svg')
          : require('@/assets/bg/card_edge.svg'),
      ]"
      class="nx-card-container__edge"
      v-if="!usesGradient"
    />
    <img
      :src="[
        isLightTheme
          ? require('@/assets/bg/large_card_edge_gradient_dark.svg')
          : require('@/assets/bg/large_card_edge_gradient_white.svg'),
      ]"
      class="nx-card-container__edge"
      v-else
    />
    <div class="nx-card-inner" ref="cardInner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Card",
  props: {
    hasShadow: {
      type: Boolean,
      default: false,
    },
    usesGradient: {
      type: Boolean,
      default: false,
    },
    beforeWidth: {
      type: String,
      default: "70%",
    },
    minHeight: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const cardInner = this.$refs.cardInner;
    cardInner.style.setProperty("--before-width", this.beforeWidth);
  },
  computed: {
    ...mapGetters(["getCurrentTheme"]),
    isLightTheme() {
      return this.getCurrentTheme === "light";
    },
  },
};
</script>

<style>
.nx-card-container {
  position: relative;
  background-color: var(--nxSecondary);
  border: 2px solid var(--nxSecondary);
  overflow: hidden;
  border-radius: 20px;
  z-index: 7;
}

.nx-card-container .nx-card-container__edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.nx-card-container.uses-gradient .nx-card-inner {
  background: var(--nxGradient2);
}

.nx-card-container.uses-gradient .nx-card-inner:before {
  background: #c90ed3;
}

.nx-card-container.uses-gradient {
  background-color: var(--nxDark) !important;
  border: unset !important;
}

.nx-card-inner {
  width: 100%;
  min-height: 100px;
  display: inline-block;
  margin: auto;
  position: relative;
  border-radius: 0 20px 20px 20px;
  margin-bottom: -8px;
  margin-top: 12px;
  padding: 3rem 2.5rem;
  background-color: var(--nxWhite);
}
.nx-card-container.min-height .nx-card-inner {
  min-height: 300px;
}

@media screen and (max-width: 768px) {
  .nx-card-inner {
    padding: 3rem 1.5rem;
  }
}
</style>
