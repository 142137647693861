import axios from "axios";
export const shortenStringAtCenter = (
  str,
  maxLength = 11,
  ellipsis = "..."
) => {
  if (str.length <= maxLength) {
    return str;
  }
  const ellipsisLength = ellipsis.length;
  const centerIndex = Math.floor((maxLength - ellipsisLength) / 2);
  const leftHalf = str.slice(0, centerIndex);
  const rightHalf = str.slice(str.length - centerIndex);
  return leftHalf + ellipsis + rightHalf;
};
export const convertToNumber = (value, precision=5) => {
  const parsedValue = Number.parseFloat(value);
  return Number.isNaN(parsedValue) ? 0.0 : parsedValue.toFixed(precision);
};
export const formatCurrency = (amount, currency = "USD") => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  });
  return formatter.format(amount);
};
// export const getBinanceUrl = async () => {
//   let response = await fetch("https://ip2c.org/s", { method: "GET" });
//   let data = await response.text();
//   let countryCode = data.split("|")[1];
//   let binanceUrl = countryCode === "US" ? "binance.us" : "binance.com";
//   return binanceUrl;
// };
export let countryCode;
export const getBinanceUrl = async () => {
  const baseUrl = `binance.us`;
  if (!countryCode) {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        countryCode = data.country_code?.toLowerCase();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return countryCode == "us" ? baseUrl : "binance.com";
};
