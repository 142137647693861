<template>
  <div>
    <div
      class="nx-select-area d-flex align-items-center bg--grey2 cursor--pointer"
      :class="{
        'nx-select-area--plain': isPlain,
      }"
      @click="openSelectModal"
    >
      <div class="d-flex align-items-center">
        <div class="me-1">
          <img
            :src="activeCoin.logo"
            alt="EFIswap img item"
            class="standard-icon standard-icon--small standard-icon--rounded"
            v-if="activeCoin"
            v-img-loaded="
              require('@/assets/icons/placeholder/broken_coin_icon.svg')
            "
          />
        </div>
        <span v-if="isLoading" class="text__body--smaller text-is--dark"
          >Fetching...</span
        >
        <span class="text__body--smaller text-is--dark" v-if="!isLoading">
          {{ activeCoin ? activeCoin.symbol : "Select Coin" }}
        </span>
      </div>
      <div class="text__body--small text-muted">
        <i class="bi bi-caret-down-fill"></i>
      </div>
    </div>
    <modal-wrapper ref="select_modal">
      <div class="nx-search-wrapper w-100 py-3">
        <img src="@/assets/icons/searchToken.svg" alt="EFIswap img item" />
        <input type="text" placeholder="Search Token" v-model="searchTerm" />
      </div>
      <div
        class="text-center text__body--small text-is--dark"
        v-if="errorOccurred"
      >
        An error occurred while fetching tokens.
        <a href="javascript:void(0)" class="text-is--primary" @click="getTokens"
          >Try agan.</a
        >
      </div>
      <div class="mt-4 nx-list-area" v-if="!isLoading && coins.length > 0">
        <div
          v-for="token in computedCoins"
          :key="token.id"
          class="cursor--pointer nx-select-item"
          :class="{
            'nx-select-item--active':
              activeCoin && token.symbol == activeCoin.symbol,
            'nx-select-item--unavailable': token.isUnavailable,
          }"
          @click="changeCoin(token)"
        >
          <div class="d-flex align-items-center py-3 my-2">
            <div class="me-3">
              <img
                :src="token.logo"
                alt="EFIswap img item"
                class="standard-icon standard-icon--rounded"
                v-img-loaded="
                  require('@/assets/icons/placeholder/broken_coin_icon.svg')
                "
              />
            </div>
            <span class="text__body--smaller text-is--dark">
              {{ token.name }}
              <!-- <span v-if="token.isUnavailable">(Unavailable)</span> -->
            </span>
          </div>
        </div>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import { getGoldPrice, Token } from "@/helpers/tokenList";
import { roraHoldings } from "@/helpers/roraHoldings";
export default {
  name: "CryptoSelect",
  props: {
    exclude: {
      type: String,
    },
    include: {
      type: Array,
    },
    isPlain: {
      type: Boolean,
    },
  },
  data() {
    return {
      coins: [],
      activeCoin: null,
      searchTerm: "",
      isLoading: false,
      errorOccurred: false,
      goldPrice: {},
    };
  },
  async mounted() {
    this.getTokens();

    this.goldPrice = await getGoldPrice();
  },
  computed: {
    computedCoins() {
      let filtered = [...this.coins].map((token) => ({
        ...token,
        isUnavailable: false,
        logo: Token.getLogo(token.symbol),
      }));
      let roraHoldingFiltered = roraHoldings.map((token) => {
        if (token.symbol?.toLowerCase() == "rorag") {
          token.price = this.goldPrice.price;
          token.priceUSD = this.goldPrice.price;
        }
        return {
          ...token,
          isUnavailable: false,
        };
      });

      if (this.exclude) {
        filtered = filtered.filter((wallet) => wallet.symbol !== this.exclude);
        roraHoldingFiltered = roraHoldingFiltered.filter(
          (wallet) => wallet.symbol !== this.exclude
        );
      }
      if (this.searchTerm) {
        filtered = filtered.filter(
          (wallet) =>
            wallet.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            wallet.symbol.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
        roraHoldingFiltered = roraHoldingFiltered.filter(
          (wallet) =>
            wallet.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            wallet.symbol.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
      if (this.include && this.include.length > 0) {
        filtered = filtered.filter((wallet) => {
          const included = this.include.includes(wallet.symbol.toLowerCase());
          if (included) {
            wallet.isUnavailable = false;
          }
          return included;
        });
        roraHoldingFiltered = roraHoldingFiltered.filter((wallet) => {
          const included = this.include.includes(wallet.symbol.toLowerCase());
          if (included) {
            wallet.isUnavailable = false;
          }
          return included;
        });
      }
      return [...roraHoldingFiltered, ...filtered];
    },
  },

  methods: {
    async getTokens() {
      this.isLoading = true;
      this.errorOccurred = false;
      try {
        const tokens = await Token.topTokens(100, 0);
        this.coins = tokens;
      } catch (error) {
        console.log(error);
        this.errorOccurred = true;
      } finally {
        this.isLoading = false;
      }
    },
    openSelectModal() {
      if (this.isLoading) {
        return;
      }
      this.$refs.select_modal.openModal();
    },
    closeSelectModal() {
      this.$refs.select_modal.closeModal();
    },
    clearSelected() {
      this.activeCoin = null;
    },
    changeCoin(coin) {
      if (coin.isUnavailable) {
        return;
      }
      this.activeCoin = coin;
      this.$emit("coinChanged", coin);
      this.closeSelectModal();
      this.searchTerm = "";
    },
  },
};
</script>

<style scoped>
.nx-select-area {
  height: 60px;
  flex: 1;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  transition: 0.5s ease-in-out;
  width: 130px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  max-width: 100%;
  transition: 0.5s ease-in-out;
}
.nx-select-area:hover {
  border: 1px solid var(--nxPrimary);
}

.nx-select-area--plain {
  height: auto;
  min-height: 48px;
  border-radius: 8px !important;
  width: auto;
  min-width: 140px;
}

.nx-select-item {
  padding: 0 0.8rem;
}

.nx-select-item.nx-select-item--active {
  border: 1px solid var(--nxSecondary);
  border-radius: 1rem;
}
.nx-select-item.nx-select-item--unavailable {
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .nx-select-area {
    min-width: 100%;
  }
}
</style>
